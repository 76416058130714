import { PaginationState, SortingState } from "@tanstack/react-table";
import classNames from "classnames";
import React, { useEffect, useMemo, useState } from "react";
import ExportUsersModal from "../../components/BackendUsers/ExportUsersTable";
import { baseObj } from "../../utils/constants";
import Button from "../../components/common/Button";
import {
  AddIcon,
  CalendarIcon,
  CloseIcon,
  CustomizeLayoutIcon,
  ExportIcon,
  HorizontalGridIcon,
  SearchIcon,
} from "../../components/common/Icons";
import DebounceSearchInput from "../../components/Tables/DebounceSearchInput";
import { NumberDropdownList } from "../../components/common/DropdownList";
import styles from "./LeaveApplication.module.scss";
import LeaveApplicationTable from "../../components/LeaveApplicationTable/LeaveApplicationTable";
import { renderDateFormatYMD } from "../../components/DashboardCalendarView/DashboardAddEventPopup";
import {
  useChangeApproveLeaveStatusMutation,
  useChangeRejectedLeaveStatusMutation,
  useEditDataMutation,
  useGetLeaveDataQuery,
  useGetLeaveFilterDataQuery,
} from "../../app/services/leave-application";
import { StringDropdownListWithDeleteBtn } from "../../components/common/StringDropdownListWithDeleteBtn";
// import { TemplateData } from "../../app/services/employee";
import { useGetByModuleTemplateQuery } from "../../app/services/template";
import { templateList } from "../../active-time-report/views/ActiveTimeReport";
import { hideAllNoneField } from "../../components/Tables/TablePaginator";
import SuccessPopup from "../../components/SuccessPopup/SuccessPopup";
import ErrorPopup from "../../components/ErrorPopup/ErrorPopup";
import { useNavigate } from "react-router-dom";
import { FetchBaseError } from "../../components/common/FetchBaseQueryError";
import {
  getAuthUser,
  useGetEventsDataQuery,
  useGetIndicatorsQuery,
} from "../../app/services/dashboard";
import DashboardCalendarView from "../../components/DashboardCalendarView/DashboardCalendarView";
import CustomDropdownListForFilter from "../../components/EmployeeTable/CustomDropdownListForFilter";
import CustomScrollbar from "../../components/CustomScrollbar/CustomScrollbar";
import DatePopupFoFilter from "../../components/EmployeeTable/DatePopupFoFilter";
import { useSpring, animated } from "react-spring";
import CommonTableSkeleton from "../../components/TableSkeleton/CommonTableSkeleton";
import axios from "../../axios";
import { endpointUrl } from "../../app/services/api";
import { toast } from "react-toastify";
import LeaveApplicationFilesPopup from "../../components/LeaveApplicationFIlesPopup/LeaveApplicationFilesPopup";

export interface IleaveType {
  id: number;
  name: string;
  code: string;
}

export interface IKeyValue {
  id: number;
  name?: string;
  label?: string;
}

export interface ILeave {
  appliedAt: string;
  employeeName: string;
  leaveType: string;
  from: string;
  to: string;
  fullHalfDay: string;
  total: number;
  reason: string;
  status: string;
  remark: string;
  documents: Tdocuments[];
}

export type Tdocuments = {
  id: number;
  hash: string;
  name: string;
  path: string;
  url: string;
  type: string;
};

const LeaveApplication: React.FC = () => {
  const navigate = useNavigate();

  const [currentView, setCurrentView] = useState<any>(0);
  const [currentViewData, setCurrentViewData] = useState<number>(0);
  const [successPopup, setSuccessPopup] = useState<boolean>(false);
  const [errorPopup, setErrorPopup] = useState<boolean>(false);
  const [message, setMessage] = useState<any>([]);

  const [leaveStatus, setLeaveStatus] = useState("All");
  const [type, setType] = useState("All");
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  //const [getDate, setGetDate] = useState<any>({ start: "", end: "" })
  const [data, setData] = useState<baseObj[]>([]);
  const [globalSearch, setGlobalSearch] = useState<string>("");
  //const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [typeData, setTypeData] = useState<baseObj[] | undefined>([]);
  let [localModuleId, setLocalModuleId] = useState<number>(0);
  const [moduleList, setModuleList] = useState<any>([]);
  const [tempList, setTempList] = useState<any>(templateList);
  const [itemCount, setItemCount] = useState<number>(data?.length);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const [leaveTypeData, setLeaveTypeData] = useState<IleaveType[]>([]);
  const [leaveFullHalfData, setLeaveFullHalfData] = useState<IKeyValue[]>([]);

  const [viewCols, setCols] = useState<any>([]);
  const [isTemplate, setIsTemplate] = useState<boolean>(false);
  const { data: eventsData } = useGetEventsDataQuery();
  const { data: indicators } = useGetIndicatorsQuery();
  const [checkExport, setCheckExport] = useState<boolean>(false);
  const defaultFields = useMemo(
    () => [
      "appliedAt",
      "employeeName",
      "leave_type_id",
      "from",
      "to",
      "fullHalfDay",
      "total",
      "reason",
      "status",
      "remark",
      "documents",
    ],
    []
  );

  const [showLeaveFile, setShowLeaveFile] = useState<boolean>(false);
  const [leaveDocuments, setLeaveDocuments] = useState<Tdocuments[]>([]);
  const [hiddenFields, setHiddenFields] = useState<string[]>([]);
  //const [templatePlaceholder, setTemplatePlaceholder] = useState<string>("---");
  const [isLayoutControlOpen, setIsLayoutControlOpen] =
    useState<boolean>(false);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  });

  const {
    data: leave,
    isFetching,
    isLoading,
    error,
  } = useGetLeaveDataQuery({
    type: type,
    search: globalSearch,
    status: leaveStatus,
    limit: pagination.pageSize,
    page: currentPage,
    start: startDate === undefined ? "" : renderDateFormatYMD(startDate, "-"),
    end: endDate === undefined ? "" : renderDateFormatYMD(endDate, "-"),
    sort: sorting.map((s) => `${s.desc ? '-' : ''}${s.id == "appliedAt" ? "applied_at" : s.id == "from" ? "from_date" : s.id == "to" ? "to_date" : s.id == "employeeName" ? "employee_name" : s.id}`).join(','),
  });
  const { data: leaveFilterData } = useGetLeaveFilterDataQuery();

  const { data: templateData } = useGetByModuleTemplateQuery(localModuleId);
  const [statusListData, setStatusListData] = useState<string[]>([]);
  const [changeApproveLeaveStatus] = useChangeApproveLeaveStatusMutation();
  const [editLeave] = useEditDataMutation();
  const [changeRejectedLeaveStatus] = useChangeRejectedLeaveStatusMutation();
  const [isTableView, setIsTableView] = useState<boolean>(true);
  // const { data: typeList } = useGetMyAuthLeaveTypesQuery();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [checkManage, setCheckManage] = useState<boolean>(false);
  const [checkManageLeave, setCheckManageLeave] = useState<boolean>(false);
  const [isExportModalOpen, setIsExportModalOpen] = useState<boolean>(false);
  const [isExporting, setIsExporting] = useState<boolean>(false);
  var getUser = getAuthUser();
  const getAuthPermis: string[] = useMemo(() => [], []);

  if (getUser?.permissions) {
    getUser?.permissions?.forEach((per: any) => {
      getAuthPermis.push(per?.name);
    });
  }

  useEffect(() => {
    const filterExport = getAuthPermis.filter((f) => f === "Export_Leave");
    const filterManagement = getAuthPermis.filter(
      (f) => f === "Manage_All_Leave_Application"
    );
    const filterApproval = getAuthPermis.filter(
      (f) => f === "Manage_Leave_Approval"
    );
    if (filterManagement.length > 0) {
      setCheckManage(true);
      setCheckManageLeave(true);
    }
    if (filterApproval.length > 0) {
      setCheckManage(true);
    }
    if (filterExport.length > 0) {
      setCheckExport(true);
    }
  }, [getUser, getAuthPermis]);

  useEffect(() => {
    if (leave) {
      if (isFetching) {
        setData([]);
      } else {
        setData(leave?.leaveData?.data);
      }

      setLocalModuleId(leave?.module_id);
      setItemCount(leave?.leaveData?.total);
    }
  }, [leaveStatus, leave, currentView, hiddenFields, isFetching]);
  useEffect(() => {
    if (leaveFilterData) {
      setLeaveTypeData(leaveFilterData?.leaveType);
      var data = leaveFilterData?.leaveType?.map((x: any, i: number) => {
        return { id: x.id, name: x.name, label: x.name };
      });
      setTypeData([{ name: "All", id: 0, label: "All" }, ...data]);
      setStatusListData(["All", ...leaveFilterData?.statusData]);
      const obj = leaveFilterData?.leaveFullHalf?.map((leave: string, index: number) => {
        return {
          id: index + 1,
          name: leave,
          label: leave,
        };
      });
      setLeaveFullHalfData(obj ? obj : []);
    }
  }, [leaveFilterData])

  useEffect(() => {
    if (templateData) {
      var updatedTemplate = templateData?.templates.map((x: any, i) => {
        return { ...x, index: i + 1 };
      });
      var defaultTemplate = {
        id: -1,
        index: 0,
        module: 0,
        name: "All",
      };
      setModuleList([defaultTemplate, ...updatedTemplate]);
    }
  }, [templateData]);

  useEffect(() => {
    if (templateList) {
      setTempList(templateList);
    }
    var viewData = templateData?.templates.find(
      (x) => x.id.toString() === currentView.toString()
    );
    setHiddenFields(viewData ? [...viewData.viewColumns] : defaultFields);
    hideAllNoneField();
  }, [currentView, defaultFields]);

  useEffect(() => {
    if (viewCols?.view_columns?.length > 0) {
      setHiddenFields(viewCols?.view_columns);
    }
  }, [viewCols?.view_columns]);

  useEffect(() => {
    if (moduleList) {
      var lastesTemplate = moduleList[moduleList?.length - 1];
      setCurrentViewData(lastesTemplate ? Number(lastesTemplate?.id) : 0);
    }
  }, [moduleList]);

  const deleteTemplateData = (id: string) => {
    var filterTempList = templateList?.filter((x) => x.id !== parseInt(id));
    setTempList(filterTempList ? filterTempList : []);
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [
    globalSearch,
    type,
    leaveStatus,
    startDate,
    endDate,
    pagination.pageSize,
  ]);

  useEffect(() => {
    if (successPopup) {
      toast("Done Successfully.", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        type: "success",
      });
    }

    setTimeout(() => {
      setSuccessPopup(false);
    }, 2000);
  }, [successPopup]);

  const props = useSpring({
    to: { opacity: 1 },
    from: { opacity: 0 },
    delay: 1000,
  });

  if (error) {
    if (FetchBaseError(error)) {
      if (Number(error?.status) === 400) {
        localStorage.removeItem("userData");
        navigate("/login");
      } else {
        setErrorPopup(true);
        setMessage(
          error?.data?.message
            ? [error?.data?.message]
            : ["You are something wrong. Pls login Again!"]
        );
      }
    }
  }

  const exportLeaveData = (format: string, isChecked: string) => {
    axios({
      url:
        endpointUrl +
        `leaveApplicationData?format=${format}&search=${globalSearch}&start=${startDate ? renderDateFormatYMD(startDate, "-") : ""
        }&end=${endDate ? renderDateFormatYMD(endDate, "-") : ""
        }&type=${type}&status=${leaveStatus}&export=true&only_this_page=${isChecked}&limit=${pagination.pageSize}`,
      method: "GET",
      responseType: "blob",
      headers: {
        Authorization: `Bearer ${getUser.token}`,
      },
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          "leaveapplication." + (format === "excel" ? "xlsx" : format)
        );
        document.body.appendChild(link);
        link.click();
        setIsExporting(false);
        setIsExportModalOpen(false);
      })
      .catch((error: any) => {
        setErrorPopup(true);
        setMessage(
          error?.message
            ? [error?.message]
            : ["Something wrong!. Trying again."]
        );
        setIsExporting(false);
      });
  };

  return (
    <>
      {isLoading ? (
        <CommonTableSkeleton rows={12} />
      ) : (
        <>
          {showLeaveFile && leaveDocuments.length && (
            <LeaveApplicationFilesPopup
              isOpen={showLeaveFile}
              setIsOpen={setShowLeaveFile}
              leaveDocuments={leaveDocuments}
            />
          )}

          <ExportUsersModal
            isOpen={isExportModalOpen}
            setIsOpen={setIsExportModalOpen}
            exportToCSV={exportLeaveData}
            exportToExcel={exportLeaveData}
            exportToPDF={exportLeaveData}
            exportToZip={exportLeaveData}
            isExporting={isExporting}
            setIsExporting={setIsExporting}
            isShowOnlyCheckbox={true}
          />
          <ErrorPopup
            descText="Error Message!"
            confirmBtnText="Yes, Close."
            show={errorPopup}
            setShow={setErrorPopup}
            message={message}
            setMessage={setMessage}
          />
          <div
            className={classNames(
              "px-2 sm:px-4 py-4 bg-white sm:mt-[25px] mt-0 font-poppins text-13 font-semibold text-[#858795] tracking-[-0.03rem] w-full h-full rounded-xl pt-3 pb-8 sm:pt-3 sm:pb-9 "
            )}
          >
            <div className="">
              <div className="flex flex-wrap items-center">
                <div className="flex flex-wrap justify-between items-center w-full">
                  <div className="flex items-center mr-2 xs:mr-4 sm:w-[260px] w-[200px] my-1">
                    <DebounceSearchInput
                      setState={setGlobalSearch}
                      debounce={800}
                    >
                      {(setState) => (
                        <div className="font-normal flex border-1 border-[#C4C4C4] bg-white hover:bg-[#EFEFEF] rounded-[28px]">
                          <input
                            type="text"
                            placeholder="Search"
                            className="font-normal focus:outline-none py-1 px-4 text-12 xl:text-14 leading-5 bg-transparent rounded-[28px] w-full text-graydark"
                            onChange={(e) => setState(e.target.value)}
                            defaultValue=""
                          />
                          <SearchIcon className={classNames("mr-2")} />
                        </div>
                      )}
                    </DebounceSearchInput>
                  </div>
                  <div className="flex my-1">
                    {checkExport ? (
                      <Button
                        label={
                          <span className="flex items-center">
                            <ExportIcon className="mr-[5px]" /> Export
                          </span>
                        }
                        onClick={() => {
                          setIsExportModalOpen(true);
                          setIsExporting(false);
                        }}
                        type="button"
                        variant="no-outline"
                        customClass=" bg-vorpmyanmar border-vorpmyanmar hover:bg-vorpblue hover:text-white transition-all duration-300 text-vorpblue xs:text-[12px] leading-[18px] font-medium py-1 px-[6px] sm:mr-[10px] mr-[5px]"
                        size="base"
                      />
                    ) : null}
                    <button
                      type="button"
                      onClick={() => {
                        setIsTableView(true);
                      }}
                      className="mr-[10px] bg-offwhite rounded-lg flex justify-center items-center 2xl:min-w-[30px] min-w-[28px] 2xl:h-[30px] h-[28px]"
                    >
                      <HorizontalGridIcon
                        width={20}
                        height={20}
                        isActive={isTableView}
                      />
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        setIsTableView(false);
                      }}
                      className="mr-[10px] bg-offwhite rounded-lg flex justify-center items-center 2xl:min-w-[30px] min-w-[28px] 2xl:h-[30px] h-[28px]"
                    >
                      <CalendarIcon
                        width={20}
                        height={20}
                        isActive={!isTableView}
                        className="h-full m-auto"
                      />
                    </button>
                  </div>
                </div>
              </div>
              <div className="flex sm:flex-row flex-col justify-between">
                <div
                  className={classNames(
                    isTableView ? "flex" : "hidden",
                    " sm:flex-row flex-col w-full items-center"
                  )}
                >
                  <div className="flex w-full mt-1 md:mb-1 sm:mr-5 custom-scrollbar">
                    <CustomScrollbar isVertical={false} isHor={true}>
                      <div className="flex w-full items-center">
                        <div
                          className={classNames(
                            styles.permissionDropdown,
                            "flex items-center mr-[10px] w-auto"
                          )}
                        >
                          <p className="hidden sm:w-auto w-1/2 text-left font-poppins font-medium xl:text-sm text-12 text-[#464E5F] mr-1">
                            Type
                          </p>
                          <CustomDropdownListForFilter
                            placeholder="Type"
                            state={type}
                            setState={setType}
                            dropdownItems={
                              typeData
                                ? typeData.map((dp) => ({
                                  value: dp.id,
                                  label: dp.name,
                                }))
                                : []
                            }
                            textAlign="text-left"
                            className="max-h-[38px] max-w-full sm:max-w-[250px] 2xl:w-32 sm:w-28 w-full"
                          />
                        </div>
                        <div
                          className={classNames(
                            "flex items-center mr-[10px] w-auto"
                          )}
                        >
                          <p className="hidden sm:w-auto w-1/2 text-left font-poppins font-medium xl:text-sm text-12 text-[#464E5F] mr-1">
                            Status
                          </p>
                          <CustomDropdownListForFilter
                            placeholder="Status"
                            state={leaveStatus}
                            setState={setLeaveStatus}
                            dropdownItems={
                              statusListData
                                ? statusListData.map((st) => ({
                                  value: st,
                                  label: st,
                                }))
                                : []
                            }
                            textAlign="text-left"
                            className="max-h-[38px] max-w-full sm:max-w-[250px] 2xl:w-32 sm:w-28 w-full"
                          />
                        </div>
                        <div className="flex items-center w-auto">
                          <p className="hidden whitespace-nowrap sm:w-auto 2xs:w-[20%] w-1/2 text-left  uppercase xl:text-sm text-12 leading-5 font-medium text-black2 mr-[10px]">
                            Leave Date
                          </p>
                          <div className="flex flex-row w-full">
                            {startDate && endDate && (
                              <button
                                type="button"
                                onClick={() => {
                                  setStartDate(undefined);
                                  setEndDate(undefined);
                                }}
                                className="text-12 mr-2"
                              >
                                
                                <CloseIcon
                                  color="#EE436D"
                                  width={10}
                                  height={10}
                                />
                              </button>
                            )}
                            <div className={` mr-[10px] htzxs:w-auto w-full`}>
                              <DatePopupFoFilter
                                placeholder="Start"
                                date={startDate}
                                setDate={setStartDate}
                              />
                            </div>
                            <div className={`htzxs:w-auto w-full`}>
                              <DatePopupFoFilter
                                placeholder="End"
                                date={endDate}
                                setDate={(value) => setEndDate(value)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </CustomScrollbar>
                  </div>
                </div>
                <div
                  className={classNames(
                    isTableView === true ? "flex" : "hidden",
                    " justify-end sm:w-auto w-full sm:flex-nowrap flex-wrap items-center sm:mt-0 mt-2 mb-1"
                  )}
                >
                  <div className="flex items-center md:ml-3 mr-[10px]">
                    <p className="base-label hidden 3xs:block htzxs:hidden sm:block xl:text-sm text-12">
                      DISPLAY
                    </p>
                    <NumberDropdownList
                      state={pagination.pageSize}
                      setState={(value) =>
                        setPagination((prev) => ({
                          ...prev,
                          pageSize: value as number,
                        }))
                      }
                      dropdownItems={new Array(5)
                        .fill(1)
                        .map((_, index: number) => {
                          let display = (index + 1) * 20;
                          return {
                            value: display,
                            label: String(display),
                          };
                        })}
                      className="max-w-[55px] 4xs:max-w-[60px] lg:max-w-[72px]"
                      textAlign="text-left pl-3"
                      paddingClass="py-[2px]"
                      placeholder="20"
                    />
                  </div>
                  <div className="flex justify-end">
                    <StringDropdownListWithDeleteBtn
                      haveDeleteBtn={true}
                      setIsTemplate={setIsTemplate}
                      state={isTemplate ? currentViewData : currentView}
                      setState={setCurrentView}
                      deleteTemplate={deleteTemplateData}
                      dropdownItems={moduleList.map((x: any, index: number) => {
                        return {
                          label: x.id === -1 ? x.name : "View #" + x.index,
                          value: x.id,
                        };
                      })}
                      setErrorPopup={setErrorPopup}
                      setSuccessPopup={setSuccessPopup}
                      setMessage={setMessage}
                      className="w-full min-w-[100px] 2xl:min-w-[128px] 6xs:text-12"
                      placeholder="View #1"
                      custPadding="py-[2px]"
                      textAlign="text-left py-2 pl-2 pr-3"
                    />
                  </div>
                  <button
                    type="button"
                    onClick={() => setIsLayoutControlOpen(true)}
                    className="flex justify-center relative items-center rounded-lg bg-vorphongkongborder hover:bg-[#FFF0D2] w-[29px] h-[29px] ml-2 group transition-all duration-300 "
                  >
                    <CustomizeLayoutIcon />
                    <div className="absolute left-7 top-[-5px] border border-white w-4 h-4 rounded-full bg-vorphongkongborder flex justify-center items-center">
                      <AddIcon width={8} height={8} color="white" />
                    </div>
                  </button>
                </div>
              </div>
            </div>
            <animated.div style={props}>
              <div
                className={classNames(isTableView ? "" : "hidden", "text-left")}
              >
                <LeaveApplicationTable
                  data={data}
                  setData={setData}
                  pagination={pagination}
                  setPagination={setPagination}
                  isLayoutControlOpen={isLayoutControlOpen}
                  setIsLayoutControlOpen={setIsLayoutControlOpen}
                  globalSearch={globalSearch}
                  setGlobalSearch={setGlobalSearch}
                  // columnFilters={columnFilters}
                  // setColumnFilters={setColumnFilters}
                  statusData={statusListData}
                  setStatusData={setStatusListData}
                  leaveType={leaveTypeData}
                  setLeaveType={setLeaveTypeData}
                  leaveFullHalfData={leaveFullHalfData}
                  setLeaveFullHalfData={setLeaveFullHalfData}
                  hiddenFields={hiddenFields ? hiddenFields : []}
                  setHiddenFields={setHiddenFields}
                  templateList={tempList}
                  changeApproveLeaveStatus={changeApproveLeaveStatus}
                  changeRejectedLeaveStatus={changeRejectedLeaveStatus}
                  localModuleId={localModuleId}
                  editLeave={editLeave}
                  setCurrentView={setCurrentView}
                  setCols={setCols}
                  // changePage={changePage}
                  total={itemCount}
                  setCurrentPage={setCurrentPage}
                  currentPage={currentPage}
                  setIsTemplate={setIsTemplate}
                  checkManage={checkManage}
                  checkManageLeave={checkManageLeave}
                  setIsEdit={setIsEdit}
                  isEditing={isEdit}
                  setShowLeaveFile={setShowLeaveFile}
                  setLeaveDocuments={setLeaveDocuments}
                  sorting={sorting}
                  setSorting={setSorting}
                />
              </div>
              <div
                className={classNames(isTableView ? "hidden" : "mx-[-16px]")}
              >
                <DashboardCalendarView
                  data={eventsData}
                  setLeaveTypesData={() => 1}
                  handlePopup={() => 1}
                  fullscreen={false}
                  checkFullScreen={() => 1}
                  eventsList={[]}
                  eventType={eventsData?.eventType}
                  employeeData={[]}
                  projectList={[]}
                  indicators={indicators?.data}
                  noFilter={true}
                />
              </div>
            </animated.div>
          </div>
        </>
      )}
    </>
  );
};
export default LeaveApplication;
