import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { ColumnFiltersState, PaginationState, SortingState } from "@tanstack/react-table";
import { ILeaveStatus } from "../../utils/constants";
import { useLocation } from "react-router-dom";
import { Tdocuments } from "../../leave-application/views/LeaveApplication";
import { IFilterDate } from "../../documents/views/EmployeeDocumentList";
import { getAuthUser } from "../../app/services/dashboard";
import { renderDateFormatYMD } from "../../components/DashboardCalendarView/DashboardAddEventPopup";
import PersonalLeaveBalanceTable from "../../components/DashboardProfile/MyLeave/PersonalLeaveBalanceTable";
import Button from "../../components/common/Button";
import classNames from "classnames";
import { CloseIcon, DocumentIcon, EditIcon } from "../../components/common/Icons";
import PersonalLeaveTable from "../../components/DashboardProfile/MyLeave/PersonalLeaveTable";
import CustomDropdownListForFilter from "../../components/EmployeeTable/CustomDropdownListForFilter";
import DatePopupFoFilter from "../../components/EmployeeTable/DatePopupFoFilter";
import CustomScrollbar from "../../components/CustomScrollbar/CustomScrollbar";
import { Fade, Modal } from "@mui/material";
import LeaveApplication from "../../components/DashboardProfile/MyLeave/LeaveApplication";
import LeaveAllowancePopup from "../../components/LeaveAllowancePopup/LeaveAllowancePopup";
import { NumberDropdownList } from "../../components/common/DropdownList";
import { ToastContainer, toast } from "react-toastify";
import CommonTableSkeleton from "../../components/TableSkeleton/CommonTableSkeleton";
import LeaveApplicationFilesPopup from "../../components/LeaveApplicationFIlesPopup/LeaveApplicationFilesPopup";
import { ILeaveAllowanceType, ILeaveBalanceData, ILeaveType, useGetLeaveInformationQuery, useGetLeaveTakenQuery, useSaveLeaveAllowanceMutation } from "../../app/services/leave-application";
import { useSpring, animated } from "react-spring";
import { useCreateCancelLeaveMutation, useCreateLeaveDataMutation, useGetMyAuthLeaveAllMutation } from "../../app/services/profile";
import Styles from './EmployeeLeave.module.scss'
const EmployeeLeave: FC = () => {
  const [status, setStatus] = useState<ILeaveStatus>("");
  const [dateClean, setDateClean] = useState<boolean>(false);
  const [filterDate, setFilterDate] = useState<IFilterDate>();
  const [start, setStart] = useState<any>("");
  const [end, setEnd] = useState<any>("");
  const location = useLocation();
  //var id = location.state;
  const [state, setState] = useState(localStorage.getItem("state"));
  //var id = state ? Number(state) : 0;
  var currentUrl = window.location.pathname.split('/employee/')[1]?.split('/')[0];
  var id = currentUrl ? parseInt(currentUrl) : 0;

  const [isLeaveApplicationOpen, setIsLeaveApplicationOpen] =
    useState<boolean>(false);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [myLeavesListData, setMyLeavesListData] = useState<any>([]);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 1,
    pageSize: 10,
  });
  const [showLeaveFile, setShowLeaveFile] = useState<boolean>(false);
  const [leaveDocuments, setLeaveDocuments] = useState<Tdocuments[]>([]);
  const [leaveBalanceData, setLeaveBalanceData] = useState<ILeaveBalanceData>();
  const [leaveAllowanceList, setLeaveAllowanceList] = useState<any>([]);
  const [viewLeave, setViewLeave] = useState<boolean>(false);
  const [createLeave, setCreateLeave] = useState<boolean>(false);
  const [manageAllLeaveApplication, setManageAllLeaveApplication] =
    useState<boolean>(false);
  const [manageLeaveApproval, setManageLeaveApproval] =
    useState<boolean>(false);
  const [cancelMyLeave, setCancelMyLeave] = useState<boolean>(false);
  const [showLeaveAllowance, setShowLeaveAllowance] = useState<boolean>(false);
  // const [hiddenFields, setLeftOverLeaveData] = useState<string[]>([]);
  const [data, setData] = useState<any>([]);
  const [itemCount, setItemCount] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [editedData, setEditedData] = useState<any>();
  const [leaveTypes, setLeaveTypes] = useState<ILeaveType[]>([]);
  const [isConfirmCancelOpen, setIsConfirmCancelOpen] =
    useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  var getUser = getAuthUser();
  const getAuthPermis: string[] = useMemo(() => [], []);

  const [saveLeaveAllowance, { isLoading: leaveAllowanceLoading }] = useSaveLeaveAllowanceMutation();
  const { data: leaveTaken } = useGetLeaveTakenQuery({
    status: status,
    start: start ? renderDateFormatYMD(new Date(start), '-') : '',
    end: end ? renderDateFormatYMD(new Date(end), '-') : '',
    id: id ? id : 0,
    page: currentPage,
    limit: pagination.pageSize,
    sort: sorting.map((s) => `${s.desc ? '-' : ''}${s.id == "appliedAt" ? "applied_at" : s.id == "from" ? "from_date" : s.id == "to" ? "to_date" : s.id == "ID" ? "code" : s.id}`).join(','),
  })
  const [LeaveBalanceAll] = useGetMyAuthLeaveAllMutation();
  const [createLeaveData] = useCreateLeaveDataMutation();
  const [createCancelLeave] = useCreateCancelLeaveMutation();
  if (getUser?.permissions) {
    const getPer = getUser?.permissions?.forEach((per: any) => {
      getAuthPermis.push(per?.name);
    });
  }
  const {
    data: myLeavesList,
    isFetching,
    isLoading,
  } = useGetLeaveInformationQuery(id);
  useEffect(() => {
    localStorage.removeItem("state");
  }, []);

  useEffect(() => {
    if (location.state) {
      setState(location.state);
    }
  }, [location]);

  useEffect(() => {
    const viewLeave = getAuthPermis.filter(
      (f) => f == "View_Leave_Application"
    );
    const createLeave = getAuthPermis.filter((f) => f == "Create_My_Leave");
    const manageAllLeaveApplication = getAuthPermis.filter(
      (f) => f == "Manage_All_Leave_Application"
    );
    const manageLeaveApproval = getAuthPermis.filter(
      (f) => f == "Manage_Leave_Approval"
    );
    const cancelMyLeave = getAuthPermis.filter((f) => f == "Cancel_My_Leave");

    if (cancelMyLeave.length > 0) {
      setCancelMyLeave(true);
    }

    if (manageLeaveApproval.length > 0) {
      setManageLeaveApproval(true);
    }
    if (manageAllLeaveApplication.length > 0) {
      setManageAllLeaveApplication(true);
    }
    if (createLeave.length > 0) {
      setCreateLeave(true);
    }
    if (viewLeave.length > 0) {
      setViewLeave(true);
    }
  }, [getUser]);

  useEffect(() => {
    if (dateClean) {
      setStart("");
    } else {
      setStart(
        filterDate?.startDate
          ? renderDateFormatYMD(new Date(filterDate?.startDate), "-")
          : ""
      );
    }
  }, [filterDate?.startDate, dateClean]);

  useEffect(() => {
    if (dateClean) {
      setEnd("");
    } else {
      setEnd(
        filterDate?.endDate
          ? renderDateFormatYMD(new Date(filterDate?.endDate), "-")
          : ""
      );
    }
  }, [filterDate?.endDate, dateClean]);

  useEffect(() => {
    var data = myLeavesList?.statusData?.map((x: any, i: number) => {
      return { id: i + 1, name: x, label: x };
    });
    if (data) {
      setMyLeavesListData([{ name: "All", id: 0, label: "All" }, ...data]);
    }
    if (myLeavesList) {
      console.log("myLeavesList ",myLeavesList)
      if (myLeavesList?.leaveData?.data) {
        //setData(myLeavesList?.leaveData?.data);
        // setItemCount(myLeavesList?.leaveData?.total);
        setLeaveAllowanceList(myLeavesList?.leaveAllowances);
        setLeaveBalanceData({
          leavesInfo: myLeavesList?.leavesInfo,
          previousBalance: myLeavesList?.previousBalance
        });
        setLeaveTypes(myLeavesList?.leaveTypes)
      } else {
        setData([]);
      }

    } else {
      setData([]);
    }
  }, [myLeavesList, isFetching]);
  useEffect(() => {
    if (leaveTaken) {
      setData(leaveTaken?.leaveData?.data);
      setItemCount(leaveTaken?.leaveData?.total);
    }
  }, [leaveTaken])
  var available = 0;
  if (myLeavesList?.previousBalance?.available) {
    available = myLeavesList?.previousBalance?.available;
  } else {
    available = 0;
  }

  const props = useSpring({
    to: { opacity: 1 },
    from: { opacity: 0 },
    delay: 500,
  });
  const saveCancel = (rowId: number) =>
    createCancelLeave(rowId).then((res: any) => {
      if (res.data.status) {
        setIsConfirmCancelOpen(false);
        setIsSuccess(true);
        toast(res?.data?.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          type: "success",
        });
      } else if (res?.error) {
        setIsConfirmCancelOpen(true);
        setIsSuccess(false);
        toast(res?.data?.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          type: "error",
        });
      }
    });
  const saveLeaveAllowanceData: any = (
    leaveAllowance: ILeaveAllowanceType[]
  ) => {
    const data = {
      id: id,
      data: { data: leaveAllowance },
    };

    saveLeaveAllowance(data)
      .then((res: any) => {
        if (res.data.status) {
          LeaveBalanceAll({ id: id ? id : 0 });
          setShowLeaveAllowance(false);
          toast("Successfully Updated.", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            type: "success",
          });
        } else {
          setShowLeaveAllowance(true);
        }
      })
      .catch((error: any) => { });
  };

  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);
  useEffect(() => {
    setCurrentPage(1)
  }, [status, start, end, pagination.pageSize])
  return (
    <>
      {isLoading ? (
        <CommonTableSkeleton rows={12} isProfile={true} />
      ) : (
        <>
          {showLeaveFile && leaveDocuments.length && (
            <LeaveApplicationFilesPopup
              isOpen={showLeaveFile}
              setIsOpen={setShowLeaveFile}
              leaveDocuments={leaveDocuments}
            />
          )}

          <ToastContainer className={"text-12 font-poppins font-normal"} />
          <div className="w-full bg-white rounded-xl text-left">
            {windowSize[0] < 450 ? (
              <div className="px-2 sm:px-4 md:px-5 py-1 md:py-2 border-b border-offwhite flex items-center justify-between">
                <div className="flex mt-1 sm:w-auto w-full sm:flex-nowrap flex-wrap items-center">
                  <div className=" mb-1 flex items-center xl:ml-3 mr-[10px]">
                    <p className="base-label text-12 xl:text-14">DISPLAY</p>
                    <NumberDropdownList
                      state={pagination.pageSize}
                      setState={(value) =>
                        setPagination((prev) => ({
                          ...prev,
                          pageSize: value as number,
                        }))
                      }
                      dropdownItems={new Array(5)
                        .fill(1)
                        .map((_, index: number) => {
                          let display = (index + 1) * 10;
                          return {
                            value: display,
                            label: String(display),
                          };
                        })}
                      className="max-w-[55px] 4xs:max-w-[60px] lg:max-w-[72px]"
                      textAlign="text-left pl-3"
                      paddingClass="py-[2px]"
                      placeholder="20"
                    />
                  </div>
                </div>
                <Button
                  label={
                    <div className="flex items-center text-13 sm:text-10 leading-[21px] font-medium text-white">
                      <DocumentIcon className="mr-2" />
                      Leave
                    </div>
                  }
                  onClick={() => setIsLeaveApplicationOpen(true)}
                  type="button"
                  variant="primary"
                  customClass={
                    id
                      ? id != 0
                        ? "hidden"
                        : ""
                      : "" + " hover:bg-vorpblue hover:text-white px-1 py-0"
                  }
                  size="base"
                />
              </div>
            ) : (
              <div className="px-2 sm:px-4 md:px-5 py-1 md:py-2 border-b border-offwhite flex items-center justify-between">
                <h3 className="text-[#3F4254] font-semibold text-[13px] sm:text-[15px] 2xl:text-[18px] leading-[22px] lg:leading-[27px]">
                  My Leave
                </h3>
                {Number(id) == 0 ? (
                  <Button
                    label={
                      <div className="flex items-center text-13 sm:text-10 leading-[21px] font-medium text-white">
                        <DocumentIcon className="mr-2" />
                        Leave Application
                      </div>
                    }
                    onClick={() => { setIsLeaveApplicationOpen(true); setEditedData({}) }}
                    type="button"
                    variant="primary"
                    customClass={
                      id
                        ? id != 0
                          ? "hidden"
                          : ""
                        : "" + " hover:bg-vorpblue hover:text-white px-2 py-1"
                    }
                    size="base"
                  />
                ) : null}
              </div>
            )}

            <LeaveAllowancePopup
              saveLeaveAllowanceData={saveLeaveAllowanceData}
              isOpen={showLeaveAllowance}
              setIsOpen={setShowLeaveAllowance}
              leaveAllowanceList={leaveAllowanceList}
              leaveAllowanceLoading={leaveAllowanceLoading}
            />

            <Modal
              open={isLeaveApplicationOpen}
              style={{
                background: "rgb(0 0 0 / 0.5)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              onClose={(event, reason) => {
                if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
                  setIsLeaveApplicationOpen(false);
                }
              }}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              disableEnforceFocus={true}
            >

              <Fade in={isLeaveApplicationOpen} timeout={500}>
                <div className="max-w-[1000px] w-full m-auto">
                  <LeaveApplication
                    setIsLeaveApplicationOpen={setIsLeaveApplicationOpen}
                    leaveTypeList={leaveTypes}
                    createLeaveData={createLeaveData}
                    LeaveBalanceList={leaveBalanceData}
                    setData={setData}
                    fromDateOld={editedData?.fromDate}
                    toDateOld={editedData?.toDate}
                    reasonOld={editedData?.reason}
                    leaveDurationOld={editedData?.leaveDuration}
                    leaveTypeOld={editedData?.leaveType}
                    id={editedData?.id}
                    documents={editedData?.documents}
                    setLeaveBalanceData={setLeaveBalanceData}
                  />
                </div>
              </Fade>
            </Modal>

            <div className="px-2 sm:px-4 md:px-5 pt-3 pb-[10px] border-b border-offwhite">
              <div className="mb-3 lg:mb-4 xl:mb-4">
                <div className="flex justify-between lg:flex-col-reverse xl:flex-row xl:items-center">
                  <div className="flex w-full mt-1 md:mb-1 sm:mr-5 custom-scrollbar ">
                    <CustomScrollbar isVertical={false} isHor={true}>
                      <div className="flex w-full items-center ">
                        <div className="flex">
                          <p className="hidden mb-3 2xs:mb-0 text-13 leading-5 font-medium text-black2 mr-3">
                            DATE
                          </p>
                          {filterDate?.startDate && filterDate?.endDate && (
                            <button
                              type="button"
                              onClick={() => {
                                setStart('');
                                setEnd('');
                                setDateClean(true);
                                setFilterDate({
                                  startDate: undefined,
                                  endDate: undefined,
                                });
                              }}
                              className="text-12 mr-2"
                            >
                              <CloseIcon
                                color="#EE436D"
                                width={10}
                                height={10}
                              />
                            </button>
                          )}

                          <div className="flex">
                            <div className={`mr-3 ${Styles.leaveDatePicker}`}>
                              <DatePopupFoFilter
                                placeholder="Start"
                                date={filterDate?.startDate}
                                setDate={(value) => {
                                  setStart(value)
                                  setFilterDate((prev) => ({
                                    ...prev,
                                    startDate: value as Date,
                                  }));
                                  setDateClean(false);
                                }}
                              />
                            </div>
                            <div className={`mr-3 ${Styles.leaveDatePicker}`}>
                              <DatePopupFoFilter
                                placeholder="End"
                                date={filterDate?.endDate}
                                setDate={(value) => {
                                  setEnd(value)
                                  setFilterDate((prev) => ({
                                    ...prev,
                                    endDate: value as Date,
                                  }));
                                  setDateClean(false);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="flex mr-3">
                          <p className="hidden mb-3 2xs:mb-0 text-13 leading-5 font-medium text-black2 mr-3">
                            STATUS
                          </p>
                          <CustomDropdownListForFilter
                            isFilter={true}
                            placeholder="Status"
                            state={status}
                            setState={setStatus}
                            dropdownItems={myLeavesListData?.map(
                              (status: any) => ({
                                value: status.name,
                                label: status.label,
                              })
                            )}
                            className="max-h-[38px] max-w-[135px]"
                          />
                        </div>
                      </div>
                    </CustomScrollbar>
                    {windowSize[0] > 450 ? (
                      <div className="flex justify-end mt-1 sm:w-auto w-full sm:flex-nowrap flex-wrap items-center">
                        <div className=" mb-1 flex items-center xl:ml-3 mr-[10px]">
                          <p className="base-label text-12 xl:text-14">
                            DISPLAY
                          </p>
                          <NumberDropdownList
                            state={pagination.pageSize}
                            setState={(value) =>
                              setPagination((prev) => ({
                                ...prev,
                                pageSize: value as number,
                              }))
                            }
                            dropdownItems={new Array(5)
                              .fill(1)
                              .map((_, index: number) => {
                                let display = (index + 1) * 10;
                                return {
                                  value: display,
                                  label: String(display),
                                };
                              })}
                            className="max-w-[55px] 4xs:max-w-[60px] lg:max-w-[72px]"
                            textAlign="text-left pl-3"
                            paddingClass="py-[2px]"
                            placeholder="20"
                          />
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
              <PersonalLeaveTable
                columnFilters={columnFilters}
                setColumnFilters={setColumnFilters}
                data={data ? data : []}
                manageAllLeaveApplication={manageAllLeaveApplication}
                hiddenFields={[]}
                id={Number(id)}
                pagination={pagination}
                setPagination={setPagination}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                total={itemCount}
                setShowLeaveFile={setShowLeaveFile}
                setLeaveDocuments={setLeaveDocuments}
                sorting={sorting}
                setSorting={setSorting}
                setIsLeaveApplicationOpen={setIsLeaveApplicationOpen}
                setEditedData={setEditedData}
                saveCancel={saveCancel}
                setIsSuccess={setIsSuccess}
                setIsConfirmCancelOpen={setIsConfirmCancelOpen}
                isConfirmCancelOpen={isConfirmCancelOpen}
              />
            </div>

            <div className="pt-5 pb-9 px-2 5xs:px-4 sm:px-5 mb-7 border-b border-offwhite">
              <animated.div style={props}>
                <div className="flex justify-between items-center">
                  <h3 className="text-14 xl:text-18 leading-[22px] pb-1 border-b border-b-offwhite font-semibold text-black2 mb-1">
                    Leave Balance
                  </h3>
                  {Number(id) !== 0 ? (
                    <div>
                      <Button
                        label={
                          <span
                            className={classNames(
                              Styles.customEdit,
                              "flex items-center"
                            )}
                          >
                            <EditIcon className="mr-[5px]" color="#000000" />{" "}
                            Edit
                          </span>
                        }
                        onClick={() => {
                          setShowLeaveAllowance(true);
                        }}
                        type="button"
                        variant="no-outline"
                        customClass="my-1 bg-offwhite border-transparent hover:border-vorpblue hover:text-vorpblue transition-all duration-300 text-black2 xs:text-[12px] leading-[18px] font-medium py-2 px-[8px] sm:mr-[10px] mr-[5px]"
                        size="base"
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <PersonalLeaveBalanceTable
                  leaveBalance={leaveBalanceData?.leavesInfo}
                />
              </animated.div>

              <div className="mt-4">
                <div className="flex items-end font-poppins md:text-14 text-12 text-black2 font-normal mt-2">
                  <p className="mr-1">Leftover Expiry Date:</p>
                  <p>
                    {leaveBalanceData?.previousBalance?.expiredDate
                      ? leaveBalanceData?.previousBalance?.expiredDate
                      : "---"}
                  </p>
                </div>
              </div>
            </div>
          </div>

        </>
      )}
    </>
  );
};

export default EmployeeLeave;
