import React, { Component, Dispatch, FC, SetStateAction, useState, useRef, useEffect, useCallback } from 'react';
// import { Modal, Fade } from "@material-ui/core";
import { Modal, Fade } from "@mui/material"
// import { Editor } from '@tinymce/tinymce-react';
// import CustomDatePicker from "../../components/common/FullWidthDatePicker";
import {
    StringDropdownList,
} from "../../components/common/DropdownList";
import {
    tagTypeArr,
    // tagTypes
} from "../../utils/announcementDatas";
// import { StringMultipleSelectBox } from "../common/MultipleSelectBox";
// import {
//     getPathArraysData,
//     useGetPathArraysDataQuery
// } from "../../app/services/dashboard";
import whiteCancel from "../common/img/white-cancel.svg";
// import blackCancel from "../common/img/black-cancel.svg";
// import saveIcon from "./img/submit-icon.svg";
import styles from "./UploadModal.module.scss";
// import { MediaItems } from '../../app/services/media';
// import { options } from '@fullcalendar/core/preact';
// import axios from '../../axios';
// import { endpointUrl } from '../../app/services/api';
import { CommonTagDropdown } from '../common/CommonTagDropdown';

var classNames = require("classnames");
interface IUploadModal {
    isOpen: boolean;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
    tagsData?: any;
    folderList: MediaList[];
    uploadDocument: any;
    createTagData?: any;
    setImporting?: Dispatch<SetStateAction<boolean>>;
    importing?: boolean;
    isDataClean?: boolean;
}

interface IFilterStates {
    tag: string;
}
const tags = tagTypeArr
    .map((tag, idx) => ({
        label: tag.name,
        value: tag.name,
    }))
    .filter((tag) => tag.label.toLowerCase() !== "all");

const UploadModal: FC<IUploadModal> = ({ isOpen, setIsOpen, tagsData, folderList, uploadDocument, createTagData, setImporting, importing, isDataClean }) => {
    const [file, setFile] = useState("");
    const uploadRef: any = useRef(null);
    const statusRef: any = useRef(null);
    const loadTotalRef: any = useRef(null);
    const progressRef: any = useRef(null);
//     const conditionRef: any = useRef(null);
    const [isLoad, setIsLoad] = useState(false);
    const [selectedFileName, setSelectedFileName] = useState("No file chosen");
    const [chosenTags, setChosenTags] = useState<any>([]);
    const [filterStates, setFilterStates] = useState<IFilterStates>({
        tag: "All",
    });

    // const updateFilterState = useCallback(
    //     (value: string, key: string) => {
    //         setFilterStates((prev) => ({ ...prev, [key]: value }));
    //     }, [setFilterStates]
    // );
    const [errorMsg, setErrorMsg] = useState<string>('');

    const [pathArray, setPathArray] = useState<string>("");
    const UploadFile = () => {
        const file = uploadRef?.current.files[0];
        setSelectedFileName(file.name);
        
        setFile(URL.createObjectURL(file));
        validateSelectedFile(uploadRef?.current.files[0])
    };

    const validateSelectedFile = (files: File) => {
        const MAX_FILE_SIZE = 100 * 1024 * 1024 // 100MB
        if (!files) {
            setErrorMsg("Please choose a file");
            return
        }
        const fileSizeKiloBytes = files.size;

        if (fileSizeKiloBytes > MAX_FILE_SIZE) {
            setErrorMsg("File size is greater than maximum limit (100MB)");
            return
        }
        setErrorMsg("")
    };

    const ProgressHandler = (e: any) => {
        // loadTotalRef.current.innerHTML = `uploaded ${e.loaded} bytes of ${e.total}`;
        var percent = (e.loaded / e.total) * 100;
        progressRef.current.value = Math.round(percent);
        statusRef.current.innerHTML = '<p className="font-primary font-medium text-10 sm:text-13 text-black2">UPLOADING: ' + Math.round(percent) + "% ...</p>";
        if (progressRef.current.value > 0) {
            setIsLoad(true);
        } else {
            setIsLoad(false);
        }
    };

    useEffect(() => {
        setChosenTags([]);
        setPathArray("");
        setSelectedFileName("No file chosen");
    }, [isOpen])

    const SuccessHandler = (e: any) => {
        // statusRef.current.innerHTML = e.target.responseText;
        progressRef.current.value = 100;
        statusRef.current.innerHTML = "uploaded 100%";
        // setTimeout(()=> {
        //     setIsLoad(false);
        // },1000)

    };

    // const ErrorHandler = () => {
    //     statusRef.current.innerHTML = "upload failed!!";
    // };
    
    // const AbortHandler = () => {
    //     statusRef.current.innerHTML = "upload aborted!!";
    // };

    const testingHandler = async (event: any) => {
        if (setImporting) {
            setImporting(true);
        }
        var tags: any = [];
        tagsData?.map((opt: any) => {
            if (chosenTags.includes(opt.name)) {
                tags.push(opt.id)
            }
        })
        var data = {
            tags: tags,
            folder_id: pathArray,
            file: file
        }
        let formSaveData = new FormData();
        formSaveData.append("file", uploadRef?.current.files[0]);
        formSaveData.append("tags", tags);
        formSaveData.append("folder_id", pathArray);
        uploadDocument(formSaveData);
        
        // closeUploadHandler();
    }
    const handleClick = () => {
        uploadRef.current.click();
    }
    const closeUploadHandler = () => {
        setIsOpen(false);
        setChosenTags([]);
        setPathArray("");
        setSelectedFileName("No file chosen");
        setIsLoad(false);
    }
    return (
        <Modal
            open={isOpen}
            style={{
                background: "rgb(0 0 0 / 0.3)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
            onClose={(event, reason) => {
                if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
                    setIsOpen(false);
                }
            }
            }
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            disableEnforceFocus={true}
        >
            <Fade in={isOpen} timeout={500}>
                <div className={classNames((importing && isOpen) ? ' pointer-events-none' : '' , "modalContent max-w-[530px] w-full m-auto px-4 py-8 xl:px-0 xl:py-0", styles.uploadModalContainer)}>
                    <div id="modal-modal-title" className="bg-vorpblue relative flex justify-between p-4 xs:px-6 sm:px-8 md:px-10 xs:py-5 rounded-t-xl">
                        <h3 className="text-14 sm:text-16 md:text-18 font-primary font-semibold text-white">Upload</h3>
                        <button className="flex items-center justify-center" onClick={closeUploadHandler}><img src={whiteCancel} alt="close modal icon" /></button>
                    </div>
                    <div id="modal-modal-description" className="bg-white p-4 xs:p-6 sm:p-8 md:p-10 rounded-b-xl">
                        <div className="flex">
                            <div className="w-full">
                                <div className="mb-5 last:mb-0">
                                    <label className="text-black2 text-12 sm:text-15 font-medium font-primary mb-[0.688rem] block">Tag</label>
                                    <CommonTagDropdown showAll={true} createData={createTagData}
                                        isObject={true}
                                        state={chosenTags}
                                        setState={setChosenTags}
                                        options={tagsData?.map((tag: any) => {
                                            return {
                                                label: tag.name,
                                                value: tag.id
                                            }
                                        })}
                                        className="border-goalinputborder 6xs:text-goalinputborder text-12 sm:text-14 py-3"
                                    />
                                </div>
                                <div className="mb-5 last:mb-0">
                                    <label className="text-black2 text-12 sm:text-15 font-medium font-primary mb-[0.688rem] block leading-tight">Choose the location of the file:</label>
                                    <StringDropdownList
                                        state={pathArray}
                                        setState={setPathArray}
                                        placeholder="Please Select"
                                        className="w-full py-3 pl-3 mb-3 border-goalinputborder text-goalinputborder 6xs:text-12 md:text-14 leading-6 min-h-[42px] md:min-h-[48px]"
                                        dropdownItems={folderList?.map((f: any) => {
                                            return {
                                                value: f.id,
                                                label: f.name
                                            }
                                        })}
                                        textAlign={
                                            "text-left text-12 sm:text-14 text-goalinputborder px-[10px] py-[6px] 6xs:text-black2"
                                        }
                                    />
                                </div>
                                <div className="mb-5 last:mb-0">
                                    <p className="text-black2 text-12 sm:text-15 font-medium font-primary mb-[0.688rem] block leading-tight">Choose a file form your computer:</p>
                                    <div className="flex items-center">
                                        <input type="file" id="upload" name="file" onChange={UploadFile} ref={uploadRef} className="hidden"
                                            // accept='application/pdf, .csv, .doc,.docx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, image/*, .zip,.rar,.7z,.gz,'
                                            accept='.pdf,.doc,.docx,.ppt,.pptx,.txt,.csv,.xls,.xlsx,.xml,.json,.md,.html,.csr,.cer,.exe,.apk,.app,.ini,.ppl,.zip,.rar,.tar,.tar,.gz,.gzip,.jpg,.png,.jpeg,.svg,.webp,.gif,.tif,.tiff,.psd,.fig,.ai,.xd,.ttf,.otf,.fnt,.woff,.woff2,.Iso,.dmg'
                                        />
                                        {/* //accept="image/*" */}
                                        <button onClick={handleClick} className={classNames(styles.uploadBtn)}>
                                            Choose file
                                        </button>
                                        <p className="font-primary text-10 sm:text-12 text-black2 ml-3">{selectedFileName}</p>
                                    </div>
                                    {/* <p className="text-maximumColor text-10 sm:text-12 font-primary mt-2.5">Maximum size: 2 MB</p> */}
                                    <div className={classNames(isLoad ? "" : "hidden")}>
                                        <div className={classNames(styles.cusprogress)}>
                                            <progress ref={progressRef} value="0" max="100" />
                                        </div>
                                        <div ref={statusRef}></div>
                                        <p ref={loadTotalRef}></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr className="border border-vorpgraylight border-b-0 border-l-0 border-r-0 px-10 my-6" />
                        <p className=' text-red-600'>{!errorMsg ? '' : errorMsg}</p>
                        {/* <div className="mb-[1.813rem]">
                        <p className="text-black2 font-primary font-medium text-12 sm:text-15 mb-2.5 leading-tight">Update existing data</p>
                        <div className='flex items-center'>
                            <input
                                type="checkbox"
                                id="update-existing"
                                className="mr-3 checked:bg-vorpblue w-3 h-3 sm:w-4 sm:h-4 p-3 border-none outline-none appearance-none bg-offwhite rounded hover:cursor-pointer"
                            />
                            <p className="text-left text-black2 font-primary text-10 sm:text-14 leading-tight">Notify the assigned users regarding the version update.</p>
                        </div>
                    </div> */}
                        <div className="mt-5 last:mb-0 flex items-center">
                            <button className={classNames((importing && isOpen) || errorMsg ? 'opacity-50 pointer-events-none' : '', "flex items-center justify-center bg-vorpblue mr-2.5 text-white font-primary text-10 sm:text-14 font-medium rounded-md py-[8.5px] px-3")} onClick={testingHandler}>{importing && isOpen ? 'Uploading...' : 'Upload'}</button>
                            <button className="flex items-center justify-center bg-offwhite font-primary text-10 sm:text-14 font-medium text-black2 rounded-md py-[8.5px] px-3" onClick={() => { setIsOpen(false) }}>Cancel</button>
                        </div>
                    </div>
                </div>
            </Fade>
        </Modal>
    )
}

export default UploadModal;