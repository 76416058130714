import { FC, useEffect, useState } from "react";
import DebounceSearchInput from "../Tables/DebounceSearchInput";
import { SearchIcon } from "../common/Icons";
import classNames from "classnames";
import CustomDropdownListForFilter from "../EmployeeTable/CustomDropdownListForFilter";
import { useGetMemberListQuery } from "../../app/services/profile-daily-report";
import styles from './MemberStatus.module.scss'
import Member from "./Member";
import Pusher from 'pusher-js';
import { IDepartment } from "../../app/services/profile";
export const memberStatusList = [
    {
        id: 0,
        name: 'All',
        value: "all"
    },
    {
        id: 1,
        name: 'On work',
        value: "in"
    },
    {
        id: 2,
        name: 'Break',
        value: "out"
    },
    {
        id: 3,
        name: 'Off work',
        value: "off"
    },
    {
        id: 4,
        name: 'Absent',
        value: "Absent"
    },
    {
        id: 5,
        name: 'Leave',
        value: "Leave"
    }
]
export const pusherKey = process.env.REACT_APP_PUSHER_KEY || '3638dceb54851abed00b';
export const pusherCluster = process.env.REACT_APP_PUSHER_CLUSTER || 'ap1';
interface IMemberStatus {
    departmentList: IDepartment[]
}
const MemberStatus: FC<IMemberStatus> = ({
    departmentList
}) => {
    const [globalSearch, setGlobalSearch] = useState<string>("");
    const [departmentID, setDepartmentID] = useState<number>(0);
    // const [departmentList, setDepartmentList] = useState<any>([]);
    const [statusID, setStatusID] = useState<string>('all');
    const [memberData, setMemberData] = useState<any>([]);
    const [statusList, setStatusList] = useState<any>(memberStatusList);
    const { data: memberList } = useGetMemberListQuery({
        search: globalSearch,
        department_id: departmentID,
        status: statusID
    });
    useEffect(() => {
        if (memberList) {
            setMemberData(memberList.data);
        }
    }, [memberList])
    // Pusher.logToConsole = true;

    // var pusher = new Pusher(pusherKey, {
    //     cluster: pusherCluster
    // });

    // var channel = pusher.subscribe('employees');
    // channel.bind('pusher:subscription_succeeded', function () {
    //     console.log('successfully subscribed!');
    // });
    // channel.bind('working-status', function (data: any) {
    //     setMemberData((prev: any) => prev.map((member: any) => {
    //         if (member.id == data.id) {
    //             return { ...member, working_status: data.working_status, time: data.time }
    //         }
    //         return member
    //     })
    //     )
    // });
    // console.log("memberData ", memberData)
    return <div className="2xl:pl-[8px] mt-[16px]">
        <div className="pl-2 5xs:pl-3 pr-2 py-5 bg-white rounded-xl">
            <h1 className="text-18 font-semibold text-black2 ">Member Status</h1>
            <div className="flex items-center w-full mt-5">
                <DebounceSearchInput setState={setGlobalSearch} debounce={800}>
                    {(setState) => (
                        <div className="font-normal flex border-1 border-[#C4C4C4] bg-white hover:bg-[#EFEFEF] rounded-[28px]">
                            <input
                                id="message"
                                autoComplete="off"
                                name="search"
                                type="text"
                                placeholder="Search"
                                className="focus:outline-none py-1 px-4 text-12 xl:text-14 leading-5 bg-transparent rounded-[28px] w-full text-graydark"
                                onChange={(e) => setState(e.target.value)}
                            />
                            <SearchIcon className={classNames("mr-2")} />
                        </div>
                    )}
                </DebounceSearchInput>
            </div>
            <div className="my-1 flex items-center flex-wrap">
                <div
                    className={classNames(
                        "flex items-center mr-[10px] w-auto my-1"
                    )}
                >
                    <CustomDropdownListForFilter
                        placeholder="Department"
                        state={departmentID}
                        setState={setDepartmentID}
                        dropdownItems={
                            departmentList
                                ? [...departmentList.map((st: any) => ({
                                    value: st.id,
                                    label: st.name,
                                }))]
                                : []
                        }
                        textAlign="text-left"
                        classNameCus="max-w-full sm:max-w-[250px] 2xl:w-32 sm:w-28 w-full"
                    />
                </div>
                <div
                    className={classNames(
                        "flex items-center w-auto my-1"
                    )}
                >
                    <CustomDropdownListForFilter
                        placeholder="Status"
                        state={statusID}
                        setState={setStatusID}
                        dropdownItems={
                            statusList
                                ? [...statusList.map((st: any) => ({
                                    value: st.name,
                                    label: st.name,
                                }))]
                                : []
                        }
                        textAlign="text-left"
                        classNameCus="max-w-full sm:max-w-[250px] 2xl:w-32 sm:w-28 w-full"
                    />
                </div>
            </div>
            <div className={classNames(styles.custom_scrollbar, "mt-5 max-h-[410px] overflow-y-auto ")}>
                <div className="grid grid-cols-2 sm:gap-1 gap-5">
                    <div>
                        {memberData.slice(0, Math.ceil(memberData?.length / 2))?.map((member: any, key: number) => {
                            let idx = key >= 4 ? key % 4 : key;
                            return <Member key={member.id} idx={idx} photo={member.photo} name={member.name} status={member.working_status} time={member.time} />
                        })}
                    </div>
                    <div>
                        {memberData.slice(Math.ceil(memberData?.length / 2), memberData?.length)?.map((member: any, key: number) => {
                            let idx = key > 4 ? key % 4 : key;
                            return <Member key={member.id} idx={idx} photo={member.photo} name={member.name} status={member.working_status} time={member.time} />
                        })}
                    </div>
                </div>

            </div>
        </div>
    </div>

}
export default MemberStatus;