import { redirectToLogin } from './annoucement-sheet';
import { api, endpointUrl } from './api';
import authHeader from './auth-header';
export type UsersResponse = { data: User[] };
export type User = {
  module_id: number,
  usersList: UsersList,

}
export interface UsersList {
  data: UserGet[],
  search?: string,
  role?: string,
  id?: number | string,
  page?: number | string,
  limit?: number | string,
  total: number;
  sort?:string;
  ids?: number[];
}

export type UserGet = {
  id: number,
  name: string,
  roles: Role[]
  email: string,
  createdBy: {
    name: string,
    createdAt: string,
  },
  enable: boolean,
}

export type Role = [
  {
    id: number,
    name: string
  }
];
export type UserStatus = {
  id: number,
  status: number
}
export const usersApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAllUsers: build.query<User, Partial<UsersList>>({
      query: ({ search, role, page, limit,sort }) => ({
        url: `users?search=${search}&role=${role}&page=${page}&limit=${limit}&sort=${sort}`,
        headers: authHeader(),
        method: "GET",
      }),
      transformErrorResponse:(err)=>{
        if(err?.status===401){
        redirectToLogin();
        }
      },
      // providesTags: ['Users']
      providesTags: [{ type: "UsersList" as const, id: "LIST" }]
    }),
    updateUsers: build.mutation<UsersList, Partial<UsersList>>({
      query: ({ id, ...rest }) => ({
        url: `${endpointUrl}users/${id}`,
        headers: authHeader(),
        method: 'PUT',
        body: rest
      }),
      invalidatesTags: (UsersList) => [{ type: 'UsersList', id: UsersList?.id }],
    }),
    createUser: build.mutation<UsersList, Partial<UserGet>>({
      query: (user) => ({
        url: `${endpointUrl}users`,
        headers: authHeader(),
        method: "POST",
        body: user
      }),
      invalidatesTags: (UsersList) => [{ type: 'UsersList', id: UsersList?.id }],
    }),

    changeUsers: build.mutation<UsersList, Partial<UsersList>>({
      query: (body) => ({
        url: `${endpointUrl}permissions/${body.id}/changedStatus`,
        headers: authHeader(),
        method: 'POST',
        body,
      }),
      invalidatesTags: (UsersList) => [{ type: 'UsersList', id: UsersList?.id }],
    }),
    deleteUserList: build.mutation<{ success: boolean; id: number }, number>({
      query: (id) => ({
        url: `${endpointUrl}users/${id}`,
        headers: authHeader(),
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, id) => [{ type: 'UsersList', id }],
    }),
    multiDeleteUserList: build.mutation<UsersList, number[]>({
      query: (ids) => ({
        url: `${endpointUrl}deleteUsers`,
        headers: authHeader(),
        method: 'DELETE',
        body: {ids}
      }),
      invalidatesTags: (UsersList) => [{ type: 'UsersList', id: UsersList?.id }],
    }),
    changeUserStatus: build.mutation<UsersList, Partial<UserStatus>>({
      query: (body) => ({
        url: `${endpointUrl}users/${body.id}/changeStatus`,
        method: 'POST',
        body,
        headers: authHeader()
      }),
      invalidatesTags: (UsersList) => [{ type: 'UsersList', id: UsersList?.id }],
    }),
    sendActivateMail: build.mutation<UsersList, number>({
      query: (body) => ({
        url: `${endpointUrl}users/${body}/send-reset-password-mail`,
        method: 'POST',
        body,
        headers: authHeader()
      }),
      invalidatesTags: (UsersList) => [{ type: 'UsersList', id: UsersList?.id }],
    }),
  }),
});


export const {
  useGetAllUsersQuery,
  useUpdateUsersMutation,
  useChangeUsersMutation,
  useCreateUserMutation,
  useDeleteUserListMutation,
  useMultiDeleteUserListMutation,
  useChangeUserStatusMutation,
  useSendActivateMailMutation
} = usersApi;

export const {
  endpoints: { getAllUsers, updateUsers, changeUsers, createUser, deleteUserList, multiDeleteUserList, changeUserStatus,sendActivateMail },
} = usersApi;

