import {
    createColumnHelper,
    SortingState,
    ColumnFiltersState,
    RowSelectionState,
    ColumnDef,
    PaginationState,
  } from "@tanstack/table-core";
  import {
    FC,
    useState,
    useMemo,
    Dispatch,
    SetStateAction,
    memo,
    useCallback,
    useRef,
    useEffect,
  } from "react";
  import styles from "./DailyReport.module.scss";
  import LayoutAdjustableTable from "../../Tables/LayoutAdjustableTable";
  import TablePaginator from "../../Tables/TablePaginator";
  import { renderDateFormatDMY } from "../../DashboardCalendarView/DashboardAddEventPopup";
  import CommonTableBottom from "../../CommonTableBottom/CommonTableBottom";
  import { CheckIcon, CloseSmallIcon, EditIcon, ViewIcon } from "../../common/Icons";
  import classNames from "classnames";
  import DeletePopup from "../../DeletePopup/DeletePopup";
  import { RefillRequestListDataItems } from "../../../app/services/profile-daily-report";
  import MemberDailyReportPopup from "./MemberDailyReportPopup";
  
  interface IMemberReportApproval {
    data?: any;
    setData?: any;
    columnFilters: ColumnFiltersState;
    setColumnFilters: Dispatch<SetStateAction<ColumnFiltersState>>;
    myLeavesList?: any;
    total: number;
    setCurrentPage: any;
    currentPage: number;
    pagination: PaginationState;
    setPagination: Dispatch<SetStateAction<PaginationState>>;
    changePage?: any;
    sorting: SortingState;
    setSorting: React.Dispatch<React.SetStateAction<SortingState>>;
    totalTime?: string;
  }
  const MyRefillTable: FC<IMemberReportApproval> = ({
    total,
    setCurrentPage,
    currentPage,
    pagination,
    setPagination,
    changePage,
    data,
    setData,
    sorting,
    setSorting,
    totalTime,
  }) => {
    const columnHelper = useMemo(() => createColumnHelper<any>(), []);
    const [customClass, setCustomClass] = useState<string>("");
    const [bottom, setBottom] = useState<number>();
    const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
    const [timeSpentPos, setTimeSpentPos] = useState<number>();
  
    const [itemCount, setItemCount] = useState<number>(total);
    const columns = useMemo(
      () => [
        columnHelper.accessor("refilled_at", {
          header: "Applied at",
          id: "refilled_at",
          cell: ({ getValue, cell }) => {
            const name = getValue();
            return (
              <div className="bg-white text-black2 px-2 flex items-center">
                <p className="px-2 py-1">{name}</p>
              </div>
            );
          },
        }),
  
        columnHelper.accessor("date", {
          header: "Report Date",
          id: "date",
          enableSorting: true,
          cell: ({ getValue, cell }) => {
            const data = getValue();
            return (
              <div className="bg-white text-black2 px-2 flex items-center">
                <p className="px-2 py-1">{data}</p>
              </div>
            );
          },
        }),
        columnHelper.accessor("reason", {
          header: "Reason",
          id: "reason",
          enableSorting: true,
          cell: ({ getValue, cell }) => {
            const name = getValue();
            return (
              <div className="bg-white text-black2 px-2 flex items-center">
                <p className="px-2 py-1">{name}</p>
              </div>
            );
          },
        }),
        columnHelper.accessor("current_status", {
          header: "Status",
          id: "current_status",
          enableSorting: false,
          cell: ({ getValue, cell }) => {
            const current_status = getValue();
            const color=current_status=="Approved"?'text-vorpgreen':current_status=="Rejected"?'text-redPink':'text-black2'
            return (
              <div className="bg-white text-black2 px-2 flex items-center justify-center">
                <p className={classNames(color,"px-2 py-1")}>{current_status}</p>
              </div>
            );
          },
        }),
      ],
      [columnHelper, data]
    );
  
    const [columnOrder, setColumnOrder] = useState<string[]>(
      columns.map((column: ColumnDef<any, any>) => column.id as string)
    );
  
    const visibleColumns: { [key: string]: boolean } = {};
  
    return (
      <div>
        <div className={styles.tableDaily}>
          <LayoutAdjustableTable
            data={data}
            hideColumn={visibleColumns}
            columns={columns}
            sorting={sorting}
            setSortingState={setSorting}
            rowSelection={rowSelection}
            setRowSelection={setRowSelection}
            columnOrder={columnOrder}
            setColumnOrder={setColumnOrder}
            unDraggableFields={["actions", "selection"]}
            theaderColor="text-graydark"
            isLayoutControlOpen={false}
            setIsLayoutControlOpen={() => 1}
            label="User List"
            setItemCount={setItemCount}
            pagination={pagination}
            setPagination={setPagination}
            noPaddingCells={["leave_type_id", "fullHalfDay", "status"]}
          />
          <CommonTableBottom
            data={[
              {
                name: totalTime ? "Total" : "",
                colSpan: 5,
                left: 0,
                bottom: bottom,
                classes: totalTime ? customClass : "hidden",
              },
              {
                name: totalTime ? totalTime : "",
                colSpan: 3,
                left: Number(timeSpentPos) - 40,
                bottom: bottom,
                classes: totalTime ? customClass : "hidden",
              },
            ]}
          />
        </div>
        <div className="pt-6">
          <TablePaginator
            total={itemCount}
            pagination={pagination}
            setPagination={setPagination}
            itemCount={total}
            setCurrentPage={setCurrentPage}
            currentPageDefault={currentPage}
            changePage={changePage}
          />
        </div>
        
      </div>
    );
  };
  
  export default memo(MyRefillTable);
  