import { redirectToLogin } from "./annoucement-sheet";
import { api } from "./api";
import authHeader from "./auth-header";
import { Ids } from "./media";

type DepartmentsResponse = {
  module_id: number;
  status: boolean;
  departmentList: DepartmentInfo[];
};

export type TData = {
  data: DepartmentInfo[];
  total: number;
};
export type DepartmentInfo = {
  id?: number;
  name: string;
};
type departmentInput = {
  // sort: string;
  // search: string;
};
export type TaskDepartmentList = {
  departmentList: TaskDepartmentListData[];
};
export type TaskDepartmentListData = {
  id: number;
  name: string;
};

export const departmentApi = api.injectEndpoints({
  endpoints: (build) => ({
    getDepartmentList: build.query<
    DepartmentInfo[],
      departmentInput
    >({
      query: () => ({
        url: `departments`,
        headers: authHeader(),
      }),
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
      // providesTags: (result: any = []) => {
      //   if (result.data) {
      //     if (result.data.length > 0) {
      //       return [
      //         ...result.data.map(
      //           (id: any) => ({ type: "Department", id } as const)
      //         ),
      //         { type: "Departments" as const, id: "LIST" },
      //       ];
      //     } else {
      //       return [{ type: "Departments" as const, id: "LIST" }];
      //     }
      //   } else {
      //     return [{ type: "Departments" as const, id: "LIST" }];
      //   }
      // },
    }),
    createDepartment: build.mutation<TaskDepartmentList, DepartmentInfo>({
      query(body) {
        return {
          headers: authHeader(),
          url: `/departments`,
          body,
          method: "POST",
        };
      }
    }),
    updateDepartment: build.mutation<TaskDepartmentList, DepartmentInfo>({
      query(body) {
        return {
          headers: authHeader(),
          url: `/departments/${body.id}`,
          body,
          method: "PUT",
        };
      },
      // invalidatesTags: (employees) => {
      //   return [{ type: "Departments", id: "LIST" }];
      // },
    }),
    deleteDepartment: build.mutation<TaskDepartmentList, number>({
      query(id) {
        return {
          headers: authHeader(),
          url: `/departments/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: (employees) => {
        return [{ type: "Departments", id: "LIST" }];
      },
    }),
  }),
});

export const {
  useGetDepartmentListQuery,
  useCreateDepartmentMutation,
  useUpdateDepartmentMutation,
  useDeleteDepartmentMutation,
} = departmentApi;

export const {
  endpoints: {
    getDepartmentList,
    createDepartment,
    updateDepartment,
    deleteDepartment,
  },
} = departmentApi;
