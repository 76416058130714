import { FC, ReactNode, useEffect, useState } from "react";
import UserDailyInfo from "../../components/DashboardProfile/Wrapper/UserDailyInfo";
import DashboardAnnoucement from "../../components/DashboardAnnoucement/DashboardAnnoucement";
import DashboardProfileDesignCard from "../../components/DashboardProfileDesignCard/DashboardProfileDesignCard";
// import ProfileGoalCard from "../../components/ProfileGoalCard/ProfileGoalCard";
import editIcon from "../../components/ProfileGoalCard/img/bxs_edit.svg";

import ModalGoalTiny from "../../components/ModalGoalTiny/ModalGoalTiny";
// import { getAuthUser, useGetAnnoucementDataQuery } from "../../app/services/dashboard";
// import { IUser, useCreateMyGoalMutation, useGetProfileDataQuery, useGetProfileQuery } from "../../app/services/profile";

import {
  getAuthUser,
} from "../../app/services/dashboard";
import {
  IEventList,
  IUser,
  ProfileInfoData,
  useCreateMyGoalMutation,
  useGetProfileInfoQuery,
  useGetProfileQuery
} from "../../app/services/profile";
import { baseObj } from "../../utils/constants";
import { useLocation } from "react-router-dom";
import ErrorPopup from "../../components/ErrorPopup/ErrorPopup";
import CommonErrorPopup from "../../components/common/CommonErrorPopup";
import classNames from "classnames";
import MediaSkeleton from "../../components/MediaFileManager/MediaSkeleton";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { setProfileNotification } from "../../reducers/dashboard";
import ProfileTodayEvent from "../../components/ProfileTodayEvent/ProfileTodayEvent";
import ProfileWeather from "../../components/ProfileWeather/ProfileWeather";
import MemberStatus from "../../components/MemberStatus/MemberStatus";
import { TDataList, TPreviousList, TProjectListDailyReport, TTaskNameList, TTaskTagsList } from "../../app/services/profile-daily-popup";
import { changeStringToHMS } from "../../components/CustomTimePicker/CustomTimePicker";
// import { Editor } from "react-draft-wysiwyg";
// import { EditorState,convertToRaw,ContentState } from 'draft-js';
// import draftToHtml from 'draftjs-to-html';
// import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
// import DOMPurify from 'dompurify';
// import { convertToHTML,convertFromHTML } from 'draft-convert';

interface IUserLayout {
  children: ReactNode;
}

const UserLayout: FC<IUserLayout> = ({ children }) => {
  const location = useLocation();
  const [state, setState] = useState(localStorage.getItem("state"));

  //var id = state ? Number(state) : 0;
  var userData = getAuthUser()?.user?.employee_id;
  var currentUrl = window.location.pathname.split('/employeeprofile/')[1]?.split('/')[0];
  var id = currentUrl ? parseInt(currentUrl) : 0;
  const { data: MyProfile, isLoading: profileLoading } = useGetProfileQuery(
    id ? id : 0
  );
  const { data, isError, error } = useGetProfileInfoQuery(id ? id : 0);
  const [showError, setShowError] = useState<boolean>(isError);
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [createMyGoal] = useCreateMyGoalMutation();
  const [successPopup, setSuccessPopup] = useState<boolean>(false);
  const [errorPopup, setErrorPopup] = useState<boolean>(false);
  const [message, setMessage] = useState<any>([]);

  const [isImportModalOpen, setIsImportModalOpen] = useState<boolean>(false);
  const [userInfo, setUserInfo] = useState<IUser>();
  const [designationData, setDesignationData] = useState<baseObj>();

  const [userTimeSheet, setUserTimeSheet] = useState<baseObj[] | undefined>([]);
  const [todayEvents, setTodayEvents] = useState<IEventList[] | undefined>([]);
  const [clockStatus, setclockStatus] = useState<string | undefined>("");
  const annoucetitle = "Annoucement";
  // const { data: annoucementData, isLoading: annLoading } =
  //   useGetAnnoucementDataQuery();
  const [profileData, setProfileData] = useState<ProfileInfoData>();
  const stateData: any = useSelector((state) => state);
  const [notifications, setNotifications] = useState<any>([]);
  const [latitude, setLatitude] = useState<any>();
  const [longitude, setLongitude] = useState<any>();
  const [departmentList, setDepartmentList] = useState<any>([]);
  const [annoucementData, setAnnoucementData] = useState<any>([]);
  const [projectLists, setProjectLists] = useState<TProjectListDailyReport[]>(
    []
  );
  const [categoriesList, setCategoriesList] = useState<TTaskTagsList[]>([]);
  const [previousDataList, setPreviousDataList] = useState<TPreviousList[]>([]);
  const [todayData, setTodayData] = useState<TPreviousList | null>();
  const [taskNames, setTaskName] = useState<TTaskNameList[]>([]);
  const [reportDate, setReportDate] = useState<string>('');
  const [maxTimeLimit, setMaxTimeLimit] = useState<number>(0);
  const [dailyPopupData,setDailyPopupData]=useState<TDataList>();
  const getMyLocation = () => {
    const location = window.navigator && window.navigator.geolocation;
    location?.getCurrentPosition((po) => { });
    if (location) {
      location.getCurrentPosition(
        (position) => {
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
        },
        (error) => {
          setErrorMsg(error?.message);
        }
      );
    } else {
    }
  };

  useEffect(() => {
    if (id == 0) {
      if (navigator.permissions != undefined) {
        navigator.permissions
          ?.query({ name: "geolocation" })
          .then(function (result) {
            if (result.state === "granted") {
              //If granted then you can directly call your function here
            } else if (result.state === "prompt") {
            } else if (result.state === "denied") {
              //If denied then you have to show instructions to enable location
            }
            result.onchange = function () {
            };
          });
      }
      getMyLocation();
    } else {
      setErrorMsg("");
    }
  }, [getAuthUser, id]);
  const title = "My Information";
  const lists = [
    {
      type: "Designation",
      name: "UIUX Designer",
    },
    {
      type: "Department",
      name: "Design",
    },
    {
      type: "Date of Join",
      name: "19 July 2021",
    },
  ];
  const options: any = {
    timeZone: "Asia/Hong_Kong",
    hour12: false,
    hour: "numeric",
    minute: "numeric",
  };
  const options1: any = {
    timeZone: "Asia/Yangon",
    hour12: false,
    hour: "numeric",
    minute: "numeric",
  };
  const options2: any = {
    timeZone: "Asia/Kuala_Lumpur",
    hour12: false,
    hour: "numeric",
    minute: "numeric",
  };
  let coordinates = [
    {
      lat: '3.1073059658208626',
      long: '101.72590276461767',
      country: 'Cheras',
      time: new Intl.DateTimeFormat("en-US", options2).format(new Date()),
    },
    {
      lat: '22.399103753278688',
      long: '114.07613916411945',
      country: 'Kwai Chung',
      time: new Intl.DateTimeFormat("en-US", options).format(new Date()),
    },
    {
      lat: '16.843394564215522',
      long: '96.17018537639565',
      country: 'Yangon',
      time: new Intl.DateTimeFormat("en-US", options1).format(new Date()),
    },
  ]
  useEffect(() => {
    localStorage.removeItem("state");
  }, []);
  useEffect(() => {
    // console.log("location state", location.state);
    if (location.state) {
      setState(location.state);
    }
  }, [location]);

  const twoCol = true;
  const [goaltitle, setGoalTitle] = useState<any>("My Goal");
  const [convertedContent, setConvertedContent] = useState<any>();

  const saveGoalLists = (tasks: any) => {
    setConvertedContent(tasks.myGoal);
    setGoalTitle(tasks.goalTitle ? tasks.goalTitle : "My Goal");
    createMyGoal({ title: tasks?.goalTitle, content: tasks?.myGoal })
      .unwrap()
      .then((payload: any) => {
        setSuccessPopup(true);
        setIsImportModalOpen(false);
        setMessage(payload ? [payload?.message] : ["Created Successfully!"]);
        toast("Successfully Updated.", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          type: "success",
          theme: "light",
        });
      })
      .catch((error: any) => {
        setErrorPopup(true);
        setMessage(error ? [error?.data?.message] : ["Something went wrong!"]);
      });
  };

  function createMarkup(html: any) {
    return { __html: html };
  }

  useEffect(() => {
    setUserInfo(MyProfile?.user);
    setUserTimeSheet(MyProfile?.timeSheets);
    setConvertedContent(MyProfile?.goalData?.content);
    setGoalTitle(
      MyProfile?.goalData?.title ? MyProfile?.goalData?.title : "My Goal"
    );
    setTodayEvents(MyProfile?.todayEvents)
    setclockStatus(MyProfile?.clockStatus);
  }, [MyProfile]);
  const dispatch = useDispatch()
  useEffect(() => {
    if(data){
      setProfileData(data);
      setDesignationData(data?.designationData);
      setShowError(isError);
      var err: any = error;
      setErrorMsg(err?.data?.message);
      var updatedData = stateData?.dashboard?.notificationDetail;
      setNotifications(updatedData);
      setAnnoucementData(data?.announcements)
      setWeatherData(data?.weather);
      if (data?.departments) {
        setDepartmentList([
          { name: "All Departments", id: 0, label: "All Departments" },
          ...data?.departments,
        ]);
      }
      setProjectLists(data?.dataForDailyReportForm?.project_list);
      setCategoriesList(data?.dataForDailyReportForm?.task_categories);
      setPreviousDataList(data?.dataForDailyReportForm?.previous_data);
      setTaskName(data?.dataForDailyReportForm?.task_list);
      setTodayData(data?.dataForDailyReportForm?.today_data);     
      setReportDate(data?.dataForDailyReportForm?.date)
      var time = changeStringToHMS(data?.dataForDailyReportForm?.total_time);
      var dailyData=data?.dataForDailyReportForm;
      setDailyPopupData({
        date:dailyData.date,
        hours:dailyData.hours,
        minutes:dailyData.minutes,
        previous_data:dailyData.previous_data,
        project_list:dailyData.project_list,
        task_categories:dailyData.task_categories,
        task_list:dailyData.task_list,
        today_data:dailyData.today_data,
        total_time:dailyData.total_time,
        total_time_text:dailyData.total_time_text
      })
      setMaxTimeLimit(time ? time : 0);      
      fetchHK_WeatherSummary();
    }
    
  }, [data, isError]);

  useEffect(() => {
    setTimeout(() => {
      setSuccessPopup(false);
    }, 1000);
  }, [successPopup]);

  // const [date, setDate] = useState(new Date());
  // useEffect(() => {
  //   const timer = setInterval(() => {
  //     setDate(new Date());
  //   }, 1000);
  //   return () => {
  //     clearInterval(timer);
  //   };
  // }, []);
  // const options: any = {
  //   timeZone: "Asia/Hong_Kong",
  //   hour12: false,
  //   hour: "numeric",
  //   minute: "numeric",
  //   second: "numeric",
  // };

  // const formattedTime = new Intl.DateTimeFormat("en-US", options).format(date);

  // const optionsDate: any = {
  //   timeZone: "Asia/Hong_Kong",
  //   year: "numeric",
  //   month: "long",
  //   day: "numeric",
  // };

  // const formattedDate = new Intl.DateTimeFormat("en-US", optionsDate).format(
  //   date
  // );
  const [weatherData, setWeatherData] = useState<any>([]);
  const [weatherCode, setWeatherCode] = useState<string>('');
  const [weatherError, setWeatherError] = useState<string>('');
  const fetchHK_WeatherSummary = async () => {
    await fetch(`https://data.weather.gov.hk/weatherAPI/opendata/weather.php?dataType=warnsum&lang=en`)
      .then(res => res.json())
      .then(result => {
        setWeatherCode(result?.code);
      });
  }
  // useEffect(() => {
  //   setWeatherData(weather?.data);
  //   fetchHK_WeatherSummary();
  // }, [weather]);


  return (
    <>
      <ToastContainer className={"text-12 font-poppins font-normal"} />
      <ErrorPopup
        descText="Error Message!"
        confirmBtnText="Yes, Close."
        show={errorPopup}
        setShow={setErrorPopup}
        message={message}
        setMessage={setMessage}
      />
      <div className="text-left py-0 min-h-screen font-primary">
        <div className="flex flex-col 2xl:flex-row justify-between items-start">
          <div className="2xl:w-[75%] w-full 2xl:mb-0 mb-5">
            {profileLoading ? ( //profileLoading
              <MediaSkeleton isProfileLayout={true} />
            ) : (
              <UserDailyInfo
                id={id}
                user={userInfo}
                userTimeSheet={userTimeSheet}
                clockStatus={clockStatus}
                data={profileData}
                // formattedDate={formattedDate}
                // formattedTime={formattedTime}
                notificationsList={notifications}
                latitude={latitude}
                longitude={longitude}
                setProjectLists={setProjectLists}
                projectLists={projectLists}
                setCategoriesList={setCategoriesList}
                categoriesList={categoriesList}
                setReportDate={setReportDate}
                reportDate={reportDate}
                dailyPopupData={dailyPopupData}
              />
            )}
            <div className="pt-4">{children}</div>
          </div>
          <div className="w-full 2xl:w-[25%] ">
            <div
              className={classNames(
                "2xl:px-[8px] ",
                Number(id) === 0 &&
                  Number(id) !== null &&
                  Number(id) !== undefined
                  ? "mb-[16px]"
                  : ""
              )}
            >
              {profileLoading ? ( //annLoading
                <MediaSkeleton isAnnouncement={true} />
              ) : (
                <ProfileWeather
                  weatherCode={weatherCode}
                  weatherData={weatherData}
                  weatherError={weatherError}
                  coordData={coordinates}
                />
              )}
            </div>
            <MemberStatus departmentList={departmentList} />
            <div
              className={classNames(
                "2xl:px-[8px] mt-[16px]",
                Number(id) !== 0 &&
                  Number(id) !== null &&
                  Number(id) !== undefined
                  ? "hidden"
                  : ""
              )}
            >
              {profileLoading ? ( //annLoading
                <MediaSkeleton isAnnouncement={true} />
              ) : (
                <DashboardAnnoucement
                  annoucements={
                    annoucementData
                      ? annoucementData
                      : []
                  }
                  title={annoucetitle}
                  twoCol={twoCol}
                />
              )}
            </div>

            {/* <div
              className={classNames(
                "2xl:px-[8px] ",
                Number(id) === 0 &&
                  Number(id) !== null &&
                  Number(id) !== undefined
                  ? "mt-[16px]"
                  : ""
              )}
            >
              {profileLoading || annLoading ? ( //annLoading
                <MediaSkeleton isAnnouncement={true} />
              ) : (
                <DashboardProfileDesignCard
                  title={title}
                  lists={lists}
                  data={designationData}
                />
              )}
            </div> */}
            <div
              className={classNames(
                "2xl:pl-[8px]",
                Number(id) === 0 &&
                  Number(id) !== null &&
                  Number(id) !== undefined
                  ? "mt-[16px]"
                  : ""
              )}
            >
              {profileLoading ? ( //annLoading
                <MediaSkeleton isAnnouncement={true} />
              ) : (
                <ProfileTodayEvent
                  eventList={todayEvents ? todayEvents : []}
                />
              )}
            </div>
            {profileLoading ? (
              <MediaSkeleton isAnnouncement={true} />
            ) : (
              <div className={classNames("2xl:px-[8px] mt-[16px]")}>
                <div className="lg:px-[30px] px-2 5xs:px-4 sm:px-5 py-[30px] bg-white rounded-xl">
                  <div className="flex justify-between">
                    <h3 className="text-14 xl:text-18 font-primary text-black2 font-semibold">
                      {goaltitle}
                    </h3>
                    {Number(id) == 0 ? (
                      <button
                        className="bg-vorpblue rounded-md p-[0.438rem]"
                        onClick={() => setIsImportModalOpen(true)}
                      >
                        <img src={editIcon} className="" alt="edit icon" />
                      </button>
                    ) : null}
                  </div>
                  <div
                    className="preview mt-5 lg:text-14 text-12 font-normal"
                    dangerouslySetInnerHTML={createMarkup(convertedContent)}
                  ></div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <ModalGoalTiny
        onSave={saveGoalLists}
        isOpen={isImportModalOpen}
        setIsOpen={setIsImportModalOpen}
        tasks={convertedContent}
        mainTitle={goaltitle}
        id={Number(id)}
      ></ModalGoalTiny>

      <CommonErrorPopup
        show={showError}
        setShow={setShowError}
        message={errorMsg} //"Not Employee Role"
        setMessage={() => 1}
      />
    </>
  );
};

export default UserLayout;
