import { ChangeEvent, useCallback, useState } from "react";

const useInput = (initialValue: string) => {
  const [value, setValue] = useState<string | number>(initialValue);
  const [error, setError] = useState<boolean>(false);

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement|HTMLTextAreaElement>) => {
      setValue(e.target.value);
    },
    [setValue]
  );

  return {
    value,
    error,
    onChange: handleChange,
    setError,
    setValue,
  };
};

export default useInput;
