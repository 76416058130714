import {
  Dispatch,
  FC,
  FocusEvent,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { useDestoryTemplateMutation } from "../../app/services/template";
import { ChevronDown, DeleteIcon } from "./Icons";
import { uniqueId } from "lodash";
import styles from "./Custom.module.scss";
const classNames = require("classnames");

interface ICustomDropdownList<P = unknown> {
  state: P;
  setState: Dispatch<SetStateAction<P>> | (() => void);
  placeholder?: string;
  dropdownItems: IListItem[];
  className?: string;
  custPadding?: string;
  textAlign?: string;
  dropdownborder?: string;
  deleteTemplate?: any;
  setSuccessPopup?: any;
  setErrorPopup?: any;
  setMessage?: any;
  isProject?: boolean;
  setIsTemplate?: Dispatch<React.SetStateAction<boolean>>;
  haveDeleteBtn?: boolean;
  isDepartment?: boolean;
}

interface IListItem {
  label: string;
  value: string | number;
  index?: number | string;
}

// Type Factory for Generic Types

const CustomDropdownListFactory = <P extends unknown>() => {
  const ActualInstance: FC<ICustomDropdownList<P>> = ({
    state,
    setState,
    dropdownItems,
    placeholder = "All",
    className = "",
    textAlign = "text-center",
    dropdownborder = "",
    deleteTemplate,
    setSuccessPopup,
    setErrorPopup,
    setMessage,
    isProject,
    setIsTemplate,
    custPadding,
    haveDeleteBtn,
    isDepartment,
  }) => {
    const [isActive, setActive] = useState(false);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [label, setLabel] = useState<string>(placeholder);
    const [destoryTemp] = useDestoryTemplateMutation();
    //const [dropItems, setDropItems] = useState(dropdownItems);

    const uuid = uniqueId("custom-dropdown-list");
    const handleCloseDropdown = (event: FocusEvent<HTMLButtonElement>) => {
      const relatedTarget = event.relatedTarget;
      // If the click target is not the dropdown or dropdown item, close the list.
      if (
        !relatedTarget ||
        !(
          relatedTarget.id == uuid ||
          relatedTarget.className.includes(`${uuid}item`)
        )
      ) {
        if (!relatedTarget?.classList.contains("cust")) {
          setIsOpen(false);
        }
      }
    };
    //function handleClickOutside(event: MouseEvent) {
    //}

    // useEffect(() => {
    //   // Bind the event listener
    //   document.addEventListener("mousedown", handleClickOutside);
    //   if (!state) {
    //     var labelData = dropdownItems.find((x) => x.value == Number(placeholder));
    //     setLabel(labelData ? labelData.label : "Allbbbb");
    //   }
    // }, [placeholder]);

    useEffect(() => {
      if (state != undefined) {
        var labelData = dropdownItems.find((x) => x.value === Number(state));
        if(!isDepartment){
          setLabel(labelData ? labelData.label : "All");
        }else{
          setLabel(labelData ? labelData.label : "All Deartments");
        }
      }
    }, [state]);

    const currentViewHandler = (item: any) => {
      setState(item.value as any);
      setLabel(item.label);
      setIsOpen(false);
      setActive(!isActive);
      if (setIsTemplate) {
        setIsTemplate(false);
      }
    };
    // const deleteTemplateUI = (item: any) => {
    //   deleteTemplate(item);
    //   setIsOpen(false);
    // }
    //var checkData: any;
    const deleteTemplateHandler = (item: any) => {
      try {
        destoryTemp(item)
          .unwrap()
          .then((payload: any) => {
            if (payload) {
              setSuccessPopup(true);
              setMessage(
                payload ? [payload?.message] : ["Delete Succcessfully"]
              );
            }
          })
          .catch((error: any) => {
            setErrorPopup(true);
            setMessage(["Something went wrong! Trying again."]);
          });
        deleteTemplate(item);
      } catch (error) {
        console.log(error);
      }
    };
    // const removeItemsById = (arr: any, id: number) => {
    //   var i = arr.length;
    //   if (i) {   // (not 0)
    //     while (--i) {
    //       var cur = arr[i];
    //       if (cur.id == id) {
    //         arr.splice(i, 1);
    //       }
    //     }
    //   }
    // }

    // useEffect(() => {
    //   if(dropItems) {
    //     setDropItems(checkData);
    //   }
    // },[dropItems])


    return (
      <div className="cust relative customDropdownContainer">
        <button
          id={uuid}
          className={classNames(
            dropdownborder,
            `dropdown-hover-btn cust flex hover:cursor-pointer text-14 leading-5 text-graydark relative justify-between w-32 items-center pl-[11px] pr-[11px] border border-[#CCDAE4] rounded-[5px] custom-dropdown-list ${className}
            ${
              custPadding && custPadding !== ""
                ? custPadding
                : " xl:py-1 py-[2px]"
            }`
          )}
          onClick={(e) => {
            setIsOpen(!isOpen);
            e.stopPropagation();
          }}
          onBlur={handleCloseDropdown}
        >
          {isProject
            ? String(label)
            : String(label) || String(state) || placeholder}

          <ChevronDown />
        </button>
        <div
          className={classNames(
            "cust absolute left-0 text-12 max-h-[200px] overflow-y-auto z-10 overflow-hidden top-[104%] w-full border border-[#EFF2F5] bg-white rounded-md custom-scrollbar ",
            { hidden: !isOpen }
          )}
        >
          {dropdownItems?.map((item: IListItem, index: number) => (
            <div
              className={classNames(
                "cust relative bg-white hover:bg-vorpblue hover:text-white text-graydark",
                isActive ? styles.isActiveClass : null
              )}
              key={index}
            >
              <button
                key={item.value}
                className={`cust px-2 custom-dropdown-item py-[2px] w-full transition-all duration-300  ${textAlign} ${
                  uuid + "item"
                }`}
                onClick={() => currentViewHandler(item)}
              >
                <div className="cust flex justify-between items-center">
                  <span className="cust ">{item.label}</span>
                </div>
              </button>
              {haveDeleteBtn ? (
                <button
                  className={classNames(
                    item.value === 0 || item.value === -1 ? "hidden" : "",
                    "cust z-[100] cursor-pointer absolute right-2 top-1/2 -translate-y-1/2 w-5 h-5"
                  )}
                  onClick={() => {
                    deleteTemplateHandler(item.value);
                  }}
                >
                  <DeleteIcon color="#fff" />
                </button>
              ) : null}
            </div>
          ))}
        </div>
      </div>
    );
  };
  return ActualInstance;
};

// Extend More for Required Types

export const StringDropdownListWithDeleteBtn =
  CustomDropdownListFactory<string>();
