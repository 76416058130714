import axios from "axios";
import { endpointUrl } from "./api";
export const login = (username: string, password: string) => {
  return axios
    .post(endpointUrl + "signin", {
      username,
      password,
    })
    .then((response) => {
        var token=response.data.accessToken;
      if (response.data.accessToken) {
        axios.defaults.headers.common["Authorization"] = token;
        localStorage.setItem("user", JSON.stringify(response.data));
      }

      return response.data;
    });
};

export const logout = () => {
  localStorage.removeItem("userData");
  // axios
  //   .get(endpointUrl + "logout")
  //   .then((response) => {
  //     console.log(response.data)
  //     return response.data;
  //   });
};

export const getCurrentUser = () => {
  const userStr = localStorage.getItem("user");
  if (userStr) return JSON.parse(userStr);

  return null;
};