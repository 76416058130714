import { ChangeEvent, FC, ReactNode, memo } from "react";
import classNames from "classnames";

type resuableInput = {
  label?: string;
  type: "text" | "number" | "email" | "password";
  value: string | number;
  placeHolder: string;
  className?: string;
  onChange: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  disabled?: boolean;
  isSubmit?: boolean;
  mailError?: boolean;
  isRequired?: boolean;
  textarea?: boolean;
  children?: any;
  errorMessage?: string;
  hasMax?: boolean;
  hasMin?: boolean;
  isLabel?: boolean;
};

const ResuableInput: FC<resuableInput> = memo(
  ({
    label,
    value,
    placeHolder,
    onChange,
    disabled,
    type,
    isSubmit,
    mailError,
    isRequired,
    className,
    textarea,
    children,
    errorMessage,
    hasMax,
    hasMin,
    isLabel,
  }) => {
    let showTextInput: ReactNode = null;

    if (type == "number") {
      showTextInput = (
        <>
          <input
            onWheel={(e: any) => e.target.blur()}
            disabled={disabled}
            name={placeHolder}
            value={value ?? ""}
            onChange={onChange}
            type="number"
            placeholder={placeHolder}
            className="focus:outline-none focus-visible:outline-none w-full rounded py-3.5 px-3 border border-vorpgraylight text-black2 text-14 font-medium font-primary placeholder:text-graydark h-[48px]"
          ></input>
          <p
            className={classNames(
              isSubmit && value === "" ? "" : " hidden",
              " font-poppins text-[12px] text-redPink"
            )}
          >
            {errorMessage}
          </p>
        </>
      );
    } else if (!textarea && type == "text") {
      showTextInput = (
        <>
          <input
            disabled={disabled}
            name={placeHolder}
            value={value ?? ""}
            onChange={onChange}
            type={type}
            placeholder={placeHolder}
            className="focus:outline-none focus-visible:outline-none w-full rounded py-3.5 px-3 border border-vorpgraylight text-black2 text-14 font-medium font-primary placeholder:text-graydark h-[48px]"
          ></input>

          <p
            className={classNames(
              (isSubmit && value === "") || mailError ? "" : "hidden",
              " font-poppins text-[12px] text-redPink"
            )}
          >
            {errorMessage}
          </p>
        </>
      );
    } else if (type == "email") {
      showTextInput = (
        <>
          <input
            disabled={disabled}
            name={placeHolder}
            value={value ?? ""}
            onChange={onChange}
            type={type}
            placeholder={placeHolder}
            className="focus:outline-none focus-visible:outline-none w-full rounded py-3.5 px-3 border border-vorpgraylight text-black2 text-14 font-medium font-primary placeholder:text-graydark h-[48px]"
          ></input>
          {mailError ? (
            <p className={classNames(" font-poppins text-[12px] text-redPink")}>
              {errorMessage}
            </p>
          ) : null}
        </>
      );
    } else {
      showTextInput = (
        <textarea
          value={value ?? ""}
          onChange={onChange}
          id="message"
          rows={4}
          className=" placeholder:text-graydark resize-none focus-within:outline-none focus:outline-none focus-visible:outline-none focus:border-goalinputborder block p-2.5 w-full text-sm text-black2 bg-white rounded-[4px] border border-goalinputborder "
          placeholder={placeHolder}
        ></textarea>
      );
    }

    return (
      <div className={classNames(className ? className : "")}>
        <label
          className={classNames(
            label ? "" : "opacity-0",
            isLabel ? "min-h-[30px]" : "",
            "text-black2 text-15 pb-2 font-medium font-primary block"
          )}
        >
          {label}
          {isRequired && label && <span className=" text-red-500">*</span>}
        </label>
        {showTextInput}
      </div>
    );
  }
);

export default ResuableInput;
