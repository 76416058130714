// import { Modal } from "@material-ui/core";
import { Modal } from "@mui/material"

import classNames from "classnames";
import React, {
    createRef,
    Dispatch,
    FC,
    SetStateAction,
    useEffect,
    useRef,
    useState,
} from "react";
import { CloseIcon, ErrorNotiICon, SearchIcon } from "../common/Icons";

interface ICreateData {
    show: boolean;
    setShow: Dispatch<SetStateAction<boolean>>;
    message?: any;
    setMessage?: any;
}
const CommonErrorPopup: FC<ICreateData> = ({ show, setShow, message, setMessage }) => {
    return (
        <div>
            <Modal open={show} onClose={() => setShow(false)}  disableEnforceFocus={true}>
                <div>
                    <div className={classNames("z-10 fixed top-0 left-0 w-full h-full")}>
                        <div
                            className={classNames(
                                "relative z-10 top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 md:max-w-[320px] max-w-[300px] bg-[#fff] rounded-xl"
                            )}
                        >
                            <button onClick={() => setShow(false)} className="absolute top-0 left-1/2 -translate-x-1/2 -translate-y-1/2">
                                <ErrorNotiICon className="lg:w-[120px] lg:h-[120px] w-[100px] h-[100px]"/>
                            </button>
                            {/* <div className="relative text-center px-12 rounded-tl-xl rounded-tr-xl mb-0 pt-5">
                                <span
                                    onClick={() => setShow(false)}
                                    className={classNames("text-black2 text-20 cursor-pointer absolute top-0 right-[15px]")}
                                >
                                    <CloseIcon color="#464E5F" width={14} height={14} />
                                </span>
                            </div> */}

                            <div className={classNames(message?.length > 15 ? '' : 'flex justify-center items-center', "h-auto min-h-[60px]  flex flex-col justify-start text-left  items-start px-6 pt-[60px]")}>
                                <h3 className="w-full text-center font-poppins xl:text-20 text-base text-[#3F4254] font-medium mb-2">Error</h3>
                                <p className="w-full text-left block font-poppins font-medium xl:text-sm text-12 text-[#6C7293]">
                                    {message}
                                </p>
                            </div>
                            <div className="w-full bg-redPink mt-5 rounded-bl-[8px] rounded-br-[8px]">
                                <button onClick={() => setShow(false)} className="font-poppins font-medium text-white xl:text-sm text-12 text-center w-full py-[10px]">OK</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
};
export default CommonErrorPopup;
