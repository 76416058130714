import classNames from "classnames";
import { FC } from "react";

interface IDataObject {
  name: string;
  colSpan: number;
  left: number | undefined;
  classes?: string | undefined;
  bottom?: number;
}
interface ICommonTableBottom {
  data: IDataObject[];
}
const CommonTableBottom: FC<ICommonTableBottom> = ({ data }) => {
  return (
    <div
      className={classNames(
        "min-h-[50px] flex items-center bg-[#F5F8FA]",
        data[0]?.classes
      )}
    >
      {data?.map((column: IDataObject, key: number) => {
        var hide = "";
        if (column.left) {
          hide = window.innerWidth - 200 < column.left ? "hidden" : "";
        }

        return (
          <div
            style={{
              left: column.left + "px",
              // top: (column.bottom ? column.bottom + 15 : 0) + "px",
              top: 0,
            }}
            className={
              `text-[#858795] sticky px-2 text-left whitespace-nowrap text-12 lg:text-14 bg-[#F5F8FA] min-w-[112px] ` +
              column.classes
            }
            key={key}
          >
            {column.name}
          </div>
        );
      })}
    </div>
  );
};
export default CommonTableBottom;
