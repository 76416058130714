import { FC, useEffect, useState } from "react";
import CustomScrollbar from "../../CustomScrollbar/CustomScrollbar";
import { NumberDropdownList } from "../../common/DropdownList";
import { CloseIcon } from "../../common/Icons";
import DatePopupFoFilter from "../../EmployeeTable/DatePopupFoFilter";
import Styles from "../MyLeave/MyLeave.module.scss";
import CustomDropdownListForFilter from "../../EmployeeTable/CustomDropdownListForFilter";
import { TProjectListDailyReport, TTaskTagsList } from "../../../app/services/profile-daily-popup";
import { ColumnFiltersState, PaginationState, SortingState } from "@tanstack/react-table";
import { useApproveRefillReportMutation, useGetProfileDailyReportRefillListQuery, useRejectRefillReportMutation } from "../../../app/services/profile-daily-report";
import { renderDateFormatYMD } from "../../DashboardCalendarView/DashboardAddEventPopup";
import MemberReportApproalTable from "./MemberReportApproalTable";
import DeletePopup from "../../DeletePopup/DeletePopup";
import { ToastContainer, toast } from "react-toastify";
interface IMemberReportApproval {
    setProjectList: React.Dispatch<React.SetStateAction<TProjectListDailyReport[] | undefined>>;
    projectLists: TProjectListDailyReport[] | undefined;
    setTaskTagsList: React.Dispatch<React.SetStateAction<TTaskTagsList[] | undefined>>;
    taskTagsList: TTaskTagsList[] | undefined;
    setPendingCount: React.Dispatch<React.SetStateAction<number>>;
}
const MemberReportApproal: FC<IMemberReportApproval> = ({
    setPendingCount
}) => {
    const [applicationStartDate, setApplicationStartDate] = useState<Date>();
    const [applicationEndDate, setApplicationEndDate] = useState<Date>();
    const [projectId, setProjectId] = useState<any>({});
    const [taskTagId, setTaskTagId] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [sorting, setSorting] = useState<SortingState>([]);
    const [data, setData] = useState<any>([]);
    const [itemCount, setItemCount] = useState<number>(0);
    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
    const [pagination, setPagination] = useState<PaginationState>({
        pageIndex: 1,
        pageSize: 2,
    });
    const { data: refillReport } = useGetProfileDailyReportRefillListQuery({
        page: currentPage,
        limit: pagination.pageSize,
        start: applicationStartDate === undefined ? "" : renderDateFormatYMD(applicationStartDate, "-"),
        end: applicationEndDate === undefined ? "" : renderDateFormatYMD(applicationEndDate, "-"),
        type: projectId.type !== undefined ? projectId.type : "",
        project_id: projectId.value !== undefined ? projectId.value : 0,
        task_category_id: taskTagId,
        sorting: sorting.map((s) => `${s.desc ? "-" : ""}${s.id}`).join(","),
    });
    const [openDeletePopup, setOpenDeleteopup] = useState<boolean>(false);
    const [openRejectedPopup, setOpenRejectedPopup] = useState<boolean>(false);
    const [approving, setApproving] = useState<boolean>(false);
    const [rejecting, setRejecting] = useState<boolean>(false);
    const [showDetailPopup, setShowDetailPopup] = useState<boolean>(false);
    const [statusChangedID, setStatusChangedID] = useState<number>(0);
    const [approveRefillReport] = useApproveRefillReportMutation();
    const [rejectRefillReport] = useRejectRefillReportMutation();
    const approveRefillReportData = (id: number) => {
        approveRefillReport(id).then((res: any) => {
            var response = res.data;
            if (response.status == true) {
                setData((prev: any) => {
                    return prev.map((refill: any, index: number) => {
                        if (refill.id === id) {
                            return {
                                ...refill,
                                approve_option: response.data.approve_option,
                                reject_option: response.data.reject_option,
                                current_status: response.data.current_status
                            };
                        }
                        return refill;
                    });
                });
                toast(response.message, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    type: "success",
                });
            }
            setApproving(false);
            setOpenDeleteopup(false);
            setShowDetailPopup(false);
        })
    }
    const rejectRefillReportData = (id: number) => {
        rejectRefillReport(id).then((res: any) => {
            var response = res.data;
            if (response.status == true) {
                setData((prev: any) => {
                    return prev.map((refill: any, index: number) => {
                        if (refill.id === id) {
                            return {
                                ...refill,
                                approve_option: response.data.approve_option,
                                reject_option: response.data.reject_option,
                                current_status: response.data.current_status
                            };
                        }
                        return refill;
                    });
                });
            }
            toast(response.message, {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                type: "success",
            });
            setRejecting(false);
            setOpenRejectedPopup(false);
            setShowDetailPopup(false);
        })
    }
    const changedStatus = (id: number, status: string) => {
        if (status == "Approved") {
            approveRefillReportData(id);
        }
        if (status == "Rejected") {
            rejectRefillReportData(id);
        }
    }
    useEffect(() => {
        if (refillReport) {
            setData(refillReport.refill_request_list.data);
            setItemCount(refillReport.refill_request_list.total)
        }
    }, [refillReport])
    useEffect(() => {
        setCurrentPage(1);
    }, [applicationStartDate, applicationEndDate, pagination.pageSize])
    return <div className="mb-1 lg:mb-1 xl:mb-1">
        <div className="flex justify-between flex-col sm:flex-row xl:items-center">
            <div className="flex w-full">
                <div className="flex w-full items-center ">
                    <div className="flex my-1">
                        <p className="hidden mb-3 2xs:mb-0 text-13 leading-5 font-medium text-black2 mr-3">
                            DATE
                        </p>
                        {applicationStartDate && applicationEndDate && (
                            <button
                                type="button"
                                onClick={() => {
                                    setApplicationStartDate(undefined);
                                    setApplicationEndDate(undefined);
                                }}
                                className="text-12 mr-2"
                            >
                                <CloseIcon
                                    color="#EE436D"
                                    width={10}
                                    height={10}
                                />
                            </button>
                        )}

                        <div className="flex">
                            <div className={`mr-3 ${Styles.leaveDatePicker}`}>
                                <DatePopupFoFilter
                                    placeholder="Start Date"
                                    date={applicationStartDate}
                                    setDate={setApplicationStartDate}
                                />
                            </div>
                            <div className={`mr-3 ${Styles.leaveDatePicker}`}>
                                <DatePopupFoFilter
                                    placeholder="End Date"
                                    date={applicationEndDate}
                                    setDate={setApplicationEndDate}
                                />
                            </div>
                        </div>
                    </div>
                    {/* <div className="flex mr-3">
                            <p className="hidden mb-3 2xs:mb-0 text-13 leading-5 font-medium text-black2 mr-3">
                                Project
                            </p>
                            <CustomDropdownListForFilter
                                isObjectGet={true}
                                isFilter={true}
                                placeholder="Project"
                                state={projectId}
                                setState={setProjectId}
                                dropdownItems={projectLists?.map((status: any) => ({
                                    value: status.id,
                                    label: status.name,
                                    type: status?.type,
                                }))}
                                className="max-h-[38px] max-w-[135px]"
                            />
                        </div>
                        <div className="flex mr-3">
                            <p className="hidden mb-3 2xs:mb-0 text-13 leading-5 font-medium text-black2 mr-3">
                                Task Tag
                            </p>
                            <CustomDropdownListForFilter
                                isFilter={true}
                                placeholder="Task Tag"
                                state={taskTagId}
                                setState={setTaskTagId}
                                dropdownItems={taskTagsList?.map((status: any) => ({
                                    value: status.id,
                                    label: status.name,
                                }))}
                                className="max-h-[38px] max-w-[135px]"
                            />
                        </div> */}
                </div>
            </div>
            <div className="flex items-center justify-end md:justify-start sm:w-auto w-full">


                <div className="flex items-center mb-auto mr-3">
                    <p className="base-label xl:text-sm text-12 hidden 3xs:block">
                        DISPLAY
                    </p>
                    <NumberDropdownList
                        state={pagination.pageSize}
                        setState={(value) => {
                            setPagination((prev) => ({
                                ...prev,
                                pageSize: value as number,
                            }));
                        }}
                        dropdownItems={new Array(5)
                            .fill(1)
                            .map((_, index: number) => {
                                let display = (index + 1) * 10;
                                return {
                                    value: display,
                                    label: String(display),
                                };
                            })}
                        className="max-w-[72px]"
                        textAlign="text-left pl-2 pr-3"
                        paddingClass="py-[2px]"
                        placeholder="20"
                    />
                </div>
            </div>
        </div>
        <MemberReportApproalTable
            columnFilters={columnFilters}
            setColumnFilters={setColumnFilters}
            data={data ? data : []}
            setData={setData}
            // hiddenFields={[]}
            pagination={pagination}
            setPagination={setPagination}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            total={itemCount}
            sorting={sorting}
            setSorting={setSorting}
            setStatusChangedID={setStatusChangedID}
            setOpenDeleteopup={setOpenDeleteopup}
            setOpenRejectedPopup={setOpenRejectedPopup}
            setShowDetailPopup={setShowDetailPopup}
            showDetailPopup={showDetailPopup}
        />
        <DeletePopup
            descText="Are you sure to Approve?"
            confirmBtnText="Yes"
            confirmBtnColor="bg-vorpgreen"
            show={openDeletePopup}
            setShow={(val) => { setOpenDeleteopup(val); setApproving(false); }}
            confirmDeleteData={() => { setApproving(true); changedStatus(statusChangedID, "Approved") }}
            loading={approving}
            loadingText="Approving"
        />
        <DeletePopup
            descText="Are you sure to Rejected?"
            confirmBtnText="Yes"
            show={openRejectedPopup}
            setShow={(val) => { setOpenRejectedPopup(val); setRejecting(false) }}
            confirmDeleteData={() => { setRejecting(true); changedStatus(statusChangedID, "Rejected") }}
            loading={rejecting}
            loadingText="Rejecting"
        />
        <ToastContainer className={"text-12 font-poppins font-normal"} />
    </div>
}
export default MemberReportApproal;