import { FC, useEffect, useMemo, useState, useCallback } from "react";
import DebounceSearchInput from "../../components/Tables/DebounceSearchInput";
import { useNavigate } from "react-router-dom";
import { SearchIcon } from "../../components/common/Icons";
import classNames from "classnames";
import Button from "../../components/common/Button";
import { checkedIsNew } from "../../app/services/employee";
import { baseObj } from "../../utils/constants";
import {
  ColumnFiltersState,
  PaginationState,
  SortingState,
} from "@tanstack/react-table";
import { useSelector } from "react-redux";
import { templateList } from "../../active-time-report/views/ActiveTimeReport";
import { useSpring, animated } from "react-spring";
import { useGetByModuleTemplateQuery } from "../../app/services/template";
import { hideAllNoneField } from "../../components/Tables/TablePaginator";
import { getAuthUser } from "../../app/services/dashboard";
import { ToastContainer, toast } from "react-toastify";
import ErrorPopup from "../../components/ErrorPopup/ErrorPopup";
import Claim2Component from "../../components/ClaimType/ClaimType2Component";
import ClaimTypeTable from "../../components/ClaimType/ClaimTypeTable";
import { RootState } from "../../app/store";
import ProjectCheckerTable from "../../components/ProjectChecker/ProjectCheckerTable";
import ProjectChecker2Component from "../../components/ProjectChecker/ProjectChecker2Component";
import { TaskCategory, useGetProgressCheckerFilterDataQuery, useGetProgressCheckerQuery } from "../../app/services/progress-checker";
import ExportUsersModal from "../../components/BackendUsers/ExportUsersTable";
import axios from "../../axios";
import { endpointUrl } from "../../app/services/api";

export interface IOTData {
  id: number;
  employeeID: string;
  employeeName: string;
  department: string;
  date: string;
  fullHalfDay: string;
  timeIn: string;
  timeOut: string;
  otAmt: string;
}

interface IOT { }

export interface IDepartment {
  id: number;
  name: string;
  label: string;
}

const defaultFields = [
  "project_id",
  "project_name",
  "stage",
  "team_leader_name",
  "project_manager_name",
  "employees",
  "time_spent",
  "remark",
  "start_date",
  "launch_date",
  "actions",
];

const claimTypeDataList: any = [
  {
    id: 0,
    name: "All",
    label: "All",
  },
  {
    id: 1,
    name: "by Year",
    label: "year",
  },
  {
    id: 2,
    name: "by Month",
    label: "month",
  },
  ,
  {
    id: 3,
    name: "by Week",
    label: "week",
  },
  {
    id: 4,
    name: "by Day",
    label: "day",
  },
];

export type TStatusListData = {
  id: number | string;
  name: string;
  label?: string;
};

const statusListData: TStatusListData[] = [
  {
    id: "All",
    name: "All",
  },
  {
    id: 1,
    name: "Active",
  },
  {
    id: 0,
    name: "InActive",
  },
];
const limitedData = [
  {
    id: "All",
    name: "All",
  },
  {
    id: 1,
    name: "Limited",
  },
  {
    id: 0,
    name: "Unlimited",
  },
];

const dymmyData = [
  {
    id: 1,
    project_id: "MWETWBGUI12372",
    project_name: "Mario",
    stage: [
      {
        id: 1,
        name: "UIUX",
      },
      {
        id: 1,
        name: "Frontend",
      },
      {
        id: 1,
        name: "Backend",
      },
    ],
    project_lead: "Sandy MY00987",
    project_manager: "Phylllis MY78",
    time_spent: "23hr 50m",
    remark: "",
  },
  {
    id: 2,
    project_id: "MWETWBGUI12372",
    project_name: "Mario",
    stage: [
      {
        id: 1,
        name: "UIUX",
      },
      {
        id: 1,
        name: "Frontend",
      },
      {
        id: 1,
        name: "Backend",
      },
    ],
    project_lead: "Sandy MY00987",
    project_manager: "Phylllis MY78",
    time_spent: "23hr 50m",
    remark: "",
  },
  {
    id: 3,
    project_id: "MWETWBGUI12372",
    project_name: "Mario",
    stage: [
      {
        id: 1,
        name: "UIUX",
      },
      {
        id: 1,
        name: "Frontend",
      },
      {
        id: 1,
        name: "Backend",
      },
    ],
    project_lead: "Sandy MY00987",
    project_manager: "Phylllis MY78",
    time_spent: "23hr 50m",
    remark: "",
  },
  {
    id: 4,
    project_id: "MWETWBGUI12372",
    project_name: "Mario",
    stage: [
      {
        id: 1,
        name: "UIUX",
      },
      {
        id: 1,
        name: "Frontend",
      },
      {
        id: 1,
        name: "Backend",
      },
    ],
    project_lead: "Sandy MY00987",
    project_manager: "Phylllis MY78",
    time_spent: "23hr 50m",
    remark: "",
  },
  {
    id: 5,
    project_id: "MWETWBGUI12372",
    project_name: "Mario",
    stage: [
      {
        id: 1,
        name: "UIUX",
      },
      {
        id: 1,
        name: "Frontend",
      },
      {
        id: 1,
        name: "Backend",
      },
    ],
    project_lead: "Sandy MY00987",
    project_manager: "Phylllis MY78",
    time_spent: "23hr 50m",
    remark: "",
  },
  {
    id: 6,
    project_id: "MWETWBGUI12372",
    project_name: "Mario",
    stage: [
      {
        id: 1,
        name: "UIUX",
      },
      {
        id: 1,
        name: "Frontend",
      },
      {
        id: 1,
        name: "Backend",
      },
    ],
    project_lead: "Sandy MY00987",
    project_manager: "Phylllis MY78",
    time_spent: "23hr 50m",
    remark: "",
  },
  {
    id: 7,
    project_id: "MWETWBGUI12372",
    project_name: "Mario",
    stage: [
      {
        id: 1,
        name: "UIUX",
      },
      {
        id: 1,
        name: "Frontend",
      },
      {
        id: 1,
        name: "Backend",
      },
    ],
    project_lead: "Sandy MY00987",
    project_manager: "Phylllis MY78",
    time_spent: "23hr 50m",
    remark: "",
  },
  {
    id: 8,
    project_id: "MWETWBGUI12372",
    project_name: "Mario",
    stage: [
      {
        id: 1,
        name: "UIUX",
      },
      {
        id: 1,
        name: "Frontend",
      },
      {
        id: 1,
        name: "Backend",
      },
    ],
    project_lead: "Sandy MY00987",
    project_manager: "Phylllis MY78",
    time_spent: "23hr 50m",
    remark: "",
  },
  {
    id: 9,
    project_id: "MWETWBGUI12372",
    project_name: "Mario",
    stage: [
      {
        id: 1,
        name: "UIUX",
      },
      {
        id: 1,
        name: "Frontend",
      },
      {
        id: 1,
        name: "Backend",
      },
    ],
    project_lead: "Sandy MY00987",
    project_manager: "Phylllis MY78",
    time_spent: "23hr 50m",
    remark: "",
  },
  {
    id: 10,
    project_id: "MWETWBGUI12372",
    project_name: "Mario",
    stage: [
      {
        id: 1,
        name: "UIUX",
      },
      {
        id: 1,
        name: "Frontend",
      },
      {
        id: 1,
        name: "Backend",
      },
    ],
    project_lead: "Sandy MY00987",
    project_manager: "Phylllis MY78",
    time_spent: "23hr 50m",
    remark: "",
  },
];

export const addNewChecker = () => {
  return {
    id: 0,
    project_id: "",
    project_name: "",
    stage: [
      {
        id: 1,
        name: "UIUX",
      },
      {
        id: 1,
        name: "Frontend",
      },
      {
        id: 1,
        name: "Backend",
      },
    ],
    project_lead: "",
    project_manager: "",
    time_spent: "",
    remark: "",
    isNew: true,
  };
};

const ProjectChecker: FC<IOT> = () => {
  const navigate = useNavigate();
  const { alerts } = useSelector((state: RootState) => state.alertClaim);

  const [globalSearch, setGlobalSearch] = useState<string>("");
  const [itemCount, setItemCount] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [department, setDepartment] = useState<string | number>("All");
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  });
  const [sorting, setSorting] = useState<SortingState>([]);
  const [otStatus, setOtStatus] = useState<any>("All");
  const [limitStatus, setLimitStatus] = useState<any>("All");
  const [message, setMessage] = useState<any>([]);
  // const [limit, setLimit] = useState<number>(10);
  const [currentView, setCurrentView] = useState<any>(0);
  const [currentViewData, setCurrentViewData] = useState<number>(0);
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  let [localModuleId, setLocalModuleId] = useState<number>(0);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [departData, setDepartData] =
    useState<IDepartment[]>(claimTypeDataList);
  const [hiddenFields, setHiddenFields] = useState<string[]>([]);
  const [tempList, setTempList] = useState<any>(templateList);
  const [viewCols, setCols] = useState<any>([]);
  const [visArray, setVisArray] = useState<any>({});
  const [moduleList, setModuleList] = useState<any>([]);
  const [data, setData] = useState<baseObj[]>([]);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [isTemplate, setIsTemplate] = useState<boolean>(false);
  const [errorPopup, setErrorPopup] = useState<boolean>(false);
  const [isEditing, setIsEdit] = useState<boolean>(false);
  const [successPopup, setSuccessPopup] = useState<boolean>(false);
  const [isExportModalOpen, setIsExportModalOpen] = useState<boolean>(false);
  const [isExporting, setIsExporting] = useState<boolean>(false);
  const [isLayoutControlOpen, setIsLayoutControlOpen] =
    useState<boolean>(false);
  const [skip, setSkip] = useState<boolean>(false);
  // const [listSkip, setListSkip] = useState<boolean>(false);
  const [taskID, setTaskID] = useState<any>([]);
  var disabledData = checkedIsNew(data);
  const { data: templateData } = useGetByModuleTemplateQuery(localModuleId);
  const [stageData, setStageData] = useState<TaskCategory[]>();
  const [totalTime, setTotalTime] = useState<string>();
  const { data: progressChecker } = useGetProgressCheckerQuery({
    page: currentPage,
    limit: pagination.pageSize,
    search: globalSearch,
    sort: sorting.map((s) => `${s.desc ? "-" : ""}${s.id == "project_id" ? "project_code" : s.id}`).join(","),
    task_category_id: taskID
  });
  const { data: progressCheckerFilter } = useGetProgressCheckerFilterDataQuery();
  useEffect(() => {
    if (progressChecker) {
      setData(progressChecker?.progress_checker_list?.data);
      setItemCount(progressChecker?.progress_checker_list?.total);
      setTotalTime(progressChecker?.total_time);
      setLocalModuleId(progressChecker?.module_id)
    }
  }, [progressChecker])
  useEffect(() => {
    if (progressCheckerFilter) {
      setStageData(progressCheckerFilter?.task_categories)
    }
  }, [progressCheckerFilter])
  const deleteTemplateData = (id: string) => {
    var filterTempList = templateList.filter((x) => x.id !== parseInt(id));
    setTempList(filterTempList);
  };

  var getUser = getAuthUser();
  const getAuthPermis: string[] = useMemo(() => [], []);
  if (getUser?.permissions) {
    getUser?.permissions?.forEach((per: any) => {
      getAuthPermis.push(per?.name);
    });
  }

  const props = useSpring({
    to: { opacity: 1 },
    from: { opacity: 0 },
    delay: 1000,
  });

  const changePage = (pageNo: number) => { };

  useEffect(() => {
    if (templateData) {
      var updatedTemplate = templateData?.templates.map((x: any, i) => {
        return { ...x, index: i + 1 };
      });
      var defaultTemplate = {
        id: -1,
        index: 0,
        module: 0,
        name: "All",
      };
      setModuleList([defaultTemplate, ...updatedTemplate]);
    }
  }, [templateData]);

  useEffect(() => {
    if (templateList) {
      setTempList(templateList);
    }
    var viewData = templateData?.templates.find(
      (x) => x.id.toString() == currentView.toString()
    );

    setHiddenFields(viewData ? [...viewData.viewColumns] : defaultFields);
    hideAllNoneField();
  }, [templateList, currentView]);

  useEffect(() => {
    if (viewCols?.view_columns?.length > 0) {
      setHiddenFields(viewCols?.view_columns);
    }
  }, [viewCols?.view_columns]);

  useEffect(() => {
    if (moduleList) {
      var lastesTemplate = moduleList[moduleList?.length - 1];
      setCurrentViewData(lastesTemplate ? Number(lastesTemplate?.id) : 0);
    }
  }, [moduleList]);

  useEffect(() => {
    setCurrentPage(1);
  }, [
    taskID,
    pagination.pageSize,
    currentView,
    globalSearch,
  ]);

  useEffect(() => {
    if (alerts[0]?.type) {
      toast(`${alerts[0]?.message}`, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        type: "success",
      });
    }
  }, [alerts[0]?.type]);
  const exportProjectCheckerData = (format: string, isChecked: string) => {
    axios({
      url:
        endpointUrl +
        `progress-checker-lists?limit=${pagination.pageSize}&page=${currentPage}&search=${globalSearch}&
        task_category_id=${taskID}&format=${format}&export=true&only_this_page=${isChecked}`,
      method: "GET",
      responseType: "blob",
      headers: {
        Authorization: `Bearer ${getUser.token}`,
        Accept: "application/json",
      },
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          "progresschecker." + (format === "excel" ? "xlsx" : format)
        );
        document.body.appendChild(link);
        link.click();
        setIsExporting(false);
        setIsExportModalOpen(false);
      })
      .catch((error: any) => {
        setErrorPopup(true);
        setMessage(
          error?.message
            ? [error?.message]
            : ["Something wrong!. Trying again."]
        );
        setIsExporting(false);
      });
  }
  return (
    <div>
      <ExportUsersModal
        isOpen={isExportModalOpen}
        setIsOpen={setIsExportModalOpen}
        exportToCSV={exportProjectCheckerData}
        exportToExcel={exportProjectCheckerData}
        exportToPDF={exportProjectCheckerData}
        isExporting={isExporting}
        setIsExporting={setIsExporting}
        isShowOnlyCheckbox={true}
      // exportToZip={exportProjectData}
      />
      <ToastContainer className={"text-12 font-poppins font-normal"} />
      <ErrorPopup
        descText="Error Message!"
        confirmBtnText="Yes, Close."
        show={errorPopup}
        setShow={setErrorPopup}
        message={message}
        setMessage={setMessage}
      />
      <div className="px-2 sm:px-4 py-4 bg-white sm:mt-[25px] mt-0 font-poppins text-13 font-semibold text-[#858795] tracking-[-0.03rem] w-full h-full rounded-xl pt-3 pb-8 sm:pt-3 sm:pb-9">
        <div className="flex flex-wrap justify-between items-center">

          {/* <div className="flex w-full sm:w-auto items-center justify-between md:justify-end lg:justify-end">
            {true ? (
              <Button
                disabled={disabledData?.disabled}
                label={<span className="flex items-center">+ Add New</span>}
                onClick={() => {
                  setIsSuccess(false);
                  setPagination((prev) => ({ ...prev, pageIndex: 0 }));
                  // handleAddNew();
                  setData((prev) => [addNewChecker(), ...prev]);
                }}
                type="button"
                variant="primary"
                customClass={
                  disabledData?.disabledClass +
                  " py-[3px] my-1 px-2 focus:outline-none hover:bg-vorpmyanmar hover:border-vorpmyanmar hover:text-vorpblue 2xl:text-[14px] text-12 leading-5 font-medium text-white"
                }
                size="base"
              />
            ) : null}
          </div> */}
        </div>

        <ProjectChecker2Component
          setGlobalSearch={setGlobalSearch}
          filterOneName="Stage"
          // filterTwoName="TimeSpent"
          // department={department}
          // setDepartment={setDepartment}
          setPagination={setPagination}
          pagination={pagination}
          // departData={departData}
          // startDate={startDate}
          // setStartDate={setStartDate}
          // endDate={endDate}
          // setEndDate={setEndDate}
          // setLimit={setLimit}
          setIsTemplate={setIsTemplate}
          isTemplate={isTemplate}
          currentViewData={currentViewData}
          currentView={currentView}
          setCurrentView={setCurrentView}
          moduleList={moduleList}
          deleteTemplateData={deleteTemplateData}
          setErrorPopup={setErrorPopup}
          setSuccessPopup={setSuccessPopup}
          setMessage={setMessage}
          setIsLayoutControlOpen={setIsLayoutControlOpen}
          otStatus={taskID}
          setOtStatus={setTaskID}
          statusListData={stageData ? stageData.map((stage: TaskCategory) => {
            return {
              id: stage.id,
              name: stage.name
            }
          }) : []}
          // limitStatus={limitStatus}
          // setLimitStatus={setLimitStatus}
          // limitedData={limitedData}
          // isExportModalOpen={isExportModalOpen}
          setIsExportModalOpen={setIsExportModalOpen}
          setIsExporting={setIsExporting}
        />

        <animated.div style={props}>
          <ProjectCheckerTable
            data={data}
            setData={setData}
            setIsSuccess={setIsSuccess}
            isSuccess={isSuccess}
            pagination={pagination}
            setPagination={setPagination}
            isLayoutControlOpen={isLayoutControlOpen}
            setIsLayoutControlOpen={setIsLayoutControlOpen}
            globalSearch={globalSearch}
            setGlobalSearch={setGlobalSearch}
            columnFilters={columnFilters}
            setColumnFilters={setColumnFilters}
            setHiddenFields={setHiddenFields}
            hiddenFields={hiddenFields ? hiddenFields : []}
            templateList={tempList}
            setCurrentView={setCurrentView}
            setIsEdit={setIsEdit}
            isEditing={isEditing}
            localModuleId={localModuleId}
            total={itemCount}
            changePage={changePage}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            setIsTemplate={setIsTemplate}
            setCols={setCols}
            visArray={visArray}
            setSkip={setSkip}
            sorting={sorting}
            setSorting={setSorting}
            totalTime={totalTime}
          />
        </animated.div>
      </div>
    </div>
  );
};
export default ProjectChecker;
