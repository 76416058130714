import { ReactElement } from "react";
import DocumentsList from "./views/EmployeeDocumentList";
import BasicLayout from "../layouts/BasicLayout";
import { Outlet } from "react-router-dom";
import DocumentViews from "../components/DocumentView/DocumentView";

export const documentRoutes = [
  { id: 1, path: "", component: <DocumentsList /> },
  { id: 2, path: "views", component: <DocumentViews /> },
];

const Documents = (props: any): ReactElement => {
  const data = props.loginData;
  const permissions = props.permissions;

  return (
    <BasicLayout userData={data} permissions={permissions}>
      <Outlet />
    </BasicLayout>
  );
};

export default Documents;
