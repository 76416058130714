import { ReactElement } from 'react';
import BasicLayout from '../layouts/BasicLayout';
import { Outlet } from 'react-router-dom';
import TaskList from './view/TaskList';

const TaskListRoute = (props:any): ReactElement => {
  const data=props.loginData;

  const permissions = props.permissions;

 return (
    <BasicLayout userData={data} permissions={permissions}>
      <TaskList/>
      <Outlet />
    </BasicLayout>
  )
};

export default TaskListRoute;
