import { Dispatch, FC, SetStateAction, useState, useEffect, memo } from "react";
import { CircularProgress, Modal } from "@mui/material";
import whiteCancel from "../common/img/white-cancel.svg";
import DOMPurify from "dompurify";
import classNames from "classnames";
import { CloseIcon, SendIcon } from "../common/Icons";
import CustomDatePicker from "../common/BaseDatePicker";
import BaseRadioButton from "../common/BaseRadioButton";
import { StringDropdownList } from "../common/DropdownList";
import Button from "../common/Button";
import { IDepartment } from "../../ot-list/views/OTList";
import styles from "./OTListTable.module.scss";
import { renderDateFormatYMD } from "../DashboardCalendarView/DashboardAddEventPopup";
import { baseObj } from "../../utils/constants";
import DebounceSearchInput from "../Tables/DebounceSearchInput";
import { StringDropdownListWithSearch } from "../common/DropdownListWithSearch";

interface IOTModal {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  totalLength: number;
  departments: IDepartment[];
  assignUserListData: baseObj[];
  setDept: Dispatch<SetStateAction<string>>;
  dept: string;
  setEmpCode: Dispatch<SetStateAction<string>>;
  setEmployeeData: Dispatch<SetStateAction<any>>;
  employeeData: any;
  uploading: boolean;
  editData: any;
  updateData: (data: any) => void;
  setSkip: Dispatch<SetStateAction<boolean>>;
  setUploading: Dispatch<SetStateAction<boolean>>;
  setEditOTData: Dispatch<SetStateAction<any>>;
}

interface IFilterStates {
  tag: string;
}

const otTypeList = [
  {
    id: 1,
    name: "OverTime Pay",
  },
  {
    id: 2,
    name: "Substitution Leave",
  },
];

const OTUpdatePopup: FC<IOTModal> = ({
  isOpen,
  setIsOpen,
  totalLength,
  departments,
  assignUserListData,
  setDept,
  dept,
  setEmpCode,
  setEmployeeData,
  employeeData,
  uploading,
  editData,
  updateData,
  setSkip,
  setUploading,
  setEditOTData,
}) => {
  const [fromDate, setFromDate] = useState<Date | undefined>(new Date());
  const [toDate, setToDate] = useState<Date | undefined>(new Date());
  const [leaveDuration, setLeaveDuration] = useState<string>("Full");
  const [userList, setUserList] = useState<baseObj[]>([]);
  const [totalDay, setTotalDay] = useState<number>(0);
  const [typeOT, setTypeOT] = useState<any[]>([]);
  const [otType, setOTType] = useState<any>("");
  const [otTypeArr, setOTTypeArr] = useState<any[]>(otTypeList);
  const [reason, setReason] = useState<string>("");
  const [selectedMember, setSelectedMember] = useState<string>("");
  const clearData = () => {
    setEmployeeData('');
    setFromDate(new Date());
    setToDate(new Date());
    setLeaveDuration('Full');
    setOTType('');
    setDept('');
  }
  useEffect(() => {
    clearData();
    if (editData) {
      setReason(editData ? editData.reason : "");
      setLeaveDuration(editData.period ?? "");
      const dataOType = otTypeList.find((item) => item.name == editData.type);
      setOTType(dataOType?.id);
      setFromDate(
        editData.from_date ? new Date(editData.from_date) : new Date()
      );
      setToDate(editData.from_date ? new Date(editData.to_date) : new Date());
    }
  }, [editData]);

  useEffect(() => {
    setToDate(fromDate);
  }, [fromDate]);

  useEffect(() => {
    setToDate(toDate);
  }, [toDate]);

  useEffect(() => {
    var diff = 0;
    var startDate: any = fromDate?.getTime();
    var endDate: any = toDate?.getTime();

    if (endDate && startDate) {
      diff = endDate - startDate;
    }
    if (toDate == undefined) {
      setToDate(fromDate);
      diff = 1;
    }

    if (startDate != undefined && endDate != undefined) {
      if (leaveDuration) {
        if (leaveDuration.includes("Half")) {
          diff = diff / (1000 * 3600 * 24) / 2;
          if (startDate === endDate) {
            diff = 0.5;
          }
          if (endDate > startDate) {
            diff += 0.5;
          }
        } else {
          diff = diff / (1000 * 3600 * 24);
          if (startDate === endDate) {
            diff = 1;
          }
          if (endDate > startDate) {
            diff += 1;
          }
        }
        setTotalDay(Math.ceil(diff));
      }
    }
  }, [toDate, fromDate, leaveDuration]);

  useEffect(() => {
    const data = otTypeArr.filter((f: any) => f.id == otType);
    setTypeOT(data);
  }, [otType]);

  useEffect(() => {
    if (assignUserListData) {
      setUserList(assignUserListData);
    }
  }, [assignUserListData]);

  const saveOTData = () => {
    const clean_reason = reason ? DOMPurify.sanitize(reason) : "";
    var data = {
      from_date: renderDateFormatYMD(fromDate ? fromDate : new Date(), "-"),
      to_date: renderDateFormatYMD(toDate ? toDate : new Date(), "-"),
      period: leaveDuration,
      type: typeOT[0] ? typeOT[0].name : "",
      reason: clean_reason ?? editData.reason,
      department_id: dept,
    };

    updateData(data);   
  };
  useEffect(()=>{
    if(uploading==false){
      clearData();
    }
    
  },[uploading])

  return (
    <Modal
      open={isOpen}
      style={{
        background: "rgb(0 0 0 / 0.3)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      onClose={(event, reason) => {
        if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
          setIsOpen(false);
          setSkip(false);
          setUploading(false);
          setEditOTData({});
        }
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableEnforceFocus={true}
    >
      <div className="modalContent lg:w-[1000px] max-w-[90%] annoucement-modal overflow-y-auto max-h-[100%] w-full">
        <div
          id="modal-modal-title"
          className="bg-vorpblue relative flex justify-between py-6 sm:px-10 px-5 rounded-t-xl"
        >
          <h3 className="text-18 font-primary font-semibold text-white">
            {`Updated OT List (#${editData.id ?? totalLength})`}
          </h3>
          <button
            className="flex items-center justify-center"
            onClick={() => {
              setIsOpen(false);
            }}
          >
            <img src={whiteCancel} alt="close modal icon" />
          </button>
        </div>

        <div
          id="modal-modal-description"
          className="py-9 px-5 bg-white rounded-b-xl"
        >
          <div className="md:flex">
            <div
              className={classNames(
                styles.verticalLine,
                "md:w-1/2 w-full sm:pl-5"
              )}
            >
              <div className="w-full">
                {/* <div className="mb-5">
                  <label className="text-black2 text-15 font-medium font-primary mb-[0.688rem] block">
                    ID
                  </label>
                  <p className="w-full rounded py-3.5 px-3 border border-goalinputborder text-black2 text-14 font-medium font-primary placeholder:text-graydark h-[48px]">
                    #{editData.id ?? totalLength}
                  </p>
                </div> */}
                <div className="mb-5">
                  <h4 className="xl:text-15 text-12 leading-[22px] font-medium text-black2 mb-2 w-full">
                    OT from date
                  </h4>
                  <CustomDatePicker
                    date={fromDate}
                    setDate={setFromDate}
                    //minDate={new Date()}
                    isFilterSection={false}
                    placeholder={new Date().toDateString()}
                    iconColor="#858795"
                  />
                </div>
                <div className="mb-5">
                  <h4 className="xl:text-15 text-12 leading-[22px] font-medium text-black2 mb-2 w-full">
                    OT to date
                  </h4>
                  <CustomDatePicker
                    date={toDate}
                    minDate={fromDate != undefined ? fromDate : new Date()}
                    setDate={setToDate}
                    isFilterSection={false}
                    placeholder={
                      toDate != undefined
                        ? new Date(toDate).toDateString()
                        : new Date(fromDate ?? new Date()).toDateString()
                    }
                    iconColor="#858795"
                  />
                </div>
                <div className="flex items-center">
                  <BaseRadioButton
                    state={leaveDuration}
                    setState={setLeaveDuration}
                    label="Full"
                    value="Full"
                    id="full"
                    className="mr-4 sm:mr-8"
                    fontSize="xl:text-15 text-12"
                    name="leave-duration"
                  />
                  <BaseRadioButton
                    state={leaveDuration}
                    setState={setLeaveDuration}
                    label="Half(AM)"
                    value="Half(AM)"
                    id="half-am"
                    className="mr-4 sm:mr-8"
                    fontSize="xl:text-15 text-12"
                    name="leave-duration"
                  />
                  <BaseRadioButton
                    state={leaveDuration}
                    setState={setLeaveDuration}
                    label="Half(PM)"
                    value="Half(PM)"
                    id="half-pm"
                    fontSize="xl:text-15 text-12"
                    name="leave-duration"
                  />
                </div>
                {/* {leaveDuration && (
                  <p className="text-graydark pt-[25px]">
                    <span className="font-medium leading-[22px] xl:text-15 text-12">
                      Leave applied for:
                    </span>{" "}
                    <span className="xl:text-15 text-12 leading-5">
                      {totalDay} day
                    </span>
                  </p>
                )} */}
                <div className="pt-[26px]">
                  <h4 className="xl:text-15 text-12 leading-[22px] font-medium text-black2 mb-2 w-full">
                    OT Type
                  </h4>
                  <StringDropdownList
                    state={String(otType)}
                    setState={setOTType}
                    placeholder="Select OT Pay Type"
                    className="w-full py-[12px] pl-3 mb-3 text-14"
                    dropdownItems={otTypeArr?.map((status: any) => ({
                      value: status.id,
                      label: status.name,
                    }))}
                    textAlign={"text-left"}
                    fontSize="xl:text-15 text-12"
                  />

                  <textarea
                    onChange={(ev) => setReason(ev.target.value)}
                    onBlur={(ev) => setReason(ev.target.value)}
                    rows={5}
                    className="placeholder:text-vorpgraylight border border-vorpgraylight w-full rounded-sm focus:outline-none p-3 xl:text-15 text-12 leading-5 text-graydark resize-none mb-5"
                    placeholder="Reason of OT"
                    // defaultValue={editData?.reason ?? ""}
                    value={reason}
                  ></textarea>
                </div>
              </div>
            </div>
            <div className="md:w-1/2 w-full sm:pl-5">
              <div className={`mb-5 ${isOpen ? " pointer-events-none opacity-50" : ""}`}>
                <label className="text-black2 text-15 font-medium font-primary mb-[0.688rem] block">
                  Department
                </label>
                <StringDropdownList
                  setSkip={setSkip}
                  state={String(dept)} //
                  setState={setDept}
                  placeholder="Select Department"
                  className="w-full py-[12px] pl-3 mb-3 text-14"
                  dropdownItems={departments?.map((status: any) => ({
                    value: status.id,
                    label: status.name,
                  }))}
                  textAlign={"text-left"}
                  fontSize="xl:text-15 text-12"
                />
              </div>
              <div className={`mb-5 ${isOpen ? " pointer-events-none opacity-50" : ""}`}>
                <label className="text-black2 text-15 font-medium font-primary mb-[0.688rem] block">
                  Employee
                </label>
                <div className="flex">
                  <div className="w-full">
                    <div
                      className={classNames(
                        isOpen ? " pointer-events-none opacity-50" : "",
                        "mb-5"
                      )}
                    >
                      <StringDropdownListWithSearch
                        setSearchData={setSelectedMember}
                        state={employeeData}
                        setState={setEmployeeData}
                        dropdownItems={userList?.map((member) => {
                          return {
                            label: member.employee_name,
                            value: member.employee_id,
                          };
                        })}
                        className={classNames(
                          styles.memberDropdownList,
                          "w-full min-w-[100px] 2xl:min-w-[128px]  xl:text-sm text-xs"
                        )}
                        placeholder="Select Employee"
                        textAlign="text-left py-2 pl-2 pr-3"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-5 last:mb-0 flex items-center sm:px-5 ">
            <Button
              type="button"
              label={
                <span className="flex items-center text-12 leading-[18px] relative justify-between">
                  {uploading ? (
                    <div
                      className={classNames(
                        uploading ? "block" : "hidden",
                        " absolute top-[55%] left-1/2 -translate-x-1/2 -translate-y-1/2"
                      )}
                    >
                      <CircularProgress />
                    </div>
                  ) : (
                    <SendIcon className="mr-[10px] group-hover:opacity-0 transition-all duration-300" />
                  )}
                  {uploading ? "Uploading" : "Submit"}
                </span>
              }
              onClick={() => {
                saveOTData();
                setSkip(false);
              }}
              variant="no-outline"
              size="lg"
              customClass={classNames(
                uploading ? "pointer-events-none opacity-50 " : "",
                "hover:bg-vorpblue mr-2"
              )}
            />
            <Button
              type="button"
              label={
                <span className="flex items-center text-12 leading-[18px] ">
                  <CloseIcon
                    width={14}
                    height={14}
                    className="mr-[10px] [&_path]:fill-graydark"
                  />
                  Cancel
                </span>
              }
              onClick={() => {
                setIsOpen(false);
                setUploading(false);
                setEditOTData({});
                clearData();
              }}
              variant="no-outline"
              size="lg"
              customClass="min-w-[112px] min-h-[43.6px] bg-offwhite text-graydark border-none"
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default memo(OTUpdatePopup);
