import { FilterFn, Row } from "@tanstack/react-table";

export const copyToClipboard = (text: string) => {
  if (navigator?.clipboard?.writeText) {
    navigator.clipboard.writeText(text);
  }
};

export const dateRangeFilterFn: FilterFn<any> = (
  { getValue },
  columnId,
  filterState
) => {
  if (filterState?.endDate && filterState?.startDate) {
    const currentDate = getValue(columnId) as string;
    const currentValue = new Date(currentDate?.split("T")[0]); // take only date part from iso string
    currentValue.setHours(0, 0, 0, 0); // time reset as we want to compare only date

    const minDate = filterState.startDate;
    minDate.setHours(0, 0, 0, 0);

    const maxDate = filterState.endDate;
    maxDate.setHours(0, 0, 0, 0);

    const isValid = currentValue <= maxDate && currentValue >= minDate;

    return isValid;
  } else {
    return true;
  }
};

export const getIndicator = (value: number, afterText: string) => {
  const lastDigit = Number(value).toString().split("").pop();

  let fullText = "";
  switch (lastDigit) {
    case "3":
      fullText += `${value}rd`;
      break;
    case "2":
      fullText += `${value}nd`;
      break;
    default:
      fullText += `${value}th`;
      break;
  }
  return fullText + " " + afterText;
};

export const customGlobalFilterFunction = (
  row: Row<unknown>,
  columnId: string,
  filterValue: string
) => {
  const safeValue = (() => {
    const value = row.getValue(columnId);
    return typeof value === "number" ? String(value) : (value as string);
  })();

  return safeValue?.toLowerCase().includes(filterValue.toLowerCase());
};

export function formatMoney(amount: number, currency: string): string {
  if (typeof amount !== "number") {
    throw new Error("formatMoney requires a numeric argument");
  }
  const [integerPart, decimalPart] = amount.toFixed(2).split(".");
  const formattedIntegerPart = integerPart.replace(
    /\B(?=(\d{3})+(?!\d))/g,
    ","
  );

  //   console.log("cc", (currency && `${currency} `) +
  //   (decimalPart
  //     ? `${formattedIntegerPart}.${decimalPart}`
  //     : formattedIntegerPart)
  // );

  return (
    (currency && `${currency} `) +
    (decimalPart
      ? `${formattedIntegerPart}.${decimalPart}`
      : formattedIntegerPart)
  );
}

export function truncateFilename(filename: string, maxLength: number): string {
  if (filename.length <= maxLength) {
    return filename;
  }

  const extensionIndex = filename.lastIndexOf(".");
  const extension = filename.substring(extensionIndex);
  const truncatedName = filename.substring(0, maxLength - extension.length - 3);

  return truncatedName + "..." + extension;
}

// Format (eg. 30 Apr 2023)
export function formatDate(date: Date, format = ""): string {
  switch (format) {
    case "dd-mm-yyyy":
      // console.log("YYYY", `${date.getDate()}-${String(date.getMonth() + 1).padStart(2, "0")}-${date.getFullYear()}`);

      // console.log("YYYYfff", [padTo2Digits(date.getDate()),
      // padTo2Digits(date.getMonth() + 1),
      // date.getFullYear(),
      // ].join('-'));

      return `${date.getDate()}-${String(date.getMonth() + 1).padStart(
        2,
        "0"
      )}-${date.getFullYear()}`;

    case "yyyy-dd-mm HH:mm:ss":
      return `${date.getFullYear()}-${date.getDate()}-${String(
        date.getMonth() + 1
      ).padStart(2, "0")} ${date.toLocaleTimeString("fr")}`;
    default:
      const options: Intl.DateTimeFormatOptions = {
        day: "numeric",
        month: "short",
        year: "numeric",
      };
      return new Intl.DateTimeFormat("en-US", options).format(date);
  }
}

export function formatDateData(date: Date | undefined) {
  const finalDate = [
    padTo2Digits(date?.getDate()),
    padTo2Digits(date ? date?.getMonth() + 1 : ""),
    date?.getFullYear(),
  ].join("-");
  // console.log("abc", finalDate)

  return finalDate;
}

export function formatDateDataKey(date: Date | undefined) {
  const finalDate = [
    date?.getFullYear(),
    padTo2Digits(date ? date?.getMonth() + 1 : ""),
    padTo2Digits(date?.getDate()),
  ].join("-");

  // console.log("abc", finalDate)

  return finalDate;
}

function padTo2Digits(num: any) {
  return num.toString().padStart(2, "0");
}

export const convertDateToIsoString = (date?: Date | undefined) => {
  if (date) {
    const dateObj = new Date(date?.toLocaleDateString());
    return dateObj?.toISOString();
  }
  return;
};
