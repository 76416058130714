import {
  ColumnDef,
  ColumnFiltersState,
  PaginationState,
  RowSelectionState,
  SortingState,
  createColumnHelper,
} from "@tanstack/react-table";
import React, {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from "react";
import EditableCell from "../Tables/EditableCell";
import { baseObj } from "../../utils/constants";
import classNames from "classnames";
import {
  DuplicateIcon,
  EditIcon,
  TrashIcon,
} from "../common/Icons";
import styles from "./WorkingShiftTable.module.scss";
import LayoutAdjustableTable from "../Tables/LayoutAdjustableTable";
import { templateData } from "../../app/services/template";
import TablePaginator from "../Tables/TablePaginator";
import DeletePopup from "../DeletePopup/DeletePopup";
import { toast } from "react-toastify";
import ErrorPopup from "../ErrorPopup/ErrorPopup";
import { useDispatch, useSelector } from "react-redux";
// import {
//   useDeleteClaimTypeMutation,
//   useMultiDeleteClaimTypeMutation,
// } from "../../app/services/claim-type";
import { useNavigate } from "react-router-dom";
import { assignedColor } from "../Documents/EmployeeDocumentsTable";
import { useDeleteWorkingShiftMutation, useMultiDeleteWorkingShiftMutation } from "../../app/services/working-shift";
interface IOTTable {
  isLayoutControlOpen: boolean;
  isWorkingShift?: boolean;
  setIsLayoutControlOpen: Dispatch<SetStateAction<boolean>>;
  setIsWorkingShift: Dispatch<SetStateAction<boolean>>;
  pagination: PaginationState;
  setPagination: Dispatch<SetStateAction<PaginationState>>;
  globalSearch: string;
  setGlobalSearch: Dispatch<SetStateAction<string>>;
  columnFilters: ColumnFiltersState;
  setColumnFilters: Dispatch<SetStateAction<ColumnFiltersState>>;
  data: baseObj[];
  setData: Dispatch<SetStateAction<baseObj[]>>;
  hiddenFields: string[];
  templateList: templateData[];
  setHiddenFields: any;
  setCurrentView: any;
  total: number;
  changePage: any;
  setCurrentPage: any;
  currentPage: number;
  setEditData: any;
  setIsEdit: Dispatch<SetStateAction<boolean>>;
  setIsOpenPopup: Dispatch<SetStateAction<boolean>>;
  setIsDuplicate: Dispatch<SetStateAction<boolean>>;
  isEditing?: boolean;
  setIsTemplate?: Dispatch<SetStateAction<boolean>>;
  localModuleId: number;
  visArray: any;
  setCols?: any;
  setIsSuccess?: React.Dispatch<React.SetStateAction<boolean>>;
  isSuccess?: boolean;
  setSkip: React.Dispatch<React.SetStateAction<boolean>>;
  sorting: SortingState;
  setSorting: React.Dispatch<React.SetStateAction<SortingState>>;
  // manageClaim?: boolean;
}

const WorkingShiftTable: FC<IOTTable> = ({
  isLayoutControlOpen,
  setIsLayoutControlOpen,
  setIsWorkingShift,
  isWorkingShift,
  pagination,
  setPagination,
  globalSearch,
  setGlobalSearch,
  columnFilters,
  setColumnFilters,
  data,
  setData,
  hiddenFields,
  total,
  changePage,
  setCurrentPage,
  currentPage,
  setIsEdit,
  setIsOpenPopup,
  setIsDuplicate,
  setEditData,
  setIsTemplate,
  localModuleId,
  visArray,
  setCols,
  // setSkip,
  setSorting,
  sorting,
  // manageClaim,
}) => {
  const navigate = useNavigate();
  const columnHelper = useMemo(() => createColumnHelper<any>(), []);
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  const [deleteID, setDeleteID] = useState<number | 0>();
  const [deleteRowID, setDeleteRowID] = useState<number | 0>();
  const [openDeletePopup, setOpenDeleteopup] = useState<boolean>(false);
  const [clickLoading, setClickLoading] = useState<boolean>(false);
  const [itemCount, setItemCount] = useState<number>(total);
  const selectRowsCount = Object.keys(rowSelection).length;
  const [errorPopup, setErrorPopup] = useState<boolean>(false);
  const [message, setMessage] = useState<any>([]);
  // const [deleteClaimType] = useDeleteClaimTypeMutation();
  const [deleteWorkingShift] = useDeleteWorkingShiftMutation();
  const [deleteWorkingShiftMultiple] = useMultiDeleteWorkingShiftMutation();
  // const [deleteClaimListByIDs] = useMultiDeleteClaimTypeMutation();
  const dispatch = useDispatch();
  const stateData: any = useSelector(
    (state: any) => state?.dashboard?.notificationDetail
  );
  const [notifications, setNotifications] = useState<any>(stateData);

  const deleteOTData = useCallback(
    (id?: any, uniqueID?: number) => {
      setDeleteID(id);
      setDeleteRowID(uniqueID);
      setOpenDeleteopup(true);
    },
    [setData, rowSelection]
  );
  const deleteClaimItem = useCallback(
    (id?: number, dataId?: number) => {
      setClickLoading(true);
      const selectedRows = Object.keys(rowSelection);
      setData((prev: baseObj[]) => {
        if (dataId != undefined) {
          deleteWorkingShift(dataId)
            .unwrap()
            .then((payload: any) => {
              setClickLoading(false);
              setOpenDeleteopup(false);
              toast("Successfully Delete.", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                type: "success",
              });
            })
            .catch((error: any) => {
              setErrorPopup(true);
              setOpenDeleteopup(false);
              setClickLoading(false);
              setMessage(
                error
                  ? [error?.data.message]
                  : ["Something went wrong!Trying again."]
              );
            });
        }
        return prev.filter((_, index) => {
          return id !== undefined
            ? id !== index
            : !selectedRows.includes(String(index));
        });
      });
      id === undefined && setRowSelection({});
    },
    [setData, rowSelection]
  );

  const deleteClaimMultiple = (ids: number[]) => {
    setClickLoading(true);
    deleteWorkingShiftMultiple(ids)
      .unwrap()
      .then((payload: any) => {
        setClickLoading(false);
        setOpenDeleteopup(false);
        toast("Successfully Delete.", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          type: "success",
        });
      })
      .catch((error: any) => {
        setClickLoading(false);
        setErrorPopup(true);
        setOpenDeleteopup(false);
        setMessage(
          error ? [error?.data.message] : ["Something went wrong!Trying again."]
        );
      });
    setRowSelection({});
  };

  const confirmDeleteData = () => {
    var id = deleteID;
    var dataID = deleteRowID;
    const selectedRows = Object.keys(rowSelection);
    var selectionIds = data
      .filter((x, index: number) => {
        return selectedRows.find((s) => s == index.toString()) ? x.id : 0;
      })
      .map((x) => x.id);

    if (selectionIds.length > 0) {
      deleteClaimMultiple(selectionIds);
    } else {
      if (id) {
        deleteClaimItem(id, dataID);
        setDeleteID(-1);
        setDeleteRowID(-1);
      }
    }
  };

  const columns = useMemo(
    () => [
      columnHelper.display({
        header: "",
        id: "selection",
      }),
      columnHelper.accessor("code", {
        header: "Shift ID",
        id: "code",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const id = getValue();
          return (
            <div className=" text-left bg-white text-black2 px-[10px] font-normal">
              <p className="h-full flex items-center">{id}</p>
            </div>
          );
        },
      }),
      columnHelper.accessor("country", {
        header: "Region",
        id: "country",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const employeeID = getValue();
          return (
            <div className=" text-left bg-white text-black2 px-[10px] font-normal">
              <p className="h-full flex items-center">{employeeID?.name}</p>
            </div>
          );
        },
      }),
      columnHelper.accessor("workingLocations", {
        header: "Locations",
        id: "workingLocations",
        cell: ({ getValue, cell }) => {
          const names = getValue();
          return (
            <div className=" text-left bg-white text-black2 px-[10px] font-normal">
              <p className="h-full flex items-center">
                {names.map((name: any) => name.name)}
              </p>
            </div>
            // <div className=" text-left font-normal">
            //   <EditableCell
            //     setData={setData}
            //     value={name}
            //     type="text"
            //     cell={cell}
            //     customDesign="text"
            //   />
            // </div>
          );
        },
      }),

      columnHelper.accessor("name", {
        header: "Shift Name",
        id: "name",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const name = getValue();
          return (
            <div className=" min-w-[140px] text-left font-normal">
              <EditableCell
                setData={setData}
                value={name}
                type="text"
                cell={cell}
                customDesign="text"
              />
            </div>
          );
        },
      }),
      columnHelper.accessor("working_days", {
        header: "Working Days",
        id: "working_days",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const year = getValue();
          return (
            <div className=" text-left bg-white text-black2 px-[10px] font-normal">
              <p className="h-full flex items-center">{year}</p>
            </div>
          );
        },
      }),

      columnHelper.accessor("shift_timing", {
        header: "Shift Timings",
        id: "shift_timing",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const status = getValue();
          // let txtColor = "text-graydark";
          // let text = "";
          // if (status === 1) {
          //   text = "Active";
          //   txtColor = "text-green-800";
          // } else if (status === 0) {
          //   text = "Inactive";
          // }
          return (
            <div className=" text-left bg-white text-black2 px-[10px] font-normal">
              <p className="h-full flex items-center">{status}</p>
            </div>
            // <div className={`text-left font-normal ${txtColor}`}>
            //   <EditableCell
            //     setData={setData}
            //     value={text}
            //     type="text"
            //     cell={cell}
            //   />
            // </div>
          );
        },
      }),

      columnHelper.accessor("working_hours_per_week", {
        header: "Working Hours(Week)",
        id: "working_hours_per_week",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const year = getValue();
          return (
            <div className=" text-left bg-white text-black2 px-[10px] font-normal">
              <p className="h-full flex items-center">{year}</p>
            </div>
          );
        },
      }),
      columnHelper.accessor("employees", {
        header: "Assign To",
        id: "employees",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const assigned = getValue();
          return (
            <div className="font-normal flex justify-center items-center h-full min-w-[150px] ml-[10px]">
              {assigned?.length == 0 && (
                <div className="flex justify-center items-center">---</div>
              )}
              {assigned?.map((x: any, index: number) => {
                if (index < 3) {
                  var idx = index;
                  if (idx > 4) {
                    idx = 0;
                  }
                  return x.profileImage ? (
                    <img
                      key={index}
                      src={x.profileImage}
                      alt="profileImage"
                      className="w-[35px] h-[35px] rounded-full object-cover ml-[-10px]"
                    />
                  ) : (
                    <div
                      key={index}
                      className={classNames(
                        "bg-[" + assignedColor[idx] + "]",
                        "ml-[-10px] w-[35px] h-[35px] rounded-full text-14 flex justify-center items-center text-white"
                      )}
                    >
                      {x.name?.substring(0, 1)}
                    </div>
                  );
                }
              })}
              {assigned?.length > 3 ? (
                <div className="ml-[-10px] w-[35px] h-[35px] rounded-full text-10 flex justify-center items-center bg-black2 text-white">
                  {assigned?.length - 3}+
                </div>
              ) : (
                ""
              )}
            </div>
          );
        },
      }),
      columnHelper.accessor("remark", {
        header: "Remark",
        id: "remark",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const year = getValue();
          return (
            <div className=" text-left bg-white text-black2 px-[10px] font-normal">
              <p className="h-full flex items-center">{year}</p>
            </div>
          );
        },
      }),

      columnHelper.display({
        header: "Action",
        id: "actions",
        enableSorting: false,
        cell: ({ cell }) => {
          var isHaveNew = data?.find((x) => x.isNew == true);
          var disabledClass = isHaveNew ? "opacity-50 pointer-events-none" : "";

          return (
            <div
              className={classNames(
                styles.actionedBtn,
                "px-2 flex justify-center items-center min-w-max max-w-[98px]"
              )}
            >
              <button
                disabled={isHaveNew ? true : false}
                type="button"
                onClick={() => {
                  // setSkip(true);
                  // navigate(`/new-claim-type`, { state: cell.row.original });
                  // if (setIsEdit) {
                  //   setIsEdit(true);
                  // }
                  setIsOpenPopup(true);
                  setEditData(cell.row.original)
                  setIsDuplicate(true);
                }}
                className={classNames(
                  disabledClass,
                  " w-6 h-6 mr-2 flex justify-center items-center group rounded-md bg-vorpblue hover:bg-annhoverbg transition-all duration-300"
                )}
              >
                <DuplicateIcon />
              </button>

              <button
                disabled={isHaveNew ? true : false}
                type="button"
                onClick={() => {
                  // setSkip(true);
                  // navigate(`/new-claim-type`, { state: cell.row.original });
                  if (setIsEdit) {
                    setIsEdit(true);
                  }
                  setIsOpenPopup(true);
                  setEditData(cell.row.original)
                }}
                className={classNames(
                  disabledClass,
                  " w-6 h-6 mr-2 flex justify-center items-center group rounded-md bg-vorpblue hover:bg-annhoverbg transition-all duration-300"
                )}
              >
                <EditIcon />
              </button>
              <button
                type="button"
                onClick={() => {
                  deleteOTData(cell.row.id, cell.row.original.id);
                }}
                className={classNames(
                  disabledClass,
                  " w-6 h-6 flex justify-center items-center group rounded-md bg-redPink hover:bg-[#FFEAEF] transition-all duration-300"
                )}
              >
                <TrashIcon />
              </button>
            </div>
          );
        },
      }),
    ],
    [columnHelper, data, hiddenFields]
  );

  const visibleColumns: { [key: string]: boolean } = {};
  hiddenFields?.map((x) => {
    visibleColumns[x] = true;
  });

  document.querySelectorAll("th,td").forEach((x) => {
    if (x.id.includes("none")) {
      x.classList.add("hidden");
    }

    var div = document.getElementById(x.id);
  });

  if (true) {
    visibleColumns["actions"] = true;
  } else {
    visibleColumns["actions"] = false;
  }

  if (hiddenFields.length > 0) {
    columns.map((x) => {
      if (x.id == "actions" || x.id == "selection" || x.id == "lasted") {
        visibleColumns[x.id ? x.id : ""] = true;
      } else if (x.id == "uniqeID") {
        visibleColumns[x.id ? x.id : ""] = false;
      } else {
        var isHidden = hiddenFields.find((h) => h == x.id);
        if (isHidden) {
          visibleColumns[x.id ? x.id : ""] = true;
        } else {
          visibleColumns[x.id ? x.id : ""] = false;
        }
      }
    });
  }

  const [columnOrder, setColumnOrder] = useState<string[]>(
    columns.map((column: ColumnDef<any, any>) => column.id as string)
  );

  return (
    <div className={classNames(styles.otListTable, "relative")}>
      <ErrorPopup
        descText="Error Message!"
        confirmBtnText="Yes, Close."
        show={errorPopup}
        setShow={setErrorPopup}
        message={message}
        setMessage={setMessage}
      />
      <div className="text-primary text-left">
        <div className="relative max-w-full inline-block">
          <LayoutAdjustableTable
            hideColumn={visibleColumns}
            columns={columns}
            data={data}
            sorting={sorting}
            setSortingState={setSorting}
            rowSelection={rowSelection}
            setRowSelection={setRowSelection}
            columnOrder={columnOrder}
            setColumnOrder={setColumnOrder}
            unDraggableFields={["actions", "selection", "id"]}
            theaderColor="text-graydark"
            isLayoutControlOpen={isLayoutControlOpen}
            setIsLayoutControlOpen={setIsLayoutControlOpen}
            isWorkingShift={isWorkingShift}
            setIsWorkingShift={setIsWorkingShift}
            label="Claim Type List"
            setItemCount={setItemCount}
            pagination={pagination}
            setPagination={setPagination}
            globalFilter={globalSearch}
            setGlobalFilter={setGlobalSearch}
            columnFilters={columnFilters}
            setColumnFilters={setColumnFilters}
            noPaddingCells={[]}
            localModuleId={localModuleId}
            visArray={visArray}
            setIsTemplate={setIsTemplate}
            setCols={setCols}
          />
        </div>
      </div>
      {selectRowsCount > 0 && (
        <div className="flex items-center text-14 leading-5 font-semibold text-black2 mt-3 mb-3 lg:mt-3 lg:mb-3 ">
          <p>{selectRowsCount} selected.</p>
          <button
            type="button"
            onClick={() => setOpenDeleteopup(true)}
            className=" flex items-center ml-5 text-12 leading-[18px] font-semibold text-redPink"
          >
            <div className="w-6 h-6 bg-redPink flex items-center group justify-center rounded-md mr-[10px] hover:bg-[#FFEAEF]  transition-all duration-300 ">
              <TrashIcon width={10} height={12} />
            </div>
            Delete
          </button>
        </div>
      )}
      <div className="pt-2">
        <TablePaginator
          total={itemCount}
          pagination={pagination}
          setPagination={setPagination}
          itemCount={total}
          setCurrentPage={setCurrentPage}
          currentPageDefault={currentPage}
          changePage={changePage}
        />
      </div>
      <DeletePopup
        descText="Are you sure to delete?"
        confirmBtnText="Yes, delete."
        show={openDeletePopup}
        setShow={setOpenDeleteopup}
        confirmDeleteData={confirmDeleteData}
        loading={clickLoading}
      />
    </div>
  );
};
export default WorkingShiftTable;

