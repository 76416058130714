import {
  ColumnDef,
  ColumnFiltersState,
  PaginationState,
  RowSelectionState,
  SortingState,
  createColumnHelper,
} from "@tanstack/react-table";
import React, {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import EditableCell from "../Tables/EditableCell";
import { baseObj } from "../../utils/constants";
import { renderDateFormatDMY } from "../DashboardCalendarView/DashboardAddEventPopup";
import DatePopup from "../EmployeeTable/DatePopup";
import classNames from "classnames";
import {
  CancelIcon,
  DownloadIcon,
  EditIcon,
  ReloadIcon,
  SendIcon,
  SendIconUpdated,
  TableSaveIcon,
  TrashIcon,
  ViewIcon,
} from "../common/Icons";
import styles from "./PayrollTable.module.scss";
import Table from "../Tables/Tables.module.scss";
import { IOTData } from "../../ot-list/views/OTList";
import LayoutAdjustableTable from "../Tables/LayoutAdjustableTable";
import { TemplateData } from "../../app/services/employee";
import { templateData } from "../../app/services/template";
import TablePaginator from "../Tables/TablePaginator";
import DeletePopup from "../DeletePopup/DeletePopup";
import { toast } from "react-toastify";
import CustomDropdownWithAddButton from "../EmployeeTable/CustomDropdownWithAddButton";
import axios from "../../axios";
import { endpointUrl } from "../../app/services/api";
import {
  useGetDownloadPayRollMutation,
  useMultRegeneratePaySlipMutation,
  useMultSendPayRollSlipMutation,
  useRegeneratePayRollSlipMutation,
  useSendPayRollSlipMutation,
  useUpdatedPayRollMutation,
  // useGetPreviewListMutation,
} from "../../app/services/payroll";
import CommonErrorPopup from "../common/CommonErrorPopup";
import { getAuthUser } from "../../app/services/dashboard";
import authHeader from "../../app/services/auth-header";
// import { useGetDownloadPayRollQuery } from "../../app/services/payroll";
export const statusList = [
  {
    id: "All",
    name: "All",
    label: "All",
  },
  {
    id: 0,
    name: "Pending",
    label: "Pending",
  },
  {
    id: 1,
    name: "Sent",
    label: "Sent",
  },
];
interface IOTTable {
  isLayoutControlOpen: boolean;
  setIsLayoutControlOpen: Dispatch<SetStateAction<boolean>>;
  pagination: PaginationState;
  setPagination: Dispatch<SetStateAction<PaginationState>>;
  globalSearch: string;
  setGlobalSearch: Dispatch<SetStateAction<string>>;
  columnFilters: ColumnFiltersState;
  setColumnFilters: Dispatch<SetStateAction<ColumnFiltersState>>;
  orgDataList: IOTData[];
  data: baseObj[];
  setData: Dispatch<SetStateAction<baseObj[]>>;
  deleteEmployee: any;
  deleteEmployeeByIDs: any;
  hiddenFields: string[];
  templateList: templateData[];
  setHiddenFields: any;
  setCurrentView: any;
  total: number;
  changePage: any;
  setCurrentPage: any;
  currentPage: number;
  setIsEdit: Dispatch<SetStateAction<boolean>>;
  isEditing?: boolean;
  setIsTemplate?: Dispatch<SetStateAction<boolean>>;
  localModuleId: number;
  visArray: any;
  setCols?: any;
  sorting: SortingState;
  setSorting: React.Dispatch<React.SetStateAction<SortingState>>;
}
const PayrollTable: FC<IOTTable> = ({
  isLayoutControlOpen,
  setIsLayoutControlOpen,
  pagination,
  setPagination,
  globalSearch,
  setGlobalSearch,
  columnFilters,
  setColumnFilters,
  data,
  setData,
  orgDataList,
  deleteEmployee,
  deleteEmployeeByIDs,
  hiddenFields,
  setHiddenFields,
  templateList,
  setCurrentView,
  total,
  changePage,
  setCurrentPage,
  currentPage,
  setIsEdit,
  isEditing,
  setIsTemplate,
  localModuleId,
  visArray,
  setCols,
  sorting,
  setSorting,
}) => {
  const columnHelper = useMemo(() => createColumnHelper<any>(), []);
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  const [deleteID, setDeleteID] = useState<number | 0>();
  const [deleteRowID, setDeleteRowID] = useState<number | 0>();
  const [openDeletePopup, setOpenDeleteopup] = useState<boolean>(false);
  const [openSendPopup, setOpenSendopup] = useState<boolean>(false);
  const [openRegeneratePopup, setOpenRegenerateopup] = useState<boolean>(false);
  const [isLoading, setisLoading] = useState<boolean>(false);
  const [isLoadingClick, setIsLoadingClick] = useState<boolean>(false);
  const [itemCount, setItemCount] = useState<number>(total);
  const [error, setError] = useState<string>("");
  const [sending, setSending] = useState<boolean>(false);
  const [selectedID, setSelectedID] = useState<number>(0);
  const [viewPayroll, setViewPayroll] = useState<any>({
    id: 0,
    isView: false,
  });
  const [downloadPayroll, setDownloadPayroll] = useState<any>({
    id: 0,
    isDownload: false,
  });
  var getUser = getAuthUser();
  // const [downloadPayrollSlip] = useGetDownloadPayRollMutation();
  const [updatedPayroll] = useUpdatedPayRollMutation();
  const [multiSend] = useMultSendPayRollSlipMutation();
  const [multiRegenerate] = useMultRegeneratePaySlipMutation();

  const [sendPayRollSlip, { isSuccess: sendPayRollSuccess }] =
    useSendPayRollSlipMutation();
  const [regeneratePayRollSlip, { isSuccess: regeneratePayRollSuccess }] =
    useRegeneratePayRollSlipMutation();

  const selectRowsCount = Object.keys(rowSelection).length;
  const saveOTListData = (id: number) => {
    saveOTList(id, false);
  };
  const [successPopup, setSuccessPopup] = useState<boolean>(false);
  const [errorPopup, setErrorPopup] = useState<boolean>(false);
  const [message, setMessage] = useState<any>([]);
  const [msg, setMsg] = useState<string>("");
  const saveOTList = useCallback(
    (id: number, isNew: boolean) => {
      setData((prev: baseObj[]) => {
        return prev.map((data, index: number) => {
          if (data.uniqeID === id) {
            return {
              ...data,
              isNew: isNew,
            };
          }
          return data;
        });
      });
    },
    [setData]
  );
  const cancelRow = (id: number) => {
    if (isEditing) {
      setIsEdit(false);
      setData((prev: baseObj[]) => {
        return prev.map((data, index: number) => {
          if (data.uniqeID === id) {
            return { ...data, isNew: false };
          }
          return data;
        });
      });
    } else {
      const filterData = data?.filter((d: any) => d?.uniqeID !== id);
      setData(filterData);
    }
  };
  const editOTList = useCallback(
    (id: number) =>
      setData((prev: baseObj[]) => {
        // if (document.getElementsByClassName(Table.horizontalScrollBar)[0]) {
        //     document.getElementsByClassName(Table.horizontalScrollBar)[0].scrollLeft = 0;
        // }
        return prev.map((data, index: number) => {
          if (index === id) {
            return { ...data, isNew: true };
          }
          return data;
        });
      }),
    [setData]
  );

  const onDownloadClick = (id: number, filename: string) => {
    setDownloadPayroll({
      id: id,
      isDownload: true,
    });
    axios({
      url: endpointUrl + `payrolls/${id}/download`,
      method: "GET",
      responseType: "blob",
      headers: authHeader(),
    })
      .then((response) => {
        setError("");
        setDownloadPayroll({
          id: id,
          isDownload: false,
        });
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        setDownloadPayroll({
          id: id,
          isDownload: false,
        });
        setError(error?.message);
      });
  };

  const previewPayRoll = (id: number) => {
    setViewPayroll({
      id: id,
      isView: true,
    });
    axios({
      url: endpointUrl + `payrolls/${id}/view`,
      method: "GET",
      headers: authHeader(),
      responseType: "blob",
    })
      .then((response) => {
        setError("");
        setViewPayroll({
          id: id,
          isView: false,
        });
        var blob = new Blob([response.data], { type: "application/pdf" });
        var blobURL = URL.createObjectURL(blob);
        window.open(blobURL);
      })
      .catch((error) => {
        setViewPayroll({
          id: id,
          isView: false,
        });
        setError(error?.message);
      });
  };

  const sendPayRoll = (id: number) => {
    setIsLoadingClick(true);
    setisLoading(true);
    sendPayRollSlip({ id })
      .then((response: any) => {
        setDeleteID(0);
        setisLoading(false);
        setOpenSendopup(false);
        setIsLoadingClick(false);
        if (response?.data?.status) {
          toast(response?.data?.message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            type: "success",
          });
        }
      })
      .catch((error: any) => {
        setDeleteID(0);
        setisLoading(false);
        setErrorPopup(true);
        setOpenSendopup(false);
        setIsLoadingClick(false);
        setMessage(
          error
            ? ["Something went wrong!Trying again."]
            : ["Something went wrong!Trying again."]
        );
      });
  };
  const regeneratePayRoll = (id: number) => {
    setisLoading(true);
    regeneratePayRollSlip({ id })
      .then((response: any) => {
        if (response.error) {
          setisLoading(false);
          setOpenRegenerateopup(false);
          setDeleteID(0);
          setError(response?.error?.data?.message)
        }
        else {
          setError('')
          setisLoading(false);
          setOpenRegenerateopup(false);
          setDeleteID(0);
          toast(response?.data?.message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            type: "success",
          });
        }
      })
      .catch((error: any) => {
        setisLoading(false);
        setErrorPopup(true);
        setOpenRegenerateopup(false);
        setDeleteID(0);
        setMessage(
          error
            ? ["Something went wrong!Trying again."]
            : ["Something went wrong!Trying again."]
        );
      });
  };

  const editRow = useCallback(
    (id: number) =>
      setData((prev: baseObj[]) => {
        if (document.getElementsByClassName(Table.horizontalScrollBar)[0]) {
          document.getElementsByClassName(
            Table.horizontalScrollBar
          )[0].scrollLeft = 0;
        }
        return prev.map((data, index: number) => {
          if (index == id) {
            return { ...data, isNew: true };
          }
          return data;
        });
      }),
    [setData]
  );

  const checkIsValidate = (editedData: any) => {
    var msg: any = [];

    // if (editedData.account === "") {
    //   msg.push("The account field is required!");
    // }
    // // if (editedData.links === "") {
    // //   msg.push("The links field is required!");
    // // }
    // if (editedData.password === "") {
    //   msg.push("The password field is required!");
    // }
    // if (editedData.platform === "") {
    //   msg.push("The platform field is required!");
    // }

    return msg;
  };
  const floatToCurrency = (total: number) => {
    return total
      .toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      })
      .replace(/(\.|,)00$/g, "")
      .replace("$", "");
  };
  const savePayRoll = (id: number) => {
    setisLoading(true);
    var editedData = data.find((x) => x.id == id);

    const updatedData = {
      id: id,
      employee_voluntary_contribution: editedData?.mpf,
      employee_social_security_fund: editedData?.ssb,
      unpaid_leave_deduction: editedData?.unpaid_leave_deduction,
      income_tax: editedData?.income_tax,
      other_deduction: editedData?.other_deduction,
      bonus: editedData?.bonus,
      ot_fee: editedData?.ot_fee,
      final_payment_sgd: editedData?.final_payment_sgd,
      receipt_bank: editedData?.receipt_bank,
      receipt_no: editedData?.receipt_no,
      bank_account_name: editedData?.bank_account_name,
      bank_account_no: editedData?.bank_account_no,
      receipt_bank_branch: editedData?.receipt_bank_branch,
      // receipt_bank_branch
    };

    if (updatedData) {
      var message: any = checkIsValidate(updatedData);
      if (message.length > 0) {
        setErrorPopup(true);
        setMessage(message);
        return;
      } else {
        updatedPayroll(updatedData)
          .unwrap()
          .then((response: any) => {
            setisLoading(false);
            setIsEdit(false);
            closeCancel(id);
            toast("Successfully Updated.", {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              type: "success",
            });
          })
          .catch((error: any) => {
            setisLoading(false);
            setErrorPopup(true);
            setMessage(
              error
                ? [error?.data?.message]
                : ["Something went wrong!Trying again."]
            );
          });
      }
    }
  };

  const closeCancel = useCallback(
    (id: number) => {
      setData((prev: baseObj[]) => {
        return prev.map((data, index: number) => {
          if (data.id == id) {
            return {
              ...data,
              isNew: false,
            };
          }
          return data;
        });
      });
    },
    [setData]
  );

  const canCeelRow = (id: number) => {
    if (isEditing) {
      setIsEdit(false);
      setData((prev: baseObj[]) => {
        return prev.map((data, index: number) => {
          if (data.id == id) {
            return { ...data, isNew: false };
          }
          return data;
        });
      });
    } else {
      const filterData = data?.filter((d: any) => d?.id !== id);
      setData(filterData);
    }
  };

  const saveTemplateData = (template: any) => {
    var views = columns
      .map((x) => {
        if (x.id) {
          if (template[x.id] == undefined) {
            return x.id;
          }
        }
      })
      .filter((x) => x != undefined);
    var viewsData = views.map((x) => (x != undefined ? x : ""));
    var newTemplate: TemplateData = {
      id: 0,
      name: "View" + templateList.length + 1,
      viewColumns: viewsData,
    };
    setHiddenFields(viewsData);
    // setCurrentView("");
  };
  const columns = useMemo(
    () => [
      columnHelper.display({
        header: "",
        id: "selection",
      }),

      columnHelper.accessor("payroll_no", {
        header: "Payroll No.",
        id: "payroll_no",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const employeeID = getValue();
          return (
            <div className=" text-left bg-white text-black2 font-normal">
              <p className="h-full flex items-center font-normal bg-white px-3">
                {employeeID}
              </p>
            </div>
            // <EditableCell
            //   setData={setData}
            //   value={employeeID ?? ""}
            //   type="text"
            //   cell={cell}
            //   hasPadding={false}
            // />
          );
        },
      }),
      columnHelper.accessor("process_date", {
        header: "Process Date",
        id: "process_date",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const processDate = getValue();
          return (
            <div className=" text-left bg-white text-black2 font-normal">
              <p className="h-full flex items-center px-3">{processDate}</p>
            </div>
          );
        },
      }),
      columnHelper.accessor("employee_id", {
        header: "Employee ID",
        id: "employee_id",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const name = getValue();
          return (
            <div className=" text-left bg-white text-black2 font-normal">
              <p className="h-full flex items-center px-3">{name}</p>
            </div>
          );
        },
      }),
      columnHelper.accessor("employee_name", {
        header: "Employee Name",
        id: "employee_name",
        // enableSorting: false,
        cell: ({ getValue, cell }) => {
          const year = getValue();
          return (
            <div className=" text-left bg-white text-black2 font-normal">
              <p className="h-full flex items-center px-3">{year}</p>
            </div>
          );
        },
      }),
      columnHelper.accessor("month", {
        header: "Month",
        id: "month",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const timeIn = getValue();
          return (
            <div className=" text-left bg-white text-black2 font-normal">
              <p className="h-full flex items-center px-3">{timeIn}</p>
            </div>
          );
        },
      }),
      columnHelper.accessor("basic_salary", {
        header: "Basic Salary",
        id: "basic_salary",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const basicSalary = getValue();
          const currencyCode = cell.row.original.currency_code;
          return (
            <div className=" text-left bg-white text-black2 font-normal">
              <p className="h-full flex items-center px-3">
                {currencyCode} {floatToCurrency(basicSalary)}
              </p>
            </div>
          );
        },
      }),
      columnHelper.accessor("basic_salary_sgd", {
        header: "Est. in SGD",
        id: "basic_salary_sgd",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const otAmt = getValue();
          return (
            <div className=" font-normal  text-left bg-white text-black2">
              <p className="h-full flex items-center px-3">
                SGD {floatToCurrency(otAmt)}
              </p>
            </div>
          );
        },
      }),
      columnHelper.accessor("total_days", {
        header: "Total Days",
        id: "total_days",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const otAmt = getValue();
          return (
            <div className=" font-normal text-left bg-white text-black2">
              <p className="h-full flex items-center px-3">{otAmt}</p>
            </div>
          );
        },
      }),
      columnHelper.accessor("office_off_days", {
        header: "Official Off",
        id: "office_off_days",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const otAmt = getValue();
          return (
            <div className=" font-normal text-left bg-white text-black2">
              <p className="h-full flex items-center px-3">{otAmt}</p>
            </div>
          );
        },
      }),
      columnHelper.accessor("hourly_rate", {
        header: "Hourly Rate",
        id: "hourly_rate",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const otAmt = getValue();
          const currencyCode = cell.row.original.currency_code;
          return (
            <div className=" font-normal text-left bg-white text-black2">
              <p className="h-full flex items-center px-3">
                {currencyCode} {floatToCurrency(otAmt)}
              </p>
            </div>
          );
        },
      }),
      columnHelper.accessor("hourly_rate_sgd", {
        header: "Est. in SGD",
        id: "hourly_rate_sgd",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const otAmt = getValue();
          return (
            <div className=" font-normal text-left bg-white text-black2">
              <p className="h-full flex items-center px-3">
                SGD {floatToCurrency(otAmt)}
              </p>
            </div>
          );
        },
      }),
      // columnHelper.accessor("annual_leave", {
      //   header: "AL",
      //   id: "annual_leave",
      //   cell: ({ getValue, cell }) => {
      //     const otAmt = getValue();
      //     return (
      //       <div className=" font-normal max-w-[180px] min-w-[100px] text-left px-[10px] bg-white text-black2">
      //         <p className="h-full flex items-center">{otAmt}</p>
      //       </div>
      //     );
      //   },
      // }),
      // columnHelper.accessor("unpaid_leave", {
      //   header: "Unpaid Leave",
      //   id: "unpaid_leave",
      //   cell: ({ getValue, cell }) => {
      //     const otAmt = getValue();
      //     return (
      //       <div className=" font-normal max-w-[180px] min-w-[100px] text-left px-[10px] bg-white text-black2">
      //         <p className="h-full flex items-center">{otAmt}</p>
      //       </div>
      //     );
      //   },
      // }),
      columnHelper.accessor("regular_working_days", {
        header: "Regular Working Days",
        id: "regular_working_days",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const otAmt = getValue();
          return (
            <div className=" font-normal text-left  bg-white text-black2">
              <p className="h-full flex items-center px-3">{otAmt}</p>
            </div>
          );
        },
      }),
      columnHelper.accessor("actual_working_days", {
        header: "Actual Working Days",
        id: "actual_working_days",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const otAmt = getValue();
          return (
            <div className=" font-normal text-left bg-white text-black2">
              <p className="h-full flex items-center px-3">{otAmt}</p>
            </div>
          );
        },
      }),
      columnHelper.accessor("counted_working_days", {
        header: "Counted Working Days",
        id: "counted_working_days",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const otAmt = getValue();
          return (
            <div className=" font-normal text-left bg-white text-black2">
              <p className="h-full flex items-center px-3">{otAmt}</p>
            </div>
          );
        },
      }),
      columnHelper.accessor("counted_working_days_salary", {
        header: "Counted Working Days Salary",
        id: "counted_working_days_salary",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const otAmt = getValue();
          const currencyCode = cell.row.original.currency_code;
          return (
            <div className=" font-normal text-left bg-white text-black2">
              <p className="h-full flex items-center px-3">
                {currencyCode} {floatToCurrency(otAmt)}
              </p>
            </div>
          );
        },
      }),
      columnHelper.accessor("counted_active_time_hours", {
        header: "Counted Active Time (Hrs)",
        id: "counted_active_time_hours",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const otAmt = getValue();
          return (
            <div className=" font-normal text-left bg-white text-black2">
              <p className="h-full flex items-center px-3">{otAmt}</p>
            </div>
          );
        },
      }),
      columnHelper.accessor("official_90_percent_active_hours", {
        header: "Official 90% Active Hours",
        id: "official_90_percent_active_hours",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const otAmt = getValue();
          return (
            <div className=" font-normal text-left bg-white text-black2">
              <p className="h-full flex items-center px-3">{otAmt}</p>
            </div>
          );
        },
      }),
      columnHelper.accessor("official_80_percent_active_hours", {
        header: "Official 80% Active Hours",
        id: "official_80_percent_active_hours",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const otAmt = getValue();
          return (
            <div className=" font-normal text-left bg-white text-black2">
              <p className="h-full flex items-center px-3">{otAmt}</p>
            </div>
          );
        },
      }),
      columnHelper.accessor("official_70_percent_active_hours", {
        header: "Official 70% Active Hours",
        id: "official_70_percent_active_hours",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const otAmt = getValue();
          return (
            <div className=" font-normal text-left bg-white text-black2">
              <p className="h-full flex items-center px-3">{otAmt}</p>
            </div>
          );
        },
      }),
      columnHelper.accessor("internet_incentive", {
        header: "Internet Incentive",
        id: "internet_incentive",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const otAmt = getValue();
          const currencyCode = cell.row.original.currency_code;
          return (
            <div className=" font-normal text-left bg-white text-black2">
              <p className="h-full flex items-center px-3">
                {currencyCode} {floatToCurrency(otAmt)}
              </p>
            </div>
          );
        },
      }),
      columnHelper.accessor("no_absent_incentive", {
        header: "No Absent Incentive",
        id: "no_absent_incentive",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const otAmt = getValue();
          const currencyCode = cell.row.original.currency_code;
          return (
            <div className=" font-normal text-left bg-white text-black2">
              <p className="h-full flex items-center px-3">
                {currencyCode} {floatToCurrency(otAmt)}
              </p>
            </div>
          );
        },
      }),
      columnHelper.accessor("no_lateness_incentive", {
        header: "No Lateness Incentive",
        id: "no_lateness_incentive",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const otAmt = getValue();
          const currencyCode = cell.row.original.currency_code;
          return (
            <div className=" font-normal text-left bg-white text-black2">
              <p className="h-full flex items-center px-3">
                {currencyCode} {floatToCurrency(otAmt)}
              </p>
            </div>
          );
        },
      }),
      columnHelper.accessor("work_quality_rewards_tier_1", {
        header: "Work Quality Rewards Tier 1",
        id: "work_quality_rewards_tier_1",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const otAmt = getValue();
          const currencyCode = cell.row.original.currency_code;
          return (
            <div className=" font-normal text-left bg-white text-black2">
              <p className="h-full flex items-center px-3">
                {currencyCode} {floatToCurrency(otAmt)}
              </p>
            </div>
          );
        },
      }),
      columnHelper.accessor("work_quality_rewards_tier_2", {
        header: "Work Quality Rewards Tier 2",
        id: "work_quality_rewards_tier_2",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const otAmt = getValue();
          const currencyCode = cell.row.original.currency_code;
          return (
            <div className=" font-normal text-left bg-white text-black2">
              <p className="h-full flex items-center px-3">
                {currencyCode} {floatToCurrency(otAmt)}
              </p>
            </div>
          );
        },
      }),
      columnHelper.accessor("other_rewards", {
        header: "Other Rewards",
        id: "other_rewards",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const otAmt = getValue();
          const currencyCode = cell.row.original.currency_code;
          return (
            <div className=" font-normal text-left bg-white text-black2">
              <p className="h-full flex items-center px-3">
                {currencyCode} {floatToCurrency(otAmt)}
              </p>
            </div>
          );
        },
      }),
      columnHelper.accessor("allowance", {
        header: "Allowance",
        id: "allowance",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const otAmt = getValue();
          const currencyCode = cell.row.original.currency_code;
          return (
            <div className=" font-normal text-left bg-white text-black2">
              <p className="h-full flex items-center px-3">
                {currencyCode} {floatToCurrency(otAmt)}
              </p>
            </div>
          );
        },
      }),
      columnHelper.accessor("total_incentive_reward", {
        header: "Total Incentive/Rewards",
        id: "total_incentive_reward",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const otAmt = getValue();
          const currencyCode = cell.row.original.currency_code;
          return (
            <div className=" font-normal text-left bg-white text-black2">
              <p className="h-full flex items-center px-3">
                {currencyCode} {floatToCurrency(otAmt)}
              </p>
            </div>
          );
        },
      }),
      columnHelper.accessor("ot_days", {
        header: "OT Days",
        id: "ot_days",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const otAmt = getValue();
          return (
            <div className=" font-normal text-left bg-white text-black2 px-2">
              <span className="flex h-full items-center">{otAmt}</span>
            </div>
          );
        },
      }),
      columnHelper.accessor("ot_fee", {
        header: "OT Fees",
        id: "ot_fee",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const otAmt = getValue();
          const isNew = cell.row.original.isNew;
          const currencyCode = cell.row.original.currency_code;
          return (
            <div className=" font-normal text-left bg-white text-black2 px-2">
              <span className="flex h-full items-center">
                {currencyCode} {floatToCurrency(otAmt)}
              </span>
            </div>
          );
        },
      }),
      columnHelper.accessor("bonus", {
        header: "Bonus",
        id: "bonus",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const otAmt = getValue();
          const isNew = cell.row.original.isNew;
          const currencyCode = cell.row.original.currency_code;
          return (
            <div className=" font-normal text-left bg-white text-black2">
              {isNew == true ? (
                <EditableCell
                  setData={setData}
                  value={otAmt ?? ""}
                  type="number"
                  cell={cell}
                // customDesign="text"
                />
              ) : (
                <span className="flex h-full items-center">
                  {currencyCode} {floatToCurrency(otAmt)}
                </span>
              )}
            </div>
          );
        },
      }),
      columnHelper.accessor("mpf", {
        header: "MPF - Employee Voluntary Contribution (HK)",
        id: "mpf",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const otAmt = getValue();
          const isNew = cell.row.original.isNew;
          const currencyCode = cell.row.original.currency_code;
          return (
            <div className=" font-normal text-left bg-white text-black2">
              <span className="flex h-full items-center px-2">
                {currencyCode} {floatToCurrency(otAmt)}
              </span>
            </div>
          );
        },
      }),
      columnHelper.accessor("ssb", {
        header: "SSB - Social Security Fund Income (MM)",
        id: "ssb",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const otAmt = getValue();
          const isNew = cell.row.original.isNew;
          const currencyCode = cell.row.original.currency_code;
          return (
            <div className=" font-normal text-left bg-white text-black2">
              {isNew == true ? (
                <EditableCell
                  setData={setData}
                  value={otAmt ?? ""}
                  type="number"
                  cell={cell}
                // customDesign="text"
                />
              ) : (
                <span className="flex h-full items-center">
                  {currencyCode} {floatToCurrency(otAmt)}
                </span>
              )}
            </div>
          );
        },
      }),

      columnHelper.accessor("income_tax", {
        header: "Income Tax",
        id: "income_tax",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const otAmt = getValue();
          const isNew = cell.row.original.isNew;
          const currencyCode = cell.row.original.currency_code;
          return (
            <div className=" font-normal text-left bg-white text-black2">
              {isNew == true ? (
                <EditableCell
                  setData={setData}
                  value={otAmt ?? ""}
                  type="number"
                  cell={cell}
                // customDesign="text"
                />
              ) : (
                <span className="flex h-full items-center">
                  {currencyCode} {floatToCurrency(otAmt)}
                </span>
              )}
            </div>
          );
        },
      }),

      columnHelper.accessor("other_deduction", {
        header: "Other Deduction",
        id: "other_deduction",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const otAmt = getValue();
          const isNew = cell.row.original.isNew;
          const currencyCode = cell.row.original.currency_code;
          return (
            <div className=" font-normal text-left bg-white text-black2">
              {isNew == true ? (
                <EditableCell
                  isYear={true}
                  setData={setData}
                  value={otAmt ?? ""}
                  type="number"
                  cell={cell}
                // customDesign="text"
                />
              ) : (
                <span className="flex h-full items-center">
                  {currencyCode} {floatToCurrency(otAmt)}
                </span>
              )}
            </div>
          );
        },
      }),

      columnHelper.accessor("total_leaves", {
        header: "Today Leave (Day)",
        id: "total_leaves",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const otAmt = getValue();
          return (
            <div className=" font-normal text-left bg-white text-black2">
              <p className="h-full flex items-center px-3">
                {otAmt ? otAmt : 0}
              </p>
            </div>
          );
        },
      }),
      columnHelper.accessor("unpaid_leave", {
        header: "Unpaid Leave (Day)",
        id: "unpaid_leave",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const otAmt = getValue();
          return (
            <div className=" font-normal text-left bg-white text-black2">
              <p className="h-full flex items-center px-3">{otAmt}</p>
            </div>
          );
        },
      }),
      columnHelper.accessor("unpaid_leave_deduction", {
        header: "Unpaid Leave Deduction",
        id: "unpaid_leave_deduction",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const otAmt = getValue();
          const currencyCode = cell.row.original.currency_code;
          const isNew = cell.row.original.isNew;
          return (
            <div className=" font-normal text-left bg-white text-black2">
              <span className="px-2 flex h-full items-center">
                {currencyCode} {floatToCurrency(otAmt ? otAmt : 0)}
              </span>
            </div>
          );
        },
      }),
      columnHelper.accessor("gross_pay", {
        header: "Gross Payment",
        id: "gross_pay",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const salaryPayment = getValue();
          const currencyCode = cell.row.original.currency_code;
          return (
            <div className=" font-normal text-left bg-white text-black2 px-2">
              <span className="px-2 flex h-full items-center">
                {currencyCode} {floatToCurrency(salaryPayment ?? 0)}
              </span>
            </div>
          );
        },
      }),
      columnHelper.accessor("total_deduction", {
        header: "Total Deduction",
        id: "total_deduction",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const salaryPayment = getValue();
          const currencyCode = cell.row.original.currency_code;
          return (
            <div className=" font-normal text-left bg-white text-black2 px-2">
              <span className="px-2 flex h-full items-center">
                {currencyCode} {floatToCurrency(salaryPayment ?? 0)}
              </span>
            </div>
          );
        },
      }),
      columnHelper.accessor("salary_payment", {
        header: "Salary ++ Payment",
        id: "salary_payment",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const salaryPayment = getValue();
          const currencyCode = cell.row.original.currency_code;
          return (
            <div className=" font-normal text-left bg-white text-black2 px-2">
              <span className="px-2 flex h-full items-center">
                {currencyCode} {floatToCurrency(salaryPayment)}
              </span>
            </div>
          );
        },
      }),
      columnHelper.accessor("salary_payment_sgd", {
        header: "Salary Est. in SGD",
        id: "salary_payment_sgd",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const salarySGD = getValue();
          return (
            <div className=" font-normal text-left bg-white text-black2 px-2">
              <span className="px-2 flex h-full items-center">
                SGD {floatToCurrency(salarySGD)}
              </span>
            </div>
          );
        },
      }),
      columnHelper.accessor("final_payment_sgd", {
        header: "Final Payment SGD",
        id: "final_payment_sgd",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const finalPayment = getValue();
          const isNew = cell.row.original.isNew;
          return (
            <div className="font-normal text-left bg-white text-black2">
              {isNew ? (
                <EditableCell
                  setData={setData}
                  value={finalPayment}
                  type="text"
                  cell={cell}
                // customDesign="tooltip"
                />
              ) : (
                <span className="px-2 flex h-full items-center">
                  SGD {finalPayment ? floatToCurrency(finalPayment ?? 0) : 0}
                </span>
              )}
            </div>
          );
        },
      }),
      columnHelper.accessor("mobile", {
        header: "Mobile No.",
        id: "mobile",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const otAmt = getValue();
          return (
            <div className=" font-normal text-left bg-white text-black2">
              <p className="h-full flex items-center px-3">{otAmt}</p>
            </div>
          );
        },
      }),
      columnHelper.accessor("receipt_bank", {
        header: "Receipt Bank",
        id: "receipt_bank",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const receiptBank = getValue();
          return (
            <div className="font-normal text-left bg-white text-black2">
              <span className="flex h-full items-center px-2">
                {receiptBank}
              </span>
            </div>
          );
        },
      }),
      columnHelper.accessor("receipt_no", {
        header: "Receipt No.",
        id: "receipt_no",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const receiptNo = getValue();
          return (
            <div className="font-normal text-left bg-white text-black2">
              <EditableCell
                setData={setData}
                value={receiptNo}
                type="text"
                cell={cell}
              // customDesign="tooltip"
              />
            </div>
          );
        },
      }),
      columnHelper.accessor("bank_account_name", {
        header: "Bank Account Name",
        id: "bank_account_name",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const accountName = getValue();
          return (
            <div className="font-normal text-left bg-white text-black2">
              <EditableCell
                setData={setData}
                value={accountName}
                type="text"
                cell={cell}
              // customDesign="tooltip"
              />
            </div>
          );
        },
      }),
      columnHelper.accessor("receipt_bank_branch", {
        header: "Branch Code",
        id: "receipt_bank_branch",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const branchCode = getValue();
          return (
            <div className=" font-normal text-left bg-white text-black2">
              <EditableCell
                setData={setData}
                value={branchCode}
                type="text"
                cell={cell}
                customDesign="tooltip"
              />
            </div>
          );
        },
      }),
      columnHelper.accessor("bank_account_no", {
        header: "Bank Account No",
        id: "bank_account_no",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const otAmt = getValue();
          return (
            <div className="font-normal text-left bg-white text-black2">
              <span className="flex h-full items-center px-2">{otAmt}</span>
            </div>
          );
        },
      }),
      columnHelper.accessor("is_sent", {
        header: "Status",
        id: "is_sent",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const status = getValue();
          return (
            <div className=" text-left bg-white text-black2 font-normal">
              <p className="h-full flex items-center font-normal px-3">
                {status == 0 ? (
                  <span className="text-[#959CB6]">Pending</span>
                ) : (
                  <span className="text-[#00B87B]">Sent</span>
                )}
              </p>
            </div>
          );
        },
      }),
      columnHelper.display({
        header: "Action",
        id: "actions",
        enableSorting: false,
        cell: ({ cell }) => {
          var isHaveNew = data?.find((x) => x.isNew == true);
          var disabledClass = isHaveNew ? "opacity-50 pointer-events-none" : "";
          const isNew: boolean = cell.row.original?.isNew;
          const url = cell.row.original.url;
          const name = cell.row?.original?.employee_name;
          const date = cell.row?.original?.process_date;
          const id = cell.row?.original?.id;
          const pdfName =
            name?.replaceAll(" ", "_") +
            "_" +
            date?.replaceAll(" ", "_") +
            ".pdf";
          return (
            <div
              className={classNames(
                styles.actionedBtn,
                "px-2 flex justify-center items-center min-w-max "
              )}
            >
              {isNew ? (
                <>
                  <button
                    type="button"
                    onClick={() => {
                      savePayRoll(Number(cell.row.original.id));
                    }}
                    className={classNames(
                      isLoading ? " pointer-events-none opacity-50" : "",
                      "w-6 h-6 flex justify-center items-center bg-vorpgreen rounded-md"
                    )}
                  >
                    <TableSaveIcon />
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      canCeelRow(Number(cell?.row?.original?.id));
                      // setIsCreated(false)
                    }}
                    className={classNames(
                      styles.customCloseIcon,
                      "w-8 h-8 ml-2 mr-2 flex justify-center items-center group rounded-md  bg-[#71717a] hover:bg-[#71717a] transition-all duration-300"
                    )}
                  >
                    <CancelIcon width={18} height={18} />
                  </button>
                </>
              ) : (
                <>
                  <button
                    disabled={isHaveNew ? true : false}
                    type="button"
                    onClick={() => {
                      editRow(Number(cell?.row?.id));
                      if (setIsEdit) {
                        setIsEdit(true);
                        // setIsSuccess(false);
                      }
                    }}
                    className={classNames(
                      disabledClass,
                      isLoading ? " pointer-events-none opacity-50" : "",
                      "w-8 h-[35px] mr-2 flex justify-center items-center group rounded-md bg-vorpblue hover:bg-annhoverbg transition-all duration-300"
                    )}
                  >
                    <EditIcon />
                  </button>

                  <button
                    disabled={isHaveNew ? true : false}
                    type="button"
                    onClick={() => previewPayRoll(id)}
                    className={classNames(
                      disabledClass,
                      isLoading ||
                        (viewPayroll?.id == id ? viewPayroll?.isView : false)
                        ? " pointer-events-none opacity-50"
                        : "",
                      "w-8 h-8 mr-2 flex justify-center items-center group rounded-md bg-[#8950FC] hover:bg-[#EAE0FF] transition-all duration-300"
                    )}
                  >
                    <a target="_blank" href={url}>
                      <ViewIcon
                        width={16}
                        height={16}
                        className={classNames(
                          disabledClass,
                          "group-hover:fill-black2"
                        )}
                      />
                    </a>
                  </button>
                  <button
                    disabled={isHaveNew ? true : false}
                    type="button"
                    onClick={() => onDownloadClick(id, pdfName)}
                    className={classNames(
                      disabledClass,
                      isLoading ||
                        (downloadPayroll?.id == id
                          ? downloadPayroll?.isDownload
                          : false)
                        ? " pointer-events-none opacity-50"
                        : "",
                      "w-6 h-6 flex justify-center items-center group rounded-md bg-black2  hover:bg-[#FFEAEF] transition-all duration-300 mr-2 last:mr-0"
                    )}
                  >
                    <DownloadIcon width={16} height={16} />
                  </button>

                  <button
                    disabled={isHaveNew ? true : false}
                    type="button"
                    onClick={() => {
                      setOpenSendopup(true);
                      setSelectedID(id);
                      setDeleteID(id);
                      // sendPayRoll(id);
                    }}
                    className={classNames(
                      disabledClass,
                      styles.sendIcon,
                      isLoading ? " pointer-events-none opacity-50" : "",
                      "w-8 h-[35px] mr-2 flex justify-center items-center group rounded-md bg-turquiseIcon hover:bg-turqouiseLight transition-all duration-300"
                    )}
                  >
                    <SendIconUpdated />
                  </button>

                  <button
                    disabled={isHaveNew ? true : false}
                    type="button"
                    onClick={() => {
                      setOpenRegenerateopup(true);
                      setDeleteID(id);
                      //regeneratePayRoll(id);
                    }}
                    className={classNames(
                      disabledClass,
                      isLoading ? " pointer-events-none opacity-50" : "",
                      "w-8 h-[35px] mr-2 flex justify-center items-center group rounded-md bg-zinc-400 hover:bg-annhoverbg transition-all duration-300"
                    )}
                  >
                    <ReloadIcon />
                  </button>
                </>
              )}
            </div>
          );
        },
      }),
    ],
    [
      columnHelper,
      data,
      hiddenFields,
      isLoading,
      viewPayroll,
      downloadPayroll,
      openSendPopup,
      openRegeneratePopup,
    ]
  );
  const visibleColumns: { [key: string]: boolean } = {};

  hiddenFields?.map((x) => {
    visibleColumns[x] = true;
  });

  // if (checkManage) {
  //     visibleColumns['actions'] = true
  // } else {
  //     visibleColumns['actions'] = false
  // }

  if (hiddenFields.length > 0) {
    columns.map((x) => {
      if (x.id == "actions" || x.id == "selection" || x.id == "lasted") {
        visibleColumns[x.id ? x.id : ""] = true;
      } else if (x.id == "uniqeID") {
        visibleColumns[x.id ? x.id : ""] = false;
      } else {
        var isHidden = hiddenFields.find((h) => h == x.id);
        if (isHidden) {
          visibleColumns[x.id ? x.id : ""] = true;
        } else {
          visibleColumns[x.id ? x.id : ""] = false;
        }
      }
    });
  }
  const [columnOrder, setColumnOrder] = useState<string[]>(
    columns.map((column: ColumnDef<any, any>) => column.id as string)
  );
  const deleteOTItem = useCallback(
    (id?: number, dataId?: number) => {
      const selectedRows = Object.keys(rowSelection);
      setData((prev: baseObj[]) => {
        if (dataId != undefined) {
          // var $dataId = dataId;
          deleteEmployee(dataId)
            .unwrap()
            .then((payload: any) => {
              if (payload.status) {
                setOpenDeleteopup(false);
                setSuccessPopup(true);
                setMessage(
                  payload ? [payload?.message] : ["Successfully Delete."]
                );
                toast("Successfully Delete.", {
                  position: "top-right",
                  autoClose: 2000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                  type: "success",
                });
              }
            })
            .catch((error: any) => {
              setErrorPopup(true);
              setOpenDeleteopup(false);
              setMessage(
                error
                  ? [error?.data?.message]
                  : ["Something went wrong!Trying again."]
              );
            });
        }
        return prev.filter((_, index) => {
          return id !== undefined
            ? id !== index
            : !selectedRows.includes(String(index));
        });
      });
      id === undefined && setRowSelection({});
    },

    [setData, rowSelection]
  );
  const sendPayRollDataMultiple = (ids: number[]) => {
    setIsLoadingClick(true);
    multiSend(ids)
      .then((response: any) => {
        setIsLoadingClick(false);
        setOpenSendopup(false);
        toast("Successfully Send PayRollSlip.", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          type: "success",
        });
      })
      .catch((error: any) => {
        setOpenSendopup(false);
        setIsLoadingClick(false);

        setErrorPopup(true);
        setMessage(
          error
            ? ["Something went wrong!Trying again."]
            : ["Something went wrong!Trying again."]
        );
      });
    setRowSelection({});
  };
  const regeneratePayRollMultiple = (ids: number[]) => {
    setIsLoadingClick(true);
    multiRegenerate(ids)
      .then((response: any) => {
        setIsLoadingClick(false);
        setOpenRegenerateopup(false);
        toast("Successfully Re-Generate PayRollSlip.", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          type: "success",
        });
      })
      .catch((error: any) => {
        setOpenRegenerateopup(false);
        setIsLoadingClick(false);
        setErrorPopup(true);
        setMessage(
          error
            ? ["Something went wrong!Trying again."]
            : ["Something went wrong!Trying again."]
        );
      });
    setRowSelection({});
  };

  const confirmSendData = () => {
    var id = deleteID;
    var dataID = deleteRowID;
    const selectedRows = Object.keys(rowSelection);
    var selectionIds = data
      .filter((x, index: number) => {
        return selectedRows.find((s) => s == index.toString()) ? x.id : 0;
      })
      .map((x) => x.id);

    if (selectionIds.length > 0) {
      sendPayRollDataMultiple(selectionIds);
    } else {
      if (id) {
        sendPayRoll(id);
        // deleteOTItem(id, dataID);
        setDeleteID(0);
        //setDeleteRowID(-1);
      }
      setOpenDeleteopup(false);
    }
  };

  const confirmRegenerateData = () => {
    var id = deleteID;
    var dataID = deleteRowID;
    const selectedRows = Object.keys(rowSelection);
    var selectionIds = data
      .filter((x, index: number) => {
        return selectedRows.find((s) => s == index.toString()) ? x.id : 0;
      })
      .map((x) => x.id);

    if (selectionIds.length > 0) {
      regeneratePayRollMultiple(selectionIds);
    } else {
      if (id) {
        regeneratePayRoll(id);
        // deleteOTItem(id, dataID);
        setDeleteID(0);
        setDeleteRowID(-1);
      }
      setOpenDeleteopup(false);
    }
  };
  return (
    <div className="text-primary text-left">
      <div
        className={classNames(
          styles.payRollTable,
          "relative inline-block max-w-full"
        )}
      >
        <LayoutAdjustableTable
          saveTemplateData={saveTemplateData}
          hideColumn={visibleColumns}
          columns={columns}
          data={data}
          sorting={sorting}
          setSortingState={setSorting}
          rowSelection={rowSelection}
          setRowSelection={setRowSelection}
          columnOrder={columnOrder}
          setColumnOrder={setColumnOrder}
          unDraggableFields={["actions", "selection", "id"]}
          theaderColor="text-graydark"
          isLayoutControlOpen={isLayoutControlOpen}
          setIsLayoutControlOpen={setIsLayoutControlOpen}
          label="Payroll List"
          setItemCount={setItemCount}
          pagination={pagination}
          setPagination={setPagination}
          globalFilter={globalSearch}
          setGlobalFilter={setGlobalSearch}
          columnFilters={columnFilters}
          setColumnFilters={setColumnFilters}
          noPaddingCells={[]}
          localModuleId={localModuleId}
          visArray={visArray}
          setIsTemplate={setIsTemplate}
          setCols={setCols}
        />
        {selectRowsCount > 0 && (
          <div className="flex items-center text-14 leading-5 font-semibold text-black2 mt-3 mb-8 lg:mt-3 lg:mb-8 ">
            <p>{selectRowsCount} selected.</p>
            <button
              type="button"
              onClick={() => setOpenSendopup(true)}
              className=" flex items-center ml-5 text-12 leading-[18px] font-semibold text-teal-400"
            >
              <div className="w-6 h-6 bg-teal-400 flex items-center group justify-center rounded-md mr-[10px] hover:bg-[#ddf0e8]  transition-all duration-300 ">
                <SendIconUpdated width={10} height={12} />
              </div>
              Send
            </button>

            <button
              type="button"
              onClick={() => setOpenRegenerateopup(true)}
              className=" flex items-center ml-5 text-12 leading-[18px] font-semibold text-zinc-400"
            >
              <div className="w-6 h-6 bg-zinc-400 hover:bg-annhoverbg flex items-center group justify-center rounded-md mr-[10px] transition-all duration-300 ">
                <ReloadIcon width={10} height={12} />
              </div>
              Re-Generate
            </button>
          </div>
        )}
        <div className="pt-2">
          <TablePaginator
            total={itemCount}
            pagination={pagination}
            setPagination={setPagination}
            itemCount={total}
            changePage={changePage}
            setCurrentPage={setCurrentPage}
            currentPageDefault={currentPage}
          />
        </div>
        <DeletePopup
          loading={isLoadingClick}
          descText="Are you sure to send?"
          confirmBtnText="Yes, send."
          show={openSendPopup}
          setShow={setOpenSendopup}
          confirmDeleteData={confirmSendData}
          confirmBtnColor="bg-turquiseIcon hover:bg-turqouiseLight hover:text-black2"
        />
        <DeletePopup
          loading={isLoading}
          descText="Are you sure to re-generate?"
          confirmBtnText="Yes"
          show={openRegeneratePopup}
          setShow={setOpenRegenerateopup}
          confirmDeleteData={confirmRegenerateData}
          confirmBtnColor="bg-turquiseIcon hover:bg-turqouiseLight hover:text-black2"
        />
        <CommonErrorPopup
          show={error != "" ? true : false}
          setShow={() => setError("")}
          message={error}
        />
      </div>
    </div>
  );
};
export default PayrollTable;
