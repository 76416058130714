import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  forwardRef,
} from "react";
import { AddIcon, ChevronDown, DeleteIcon, SearchIcon, TrashIcon } from "../common/Icons";
// import { Listbox } from "@headlessui/react";
import { usePopper } from "../../hooks/usePopper";
import DebounceSearchInput from "../Tables/DebounceSearchInput";
import { EmployeeData } from "../EmployeeTable/EmployeeTable";
import classNames from "classnames";
import CreateNewItemPopup from "../CreateNewItemPopup/CreateNewItemPopup";
import { baseObj } from "../../utils/constants";
// import { usePopperCustom } from "../../hooks/usePopperCustom";
import styles from "./EmployeeTable.module.scss";

// import ClickAwayListener from '@mui/base/ClickAwayListener';
import { makeStyles } from '@mui/styles';
import { Tooltip, Popper, Box,ClickAwayListener } from "@mui/material"
import { useSpring, animated } from '@react-spring/web';
import { Cell, RowSelectionState } from "@tanstack/react-table";
import { assignUsers } from "../../app/services/password";

interface FadeProps {
  children?: React.ReactElement;
  in?: boolean;
  onEnter?: () => void;
  onExited?: () => void;
}

export type optionsType = {
  id: number;
  label: string;
  name: string;
}
interface ICustomDropdownList {
  // dataList: EmployeeData[];
  dataList: baseObj[];
  options: baseObj[];
  title: string;
  value: number;
  setData: Dispatch<SetStateAction<baseObj[]>>;
  setValue: Dispatch<SetStateAction<number>>;
  data: baseObj[];
  setDataList: Dispatch<SetStateAction<EmployeeData[]>>;
  customHeight?: string;
  setSelectedData: Dispatch<SetStateAction<any>>;
  selectedDataItem: string;
  selectedDataItemData?: baseObj;
  cell: Cell<unknown, unknown>;
  isCode?: boolean;
  isText?: boolean;
  addNewItemData?: any;
  isCurrency?: boolean;
  // setPaymentTerms?: any;
  isPayment?: boolean;
  setIsRegion?: any;
  setIsPJType?: any;
  setIsPJStatus?: any;
  setIsCurrency?: any;
  setIsPYStatus?: any;

  setDeleteID?: any;
  setOpenDeleteopup?: any;
  isRegion?: boolean;
  isProjectType?: boolean;
  isProjectStatus?: boolean;
  isPaymentStatus?: boolean;
  isPaymentTerm?: boolean;
  isShowDelete?: boolean;
  isShowAdd?: boolean;
  isValue?: boolean;
  addTitle?: string;
  isEditable?: boolean;
  isBorder?: boolean;
  customClass?:string;
}

const Fade = forwardRef<HTMLDivElement, FadeProps>(function Fade(props: any, ref: any) {
  const { in: open, children, onEnter, onExited, ...other } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter();
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited();
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {children}
    </animated.div>
  );
});

const useStyles = makeStyles(() => ({
  tooltip: {
    backgroundColor: "transparent",
    margin: 0,
    marginRight: 8,
    padding: 0,
  },
}));

const CustomDropdownList: FC<ICustomDropdownList> = ({
  options,
  title,
  dataList,
  data,
  setData,
  value,
  setValue,
  setDataList,
  customHeight,
  setSelectedData,
  selectedDataItem,
  selectedDataItemData,
  cell,
  isCode,
  isText,
  addNewItemData,
  isCurrency,
  // setPaymentTerms,
  isPayment,
  setIsRegion,
  setDeleteID,
  setOpenDeleteopup,
  setIsPJType,
  setIsPJStatus,
  setIsCurrency,
  setIsPYStatus,
  isRegion,
  isProjectType,
  isProjectStatus,
  isPaymentStatus,
  isPaymentTerm,
  isShowDelete,
  isShowAdd,
  isValue,
  addTitle,
  isEditable,
  isBorder,
  customClass
  // isLeave,
}) => {
  const [placement, setPlacement] = useState<any>();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState(false);
  // const [dataItems, setDataItems] = useState<EmployeeData[]>(dataList ? dataList : []);
  const [dataItems, setDataItems] = useState<baseObj[]>(dataList ? dataList : []);
  const [selectedValue, setSelectedValue] = useState<number>(value);
  const [selectedLabel, setSelectedLabel] = useState<string>(selectedDataItem);
  const [OK, setOK] = useState<any>();
  const [selectedLabelData, setSelectedLabelData] = useState<baseObj | undefined>(selectedDataItemData);
  // const [selectedName, setSelectedName] = useState<string>(selectedDataItem);
  // const [selectedName, setSelectedName] = useState<boolean>(false);
  const [search, SetSearch] = useState<string>("");
  const [showCreatePopup, setShowCreatePopup] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);
  const [isCreated, setIsCreated] = useState<boolean>(false);
  const mainContainer = useRef<HTMLDivElement | null>(null);

  const [width, setWidth] = useState<number>();
  const originalItem = useMemo(
    () => [...dataItems],
    // () => [{ id: 1, value: 0, label: "--", name: "" }, ...dataItems],
    []
  );

  const [filteredList, setFilteredList] = useState(originalItem);

  useEffect(() => {
    const keyword = search?.trim().toLowerCase();
    if (keyword.length > 0) {
      const matchedItems = originalItem.filter((item) =>
        item.name?.toLowerCase().includes(keyword)
      );
      setFilteredList(matchedItems);
    } else {
      setFilteredList(originalItem);
    }

  }, [search, setFilteredList, originalItem, selectedValue]);

  const classes = useStyles();
  const selectedData = dataItems.find((x) => x.id == selectedValue);
  const closeCreatePopup = () => {
    if (showCreatePopup == true) {
      setOpen(true);
      setShow(true);
      setIsCreated(true);
    } else {
      setOpen(false);
      setShow(false);
      setIsCreated(false);
    }
    setShowCreatePopup(false);
  };

  const addNewItem = (name: string, code?: string) => {
    // console.log("name", name)
    // console.log("code", code)

    if (code !== undefined) {
      if (addNewItemData) {
        addNewItemData(name, code);
      }
    } else {
      if (addNewItemData) {
        
        addNewItemData(name);
      }
    }


    var id = Math.max(...dataItems.map((d) => d.id));
    if (id) {
      id = id + 1;
      var newDataList = [...dataItems, { id: id, label: name, name: name }];
      setDataItems(newDataList);
      // setDataList(newDataList);
      setFilteredList(newDataList);
    }
    if (showCreatePopup == true) {
      setOpen(true);
      setShow(true);
      setIsCreated(true);
    } else {
      setOpen(false);
      setShow(false);
      setIsCreated(false);
    }
    setShowCreatePopup(false);
  };

  useEffect(() => {
    setWidth(mainContainer.current?.clientWidth as number);
    const resizer: any = window.addEventListener("resize", () =>
      setWidth(mainContainer.current?.clientWidth as number)
    );
    return () => window.removeEventListener("resize", resizer);
  }, []);

  const handleClick = (
    newPlacement: any,
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget);
    setPlacement(newPlacement);
  };

  const handleClickAway = () => {
    if (showCreatePopup == true || isCreated == true) {
      setOpen(true);
      setShow(true);
      setIsCreated(false);
    } else {
      setOpen(false);
      setShow(false);
      setIsCreated(false);
    }
    if (setIsRegion) {
      setIsRegion(false)
    }
    if (setIsPJType) {
      setIsPJType(false)
    }
    if (setIsPJStatus) {
      setIsPJStatus(false)
    }
    if (setIsCurrency) {
      setIsCurrency(false)
    }
    if (setIsPYStatus) {
      setIsPYStatus(false)
    }
  };

  const changeDropdownField = (value: string, id: number) => {
    // console.log("v d", {value, id})
    // if (isPayment) {
    //   // setPaymentTerms(Number(value))
    //   // setData((prev: baseObj[]) => {
    //   //   return prev.map((data: baseObj, index: number) => {
    //   //     if (index === Number(cell.row.id)) {
    //   //       var currencyData: any = id;
    //   //       if (isCode) {
    //   //         currencyData = id;
    //   //       }
    //   //       if (isText) {
    //   //         currencyData = value;
    //   //       }
    //   //       return { ...data, [cell.column.id]: currencyData };
    //   //     }
    //   //     return data;
    //   //   });
    //   // });
    // } else {
    // }

    setData((prev: baseObj[]) => {
      return prev.map((data: baseObj, index: number) => {
        if (index === Number(cell.row.id)) {
          var currencyData: any = id;
          if (isCode) {
            currencyData = id;
          }
          if (isText) {
            currencyData = value;
          }
          if (isValue) {
            currencyData = {
              id: id, name: value
            };
          }

          // if (isLeave) {
          //   currencyData = id;
          // }

          return { ...data, [cell.column.id]: currencyData };
        }
        return data;
      });
    });
  };

  const deleteHandler = (id: number) => {
    setDeleteID(id ? id : 0);
    setOpenDeleteopup(true);
  }

  const canBeOpen = open && Boolean(anchorEl);
  const id = canBeOpen ? 'spring-popper' : undefined;
  //console.log("selectedLabel ",selectedLabel,selectedLabelData)
  return (
    <div ref={mainContainer} className="h-full w-full">
      <CreateNewItemPopup
        isCurrency={isCurrency}
        isRegion={isRegion}
        isProjectType={isProjectType}
        isProjectStatus={isProjectStatus}
        isPaymentStatus={isPaymentStatus}
        show={showCreatePopup}
        setShow={setShowCreatePopup}
        addNewItem={addNewItem}
        closePopup={closeCreatePopup}
        title={addTitle}
      />

      <ClickAwayListener onClickAway={handleClickAway} >
        <Box sx={{ position: "relative" }} className="h-full w-full">
          <button
            onClick={(event) => {
              handleClick("bottom-start", event);
              setIsCreated(false);
              setShowCreatePopup(false);
              setOpen(!open);
              setShow(!show);
              if (setIsRegion) {
                setIsRegion(true);
              }
              if (setIsPJType) {
                setIsPJType(true);
              }
              if (setIsPJStatus) {
                setIsPJStatus(true)
              }
              if (setIsCurrency) {
                setIsCurrency(true)
              }
              if (setIsPYStatus) {
                setIsPYStatus(true)
              }
            }}
            className={
              classNames(isEditable == true ? 'bg-vorpmyanmar text-black2' :
                'bg-offwhite',
                isBorder == false?'':'border',
                customClass,
          "flex w-full items-center justify-between h-full min-h-[35px] transition-all duration-300  px-[10px]  border-vorpgraylight custom-dropdown-container")
            }
          >
          <p className="whitespace-nowrap">
            {isCurrency === true ?
              (selectedLabelData?.sing ?
                ((selectedLabelData ? (selectedLabelData?.label + "(" + selectedLabelData?.sing + ")")
                  : "Select")) :
                ((selectedLabelData ?
                  (selectedLabelData?.name + "(" + selectedLabelData?.label + ")") : selectedLabel)))
              :
              selectedLabel ? selectedLabel : 'Select'
            }
          </p>
          {
            !isCurrency && (selectedLabelData?.name + '' + selectedLabelData?.label).toString().length > 18 && (
              <>
                <Tooltip
                  arrow
                  classes={{ tooltip: classes.tooltip }}
                  enterTouchDelay={0}
                  leaveTouchDelay={5000}
                  placement="left"
                  title={
                    <>
                      <div className="text-white text-[10px] hover:cursor-pointer leading-[15px] z-[2] rounded p-4 w-full bg-tooltipbg">
                        <span className="block">{(selectedLabelData?.name + '(' + selectedLabelData?.label + ')')}</span>
                      </div>
                    </>
                  }
                >
                  <span className="font-semibold cursor-pointer">+</span>
                </Tooltip>
                <div className="relative hover:cursor-pointer pl-1"></div>
              </>
            )}
          <ChevronDown />
        </button>
        {
          show ? (
            anchorEl && <Popper id={id} open={open} anchorEl={anchorEl} transition>
              {({ TransitionProps }) => (
                <Fade {...TransitionProps}>
                  <div>
                    <div
                      style={{ width }}
                      className={classNames(
                        styles.customList,
                        customHeight ? customHeight : "min-h-[317px]",
                        "rounded-4 overflow-hidden w-full max-w-[218px] z-10 bg-white"
                      )}
                    >
                      <div className="bg-offwhite  py-[14px] px-5">
                        <div className={classNames("flex items-center justify-between mb-2")}>
                          <h2 className="text-15 text-[#3F4254] font-medium">
                            {title}
                          </h2>
                          {
                            !isPaymentTerm ? (
                              <button
                                type="button"
                                className={classNames((cell.id.includes("gender") ? "hidden" : "flex"), isShowAdd == false ? 'hidden' : '', "hover:cursor-pointer")}
                                onClick={() => {
                                  setShowCreatePopup(true);
                                  if (setIsCurrency) {
                                    setIsCurrency(false)
                                  }
                                  if (setIsRegion) {
                                    setIsRegion(false)
                                  }
                                  if (setIsPJType) {
                                    setIsPJType(false)
                                  }
                                  if (setIsPJStatus) {
                                    setIsPJStatus(false)
                                  }
                                  if (setIsPYStatus) {
                                    setIsPYStatus(false)
                                  }
                                }}
                              >
                                <AddIcon color="#464E5F" />
                              </button>
                            ) : null
                          }

                        </div>
                        <div className="relative">
                          <DebounceSearchInput setState={SetSearch} debounce={400}>
                            {(setState) => (
                              <input
                                type="text"
                                placeholder="Search"
                                className="py-2 px-4 border border-vorpgraylight rounded-4 focus:outline-none w-full text-14 leading-5 text-vorpgraylight"
                                onChange={(e) => setState(e.target.value)}
                                onKeyDown={(e) => e.stopPropagation()}
                              />
                            )}
                          </DebounceSearchInput>
                          <SearchIcon className="absolute top-0 bottom-0 my-auto right-4" />
                        </div>
                      </div>
                      <div className="flex flex-col overflow-y-auto w-full max-h-[222px] bg-white">
                        {filteredList?.map((item, index) => (
                          <div key={Math.random() * 1000 * index} className={classNames(item.id == 0 ? "hidden" : "flex", " flex-row items-center justify-between hover:bg-vorpblue hover:text-white  text-graydark")}>
                            <button
                              type="button"
                              onClick={() => {
                                setSelectedLabel(item.label);
                                setSelectedData(item.id);
                                // setSelectedName(true);
                                setValue(item.id);
                                setSelectedValue(item.id);
                                setOpen(false);
                                setShow(false);
                                setIsCreated(false);
                                changeDropdownField(item.name, item.id);
                              }}
                              // key={item.id * Math.random() * 100}
                              className="capitalize focus:outline-none flex items-center justify-between group px-5 py-2  transition-all duration-300 text-left text-14 leading-5"
                            >
                              {item.name}
                            </button>
                            {
                              !isPayment ? (
                                <button
                                  key={item.id * Math.random() * 100}
                                  type="button"
                                  onClick={() => {
                                    deleteHandler(Number(item?.id))
                                  }}
                                  className={classNames(isShowDelete == false ? 'hidden' : 'block', "m-1 w-5 h-5 flex justify-center items-center group rounded-md bg-redPink hover:bg-redPinkLight transition-all duration-300")}
                                >
                                  <TrashIcon />
                                </button>
                              ) : null
                            }

                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </Fade>
              )}
            </Popper>
          ) : null
        }

      </Box>
    </ClickAwayListener>
    </div >
  );
};

export default CustomDropdownList;
