import {
  ColumnDef,
  ColumnFiltersState,
  PaginationState,
  RowSelectionState,
  SortingState,
  createColumnHelper,
} from "@tanstack/react-table";
import React, {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import EditableCell from "../Tables/EditableCell";
import { baseObj } from "../../utils/constants";
import {
  renderDateFormatDMY,
  renderDateFormatYMD,
} from "../DashboardCalendarView/DashboardAddEventPopup";
import DatePopup from "../EmployeeTable/DatePopup";
import classNames from "classnames";
import {
  CancelIcon,
  CheckIcon,
  CloseSmallIcon,
  EditIcon,
  TableSaveIcon,
  TrashIcon,
  ViewIcon,
} from "../common/Icons";
import styles from "./TaskListTable.module.scss";
import LayoutAdjustableTable from "../Tables/LayoutAdjustableTable";
import { templateData } from "../../app/services/template";
import TablePaginator from "../Tables/TablePaginator";
import DeletePopup from "../DeletePopup/DeletePopup";
import { toast } from "react-toastify";
import { makeStyles } from "@mui/styles";
import ErrorPopup from "../ErrorPopup/ErrorPopup";
import { getAuthUser } from "../../app/services/dashboard";
import { useDispatch, useSelector } from "react-redux";
import { setProfileNotification } from "../../reducers/dashboard";
import {
  useDeleteClaimTypeMutation,
  useMultiDeleteClaimTypeMutation,
} from "../../app/services/claim-type";
import { useNavigate } from "react-router-dom";
import CustomDropdownWithAddButton from "../EmployeeTable/CustomDropdownWithAddButton";
import { EmployeeData } from "../EmployeeTable/EmployeeTable";
import { Modal, Tooltip } from "@mui/material";
import AssignPopup from "../Passwords/AssignPopup";
import { KeyValueLabel } from "../../app/services/password";
import CommonTableBottom from "../CommonTableBottom/CommonTableBottom";
interface IOTTable {
  isLayoutControlOpen: boolean;
  setIsLayoutControlOpen: Dispatch<SetStateAction<boolean>>;
  pagination: PaginationState;
  setPagination: Dispatch<SetStateAction<PaginationState>>;
  globalSearch: string;
  setGlobalSearch: Dispatch<SetStateAction<string>>;
  columnFilters: ColumnFiltersState;
  setColumnFilters: Dispatch<SetStateAction<ColumnFiltersState>>;
  data: baseObj[];
  setData: Dispatch<SetStateAction<baseObj[]>>;
  hiddenFields: string[];
  templateList: templateData[];
  setHiddenFields: any;
  setCurrentView: any;
  total: number;
  changePage: any;
  setCurrentPage: any;
  currentPage: number;
  setIsEdit: Dispatch<SetStateAction<boolean>>;
  isEditing?: boolean;
  setIsTemplate?: Dispatch<SetStateAction<boolean>>;
  localModuleId: number;
  visArray: any;
  setCols?: any;
  setIsSuccess?: React.Dispatch<React.SetStateAction<boolean>>;
  isSuccess?: boolean;
  setSkip: React.Dispatch<React.SetStateAction<boolean>>;
  sorting: SortingState;
  setSorting: React.Dispatch<React.SetStateAction<SortingState>>;
  manageClaim?: boolean;
  totalTime?:string;
}

const useStyles = makeStyles(() => ({
  tooltip: {
    backgroundColor: "transparent",
    margin: 0,
    marginRight: 8,
    padding: 0,
  },
}));

const tagsTasks = [
  {
    id: 1,
    name: "UIUX",
  },
  {
    id: 1,
    name: "Frontend",
  },
  {
    id: 1,
    name: "Backend",
  },
];

const TaskListTable: FC<IOTTable> = ({
  isLayoutControlOpen,
  setIsLayoutControlOpen,
  pagination,
  setPagination,
  globalSearch,
  setGlobalSearch,
  columnFilters,
  setColumnFilters,
  data,
  setData,
  hiddenFields,
  total,
  changePage,
  setCurrentPage,
  currentPage,
  setIsEdit,
  isEditing,
  setIsTemplate,
  localModuleId,
  visArray,
  setCols,
  setSkip,
  setSorting,
  sorting,
  manageClaim,
  totalTime
}) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [customClass, setCustomClass] = useState<string>('');
  const columnHelper = useMemo(() => createColumnHelper<any>(), []);
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  const [deleteID, setDeleteID] = useState<number | 0>();
  const [deleteRowID, setDeleteRowID] = useState<number | 0>();
  const [openDeletePopup, setOpenDeleteopup] = useState<boolean>(false);
  const [clickLoading, setClickLoading] = useState<boolean>(false);
  const [isShow, setIsShow] = useState<boolean>(false);
  const [cellData, setCellData] = useState<any>();
  const [editedTagData, setEditedTagData] = useState<any>();
  const [showTagPopup, setShowTagPopup] = useState<boolean>(false);
  const [filterTagData, setFilterTagData] = useState<KeyValueLabel[]>(
    tagsTasks ?? []
  );
  const [uploading, setUploading] = useState<boolean>(false);
  const [timeSpentPos, setTimeSpentPos] = useState<number>();
  const [totalPos, setTotalPos] = useState<number>();
  const [bottom, setBottom] = useState<number>();
  const [itemCount, setItemCount] = useState<number>(total);
  const selectRowsCount = Object.keys(rowSelection).length;
  const [errorPopup, setErrorPopup] = useState<boolean>(false);
  const [message, setMessage] = useState<any>([]);
  const [deleteClaimType] = useDeleteClaimTypeMutation();

  const [deleteClaimListByIDs] = useMultiDeleteClaimTypeMutation();
  const dispatch = useDispatch();
  const stateData: any = useSelector((state: any) => state);
  const [notifications, setNotifications] = useState<any>(
    stateData?.dashboard?.notificationDetail
  );
  const createTagData = (label: string) => {
    // var data = {
    //   name: label,
    //   flag: "pwd",
    // };
    // return createTag(data);
  };

  const deleteOTData = useCallback(
    (id?: any, uniqueID?: number) => {
      setDeleteID(id);
      setDeleteRowID(uniqueID);
      setOpenDeleteopup(true);
    },
    [setData, rowSelection]
  );
  const divRef = useRef(null);
  const [width, setWidth] = useState<number>();
  const calculateTotalSpentTime = () => {
    var width=document.getElementById('sidebar')?.getBoundingClientRect()?.width;
    var showSidebar = document.getElementById('sidebar')?.classList[0].includes('hidden');
    var sidebarWidth = !showSidebar ? width ? width : 0 : 0;
    const left = document.getElementById('headertime_spent_text')?.getBoundingClientRect()?.left;
    const total =document.querySelector('table tr td:first-child')?.getBoundingClientRect().left;
    setTotalPos(total ? total : 0)
    setTimeSpentPos(left ? left-sidebarWidth: 0);
    const bottom = document.getElementById('table-scroll')?.getBoundingClientRect()?.bottom;
    setBottom(bottom ? bottom : 0)
    if (left == undefined) {
      setCustomClass('hidden')
    }
  }
  const handleScroll = () => {
    calculateTotalSpentTime();
  };
  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      setWidth(entries[0].contentRect.width);
    });

    if (divRef.current) {
      observer.observe(divRef.current);
    }

    return () => {
      divRef.current && observer.unobserve(divRef.current);
    };
  }, []);
  useEffect(() => {
    calculateTotalSpentTime();
  }, [width])
  useEffect(() => {
    calculateTotalSpentTime()
  }, [data])
  useEffect(() => {
    const handleResize = () => {
      calculateTotalSpentTime();
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  useEffect(() => {
    const handleScroll = () => {
      calculateTotalSpentTime()
    };
    document.getElementById('content-body')?.addEventListener('scroll', handleScroll);
    return () => {
      document.getElementById('content-body')?.removeEventListener('scroll', handleScroll);
    };
  }, []);
  const deleteClaimItem = useCallback(
    (id?: number, dataId?: number) => {
      setClickLoading(true);
      const selectedRows = Object.keys(rowSelection);
      setData((prev: baseObj[]) => {
        if (dataId != undefined) {
          deleteClaimType(dataId)
            .unwrap()
            .then((payload: any) => {
              setClickLoading(false);
              setOpenDeleteopup(false);
              toast("Successfully Delete.", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                type: "success",
              });
            })
            .catch((error: any) => {
              setErrorPopup(true);
              setOpenDeleteopup(false);
              setClickLoading(false);
              setMessage(
                error
                  ? [error?.data.message]
                  : ["Something went wrong!Trying again."]
              );
            });
        }
        return prev.filter((_, index) => {
          return id !== undefined
            ? id !== index
            : !selectedRows.includes(String(index));
        });
      });
      id === undefined && setRowSelection({});
    },
    [setData, rowSelection]
  );

  const deleteClaimMultiple = (ids: number[]) => {
    setClickLoading(true);
    deleteClaimListByIDs(ids)
      .unwrap()
      .then((payload: any) => {
        setClickLoading(false);
        setOpenDeleteopup(false);
        toast("Successfully Delete.", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          type: "success",
        });
      })
      .catch((error: any) => {
        setClickLoading(false);
        setErrorPopup(true);
        setOpenDeleteopup(false);
        setMessage(
          error ? [error?.data.message] : ["Something went wrong!Trying again."]
        );
      });
    setRowSelection({});
  };

  const confirmDeleteData = () => {
    var id = deleteID;
    var dataID = deleteRowID;
    const selectedRows = Object.keys(rowSelection);
    var selectionIds = data
      .filter((x, index: number) => {
        return selectedRows.find((s) => s == index.toString()) ? x.id : 0;
      })
      .map((x) => x.id);

    if (selectionIds.length > 0) {
      deleteClaimMultiple(selectionIds);
    } else {
      if (id) {
        deleteClaimItem(id, dataID);
        setDeleteID(-1);
        setDeleteRowID(-1);
      }
    }
  };

  const addDesignationData = useCallback(() => { }, []);

  const canCeelRow = (id: number) => {
    if (isEditing) {
      setIsEdit(false);
      setData((prev: baseObj[]) => {
        return prev.map((data, index: number) => {
          if (data.id == id) {
            return { ...data, isNew: false };
          }
          return data;
        });
      });
    } else {
      const filterData = data?.filter((d: any) => d?.id !== id);
      setData(filterData);
    }
  };

  const columns = useMemo(
    () => [
      columnHelper.accessor("code", {
        header: "#",
        id: "code",
        enableSorting: true,
        cell: ({ getValue, cell }) => {
          const id = getValue();
          return (
            <div className=" text-left bg-white text-black2 px-[10px] font-normal">
              <p className="h-full flex items-center">{id}</p>
            </div>
          );
        },
      }),
      columnHelper.accessor("project_code", {
        header: "Project ID",
        id: "project_code",
        enableSorting: true,
        cell: ({ getValue, cell }) => {
          const project_id = getValue();
          return (
            <div className={`text-left font-normal`}>
              <span className="px-2 flex h-full items-center">{project_id}</span>
            </div>
          );
        },
      }),
      columnHelper.accessor("project_name", {
        header: "Project Name",
        id: "project_name",
        enableSorting: true,
        cell: ({ getValue, cell }) => {
          const project_name = getValue();
          return (
            <div className={`text-left font-normal`}>
              <span className="px-2 flex h-full items-center">{project_name}</span>
            </div>
          );
        },
      }),
      columnHelper.accessor("task_name", {
        header: "Task Name",
        id: "task_name",
        enableSorting: true,
        cell: ({ getValue, cell }) => {
          const id = getValue();
          return (
            <div className={`text-left font-normal relative overflow-hidden mx-2`}>
              <span className="px-2 flex h-full items-center absolute">{id}</span>
            </div>
          );
        },
      }),
      columnHelper.accessor("task_category_name", {
        header: "Task Tags",
        id: "task_category_name",
        cell: ({ getValue, cell }) => {
          const department = getValue();
          return (
            <div className={`text-left font-normal flex items-center`}>
              <span className="px-2 py-1 block items-center bg-[#C9F7F5] mx-2 text-[#1BC5BD] rounded-[5px]">{department}</span>
            </div>
          );
        },
      }),
      columnHelper.accessor("employee_name", {
        header: "Employee Name",
        id: "employee_name",
        cell: ({ getValue, cell }) => {
          const employee_name = getValue();
          return (
            <div className={`text-left font-normal flex items-center`}>
              <span className="px-2 py-1 block items-center ">{employee_name}</span>
            </div>
          );
        },
      }),
      columnHelper.accessor("project_manager_name", {
        header: "Project Manager",
        id: "project_manager_name",
        enableSorting: true,
        cell: ({ getValue, cell }) => {
          const designation = getValue();
          const project_manager_code = cell.row.original?.project_manager_code;
          return (
            <div className={`text-left font-normal`}>
              <span className="px-2 flex h-full items-center text-[#858795]">{designation} - {project_manager_code}</span>
            </div>
          );
        },
      }),
      columnHelper.accessor("team_leader_name", {
        header: "Team Lead",
        id: "team_leader_name",
        enableSorting: true,
        cell: ({ getValue, cell }) => {
          const team_leader_name = getValue();
          const team_leader_code = cell.row.original?.team_leader_code;
          return (
            <div className={`text-left font-normal`}>
              <span className="px-2 flex h-full items-center text-[#858795]">{team_leader_name} - {team_leader_code}</span>
            </div>
          );
        },
      }),
      columnHelper.accessor("date", {
        header: "Date",
        id: "date",
        enableSorting: true,
        cell: ({ getValue, cell }) => {
          const date = getValue();
          return (
            <div className={`text-left font-normal`}>
              <span className="px-2 flex h-full items-center">{date}</span>
            </div>
          );
        },
      }),
      columnHelper.accessor("time_spent_text", {
        header: "Time Spent",
        id: "time_spent_text",
        enableSorting: true,
        cell: ({ getValue, cell }) => {
          const status: number = getValue();
          return (
            <div className={`text-left font-normal`}>
              <span className="px-2 flex h-full items-center">{status}</span>
            </div>
          );
        },
      }),

      // columnHelper.display({
      //   header: "Action",
      //   id: "actions",
      //   enableSorting: false,
      //   // `${
      //   //   manageClaim || data?.find((x) => x.isNew == true)
      //   //     ? "actions"
      //   //     : "none-actions"
      //   // }`,
      //   cell: ({ cell }) => {
      //     var isHaveNew = data?.find((x) => x.isNew == true);
      //     var disabledClass = isHaveNew ? "opacity-50 pointer-events-none" : "";
      //     const isNew: boolean = cell.row.original?.isNew;
      //     return (
      //       <div
      //         className={classNames(
      //           styles.actionedBtn,
      //           "px-2 flex justify-center items-center min-w-max max-w-[98px]"
      //         )}
      //       >
      //          <button
      //               onClick={() => {
      //                 navigate(`/progress-checker/${cell.row.original?.project_code}/task-detail-list`)
      //                 //   localStorage.setItem(
      //                 //     "state",
      //                 //     cell.row.original.uniqeID
      //                 //   );
      //                 //   window.open("/employeeprofile/"+cell.row.original.uniqeID, "_blank", "noreferrer");
      //               }}
      //               className={classNames(
      //                 styles.customViewdBtn,
      //                 disabledClass,
      //                 "w-6 h-6 mr-2 flex justify-center items-center group rounded-md bg-darkpurple hover:bg-[#EAE0FF] transition-all duration-300"
      //               )}
      //             >
      //               <ViewIcon />
      //             </button>
      //       </div>
      //     );
      //   },
      // }),
    ],
    [columnHelper, data, hiddenFields]
  );

  const visibleColumns: { [key: string]: boolean } = {};
  hiddenFields?.map((x) => {
    visibleColumns[x] = true;
  });

  document.querySelectorAll("th,td").forEach((x) => {
    if (x.id.includes("none")) {
      x.classList.add("hidden");
    }

    var div = document.getElementById(x.id);
  });

  if (true) {
    visibleColumns["actions"] = true;
  } else {
    visibleColumns["actions"] = false;
  }

  if (hiddenFields.length > 0) {
    columns.map((x) => {
      if (x.id == "actions" || x.id == "selection" || x.id == "lasted") {
        visibleColumns[x.id ? x.id : ""] = true;
      } else if (x.id == "uniqeID") {
        visibleColumns[x.id ? x.id : ""] = false;
      } else {
        var isHidden = hiddenFields.find((h) => h == x.id);
        if (isHidden) {
          visibleColumns[x.id ? x.id : ""] = true;
        } else {
          visibleColumns[x.id ? x.id : ""] = false;
        }
      }
    });
  }

  const [columnOrder, setColumnOrder] = useState<string[]>(
    columns.map((column: ColumnDef<any, any>) => column.id as string)
  );

  return (
    <div ref={divRef} id="progress-checker-acc" className={classNames(styles.otListTable, "relative")}>
      <ErrorPopup
        descText="Error Message!"
        confirmBtnText="Yes, Close."
        show={errorPopup}
        setShow={setErrorPopup}
        message={message}
        setMessage={setMessage}
      />

      <LayoutAdjustableTable
        hideColumn={visibleColumns}
        columns={columns}
        data={data}
        sorting={sorting}
        setSortingState={setSorting}
        rowSelection={rowSelection}
        setRowSelection={setRowSelection}
        columnOrder={columnOrder}
        setColumnOrder={setColumnOrder}
        unDraggableFields={["actions", "selection", "id"]}
        theaderColor="text-graydark"
        isLayoutControlOpen={isLayoutControlOpen}
        setIsLayoutControlOpen={setIsLayoutControlOpen}
        label="Task List"
        setItemCount={setItemCount}
        pagination={pagination}
        setPagination={setPagination}
        globalFilter={globalSearch}
        setGlobalFilter={setGlobalSearch}
        columnFilters={columnFilters}
        setColumnFilters={setColumnFilters}
        noPaddingCells={[]}
        localModuleId={localModuleId}
        visArray={visArray}
        setIsTemplate={setIsTemplate}
        setCols={setCols}
        handleScroll={handleScroll}
      />
      <CommonTableBottom data={[
        {
          name: totalTime ? "Total" : '',
          colSpan: 5,
          left: 0,
          bottom: bottom,
          classes: totalTime ? customClass : 'hidden'
        },
        {
          name: totalTime ? totalTime : '',
          colSpan: 3,
          left: timeSpentPos,
          bottom: bottom,
          classes: totalTime ? customClass : 'hidden'
        }]} />
      {selectRowsCount > 0 && (
        <div className="flex items-center text-14 leading-5 font-semibold text-black2 mt-3 mb-3 lg:mt-3 lg:mb-3 ">
          <p>{selectRowsCount} selected.</p>
          <button
            type="button"
            onClick={() => setOpenDeleteopup(true)}
            className=" flex items-center ml-5 text-12 leading-[18px] font-semibold text-redPink"
          >
            <div className="w-6 h-6 bg-redPink flex items-center group justify-center rounded-md mr-[10px] hover:bg-[#FFEAEF]  transition-all duration-300 ">
              <TrashIcon width={10} height={12} />
            </div>
            Delete
          </button>
        </div>
      )}
      <div className="pt-2">
        <TablePaginator
          total={itemCount}
          pagination={pagination}
          setPagination={setPagination}
          itemCount={total}
          setCurrentPage={setCurrentPage}
          currentPageDefault={currentPage}
          changePage={changePage}
        />
      </div>
      <DeletePopup
        descText="Are you sure to delete?"
        confirmBtnText="Yes, delete."
        show={openDeletePopup}
        setShow={setOpenDeleteopup}
        confirmDeleteData={confirmDeleteData}
        loading={clickLoading}
      />

      <Modal
        open={isShow}
        style={{
          background: "rgb(0 0 0 / 0.5)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        onClose={() => setIsShow(false)}
        aria-labelledby="Password Add Modal"
        aria-describedby="Add New Password Form Pop up"
        disableEnforceFocus={true}
      >
        <div className=" max-w-[558px] w-full m-auto">
          <AssignPopup
            createTagData={createTagData}
            editRowData={editedTagData}
            showTagPopup={showTagPopup}
            setIsOpen={setIsShow}
            modalType="assign"
            options={filterTagData}
            cell={cellData}
            setTags={() => 1}
            data={data}
            setData={setData}
          />
        </div>
      </Modal>
    </div>
  );
};
export default TaskListTable;
