import { ReactElement } from 'react';
import BasicLayout from '../layouts/BasicLayout';
import { Outlet } from 'react-router-dom';
import PerformanceAppraisalDetail from './views/PerformanceAppraisalDetail';

const PerformanceAppraisalDetailRoute = (props: any): ReactElement => {
    const data = props.loginData;
    const permissions = props.permissions;
    return (
        <BasicLayout userData={data} permissions={permissions}>
            <PerformanceAppraisalDetail />
            <Outlet />
        </BasicLayout>
    )
};

export default PerformanceAppraisalDetailRoute;
