import React from "react";
import { StringDropdownListWithDeleteBtn } from "../common/StringDropdownListWithDeleteBtn";
import CustomScrollbar from "../CustomScrollbar/CustomScrollbar";
import CustomDropdownListForFilter from "../EmployeeTable/CustomDropdownListForFilter";
import DatePopupFoFilter from "../EmployeeTable/DatePopupFoFilter";
import { NumberDropdownList } from "../common/DropdownList";
import { AddIcon, CloseIcon, CustomizeLayoutIcon } from "../common/Icons";
import { PaginationState } from "@tanstack/react-table";
import { IDepartment, TStatusListData } from "../../ot-list/views/OTList";
import classNames from "classnames";

export type OT2Component = {
  department: string | number;
  setDepartment: React.Dispatch<React.SetStateAction<string | number>>;
  setPagination: React.Dispatch<React.SetStateAction<PaginationState>>;
  pagination: PaginationState;
  departData: IDepartment[];
  startDate: Date | undefined;
  setStartDate: React.Dispatch<React.SetStateAction<Date | undefined>>;
  setEndDate: React.Dispatch<React.SetStateAction<Date | undefined>>;
  endDate: Date | undefined;
  setLimit: React.Dispatch<React.SetStateAction<number>>;
  setIsTemplate: React.Dispatch<React.SetStateAction<boolean>>;
  isTemplate: boolean;
  currentViewData: number;
  currentView: any;
  setCurrentView: React.Dispatch<any>;
  deleteTemplateData: (id: string) => void;
  moduleList: any;
  setErrorPopup: React.Dispatch<React.SetStateAction<boolean>>;
  setSuccessPopup: React.Dispatch<React.SetStateAction<boolean>>;
  setMessage: React.Dispatch<any>;
  setIsLayoutControlOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setOtStatus :React.Dispatch<any>;
  otStatus: any;
  statusListData: TStatusListData[];
};
const OT2Component = ({
  department,
  setDepartment,
  setPagination,
  pagination,
  departData,
  startDate,
  setStartDate,
  setEndDate,
  endDate,
  setLimit,
  setIsTemplate,
  isTemplate,
  currentViewData,
  currentView,
  setCurrentView,
  deleteTemplateData,
  moduleList,
  setErrorPopup,
  setSuccessPopup,
  setMessage,
  setIsLayoutControlOpen,
  otStatus,
  setOtStatus,
  statusListData,
}: OT2Component) => {
  return (
    <div className="flex sm:flex-row flex-col justify-between items-center mb-1">
      <div className="flex w-full sm:mr-5 custom-scrollbar sm:mt-0 mt-1 sm:mb-0 mb-1">
        <CustomScrollbar isVertical={false} isHor={true}>
          <div className="flex w-full items-center">
            <div className={`w-auto flex items-center mr-3`}>
              <p className="htzxs:w-auto w-1/2 text-left base-label xl:text-sm text-12 hidden">
                Department
              </p>
              <CustomDropdownListForFilter
                isFilter={true}
                state={department}
                setState={setDepartment}
                dropdownItems={
                  departData
                    ? departData.map((dp) => ({
                        value: dp.id,
                        label: dp.name,
                      }))
                    : []
                }
                className=" 2xl:w-32 w-28 min-w-[100px] 6xs:text-12"
                placeholder="Department"
                textAlign="text-left py-2 pl-2 pr-3"
              />
            </div>
            <div className="flex items-center w-auto">
              <p className="hidden whitespace-nowrap sm:w-auto 2xs:w-[20%] w-1/2 text-left  uppercase xl:text-sm text-12 leading-5 font-medium text-black2 mr-[10px]">
                Leave Date
              </p>
              <div className="flex flex-row w-full">
                {startDate && endDate && (
                  <button
                    type="button"
                    onClick={() => {
                      setStartDate(undefined);
                      setEndDate(undefined);
                    }}
                    className="text-12 mr-2"
                  >
                    <CloseIcon color="#EE436D" width={10} height={10} />
                  </button>
                )}
                <div className={` mr-[10px] htzxs:w-auto w-full`}>
                  <DatePopupFoFilter
                    placeholder="Start"
                    date={startDate}
                    setDate={setStartDate}
                  />
                </div>
                <div className={`htzxs:w-auto w-full`}>
                  <DatePopupFoFilter
                    placeholder="End"
                    date={endDate}
                    setDate={(value) => setEndDate(value)}
                  />
                </div>
                <div
                  className={classNames("flex items-center mr-[10px] w-auto ml-2")}
                >
                  <p className="hidden sm:w-auto w-1/2 text-left font-poppins font-medium xl:text-sm text-12 text-[#464E5F] mr-1">
                    Status
                  </p>
                  <CustomDropdownListForFilter
                    placeholder="Status"
                    state={otStatus}
                    setState={setOtStatus}
                    dropdownItems={
                      statusListData
                        ? statusListData.map((st) => ({
                            value: st.id,
                            label: st.name,
                          }))
                        : []
                    }
                    textAlign="text-left"
                    className="max-h-[38px] max-w-full sm:max-w-[250px] 2xl:w-32 sm:w-28 w-full"
                  />
                </div>
              </div>
            </div>
          </div>
        </CustomScrollbar>
      </div>
      <div className="flex items-center justify-between md:justify-start sm:w-auto w-full">
        <div className="flex items-center  mr-3">
          <p className="base-label xl:text-sm text-12 hidden 3xs:block">
            DISPLAY
          </p>
          <NumberDropdownList
            state={pagination.pageSize}
            setState={(value) => {
              setPagination((prev) => ({
                ...prev,
                pageSize: value as number,
              }));
              setLimit(value);
            }}
            dropdownItems={new Array(5).fill(1).map((_, index: number) => {
              let display = (index + 1) * 20;
              return {
                value: display,
                label: String(display),
              };
            })}
            className="max-w-[72px] pl-2 pr-3"
            textAlign="text-left pl-2 pr-3"
            placeholder="20"
            paddingClass="py-[2px]"
          />
        </div>
        <StringDropdownListWithDeleteBtn
          haveDeleteBtn={true}
          setIsTemplate={setIsTemplate}
          state={isTemplate ? currentViewData : currentView}
          setState={setCurrentView}
          deleteTemplate={deleteTemplateData}
          dropdownItems={moduleList.map((x: any, index: number) => {
            return {
              label: x.id == -1 ? x.name : "View #" + x.index,
              value: x.id,
            };
          })}
          className="w-full min-w-[100px] 2xl:min-w-[137px] 6xs:text-12"
          placeholder="View #1"
          textAlign="text-left py-2 pl-2 pr-3"
          custPadding="py-[2px]"
          setErrorPopup={setErrorPopup}
          setSuccessPopup={setSuccessPopup}
          setMessage={setMessage}
        />
        <button
          type="button"
          onClick={() => setIsLayoutControlOpen(true)}
          className="flex relative justify-center items-center rounded-lg bg-vorphongkongborder w-[29px] h-[29px] ml-2 group transition-all duration-300 "
        >
          <CustomizeLayoutIcon pathClassName="group-hover:fill-white" />
          <div className="absolute left-7 top-[-5px] border border-white w-4 h-4 rounded-full bg-vorphongkongborder hover:bg-vorphongkongborder flex justify-center items-center">
            <AddIcon width={8} height={8} color="white" />
          </div>
        </button>
      </div>
    </div>
  );
};

export default OT2Component;
