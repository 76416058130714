import classNames from "classnames";
import { assignedColor } from "../Documents/EmployeeDocumentsTable";
import { memberStatusList } from "./MemberStatus";
import { useEffect } from "react";

const Member = ({ photo, name, status, idx, time }: any) => {    
    useEffect(()=>{
        console.log('status ',status,name,idx,time)
    },[status])
    var current_status = memberStatusList?.find(x => x.name.toLocaleLowerCase() == status?.toLocaleLowerCase());
    return <div className="flex items-center mt-3" key={idx}>
        {photo ? <div className={classNames(current_status?.value == "in" ? 'before:bg-vorpgreen' : (current_status?.value == "out") ?
            'before:bg-voyellow' : 'before:bg-vooffgray',
            " before:absolute before:bottom-[2px] before:right-[-2px] before:content-[''] before:w-[10px] before:h-[10px] before:border before:rounded-full relative 4xl:w-10 4xl:h-10 w-8 h-8 max-w-[40px] max-h-[40px] 4xl:mr-2 mr-1 rounded-full before:z-[1]")}>
            <img src={photo} alt="photo"
                className={classNames(
                    "4xl:w-10 4xl:h-10 w-8 h-8 max-w-[40px] max-h-[40px] rounded-full object-cover relative")} />
        </div> :
            <div className={classNames("4xl:w-10 4xl:h-10 w-8 h-8 rounded-full 4xl:mr-2 mr-1")} style={{ backgroundColor: assignedColor[idx] ? assignedColor[idx] : assignedColor[0] }}>
                <p className={classNames(
                    current_status?.value == "in" ? 'before:bg-vorpgreen' : (current_status?.value == "out") ? 'before:bg-voyellow' : 'before:bg-vooffgray',
                    "flex h-full justify-center items-center text-white font-bold relative before:absolute before:bottom-[2px] before:right-[-2px] before:content-[''] before:w-[10px] before:h-[10px] before:border before:rounded-full")}>{name.substring(0, 1)}</p>
            </div>}
        <div>
            <p className="font-medium text-black2 text-14">{name}</p>
            <div className="flex items-center">
                {current_status?.value == "in" || current_status?.value == "out" || current_status?.value == "off" || current_status?.value == "Leave" ?
                    <p className={classNames(current_status?.value == "in" ? 'bg-vorpgreen' :
                        current_status?.value == "Leave" ? 'bg-vorpblue' :
                            (current_status?.value == "out") ? 'bg-voyellow' : current_status?.value == "off" ? 'bg-vooffgray' : 'bg-vooffgray', "font-medium text-white text-12 uppercase px-2 rounded-[9px] mr-1")}>
                        {current_status?.value == "off" ? "out" : current_status?.value}</p> : ''}
                <p className="font-medium text-black1 text-12">{current_status?.value!=="Leave"?time:''}</p>
            </div>
        </div>
    </div>
}
export default Member;