import { redirectToLogin } from "./annoucement-sheet";
import { api } from "./api";
import authHeader from "./auth-header";
import { Ids } from "./media";

type DesignationResponse = {
  module_id: number;
  status: boolean;
  designationList: DepartmentInfo[];
};

export type TData = {
  data: DepartmentInfo[];
  total: number;
};
export type DepartmentInfo = {
  id?: number;
  name: string;
  department_id:string;
};
type departmentInput = {
  // sort: string;
  // search: string;
};
export type TaskDepartmentList = {
  departmentList: TaskDepartmentListData[];
};
export type TaskDepartmentListData = {
  id: number;
  name: string;
};

export const designationApi = api.injectEndpoints({
  endpoints: (build) => ({
    getDesignations: build.query<
    DesignationResponse,
      departmentInput
    >({
      query: () => ({
        url: `designations`,
        headers: authHeader(),
      }),
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
      // providesTags: (result: any = []) => {
      //   if (result.data) {
      //     if (result.data.length > 0) {
      //       return [
      //         ...result.data.map(
      //           (id: any) => ({ type: "Department", id } as const)
      //         ),
      //         { type: "Departments" as const, id: "LIST" },
      //       ];
      //     } else {
      //       return [{ type: "Departments" as const, id: "LIST" }];
      //     }
      //   } else {
      //     return [{ type: "Departments" as const, id: "LIST" }];
      //   }
      // },
    }),
    createDesignation: build.mutation<TaskDepartmentList, DepartmentInfo>({
      query(body) {
        return {
          headers: authHeader(),
          url: `/designations`,
          body,
          method: "POST",
        };
      }
    }),
    updateDesignation: build.mutation<TaskDepartmentList, DepartmentInfo>({
      query(body) {
        return {
          headers: authHeader(),
          url: `/designations/${body.id}`,
          body,
          method: "PUT",
        };
      },
      // invalidatesTags: (employees) => {
      //   return [{ type: "Departments", id: "LIST" }];
      // },
    }),
    deleteDesignation: build.mutation<TaskDepartmentList, number>({
      query(id) {
        return {
          headers: authHeader(),
          url: `/designations/${id}`,
          method: "DELETE",
        };
      },
    }),
  }),
});

export const {
  useGetDesignationsQuery,
  useCreateDesignationMutation,
  useUpdateDesignationMutation,
  useDeleteDesignationMutation,
} = designationApi;

export const {
  endpoints: {
    getDesignations,
    createDesignation,
    updateDesignation,
    deleteDesignation,
  },
} = designationApi;
