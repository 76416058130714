import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import Button from "../common/Button";
import { CloseIcon, SendIcon } from "../common/Icons";
import { Fade, Modal } from "@mui/material";
import classNames from "classnames";
import "react-phone-input-2/lib/style.css";
import { toast } from "react-toastify";
import { useCreateProjectStatusMutation, useUpdateProjectStatusMutation } from "../../app/services/project-status";

export type TCreateProjectPopup = {
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  isOpen: boolean;
  editedData?: any;
  setEditData?: any;
};
const CreateProjectStatusPopup: FC<TCreateProjectPopup> = ({
  isOpen,
  setIsOpen,
  editedData,
  setEditData,
}) => {
//   console.log("edit", editedData);

  const [errorMsg, setErrorMsg] = useState<string>("");
  const [isSubmit, setIsSubmit] = useState<boolean>(false);
  const [uploading, setUploading] = useState<boolean>(false);
  const [name, setName] = useState<string>("");
  const [order, setOrder] = useState<string>("");
  const [saveProjectStatus] = useCreateProjectStatusMutation();
  const [updatedProjectStatus] = useUpdateProjectStatusMutation();
  const clearData = () => {
    setName("");
    setOrder("");
  };

  const checkValidation = (saveData: any) => {
    if (saveData.name === "" || saveData.order === "") {
      return true;
    } else {
      return false;
    }
  };

  const handleSubmit = async () => {
    setIsSubmit(true);
    setUploading(true);
    const saveData = {
      name,
      order: Number(order),
    };
    const check = checkValidation(saveData);

    if (!check) {
      if (editedData) {
        updatedProjectStatus({ ...saveData, id: editedData?.id })
          .unwrap()
          .then((payload: any) => {
            setErrorMsg("");
            setUploading(false);
            setEditData("");
            setIsSubmit(false);
            // console.log("p", payload);
            setIsSubmit(false);
            setIsOpen(false);
            clearData();
            toast("Successfully Updated.", {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              type: "success",
              theme: "light",
            });
          })
          .catch((error: any) => {
            // console.log("error", error);
            setIsSubmit(false);
            setUploading(false);
            setErrorMsg(error?.data?.message);
          });
      } else {
        saveProjectStatus(saveData)
          .unwrap()
          .then((payload: any) => {
            // console.log("p", payload);
            setEditData("");
            setErrorMsg("");
            setUploading(false);
            setIsSubmit(false);
            setIsOpen(false);
            clearData();
            toast("Successfully Saved.", {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              type: "success",
              theme: "light",
            });
          })
          .catch((error: any) => {
            setIsSubmit(false);
            setUploading(false);
            setErrorMsg(error?.data?.message);
          });
      }
    } else {
      setUploading(false);
    }
  };

  useEffect(() => {
    if (editedData) {
      setName(editedData.name);
      setOrder(String(editedData.order));
    }
  }, [editedData]);

  return (
    <Modal
      className="project_popup"
      open={isOpen}
      style={{
        background: "rgb(0 0 0 / 0.5)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      onClose={(event, reason) => {
        if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
          setIsOpen(false);
          setEditData("");
          setIsSubmit(false);
        }
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Fade in={isOpen} timeout={500}>
        <div className="max-w-[450px] w-full m-auto">
          <div className="modalContent w-full h-full p-6 lg:p-0 flex justify-center items-center relative z-[999]">
            <div className="rounded-xl bg-white font-primary w-full">
              <div className="px-2 sm:pl-4 sm:pr-4 py-3 bg-vorpblue flex justify-between rounded-t-lg items-center relative z-[999]">
                <h3 className="text-18 leading-[27px] font-semibold text-white">
                  {editedData ? "Update Project Status" : "Add  Project Status"}
                </h3>
                <button
                  key={Math.random() * 100}
                  type="button"
                  aria-label="Close Modal"
                  onClick={() => {
                    setIsOpen(false);
                    setIsSubmit(false);
                    setEditData("");
                    setUploading(false);
                    setErrorMsg("");
                    clearData();
                  }}
                >
                  <CloseIcon />
                </button>
              </div>

              <div className="flex flex-col justify-between w-full ">
                <div className="mb-0 last:mb-0 w-full px-3">
                  <label className="text-black2 text-15 font-medium font-primary mt-[0.600rem] block">
                    Name<span className=" text-red-500">*</span>
                  </label>
                  <input
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    type="text"
                    placeholder="Name"
                    className="focus:outline-none focus-visible:outline-none w-full rounded py-3.5 px-3 border border-goalinputborder text-black2 text-14 font-medium font-primary placeholder:text-graydark h-[48px]"
                  ></input>
                  <p
                    className={classNames(
                      isSubmit && name === "" ? "" : "hidden",
                      "mb-2 font-poppins text-12 text-redPink"
                    )}
                  >
                    Name required
                  </p>
                </div>
                <div className="mb-0 last:mb-0 w-full px-3">
                  <label className="text-black2 text-15 font-medium font-primary mt-[0.600rem] block">
                    Order<span className=" text-red-500">*</span>
                  </label>
                  <input
                    onKeyDown={(evt) =>
                      ["e", "E", "+", "-"].includes(evt.key) &&
                      evt.preventDefault()
                    }
                    onWheel={(e: any) => e.target.blur()}
                    value={order}
                    onChange={(e) => setOrder(e.target.value)}
                    type="number"
                    placeholder="Order"
                    className="focus:outline-none focus-visible:outline-none w-full rounded py-3.5 px-3 border border-goalinputborder text-black2 text-14 font-medium font-primary placeholder:text-graydark h-[48px]"
                  ></input>
                  <p
                    className={classNames(
                      isSubmit && order === "" ? "" : "hidden",
                      "mb-2 font-poppins text-12 text-redPink"
                    )}
                  >
                    Order required
                  </p>
                </div>
              </div>

              <div className="px-2 pb-5 pt-5 flex justify-between items-center">
                <div className="flex flex-wrap">
                  <div className="flex px-2 justify-center items-center">
                    <Button
                      type="button"
                      label={
                        <span className="flex items-center text-12 leading-[18px]">
                          <SendIcon className="mr-[10px] group-hover:opacity-0 transition-all duration-300" />
                          {editedData ? "Update" : "Save"}
                        </span>
                      }
                      onClick={() => {
                        handleSubmit();
                      }}
                      variant="no-outline"
                      size="lg"
                      customClass={classNames(
                        uploading
                          ? " pointer-events-none opacity-50"
                          : "",
                        "hover:bg-vorpblue mr-2 py-2 lg:py-2 px-3 custom-button-size"
                      )}
                    />

                    <Button
                      type="button"
                      label={
                        <span className="flex items-center text-12 leading-[18px] ">
                          <CloseIcon
                            width={14}
                            height={14}
                            className="mr-[10px] [&_path]:fill-graydark"
                          />
                          Cancel
                        </span>
                      }
                      onClick={() => {
                        setIsOpen(false);
                        setIsSubmit(false);
                        setEditData("");
                        setErrorMsg("");
                        clearData();
                        setUploading(false);
                        setIsSubmit(false);
                      }}
                      variant="no-outline"
                      size="lg"
                      customClass=" mr-2 py-2 lg:py-2 px-3 bg-offwhite text-black2 border-none custom-button-size"
                    />
                  </div>
                </div>
                <p
                  className={classNames(
                    isSubmit || errorMsg ? "" : "hidden",
                    "mb-2 font-poppins text-12 text-redPink"
                  )}
                >
                  {errorMsg}
                </p>
              </div>
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default CreateProjectStatusPopup;
