import { FC, ReactNode } from "react";
import { twMerge } from "tailwind-merge";
var classNames = require("classnames");

interface IButton {
  label: string | ReactNode;
  type: "button" | "submit";
  size: "lg" | "base" | "sm";
  variant: "primary" | "no-outline" | "#E1F0FF";
  onClick: () => void;
  customClass?: any; // For Inconsistence Button Sizes
  disabled?:boolean;
}

const Button: FC<IButton> = ({
  label,
  type = "button",
  size = "base",
  variant = "primary",
  onClick,
  customClass = "",
  disabled
}) => {
  return (
    <button disabled={disabled?disabled:false}
      type={type}
      onClick={onClick}
      className={twMerge(
        "rounded-md leading-[18px] font-semibold group text-[12px] 6xs:text-12 2xl:text-14",
        classNames({
          "bg-vorpblue text-white hover:bg-transparent border border-vorpblue hover:text-vorpblue transition-all duration-300":
            variant === "primary",
          "bg-vorpblue text-white border border-vorpblue":
            variant === "no-outline",
          "py-2 px-4": size === "base",
          "py-3 px-5": size === "lg",
        }),
        customClass
      )}
    >
      {label}
    </button>
  );
};

export default Button;
