import { ReactElement } from 'react';
import BasicLayout from '../layouts/BasicLayout';
import { Outlet } from 'react-router-dom';
import PerformanceAppraisal from './views/PerformanceAppraisal';
import PerformanceAppraisalDetail from '../performance-appraisal-detail/views/PerformanceAppraisalDetail';
export const performanceRoutes = [
  {
    id: 1,
    path: "",
    component: <PerformanceAppraisal />
  },
  {
    id: 2,
    path: "test",
    component: <PerformanceAppraisalDetail />
  },
  
  ];
const PerformanceAppraisalRoute = (props: any): ReactElement => {
    const data = props.loginData;
    const permissions = props.permissions;
    return (
        <BasicLayout userData={data} permissions={permissions}>
          <PerformanceAppraisal />
            <Outlet />
        </BasicLayout>
    )
};

export default PerformanceAppraisalRoute;
