import React, {
  createRef,
  FocusEvent,
  useEffect,
  useRef,
  useState,
} from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import styles from "./DashboardCalendarView.module.scss";
import basicStyle from "../../layouts/BasicLayout/BasicLayout.module.scss";
import classNames from "classnames";
import expand from "./img/teenyicons_expand-solid.svg";
import collpase from "./img/teenyicons_collpase-solid.svg";
import sgHoliday from "./img/sgHoliday.svg";
import hkHoliday from "./img/hkHoliday.svg";
import searchicon from "./img/searchicon.svg";
import addicon from "./img/fluent_add-12-regular.svg";
import exitfullscreen from "./img/radix-icons_exit-full-screen.svg";
import DashboardCalendar from "../DashboardCalendar/DashboardCalendar";
import {
  AddEventType,
  CreateEventData,
  EventData,
  EventList,
  getAuthUser,
  useCreateEventMutation,
  useCreateEventTypeDataMutation,
  useEditNoteDataMutation,
  useGetEventsDataQuery,
  useGetEventsListForLeaveQuery,
  useGetEventsListQuery,
  useGetEventTypesQuery,
  useGetIndicatorsQuery,
} from "../../app/services/dashboard";
import { useLocation } from "react-router-dom";
import DashboardAddEventPopup, {
  renderDateFormat, renderDateFormatDMY, renderDateFormatDMYTimeStamp,
} from "./DashboardAddEventPopup";
import CreateNewItemPopup from "../CreateNewItemPopup/CreateNewItemPopup";
import { endpointUrl } from "../../app/services/api";
import axios from "axios";
import EventCreateNewItemPopup from "../CreateNewItemPopup/EventCreateNewItemPopup";
import { uniqueId } from "lodash/fp";
import { useGetKeyDateDataQuery } from "../../app/services/project";
import CommonErrorPopup from "../common/CommonErrorPopup";
import { toast, ToastContainer } from "react-toastify";
import DashboardCalendarMobile from "../DashboardCalendar/DashboardCalendarMobile";
export type DefaultEvent = {
  eventID: number;
  eventType: string;
  eventTypeID: number;
  assign: string;
  startDate: string;
  endDate: string;
  eventNote: boolean;
  leaveType: number;
  indicator: string;
};
export type EventFormat = {
  "uniqueID": number,
  "title": string,
  "date": string,
  "start": string,
  "end": string,
  "note": string,
  "eventType": string,
  "eventTarget": string,
  "targetId": number,
  "eventNote": boolean,
  "fullHalfDay": string,
  "description": string,
}
// const defaultEvents: DefaultEvent[] = [
//   {
//     eventID: 1,
//     eventType: "Annual Leave (1/2)",
//     eventTypeID: 2,
//     assign: "",
//     startDate: "2023-02-25",
//     endDate: "2023-02-25",
//     eventNote:false
//   },
// ];
var defaultEvt = [{
  "eventID": 0,
  "eventType": '',
  "eventTypeID": 0,
  "assign": '',
  "startDate": renderDateFormat(new Date(), '-'),
  "endDate": renderDateFormat(new Date(), '-'),
  "start": renderDateFormat(new Date(), '-'),
  "end": renderDateFormat(new Date(), '-'),
  "eventNote": false,
  "leaveType": 0,
  "indicator": '',
  "keyId": 0,
  "projectId": 0,
  "eventTarget": ''
}];
var date = new Date();
export const defaultEvent = {
  month: date.getMonth() + 1,
  year: date.getFullYear(),
};
const DashboardCalendarView = (props: any) => {
  let location = useLocation();
  // const { data } = useGetEventsDataQuery();
  const { data: eventTypeData } = useGetEventTypesQuery();
  const [curMonth, setCurMonth] = useState<number>(date.getMonth() + 1);
  const [curYear, setCurYear] = useState<number>(date.getFullYear());
  const { data: eventsDataList, isLoading } = useGetEventsListQuery({
    month: curMonth,
    year: curYear,
  });
  const { data: eventsDataListForLeave } = useGetEventsListForLeaveQuery({
    month: curMonth,
    year: curYear,
  });
  const { data: keyDates } = useGetKeyDateDataQuery();
  var eventsList: any = eventsDataList ? eventsDataList.data : [];
  if (props.noFilter == true) {
    eventsList = eventsDataListForLeave ? eventsDataListForLeave?.data : [];
  }
  var eventType: any = [];
  if (eventTypeData) {
    eventType = eventTypeData?.data;
  }
  var leaveType = props.data?.leaveType;

  const [employee, setEmployee] = useState(true);
  const [project, setProject] = useState(true);
  const [eventList, setEventList] = useState<EventList[]>(eventsList);
  const [eventTypes, setEventTypes] = useState(eventType ? eventType : []);
  const [leaveTypes, setLeaveTypes] = useState(leaveType);
  const [popupIsOpen, setPopupIsOpen] = useState(false);
  const [selectedEventType, setSelectedEventType] = useState("All");
  const [showEventTypePopup, setShowEventTypePopup] = useState(false);
  const [newEventType, setNewEventType] = useState("");
  const [profileFullscreen, setProfileFullscreen] = useState(false);
  const [isProfile, setIsProfile] = useState(false);
  const [newEvents, setNewEvents] = useState<DefaultEvent[]>(defaultEvt);
  const [openNewEventPopup, setNewEventPopup] = useState<boolean>(false);
  const [isDateChanged, setDateChanged] = useState<boolean>(false);
  const [currentMonth, setCurrentMonth] = useState<number>(
    new Date().getMonth() + 1
  );
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [keyDatesData, setKeydatesData] = useState<any>([]);
  const [createEvent] = useCreateEventMutation();
  const [editNoteData] = useEditNoteDataMutation();
  const calendarRef = useRef<any>(null!);
  const calendarMobileRef = useRef<any>(null!);
  const [createEventType] = useCreateEventTypeDataMutation();
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [msg, setMsg] = useState<string>('');

  const handleEventFilter = (name: string, event: any) => {
    if (name == "employee") {
      setEmployee(event.target.checked);
      if (event.target.checked == false) {
        setProject(!event.target.checked);
      }
    }
    if (name == "project") {
      if (event.target.checked == false) {
        setEmployee(!event.target.checked);
      }

      setProject(event.target.checked);
    }
  };
  const profileCalendarFullScreen = () => {
    setProfileFullscreen(!profileFullscreen);
    if (profileFullscreen == false) {
      document
        .getElementsByClassName(basicStyle.leftSidebar)[0]
        .classList.add("hidden");
    } else {
      document
        .getElementsByClassName(basicStyle.leftSidebar)[0]
        .classList.remove("hidden");
    }
  };

  useEffect(() => {
    if (keyDates) {
      setKeydatesData(keyDates)
    }
    setEventTypes(eventType);
    setLeaveTypes(leaveType);
    if (props.setLeaveTypesData) {
      props.setLeaveTypesData(leaveType);
    }

    var empty = "";
    var filterEvents = eventsList;
    if (employee == true && project == true) {
      filterEvents = eventsList;
    } else if (employee == true && project == false) {
      filterEvents = eventsList?.filter(
        (x: any) => x.eventTarget == "L" || x.eventTarget == "O"
      );
    } else if (employee == false && project == true) {
      filterEvents = eventsList?.filter(
        (x: any) => x.eventTarget == "P" || x.eventTarget == "O"
      );
    } else {
      filterEvents = eventsList?.filter((x: any) => x.eventTarget == "");
    }
    if (selectedEventType != "All") {
      const selectedEventTypeId = eventType?.find(
        (x: any) => x.typeName == selectedEventType
      );
      filterEvents = filterEvents?.filter(
        (x: any) =>
          x.eventType == (selectedEventTypeId ? selectedEventTypeId.flag : "")
      );
    }
    var filterData: any = [];
    filterEvents.map((x: any) => {
      // filterData.push(x);
      var endDate = x.end;
      if (x.end) {
        var endD = new Date(x.end);
        var custEndD = endD.getDate() + 1;
        endD.setDate(custEndD);
        filterData.end = renderDateFormat(endD, "-");
        filterData.date = renderDateFormat(endD, "-");
        endDate = renderDateFormat(endD, "-");
      }
      filterData.push({
        "uniqueID": x.uniqueID,
        "title": x.title,
        "date": endDate,
        "start": x.start,
        "end": endDate,
        "note": x.note,
        "eventType": x.eventType,
        "eventTarget": x.eventTarget,
        "targetId": x.targetId,
        "eventNote": x.eventNote,
        "fullHalfDay": x.fullHalfDay,
        "description": x.description ? x.description : '',
        "show_note": x.show_note,
        "sort": x.show_note == true ? 0 : 1
      })

      return filterData;//.sort((a:any, b:any) => b.eventNote - a.eventNote);
    });
    if (props.noFilter == true) {
      filterData = filterData.filter((x: any) => x.eventNote === false && x.eventTarget === "L")
      setEventList([...filterData]);
      eventType = eventType?.filter((x: any) => x.indicator == "L");
      setEventTypes(eventType);
    } else {
      var filterDataSort = filterData.map((x: any) => {
        if (x.eventNote == true) {
          x.title = x.description;
        }
        if (x.eventNote == true && x.show_note == false) {
          x.sorrt = 1;
        }
        else if (x.show_note == true) {
          x.sort = 3;
        } else {
          x.sort = 2;
        }
        return x;
      });
      setEventList([...filterDataSort]);
    }

    // if (props.eventsList) {
    //   setIsProfile(false);
    // } else {
    //   setIsProfile(true);
    // }
  }, [
    employee,
    project,
    selectedEventType,
    eventType,
    // props.fullscreen,
    // isProfile,
    // props.fullscreenProfile,
    //props.eventType,
    //props.projectList,
    isDateChanged,
    currentMonth,
    eventsDataList,
    eventsDataListForLeave,
    keyDates
    //projectList
  ]);
  const deleteEventType = (removeID: number) => {
    var data = newEvents.filter((x) => x.eventID != removeID);
    setNewEvents(data);
  };
  const addNewEvent = (
    assign: string,
    eventType: string,
    eventTypeID: number,
    startDate: string,
    endDate: string,
    indicator: string
  ) => {
    var id = newEvents.length + 1;
    var data = {
      eventID: id,
      eventType: eventType,
      eventTypeID: eventTypeID,
      assign: assign,
      startDate: startDate,
      endDate: endDate,
      eventNote: false,
      leaveType: 1,
      indicator: indicator,
    };
    var cloneEvents = [...newEvents];
    cloneEvents.push(data);
    setNewEvents(cloneEvents);
  };
  const updatedEventByID = (id: number) => { };
  const updateEvents = (eventDataList: any) => {
    if (eventList) {
      var eventData = [...eventList, ...eventDataList];
      setEventList(eventDataList);
    }
  };
  const addDate = (date: Date) => {
    date.setDate(date.getDate() + 1);
    return date;
  };
  var userData = getAuthUser();
  const saveNewEvents = (eventDataList: any) => {
    var data: CreateEventData[] = [];
    eventDataList.map((x: any) => {

      var eventTypeID = eventType?.find(
        (e: any) => e.typeName == x.eventType
      )?.flag;
      const diffTime = Math.abs(endDate.getTime() - startDate.getTime());
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      var cusEndDate = x.end ? new Date(x.end) : new Date();
      var endCustDate = cusEndDate.getDate() + 1;
      cusEndDate.setDate(endCustDate);
      if (x.eventNote == true) {
        // for (var i = 0; i < diffDays; i++) {
        //   var startUpdatedDate = addDate(startDate);
        //   var evtData: CreateEventData = {
        //     title: x.eventNote == true ? "" : x.assign,
        //     date: renderDateFormat(endDate, "-"),
        //     start: renderDateFormat(startUpdatedDate, "-"),
        //     end: (x.indicator=="P"?renderDateFormat(startUpdatedDate, "-"):renderDateFormat(new Date(endDate.getDate()+1), "-")),
        //     note: x.eventNote == true ? x.assign : "",
        //     eventType:
        //       x.eventNote == true ? "N" : eventTypeID ? eventTypeID : "N",
        //     eventTarget: "",
        //     // description: "",
        //     eventNote: x.eventNote,
        //     leaveType: x.eventNote == true ? "" : x.leaveType,
        //     TargetId: x.TargetId,
        //     day:
        //       x.eventNote == true
        //         ? ""
        //         : x.leaveType?.includes("Full")
        //         ? "Full Day"
        //         : "Half Day",
        //   };
        //   data.push(evtData);
        // }
        if (diffDays == 0) {
          var evtData: CreateEventData = {
            title: x.eventNote == true ? "" : x.assign,
            date: renderDateFormat(endDate ? endDate : new Date(), "-"),
            start: renderDateFormat(startDate ? startDate : new Date(), "-"),
            end: renderDateFormat(endDate ? endDate : new Date(), "-"),
            note: x.eventNote == true ? x.assign : "",
            eventType:
              x.eventNote == true ? "N" : eventTypeID ? eventTypeID : x.eventType,
            eventTarget: "",
            // description: "",
            eventNote: x.eventNote,
            leaveType: x.eventNote == true ? "" : x.leaveType,
            TargetId: x.TargetId,
            day: x.eventNote == true ? "" :
              (x.indicator == "L") ?
                x.leaveType?.includes("Full")
                  ? "Full Day"
                  : "Half Day" : '',
            keyId: x.keyId,
            projectId: x.projectId
          };
          data.push(evtData);
        }
      } else {
        var evtData: CreateEventData = {
          title: x.eventNote == true ? "" : x.assign,
          date: renderDateFormat(x.end ? (x.end != "NaN-NaN-NaN" ? new Date(x.end) : new Date()) : new Date(), "-"),
          start: renderDateFormat(x.start ? (x.start != "NaN-NaN-NaN" ? new Date(x.start) : new Date()) : new Date(), "-"),
          end: renderDateFormat(x.end ? (x.end != "NaN-NaN-NaN" ? new Date(x.end) : new Date()) : new Date(), "-"),
          note: x.eventNote == true ? x.assign : "",
          eventType:
            x.eventNote == true ? "N" : eventTypeID ? eventTypeID : x.eventType,
          eventTarget: x.indicator ? x.indicator : 'L',
          // description: "",
          eventNote: x.eventNote,
          leaveType: x.eventNote == true ? "" : (x.leaveType ? x.leaveType : "Full"),
          TargetId: x.TargetId,
          day: x.eventNote == true ? "" :
            (x.indicator == "L") ?
              x.leaveType != 0 ? x.leaveType?.includes("Full")
                ? "Full Day"
                : "Half Day" : '' : '',
          keyId: x.keyId,
          projectId: x.projectId
        };
        data.push(evtData);
      }
      
      // console.log("x data ",renderDateFormat(x.end?new Date(x.end):new Date(), "-"),x.end?typeof(x.end):typeof(x.end))
    });
    if (eventList) {
      var eventData = [...eventList, ...data];
      // setEventList(eventData ? eventData : []);
    }
    createEvent(data).then((res: any) => {
      if (res?.data?.status == true) {
        setShowAlert(false);
        setMsg("");
        setNewEventPopup(false);
        toast('Successfully created!', {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          type: "success",
        });
      } else {
        setShowAlert(true);
        setMsg(res?.data?.message);
      }
    });

    var defat = [...defaultEvt.map((x) => {
      x.startDate = renderDateFormat(new Date(), '-');
      x.endDate = renderDateFormat(new Date(), '-');
      x.start = renderDateFormat(new Date(), '-');
      x.end = renderDateFormat(new Date(), '-');
      return x;
    })]
    setNewEvents(defat);
  };
  const handleChangeEventType = (event: any, id: string) => {
    if (id) {
      var evt = eventType?.find((x: any) => x.uniqeID == id);
      setPopupIsOpen(false);
      setSelectedEventType(evt ? evt.typeName : "All");
    }
  };
  const changeEventTypeFilter = (event: any) => {
    var filterEventType = eventType?.filter((x: any) =>
      x.typeName?.toLowerCase().includes(event.target.value.toLowerCase())
    );
    setEventTypes(filterEventType);
  };
  const createEventTypeData = (data: AddEventType) => {
    if (data) {
      // var newEvt = {
      //   id: newEventType.replace(" ", ""),
      //   name: newEventType,
      //   selectedType:selectedType
      // };
      //createEventTypeData(data);
      if (eventTypes) {
        setEventTypes([...eventTypes, data]);
      } else {
        setEventTypes([data]);
      }
      setShowEventTypePopup(false);
    }
    createEventType(data);
  };
  const changeEventTypeInput = (event: any) => {
    setNewEventType(event.target.value);
  };
  const handleShowAddEventPopup = () => {
    setStartDate(new Date());
    setEndDate(new Date());
    setNewEventPopup(!openNewEventPopup);
    setNewEvents(defaultEvt);
    // props.handlePopup();
  };
  const handleDateChange = () => {
    var calendarDate = calendarRef.current?.getApi().getDate();
    if (window.innerWidth < 641) {
      calendarDate = calendarMobileRef.current?.getApi().getDate();
    }
    setCurrentMonth(calendarDate?.getMonth() + 1);
  };
  const getEventsDataByDate = (month: number, year: number) => {
    axios
      .get(endpointUrl + "eventsList", {
        params: {
          month: month,
          year: year,
        },
      })
      .then((res) => res)
      .then((res) => {
        setEventList(res.data ? res.data : []);
      });
  };
  const changeToPrevMonth = (month: number, year: number) => {
    let calendarApi = calendarRef.current.getApi();
    if (window.innerWidth < 641) {
      calendarApi = calendarMobileRef.current?.getApi();
    }
    calendarApi.prev();
    var calendarData = new Date(calendarApi.currentData.currentDate);
    var month = calendarData.getMonth() + 1;
    var year = calendarData.getFullYear();
    setCurMonth(month);
    setCurYear(year);
    calendarApi.refetchEvents();
    //getEventsDataByDate(month, year);
  };
  const changeToToday = (month: number, year: number) => {
    let calendarApi = calendarRef.current.getApi();
    if (window.innerWidth < 641) {
      calendarApi = calendarMobileRef.current?.getApi();
    }
    calendarApi.today();
    var calendarData = new Date(calendarApi.currentData.currentDate);
    var month = calendarData.getMonth() + 1;
    var year = calendarData.getFullYear();
    setCurMonth(month);
    setCurYear(year);
    calendarApi.refetchEvents();
  };
  const changeToNextMonth = (month: number, year: number) => {
    let calendarApi = calendarRef.current.getApi();
    if (window.innerWidth < 641) {
      calendarApi = calendarMobileRef.current?.getApi();
    }
    calendarApi.next();
    var calendarData = new Date(calendarApi.currentData.currentDate);
    var month = calendarData.getMonth() + 1;
    var year = calendarData.getFullYear();
    setCurMonth(month);
    setCurYear(year);
    calendarApi.refetchEvents();
  };
  const ref = useRef(null);

  const handleClickOutside = (event: any) => {
    if (!event.target.classList.contains("evtCustomDropdown")) {
      setPopupIsOpen(false);
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);
  const [selectedIndicator, setSelectedIndicator] = useState<string>('');
  const showIndicator = (name: string) => {
    if (name == selectedIndicator) {
      setSelectedIndicator('');
    } else {
      setSelectedIndicator(name);
    }

  }
  return (
    <div>
      <div
        className={classNames(
          "bg-offwhite",
          isProfile
            ? profileFullscreen == true
              ? "fixed -top-6 left-0 w-full h-full overflow-auto px-9"
              : ""
            : ""
        )}
      >
        <div className={classNames("bg-white")}>
          <div
            className={classNames(props.noFilter == true ? '' : 'pt-[15px]', "bg-white mt-0 md:px-[27px] px-4 ")}
          >
            <div className={classNames(props.noFilter == true ? 'hidden' : '', "flex justify-between items-center pb-3 border-b-[1px] border-b-[#E4E6EF]")}>
              <div className="flex">
                <label
                  className={classNames(
                    styles.customCheckbox,
                    "flex items-center self-center min-h-[24px] font-poppins text-xs font-semibold text-[#858795] mr-[22px]"
                  )}
                >
                  <div className="">
                    <input
                      defaultValue=""
                      checked={employee}
                      type="checkbox"
                      name="employee"
                      className="mr-2"
                      onChange={(e) => handleEventFilter("employee", e)}
                    />
                    <span className={styles.checkmark}></span>
                  </div>
                  Employee
                </label>
                <label
                  className={classNames(
                    styles.customCheckbox,
                    "flex items-center self-center min-h-[24px] font-poppins text-xs font-semibold text-[#858795]"
                  )}
                >
                  <div className="">
                    <input
                      defaultValue=""
                      checked={project}
                      type="checkbox"
                      name="project"
                      className="mr-2"
                      onChange={(e) => handleEventFilter("project", e)}
                    />
                    <span className={styles.checkmark}></span>
                  </div>
                  Project
                </label>
              </div>
              <div>
                <img
                  onClick={() =>
                    isProfile
                      ? profileCalendarFullScreen()
                      : props.checkFullScreen()
                  }
                  src={exitfullscreen}
                  alt="expand"
                  className={classNames(
                    isProfile
                      ? props.fullscreenProfile == true
                        ? ""
                        : "hidden"
                      : props.fullscreen == true
                        ? ""
                        : "hidden",
                    "cursor-pointer object-contain"
                  )}
                />
                <img
                  onClick={() =>
                    isProfile
                      ? profileCalendarFullScreen()
                      : props.checkFullScreen()
                  }
                  src={collpase}
                  alt="collpase"
                  className={classNames(
                    isProfile
                      ? props.fullscreenProfile == true
                        ? "hidden"
                        : ""
                      : props.fullscreen == true
                        ? "hidden"
                        : "",
                    "cursor-pointer object-contain"
                  )}
                />
              </div>
            </div>
            <div className={classNames(styles.fc, "relative")}>
              <DashboardCalendar
                changeToPrevMonth={changeToPrevMonth}
                changeToNextMonth={changeToNextMonth}
                changeToToday={changeToToday}
                handleDateChange={handleDateChange}
                calendarRef={calendarRef}
                isProfile={isProfile}
                fullscreen={props.fullscreen}
                eventsList={eventList}
                setNewEventPopup={handleShowAddEventPopup}
                editNoteData={editNoteData}
                createEvent={createEvent}
                noFilter={props.noFilter}
                indicators={eventTypes}
              />
              <DashboardCalendarMobile
                changeToPrevMonth={changeToPrevMonth}
                changeToNextMonth={changeToNextMonth}
                changeToToday={changeToToday}
                handleDateChange={handleDateChange}
                calendarRef={calendarMobileRef}
                isProfile={isProfile}
                fullscreen={props.fullscreen}
                eventsList={eventList}
                setNewEventPopup={handleShowAddEventPopup}
                editNoteData={editNoteData}
                createEvent={createEvent}
                noFilter={props.noFilter}
                indicators={eventTypes}
              />
              <div
                className={classNames(
                  //props.noFilter==true?'hidden':'',
                  styles.customCalendarDropdown,
                  "absolute md:min-w-[218px] min-w-[150px] left-[152px] z-10 md:block hidden",
                  (isProfile || props.noFilter) ? "top-0" : "top-0"
                )}
              >
                <div className="relative">
                  <p
                    ref={ref}
                    onClick={(e) => {
                      e.stopPropagation();
                      setPopupIsOpen(!popupIsOpen);
                    }}
                    className={classNames(
                      styles.selectedEventType,
                      "bg-[#F3F6F9] evtCustomDropdown rounded-[6px] px-3 py-2 flex justify-between items-center md:max-w-[218px] max-w-[150px] font-poppns text-xs font-semibold text-[#858795]"
                    )}
                  >
                    {selectedEventType}
                  </p>
                  <div
                    className={classNames(
                      styles.customEvent,
                      popupIsOpen ? "block" : "hidden",
                      "customEventCss max-w-[218px] absolute left-0 z-10 evtCustomDropdown"
                    )}
                  >
                    <div
                      className={classNames(
                        "bg-[#EBF0F7] px-3 pt-[15px] pb-3 evtCustomDropdown"
                      )}
                    >
                      <div className="flex justify-between items-center evtCustomDropdown">
                        <p className="font-poppins text-[13px] font-semibold text-[#3F4254] evtCustomDropdown">
                          {props.noFilter == true ? "Leave Type" : "Event Type"}
                        </p>
                        {/* <img
                          onClick={() =>
                            setShowEventTypePopup(!showEventTypePopup)
                          }
                          src={addicon}
                          alt="addicon"
                          className="object-contain cursor-pointer evtCustomDropdown"
                        /> */}
                      </div>
                      <div className="pl-3 pr-[6px] evtCustomDropdown rounded-[4px] mt-[11px] bg-[#fff] flex justify-between items-center">
                        <input
                          onChange={(e) => changeEventTypeFilter(e)}
                          type="text"
                          defaultValue=""
                          className={classNames(
                            styles.searchfilter,
                            "focus:outline-none evtCustomDropdown w-full font-poppins text-[13px] font-normal text-[#B5B5C3] py-2 "
                          )}
                          placeholder="Search"
                        />
                        <img
                          src={searchicon}
                          alt="searchicon"
                          className="object-contain evtCustomDropdown"
                        />
                      </div>
                    </div>
                    <ul
                      className={classNames(
                        "bg-white max-h-[223px] overflow-y-auto evtCustomDropdown"
                      )}
                    >
                      <li
                        onClick={(e) => handleChangeEventType(e, "0")}
                        data-value={0}
                        className={classNames(
                          selectedEventType == "All"
                            ? "bg-[#3699FF] text-white"
                            : "bg-white text-[#858795]",
                          "text-left hover:bg-[#3699FF] hover:text-[#fff] bg-[#3699FF]  py-2 px-[19px] min-h-[37px]"
                        )}
                      >
                        All
                      </li>
                      {eventTypes?.map((et: any, index: number) => (
                        <li
                          key={index}
                          onClick={(e) => handleChangeEventType(e, et.uniqeID)}
                          data-value={et.uniqeID}
                          className={classNames(
                            selectedEventType == et.typeName
                              ? "bg-[#3699FF] text-white"
                              : "",
                            "hover:bg-[#3699FF] hover:text-[#fff] cursor-pointer text-left font-poppins text-sm font-normal text-[#858795] py-2 px-[19px]"
                          )}
                        >
                          {et.typeName}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={classNames(
              isProfile ? "hidden" : "flex md:px-[27px] px-4",
              props.fullscreen ? "md:px-[27px] px-4" : "",
              "xl:pb-[100px] pb-20 my-4 flex-wrap justify-between"
            )}
          >
            <div className="my-1 flex flex-wrap w-full">
              {props.indicators
                ?.filter((x: any) => x.flag != "O")
                ?.map((ind: any, index: number) => {
                  return (
                    <div className="flex sm:flex-row flex-col w-full" key={index}>
                      <div className="flex justify-between">
                        <p onClick={() => showIndicator(ind.name)}
                          key={index}
                          className={classNames(props.noFilter == true ? (ind.name != "Leave" ? 'hidden' : '') : '',
                            selectedIndicator == ind.name ? 'active' : '',
                            " accordion-indicatior relative w-full uppercase sm:mr-[35px] font-poppins text-xs font-semibold text-[#464E5F] min-w-[70px] text-left")}
                        >
                          {ind.name}
                        </p>
                        <div className="sm:hidden block">
                          <svg xmlns="http://www.w3.org/2000/svg" width="10" height="5" viewBox="0 0 10 5" fill="none">
                            <path d="M0 0L5 5L10 0H0Z" fill="#B6B6B6" />
                          </svg>
                        </div>
                      </div>
                      <div className={classNames(selectedIndicator == ind.name ? 'active' : '', "flex-wrap indicator-collpase sm:my-0 my-2")}>
                        {eventTypes
                          .filter((x: any) => x.indicator != "O")
                          ?.filter((evt: any) => evt.indicator == ind.flag)
                          ?.map((x: any, key: number) => {
                            return (
                              <p key={key}
                                className={classNames(
                                  x.typeName.includes("Design")
                                    ? styles.design
                                    : x.typeName.includes("Annual")
                                      ? styles.annualLeave
                                      : x.typeName.includes("Birthday")
                                        ? styles.birthday
                                        : x.typeName.includes("Sick")
                                          ? styles.sick
                                          : x.typeName.includes("Unpaid")
                                            ? styles.unpaid
                                            : x.flag == "UAT"
                                              ? styles.uat
                                              : x.flag == "CUAT"
                                                ? styles.clientUat
                                                : x.typeName.includes("Launch")
                                                  ? styles.launch
                                                  : x.flag == "KOM"
                                                    ? styles.eventKOM
                                                    : x.flag == "D"
                                                      ? styles.eventDevelopment
                                                      : x.flag == "SEOS"
                                                        ? styles.eventSEO
                                                        : x.flag == "ML"
                                                          ? styles.eventMaternity
                                                          : x.flag == "TL"
                                                            ? styles.eventSubstitute
                                                            : x.flag == "PL"
                                                              ? styles.eventPaternity
                                                              : x.flag == "OL"
                                                                ? styles.eventLeftOver
                                                                : x.flag == "CL"
                                                                ? styles.eventChildLeave        
                                                                : x.flag == "SGH" || x.flag == "HKH" ? 'whitespace-nowrap'
                                                                  : "",
                                  "flex text-left mr-3 font-poppins text-xs font-normal text-[#858795]"
                                )}
                              >
                                {x.flag == "SGH" ? <img src={sgHoliday} alt="sgHoliday" className="mr-2" /> :
                                  x.flag == "HKH" ? <img src={hkHoliday} alt="hkHoliday" className="mr-2" /> : ''}
                                {x.typeName} ({x.flag})
                              </p>
                            );
                          })}
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
          <div
            className={classNames(
              isProfile ? "flex md:px-[27px] px-4" : "hidden",
              profileFullscreen ? "" : "",
              "xl:pb-[100px] pb-20 my-4 flex-wrap"
            )}
          >
            <div className="my-1 flex flex-wrap 7xl:mr-20 4xl:mr-5 mr-2">
              <p className="uppercase 4xl:mr-5 mr-2 font-poppins 4xl:text-xs text-10 font-semibold text-[#464E5F]">
                Leave Indicators
              </p>
              <div>
                <p
                  className={classNames(
                    styles.annualLeave,
                    "mb-3 flex text-left mr-3 font-poppins text-xs font-normal text-[#858795]"
                  )}
                >
                  AL
                </p>
                <p
                  className={classNames(
                    styles.birthday,
                    "mb-3 flex text-left mr-3 font-poppins text-xs font-normal text-[#858795]"
                  )}
                >
                  Birthday
                </p>
                <p
                  className={classNames(
                    styles.sick,
                    "mb-3 flex text-left mr-3 font-poppins text-xs font-normal text-[#858795]"
                  )}
                >
                  Sick
                </p>
                <p
                  className={classNames(
                    styles.unpaid,
                    "mb-3 flex text-left mr-3 font-poppins text-xs font-normal text-[#858795]"
                  )}
                >
                  Unpaid
                </p>
              </div>
            </div>
            <div className="my-1 flex flex-wrap 7xl:mr-20 4xl:mr-5 mr-2 ">
              <p className="uppercase 4xl:mr-5 mr-2 font-poppins 4xl:text-xs text-10 font-semibold text-[#464E5F]">
                PROJECT Indicators
              </p>
              <div>
                <p
                  className={classNames(
                    styles.design,
                    "mb-3 flex text-left mr-3 font-poppins text-xs font-normal text-[#858795]"
                  )}
                >
                  Design Confirmation
                </p>
                <p
                  className={classNames(
                    styles.uat,
                    "mb-3 flex text-left mr-3 font-poppins text-xs font-normal text-[#858795]"
                  )}
                >
                  UAT
                </p>
                <p
                  className={classNames(
                    styles.launch,
                    "mb-3 flex text-left mr-3 font-poppins text-xs font-normal text-[#858795]"
                  )}
                >
                  Launch Day
                </p>
              </div>
            </div>
            <div className="my-1 flex flex-wrap 7xl:mr-20 4xl:mr-5 mr-2 ">
              <p className="uppercase 4xl:mr-5 mr-2 font-poppins 4xl:text-xs text-10 font-semibold text-[#464E5F]">
                HOLIDAY INDICATORS
              </p>
              <div>
                <p
                  className={classNames(
                    "mb-3 whitespace-nowrap flex text-left mr-3 font-poppins text-xs font-normal text-[#858795]"
                  )}
                >
                  <img src={hkHoliday} alt="hkHoliday" className="mr-2" />
                  HK Holidays
                </p>
                <p
                  className={classNames(
                    "mb-3 whitespace-nowrap flex text-left mr-3 font-poppins text-xs font-normal text-[#858795]"
                  )}
                >
                  <img src={sgHoliday} alt="sgHoliday" className="mr-2" />
                  SG Holidays
                </p>
              </div>
            </div>
          </div>
          <div>
            <EventCreateNewItemPopup
              addNewItem={createEventTypeData}
              show={showEventTypePopup}
              setShow={setShowEventTypePopup}
              dropdownList={eventTypes}
            />
          </div>
        </div>
      </div>
      <DashboardAddEventPopup
        eventType={eventType}
        setEventList={setEventList}
        eventList={eventList}
        updateEvents={updateEvents}
        updatedEventByID={updatedEventByID}
        newEvents={newEvents}
        setNewEvents={setNewEvents}
        // selectLeave={selectLeave}
        // setSelectLeave={setSelectLeave}
        leaveType={leaveTypes}
        deleteEventType={deleteEventType}
        addNewEvent={addNewEvent}
        openNewEventPopup={openNewEventPopup}
        setNewEventPopup={handleShowAddEventPopup}
        saveNewEvents={saveNewEvents}
        employeeData={props.employeeData}
        projectList={props.projectList}
        startDate={startDate}
        endDate={endDate}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        keyDates={keyDates}
      />
      <CommonErrorPopup show={showAlert} setShow={setShowAlert} message={msg} />
      <ToastContainer className={"text-12 font-poppins font-normal"} />
    </div>
  );
};
export default DashboardCalendarView;
