import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { DeleteIcon, EditIcon, SelectedIcon } from "../../common/Icons";
import { baseObj } from "../../../utils/constants";
import { EmployeeData } from "../../EmployeeTable/EmployeeTable";
import classNames from "classnames";

interface IChecklistModal {
  // initialValue: IChecklist[];
  initialValue: EmployeeData[];
  rowId: number;
  data: baseObj[];
  setData: Dispatch<SetStateAction<baseObj[]>>;
  checkListData: EmployeeData[];
  addCheckListData?: any;
  closeModal: () => void;
  checkManagement: boolean;
}

interface IChecklist {
  id: number;
  label: string;
  isCheck: boolean;
}

interface IChecklistData {
  id: number;
  label: string;
  isCheck: boolean;
}


const ChecklistModal: FC<IChecklistModal> = ({
  initialValue,
  setData,
  rowId,
  checkListData,
  addCheckListData,
  closeModal,
  data,
  checkManagement,
}) => {
  // const [state, setState] = useState<IChecklist[]>(initialValue);
  const [state, setState] = useState<EmployeeData[]>(checkListData);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [newIds, setNewIds] = useState<number[]>([]);

  const updateCheckItem = (id: number, value?: string) => {
    const label = value;
    setState((prev: any) =>
      prev.map((item: any) => {
        if (item?.id == id) {
          return {
            ...item,
            ...(label !== undefined ? { label: label } : { isChecked: !item.isChecked }),
          }
        } else {
          return item;
        }
      })
    );
  };

  const addNewChecklist = () => {
    setState((prev: any) => {
      if (prev) {
        const id = (prev?.length == 0 || prev == undefined) ? 1 : prev[prev?.length - 1]?.id + 1;
        setNewIds((prev) => [...prev, id]);
        return [...prev, { id, label: "", isChecked: false }];
      } else {
        const id = (prev?.length == 0 || prev == undefined) ? 1 : prev[prev?.length - 1]?.id + 1;
        setNewIds((prev) => [id]);
        return [{ id, label: "", isChecked: false }];
      }

    });
  };


  const saveHandler = () => {
    setData((prev) =>
      prev.map((row) => {
        if (row.uniqeID == rowId) {
          var listData = state?.map((s: any) => {
            return { ...s, project_id: row.uniqeID,date:s.date?s.date:new Date() }
          })
          const finalCheck = state[state?.length - 1];
          // addCheckListData({ isCheck: finalCheck?.isCheck, label: finalCheck?.label, id: rowId })
          if(row.uniqeID!=0){
            addCheckListData(listData)
          }
          
          return { ...row, checkList: state };
        } else {
          return row;
        }
      })
    );
    setIsEdit(false);
    // closeModal()
  };

  const removeListData = (id: number) => {
    setData((prev) =>
      prev.map((row) => {
        if (row.uniqeID == rowId) {
          var removedData = state.filter(x => x.id != id);
          var listData = removedData.map((s: any) => {
            return { ...s, project_id: row.uniqeID }
          })
          const finalCheck = state[state?.length - 1];
          //addCheckListData(listData)
          setState(removedData);
          return { ...row, checkList: removedData };
        } else {
          return row;
        }
      })
    );
  }
  return (
    <div className="rounded-xl min-h-[150px] w-full sm:min-w-[558px] sm:max-w-[558px] max-w-[90%] bg-white border border-offwhite">
      <div className="flex items-center justify-between py-3 px-4 bg-modalHeader border-offwhite border rounded-t-4">
        <h1 className="text-12 md:text-14 leading-[18px] font-semibold text-black2">
          Reminder Checklist
        </h1>
        {/* {(!isEdit && checkManagement) && (
          <button
            type="button"
            onClick={() => setIsEdit(!isEdit)}
            className="w-5 h-5 flex justify-center items-center bg-vorpblue rounded-4"
          >
            <EditIcon width={12} height={12} />
          </button>
        )} */}
      </div>
      <div className="p-4 lg:max-h-[500px] overflow-y-auto">
        {state?.map((checkItem) => (
          <div className="flex items-center py-2 px-3 hover:bg-offwhite mx-[-0.75rem] justify-between" key={checkItem?.id}>
            <div className="flex w-full">
              <label
                htmlFor={`checklist${checkItem?.id}`}
                className={`flex items-center ${isEdit ? " hover:cursor-pointer" : ""
                  }`}
              >
                <input
                  type="checkbox"
                  id={`checklist${checkItem?.id}`}
                  className="hidden"
                  onChange={() => isEdit && updateCheckItem(checkItem?.id)}
                  checked={checkItem?.isChecked}
                />
                <div
                  className={`w-5 h-5 rounded-4 flex justify-center items-center  mr-4 ${(checkItem?.isChecked)
                    ? "bg-checkItemColor"
                    : "border border-[#DCE6F0] bg-disabledColor"
                    }`}
                >
                  {(checkItem?.isChecked) && <SelectedIcon width={9} height={7} />}
                </div>
              </label>
              {newIds.includes(checkItem?.id) && isEdit ? (
                <div className="flex justify-between w-full mr-2">
                  <input
                    type="text"
                    className="focus:outline-none w-full text-12 leading-[18px] text-black2"
                    placeholder="Add Label"
                    onChange={(e) =>
                      updateCheckItem(checkItem?.id, e.target.value)
                    }
                    value={checkItem?.label}
                  />

                </div>
              ) : (
                <p
                  onClick={() => setNewIds((prev) => [...prev, checkItem?.id])}
                  className="text-12 leading-[18px] text-black2"
                >
                  {checkItem?.label}
                </p>
              )}
            </div>
            <div onClick={() => removeListData(checkItem?.id)} className={classNames(isEdit ? 'cursor-pointer' : 'hidden')}>
              <DeleteIcon color="#EE436D" />
            </div>
          </div>
        ))}
        {isEdit && (
          <>
            <button
              type="button"
              onClick={() => addNewChecklist()}
              className="text-vorpblue text-12 leading-[18px] mt-1 mb-4"
            >
              + Add
            </button>
            <div className="flex justify-end items-center text-12 leading-[18px] font-semibold">
              <button
                type="button"
                onClick={() => {
                  // setState(initialValue);
                  setState(checkListData);
                  setIsEdit(false);
                }}
                className="py-2 px-4 bg-[#EFEFEF] rounded-md overflow-hidden flex justify-center items-center text-[#979797] mr-2"
              >
                Discard
              </button>
              <button disabled={state?false:true}
                type="button"
                onClick={saveHandler}
                className={classNames(state?'':'opacity-50',"py-2 px-4 bg-vorpblue text-white rounded-md")}
              >
                Save
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ChecklistModal;
