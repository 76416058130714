import {
  Dispatch,
  FC,
  FocusEvent,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { ChevronDown } from "./Icons";
import { uniqueId } from "lodash";
import styles from "./Custom.module.scss";
import { baseObj } from "../../utils/constants";
const classNames = require("classnames");

interface ICustomDropdownList<P = unknown> {
  state: P;
  setState: Dispatch<SetStateAction<P>> | (() => void);
  placeholder?: string;
  dropdownItems: IListItem[];
  className?: string;
  textAlign?: string;
  dropdownborder?: string;
  setSearchData: Dispatch<SetStateAction<string>>;
  isShow?: boolean;
  setIsShow?: Dispatch<SetStateAction<boolean>>;
  isNew?: boolean;
  isPlaceHolder?: string;
  isCreate?: boolean;
  setData?: Dispatch<SetStateAction<any>>;
  setValidProject?: Dispatch<SetStateAction<boolean>>;
  setIsCheckValue?: Dispatch<SetStateAction<boolean>>;
  id: number;
  isObjectGet?: boolean;
  setProjectData: any;
}

interface IListItem {
  label: string;
  value: string | number;
  type: string;
  photo?: string;
  email?: string;
}

// Type Factory for Generic Types

const SearchInputDropdown = <P extends unknown>() => {
  const ActualInstance: FC<ICustomDropdownList<P>> = ({
    state,
    setState,
    setSearchData,
    dropdownItems,
    placeholder = "All",
    className,
    textAlign = "text-center",
    dropdownborder = "",
    isShow,
    setIsShow,
    isNew,
    isPlaceHolder,
    isCreate,
    setData,
    id,
    isObjectGet,
    setProjectData,
    setValidProject,
    setIsCheckValue,
  }) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [focusedItem, setFocusedItem] = useState<number>(-1);
    const [filterDropdownItem, setFilterDropdownItem] =
      useState<IListItem[]>(dropdownItems);
    const [label, setLabel] = useState<string>(
      dropdownItems?.filter((item) => item.value == state)[0]?.label || "Please Select"
    );

    const uuid = uniqueId("custom-dropdown-list");
    var name = useRef<any>(null);
    const handleCloseDropdown = (event: FocusEvent<HTMLButtonElement>) => {
      const relatedTarget = event.relatedTarget;
      // If the click target is not the dropdown or dropdown item, close the list.
      if (
        !relatedTarget ||
        !(
          relatedTarget.id === uuid ||
          relatedTarget.className.includes(`${uuid}item`)
        )
      ) {
        setIsOpen(false);
      }
    };

    const handleFocus = () => {
      if (name.current) {
        name.current.focus();
      }

      setIsOpen(true);
      if (setIsShow) {
        setIsShow(!isShow);
      }
    };

    const handleBlur = () => {
      setTimeout(() => {
        setIsOpen(false);
      }, 500);
    };

    const [search, setSearch] = useState<any>();
    const handleInputChange = (search: string, id: number) => {
      // if(setIsCheckValue){
      //   setIsCheckValue(true);
      // }
      if (name.current.value.length === 0) {
        if (setData) {
          setData((prev: baseObj[]) => {
            return prev.map((data: baseObj) => {
              if (data.uniqueId == Number(id)) {
                return {
                  ...data,
                  project_id: 0,
                  type: "",
                  isDisableRow: false,
                };
              }
              return data;
            });
          });
        }

        if (setValidProject) {
          setValidProject(true);
        }
      } else {
        if (setValidProject) {
          setValidProject(false);
        }
      }

      const timeout = setTimeout(() => {
        setSearch(search);
      }, 300);
      return () => clearTimeout(timeout);
    };

    const changeDropdownField = (items: any) => {
      if (setIsCheckValue) {
        setIsCheckValue(true);
      }
      if (setValidProject) {
        setValidProject(false);
      }

      if (setData) {
        setData((prev: baseObj[]) => {
          return prev.map((data: baseObj) => {
            if (data.uniqueId == Number(id)) {
              return {
                ...data,
                project_id: items.value,
                type: items.type,
                isDisableRow: false,
              };
            }
            return data;
          });
        });
      }
    };

    const handleKeyDown = (e: any) => {
      if (focusedItem < filterDropdownItem.length) {
        if (e.key === "ArrowUp" && focusedItem > 0) {
          setFocusedItem((prev) => prev - 1);
        } else if (e.key === "ArrowDown" && filterDropdownItem.length - 1) {
          setFocusedItem((prev) => prev + 1);
        } else if (e.key === "Enter" && focusedItem >= 0) {
          setLabel(filterDropdownItem[focusedItem].label);
          setState(filterDropdownItem[focusedItem] as unknown as any);
          if (name.current) {
            name.current.value = filterDropdownItem[focusedItem].label ?? "";
          }

          setIsOpen(false);
          changeDropdownField(filterDropdownItem[focusedItem]);
        }
      } else {
        setFocusedItem(-1);
      }
    };

    useEffect(() => {
      if (search) {
        var filterData = dropdownItems?.filter((x) =>
          (x.label.toLocaleLowerCase().startsWith(search.toLocaleLowerCase())||x.label.toLocaleLowerCase().includes(search.toLocaleLowerCase()))
        );
        setFilterDropdownItem(filterData);
      } else {
        setFilterDropdownItem(dropdownItems);
      }
    }, [search]);

    useEffect(() => {
      setFilterDropdownItem(dropdownItems);
    }, [dropdownItems]);

    useEffect(() => {
      const labels = dropdownItems?.filter((item) => item.value == state)[0]
        ?.label;
      setLabel(labels);
      if (name.current) {
        name.current.value = labels ?? "";
      }

    }, [state]);

    
    return (
      <div className="relative h-full customDropdownContainer daily_report_dropdown">
        <div>
          <button
            id={uuid}
            className={classNames(
              dropdownborder,
              `flex hover:cursor-pointer text-14 leading-5 text-graydark relative
                 justify-between w-32 h-12 items-center pl-[11px] pr-[17px] border
                  border-[#CCDAE4] rounded-[5px] custom-dropdown-list mb-0 overflow-x-auto ${className}`
            )}
            onClick={() => {
              setIsOpen(true);
              if (setIsShow) {
                setIsShow(!isShow);
              }
            }}
            onBlur={handleCloseDropdown}
          >
            <span className="px-2 whitespace-nowrap w-full text-ellipsis block overflow-hidden">{label?String(label) : "Please Select"}</span>
            {!isCreate ? <ChevronDown className="" /> : null}
          </button>
        </div>
        {isOpen && (
          <div className="relative">
            <div
              className={classNames(
                isNew ? "" : " pointer-events-none ",
                "absolute z-[9999] top-0 bg-white rounded-4 h-[40px] border-zinc-200 border-solid border-2 w-full mb-0 flex items-center justify-between px-2 py-1 border-b border--b-goalinputborder"
              )}
            >
              <input
                onChange={(e) => {
                  handleInputChange(e.target.value, id);
                  setIsOpen(true);
                }}
                onKeyDown={handleKeyDown}
                onFocus={handleFocus}
                onBlur={handleBlur}
                ref={name}
                autoFocus={isOpen ? true : false}
                type="text"
                className={classNames(
                  uuid + "item",
                  "text-graydark bg-transparent font-poppins h-full font-normal xl:text-sm text-xs rounded w-full bg-[#fff] focus:outline-none"
                )}
                placeholder={isPlaceHolder ?? "Enter Name"}
              />

              {/* <SearchIcon /> */}
            </div>
            <div
              className={classNames(
                styles.customDropdownScroll,
                " absolute left-0 text-12 max-h-[140px] overflow-y-auto z-10 overflow-hidden  top-[40px] w-full border-1  rounded-md"
                // " absolute left-0 text-12 max-h-[140px] overflow-y-auto z-10 overflow-hidden top-[104%] w-full border-1   rounded-md"
                //border-goalinputborder bg-white
                // { hidden: false }
                // { hidden: !isOpen }
              )}
            >
              {filterDropdownItem.length
                ? filterDropdownItem?.map((item: any, index: number) => (
                  <div className={classNames("flex flex-col")} key={index}>
                    <button
                      className={classNames(
                        focusedItem === index
                          ? " bg-vorpblue text-white"
                          : item.is_assign === 1
                            ? "bg-[#E1F0FF]"
                            : "bg-white",
                        `custom-dropdown-item  hover:bg-vorpblue py-1 w-full hover:text-white transition-all duration-300 text-graydark ${textAlign} ${uuid + "item"
                        }`
                      )}
                      onClick={() => {
                        if (isObjectGet) {
                          setState(item);
                          setProjectData(item);
                          setIsOpen(false);
                          setLabel(item.label);
                          changeDropdownField(item);
                        } else {
                          changeDropdownField(item?.value);
                          setState(item.value as any);
                          setLabel(item.label);
                          setIsOpen(false);
                          if (setIsShow) {
                            setIsShow(!isShow);
                          }
                        }
                      }}
                    >
                      <div className="flex flex-row items-center">
                        <div className="flex flex-col text-sm">
                          <span className={item.label=="Visibee Working Down Time"?'text-redPink':''}>{item.label ?? ""}</span>
                        </div>
                      </div>
                    </button>
                  </div>
                ))
                : null}
            </div>
          </div>

        )}
      </div>
    );
  };
  return ActualInstance;
};

// Extend More for Required Types
export const ProjectDropdownSearchInput = SearchInputDropdown<string>();
