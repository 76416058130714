import { FC, useCallback, useEffect, useState } from "react";
import DebounceSearchInput from "../../components/Tables/DebounceSearchInput";
import {
  AddIcon,
  CloseIcon,
  CustomizeLayoutIcon,
  ExportIcon,
  GenerateIcon,
  ImportIcon,
  SearchIcon,
} from "../../components/common/Icons";
import classNames from "classnames";
import Button from "../../components/common/Button";
import {
  TemplateData,
  checkedIsNew,
  useGetDepartmentListQuery,
} from "../../app/services/employee";
import { baseObj } from "../../utils/constants";
import {
  ColumnFiltersState,
  PaginationState,
  SortingState,
} from "@tanstack/react-table";
import CustomScrollbar from "../../components/CustomScrollbar/CustomScrollbar";
import CustomDropdownListForFilter from "../../components/EmployeeTable/CustomDropdownListForFilter";
import { GenderData } from "../../components/EmployeeTable/EmployeeTable";
import { NumberDropdownList } from "../../components/common/DropdownList";
import { StringDropdownListWithDeleteBtn } from "../../components/common/StringDropdownListWithDeleteBtn";
import {
  EmployeeData,
  templateList,
} from "../../active-time-report/views/ActiveTimeReport";
import { useSpring, animated } from "react-spring";
import LayoutAdjustableTable from "../../components/Tables/LayoutAdjustableTable";
import DatePopupFoFilter from "../../components/EmployeeTable/DatePopupFoFilter";
import {
  renderDateFormatMY,
  renderDateFormatYMD,
} from "../../components/DashboardCalendarView/DashboardAddEventPopup";
import OTListTable from "../../components/OTListTable/OTListTable";
import { useGetByModuleTemplateQuery } from "../../app/services/template";
import { hideAllNoneField } from "../../components/Tables/TablePaginator";
import OTCreatePopup from "../../components/OTListTable/OTCreatePopup";
import PayrollTable, {
  statusList,
} from "../../components/PayrollTable/PayrollTable";
import ImportUsersModal from "../../components/BackendUsers/ImportUsersModal";
import ExportUsersModal from "../../components/BackendUsers/ExportUsersTable";
import axios from "../../axios";
import { endpointUrl } from "../../app/services/api";
import { toast } from "react-toastify";
import { useGetPayRollFilterListQuery, useGetPayRollListQuery } from "../../app/services/payroll";
import GeneratePopup from "../../components/BackendUsers/GeneratePopup";
import MonthYearPicker from "../../components/EmployeeTable/MonthYearPicker";
import { getAuthUser } from "../../app/services/dashboard";
import CommonTableSkeleton from "../../components/TableSkeleton/CommonTableSkeleton";
export interface IPayListData {
  uniqeID: number;
  payrollNo: string;
  processDate: string;
  employeeID: string;
  employeeName: string;
  month: string;
  basicSalary: string;
  sgd: string;
  totalDays: string;
  officialOff: string;
  hourlyRate: string;
  sgd1: string;
  al: number;
  unpaidLeave: number;
  regWorkingDays: number;
  actWorkingDays: number;
  countWorkingDays: number;
  countSalary: string;
  countActiveTime: number;
  official90ActiveHours: number;
  official80ActiveHours: number;
  official70ActiveHours: number;
  internetIncentive: string;
  noAbsentIncentive: string;
  noLatenessIncentive: string;
  wordingRewards1: string;
  wordingRewards2: string;
  otherRewards: string;
  allowance: string;
  totalIncentive: string;
  otFees: string;
  bonus: string;
  mpf: string;
  ssb: string;
  incomeTax: string;
  unpaidLeaveDay: number;
  unpaidLeaveDeduction: string;
  salaryPayment: string;
  salarySGD: string;
  finalPayment: string;
  mobileNo: string;
  receiptBank: string;
  receiptNo: string;
  accountName: string;
  branchCode: string;
  bankAccNo: string;
  status: string;
}

interface IOT { }
export interface IDepartment {
  id: number;
  name: string;
  label: string;
}
const defaultFields = [
  "uniqeID",
  "payroll_no",
  "process_date",
  "employee_id",
  "employee_name",
  "month",
  "basic_salary",
  "basic_salary_sgd",
  "total_days",
  "office_off_days",
  "hourly_rate",
  "hourly_rate_sgd",
  "annual_leave",
  "unpaid_leave",
  "regular_working_days",
  "actual_working_days",
  "counted_working_days",
  "counted_working_days_salary",
  "counted_active_time_hours",
  "official_90_percent_active_hours",
  "official_80_percent_active_hours",
  "official_70_percent_active_hours",
  "internet_incentive",
  "no_absent_incentive",
  "no_lateness_incentive",
  "work_quality_rewards_tier_1",
  "work_quality_rewards_tier_2",
  "other_rewards",
  "allowance",
  "total_incentive_reward",
  "ot_days",
  "ot_fee",
  "bonus",
  "mpf",
  "ssb",
  "income_tax",
  "other_deduction",
  "total_leaves",
  "unpaid_leave",
  "unpaid_leave_deduction",
  "gross_pay",
  "total_deduction",
  "salary_payment",
  "salary_payment_sgd",

  "final_payment_sgd",

  "mobile",
  "receipt_bank",
  "receipt_no",

  "bank_account_name",

  "receipt_bank_branch",

  "bank_account_no",

  "is_sent",
  "actions",
];
const PayRollList: FC<IOT> = () => {
  const [globalSearch, setGlobalSearch] = useState<string>("");
  const [isImportModalOpen, setIsImportModalOpen] = useState<boolean>(false);
  const [isExportModalOpen, setIsExportModalOpen] = useState<boolean>(false);
  const [isImporting, setIsImporting] = useState<boolean>(false);
  const [isExporting, setIsExporting] = useState<boolean>(false);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const [data, setData] = useState<baseObj[]>([]);
  const [itemCount, setItemCount] = useState<number>(data?.length);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  });
  const [startDateData, setStartDateData] = useState<any>();
  const [sorting, setSorting] = useState<SortingState>([]);

  const [status, setStatus] = useState("All");
  const [limit, setLimit] = useState<number>(20);
  const [isTemplate, setIsTemplate] = useState<boolean>(false);
  const [viewCols, setCols] = useState<any>([]);
  const [visArray, setVisArray] = useState<any>({});
  const [currentView, setCurrentView] = useState<any>(0);
  const [currentViewData, setCurrentViewData] = useState<number>(0);
  const [tempList, setTempList] = useState<any>(templateList);
  const [moduleList, setModuleList] = useState<any>([]);
  const [errorPopup, setErrorPopup] = useState<boolean>(false);
  const [message, setMessage] = useState<any>([]);
  const [isEditing, setIsEdit] = useState<boolean>(false);
  const [successPopup, setSuccessPopup] = useState<boolean>(false);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [isLayoutControlOpen, setIsLayoutControlOpen] =
    useState<boolean>(false);
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  const [hiddenFields, setHiddenFields] = useState<string[]>([]);
  let [localModuleId, setLocalModuleId] = useState<number>(0);
  const { data: templateData } = useGetByModuleTemplateQuery(localModuleId);
  var getUser = getAuthUser();

  const { data: payRollList, isLoading: PayRollListLoading } =
    useGetPayRollListQuery({
      page: currentPage,
      limit: pagination.pageSize,
      search: globalSearch,
      status: status,
      month: startDateData ? renderDateFormatMY(startDateData, " ") : "",
      sorting: sorting.map((s) => `${s.desc ? "-" : ""}${s.id}`).join(","),
    });
  var disabledData = checkedIsNew(data);
  const addOTList = () => {
    return {};
  };
  const deleteTemplateData = (id: string) => {
    var filterTempList = templateList?.filter((x) => x.id != parseInt(id));
    setTempList(filterTempList ? filterTempList : []);
    // deleteTemplate(parseInt(id));
  };
  const props = useSpring({
    to: { opacity: 1 },
    from: { opacity: 0 },
    delay: 1000,
  });

  const fetchDataMemoized = useCallback(() => {
    if (payRollList) {
      setData(payRollList.payrolls.data);
      setItemCount(payRollList.payrolls.total);
      setLocalModuleId(payRollList.module_id);
    }
  }, [payRollList]);

  useEffect(() => {
    fetchDataMemoized();
  }, [fetchDataMemoized, hiddenFields, currentView]);

  useEffect(() => {
    if (templateData) {
      var updatedTemplate = templateData?.templates.map((x: any, i: any) => {
        return { ...x, index: i + 1 };
      });
      var defaultTemplate = {
        id: -1,
        index: 0,
        module: 0,
        name: "All",
      };
      setModuleList([defaultTemplate, ...updatedTemplate]);
    }
  }, [templateData]);

  useEffect(() => {
    if (templateList) {
      setTempList(templateList);
    }
    var viewData = templateData?.templates.find(
      (x) => x.id.toString() == currentView
    );
    setHiddenFields(viewData ? [...viewData.viewColumns] : defaultFields);
    hideAllNoneField();
  }, [currentView, defaultFields]);

  useEffect(() => {
    if (viewCols?.view_columns?.length > 0) {
      setHiddenFields(viewCols?.view_columns);
    }
  }, [viewCols?.view_columns]);

  useEffect(() => {
    if (moduleList) {
      var lastesTemplate = moduleList[moduleList?.length - 1];
      setCurrentViewData(lastesTemplate ? Number(lastesTemplate?.id) : 0);
    }
  }, [moduleList]);

  const changePage = (pageNo: number) => {
    var pageData = {
      limit: pagination.pageSize,
      currentPage: pagination.pageIndex + 1,
    };
    // getPermissions(pageData)
  };

  const SaveOTListData = (data: any) => { };

  const downloadSampleFile = () => {
    axios({
      url: endpointUrl + "downloadEmployeeSample",
      method: "GET",
      responseType: "blob",
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "sampleEmployee.xlsx");
      document.body.appendChild(link);
      link.click();
      setIsDownloading(false);
    });
  };
  const sampleImportCSV = () => {
    downloadSampleFile();
  };
  useEffect(() => {
    setCurrentPage(1)
  }, [limit, status, globalSearch, startDateData])

  const exportPayRoll = (format: string, isChecked: string) => {
    axios({
      url: endpointUrl + `payrolls?search=${globalSearch}&page=${currentPage}&limit=${limit}&month=${startDateData ? renderDateFormatMY(startDateData, " ") : ""}&export=true&only_this_page=${isChecked}&format=` + format + "",
      method: "GET",
      responseType: "blob",
      headers: {
        Authorization: `Bearer ${getUser.token}`,
      },
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          "payroll." + (format === "excel" ? "xlsx" : format)
        );
        document.body.appendChild(link);
        link.click();
        setIsExporting(false);
        setIsExportModalOpen(false);
      })
      .catch((error: any) => {
        setErrorPopup(true);
        setMessage(
          error?.message
            ? [error?.message]
            : ["Something wrong!. Trying again."]
        );
      });
  };

  const importPayrollList = async (formSaveData: any) => {
    await axios(endpointUrl + "importEmployees", {
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formSaveData,
    }).then((res) => {
      // setImportProject(true)
      setSuccessPopup(true);
      setMessage(["Successfully import."]);

      axios
        .get(
          `${endpointUrl}employeeListData?department=All&nationality=All&status=All&limit=20&page=1&search=`,
          {}
        )
        .then((result) => {
          var empUpdatedData = result.data;
          setData(empUpdatedData.employeeList?.data);
          setIsImporting(true);
          setIsImportModalOpen(false);
          toast("Successfully Import.", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            type: "success",
          });
        });
    });
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [globalSearch, pagination.pageSize, status, startDateData]);

  return (
    <>
      {PayRollListLoading ? (
        <CommonTableSkeleton rows={12} />
      ) : (
        <div>
          <GeneratePopup
            isOpen={isImportModalOpen}
            setIsOpen={setIsImportModalOpen}
            sampleCSV={sampleImportCSV}
            importEmployee={importPayrollList}
            isImporting={isImporting}
            setIsImporting={setIsImporting}
            setIsDownloading={setIsDownloading}
            isDownloading={isDownloading}
          />

          <ExportUsersModal
            isOpen={isExportModalOpen}
            setIsOpen={setIsExportModalOpen}
            exportToCSV={exportPayRoll}
            exportToExcel={exportPayRoll}
            exportToPDF={exportPayRoll}
            exportToZip={exportPayRoll}
            isExporting={isExporting}
            setIsExporting={setIsExporting}
            isShowOnlyCheckbox={true}            
          />
          <div className="px-2 sm:px-4 py-4 bg-white sm:mt-[25px] mt-0 font-poppins text-13 font-semibold text-[#858795] tracking-[-0.03rem] w-full h-full rounded-xl pt-3 pb-8 sm:pt-3 sm:pb-9">
            <div className="flex flex-wrap justify-between items-center">
              <div className="flex items-center mr-2 xs:mr-4 sm:w-[260px] w-[225px] md:mb-1">
                <DebounceSearchInput setState={setGlobalSearch} debounce={800}>
                  {(setState) => (
                    <div className="font-normal flex border-1 border-[#C4C4C4] bg-white hover:bg-[#EFEFEF] rounded-[28px]">
                      <input
                        id="message"
                        autoComplete="off"
                        name="search"
                        type="text"
                        placeholder="Search"
                        className="focus:outline-none py-1 px-4 text-12 xl:text-14 leading-5 bg-transparent rounded-[28px] w-full text-graydark"
                        onChange={(e) => setState(e.target.value)}
                      />
                      <SearchIcon className={classNames("mr-2")} />
                    </div>
                  )}
                </DebounceSearchInput>
              </div>
              <div className="flex  items-center xs:justify-end md:justify-end justify-end">
                <Button
                  label={
                    <span className="flex items-center">
                      <GenerateIcon className="mr-[5px]" />
                      Generate
                    </span>
                  }
                  onClick={() => {
                    setIsImportModalOpen(true);
                    setIsImporting(false);
                  }}
                  type="button"
                  variant="no-outline"
                  customClass="bg-transparent border-transparent sm:flex hover:border-vorpblue hover:text-vorpblue transition-all duration-1000 text-black2 xs:text-[12px] leading-[18px] font-medium py-[7px] px-[6px] mr-[10px]"
                  size="base"
                />
                <Button
                  label={
                    <span className="flex items-center">
                      <ExportIcon className="mr-[5px]" />
                      Export
                    </span>
                  }
                  onClick={() => {
                    setIsExportModalOpen(true);
                    setIsExporting(false);
                  }}
                  type="button"
                  variant="no-outline"
                  customClass="my-1 bg-vorpmyanmar border-vorpmyanmar hover:bg-vorpblue hover:text-white transition-all duration-300 text-vorpblue xs:text-[12px] leading-[18px] font-medium py-1 px-[6px] sm:mr-[10px] mr-[5px]"
                  size="base"
                />
              </div>
            </div>

            <div className="flex sm:flex-row flex-col justify-between items-center mb-1">
              <div className="flex w-full sm:mr-5 custom-scrollbar sm:mt-0 mt-1 sm:mb-0 mb-1">
                <CustomScrollbar isVertical={false} isHor={true}>
                  <div className="flex w-full items-center">
                    <div className={`w-auto flex items-center mr-3`}>
                      <p className="htzxs:w-auto w-1/2 text-left base-label xl:text-sm text-12 hidden">
                        Status
                      </p>
                      <CustomDropdownListForFilter
                        isFilter={true}
                        state={status}
                        setState={setStatus}
                        dropdownItems={
                          statusList
                            ? statusList.map((dp) => ({
                              value: dp.id,
                              label: dp.name,
                            }))
                            : []
                        }
                        className=" 2xl:w-32 w-28 min-w-[100px] 6xs:text-12"
                        placeholder="Status"
                        textAlign="text-left py-2 pl-2 pr-3"
                      />
                    </div>
                    <div className="flex items-center w-auto">
                      <p className="hidden whitespace-nowrap sm:w-auto 2xs:w-[20%] w-1/2 text-left  uppercase xl:text-sm text-12 leading-5 font-medium text-black2 mr-[10px]">
                        Process Date
                      </p>
                      <div className="flex flex-row w-full">
                        {startDateData && (
                          <button
                            type="button"
                            onClick={() => {
                              setStartDateData(undefined);
                            }}
                            className="text-12 mr-2"
                          >
                            <CloseIcon color="#EE436D" width={10} height={10} />
                          </button>
                        )}
                        <div className={` mr-[10px] htzxs:w-auto w-full`}>
                          <MonthYearPicker
                            isShow={false}
                            dobDate={startDateData}
                            setDate={setStartDateData}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </CustomScrollbar>
              </div>
              <div className="flex items-center justify-end md:justify-start sm:w-auto w-full">
                <div className="flex items-center mb-auto mr-3">
                  <p className="base-label xl:text-sm text-12 hidden 3xs:block">
                    DISPLAY
                  </p>
                  <NumberDropdownList
                    state={pagination.pageSize}
                    setState={(value) => {
                      setPagination((prev) => ({
                        ...prev,
                        pageSize: value as number,
                      }));
                      setLimit(value);
                    }}
                    dropdownItems={new Array(5)
                      .fill(1)
                      .map((_, index: number) => {
                        let display = (index + 1) * 20;
                        return {
                          value: display,
                          label: String(display),
                        };
                      })}
                    className="max-w-[72px]"
                    textAlign="text-left pl-2 pr-3"
                    paddingClass="py-[2px]"
                    placeholder="20"
                  />
                </div>
                <StringDropdownListWithDeleteBtn
                  haveDeleteBtn={true}
                  setIsTemplate={setIsTemplate}
                  state={isTemplate ? currentViewData : currentView}
                  setState={setCurrentView}
                  deleteTemplate={deleteTemplateData}
                  dropdownItems={moduleList.map((x: any, index: number) => {
                    return {
                      label: x.id === -1 ? x.name : "View #" + x.index,
                      value: x.id,
                    };
                  })}
                  setErrorPopup={setErrorPopup}
                  setSuccessPopup={setSuccessPopup}
                  setMessage={setMessage}
                  className="w-full min-w-[100px] 2xl:min-w-[128px] 6xs:text-12"
                  placeholder="View #1"
                  custPadding="py-[2px]"
                  textAlign="text-left py-2 pl-2 pr-3"
                />
                <button
                  type="button"
                  onClick={() => setIsLayoutControlOpen(true)}
                  className="flex relative justify-center items-center rounded-lg bg-vorphongkongborder w-[29px] h-[29px] ml-2 group transition-all duration-300 "
                >
                  <CustomizeLayoutIcon pathClassName="group-hover:fill-white" />
                  <div className="absolute left-7 top-[-5px] border border-white w-4 h-4 rounded-full bg-vorphongkongborder hover:bg-vorphongkongborder flex justify-center items-center">
                    <AddIcon width={8} height={8} color="white" />
                  </div>
                </button>
              </div>
            </div>
            <animated.div style={props}>
              <PayrollTable
                data={data}
                setData={setData}
                pagination={pagination}
                setPagination={setPagination}
                isLayoutControlOpen={isLayoutControlOpen}
                setIsLayoutControlOpen={setIsLayoutControlOpen}
                globalSearch={globalSearch}
                setGlobalSearch={setGlobalSearch}
                columnFilters={columnFilters}
                setColumnFilters={setColumnFilters}
                setHiddenFields={setHiddenFields}
                deleteEmployee={() => 1}
                deleteEmployeeByIDs={() => 1}
                hiddenFields={hiddenFields ? hiddenFields : []}
                orgDataList={[]}
                templateList={[]}
                setCurrentView={setCurrentView}
                setIsEdit={setIsEdit}
                isEditing={isEditing}
                localModuleId={localModuleId}
                total={itemCount}
                changePage={changePage}
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
                setIsTemplate={setIsTemplate}
                setCols={setCols}
                visArray={visArray}
                sorting={sorting}
                setSorting={setSorting}
              />
            </animated.div>
          </div>
        </div>
      )}
    </>
  );
};
export default PayRollList;
