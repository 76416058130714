import { FC, Dispatch, useState, SetStateAction, useEffect } from "react";
import { CloseIcon } from "../../common/Icons";
import { baseObj } from "../../../utils/constants";
import eyehide from "../../../login/img/eye-open.svg";
import eyeshow from "../../../login/img/eye-show.svg";
import styles from "../../../login/components/LogInComponent.module.scss";
import { Fade } from "@mui/material";

var classNames = require("classnames");

interface IQuotationNumModal {
  closeModal: () => void;
  setData: Dispatch<SetStateAction<baseObj[]>>;
  rowId: number;
  keyname: string;
  addNewItemData?: any;
  getAllSeePwd?: any;
  isSuccess?: boolean;
  setIsSuccess?: any;
  isError?: boolean;
  setIsError?: any;
  opens?: boolean;
}

const ShowPassword: FC<IQuotationNumModal> = ({
  closeModal,
  setData,
  rowId,
  keyname,
  addNewItemData,
  opens,
  getAllSeePwd,
  isSuccess,
  setIsSuccess,
  setIsError,
  isError,
}) => {
  const [values, setValues] = useState<any | undefined>("");
  const [loadingBtn, setLoadingBtn] = useState<boolean>(false);

  const addNewItem = (e: any) => {
    e.preventDefault();
    setIsError(false);
    setLoadingBtn(true);
    setData((prev) =>
      prev.map((row) => {
        if (row.uniqeID === rowId) {
          return { ...row, [keyname]: values };
        } else {
          return row;
        }
      })
    );
    if (getAllSeePwd) {
      getAllSeePwd(values);
    }
  };

  useEffect(() => {
    if (isSuccess) {
      closeModal();
      setLoadingBtn(false);
    }
    if (isError) {
      setLoadingBtn(false);
    }
  }, [isSuccess, isError]);

  const switchEyeHandler = (event: any) => {
    setIsSuccess(false);
    setIsError(false);
    var hideimg = eyehide;
    var showimg = eyeshow;
    const type =
      event.target.previousElementSibling.getAttribute("type") === "password"
        ? "text"
        : "password";

    const img =
      event.target.previousElementSibling.getAttribute("type") === "password"
        ? hideimg
        : showimg;

    event.target.previousElementSibling.setAttribute("type", type);
    event.target.setAttribute("src", img);
  };

  // const handleKeypress = (e: any) => {
  //   //it triggers by pressing the enter key
  //   if (e.keyCode === 13) {
  //     addNewItem(e);
  //   }
  // };

  return (
    <Fade in={opens} timeout={500}>
      <div className="6xs:max-w-[320px] px-4 5xs:px-0 w-full m-auto focus:outline-none">
        <div className="bg-white rounded-lg w-full 5xs:w-[320px] h-[160px] left-0 right-0 mx-auto relative p-5">
          <button
            type="button"
            onClick={closeModal}
            className="absolute right-4 top-4 w-[10px] h-[10px] z-10"
          >
            <CloseIcon width={12} height={12} color="#C4C4C4" />
          </button>
          <form onSubmit={addNewItem} autoComplete="off">
            <h1 className="text-12 leading-[16px] font-semibold text-black2 mb-0">
              Enter your password
            </h1>
            <span className="text-12 leading-[10px] font-semibold text-red-600 mb-1">
              {/* {isError ? "Your are't Authorized" : ""} */}
            </span>
            <div className="flex justify-center items-center relative">
              <input
                value={values}
                onChange={(e) => setValues(e.target.value)}
                type="password"
                name="pwd"
                id="password"
                autoComplete="new-password"
                placeholder="Enter Password"
                className={`text-vorpgraylight py-2 px-3 w-full focus:outline-none border-2 border-vorpblue rounded-4 text-14 leading-5 mt-5`} //${isError ? `mt-0` : `mt-5`}
              />
              <img
                src={eyeshow}
                alt="eye-open"
                className={classNames(
                  styles.theeye,
                  "cursor-pointer w-[22px] h-[16px] object-cover absolute top-10 right-2"
                )}
                onClick={switchEyeHandler}
              />
            </div>

            <div className="flex justify-end items-center pt-3">
              <button
                type="button"
                onClick={closeModal}
                className="py-2 px-4 flex justify-center items-center text-12 leading-[18px] font-semibold text-vorpgraylight bg-offwhite mr-2 rounded-md transition-all duration-300 hover:bg-[#C7C7C7] hover:text-white"
              >
                Cancel
              </button>
              <button
                onClick={addNewItem}
                type="submit"
                // type="button"
                className={classNames(
                  loadingBtn ? "opacity-50 pointer-events-none" : "",
                  "py-2 px-4 flex justify-center items-center text-12 leading-[18px] font-semibold text-white bg-vorpblue rounded-md transition-all duration-300 hover:bg-annhoverbg hover:text-vorpblue"
                )}
              >
                {loadingBtn ? "Loading" : "Submit"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </Fade>
  );
};

export default ShowPassword;
