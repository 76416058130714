import { ReactElement } from 'react';
import BasicLayout from '../layouts/BasicLayout';
import { Outlet } from 'react-router-dom';
import ProjectCheckerAcc from './view/ProjectCheckerAcc';

const ProjectCheckerAccRoute = (props:any): ReactElement => {
  const data=props.loginData;

  const permissions = props.permissions;

 return (
    <BasicLayout userData={data} permissions={permissions}>
      <ProjectCheckerAcc/>
      <Outlet />
    </BasicLayout>
  )
};

export default ProjectCheckerAccRoute;
