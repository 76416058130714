import {
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { baseObj } from "../../utils/constants";
import { Cell } from "@tanstack/react-table";
// import { makeStyles, Tooltip } from "@material-ui/core";
import { makeStyles } from "@mui/styles";
import { Tooltip } from "@mui/material";

import CopyToClipboardBtn from "../DashboardProfile/MyPasswords/CopyToClipboardBtn";
import eyehide from "../../login/img/eye-open.svg";
import eyeshow from "../../login/img/eye-show.svg";
import classNames from "classnames";
import DebounceSearchInput from "./DebounceSearchInput";
import DebounceInput from "./DebounceInput";

const useStyles = makeStyles(() => ({
  tooltip: {
    backgroundColor: "transparent",
    margin: 0,
    marginRight: 8,
    padding: 0,
  },
}));
interface IEditableCell {
  value: string;
  cell: Cell<any, any>;
  type: string;
  setData: Dispatch<SetStateAction<baseObj[]>>;
  className?: string;
  CustomComponent?: ReactNode;
  hasPadding?: boolean;
  formatResult?: (value: string | number | string[]) => ReactNode;
  customDesign?: string;
  customDesignOverflow?: string;
  IsDisabled?: boolean;
  isDuration?: boolean;
  setEditProjectAmountValue?: Dispatch<any>;
  maxLength?: number;
  setIsChanged?: any;
  isChanged?: string;
  isYear?: boolean;
  id: number;
  isEdit?: boolean;
  setDataChanged: any;
  setIsShow: React.Dispatch<React.SetStateAction<any>>;
  isShow: any;
  setActiveRow?:React.Dispatch<React.SetStateAction<number | undefined>>;
  code?:string;
}

const EditableCellV2: FC<IEditableCell> = ({
  value,
  cell,
  type,
  setData,
  className,
  CustomComponent,
  hasPadding = false,
  formatResult,
  customDesign,
  IsDisabled,
  isDuration,
  setEditProjectAmountValue,
  maxLength,
  setIsChanged,
  isChanged,
  isYear,
  id,
  isEdit,
  setDataChanged,
  isShow,
  setIsShow,
  setActiveRow,
  code
}) => {

  const encodeAlphabet = (alphabet: string) => {
    if (alphabet) {
      return alphabet.toString().replace(/[a-zA-Z0-9]/g, "*");
    } else {
      return "";
    }
  };
  const [fieldValue, setFieldValue] = useState<any>(
    //encodeAlphabet(value) || ""
    code+" "+value || ""
  );
  const classes = useStyles();
  // const isEllipsisActive = (element: any) => {
  //   return element.offsetWidth < element.scrollWidth;
  // }

  const isEditable = cell.row.original?.activeId;
  const fieldType = type === "email" ? "text" : type;
  const editCell = useCallback(() => {
    setData((prev: baseObj[]) => {
      return prev.map((data: baseObj, index: number) => {
        if (index === Number(cell.row.id)) {
          if (cell.column.id == "password") {
            return { ...data, [cell.column.id]: fieldValue, isChanged: true };
          } else {
            return { ...data, [cell.column.id]: fieldValue, isChanged: false };
          }
        }
        return data;
      });
    });
  }, [fieldValue, setData, cell]);

  useEffect(() => {
    if (setEditProjectAmountValue) {
      setEditProjectAmountValue(fieldValue);
    }
  }, [fieldValue]);
  const switchEyeHandler = (event: any) => {
    var hideimg = eyehide;
    var showimg = eyeshow;
    const type =
      event.target.previousElementSibling.getAttribute("type") === "password"
        ? "text"
        : "password";
    const img =
      event.target.previousElementSibling.getAttribute("type") === "password"
        ? hideimg
        : showimg;
    event.target.previousElementSibling.setAttribute("type", type);
    event.target.setAttribute("src", img);
  };
  const handleFocusInput = (event: any) => {

  }
  switch (fieldType) {
    case "text":
      return (
        <DebounceInput setState={setDataChanged} debounce={800}>
          {(setState) => (
            <input
              id={cell.id}
              type="text"
              defaultValue={value}
              className={`min-w-full min-h-full w-[80%] h-full focus:outline-none focus-within:outline-none focus-visible:outline-none text-ellipsis overflow-hidden disabled:bg-transparent px-2 font-normal font-poppins ${className}`}
              onChange={(e) => setState({
                id: id,
                cellId: cell.column.id,
                value: e.target.value
              })}
            />
          )}
        </DebounceInput>
      );
    case "number":
      return (
        <DebounceInput setState={setDataChanged} debounce={800}>
          {(setState) => (
            <input
              id={cell.id}
              type="text"
              //defaultValue={isShow.find((x: any) => x.id == cell.id)?.isShow == true ? value : fieldValue}
              defaultValue={fieldValue}
              placeholder="0"
              className={`min-w-full min-h-full w-[80%] h-full focus:outline-none focus-within:outline-none focus-visible:outline-none text-ellipsis overflow-hidden disabled:bg-transparent px-2 font-normal font-poppins ${className}`}
              onChange={(e) => {
                const result = e.target.value.replace(/\D/g, '');
                e.target.value = result;

                if(setActiveRow){
                  setActiveRow(id);
                }                
                setState({
                id: id,
                cellId: cell.column.id,
                value: result
              })}}
              onFocus={(e) => {
                e.target.value = value;
               }}
              // onBlur={(e) => {
              //   e.target.value = fieldValue
              // }}
            />
          )}
        </DebounceInput>

      );
    case "password":
      return (
        <div className="flex justify-center min-w-[200px] items-center">
          <input
            disabled={IsDisabled}
            autoComplete="new-password"
            type={type}
            name={cell.id}
            id={cell.id}
            className={`min-w-[200px] min-h-full h-full focus:outline-none focus-within:outline-none focus-visible:outline-nonefocus:outline-none disabled:bg-transparent pr-2 font-normal font-poppins ${isEditable == cell.id ? "bg-vorpmyanmar text-black2" : "pointer-events-none"
              } ${className}`}
            value={fieldValue}
            // value={fieldValue == "***********" ? "" : fieldValue}
            {...(isEditable == cell.id ? {} : { disabled: true })}
            onChange={(e) => setFieldValue(e.target.value)}
            onBlur={editCell}
          // defaultValue={value}
          />
          <img
            src={eyeshow}
            alt="eye-open"
            className={classNames(
              //styles.theeye,
              "absolute top-1/2 -translate-y-1/2 right-3 cursor-pointer w-[22px] h-[16px] object-cover"
            )}
            onClick={switchEyeHandler}
          />
        </div>
      );
    case "custom":
      return <div className="w-full h-full">{CustomComponent}</div>;
    case "label":
      return <div className="w-full h-full">{fieldValue}</div>;
    default:
      return <></>;
  }
};

export default EditableCellV2;
