import {
  Column,
  ColumnOrderState,
  Header,
  Table,
  flexRender,
  ColumnResizeMode,
} from "@tanstack/react-table";
import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { DraggableIcon, SelectedIcon, SortIcon } from "../common/Icons";
import { useDrag, useDrop, DndProvider } from "react-dnd";
import classNames from "classnames";
import { IColumnHelpersState } from "./LayoutAdjustableTable";
import ColumnHelperTooltip from "./HelperTooltip";
// import { CSSProperties } from "@material-ui/core/styles/withStyles";
import { CSSProperties } from "@mui/styles/withStyles";
import { HTML5Backend } from "react-dnd-html5-backend";
import { usePreview } from "react-dnd-preview";
import Tables from "./Tables.module.scss";

interface IReusableDraggableHeader {
  header: Header<unknown, unknown>;
  table: Table<unknown>;
  unDraggableFields: string[];
  theaderColor?: string;
  setIsColumnHelpersOpen: Dispatch<SetStateAction<IColumnHelpersState>>;
  isColumnHelpersOpen: IColumnHelpersState;
  thColor?: string;
  isPinned: any;
  setPinnedArr: any;
  classes: string;
  setSortDirection?: Dispatch<SetStateAction<string>>;
  sortDirection?: string;
  columnResizeMode: ColumnResizeMode;
  // getLeftStickyPos:any;
  index: number;
  setChangeHeader: Dispatch<SetStateAction<boolean>>;
  // pinnedArr: string[];
}

const reorderColumn = (
  draggedColumnId: string,
  targetColumnId: string,
  columnOrder: string[]
): ColumnOrderState => {
  columnOrder.splice(
    columnOrder.indexOf(targetColumnId),
    0,
    columnOrder.splice(columnOrder.indexOf(draggedColumnId), 1)[0] as string
  );
  return [...columnOrder];
};

const ResuableDraggableHeader: FC<IReusableDraggableHeader> = ({
  header,
  table,
  unDraggableFields,
  theaderColor = "",
  setIsColumnHelpersOpen,
  isColumnHelpersOpen,
  thColor,
  isPinned,
  setPinnedArr,
  classes,
  setSortDirection,
  sortDirection,
  columnResizeMode,
  // getLeftStickyPos,
  index,
  setChangeHeader,
  // pinnedArr,
}) => {
  const { getState, setColumnOrder } = table;
  const { columnOrder } = getState();
  const { column } = header;
  const isSelection = header.column.id === "selection";
  const uniqeID = header.column.id === "uniqeID";
  const [isDraggingItem, setIsDraggingItem] = useState<boolean>(false);

  const [, dropRef] = useDrop({
    accept: "column",
    drop: (draggedColumn: Column<unknown>) => {
      const newColumnOrder = reorderColumn(
        draggedColumn.id,
        column.id,
        columnOrder
      );
      setColumnOrder(newColumnOrder);
    },
  });
  const handleStyle: CSSProperties = {
    cursor: "grabbing",
  };
  const MyPreview = (props: any) => {
    const preview = usePreview();
    if (!preview.display) {
      return null;
    }
    const { itemType, item, style } = preview;
    var itemData: any = item;
    return (
      <div
        className="text-13 leading-[18px] whitespace-nowrap font-semibold bg-[#C4C4C4] border border-vorpgraylight p-[6px] w-[150px] text-graydark cursor-grabbing custom-preview z-[999]"
        style={getItemStyles()}
      >
        {itemData?.columnDef?.header}
      </div>
    );
  };
  const [{ currentOffset, isDragging, opacity }, dragRef] = useDrag({
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      opacity: monitor.isDragging() ? 0.5 : 1,
      currentOffset: monitor.getSourceClientOffset(),
    }),
    item: () => column,
    type: "column",
  });
  function getItemStyles() {
    const { x, y } = currentOffset || {};

    // Define your custom preview text styles here
    const previewTextStyle: CSSProperties = {
      position: "fixed",
      left: x,
      top: y,
      pointerEvents: "none",
      //fontWeight: 'bold',
      color: "text-black2",
      //textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
      fontSize: "12px",
    };

    return previewTextStyle;
  }

  return (
    <th
      {...{
        key: header.id,
        style: {
          width: header.getSize(),
        },
      }}
      id={"header" + header.id}
      key={header.id}
      // onClick={header.column.getToggleSortingHandler()}
      onContextMenu={(e) => {
        e.preventDefault();
        const headerId = header.id;
        setIsColumnHelpersOpen({
          [headerId]: true,
        });
      }}
      ref={unDraggableFields.includes(header.id) ? null : dropRef}
      style={{
        backgroundColor: thColor || "#F3F6F9",
        //left: getLeftStickyPos(index),
      }}
      className={classNames(
        isPinned ? "sticky z-[9999]" : "relative",
        classes,
        "lg:text-13 text-12 leading-[18px] whitespace-nowrap font-semibold  bg-offWhite border border-vorpgraylight p-3",
        {
          "text-center": isSelection,
          "text-vorpgraylight": theaderColor === "",
          [theaderColor]: theaderColor !== "",
        }
      )}
    >
      <DndProvider backend={HTML5Backend}>
        <div
          className={classNames(
            isDragging ? "cursor-grabbing" : "",
            `flex items-center ${
              isSelection ? "justify-center" : "justify-between"
            }`
          )}
        >
          {isSelection && (
            <label htmlFor="checkAll" className="relative">
              <input
                type="checkbox"
                id="checkAll"
                className=" checked:bg-vorpblue w-3 h-3 sm:w-4 sm:h-4 p-3 border-none outline-none appearance-none bg-[#EFF2F5] rounded hover:cursor-pointer"
                checked={table.getIsAllRowsSelected()}
                onChange={table.getToggleAllRowsSelectedHandler()}
              />
              {table.getIsAllRowsSelected() && (
                <SelectedIcon className="absolute top-2 left-0 right-0 m-auto" />
              )}
            </label>
          )}

          {!isSelection && (
            <div
              style={{ opacity }}
              className={classNames(
                isDragging ? "cursor-grabbing" : "",
                "flex items-center w-full"
              )}
            >
              {!unDraggableFields.includes(header.id) && (
                <button
                  ref={dragRef}
                  style={handleStyle}
                  type="button"
                  className={classNames(
                    isDragging ? "cursor-grabbing" : "cursor-grabbing",
                    "mr-2 hover:cursor-grabbing"
                  )}
                >
                  <DraggableIcon />
                </button>
              )}
              {header.isPlaceholder
                ? null
                : flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}
              <div
                key={Math.random() * 1000}
                onMouseDown={header.getResizeHandler()}
                onTouchStart={header.getResizeHandler()}
                className={`${Tables.resizer} 
                          hover:bg-sky-700 hover:w-1
                      ${
                        header.column.getIsResizing()
                          ? `${Tables.isResizing}`
                          : ""
                      }`}
                style={{
                  transform:
                    columnResizeMode === "onEnd" &&
                    header.column.getIsResizing()
                      ? `translateX(${
                          table.getState().columnSizingInfo.deltaOffset
                        }px)`
                      : "",
                }}
              ></div>
            </div>
          )}
          {header.column.getCanSort() && (
            <button
              onClick={() => {
                const currentState = header.column.getIsSorted() === "desc";
                if (setSortDirection) {
                  setSortDirection(sortDirection === "asc" ? "desc" : "asc");
                }
                header.column.toggleSorting(!currentState, false);
              }}
              className="ml-2"
            >
              <SortIcon className="sort-icon" />
            </button>
          )}
        </div>
        {isDragging ? (
          <MyPreview
            content={
              header.isPlaceholder
                ? null
                : flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )
            }
          />
        ) : (
          ""
        )}
      </DndProvider>

      {/* Right Click Helper */}

      {header.id != "permission" && header.id != "actions"
        ? isColumnHelpersOpen?.[header.id] && (
            <>
              <ColumnHelperTooltip
                IsColumnHelpersOpen={isColumnHelpersOpen}
                setIsColumnHelpersOpen={setIsColumnHelpersOpen}
                header={header}
                setPinnedArr={setPinnedArr}
              />
            </>
          )
        : ""}
    </th>
  );
};

export default ResuableDraggableHeader;
