import Ai from "./ai.svg";
import Css from "./css.svg";
import Docs from "./doc.svg";
import Pdf from "./pdf.svg";
import Tif from "./tif.svg";
import Xml from "./xml.svg";
import User1 from "./user1.svg";
import User2 from "./user2.svg";
import User3 from "./user3.svg";
import Folder from "./folder.svg";
import FolderOpen from "./folderOpen.svg";
import Thumbnail from "./thumbnail.svg";
import XLSXIcon from "./xlsx_icon.svg";
import ZIP from "./zip.svg";
import Unknown from "./unknown.svg";

export type IDocsIcon = { [key: string]: string };

export const DocsIcon: IDocsIcon = {
  ai: Ai,
  css: Css,
  doc: Docs,
  pdf: Pdf,
  tif: Tif,
  xml: Xml,
  folder: Folder,
  png:Thumbnail,
  jpg:Thumbnail,
  jpeg:Thumbnail,
  gif:Thumbnail,
  webp:Thumbnail,
  svg:Thumbnail,
  folderOpen: FolderOpen,
  xlsx: XLSXIcon,
  zip: ZIP,
  unknow: Unknown
};

export { User1, User2, User3 };
