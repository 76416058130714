import {
  ColumnDef,
  ColumnFiltersState,
  createColumnHelper,
  PaginationState,
  RowData,
  RowSelectionState,
  SortingState,
} from "@tanstack/react-table";
import axios from "axios";
import classNames from "classnames";
import React, {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { endpointUrl } from "../../app/services/api";
import { TemplateData } from "../../app/services/employee";
import { IKeyValue, Tdocuments } from "../../leave-application/views/LeaveApplication";
import { baseObj } from "../../utils/constants";
import {
  CancelIcon,
  CheckIcon,
  CloseSmallIcon,
  EditIcon,
  LoadingIcon,
  TableSaveIcon,
  TrashIcon,
} from "../common/Icons";
import DeletePopup from "../DeletePopup/DeletePopup";
import CustomDropdownWithAddButton from "../EmployeeTable/CustomDropdownWithAddButton";
import SharePopup from "../SharePopup/SharePopup";
import EditableCell from "../Tables/EditableCell";
import LayoutAdjustableTable from "../Tables/LayoutAdjustableTable";
import TablePaginator from "../Tables/TablePaginator";
import styles from "./LeaveApplicationTable.module.scss";
import SuccessPopup from "../SuccessPopup/SuccessPopup";
import ErrorPopup from "../ErrorPopup/ErrorPopup";
import { toast, ToastContainer } from "react-toastify";
import { CircularProgress, Tooltip } from "@mui/material";
import { makeStyles } from '@mui/styles';
import { getAuthUser } from "../../app/services/dashboard";
import { setNotification, setProfileNotification } from "../../reducers/dashboard";
import { useDispatch, useSelector } from "react-redux";
const useStyles = makeStyles(() => ({
  tooltip: {
    backgroundColor: "transparent",
    margin: 0,
    marginRight: 8,
    padding: 0,
  },
}));

interface IEmployeeTable {
  isLayoutControlOpen: boolean;
  setIsLayoutControlOpen: Dispatch<SetStateAction<boolean>>;
  pagination: PaginationState;
  setPagination: Dispatch<SetStateAction<PaginationState>>;
  globalSearch: string;
  setGlobalSearch: Dispatch<SetStateAction<string>>;
  // setStatusData: React.Dispatch<React.SetStateAction<IKeyValue[]>>;
  setStatusData: React.Dispatch<React.SetStateAction<string[]>>;
  // columnFilters: ColumnFiltersState;
  // setColumnFilters: Dispatch<SetStateAction<ColumnFiltersState>>;
  statusData: any;
  // orgDataList: any;
  data: baseObj[];
  setData: Dispatch<SetStateAction<baseObj[]>>;
  leaveType: any;
  setLeaveType: Dispatch<SetStateAction<any>>;
  leaveFullHalfData: any;
  setLeaveFullHalfData: Dispatch<SetStateAction<any>>;
  hiddenFields: string[];
  templateList: TemplateData[];
  setHiddenFields: any;
  setCurrentView: any;
  changeRejectedLeaveStatus: any;
  changeApproveLeaveStatus: any;
  localModuleId: number;
  changePage?: any;
  total: number;
  setCurrentPage: any;
  currentPage: number;
  editLeave?: any;
  setCols?: any;
  setIsTemplate?: Dispatch<SetStateAction<boolean>>;
  checkManage: boolean;
  isEditing?: boolean;
  setIsEdit: Dispatch<SetStateAction<boolean>>;
  checkManageLeave: boolean;
  setShowLeaveFile: Dispatch<SetStateAction<boolean>>;
  setLeaveDocuments: Dispatch<React.SetStateAction<Tdocuments[]>>;
  sorting: SortingState;
  setSorting: React.Dispatch<React.SetStateAction<SortingState>>;
}

interface DropdownFieldData {
  key: string;
  value: string;
}
interface IStatusChangedData {
  id: number;
  dataID: number;
}
const LeaveApplicationTable: FC<IEmployeeTable> = ({
  isLayoutControlOpen,
  setIsLayoutControlOpen,
  pagination,
  setPagination,
  globalSearch,
  setGlobalSearch,
  // columnFilters,
  // setColumnFilters,
  statusData,
  setStatusData,
  data,
  setData,
  leaveType,
  setLeaveType,
  leaveFullHalfData,
  setLeaveFullHalfData,
  // orgDataList,
  hiddenFields,
  templateList,
  setHiddenFields,
  setCurrentView,
  changeRejectedLeaveStatus,
  changeApproveLeaveStatus,
  localModuleId,
  changePage,
  total,
  setCurrentPage,
  currentPage,
  editLeave,
  setCols,
  setIsTemplate,
  checkManage,
  checkManageLeave,
  isEditing,
  setIsEdit,
  setShowLeaveFile,
  setLeaveDocuments,
  sorting,
  setSorting
}) => {
  const columnHelper = useMemo(() => createColumnHelper<any>(), []);

  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  // const [itemCount, setItemCount] = useState<number>(data.length);
  const [itemCount, setItemCount] = useState<number>(total);

  const [successPopup, setSuccessPopup] = useState<boolean>(false);
  const [errorPopup, setErrorPopup] = useState<boolean>(false);
  const [message, setMessage] = useState<any>([]);

  const selectRowsCount = Object.keys(rowSelection).length;
  const [openSharePopup, setOpenSharePopup] = useState<boolean>(false);
  const [openDeletePopup, setOpenDeleteopup] = useState<boolean>(false);
  const [openRejectedPopup, setOpenRejectedopup] = useState<boolean>(false);
  const [deleteID, setDeleteID] = useState<number | 0>();
  const [selectedLeaveType, setSelectedLeaveType] = useState("All");
  const [selectedFullHalf, setSelectedFullHalf] = useState("All");
  const [statusChangedID, setStatusChangedID] = useState<IStatusChangedData>({
    dataID: 0,
    id: -1,
  });
  const dispatch = useDispatch();
  const stateData: any = useSelector((state: any) => state?.dashboard?.notificationDetail);
  const [notifications, setNotifications] = useState<any>(stateData);
  const classes = useStyles();
  const encodeAlphabet = (alphabet: string) => {
    if (alphabet) {
      return alphabet.toString().replace(/[a-zA-Z0-9]/g, "*");
    } else {
      return "";
    }
  };
  const [approving, setApproving] = useState<boolean>(false);
  const [rejecting, setRejecting] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);
  const saveLeaveData = useCallback(
    async (data: any) => {
      var updated = {
        id: data.id,
        from_date: data.from,
        to_date: data.to,
        full_half_day: data.fullHalfDay,
        total_days: data.total,
        leave_type_id: data.leave_type_id,
        reason: data.reason,
        remark: data.remark,
        // isNew: false,
      };
      let formSaveData = new FormData();
      formSaveData.append("from_date", data.from.toString());
      formSaveData.append("to_date", data.to.toString());
      formSaveData.append("full_half_day", data.fullHalfDay.toString());
      formSaveData.append("leave_type_id", data.leave_type_id,);
      formSaveData.append("reason", data.reason ? data.reason.toString() : '');
      formSaveData.append("remark", data.remark.toString());
      formSaveData.append("total_days", data.total);
      formSaveData.append("id", data.id);
      formSaveData.append("_method", 'PUT');
      await axios(endpointUrl + "leaves/" + data.id, {
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${getUser.token}`,
          Accept: "application/json",
        },
        data: formSaveData,
      })
        .then((res: any) => {
          // console.log("res ", res)
          setSaving(false);
          setData((prev: baseObj[]) => {
            return prev.map((data, index: number) => {
              if (updated.id == data.id) {
                return {
                  ...data,
                  isNew: false,
                };
              }
              return data;
            });
          });
          setOpenSharePopup(false);
          setSuccessPopup(true);
          setMessage([res?.message]);
          toast("Successfully Updated.", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            type: "success",
          });
        })
        .catch((error: any) => {
          setSaving(false);
          setErrorPopup(true);
          setMessage([error?.response?.data?.message]);
        });
      // editLeave(updated)
      //   .unwrap()
      //   .then((payload: any) => {
      //     if (payload.status) {
      //       setSaving(false);
      //       setData((prev: baseObj[]) => {
      //         return prev.map((data, index: number) => {
      //           if (updated.id == data.id) {
      //             return {
      //               ...data,
      //               isNew: false,
      //             };
      //           }
      //           return data;
      //         });
      //       });
      //       setOpenSharePopup(false);
      //       setSuccessPopup(true);
      //       setMessage([payload?.message]);
      //       toast("Successfully Updated.", {
      //         position: "top-right",
      //         autoClose: 2000,
      //         hideProgressBar: false,
      //         closeOnClick: true,
      //         pauseOnHover: true,
      //         draggable: true,
      //         progress: undefined,
      //         theme: "light",
      //         type: "success",
      //       });
      //     }
      //   })
      //   .catch((error: any) => {
      //     setErrorPopup(true);
      //     setMessage(["Something went wrong!"]);
      //   });
    },
    [setData]
  );

  const editLeaveData = (id: number) => {
    setData((prev: baseObj[]) => {
      return prev.map((data, index: number) => {
        if (index == id) {
          return { ...data, isNew: true };
        }
        return data;
      });
    });
  };

  const editEmployee = useCallback(
    (id: number) =>
      setData((prev: baseObj[]) => {
        return prev.map((data, index: number) => {
          if (index == id) {
            return { ...data, isNew: true };
          }
          return data;
        });
      }),
    [setData]
  );
  const approveRequest = useCallback(
    (id: number, realID: number) => {
      setStatusChangedID({
        id: id,
        dataID: realID,
      });
      setOpenDeleteopup(true);
    },
    [setData]
  );

  const rejectRequest = useCallback(
    (id: number, realID: number) => {
      setStatusChangedID({
        id: id,
        dataID: realID,
      });
      setOpenRejectedopup(true);
    },
    [setData]
  );

  var getUser = getAuthUser();
  const changedStatus = (datas: IStatusChangedData, status: string) => {
    var editedData: any = data.find(
      (x) => Number(x.id) === Number(datas.dataID)
    );

    if (status == "Approved") {
      changeApproveLeaveStatus({
        id: datas.dataID,
        status: status,
      })
        .unwrap()
        .then((payload: any) => {
          if (payload.status) {
            var notiList = notifications.notifications;            
            if (payload.decrease_unapproved_count == true) {
              var data = {
                profile_unread_noti_count: notifications.profile_unread_noti_count,
                unapprove_claim_count: notifications.unapprove_claim_count,
                unapprove_leave_count: notifications.unapprove_leave_count - 1,
                unapprove_ot_count: notifications?.unapprove_ot_count,
                unread_count: notifications.unread_count,
                unread_data_count: {
                  unread_document_count: notifications.unread_data_count?.unread_document_count,
                  unread_leave_count: notifications.unread_data_count?.unread_leave_count,
                  unread_password_count: notifications.unread_data_count?.unread_password_count,
                  unread_claim_count: notifications?.unread_data_count?.unread_claim_count
                },
                notifications: notiList
              }
              setNotifications(data)
              dispatch(setProfileNotification(data))
            }
            setApproving(false);
            setOpenDeleteopup(false);
            setErrorPopup(false)
            //setSuccessPopup(true)
            //setMessage([payload?.message]);
            toast('Successfully status changed.', {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              type: "success",
            });
            setData((prev: baseObj[]) => {
              return prev.map((data: baseObj, index: number) => {
                if (data.id === datas.dataID) {
                  return { ...data, approveOption: payload.data.approveOption, status: payload.data.status };
                }
                return data;
              });
            });
            // getLastedNoti();
          }else{
            setApproving(false);
            setOpenDeleteopup(false);
            setErrorPopup(true);
            setMessage(payload ? [payload?.data?.message] : ["Something went wrong!"])
          }
        })
        .catch((error: any) => {
          console.error("e", error)
          setApproving(false);
          setOpenDeleteopup(false);
          setErrorPopup(true)
          setMessage(error ? [error?.data?.message] : ["Something went wrong!"])
        })
    }
    else if (status == "Rejected") {
      changeRejectedLeaveStatus({
        id: datas.dataID,
        status: status,
        remark: editedData?.remark,
      })
        .then((payload: any) => {
          if (payload.data.status) {
            setRejecting(false);
            // getLastedNoti();
            setOpenRejectedopup(false)
            setSuccessPopup(true)
            setRejecting(false);
            setMessage([payload?.data?.message]);
            toast("Successfully status changed.", {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              type: "success",
            });
            setData((prev: baseObj[]) => {
              return prev.map((data: baseObj, index: number) => {
                if (data.id === datas.dataID) {
                  // console.log("payload.data.status ", payload.data.data)
                  return { ...data, rejectOption: payload.data.data.rejectOption, status: payload.data.data.status };
                }
                return data;
              });
            });
          }
        })
        .catch((error: any) => {
          setErrorPopup(true);
          setRejecting(false);
          setOpenRejectedopup(false)
          setMessage(error ? [error?.data?.message] : ["Something went wrong!"])
        })
    }
  }
  const getLastedNoti = async () => {
    await axios(endpointUrl + "latest-notifications", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getUser.token}`,
        Accept: "application/json",
      },
    }).then((res) => {
      var notificationList = res.data;
      dispatch(setProfileNotification(notificationList?.data.unread_data_count))
    })
  }
  const cancelRow = (id: number) => {
    if (isEditing) {
      setIsEdit(false);
      setData((prev: baseObj[]) => {
        return prev.map((data, index: number) => {
          if (data.id == id) {
            return { ...data, isNew: false };
          }
          return data;
        });
      });
    } else {
      const filterData = data?.filter((d: any) => d?.id !== id);
      setData(filterData);
    }
    localStorage.setItem('selectedObj', '')
  }
  const showWithTooltip = (fieldValue: any) => {
    return <Tooltip
      arrow
      classes={{ tooltip: classes.tooltip }}
      enterTouchDelay={0}
      leaveTouchDelay={5000}
      placement="left"
      title={
        <>
          <div className="text-white text-[10px] hover:cursor-pointer leading-[15px] z-[2] rounded p-4 w-full bg-tooltipbg">
            <span className="block">{fieldValue}</span>
          </div>
        </>
      }
    >
      <span className="font-semibold cursor-pointer">+</span>
    </Tooltip>
  }
  const columns = useMemo(
    () => [
      columnHelper.accessor("appliedAt", {
        header: "Applied At",
        id: "appliedAt",
        cell: ({ getValue, cell }) => {
          const applitedAt = getValue();
          return (
            <div className="bg-white text-black2 px-2">
              <p className="h-full flex items-center">{applitedAt}</p>
            </div>
          );
        },
      }),
      columnHelper.accessor("employeeName", {
        header: "Employee Name",
        id: "employeeName",
        cell: ({ getValue, cell }) => {
          const employeeName = getValue();
          return (
            <div className="bg-white text-black2 px-2">
              <p className="h-full flex items-center">{employeeName}</p>
            </div>
          );
        },
      }),
      // columnHelper.accessor("leaveType", {
      //   header: "Leave Type",
      //   id: "leaveType",
      //   cell: ({ getValue, cell }) => {
      //     const leaveTypeVal = getValue();
      //     const originalValues = cell.row.original?.role?.map(
      //       (role: baseObj) => role.id
      //     );

      //     return (
      //       <div className="min-w-full max-w-full text-left">
      //         <EditableCell
      //           hasPadding={true}
      //           setData={setData}
      //           value={leaveTypeVal}
      //           type="custom"
      //           cell={cell}
      //           CustomComponent={
      //             <CustomDropdownWithAddButton
      //               isLeave={true}
      //               initialValues={0}
      //               dataList={leaveType ?? []}
      //               setDataList={setLeaveType}
      //               title="Leave Type"
      //               cell={cell}
      //               data={data}
      //               setData={setData}
      //               setSelectedData={setSelectedLeaveType}
      //               selectedData={leaveTypeVal}
      //             />
      //           }
      //         />
      //       </div>
      //     );
      //   },
      // }),

      columnHelper.accessor("leave_type_id", {
        header: "LeaveType",
        id: "leave_type_id",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const leaveTypeVal = getValue();
          var view = data;
          var data = leaveType?.find((x: any) => x.id == leaveTypeVal);
          if (data == undefined) {
            view = leaveType?.find((x: any) => x.name == leaveTypeVal);
          } else {
            view = data;
          }
          const originalValues = cell.row.original?.role?.map(
            (role: baseObj) => role.id
          );
          const isNew: boolean = cell.row.original?.isNew;
          return (
            <div className="min-w-full max-w-full text-left">
              <EditableCell
                hasPadding={true}
                setData={setData}
                value={view ? view.name : ""}
                type="custom"
                cell={cell}
                CustomComponent={
                  <CustomDropdownWithAddButton
                    // isLeave={true}
                    initialValues={0}
                    dataList={leaveType ?? []}
                    setDataList={setLeaveType}
                    title="Leave Type"
                    cell={cell}
                    data={data}
                    setData={setData}
                    setSelectedData={setSelectedLeaveType}
                    selectedData={view ? view.name : ""}
                    isEditable={isNew}
                  />
                }
              />
            </div>
          );
        },
      }),
      columnHelper.accessor("from", {
        header: "Date(From)",
        id: "from",
        cell: ({ getValue, cell }) => {
          const from = getValue();
          return (
            <div className="bg-white text-black2 px-2">
              <p className="h-full flex items-center">{from}</p>
            </div>
          );
        },
      }),
      columnHelper.accessor("to", {
        header: "Date(To)",
        id: "to",
        cell: ({ getValue, cell }) => {
          const to = getValue();
          return (
            <div className="bg-white text-black2 px-2">
              <p className="h-full flex items-center">{to}</p>
            </div>
          );
        },
      }),
      columnHelper.accessor("fullHalfDay", {
        header: "Full/Half Day",
        id: "fullHalfDay",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const fullhalfData = getValue();

          const originalValues = cell.row.original?.role?.map(
            (role: baseObj) => role.id
          );
          const isNew: boolean = cell.row.original?.isNew;
          return (
            <div className="min-w-full max-w-full text-left">
              <EditableCell
                hasPadding={true}
                setData={setData}
                value={fullhalfData}
                type="custom"
                cell={cell}
                CustomComponent={
                  <CustomDropdownWithAddButton
                    isText={true}
                    initialValues={originalValues}
                    dataList={leaveFullHalfData}
                    setDataList={setLeaveFullHalfData}
                    title="Full/Half Day"
                    cell={cell}
                    data={data}
                    setData={setData}
                    setSelectedData={setSelectedFullHalf}
                    selectedData={fullhalfData ?? ""}
                    isEditable={isNew}
                  />
                }
              />
            </div>
          );
        },
      }),
      columnHelper.accessor("total", {
        header: "Totals",
        id: "total",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const total = getValue();
          return (
            <div className="min-w-[110px] bg-white text-black2 px-2 text-left">
              <p className="h-full flex items-center">{total}</p>
            </div>
          );
        },
      }),
      columnHelper.accessor("reason", {
        header: "Reason",
        id: "reason",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const reason = getValue();
          return (
            <div className="min-w-[110px] text-left bg-white px-2 flex items-center">
              <p className="font-poppins h-full flex items-center font-normal">{reason}</p>
              {/* {reason?.toString().length > 5 ? showWithTooltip(reason) : ''} */}
            </div>
          );
        },
      }),
      columnHelper.accessor("status", {
        header: "Status",
        id: "status",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const status = getValue();
          var txtColor = "text-graydark";
          if (status == "Rejected") {
            txtColor = "text-redPink";
          } else if (status == "Approved") {
            txtColor = "text-vorpgreen";
          }
          return (
            <span
              className={classNames(
                txtColor,
                "text-center justify-center h-full bg-white px-[10px] flex items-center"
              )}
            >
              {status}
            </span>
          );
        },
      }),
      columnHelper.accessor("remark", {
        header: "Remark",
        id: "remark",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const remark = getValue();
          return (
            <div className="max-w-[132px] min-w-[110px] text-left">
              <EditableCell
                setData={setData}
                value={remark}
                type="text"
                cell={cell}
                customDesign="tooltip"
              />
            </div>
          );
        },
      }),

      columnHelper.accessor("documents", {
        header: "Supportive Documents",
        id: "documents",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const docs = getValue();
          var txtColor = "text-graydark";
          if (docs.length) {
            txtColor = "text-blue-400";
          }
          return (
            <span
              onClick={() => {
                setLeaveDocuments(docs);
                setShowLeaveFile(true);
              }}
              className={classNames(
                txtColor,
                docs.length ? "" : " pointer-events-none",
                "text-center justify-center h-full bg-white px-[10px] flex items-center cursor-pointer"
              )}
            >
              {docs.length ? "Attached Files" : "---"}
            </span>
          );
        },
      }),
      columnHelper.display({
        header: "Action",
        id: `${checkManage ? "actions" : "none-actions"}`,
        cell: ({ cell }) => {
          const isNew: boolean = cell.row.original?.isNew;
          const editOption: any = cell?.row?.original?.editOption;
          const approveOption: any = cell?.row?.original?.approveOption;
          const rejectOption: any = cell?.row?.original?.rejectOption;

          // const isPending: boolean = cell.row.original?.status == "Pending" ? true : false;
          // console.log("isNew", cell.row.original.isNew)
          // const isApprovedSPV: boolean = cell.row.original?.status == "Approved(SPVS)" ? true : false;
          // const isApprovedHR: boolean = cell.row.original?.status == "Approved(HR)" ? true : false;
          // console.log("checkManageLeave || checkManage || editOption ",checkManageLeave || checkManage || editOption,checkManage,isNew)
          return (
            <div
              className={classNames(
                styles.actionedBtn,
                "flex justify-center items-center max-w-full mx-[4px]"
              )}
            >
              {!approveOption && !rejectOption && !editOption ? (
                <span>---</span>
              ) : null}

              {checkManage && approveOption && !isNew ? (
                <button
                  type="button"
                  onClick={() => {
                    approveRequest(
                      Number(cell?.row?.id),
                      Number(cell?.row?.original?.id)
                    );
                  }}
                  className={classNames(
                    styles.customSharedBtn,
                    "w-8 h-8 max-w-[24px] mr-2 flex justify-center items-center group rounded-md bg-vorpphillipines hover:bg-turqouiseLight transition-all duration-300"
                  )}
                >
                  <CheckIcon width={16} height={16} />
                </button>
              ) : null}

              {checkManage && rejectOption && !isNew ? (
                <button
                  type="button"
                  onClick={() =>
                    rejectRequest(
                      Number(cell.row.id),
                      Number(cell.row.original.id)
                    )
                  }
                  className={classNames(
                    styles.customCloseIcon,
                    "w-8 h-8 max-w-[24px] mr-2 flex justify-center items-center group rounded-md bg-redPink hover:bg-[#FFEAEF] transition-all duration-300"
                  )}
                >
                  <CloseSmallIcon width={16} height={16} />
                </button>
              ) : null}

              {checkManage && editOption && isNew ? (
                <div className="flex">
                  <button
                    type="button"
                    onClick={() => {
                      setSaving(true);
                      saveLeaveData(cell?.row?.original);
                    }}
                    className={classNames(saving == true ? 'opacity-50 pointer-events-none' : '', "w-8 h-8 flex justify-center items-center bg-vorpgreen rounded-md mr-2")}
                  >
                    <TableSaveIcon width={16} height={16} />{saving}
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      cancelRow(Number(cell?.row?.original?.id));
                    }}
                    className={classNames(
                      styles.customCloseIcon,
                      "w-8 h-8 mr-2 flex justify-center items-center group rounded-md  bg-[#71717a] hover:bg-[#71717a] transition-all duration-300"
                    )}
                  >
                    <CancelIcon width={16} height={16} />
                  </button>
                </div>
              ) : editOption ? (
                <button
                  type="button"
                  onClick={() => {
                    if (setIsEdit) {
                      setIsEdit(true);
                    }
                    editLeaveData(Number(cell.row.id));
                  }}
                  className="w-8 h-8 flex mr-2 max-w-[24px] justify-center items-center group rounded-md bg-vorpblue hover:bg-annhoverbg transition-all duration-300"
                >
                  <EditIcon width={16} height={16} />
                </button>
              ) : null}

              {/* {
                isApprovedSPV ? (
                  isNew ? (
                    <button
                      type="button"
                      onClick={() => saveLeaveData(cell?.row?.original)}
                      className="w-8 h-8 flex justify-center items-center bg-vorpgreen rounded-md"
                    >
                      <TableSaveIcon />
                    </button>
                  ) : (
                    <button
                      type="button"
                      onClick={() => editLeaveData(Number(cell.row.id))}
                      className="w-8 h-8 flex justify-center items-center group rounded-md bg-vorpblue hover:bg-annhoverbg transition-all duration-300"
                    >
                      <EditIcon />
                    </button>
                  )
                ) : null

              }

              {
                isApprovedHR ? (
                  isNew ? (
                    <button
                      type="button"
                      onClick={() => saveLeaveData(cell?.row?.original)}
                      className="w-8 h-8 flex justify-center items-center bg-vorpgreen rounded-md"
                    >
                      <TableSaveIcon />
                    </button>
                  ) : (
                    <button
                      type="button"
                      onClick={() => editLeaveData(Number(cell.row.id))}
                      className="w-8 h-8 flex justify-center items-center group rounded-md bg-vorpblue hover:bg-annhoverbg transition-all duration-300"
                    >
                      <EditIcon />
                    </button>
                  )
                ) : null
              }

              {
                isPending ?
                  isNew ? (
                    <button
                      type="button"
                      onClick={() => saveLeaveData(cell?.row?.original)}
                      className="w-8 h-8 flex justify-center items-center bg-vorpgreen rounded-md"
                    >
                      <TableSaveIcon />
                    </button>
                  ) : (
                    <>
                      <button
                        type="button"
                        onClick={() => {
                          approveRequest(Number(cell?.row?.id), Number(cell?.row?.original?.id))
                        }}
                        className={classNames(
                          styles.customSharedBtn,
                          "w-8 h-8 mr-2 flex justify-center items-center group rounded-md bg-vorpphillipines hover:bg-turqouiseLight transition-all duration-300"
                        )}
                      >
                        <CheckIcon />
                      </button>
                      <button
                        type="button"
                        onClick={() => rejectRequest(Number(cell.row.id), Number(cell.row.original.id))}
                        className={classNames(styles.customCloseIcon, "w-8 h-8 mr-2 flex justify-center items-center group rounded-md bg-redPink hover:bg-[#FFEAEF] transition-all duration-300")}
                      >
                        <CloseSmallIcon width={16} height={16} />
                      </button>
                      <button
                        type="button"
                        onClick={() => editLeaveData(Number(cell.row.id))}
                        className="w-8 h-8 flex justify-center items-center group rounded-md bg-vorpblue hover:bg-annhoverbg transition-all duration-300"
                      >
                        <EditIcon />
                      </button>

                    </>
                  ) : ''
              } */}
            </div>
          );
        },
      }),
    ],
    [columnHelper, data, hiddenFields, saving]
  );

  const [columnOrder, setColumnOrder] = useState<string[]>(
    columns.map((column: ColumnDef<any, any>) => column.id as string)
  );

  const visibleColumns: { [key: string]: boolean } = {};

  hiddenFields?.map((x) => {
    visibleColumns[x] = true;
  });

  if (hiddenFields.length > 0) {
    columns.map((x: any) => {
      if (x.id == "actions" || x.id == "lasted" || x.id == "selection") {
        visibleColumns[x.id ? x.id : ""] = true;
      } else if (x.id == "uniqeID") {
        visibleColumns[x.id ? x.id : ""] = false;
      } else if (x.id?.includes("none")) {
        visibleColumns[x.id ? x.id : ""] = true;
      } else {
        var isHidden = hiddenFields.find((h) => h == x.id);
        if (isHidden) {
          visibleColumns[x.id ? x.id : ""] = true;
        } else {
          visibleColumns[x.id ? x.id : ""] = false;
        }
      }
    });
  }

  useEffect(() => {
    if (hiddenFields.length > 0) {
      setColumnOrder(["selection", ...hiddenFields]);
    }
  }, [hiddenFields]);

  useEffect(() => {
    setTimeout(() => {
      setSuccessPopup(false);
    }, 1000);
  }, [successPopup]);

  // console.log("hidden ",data)

  return (
    <div
      className={classNames(
        styles.leaveTable,
        "relative inline-block max-w-full w-full"
      )}
    >
      {/* <SuccessPopup
        descText="Done Successfully!"
        confirmBtnText="Yes, Close."
        show={successPopup}
        setShow={setSuccessPopup}
        message={message}
        setMessage={setMessage}
      /> */}
      <ToastContainer className={"text-12 font-poppins font-normal"} />
      <ErrorPopup
        descText="Error Message!"
        confirmBtnText="Yes, Close."
        show={errorPopup}
        setShow={setErrorPopup}
        message={message}
        setMessage={setMessage}
      />

      <DeletePopup
        descText="Are you sure to Approve?"
        confirmBtnText="Yes"
        confirmBtnColor="bg-vorpgreen"
        show={openDeletePopup}
        setShow={(val) => { setOpenDeleteopup(val); setApproving(false); }}
        confirmDeleteData={() => { setApproving(true); changedStatus(statusChangedID, "Approved") }}
        loading={approving}
        loadingText="Updating"
      />
      <DeletePopup
        descText="Are you sure to Rejected?"
        confirmBtnText="Yes"
        show={openRejectedPopup}
        setShow={(val) => { setOpenRejectedopup(val); setRejecting(false) }}
        confirmDeleteData={() => { setRejecting(true); changedStatus(statusChangedID, "Rejected") }}
        loading={rejecting}
        loadingText="Updating"
      />

      <SharePopup show={openSharePopup} setShow={setOpenSharePopup} />
      <LayoutAdjustableTable
        hideColumn={visibleColumns}
        columns={columns}
        data={data}
        sorting={sorting}
        setSortingState={setSorting}
        rowSelection={rowSelection}
        setRowSelection={setRowSelection}
        columnOrder={columnOrder}
        setColumnOrder={setColumnOrder}
        unDraggableFields={["actions", "selection"]}
        theaderColor="text-graydark"
        isLayoutControlOpen={isLayoutControlOpen}
        setIsLayoutControlOpen={setIsLayoutControlOpen}
        label="Leave List"
        setItemCount={setItemCount}
        pagination={pagination}
        setPagination={setPagination}
        globalFilter={globalSearch}
        setGlobalFilter={setGlobalSearch}
        // columnFilters={columnFilters}
        // setColumnFilters={setColumnFilters}
        noPaddingCells={["leave_type_id", "fullHalfDay", "status"]}
        localModuleId={localModuleId}
        setCols={setCols}
        setIsTemplate={setIsTemplate}
      />
      {selectRowsCount > 0 && (
        <div className="flex items-center text-14 leading-5 font-semibold text-black2 mt-8 mb-10 lg:mt-10 lg:mb-12 ">
          <p>{selectRowsCount} selected.</p>

          <button
            type="button"
            onClick={() => setOpenDeleteopup(true)}
            className=" flex items-center ml-5 text-12 leading-[18px] font-semibold text-redPink"
          >
            <div className="w-6 h-6 bg-redPink flex items-center group justify-center rounded-md mr-[10px] hover:bg-[#FFEAEF]  transition-all duration-300 ">
              <TrashIcon width={10} height={12} />
            </div>
            Delete
          </button>
        </div>
      )}
      <div className="pt-6">
        <TablePaginator
          total={itemCount}
          pagination={pagination}
          setPagination={setPagination}
          itemCount={total}
          setCurrentPage={setCurrentPage}
          currentPageDefault={currentPage}
          changePage={changePage}
        />
      </div>
    </div>
  );
};
export default LeaveApplicationTable;
