import { FC } from "react";
import BasicLayout from "../layouts/BasicLayout";
import { Outlet } from "react-router-dom";
import WorkingShift from "./view/WorkingShift";

interface IAnnouncementsRoute {
  loginData: any;
  permissions: any;
}

const WorkingShiftRoute: FC<IAnnouncementsRoute> = ({
  loginData,
  permissions,
}) => {
  return (
    <BasicLayout userData={loginData} permissions={permissions}>
      <div className="pt-0 pb-[58px]">
        <WorkingShift />
        <Outlet />
      </div>
    </BasicLayout>
  );
};

export default WorkingShiftRoute;
