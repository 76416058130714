import { redirectToLogin } from "./annoucement-sheet";
import { api } from "./api";
import authHeader from "./auth-header";
import { Ids } from "./media";

type ProjectTechResponse = {
  status: boolean;
  data: TData;
};

export type TData = {
  data: TechInfo[];
  total: number;
};
export type TechInfo = {
  id?: number;
  type_name?: string;
  name?: string;
  priority: number;
};

type projectTechInput = {
  sort?: string;
  search: string;
  page: number;
  limit: number;
};

export type TaskDepartmentList = {
  departmentList: TaskDepartmentListData[];
};

export type responseType = {
  status: boolean;
  message: string;
};

export type TaskDepartmentListData = {
  id: number;
  name: string;
};

export const projectTechApi = api.injectEndpoints({
  endpoints: (build) => ({
    getProjectTechList: build.query<ProjectTechResponse, projectTechInput>({
      query: ({ search, limit, page }) => ({
        url: `projectTypes?&search=${search}&limit=${limit}&page=${page}`,
        headers: authHeader(),
      }),
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
      providesTags: () => [{ type: "ProjectTech", id: "LIST" }],
    }),
    createProjectTech: build.mutation<TaskDepartmentList, TechInfo>({
      query(body) {
        return {
          headers: authHeader(),
          url: `/projectTypes`,
          body,
          method: "POST",
        };
      },
      invalidatesTags: () => [{ type: "ProjectTech", id: "LIST" }],
    }),
    updateProjectTech: build.mutation<TaskDepartmentList, TechInfo>({
      query(body) {
        return {
          headers: authHeader(),
          url: `/projectTypes/${body.id}`,
          body,
          method: "PUT",
        };
      },
      invalidatesTags: () => [{ type: "ProjectTech", id: "LIST" }],
    }),
    deleteProjectTech: build.mutation<responseType, number>({
      query(id) {
        return {
          headers: authHeader(),
          url: `/projectTypes/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: () => {
        return [{ type: "ProjectTech", id: "LIST" }];
      },
    }),
  }),
});

export const {
  useGetProjectTechListQuery,
  useCreateProjectTechMutation,
  useUpdateProjectTechMutation,
  useDeleteProjectTechMutation,
} = projectTechApi;

export const {
  endpoints: {
    getProjectTechList,
    createProjectTech,
    updateProjectTech,
    deleteProjectTech,
  },
} = projectTechApi;
