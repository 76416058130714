import { StringDropdownList } from "../common/DropdownList";
import React, { createRef, useEffect, useState } from "react";
import trashIcon from "./img/trash-fill.svg";
import { CustomLeaveTypeDropdown } from "./CustomLeaveTypeDropdown";
import { LeaveTypeData } from "../../app/services/dashboard";
import DatePicker from "react-datepicker";
import calendarIcon from "./img/bi_calendar-week.svg";
import classNames from "classnames";
import { renderDateFormat, renderDateFormatDMY } from "./DashboardAddEventPopup";
const CommonLeaveType = [
  {
    id: 1,
    name: 'Leave',
    indicator: "L"
  },
  {
    id: 2,
    name: 'Holiday',
    indicator: "H"
  },
  {
    id: 3,
    name: 'Project',
    indicator: "P"
  }
]

const DashboardEventContainer = (props: any) => {
  var leaveTypeData = props.leaveType.find((x: any) => x.uniqeID == props.selectedLeave);
  const [selectedLT, setSelectedLT] = useState<any>();
  const changeType = (dataT: any) => {
    props.changeLeaveType(dataT)
    setSelectedLT(dataT);
  }
  // useEffect(()=>{

  // },[props])
  return (
    <div>
      <div className="mt-2 mb-2">
        <p className="mt-2 text-left font-poppins font-medium text-15 text-black2">
          Event Type
        </p>
        <div className="flex sm:flex-nowrap flex-wrap justify-between">
          <div className="sm:w-[48%] w-full">
            <div className={classNames("w-full sm:min-w-[239px]", " bg-offwhite rounded-md sm:mr-2 my-1")}>
              <CustomLeaveTypeDropdown
                parendID={props.id}
                dropdownborder="border-none"
                state={props.selectedLeave}
                setState={changeType}
                dropdownItems={CommonLeaveType?.map((leave: any) => ({
                  value: leave.indicator,
                  label: leave.name + "(" + leave.indicator + ")",
                  indicator: leave.indicator,
                }))}
                className="font-poppins w-full min-w-[152px] max-w-full xs:min-w-[152px] sm:min-w-[152px] md:min-w-[152px] 6xs:text-12  py-2 h-auto text-12"
                placeholder="Please Select"
                textAlign="text-left py-2 pl-2 pr-3"
              />

            </div>
            <p className={classNames(!selectedLT ? '' : 'hidden', "mt-2 font-poppins text-12 text-redPink errorMsg")}>
              Event type field is requried.
            </p>
          </div>
          <div className={selectedLT?.indicator !== "L" ? "hidden" : "sm:w-[48%] full"}>
            <div className={classNames(classNames(selectedLT?.indicator != "L" ? "hidden" : "", " sm:mr-2"), "w-full sm:min-w-[239px] bg-offwhite rounded-md my-1")}>
              <CustomLeaveTypeDropdown
                parendID={props.id}
                dropdownborder="border-none"
                state={props.leaveType}
                setState={props.changeLeaveTypeData}
                dropdownItems={LeaveTypeData.map((leave) => ({
                  value: leave.name,
                  label: leave.name,
                }))}
                className="font-poppins w-full min-w-[152px] max-w-full xs:min-w-[152px] sm:min-w-[152px] md:min-w-[152px] 6xs:text-12  py-2 h-auto text-12"
                placeholder="---"
                textAlign="text-left py-2 pl-2 pr-3"
              />

            </div>
            <p className={classNames((props.placeholder == "Leave(L)" || props.placeholder?.endsWith('L')) ? (!props.leaveData ? '' : 'hidden') : 'hidden', "mt-2 font-poppins text-12 text-redPink errorMsg")}>
              Leave type field is requried.
            </p>
          </div>
        </div>
        <div className={classNames(selectedLT ? '' : 'hidden', "mt-2")}>
          <p className="text-left font-poppins font-medium text-15 text-black2">
            Date
          </p>
          <div className="flex sm:flex-nowrap flex-wrap justify-between">
            <div className="sm:w-1/2 w-full sm:min-w-[239px] min-w-max bg-offwhite rounded-md my-1 sm:mr-2 mr-0">
              <DatePicker
                className="w-[100%]"
                selected={props.startDate}
                onChange={(prev: Date) => { props.setStartDate(prev); props.changeStartDate(prev, props.id) }}
                dateFormat={"dd-MM-yyyy"}
                showMonthDropdown
                showYearDropdown
                //minDate={new Date()}
                customInput={
                  <button className="border rounded-md border-offwhite flex justify-between items-center w-full text-[13px] sm:text-14 leading-5 text-graydark">
                    <span
                      className={classNames(
                        "font-poppins text-graydark text-12 font-normal w-full h-full px-[14px] py-2 text-left"
                      )}
                    >
                      {renderDateFormatDMY(props.startDate, "-")}
                    </span>
                    <div className={classNames("px-[14px] py-2")}>
                      <img src={calendarIcon} alt="calendarIcon" />
                    </div>
                  </button>
                }
              />
            </div>
            <div className={classNames(selectedLT?.indicator == "P" ? 'hidden' : '', "sm:w-1/2 w-full sm:min-w-[239px] min-w-max bg-offwhite rounded-md my-1")}>
              <DatePicker
                className="w-full"
                selected={props.startDate > props.endDate ? props.startDate : props.endDate}
                // minDate={props.startDate}
                onChange={(prev: Date) => { props.setEndDate(prev); props.changeEndDate(prev, props.id) }}
                dateFormat={"dd-MM-yyyy"}
                showMonthDropdown
                showYearDropdown
                customInput={
                  <button className="border rounded-md border-offwhite flex justify-between items-center w-full text-[13px] sm:text-14 leading-5 text-graydark">
                    <span
                      className={classNames(
                        "font-poppins text-graydark text-12 font-normal w-full h-full px-[14px] py-2 text-left"
                      )}
                    >
                      {renderDateFormatDMY(props.endDate, "-")}
                    </span>
                    <div className={classNames("px-[14px] py-2")}>
                      <img src={calendarIcon} alt="calendarIcon" />
                    </div>
                  </button>
                }
              />
            </div>
          </div>
        </div>
        <div className={classNames(selectedLT != null ? "" : "hidden")}>
          <p className="mt-2 text-left font-poppins font-medium text-15 text-black2">
            Events
          </p>
          <div className="flex sm:flex-nowrap flex-wrap justify-between items-start">
            <div className={selectedLT?.indicator == "L" ? "sm:w-[48%] w-full" : "hidden"}>
              <div className={classNames(selectedLT?.indicator == "L" ? "" : "hidden", "sm:w-auto w-full sm:min-w-[239px] bg-offwhite rounded-md mr-2 my-1")}>
                <CustomLeaveTypeDropdown
                  isSearch={true}
                  parendID={props.id}
                  dropdownborder="border-none"
                  state={props.selectedLeave}
                  setState={props.setSelectLeave}
                  dropdownItems={props.leaveType?.filter((x: any) => x.indicator == selectedLT?.indicator)?.map((leave: any) => {
                    return ({
                      value: leave.uniqeID,
                      label: leave.typeName,
                      leaveType: props.leaveType,
                      indicator: leave.indicator,
                    })
                  })}
                  className="font-poppins w-full min-w-[152px] max-w-full xs:min-w-[152px] 6xs:text-12  py-2 h-auto text-12"
                  placeholder="Please Select"
                  textAlign="text-left py-2 pl-2 pr-3"
                />

              </div>
              <p className={classNames((props.placeholder == "Leave(L)" || props.placeholder?.endsWith('L')) ? !props.selectedLeave ? '' : 'hidden' : 'hidden', "mt-2 font-poppins text-12 text-redPink errorMsg")}>
                Event type field is requried.
              </p>
            </div>
            <div className={selectedLT?.indicator == "H" ? "" : "hidden"}>
              <div className={classNames(selectedLT?.indicator == "H" ? "" : "hidden", "sm:w-auto w-full sm:min-w-[239px] bg-offwhite rounded-md mr-2 my-1")}>
                <div>
                  <CustomLeaveTypeDropdown
                    parendID={props.id}
                    dropdownborder="border-none"
                    state={props.selectedLeave}
                    setState={props.setSelectLeave}
                    dropdownItems={props.leaveType?.filter((x: any) => x.indicator == "H")?.map((leave: any) => ({
                      value: leave.uniqeID,
                      label: leave.typeName,
                      leaveType: props.leaveType,
                      indicator: leave.indicator,
                    }))}
                    className="font-poppins w-full min-w-[152px] max-w-full xs:min-w-[152px] sm:min-w-[152px] md:min-w-[152px] 6xs:text-12  py-2 h-auto text-12"
                    placeholder="Please Select"
                    textAlign="text-left py-2 pl-2 pr-3"
                  />

                </div>
              </div>
              <p className={classNames(props.placeholder == 'Holiday(H)' ? !props.selectedLeave ? '' : 'hidden' : 'hidden', "mt-2 font-poppins text-12 text-redPink errorMsg")}>
                Event field is requried.
              </p>
            </div>
            <div className={selectedLT?.indicator == "P" ? "" : "hidden"}>
              <div className={classNames(selectedLT?.indicator == "P" ? "" : "hidden", "sm:w-auto w-full sm:min-w-[239px] bg-offwhite rounded-md mr-2 my-1")}>
                <div className="">
                  <CustomLeaveTypeDropdown
                    isSearch={true}
                    parendID={props.id}
                    dropdownborder="border-none"
                    state={props.selectedLeave}
                    setState={props.setSelectLeave}
                    dropdownItems={props.keyDates?.map((leave: any) => {
                      return ({
                        value: leave.id,
                        label: leave.label,
                        //leaveType: props.leaveType,
                        indicator: "P",
                      })
                    })}
                    className="font-poppins w-full min-w-[152px] max-w-full xs:min-w-[152px] sm:min-w-[152px] md:min-w-[152px] 6xs:text-12  py-2 h-auto text-12"
                    placeholder="Please Select"
                    textAlign="text-left py-2 pl-2 pr-3"
                  />

                </div>
              </div>
              <p className={classNames((props.placeholder == "Project(P)") ? !props.selectedLeave ? '' : 'hidden' : 'hidden', "mt-2 font-poppins text-12 text-redPink errorMsg")}>
                Event type field is requried.
              </p>
            </div>
            <div className={selectedLT?.indicator != "L" ? "hidden" : "block"}>
              <div className={classNames(selectedLT?.indicator != "L" ? "hidden" : "flex", "xl:min-w-[190px] min-w-[185px] flex items-center mr-2  sm:w-[90%] w-[80%]")}>
                <div>
                  <CustomLeaveTypeDropdown
                    isSearch={true}
                    parendID={props.id}
                    leaveDataType="Leave"
                    //parentType={}
                    dropdownborder="border-none"
                    state={props.assign}
                    setState={props.changeAssignValue}
                    dropdownItems={props.employeeData?.map((emp: any) => ({
                      value: emp.id,
                      label: emp.name,
                      leaveType: props.leaveType,
                    }))}
                    className=" bg-offwhite rounded-md font-poppins w-full min-w-[152px] max-w-full xs:min-w-[152px] sm:min-w-[152px] md:min-w-[152px] 6xs:text-12  py-2 h-auto text-12"
                    placeholder="---"
                    textAlign="text-left py-2 pl-2 pr-3"
                  />

                </div>
              </div>
              <p className={classNames((props.placeholder == "Leave(L)" || props.placeholder?.endsWith('L')) ? !props.assign ? '' : 'hidden' : 'hidden', "mt-2 font-poppins text-12 text-redPink errorMsg")}>
                User field is requried.
              </p>
            </div>
            <div className={classNames(selectedLT?.indicator != "L" ? "hidden" : "flex", "xl:min-w-[28px] min-w-[20px]")}>
              <button
                onClick={() => props.deleteEventType(props.id)}
                className="xl:p-[6px] p-[5px] bg-redPink rounded-md cursor-pointer xl:max-w-[28px] max-w-[20px]"
              >
                <img src={trashIcon} alt="trashIcon" className="object-cover sm:w-[40px] w-[15px]" />
              </button>
            </div>
            <div className={classNames((selectedLT?.indicator != "H" && selectedLT?.indicator != "O") ? "hidden" : "block", "xl:max-w-full sm:max-w-[180px] max-w-[80%] w-full rounded-md mr-[10px]")}>
              <div className={classNames((selectedLT?.indicator != "H" && selectedLT?.indicator != "O") ? "hidden" : "flex", "h-full xl:min-w-[193px] min-w-[185px] bg-offwhite rounded-md mr-2 w-full")}>
                <input
                  value={props.assign}
                  onChange={(e) => props.changeAssignValue(e, props.id, leaveTypeData?.indicator)}
                  type="text"
                  className="font-poppins text-12 font-normal text-graydark p-2 max-w-full focus:outline-none bg-offwhite"
                />
              </div>
              <p className={classNames(((props.placeholder == "HKH" || props.placeholder == "SGH" || props.placeholder == "Holiday(H)")) ? !props.assign ? '' : 'hidden' : 'hidden', "mt-2 font-poppins text-12 text-redPink errorMsg")}>
                Holiday description is required.
              </p>
            </div>
            <div className={selectedLT?.indicator != "P" ? "hidden" : ""}>
              <div className={classNames(selectedLT?.indicator != "P" ? "hidden" : "flex", "items-center mr-2 xl:min-w-[193px]  sm:min-w-[185px] w-full sm:max-w-full max-w-[80%]")}>
                <CustomLeaveTypeDropdown
                 isSearch={true}
                  parendID={props.id}
                  leaveDataType="Project"
                  dropdownborder="border-none"
                  state={props.assign}
                  setState={props.changeAssignValue}
                  dropdownItems={props.projectList?.map((emp: any) => ({
                    value: emp.uniqeID,
                    label: emp.projectName,
                  }))}
                  className=" bg-offwhite rounded-md font-poppins w-full min-w-[152px] max-w-full xs:min-w-[152px] sm:min-w-[152px] md:min-w-[152px] 6xs:text-12  py-2 h-auto text-12"
                  placeholder="---"
                  textAlign="text-left py-2 pl-2 pr-3"
                />
              </div>
              <p className={classNames((props.placeholder == "Project(P)" || props.leaveType.find((x: any) => x.flag == props.placeholder)) ? !props.assign ? '' : 'hidden' : 'hidden', "mt-2 font-poppins text-12 text-redPink errorMsg")}>
                Project field is required.
              </p>
            </div>
            <div className={classNames(selectedLT?.indicator != "L" ? "flex" : "hidden", "xl:min-w-[28px] min-w-[20px]")}>
              <button
                onClick={() => props.deleteEventType(props.id)}
                className="xl:p-[6px] p-[5px] bg-redPink rounded-md cursor-pointer xl:max-w-[28px] max-w-[20px]"
              >
                <img src={trashIcon} alt="trashIcon" className="object-cover sm:w-[40px] w-[15px]" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <hr className="border-1 border-[#d5d5d5] mt-2" />
    </div>
  );
};
export default DashboardEventContainer;
