import {
  createColumnHelper,
  SortingState,
  ColumnFiltersState,
  RowSelectionState,
  ColumnDef,
  PaginationState,
} from "@tanstack/table-core";
import {
  FC,
  useState,
  useMemo,
  Dispatch,
  SetStateAction,
  useEffect,
  memo,
  useCallback,
} from "react";
import { CircularProgress, Modal } from "@mui/material";
import {
  CheckIcon,
  CloseIcon,
  CloseSmallIcon,
  EditIcon,
  TrashIcon,
} from "../../common/Icons";
import { useCreateCancelLeaveMutation } from "../../../app/services/profile";
import styles from "./MyClaim.module.scss";
import LayoutAdjustableTable from "../../Tables/LayoutAdjustableTable";
import TablePaginator from "../../Tables/TablePaginator";
import { Tdocuments } from "../../../leave-application/views/LeaveApplication";
import EditableCell from "../../Tables/EditableCell";
import { baseObj } from "../../../utils/constants";
import { toast } from "react-toastify";
import {
  useCancelMyClaimMutation,
  useDeleteMyClaimMutation,
} from "../../../app/services/claims";
import DeletePopup from "../../DeletePopup/DeletePopup";
import { renderDateFormatDMY } from "../../DashboardCalendarView/DashboardAddEventPopup";
import { formatMoney } from "../../../utils/helpers";
const classNames = require("classnames");

interface IPersonalLeaveTable {
  data?: any;
  setData?: any;
  columnFilters: ColumnFiltersState;
  setColumnFilters: Dispatch<SetStateAction<ColumnFiltersState>>;
  myLeavesList?: any;
  manageAllLeaveApplication?: boolean;
  id?: number;
  hiddenFields: string[];
  total: number;
  setCurrentPage: any;
  currentPage: number;
  pagination: PaginationState;
  setPagination: Dispatch<SetStateAction<PaginationState>>;
  changePage?: any;
  setShowLeaveFile: Dispatch<SetStateAction<boolean>>;
  setLeaveDocuments: Dispatch<React.SetStateAction<Tdocuments[]>>;
  sorting: SortingState;
  setSorting: React.Dispatch<React.SetStateAction<SortingState>>;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setEditOTData: any;
}

const MyClaimTable: FC<IPersonalLeaveTable> = ({
  // columnFilters,
  // setColumnFilters,
  myLeavesList,
  // manageAllLeaveApplication,
  id,
  hiddenFields,
  total,
  setCurrentPage,
  currentPage,
  pagination,
  setPagination,
  changePage,
  data,
  setData,
  setShowLeaveFile,
  setLeaveDocuments,
  sorting,
  setSorting,
  setEditOTData,
  setIsOpen,
}) => {
  const columnHelper = useMemo(() => createColumnHelper<any>(), []); // TODO add type definition when api binding
  const [isConfirmCancelOpen, setIsConfirmCancelOpen] =
    useState<boolean>(false);
  const [rowId, setRowId] = useState<number>();
  const [deleteID, setDeleteID] = useState<number | 0>();
  const [cancelID, setCancelID] = useState<number>(0);
  const [errorPopup, setErrorPopup] = useState<boolean>(false);
  const [message, setMessage] = useState<any>([]);
  const [deleteRowID, setDeleteRowID] = useState<number | 0>();
  const [openDeletePopup, setOpenDeleteopup] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  const [itemCount, setItemCount] = useState<number>(total);
  // const { data: myLeavesList } = useGetMyAuthLeaveQuery();
  const [createCancelLeave] = useCreateCancelLeaveMutation();
  const [deleteClaim, { isLoading: deleteLoading }] =
    useDeleteMyClaimMutation();
  const [cancelClaim, { isLoading: cancelLoading }] =
    useCancelMyClaimMutation();

  const deleteOTData = useCallback(
    (id?: any, uniqueID?: number) => {
      setDeleteID(id);
      setDeleteRowID(uniqueID);
      setOpenDeleteopup(true);
    },
    [setData, rowSelection]
  );
  const deleteOTItem = useCallback(
    (id?: number, dataId?: number) => {
      const selectedRows = Object.keys(rowSelection);
      setData((prev: baseObj[]) => {
        if (dataId != undefined) {
          deleteClaim(dataId)
            .unwrap()
            .then((payload: any) => {
              setOpenDeleteopup(false);
              toast("Successfully Delete.", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                type: "success",
              });
            })
            .catch((error: any) => {
              setErrorPopup(true);
              setOpenDeleteopup(false);
              setMessage(
                error
                  ? [error?.data.message]
                  : ["Something went wrong!Trying again."]
              );
            });
        }
        return prev.filter((_, index) => {
          return id !== undefined
            ? id !== index
            : !selectedRows.includes(String(index));
        });
      });
      id === undefined && setRowSelection({});
    },
    [setData, rowSelection]
  );
  const deleteOTDataMultiple = (ids: number[]) => {
    // deleteOTListByIDs(ids)
    //   .unwrap()
    //   .then((payload: any) => {
    //     setOpenDeleteopup(false);
    //     toast("Successfully Delete.", {
    //       position: "top-right",
    //       autoClose: 2000,
    //       hideProgressBar: false,
    //       closeOnClick: true,
    //       pauseOnHover: true,
    //       draggable: true,
    //       progress: undefined,
    //       theme: "light",
    //       type: "success",
    //     });
    //   })
    //   .catch((error: any) => {
    //     setErrorPopup(true);
    //     setOpenDeleteopup(false);
    //     setMessage(
    //       error ? [error?.data.message] : ["Something went wrong!Trying again."]
    //     );
    //   });
    // setRowSelection({});
  };

  const confirmDeleteData = () => {
    // if (cancelID) {
    //   cancelClaim(cancelID)
    //     .unwrap()
    //     .then((payload: any) => {
    //       setOpenDeleteopup(false);
    //       toast("Successfully Delete.", {
    //         position: "top-right",
    //         autoClose: 2000,
    //         hideProgressBar: false,
    //         closeOnClick: true,
    //         pauseOnHover: true,
    //         draggable: true,
    //         progress: undefined,
    //         theme: "light",
    //         type: "success",
    //       });
    //     })
    //     .catch((error: any) => {
    //       setErrorPopup(true);
    //       setOpenDeleteopup(false);
    //       setMessage(
    //         error
    //           ? [error?.data.message]
    //           : ["Something went wrong!Trying again."]
    //       );
    //     });
    // }
    var id = deleteID;
    var dataID = deleteRowID;
    const selectedRows = Object.keys(rowSelection);
    var selectionIds = data
      .filter((x: any, index: number) => {
        return selectedRows.find((s) => s == index.toString()) ? x.id : 0;
      })
      .map((x: any) => x.id);

    if (selectionIds.length > 0) {
      deleteOTDataMultiple(selectionIds);
    } else {
      if (id) {
        deleteOTItem(id, dataID);
        setDeleteID(-1);
        setDeleteRowID(-1);
      }
    }
  };

  const columns = useMemo(
    () => [
      // columnHelper.display({
      //   header: "",
      //   id: "selection",
      // }),
      columnHelper.accessor("code", {
        header: "Claim ID",
        id: "code",
        cell: ({ getValue, cell }) => {
          const id = getValue();
          return (
            <div className=" text-left bg-white text-black2 px-[10px] font-normal">
              <p className="h-full flex items-center">{id}</p>
            </div>
          );
        },
      }),
      columnHelper.accessor("claim_date", {
        header: "Claim Date",
        id: "applied_at",
        cell: ({ getValue, cell }) => {
          const date = getValue();
          return (
            <div className=" text-left bg-white text-black2 px-[10px] font-normal">
              <p className="h-full flex items-center">{renderDateFormatDMY(new Date(date), '-')}</p>
            </div>
          );
        },
      }),
      // columnHelper.accessor("employee_name", {
      //   header: "Employee",
      //   id: "employee_name",
      //   cell: ({ getValue, cell }) => {
      //     const name = getValue();
      //     return (
      //       <div className="text-left font-normal">
      //         <EditableCell
      //           setData={setData}
      //           value={name}
      //           type="text"
      //           cell={cell}
      //           customDesign="tooltip"
      //         />
      //       </div>
      //     );
      //   },
      // }),
      columnHelper.accessor("claim_type_name", {
        header: "Claim Type",
        id: "claim_type_name",
        cell: ({ getValue, cell }) => {
          const name = getValue();
          return (
            <div className=" text-left font-normal">
              <EditableCell
                setData={setData}
                value={name}
                type="text"
                cell={cell}
                customDesign="text"
              />
            </div>
          );
        },
      }),
      columnHelper.accessor("amount_text", {
        header: "Amount",
        id: "amount",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const name = getValue();
          const currency = cell.row.original.currency_code;
          return (
            <div className="text-left font-normal">
              <p className="flex h-full items-center">{formatMoney(Number(name.replace(currency, '')), currency)}</p>
            </div>
          );
        },
      }),
      columnHelper.accessor("description", {
        header: "Description",
        id: "description",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const name = getValue();
          return (
            <div className=" text-left font-normal">
              <EditableCell
                setData={setData}
                value={name}
                type="text"
                cell={cell}
                customDesign="text"
              />
            </div>
          );
        },
      }),
      columnHelper.accessor("documents", {
        header: "Supportive Document",
        id: "documents",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const docs = getValue();
          var txtColor = "text-graydark";
          if (docs.length) {
            txtColor = "text-blue-400";
          }
          return (
            <span
              onClick={() => {
                setLeaveDocuments(docs);
                setShowLeaveFile(true);
              }}
              className={classNames(
                txtColor,
                !docs.length ? " pointer-events-none " : "",
                "text-center justify-center h-full bg-white px-[10px] flex items-center cursor-pointer"
              )}
            >
              {docs.length ? "Attached Files" : "---"}
            </span>
          );
        },
      }),

      columnHelper.accessor("current_status", {
        header: "Status",
        id: "current_status",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const status = getValue();
          let txtColor = "text-graydark";
          if (status == "Rejected") {
            txtColor = "text-redPink";
          } else if (status == "Approved") {
            txtColor = "text-vorpgreen";
          } else if (status === "Completed") {
            txtColor = "text-sky-500";
          }

          return (
            <div className={`text-left font-normal ${txtColor}`}>
              <EditableCell
                setData={setData}
                value={status}
                IsDisabled={true}
                type="text"
                cell={cell}
                isYear={true}
              />
            </div>
          );
        },
      }),
      // columnHelper.accessor("remark", {
      //   header: "Remark",
      //   id: "remark",
      //   cell: ({ getValue, cell }) => {
      //     const year = getValue();
      //     return (
      //       <div className=" text-left font-normal">
      //         <EditableCell
      //           setData={setData}
      //           value={year}
      //           IsDisabled={true}
      //           type="text"
      //           cell={cell}
      //         />
      //       </div>
      //     );
      //   },
      // }),
      columnHelper.display({
        header: "Action",
        id: "actions",
        enableSorting: false,
        cell: ({ cell }) => {
          var isHaveNew = data?.find((x: any) => x.isNew == true);
          var disabledClass = isHaveNew ? "opacity-50 pointer-events-none" : "";
          const cancelOption: number = cell?.row?.original?.cancel_option;
          // const deleteOption: number = cell?.row?.original?.delete_option;
          const editOption: number = cell?.row?.original?.edit_option;

          return (
            <div
              className={classNames(
                styles.actionedBtn,
                "px-2 flex justify-center items-center min-w-max"
              )}
            >
              {id == 0 ? (
                <>
                  {!cancelOption && !editOption ? <span>---</span> : null}
                  {cancelOption ? (
                    // <button
                    //   className="text-black2 underline mr-7"
                    //   onClick={() => {
                    //     setIsConfirmCancelOpen(true);
                    //     // setRowId(ids);
                    //     setCancelID(cell.row.original.id);
                    //     // setIsSuccess(false);
                    //   }}
                    // >
                    //   Cancel
                    // </button>
                    <button
                      type="button"
                      onClick={() =>
                      // rejectRequest(
                      //   Number(cell.row.id),
                      //   Number(cell.row.original.id)
                      // )
                      {
                        setIsConfirmCancelOpen(true);
                        setCancelID(cell.row.original.id);
                      }
                      }
                      className={classNames(
                        styles.customCloseIcon,
                        "w-8 h-8 max-w-[24px] mr-2 flex justify-center items-center group rounded-md bg-redPink hover:bg-[#FFEAEF] transition-all duration-300"
                      )}
                    >
                      <CloseSmallIcon width={16} height={16} />
                    </button>
                  ) : null}

                  {editOption ? (
                    <button
                      disabled={isHaveNew ? true : false}
                      type="button"
                      onClick={() => {
                        setEditOTData(cell.row.original);
                        setIsOpen(true);
                      }}
                      className={classNames(
                        disabledClass,
                        " w-6 h-6 mr-2 flex justify-center items-center group rounded-md bg-vorpblue hover:bg-annhoverbg transition-all duration-300"
                      )}
                    >
                      <EditIcon />
                    </button>
                  ) : null}
                  {/* {deleteOption ? (
                    <button
                      type="button"
                      onClick={() => {
                        deleteOTData(cell.row.id, cell.row.original.id);
                      }}
                      className={classNames(
                        disabledClass,
                        " w-6 h-6 flex justify-center items-center group rounded-md bg-redPink hover:bg-[#FFEAEF] transition-all duration-300"
                      )}
                    >
                      <TrashIcon />
                    </button>
                  ) : null} */}
                </>
              ) : '---'}
            </div>
          );
        },
      }),
    ],
    [columnHelper, data, hiddenFields]
  );

  const [columnOrder, setColumnOrder] = useState<string[]>(
    columns.map((column: ColumnDef<any, any>) => column.id as string)
  );

  const saveCancel = () =>
    cancelClaim(cancelID)
      .unwrap()
      .then((payload: any) => {
        setOpenDeleteopup(false);
        setIsConfirmCancelOpen(false);
        toast("Successfully Cancel.", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          type: "success",
        });
      })
      .catch((error: any) => {
        setIsConfirmCancelOpen(false);
        setErrorPopup(true);
        setOpenDeleteopup(false);
        setMessage(
          error ? [error?.data.message] : ["Something went wrong!Trying again."]
        );
      });

  const visibleColumns: { [key: string]: boolean } = {};

  hiddenFields?.map((x: any) => {
    visibleColumns[x] = true;
  });

  if (hiddenFields.length > 0) {
    columns.map((x: any) => {
      if (x.id == "actions" || x.id == "lasted" || x.id == "selection") {
        visibleColumns[x.id ? x.id : ""] = true;
      } else if (x.id == "uniqeID") {
        visibleColumns[x.id ? x.id : ""] = false;
      } else if (x.id?.includes("none")) {
        visibleColumns[x.id ? x.id : ""] = true;
      } else {
        var isHidden = hiddenFields.find((h) => h == x.id);
        if (isHidden) {
          visibleColumns[x.id ? x.id : ""] = true;
        } else {
          visibleColumns[x.id ? x.id : ""] = false;
        }
      }
    });
  }
  return (
    <div>
      <Modal
        open={isConfirmCancelOpen}
        style={{
          background: "rgb(0 0 0 / 0.5)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        onClose={() => setIsConfirmCancelOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableEnforceFocus={true}
      >
        <div className="modalContent max-w-[90%] 6xs:max-w-[320px] w-full m-auto">
          <div className="w-full max-h-[138px] bg-white rounded-md overflow-hidden">
            <div className="p-[15px] pb-[23px]">
              <button
                className="ml-auto block"
                type="button"
                aria-label="Close Modal"
                onClick={() => setIsConfirmCancelOpen(false)}
              >
                <CloseIcon width={12} height={12} color="#858795" />
              </button>
              <h3 className="text-black2 max-w-[224px] mx-auto text-14 leading-6 text-center font-primary">
                Are you sure to cancel?
              </h3>
            </div>
            <div className="flex text-12 leading-[18px] font-semibold font-primary">
              <button
                onClick={() => {
                  saveCancel();
                  // setIsConfirmCancelOpen(false)
                }}
                type="button"
                className={classNames(
                  cancelLoading ? "opacity-50 pointer-events-none" : "",
                  "flex-1 py-3 flex justify-center items-center bg-vorpblue text-white"
                )}
              >
                {cancelLoading == true ? (
                  <div className="flex items-center justify-center w-full">
                    <CircularProgress
                      sx={{ color: "white" }}
                      className="w-5 h-5"
                      style={{ width: "20px", height: "20px" }}
                    />
                    <span className="ml-2">Yes</span>
                  </div>
                ) : (
                  "Yes"
                )}
              </button>
              <button
                onClick={() => setIsConfirmCancelOpen(false)}
                type="button"
                className="flex-1 py-3 flex justify-center items-center bg-offwhite text-vorpgraylight"
              >
                Discard
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <div className={styles.personalLeaveTable}>
        <LayoutAdjustableTable
          data={data}
          hideColumn={visibleColumns}
          columns={columns}
          sorting={sorting}
          setSortingState={setSorting}
          rowSelection={rowSelection}
          setRowSelection={setRowSelection}
          columnOrder={columnOrder}
          setColumnOrder={setColumnOrder}
          unDraggableFields={["actions", "selection"]}
          theaderColor="text-graydark"
          isLayoutControlOpen={false}
          setIsLayoutControlOpen={() => 1}
          label="User List"
          setItemCount={setItemCount}
          pagination={pagination}
          setPagination={setPagination}
          noPaddingCells={["leave_type_id", "fullHalfDay", "status"]}
        />
      </div>
      <div className="pt-6">
        <TablePaginator
          total={itemCount}
          pagination={pagination}
          setPagination={setPagination}
          itemCount={total}
          setCurrentPage={setCurrentPage}
          currentPageDefault={currentPage}
          changePage={changePage}
        />
      </div>
      <DeletePopup
        descText="Are you sure to delete?"
        confirmBtnText="Yes, delete."
        show={openDeletePopup}
        setShow={setOpenDeleteopup}
        confirmDeleteData={confirmDeleteData}
        loading={deleteLoading}
      />
    </div>
  );
};

export default memo(MyClaimTable);
