import authHeader from "./auth-header";
import { api, endpointUrl } from "./api";
import { redirectToLogin } from "./annoucement-sheet";
import { TClaimType, TRequestClaimSave } from "../../claim-type/claim.modal";
import fileAuthHeader from "./file-auth-header";

export type PermissionResponse = {
  data: EmployeeList[];
};

export type EmployeeList = {
  id: number;
  name: string;
  icon: string;
  email: string;
};
export type createdBy = {
  name: string;
  createdAt: Date;
};
export type AssignData = {
  id: number;
  name: string;
  email: string;
  profileImage: string;
};

export type PasswordResponse = {
  module_id: number;
  passwords: Password;
  passwordUserList: PasswordUserList[];
  // show_password: boolean
};

export type PasswordResponseMutation = {
  data: DD;
};

export type DD = {
  data: Datas;
};

export type Datas = {
  module_id: number;
  passwords: Password;
  passwordUserList: PasswordUserList[];
  // show_password: boolean
};

export type Password = {
  data: Pwd[];
  total: number;
  limit?: number | string;
  page?: number | string;
  tag?: number | string;
  share_to?: number | string;
  search?: number | string;
  sort?: string;
  sortBy?: any;
};

export type Pwd = {
  id: number;
  platform: string;
  account: string;
  password: string;
  links: string;
  department: string;
  createdBy: createdBy;
  assignUsers: AssignData[];
};

export type PasswordData = {
  platform: string;
  account: string;
  password: string;
  links: string;
  department: string;
  createdBy: createdBy;
  assignUsers: AssignData[];
  tags?: number[];
  id?: number;
};
export type PasswordDataHR = {
  platform: string;
  company: string;
  account: string;
  password: string;
  links: string;
  tags: number[];
  user_ids?: number[];
};
export type DepartmentResponse = { departmentList: KeyValueLabel[] };

export type KeyValueLabel = {
  id: number;
  name: string;
  label?: string;
};

export type PasswordUserList = {
  id: number;
  name: string;
  email: string;
  profile_image: string;
  photo: string;
};
export type AssignDataList = {
  password_ids: number[];
  user_ids: number[];
};
export type AssignDataSingle = {
  password_ids: number;
  user_ids: number[];
};

export interface IOnePasswordResponse {
  platform: string;
  account: string;
  password: string;
  links: string;
  createdBy: createdBy;
  assignUsers: AssignData[];
  tags?: number[];
  id?: number;
}

export type ResponseOnePassword = {
  password: IOnePasswordResponse;
};
export interface IOnePasswordRequest {
  id: number;
}

export type ResponseKeyPassword = {
  publicKey: string;
};

export type ResponseIsPwdKey = {
  can_see: boolean;
};

export type RequestUserList = {
  search: string;
  department_id: number | string;
};
export interface assignUsers {
  id: number;
  name: string;
  email: string;
  profile_image: string;
  photo: string;
  label?: string | number;
}
export type ResponseUserList = {
  users: assignUsers[];
};

// -----------------------------------
export type PayRollObjResponse = {
  payrolls: PayRollType;
  module_id: number;
};
export type PayRollObjFilterResponse = {
  payrolls: PayRollType;
  module_id: number;
};

export type TCurrencyList = {
  id: number;
  currency_name: string;
  currency_code: string;
};

export type TactiveClaimTypeList = {
  id: number;
  name: string;
  status: number;
  limit: string;
};

export type TClaimTypeData = {
  id: number;
  name: string;
  status: number;
  limit: string;
  label?: string;
};

export type PayRollType = {
  data: TPayRollResponse[];
  total: number;
};

export type TPayRollResponse = {
  id: number;
  employee_id: string;
  code: string;
  process_date: string;
  employee_name: string;
  month: string;
  currency_id: number;
  currency_name: string;
  currency_code: string;
  basic_salary: string;
  basic_salary_sgd: string;
  total_days: number;
  office_off_days: number;
  hourly_rate: string;
  hourly_rate_sgd: string;
  daily_rate: string;
  daily_rate_sgd: string;
  annual_leave: number;
  unpaid_leave: number;
  regular_working_days: number;
  actual_working_days: number;
  counted_working_days: number;
  counted_working_days_salary: string;
  counted_active_time_hours: number;
  official_90_percent_active_hours: number;
  official_80_percent_active_hours: number;
  official_70_percent_active_hours: number;
  internet_incentive: string;
  no_absent_incentive: string;
  no_lateness_incentive: string;
  work_quality_rewards_tier_1: string;
  work_quality_rewards_tier_2: string;
  other_rewards: string;
  allowance: string;
  total_incentive_reward: string;
  ot_fee: string;
  bonus: string;
  income_tax: string;
  unpaid_leave_deduction: string;
  final_payment: string;
  final_payment_sgd: string;
  mobile: string;
  receipt_bank: string;
  receipt_no: string;
  bank_account_name: string;
  bank_account_no: string;
  receipt_bank_branch: string;
  status: number;
  is_sent: number;
  is_edited: number;
  document_id: number;
  handled_by: string;
};

export type TRequestPryroll = {
  id?: number;
  limit?: number | string;
  page?: number | string;
  tag?: number | string;
  share_to?: number | string;
  search?: number | string;
  sort?: string;
  sortBy?: any;
  status?: number | string;
  type?: string | number;
  start?: string;
  end?: string;
  limited?: string;
  sorting?: string;
  month?: string;
};

export type TRequestPryrollData = {
  employee_voluntary_contribution: number;
  employee_social_security_fund: number;
  unpaid_leave_deduction: number;
  income_tax: number;
  bonus: number;
  ot_fee: number;
  final_payment: number;
  receipt_bank: string;
  receipt_no: string;
  bank_account_name: string;
  bank_account_no: string;
  receipt_bank_branch?: string;
  id?: number;
};

export type TDocuments = {
  id: number;
  name: string;
  hash: string;
  path: string;
  type: string;
  url: string;
};

export interface FileResponse {
  // Define the shape of your file response
  data: Blob;
}

export type TGeneratePayRoll = {
  status: boolean;
  message: string;
};


export const payrollApi = api.injectEndpoints({
  endpoints: (build) => ({
    getPayRollList: build.query<PayRollObjResponse, Partial<TRequestPryroll>>({
      query: ({ page, limit, search, status, month, sorting }) => ({
        url: `${endpointUrl}payrolls?page=${page}&limit=${limit}&search=${search}&is_sent=${status}&month=${month}&sort=${sorting}`,
        headers: authHeader(),
        method: "GET",
      }),
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
      providesTags: () => [{ type: "PayRoll" as const, id: "LIST" }],
    }),
    getPayRollFilterList: build.query<PayRollObjFilterResponse, void>({
      query: () => ({
        url: `${endpointUrl}payrolls-filter-data`,
        headers: authHeader(),
        method: "GET",
      }),
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
      providesTags: () => [{ type: "PayRollFilterData" as const, id: "LIST" }],
    }),
    getDownloadPayRoll: build.mutation<FileResponse, Partial<TRequestPryroll>>({
      query: ({ id }) => ({
        url: `${endpointUrl}payrolls/${id}/download`,
        headers: authHeader(),
        method: "GET",
        responseType: "blob",
      }),
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
      // providesTags: () => [{ type: "MyClaims" as const, id: "LIST" }],
      invalidatesTags: [{ type: "PayRoll", id: "LIST" }],
    }),

    sendPayRollSlip: build.mutation<PasswordData, Partial<TRequestClaimSave>>({
      query: ({ id }) => ({
        url: `${endpointUrl}payrolls/${id}/send`,
        headers: authHeader(),
        method: "POST",
      }),
      invalidatesTags: [{ type: "PayRoll", id: "LIST" }],
    }),

    multSendPayRollSlip: build.mutation<
      { success: boolean; ids: number[] },
      number[]
    >({
      query: (ids) => ({
        url: `${endpointUrl}payrolls/multi-send`,
        headers: authHeader(),
        method: "POST",
        body: { ids },
      }),
      invalidatesTags: [{ type: "PayRoll", id: "LIST" }],
    }),

    multRegeneratePaySlip: build.mutation<
      { success: boolean; ids: number[] },
      number[]
    >({
      query: (ids) => ({
        url: `${endpointUrl}payrolls/multi-regenerate`,
        headers: authHeader(),
        method: "POST",
        body: { ids },
      }),
      invalidatesTags: [{ type: "PayRoll", id: "LIST" }],
    }),

    regeneratePayRollSlip: build.mutation<
      PasswordData,
      Partial<TRequestClaimSave>
    >({
      query: ({ id }) => ({
        url: `${endpointUrl}payrolls/${id}/regenerate`,
        headers: authHeader(),
        method: "POST",
      }),
      invalidatesTags: [{ type: "PayRoll", id: "LIST" }],
    }),

    updatedPayRoll: build.mutation<
      Partial<TPayRollResponse>,
      Partial<TRequestPryrollData>
    >({
      query: (body) => ({
        url: `${endpointUrl}payrolls/${body.id}`,
        headers: authHeader(),
        method: "PUT",
        body,
      }),
      invalidatesTags: [{ type: "PayRoll", id: "LIST" }],
    }),

    generatePayRoll: build.mutation<TGeneratePayRoll, void>({
      query: (body) => ({
        url: `${endpointUrl}payrolls`,
        headers: authHeader(),
        method: "POST",
        body,
      }),
      invalidatesTags: [{ type: "PayRoll", id: "LIST" }],
    }),

    updatedClaimType: build.mutation<PasswordData, Partial<TRequestClaimSave>>({
      query: (data) => ({
        url: `${endpointUrl}claim-types/${data.id}`,
        headers: authHeader(),
        method: "PUT",
        body: data,
      }),

      invalidatesTags: [{ type: "ClaimTypeList", id: "LIST" }],
    }),

    deleteClaimType: build.mutation<{ id: number }, number>({
      query: (id) => ({
        url: `${endpointUrl}claim-types/${id}`,
        method: "DELETE",
        headers: authHeader(),
      }),
      invalidatesTags: ["ClaimTypeList"],
    }),

    cancelMyClaim: build.mutation<{ id: number }, number>({
      query: (id) => ({
        url: `${endpointUrl}claim-forms/${id}/cancel`,
        method: "POST",
        headers: authHeader(),
      }),
      invalidatesTags: ["MyClaims"],
    }),

    deleteMyClaim: build.mutation<{ id: number }, number>({
      query: (id) => ({
        url: `${endpointUrl}claim-forms/${id}`,
        method: "DELETE",
        headers: authHeader(),
      }),
      invalidatesTags: ["MyClaims"],
    }),

    deleteAdminClaim: build.mutation<{ id: number }, number>({
      query: (id) => ({
        url: `${endpointUrl}claim-forms/${id}`,
        method: "DELETE",
        headers: authHeader(),
      }),
      invalidatesTags: ["Claims"],
    }),

    multiDeleteClaimType: build.mutation<
      { success: boolean; ids: number[] },
      number[]
    >({
      query: (ids) => ({
        url: `${endpointUrl}delete-claim-types`,
        headers: authHeader(),
        method: "DELETE",
        body: { ids },
      }),
      invalidatesTags: (Password) => [{ type: "ClaimTypeList" }],
    }),
  }),
});

export const {
  useGetPayRollListQuery,
  useGetDownloadPayRollMutation,
  useMultSendPayRollSlipMutation,
  useMultRegeneratePaySlipMutation,
  useSendPayRollSlipMutation,
  useRegeneratePayRollSlipMutation,
  useUpdatedPayRollMutation,
  useGeneratePayRollMutation,
  useDeleteClaimTypeMutation,
  useDeleteMyClaimMutation,
  useDeleteAdminClaimMutation,
  useCancelMyClaimMutation,

  useMultiDeleteClaimTypeMutation,
  useUpdatedClaimTypeMutation,
  useGetPayRollFilterListQuery
} = payrollApi;

export const {
  endpoints: {
    getPayRollList,
    multSendPayRollSlip,
    multRegeneratePaySlip,
    getDownloadPayRoll,
    sendPayRollSlip,
    regeneratePayRollSlip,
    updatedPayRoll,
    generatePayRoll,
    multiDeleteClaimType,
    deleteClaimType,
    deleteMyClaim,
    deleteAdminClaim,
    cancelMyClaim,
    updatedClaimType,
    getPayRollFilterList
  },
} = payrollApi;
