import {
  ColumnDef,
  ColumnFiltersState,
  createColumnHelper,
  PaginationState,
  RowSelectionState,
  SortingState,
} from "@tanstack/react-table";
import { Dispatch, FC, SetStateAction, useMemo, useState } from "react";
import LayoutAdjustableTable from "../../Tables/LayoutAdjustableTable";
import { baseObj, dummyDocumentTableData } from "../../../utils/constants";
import {
  DownloadIcon,
  // SolidDownloadIcon,
  StarIcon,
  ViewIcon,
} from "../../common/Icons";
import { DocsIcon } from "./icons/IconMapper";
import TablePaginator from "../../Tables/TablePaginator";
// import { User1, User2, User3 } from "./icons/IconMapper";
import DocumentsGrid from "./DocumentsGrid";
import { IFilterDate } from "./Documents";
import { dateRangeFilterFn, formatDate } from "../../../utils/helpers";
import classNames from "classnames";
import style from "./Documents.module.scss";
// import { makeStyles, Tooltip } from "@material-ui/core";
import { makeStyles } from "@mui/styles";
import { Tooltip } from "@mui/material";
import axios from "../../../axios";
import { endpointUrl } from "../../../app/services/api";
import { assignedColor } from "../../Documents/EmployeeDocumentsTable";
import { ImageFormat } from "../../../app/services/documents";
import { tagsColor } from "../../Announcements/AnouncementsTable";
import { getAuthUser } from "../../../app/services/dashboard";
import { onViewDocument } from "../../LeaveApplicationFIlesPopup/LeaveApplicationFilesPopup";
// import { httpErrorHandler } from "../../../app/services/dashboard";

interface IMarkAsImportantBtn {
  initialState?: boolean;
  onClick: () => void;
  setData: Dispatch<SetStateAction<baseObj[]>>;
  rowId: string;
}

interface IDocumentTable {
  isTableView?: boolean;
  pagination: PaginationState;
  setPagination: Dispatch<SetStateAction<PaginationState>>;
  globalSearch: string;
  setGlobalSearch: Dispatch<SetStateAction<string>>;
  columnFilters: ColumnFiltersState;
  setColumnFilters: Dispatch<SetStateAction<ColumnFiltersState>>;
  filterDate?: IFilterDate;
  id: number;
  manageDocument: boolean;
  data: baseObj[];
  setData: React.Dispatch<React.SetStateAction<baseObj[]>>;
  sorting: SortingState;
  setSorting: React.Dispatch<React.SetStateAction<SortingState>>;
  total: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  currentPage: number;
  changePage: (pageNo: number) => void;
  onDownloadClick: (id: number, name: string) => void
}

export const MarkAsImportantBtn: FC<IMarkAsImportantBtn> = ({
  initialState = false,
  onClick,
  setData,
  rowId,
}) => {
  const updateImportantStatus = () => {
    setData((prev) =>
      prev.map((row) => {
        if (row.id === rowId) {
          return { ...row, isImportant: !initialState };
        } else {
          return row;
        }
      })
    );
  };
  return (
    <button
      onClick={() => {
        onClick();
        updateImportantStatus();
      }}
    >
      <StarIcon color={initialState ? "#FFA800" : "#F3F6F9"} />
    </button>
  );
};
const useStyles = makeStyles(() => ({
  tooltip: {
    backgroundColor: "transparent",
    margin: 0,
    marginRight: 8,
    padding: 0,
  },
}));

const DocumentsTable: FC<IDocumentTable> = ({
  isTableView = true,
  pagination,
  setPagination,
  globalSearch,
  setGlobalSearch,
  columnFilters,
  setColumnFilters,
  id,
  data,
  setData,
  manageDocument,
  setSorting,
  sorting,
  total,
  setCurrentPage,
  currentPage,
  changePage,
  onDownloadClick
}) => {
  const columnHelper = useMemo(() => createColumnHelper<any>(), []); // TODO add type definitions when api binding
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  const [itemCount, setItemCount] = useState<number>(total);
  var getUser = getAuthUser();
  const selectionCount = Object.keys(rowSelection).length;
  const classes = useStyles();
 
  
  // TODO add type definitions when api binding
  var columns: any;

  const columnsPermis = useMemo(
    () => [
      columnHelper.display({
        header: "",
        id: 'selection',
      }),
      
      columnHelper.accessor("code", {
        id: "code",
        header: "ID",
        cell: (props) => (
          <span className="block min-w-[130px] text-left">
            {props.getValue()}
          </span>
        ),
      }),
      columnHelper.accessor("filepath", {
        id: "filepath",
        header: "",
        cell: (props) => (
          <span className="block min-w-[130px] text-left"></span>
        ),
      }),
      columnHelper.accessor("name", {
        header: "Name",
        id: "name",
        filterFn: "includesStringSensitive",
        cell: ({ getValue, cell }) => {
          const name = getValue();
          var url = cell.row.original.url;
          const extension = name.split(".").pop() as string;

          return (
            <div className="block pr-[6px] min-w-[230px] ">
              <div className="flex items-center">
                <img
                 src={
                  extension
                    ? DocsIcon[extension]
                      ? DocsIcon[extension]
                      : ImageFormat.includes(extension)
                        ? DocsIcon[extension]
                        : DocsIcon["unknow"]
                    : DocsIcon.folder
                }
                  alt={name}
                  width={16}
                  height={16}
                  loading="lazy"
                  className="mr-[10px]"
                />
                <span className="max-w-[95%] overflow-hidden whitespace-nowrap text-ellipsis">
                  {name}
                </span>

                {/* <Tooltip
                  arrow
                  classes={{ tooltip: classes.tooltip }}
                  enterTouchDelay={0}
                  leaveTouchDelay={5000}
                  placement="left"
                  title={
                    <>
                      <div className="text-white text-[10px] hover:cursor-pointer leading-[15px] z-[2] rounded p-4 w-full bg-tooltipbg">
                        <span className="text-[#fff] block">{name}</span>
                      </div>
                    </>
                  }
                >
                  <span className="max-w-[95%] overflow-hidden whitespace-nowrap text-ellipsis">{name}</span>
                </Tooltip> */}
              </div>
              {/* <MarkAsImportantBtn
                onClick={() => 1}
                initialState={cell.row.original.isImportant}
                setData={setData}
                rowId={cell.row.original.id}
              /> */}
            </div>
          );
        },
      }),
      columnHelper.accessor("tags", {
        header: "Tag",
        id: "tags",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const tags = getValue();
          return (
            <div className="min-w-[188px] text-left flex items-center">
              <div className="max-w-[300px] overflow-hidden">
                <div className="w-[97%] overflow-hidden flex items-center">
                  {tags?.length == 0 && (
                    <div className="flex justify-center items-center">---</div>
                  )}
                  {tags.map((x: any, index: number) => {
                    var idx = index;
                    if (index >= 3) {
                      idx = index % 3;
                    }
                    return (
                      <div
                        key={index}
                        className="px-[5px] mr-[3px] rounded-[5px] font-normal"
                        style={{
                          backgroundColor: tagsColor[idx],
                          height: "auto",
                        }}
                      >
                        {x.name}
                      </div>
                    );
                  })}
                </div>
              </div>

              {/* <Tooltip
                arrow
                classes={{ tooltip: classes.tooltip }}
                enterTouchDelay={0}
                leaveTouchDelay={5000}
                placement="left"
                title={
                  <>
                    <div className="text-white text-[10px] hover:cursor-pointer leading-[15px] z-[2] rounded p-4 w-full bg-tooltipbg">
                      <span className="text-[#fff] block">
                        {tags?.map((x: any) => x.name)?.join(", ")}
                      </span>
                    </div>
                  </>
                }
              >
                <div className="max-w-[300px] overflow-hidden">
                  <div className="w-[97%] overflow-hidden flex items-center">
                    {tags?.length == 0 && (
                      <div className="flex justify-center items-center">
                        ---
                      </div>
                    )}
                    {tags.map((x: any, index: number) => {
                      var idx = index;
                      if (index >= 3) {
                        idx = index % 3;
                      }
                      return (
                        <div
                          key={index}
                          className="px-[5px] mr-[3px] rounded-[5px] font-normal"
                          style={{
                            backgroundColor: tagsColor[idx],
                            height: "auto",
                          }}
                        >
                          {x.name}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </Tooltip> */}
            </div>
          );
        },
      }),
      columnHelper.accessor("owner", {
        header: "Owner",
        id: "owner",
        cell: (props) => (
          <div className="min-w-[130px] text-left flex items-center">
            {props.getValue()?.name == "" && <span>----</span>}
            <span>{props.getValue()?.name}</span>
          </div>
        ),
      }),
      columnHelper.accessor("path", {
        header: "Path",
        id: "path",
        cell: (props) => (
          <div className="min-w-[100px] text-left flex items-center">
             <span className="max-w-[97%] overflow-hidden whitespace-nowrap text-ellipsis">
                {props.getValue()}
              </span>
            {/* <Tooltip
              arrow
              classes={{ tooltip: classes.tooltip }}
              enterTouchDelay={0}
              leaveTouchDelay={5000}
              placement="left"
              title={
                <>
                  <div className="text-white text-[10px] hover:cursor-pointer leading-[15px] z-[2] rounded p-4 w-full bg-tooltipbg">
                    <span className="block">{props.getValue()}</span>
                  </div>
                </>
              }
            >
              <span className="max-w-[90%] overflow-hidden whitespace-nowrap text-ellipsis">
                {props.getValue()}
              </span>
            </Tooltip> */}
          </div>
        ),
      }),
      columnHelper.accessor("lastModifiedAt", {
        header: "Last modified",
        id: "lastModifiedAt",
        filterFn: dateRangeFilterFn,
        cell: (props) => (
          <div className="min-w-[130px] text-left flex items-center">
            <span>
              {props.getValue()}
            </span>
          </div>
        ),
      }),
      columnHelper.accessor("postedBy", {
        header: "Posted By",
        id: "postedBy",
        cell: (props) => (
          <div className="min-w-[130px] text-left flex items-center">
            <span>{props.getValue()?.name}</span>
          </div>
        ),
      }),
      // TODO Change display to accessor when api binding
      columnHelper.display({
        header: () => <span className="text-center w-full">Members</span>,
        id: "assignUsers",
        enableSorting: false,
        cell: ({ cell, getValue }) => {
          // const id = cell.row.original.id;
          const assigned = cell.row.original.assignUsers;
          return (
            <button
              type="button"
              className="min-w-[150px] flex justify-center items-center h-full ml-[10px]"
            >
              {assigned?.map((x: any, index: number) => {
                if (index < 3) {
                  var idx = index;
                  if (idx > 4) {
                    idx = 0;
                  }

                  return x.profileImage ? (
                    <img
                      key={index}
                      src={x.profileImage}
                      alt="profileImage"
                      className="w-[35px] h-[35px] rounded-full object-cover ml-[-10px]"
                    />
                  ) : (
                    <div
                      key={index}
                      className={classNames(
                        "bg-[" + assignedColor[idx] + "]",
                        "ml-[-10px] w-[35px] h-[35px] rounded-full text-14 flex justify-center items-center text-white"
                      )}
                    >
                      {x.name?.substring(0, 1)}
                    </div>
                  );
                } else {
                  return null;
                }
              })}
              {assigned?.length > 3 ? (
                <div className="ml-[-10px] w-[35px] h-[35px] rounded-full text-10 flex justify-center items-center bg-black2 text-white">
                  {assigned?.length - 4}+
                </div>) : ''}
              
            </button>
          )
        }
      }),
      columnHelper.display({
        header: "Actions",
        id: "actions",
        cell: ({ cell, row }) => {
          const did = cell.row?.original?.id;
          const name = cell.row?.original?.name;
          const url = cell.row.original.url;
          const extension = cell.row.original.path.split(".").pop();
          return (
            <div className="flex justify-center items-center min-w-[130px]">
              {Number(id) === 0 && !id ? (
                <>
                  <button
                    type="button"
                    onClick={() => 1}
                    className="w-8 h-8 mr-2 flex justify-center items-center group rounded-md bg-[#8950FC] hover:bg-[#EAE0FF] transition-all duration-300"
                  >
                    {extension == "xlsx" ? (
                      <button
                        type="button"
                        onClick={() => onDownloadClick(id, name)}
                        className="w-6 h-6 flex justify-center items-center group rounded-md bg-black2  hover:bg-[#FFEAEF] transition-all duration-300 mr-2 last:mr-0"
                      >
                        <ViewIcon className="group-hover:fill-black2" />
                      </button>
                    ) : (
                      <button className="flex items-center justify-center" onClick={() => {
                        onViewDocument(cell.row.original.url,cell.row.original.type,getUser.token)
                      }
                      }
                      // target="_blank" href={url}
                      >
                        <ViewIcon
                          width={16}
                          height={16}
                          className={classNames(
                            "group-hover:fill-black2"
                          )}
                        />
                      </button>
                    )}
                  </button>
                  <button
                    type="button"
                    onClick={() => onDownloadClick(did, name)}
                    className="w-8 h-8 flex justify-center items-center group rounded-md bg-black2 hover:bg-white transition-all duration-300"
                  >
                    <a download href={row.original.filepath}>
                      <DownloadIcon className="group-hover:fill-black2" />
                    </a>
                  </button>
                </>
              ) : (
                <>---</>
              )}
            </div>
          );
        },
      }),
    ],
    [columnHelper, data]
  );

  const columnsNot = useMemo(
    () => [
      columnHelper.accessor("code", {
        id: "code",
        header: "ID",
        cell: (props) => (
          <span className="block min-w-[130px] text-left">
            {props.getValue()}
          </span>
        ),
      }),
      columnHelper.accessor("filepath", {
        id: "filepath",
        header: "",
        cell: (props) => (
          <span className="block min-w-[130px] text-left"></span>
        ),
      }),
      columnHelper.accessor("name", {
        header: "Name",
        id: "name",
        filterFn: "includesStringSensitive",
        cell: ({ getValue, cell }) => {
          const name = getValue();
          var url = cell.row.original.url;
          const extension = name.split(".").pop() as string;

          return (
            <div className="block pr-[6px] min-w-[230px]">
              <div className="flex items-center">
                <img
                  src={
                    extension
                      ? DocsIcon[extension]
                        ? DocsIcon[extension]
                        : ImageFormat.includes(extension)
                          ? DocsIcon[extension]
                          : DocsIcon["unknow"]
                      : DocsIcon.folder
                  }
                  alt={name}
                  width={16}
                  height={16}
                  loading="lazy"
                  className="mr-[10px]"
                />
                <span className="max-w-[95%] overflow-hidden whitespace-nowrap text-ellipsis">
                    {name}
                  </span>
                {/* <Tooltip
                  arrow
                  classes={{ tooltip: classes.tooltip }}
                  enterTouchDelay={0}
                  leaveTouchDelay={5000}
                  placement="left"
                  title={
                    <>
                      <div className="text-white text-[10px] hover:cursor-pointer leading-[15px] z-[2] rounded p-4 w-full bg-tooltipbg">
                        <span className="text-[#fff] block">{name}</span>
                      </div>
                    </>
                  }
                >
                  <span className="max-w-[95%] overflow-hidden whitespace-nowrap text-ellipsis">
                    {name}
                  </span>
                </Tooltip> */}
              </div>
              {/* <MarkAsImportantBtn
                onClick={() => 1}
                initialState={cell.row.original.isImportant}
                setData={setData}
                rowId={cell.row.original.id}
              /> */}
            </div>
          );
        },
      }),
      columnHelper.accessor("tags", {
        header: "Tag",
        id: "tags",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const tags = getValue();
          return (
            <div className="min-w-[188px] text-left flex items-center">
               <div className=" overflow-hidden">
                  <div className="w-[97%] overflow-hidden flex items-center">
                    {tags?.length == 0 && (
                      <div className="flex justify-center items-center">
                        ---
                      </div>
                    )}
                    {tags.map((x: any, index: number) => {
                      var idx = index;
                      if (index >= 3) {
                        idx = index % 3;
                      }
                      return (
                        <div
                          key={index}
                          className="px-[5px] mr-[3px] rounded-[5px] font-normal"
                          style={{
                            backgroundColor: tagsColor[idx],
                            height: "auto",
                          }}
                        >
                          {x.name}
                        </div>
                      );
                    })}
                  </div>
                </div>
              {/* <Tooltip
                arrow
                classes={{ tooltip: classes.tooltip }}
                enterTouchDelay={0}
                leaveTouchDelay={5000}
                placement="left"
                title={
                  <>
                    <div className="text-white text-[10px] hover:cursor-pointer leading-[15px] z-[2] rounded p-4 w-full bg-tooltipbg">
                      <span className="text-[#fff] block">
                        {tags?.map((x: any) => x.name)?.join(", ")}
                      </span>
                    </div>
                  </>
                }
              >
                <div className="max-w-[300px] overflow-hidden">
                  <div className="w-[97%] overflow-hidden flex items-center">
                    {tags?.length == 0 && (
                      <div className="flex justify-center items-center">
                        ---
                      </div>
                    )}
                    {tags.map((x: any, index: number) => {
                      var idx = index;
                      if (index >= 3) {
                        idx = index % 3;
                      }
                      return (
                        <div
                          key={index}
                          className="px-[5px] mr-[3px] rounded-[5px] font-normal"
                          style={{
                            backgroundColor: tagsColor[idx],
                            height: "auto",
                          }}
                        >
                          {x.name}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </Tooltip> */}
            </div>
          );
        },
      }),
      columnHelper.accessor("owner", {
        header: "Owner",
        id: "owner",
        cell: (props) => (
          <div className="min-w-[130px] text-left flex items-center">
            {props.getValue()?.name == "" && <span>----</span>}
            <span>{props.getValue()?.name}</span>
          </div>
        ),
      }),
      columnHelper.accessor("path", {
        header: "Path",
        id: "path",
        cell: (props) => (
          <div className="min-w-[130px] text-left flex items-center">
            <span className="max-w-[90%] overflow-hidden whitespace-nowrap text-ellipsis">
                {props.getValue()}
              </span>
            {/* <Tooltip
              arrow
              classes={{ tooltip: classes.tooltip }}
              enterTouchDelay={0}
              leaveTouchDelay={5000}
              placement="left"
              title={
                <>
                  <div className="text-white text-[10px] hover:cursor-pointer leading-[15px] z-[2] rounded p-4 w-full bg-tooltipbg">
                    <span className="block">{props.getValue()}</span>
                  </div>
                </>
              }
            >
              <span className="max-w-[90%] overflow-hidden whitespace-nowrap text-ellipsis">
                {props.getValue()}
              </span>
            </Tooltip> */}
          </div>
        ),
      }),
      columnHelper.accessor("lastModifiedAt", {
        header: "Last modified",
        id: "lastModifiedAt",
        filterFn: dateRangeFilterFn,
        cell: (props) => (
          <div className="min-w-[130px] text-left flex items-center">
            <span>
              {props.getValue()}
            </span>
          </div>
        ),
      }),
      columnHelper.accessor("postedBy", {
        header: "Posted By",
        id: "postedBy",
        cell: (props) => (
          <div className="min-w-[130px] text-left flex items-center">
            <span>{props.getValue()?.name}</span>
          </div>
        ),
      }),
      // TODO Change display to accessor when api binding
      columnHelper.display({
        header: () => <span className="text-center w-full">Members</span>,
        id: "assignUsers",
        enableSorting: false,
        cell: ({ cell, getValue }) => {
          const assigned = cell.row.original.assignUsers;
          return (
            <button
              type="button"
              className="min-w-[150px] flex justify-center items-center h-full ml-[10px]"
            >
              {assigned?.map((x: any, index: number) => {
                if (index < 3) {
                  var idx = index;
                  if (idx > 4) {
                    idx = 0;
                  }

                  return x.profileImage ? (
                    <img
                      key={index}
                      src={x.profileImage}
                      alt="profileImage"
                      className="w-[35px] h-[35px] rounded-full object-cover"
                    />
                  ) : (
                    <div
                      key={index}
                      className={classNames(
                        "bg-[" + assignedColor[idx] + "]",
                        "ml-[-10px] w-[35px] h-[35px] rounded-full text-14 flex justify-center items-center text-white"
                      )}
                    >
                      {x.name?.substring(0, 1)}
                    </div>
                  );
                } else {
                  return null;
                }
              })}
              {assigned?.length > 3 ? (
                <div className="ml-[-10px] w-[35px] h-[35px] rounded-full text-10 flex justify-center items-center bg-black2 text-white">
                  {assigned?.length - 3}+
                </div>
              ) : (
                ""
              )}
            </button>
          );
        },
      }),
     
      columnHelper.display({
        header: "Actions",
        id: "actions",
        cell: ({ cell, row }) => {
          const did = cell.row?.original?.id;
          const name = cell.row?.original?.name;
          const url = cell.row.original.url;
          const extension = cell.row.original.name.split(".").pop();
          return (
            <div className="flex justify-center items-center min-w-[130px]">
              {Number(id) === 0 && !id ? (
                <>
                  <button
                    type="button"
                    onClick={() => 1}
                    className="w-8 h-8 mr-2 flex justify-center items-center group rounded-md bg-[#8950FC] hover:bg-[#EAE0FF] transition-all duration-300"
                  >
                    {extension == "xlsx" ? (
                      <button
                        type="button"
                        onClick={() => onDownloadClick(id, name)}
                        className="w-6 h-6 flex justify-center items-center group rounded-md bg-black2  hover:bg-[#FFEAEF] transition-all duration-300 mr-2 last:mr-0"
                      >
                        <ViewIcon className="group-hover:fill-black2" />
                      </button>
                    ) : (
                      <button className="flex items-center justify-center" onClick={() => {
                        onViewDocument(cell.row.original.url,cell.row.original.type,getUser.token)
                      }
                      }
                      // target="_blank" href={url}
                      >
                        <ViewIcon
                          width={16}
                          height={16}
                          className={classNames(
                            "group-hover:fill-black2"
                          )}
                        />
                      </button>
                    )}
                  </button>
                  <button
                    type="button"
                    onClick={() => onDownloadClick(did, name)}
                    className="w-8 h-8 flex justify-center items-center group rounded-md bg-black2 hover:bg-white transition-all duration-300"
                  >
                    <a download href={row.original.filepath}>
                      <DownloadIcon className="group-hover:fill-black2" />
                    </a>
                  </button>
                </>
              ) : (
                <>---</>
              )}
            </div>
          );
        },
      }),
    ],
    [columnHelper, data]
  );

  if (manageDocument && id == 0) {
    columns = columnsPermis;
  } else {
    columns = columnsNot;
  }

  const [columnOrder, setColumnOrder] = useState<string[]>(
    columns.map((column: ColumnDef<any, unknown>) => column.id as string)
  );

  const visibleColumns: { [key: string]: boolean } = {};
  visibleColumns["filepath"] = false;

  // const hideColumns = visibleColumns;
  // const downloadDocumentByIDs = () => {
  //   var ids: number[] = [];
  //   const selectedRows = Object.keys(rowSelection);
  //   data?.map((x, index: number) => {
  //     if (selectedRows.includes(index.toString())) {
  //       ids.push(x.id);
  //     }
  //   })
  //   axios({
  //     url: endpointUrl + `downloadDocuments?ids=${ids.join(',')}`,
  //     method: "GET",
  //     responseType: "blob",
  //   }).then((response) => {
  //     const url = window.URL.createObjectURL(new Blob([response.data]));
  //     const link = document.createElement("a");
  //     link.href = url;
  //     console.log("url ", url)
  //     link.setAttribute("download", "documents.zip");
  //     document.body.appendChild(link);
  //     link.click();
  //   }).catch(httpErrorHandler)
  // }

  return (
    <div
      className={classNames(style.ProfileDocTable, "relative profileDocTable")}
    >
      <>
        <LayoutAdjustableTable
          hideColumn={visibleColumns}
          columns={columns}
          data={data}
          sorting={sorting}
          setSortingState={setSorting}
          rowSelection={rowSelection}
          setRowSelection={setRowSelection}
          columnOrder={columnOrder}
          setColumnOrder={setColumnOrder}
          unDraggableFields={["members", "selection"]}
          setItemCount={setItemCount}
          pagination={pagination}
          setPagination={setPagination}
          globalFilter={globalSearch}
          setGlobalFilter={setGlobalSearch}
          columnFilters={columnFilters}
          setColumnFilters={setColumnFilters}
          isTableView={isTableView}
          getsharedStateComponent={(rows) => <DocumentsGrid rows={rows} />}
        />
        {/* {selectionCount > 0 && (
          <div className="flex items-center pt-7">
            <p className="text-14 leading-5 text-black2 mr-5">
              {selectionCount} selected.
            </p>
            {
              Number(id) === 0 ? (
                <div className="flex items-center">
                  <button className="flex items-center text-12 leading-[18px] font-semibold text-black2" onClick={() => downloadDocumentByIDs()}>
                    <div className="w-6 h-6 flex justify-center items-center bg-black2 rounded-md mr-[10px]">
                      <DownloadIcon width={9} height={10} />
                    </div>
                    Download
                  </button>
                </div>
              ) : null
            }

          </div>
        )} */}
      </>

      <div className={`${selectionCount > 0 ? "pt-2" : "pt-2"}`}>
        <TablePaginator
          total={itemCount}
          pagination={pagination}
          setPagination={setPagination}
          itemCount={total}
          changePage={changePage}
          setCurrentPage={setCurrentPage}
          currentPageDefault={currentPage}
        />
      </div>
    </div>
  );
};

export default DocumentsTable;
