import {
  ColumnFiltersState,
  PaginationState,
  SortingState,
} from "@tanstack/react-table";
import classNames from "classnames";
import { useCallback, useEffect, useMemo, useState } from "react";
import ExportUsersModal from "../../components/BackendUsers/ExportUsersTable";
import ImportUsersModal from "../../components/BackendUsers/ImportUsersModal";
import Button from "../../components/common/Button";
import styles from "./EmployeeList.module.scss";
import jsPDF from "jspdf";
import JSZip from "jszip";
import autoTable from "jspdf-autotable";
import { NumberDropdownList } from "../../components/common/DropdownList";
import {
  AddIcon,
  CustomizeLayoutIcon,
  ExportIcon,
  ImportIcon,
  SearchIcon,
} from "../../components/common/Icons";
import DebounceSearchInput from "../../components/Tables/DebounceSearchInput";
import { baseObj } from "../../utils/constants";
import EmployeeTable, {
  EmployeeData,
  GenderData,
} from "../../components/EmployeeTable/EmployeeTable";
import XLSX from "sheetjs-style";
import FileSaver from "file-saver";
import {
  checkedIsNew,
  useDeleteEmployeeMutation,
  useDeleteEmpMutation,
  // useGetDesignationListQuery,
  useGetEmployeeDataQuery,
  useGetEmployeeFilterDataQuery,
} from "../../app/services/employee";
import { StringDropdownListWithDeleteBtn } from "../../components/common/StringDropdownListWithDeleteBtn";
//import { callback } from "chart.js/dist/helpers/helpers.core";
import { hideAllNoneField } from "../../components/Tables/TablePaginator";
import { getAuthUser } from "../../app/services/dashboard";
import { useGetByModuleTemplateQuery } from "../../app/services/template";
import { templateList } from "../../active-time-report/views/ActiveTimeReport";
import axios from "../../axios";
import { endpointUrl } from "../../app/services/api";
import ErrorPopup from "../../components/ErrorPopup/ErrorPopup";
import { useNavigate } from "react-router-dom";
import { FetchBaseError } from "../../components/common/FetchBaseQueryError";
import CustomScrollbar from "../../components/CustomScrollbar/CustomScrollbar";
import CustomDropdownListForFilter from "../../components/EmployeeTable/CustomDropdownListForFilter";
import { useSpring, animated } from "react-spring";
import CommonTableSkeleton from "../../components/TableSkeleton/CommonTableSkeleton";
import { toast } from "react-toastify";
import LeaveAllowancePopup from "../../components/LeaveAllowancePopup/LeaveAllowancePopup";
import {
  ILeaveAllowanceType,
  useGetLeaveAllowancesMutation,
  useSaveLeaveAllowanceMutation,
} from "../../app/services/leave-application";

// const statusListData = [
//   {
//     id: 0,
//     name: "All",
//     label: "All",
//   },
//   {
//     id: 1,
//     name: "Full Time",
//     label: "Full Time",
//   },
//   {
//     id: 2,
//     name: "Part Time",
//     label: "Part Time",
//   },
//   {

//     id: 3,
//     name: "Internship",
//     label: "Internship",
//   },
// ];
// const departmentData = [
//   {
//     id: 0,
//     name: "All",
//     label: "Project",
//   },
//   {
//     id: 1,
//     name: "Project",
//     label: "Project",
//   },
//   {
//     id: 2,
//     name: "Design",
//     label: "Design",
//   },
//   {
//     id: 3,
//     name: "Wordpress",
//     label: "Wordpress",
//   },
//   {
//     id: 4,
//     name: "Frontend",
//     label: "Frontend",
//   },
//   {
//     id: 5,
//     name: "Laravel",
//     label: "Laravel",
//   },
// ];
// const designationData = [
//   {
//     id: 0,
//     name: "All",
//     label: "Project",
//   },
//   {
//     id: 1,
//     name: "Graphic Designer",
//     label: "Graphic Designer",
//   },
//   {
//     id: 2,
//     name: "Project Coordinator",
//     label: "Project Coordinator",
//   },
//   {
//     id: 3,
//     name: "UI Designer",
//     label: "UI Designer",
//   },
//   {
//     id: 4,
//     name: "Web Developer",
//     label: "Web Developer",
//   },
//   {
//     id: 5,
//     name: "Frontend Developer",
//     label: "Frontend Developer",
//   },
//   {
//     id: 6,
//     name: "Backend Developer",
//     label: "Backend Developer",
//   },
// ];
// const nationalityListData = [
//   {
//     id: 0,
//     name: "All",
//     label: "All",
//   },
//   {
//     id: 1,
//     name: "Filipino",
//     label: "Filipino",
//   },
//   {
//     id: 2,
//     name: "Malaysia",
//     label: "Malaysia",
//   },
//   {
//     id: 3,
//     name: "Myanmar",
//     label: "Myanmar",
//   },
// ];
// const memberListData = [
//   {
//     id: 1,
//     name: "Sandy",
//     label: "Sandy",
//   },
//   {
//     id: 2,
//     name: "Adam",
//     label: "Adam",
//   },
//   {
//     id: 3,
//     name: "Aishi",
//     label: "Aishi",
//   },
//   {
//     id: 4,
//     name: "Sandy",
//     label: "Sandy",
//   },
//   {
//     id: 5,
//     name: "Adam",
//     label: "Adam",
//   },
//   {
//     id: 6,
//     name: "Aishi",
//     label: "Aishi",
//   },
// ];
// const genderListData = [
//   {
//     id: 1,
//     name: "Male",
//     label: "Male",
//   },
//   {
//     id: 2,
//     name: "Female",
//     label: "Female",
//   },
// ];

export const addNewEmployee = () => {
  return {
    uniqeID: 0,
    employeeID: "",
    name: "",
    preferredName: "",
    email: "",
    mobile: "",
    joinedDate: "",
    lastDate: "",
    years: "",
    department: "",
    designation: "",
    report_to: "",
    salary: "",
    dob: "",
    age: "",
    gender: "",
    race: "",
    religion: "",
    nationality: "",
    nationalityID: "",
    passportNo: "",
    currency_id: "",
    device: "",
    device_user_name: "",
    bank: "",
    bankAccNo: "",
    // leave_allowance: "",
    status: "",
    isNew: true,
  };
};

const tableHeader = [
  "Employee ID",
  "Name",
  "Email",
  "Mobile",
  "Joined Date",
  "Years w/VO",
  "Department",
  //"designation",
  //"reportTo",
  // "salary",
  //"dob",
  //"age",
  // "gender",
  // "race",
  // "religion",
  //"nationality",
  // "nationalityID",
  // "passwordNo",
  // "device",
  // "bank",
  // "bankAccNo",
  // "status",
];
export const saveFile = (data: Blob, filename: string) => {
  FileSaver.saveAs(data, filename);
};
export const exportDataSave = (
  dataList: any,
  filename: string,
  fileExt: any
) => {
  const fileExtension = "." + fileExt;
  var data = exportData(dataList, filename, fileExt);
  saveFile(data, filename + fileExtension);
};
export const exportData = (dataList: any, filename: string, fileExt: any) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  // const fileType="application/zip";
  //const fileExtension = "." + fileExt;
  const ws = XLSX.utils.json_to_sheet(dataList);
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };

  const excelBuffer = XLSX.write(wb, { bookType: fileExt, type: "array" });
  const data = new Blob([excelBuffer], { type: fileType });

  // FileSaver.saveAs(data, filename + fileExtension);
  return data;
};
export const exportToCSV = (dataList: any, filename: string) => {
  exportDataSave(dataList, filename, "csv");
};
export const exportToExcel = (dataList: any, filename: string) => {
  exportDataSave(dataList, filename, "xlsx");
};

export const exportToPDF = (data: any, titleText: string, fileName: string) => {
  const unit = "pt";
  const size = "A4";
  const orientation = "portrait";

  const marginLeft = 10;
  const doc = new jsPDF(orientation, unit, size);

  doc.setFontSize(16);
  //doc.addFont('Poppins', 'Poppins', 'normal');
  doc.setFont("helvetica");
  const title = titleText;
  const headers = [tableHeader];
  doc.text(title, marginLeft, 20);
  autoTable(doc, {
    margin: { top: 40, left: 0, right: 0 },
    head: headers,
    headStyles: {
      fontSize: 10,
      font: "helvetica",
    },
    body: data,
    bodyStyles: {
      fontSize: 10,
    },
  });

  doc.save(fileName);
};

export const createZip = (csvHeader: any, csvBody: any, fileName: string) => {
  var csvData = csvHeader.concat(csvBody);
  downloadAsZip({ fileName: fileName, csvData: csvData });
};

export const downloadAsZip = ({ fileName = "", csvData = "" }) => {
  const zip = new JSZip();
  zip.file(`${fileName}.csv`, csvData);

  zip.generateAsync({ type: "blob" }).then((blob) => {
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = `${fileName}.zip`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  });
};

const EmployeeList = () => {
  const navigate = useNavigate();
  const [employeeID, setEmployeeID] = useState<string>("");
  const [empStatus, setEmpStatus] = useState("All");
  const [department, setDepartment] = useState("All");
  const [nationality, setNationality] = useState("All");
  const [isImportModalOpen, setIsImportModalOpen] = useState<boolean>(false);
  const [isExportModalOpen, setIsExportModalOpen] = useState<boolean>(false);
  const [isImporting, setIsImporting] = useState<boolean>(false);
  const [isExporting, setIsExporting] = useState<boolean>(false);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const [data, setData] = useState<baseObj[]>([]);
  // console.log("data", data);
  const [currentView, setCurrentView] = useState<any>(0);
  const [currentViewData, setCurrentViewData] = useState<number>(0);
  const [isTemplate, setIsTemplate] = useState<boolean>(false);
  const [viewCols, setCols] = useState<any>([]);
  const defaultFields = useMemo(
    () => [
      "employeeID",
      "name",
      "preferredName",
      "email",
      "workEmail",
      "mobile",
      "joinedDate",
      "lastDate",
      "years",
      "department",
      "designation",
      "reportTo",
      "currency_id",
      "salary",
      "total_incentive",
      "hourly_rate",
      "dob",
      "age",
      "gender",
      "race",
      "religion",
      "nationality",
      "nationalityID",
      "passportNo",
      "device",
      "device_user_name",
      "is_agent_installed",
      "bank",
      "bankAccountNo",
      "leave_allowance",
      "status",
    ],
    []
  );
  const [leaveID, setLeaveID] = useState<number>(0);
  const [leaveAllowances, { isSuccess }] = useGetLeaveAllowancesMutation();
  const [showLeaveAllowance, setShowLeaveAllowance] = useState<boolean>(false);
  const [allowanceSuccess, setAllowance] = useState<boolean>(false);
  //const [templatePlaceholder, setTemplatePlaceholder] = useState<string>("---");
  const [globalSearch, setGlobalSearch] = useState<string>("");
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [isLayoutControlOpen, setIsLayoutControlOpen] =
    useState<boolean>(false);
  const [itemCount, setItemCount] = useState<number>(data?.length);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  });

  const [departData, setDepartData] = useState<EmployeeData[]>([]);
  // const [currencyData, setCurrencytData] = useState<EmployeeData[]>([]);
  // const [designData, setDesignData] = useState<EmployeeData[]>([]);
  const [memberData, setMemberData] = useState<EmployeeData[]>([]);
  const [genderData, setGenderData] = useState<EmployeeData[]>([]);
  const [nationalityData, setNationalityData] = useState<GenderData[]>([]);
  const [statusData, setStatusData] = useState<EmployeeData[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [isEditing, setIsEdit] = useState<boolean>(false);
  const [successPopup, setSuccessPopup] = useState<boolean>(false);
  const [errorPopup, setErrorPopup] = useState<boolean>(false);
  const [message, setMessage] = useState<any>([]);
  const [sorting, setSorting] = useState<SortingState>([]);
  // const [leaveSuccess, setLeaveSuccess] = useState<boolean>(false);

  // const [nationalityE, setNationalityE] = useState<number | string | undefined>(0);
  // const [limitE, setLimitE] = useState<number>(10);
  const [saveLeaveAllowance] = useSaveLeaveAllowanceMutation();
  const {
    data: empInfoData,
    isLoading,
    isFetching,
    error: employeeError,
  } = useGetEmployeeDataQuery({
    department: department,
    nationality: nationality,
    status: empStatus,
    limit: pagination.pageSize,
    page: currentPage,
    search: globalSearch,
    sort: sorting
      .map(
        (s) =>
          `${s.desc ? "-" : ""}${
            s.id == "employeeID"
              ? "code"
              : s.id == "preferredName"
              ? "preferred_name"
              : s.id == "workEmail"
              ? "work_email"
              : s.id == "joinedDate"
              ? "joined_date"
              : s.id
          }`
      )
      .join(","),
  });
  const { data: employeeFilterData } = useGetEmployeeFilterDataQuery();
  // const { data: empDesignData } = useGetDesignationListQuery();
  const [isEncrypt, setIsEncrypt] = useState<boolean>(true);
  const [tempList, setTempList] = useState<any>(templateList);
  // const [deleteTemplate] = useDeleteTemplateDataMutation();
  const [deleteEmployees] = useDeleteEmployeeMutation();
  const [deleteEmp] = useDeleteEmpMutation();
  const [hiddenFields, setHiddenFields] = useState<string[]>([]);
  // const { data: templateList } = useGetTemplateListQuery();
  let [localModuleId, setLocalModuleId] = useState<number>(0);
  const { data: templateData } = useGetByModuleTemplateQuery(localModuleId);

  const [moduleList, setModuleList] = useState<any>([]);

  const [leaveAllowanceList, setLeaveAllowanceList] = useState<any>([]);

  const [addNew, setAddNew] = useState<boolean>(false);
  const [checkExport, setCheckExport] = useState<boolean>(false);
  const [checkImport, setCheckImport] = useState<boolean>(false);
  const [checkCodeValue, setCheckCodeValue] = useState<boolean>(false);
  const [checkManage, setManage] = useState<boolean>(false);

  var getUser = getAuthUser();
  const getAuthPermis: string[] = useMemo(() => [], []);

  if (getUser?.permissions) {
    getUser?.permissions?.forEach((per: any) => {
      getAuthPermis.push(per?.name);
    });
  }

  const importEmployee = async (formSaveData: any) => {
    await axios(endpointUrl + "importEmployees", {
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${getUser.token}`,
      },
      data: formSaveData,
    })
      .then((res) => {
        setSuccessPopup(true);
        setMessage(["Successfully import."]);

        axios
          .get(
            `${endpointUrl}employeeListData?department=All&nationality=All&status=All&limit=20&page=1&search=`,
            {}
          )
          .then((result) => {
            var empUpdatedData = result.data;
            setData(empUpdatedData.employeeList?.data);
            setIsImporting(true);
            setIsImportModalOpen(false);
            toast("Successfully Import.", {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              type: "success",
            });
          });
      })
      .catch((error: any) => {
        setErrorPopup(true);
        setMessage(
          error?.response
            ? [error?.response?.data?.message]
            : ["Something wrong!. Trying again."]
        );
        setIsImportModalOpen(false);
      });
  };

  useEffect(() => {
    const filterManage = getAuthPermis.filter((f) => f === "Manage_Employee");
    const filterCreate = getAuthPermis.filter(
      (f) => f === "Create_New_Employee"
    );
    const filterExport = getAuthPermis.filter((f) => f === "Export_Employee");
    const filterImport = getAuthPermis.filter((f) => f === "Import_Employee");
    // const filterCodeValue = getAuthPermis.filter((f) => f === "View_Employee_Coded_Value");

    if (filterManage.length > 0) {
      setCheckCodeValue(true);
    }
    if (filterImport.length > 0) {
      setCheckImport(true);
    }
    if (filterExport.length > 0) {
      setCheckExport(true);
    }
    if (filterCreate.length > 0) {
      setAddNew(true);
    }

    if (filterManage.length > 0) {
      setManage(true);
    }
  }, [getUser, getAuthPermis]);

  useEffect(() => {
    if (empInfoData) {
      if (isFetching) {
        // setData([]);
      } else {
        setData(empInfoData?.employeeList?.data);
      }
      setItemCount(empInfoData?.employeeList?.total);
      setLocalModuleId(empInfoData.module_id);
    }

    hideAllNoneField();
  }, [department, nationality, isFetching, globalSearch]);
  useEffect(() => {
    if (employeeFilterData) {
      setDepartData([
        { name: "All", id: 0, label: "All" },
        ...employeeFilterData?.departmentData,
      ]);
      setMemberData(employeeFilterData.memberListData);
      var genderDataType: EmployeeData[] =
        employeeFilterData.genderListData.map((x: string, index: number) => {
          return {
            id: index + 1,
            name: x,
            label: x,
          };
        });
      setGenderData(genderDataType);

      setNationalityData([
        { name: "All", id: 0, label: "All" },
        ...employeeFilterData.nationalityListData,
      ]);
      const statusDataEmp = employeeFilterData?.statusListData?.map(
        (status: any, index: number) => {
          return {
            id: index + 1,
            name: status,
            label: status,
          };
        }
      );
      setStatusData(statusDataEmp ?? []);
      if (employeeFilterData?.statusListData?.length > 0) {
        var data = employeeFilterData?.statusListData?.map(
          (x: string, i: number) => {
            return { id: i + 1, name: x, label: x };
          }
        );
        setStatusData([{ name: "All", id: 0, label: "All" }, ...data]);
      }
    }
  }, [employeeFilterData]);
  useEffect(() => {
    if (templateData) {
      var updatedTemplate = templateData?.templates.map((x: any, i) => {
        return { ...x, index: i + 1 };
      });
      var defaultTemplate = {
        id: -1,
        index: 0,
        module: 0,
        name: "All",
      };
      setModuleList([defaultTemplate, ...updatedTemplate]);
    }
  }, [templateData?.templates, templateData]);

  useEffect(() => {
    if (templateList) {
      setTempList(templateList);
    }
    var viewData = templateData?.templates.find(
      (x) => x.id.toString() === currentView.toString()
    );
    setHiddenFields(viewData ? [...viewData.viewColumns] : defaultFields);

    document.querySelectorAll("th,td").forEach((x) => {
      if (x.id.includes("none")) {
        x.classList.add("hidden");
      }
      if (x.id.includes("action")) {
        x.classList.add("absolute");
        x.classList.add("right-0");
      }
      x.classList.remove("absolute");
      x.classList.add("left-0");
      x.classList.add("relative");
      var div = document.getElementById(x.id);
      if (div) {
        div.style.setProperty("left", "");
      }
    });
  }, [currentView, defaultFields]);

  useEffect(() => {
    if (viewCols?.view_columns?.length > 0) {
      setHiddenFields(viewCols?.view_columns);
    }
  }, [viewCols?.view_columns]);

  useEffect(() => {
    if (moduleList) {
      var lastesTemplate = moduleList[moduleList?.length - 1];
      setCurrentViewData(lastesTemplate ? Number(lastesTemplate?.id) : 0);
    }
  }, [moduleList]);

  const getAllowance = (id: number) => {
    leaveAllowances(id)
      .then((result: any) => {
        if (result.data.status) {
          setLeaveAllowanceList(result.data.data);
        }
      })
      .catch((error: any) => {
        setErrorPopup(true);
        setMessage(
          error?.message
            ? [error?.message]
            : ["Something wrong!. Trying again."]
        );
      });
  };
  // useEffect(() => {
  //   if (leaveAllowances?.data) {
  //     setLeaveAllowanceList(leaveAllowances?.data)
  //   }
  // }, [leaveAllowances])

  const downloadSampleFile = () => {
    axios({
      url: endpointUrl + "downloadEmployeeSample",
      method: "GET",
      responseType: "blob",
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "sampleEmployee.xlsx");
      document.body.appendChild(link);
      link.click();
      setIsDownloading(false);
    });
  };

  const exportEmployeeData = (format: string, isChecked: string) => {
    axios({
      url:
        endpointUrl +
        `employeeListData?limit=${pagination.pageSize}&department=${department}&page=${currentPage}&status=${empStatus}&nationality=${nationality}&export=true&only_this_page=${isChecked}&format=` +
        format +
        "",
      method: "GET",
      responseType: "blob",
      headers: {
        Authorization: `Bearer ${getUser.token}`,
      },
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          "employees." + (format === "excel" ? "xlsx" : format)
        );
        document.body.appendChild(link);
        link.click();
        setIsExporting(false);
        setIsExportModalOpen(false);
      })
      .catch((error: any) => {
        setErrorPopup(true);
        setMessage(
          error?.message
            ? [error?.message]
            : ["Something wrong!. Trying again."]
        );
        setIsImportModalOpen(false);
      });
  };

  const sampleImportCSV = () => {
    downloadSampleFile();
  };

  const showAllStarCoded = () => {
    setIsEncrypt(!isEncrypt);
    var empUpdatedData = data.map((x) => {
      return { ...x, isEncrypt: !isEncrypt };
    });
    setData(empUpdatedData);
  };

  const deleteTemplateData = (id: string) => {
    var filterTempList = templateList?.filter((x) => x.id !== parseInt(id));
    setTempList(filterTempList ? filterTempList : []);
    // deleteTemplate(parseInt(id));
  };

  const saveLeaveAllowanceData = (leaveAllowance: ILeaveAllowanceType[]) => {
    const data = {
      id: leaveID,
      data: { data: leaveAllowance },
    };
    // console.log("d", data);

    saveLeaveAllowance(data)
      .then((res: any) => {
        if (res?.data?.status) {
          setShowLeaveAllowance(false);
          setAllowance(true);
          toast("Successfully Updated.", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            type: "success",
          });

          setData((prev: baseObj[]) => {
            return prev.map((data, index: number) => {
              if (data.uniqeID == leaveID) {
                return { ...data, leave_allowance: res?.data?.leave_allowance };
              }
              return data;
            });
          });
        } else {
          setShowLeaveAllowance(true);
        }
      })
      .catch((error: any) => {
        // console.log("e", error)
      });
  };

  var disabledData = checkedIsNew(data);

  useEffect(() => {
    setCurrentPage(1);
  }, [globalSearch, empStatus, department, nationality, pagination.pageSize]);

  useEffect(() => {
    if (successPopup) {
      toast("Done Successfully.", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        type: "success",
      });
    }

    setTimeout(() => {
      setSuccessPopup(false);
    }, 2000);
  }, [successPopup]);

  const props = useSpring({
    to: { opacity: 1 },
    from: { opacity: 0 },
    delay: 1000,
  });

  if (employeeError) {
    if (FetchBaseError(employeeError)) {
      if (Number(employeeError?.status) === 400) {
        localStorage.removeItem("userData");
        navigate("/login");
      } else {
        setErrorPopup(true);
        setMessage(
          employeeError?.data?.message
            ? [employeeError?.data?.message]
            : ["You are something wrong. Pls login Again!"]
        );
      }
    }
  }

  return (
    <>
      {isLoading ? (
        <CommonTableSkeleton rows={12} />
      ) : (
        <>
          <animated.div style={props}>
            {leaveID !== 0 && isSuccess && (
              <LeaveAllowancePopup
                saveLeaveAllowanceData={saveLeaveAllowanceData}
                isOpen={showLeaveAllowance}
                setIsOpen={setShowLeaveAllowance}
                leaveAllowanceList={leaveAllowanceList}
                leaveAllowances={leaveAllowances}
                employeeID={employeeID}
              />
            )}

            <ImportUsersModal
              isOpen={isImportModalOpen}
              setIsOpen={setIsImportModalOpen}
              sampleCSV={sampleImportCSV}
              importEmployee={importEmployee}
              isImporting={isImporting}
              setIsImporting={setIsImporting}
              setIsDownloading={setIsDownloading}
              isDownloading={isDownloading}
            />

            <ExportUsersModal
              isOpen={isExportModalOpen}
              setIsOpen={setIsExportModalOpen}
              exportToCSV={exportEmployeeData}
              exportToExcel={exportEmployeeData}
              exportToPDF={exportEmployeeData}
              exportToZip={exportEmployeeData}
              isExporting={isExporting}
              setIsExporting={setIsExporting}
              isShowOnlyCheckbox={true}
            />

            <ErrorPopup
              descText="Error Message!"
              confirmBtnText="Yes, Close."
              show={errorPopup}
              setShow={setErrorPopup}
              message={message}
              setMessage={setMessage}
            />
            <div
              className={classNames(
                "px-2 sm:px-4 py-4 bg-white sm:mt-[25px] mt-0 font-poppins text-13 font-semibold text-[#858795] tracking-[-0.03rem] w-full h-full rounded-xl pt-3 pb-8 sm:pt-3 sm:pb-9 "
              )}
            >
              <div className="flex flex-wrap justify-between items-center">
                <div className="flex items-center mr-2 xs:mr-4 sm:w-[260px] w-[225px] md:mb-1">
                  <DebounceSearchInput
                    setState={setGlobalSearch}
                    debounce={800}
                  >
                    {(setState) => (
                      <div className="font-normal flex border-1 border-[#C4C4C4] bg-white hover:bg-[#EFEFEF] rounded-[28px]">
                        <input
                          type="text"
                          placeholder="Search"
                          className="focus:outline-none py-1 px-4 text-12 xl:text-14 leading-5 bg-transparent rounded-[28px] w-full text-graydark"
                          onChange={(e) => setState(e.target.value)}
                        />
                        <SearchIcon className="mr-2" />
                      </div>
                    )}
                  </DebounceSearchInput>
                </div>
                <div className="flex flex-wrap justify-end items-center">
                  {checkCodeValue ? (
                    <button
                      onClick={() => showAllStarCoded()}
                      className="my-1 sm:px-3 px-1 font-poppins text-vorpblue font-medium md:text-14 text-12 rounded-[5px] border border-white hover:border hover:border-vorpblue"
                    >
                      {isEncrypt ? "Show" : "Hide"} All coded
                    </button>
                  ) : null}
                  {checkImport ? (
                    <Button
                      label={
                        <span className="flex items-center">
                          <ImportIcon className="mr-[5px]" /> Import
                        </span>
                      }
                      onClick={() => {
                        setIsImportModalOpen(true);
                        setIsImporting(false);
                      }}
                      type="button"
                      variant="no-outline"
                      customClass="my-1 bg-transparent border-transparent hover:border-vorpblue hover:text-vorpblue transition-all duration-300 text-black2 xs:text-[12px] leading-[18px] font-medium py-1 px-[6px] sm:mr-[10px] mr-[5px]"
                      size="base"
                    />
                  ) : null}
                  {checkExport ? (
                    <Button
                      label={
                        <span className="flex items-center">
                          <ExportIcon className="mr-[5px]" /> Export
                        </span>
                      }
                      onClick={() => {
                        setIsExportModalOpen(true);
                        setIsExporting(false);
                      }}
                      type="button"
                      variant="no-outline"
                      customClass="my-1 bg-vorpmyanmar border-vorpmyanmar hover:bg-vorpblue hover:text-white transition-all duration-300 text-vorpblue xs:text-[12px] leading-[18px] font-medium py-1 px-[6px] sm:mr-[10px] mr-[5px]"
                      size="base"
                    />
                  ) : null}
                  {addNew ? (
                    <Button
                      disabled={disabledData?.disabled}
                      label="+ Add New"
                      onClick={() => {
                        setPagination((prev) => ({ ...prev, pageIndex: 0 }));
                        setData((prev) => [addNewEmployee(), ...prev]);
                      }}
                      type="button"
                      variant="primary"
                      customClass={
                        disabledData?.disabledClass +
                        " py-[3px] my-1 px-2 focus:outline-none hover:bg-vorpmyanmar hover:border-vorpmyanmar hover:text-vorpblue 2xl:text-[14px] text-12 leading-5 font-medium text-white"
                      }
                      size="base"
                    />
                  ) : null}
                </div>
              </div>

              <div className="flex sm:flex-row flex-col sm:justify-between justify-end items-center">
                <div className="flex w-full mt-1 md:mb-1 sm:mr-5 custom-scrollbar">
                  <CustomScrollbar isVertical={false} isHor={true}>
                    <div className="flex w-full items-center">
                      <div
                        className={classNames(
                          "flex items-center mr-[10px]  sm:w-auto w-full"
                        )}
                      >
                        <p className="hidden sm:w-auto w-1/2 text-left font-poppins font-medium xl:text-sm text-12 text-[#464E5F] mr-1">
                          Status
                        </p>
                        <CustomDropdownListForFilter
                          isFilter={true}
                          state={empStatus}
                          placeholder="Status"
                          setState={setEmpStatus}
                          dropdownItems={
                            statusData
                              ? statusData.map((st, id: number) => ({
                                  value: st.name,
                                  label: st.name,
                                }))
                              : []
                          }
                          textAlign="text-left"
                          className="max-h-[38px] max-w-full sm:max-w-[250px] 2xl:w-32 sm:w-28 w-full"
                        />
                      </div>
                      <div
                        className={classNames(
                          styles.permissionDropdown,
                          "flex items-center mr-[10px] sm:w-auto w-full"
                        )}
                      >
                        <p className="hidden sm:w-auto w-1/2 text-left font-poppins font-medium  xl:text-sm text-12 text-[#464E5F] mr-1">
                          Department
                        </p>
                        <CustomDropdownListForFilter
                          placeholder="Department"
                          state={department}
                          setState={setDepartment}
                          dropdownItems={
                            departData
                              ? departData.map((dp) => ({
                                  value: dp.id,
                                  label: dp.name,
                                }))
                              : []
                          }
                          textAlign="text-left"
                          className="max-h-[38px] max-w-full sm:max-w-[250px] 2xl:w-32 sm:w-28 w-full"
                        />
                      </div>
                      <div
                        className={classNames(
                          styles.permissionDropdown,
                          "flex items-center mr-[10px] sm:w-auto w-full"
                        )}
                      >
                        <p className="hidden sm:w-auto w-1/2 text-left font-poppins font-medium  xl:text-sm text-12 text-[#464E5F] mr-1">
                          Nationality
                        </p>
                        <CustomDropdownListForFilter
                          placeholder="Nationality"
                          state={nationality}
                          setState={setNationality}
                          dropdownItems={
                            nationalityData
                              ? nationalityData.map((nationD) => ({
                                  value: nationD.id,
                                  label: nationD.name,
                                }))
                              : []
                          }
                          textAlign="text-left"
                          // className="max-h-[38px] max-w-[78px] xs:max-w-[250px] 2xl:w-32 w-28"
                          className="max-h-[38px] max-w-full sm:max-w-[250px] 2xl:w-32 sm:w-28 w-full"
                        />
                      </div>
                    </div>
                  </CustomScrollbar>
                </div>
                <div className="flex justify-end mt-1 sm:w-auto w-full sm:flex-nowrap flex-wrap items-center">
                  <div className=" mb-1 flex items-center xl:ml-3 mr-[10px]">
                    <p className="base-label text-12 xl:text-14">DISPLAY</p>
                    <NumberDropdownList
                      state={pagination.pageSize}
                      setState={(value) =>
                        setPagination((prev) => ({
                          ...prev,
                          pageSize: value as number,
                        }))
                      }
                      dropdownItems={new Array(5)
                        .fill(1)
                        .map((_, index: number) => {
                          let display = (index + 1) * 20;
                          return {
                            value: display,
                            label: String(display),
                          };
                        })}
                      className="max-w-[55px] 4xs:max-w-[60px] lg:max-w-[72px]"
                      textAlign="text-left pl-3"
                      paddingClass="py-[2px]"
                      placeholder="20"
                    />
                  </div>

                  <div className="mb-1 flex justify-end">
                    <StringDropdownListWithDeleteBtn
                      haveDeleteBtn={true}
                      setIsTemplate={setIsTemplate}
                      state={isTemplate ? currentViewData : currentView}
                      setState={setCurrentView}
                      deleteTemplate={deleteTemplateData}
                      dropdownItems={moduleList.map((x: any, index: number) => {
                        return {
                          label: x.id === -1 ? x.name : "View #" + x.index,
                          value: x.id,
                        };
                      })}
                      className="min-w-[80px] 2xl:min-w-[128px] 6xs:text-12 2xl:w-32 w-24"
                      placeholder="View#1"
                      textAlign="text-left py-1 pl-2 pr-2"
                      custPadding="py-[2px]"
                      setSuccessPopup={setSuccessPopup}
                      setErrorPopup={setErrorPopup}
                      setMessage={setMessage}
                    />
                  </div>

                  <button
                    type="button"
                    onClick={() => setIsLayoutControlOpen(true)}
                    className="flex justify-center relative items-center rounded-lg bg-vorphongkongborder hover:bg-[#FFF0D2] w-[29px] h-[29px] ml-2 group transition-all duration-300 "
                  >
                    <CustomizeLayoutIcon />
                    <div className="absolute left-7 top-[-5px] border border-white w-4 h-4 rounded-full bg-vorphongkongborder flex justify-center items-center">
                      <AddIcon width={8} height={8} color="white" />
                    </div>
                  </button>
                </div>
              </div>
              <div className="text-left md:mt-0 mt-2">
                <animated.div style={props}>
                  <EmployeeTable
                    setCurrentView={setCurrentView}
                    data={data}
                    setData={setData}
                    pagination={pagination}
                    setPagination={setPagination}
                    isLayoutControlOpen={isLayoutControlOpen}
                    setIsLayoutControlOpen={setIsLayoutControlOpen}
                    globalSearch={globalSearch}
                    setGlobalSearch={setGlobalSearch}
                    columnFilters={columnFilters}
                    setColumnFilters={setColumnFilters}
                    statusData={statusData}
                    setStatusData={setStatusData}
                    // departmentData={departData}
                    // setDepartmentData={setDepartData}
                    //  currencyData={currencyData}
                    //  setCurrencytData={setCurrencytData}
                    // nationalityData={nationalityData}
                    // setNationalityData={setNationalityData}
                    // designationData={designData}
                    // setDesignationData={setDesignData}
                    memberData={memberData}
                    setMemberData={setMemberData}
                    genderData={genderData}
                    setGenderData={setGenderData}
                    // orgDataList={empInfoData ? empInfoData.employeeList : []}
                    orgDataList={[]}
                    deleteEmployee={deleteEmp}
                    deleteEmployeeByIDs={deleteEmployees}
                    hiddenFields={hiddenFields ? hiddenFields : []}
                    setHiddenFields={setHiddenFields}
                    templateList={tempList}
                    localModuleId={localModuleId}
                    total={itemCount}
                    // changePage={changePage}
                    setCurrentPage={setCurrentPage}
                    currentPage={currentPage}
                    setIsTemplate={setIsTemplate}
                    setCols={setCols}
                    checkManage={checkManage}
                    setIsEdit={setIsEdit}
                    isEditing={isEditing}
                    getAllowance={getAllowance}
                    setLeaveID={setLeaveID}
                    setShowLeaveAllowance={setShowLeaveAllowance}
                    allowanceSuccess={allowanceSuccess}
                    addNew={addNew}
                    setEmployeeID={setEmployeeID}
                    setLeaveAllowanceList={setLeaveAllowanceList}
                    sorting={sorting}
                    setSorting={setSorting}
                  />
                </animated.div>
              </div>
            </div>
          </animated.div>
        </>
      )}
    </>
  );
};
export default EmployeeList;
