import {
  ColumnDef,
  ColumnFiltersState,
  PaginationState,
  RowSelectionState,
  SortingState,
  createColumnHelper,
} from "@tanstack/react-table";
import React, {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import EditableCell from "../Tables/EditableCell";
import { baseObj } from "../../utils/constants";
import { renderDateFormatDMY, renderDateFormatYMD } from "../DashboardCalendarView/DashboardAddEventPopup";
import DatePopup from "../EmployeeTable/DatePopup";
import classNames from "classnames";
import {
  CheckIcon,
  CloseSmallIcon,
  EditIcon,
  TrashIcon,
} from "../common/Icons";
import styles from "./OTListTable.module.scss";
import LayoutAdjustableTable from "../Tables/LayoutAdjustableTable";
import { templateData } from "../../app/services/template";
import TablePaginator from "../Tables/TablePaginator";
import DeletePopup from "../DeletePopup/DeletePopup";
import { toast } from "react-toastify";
import {
  useApproveOTMutation,
  useDeleteOTListMutation,
  useDeleteOTMutation,
  useRejectOTMutation,
} from "../../app/services/otlist";
import ErrorPopup from "../ErrorPopup/ErrorPopup";
import { getAuthUser } from "../../app/services/dashboard";
import { useDispatch, useSelector } from "react-redux";
import { setProfileNotification } from "../../reducers/dashboard";
interface IOTTable {
  isLayoutControlOpen: boolean;
  setIsLayoutControlOpen: Dispatch<SetStateAction<boolean>>;
  pagination: PaginationState;
  setPagination: Dispatch<SetStateAction<PaginationState>>;
  globalSearch: string;
  setGlobalSearch: Dispatch<SetStateAction<string>>;
  columnFilters: ColumnFiltersState;
  setColumnFilters: Dispatch<SetStateAction<ColumnFiltersState>>;
  data: baseObj[];
  setData: Dispatch<SetStateAction<baseObj[]>>;
  hiddenFields: string[];
  templateList: templateData[];
  setHiddenFields: any;
  setCurrentView: any;
  total: number;
  changePage: any;
  setCurrentPage: any;
  currentPage: number;
  setIsEdit: Dispatch<SetStateAction<boolean>>;
  isEditing?: boolean;
  setIsTemplate?: Dispatch<SetStateAction<boolean>>;
  localModuleId: number;
  visArray: any;
  setCols?: any;
  setIsSuccess?: React.Dispatch<React.SetStateAction<boolean>>;
  isSuccess?: boolean;
  setEditOTData?: any;
  setSkip: React.Dispatch<React.SetStateAction<boolean>>;
  sorting: SortingState;
  setSorting: React.Dispatch<React.SetStateAction<SortingState>>;
}

const OTListTable: FC<IOTTable> = ({
  isLayoutControlOpen,
  setIsLayoutControlOpen,
  pagination,
  setPagination,
  globalSearch,
  setGlobalSearch,
  columnFilters,
  setColumnFilters,
  data,
  setData,
  hiddenFields,
  total,
  changePage,
  setCurrentPage,
  currentPage,
  setIsEdit,
  setIsTemplate,
  localModuleId,
  visArray,
  setCols,
  setEditOTData,
  setSkip,
  setSorting,
  sorting
}) => {
  const columnHelper = useMemo(() => createColumnHelper<any>(), []);
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  const [deleteID, setDeleteID] = useState<number | 0>();
  const [deleteRowID, setDeleteRowID] = useState<number | 0>();
  const [openDeletePopup, setOpenDeleteopup] = useState<boolean>(false);
  const [itemCount, setItemCount] = useState<number>(total);
  const [ownManage, setOwnManage] = useState<boolean>(false);
  const [manageOT, setManageOT] = useState<boolean>(false);
  const [rejectId, setRejectId] = useState<number>(0);
  const [isReject, setIsReject] = useState<boolean>(false);
  const [approveId, setApproveId] = useState<number>(0);
  const [isApprove, setIsApprove] = useState<boolean>(false);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const selectRowsCount = Object.keys(rowSelection).length;
  const [errorPopup, setErrorPopup] = useState<boolean>(false);
  const [message, setMessage] = useState<any>([]);
  const [deleteOneOT] = useDeleteOTMutation();
  const [deleteOTListByIDs] = useDeleteOTListMutation();
  const [rejectOT] = useRejectOTMutation();
  const [approveOT] = useApproveOTMutation();
  const dispatch = useDispatch();
  const stateData: any = useSelector((state: any) => state?.dashboard?.notificationDetail);
  const [notifications, setNotifications] = useState<any>(
    stateData
  );
  var getUser = getAuthUser();
  const getAuthPermis: string[] = useMemo(() => [], []);
  if (getUser?.permissions) {
    getUser?.permissions?.forEach((per: any) => {
      getAuthPermis.push(per?.name);
    });
  }

  const rejectOTData = () => {
    setSubmitLoading(true);
    rejectOT(rejectId)
      .then((res: any) => {
        setSubmitLoading(false);
        setIsReject(false);
        if (res.data) {
          setNotificationToState();
        }
        toast(`${res.data ? res.data.message : "Successfully Rejected."}`, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          type: "success",
        });
      })
      .catch((error: any) => {
        setSubmitLoading(true);
        setIsReject(false);
        setErrorPopup(true);
        setMessage(error ? [error?.data?.message] : ["Something went wrong!"]);
      });
  };

  const approveOTData = () => {
    setSubmitLoading(true);
    approveOT(approveId)
      .then((res: any) => {
        setSubmitLoading(false);
        setIsApprove(false);
        if (res.data) {
          setNotificationToState();
        }
        toast(`${res.data ? res.data.message : "Successfully Approved."}`, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          type: "success",
        });
      })
      .catch((error: any) => {
        setSubmitLoading(false);
        setIsApprove(false);
        setErrorPopup(true);
        setMessage(error ? [error?.data?.message] : ["Something went wrong!"]);
      });
  };

  const setNotificationToState = () => {
    var notiData = stateData//?.dashboard?.notificationDetail;
    var notiList = notiData.notifications;
    var data = {
      profile_unread_noti_count: notiData.profile_unread_noti_count,
      unapprove_claim_count: notiData.unapprove_claim_count,
      unapprove_leave_count: notiData.unapprove_leave_count,
      unapprove_ot_count: notiData?.unapprove_ot_count - 1,
      unread_count: notiData.unread_count,
      unread_data_count: {
        unread_document_count:
          notiData.unread_data_count?.unread_document_count,
        unread_leave_count: notiData.unread_data_count?.unread_leave_count,
        unread_password_count:
          notiData.unread_data_count?.unread_password_count,
        unread_claim_count: notiData?.unread_data_count?.unread_claim_count
      },
      notifications: notiList,
    };
    setNotifications(data);
    dispatch(setProfileNotification(data));
  };
  const deleteOTData = useCallback(
    (id?: any, uniqueID?: number) => {
      setDeleteID(id);
      setDeleteRowID(uniqueID);
      setOpenDeleteopup(true);
    },
    [setData, rowSelection]
  );
  const deleteOTItem = useCallback(
    (id?: number, dataId?: number) => {
      const selectedRows = Object.keys(rowSelection);
      setData((prev: baseObj[]) => {
        if (dataId != undefined) {
          deleteOneOT(dataId)
            .unwrap()
            .then((payload: any) => {
              setOpenDeleteopup(false);
              toast("Successfully Delete.", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                type: "success",
              });
            })
            .catch((error: any) => {
              setErrorPopup(true);
              setOpenDeleteopup(false);
              setMessage(
                error
                  ? [error?.data.message]
                  : ["Something went wrong!Trying again."]
              );
            });
        }
        return prev.filter((_, index) => {
          return id !== undefined
            ? id !== index
            : !selectedRows.includes(String(index));
        });
      });
      id === undefined && setRowSelection({});
    },
    [setData, rowSelection]
  );

  const deleteOTDataMultiple = (ids: number[]) => {
    deleteOTListByIDs(ids)
      .unwrap()
      .then((payload: any) => {
        setOpenDeleteopup(false);
        toast("Successfully Delete.", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          type: "success",
        });
      })
      .catch((error: any) => {
        setErrorPopup(true);
        setOpenDeleteopup(false);
        setMessage(
          error ? [error?.data.message] : ["Something went wrong!Trying again."]
        );
      });
    setRowSelection({});
  };

  const confirmDeleteData = () => {
    var id = deleteID;
    var dataID = deleteRowID;
    const selectedRows = Object.keys(rowSelection);
    var selectionIds = data
      .filter((x, index: number) => {
        return selectedRows.find((s) => s == index.toString()) ? x.id : 0;
      })
      .map((x) => x.id);

    if (selectionIds.length > 0) {
      deleteOTDataMultiple(selectionIds);
    } else {
      if (id) {
        deleteOTItem(id, dataID);
        setDeleteID(-1);
        setDeleteRowID(-1);
      }
      setOpenDeleteopup(false);
    }
  };

  const columns = useMemo(
    () => [
      columnHelper.display({
        header: "",
        id: "selection",
      }),
      columnHelper.accessor("code", {
        header: "#",
        id: "id",
        cell: ({ getValue, cell }) => {
          const id = getValue();
          return (
            <div className=" min-w-[140px] text-left bg-white text-black2 px-[10px] font-normal">
              <p className="h-full flex items-center">{id}</p>
            </div>
          );
        },
      }),
      columnHelper.accessor("employee_code", {
        header: "Employee ID",
        id: "employeeID",
        cell: ({ getValue, cell }) => {
          const employeeID = getValue();
          return (
            <div className=" min-w-[140px] text-left bg-white text-black2 px-[10px] font-normal">
              <p className="h-full flex items-center">{employeeID}</p>
            </div>
          );
        },
      }),
      columnHelper.accessor("employee_name", {
        header: "Employee  Name",
        id: "employeeName",
        cell: ({ getValue, cell }) => {
          const name = getValue();
          return (
            <div className=" min-w-[140px] text-left font-normal">
              <EditableCell
                setData={setData}
                value={name}
                type="text"
                cell={cell}
                customDesign="tooltip"
              />
            </div>
          );
        },
      }),
      columnHelper.accessor("department_name", {
        header: "Department",
        id: "department",
        cell: ({ getValue, cell }) => {
          const name = getValue();
          return (
            <div className=" min-w-[140px] text-left font-normal">
              <EditableCell
                setData={setData}
                value={name}
                type="text"
                cell={cell}
                customDesign="tooltip"
              />
            </div>
          );
        },
      }),
      columnHelper.accessor("from_date", {
        header: "Date(from)",
        id: "from_date",
        cell: ({ getValue, cell }) => {
          const joinedDate = getValue();
          var joinDate = new Date();
          if (joinedDate) {
            joinDate = new Date(joinedDate);
          }
          //  var formatDate = renderDateFormatYMD(joinDate, "-");

          return (
            <div className=" min-w-[180px] pr-1 flex justify-between items-center text-left font-normal">
              {cell?.row?.original.isNew == true ? <EditableCell
                setData={setData}
                value={cell.row.original?.isNew ? joinedDate : joinedDate}
                type="custom"
                cell={cell}
                CustomComponent={
                  <DatePopup
                    dobDate={joinDate}
                    setDate={() => 1}
                    cell={cell}
                    data={data}
                    setData={setData}
                  />
                }
              /> : <div className="flex items-center">{renderDateFormatDMY(joinDate, '-')}</div>}

            </div>
          );
        },
      }),
      columnHelper.accessor("to_date", {
        header: "Date(to)",
        id: "to_date",
        cell: ({ getValue, cell }) => {
          const joinedDate = getValue();
          var joinDate = new Date();
          if (joinedDate) {
            joinDate = new Date(joinedDate);
          }
          // var formatDate = renderDateFormatYMD(joinDate, "-");

          return (
            <div className=" min-w-[180px] pr-1 flex justify-between items-center text-left font-normal">
              {cell?.row?.original.isNew ? <EditableCell
                setData={setData}
                value={cell.row.original?.isNew ? joinedDate : joinedDate}
                type="custom"
                cell={cell}
                CustomComponent={
                  <DatePopup
                    dobDate={joinDate}
                    setDate={() => 1}
                    cell={cell}
                    data={data}
                    setData={setData}
                  />
                }
              /> : <div className="flex items-center">{renderDateFormatDMY(joinDate, '-')}</div>}

            </div>
          );
        },
      }),
      columnHelper.accessor("created_at", {
        header: "Applied At",
        id: "created_at",
        cell: ({ getValue, cell }) => {
          const created_at = getValue();
          return (
            <div className=" min-w-[180px] pr-1 flex justify-between items-center text-left font-normal">
             <div className="flex items-center">{created_at}</div>

            </div>
          );
        },
      }),
      columnHelper.accessor("period", {
        header: "Full / Half day",
        id: "fullHalfDay",
        cell: ({ getValue, cell }) => {
          const year = getValue();
          return (
            <div className=" min-w-[100px] text-left font-normal">
              <EditableCell
                setData={setData}
                value={year}
                IsDisabled={true}
                type="text"
                cell={cell}
                isYear={true}
              />
            </div>
          );
        },
      }),

      columnHelper.accessor("current_status", {
        header: "Status",
        id: "current_status",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const status = getValue();
          let txtColor = "text-graydark";
          if (status == "Rejected") {
            txtColor = "text-redPink";
          } else if (status == "Approved") {
            txtColor = "text-vorpgreen";
          }
          return (
            <div className={`min-w-[220px] text-left font-normal ${txtColor}`}>
              <EditableCell
                setData={setData}
                value={status}
                IsDisabled={true}
                type="text"
                cell={cell}
                isYear={true}
              />
            </div>
          );
        },
      }),
      columnHelper.accessor("type", {
        header: "Type",
        id: "type",
        cell: ({ getValue, cell }) => {
          const year = getValue();
          return (
            <div className=" min-w-[220px] text-left font-normal">
              <EditableCell
                setData={setData}
                value={year}
                IsDisabled={true}
                type="text"
                cell={cell}
                isYear={true}
              />
            </div>
          );
        },
      }),

      columnHelper.accessor("total_days", {
        header: "Total Days",
        id: "total_days",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const year = getValue();
          return (
            <div className=" min-w-[100px] text-left font-normal">
              <EditableCell
                setData={setData}
                value={year}
                IsDisabled={true}
                type="text"
                cell={cell}
                isYear={true}
              />
            </div>
          );
        },
      }),

      columnHelper.accessor("reason", {
        header: "Reason",
        id: "reason",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const year = getValue();
          return (
            <div className="max-w-[260px] min-w-[240px] text-left font-normal">
              <EditableCell
                setData={setData}
                value={year}
                IsDisabled={true}
                type="text"
                cell={cell}
                isYear={true}
              />
            </div>
          );
        },
      }),
      columnHelper.accessor("created_user", {
        header: "Created By",
        id: "created_user",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const created_user = getValue();
          return (
            <div className=" min-w-[140px] text-left font-normal flex items-center px-2">
              <span>{created_user}</span>
            </div>
          );
        },
      }),

      // columnHelper.accessor("time_in", {
      //   header: "Time In",
      //   id: "timeIn",
      //   cell: ({ getValue, cell }) => {
      //     const timeIn = getValue();
      //     return (
      //       <div className=" min-w-[110px] text-left font-normal">
      //         <EditableCell
      //           setData={setData}
      //           value={timeIn}
      //           type="text"
      //           cell={cell}
      //         />
      //       </div>
      //     );
      //   },
      // }),
      // columnHelper.accessor("time_out", {
      //   header: "Time Out",
      //   id: "timeOut",
      //   cell: ({ getValue, cell }) => {
      //     const timeOUt = getValue();
      //     return (
      //       <div className=" min-w-[110px] text-left font-normal">
      //         <EditableCell
      //           setData={setData}
      //           value={timeOUt}
      //           type="text"
      //           cell={cell}
      //         />
      //       </div>
      //     );
      //   },
      // }),
      // columnHelper.accessor("total_time", {
      //   header: "OT Amount",
      //   id: "otAmt",
      //   cell: ({ getValue, cell }) => {
      //     const otAmt = getValue();
      //     return (
      //       <div className=" font-normal  min-w-[100px] text-left px-[10px] bg-white text-black2">
      //         <p className="h-full flex items-center">{otAmt ?? "---"}</p>
      //       </div>
      //     );
      //   },
      // }),
      columnHelper.display({
        header: "Action",
        id: "actions",
        cell: ({ cell }) => {
          var isHaveNew = data?.find((x) => x.isNew == true);
          var disabledClass = isHaveNew ? "opacity-50 pointer-events-none" : "";
          const approveOption: boolean = cell?.row?.original?.approve_option;
          const rejectOption: boolean = cell?.row?.original?.reject_option;
          const editOption: boolean = cell?.row?.original?.edit_option;
          const deleteOption: boolean = cell?.row?.original?.delete_option;

          return (
            <div
              className={classNames(
                styles.actionedBtn,
                "px-2 flex justify-center items-center min-w-max max-w-[98px]"
              )}
            >
              {ownManage || manageOT ? (
                <>
                  {!approveOption &&
                    !rejectOption &&
                    !editOption &&
                    !deleteOption ? (
                    <span className="flex justify-center items-center">
                      ---
                    </span>
                  ) : null}

                  {approveOption ? (
                    <button
                      type="button"
                      onClick={() => {
                        setApproveId(cell.row.original.id);
                        setIsApprove(true);
                      }}
                      className={classNames(
                        styles.customSharedBtn,
                        "w-8 h-8 max-w-[24px] mr-1 flex justify-center items-center group rounded-md bg-vorpphillipines hover:bg-turqouiseLight transition-all duration-300"
                      )}
                    >
                      <CheckIcon width={16} height={16} />
                    </button>
                  ) : null}
                  {rejectOption ? (
                    <button
                      type="button"
                      onClick={() => {
                        setRejectId(cell.row.original.id);
                        setIsReject(true);
                      }}
                      className={classNames(
                        styles.customCloseIcon,
                        "w-8 h-8 max-w-[24px] mr-2 ml-1 flex justify-center items-center group rounded-md bg-redPink hover:bg-[#FFEAEF] transition-all duration-300"
                      )}
                    >
                      <CloseSmallIcon width={16} height={16} />
                    </button>
                  ) : null}

                  {editOption ? (
                    <button
                      disabled={isHaveNew ? true : false}
                      type="button"
                      onClick={() => {
                        setSkip(true);
                        if (setIsEdit) {
                          setEditOTData(cell.row.original);
                          setIsEdit(true);
                        }
                      }}
                      className={classNames(
                        disabledClass,
                        " w-6 h-6 mr-2 flex justify-center items-center group rounded-md bg-vorpblue hover:bg-annhoverbg transition-all duration-300"
                      )}
                    >
                      <EditIcon />
                    </button>
                  ) : null}
                  {deleteOption ? (
                    <button
                      type="button"
                      onClick={() => {
                        deleteOTData(cell.row.id, cell.row.original.id);
                      }}
                      className={classNames(
                        disabledClass,
                        " w-6 h-6 flex justify-center items-center group rounded-md bg-redPink hover:bg-[#FFEAEF] transition-all duration-300"
                      )}
                    >
                      <TrashIcon />
                    </button>
                  ) : null}
                </>
              ) : null}
            </div>
          );
        },
      }),
    ],
    [columnHelper, data, hiddenFields]
  );

  const visibleColumns: { [key: string]: boolean } = {};
  hiddenFields?.map((x) => {
    visibleColumns[x] = true;
  });

  document.querySelectorAll("th,td").forEach((x) => {
    if (x.id.includes("none")) {
      x.classList.add("hidden");
    }

    var div = document.getElementById(x.id);
  });

  if (manageOT || ownManage) {
    visibleColumns["actions"] = true;
  } else {
    visibleColumns["actions"] = false;
  }

  if (hiddenFields.length > 0) {
    columns.map((x) => {
      if (x.id == "actions" || x.id == "selection" || x.id == "lasted") {
        visibleColumns[x.id ? x.id : ""] = true;
      } else if (x.id == "uniqeID") {
        visibleColumns[x.id ? x.id : ""] = false;
      } else {
        var isHidden = hiddenFields.find((h) => h == x.id);
        if (isHidden) {
          visibleColumns[x.id ? x.id : ""] = true;
        } else {
          visibleColumns[x.id ? x.id : ""] = false;
        }
      }
    });
  }

  const [columnOrder, setColumnOrder] = useState<string[]>(
    columns.map((column: ColumnDef<any, any>) => column.id as string)
  );

  useEffect(() => {
    const ownManageOT = getAuthPermis.filter((f) => f === "Manage_Own_OT_List");
    const filterManage = getAuthPermis.filter(
      (f) => f === "Manage_All_OT_List"
    );

    if (ownManageOT.length > 0) {
      setOwnManage(true);
    }
    if (filterManage.length > 0) {
      setManageOT(true);
    }
  }, [getUser, getAuthPermis]);

  return (
    <div className={classNames(styles.otListTable, "relative")}>
      <ErrorPopup
        descText="Error Message!"
        confirmBtnText="Yes, Close."
        show={errorPopup}
        setShow={setErrorPopup}
        message={message}
        setMessage={setMessage}
      />

      <DeletePopup
        loading={submitLoading}
        descText="Are you sure to Rejected?"
        confirmBtnText="Yes"
        show={isReject}
        setShow={setIsReject}
        confirmDeleteData={rejectOTData}
      />
      <DeletePopup
        loading={submitLoading}
        confirmBtnColor="bg-vorpgreen hover:bg-vorpgreen hover:opacity-50 opacity-100"
        descText="Are you sure to Approved?"
        confirmBtnText="Yes"
        show={isApprove}
        setShow={setIsApprove}
        confirmDeleteData={approveOTData}
      />

      <LayoutAdjustableTable
        hideColumn={visibleColumns}
        columns={columns}
        data={data}
        sorting={sorting}
        setSortingState={setSorting}
        rowSelection={rowSelection}
        setRowSelection={setRowSelection}
        columnOrder={columnOrder}
        setColumnOrder={setColumnOrder}
        unDraggableFields={["actions", "selection", "id"]}
        theaderColor="text-graydark"
        isLayoutControlOpen={isLayoutControlOpen}
        setIsLayoutControlOpen={setIsLayoutControlOpen}
        label="OT List"
        setItemCount={setItemCount}
        pagination={pagination}
        setPagination={setPagination}
        globalFilter={globalSearch}
        setGlobalFilter={setGlobalSearch}
        columnFilters={columnFilters}
        setColumnFilters={setColumnFilters}
        noPaddingCells={[]}
        localModuleId={localModuleId}
        visArray={visArray}
        setIsTemplate={setIsTemplate}
        setCols={setCols}
      />
      {selectRowsCount > 0 && (
        <div className="flex items-center text-14 leading-5 font-semibold text-black2 mt-3 mb-3 lg:mt-3 lg:mb-3 ">
          <p>{selectRowsCount} selected.</p>
          <button
            type="button"
            onClick={() => setOpenDeleteopup(true)}
            className=" flex items-center ml-5 text-12 leading-[18px] font-semibold text-redPink"
          >
            <div className="w-6 h-6 bg-redPink flex items-center group justify-center rounded-md mr-[10px] hover:bg-[#FFEAEF]  transition-all duration-300 ">
              <TrashIcon width={10} height={12} />
            </div>
            Delete
          </button>
        </div>
      )}
      <div className="pt-2">
        <TablePaginator
          total={itemCount}
          pagination={pagination}
          setPagination={setPagination}
          itemCount={total}
          setCurrentPage={setCurrentPage}
          currentPageDefault={currentPage}
          changePage={changePage}
        />
      </div>
      <DeletePopup
        descText="Are you sure to delete?"
        confirmBtnText="Yes, delete."
        show={openDeletePopup}
        setShow={setOpenDeleteopup}
        confirmDeleteData={confirmDeleteData}
      />
    </div>
  );
};
export default OTListTable;
