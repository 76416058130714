import { FC, useState } from "react";
import { copyToClipboard } from "../../../utils/helpers";
import { CopyClipIcon } from "../../common/Icons";
import classNames from "classnames";

interface ICopyToClipboardBtn {
  value: string;
  isAccount?: boolean;
  color?: string;
}

const CopyToClipboardBtn: FC<ICopyToClipboardBtn> = ({
  value,
  isAccount,
}) => {
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  return (
    <div className="relative">
      <span
        className={classNames(
          isAccount ?  'text-green-600' : "text-gray-500" ,
          "absolute right-10 text-12 bg-green-200 py-2 px-3 rounded-md font-semibold",
          {
            hidden: !showTooltip,
          }
        )}
      >
        Copied
      </span>
      <button
        type="button"
        className="ml-3"
        onClick={() => {
          setShowTooltip(true);
          copyToClipboard(value);
          setTimeout(() => setShowTooltip(false), 1000);
        }}
      >
        <CopyClipIcon />
      </button>
    </div>
  );
};

export default CopyToClipboardBtn;
