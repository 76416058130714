import { FC, Dispatch, useState, SetStateAction, useEffect } from "react";
import { CloseIcon } from "../../common/Icons";
import { baseObj } from "../../../utils/constants";
import { useDeleteQuotationNoMutation } from "../../../app/services/project";
import classNames from "classnames";

interface IQuotationNumModal {
  closeModal: () => void;
  setData: Dispatch<SetStateAction<baseObj[]>>;
  rowId: number;
  keyname: string;
  addNewItemData?: any;
  addQuotationData?: any;
  data?: baseObj[];
  removeQuotation: any;
}

const QuotationNumAddModal: FC<IQuotationNumModal> = ({ closeModal, setData, rowId, keyname, addNewItemData, addQuotationData, data, removeQuotation }) => {
  const [values, setValues] = useState<any | undefined>("");
  const [quotations, setQuotations] = useState<string>('');
  const [deleteQuotation] = useDeleteQuotationNoMutation();
  // const saveHandler = () => {
  //   console.log("first", values)
  //   setData((prev) =>
  //     prev.map((row) => {
  //       if (row.uniqeID === rowId) {
  //         return { ...row, [keyname]: values };
  //       } else {
  //         return row;
  //       }
  //     })
  //   );
  //   // setIsEdit(false);
  //   closeModal()
  // };

  const addNewItem = () => {
    setData((prev) =>
      prev.map((row) => {
        if (row.uniqeID === rowId) {
          return { ...row, [keyname]: values };
        } else {
          return row;
        }
      })
    );

    if (addNewItemData) {
      addNewItemData(values);
    }
    if (addQuotationData) {
      addQuotationData(rowId, values);
      closeModal();
    }
  }
  const removeQuotationData = (quot: string) => {
    setData((prev) =>
      prev.map((row) => {
        if (row.uniqeID === rowId) {
          var orgQuo=row.quotationNumber.split(',');
          var filter=orgQuo?.filter((x:any)=>x!=quot).join(',');
          removeQuotation({
            project_id: row.uniqeID,
            quotationNo: quot
          })
          setQuotations(filter)
          return { ...row, quotationNumber: filter };
        } else {
          return row;
        }
      })
    );
  }
  useEffect(() => {
    var quotationList = data?.find(x => x.uniqeID === rowId)?.quotationNumber;
    setQuotations(quotationList)
  }, [data])
  return (
    <div className="6xs:max-w-[320px] px-4 5xs:px-0 w-full m-auto focus:outline-none">
      <div className="bg-white rounded-lg w-full 5xs:w-[320px] h-[auto] left-0 right-0 mx-auto relative p-5">
        <button
          type="button"
          onClick={closeModal}
          className="absolute right-4 top-4 w-[10px] h-[10px] z-10"
        >
          <CloseIcon width={12} height={12} color="#C4C4C4" />
        </button>
        <div className="flex flex-wrap my-5">
          {quotations?.split(',')?.map((quo: any, index: number) => {
            return quo ? <div key={index} className="flex justify-between mr-2 py-1 px-2 rounded-[6px] border-1 border-vorpblue">
              <button className="font-poppins text-12 mr-2">{quo}</button>
              <button onClick={() => removeQuotationData(quo)} className="cursor-pointer">&times;</button>
            </div> : ''
          })}
        </div>
        <h1 className="text-12 leading-[18px] font-semibold text-black2 mb-5">
          Add new Quotation No.
        </h1>
        <input
          value={values}
          onChange={(e) => setValues(e.target.value)}
          type="text"
          placeholder="Untitled"
          className="text-black2 placeholder-vorpgraylight py-2 px-3 w-full focus:outline-none border-2 border-vorpblue rounded-4 text-14 leading-5"
        />
        <div className="flex justify-end items-center pt-3">
          <button
            type="button"
            onClick={closeModal}
            className="py-2 px-4 flex justify-center items-center text-12 leading-[18px] font-semibold text-vorpgraylight bg-offwhite mr-2 rounded-md transition-all duration-300 hover:bg-[#C7C7C7] hover:text-white"
          >
            Cancel
          </button>
          <button disabled={values?false:true}
            onClick={addNewItem}
            type="button"
            className={classNames(values?'':'opacity-50',"py-2 px-4 flex justify-center items-center text-12 leading-[18px] font-semibold text-white bg-vorpblue rounded-md transition-all duration-300 hover:bg-annhoverbg hover:text-vorpblue")}
          >
            Create
          </button>
        </div>
      </div>
    </div>
  );
};

export default QuotationNumAddModal;
