import { FC, useEffect, useState, useMemo } from "react";
import Button from "../../components/common/Button";
import { baseObj, dummyFileTypes } from "../../utils/constants";
import { NumberDropdownList } from "../../components/common/DropdownList";
import { StringDropdownListWithDeleteBtn } from "../../components/common/StringDropdownListWithDeleteBtn";
import {
  AddIcon,
  CloseIcon,
  CustomizeLayoutIcon,
  DownloadAllIcon,
  GridLayoutIcon,
  HorizontalGridIcon,
  ImportIcon,
  SearchIcon,
} from "../../components/common/Icons";
import {
  ColumnFiltersState,
  PaginationState,
  RowSelectionState,
  SortingState,
} from "@tanstack/react-table";
import DebounceSearchInput from "../../components/Tables/DebounceSearchInput";
import EmployeeDocumentsTable from "../../components/Documents/EmployeeDocumentsTable";
import UploadModal from "../../components/UploadModal/UploadModal";
import {
  docUserData,
  getAuthUser,
  useGetDocumentTableDataQuery,
} from "../../app/services/dashboard";
import styles from "./EmployeeDocumentList.module.scss";

import {
  useAssignUserMutation,
  useAssignUserMultipleMutation,
  useDownloadDocMutation,
  useDeleteDocMutation,
  useDeleteDocMultipleMutation,
  useGetTagDataQuery,
  useAssignOwnerMutation,
  useAssignTagsMutation,
  useGetDocumentTableFilterDataQuery,
} from "../../app/services/documents";
import {
  checkedIsNew,
  useGetDepartmentListQuery,
} from "../../app/services/employee";
import { useGetByModuleTemplateQuery } from "../../app/services/template";
import { templateList } from "../../active-time-report/views/ActiveTimeReport";
import { endpointUrl } from "../../app/services/api";
import axios from "../../axios";
import CommonErrorPopup from "../../components/common/CommonErrorPopup";
import { useCreateTagMutation } from "../../app/services/annoucement-sheet";
import CustomDropdownListForFilter from "../../components/EmployeeTable/CustomDropdownListForFilter";
import DatePopupFoFilter from "../../components/EmployeeTable/DatePopupFoFilter";
import CustomScrollbar from "../../components/CustomScrollbar/CustomScrollbar";
import { useSpring, animated } from "react-spring";
import CommonTableSkeleton from "../../components/TableSkeleton/CommonTableSkeleton";
import { ToastContainer, toast } from "react-toastify";
import ErrorPopup from "../../components/ErrorPopup/ErrorPopup";
import { EmployeeData } from "../../components/EmployeeTable/EmployeeTable";
import {
  assignUsers,
  useGetUserListAssignQuery,
} from "../../app/services/password";

interface IDocuments { }

export interface IFilterDate {
  startDate?: Date | undefined;
  endDate?: Date | undefined;
}

const DocumentsList: FC<IDocuments> = () => {
  const [filterDate, setFilterDate] = useState<IFilterDate>();

  const [filterStates, setFilterStates] = useState<baseObj>({
    name: "All",
    tags: "All",
  });

  // For switching list view and grid view
  const [isTableView, setIsTableView] = useState<boolean>(true);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  });
  const [globalSearch, setGlobalSearch] = useState<string>("");
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [currentView, setCurrentView] = useState<any>();
  const [isLayoutControlOpen, setIsLayoutControlOpen] =
    useState<boolean>(false);

  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  const [sorting, setSorting] = useState<SortingState>([]);
  const [data, setData] = useState<baseObj[]>([]);
  const [userList, setUserList] = useState<docUserData[]>([]);
  const [tags, setTags] = useState<any>([]);
  const [tagsDataList, setTagsData] = useState<any>([]);
  const [itemCount, setItemCount] = useState<number>(data?.length);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [importing, setImporting] = useState<boolean>(false);
  const [isDataClean, setIsDataClean] = useState<boolean>(false);
  const [tempList, setTempList] = useState<any>([]);
  const [hiddenFields, setHiddenFields] = useState<string[]>([]);
  //const [templatePlaceholder, setTemplatePlaceholder] = useState<string>("---");
  const [currentPage, setCurrentPage] = useState<number>(1);
  //const [deleteTemplate] = useDeleteDocTemplateDataMutation();
  const [assignedUsers] = useAssignUserMutation();
  const [deleteDoc] = useDeleteDocMutation();
  const [deleteDocs] = useDeleteDocMultipleMutation();
  const [assignedUsersMultiple] = useAssignUserMultipleMutation();
  const [downloadDoc] = useDownloadDocMutation();
  const [assignOwner] = useAssignOwnerMutation();
  const [assignTags] = useAssignTagsMutation();
  const { data: tagData } = useGetTagDataQuery();

  const [assignDepartmentId, setAssignDepartmentId] = useState<string>("all");
  const [assignKeword, setAssignKeword] = useState<string>("");

  const { data: departments } = useGetDepartmentListQuery();
  const { data: assignUserList } = useGetUserListAssignQuery({
    search: assignKeword,
    department_id: assignDepartmentId,
  });

  const [departmentList, setDepartmentList] = useState<EmployeeData[]>([]);
  const [assignUserListData, setAssignUserListData] = useState<assignUsers[]>(
    []
  );

  let [localModuleId, setLocalModuleId] = useState<number>(0);
  const { data: templateData } = useGetByModuleTemplateQuery(localModuleId);
  const [moduleList, setModuleList] = useState<any>([]);
  const [viewCols, setCols] = useState<any>([]);
  const [isTemplate, setIsTemplate] = useState<boolean>(false);
  const [currentViewData, setCurrentViewData] = useState<number>(0);
  const [successPopup, setSuccessPopup] = useState<boolean>(false);
  const [errorPopup, setErrorPopup] = useState<boolean>(false);
  const [message, setMessage] = useState<any>([]);
  const [pathArray, setPathArray] = useState<MediaList[]>([]);
  const [error, setError] = useState<string>("");
  const [isEditing, setIsEdit] = useState<boolean>(false);
  const [isSuccessSave, setIsSuccessSave] = useState<boolean>(false);
  //const [isShow, setIsShow] = useState<boolean>(false);
  const [createTag] = useCreateTagMutation();
  const {
    data: DocumentTableData,
    isLoading,
    isFetching,
  } = useGetDocumentTableDataQuery({
    start: filterDate?.startDate?.toDateString(),
    end: filterDate?.endDate?.toDateString(),
    limit: pagination.pageSize,
    search: globalSearch,
    type: filterStates?.name,
    tag: filterStates?.tags,
    page: currentPage,
    id: 0,
    sort: sorting.map((s) => `${s.desc ? '-' : ''}${s.id == "lastModifiedAt" ? "last_modified_at" : s.id == "owner" ? "owner_name" : s.id == "postedBy" ? "posted_user" : s.id}`).join(','),
  });
  const {data:docFilterData}=useGetDocumentTableFilterDataQuery();
  const [managementDocument, setManagementDocument] = useState<boolean>(false);

  var getUser = getAuthUser();
  const getAuthPermis: string[] = useMemo(() => [], []);

  if (getUser?.permissions) {
    getUser?.permissions?.forEach((per: any) => {
      getAuthPermis.push(per?.name);
    });
  }

  useEffect(() => {
    if (assignUserList) {
      setAssignUserListData(assignUserList.users);
      setUserList(assignUserList.users)
    }
    if (departments) {
      setDepartmentList([
        { name: "All Departments", id: 0, label: "All Departments" },
        ...departments,
      ]);
    }
  }, [assignUserList, departments]);

  useEffect(() => {
    const managementDocument = getAuthPermis.filter(
      (f) => f === "Manage_Document"
    );
    if (managementDocument.length > 0) {
      setManagementDocument(true);
    }
  }, [getUser, getAuthPermis]);

  useEffect(() => {
    // let filterParams = Object.entries(filterStates).map(([key, value]) => ({
    //   id: key,
    //   value: value == "All" ? "" : value,
    // }));
    // setColumnFilters(filterParams);
  }, [filterStates]);

  useEffect(() => {
    if (filterDate?.startDate && filterDate?.endDate) {
      setColumnFilters((prev) => [
        ...prev,
        {
          id: "lastModifiedAt",
          value: filterDate,
        },
      ]);
    } else {
      setColumnFilters((prev) => [
        ...prev,
        {
          id: "lastModifiedAt",
          value: {},
        },
      ]);
    }
  }, [filterDate]);

  // const base64Toblob = (b64Data: string) => {
  //   var byteCharacters = atob(b64Data);
  //   var byteArrays = [];

  //   var sliceSize = 512;
  //   for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
  //     var slice = byteCharacters.slice(offset, offset + sliceSize);

  //     var byteNumbers = new Array(slice.length);
  //     for (var i = 0; i < slice.length; i++) {
  //       byteNumbers[i] = slice.charCodeAt(i);
  //     }

  //     var byteArray = new Uint8Array(byteNumbers);

  //     byteArrays.push(byteArray);
  //   }

  //   var blob = new Blob(byteArrays, { type: "image/png" });
  //   return blob;
  // };


  useEffect(() => {
    if (DocumentTableData) {
      if (DocumentTableData?.documents?.data.length > 0) {
        if (isFetching) {
          setData([]);
        } else {
          setData(DocumentTableData?.documents?.data);
        }
      } else {
        setData([]);
      }
      // setUserList(DocumentTableData?.userList);
      setLocalModuleId(DocumentTableData?.module_id);
      
      setItemCount(DocumentTableData?.documents?.total);
    }
  }, [DocumentTableData]);
  useEffect(()=>{
    if(docFilterData){
      setPathArray(docFilterData?.folderList);
    }
  },[docFilterData])

  useEffect(() => {
    if (tagData) {
      setTags([
        { name: "All", id: 0, label: "All" },
        ...tagData?.tagsData?.filter(
          (x) => x.flag === "doc" || x.flag === null
        ),
      ]);
      setTagsData(
        tagData?.tagsData?.filter((x) => x.flag === "doc" || x.flag === null)
      );
    }
  }, [tagData]);

  useEffect(() => {
    if (templateData) {
      var updatedTemplate = templateData?.templates.map((x: any, i) => {
        return { ...x, index: i + 1 };
      });
      var defaultTemplate = {
        id: -1,
        index: 0,
        module: 0,
        name: "All",
      };
      setModuleList([defaultTemplate, ...updatedTemplate]);
    }
  }, [templateData]);

  useEffect(() => {
    if (templateList) {
      setTempList(templateList);
    }
    var viewData = templateData?.templates?.find(
      (x) => x?.id?.toString() === currentView?.toString()
    );
    var defaultView = [
      "selection",
      "code",
      "name",
      "tags",
      "owner",
      "path",
      "lastModifiedAt",
      "postedBy",
      "assignUsers",
    ];
    setHiddenFields(viewData ? [...viewData.viewColumns] : defaultView);

    document.querySelectorAll("th,td").forEach((x) => {
      if (x.id.includes("none")) {
        x.classList.add("hidden");
      }
      if (x.id.includes("action")) {
        x.classList.add("absolute");
        x.classList.add("right-0");
      }
      x.classList.remove("absolute");
      x.classList.add("left-0");
      x.classList.add("relative");
      var div = document.getElementById(x.id);
      if (div) {
        div.style.setProperty("left", "");
      }
    });
  }, [currentView]); //templateList,

  // const createAllZip = () => {
  //   const makeid = (length: number) => {
  //     let result = '';
  //     const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  //     const charactersLength = characters.length;
  //     let counter = 0;
  //     while (counter < length) {
  //       result += characters.charAt(Math.floor(Math.random() * charactersLength));
  //       counter += 1;
  //     }
  //     return result;
  //   }
  //   var count = 0;
  //   const zip = new JSZip();
  //   var files;
  //   if (DocumentTableData) {
  //     files = DocumentTableData?.documents?.data;
  //   }

  //   files?.forEach(async function (file: any, i: any) {
  //     var filename = file ? file.name : "";
  //     if (file?.filepath) {
  //       var image = await fetch(file.filepath);
  //       var imageBlog = await image.blob();
  //       var img = zip.folder("documents");
  //       img?.file(filename, imageBlog, { binary: true });
  //       count++;
  //       if (count == files.length) {
  //         zip.generateAsync({ type: "blob" }).then(function (content) {
  //           FileSaver.saveAs(content, makeid(5) + ".zip");
  //         });
  //       }
  //     }
  //   });
  // }

  // const changePage = (pageNo: number) => {
  //   var pageData = {
  //     limit: pagination.pageSize,
  //     currentPage: pagination.pageIndex + 1,
  //   };
  //   // getPermissions(pageData)
  // };

  const deleteTemplateData = (id: string) => {
    var filterTempList = templateList.filter((x) => x.id !== parseInt(id));
    setTempList(filterTempList);
  };

  // const onDownloadAllClick = () => {
  //   axios({
  //     url: endpointUrl + `downloadAllDocuments`,
  //     method: "GET",
  //     responseType: "blob",
  //     headers: {
  //       Authorization: `Bearer ${getUser.token}`,
  //       "Accept": "application/json",
  //     },
  //   }).then((response) => {
  //     const url = window.URL.createObjectURL(new Blob([response.data]));
  //     const link = document.createElement("a");
  //     link.href = url;
  //     link.setAttribute("download", "documents.zip");
  //     document.body.appendChild(link);
  //     link.click();
  //   });
  // }

  const assignOwnerData = (documentId: number, ownderId: number) => {
    assignOwner({
      id: documentId,
      owner_id: ownderId,
    })
      .then((res: any) => {
        if (res?.data.status == true) {
          setIsSuccessSave(true);
          setIsEdit(true);
        }
      })
      .catch((error: any) => {
        setIsSuccessSave(false);
        setErrorPopup(true);
        setIsEdit(false);
        setMessage(
          error?.response
            ? [error?.response?.data?.message]
            : ["Something wrong!. Trying again."]
        );
      });
  };

  const assignTagsData = (documentId: number, tagsId: number[]) => {
    assignTags({
      id: documentId,
      tag_ids: tagsId,
    })
      .then((res: any) => {
        if (res?.data.status == true) {
          setIsSuccessSave(true);
          setIsEdit(true);
        }
      })
      .catch((error: any) => {
        setIsSuccessSave(false);
        setErrorPopup(true);
        setIsEdit(true);
        setMessage(
          error?.response
            ? [error?.response?.data?.message]
            : ["Something wrong!. Trying again."]
        );
      });
  };

  useEffect(() => {
    if (isSuccessSave === true) {
      toast("Successfully Uploaded.", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        type: "success",
        theme: "light",
      });
    }
    setTimeout(() => {
      setIsSuccessSave(false);
    }, 4000);
  }, [isSuccessSave]);

  // var filterTags = tagsDataList;
  const saveDocument = (
    documentId: number,
    ownderId: number,
    tagsId: string[],
    editedData: any
  ) => {
    // var editedData = data?.find(x => x.id == documentId);
    if (ownderId && Number(ownderId) !== 0) {
      assignOwnerData(documentId, ownderId);
    }
    if (tagsId.length > 0) {
      var tagsIDs: number[] = [];
      tagsDataList?.forEach((t: any) => {
        var isSame = tagsId.find((tg) => tg === t.name);
        if (isSame) {
          tagsIDs.push(t.id);
        }
      });
      assignTagsData(
        documentId,
        editedData?.tags?.map((x: any) => x.id)
      );
    }
  };

  const createTagData = (label: string) => {
    var data = {
      name: label,
      flag: "doc",
    };
    return createTag(data);
  };

  const uploadDocument = async (formSaveData: any) => {
    await axios(endpointUrl + "documents", {
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${getUser.token}`,
        Accept: "application/json",
      },
      data: formSaveData,
    })
      .then((res) => {
        axios
          .get(
            `${endpointUrl}documents?start=${filterDate?.startDate ? filterDate?.startDate?.toDateString() : ""
            }&end=${filterDate?.endDate ? filterDate?.endDate?.toDateString() : ""
            }&limit=${pagination.pageSize}&search=${globalSearch}&type=${filterStates?.name
            }&tag=${filterStates?.tags}`,
            {}
          )
          .then((result) => {
            var resD = result.data;
            setData(resD?.documents?.data);
            setIsModalOpen(false);
            setImporting(false);
            setIsDataClean(true);
            toast("Successfully Uploaded.", {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              type: "success",
              theme: "light",
            });
          });
      })
      .catch((error) => {
        setErrorPopup(true);
        setMessage(
          error?.response
            ? [error?.response?.data?.message]
            : ["Something wrong!. Trying again."]
        );
        setImporting(false);
      });
  };

  useEffect(() => {
    if (viewCols?.view_columns?.length > 0) {
      setHiddenFields(viewCols?.view_columns);
    }
  }, [viewCols?.view_columns]);

  useEffect(() => {
    var temp = templateData?.templates;
    if (temp) {
      if (currentView) {
        setCurrentView(temp[temp?.length - 1]?.id);
      }
    }
    setCurrentViewData(currentView);
    setIsTemplate(true);
  }, [templateData]);

  useEffect(() => {
    setCurrentPage(1);
  }, [globalSearch, filterStates, pagination.pageSize, filterDate]);

  useEffect(() => {
    if (successPopup) {
      toast("Done Successfully.", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        type: "success",
      });
    }

    setTimeout(() => {
      setSuccessPopup(false);
    }, 2000);
  }, [successPopup]);

  var disabledData = checkedIsNew(data);

  const props = useSpring({
    to: { opacity: 1 },
    from: { opacity: 0 },
    delay: 900,
  });

  return (
    <>
      {isLoading ? (
        <CommonTableSkeleton rows={12} />
      ) : (
        <>
          <animated.div style={props}>
            <ErrorPopup
              descText="Error Message!"
              confirmBtnText="Yes, Close."
              show={errorPopup}
              setShow={setErrorPopup}
              message={message}
              setMessage={setMessage}
            />
            <div className="p-4 bg-white mt-[25px] font-poppins text-13 tracking-[-0.03rem] w-full h-full rounded-xl pt-3 pb-8 sm:pt-3 sm:pb-9 px-[10px] 5xs:px-4 sm:px-6 6xl:px-9 ">
              <div className={`flex flex-wrap justify-between items-center`}>
                <div className="flex items-center mr-2 xs:mr-4 sm:w-[260px] w-[225px] my-1">
                  <DebounceSearchInput
                    setState={setGlobalSearch}
                    debounce={800}
                  >
                    {(setState) => (
                      <div className="font-normal flex border-1 border-[#C4C4C4] bg-white hover:bg-[#EFEFEF] rounded-[28px]">
                        <input
                          type="text"
                          placeholder="Search"
                          className="focus:outline-none py-1 px-4 text-12 xl:text-14 leading-5 bg-transparent rounded-[28px] w-full text-graydark"
                          onChange={(e) => setState(e.target.value)}
                        />
                        <SearchIcon className="mr-2" />
                      </div>
                    )}
                  </DebounceSearchInput>
                </div>
                <div className="flex items-center justify-end my-1">
                  <button
                    type="button"
                    onClick={() => setIsTableView(true)}
                    className="mr-[10px] bg-offwhite rounded-md flex justify-center items-center 2xl:w-[34px] 2xl:h-[34px] w-[28px] h-[28px]"
                  >
                    <HorizontalGridIcon isActive={isTableView} />
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      setIsTableView(false);
                      setRowSelection({});
                    }}
                    className="mr-[10px] bg-offwhite rounded-md flex justify-center items-center 2xl:w-[34px] 2xl:h-[34px] w-[28px] h-[28px]"
                  >
                    <GridLayoutIcon isActive={!isTableView} />
                  </button>
                  {managementDocument ? (
                    <>
                      <button
                        disabled={disabledData?.disabled}
                        type="button"
                        onClick={() => {
                          setIsModalOpen(true);
                          setImporting(false);
                          setIsDataClean(true);
                        }}
                        className={
                          disabledData?.disabledClass +
                          " mr-[10px]  rounded-md flex justify-center items-center w-[72px] h-[34px] px-1 py-1.5 text-12 leading-[18px] font-medium text-black2 border border-transparent hover:border-vorpblue hover:text-vorpblue group transition-all duration-300"
                        }
                      >
                        <ImportIcon className="mr-[5px] hover:fill-vorpblue" />
                        Upload
                      </button>
                      {/* <Button
                            label={
                              <div className="flex items-center group">
                                <DownloadAllIcon className="mr-2" />
                                Download{" "}
                                <span className="hidden 5xs:inline-block">&nbsp;All</span>
                              </div>
                            }
                            type="button"
                            variant="primary"
                            size="base"
                            customClass={"border-transparent bg-[#F1FAFF] text-vorpblue hover:bg-vorpblue hover:text-white py-[7px] px-[6px] 6xs:text-[12px] xs:text-[12px] leading-[18px] font-semibold"}
                            onClick={() => onDownloadAllClick()}
                          /> */}
                    </>
                  ) : null}
                </div>
              </div>

              {/* Controls Sections */}
              <div className="">
                <div className="flex sm:flex-row flex-col justify-between items-start 7xl:items-center mb-1">
                  <div className="flex w-full sm:mt-0 mt-1 sm:mb-0 mb-1 sm:mr-5 custom-scrollbar">
                    <CustomScrollbar isVertical={false} isHor={true}>
                      <div className="flex w-full items-center">
                        <div className="flex items-center lex-1 w-auto mr-3">
                          <p className="hidden 2xs:w-auto w-1/2 text-left base-label-sm text-12 xl:text-14 whitespace-nowrap">
                            FILE TYPE
                          </p>
                          <div className={`${styles.customDropdown}  w-full`}>
                            <CustomDropdownListForFilter
                              isFilter={true}
                              placeholder="File Type"
                              state={filterStates.name}
                              setState={(value) =>
                                setFilterStates((prev) => ({
                                  ...prev,
                                  name: value,
                                }))
                              }
                              dropdownItems={dummyFileTypes.map((type) => ({
                                value: type,
                                label: type,
                              }))}
                              textAlign="text-left  pl-3"
                              className="max-h-[38px] max-w-full sm:max-w-[250px] 2xl:w-32 sm:w-28 w-full"
                            />
                          </div>
                        </div>
                        <div className="flex items-center mr-3 w-auto">
                          <p className="hidden 2xs:w-auto w-1/2 text-left base-label-sm whitespace-nowrap text-12 xl:text-14">
                            TAG
                          </p>
                          <div className={`${styles.customDropdown} w-full`}>
                            <CustomDropdownListForFilter
                              isFilter={true}
                              placeholder="Tag"
                              state={filterStates.tags}
                              setState={(value) =>
                                setFilterStates((prev) => ({
                                  ...prev,
                                  tags: value,
                                }))
                              }
                              dropdownItems={tags.map((tag: any) => ({
                                value: tag.id,
                                label: tag.name,
                              }))}
                              textAlign="text-left pl-3"
                              className="max-h-[38px] max-w-full sm:max-w-[250px] 2xl:w-32 sm:w-28 w-full"
                            />
                          </div>
                        </div>
                        <div className="flex items-center 2xs:w-auto w-1/2 ">
                          <p className="hidden base-label-sm mb-3 2xs:mb-0 text-12 xl:text-14">
                            DATE
                          </p>
                          {filterDate?.startDate && filterDate?.endDate && (
                            <button
                              type="button"
                              onClick={() => setFilterDate({})}
                              className="text-12 mr-2"
                            >
                              <CloseIcon
                                color="#EE436D"
                                width={10}
                                height={10}
                              />
                            </button>
                          )}
                        </div>
                        <div className="flex 5xs:items-center">
                          <div className={`mr-3 ${styles.customDatePicker}`}>
                            <DatePopupFoFilter
                              placeholder="Start"
                              date={filterDate?.startDate}
                              setDate={(value) =>
                                setFilterDate((prev) => ({
                                  ...prev,
                                  startDate: value as Date,
                                }))
                              }
                            />
                          </div>
                          <div className={`${styles.customDatePicker} mr-3`}>
                            <DatePopupFoFilter
                              placeholder="End"
                              date={filterDate?.endDate}
                              setDate={(value) =>
                                setFilterDate((prev) => ({
                                  ...prev,
                                  endDate: value as Date,
                                }))
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </CustomScrollbar>
                  </div>
                  <div className="flex items-center sm:flex-nowrap flex-wrap justify-end sm:w-auto w-full">
                    <div className="flex items-center mr-2  mt-1">
                      <p className="base-label text-12 xl:text-14">DISPLAY</p>
                      <div
                        className={`${styles.customDropdown} ${styles.textGrayDark}`}
                      >
                        <NumberDropdownList
                          state={pagination.pageSize}
                          setState={(value) =>
                            setPagination((prev) => ({
                              ...prev,
                              pageSize: value as number,
                            }))
                          }
                          dropdownItems={new Array(5)
                            .fill(1)
                            .map((_, index: number) => {
                              let display = (index + 1) * 20;
                              return {
                                value: display,
                                label: String(display),
                              };
                            })}
                          className="max-w-[60px] md:max-w-[80px] lg:max-w-[52px] xl:max-w-[60px] 7xl:max-w-[72px]"
                          textAlign="text-left pl-2 pr-3"
                          paddingClass="py-[2px]"
                          placeholder="All"
                        />
                      </div>
                    </div>
                    {isTableView == true ?
                      <div className={styles.customDropdown + " mt-1"}>
                        <StringDropdownListWithDeleteBtn
                          haveDeleteBtn={true}
                          setIsTemplate={setIsTemplate}
                          state={isTemplate ? currentViewData : currentView}
                          setState={setCurrentView}
                          deleteTemplate={deleteTemplateData}
                          dropdownItems={moduleList.map(
                            (x: any, index: number) => {
                              return {
                                label: x.id === -1 ? x.name : "View #" + x.index,
                                value: x.id,
                              };
                            }
                          )}
                          className="min-w-[80px] 2xl:min-w-[128px] 6xs:text-12  2xl:w-32 w-24"
                          placeholder="All"
                          textAlign="text-left py-2 pl-2 pr-3"
                          custPadding="py-[2px]"
                          setErrorPopup={setErrorPopup}
                          setSuccessPopup={setSuccessPopup}
                          setMessage={setMessage}
                        />
                      </div> : ''}

                    {
                      isTableView == true ?
                        <button
                          type="button"
                          onClick={() => setIsLayoutControlOpen(true)}
                          className={(" mt-1 flex justify-center relative items-center rounded-lg bg-vorphongkongborder hover:bg-[#FFF0D2] w-[29px] h-[29px] ml-2 group transition-all duration-300 ")}
                        >
                          <CustomizeLayoutIcon />
                          <div className="absolute left-7 top-[-5px] border border-white w-4 h-4 rounded-full bg-vorphongkongborder flex justify-center items-center">
                            <AddIcon width={8} height={8} color="white" />
                          </div>
                        </button>
                        : ''
                    }

                  </div>
                </div>

                {/* Document Table Section */}
                <div className="text-left">
                  <EmployeeDocumentsTable
                    rowSelection={rowSelection}
                    setRowSelection={setRowSelection}
                    deleteDoc={deleteDoc}
                    deleteDocs={deleteDocs}
                    assignedUsers={assignedUsers}
                    assignedUsersMultiple={assignedUsersMultiple}
                    userList={userList}
                    isTableView={isTableView}
                    pagination={pagination}
                    setPagination={setPagination}
                    globalSearch={globalSearch}
                    setGlobalSearch={setGlobalSearch}
                    columnFilters={columnFilters}
                    setColumnFilters={setColumnFilters}
                    filterDate={filterDate}
                    isLayoutControlOpen={isLayoutControlOpen}
                    setIsLayoutControlOpen={setIsLayoutControlOpen}
                    data={data}
                    setData={setData}
                    // changePage={changePage}
                    setCurrentPage={setCurrentPage}
                    currentPage={currentPage}
                    total={itemCount}
                    hiddenFields={hiddenFields ? hiddenFields : []}
                    setHiddenFields={setHiddenFields}
                    templateList={tempList}
                    setCurrentView={setCurrentView}
                    localModuleId={localModuleId}
                    downloadDoc={downloadDoc}
                    saveDocumentData={saveDocument}
                    assignTagsData={assignTagsData}
                    tags={tagsDataList}
                    setCols={setCols}
                    setIsEdit={setIsEdit}
                    isEditing={isEditing}
                    managementDocument={managementDocument}
                    assignUserListData={assignUserListData}
                    departmentList={departmentList}
                    setAssignDepartmentId={setAssignDepartmentId}
                    assignDepartmentId={assignDepartmentId}
                    setAssignKeword={setAssignKeword}
                    sorting={sorting}
                    setSorting={setSorting}
                  />
                </div>
              </div>
              <UploadModal
                createTagData={createTagData}
                uploadDocument={uploadDocument}
                isDataClean={isDataClean}
                setImporting={setImporting}
                importing={importing}
                folderList={pathArray}
                tagsData={tags}
                isOpen={isModalOpen}
                setIsOpen={setIsModalOpen}
              />
              <CommonErrorPopup
                show={error !== "" ? true : false}
                setShow={() => setError("")}
                message={error}
              />
              <ToastContainer className={"text-12 font-poppins font-normal"} />
            </div>
          </animated.div>
        </>
      )}
    </>
  );
};

export default DocumentsList;
