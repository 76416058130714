import { createColumnHelper, SortingState } from "@tanstack/table-core";
import { FC, useState, useMemo } from "react";
import MinimalTable from "../../Tables/MinimalTable";
import styles from './MyLeave.module.scss';
// import { dummyLeaveBalanceData } from "../../../utils/constants";
// import { useGetMyAuthLeaveBalanceQuery } from "../../../app/services/profile";
interface IPersonalLeave {
  leaveBalance?: any;
}

const PersonalLeaveBalanceTable: FC<IPersonalLeave> = ({leaveBalance}) => {
  const columnHelper = useMemo(() => createColumnHelper<any>(), []); // TODO add type definition when api binding
  const [sorting, setSorting] = useState<SortingState>([]);  
  const columns = useMemo(
    () => [
      columnHelper.accessor("leave_type", {
        id: "Leave Type",
        header: () => (
          <span className="lg:text-[13px] text-12 leading-5 tracking-[0.03em]">
            Leave Type
          </span>
        ),
        cell: ({ getValue }) => {
          const leaveType = getValue();
          return <span className="pl-2">{leaveType}</span>;
        },
      }),
      columnHelper.accessor("total_allowance", {
        id: "Total Allowance",
        header: () => (
          <span className="lg:text-[13px] text-12 leading-5 tracking-[0.03em]">
            Total Allowance
          </span>
        ),
        cell: ({ getValue }) => {
          const totalAllowance = getValue();
          return <span className="pl-[6px]">{totalAllowance}</span>;
        },
      }),
      columnHelper.accessor("actual_allowance", {
        id: "Actual allowance",
        header: () => (
          <span className="lg:text-[13px] text-12 leading-5 tracking-[0.03em]">
            Actual allowance
          </span>
        ),
        cell: ({ getValue }) => {
          const totalAllowance = getValue();
          return <span className="pl-[6px]">{totalAllowance}</span>;
        },
      }),
      
      columnHelper.accessor("leave_taken", {
        id: "Leave Taken",
        header: () => (
          <span className="lg:text-[13px] text-12 leading-5 tracking-[0.03em]">
            Leave Taken
          </span>
        ),
        cell: ({ getValue }) => {
          const leaveTaken = getValue();
          return <span className="pl-[6px]">{leaveTaken}</span>;
        },
      }),      
      columnHelper.accessor("leave_balance", {
        id: "leave_balance",
        header: () => (
          <span className="lg:text-[13px] text-12 leading-5 tracking-[0.03em]">
            Leave Balance
          </span>
        ),
        cell: ({ getValue }) => {
          const leaveBalance = getValue();
          return <span className="pl-[6px]">{leaveBalance}</span>;
        },
      }),
    ],
    [columnHelper,leaveBalance]
  );

  return (
    <div className={styles.personalLeaveBalance}>
      <MinimalTable
        data={leaveBalance}
        columns={columns}
        sorting={sorting}
        setSortingState={setSorting}
        theaderColor="text-graydark"
      />
    </div>
  );
};

export default PersonalLeaveBalanceTable;
