import { useEffect, useState } from "react";
import { endpointUrl } from "../../app/services/api";
import { getAuthUser } from "../../app/services/dashboard";
import { useViewFileQuery } from "../../app/services/documents";
import axios from "../../axios";
import { Worker } from '@react-pdf-viewer/core';

const DocumentViews=()=>{
    var url=window.location.search.split('?id=')[1];
    var getUser = getAuthUser();   
    const [imgSrc,setImgSrc]=useState<string>("");
    // const onViewDocument = (name: string) => {
    //     const extension = name.split(".").pop() as string;
    //     //downloadDoc();
    //     // using Java Script method to get PDF file
    //     axios({
    //       url: endpointUrl + `documents/view/${name}`,
    //       method: "GET",
    //       responseType: "blob",
    //       headers: {
    //         Authorization: `Bearer ${getUser.token}`,
    //         Accept: "application/json",
    //       },
    //     }).then((response) => {
    //       const url = window.URL.createObjectURL(new Blob([response.data]));
    //       const link = document.createElement("a");
    //       link.href = url;
    //       console.log("url view",url)
    //       setImgSrc(url);
    //     });
    //   };
    //   useEffect(()=>{
    //     onViewDocument(url);
    //   },[])
    return <div className="bg-black">
        {imgSrc?<img src={imgSrc} alt="documents" className="w-full h-[100vh] mx-auto object-contain bg-black"/>:''}        
    </div>
}
export default DocumentViews;