import React, { useRef, useState } from "react";
import classNames from "classnames";
import styles from "./EmployeeChart.module.scss";
import prevIcon from "./img/cal-pre.svg";
import nextIcon from "./img/cal-next.svg";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { monthAbbr } from "../ProjectChart/ProjectChart";
import { LeftIcon, RightIcon } from "../common/Icons";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const EmployeeChart = (props: any) => {
  const chartRef = useRef(null);
  return (
    <>
      <div className="xl:px-[25px] px-5 py-[26px] bg-white h-full">
        <div
          className={classNames(
            styles.titleWithButton,
            "flex flex flex-wrap justify-between items-baseline  mb-10"
          )}
        >
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <p className="mr-2 text-black2 text-14 2xl:text-18 font-poppins font-semibold text-left">
                Employee
              </p>
              <div className="flex bg-offwhite rounded-md">
                <button onClick={() => props.changeToPrev()} className={classNames(styles.prevMonth, "px-2 w-1/2 bg-offwhite hover:bg-vorpblue py-2 rounded-md")}>
                  <div><LeftIcon width={12} height={12} /></div>
                </button>
                <button onClick={() => props.changeToNext()} className={classNames(styles.nextMonth, "px-2 w-1/2 bg-offwhite hover:bg-vorpblue py-2 rounded-md")}>
                  <div><RightIcon width={12} height={12} /></div>
                </button>
              </div>
            </div>            
          </div>
          <div className="flex">
              <p className={classNames("2xl:text-14 text-12 text-graydark", (props.dataViewEmployee == "year") ? '' : 'hidden')}>{props.pYear}</p>
              <p className={classNames("2xl:text-14 text-12 text-graydark", (props.dataViewEmployee == "month") ? '' : 'hidden')}>{monthAbbr[props.pMonth - 1]}-{props.pYear}</p>
              <p className={classNames("2xl:text-14 text-12 text-graydark", props.dataViewEmployee == "week" ? '' : 'hidden')}>({props.employeeLabel ? props.employeeLabel[0] : ''}-{props.employeeLabel ? props.employeeLabel[props.employeeLabel.length - 1] : ''}) {props.pYear}</p>
            </div>
          <div className="flex items-center">
            <button
              className={classNames(
                props.dataViewEmployee == "year"
                  ? "text-vorpblue bg-offwhite rounded-md"
                  : "",
                "font-poppins font-semibold 2xl:text-sm text-12 text-[#C4C4C4] 2xl:px-11 px-1"
              )}
              onClick={(e) => props.hangleChangeViewEmployee("year")}
            >
              Year
            </button>
            <button
              className={classNames(
                props.dataViewEmployee == "month"
                  ? "text-vorpblue bg-offwhite rounded-md"
                  : "",
                "font-poppins font-semibold 2xl:text-sm text-12 text-[#C4C4C4] 2xl:px-11 px-1"
              )}
              onClick={(e) => props.hangleChangeViewEmployee("month")}
            >
              Month
            </button>
            <button
              className={classNames(
                props.dataViewEmployee == "week"
                  ? "text-vorpblue bg-offwhite rounded-md"
                  : "",
                "font-poppins font-semibold 2xl:text-sm text-12 text-[#C4C4C4] 2xl:px-11 px-1"
              )}
              onClick={(e) => props.hangleChangeViewEmployee("week")}
            >
              Week
            </button>
          </div>
        </div>

        <div className={classNames(styles.employeeChart, '')}>
          <Bar
           style={{
            width: 750,
            height: 350,
          }}
            ref={chartRef}
            options={{
              maintainAspectRatio:false,
              scales: {
                y: {
                  ticks: {
                    stepSize: 10,
                    color: "#858795",
                    font: {
                      family: "Poppins",
                    },
                  },
                },
                x: {
                  ticks: {
                    padding:0,
                    maxTicksLimit:31,               
                    autoSkipPadding:-0,
                    autoSkip:false,
                    color: "#858795",
                    font: {
                      family: "Poppins",
                      size:9
                    },
                  },
                },
              },
              
              responsive: true,
              plugins: {
                legend: {
                  display: false,
                  position: "bottom" as const,
                  labels: {
                    font: {
                      size: 12,
                      family: "Poppins",
                    },
                  },
                },
                title: {
                  display: false,
                  text: "Employee",
                },
              },
            }}
            data={props.emLabel}
          />
        </div>
        <div
          className={classNames(
            "flex items-center rounded-[12px]  bg-white px-2 mt-[18px] flex-wrap",
            styles.embarLabel
          )}
        >
          {props.emLabel.datasets.map((data: any, idx: any) => {
            return (
              <div
                className={classNames(
                  "flex flex-row items-center",
                  styles.embarLabel__item
                )}
                key={`embarLabel-${idx}`}
              >
                <span
                  className={classNames(
                    "w-[14px] h-[14px] rounded-full border",
                    styles.embarLabel__item__dot
                  )}
                  style={{
                    background: data.backgroundColor,
                    borderColor: data.borderColor,
                  }}
                ></span>
                <div
                  className={classNames(
                    "text-left ml-[10px]",
                    styles.embarLabel__item__name
                  )}
                >
                  <p className="font-primary text-graydark text-10 5xl:text-12">
                    {data.label}
                  </p>
                  <p className="font-primary text-black2 text-12 5xl:text-18 font-semibold">
                    {data.total}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default EmployeeChart;
