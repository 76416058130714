import { useState } from "react";
import ProfileCalendarView from "./ProfileCalendarView";
import ProfileCalendarFullView from "../CreateNewItemPopup/ProfileCalendarFullView";
const ProfileCalendarViewContainer = (props: any) => {
  const [isFullscreen, setIsFullscreen] = useState<boolean>(false);
  
  return (
    <>
      <ProfileCalendarView isFullscreen={isFullscreen} setIsFullscreen={setIsFullscreen} />
      <ProfileCalendarFullView isFullscreen={isFullscreen} setIsFullscreen={setIsFullscreen} setShow={setIsFullscreen} show={isFullscreen} />
    </>
  );
};
export default ProfileCalendarViewContainer;
