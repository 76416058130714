import { Modal } from "@mui/material"
import classNames from "classnames";
import {
    Dispatch,
    FC,
    SetStateAction,
} from "react";
import { ErrorNotiICon } from "../common/Icons";

interface ICreateData {
    show: boolean;
    setShow: Dispatch<SetStateAction<boolean>>;
    descText?: string;
    confirmBtnText?: string;
    message?: any;
    setMessage?: any;
    setIsProjectAmount?: Dispatch<SetStateAction<boolean>>;
}
const ErrorPopup: FC<ICreateData> = ({ show, setShow,
    message, setMessage, setIsProjectAmount }) => {

    return (
        <div>
            <Modal open={show} onClose={() => {
                setShow(false);
                if (setIsProjectAmount) {
                    setIsProjectAmount(false)
                }                
            }} disableEnforceFocus={true}>
                <div>
                    <div className={classNames("z-10 fixed top-0 left-0 w-full h-full")}>
                        <div
                            className={classNames(
                                "relative z-10 top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 md:max-w-[320px] max-w-[300px] bg-[#fff] rounded-xl"
                            )}
                        >
                            <button onClick={() => setShow(false)} className="absolute top-0 left-1/2 -translate-x-1/2 -translate-y-1/2">
                                <ErrorNotiICon className="lg:w-[120px] lg:h-[120px] w-[100px] h-[100px]" />
                            </button>
                            <div className={classNames(message?.length > 15 ? '' : 'flex justify-center items-center', "h-auto min-h-[60px]  flex flex-col justify-start text-left  items-start pt-[60px] px-6")}>
                                <h3 className="w-full text-center font-poppins xl:text-20 text-base text-[#3F4254] font-medium mb-2">Error</h3>
                                {
                                    message.length > 0 ? message?.map((m: any, index: number) => (
                                        <p key={index} className="w-full text-start block font-poppins font-medium xl:text-sm text-12 text-[#6C7293]">
                                            {m}
                                        </p>
                                    ))
                                        : ""
                                }
                            </div>
                            <div className="w-full bg-redPink mt-5 rounded-bl-[8px] rounded-br-[8px]">
                                <button onClick={() => setShow(false)} className="font-poppins font-medium text-white xl:text-sm text-12 text-center w-full py-[10px]">OK</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
};
export default ErrorPopup;
