import { redirectToLogin } from './annoucement-sheet';
import { api } from './api';
import authHeader from './auth-header';
import { Ids } from './media';

type TaskListResponse = {
    progress_checker_all_tasks: TaskListData,
    module_id: number,
    status: boolean,
    total_time: string,
    project_lists: CommonListData[],
    task_categories: TaskCategory[]
    project_manager_lists: TeamLeadData[],    
}
export type TaskListFilterResponse = {
    project_lists: ProjectListType[],
    task_categories: TaskCategory[],
    project_manager_lists: TeamLeadData[],
    employee_list: EmployeeListType[],
    team_leader_lists:TeamLeadData[],
}
export type EmployeeListType = {
    "id": number;
    "name": string;
    "code": string;
    "year_w_vo": number;
    "age": number;
}
export type ProjectListType = {
    "project_id": number;
    "project_code": string;
    "project_name": string;
}
export type PMType = {
    "id": number;
    "preferred_name": string;
    "code": string;
    "year_w_vo": number;
    "age": number;
}
export type TaskCategory = {
    id: number;
    name: string;
}
export type TeamLeadData = {
    id: number;
    code: string,
    name: string;
    preferred_name: string;
    year_w_vo: number;
    age: number;
}
export type CommonListData = {
    "project_id": number,
    "project_code": string,
    "project_name": string,
}
export type TaskListDataFormat = {
    "id": number,
    "code": string,
    "project_id": number,
    "project_code": string,
    "task_name": string,
    "task_category_name": string,
    "project_manager_name": string,
    "project_manager_code": string,
    "team_leader_name": string,
    "team_leader_code": "string",
    "date": string,
    "time_spent": string,
    "time_spent_text": string,
}
export type TaskListData = {
    current_page: number;
    data: TaskListDataFormat[],
    total: number;
}
type taskListInput = {
    task_category_id: string,
    employee_ids:string,
    sort: string,
    search: string,
    page: number,
    limit: number,
    project_id?: string,
    pic?: string,
    start?: string,
    end?: string
    team_leader_ids:string,
}
export const taskListApi = api.injectEndpoints({
    endpoints: (build) => ({
        getTaskList: build.query<TaskListResponse, taskListInput>({
            query: ({ sort, search, task_category_id, limit, page, pic, project_id, start, end,employee_ids,team_leader_ids }) => ({
                url: `progress-checker-lists/all-tasks?task_category_ids=${task_category_id}&sort=${sort}&search=${search}&limit=${limit}&page=${page}&start=${start}&end=${end}&project_ids=${project_id}&project_manager_ids=${pic}&employee_ids=${employee_ids}&team_leader_ids=${team_leader_ids}
            
            `,
                headers: authHeader()
            }),
            keepUnusedDataFor: 0,
            transformErrorResponse: (err) => {
                if (err?.status === 401) {
                    redirectToLogin();
                }
            },
            providesTags: (result: any = []) => {
                if (result.data) {
                    if (result.data.length > 0) {
                        return [
                            ...result.data.map((id: any) => ({ type: "TaskList", id } as const)),
                            { type: "TaskList" as const, id: "LIST" },
                        ]
                    }
                    else {
                        return [{ type: "TaskList" as const, id: "LIST" },]
                    }
                }
                else {
                    return [{ type: "TaskList" as const, id: "LIST" },]
                }

            },
        }),
        getTaskListFilterData: build.query<TaskListFilterResponse, void>({
            query: () => ({ url: `progress-checker-lists/all-tasks-filter-data`, headers: authHeader() }),
            transformErrorResponse: (err) => {
                if (err?.status === 401) {
                    redirectToLogin();
                }
            },
        }),
    }),
});

export const {
    useGetTaskListQuery,
    useGetTaskListFilterDataQuery
} = taskListApi;


export const {
    endpoints: {
        getTaskList,
        getTaskListFilterData
    },
} = taskListApi;