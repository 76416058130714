import { redirectToLogin } from './annoucement-sheet';
import { api } from './api';
import authHeader from './auth-header';
import { Ids } from './media';

type EmployeeIncentiveResponse = {
    employee_incentives: EmployeeIncentiveListData[],
    module_id:number,
    status:boolean,
    currencyList:currencyData[],
    countryList:CountryListData[]
}
type EmployeeIncentiveFilterResponse = {
    currencyList:currencyData[],
    countryList:CountryListData[]
}
export type CountryListData={
    id:number;
    name:string;
    code:string;
    time_zone:string;
}
type EmployeeIncentiveListData = {
    "id": number,
    "employee_code":string,
    "employee_id": number,
    "employee_name": string,
    "currency_id": number,
    "currency_name": string,
    "currency_code": string,
    "internet_incentive": string,
    "no_absent_incentive": string,
    "no_lateness_incentive": string,
    "work_quality_rewards_tier_1": string,
    "work_quality_rewards_tier_2": string,
    "other_rewards": string,
    "allowance": string,
    "description": string,
    "status": number,
    "created_by_name": string,
    "created_at": string,
    "updated_at": string
}
export type currencyData={
    "id": number,
    "currency_name": string,
    "currency_code": string
}
type employeeIncentiveInput={
    currencyId:string,
    countryId:string,
    sorting:string,
    search:string
}
export const incentiveApi = api.injectEndpoints({
    endpoints: (build) => ({
        getEmployeeIncentive: build.query<EmployeeIncentiveResponse, employeeIncentiveInput>({
            query: ({sorting,currencyId,countryId,search}) => ({ url: `employee-incentives?currency_id=${currencyId}&country_id=${countryId}&sort=${sorting}&search=${search}`, headers: authHeader() }),
            transformErrorResponse: (err) => {
                if (err?.status === 401) {
                    redirectToLogin();
                }
            },
            providesTags: (result: any = []) => {
                if (result.data) {
                    if (result.data.length > 0) {
                        return [
                            ...result.data.map((id: any) => ({ type: "EmployeeIncentive", id } as const)),
                            { type: "EmployeeIncentive" as const, id: "LIST" },
                        ]
                    }
                    else {
                        return [{ type: "EmployeeIncentive" as const, id: "LIST" },]
                    }
                }
                else {
                    return [{ type: "EmployeeIncentive" as const, id: "LIST" },]
                }

            },
        }),
        getEmployeeIncentiveFilterData: build.query<EmployeeIncentiveFilterResponse, void>({
            query: () => ({ url: `employee-incentives-filter-data`, headers: authHeader() }),
            transformErrorResponse: (err) => {
                if (err?.status === 401) {
                    redirectToLogin();
                }
            },
            providesTags: (result: any = []) => {
                if (result.data) {
                    if (result.data.length > 0) {
                        return [
                            ...result.data.map((id: any) => ({ type: "EmployeeIncentiveFilterData", id } as const)),
                            { type: "EmployeeIncentiveFilterData" as const, id: "LIST" },
                        ]
                    }
                    else {
                        return [{ type: "EmployeeIncentiveFilterData" as const, id: "LIST" },]
                    }
                }
                else {
                    return [{ type: "EmployeeIncentiveFilterData" as const, id: "LIST" },]
                }

            },
        }),
        updatedIncentive: build.mutation<EmployeeIncentiveResponse, any>({
            query: (body) => {
                return {
                    url: `employee-incentives/${body.id}`,
                    method: "PUT",
                    body,
                    headers: authHeader(),
                };
            },
            invalidatesTags: (events) => [{ type: "EmployeeIncentive", id: "LIST" }],
        }),
    }),
});

export const {
    useGetEmployeeIncentiveQuery,
    useUpdatedIncentiveMutation,
    useGetEmployeeIncentiveFilterDataQuery
} = incentiveApi;


export const {
    endpoints: {
        getEmployeeIncentive,
        updatedIncentive,
        getEmployeeIncentiveFilterData
    },
} = incentiveApi;