import ForgetPasswordComponent from '../components/ForgetPasswordComponent';
function App() {
  return (
    <>      
      <ForgetPasswordComponent />
    </>
  );
}

export default App;
