import classNames from "classnames";
import {
  Dispatch,
  FC,
  FocusEvent,
  Fragment,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { EditIcon, TickIcon } from "../common/Icons";
import { formatMoney } from "../../utils/helpers";
import { usePopper } from "../../hooks/usePopper";
import { uniqueId } from "lodash";
import PaymentTermsEdit from "./PaymentTermsEdit";
import { baseObj } from "../../utils/constants";
import {
  useCreatePaymentMutation,
  useGetPaymentDataQuery,
} from "../../app/services/project";
import { Cell } from "@tanstack/react-table";

interface IPaymentProgressBar {
  projectAmount: number;
  paidAmount: IPaidAmount;
  currency: string;
  setData: Dispatch<SetStateAction<baseObj[]>>;
  id: number;
  paymentTerm: number;
  rowId?: number;
  isNew?: boolean;
  setErrorPopup: Dispatch<SetStateAction<boolean>>;
  setSuccessPopup: Dispatch<SetStateAction<boolean>>;
  setMessage: any;
  setIsPaymentChange: Dispatch<SetStateAction<boolean>>;
  setEditedData: any;
  editedData: any;
  cell: Cell<any, any>;
}

export interface IPaidAmount {
  value: number;
  paymentValue: { amount: number; isDone: boolean; id: number }[];
}

const PaymentProgressBar: FC<IPaymentProgressBar> = ({
  paidAmount,
  projectAmount,
  currency,
  setData,
  paymentTerm,
  id,
  rowId,
  isNew,
  setErrorPopup,
  setSuccessPopup,
  setMessage,
  setIsPaymentChange,
  setEditedData,
  editedData,
  cell,
}) => {
  const [createPayment] = useCreatePaymentMutation();
  // console.log('leg', paidAmount?.paymentValue)
  const paymentStage =
    paidAmount?.paymentValue?.length !== 0
      ? paidAmount?.paymentValue?.filter((term) => term?.isDone)?.length
      : [];

  const [amountOver, setAmountOver] = useState<boolean>(false);

  const realCurrentProjectAmount: number = projectAmount;

  const [currentProjectAmount, setCurrentProjectAmount] =
    useState<number>(projectAmount);
  const [currentPaidAmount, setCurrentPaidAmount] = useState<IPaidAmount>({
    value: paidAmount?.value,
    paymentValue: paidAmount?.paymentValue?.slice(0, paymentTerm),
  });

  const actualPaidPercent = Math.ceil(
    (currentPaidAmount.value / currentProjectAmount) * 100
  );

  const paidPercent = actualPaidPercent - (actualPaidPercent % 10) || 0;

  const paymentPercent = currentPaidAmount?.paymentValue
    ?.map((value) => {
      const actualPercent = Math.ceil(
        (value.amount / currentProjectAmount) * 100
      );
      const roundedPercent = actualPercent - (actualPercent % 10);
      return roundedPercent || 0;
    })
    .filter((value) => value !== 0);

  let [trigger, container] = usePopper({
    placement: "bottom-end",
    strategy: "fixed",
    modifiers: [{ name: "offset", options: { offset: [80, 7] } }],
  });

  const uuid = uniqueId("payment-amounts");
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const handleCloseDropdown = (event: FocusEvent<HTMLButtonElement>) => {
    const relatedTarget = event.relatedTarget;
    // If the click target is not the view button, close the tooltip.
    if (
      !relatedTarget ||
      !(
        relatedTarget.id === uuid ||
        relatedTarget.className.includes(`${uuid}child`)
      )
    ) {
      discardChanges();
      setIsOpen(false);
    }
  };

  const discardChanges = () => {
    setIsEditing(false);
    setCurrentPaidAmount({
      value: paidAmount?.value,
      paymentValue: paidAmount?.paymentValue?.slice(0, paymentTerm),
    });
    setCurrentProjectAmount(projectAmount);
  };

  const saveChanges = () => {
    setIsPaymentChange(true);
    // console.log("res setIsPaymentChange");

    var savePaymentData: any = [];
    let paidAmount: any = [];
    let lastAmount = 0;
    currentPaidAmount?.paymentValue?.forEach((d: any) => {
      lastAmount += Number(d?.amount);
      savePaymentData.push({
        project_id: rowId,
        id: d.id,
        amount: d.amount,
        isDone: d.isDone,
      });
      paidAmount.push({
        id: d.id,
        amount: d.amount,
        isDone: d.isDone,
      });
    });

    setEditedData({
      ...editedData,
      paidAmount: { paymentValue: savePaymentData, value: lastAmount },
    });

    setIsOpen(false);
    setIsEditing(false);
    createPayment(savePaymentData)
      .unwrap()
      .then((payload: any) => {
        if (payload?.status) {
          setSuccessPopup(true);
          setMessage(payload ? [payload?.message] : ["Create Successfully"]);
          setData((prev) =>
            prev.map((prev) => {
              if (prev.uniqeID === id) {
                return {
                  ...prev,
                  paidAmount: currentPaidAmount,
                };
              } else {
                return prev;
              }
            })
          );
        }
      })
      .catch((error: any) => {
        // console.log("er", error.data.message)
        if (error) {
          setErrorPopup(true);
          setMessage(
            error?.data?.message
              ? [error?.data?.message]
              : ["Something wrong! Try again."]
          );
        }
      });
  };

  useEffect(() => {
    if (realCurrentProjectAmount < currentProjectAmount) {
      setAmountOver(true);
    } else {
      setAmountOver(false);
    }
  }, [currentProjectAmount]);


  // console.log("percent", projectAmount)

  return (
    <div className="flex items-center w-full h-full relative">
      <div
        className={classNames(
          " rounded-2xl px-2 py-[5px] relative w-full min-h-[32px] overflow-hidden",
          {
            "bg-vorpmyanmar": paymentStage !== 0,
            "bg-offwhite": paymentStage === 0,
          }
        )}
      >
        <div
          className={classNames(
            "absolute h-full top-0 left-0 rounded-2xl  z-[1] flex items-center",
            {
              "bg-vorpblue": paidPercent !== 100,
              "bg-vorpgreen": paidPercent === 100,
            }
          )}
          style={{ width: `${paidPercent}%` }}
        >
          <div
            className={classNames(
              "rounded-full min-w-[22px] min-h-[22px] mr-3  my-auto ml-2 flex justify-center items-center text-12 leading-[18px] font-medium text-vorpblue",
              {
                "bg-vorpmyanmar": paymentStage !== 0,
                "bg-white": paymentStage === 0,
              }
            )}
          >
            {paidPercent === 100 ? <TickIcon /> : paymentStage}
          </div>
        </div>
        <p
          className={classNames(
            "text-[10px] leading-4 font-semibold z-[4] absolute left-10 top-2 bottom-0 my-auto",
            {
              "text-black2": paidPercent === 0,
              " text-white": paidPercent ? paidPercent : 0 > 0,
            }
          )}
        >
          {formatMoney(
            currentPaidAmount?.value ? currentPaidAmount?.value : 0,
            currency ? currency : "$"
          )}{" "}
          paid
        </p>
        {paidPercent !== 100 &&
          (() => {
            let previousPercent = 0;
            return paymentPercent?.map((percent: any, index: any) => {
              const currentPos = previousPercent + percent;
              previousPercent += percent;
              return index !== paymentPercent?.length - 1 &&
                currentPos !== 0 ? (
                <div
                  className="w-[1px] h-full absolute top-0 bg-white z-[2]"
                  style={{ left: `${currentPos}%` }}
                  key={index}
                ></div>
              ) : (
                <Fragment key={index}></Fragment>
              );
            });
          })()}
        <div className="bg-white px-2 w-10 h-[22px] rounded-xl flex justify-center items-center text-12 leading-[18px] font-medium text-black2 absolute right-[5px] top-0 bottom-0 my-auto z-[3]">
          {paidPercent > 100 ? "100" : paidPercent}%
        </div>
      </div>
      {isNew ? (
        <button
          type="button"
          ref={trigger}
          className={classNames(
            isNew == true ? "" : "pointer-events-none",
            "ml-3 text-12 leading-[18px] text-vorpblue underline"
          )}
          onClick={() => {
            setIsOpen(!isOpen);
          }}
          onBlur={handleCloseDropdown}
        >
          View
        </button>
      ) : null}
      {isOpen && (
        <button
          ref={container}
          onBlur={handleCloseDropdown}
          className={`absolute top-[90%] -right-[20%] w-[204px] min-h-[294px] rounded-4 overflow-hidden z-[4] hover:cursor-default`}
          id={uuid}
        >
          <div
            className="flex justify-between items-center bg-[#EBF0F7] px-4 py-3 text-12 leading-[18px] text-black2
           font-semibold border border-offwhite rounded-t-4"
          >
            Payment Details
            {!isEditing && (
              <span
                aria-label="edit-btn"
                className="w-5 h-5 flex justify-center items-center bg-vorpblue rounded-4 hover:cursor-pointer"
                onClick={() => {
                  setIsEditing(true);
                }}
              >
                <EditIcon width={12} height={12} />
              </span>
            )}
          </div>
          <div className="px-4 pt-4 bg-white rounded-b-4 overflow-hidden">
            <div className="flex px-0.5 items-center border-b pb-4 text-12 leading-[18px] text-black2 border-b-black2">
              <span className="text-black2  mr-[7px]">Payment Amt:</span>
              <span className="mr-[1px]">{currency}</span>
              <span>
                {
                  formatMoney(
                    realCurrentProjectAmount ? realCurrentProjectAmount : 0,
                    ""
                  ).split(".")[0]
                }
              </span>
            </div>
            <div className="pt-4 pb-1 mb-5">
              {currentPaidAmount?.paymentValue?.map((value, index: number) => (
                <PaymentTermsEdit
                  key={index}
                  index={index}
                  value={value}
                  currency={currency}
                  isEditing={isEditing}
                  setCurrentPaidAmount={setCurrentPaidAmount}
                  isLastItem={
                    index === currentPaidAmount?.paymentValue?.length - 1
                  }
                  currentPaidAmount={currentPaidAmount}
                  setCurrentProjectAmount={setCurrentProjectAmount}
                  uuid={uuid}
                  rowId={rowId}
                />
              ))}
              <div className="flex px-0.5 items-center border-t pb-4 text-12 leading-[18px] text-black2 border-y-neutral-800 ">
                <div className="mt-3">
                  {amountOver ? (
                    <span className="mr-[18px] text-red-700">
                      Your Payment Amount is over.
                    </span>
                  ) : null}
                  <span
                    className={` ${amountOver ? "text-red-700" : "text-black2"
                      }  mr-[7px]`}
                  >
                    Total Amt:
                  </span>
                  <span className="mr-[3px]">{currency}</span>
                  <span>
                    {
                      formatMoney(
                        currentProjectAmount ? currentProjectAmount : 0,
                        ""
                      ).split(".")[0]
                    }
                  </span>
                </div>
              </div>

              {isEditing && (
                <div className="pt-1 pb-3 flex items-center text-12 leading-[18px] font-semibold mt-15 border-t text-black2 border-b-black2">
                  <span
                    onClick={discardChanges}
                    className={`${uuid + "child"
                      } py-2 px-4 bg-[#EFEFEF] rounded-md overflow-hidden flex justify-center items-center text-[#979797] mr-[10px]`}
                  >
                    Discard
                  </span>
                  <span
                    onClick={saveChanges}
                    className={`${uuid + "child"} ${amountOver ? "pointer-events-none" : "cursor-pointer"
                      } py-2 px-6 bg-vorpblue text-white rounded-md`}
                  >
                    Save
                  </span>
                </div>
              )}
            </div>
          </div>
        </button>
      )}
    </div>
  );
};

export default PaymentProgressBar;
