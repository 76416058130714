import { redirectToLogin } from './annoucement-sheet';
import { api } from './api';
import authHeader from './auth-header';
import { Ids } from './media';
import { EmployeePC } from './progress-checker-acc';

type ProfileAnnoucementResponse = {
    announsheets: ProfileAnnoucementData,
    module_id: number,
    status: boolean,    
}
export type TaskCategory = {
    id: number;
    name: string;
}
export type ProfileAnnoucements = {
    "id": number,
    "title": string,
    "tags": TaskCategory[],
    "viewers": TaskCategory[],
    "postedDate": string,
    "expiryDate": string,
    "postedBy": number,
    "updatedBy":EmployeePC[],
    "content":string,
    "type":string,
    "is_read":boolean
}
export type ProfileAnnoucementData={
    current_page:number;
    data:ProfileAnnoucements[],
    total:number;
}
type profileAnnoucementInput = {
    sort: string,
    search: string,
    page:number,
    limit:number,
    status:string,
    isexpired:boolean,
}
export const profileAnncApi = api.injectEndpoints({
    endpoints: (build) => ({
        getProfileAnnoucements: build.query<ProfileAnnoucementResponse, profileAnnoucementInput>({
            query: ({ sort, search, limit,page,status,isexpired }) => ({ url: `profile/announcements?sort=${sort}&search=${search}&limit=${limit}&page=${page}&status=${status}&isexpired=${isexpired}`, headers: authHeader() }),
            transformErrorResponse: (err) => {
                if (err?.status === 401) {
                    redirectToLogin();
                }
            },
        }),
    }),
});

export const {
    useGetProfileAnnoucementsQuery
} = profileAnncApi;


export const {
    endpoints: {
        getProfileAnnoucements
    },
} = profileAnncApi;