import React, { useEffect, useMemo, useState } from "react";
import styles from "./Dashboard.module.scss";
import classNames from "classnames";
import DashboardInformation from "../../../components/DashboardInformation/DashboardInformation";
import ProjectChart from "../../../components/ProjectChart/ProjectChart";
import EmployeeChart from "../../../components/EmployeeChart/EmployeeChart";
// import { Select, FormControl } from "@material-ui/core";
// end 24.2.2023
import DashboardCalendarView from "../../../components/DashboardCalendarView/DashboardCalendarView";

import {
  useGetEventsDataQuery,
  useGetAnnoucementDataQuery,
  useGetDashboardCountDataQuery,
  useGetProjectLabelDataQuery,
  ProjectData,
  DatasetArr,
  ProjectLabel,
  useGetEmployeeLabelDataQuery,
  ActivitiesData,
  ProjectDatasetArr,
  CustomProjectData,
  useGetIndicatorsQuery,
  useGetProjectsQuery,
  useGetDocumentLabelDataQuery,
  DocumentChart,
  useGetActivityLogsDataQuery,
  getAuthUser,
} from "../../../app/services/dashboard";
import DashboardOverview from "../../../components/DashboardOverview/DashboardOverview";
import {
  CustomEmployeeList,
  useGetEmployeeListQuery,
} from "../../../app/services/employee";
import { useNavigate } from "react-router-dom";
import MediaSkeleton from "../../../components/MediaFileManager/MediaSkeleton";
const dummyActivities = [
  {
    avatar: "../img/avatar-1.jpg",
    name: "William Cili",
    time: "3m ago",
    message:
      "Outlines keep you honest. Indulging in poorly driving and keep structure Outlines keep you honest. Indulging in poorly driving and keep structure",
    date: "Today 7:51 PM",
  },
  {
    avatar: "../img/avatar-2.jpg",
    name: "Margret Cate",
    time: "3m ago",
    message:
      "Outlines keep you honest. Indulging in poorly driving and keep structure",
    date: "Today 7:51 PM",
  },
  {
    avatar: "../img/avatar-3.jpg",
    name: "Margret Cate",
    time: "3m ago",
    message:
      "Outlines keep you honest. Indulging in poorly driving and keep structure",
    date: "Today 7:51 PM",
  },
  {
    avatar: "../img/profile-image.png",
    name: "Margret Cate",
    time: "3m ago",
    message:
      "Outlines keep you honest. Indulging in poorly driving and keep structure",
    date: "Today 7:51 PM",
  },
  {
    avatar: "../img/avatar-1.jpg",
    name: "William Cili",
    time: "3m ago",
    message:
      "Outlines keep you honest. Indulging in poorly driving and keep structure Outlines keep you honest. Indulging in poorly driving and keep structure",
    date: "Today 7:51 PM",
  },
  {
    avatar: "../img/avatar-2.jpg",
    name: "Margret Cate",
    time: "3m ago",
    message:
      "Outlines keep you honest. Indulging in poorly driving and keep structure",
    date: "Today 7:51 PM",
  },
  {
    avatar: "../img/avatar-3.jpg",
    name: "Margret Cate",
    time: "3m ago",
    message:
      "Outlines keep you honest. Indulging in poorly driving and keep structure",
    date: "Today 7:51 PM",
  },
  {
    avatar: "../img/profile-image.png",
    name: "Margret Cate",
    time: "3m ago",
    message:
      "Outlines keep you honest. Indulging in poorly driving and keep structure",
    date: "Today 7:51 PM",
  },
];
const dummyDocumentData = {
  dataLists: [
    {
      name: "HR",
      total: 1200,
      amount: 1200,
      icon: "../img/hr-icon.svg",
      iconalt: "hr icon",
      color: "#8950FC",
    },
    {
      name: "Frontend",
      total: 1200,
      amount: 1200,
      icon: "../img/pdf-icon.svg",
      iconalt: "frontend icon",
      color: "#FFA800",
    },
    {
      name: "Design",
      total: 2400,
      amount: 2400,
      icon: "../img/design-icon.svg",
      iconalt: "design icon",
      color: "#3699FF",
    },
  ],
  totalDataUse: 4800,
  freeSpace: 512,
};
const renderEventContent = (eventInfo: any) => {
  return (
    <>
      <div className={classNames(styles.eventContent)}>
        {eventInfo.event.title ? (
          <p className={styles.eventtitle}>{eventInfo.event.title}</p>
        ) : (
          ""
        )}
      </div>
      {eventInfo.event.extendedProps.note ? (
        <div className={styles.stickerNote}>
          <span>{eventInfo.event.extendedProps.note}</span>
        </div>
      ) : (
        ""
      )}
      <div className={styles.stickerNoteContent}>
        <span>StickerNode</span>
      </div>
    </>
  );
};
type EventList = {
  title: string;
  date: string;
  note: string;
  eventType: string;
  descritpion: string;
  eventTarget: string;
};
type EventType = {
  id: string;
  name: string;
};

// const eventsList = [
//   {
//     title: "",
//     date: "2023-02-01",
//     note: "note",
//     eventType: "",
//     descritpion: "description",
//     eventTarget: "",
//   },
//   {
//     title: "Chloe",
//     date: "2023-02-01",
//     note: "",
//     eventType: "AL",
//     descritpion: "",
//     eventTarget: "employee",
//   },
//   {
//     title: "Seko",
//     date: "2023-02-01",
//     note: "",
//     eventType: "AL",
//     descritpion: "",
//     eventTarget: "employee",
//   },
//   {
//     title: "Hugo",
//     date: "2023-02-01",
//     note: "",
//     eventType: "AL",
//     descritpion: "",
//     eventTarget: "employee",
//   },
//   {
//     title: "Nang",
//     date: "2023-02-01",
//     note: "",
//     eventType: "AL",
//     descritpion: "",
//     eventTarget: "employee",
//   },
//   {
//     title: "Kimmy",
//     date: "2023-02-01",
//     note: "",
//     eventType: "AL",
//     descritpion: "",
//     eventTarget: "employee",
//   },
//   {
//     title: "Adam",
//     date: "2023-02-01",
//     note: "",
//     eventType: "AL",
//     descritpion: "",
//     eventTarget: "employee",
//   },
//   {
//     title: "Sandy",
//     date: "2023-02-01",
//     note: "",
//     eventType: "AL",
//     descritpion: "",
//     eventTarget: "employee",
//   },
// ];

const Dashboard = (props: any) => {
  const navigate = useNavigate();
  var indexP = -1;
  var indexD = -1;
  props.userData?.permissions?.find((x: any, i: number) => {
    if (x.name == "View_Admin_Dashboard") {
      indexD = i;
    }
  });
  props.userData?.permissions?.find((x: any, i: number) => {
    if (x.name == "View_My_Profile") {
      indexP = i;
    }
  });
  if (indexP != -1 && indexD != -1) {
    if (indexP < indexD) {
      navigate("/profile");
    }
  }
  if (indexD == -1 && indexP != -1) {
    navigate("/profile");
  }

  var fullscreen = props.fullscreen;
  var twoCol = false;
  // project
  var labels = ["January", "February", "March", "April", "May", "June", "July"];
  const monthAbbr = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  var currentDate = new Date();
  var currentMonth = currentDate.getMonth();
  var currentYear = currentDate.getFullYear();
  var curMonthText = monthAbbr[currentMonth];
  const monthLabel = [
    "1 " + monthAbbr[currentMonth],
    "5 " + monthAbbr[currentMonth],
    "10 " + monthAbbr[currentMonth],
    "15 " + monthAbbr[currentMonth],
    "20 " + monthAbbr[currentMonth],
    "25 " + monthAbbr[currentMonth],
    "30 " + monthAbbr[currentMonth],
  ];
  const yearLabel = monthAbbr;
  const weekLabel = ["1", "2", "3", "4"];
  //labels = monthLabel;
  const [projectData, setProjectData] = useState<ProjectData>({
    labels: [],
    datasets: [],
  });
  // employee
  const [emLabel, setemLabel] = useState<ProjectData>({
    labels: [],
    datasets: [],
  });
  // Date.prototype.getWeek = function () {
  //   var onejan = new Date(this.getFullYear(), 0, 1);
  //   return Math.ceil(((this - onejan) / 86400000 + onejan.getDay() + 1) / 7);
  // };

  // var weekNumber = new Date().getWeek();
  const getWeekNumber = (
    currentDate: Date,
    monthIndex: number,
    dateInex: number
  ) => {
    var startDate = new Date(currentDate.getFullYear(), monthIndex, dateInex);
    var diff: number = currentDate.getTime() - startDate.getTime();
    var days = Math.floor(diff / (24 * 60 * 60 * 1000));
    var weekNumber = Math.ceil(days / 7);
    return weekNumber;
  };
  var currentDate = new Date();
  var weekNumber = getWeekNumber(currentDate, 0, 1);
  const [year, setYear] = useState<number>(currentDate.getFullYear());
  const [month, setMonth] = useState<number>(currentDate.getMonth() + 1);
  const [week, setWeek] = useState<number>(weekNumber);

  const [pyear, setPYear] = useState<number>(currentDate.getFullYear());
  const [pmonth, setPMonth] = useState<number>(currentDate.getMonth() + 1);
  const [pweek, setPWeek] = useState<number>(weekNumber);

  const [dataLabels, setDataLabels] = useState(monthLabel);
  const [dataView, setDataView] = useState("year");
  const [dataLabelsEmployee, setDataLabelsEmployee] = useState<string[]>([]);
  const [dataViewEmployee, setDataViewEmployee] = useState("year");
  const [activitieData, setActivities] =
    useState<ActivitiesData[]>(dummyActivities);
  const { data: documentChartData } = useGetDocumentLabelDataQuery();
  const { data: activityLogsData } = useGetActivityLogsDataQuery();
  const [documentData, setDocumentData] = useState<DocumentChart>();
  const [employeeData, setEmployeeData] = useState<CustomEmployeeList[]>([]);
  const [activityPermis, setActivityPermis] = useState<boolean>(false);

  // const [departmentE, setDepartmentE] = useState<string>("All");
  // const [nationalityE, setNationalityE] = useState<string>("All");
  // const [statusE, setStatusEE] = useState<string>("All");
  // const [limitE, setLimitE] = useState<number>(100);

  var getUser = getAuthUser();
  const getAuthPermis: string[] = useMemo(() => [], []);
  if (getUser?.permissions) {
    getUser?.permissions?.forEach((per: any) => {
      getAuthPermis.push(per?.name);
    });
  }

  const options = { pollingInterval: 1000 };
  const { data, isLoading: eventLoading } = useGetEventsDataQuery();
  // const { data: eventsDataList } = useGetEventsListQuery(defaultEvent);
  const { data: indicators } = useGetIndicatorsQuery();
  const { data: projectList, isLoading: pjLoading } = useGetProjectsQuery();
  //const { data: ActivitiesData } = useGetActivitiesDataQuery();
  const { data: AnnoucementData } = useGetAnnoucementDataQuery();
  //const { data: DocumentsData } = useGetDocumentDataQuery();
  const { data: dashboardCountData } = useGetDashboardCountDataQuery();
  const { data: projectLabel } = useGetProjectLabelDataQuery({
    option: dataView,
    year: pyear,
    month: pmonth,
    week: pweek,
  });
  const { data: employeeLabel } = useGetEmployeeLabelDataQuery({
    option: dataViewEmployee,
    year: year,
    month: month,
    week: week,
  });

  const { data: employeeListData, isLoading: empLoading } =
    useGetEmployeeListQuery();
  const hangleChangeView = (target: string) => {
    setDataView(target);
  };
  const hangleChangeViewEmployee = (target: string) => {
    setDataViewEmployee(target);
  };
  const bgColors = ["#3699FF", "#FFA800", "#4FC9DA","#f64e60","#199791","#ca73e0","#de4296"];
  const bgColorsEmp = ["#E1F0FF", "#3699FF", "#FFA800", "#4FC9DA","#f64e60","#199791","#ca73e0","#de4296"];
  const bgColorsEmpBorder = ["#E1F0FF", "#3699FF", "#FFA800", "#4FC9DA","#f64e60","#199791","#ca73e0","#de4296"];
  useEffect(() => {
    // if(ActivitiesData){
    //   setActivities(ActivitiesData);
    // }
    if (employeeLabel) {
      var labels: string[] = employeeLabel.data.labels;
      var pjData = employeeLabel.data.datasets;
      setDataLabelsEmployee(employeeLabel.data.labels);
      //labels = pjData.map((x) => x.label);
      var employeeLabelData: ProjectData = {
        labels: employeeLabel.data.labels,
        datasets: pjData.map((x: ProjectLabel, i: number) => {
          var ind: number = i;
          if (i > 7) {
            ind = 0;
          }
          var data: DatasetArr = {
            id: i + 1,
            label: x.label,
            data: x.data,
            borderColor: bgColorsEmpBorder[ind],
            backgroundColor: bgColorsEmp[ind],
            borderRadius: 30,
          };
          return data;
        }),
      };
      //console.log("employeeLabelData ", employeeLabelData)
      setemLabel(employeeLabelData);
    }
    if (projectLabel) {
      var projectDataList = { ...projectLabel.data };
      var labels: string[] = [];
      var pjData = projectLabel.data;
      var data: any = pjData[0].label;
      data = data?.map((d: any) => d.replace(" ", ""));
      labels = pjData.map((x: any) => x.label);
      var projectLabelData: CustomProjectData = {
        labels: data,
        name: pjData.map((x: any) => x.name),
        datasets: pjData.map((x: any, i: number) => {
          var data: ProjectDatasetArr = {
            id: i + 1,
            label: x.name,
            data: x.data,
            name: x.name,
            borderColor: bgColors[i],
            backgroundColor: bgColors[i],
            borderRadius: 30,
          };
          return data;
        }),
      };
      setProjectData(projectLabelData);
    }
    if (employeeListData) {
      setEmployeeData(employeeListData?.employeeList);
    }
  }, [
    dataView,
    dataLabels,
    dataViewEmployee,
    dataLabelsEmployee,
    projectLabel,
    employeeLabel,
    employeeListData,
  ]);
  useEffect(() => {
    if (documentChartData) {
      setDocumentData(documentChartData);
    }
  }, [documentChartData]);

  useEffect(() => {
    getAuthUser();
    const filterView = getAuthPermis.filter((f) => f === "View_Activity_Log");
    if (filterView.length > 0) {
      setActivityPermis(true);
    }
  }, [getUser, getAuthPermis]);

  const changeToPrevEmployee = () => {
    changeToPrev("Employee");
  };
  const changeToNextEmployee = () => {
    changeToNext("Employee");
  };
  const changeToPrevProject = () => {
    changeToPrev("Project");
  };
  const changeToNextProject = () => {
    changeToNext("Project");
  };
  const changePrevEmployeeData = () => {
    if (dataViewEmployee == "year") {
      setYear(year - 1);
      // setMonth(0)
      // setWeek(0)
    } else if (dataViewEmployee == "month") {
      var uMonth = month - 1;
      if (uMonth < 1) {
        setYear(year - 1);
        setMonth(12);
        uMonth = 12;
      }
      setMonth(uMonth);
      // setWeek(0);
    } else if (dataViewEmployee == "week") {
      var uWeek = week - 1;
      setWeek(uWeek);
      if (uWeek < 1) {
        setWeek(52);
        setYear(year - 1);
        uWeek = 52;
      }
      setWeek(uWeek);
    }
  };
  const changeNextEmployeeData = () => {
    if (dataViewEmployee == "year") {
      setYear(year + 1);
    } else if (dataViewEmployee == "month") {
      var uMonth = month + 1;
      if (uMonth > 12) {
        setYear(year + 1);
        setMonth(1);
        uMonth = 1;
      }
      setMonth(uMonth);
    } else if (dataViewEmployee == "week") {
      var uWeek = week + 1;
      setWeek(uWeek);
      if (uWeek > 52) {
        setWeek(1);
        setYear(year + 1);
        uWeek = 1;
      }
      setWeek(uWeek);
    }
  };
  const changePrevProjectData = () => {
    if (dataView == "year") {
      setPYear(pyear - 1);
    } else if (dataView == "month") {
      var uMonth = pmonth - 1;
      if (uMonth < 1) {
        setPYear(pyear - 1);
        setPMonth(12);
        uMonth = 12;
      }
      setPMonth(uMonth);
    } else if (dataView == "week") {
      var uWeek = pweek - 1;
      setPWeek(uWeek);
      if (uWeek < 1) {
        setPWeek(52);
        setPYear(pyear - 1);
        uWeek = 52;
      }
      setPWeek(uWeek);
    }
  };
  const changeNextProjectData = () => {
    if (dataView == "year") {
      setPYear(pyear + 1);
    } else if (dataView == "month") {
      var uMonth = pmonth + 1;
      if (uMonth > 12) {
        setPYear(pyear + 1);
        setPMonth(1);
        uMonth = 1;
      }
      setPMonth(uMonth);
    } else if (dataView == "week") {
      var uWeek = pweek + 1;
      setPWeek(uWeek);
      if (uWeek > 52) {
        setPWeek(1);
        setPYear(pyear + 1);
        uWeek = 1;
      }
      setPWeek(uWeek);
    }
  };
  const changeToPrev = (type: string) => {
    if (type == "Project") {
      changePrevProjectData();
    } else if (type == "Employee") {
      changePrevEmployeeData();
    }
  };
  const changeToNext = (type: string) => {
    if (type == "Project") {
      changeNextProjectData();
    } else if (type == "Employee") {
      changeNextEmployeeData();
    }
  };

  return (
    <>
      <div className={classNames(fullscreen == true ? "hidden" : "")}>
        {eventLoading || empLoading || pjLoading ? (
          <div
            role="status"
            className="space-y-8 animate-pulse md:space-y-0 md:space-x-8 md:flex md:items-center mb-2"
          >
            <div className="flex items-center justify-center w-36 h-40 bg-gray-300 rounded sm:w-1/4">
              <div className="w-9 h-10 text-gray-200"></div>
            </div>
            <div className="flex items-center justify-center w-36 h-40 bg-gray-300 rounded sm:w-1/4">
              <div className="w-9 h-10 text-gray-200"></div>
            </div>
            <div className="flex items-center justify-center w-36 h-40 bg-gray-300 rounded sm:w-1/4">
              <div className="w-9 h-10 text-gray-200"></div>
            </div>
            <div className="flex items-center justify-center w-36 h-40 bg-gray-300 rounded sm:w-1/4">
              <div className="w-9 h-10 text-gray-200"></div>
            </div>
          </div>
        ) : (
          <DashboardInformation
            dashboardCountData={
              dashboardCountData ? dashboardCountData?.listData : []
            }
          />
        )}
        <div className="flex flex-wrap lg:flex-nowrap">
          <div className="w-full pr-0 lg:w-1/2 lg:pr-[12px]">
            {eventLoading || empLoading || pjLoading ? (
              <div
                role="status"
                className="max-w-sm p-4 border border-gray-200 rounded shadow animate-pulse md:p-6 dark:border-gray-300"
              >
                <div className="flex items-baseline mt-4 space-x-5">
                  <div className="w-5 bg-gray-200 rounded-t-lg h-72 dark:bg-gray-300"></div>
                  <div className="w-10 h-56 bg-gray-200 rounded-t-lg dark:bg-gray-300"></div>
                  <div className="w-3 bg-gray-200 rounded-t-lg h-72 dark:bg-gray-300"></div>
                  <div className="w-11 h-64 bg-gray-200 rounded-t-lg dark:bg-gray-300"></div>
                  <div className="w-7 bg-gray-200 rounded-t-lg h-80 dark:bg-gray-300"></div>
                  <div className="w-7 bg-gray-200 rounded-t-lg h-80 dark:bg-gray-300"></div>
                  <div className="w-9 bg-gray-200 rounded-t-lg h-72 dark:bg-gray-300"></div>
                  <div className="w-7 bg-gray-200 rounded-t-lg h-80 dark:bg-gray-300"></div>
                  <div className="w-14 bg-gray-200 rounded-t-lg h-80 dark:bg-gray-300"></div>
                  <div className="w-7 bg-gray-200 rounded-t-lg h-80 dark:bg-gray-300"></div>
                </div>
                <span className="sr-only">Loading...</span>
              </div>
            ) : (
              <ProjectChart
                changeToPrev={changeToPrevProject}
                changeToNext={changeToNextProject}
                bgColors={bgColors}
                dataView={dataView}
                hangleChangeView={hangleChangeView}
                projectData={projectData}
                pYear={pyear}
                pMonth={pmonth}
                pWeek={pweek}
                projectLabel={projectLabel?.data[0]?.label}
              />
            )}
          </div>
          <div className="w-full lg:w-1/2 pl-0 lg:pl-[12px] mt-5 lg:mt-0">
            {eventLoading || empLoading || pjLoading ? (
              <div
                role="status"
                className="max-w-sm p-4 border border-gray-200 rounded shadow animate-pulse md:p-6 dark:border-gray-300 m-t-2"
              >
                
                <div className="flex items-baseline mt-4 space-x-5">
                  <div className="w-7 bg-gray-200 rounded-t-lg h-10 dark:bg-gray-300"></div>
                  <div className="w-1 bg-gray-200 rounded-t-lg h-72 dark:bg-gray-300"></div>
                  <div className="w-4 h-56 bg-gray-200 rounded-t-lg dark:bg-gray-300"></div>
                  <div className="w-3 bg-gray-200 rounded-t-lg h-72 dark:bg-gray-300"></div>
                  <div className="w-8 h-64 bg-gray-200 rounded-t-lg dark:bg-gray-300"></div>
                  <div className="w-7 bg-gray-200 rounded-t-lg h-80 dark:bg-gray-300"></div>
                  <div className="w-9 bg-gray-200 rounded-t-lg h-72 dark:bg-gray-300"></div>
                  <div className="w-2 bg-gray-200 rounded-t-lg h-56 dark:bg-gray-300"></div>
                  <div className="w-6 bg-gray-200 rounded-t-lg h-80 dark:bg-gray-300"></div>
                  <div className="w-4 bg-gray-200 rounded-t-lg h-40 dark:bg-gray-300"></div>
                  <div className="w-1 bg-gray-200 rounded-t-lg h-36 dark:bg-gray-300"></div>
                  <div className="w-2 bg-gray-200 rounded-t-lg h-52 dark:bg-gray-300"></div>
                  <div className="w-3 bg-gray-200 rounded-t-lg h-28 dark:bg-gray-300"></div>
                  <div className="w-2 bg-gray-200 rounded-t-lg h-36 dark:bg-gray-300"></div>
                  <div className="w-4 bg-gray-200 rounded-t-lg h-44 dark:bg-gray-300"></div>
                </div>
                <span className="sr-only">Loading...</span>
              </div>
            ) : (
              <EmployeeChart
                changeToPrev={changeToPrevEmployee}
                changeToNext={changeToNextEmployee}
                emLabel={emLabel}
                dataViewEmployee={dataViewEmployee}
                hangleChangeViewEmployee={hangleChangeViewEmployee}
                pYear={year}
                pMonth={month}
                pWeek={week}
                employeeLabel={employeeLabel?.data?.labels}
              />
            )}
          </div>
        </div>
        <div className="mt-5 mb-6">
          {eventLoading || empLoading || pjLoading ? (
            <div
              role="status"
              className="space-y-8 animate-pulse md:space-y-0 md:space-x-8 md:flex md:items-center mb-2"
            >
              <div className="flex items-center justify-center w-36 h-96 bg-gray-300 rounded sm:w-2/6">
                <div className="w-9 h-10 text-gray-200"></div>
              </div>
              <div className="flex items-center justify-center w-36 h-96 bg-gray-300 rounded sm:w-2/6">
                <div className="w-9 h-10 text-gray-200"></div>
              </div>
              <div className="flex items-center justify-center w-36 h-96 bg-gray-300 rounded sm:w-2/6">
                <div className="w-9 h-10 text-gray-200"></div>
              </div>
            </div>
          ) : (
            <DashboardOverview
              // dataLists={data?.dataLists}
              // totalDataUse={data?.totalDataUse}
              // freeSpace={data?.freeSpace}
              docs={documentData ? documentData : []}
              // activitiesData={activitieData ? activitieData : []}
              activitiesData={
                activityLogsData?.activity_logs
                  ? activityLogsData?.activity_logs
                  : []
              }
              annoucementData={
                AnnoucementData ? AnnoucementData?.announcementData : []
              }
              twoCol={twoCol}
              activityPermis={activityPermis}
            ></DashboardOverview>
          )}
        </div>
      </div>
      {eventLoading || empLoading || pjLoading ? (
        <MediaSkeleton isCalendar={true} />
      ) : (
        <DashboardCalendarView
          data={data}
          setLeaveTypesData={props.setLeaveTypesData}
          handlePopup={props.handlePopup}
          fullscreen={fullscreen}
          checkFullScreen={props.checkFullScreen}
          eventsList={[]}
          eventType={data?.eventType}
          employeeData={employeeData}
          projectList={projectList?.data}
          indicators={indicators?.data}
        />
      )}
    </>
  );
};
export default Dashboard;
