import React, { FC } from "react";
import classNames from "classnames";
import styles from "./DashboardInformation.module.scss";
import employeeicon from "./img/employeeicon.svg";
import projectsicon from "./img/projectsicon.svg";
import paymenticon from "./img/paymenticon.svg";
import applicantsicon from "./img/applicantsicon.svg";
import { DashboardCountData } from "../../app/services/dashboard";
interface IDashboardCountData {
  dashboardCountData?: DashboardCountData[];
}

const DashboardInformation: FC<IDashboardCountData> = ({
  dashboardCountData = [],
}) => {
  return (
    <>
      <div
        className={classNames(styles.informationcard, "pt-0 pb-3 font-primary")}
      >
        {/* <p className="font-primary text-black2 text-20 font-medium text-left">
          Hi <span className="text-vorpblue">Admin</span>, welcome to Dashboard
        </p> */}
        <div
          className={classNames(
            styles.informationcard__card,
            "sm:flex grid grid-cols-2 flex-row items-stretch mt-5"
          )}
        >
          {dashboardCountData.map((dashboard, index) => {
            var bgColor="bg-white";
            var icon=employeeicon;
            if(dashboard.label=="Projects"){
              bgColor="bg-vorpblue";
              icon=projectsicon;
            }
            else if(index==2){
              bgColor="bg-vorinfored";
              icon=paymenticon;
            }
            else if(dashboard.label=="Applicants"){
              bgColor="bg-vorinfoblack";
              icon=applicantsicon;
            }
            return (
              <div key={index}
                className={classNames(
                  styles.informationcard__card__item,
                  bgColor,
                  "flex flex-col py-[15px] px-[15px] md:px-[30px] rounded-[12px] text-left sm:w-1/4 mr-[20px] sm:mr-[24px]"
                )}
              >
                <img src={icon} alt="alt" className="w-[26px]" />
                <p
                  className={classNames(dashboard.label=="Employees"?"text-black2":"text-white"," text-[25px] font-bold mt-[25px]")}
                  //key={index}
                >
                  {index==2?Math.ceil(dashboard.total)+'%':dashboard.total}
                </p>
                <p
                  className={classNames(dashboard.label=="Employees"?"text-black2":"text-white","text-vorpgraylight text-12 font-semibold")}
                  //key={index}
                >
                  {dashboard.label}
                </p>
              </div>
            );
          })}
          {/* <div
            className={classNames(
              styles.informationcard__card__item,
              "bg-white flex flex-col py-[15px] px-[15px] md:px-[30px] rounded-[12px] text-left sm:w-1/4 mr-[20px] sm:mr-[24px]"
            )}
          >
            <img src={employeeicon} alt="alt" className="w-[26px]" />
            {props.EmployeeListsTotal?.employeeTotal?.map(
              (emp: any, idx: any) => {
                return (
                  <>
                    <p
                      className="text-black2 text-[25px] font-bold mt-[25px]"
                      key={idx}
                    >
                      {emp.total}
                    </p>
                    <p
                      className="text-vorpgraylight text-12 font-semibold"
                      key={idx}
                    >
                      {emp.label}
                    </p>
                  </>
                );
              }
            )}
          </div>
          <div
            className={classNames(
              styles.informationcard__card__item,
              "bg-vorpblue flex flex-col py-[15px] px-[15px] md:px-[30px] rounded-[12px] text-left sm:w-1/4 sm:mr-[24px]"
            )}
          >
            <img src={projectsicon} alt="alt" className="w-[22px]" />
            {props.ProjectListsTotal?.projectTotal?.map(
              (emp: any, idx: any) => {
                return (
                  <div id={idx}>
                    <p
                      className="text-white text-[25px] font-bold mt-[25px]"
                      key={idx}
                    >
                      {emp.total}
                    </p>
                    <p className="text-white text-12 font-semibold" key={idx}>
                      {emp.label}
                    </p>
                  </div>
                );
              }
            )}
          </div>
          <div
            className={classNames(
              styles.informationcard__card__item,
              "bg-vorinfored flex flex-col py-[15px] px-[15px] md:px-[30px] rounded-[12px] text-left sm:w-1/4 mr-[20px] sm:mr-[24px]"
            )}
          >
            <img src={paymenticon} alt="alt" className="w-[22px]" />
            {props.PaymentListsTotal?.paymentTotal?.map(
              (emp: any, idx: any) => {
                return (
                  <div id={idx}>
                    <p
                      className="text-white text-[25px] font-bold mt-[25px]"
                      key={idx}
                    >
                      {emp.total}
                    </p>
                    <p className="text-white text-12 font-semibold" key={idx}>
                      {emp.label}
                    </p>
                  </div>
                );
              }
            )}
          </div>
          <div
            className={classNames(
              styles.informationcard__card__item,
              "bg-vorinfoblack flex flex-col py-[15px] px-[15px] md:px-[30px] rounded-[12px] text-left sm:w-1/4"
            )}
          >
            <img src={applicantsicon} alt="alt" className="w-[22px]" />
            {props.ApplicantListsTotal?.applicantTotal?.map(
              (emp: any, idx: any) => {
                return (
                  <div id={idx}>
                    <p
                      className="text-white text-[25px] font-bold mt-[25px]"
                      key={idx}
                    >
                      {emp.total}
                    </p>
                    <p className="text-white text-12 font-semibold" key={idx}>
                      {emp.label}
                    </p>
                  </div>
                );
              }
            )}
          </div> */}
        </div>
      </div>
    </>
  );
};
export default DashboardInformation;
