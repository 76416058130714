import React, { useState } from "react";
import styles from "./ForgetPasswordComponent.module.scss";

import { useNavigate } from 'react-router-dom';
import logo from '../../login/img/companyLogo.svg';
import bglogin from '../../login/img/loginbg.svg';
import axios from "axios";
import { endpointUrl } from "../../app/services/api";
var classNames = require("classnames");



const ForgetPasswordComponent = () => {
  const navigate = useNavigate();
  const [errorMessages, setErrorMessages] = useState({ name: "", message: "" });
  //const [isSubmitted, setIsSubmitted] = useState(false);
  const [loginError, setLoginError] = useState("");

  const renderErrorMessage = (name: any) => {
    return name == errorMessages.name && (
      <div className="text-red-500 text-15 font-primary mt-1 text-left">{errorMessages.message}</div>
    );
  }
  const handleSubmit = (event: any) => {
    // Prevent page reload
    event.preventDefault();
    var { email } = document.forms[0];

    var values = {
      "email": email.value,
    }
    var data = {
      "token": 'token',
      "email": email.value,
      "role": ""
    }

    axios
      .post(endpointUrl + "password/forgot", {
        email: email.value
      })
      .then(function (response) {
        // console.log(JSON.parse(response.config.data))
        navigate('/emailsent');
      }).catch((reason) => {
        console.log("error", reason.response.data.message)
        setLoginError(reason.response.data.message)
      })
    // Find user login info
    // const userData = DBusers.find((user) => user.email == email.value);
    // // Compare user info
    // if (userData) {
    //   navigate('/emailsent');
    // } else {
    //   // email not found
    //   setErrorMessages({ name: "email", message: errors.email });
    // }
    //navigate('/emailsent');
  };
  const renderForm = (
    <form onSubmit={handleSubmit} className="w-full htzxs:w-[440px] px-5 htzxs:px-0 ">
      <div className={classNames("flex justify-start flex-col w-full", styles.signinform)}>
        <h3 className="text-darkblue text-25 sm:text-30 font-semibold leading-[1.2]">Forgot Password?</h3>
        <h6 className="font-primary font-normal text-black2 text-15 mt-[24px] leading-[1.2]">Please fill in your Email Address. After submitting, you email will receive a password reset email.</h6>
        <div className={classNames(styles.inputContainer, "mb-[36px] last:mb-0")}>
          <label className="text-black2 text-15 font-medium my-[24px] block text-left">Your Email</label>
          <input type="text" name="email" required className="h-[70px] placeholder:text-black2 placeholder:text-14 text-black2 rounded-[9px] px-[24px] w-full focus:outline-none" placeholder='visibee@visibleone.com' style={{ border: errorMessages?.name == 'email' ? '1px solid red' : "" }} />
          {renderErrorMessage("email")}
        </div>
        <div className={classNames(styles.buttonContainer)}>
          <button type="submit" className="transition-all hover:bg-white hover:text-vorpblue border border-vorpblue bg-vorpblue text-15 text-white w-full flex items-center justify-center rounded-[6px] py-[0.8rem] font-semibold font-primary">Submit</button>
        </div>
      </div>
      <div className="text-red-500 text-15 font-primary mt-3">
        {loginError}
      </div>
    </form>
  )
  return (
    <div
      className={classNames(
        "flex items-stretch h-screen font-primary flex-col md:flex-row",
        styles.logincomponent
      )}
    >
      <div
        className={classNames(
          styles.leftLogoBox,
          "w-full md:w-2/5 bg-bottom 7xl:bg-auto py-[50px] md:pt-[223px] md:pb-0 bg-no-repeat"
        )}
        style={{ backgroundImage: `url(${bglogin})` }}
      >
        <img
          src={logo}
          alt="logo"
          className={classNames("mx-auto", styles.companylogo)}
        />
        <p className="text-vorpgray text-10 md:text-12 xl:text-16 4xl:text-18 font-semibold">
          Visible One Internal Operation System
        </p>
      </div>
      <div
        className={classNames(
          styles.rightLoginBox,
          "flex items-center justify-center w-full md:w-3/5 bg-offwhite"
        )}>
        {renderForm}
      </div>
    </div>
  )
}

export default ForgetPasswordComponent;