import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import {
  CloseIcon,
  ConfirmIcon,
  RemoveUserIcon,
  SearchIcon,
  SelectedIcon,
} from "../common/Icons";
import { baseObj, departments } from "../../utils/constants";
import UserSelectionTab from "./UserSelectionTab";
import {
  // EmployeeList,
  PasswordUserList,
  // assignUsers,
  // useGetEmployeeListQuery,
} from "../../app/services/password";
import styles from "./PasswordsTable.module.scss"; 
import classNames from "classnames";
import { EmployeeData } from "../EmployeeTable/EmployeeTable";
import DebounceSearchInput from "../Tables/DebounceSearchInput";
import CustomDropdownListWithSearch from "../EmployeeTable/CustomDropdownListWithSearch";
// import { StringMultipleSelectBox } from "../common/MultipleSelectBox";
// import CustomDropdownListForFilter from "../EmployeeTable/CustomDropdownListForFilter";
// import { StringDropdownListWithDeleteBtn } from "../common/StringDropdownListWithDeleteBtn";
// import CustomDropdownListForFilter from "../EmployeeTable/CustomDropdownListForFilter";

interface IEmployeeAssignment {
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  modalType: "remove" | "assign";
  assignedUsers?: any;
  // employeeList?: PasswordUserList[];
  employeeList?: baseObj[];
  existingMember?: PasswordUserList[];
  setSelectedMemberData?: any;
  assignedUsersMultipleDoc?: any;
  isMultiple?: boolean;
  isOpen?: boolean;
  title?: string;
  showInfo?: boolean;
  departmentList?: EmployeeData[];
  setAssignDepartmentId?: React.Dispatch<React.SetStateAction<string>>;
  setAssignKeword?: React.Dispatch<React.SetStateAction<string>>;
  assignDepartmentId?: string;
  isAnnounc?: boolean;
  isDepartment?: boolean;
}

const tags = departments
  .map((department) => ({
    label: department,
    value: department,
  }))
  .filter((tag) => tag.label.toLowerCase() !== "all");
 
const EmployeeAssignment: FC<IEmployeeAssignment> = ({
  setIsOpen,
  modalType,
  assignedUsers,
  employeeList,
  existingMember,
  setSelectedMemberData,
  isMultiple,
  assignedUsersMultipleDoc,
  isOpen,
  title,
  showInfo,
  departmentList,
  setAssignDepartmentId,
  assignDepartmentId,
  setAssignKeword,
  isAnnounc,
  isDepartment,
}) => {
  const [searchText, setSearchText] = useState<string>("");
  const [deaprtmentList, setDepartmentList] = useState<EmployeeData[]>(
    departmentList ?? []
  );
  const [assignDepartment, setAssignDepartment] = useState<string>("");
  const [isSelectedAll, setIsSelectedAll] = useState<boolean>(
    existingMember?.length == employeeList?.length
  );
  const [isClear, setIsClear] = useState<boolean>(false);
  const [employeeListData, setEmployeeListData] = useState<any[] | undefined>(
    []
  ); //employeeList
  const [empList, setEmpList] = useState<any>(employeeList);
  const [selectedMember, setSelectedMember] = useState<number[]>(
    existingMember ? existingMember.map((x) => x.id) : []
  );
  const setSelectedCount = (id: number, isChosen: boolean) => {
    var alreadySelected = selectedMember.find((x) => x == id);
    var allData = [];
    if (!alreadySelected) {
      allData = [...selectedMember, id];
      setSelectedMemberData([...selectedMember, id]);
      setSelectedMember([...selectedMember, id]);
    } else {
      allData = selectedMember.filter((x) => x != id);
      setSelectedMember(selectedMember.filter((x) => x != id));
      setSelectedMemberData(selectedMember.filter((x) => x != id));
    }
    if (allData.length == empList.length) {
      setIsSelectedAll(true);
    } else {
      setIsSelectedAll(false);
    }
  };

  useEffect(() => {
    if (searchText != "" && isAnnounc) {
      var data = employeeList?.filter((x) =>
        x.name.toUpperCase().includes(searchText.toUpperCase())
      );

      setEmployeeListData(data);
    } else {
      // setEmployeeListData(employeeList);
      const matchingObjects = employeeList?.filter((obj1) =>
        existingMember?.some((obj2) => obj2.id === obj1.id)
      );
      const nonMatchingObjects = employeeList?.filter(
        (obj1) => !existingMember?.some((obj2) => obj2.id === obj1.id)
      );
      if (matchingObjects && nonMatchingObjects) {
        setEmployeeListData([...matchingObjects, ...nonMatchingObjects]);
      }
    }
  }, [selectedMember, employeeList, searchText]);

  useEffect(() => {
    if (employeeList) {
      // setEmployeeListData(employeeList);
      const matchingObjects = existingMember?.filter((obj1) =>
        employeeList?.some((obj2) => obj2.id === obj1.id)
      );
      const nonMatchingObjects = employeeList.filter(
        (obj1) => !existingMember?.some((obj2) => obj2.id === obj1.id)
      );
      if (matchingObjects && nonMatchingObjects) {
        setEmployeeListData([...matchingObjects, ...nonMatchingObjects]);
      }

      setEmpList(employeeList);
    }
  }, [employeeList]);

  const setClearAllSelectedData = () => {
    setSelectedMember([]);
    setIsSelectedAll(false);
    setIsClear(true);
  };

  const setSelectedAll = () => {
    var isAll = !isSelectedAll;
    setIsSelectedAll(isAll);
    if (isAll) {
      setSelectedMember(empList.map((x: any) => x.id));
    } else {
      setSelectedMember([]);
    }
  };

  return (
    <div
      className={classNames(
        "modalContent transition-all  max-w-[530px] max-h-[98vh] overflow-y-auto xl:max-h-none w-full m-auto px-4 py-8 xl:px-0 xl:py-0 font-primary"
      )}
    >
      <div className="w-full bg-white rounded-xl overflow-hidden">
        <div className="px-6 sm:px-8 md:px-10 py-4 md:py-6 bg-vorpblue flex justify-between items-center rounded-t-xl overflow-hidden">
          <h1 className="text-16 sm:text-18 leading-[27px] font-medium text-white">
            {title ? title : "Assign Members"}
          </h1>
          <button type="button" onClick={() => setIsOpen(false)}>
            <CloseIcon width={18} height={18} />
          </button>
        </div>
        <div className="bg-white p-4 xs:p-6 sm:p-8 md:px-10 md:py-9">
          <div
            className={classNames(
              showInfo == false ? "hidden" : "",
              "border-b pb-[14px] border-[#F5F5F5] flex flex-col 4xs:flex-row justify-between 4xs:items-center"
            )}
          >
            <h1 className="text-[#5E6278] text-18 md:text-24 leading-9 font-semibold">
              Members
            </h1>
            <div className="flex items-start justify-end">
              <p className="text-12 md:text-15 leading-[22px] text-vorpblue mr-[10px]">
                Selected members ({selectedMember.length})
              </p>
              <button
                onClick={() => setClearAllSelectedData()}
                type="button"
                className="text-14 md:text-15 leading-[22px] text-black2"
              >
                Clear
              </button>
            </div>
          </div>
          <div className="pt-5 flex flex-row gap-x-2">
            <div
              className={classNames(
                isAnnounc ? "w-full" : "max-w-[210px]",
                "flex border border-vorpgraylight  rounded overflow-hidden mb-5"
              )}
            >
              {/* <input
                type="text"
                className="p-3 py-[14px] text-12 sm:text-14 leading-5 text-goalinputborder placeholder:text-vorpgraylight w-full max-w-[200px] focus-within:outline-none focus:outline-none"
                placeholder="Search"
                value={searchText}
                onChange={(e) => {
                  setSearchText(e.target.value);
                  if(setAssignKeword){
                    setAssignKeword(e.target.value);
                  }
                }}
                onBlur={(e) => setSearchText(e.target.value)}
              /> */}
              <DebounceSearchInput
                setState={setAssignKeword ?? setAssignDepartment}
                debounce={800}
              >
                {(setState) => (
                  <div className="font-normal flex w-full bg-white hover:bg-[#EFEFEF] ">
                    <input
                      type="text"
                      className={classNames(
                        isAnnounc ? "w-full" : "max-w-[200px]",
                        "p-3 py-[14px] text-12 sm:text-14 leading-5 text-goalinputborder placeholder:text-vorpgraylight w-full focus-within:outline-none focus:outline-none"
                      )}
                      placeholder="Search"
                      onChange={(e) => {
                        setState(e.target.value);
                        setSearchText(e.target.value);
                      }}
                    />
                  </div>
                )}
              </DebounceSearchInput>
              <button type="button" className="pr-3">
                <SearchIcon width={24} height={24} />
              </button>
            </div>
            {!isAnnounc ? (
              // <StringDropdownListWithDeleteBtn
              //   haveDeleteBtn={false}
              //   isDepartment={true}
              //   state={assignDepartmentId ?? ""}
              //   setState={setAssignDepartmentId ?? setAssignDepartment}
              //   dropdownItems={deaprtmentList.map((x: any, index: number) => {
              //     return {
              //       label: x.name,
              //       value: x.id,
              //     };
              //   })}
              //   className="w-full min-w-[210px] min-h-[50px] 6xs:text-12"
              //   placeholder="Allhh"
              //   textAlign="text-left py-2 pl-2 pr-3"
              //   custPadding="py-[2px]"
              // />
              <CustomDropdownListWithSearch
                isFilter={true}
                state={assignDepartmentId ?? ""}
                setState={setAssignDepartmentId ?? setAssignDepartment}
                dropdownItems={
                  deaprtmentList
                    ? deaprtmentList.map((dp) => ({
                      value: dp.id,
                      label: dp.name,
                    }))
                    : []
                }
                className=" 2xl:w-32 w-28 min-w-[100px] 6xs:text-12"
                placeholder=""
                textAlign="text-left py-2 pl-2 pr-3"
              />
            ) : null}

            {/* <div className="w-full mb-5">
              <label htmlFor="" className="base-label mb-2 block">
                Tag
              </label>
              <StringMultipleSelectBox
                state={chosenTags}
                setState={setChosenTags}
                options={tags}
                className="border-goalinputborder 6xs:text-goalinputborder text-14"
              />
            </div> */}
          </div>
          <label
            htmlFor="check-all"
            className={classNames(
              showInfo == false ? "hidden" : "",
              "flex mb-5 items-center hover:cursor-pointer"
            )}
          >
            <div className="relative max-h-6">
              <input
                type="checkbox"
                onChange={() => setSelectedAll()}
                checked={isSelectedAll}
                id="check-all"
                className="checked:bg-vorpblue w-3 h-3 sm:w-4 sm:h-4 p-3 border-none outline-none appearance-none bg-offwhite rounded hover:cursor-pointer"
              />
              {isSelectedAll && (
                <SelectedIcon className="absolute top-2 left-0 right-0 m-auto" />
              )}
            </div>
            <p className="text-14 leading-5 text-[#3F4254] ml-3">Select All</p>
          </label>
          <div className="max-h-[150px] sm:max-h-[200px] 7xl:max-h-[300px] overflow-y-auto mb-7 pr-3">
            <h2 className="text-14 leading-5 text-[#5E6278] font-semibold mb-[23px]">
              {employeeListData ? employeeListData.length : 0} members
            </h2>
            <div>
              {employeeListData?.map((emp, index: number) => {
                var className = selectedMember.find((x) => x == emp.id)
                  ? styles.selectedMember
                  : "";
                return (
                  <UserSelectionTab
                    setSelectedMember={setSelectedCount}
                    id={emp.id}
                    index={index}
                    key={index}
                    className={"mb-3 " + className}
                    name={emp.name}
                    email={emp.email}
                    initialState={isSelectedAll}
                    // icon={emp.profile_image}
                    icon={emp.photo}
                    isSelected={isClear}
                    setIsClear={setIsClear}
                    selectedMember={selectedMember}
                  />
                );
              })}
            </div>
          </div>
          {showInfo == false ? (
            <button
              type="button"
              onClick={() => setIsOpen(false)}
              className="px-3 py-[8.5px] text-14 leading-5 font-medium flex items-center  text-black2 rounded-md overflow-hidden bg-offwhite
            "
            >
              <CloseIcon
                color="#464E5F"
                width={16}
                height={16}
                className="pr-[6px]"
              />
              Close
            </button>
          ) : (
            <div className="flex items-center">
              {modalType == "assign" ? (
                <button
                  onClick={() => {
                    !isMultiple
                      ? assignedUsers(selectedMember)
                      : assignedUsersMultipleDoc(selectedMember);
                  }}
                  className="px-3 mr-[10px] py-[8.5px] text-14 leading-5 font-medium flex items-center group hover:bg-vorpmyanmar text-white hover:text-vorpblue bg-vorpblue rounded-md overflow-hidden transition-all duration-300"
                >
                  <ConfirmIcon
                    className="pr-[6px]"
                    pathClassName="group-hover:fill-vorpblue"
                  />
                  Confirm
                </button>
              ) : (
                <button
                  onClick={() => {
                    !isMultiple
                      ? assignedUsers(selectedMember)
                      : assignedUsersMultipleDoc(selectedMember);
                  }}
                  className="px-3 mr-[10px] py-[8.5px] text-14 leading-5 font-medium flex items-center group hover:bg-vorpmyanmar text-white hover:text-vorpblue bg-vorpblue rounded-md overflow-hidden transition-all duration-300"
                >
                  <RemoveUserIcon
                    className="pr-[6px]"
                    pathClassName="group-hover:fill-vorpblue"
                  />
                  Assign User
                </button>
              )}
              <button
                type="button"
                onClick={() => setIsOpen(false)}
                className="px-3 py-[8.5px] text-14 leading-5 font-medium flex items-center  text-black2 rounded-md overflow-hidden bg-offwhite"
              >
                <CloseIcon
                  color="#464E5F"
                  width={16}
                  height={16}
                  className="pr-[6px]"
                />
                Cancel
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default EmployeeAssignment;
