import { redirectToLogin } from './annoucement-sheet';
import { api } from './api';
import authHeader from './auth-header';
import { Ids } from './media';

type EmployeeIncentiveResponse = {
    leaveAllowanceList: EmployeeLeaveAllowanceData[],
    module_id: number,
    status: boolean,
}
export type CountryListData = {
    id: number;
    name: string;
    code: string;
    time_zone: string;
}
type EmployeeLeaveAllowanceData = {
    "id": number;
    "code": string;
    "preferred_name": string;
    "joined_date": string;
    "annual_leave_balance": number;
    "birthday_leave_balance": number;
    "sick_leave_balance": number;
    "unpaid_leave_balance": number;
    "maternity_leave_balance": number;
    "paternity_leave_balance": number;
    "substitution_leave_balance": number;
    "left_over_leave_balance": number;
}
type employeeLeaveAllowanceInput = {
    // sorting: string,
    search: string
}
export const leaveAllowanceApi = api.injectEndpoints({
    endpoints: (build) => ({
        getEmployeeLeaveAllowance: build.query<EmployeeIncentiveResponse, employeeLeaveAllowanceInput>({
            query: ({ search }) => ({ url: `leave-allowances?search=${search}`, headers: authHeader() }),
            transformErrorResponse: (err) => {
                if (err?.status === 401) {
                    redirectToLogin();
                }
            },
            providesTags: (result: any = []) => {
                if (result.data) {
                    if (result.data.length > 0) {
                        return [
                            ...result.data.map((id: any) => ({ type: "EmployeeLeaveAllowance", id } as const)),
                            { type: "EmployeeLeaveAllowance" as const, id: "LIST" },
                        ]
                    }
                    else {
                        return [{ type: "EmployeeLeaveAllowance" as const, id: "LIST" },]
                    }
                }
                else {
                    return [{ type: "EmployeeLeaveAllowance" as const, id: "LIST" },]
                }

            },
        }),
    }),
});

export const {
    useGetEmployeeLeaveAllowanceQuery
} = leaveAllowanceApi;


export const {
    endpoints: {
        getEmployeeLeaveAllowance
    },
} = leaveAllowanceApi;