import React, { FC, useEffect, useState } from "react";
// import DatePopupFoFilter from "../EmployeeTable/DatePopupFoFilter";
import { CloseIcon, TrashIcon } from "../common/Icons";
import classNames from "classnames";
import { StringDropdownList } from "../common/DropdownList";
import { baseObj } from "../../utils/constants";
import { renderDateFormatYMD } from "../DashboardCalendarView/DashboardAddEventPopup";
import CustomDatePicker from "../common/FullWidthDatePicker";

export type TKeyDateProject = {
  keyDate: any;
  keyListData: any[];
  setData: any;
  handleKeyDelete: (id: number) => void;
  isSubmit: boolean;
};

const KeyDateProject: FC<TKeyDateProject> = ({
  keyDate,
  keyListData,
  setData,
  handleKeyDelete,
  isSubmit,
}) => {
  const [keyData, setKeyData] = useState<string>("");
  const [importantDate, setImportantDate] = useState<Date | undefined>();

  const handleKeyChange = (value: any) => {
    setKeyData(value);
    setData((prev: baseObj) => {
      return prev.map((data: any) => {
        if (data.id === keyDate.id) {
          return { ...data, key_id: value };
        }
        return data;
      });
    });
  };

  const handleDateKey = (value: any) => {
    let date = renderDateFormatYMD(
      value !== undefined ? new Date(value) : new Date(),
      "-"
    );
    setImportantDate(value);
    setData((prev: baseObj) => {
      return prev.map((data: any) => {
        if (data.id === keyDate.id) {
          return { ...data, key_date: date };
        }
        return data;
      });
    });
  };

  useEffect(() => {
    setImportantDate(
      keyDate?.key_date !== undefined ? new Date(keyDate?.key_date) : undefined
    );
    setKeyData(keyDate?.key_id !== "" ? keyDate?.key_id : 0);
  }, [keyDate]);

  return (
    <div className="flex flex-col w-full justify-start items-center ">
      <div className="flex mb-0 w-full justify-between items-center">
        <div className="mb-3 flex items-center w-full max-w-[300px] mr-3">
          <div className="flex items-center w-full">
            {/* {importantDate && (
              <button
                type="button"
                onClick={() => {
                  setImportantDate(undefined);
                }}
                className="text-12 mr-2"
              >
                <CloseIcon color="#EE436D" width={10} height={10} />
              </button>
            )} */}
            <div className="w-full ">
              <CustomDatePicker
                isProject={true}
                placeholder={"Select"}
                date={importantDate}
                setDate={(value) => handleDateKey(value)}
              />
            </div>
          </div>
        </div>
        <div className="mb-3 w-full flex flex-col justify-center items-center">
          <StringDropdownList
            state={keyData}
            setState={handleKeyChange}
            dropdownItems={keyListData?.map((x: any) => {
              return {
                label: x.label,
                value: x.id,
              };
            })}
            className="w-full h-12 text-14 py-[9px] border-goalinputborder"
            placeholder="All"
            textAlign="text-left text-14 py-2 pl-2 pr-3"
            fontSize="text-14"
          />
        </div>
        <button
          type="button"
          onClick={() => handleKeyDelete(keyDate.id)}
          className={classNames(
            " mx-2 min-w-[3rem] w-12 h-[48px] mr-0 mb-4 flex justify-center items-center group rounded-md bg-redPink hover:bg-redPinkLight transition-all duration-300"
          )}
        >
          <TrashIcon />
        </button>
      </div>
    </div>
  );
};

export default KeyDateProject;
