import {
  createColumnHelper,
  SortingState,
  ColumnFiltersState,
  RowSelectionState,
  ColumnDef,
  PaginationState,
} from "@tanstack/table-core";
import {
  FC,
  useState,
  useMemo,
  Dispatch,
  SetStateAction,
  memo,
  useCallback,
  useRef,
  useEffect,
} from "react";
import styles from "./DailyReport.module.scss";
import LayoutAdjustableTable from "../../Tables/LayoutAdjustableTable";
import TablePaginator from "../../Tables/TablePaginator";
import { renderDateFormatDMY } from "../../DashboardCalendarView/DashboardAddEventPopup";
import CommonTableBottom from "../../CommonTableBottom/CommonTableBottom";
import { CheckIcon, CloseSmallIcon, EditIcon, ViewIcon } from "../../common/Icons";
import classNames from "classnames";
import DeletePopup from "../../DeletePopup/DeletePopup";
import { RefillRequestListDataItems } from "../../../app/services/profile-daily-report";
import MemberDailyReportPopup from "./MemberDailyReportPopup";

interface IMemberReportApproval {
  data?: any;
  setData?: any;
  columnFilters: ColumnFiltersState;
  setColumnFilters: Dispatch<SetStateAction<ColumnFiltersState>>;
  myLeavesList?: any;
  total: number;
  setCurrentPage: any;
  currentPage: number;
  pagination: PaginationState;
  setPagination: Dispatch<SetStateAction<PaginationState>>;
  changePage?: any;
  sorting: SortingState;
  setSorting: React.Dispatch<React.SetStateAction<SortingState>>;
  totalTime?: string;
  setStatusChangedID: React.Dispatch<React.SetStateAction<number>>;
  setOpenDeleteopup: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenRejectedPopup: React.Dispatch<React.SetStateAction<boolean>>;
  setShowDetailPopup: React.Dispatch<React.SetStateAction<boolean>>;
  showDetailPopup: boolean;
}
const MemberReportApproalTable: FC<IMemberReportApproval> = ({
  total,
  setCurrentPage,
  currentPage,
  pagination,
  setPagination,
  changePage,
  data,
  setData,
  sorting,
  setSorting,
  totalTime,
  setStatusChangedID,
  setOpenDeleteopup,
  setOpenRejectedPopup,
  setShowDetailPopup,
  showDetailPopup
}) => {
  const columnHelper = useMemo(() => createColumnHelper<any>(), []);
  const [customClass, setCustomClass] = useState<string>("");
  const [totalPos, setTotalPos] = useState<number>();
  const [bottom, setBottom] = useState<number>();
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  const [timeSpentPos, setTimeSpentPos] = useState<number>();

  const [itemCount, setItemCount] = useState<number>(total);
  const [taskList, setTaskList] = useState<RefillRequestListDataItems[]>([]);
  const [memberData, setMemberData] = useState<any>();
  const divRef = useRef(null);
  const viewMemberRefillReport = (id: number, approveOption: boolean, rejectOption: boolean) => {
    setShowDetailPopup(true);
    const tasks = data.find((x: any) => x.id == id);

    if (tasks) {
      setTaskList(tasks?.items);
      setMemberData({
        date: tasks.date,
        reason: tasks.reason,
        employee_name: tasks.employee_name,
        day_format: tasks.day_format,
        id: id,
        approveOption: approveOption,
        rejectOption: rejectOption,
        clockIn1: tasks.clockIn1,
        clockIn2: tasks.clockIn2,
        clockIn3: tasks.clockIn3,
        clockOut1: tasks.clockOut1,
        clockOut2: tasks.clockOut2,
        clockOut3: tasks.clockOut3,
        total_clocking_time_text:tasks.total_clocking_time_text
      })
    }

  }
  const showConfirmPopup = (id: number, status: string) => {
    setStatusChangedID(id);
    if (status == "Approved") {
      setOpenDeleteopup(true);
    }
    if (status == "Rejected") {
      setOpenRejectedPopup(true);
    }
    setShowDetailPopup(false);
  }
  const columns = useMemo(
    () => [
      columnHelper.accessor("employee_code", {
        header: "Employee ID",
        id: "employee_code",
        cell: ({ getValue, cell }) => {
          const data = getValue();
          return (
            <div className="bg-white text-black2 px-2 flex items-center">
              <p className=" px-2 py-1">{data}</p>
            </div>
          );
        },
      }),
      columnHelper.accessor("employee_name", {
        header: "Employee Name",
        id: "employee_id",
        cell: ({ getValue, cell }) => {
          const data = getValue();
          return (
            <div className="bg-white text-black2 px-2 flex items-center">
              <p className=" px-2 py-1">{data}</p>
            </div>
          );
        },
      }),
      columnHelper.accessor("refilled_at", {
        header: "Applied at",
        id: "refilled_at",
        cell: ({ getValue, cell }) => {
          const name = getValue();
          return (
            <div className="bg-white text-black2 px-2 flex items-center">
              <p className="px-2 py-1">{name}</p>
            </div>
          );
        },
      }),

      columnHelper.accessor("date", {
        header: "Report Date",
        id: "date",
        enableSorting: true,
        cell: ({ getValue, cell }) => {
          const data = getValue();
          return (
            <div className="bg-white text-black2 px-2 flex items-center">
              <p className="px-2 py-1">{data}</p>
            </div>
          );
        },
      }),
      columnHelper.accessor("reason", {
        header: "Reason",
        id: "reason",
        enableSorting: true,
        cell: ({ getValue, cell }) => {
          const name = getValue();
          return (
            <div className="bg-white text-black2 px-2 flex items-center">
              <p className="px-2 py-1">{name}</p>
            </div>
          );
        },
      }),
      columnHelper.accessor("current_status", {
        header: "Status",
        id: "current_status",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const current_status = getValue();
          const color=current_status=="Approved"?'text-vorpgreen':current_status=="Rejected"?'text-redPink':'text-black2'
          return (
            <div className="bg-white text-black2 px-2 flex items-center justify-center">
              <p className={classNames(color,"px-2 py-1")}>{current_status}</p>
            </div>
          );
        },
      }),
      columnHelper.display({
        header: "Action",
        id: `actions`,
        cell: ({ cell }) => {
          const approveOption: any = cell?.row?.original?.approve_option;
          const rejectOption: any = cell?.row?.original?.reject_option;
          return (
            <div
              className={classNames(
                styles.actionedBtn,
                "flex justify-start items-center max-w-full px-2"
              )}
            >
              {approveOption ? (
                <button
                  type="button"
                  onClick={() => {
                    showConfirmPopup(Number(cell?.row?.original?.id), 'Approved');
                  }}
                  className={classNames(
                    styles.customSharedBtn,
                    "w-8 h-8 max-w-[24px] mr-2 flex justify-center items-center group rounded-md bg-vorpphillipines hover:bg-turqouiseLight transition-all duration-300"
                  )}
                >
                  <CheckIcon width={16} height={16} />
                </button>
              ) : null}
              {rejectOption ? (
                <button
                  type="button"
                  onClick={() =>
                    showConfirmPopup(Number(cell?.row?.original?.id), 'Rejected')
                  }
                  className={classNames(
                    styles.customCloseIcon,
                    "w-8 h-8 max-w-[24px] mr-2 flex justify-center items-center group rounded-md bg-redPink hover:bg-[#FFEAEF] transition-all duration-300"
                  )}
                >
                  <CloseSmallIcon width={16} height={16} />
                </button>
              ) : null}
              <button
                onClick={() => {
                  viewMemberRefillReport(Number(cell.row.original.id), approveOption, rejectOption)
                }}
                className={classNames(
                  styles.customViewdBtn,
                  "w-6 h-6 mr-2 flex justify-center items-center group rounded-md bg-darkpurple hover:bg-[#EAE0FF] transition-all duration-300"
                )}
              >
                <ViewIcon />
              </button>
            </div>
          );
        },
      }),
    ],
    [columnHelper, data]
  );

  const [columnOrder, setColumnOrder] = useState<string[]>(
    columns.map((column: ColumnDef<any, any>) => column.id as string)
  );

  const visibleColumns: { [key: string]: boolean } = {};

  return (
    <div>
      <div className={styles.tableDaily}>
        <LayoutAdjustableTable
          data={data}
          hideColumn={visibleColumns}
          columns={columns}
          sorting={sorting}
          setSortingState={setSorting}
          rowSelection={rowSelection}
          setRowSelection={setRowSelection}
          columnOrder={columnOrder}
          setColumnOrder={setColumnOrder}
          unDraggableFields={["actions", "selection"]}
          theaderColor="text-graydark"
          isLayoutControlOpen={false}
          setIsLayoutControlOpen={() => 1}
          label="User List"
          setItemCount={setItemCount}
          pagination={pagination}
          setPagination={setPagination}
          noPaddingCells={["leave_type_id", "fullHalfDay", "status"]}
        />
        <CommonTableBottom
          data={[
            {
              name: totalTime ? "Total" : "",
              colSpan: 5,
              left: 0,
              bottom: bottom,
              classes: totalTime ? customClass : "hidden",
            },
            {
              name: totalTime ? totalTime : "",
              colSpan: 3,
              left: Number(timeSpentPos) - 40,
              bottom: bottom,
              classes: totalTime ? customClass : "hidden",
            },
          ]}
        />
      </div>
      <div className="pt-6">
        <TablePaginator
          total={itemCount}
          pagination={pagination}
          setPagination={setPagination}
          itemCount={total}
          setCurrentPage={setCurrentPage}
          currentPageDefault={currentPage}
          changePage={changePage}
        />
      </div>
      <MemberDailyReportPopup
        date={memberData?.date}
        day_format={memberData?.day_format}
        employee_name={memberData?.employee_name}
        reason={memberData?.reason}
        clockIn1={memberData?.clockIn1}
        clockIn2={memberData?.clockIn2}
        clockIn3={memberData?.clockIn3}
        clockOut1={memberData?.clockOut1}
        clockOut2={memberData?.clockOut2}
        clockOut3={memberData?.clockOut3}
        total_clocking_time_text={memberData?.total_clocking_time_text}
        id={memberData?.id}
        data={taskList}
        setData={setTaskList}
        isOpen={showDetailPopup}
        showConfirmPopup={showConfirmPopup}
        approveOption={memberData?.approveOption}
        rejectOption={memberData?.rejectOption}
        setIsOpen={setShowDetailPopup} />
    </div>
  );
};

export default memo(MemberReportApproalTable);
