import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { Cell } from "@tanstack/react-table";
import { EmployeeData } from "./EmployeeTable";
import { baseObj } from "../../utils/constants";
import CustomDropdownList, { optionsType } from "./CustomDropdownList";
import { assignUsers } from "../../app/services/password";


interface ICustomDropdownWithAddButton {
  cell: Cell<unknown, unknown>;
  initialValues: number;
  dataList: baseObj[];
  // dataList: EmployeeData[];
  title: string;
  setData: Dispatch<SetStateAction<baseObj[]>>;
  setSelectedData: Dispatch<SetStateAction<string>>;
  data: baseObj[];
  setDataList: Dispatch<SetStateAction<any>>;
  customHeight?: string;
  selectedData: string;
  selectedDatas?: baseObj;
  isCode?: boolean;
  isText?: boolean;
  addNewItemData?: any;
  isCurrency?: boolean;
  // setPaymentTerms?: Dispatch<SetStateAction<number>>;
  isPayment?: boolean;
  setIsRegion?: any;
  setDeleteID?: any;
  setOpenDeleteopup?: any;

  setIsPJType?: any;
  setIsPJStatus?: any;
  setIsCurrency?: any;
  setIsPYStatus?: any;
  isRegion?: boolean;
  isProjectType?: boolean;
  isProjectStatus?: boolean;
  isPaymentStatus?: boolean;
  isPaymentTerm?: boolean;
  isShowDelete?:boolean;
  isShowAdd?:boolean;
  isValue?:boolean;
  addTitle?:string;
  isEditable?:boolean;
  isBorder?:boolean;
  customClass?:string;
}

const CustomDropdownWithAddButton: FC<ICustomDropdownWithAddButton> = ({
  initialValues,
  title,
  dataList,
  setData,
  data,
  cell,
  setDataList,
  customHeight,
  setSelectedData,
  selectedData,
  selectedDatas,
  isCode,
  isText,
  addNewItemData,
  isCurrency,
  // setPaymentTerms,
  isPayment,
  setIsRegion,
  setIsPJType,
  setIsPJStatus,
  setIsCurrency,
  setIsPYStatus,
  setDeleteID,
  setOpenDeleteopup,
  isRegion,
  isProjectType,
  isProjectStatus,
  isPaymentStatus,
  isPaymentTerm,
  isShowDelete,
  isShowAdd,
  isValue,
  addTitle,
  isEditable,
  isBorder,
  customClass
  // isLeave,
}) => {
  const options: baseObj[] = dataList?.filter((d) => d.id !== 1);
  // const options = dataList?.filter((d) => d.uniqeID !== 1);
  const [value, setValue] = useState<number>(0);
  return (
    <>
      <CustomDropdownList
        isCurrency={isCurrency}
        addNewItemData={addNewItemData}
        isCode={isCode}
        isText={isText}
        customHeight={customHeight}
        dataList={dataList}
        value={value}
        setValue={setValue}
        options={options}
        title={title}
        setData={setData}
        data={data}
        setDataList={setDataList}
        setSelectedData={setSelectedData}
        selectedDataItem={selectedData}
        selectedDataItemData={selectedDatas}
        cell={cell}
        // setPaymentTerms={setPaymentTerms}
        isPayment={isPayment}
        setIsRegion={setIsRegion}
        setIsPJType={setIsPJType}
        setIsPJStatus={setIsPJStatus}
        setIsCurrency={setIsCurrency}
        setIsPYStatus={setIsPYStatus}
        isRegion={isRegion}
        isProjectType={isProjectType}
        isProjectStatus={isProjectStatus}
        isPaymentStatus={isPaymentStatus}
        isPaymentTerm={isPaymentTerm}

        setDeleteID={setDeleteID}
        setOpenDeleteopup={setOpenDeleteopup}
        isShowDelete={isShowDelete}
        isShowAdd={isShowAdd}
        isValue={isValue}
        addTitle={addTitle}
        isEditable={isEditable}
        isBorder={isBorder}
        customClass={customClass}
        // isLeave={isLeave}
      />
    </>
  );
};

export default CustomDropdownWithAddButton;
