import {
  Dispatch,
  FC,
  SetStateAction,
  useState,
  useEffect,
  memo,
  useCallback,
  useRef,
} from "react";
import { CircularProgress, Modal } from "@mui/material";
import whiteCancel from "../common/img/white-cancel.svg";
import DOMPurify from "dompurify";
import classNames from "classnames";
import { CloseIcon, SendIcon } from "../common/Icons";
import CustomDatePicker from "../common/BaseDatePicker";
import { StringDropdownList } from "../common/DropdownList";
import Button from "../common/Button";
import styles from "./ClaimForm.module.scss";
import { renderDateFormatYMD } from "../DashboardCalendarView/DashboardAddEventPopup";
import { baseObj } from "../../utils/constants";
import { endpointUrl } from "../../app/services/api";
import axios from "../../axios";
import { getAuthUser } from "../../app/services/dashboard";
import { toast } from "react-toastify";

interface IOTModal {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  saveData: (data: any) => void;
  setEmployeeData: Dispatch<SetStateAction<any>>;
  employeeData: any;
  uploading: boolean;
  setUploading: Dispatch<SetStateAction<boolean>>;
  setSkip: Dispatch<SetStateAction<boolean>>;
  data: baseObj[];
  typeArr: baseObj[];
  currencyList: baseObj[];
  setData: Dispatch<SetStateAction<baseObj[]>>;
  setItemCount: Dispatch<SetStateAction<number>>;
  setErrorPopup: React.Dispatch<React.SetStateAction<boolean>>;
  setMessage: React.Dispatch<any>;
  isMyprofile?: boolean;
}

const MyClaimCreatePopup: FC<IOTModal> = ({
  setData,
  isOpen,
  setIsOpen,
  saveData,
  isMyprofile,
  setEmployeeData,
  employeeData,
  uploading,
  setUploading,
  setSkip,
  data,
  typeArr,
  currencyList,
  setItemCount,
  setErrorPopup,
  setMessage,
}) => {
  var getUser = getAuthUser();
  const [fromDate, setFromDate] = useState<Date | undefined>(new Date());
  const [currency, setCurrency] = useState<any>("");
  const [type, setType] = useState<any>("");
  const [reason, setReason] = useState<string>("");
  const [allPrevFiles, setAllPrevFiles] = useState<any[]>([]);
  const uploadRef: any = useRef(null);
  const [allFile, setFile] = useState<any[]>([]);
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [selectedFileName, setSelectedFileName] = useState("No file chosen");
  const [amount, setAmount] = useState<any>("");

  const handleAmount = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAmount(event.target.value);
  };

  const checkIsValidate = (editedData: any) => {
    var msg: any = [];

    if (editedData.claim_type_id === "") {
      msg.push("Claim Type is required.");
    }
    if (editedData.reason === "") {
      msg.push("Description is required.");
    }
    if (editedData.currency_id === "") {
      msg.push("Please select currency type.");
    }
    if (editedData.amount === "") {
      msg.push("Please enter the amount.");
    }

    return msg;
  };

  const cleanData = () => {
    setCurrency("");
    setType("");
    setReason("");
    setAllPrevFiles([]);
    setAmount("");
  };

  const saveClaimData = async () => {
    setUploading(true);
    const dataAll = {
      employee_id: employeeData,
      claim_type_id: type,
      description: reason,
      currency_id: currency,
      amount: amount,
    };

    var message: any = checkIsValidate(dataAll);
    if (message.length > 0) {
      setErrorPopup(true);
      setMessage(message);
      setUploading(false);
      return;
    } else {
      const clean_reason = reason ? DOMPurify.sanitize(reason) : "";
      const claim_date = renderDateFormatYMD(
        fromDate ? fromDate : new Date(),
        "-"
      );

      let formSaveData = new FormData();
      if (allPrevFiles.length > 0) {
        allPrevFiles?.forEach((image_file: any) => {
          formSaveData.append("documents[]", image_file);
        });
      }

      formSaveData.append("claim_date", claim_date);
      formSaveData.append("claim_type_id", type);
      formSaveData.append("description", clean_reason);
      formSaveData.append("currency_id", currency);
      formSaveData.append("amount", amount);

      await axios(endpointUrl + "claim-forms", {
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${getUser.token}`,
          Accept: "application/json",
        },
        data: formSaveData,
      })
        .then((res: any) => {
          setUploading(false);
          cleanData();
          axios
            .get(`${endpointUrl}my-claim-forms`, {
              method: "GET",
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${getUser.token}`,
                Accept: "application/json",
              },
            })
            .then((res: any) => {
              setUploading(false);
              setData(res.data.claimFormList.data);
              setItemCount(res.data.claimFormList.total);
              toast("Successfully Create.", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                type: "success",
              });
              setIsOpen(false);
            })
            .catch((error: any) => {
              setUploading(false);
              setErrorPopup(true);
              setMessage(
                error.response.data.message
                  ? [error.response.data.message]
                  : ["Something went wrong!Trying again."]
              );
            });
        })
        .catch((error: any) => {
          setErrorPopup(true);
          setUploading(false);
          setMessage(
            error.response.data.message
              ? [error.response.data.message]
              : ["Something went wrong!Trying again."]
          );
        });
    }
  };

  const handleSelectedFile = (name: number) => {
    const filtered = allPrevFiles.filter((file) => file.lastModified !== name);
    setAllPrevFiles(filtered);
  };

  const UploadFile = useCallback(() => {
    const file = uploadRef?.current.files;
    setFile([...file]);

    const MAX_FILE_SIZE = 100 * 1024 * 1024; // 100MB
    if (!file) {
      setErrorMsg("Please choose a file");
      return;
    }
    for (let i = 0; i < file.length; i++) {
      if (file[i].size > MAX_FILE_SIZE) {
        setErrorMsg("File size exceeds the maximum allowed (100MB).");
        return;
      } else {
        setErrorMsg("");
      }
    }
  }, [setSelectedFileName, setFile, setErrorMsg]);

  const CheckedFile = useCallback(
    (fileData: any) => {
      setSelectedFileName(`${fileData.length} files selected`);
      const MAX_FILE_SIZE = 100 * 1024 * 1024; // 100MB
      if (!fileData) {
        setErrorMsg("Please choose a file");
        return;
      }
      if (fileData.length > 5) {
        setErrorMsg("Please choose only 5 files");
        return;
      }
      for (let i = 0; i < fileData.length; i++) {
        if (fileData[i].size > MAX_FILE_SIZE) {
          setErrorMsg("File size exceeds the maximum allowed (100MB).");
          return;
        } else {
          setErrorMsg("");
        }
      }
    },
    [setSelectedFileName, setErrorMsg]
  );

  const handleClick = () => {
    uploadRef.current.click();
  };

  useEffect(() => {
    if (allFile.length > 0) {
      if (allPrevFiles.length === 0) {
        setAllPrevFiles([...allPrevFiles, ...allFile]);
      } else {
        const difference = allFile.filter((item1) => {
          // Use the find method to check if an item in checkAge exists in allPrevFiles
          return !allPrevFiles.find((item2) => item2.name === item1.name);
        });

        // const match = allPrevFiles.filter((item1) => {
        //   // Use the find method to check if an item in checkAge exists in allPrevFiles
        //   return allFile.find((item2) => item2.name === item1.name);
        // });
        // console.log("m", match);

        setAllPrevFiles([...allPrevFiles, ...difference]);
      }
    }
  }, [allFile]);

  useEffect(() => {
    if (allPrevFiles.length > 0) {
      CheckedFile(allPrevFiles);
      setSelectedFileName(`${allPrevFiles.length} files selected`);
    } else {
      setSelectedFileName("No file choosen");
    }
  }, [allPrevFiles]);

  return (
    <Modal
      open={isOpen}
      style={{
        background: "rgb(0 0 0 / 0.3)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      onClose={(event, reason) => {
        if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
          setIsOpen(false);
          setUploading(false);
          setSkip(false);
        }
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableEnforceFocus={true}
    >
     <div className="modalContent transition-all  max-w-[530px] max-h-[98vh] overflow-y-auto xl:max-h-none w-full m-auto px-4 py-8 xl:px-0 xl:py-0 font-primary">
        <div className="w-full bg-white rounded-xl overflow-hidden">
        <div
          id="modal-modal-title"
          className="bg-vorpblue relative flex justify-between py-6 sm:px-10 px-5 rounded-t-xl"
        >
          <h3 className="text-18 font-primary font-semibold text-white">
            Add New Claim
          </h3>
          <button
            className="flex items-center justify-center"
            onClick={() => {
              setIsOpen(false);
            }}
          >
            <img src={whiteCancel} alt="close modal icon" />
          </button>
        </div>

        <div
            id="modal-modal-description"
            className="py-9 px-5 bg-white rounded-b-xl max-h-[500px] overflow-y-auto mb-7 pr-3 custom_scrollbar_popup"
          >
          <div className="md:flex">
            <div
              className={classNames(
                // styles.verticalLine,
                " w-full"
              )}
            >
              <div className="w-full">
                <div className="mb-3">
                  <h4 className="xl:text-15 text-12 leading-[22px] font-medium text-black2 mb-2 w-full">
                  Claim Date
                  </h4>
                  <CustomDatePicker
                    date={fromDate}
                    setDate={setFromDate}
                    // minDate={new Date()}
                    isFilterSection={false}
                    placeholder={new Date().toDateString()}
                    iconColor="#858795"
                  />
                </div>

                <div className="pt-[3px]">
                  <h4 className="xl:text-15 text-12 leading-[22px] font-medium text-black2 mb-2 w-full">
                    Claim Type
                  </h4>
                  <StringDropdownList
                    state={type}
                    setState={setType}
                    placeholder="Select Claim Type"
                    className="w-full py-[12px] pl-3 mb-3 text-14"
                    dropdownItems={typeArr?.map((status: any) => ({
                      value: status.id,
                      label: status.name,
                    }))}
                    textAlign={"text-left"}
                    fontSize="xl:text-15 text-12"
                  />

                  <textarea
                    onChange={(ev) => setReason(ev.target.value)}
                    onBlur={(ev) => setReason(ev.target.value)}
                    rows={5}
                    className="placeholder:text-vorpgraylight border border-vorpgraylight w-full rounded-sm focus:outline-none p-3 xl:text-15 text-12 leading-5 text-graydark resize-none mb-5"
                    placeholder="Reason of Claim"
                  ></textarea>
                </div>

                <div className="mb-3 last:mb-0">
                  <p className="text-black2 text-12 sm:text-15 font-medium font-primary mb-[0.688rem] block leading-tight">
                    Supportive Documents:
                  </p>
                  <div className=" border border-gray-300 text-gray-400">
                    <p className=" text-sm px-4 pt-3 italic">
                      Upload file (.jpg, .png, .pdf,.docx,.jpeg) maximum 5 files
                    </p>
                    <div className="flex items-center p-4">
                      <input
                        multiple
                        type="file"
                        id="upload"
                        name="file"
                        onChange={UploadFile}
                        ref={uploadRef}
                        className="hidden"
                        accept=".pdf,.doc,.docx,.jpg,.png,.jpeg,.svg"
                      />
                      <button
                        onClick={handleClick}
                        className={classNames(
                          allPrevFiles.length > 5 || uploading
                            ? "pointer-events-none"
                            : "",
                          styles.uploadBtn
                        )}
                      >
                        Choose file
                      </button>
                      <p className="font-primary text-10 sm:text-12 text-black2 ml-3">
                        {selectedFileName}
                      </p>
                    </div>
                    {allPrevFiles.length > 0 &&
                      allPrevFiles.map((file: File, i: number) => (
                        <div className="flex" key={i}>
                          <p className=" text-sm py-2 px-4">{file.name}</p>
                          <button
                            type="button"
                            onClick={() =>
                              handleSelectedFile(file.lastModified)
                            }
                            className="text-12 mr-2 p-4"
                          >
                            <CloseIcon color="#EE436D" width={10} height={10} />
                          </button>
                        </div>
                      ))}
                  </div>
                </div>
                <p className=" text-red-600">{!errorMsg ? "" : errorMsg}</p>
                <hr className="border border-vorpgraylight border-b-0 border-l-0 border-r-0 px-10 my-2" />
                <div className="pt-[3px] w-full flex justify-between items-center">
                  <div className="w-5/12">
                    <h4 className="xl:text-15 text-12 leading-[22px] font-medium text-black2 mb-2 w-full">
                      Amount
                    </h4>
                    <StringDropdownList
                      state={currency}
                      setState={setCurrency}
                      placeholder="Select"
                      className="w-full py-[10px] pl-3 mb-2 text-14"
                      dropdownItems={currencyList?.map((status: any) => ({
                        value: status.id,
                        label: status.currency_name,
                      }))}
                      textAlign={"text-left"}
                      fontSize="xl:text-13 text-13"
                    />
                  </div>
                  <div className="w-6/12 mt-3.5">
                    <input
                      type="number"
                      value={amount ?? ""}
                      onChange={(event) => handleAmount(event)}
                      placeholder="Enter Amount"
                      className="focus:outline-none focus-visible:outline-none w-full rounded py-3 px-3 border border-goalinputborder text-black2 text-14 font-medium font-primary placeholder:text-graydark h-[40px]"
                      onKeyDown={
                        (evt) =>
                              ["e", "E", "+", "-"].includes(
                                evt.key
                              ) && evt.preventDefault()
                      }
                      onWheel={(e:any)=>e.target.blur()}
                    ></input>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-5 last:mb-0 flex items-center sm:px-0 ">
            <Button
              type="button"
              label={
                <span className="flex items-center text-12 leading-[18px] relative justify-between">
                  {uploading ? (
                    <div
                      className={classNames(
                        uploading ? "block" : "hidden",
                        " absolute top-[55%] left-1/2 -translate-x-1/2 -translate-y-1/2"
                      )}
                    >
                      <CircularProgress />
                    </div>
                  ) : (
                    <SendIcon
                      className={classNames(
                        "mr-[10px] group-hover:opacity-0 transition-all duration-300"
                      )}
                    />
                  )}
                  {uploading ? "Uploading" : "Submit"}
                </span>
              }
              onClick={() => saveClaimData()}
              variant="no-outline"
              size="lg"
              customClass={classNames(
                uploading ? "pointer-events-none opacity-50 " : "",
                "hover:bg-vorpblue mr-2",
                errorMsg ? " pointer-events-none opacity-40" : ""
              )}
            />
            <Button
              type="button"
              label={
                <span className="flex items-center text-12 leading-[18px] ">
                  <CloseIcon
                    width={14}
                    height={14}
                    className="mr-[10px] [&_path]:fill-graydark"
                  />
                  Cancel
                </span>
              }
              onClick={() => {
                setIsOpen(false);
                setUploading(false);
                setSkip(false);
                cleanData();
              }}
              variant="no-outline"
              size="lg"
              customClass="min-w-[112px] min-h-[43.6px] bg-offwhite text-graydark border-none"
            />
          </div>
        </div>
      </div>
      </div>
    </Modal>
  );
};

export default memo(MyClaimCreatePopup);
