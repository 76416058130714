import { Dispatch, FC, SetStateAction, useEffect, useRef, useState } from "react";
import { PaymentDoneIcon } from "../common/Icons";
import { formatMoney } from "../../utils/helpers";
import { IPaidAmount } from "./PaymentProgressBar";

interface IPaymentTermsEdit {
  value: {
    amount: number;
    isDone: boolean;
    id: number;
  };
  index: number;
  currency: string;
  isEditing: boolean;
  currentPaidAmount: IPaidAmount;
  setCurrentPaidAmount: Dispatch<SetStateAction<IPaidAmount>>;
  isLastItem: boolean;
  uuid: string;
  setCurrentProjectAmount: Dispatch<SetStateAction<number>>;
  rowId?: number;
  key: number;
  projectAmountValue?: boolean;
}

const PaymentTermsEdit: FC<IPaymentTermsEdit> = ({
  value,
  index,
  currency,
  isEditing,
  setCurrentPaidAmount,
  uuid,
  isLastItem,
  setCurrentProjectAmount,
  currentPaidAmount,
  key,
  projectAmountValue,
}) => {
  const [isDone, setIsDone] = useState<boolean>(value?.isDone);
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const inputRef = useRef<any>({ value: '' });

  const handleChange = (currentValue: number, id: number) => {

    if (!Number.isNaN(currentValue)) {
      setCurrentPaidAmount((prev) => (
        {
          ...prev,
          paymentValue: prev?.paymentValue?.map((paymentValue) => {
            if (paymentValue.id === id) {
              return { ...paymentValue, amount: currentValue };
            } else {
              return paymentValue;
            }
          }),
        }
      ));
    }

  };

  useEffect(() => {
    if (isEditing && isLastItem) {
      setIsEditable(true);
    } else {
      setIsEditable(false);
    }
  }, [isEditing, isLastItem]);

  // const onChangeHandler = (currentValue: number, id: number) => {
  //   if (!Number.isNaN(currentValue)) {
  //     setCurrentPaidAmount((prev) => (
  //       {
  //         ...prev,
  //         paymentValue: prev?.paymentValue?.map((paymentValue) => {
  //           if (paymentValue.id === id) {
  //             return { ...paymentValue, amount: currentValue };
  //           } else {
  //             return paymentValue;
  //           }
  //         }),
  //       }
  //     ));
  //   }
  // };

  const handlePaymentDone = (id: number) => {
    setCurrentPaidAmount((prev) => ({
      ...prev,
      paymentValue: prev?.paymentValue?.map((paymentValue) => {
        
        if (paymentValue.id == id) {
          return { ...paymentValue, isDone: !isDone };
        } else {
          return { ...paymentValue };
        }
      }),
    }));
    setIsDone(!isDone);
  };

  useEffect(() => {
    const totalAmout = currentPaidAmount.paymentValue.reduce(
      (total, current) => (total += current.amount),
      0
    );

    const paidAmount = currentPaidAmount.paymentValue
      .filter((paidAmount) => paidAmount.isDone)
      .reduce((total, current) => (total += current.amount), 0);

    setCurrentProjectAmount(totalAmout);
    setCurrentPaidAmount((prev) => ({ ...prev, value: paidAmount }));
  },
    [currentPaidAmount.paymentValue]
  );


  return (
    <div key={index * Math.random() * 100} className="flex justify-between items-center text-12 leading-[18px] mb-3">
      <span>{index + 1}</span>
      <div className="flex items-center">
        <div className="flex justify-between items-center min-w-[67px]">
          {isEditable ? (
            <>
              <input
                autoFocus={inputRef.current === document.activeElement}
                type="text"
                pattern="[0-9]"
                placeholder="Enter Amount"
                className={`focus:outline-none ${uuid + "child"} max-w-[82px] min-h-[20px]`}
                value={value?.amount}
                onChange={(e) => handleChange(Number(e.target.value), value.id)}
                ref={inputRef}
              // onChange={(e) => {
              //   onChangeHandler(Number(e.target.value), value.id);
              // }
              // }
              />
            </>
          ) : (

            <div
              onClick={() => isEditing && setIsEditable(true)}
              className={`${uuid + "child"} flex justify-between items-center`}
            >
              {/* <span className="mr-1">{currency}</span> */}
              <span>{formatMoney(value.amount, "").split(".")[0]}</span>
            </div>
          )}
        </div>
        <span
          onClick={() => isEditing && handlePaymentDone(value.id)}
          className={`w-4 h-4 ml-[9px] flex justify-center items-center ${isEditing ? "hover:cursor-pointer" : "hover:cursor-default"
            }`}
        >
          <PaymentDoneIcon color={isDone ? "#50CD89" : "#C4C4C4"} />
        </span>
      </div>
    </div>
  );
};

export default PaymentTermsEdit;
