import { ReactElement } from 'react';
import BasicLayout from '../layouts/BasicLayout';
import { Outlet } from 'react-router-dom';
import Media from './views/Media';


const MedaiRoute = (props: any): ReactElement => {
  const data=props.loginData;
  const permissions = props.permissions;
  return (
    <BasicLayout userData={data} permissions={permissions}>
      <Media />
      <Outlet />
    </BasicLayout>
  )
};

export default MedaiRoute;
