import React, { memo, useEffect, useState } from "react";

const useDate = () => {
  const [date, setDate] = useState(new Date());

  const options: any = {
    timeZone: "Asia/Hong_Kong",
    hour12: false,
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  };
  const optionsDate: any = {
    timeZone: "Asia/Hong_Kong",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  const formattedTime = new Intl.DateTimeFormat("en-US", options).format(date);
  const formattedDate = new Intl.DateTimeFormat("en-US", optionsDate).format(
    date
  );

  useEffect(() => {
    const timer = setInterval(() => {
      setDate(new Date());
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return {formattedDate, formattedTime};
};

export default (useDate);
