import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
// import timeGridPlugin from "@fullcalendar/timegrid"; // a plugin!
import interactionPlugin from '@fullcalendar/interaction';
import styles from "./DashboardCalendar.module.scss";
import classNames from "classnames";
import stickerNote from "./img/sticker-note-icon.svg";
import sgHoliday from "./img/sgHoliday.svg";
import hkHoliday from "./img/hkHoliday.svg";
import noteIcon from './img/note-icon.svg';
import {
  renderDateFormat, renderDateFormatDMY,
  // renderDateFormatDMY,
} from "../DashboardCalendarView/DashboardAddEventPopup";
// import { EventApi } from "@fullcalendar/core";
import { getAuthUser } from "../../app/services/dashboard";
// import { Tooltip, makeStyles } from "@material-ui/core";
import { makeStyles } from '@mui/styles';
import { Tooltip } from "@mui/material"

const renderDropdown = () => {
  return `<div className="${styles.selectRoot} selectData">
  <FormControl
    fullWidth
    className=${styles.customSelect}
    size="small"
  >
    <Select
      className="${classNames(
    styles.selectRoot,
    "3xl:min-w-[218px] xl:min-w-[140px] min-w-[110px] bg-[#F3F6F9] rounded-[6px] border-none"
  )}"
    >
      <option className=${styles.cusOption} value="All">
        All
      </option>
      <option className=${styles.cusOption} value="All">
        All
      </option>
    </Select>
  </FormControl>
</div>`;
};
const renderCustomDropdown = () => {
  return `<div class="relative min-w-[200px] md:block hidden">
</div>`;
};
interface noteData {
  uniqueID: number,
  note: string
}
const useStyles = makeStyles(() => ({
  tooltip: {
    backgroundColor: "transparent",
    margin: 0,
    marginRight: 8,
    padding: 0,
  },
}));
const DashboardCalendarMobile = (props: any) => {
  const classes = useStyles();
  const [custButton, setCusButton] = useState("");
  const [custFilterButton, setCusFilterButton] = useState("myCustomFilter");
  const [noteData, setNoteData] = useState<noteData>({
    uniqueID: 0,
    note: ''
  });
  const [currentEventList, setCurrentEventList] = useState<any>([]);
  const [currentDate, setCurrentDate] = useState<string>('');
  const [mobileNote, setMobileNote] = useState<string>('');
  const [eventsDataList, setEventsDataList] = useState(props.eventsList);
  const [isDesktop, setIsDesktop] = useState<boolean>(true);

  const unHoverStickerNote = (event: any) => {
    event.target.parentElement.parentElement?.classList.remove("block");
    document
      .getElementsByClassName(styles.noteButtons)[0]
      ?.classList.add("hidden");
  };
  const noteRef = useRef<any>();
  const hideAllPopup = () => {
    var stickerNotes = document.getElementsByClassName(
      styles.stickerNoteContent
    );
    for (var i = 0; i < stickerNotes.length; i++) {
      stickerNotes[i].classList.remove("block");
      stickerNotes[i].classList.remove("show");
    }
  };
  const hoverStickerNote = (event: any) => {
    if (event.target.nextElementSibling.classList.contains('show')) {
      event.target.nextElementSibling.classList.remove("block");
      event.target.nextElementSibling.classList.remove("show");
    } else {
      hideAllPopup();
      event.target.nextElementSibling.classList.add("block");
      event.target.nextElementSibling.classList.add("show");
    }

    if (props.isProfile) {
      if (event.pageX < 120) {
        event.target.nextElementSibling.classList.add("right-[-110px]");
        event.target.nextElementSibling.classList.add("bottom-[20px]");
        event.target.nextElementSibling.classList.remove("bottom-[-20px]");
      }
      if (event.pageX < 500) {
        event.target.nextElementSibling.classList.add("right-[-90px]");
        event.target.nextElementSibling.classList.add("bottom-[20px]");
        event.target.nextElementSibling.classList.remove("bottom-[-20px]");
      } else if (event.pageY < 650) {
        event.target.nextElementSibling.classList.remove("right-[-80px]");
        event.target.nextElementSibling.classList.remove("bottom-[20px]");
        event.target.nextElementSibling.classList.add("bottom-[-20px]");
      } else {
        event.target.nextElementSibling.classList.add("bottom-[0px]");
        event.target.nextElementSibling.classList.remove("right-[-80px]");
        event.target.nextElementSibling.classList.remove("bottom-[20px]");
        event.target.nextElementSibling.classList.remove("bottom-[-20px]");
      }
    } else {
      if (event.pageX < 150) {
        event.target.nextElementSibling.classList.add("right-[-120px]");
        event.target.nextElementSibling.classList.add("bottom-[20px]");
        event.target.nextElementSibling.classList.remove("bottom-[-20px]");
      }
      if (event.pageX < 368) {
        event.target.nextElementSibling.classList.add("right-[-120px]");
        event.target.nextElementSibling.classList.add("bottom-[20px]");
        event.target.nextElementSibling.classList.remove("bottom-[-20px]");
      }
      if (event.pageX < 500) {
        event.target.nextElementSibling.classList.add("right-[-80px]");
        event.target.nextElementSibling.classList.add("bottom-[20px]");
        event.target.nextElementSibling.classList.remove("bottom-[-20px]");
      }
      else if (event.pageY < 500) {
        event.target.nextElementSibling.classList.remove("right-[-80px]");
        event.target.nextElementSibling.classList.remove("bottom-[20px]");
        event.target.nextElementSibling.classList.add("bottom-[-20px]");
      } else {
        event.target.nextElementSibling.classList.add("bottom-[0px]");
        event.target.nextElementSibling.classList.remove("right-[-80px]");
        event.target.nextElementSibling.classList.remove("bottom-[20px]");
        event.target.nextElementSibling.classList.remove("bottom-[-20px]");
      }
    }

  };
  const clickDiscardBtn = (event: any) => {
    event.target.parentElement.classList.add("hidden");
    event.target.parentElement.parentElement.classList.remove("show");
  };
  const clickSaveBtn = (event: any, data: any, noteData: string, uniqeID: number, date: string) => {
    var loginData = getAuthUser();

    var extData = data.extendedProps;
    var start = data?.start ? renderDateFormat(data.start, "-") : "";
    var end = data?.start ? renderDateFormat(data.start, "-") : "";
    var noteDescription = '';
    //var noteDescription = (extData.start == start) ? noteRef.current?.value : '';
    //var noteDescription = noteData;
    if (uniqeID) {
      noteDescription = (extData.uniqueID == uniqeID) ? noteData : '';
    } else {
      noteDescription = (extData.from_date == date ? noteData : '');
    }
    if (extData.uniqueID) {
      var editedNoteData = {
        uniqeID: extData.uniqueID ? extData.uniqueID : 0,
        start: start,
        end: end,
        eventType: "N",
        eventTarget: 0,
        targetId: loginData?.user?.id,
        eventNote: true,
        note: noteDescription,
        description: noteDescription,
        title: "",
        leaveType: "",
        day: ""
      };
      props.editNoteData(editedNoteData);
    } else {
      var editedNoteDataNew = {
        uniqeID: extData.uniqueID ? extData.uniqueID : 0,
        start: start,
        end: end,
        eventType: "N",
        eventTarget: 0,
        TargetId: loginData?.user?.id,
        eventNote: true,
        note: noteDescription,
        description: noteDescription,
        title: "",
        leaveType: "",
        day: ""
      };
      var newNote = [];
      newNote.push(editedNoteDataNew);
      props.createEvent(newNote);
    }

    event.target.parentElement.classList.add("hidden");
    event.target.parentElement.parentElement.classList.remove("show");
  };
  const editNote = (event: any) => {
    event.target.removeAttribute("readOnly");
    event.target.parentElement.nextElementSibling?.classList.remove("hidden");
  };

  const eventMount = () => {
    var div = document.getElementsByClassName(styles.stickerNote);
    for (var i = 0; i < div.length; i++) {
      div[i].parentElement?.parentElement?.parentElement?.classList.add('position-unset');
    }
  }

  const renderEventContent = (eventInfo: any) => {
    var noteDesc = eventInfo?.event?.extendedProps?.note?.trim().length <= 0 ? "" : eventInfo?.event?.extendedProps?.note
    const eventOverlapClass = eventInfo.isStart ? styles.eventOverlap : '';
    var html = document.getElementsByClassName("fc-myCustomFilter-button")[0];
    var alreadyPush = document.getElementsByClassName(
      "fc-myCustomFilter-button"
    )[0]?.innerHTML;
    if (!alreadyPush) {
      if (html) {
        html.insertAdjacentHTML("beforeend", renderCustomDropdown());
      }
    }
    var eventType = eventInfo.event.extendedProps.eventType;
    var leaveFullHalf = eventInfo.event.extendedProps.fullHalfDay;
    // var div = document.getElementsByClassName(styles.stickerNote);
    // for (var i = 0; i < div.length; i++) {
    //   div[i].parentElement?.parentElement?.parentElement?.classList.add('position-unset')
    // }
    var start = renderDateFormat(new Date(eventInfo.event.extendedProps.displayStart), '-');
    var endDate = new Date(eventInfo.event.extendedProps.displayEnd);
    var endDateCus = endDate.getDate() - 1;
    endDate.setDate(endDateCus);
    var endD = renderDateFormat(new Date(endDate), '-');
    var end = (endD == "1970-01-01" ? "" : endD);
    var eventTarget = eventInfo.event.extendedProps.eventTarget;
    if (eventTarget == "P") {
      end = "";
    }
    return (
      <>
        {eventInfo.event.extendedProps.eventNote == true ? (
          <div>
            <div>
              {eventInfo.event.extendedProps.show_note == true ? <div id={eventInfo.event.uniqeID}
                className={classNames(
                  styles.eventContent, eventOverlapClass,
                  "xl:px-[4px] px-1 py-[1px] mb-[1px] rounded-[3px] eventContent",
                  eventType == "SL"
                    ? "bg-[#FFEBED]"
                    : eventInfo.event.title
                      ? "bg-[#E5F6FF]"
                      : ""
                )}
              >
                {(eventInfo.event.title != "" && eventInfo.event.title != null) ? (
                  <div
                    className={classNames((eventInfo.event.title == "" && eventInfo.event.title == null) ? 'hidden' : '',
                      "flex font-poppins 2xl:text-xs text-[0.65rem] font-normal text-[#464E5F]"
                    )}
                  >
                    <Tooltip
                      arrow
                      classes={{ tooltip: classes.tooltip }}
                      enterTouchDelay={0}
                      leaveTouchDelay={5000}
                      placement="left"
                      title={
                        <>
                          <div className="text-white text-[10px] hover:cursor-pointer leading-[15px] z-[2] rounded p-4 w-full bg-tooltipbg">
                            <p className="whitespace-normal">
                              {eventInfo.event.title}
                            </p>
                          </div>
                        </>
                      }
                    >
                      <div className="flex items-center">
                        <img
                          src={stickerNote}
                          alt="stickernote"
                          className="cursor-pointer max-w-[16px] mr-3"
                        />
                        <p className="absolute left-6 block whitespace-nowrap overflow-hidden text-ellipsis w-[80%]">{eventInfo.event.extendedProps.description}</p>
                      </div>
                    </Tooltip>
                  </div>
                ) : (
                  ""
                )}
              </div> : ''}
              <div className={classNames(styles.stickerNote, "stickerNote")}>
                <img
                  onClick={(e) => hoverStickerNote(e)}
                  src={stickerNote}
                  alt="stickernote"
                  className="ml-auto cursor-pointer md:max-w-5 md:max-h-5"
                />
                <div className={classNames(styles.stickerNoteContent, "bottom-0 stickerNoteContent")}>
                  <div
                    onMouseOut={(e) => unHoverStickerNote(e)}
                    onClick={(e) => editNote(e)}
                  >
                    <textarea
                      placeholder="Click to submit note"
                      onBlur={(e) => {
                        noteDesc = e.target.value
                      }}
                      // onChange={(e) => {
                      //   setTimeout(() => {
                      //     console.log("Testing Note ",e.target.value)
                      //     noteDesc = e.target.value
                      //   }, 1500);
                      // }}
                      defaultValue={eventInfo?.event?.extendedProps?.note?.trim().length <= 0 ? "" : eventInfo?.event?.extendedProps?.note}
                      ref={noteRef}
                      readOnly
                      className="focus:outline-none resize-none min-h-[80px] whitespace-normal px-4 py-[10px] text-left font-poppins text-[13px] font-semibold text-[#464E5F]"
                    />
                    {/* {eventInfo.event.extendedProps.note?.trim().length <= 0 ? "" : eventInfo.event.extendedProps.note} */}
                    {/* </textarea> */}

                  </div>
                  <div
                    className={classNames(
                      styles.noteButtons,
                      " flex flex-wrap justify-center mt-1 mb-4"
                    )}
                  >
                    <button
                      onClick={(e) => clickDiscardBtn(e)}
                      className={classNames(
                        styles.discardBtn,
                        "mx-1 rounded-[10px] py-2 px-4 bg-[#EFEFEF] font-poppins text-xs font-semibold text-[#979797]"
                      )}
                    >
                      Discard
                    </button>
                    <button
                      onClick={(e) => clickSaveBtn(e, eventInfo.event, noteDesc, eventInfo.event.extendedProps.uniqeID, eventInfo.event.extendedProps.from_date)}
                      className={classNames(
                        styles.saveBtn,
                        "mx-1 rounded-[10px] py-2 px-4 bg-[#009EF7] font-poppins text-xs font-semibold text-white"
                      )}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="md:hidden block mobile-calendar-event">
              <span className="block w-2 h-2 bg-vorpblue rounded-full mx-auto"></span>
            </div>
          </div>

        ) : (
          <div>
            <div id={eventInfo.event.uniqeID}
              className={classNames(
                styles.eventContent, eventOverlapClass,
                "xl:px-[4px] px-1 py-[1px] mb-[1px] rounded-[3px] eventContent",
                eventType == "SL"
                  ? "bg-[#FFEBED]"
                  : eventInfo.event.title
                    ? "bg-[#E5F6FF]"
                    : ""
              )}
            >
              {eventInfo.event.title ? (
                <div
                  className={classNames(
                    eventType == "AL"
                      ? styles.eventtitle
                      : eventType == "UAT"
                        ? styles.eventProjectUAT
                        : eventType == "Launch"
                          ? styles.eventProjectLaunch
                          : eventType == "DC"
                            ? styles.eventProjectDesign
                            : eventType == "SL"
                              ? styles.eventSickLeave
                              : eventType == "SL" && leaveFullHalf?.includes("Half")
                                ? styles.eventHalfSickLeave
                                : eventType == "UL"
                                  ? styles.eventUnpaidLeave
                                  : eventType == "UL" && leaveFullHalf?.includes("Half")
                                    ? styles.eventHalfUnpaidLeave
                                    : eventType == "BL"
                                      ? styles.eventBd
                                      : eventType == "BL" && leaveFullHalf?.includes("Half")
                                        ? styles.eventHalfBd
                                        : eventType == "AL" && leaveFullHalf?.includes("Half")
                                          ? styles.eventHalfDay
                                          : eventType == "KOM"
                                            ? styles.eventKOM
                                            : eventType == "D"
                                              ? styles.eventDevelopment
                                              : eventType == "SEOS"
                                                ? styles.eventSEO
                                                : eventType == "CUAT"
                                                  ? styles.eventClientUAT
                                                  : eventType == "ML"
                                                    ? styles.eventMaternity
                                                    : eventType == "PL"
                                                      ? styles.eventPaternity
                                                      : eventType == "TL"
                                                        ? styles.eventSubstitute
                                                        : eventType == "OL"
                                                          ? styles.eventLeftOver
                                                          : eventType == "CL"
                                                          ? styles.eventChildLeave                                                          
                                                          : eventType == "LD"
                                                            ? styles.eventLaunchDay
                                                            : "",
                    "flex font-poppins 2xl:text-xs text-[0.65rem] font-normal text-[#464E5F]"
                  )}
                >
                  {eventType == "SGH" ? (
                    <img src={sgHoliday} alt="sgHoliday" className="mr-2 sm:w-auto w-[7px]" />
                  ) : eventType == "HKH" ? (
                    <img src={hkHoliday} alt="hkHoliday" className="mr-2 sm:w-auto w-[7px]" />
                  ) : (
                    ""
                  )}
                  <Tooltip
                    arrow
                    classes={{ tooltip: classes.tooltip }}
                    enterTouchDelay={0}
                    leaveTouchDelay={5000}
                    placement="left"
                    title={
                      <>
                        <div className="text-white text-[10px] hover:cursor-pointer leading-[15px] z-[2] rounded p-4 w-full bg-tooltipbg">
                          <p className="whitespace-normal">

                            {
                              eventTarget == "L" ? eventInfo.event.title + '(' + start + ' - ' + end + ')' :
                                eventTarget == "H" ? eventInfo.event.title + '(' + start + ' - ' + end + ')' :
                                  props.indicators?.find((x: any) => x.flag == eventType)?.typeName + '(' + eventInfo.event.title + ')'}
                          </p>
                        </div>
                      </>
                    }
                  >
                    <p className="whitespace-nowrap overflow-hidden text-ellipsis  3xl:w-[100%] sm:w-[80%] w-1/2">
                      {eventInfo.event.title} {leaveFullHalf}
                    </p>
                  </Tooltip>
                </div>
              ) : (
                ""
              )}
              {eventInfo.event.extendedProps.subtitle ? (
                <p className=" md:block hidden ml-[18px] whitespace-normal font-poppins 2xl:text-xs text-[0.65rem] font-normal text-[#464E5F]">
                  {eventInfo.event.extendedProps.subtitle}
                </p>
              ) : (
                ""
              )}
            </div>
            <div className="md:hidden block mobile-calendar-event">
              <span className="block w-2 h-2 bg-vorpblue rounded-full mx-auto"></span>
            </div>
          </div>

        )}
      </>
    );
  };
  const renderMobileEventList = (currentEventList: any, currentDate: string) => {
    return <div className={classNames(currentEventList.length > 0 ? 'md:hidden block' : 'hidden', " mobile-view mt-5")}>
      <div>
        <span className={classNames(styles.mobileDate, "text-[#464E5F] text-[12px] font-primary mb-3 flex w-full items-center whitespace-nowrap")}>{currentDate}</span>
      </div>
      <ul>
        {currentEventList?.map((curEvent: any, key: number) => {
          var eventType = curEvent.eventType;
          var leaveFullHalf = curEvent.fullHalfDay;
          var eventTarget = curEvent.eventTarget;
          var start = curEvent.start;
          var end = curEvent.end;
          
          return <li key={key}>
            <div
                className={classNames(
                  eventType == "AL"&& !leaveFullHalf?.includes("Half")
                    ? styles.eventtitle
                    : eventType == "UAT"
                      ? styles.eventProjectUAT
                      : eventType == "Launch"
                        ? styles.eventProjectLaunch
                        : eventType == "DC"
                          ? styles.eventProjectDesign
                          : eventType == "SL"&& !leaveFullHalf?.includes("Half")
                            ? styles.eventSickLeave
                            : eventType == "SL" && leaveFullHalf?.includes("Half")
                              ? styles.eventHalfSickLeave
                              : eventType == "UL"&& !leaveFullHalf?.includes("Half")
                                ? styles.eventUnpaidLeave
                                : eventType == "UL" && leaveFullHalf?.includes("Half")
                                  ? styles.eventHalfUnpaidLeave
                                  : eventType == "BL"
                                    ? styles.eventBd
                                    : eventType == "BL" && leaveFullHalf?.includes("Half")
                                      ? styles.eventHalfBd
                                      : eventType == "AL" && leaveFullHalf?.includes("Half")
                                        ? styles.eventHalfDay
                                        : eventType == "KOM"
                                          ? styles.eventKOM
                                          : eventType == "D"
                                            ? styles.eventDevelopment
                                            : eventType == "SEOS"
                                              ? styles.eventSEO
                                              : eventType == "CUAT"
                                                ? styles.eventClientUAT
                                                : eventType == "ML"
                                                  ? styles.eventMaternity
                                                  : eventType == "PL"
                                                    ? styles.eventPaternity
                                                    : eventType == "TL"
                                                      ? styles.eventSubstitute
                                                      : eventType == "OL"
                                                        ? styles.eventLeftOver
                                                        : eventType == "LD"
                                                          ? styles.eventLaunchDay
                                                          : "",
                  "flex font-poppins 2xl:text-xs text-[0.65rem] font-normal text-[#464E5F]"
                )}
              >
                {eventType == "SGH" ? (
                  <img src={sgHoliday} alt="sgHoliday" className="mr-2 sm:w-[10px] w-[10px]" />
                ) : eventType == "HKH" ? (
                  <img src={hkHoliday} alt="hkHoliday" className="mr-2 sm:w-[10px] w-[10px]" />
                ) : (
                  ""
                )}
                <div className="md:hidden block text-[#858795] text-[10px] hover:cursor-pointer leading-[15px] z-[2] rounded py-[2px] w-full">
                  <div className="whitespace-normal">

                    {curEvent.eventNote == false ?
                      <span className="block text-left">{eventTarget == "L" ? curEvent.title + '(' + renderDateFormatDMY(new Date(start),'-') + ' - ' + renderDateFormatDMY(new Date(end),'-') + ')' :
                        eventTarget == "H" ? curEvent.title + '(' + renderDateFormatDMY(new Date(start),'-') + ' - ' + renderDateFormatDMY(new Date(end),'-') + ')' :
                          props.indicators?.find((x: any) => x.flag == eventType)?.typeName + '(' + curEvent.title + ')'}</span> :
                      <div className={classNames(curEvent.eventNote == true ? 'opacity-1' : 'opacity-0 z-[-1] overflow-hidden h-0', " items-center transition-all")}>
                        <div className="flex items-center">
                          <img src={noteIcon} alt="sticker note" className="max-w-[10px] mr-2" />
                          <textarea onBlur={(e) => setMobileNote(e.target.value)} placeholder="Click to submit note" rows={3} className={classNames(isShowEditNote == true ? '' : 'hidden', styles.stickerNoteMobile, "p-4 resize-none w-full focus-visible:outline-none focus:outline-none border-2 border-[#fff0cd] rounded-md")}>
                            {mobileNote}</textarea>
                          <span onClick={(e) => {
                            setMobileNote(curEvent.description)
                            setIsShowEditNote(true);

                          }} className={classNames(isShowEditNote == false ? '' : 'hidden', "text-left block text-[#858795] text-[10px] cursor-pointer w-full")}>
                            {
                              //noteDescRef ? noteDescRef.current?noteDescRef.current.value: curEvent.description ? curEvent.description : "Click to submit note":
                              mobileNote ? mobileNote : "Click to submit note"}</span>
                        </div>
                        <div
                          className={classNames(
                            styles.noteButtons,
                            isShowEditNote == true ? 'flex ' : 'hidden',
                            " flex-wrap justify-center mt-4 md:mb-4 mb-2"
                          )}
                        >
                          <button
                            onClick={(e) => { setIsShowEditNote(false); clickDiscardBtn(e) }}
                            className={classNames(
                              styles.discardBtn,
                              "mx-1 rounded-[10px] md:py-2 py-1 px-4 bg-[#EFEFEF] font-poppins text-xs font-semibold text-[#979797]"
                            )}
                          >
                            Discard
                          </button>
                          <button
                            onClick={(e) => clickSaveBtnMobile(e, curEvent, mobileNote, curEvent.uniqeID, curEvent.start)}
                            className={classNames(
                              styles.saveBtn,
                              "mx-1 rounded-[10px] md:py-2 py-1 px-4 bg-[#009EF7] font-poppins text-xs font-semibold text-white"
                            )}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    }
                  </div>
                </div>
              </div>
          </li>
        })}
      </ul>

    </div>
  }
  let customView = props.isProfile ? "" : "myCustomButton";
  var isAuthCreateEvent = getAuthUser()?.permissions?.find((x: any) => x.name == "Create_New_Event");
  useEffect(() => {
    if (isAuthCreateEvent) {
      if (props.isProfile == true || props.noFilter == true) {
        setCusButton("");
        setCusFilterButton("");
      } else {
        setCusButton("myCustomButton");
        setCusFilterButton("");
      }
    } else {
      setCusButton("");
      setCusFilterButton("");
    }

    const calendarApi = props.calendarRef?.current.getApi();
    // calendarApi.setOption('eventLimit', true);
    // calendarApi.setOption('eventLimitClick', 'popover');
    setEventsDataList(props.eventsList)
    setCurrentEventList(props.eventsList?.filter((x: any) => x.start == currentDate));
    calendarApi.refetchEvents();
    calendarApi.setOption('contentHeight', 'auto');
    var today = document.getElementsByClassName('fc-today-button')[0];
    if (today) {
      today.addEventListener('click', function () {
        props.changeToToday();
      })
    }
    var div = document.getElementsByClassName(styles.stickerNote);
    for (var i = 0; i < div.length; i++) {
      div[i].parentElement?.parentElement?.parentElement?.classList.add('position-unset')
    }

  }, [custButton, props]);
  useEffect(() => {
    window.addEventListener("resize", handleResize, false);
  }, []);
  const handleResize = () => {
    if (window.innerWidth > 641) {
      setIsDesktop(true);
    } else {
      setIsDesktop(false);
    }
  }
  const noteDescRef = useRef<any>(null);
  const [isShowEditNote, setIsShowEditNote] = useState<boolean>(false);

  const clickSaveBtnMobile = (event: any, extData: any, noteData: string, uniqeID: number, date: string) => {
    var loginData = getAuthUser();
    var start = extData?.start ? renderDateFormat(new Date(extData.start), "-") : "";
    var end = extData?.start ? renderDateFormat(new Date(extData.start), "-") : "";
    var noteDescription = noteData;
    if (extData.uniqueID) {
      var editedNoteData = {
        uniqeID: extData.uniqueID ? extData.uniqueID : 0,
        start: start,
        end: end,
        eventType: "N",
        eventTarget: 0,
        targetId: loginData?.user?.id,
        eventNote: true,
        note: noteDescription,
        description: noteDescription,
        title: "",
        leaveType: "",
        day: ""
      };
      props.editNoteData(editedNoteData);
    } else {
      var editedNoteDataNew = {
        uniqeID: extData.uniqueID ? extData.uniqueID : 0,
        start: start,
        end: end,
        eventType: "N",
        eventTarget: 0,
        TargetId: loginData?.user?.id,
        eventNote: true,
        note: noteDescription,
        description: noteDescription,
        title: "",
        leaveType: "",
        day: ""
      };
      var newNote = [];
      newNote.push(editedNoteDataNew);
      props.createEvent(newNote);
    }
    setEventsDataList((prevState: any) => {
      const newState = prevState.map((obj: any) => {
        if (obj.uniqueID === uniqeID) {
          return { ...obj, description: noteData, title: noteData };
        }
        return obj;
      });
      return newState;
    })
    setIsShowEditNote(false);
  };

  const changeCurrentEventList = useCallback(
    (currentEvt?: any) => {
      setCurrentEventList(currentEvt);
    },
    [currentEventList]
  );

  return (
    <div
      className={classNames(
        styles.fc,
        props.isProfile ? styles.isProfile : props.noFilter == true ? styles.noFilter : "",
        props.isProfile ? 'profileCalendar' : 'dashboardCalendar',
        "md:hidden block",
        "mt-5"
      )}
    >
      <FullCalendar
        timeZone="UTC"
        ref={props.calendarRef}
        contentHeight="auto"
        eventOverlap={false}
        height="auto"
        selectMirror={true}
        viewClassNames={styles.viewStyle}
        customButtons={{
          myCustomButton: {
            text: "+ Add New",
            click: function () {
              props.setNewEventPopup();
            },
          },
          prevCustomButton: {
            icon: "chevron-left",
            click: function () {
              props.changeToPrevMonth();
            },
          },
          nextCustomButton: {
            icon: "chevron-right",
            click: function () {
              props.changeToNextMonth();
            },
          },
        }}
        moreLinkContent={(info) => {
          return "... " + info.num + " more";
        }}
        dayMaxEvents={2}
        // dayMaxEventRows={3}
        views={{
          dayGrid: {
            // eventLimit: 3, // Set the maximum number of events to display per day
          },
        }}
        headerToolbar={{
          left: "prevCustomButton,nextCustomButton,today " + custFilterButton,
          center: "title",
          right: custButton,
        }}
        selectable={true}
        editable={true}
        eventOrder="sort"
        datesSet={props.handleDateChange}
        dayHeaderClassNames={styles.customHeader}
        dayCellClassNames={styles.customDayCell}
        allDayText={styles.customAllDay}
        eventClassNames={classNames(styles.customEvent)}
        moreLinkClassNames={styles.eventPopup}
        //events={eventsDataList}
        events={eventsDataList.map((event: any) => {
          return ({
            ...event,
            displayStart: new Date(new Date(event.start).getTime()), // Adjust displayStart by reducing 1 day
            displayEnd: new Date(new Date(event.end).getTime()), // Adjust displayEnd by reducing 1 day
          })
        })}
        dayCellContent={(day: any) =>
          ("00" + day.dayNumberText.toString()).substring(
            day.dayNumberText.toString().length
          )
        }
        // dateClick={handleDateClick}
        plugins={[dayGridPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        eventContent={renderEventContent}
        slotEventOverlap={false}
        selectOverlap={false}
        dateClick={(info: any) => {
          var currentDate = renderDateFormat(new Date(info.dateStr), '-');
          setCurrentDate(currentDate);
          var filterData: any = [];
          eventsDataList.map((x: any) => {
            // filterData.push(x);
            var endDate = x.end;
            if (x.end) {
              var endD = new Date(x.end);
              var custEndD = endD.getDate() - 1;
              endD.setDate(custEndD);
              filterData.end = renderDateFormat(endD, "-");
              filterData.date = renderDateFormat(endD, "-");
              endDate = renderDateFormat(endD, "-");
            }
            filterData.push({
              "uniqueID": x.uniqueID,
              "title": x.title,
              "date": endDate,
              "start": x.start,
              "end": endDate,
              "note": x.note,
              "eventType": x.eventType,
              "eventTarget": x.eventTarget,
              "targetId": x.targetId,
              "eventNote": x.eventNote,
              "fullHalfDay": x.fullHalfDay,
              "description": x.description ? x.description : '',
              "show_note": x.show_note,
              "sort": x.show_note == true ? 0 : 1
            });
            return filterData;
          });
          setIsShowEditNote(false);
          var currentEvents = filterData?.filter((x: any) => (x.date == currentDate || x.start == currentDate || (new Date(x.start) < new Date(currentDate) && new Date(x.end) > new Date(currentDate)) || (x.eventNote == true && x.start == currentDate && x.show_note == true)));          
          changeCurrentEventList(currentEvents)
          setCurrentEventList(currentEvents);          
          setMobileNote(currentEvents?.find((x: any) => x.eventNote == true)?.description)
        }}

      />
      {currentEventList?.length > 0 ? renderMobileEventList(currentEventList, currentDate) : ''}
    </div>

  );
};
export default DashboardCalendarMobile;
