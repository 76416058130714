import { Modal, Fade } from "@mui/material"
import classNames from "classnames";
import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
// import { Employee } from "../../active-time-report/views/ActiveTimeReport";
// import CustomDatePicker from "../common/BaseDatePicker";
import { StringDropdownList } from "../common/DropdownList";
import { CalendarIcon, CloseIcon } from "../common/Icons";
import TimeTrackingPieChart from "./TimeTrackingPieChart";
import styles from "./TimeTrackingPopup.module.scss";
// import calendarIcon from "./img/bi_calendar-week.svg";
import { EmpyTimeSheet, TimeSheetDetail, useGetTimesheetDetailQuery } from "../../app/services/timesheet";
import { renderDateFormatYMD } from "../DashboardCalendarView/DashboardAddEventPopup";
// import { baseObj } from "../../utils/constants";


interface ITimeTrackingModal {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  // employeeList: Employee[];
  employeeList: EmpyTimeSheet[];
}
const TimeTrackingPopup: FC<ITimeTrackingModal> = ({
  isOpen,
  setIsOpen,
  employeeList,
}) => {
  const defaultDate = new Date("11/01/2022");
  const [currentEmployee, setEmployee] = useState<string>("");
  const [date, setDate] = useState<Date>(new Date());
  const [getDate, setGetDate] = useState<any>()
  const [employeeOneData, setemployeeOneData] = useState<TimeSheetDetail>()
  const { data: timeSheetDetail } = useGetTimesheetDetailQuery({ employee_id: currentEmployee ? currentEmployee : 0, date: getDate === undefined ? renderDateFormatYMD(new Date(), '-') : renderDateFormatYMD(getDate, '-') });


  useEffect(() => {
    setemployeeOneData(timeSheetDetail?.data)
  }, [timeSheetDetail])

  var dateData = employeeOneData?.last_updated_at ? new Date(employeeOneData?.last_updated_at) : null;
  var dateStr = (dateData ? ("00" + (dateData?.getMonth() + 1)).slice(-2) : null) + "/" +
    (dateData ? ("00" + dateData?.getDate()).slice(-2) : null) + "/" +
    (dateData ? dateData.getFullYear() : null) + " " +
    (dateData ? ("00" + dateData.getHours()).slice(-2) : null) + ":" +
    (dateData ? ("00" + dateData.getMinutes()).slice(-2) : null) + ":" +
    (dateData ? ("00" + dateData.getSeconds()).slice(-2) : null);

  // const addZeroToDate = (date: string) => {
  //   var dateStr = date;
  //   if (date.length < 2) {
  //     dateStr = "0" + dateStr;
  //   }
  //   return dateStr;
  // };

  // const getCurrentData = (name: string) => {
  //   console.log("name", name)
  //   var curDate = date;
  //   console.log("return ", employeeList?.find((x) => x.id.toString() == name))
  //   return employeeList?.find((x) => x.id.toString() == name)?.data.find(d => d.date == changeDateFormat(curDate));
  // }

  // const changeDateFormat = (date?: Date) => {
  //   if (date != undefined) {
  //     var dateData = addZeroToDate(date.getDate().toString()) + '/' + addZeroToDate((date.getMonth() + 1).toString()) + '/' + date.getFullYear();
  //     return dateData;
  //   }
  //   else {
  //     return "";
  //   }
  // }

  const renderWorkingInfoContent = (name: string) => {
    return (
      <div>
        <div className="flex items-center">
          <div className="md:min-w-[240px] sm:min-w-[150px]  min-w-[50%] bg-offwhite px-3 py-[7px] sm:mr-5 mr-3">
            <p className="font-poppins font-medium lg:text-15 text-xs  text-black2">
              Start of Work
            </p>
          </div>
          <div>
            <p className="font-poppins font-normal lg:text-15 text-xs  text-black2">
              {employeeOneData?.startOfWork ? employeeOneData?.startOfWork : "00:00:00"}
            </p>
          </div>
        </div>
        <div className="flex items-center mt-[2px]">
          <div className="md:min-w-[240px] sm:min-w-[150px]  min-w-[50%]  bg-offwhite px-3 py-[7px] sm:mr-5 mr-3">
            <p className="font-poppins font-medium lg:text-15 text-xs  text-black2">
              End of work
            </p>
          </div>
          <div>
            <p className="font-poppins font-normal lg:text-15 text-xs  text-black2">
              {employeeOneData?.endOfWork ? employeeOneData?.endOfWork : "00:00:00"}
            </p>
          </div>
        </div>
        <div className="flex items-center mt-[2px]">
          <div className="md:min-w-[240px] sm:min-w-[150px]  min-w-[50%]  bg-offwhite px-3 py-[7px] sm:mr-5 mr-3">
            <p className="font-poppins font-medium lg:text-15 text-xs  text-black2">
              Total Hours
            </p>
          </div>
          <div>
            <p className="font-poppins font-normal lg:text-15 text-xs  text-black2">
              {employeeOneData?.totalTime ? employeeOneData?.totalTime : "00h:00m:00s"}
            </p>
          </div>
        </div>
      </div>
    );
  };

  const renderWorkingInfoDetailContent = (name: string) => {
    return (
      <div>
        <div className="flex items-center">
          <div className="md:min-w-[240px] sm:min-w-[150px]  min-w-[50%]  bg-offwhite px-3 py-[7px] sm:mr-5 mr-3">
            <p className="font-poppins font-medium lg:text-15 text-xs  text-black2">
              Active Time
            </p>
          </div>
          <div>
            <p className="font-poppins font-normal lg:text-15 text-xs  text-black2">
              {employeeOneData?.active ? employeeOneData?.active : "00h:00m:00s"}
            </p>
          </div>
        </div>
        <div className="flex items-center mt-[2px]">
          <div className="md:min-w-[240px] sm:min-w-[150px]  min-w-[50%]  bg-offwhite px-3 py-[7px] sm:mr-5 mr-3">
            <p className="font-poppins font-medium lg:text-15 text-xs  text-black2">
              Downtime
            </p>
          </div>
          <div>
            <p className="font-poppins font-normal lg:text-15 text-xs  text-black2">
              {employeeOneData?.downTime ? employeeOneData?.downTime : "00h:00m:00s"}
            </p>
          </div>
        </div>
        <div className="flex items-center mt-[2px]">
          <div className="md:min-w-[240px] sm:min-w-[150px]  min-w-[50%]  bg-offwhite px-3 py-[7px] sm:mr-5 mr-3">
            <p className="font-poppins font-medium lg:text-15 text-xs  text-black2">
              Lateness
            </p>
          </div>
          <div>
            <p className="font-poppins font-normal lg:text-15 text-xs  text-black2">
              {employeeOneData?.lateness ? employeeOneData?.lateness : "00h:00m:00s"}
            </p>
          </div>
        </div>
        <div className="flex items-center mt-[2px]">
          <div className="md:min-w-[240px] sm:min-w-[150px]  min-w-[50%]  bg-offwhite px-3 py-[7px] sm:mr-5 mr-3">
            <p className="font-poppins font-medium lg:text-15 text-xs  text-black2">
              Overtime
            </p>
          </div>
          <div>
            <p className="font-poppins font-normal lg:text-15 text-xs  text-black2">
              {employeeOneData?.overTime ? employeeOneData?.overTime : "00h:00m:00s"}
            </p>
          </div>
        </div>
      </div>
    );
  };

  const renderWorkingSummarizeContent = (name: string) => {
    // var currentEmployeeData = getCurrentData(name);
    return (
      <div>
        <div className="flex items-center">
          <div className="md:min-w-[240px] sm:min-w-[150px]  min-w-[50%]  bg-vorpgreen px-3 py-[7px] sm:mr-5 mr-3">
            <p className="font-poppins font-medium lg:text-15 text-xs  text-white">
              Productive
            </p>
          </div>
          <div>
            <p className="font-poppins font-normal lg:text-15 text-xs  text-black2">
              {/* {currentEmployeeData?.productive} */}
              {employeeOneData?.productive}
              <span className="ml-5">
                {/* {currentEmployeeData?.productivePercent} */}
                {employeeOneData?.productive_percentage}%
              </span>
            </p>
          </div>
        </div>
        <div className="flex items-center mt-[2px]">
          <div className="md:min-w-[240px] sm:min-w-[150px]  min-w-[50%]  bg-redPink px-3 py-[7px] sm:mr-5 mr-3">
            <p className="font-poppins font-medium lg:text-15 text-xs  text-white">
              Unproductive
            </p>
          </div>
          <div>
            <p className="font-poppins font-normal lg:text-15 text-xs  text-black2">
              {/* {currentEmployeeData?.unproductive} */}
              {employeeOneData?.unproductive}
              <span className="ml-5">
                {/* {currentEmployeeData?.unproductivePercent} */}
                {employeeOneData?.unproductive_percentage}%
              </span>
            </p>
          </div>
        </div>
        <div className="flex items-center mt-[2px]">
          <div className="md:min-w-[240px] sm:min-w-[150px]  min-w-[50%]  bg-vorphongkongborder px-3 py-[7px] sm:mr-5 mr-3">
            <p className="font-poppins font-medium lg:text-15 text-xs  text-white">
              Neutral
            </p>
          </div>
          <div>
            <p className="font-poppins font-normal lg:text-15 text-xs  text-black2">
              {/* {currentEmployeeData?.neutral} */}
              {employeeOneData?.neutral}
              <span className="ml-5">
                {/* {currentEmployeeData?.neutralPercent} */}
                {employeeOneData?.neutral_percentage}%
              </span>
            </p>
          </div>
        </div>
      </div>
    );
  };

  const renderPieChartData = (name: string) => {
    return (
      <div>
        <TimeTrackingPieChart currentEmployeeData={employeeOneData} />
      </div>
    );
  };

  const renderDateFormat = (date: Date) => {
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var day = date.getDate();
    return (
      (day.toString().length < 2 ? "0" + day : day) +
      "/" +
      (month.toString().length < 2 ? "0" + month : month) +
      "/" +
      year
    );
  };


  return (
    <Modal
      open={isOpen}
      style={{
        background: "rgb(0 0 0 / 0.3)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      // onClose={() => { setIsOpen(false); setEmployee(''); setGetDate(new Date()); setDate(new Date()) }}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
          // Set 'open' to false, however you would do that with your particular code.
          setIsOpen(false);
          setEmployee('');
          setGetDate(new Date());
          setDate(new Date())
        }
      }
      }
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableEnforceFocus={true}
    >
      <Fade in={isOpen} timeout={500}>
        <div className={classNames(styles.scrollHide, "modalContent focus-visible:outline-none max-w-[1161px] w-full m-auto px-4 py-8 xl:px-0 xl:py-0 font-primary max-h-[640px] overflow-y-auto")}>
          <div className="w-full bg-white rounded-xl overflow-hidden">
            <div className="px-6 sm:px-8 md:px-10 py-6 bg-vorpblue flex justify-between items-center rounded-t-xl overflow-hidden">
              <h1 className="text-16 sm:text-18 leading-[27px] font-semibold text-white">
                Time Tracking
              </h1>
              <button type="button" onClick={() => { setIsOpen(false); setEmployee(''); setGetDate(new Date()); setDate(new Date()) }}>
                <CloseIcon width={18} height={18} />
              </button>
            </div>
            <div className="lg:px-20 px-4 py-8 flex sm:flex-row flex-col items-center justify-center">
              <div
                className={classNames(
                  "flex relative lg:pr-10 sm:pr-6 py-4 xl:w-[55.5%] lg:w-[65%] sm:w-3/5",
                  styles.employeeData
                )}
              >
                <div className="w-full">
                  <div className="flex lg:flex-nowrap flex-wrap lg:flex-row sm:flex-col flex-row justify-between">
                    <div className="flex items-center sm:mr-10 mb-2">
                      <p className="base-label text-xs font-medium text-black2">
                        EMPLOYEE
                      </p>
                      <div className={classNames(styles.employeeListDropdown)}>
                        <StringDropdownList
                          state={currentEmployee}
                          setState={
                            setEmployee
                          }
                          dropdownItems={employeeList?.map((emp) => {
                            return {
                              label: emp.name,
                              value: emp.id,
                            };
                          })}
                          className="w-full min-w-[100px] 2xl:min-w-[128px] 6xs:text-12"
                          placeholder="Select"
                          paddingClass="py-2"
                          textAlign="text-left py-2 pl-2 pr-3"
                        />
                      </div>
                    </div>
                    <div className="flex items-center mb-2">
                      <p className="base-label text-xs font-medium text-black2">
                        DATE
                      </p>
                      <div className={`5xs:mr-3`}>
                        <ReactDatePicker
                          className="w-fit"
                          selected={date}
                          onChange={(prev: Date) => {
                            setDate(prev)
                            setGetDate(prev)
                          }}
                          dateFormat={"yyyy-MM-dd"}
                          showMonthDropdown
                          showYearDropdown
                          customInput={
                            <button className="border rounded-md border-offwhite flex justify-between items-center w-full text-[13px] sm:text-14 leading-5 text-graydark">
                              <span
                                className={classNames(
                                  "font-poppins text-graydark text-sm font-normal w-full h-full px-[14px] py-2 text-left"
                                )}
                              >
                                {renderDateFormat(date)}
                              </span>
                              <div className={classNames("px-[14px] py-2")}>
                                <CalendarIcon width={18} height={20} />
                              </div>
                            </button>
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="mt-2">
                    {renderWorkingInfoContent(currentEmployee)}
                    <div className="mt-[15px]">
                      {renderWorkingInfoDetailContent(currentEmployee)}
                    </div>
                    <div className="mt-[15px]">
                      {renderWorkingSummarizeContent(currentEmployee)}
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={classNames(
                  styles.TimetrackingChart,
                  "lg:pl-10 sm:pl-6 xl:w-[44.5%] lg:w-[35%] sm:w-2/5"
                )}
              >
                {renderPieChartData(currentEmployee)}
                <div className="lg:mt-6 mt-2 text-center">
                  <p className="font-poppins font-medium lg:text-15 text-xs  text-black2">
                    Last Updated on:{" "}
                    <span className="font-normal ml-2">{dateData != null ? dateStr : ""}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};
export default TimeTrackingPopup;
