import { ResponseWorkingShift, ResponseWorkingShiftList, ResuestWorkingShiftList } from "../../modals/working-shift/WorkingShift";
import { redirectToLogin } from "./annoucement-sheet";
import { api, endpointUrl } from "./api";
import authHeader from "./auth-header";

export const workingShiftApi = api.injectEndpoints({
  endpoints: (build) => ({
    getWorkingShiftList: build.query<ResponseWorkingShiftList, Partial<ResuestWorkingShiftList>>({
      query: ({
        search,
        country_id,
        shift_timing,
        page,
        limit,
      }) => ({
        url: `${endpointUrl}working-shifts?search=${search}&country_id=${country_id}&shift_timing=${shift_timing}&page=${page}&limit=${limit}`,
        headers: authHeader(),
      }),
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
      providesTags: () => [{ type: "WorkingShift" as const, id: "LIST" }],
    }),

    getWorkingShiftFilterList: build.query<ResponseWorkingShift, void>({
      query: () => ({
        url: `${endpointUrl}working-shifts-filter-data`,
        headers: authHeader(),
      }),
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
      // providesTags: (result: any = {}) => [
      //   { type: "Monitoring" as const, id: "LIST" }
      // ],
    }),

    deleteWorkingShift: build.mutation<{ success: boolean }, number>({
      query(id) {
        return {
          url: `/working-shifts/${id}`,
          method: "DELETE",
          headers: authHeader(),
        };
      },
      invalidatesTags: () => [{ type: "WorkingShift", id: "LIST" }],
    }),

    saveWorkingShift: build.mutation<any, any>({
      query: (data) => ({
        url: `${endpointUrl}working-shifts`,
        headers: authHeader(),
        method: "POST",
        body: data,
      }),
      invalidatesTags: [{ type: "WorkingShift", id: "LIST" }],
    }),

    updateWorkingShift: build.mutation<any, any>({
      query: (data) => ({
        url: `${endpointUrl}working-shifts/${data.id}`,
        headers: authHeader(),
        method: "PUT",
        body: data,
      }),
      invalidatesTags: [{ type: "WorkingShift", id: "LIST" }],
    }),

    multiDeleteWorkingShift: build.mutation<any, any>({
      query: (ids) => (
        {
          url: `${endpointUrl}working-shifts`,
          headers: authHeader(),
          method: "DELETE",
          body: { ids },
        }
      ),
      invalidatesTags: [{ type: "WorkingShift", id: "LIST" }],
    }),
  }),
});

export const {
  useGetWorkingShiftListQuery,
  useGetWorkingShiftFilterListQuery,
  useSaveWorkingShiftMutation,
  useUpdateWorkingShiftMutation,
  useDeleteWorkingShiftMutation,
  useMultiDeleteWorkingShiftMutation,
} = workingShiftApi;

export const {
  endpoints: {
    getWorkingShiftList,
    getWorkingShiftFilterList,
    deleteWorkingShift,
    saveWorkingShift,
    updateWorkingShift,
    multiDeleteWorkingShift,
  },
} = workingShiftApi;

