import { ColumnDef, ColumnFiltersState, PaginationState, RowSelectionState, SortingState, createColumnHelper } from "@tanstack/react-table";
import { Dispatch, FC, SetStateAction, useMemo, useState } from "react";
import { IOTData } from "../../ot-list/views/OTList";
import { baseObj } from "../../utils/constants";
import { templateData } from "../../app/services/template";
import { TemplateData } from "../../app/services/annoucement-sheet";
import { ToastContainer } from "react-toastify";
import classNames from "classnames";
import LayoutAdjustableTable from "../Tables/LayoutAdjustableTable";
import TablePaginator from "../Tables/TablePaginator";

interface ILeaveAllowanceTable {
    isLayoutControlOpen: boolean;
    setIsLayoutControlOpen: Dispatch<SetStateAction<boolean>>;
    pagination: PaginationState;
    setPagination: Dispatch<SetStateAction<PaginationState>>;
    globalSearch: string;
    setGlobalSearch: Dispatch<SetStateAction<string>>;
    columnFilters: ColumnFiltersState;
    setColumnFilters: Dispatch<SetStateAction<ColumnFiltersState>>;
    orgDataList: IOTData[];
    data: baseObj[];
    setData: Dispatch<SetStateAction<baseObj[]>>;
    hiddenFields: string[];
    templateList: templateData[];
    setHiddenFields: any;
    setCurrentView: any;
    total: number;
    setCurrentPage: any;
    currentPage: number;
    setIsTemplate?: Dispatch<SetStateAction<boolean>>;
    localModuleId: number;
    setCols?: any;
    sorting: SortingState;
    setSorting: React.Dispatch<React.SetStateAction<SortingState>>;
}
const EmployeeLeaveAllowanceTable: FC<ILeaveAllowanceTable> = ({
    isLayoutControlOpen,
    setIsLayoutControlOpen,
    pagination,
    setPagination,
    globalSearch,
    setGlobalSearch,
    columnFilters,
    setColumnFilters,
    data,
    setData,
    orgDataList,
    hiddenFields,
    setHiddenFields,
    templateList,
    setCurrentView,
    total,
    setCurrentPage,
    currentPage,
    setIsTemplate,
    localModuleId,
    setCols,
    sorting,
    setSorting
}) => {
    const columnHelper = useMemo(() => createColumnHelper<any>(), []);
    const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
    const [itemCount, setItemCount] = useState<number>(total);
    const [isShow, setIsShow] = useState<any>([{ id: 0, isShow: false }]);
    const saveTemplateData = (template: any) => {
        var views = columns
            .map((x) => {
                if (x.id) {
                    if (template[x.id] == undefined) {
                        return x.id;
                    }
                }
            })
            .filter((x) => x != undefined);
        var viewsData = views.map((x) => (x != undefined ? x : ""));
        var newTemplate: TemplateData = {
            id: 0,
            name: "View" + templateList.length + 1,
            viewColumns: viewsData,
        };
        setHiddenFields(viewsData);
        setCurrentView("");
    };
    const columns = useMemo(
        () => [
            columnHelper.accessor("code", {
                header: "Employee Code",
                id: "code",
                cell: ({ getValue, cell }) => {
                    const code = getValue();
                    return (
                        <div className="max-w-[160px] min-w-[140px] text-left bg-white text-black2 px-[10px] font-normal">
                            <p className="h-full flex items-center">{code}</p>
                        </div>
                    );
                },
            }),
            columnHelper.accessor("preferred_name", {
                header: "Employee Name",
                id: "preferred_name",

                cell: ({ getValue, cell }) => {
                    const preferred_name = getValue();
                    return (
                        <div className="max-w-[160px] min-w-[140px] text-left bg-white text-black2 px-[10px] font-normal">
                            <p className="h-full flex items-center">{preferred_name}</p>
                        </div>
                    );
                },
            }),
            columnHelper.accessor("joined_date", {
                header: "Joined Date",
                id: "joined_date",
                enableSorting:false,
                cell: ({ getValue, cell }) => {
                    const joined_date = getValue();
                    return (
                        <div className="max-w-[160px] min-w-[140px] text-left bg-white text-black2 px-[10px] font-normal">
                            <p className="h-full flex items-center">{joined_date}</p>
                        </div>
                    );
                },
            }),
            columnHelper.accessor("annual_leave_balance", {
                header: "Annual Leave Balance",
                id: "annual_leave_balance",
                cell: ({ getValue, cell }) => {
                    const annual_leave_balance = getValue();
                    return (
                        <div className="max-w-[160px] min-w-[140px] text-left bg-white text-black2 px-[10px] font-normal">
                            <p className="h-full flex items-center">{annual_leave_balance}</p>
                        </div>
                    );
                },
            }),
            columnHelper.accessor("birthday_leave_balance", {
                header: "Birthday Leave Balance",
                id: "birthday_leave_balance",
                cell: ({ getValue, cell }) => {
                    const birthday_leave_balance = getValue();
                    return (
                        <div className="max-w-[160px] min-w-[140px] text-left bg-white text-black2 px-[10px] font-normal">
                            <p className="h-full flex items-center">{birthday_leave_balance}</p>
                        </div>
                    );
                },
            }),
            columnHelper.accessor("sick_leave_balance", {
                header: "Sick Leave Balance",
                id: "sick_leave_balance",
                cell: ({ getValue, cell }) => {
                    const sick_leave_balance = getValue();
                    return (
                        <div className="max-w-[160px] min-w-[140px] text-left bg-white text-black2 px-[10px] font-normal">
                            <p className="h-full flex items-center">{sick_leave_balance}</p>
                        </div>
                    );
                },
            }),
            columnHelper.accessor("unpaid_leave_balance", {
                header: "Unpaid Leave Balance",
                id: "unpaid_leave_balance",
                cell: ({ getValue, cell }) => {
                    const unpaid_leave_balance = getValue();
                    return (
                        <div className="max-w-[160px] min-w-[140px] text-left bg-white text-black2 px-[10px] font-normal">
                            <p className="h-full flex items-center">{unpaid_leave_balance}</p>
                        </div>
                    );
                },
            }),
            columnHelper.accessor("maternity_leave_balance", {
                header: "Maternity Leave Balance",
                id: "maternity_leave_balance",
                cell: ({ getValue, cell }) => {
                    const maternity_leave_balance = getValue();
                    return (
                        <div className="max-w-[160px] min-w-[140px] text-left bg-white text-black2 px-[10px] font-normal">
                            <p className="h-full flex items-center">{maternity_leave_balance}</p>
                        </div>
                    );
                },
            }),
            columnHelper.accessor("paternity_leave_balance", {
                header: "Paternity Leave Balance",
                id: "paternity_leave_balance",
                cell: ({ getValue, cell }) => {
                    const paternity_leave_balance = getValue();
                    return (
                        <div className="max-w-[160px] min-w-[140px] text-left bg-white text-black2 px-[10px] font-normal">
                            <p className="h-full flex items-center">{paternity_leave_balance}</p>
                        </div>
                    );
                },
            }),
            columnHelper.accessor("substitution_leave_balance", {
                header: "Substitution Leave Balance",
                id: "substitution_leave_balance",
                cell: ({ getValue, cell }) => {
                    const substitution_leave_balance = getValue();
                    return (
                        <div className="max-w-[160px] min-w-[140px] text-left bg-white text-black2 px-[10px] font-normal">
                            <p className="h-full flex items-center">{substitution_leave_balance}</p>
                        </div>
                    );
                },
            }),
            columnHelper.accessor("left_over_leave_balance", {
                header: "Left Over Leave Balance",
                id: "left_over_leave_balance",
                cell: ({ getValue, cell }) => {
                    const left_over_leave_balance = getValue();
                    return (
                        <div className="max-w-[160px] min-w-[140px] text-left bg-white text-black2 px-[10px] font-normal">
                            <p className="h-full flex items-center">{left_over_leave_balance}</p>
                        </div>
                    );
                },
            }),
            columnHelper.accessor("left_over_expired_date", {
                header: "Left Over Leave Expired Date",
                id: "left_over_expired_date",
                enableSorting:false,
                cell: ({ getValue, cell }) => {
                    const left_over_expired_date = getValue();
                    return (
                        <div className="max-w-[160px] min-w-[140px] text-left bg-white text-black2 px-[10px] font-normal">
                            <p className="h-full flex items-center">{left_over_expired_date ? left_over_expired_date : '---'}</p>
                        </div>
                    );
                },
            }),
            columnHelper.accessor("child_care_leave_balance", {
                header: "Child Care Leave Balance",
                id: "child_care_leave_balance",
                cell: ({ getValue, cell }) => {
                    const child_care_leave_balance = getValue();
                    return (
                        <div className="max-w-[160px] min-w-[140px] text-left bg-white text-black2 px-[10px] font-normal">
                            <p className="h-full flex items-center">{child_care_leave_balance}</p>
                        </div>
                    );
                },
            }),
        ],
        [
            columnHelper,
            data,
            hiddenFields
        ]
    );
    const visibleColumns: { [key: string]: boolean } = {};

    hiddenFields?.map((x) => {
        visibleColumns[x] = true;
    });
    const [columnOrder, setColumnOrder] = useState<string[]>(
        columns.map((column: ColumnDef<any, any>) => column.id as string)
    );
    hiddenFields?.map((x) => {
        visibleColumns[x] = true;
    });

    if (hiddenFields.length > 0) {
        columns.map((x) => {
            if (x.id == "actions" || x.id == "selection" || x.id == "lasted") {
                visibleColumns[x.id ? x.id : ""] = true;
            } else if (x.id == "uniqeID") {
                visibleColumns[x.id ? x.id : ""] = false;
            } else if (x.id?.includes("none")) {
                visibleColumns[x.id ? x.id : ""] = true;
            } else {
                var isHidden = hiddenFields.find((h) => h == x.id);
                if (isHidden) {
                    visibleColumns[x.id ? x.id : ""] = true;
                } else {
                    visibleColumns[x.id ? x.id : ""] = false;
                }
            }
        });
    }
    return <div className={classNames('relative inline-block max-w-full')}>
        <ToastContainer className={"text-12 font-poppins font-normal"} />
        <LayoutAdjustableTable
            isManualSorting={false}
            saveTemplateData={saveTemplateData}
            hideColumn={visibleColumns}
            columns={columns}
            data={data}
            sorting={sorting}
            setSortingState={setSorting}
            rowSelection={rowSelection}
            setRowSelection={setRowSelection}
            columnOrder={columnOrder}
            setColumnOrder={setColumnOrder}
            unDraggableFields={["actions", "selection", "id"]}
            theaderColor="text-graydark"
            isLayoutControlOpen={isLayoutControlOpen}
            setIsLayoutControlOpen={setIsLayoutControlOpen}
            label="Leave Allowance List"
            setItemCount={setItemCount}
            pagination={pagination}
            setPagination={setPagination}
            globalFilter={globalSearch}
            setGlobalFilter={setGlobalSearch}
            columnFilters={columnFilters}
            setColumnFilters={setColumnFilters}
            noPaddingCells={[
                "currency_name",
                "Currency"
            ]}
            localModuleId={localModuleId}
            setIsTemplate={setIsTemplate}
            setCols={setCols}

        />
        <div className="pt-6">
            <TablePaginator
                total={itemCount}
                pagination={pagination}
                setPagination={setPagination}
                itemCount={total}
                setCurrentPage={setCurrentPage}
                currentPageDefault={currentPage}
            />
        </div>
    </div>
}
export default EmployeeLeaveAllowanceTable;