import { redirectToLogin } from './annoucement-sheet';
import { api } from './api';
import authHeader from './auth-header';
import { Ids } from './media';

export type ExchangeRageResponse = {
    exchangeRateList: ExchangeRateData,
    currencyList:currencyData[]
    module_id:number,
    status:boolean,
}
type ExchangeRateData={
    current_page:number;
    data:ExchangeRageListData[];
    total:number;
    
}
export type CountryCodeData={
    id:number;
    code:string;
    name:string;
}
export type ExchangeRageListData = {
    "id": number,
    "country_id": number,
    "country_name": string,
    "date": string,
    "month": string,
    "rate": string,
    "created_by_name": string,
    "created_at": string,
    "updated_at": string,
    "sorting":string
}
export type currencyData={
    "id": number,
    "currency_name": string,
    "currency_code": string
}
type exchangeRateInput={
    sorting:string,
    page:number,
    limit:number,
    search:string,
    currency?:any
}
export const incentiveApi = api.injectEndpoints({
    endpoints: (build) => ({
        getExchangeRate: build.query<ExchangeRageResponse, exchangeRateInput>({
            query: ({sorting,currency,page,limit,search}) => ({ url: `exchange-rates?currency_id=${currency}&limit=${limit}&page=${page}&search=${search}&sort=${sorting}`, headers: authHeader() }),
            transformErrorResponse: (err) => {
                if (err?.status === 401) {
                    redirectToLogin();
                }
            },
            providesTags: (result: any = []) => {
                if (result.data) {
                    if (result.data.length > 0) {
                        return [
                            ...result.data.map((id: any) => ({ type: "ExchangeRate", id } as const)),
                            { type: "ExchangeRate" as const, id: "LIST" },
                        ]
                    }
                    else {
                        return [{ type: "ExchangeRate" as const, id: "LIST" },]
                    }
                }
                else {
                    return [{ type: "ExchangeRate" as const, id: "LIST" },]
                }

            },
        }),
        createExchangeRate: build.mutation<ExchangeRageResponse, any>({
            query: (body) => {
                return {
                    url: `exchange-rates`,
                    method: "POST",
                    body,
                    headers: authHeader(),
                };
            },
            invalidatesTags: (events) => [{ type: "ExchangeRate", id: "LIST" }],
        }),
        updatedExchangeRate: build.mutation<ExchangeRageResponse, any>({
            query: (body) => {
                return {
                    url: `exchange-rates/${body.id}`,
                    method: "PUT",
                    body,
                    headers: authHeader(),
                };
            },
            invalidatesTags: (events) => [{ type: "ExchangeRate", id: "LIST" }],
        }),
    }),
});

export const {
    useGetExchangeRateQuery,
    useCreateExchangeRateMutation,
    useUpdatedExchangeRateMutation
} = incentiveApi;


export const {
    endpoints: {
        getExchangeRate,
        createExchangeRate,
        updatedExchangeRate
    },
} = incentiveApi;