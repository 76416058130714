import {
  ColumnDef,
  ColumnFiltersState,
  createColumnHelper,
  PaginationState,
  RowSelectionState,
  SortingState,
} from "@tanstack/react-table";
import classNames from "classnames";
import { ToastContainer, toast } from "react-toastify";

import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import LayoutAdjustableTable from "../Tables/LayoutAdjustableTable";
import { baseObj } from "../../utils/constants";
import {
  AssignIcon,
  TrashIcon,
  DownloadIcon,
  ViewIcon,
  EditIcon,
  TableSaveIcon,
  CancelIcon,
} from "../common/Icons";
import { DocsIcon } from "../DashboardProfile/Documents/icons/IconMapper";
import TablePaginator from "../Tables/TablePaginator";
import DocumentsGrid from "../DashboardProfile/Documents/DocumentsGrid";
import { IFilterDate } from "../DashboardProfile/Documents/Documents";
import { dateRangeFilterFn, formatDate } from "../../utils/helpers";
import { makeStyles } from "@mui/styles";
import { Modal, Tooltip } from "@mui/material";

import EmployeeAssignment from "../Passwords/EmployeeAssignment";
import DeletePopup from "../DeletePopup/DeletePopup";
import styles from "./EmployeeDocumentsTable.module.scss";
import { TemplateData } from "../../app/services/employee";
import { endpointUrl } from "../../app/services/api";
import axios from "../../axios";
import { assignUsers, PasswordUserList } from "../../app/services/password";
import EditableCell from "../Tables/EditableCell";
import CustomDropdownWithAddButton from "../EmployeeTable/CustomDropdownWithAddButton";
import { getAuthUser } from "../../app/services/dashboard";
import { DocFiles, ImageFormat } from "../../app/services/documents";
import AssignPopup from "../Passwords/AssignPopup";
import { useCreateTagMutation } from "../../app/services/annoucement-sheet";
import { tagsColor } from "../Announcements/AnouncementsTable";
import { EmployeeData } from "../EmployeeTable/EmployeeTable";
import { onViewDocument } from "../LeaveApplicationFIlesPopup/LeaveApplicationFilesPopup";
const useStyles = makeStyles(() => ({
  tooltip: {
    backgroundColor: "transparent",
    margin: 0,
    marginRight: 8,
    padding: 0,
  },
}));

interface IDocumentTable {
  isTableView?: boolean;
  pagination: PaginationState;
  setPagination: Dispatch<SetStateAction<PaginationState>>;
  globalSearch: string;
  setGlobalSearch: Dispatch<SetStateAction<string>>;
  columnFilters: ColumnFiltersState;
  setColumnFilters: Dispatch<SetStateAction<ColumnFiltersState>>;
  filterDate?: IFilterDate;
  isLayoutControlOpen: boolean;
  setIsLayoutControlOpen: Dispatch<SetStateAction<boolean>>;
  data: baseObj[];
  setData: Dispatch<SetStateAction<baseObj[]>>;
  hiddenFields: string[];
  templateList: TemplateData[];
  setHiddenFields: any;
  setCurrentView: any;
  changePage?: any;
  setCurrentPage: any;
  currentPage: number;
  total: number;
  userList: any;
  assignedUsers: any;
  localModuleId: number;
  assignedUsersMultiple: any;
  downloadDoc: any;
  deleteDoc: any;
  deleteDocs: any;
  setRowSelection: React.Dispatch<React.SetStateAction<RowSelectionState>>;
  rowSelection: RowSelectionState;
  saveDocumentData: any;
  assignTagsData: (documentId: number, tagsId: number[]) => void;
  tags: any;
  setCols?: any;
  setIsEdit: React.Dispatch<React.SetStateAction<boolean>>;
  isEditing: boolean;
  managementDocument: boolean;
  assignUserListData: assignUsers[];
  departmentList: EmployeeData[];
  setAssignDepartmentId: React.Dispatch<React.SetStateAction<string>>;
  setAssignKeword: React.Dispatch<React.SetStateAction<string>>;
  assignDepartmentId: string;
  sorting: SortingState;
  setSorting: Dispatch<SetStateAction<SortingState>>;
}
export const assignedColor = ["#FFC700", "#464E5F", "#3699FF", "#EE436D"];

const EmployeeDocumentsTable: FC<IDocumentTable> = ({
  isTableView = true,
  pagination,
  setPagination,
  globalSearch,
  setGlobalSearch,
  columnFilters,
  setColumnFilters,
  isLayoutControlOpen,
  setIsLayoutControlOpen,
  data,
  setData,
  hiddenFields,
  templateList,
  setHiddenFields,
  setCurrentView,
  setCurrentPage,
  currentPage,
  changePage,
  total,
  userList,
  assignedUsers,
  localModuleId,
  assignedUsersMultiple,
  downloadDoc,
  deleteDoc,
  deleteDocs,
  rowSelection,
  setRowSelection,
  saveDocumentData,
  assignTagsData,
  tags,
  setCols,
  setIsEdit,
  isEditing,
  managementDocument,
  assignUserListData,
  departmentList,
  setAssignDepartmentId,
  assignDepartmentId,
  setAssignKeword,
  sorting,
  setSorting,
}) => {
  const classes = useStyles();
  const columnHelper = useMemo(() => createColumnHelper<any>(), []); // TODO add type definitions when api binding
  var getUser = getAuthUser();

  const [itemCount, setItemCount] = useState<number>(total);
  const [createTag] = useCreateTagMutation();
  const selectionCount = Object.keys(rowSelection).length;
  const [isAssignModalOpen, setIsAssignModalOpen] = useState<boolean>(false);
  const [modalType, setModalType] = useState<"assign" | "remove">("assign");
  const [openDeletePopup, setOpenDeleteopup] = useState<boolean>(false);
  const [deleteID, setDeleteID] = useState<number | 0>();
  const [selectedUser, setSelectedUser] = useState<number>(0);
  const [selectedMember, setSelectedMember] = useState<number[]>();
  const [isMultiple, setIsMultiple] = useState<boolean>(false);
  const [showTagPopup, setShowTagPopup] = useState<boolean>(false);
  const [existingMember, setExistingMember] = useState<PasswordUserList[]>([]);
  const [ownerData, setOwnerData] = useState<string>("");
  const [chosenTags, setChosenTags] = useState<string[]>([]);
  const [editedTagData, setEditedTagData] = useState<any>();
  const [cellData, setCellData] = useState<any>();
  const [isShow, setIsShow] = useState<boolean>(false);
  const tagsData = tags
    ?.map((tag: any, idx: number) => ({
      label: tag.name,
      value: tag.name,
      name: tag.name,
      id: tag.id,
    }))
    .filter((tag: any) => tag.label.toLowerCase() !== "all");

  const onDownloadClick = (id: number, name: string) => {
    const extension = name.split(".").pop() as string;
    //downloadDoc();
    // using Java Script method to get PDF file
    axios({
      url: endpointUrl + `downloadDocument/${id}`,
      method: "GET",
      responseType: "blob",
      headers: {
        Authorization: `Bearer ${getUser.token}`,
        Accept: "application/json",
      },
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", name + "." + extension);
      document.body.appendChild(link);
      link.click();
    });
  };
  const deleteRow = useCallback(
    (id?: number) => {
      setDeleteID(id);
      setOpenDeleteopup(true);
    },
    [setData, rowSelection]
  );

  const confirmDeleteData = () => {
    let id = deleteID;
    setDeleteID(-1);
    if (id != -1 && id != undefined) {
      deleteDoc(id).then((res: any) => {
        if (res.data.status) {
          toast(`${res.data.message}`, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            type: "success",
            theme: "light",
          });
          setOpenDeleteopup(false);
        }
      });
    } else {
      deleteMultipleDoc();
    }
  };

  const editDocument = useCallback(
    (id: number) =>
      setData((prev: baseObj[]) => {
        return prev.map((data, index: number) => {
          if (data.id === id) {
            return { ...data, isNew: true };
          }
          return data;
        });
      }),
    [setData]
  );

  const saveDocument = useCallback(
    (id: number) =>
      setData((prev: baseObj[]) => {
        return prev.map((data, index: number) => {
          if (data.id === id) {
            let finalTags: any = [];
            tags?.map(function (item: any) {
              data.tags?.map((vtag: any, id: any) => {
                if (item.name == vtag) {
                  finalTags.push({
                    id: item.id,
                    name: item.name,
                  });
                }
              });
            });
            saveDocumentData(id, data?.owner?.id, data.tags, data);
            return { ...data, isNew: false };
          }
          return data;
        });
      }),
    [setData]
  );

  const cancelRow = (id: number) => {
    if (isEditing) {
      setIsEdit(false);
      setData((prev: baseObj[]) => {
        return prev.map((data, index: number) => {
          if (data.id === id) {
            return { ...data, isNew: false };
          }
          return data;
        });
      });
    } else {
      const filterData = data?.filter((d: any) => d?.uniqeID !== id);
      setData(filterData);
    }
  };

  const setChoosenTagData = useCallback((tagsData: any) => {
    setData((prev: any) => {
      if (prev.isNew == true) {
        prev.tag = tagsData;
      }
      return prev;
    });
    setChosenTags(tagsData);
  }, []);

  // TODO add type definitions when api binding
  const columns = useMemo(
    () => [
      columnHelper.display({
        header: "",
        id: `${managementDocument ? "selection" : "none-selection"}`,
      }),
      columnHelper.accessor("code", {
        id: "code",
        header: "ID",
        cell: (props) => (
          <span className="flex min-w-[130px] text-left bg-white text-black2 px-[20px] items-center h-full">
            {props.getValue()}
          </span>
        ),
      }),
      columnHelper.accessor("name", {
        header: "Name",
        id: "name",
        filterFn: "includesStringSensitive",
        cell: ({ getValue, cell }) => {
          const name = getValue();
          const url = cell.row.original.url;
          const extension = name.split(".").pop() as string;

          return (
            <div className="items-center justify-between pr-[6px] inline-block w-full bg-white text-black2 px-2">
              <div className="flex items-center w-full overflow-hidden">
                <img
                  src={
                    extension
                      ? DocsIcon[extension]
                        ? DocsIcon[extension]
                        : ImageFormat.includes(extension)
                        ? DocsIcon[extension]
                        : DocsIcon["unknow"]
                      : DocsIcon.folder
                  }
                  alt={name}
                  width={16}
                  height={16}
                  loading="lazy"
                  className="mr-[10px] max-w-[16px] max-h-[16px]"
                />
                <span className=" overflow-hidden whitespace-nowrap text-ellipsis">
                  {name}
                </span>
                {/* <Tooltip
                  arrow
                  classes={{ tooltip: classes.tooltip }}
                  enterTouchDelay={0}
                  leaveTouchDelay={5000}
                  placement="left"
                  title={
                    <>
                      <div className="text-white text-[10px] hover:cursor-pointer leading-[15px] z-[2] rounded p-4 w-full bg-tooltipbg">
                        <span className="text-[#fff] block">{name}</span>
                      </div>
                    </>
                  }
                >
                  <span className="max-w-[97%] overflow-hidden whitespace-nowrap text-ellipsis">
                    {name}
                  </span>
                </Tooltip> */}
              </div>
              {/* <MarkAsImportantBtn
                onClick={() => 1}
                initialState={cell.row.original.isImportant}
                setData={setData}
                rowId={cell.row.original.id}
              /> */}
            </div>
          );
        },
      }),

      columnHelper.accessor("tags", {
        header: "Tag",
        id: "tags",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const projectT = cell.row.original.tags;
          let finalTags: any = [];
          tags?.map(function (item: any) {
            projectT?.map((vtag: any, id: any) => {
              if (item.name == vtag.name) {
                finalTags.push(item.name);
              }
            });
          });
          const isEditable = cell.row.original?.isNew;
          return (
            <>
              {!isEditable ? (
                projectT?.length > 0 ? (
                  <div className="min-w-[150px] overflow-hidden px-2">
                    <div className="w-[97%] overflow-hidden flex items-center">
                      {projectT?.map((x: any, index: number) => {
                        var idx = index;
                        if (index >= 3) {
                          idx = index % 3;
                        }
                        return (
                          <div
                            key={index}
                            className="px-[5px] mr-[3px] rounded-[5px]"
                            style={{
                              backgroundColor: tagsColor[idx],
                              height: "auto",
                            }}
                          >
                            {x.name}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                ) : (
                  // <Tooltip
                  //   arrow
                  //   classes={{ tooltip: classes.tooltip }}
                  //   enterTouchDelay={0}
                  //   leaveTouchDelay={5000}
                  //   placement="left"
                  //   title={
                  //     <>
                  //       <div className="text-white text-[10px] hover:cursor-pointer leading-[15px] z-[2] rounded p-4 w-full bg-tooltipbg">
                  //         <span className="text-[#fff] block">
                  //           {projectT?.map((x: any) => x.name)?.join(", ")}
                  //         </span>
                  //       </div>
                  //     </>
                  //   }
                  // >
                  //   <div className="max-w-[300px] overflow-hidden px-2">
                  //     <div className="w-[97%] overflow-hidden flex items-center">
                  //       {projectT?.map((x: any, index: number) => {
                  //         var idx = index;
                  //         if (index >= 3) {
                  //           idx = index % 3;
                  //         }
                  //         return (
                  //           <div
                  //             key={index}
                  //             className="px-[5px] mr-[3px] rounded-[5px]"
                  //             style={{
                  //               backgroundColor: tagsColor[idx],
                  //               height: "auto",
                  //             }}
                  //           >
                  //             {x.name}
                  //           </div>
                  //         );
                  //       })}
                  //     </div>
                  //   </div>
                  // </Tooltip>
                  <span className="px-2">---</span>
                )
              ) : (
                <div className="min-w-full w-full flex justify-between items-center">
                  <EditableCell
                    customDesign="tooltip"
                    hasPadding={true}
                    setData={setData}
                    value={projectT?.map((x: any) => x.name).join(",")}
                    type="custom"
                    cell={cell}
                    CustomComponent={
                      <Tooltip
                        arrow
                        classes={{ tooltip: classes.tooltip }}
                        enterTouchDelay={0}
                        leaveTouchDelay={5000}
                        placement="left"
                        title={
                          <>
                            <div className="text-white text-[10px] hover:cursor-pointer leading-[15px] z-[2] rounded p-4 w-full bg-tooltipbg">
                              <span className="text-[#fff] block">
                                {projectT?.map((x: any) => x.name)?.join(", ")}
                              </span>
                            </div>
                          </>
                        }
                      >
                        <button
                          onClick={() => {
                            setIsShow(true);
                            setCellData(cell);
                            setEditedTagData(projectT);
                          }}
                          className="max-w-[200px] h-full font-poppins w-[90%] text-12 lg:text-14 px-5 overflow-hidden whitespace-nowrap text-ellipsis bg-vorpmyanmar text-black2"
                        >
                          {projectT?.length > 0 ? (
                            projectT?.map((x: any) => x.name)?.join(", ")
                          ) : (
                            <span className="px-2">---</span>
                          )}
                        </button>
                      </Tooltip>
                    }
                  />
                </div>
              )}
            </>
          );
        },
      }),
      columnHelper.accessor("owner", {
        header: "Owner",
        id: "owner",
        cell: ({ cell, getValue }) => {
          const owner = getValue();
          var ownD = "";
          if (typeof owner === "object") {
            ownD = owner?.name;
          } else if (typeof owner === "number") {
            ownD = userList?.find((x: any) => x.id == owner)?.name;
          } else {
            ownD = owner;
          }
          const originalValues = cell.row.original?.owner?.name;
          const isNew: boolean = cell.row.original?.isNew;
          return (
            <div className="min-w-[150px] text-left flex justify-center items-center">
              <EditableCell
                className="py-0"
                hasPadding={true}
                setData={setData}
                value={ownD ? ownD : "Select"}
                type="custom"
                cell={cell}
                CustomComponent={
                  <CustomDropdownWithAddButton
                    isShowDelete={false}
                    isShowAdd={false}
                    isValue={true}
                    customHeight="min-h-[220px]"
                    initialValues={originalValues}
                    dataList={userList}
                    setDataList={() => 1}
                    title="Document"
                    cell={cell}
                    data={data}
                    setData={setData}
                    setSelectedData={setOwnerData}
                    selectedData={ownD ? ownD : "Select"}
                    isEditable={isNew}
                  />
                }
              />
            </div>
          );
        },
      }),
      columnHelper.accessor("path", {
        header: "Path",
        id: "path",
        cell: (props) => (
          <div className="min-w-[200px] text-left flex items-center bg-white text-black2 px-2">
            {/* <Tooltip
              arrow
              classes={{ tooltip: classes.tooltip }}
              enterTouchDelay={0}
              leaveTouchDelay={5000}
              placement="left"
              title={
                <>
                  <div className="text-white text-[10px] hover:cursor-pointer leading-[15px] z-[2] rounded p-4 w-full bg-tooltipbg">
                    <span className="block">{props.getValue()}</span>
                  </div>
                </>
              }
            >
              <span className="max-w-[90%] overflow-hidden whitespace-nowrap text-ellipsis">
                {props.getValue()}
              </span>
            </Tooltip> */}
            <span className="w-[80%] overflow-hidden whitespace-nowrap text-ellipsis">
              {props.getValue()}
            </span>
          </div>
        ),
      }),
      columnHelper.accessor("lastModifiedAt", {
        header: "Last modified",
        id: "lastModifiedAt",
        filterFn: dateRangeFilterFn,
        cell: (props) => (
          <div className="min-w-[130px] text-left flex items-center bg-white text-black2 px-2">
            <span>{props.getValue()}</span>
          </div>
        ),
      }),
      columnHelper.accessor("postedBy", {
        header: "Posted By",
        id: "postedBy",
        cell: (props) => (
          <div className="min-w-[130px] text-left flex items-center bg-white text-black2 px-2">
            <span className="h-full flex items-center">
              {props.getValue()?.name}
            </span>
          </div>
        ),
      }),

      columnHelper.display({
        // TODO Change display to accessor when api binding
        header: () => <span className="text-center w-full">Assign To</span>,
        id: `${managementDocument ? "assignUsers" : "assignUsers-no"}`,
        enableSorting: false,
        cell: ({ cell, getValue }) => {
          const id = cell.row.original.id;
          const assigned = cell.row.original.assignUsers;
          return (
            <button
              type="button"
              onClick={() => {
                setModalType("remove");
                setIsAssignModalOpen(!isAssignModalOpen);
                setSelectedUser(id);
                setExistingMember(assigned);
              }}
              className="flex justify-center items-center h-full min-w-[150px] ml-[10px]"
            >
              {assigned?.length > 0 ? (
                assigned?.map((x: any, index: number) => {
                  if (index < 3) {
                    var idx = index;
                    if (idx > 4) {
                      idx = 0;
                    }
                    return x.profileImage ? (
                      <img
                        key={index}
                        src={x.profileImage}
                        alt="profileImage"
                        className="w-[35px] h-[35px] rounded-full object-cover ml-[-10px]"
                      />
                    ) : (
                      <div
                        key={index}
                        className={classNames(
                          "bg-[" + assignedColor[idx] + "]",
                          "ml-[-10px] w-[35px] h-[35px] rounded-full text-14 flex justify-center items-center text-white"
                        )}
                      >
                        {x.name?.substring(0, 1)}
                      </div>
                    );
                  }
                })
              ) : (
                <span>---</span>
              )}
              {assigned?.length > 3 ? (
                <div className="ml-[-10px] w-[35px] h-[35px] rounded-full text-10 flex justify-center items-center bg-black2 text-white">
                  {assigned?.length - 3}+
                </div>
              ) : (
                ""
              )}
              {/* {[User1, User3, User2].map((user, index) => (
                <img
                  src={user}
                  key={index}
                  width={35}
                  height={35}
                  className="w-[35px] h-[35px] ml-[-10px] object-contain object-center"
                  alt={`User ${index + 1}`}
                  loading="lazy"
                />
              ))}
              <div className="ml-[-10px] w-[35px] h-[35px] rounded-full text-14 flex justify-center items-center bg-vorpblue text-white">
                S
              </div>
              <div className="ml-[-10px] w-[35px] h-[35px] rounded-full text-10 flex justify-center items-center bg-black2 text-white">
                +42
              </div> */}
            </button>
          );
        },
      }),
      columnHelper.display({
        header: "Action",
        id: `${managementDocument ? "actions" : "actions-no"}`,
        cell: ({ cell, row }) => {
          const isNew: boolean = cell.row.original?.isNew;
          var isHaveNew = data?.find((x) => x.isNew == true);
          var disabledClass = isHaveNew ? "opacity-50 pointer-events-none" : "";
          const id = cell.row?.original?.id;
          const name = cell.row?.original?.name;
          const urls = cell.row.original.url;
          const extension = cell.row.original.type;
          const url = window.location.origin + "/document-view?id=" + urls;
          return (
            <div
              className={classNames(
                styles.actionedBtn,
                "flex justify-center items-center  max-w-[120px] min-w-[100px]"
              )}
            >
              {isNew ? (
                <div className="flex">
                  <button
                    type="button"
                    onClick={() => {
                      saveDocument(Number(id));
                    }}
                    className="w-6 h-6 flex justify-center items-center bg-vorpgreen rounded-md"
                  >
                    <TableSaveIcon width={16} height={16} />
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      cancelRow(Number(cell?.row?.original?.id));
                      // setIsCreated(false)
                    }}
                    className={classNames(
                      styles.customCloseIcon,
                      "w-8 h-8 ml-2 mr-2 flex justify-center items-center group rounded-md  bg-[#71717a] hover:bg-[#71717a] transition-all duration-300"
                    )}
                  >
                    <CancelIcon width={16} height={16} />
                  </button>
                </div>
              ) : (
                <div className="flex ">
                  <button
                    type="button"
                    onClick={() => {
                      if (setIsEdit) {
                        setIsEdit(true);
                      }
                      editDocument(id);
                    }}
                    className={classNames(
                      disabledClass,
                      " w-6 h-6 mr-2 flex justify-center items-center group rounded-md bg-vorpblue hover:bg-annhoverbg transition-all duration-300"
                    )}
                  >
                    <EditIcon width={16} height={16} />
                  </button>
                  <div
                    // type="button"
                    // onClick={() => 1}
                    className="w-6 h-6 mr-2 flex justify-center items-center group rounded-md bg-[#8950FC] hover:bg-[#EAE0FF] transition-all duration-300"
                  >
                    {!DocFiles.includes(extension ? extension : "") ? (
                      <button
                        //href={window.location.href + '/views/' + urls}
                        type="button"
                        onClick={() => onDownloadClick(id, name)}
                        className={classNames(
                          disabledClass,
                          "w-5 h-5 flex justify-center items-center group rounded-md bg-black2  hover:bg-[#FFEAEF] transition-all duration-300 mr-2 last:mr-0"
                        )}
                      >
                        <ViewIcon
                          width={16}
                          height={16}
                          className="group-hover:fill-black2"
                        />
                      </button>
                    ) : (
                      // <a target="_blank" onClick={handleImg} href='#'>
                      <button
                        className="flex items-center justify-center"
                        onClick={() =>
                          onViewDocument(
                            urls,
                            cell.row.original.type,
                            getUser.token
                          )
                        }
                        // target="_blank" href={url}
                      >
                        <ViewIcon
                          width={16}
                          height={16}
                          className={classNames(
                            disabledClass,
                            "group-hover:fill-black2"
                          )}
                        />
                      </button>
                    )}
                  </div>
                  <button
                    type="button"
                    onClick={() => onDownloadClick(id, name)}
                    className={classNames(
                      disabledClass,
                      "w-6 h-6 flex justify-center items-center group rounded-md bg-black2  hover:bg-[#FFEAEF] transition-all duration-300 mr-2 last:mr-0"
                    )}
                  >
                    <DownloadIcon width={16} height={16} />
                  </button>
                  <button
                    type="button"
                    onClick={() => deleteRow(id)}
                    className={classNames(
                      disabledClass,
                      "w-6 h-6 flex justify-center items-center group rounded-md bg-redPink hover:bg-[#FFEAEF] transition-all duration-300 mr-2 last:mr-0"
                    )}
                  >
                    <TrashIcon width={16} height={16} />
                  </button>
                </div>
              )}
            </div>
          );
        },
      }),
    ],
    [columnHelper, data]
  );

  const [columnOrder, setColumnOrder] = useState<string[]>(
    columns.map((column: ColumnDef<any, any>) => column.id as string)
  );

  document.querySelectorAll("th,td").forEach((x) => {
    if (x.id.includes("none")) {
      x.classList.add("hidden");
    }

    x.classList.remove("absolute");
    x.classList.add("left-0");
    x.classList.add("relative");
    let div = document.getElementById(x.id);
    if (div) {
      div.style.setProperty("left", "");
    }
    if (x.id.includes("action")) {
      x.classList.add("absolute");
      x.classList.remove("relative");
      x.classList.add("right-0");
      x.classList.remove("left-0");
    }
  });

  const visibleColumns: { [key: string]: boolean } = {};

  hiddenFields?.map((x) => {
    visibleColumns[x] = true;
  });

  if (hiddenFields.length > 0) {
    columns.map((x) => {
      if (x.id == "actions" || x.id == "lasted" || x.id == "selection") {
        visibleColumns[x.id ? x.id : ""] = true;
      } else if (x.id == "uniqeID") {
        visibleColumns[x.id ? x.id : ""] = false;
      } else if (x.id?.includes("none")) {
        visibleColumns[x.id ? x.id : ""] = true;
      } else {
        var isHidden = hiddenFields.find((h) => h == x.id);
        if (isHidden) {
          visibleColumns[x.id ? x.id : ""] = true;
        } else {
          visibleColumns[x.id ? x.id : ""] = false;
        }
      }
    });
  }

  const saveTemplateData = (template: any) => {
    let views = columns
      .map((x) => {
        if (x.id) {
          if (template[x.id] == undefined) {
            return x.id;
          }
        }
      })
      .filter((x) => x != undefined);
    let viewsData = views.map((x) => (x != undefined ? x : ""));
    let newTemplate: TemplateData = {
      id: 0,
      name: "View" + templateList.length + 1,
      viewColumns: viewsData,
    };
    setHiddenFields(viewsData);
    // setCurrentView(lastTemplate?.id)
  };

  const assignedUsersDoc = (ids: number[]) => {
    assignedUsers({
      id: selectedUser,
      user_ids: ids,
    }).then((res: any) => {
      if (res.data.status) {
        toast(`${res.data.message}`, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          type: "success",
          theme: "light",
        });
        setIsAssignModalOpen(false);
        setRowSelection({});
      }
    });
  };

  const assignedUsersMultipleDoc = (userIds: number[]) => {
    let ids: number[] = [];
    const selectedRows = Object.keys(rowSelection);
    setData((prev: baseObj[]) =>
      prev.filter((d, index) => {
        if (selectedRows.includes(String(index))) {
          ids.push(parseInt(d.id));
        }
        return prev;
      })
    );

    assignedUsersMultiple({
      user_ids: userIds,
      document_ids: ids,
    }).then((res: any) => {
      if (res.data.status) {
        toast("Assign Successfully", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          type: "success",
          theme: "light",
        });
      }
      setIsMultiple(false);
      setIsAssignModalOpen(false);
      setRowSelection({});
    });
  };

  const deleteMultipleDoc = () => {
    let ids: number[] = [];
    const selectedRows = Object.keys(rowSelection);
    setData((prev: baseObj[]) =>
      prev.filter((d, index) => {
        if (selectedRows.includes(String(index))) {
          ids.push(parseInt(d.id));
        }
        return prev;
      })
    );
    deleteDocs({ ids: ids }).then((res: any) => {
      if (res.data.status) {
        toast(`${res.data.message}`, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          type: "success",
          theme: "light",
        });
        setOpenDeleteopup(false);
        setRowSelection({});
      }
    });
  };

  useEffect(() => {
    if (hiddenFields.length > 0) {
      setColumnOrder(["selection", ...hiddenFields]);
    }
  }, [hiddenFields]);

  const createTagData = (label: string) => {
    var data = {
      name: label,
      flag: "doc",
    };
    return createTag(data);
  };

  
  return (
    <div
      className={classNames(
        styles.EmployeeDocumentsTable,
        "EmployeeDocumentsTable"
      )}
    >
      <>
        <DeletePopup
          show={openDeletePopup}
          setShow={setOpenDeleteopup}
          confirmDeleteData={confirmDeleteData}
        />
        <Modal
          open={isAssignModalOpen}
          style={{
            background: "rgb(0 0 0 / 0.3)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            transition: "all 5s",
          }}
          onClose={(event, reason) => {
            if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
              // Set 'open' to false, however you would do that with your particular code.
              setIsAssignModalOpen(false);
            }
          }}
          aria-labelledby="password-add-modal"
          aria-describedby="password-add-modal"
          disableEnforceFocus={true}
          // disableBackdropClick={true}
          closeAfterTransition
          // BackdropComponent={Backdrop}
          // BackdropProps={{
          //   timeout: 500,
          // }}
        >
          <>
            <EmployeeAssignment
              isMultiple={isMultiple}
              assignedUsersMultipleDoc={assignedUsersMultipleDoc}
              setSelectedMemberData={setSelectedMember}
              assignedUsers={assignedUsersDoc}
              // employeeList={userList}
              employeeList={assignUserListData}
              departmentList={departmentList}
              modalType={modalType}
              setIsOpen={setIsAssignModalOpen}
              isOpen={isAssignModalOpen}
              existingMember={existingMember}
              setAssignDepartmentId={setAssignDepartmentId}
              assignDepartmentId={assignDepartmentId}
              setAssignKeword={setAssignKeword}
            />
          </>
        </Modal>
        {/* <div className="relative inline-block max-w-full"> */}
        <div className="relative inline-block max-w-full">
          <LayoutAdjustableTable
            label="Document List"
            setCurrentView={setCurrentView}
            saveTemplateData={saveTemplateData}
            hideColumn={visibleColumns}
            columns={columns}
            data={data}
            sorting={sorting}
            setSortingState={setSorting}
            rowSelection={rowSelection}
            setRowSelection={setRowSelection}
            columnOrder={columnOrder}
            setColumnOrder={setColumnOrder}
            unDraggableFields={["members", "selection"]}
            setItemCount={setItemCount}
            pagination={pagination}
            setPagination={setPagination}
            globalFilter={globalSearch}
            setGlobalFilter={setGlobalSearch}
            columnFilters={columnFilters}
            setColumnFilters={setColumnFilters}
            isLayoutControlOpen={isLayoutControlOpen}
            setIsLayoutControlOpen={setIsLayoutControlOpen}
            theaderColor="text-[#858795]"
            isTableView={isTableView}
            localModuleId={localModuleId}
            noPaddingCells={["owner", "tags"]}
            setCols={setCols}
            getsharedStateComponent={(rows) => (
              <DocumentsGrid className={"3xl:grid-cols-5"} rows={rows} />
            )}
          />
        </div>
        {selectionCount > 0 && (
          <div className="flex items-center pt-7 flex-wrap justify-center md:flex-nowrap md:justify-start">
            <p className="text-14 leading-5 text-black2 md:mr-5 w-full mb-2 md:mb-0 md:w-auto">
              {selectionCount} selected.
            </p>
            <div className="flex items-center mr-5 mb-2 5xs:mb-0">
              <button
                className="flex items-center text-12 leading-[18px] font-semibold text-vorpblue"
                onClick={() => {
                  setModalType("assign");
                  setIsAssignModalOpen(!isAssignModalOpen);
                  setIsMultiple(true);
                }}
              >
                <div className="w-6 h-6 flex justify-center items-center bg-vorpblue rounded-md mr-[10px]">
                  <AssignIcon width={16} height={16} />
                </div>
                Assign
              </button>
            </div>
            {/* <div className="flex items-center mr-5 mb-2 5xs:mb-0">
              <button className="flex items-center text-12 leading-[18px] font-semibold text-black2" onClick={() => downloadDocumentByIDs()}>
                <div className="w-6 h-6 flex justify-center items-center bg-black2 rounded-md mr-[10px]">
                  <DownloadIcon width={9} height={10} />
                </div>
                Download
              </button>
            </div> */}
            <div className="flex items-center mb-2 5xs:mb-0">
              <button
                className="flex items-center text-12 leading-[18px] font-semibold text-redPink"
                onClick={() => {
                  setOpenDeleteopup(true);
                }}
              >
                <div className="w-6 h-6 flex justify-center items-center bg-redPink hover:bg-redPinkLight rounded-md mr-[10px] group">
                  <TrashIcon width={12} height={12} hasHoverState={true} />
                </div>
                Delete
              </button>
            </div>
          </div>
        )}
      </>
      <div className={`${selectionCount > 0 ? "pt-7" : "pt-10"}`}>
        {total ? (
          <TablePaginator
            total={itemCount}
            pagination={pagination}
            setPagination={setPagination}
            itemCount={total}
            setCurrentPage={setCurrentPage}
            currentPageDefault={currentPage}
            changePage={changePage}
          />
        ) : (
          ""
        )}
      </div>
      <Modal
        open={isShow}
        style={{
          background: "rgb(0 0 0 / 0.5)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        onClose={(event, reason) => {
          if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
            // Set 'open' to false, however you would do that with your particular code.
            setIsShow(false);
          }
        }}
        aria-labelledby="Password Add Modal"
        aria-describedby="Add New Password Form Pop up"
      >
        <div className=" max-w-[558px] w-full m-auto">
          <AssignPopup
            createTagData={createTagData}
            editRowData={editedTagData}
            showTagPopup={showTagPopup}
            setIsOpen={setIsShow}
            modalType="assign"
            options={tagsData}
            cell={cellData}
            setTags={() => 1}
            data={data}
            setData={setData}
          />
        </div>
      </Modal>
      <ToastContainer className={"text-12 font-poppins font-normal"} />
    </div>
  );
};

export default EmployeeDocumentsTable;
