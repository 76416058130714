import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
// import { StringDropdownList } from "../common/DropdownList";
import TimePicker from "rc-time-picker";
import { CloseIcon, QuestionIcon } from "../common/Icons";
import styles from "./WorkingShiftTable.module.scss";
import moment from "moment";
import { baseObj } from "../../utils/constants";
import { CommonTagDropdown } from "../common/CommonTagDropdown";
import { Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";

const format = "HH:mm:ss ";
// const workingAMPM = ["AM", "PM"];
const now = moment().hour(0).minute(0);

type TWorkingShiftListProps = {
  multiFlexibleDaily?: any;
  setMultiFlexibleDaily?: any;
  setMultiFlexibleDailyID?: any;
  regigonList?: any;
  work: any;
  locationList?: any;
  handleDelete: (id: any) => void;
  setData: Dispatch<SetStateAction<any[]>>;
  isSubmit: boolean;
};

const useStyles = makeStyles(() => ({
  tooltip: {
    backgroundColor: "transparent",
    margin: 0,
    marginRight: 8,
    padding: 0,
    zIndex: 9999999,
  },
}));

const FlexibleWorkingShiftList = ({
  multiFlexibleDaily,
  setMultiFlexibleDaily,
  setMultiFlexibleDailyID,
  regigonList,
  work,
  handleDelete,
  setData,
  locationList,
  isSubmit,
}: TWorkingShiftListProps) => {
  const classes = useStyles();

  const [location, setLocation] = useState<string>("");
  const [firstAMPM, setFirstAMPM] = useState<string>("AM");
  const [secondAMPM, setSecondAMPM] = useState<string>("PM");
  // const [firstClock, setFirstClock] = useState<any>(moment().hour(0).minute(0));
  // const [secondClock, setSecondClock] = useState<any>(moment().hour(0).minute(0));

  const [firstHours, setFirstHours] = useState<string>("");
  const [firstMinutes, setFirstMinutes] = useState<string>("");
  const [firstSecond, setFirstSecond] = useState<string>("");
  const [secondHours, setSecondHours] = useState<string>("");
  const [secondMinutes, setSecondMinutes] = useState<string>("");
  const [secondSecond, setSecondSecond] = useState<string>("");
  // console.log("f-work", work);
  //   console.log("all", secondHours, secondMinutes);

  // const haldleLocationChange = (value: any) => {
  //   setLocation(value);
  //   setData((prev: baseObj) => {
  //     return prev.map((data: any) => {
  //       if (data.key === work.key) {
  //         return { ...data, location: value };
  //       }
  //       return data;
  //     });
  //   });
  // };

  // const handleFirstAMPMChange = (value: any) => {
  //   setFirstAMPM(value);
  //   setData((prev: baseObj) => {
  //     return prev.map((data: any) => {
  //       if (data.key === work.key) {
  //         return { ...data, firstAMPM: value };
  //       }
  //       return data;
  //     });
  //   });
  // };

  // const handleSecondAMPMChange = (value: any) => {
  //   setSecondAMPM(value);
  //   setData((prev: baseObj) => {
  //     return prev.map((data: any) => {
  //       if (data.key === work.key) {
  //         return { ...data, secondAMPM: value };
  //       }
  //       return data;
  //     });
  //   });
  // };

  function onChangeDateStart(value: any) {
    console.log("start", value && value.format(format));
    // setFirstClock(value && value.format(format));
    setData((prev: baseObj) => {
      return prev.map((data: any) => {
        if (data.key === work.key) {
          return { ...data, firstClock: value && value.format(format) };
        }
        return data;
      });
    });
  }

  function onChangeDateEnd(value: any) {
    // setSecondClock(value && value.format(format));
    setData((prev: baseObj) => {
      return prev.map((data: any) => {
        if (data.key === work.key) {
          return { ...data, secondClock: value && value.format(format) };
        }
        return data;
      });
    });
  }

  useEffect(() => {
    setFirstAMPM(work.firstAMPM);
    setSecondAMPM(work.secondAMPM);
    setFirstHours(String(work.firstClock).split(":")[0]);
    setFirstMinutes(String(work.firstClock).split(":")[1]);
    setFirstSecond(String(work.firstClock).split(":")[2]);
    setSecondHours(String(work.secondClock).split(":")[0]);
    setSecondMinutes(String(work.secondClock).split(":")[1]);
    setSecondSecond(String(work.secondClock).split(":")[2]);

  }, [work]);

  const handleClearClickFirst = () => {
    setData((prev: baseObj) => {
      return prev.map((data: any) => {
        if (data.key === work.key) {
          return { ...data, firstClock: "00:00:00" };
        }
        return data;
      });
    });

  };
  const handleClearClickSecond = () => {
    setData((prev: baseObj) => {
      return prev.map((data: any) => {
        if (data.key === work.key) {
          return { ...data, secondClock: "00:00:00" };
        }
        return data;
      });
    });

  };



  return (
    <div className="flex mb-4">
      <div className="flex flex-col">
        <label className="text-black2 text-15 flex font-medium font-primary mt-0 mb-2 text-start ">
          Working Times
          <span className=" text-red-500 inline">*</span>
          <div className="text-center ml-2">
            <Tooltip
              PopperProps={{ disablePortal: true }}
              style={{ zIndex: 999999999999 }}
              arrow
              className="customTooltip"
              classes={{ tooltip: classes.tooltip }}
              enterTouchDelay={1000}
              leaveTouchDelay={5000}
              placement="left"
              title={
                <>
                  <div className="text-white text-[10px] hover:cursor-pointer leading-[15px] z-[2] rounded p-1 w-full bg-tooltipbg">
                    <span className="flex w-full justify-center h-full items-center">
                      Time range for working
                    </span>
                  </div>
                </>
              }
            >
              <div className="flex flex-wrap ">
                <QuestionIcon width={16} height={17} />
              </div>
            </Tooltip>
          </div>
        </label>
        <div className="flex justify-between items-center working_shift_time_clear">
          <TimePicker
            showSecond={false}
            value={moment()
              .hours(Number(firstHours))
              .minute(Number(firstMinutes))
              .second(Number(firstSecond))
            }
            defaultValue={now}
            className={styles.customTimePickerInput}
            onChange={onChangeDateStart}
            format={format}
            use12Hours={false}
            popupClassName="z-[9999999]"
            allowEmpty={false}
            addon={() => <button className="p-1 " onClick={handleClearClickFirst}>Clear</button>}
            
          />
         
          <span className="px-5">-</span>
          <TimePicker
            showSecond={false}
            value={moment()
              .hours(Number(secondHours))
              .minute(Number(secondMinutes))
              .second(Number(secondSecond))
            }
            defaultValue={now}
            className={styles.customTimePickerInput}
            onChange={onChangeDateEnd}
            format={format}
            use12Hours={false}
            popupClassName="z-[9999999]"
            allowEmpty={false}
            addon={() => <button className="p-1 " onClick={handleClearClickSecond}>Clear</button>}
          />
        </div>
      </div>
      <div className="w-full pl-5">
        <label className="text-black2 text-15 font-medium font-primary block mb-2 text-start">
          Location
          <span className=" text-red-500">*</span>
        </label>
        <div className="mb-0">
          <CommonTagDropdown
            placeholder="Select"
            isCreate={false}
            showAll={true}
            state={multiFlexibleDaily ? multiFlexibleDaily : []}
            setState={setMultiFlexibleDaily}
            setSelected={setMultiFlexibleDailyID}
            options={locationList?.map((member: any) => {
              return {
                label: member.name,
                value: member.id,
              };
            })}
            className="border-goalinputborder 6xs:text-goalinputborder text-14"
          />
          <p
          className={classNames(
            isSubmit && multiFlexibleDaily.length == 0 ? "" : "hidden",
            "mb-2 font-poppins text-12 text-redPink"
          )}
        >
          Locations is required
        </p>
        </div>

        
      </div>
    </div>
  );
};

export default FlexibleWorkingShiftList;

