import { Dispatch, FC, SetStateAction, useCallback, useEffect, useState } from "react";
import { Cell } from "@tanstack/react-table";
import { IRole } from "../../backendUsers/views/UserList";
import CustomDropdownEdit from "../Tables/CustomDropdownEdit";


interface IRolesDropdownList {
  cell: Cell<unknown, unknown>;
  initialValues: number[];
  // updateData: (rowData: baseObj) => void;
  roles: IRole[];
  setRoles: Dispatch<SetStateAction<IRole[]>>;
  isOverflow?: boolean;
  isShowAddBtn?: boolean;
  isOpen?: boolean;
  isShow?: boolean;
  setOpen?: Dispatch<SetStateAction<boolean>>;
  setShow?: Dispatch<SetStateAction<boolean>>;
  setData?: any;
  setAnchorEl?: any;
  anchorE1?: any;
  setPlacement?: any;
  placement?: any;
  setSelectedRole?: any;
  isEditable?: boolean;
  isEditing?: boolean;
}



const RolesDropdownList: FC<IRolesDropdownList> = ({
  initialValues,
  // updateData,
  roles,
  setRoles,
  isOverflow,
  isShowAddBtn,
  isShow,
  isOpen,
  // setShow,
  // setOpen,
  setData,
  cell,
  // setAnchorEl,
  // anchorE1,
  setPlacement,
  placement,
  setSelectedRole,
  isEditable,
  isEditing
}) => {
  const [value, setValue] = useState<number[]>([]);

  const addNewItem = useCallback(
    (val: string) => {
      const label = val.trim();
      if (label.length > 0) {
        setRoles((roles) => [
          ...roles,
          { value: roles[roles.length - 1].value + 1, label },
        ]);
      }
    }, [setRoles]);

  useEffect(() => {
    if (initialValues) {
      setValue(initialValues)
    }
  }, [value, initialValues, roles]);

  return (
    <div className="w-full">
      {
        roles ? (
          <CustomDropdownEdit
            value={value}
            setValue={setValue}
            options={roles ? roles : []}
            title="Role"
            addNewItem={addNewItem}
            isOverflow={isOverflow}
            isShowAddBtn={isShowAddBtn}
            isShow={isShow}
            // setShow={setShow}
            isopen={isOpen}
            // setOpen={setOpen}
            placement={placement}
            setPlacement={setPlacement}
            setData={setData}
            cell={cell}
            setSelectedRole={setSelectedRole}
            isEditable={isEditable}
            isEditing={isEditing}
          />
        ) : null
      }
    </div>
  );
};

export default RolesDropdownList;
