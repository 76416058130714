import {
  ColumnDef,
  ColumnFiltersState,
  ColumnPinningState,
  createColumnHelper,
  PaginationState,
  RowSelectionState,
  SortingState,
} from "@tanstack/react-table";
import classNames from "classnames";
import React, {
  createRef,
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";
import styles from "./PermissionTable.module.scss";
import MinimalTable from "../../components/Tables/MinimalTable";
import LayoutAdjustableTable from "../../components/Tables/LayoutAdjustableTable";
import { changePermission, Permission, useChangePermissionMutation } from "../../app/services/permissions";
import search from "./img/search-icon.svg";
import tableTemplate from "./img/table-template.svg";
import tableTemplateActive from "./img/table-template-active.svg";
import TableSearchbox from "../../components/common/TableSearchbox";
import {
  NumberDropdownList,
  StringDropdownList,
} from "../../components/common/DropdownList";
import TableTemplatePopup from "../../components/common/TableTemplatePopup";
import TablePaginator from "../Tables/TablePaginator";
import { AddIcon, CustomizeLayoutIcon } from "../common/Icons";
import { templateList } from "../../active-time-report/views/ActiveTimeReport";
import { StringDropdownListWithDeleteBtn } from "../common/StringDropdownListWithDeleteBtn";
import { TemplateData } from "../../app/services/employee";
import { templateData } from "../../app/services/template";
import { baseObj } from "../../utils/constants";
import axios from "axios";
import { endpointUrl } from "../../app/services/api";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/store";
// import { permissionSliceData } from "../../reducers/permission";
import { idText } from "typescript";
import { getAuthUser } from "../../app/services/dashboard";
interface IPermission {
  isLayoutControlOpen: boolean;
  setIsLayoutControlOpen: Dispatch<SetStateAction<boolean>>;
  pagination: PaginationState;
  setPagination: Dispatch<SetStateAction<PaginationState>>;
  globalSearch: string;
  setGlobalSearch: Dispatch<SetStateAction<string>>;
  columnFilters: ColumnFiltersState;
  setColumnFilters: Dispatch<SetStateAction<ColumnFiltersState>>;
  data: baseObj[];
  setData: Dispatch<SetStateAction<baseObj[]>>;
  hiddenFields: string[];
  templateList: templateData[];
  setHiddenFields: any;
  setCurrentView: any;
  columnsData: any;
  status: boolean;
  setStatus: React.Dispatch<React.SetStateAction<boolean>>;
  getPermissions: any;
  localModuleId: number;
  total: number;
  changePage: any;
  setCurrentPage: any;
  currentPage: number;
  columnPinning?: any;
  setColumnPinning?: any;
  setCols: Dispatch<any>;
  setIsTemplate: Dispatch<React.SetStateAction<boolean>>;
  sorting: SortingState;
  setSorting: React.Dispatch<React.SetStateAction<SortingState>>;
}

const PermissionTable: FC<IPermission> = ({
  isLayoutControlOpen,
  setIsLayoutControlOpen,
  pagination,
  setPagination,
  globalSearch,
  setGlobalSearch,
  columnFilters,
  setColumnFilters,
  data,
  setData,
  hiddenFields,
  templateList,
  setHiddenFields,
  setCurrentView,
  columnsData,
  getPermissions,
  localModuleId,
  total,
  changePage,
  setCurrentPage,
  currentPage,
  setCols,
  setIsTemplate,
  sorting,
  setSorting
}) => {
  const [isConfirmCancelOpen, setIsConfirmCancelOpen] =
    useState<boolean>(false);
  
  const [status, setStatus] = useState<boolean>();
  const columnHelper = useMemo(() => createColumnHelper<any>(), []);
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  //const [currentPage, setCurrentPage] = useState<number>(1);
  // const [permissionsTempList, setPermissionsTempList] = useState<Permission[]>([]);
  // const [fileType, setFileType] = useState<string>("");
  // const [limit, setLimit] = useState<number>(10);
  // const [searchInput, setSearchInput] = useState<string>("");
  // const [permissionFilter, setPermissionFilter] = useState("");
  // const [stateChange, setStateChange] = useState(false);
  const [permissionFilterList, setPermissionListFilter] = useState<
    Permission[] | []
  >([]);
  // const [statusFilter, setStatusFilter] = useState("");
  const [statusId, setStatusID] = useState<number>(0);
  const [roleID, setRoleD] = useState<string>("");
  // const count = useSelector(permissionSliceData);
  const [changePermission] = useChangePermissionMutation();
  const [columnPinning, setColumnPinning] = useState<ColumnPinningState>({
    left: [],
    right: ["lasted", "action"],
  });
  const handleChangePermission = (
    event: any,
    id: number,
    roleId: string,
    data: any,
    mainData: any
  ) => {

    var permissionID = mainData.cell.row.original.id;
    setStatusID(id);
    setRoleD(roleId);
    setStatus(event.target.checked);
    var statusData = {
      id: id,
      roleID: data?.id,
      status: event.target.checked == true ? 1 : 0,
    };
    changePermission(statusData).then(() => {
    });
    // axios
    //   .post(`${endpointUrl}permissions/${permissionID}/changedStatus`, {
    //     roleID: data?.id,
    //     status: event.target.checked == true ? 1 : 0,
    //   })
    //   .then((response) => {
    //     getPermissions();
    //     console.log("response ", response);
    //   });
  };
  const [itemCount, setItemCount] = useState<number>(total);

  const renderCustomSwitch = (
    status: boolean,
    id: number,
    roleId: string,
    data: any
  ) => {
    var roleID = data.column.columnDef.header;
    var isDisabled =
      data.row.original.id == id
        ? data.row.original.data.find((x: any) => x.name == roleID)
        : {};
    return (
      <div
        className={classNames("text-center px-8 h-full items-center", styles.customSwitchContainer)}
      >
        <label className="cursor-pointer relative inline-block w-12 h-[30px]">
          <input
            onChange={(e) => {
              handleChangePermission(e, id, roleId, isDisabled, data);
            }}
            type="checkbox"
            checked={status}
            disabled={isDisabled?.isDisabled == true ? true : false}
            className={classNames("opacity-0 w-full h-full")}
          />
          <span
            className={classNames(
              isDisabled?.isDisabled == true ? "bg-disabledColor" : "",
              styles.customSwitch,
              "bg-[#3699FF] absolute w-12 h-[30px] rounded-full top-0 left-0 bottom-0 right-0"
            )}
          ></span>
        </label>
      </div>
    );
  };

  const columns = useMemo(() => columnsData, [columnHelper, data]);  
  const [columnOrder, setColumnOrder] = useState<string[]>(
    columns.map((column: ColumnDef<any, unknown>) => column?.id as string)
  );
  useEffect(()=>{
    setColumnOrder( columns.map((column: ColumnDef<any, unknown>) => column?.id as string))
  },[data])
  const changedStatus = (data: any, roleData: any) => {
    if (roleData) {
      var id = data.row.original.id;
      var role = roleData.id;
      var status = roleData.isDisabled == true ? 1 : 0;
      var statusData = {
        id: id,
        role: role,
        status: status,
      };
    }
  };

  // const saveTemplateData = (template: any) => {

  //   var views = columns
  //     .map((x: any) => {
  //       if (x.id) {
  //         if(x.id.includes("none")){
  //           return idText;
  //         }
  //         if (template[x.id] == undefined) {
  //           return x.id;
  //         }
  //       }
  //     })
  //     .filter((x: any) => x != undefined);
  //   var viewsData = views.map((x: any) => (x != undefined ? x : ""));
  //   var newTemplate: TemplateData = {
  //     id: 0,
  //     name: "View" + templateList.length + 1,
  //     viewColumns: viewsData,
  //   };
  //   setHiddenFields(viewsData);
  //   setCurrentView("");
  // };
  const visibleColumns: { [key: string]: boolean } = {};
  hiddenFields?.map((x) => {    
    visibleColumns[x] = true;
  });
  if (true) {
    visibleColumns["Super Admin"] = false;
  }
  // else {
  //   visibleColumns[columnsData[0]?.accessorKey] = false
  // }


  if (hiddenFields.length > 0) {
    columns.map((x: any) => {
      if (x.id == "actions" || x.id == "selection" || x.id == "permission") {
        visibleColumns[x.id ? x.id : ""] = true;
      } else if (x.id == "uniqeID") {
        visibleColumns[x.id ? x.id : ""] = false;
      } else if (x.id?.includes("none")) {
        
        visibleColumns[x.id ? x.id : ""] = true;
      }
      else {
        var isHidden = hiddenFields.find((h) => h == x.id);
        if (isHidden) {
          visibleColumns[x.id ? x.id : ""] = true;
        } else {
          visibleColumns[x.id ? x.id : ""] = false;
        }
      }
    });
  }

  useEffect(() => {
    if (hiddenFields.length > 0) {
      setColumnOrder(["permission", ...hiddenFields]);
    }
  }, [hiddenFields])

  // console.log("columnsData", visibleColumns)

  return (
    <>
      <div className={classNames("", styles.PermissionTable)}>
        <div className="text-left">
          <div className="relative inline-block max-w-full">
            <LayoutAdjustableTable
              // saveTemplateData={saveTemplateData}
              columns={columns}
              data={data}
              sorting={sorting}
              setSortingState={setSorting}
              rowSelection={rowSelection}
              setRowSelection={setRowSelection}
              columnOrder={columnOrder}
              setColumnOrder={setColumnOrder}
              unDraggableFields={["permission","SuperAdmin"]}
              theaderColor="text-graydark"
              isLayoutControlOpen={isLayoutControlOpen}
              setIsLayoutControlOpen={setIsLayoutControlOpen}
              //label="Permissions List"
              setItemCount={setItemCount}
              pagination={pagination}
              setPagination={setPagination}
              globalFilter={globalSearch}
              setGlobalFilter={setGlobalSearch}
              columnFilters={columnFilters}
              setColumnFilters={setColumnFilters}
              noPaddingCells={["role"]}
              localModuleId={localModuleId}
              hideColumn={visibleColumns}
    
              setCols={setCols}
              setIsTemplate={setIsTemplate}

            />
          </div>
        </div>
        <div className=" pt-6">
          <TablePaginator
            total={itemCount}
            pagination={pagination}
            setPagination={setPagination}
            itemCount={total}
            changePage={changePage}
            setCurrentPage={setCurrentPage}
            currentPageDefault={currentPage}
          />
        </div>
      </div>
    </>
  );
};
export default PermissionTable;
