import authHeader from "./auth-header";
import { api, endpointUrl } from "./api";
import { redirectToLogin } from "./annoucement-sheet";
export type TNotification = {
  id: number;
  title: string;
  description: string;
  type: string;
  user_id: 5;
  read_at: string;
  causer_id: 1;
  causer: string;
  causer_image: string;
  human_time: string;
  created_at: string;
  updated_at: string;
};

export type TResponseNoti = {
  unapprove_leave_count: number;
  unapprove_ot_count: number;
  unread_count: number;
  notifications: TNotification[];
  unread_notifications: TNotification[];
  unread_data_count: any;
};
export type NotificationWithPagination = {
  data: {
    unread_count: number;
    notifications: {
      current_page: number;
      from: number;
      to: number;
      total: number;
      per_page: number;
      data: TNotification[];
      first_page_url: string;
      last_page_url: string;
      next_page_url: string;
    }
  }
}
export type NotificationPaginationData = {
  page: number;
  type: number | string | undefined,
  status: number | string | undefined,
  limit: number | string | undefined,
  search: string | number,
}
export const notificationApi = api.injectEndpoints({
  endpoints: (build) => ({
    getLastedNoti: build.query<TResponseNoti, string>({      
      query: (search) => ({
        url: `${endpointUrl}latest-notifications?` + search,
        headers: authHeader(),
        method: "GET",
      }),
      keepUnusedDataFor:0,
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
         redirectToLogin();
        }
      },
      transformResponse: (res: any) => {
        return res.data;
      },
      providesTags: [{ type: "Notification", id: "LIST" }],
    }),
    viewNotification: build.mutation<TResponseNoti, number>({
      query: (id) => ({
        url: `notifications/${id}/read`,
        headers: authHeader(),
        method: 'PUT',
        body: {
          notification_id: id
        }
      }),
      invalidatesTags: [{ type: 'Notification', id: "LIST" }],
    }),
    viewNotificationByType: build.mutation<TResponseNoti, string>({
      query: (notiType) => ({
        url: `read-notifications-by-type`,
        headers: authHeader(),
        method: 'PUT',
        body: {
          type: notiType
        }
      }),
      invalidatesTags: [{ type: 'Notification', id: "LIST" }],
    }),
    notificationMaskAllAsRead: build.mutation<TResponseNoti, string>({
      query: (notiType) => ({
        url: `mark-as-read`,
        headers: authHeader(),
        method: 'PUT',
        body: {
          type: notiType
        }
      }),
      invalidatesTags: [{ type: 'Notification', id: "LIST" }],
    }),
    getAllNotifications: build.query<NotificationWithPagination, NotificationPaginationData>({
      query: ({ search, type, status, limit, page }) => ({
        url: `notifications?page=${page}&limit=${limit}&type=${type}&status=${status}&search=${search}`,
        headers: authHeader(),
        method: 'GET',
      }),
      providesTags: [{ type: "NotificationList", id: "LIST" }],
    }),
  }),
});

export const {
  useGetLastedNotiQuery,
  useViewNotificationMutation,
  useViewNotificationByTypeMutation,
  useNotificationMaskAllAsReadMutation,
  useGetAllNotificationsQuery
} = notificationApi;

export const {
  endpoints: { getLastedNoti, viewNotification, viewNotificationByType, notificationMaskAllAsRead },
} = notificationApi;

