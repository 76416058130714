import {
  EmployeeSaveDeviceRequest,
  MonitoringFilterResponse,
  MonitoringScreenShotRequest,
  MonitoringScreenShotResponse,
  RequestMonitoringType,
  ResponseMonitoringType,
  ScreenShotByIDRequest,
  UpdateMonitoringRequest,
} from "../../modals/moniroting/Monitoring";
import { redirectToLogin } from "./annoucement-sheet";
import { api, endpointUrl } from "./api";
import authHeader from "./auth-header";

export const monitoringApi = api.injectEndpoints({
  endpoints: (build) => ({
    getMonitoringList: build.query<
      ResponseMonitoringType,
      Partial<RequestMonitoringType>
    >({
      query: ({
        search,
        isToday,
        start,
        end,
        employeeID,
        departmentID,
        page,
        limit,
      }) => ({
        // {   sort }
        url: `${endpointUrl}active-time-report?search=${search}&is_today=${isToday}&start=${start}&end=${end}&employee_ids=${employeeID}&department_ids=${departmentID}&page=${page}&limit=${limit}`,
        // ?&page=${page}&sort=${sort}`
        headers: authHeader(),
      }),
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
      providesTags: () => [{ type: "Monitoring" as const, id: "LIST" }],
    }),

    getMonitorinFilterList: build.query<MonitoringFilterResponse, void>({
      query: () => ({
        url: `${endpointUrl}active-time-report-filter-data`,
        headers: authHeader(),
      }),
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
      // providesTags: (result: any = {}) => [
      //   { type: "Monitoring" as const, id: "LIST" }
      // ],
    }),

    saveEmployeeDevice: build.mutation<void, EmployeeSaveDeviceRequest>({
      query: (data) => ({
        url: `${endpointUrl}employee-devices`,
        headers: authHeader(),
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: [{ type: "EmployeeDevice", id: "LIST" }],
    }),

    updateMonitoring: build.mutation<void, UpdateMonitoringRequest>({
      query: (data) => ({
        url: `${endpointUrl}active-time-report`,
        headers: authHeader(),
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: [{ type: "Monitoring", id: "LIST" }],
    }),

    getMonitorinScreenShotList: build.query<
      MonitoringScreenShotResponse,
      MonitoringScreenShotRequest
    >({
      query: ({ is_today, page, department_id, employee_id, start, end, limit,search }) => ({
        url: `${endpointUrl}active-time-screenshoots?is_today=${is_today}&page=${page}&department_ids=${department_id}&employee_ids=${employee_id}&start=${start}&end=${end}&limit=${limit}&search=${search}`,
        headers: authHeader(),
      }),
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
      providesTags: (result: any = {}) => [
        { type: "MonitoringScreenShot" as const, id: "LIST" },
      ],
    }),

    getScreenShotByID: build.query<
    MonitoringScreenShotResponse,
      ScreenShotByIDRequest
    >({
      query: ({ id, date }) => ({
        url: `${endpointUrl}active-time-report/${id}/screenshoots?date=${date}`,
        headers: authHeader(),
      }),
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
      providesTags: (result: any = {}) => [
        { type: "Monitoring" as const, id: "LIST" },
      ],
    }),
  }),
});

export const {
  useGetScreenShotByIDQuery,
  useGetMonitoringListQuery,
  useGetMonitorinFilterListQuery,
  useSaveEmployeeDeviceMutation,
  useUpdateMonitoringMutation,
  useGetMonitorinScreenShotListQuery,
} = monitoringApi;

export const {
  endpoints: {
    getScreenShotByID,
    getMonitoringList,
    getMonitorinFilterList,
    saveEmployeeDevice,
    updateMonitoring,
    getMonitorinScreenShotList,
  },
} = monitoringApi;
