import React, { useRef, useEffect, useCallback, useState } from "react";
import classNames from "classnames";
import styles from "./ProjectChart.module.scss";
// import prevIcon from "../EmployeeChart/img/cal-pre.svg";
// import nextIcon from "../EmployeeChart/img/cal-next.svg";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { LeftIcon, RightIcon } from "../common/Icons";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);
export const monthAbbr = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
const ProjectChart = (props: any) => {
  const title = "Project";
  const chartRef = useRef(null);
  return (
    <>
      <div className="3xl:px-[25px] px-2 py-[26px] bg-white h-full">
        <div>
          <div>
            <div className="flex flex-wrap justify-between items-center mb-10">
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <p className="mr-2 text-black2 text-14 2xl:text-18 font-poppins font-semibold text-left">
                    {title}
                  </p>
                  <div className="flex bg-offwhite rounded-md">
                    <button
                      onClick={() => props.changeToPrev()}
                      className={classNames(styles.prevMonth, "px-2 w-1/2 bg-offwhite hover:bg-vorpblue py-2 rounded-md")}
                    >
                      <div><LeftIcon width={12} height={12} /></div>
                    </button>
                    <button
                      onClick={() => props.changeToNext()}
                      className={classNames(styles.nextMonth, "px-2 w-1/2 bg-offwhite hover:bg-vorpblue py-2 rounded-md")}
                    >
                      <div><RightIcon width={12} height={12} /></div>
                    </button>
                  </div>
                </div>                
              </div>
              <div className="flex">
                  <p className={classNames("2xl:text-14 text-12 text-graydark", (props.dataView == "year") ? '' : 'hidden')}>{props.pYear}</p>
                  <p className={classNames("2xl:text-14 text-12 text-graydark", (props.dataView == "month") ? '' : 'hidden')}>{monthAbbr[props.pMonth - 1]}-{props.pYear}</p>
                  <p className={classNames("2xl:text-14 text-12 text-graydark", props.dataView == "week" ? '' : 'hidden')}>({props.projectLabel ? props.projectLabel[0] : ''}-{props.projectLabel ? props.projectLabel[props.projectLabel.length - 1] : ''}) {props.pYear}</p>
                </div>
              <div className="flex">
                <button
                  className={classNames(
                    props.dataView == "year"
                      ? "text-vorpblue bg-offwhite rounded-md"
                      : "",
                    "font-poppins font-semibold 2xl:text-sm text-12 text-[#C4C4C4] 2xl:px-11 px-1"
                  )}
                  onClick={(e) => props.hangleChangeView("year")}
                >
                  Year
                </button>
                <button
                  className={classNames(
                    props.dataView == "month"
                      ? "text-vorpblue bg-offwhite rounded-md"
                      : "",
                    "font-poppins font-semibold 2xl:text-sm text-12 text-[#C4C4C4] 2xl:px-11 px-1"
                  )}
                  onClick={(e) => props.hangleChangeView("month")}
                >
                  Month
                </button>
                <button
                  className={classNames(
                    props.dataView == "week"
                      ? "text-vorpblue bg-offwhite rounded-md"
                      : "",
                    "font-poppins font-semibold 2xl:text-sm text-12 text-[#C4C4C4] 2xl:px-11 px-1"
                  )}
                  onClick={(e) => props.hangleChangeView("week")}
                >
                  Week
                </button>
              </div>
            </div>
            {/* <p className="font-poppins font-semibold md:text-xs text-10 text-graydark mb-5 text-left">
              Projects
            </p> */}
          </div>
          <div className={classNames(styles.LineChart, "")}>
            <Line
             style={{
              width: 750,
              height: 350,
            }}
              ref={chartRef}
              options={{
                maintainAspectRatio:false,
                scales: {
                  
                  y: {
                    min: 0,
                    ticks: {
                      stepSize: 2,
                      color: "#858795",
                      font: {
                        family: "Poppins",
                      },
                    },
                  },
                  x: {            
                    
                    stacked:true,
                    time:{
                      unit:"day",
                      displayFormats: {
                        day: 'dd'
                      }
                    },        
                    ticks: {       
                      padding:0,
                      maxTicksLimit:31,               
                      autoSkipPadding:-0,
                      autoSkip:false,
                      color: "#858795",
                      font: {
                        family: "Poppins",
                        size:9,                        
                      },
                    },
                  },
                },
                responsive: true,
                plugins: {
                  legend: {
                    display: false,
                    position: "bottom" as const,
                    labels: {
                      font: {
                        size: 12,
                        family: "Poppins",
                      },
                    },
                  },
                  title: {
                    display: false,
                  },
                },
              }}
              data={props.projectData}
            />
          </div>
        </div>
        <div
          className={classNames(
            "flex items-center justify-center mt-[18px] rounded-[12px]  bg-white px-[32px]",
            styles.projectLabel
          )}
        >
          {props.projectData.datasets.length &&
            props.projectData.datasets.map((data: any, idx: any) => {
              return (
                <div
                  className={classNames(
                    "flex flex-row items-center",
                    styles.projectLabel__item
                  )}
                  key={`projectLabel-${idx}`}
                >
                  <span
                    className={classNames(
                      "w-[12px] h-[12px] relative",
                      styles.projectLabel__item__square
                    )}
                    style={{ background: data.backgroundColor }}
                  >
                    <span
                      className={classNames(
                        "w-[27px] h-[3px] absolute top-1/2 left-1/2",
                        styles.projectLabel__item__square__children
                      )}
                      style={{ background: data.backgroundColor }}
                    ></span>
                  </span>
                  <div
                    className={classNames(
                      "text-left ml-[16px]",
                      styles.projectLabel__item__name
                    )}
                  >
                    <p className="font-primary text-black2 text-10 5xl:text-12 font-semibold">
                      {data.name}
                    </p>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </>
  );
};
export default ProjectChart;
