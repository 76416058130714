// import { Modal } from "@material-ui/core";
import { CircularProgress, Modal } from "@mui/material"
import React, {
  createRef,
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { CloseIcon } from "../../components/common/Icons";
import { twMerge } from "tailwind-merge";
import BaseRadioButton from "../../components/common/BaseRadioButton";
import Button from "../../components/common/Button";
import { EquipmentObjectKeys, IEquipmentApp } from "../../app/services/equipments";
import { StringDropdownList } from "../../components/common/DropdownList";
import classes from './EquipmentPopup.module.scss'
import { IAppEquipment, IEquipment } from "./Equipments";
import MultiDatePopupFilterCustom from "../../components/EmployeeTable/MultiDatePopupFilter";
import CustomDatePicker from "../../components/common/BaseDatePicker";
import { renderDateFormatYMD } from "../../components/DashboardCalendarView/DashboardAddEventPopup";
import classNames from "classnames";
import MultiDatePopupFilter from "../../components/EmployeeTable/MultiDatePopupFilter";
interface ICreateData {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  departments: EquipmentObjectKeys[];
  employees: EquipmentObjectKeys[];
  editedData: any;
  applyEquipmentData: (data: IEquipmentApp) => void;
  curLocation: string;
  setIsApply: React.Dispatch<React.SetStateAction<boolean>>;
  isApply: boolean;
}
const EquipmentApplicationPopup: FC<ICreateData> = ({ isOpen, setIsOpen, departments, employees, editedData, applyEquipmentData, curLocation, setIsApply, isApply }) => {
  const [dateRange, setDateRange] = useState<any[]>([null, null]);
  const [usageDurationStart, usageDurationEnd] = dateRange;
  const [application, setApplication] = useState<string>('Borrow');
  const [department, setDepartment] = useState<string>('');
  const [employee, setEmployee] = useState<string>('');
  const [updatedData, setUpdatedData] = useState<any>([]);
  const [assignDate, setAssignDate] = useState<Date | undefined>();
  const [isError, setIsError] = useState<boolean>(false);
  useEffect(() => {
    if (editedData) {      
      setEmployee(editedData?.employee?.id);
      setDepartment(editedData?.employee?.department_id);
      setApplication(editedData.application_type ? editedData.application_type : 'Borrow');
      setDateRange([editedData.assign_date ? editedData.assign_date : undefined, (editedData.expected_return_date.date ? editedData.expected_return_date.date : undefined)])
      setAssignDate(editedData.assign_date ? editedData.assign_date : undefined)
      setUpdatedData({
        "equipmentID": (curLocation == 'all' ? (editedData ? editedData.equipment_id : 0) : editedData ? editedData.equipment_id : 0),
        "reason": editedData?.reason,
        "application": editedData.application_type,
        // "usage_duration_start": editedData.assign_date,
        // "usage_duration_end": editedData.expected_return_date?.date ? editedData.expected_return_date.date : undefined,
      })
    }

  }, [editedData])
  const changeInputData = (event: any) => {
    setUpdatedData({ ...updatedData, [event.target.name]: event.target.value });
  }
  const handleConfirm = () => {
    const data: IEquipmentApp = {
      id: editedData ? editedData.id : 0,
      equipment_id: (curLocation == 'all' ? (editedData ? editedData.equipment_id : 0) : editedData ? editedData.equipment_id : 0),
      employee_id: employee ? Number(employee) : 0,
      department_id: department ? Number(department) : 0,
      reason: updatedData?.reason,
      "application_type": application,
      "assign_date": (application == 'Borrow' ? (usageDurationStart ? renderDateFormatYMD(new Date(usageDurationStart), '-') : '') :
        (assignDate ? renderDateFormatYMD(new Date(assignDate), '-') : '')),
      "expected_return_date": (application == 'Borrow' ? (usageDurationEnd ? renderDateFormatYMD(new Date(usageDurationEnd), '-') : '') : ''),
    }
    if (data.employee_id && data.department_id && data.application_type && data.assign_date) {
      setIsError(false);
      applyEquipmentData(data);
      setIsApply(true);
    } else {
      setIsError(true);
      setIsApply(false);
    }
  }
  useEffect(()=>{
    if(isOpen==true){
      setIsApply(false);
      setIsError(false);
    }
  },[isOpen])
  return (
    <Modal
      open={isOpen}
      style={{
        background: "rgb(0 0 0 / 0.3)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      onClose={(event, reason) => {
        if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
          setIsOpen(false);
        }
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className="modalContent rounded-xl lg:w-[1000px] max-w-[90%] annoucement-modal overflow-y-auto max-h-[90vh] w-full focus-visible:outline-none">
        <div
          id="modal-modal-title"
          className="bg-vorpblue relative flex justify-between py-6 sm:px-10 px-5 rounded-t-xl"
        >
          <h3 className="text-18 font-primary font-semibold text-white">
            Equipment Application
          </h3>
          <button
            className="flex items-center justify-center"
            onClick={() => {
              setIsOpen(false);
            }}
          >
            <CloseIcon />
          </button>
        </div>

        <div
          id="modal-modal-description"
          className="py-9 pl-5 pr-2 bg-white rounded-b-xl"
        >
          <div className="w-full">
            <div className="overflow-y-auto max-h-[50vh] custom_scrollbar_popup">
              <div className={twMerge("grid grid-cols-1 md:grid-cols-2 gap-4 pr-3")}>
                <div className="md:border-r border-r-vof5 md:pr-4">
                  <div>
                    <label className="text-black2 font-normal body-15 block mb-2" htmlFor="">Type of application <span className="text-redPink">*</span></label>
                    <div className="flex items-center mt-1">
                      <BaseRadioButton
                        state={application}
                        setState={setApplication}
                        label="Borrow"
                        value="Borrow"
                        // value="full"
                        id="full"
                        className="mr-4 sm:mr-8"
                        fontSize="md:text-sm text-xs"
                        name="condition"
                      />
                      <BaseRadioButton
                        state={application}
                        setState={setApplication}
                        label="Assign"
                        value="Assign"
                        // value="half-am"
                        id="half-am"
                        className="mr-4 sm:mr-8"
                        fontSize="md:text-sm text-xs"
                        name="condition"
                      />
                    </div>
                    <span className="text-xs font-normal text-redPink my-1 hidden">Type of application is required.</span>
                  </div>
                  <div className="mt-4">
                    <label className="text-black2 font-normal body-15 block mb-2" htmlFor="">Usage Duration</label>
                    <div className="mt-1">
                      {application == "Borrow" ?
                        <div className="equipment-borrow-date">
                          <MultiDatePopupFilterCustom
                            placeholder="Start Date - End Date"
                            startDate={usageDurationStart ? new Date(usageDurationStart) : null}
                            endDate={usageDurationEnd ? new Date(usageDurationEnd) : null}
                            setDateRange={setDateRange}
                            fontSize="md:text-sm text-12 "
                            color="text-black2"
                            padding="py-3"                                                        
                            borderRadius="rounded-md w-full"                           
                          />
                          {(isError == true && (!usageDurationStart)) ? <span className="text-redPink font-normal text-xs my-1">Date is required.</span> : ''}
                        </div> : <div className="equipment-assign-date"    >
                          <CustomDatePicker
                            format="yyyy-mm-dd"
                            date={assignDate ? new Date(assignDate) : new Date()}
                            minDate={new Date()}
                            setDate={setAssignDate}
                            isFilterSection={false}
                            placeholder="yyyy-mm-dd"
                            iconColor="#C4C4C4"
                            borderColor="border-goalinputborder"
                            fontSize="md:text-sm text-xs"      
                                              
                          />
                          {(isError == true && (!assignDate)) ? <span className="text-redPink font-normal text-xs my-1">Assign Date is required.</span> : ''}
                        </div>}
                        
                    </div>
                  </div>
                </div>
                <div>
                  <div>
                    <label className="text-black2 font-normal body-15 block mb-2" htmlFor="">Department</label>
                    <div className={classNames(curLocation == 'all' ? '' : 'pointer-events-none opacity-50', "mt-1")}>
                      <StringDropdownList
                        state={department}
                        setState={setDepartment}
                        placeholder="Departments"
                        className="w-full py-[12px] pl-3 md:text-sm text-xs text-black2"
                        dropdownItems={departments?.map((department: any) => ({
                          value: department.id,
                          label: department.name,
                        }))}
                        textAlign={"text-left"}
                        fontSize="md:text-14 text-12"
                        border="border-goalinputborder"
                        color="text-black2"
                        paddingLi="py-2"
                      />
                    </div>
                    {(isError == true && (!department)) ? <span className="text-redPink font-normal text-xs my-1">Department is required.</span> : ''}
                  </div>
                  <div className="mt-4">
                    <label className="text-black2 font-normal body-15 block mb-2" htmlFor="">Employee<span className="text-redPink">*</span></label>
                    <div className={classNames(curLocation == 'all' ? '' : 'pointer-events-none opacity-50', "mt-1")}>
                      <StringDropdownList
                        state={employee}
                        setState={setEmployee}
                        placeholder="Employee"
                        className="w-full py-[12px] pl-3 md:text-sm text-xs text-black2"
                        dropdownItems={employees?.map((employee: any) => ({
                          value: employee.id,
                          label: employee.name,
                        }))}
                        textAlign={"text-left"}
                        fontSize="md:text-14 text-12"
                        border="border-goalinputborder"
                        color="text-black2"
                        padding="py-2"
                      />
                    </div>
                    {(isError == true && (!employee)) ? <span className="text-redPink font-normal text-xs my-1">Employee is required.</span> : ''}
                  </div>
                  <div className="mt-4">
                    <label className="text-black2 font-normal body-15 block mb-2" htmlFor="">Reason<span className="text-redPink">*</span></label>
                    <textarea
                      name="reason"
                      value={updatedData.reason}
                      onChange={changeInputData}
                      rows={3} placeholder="Reason" className="md:text-sm text-xs placeholder:text-black2 px-3 py-11 w-full bg-white rounded mt-1 border border-goalinputborder focus-within:outline-none focus:outline-none focus-visible:outline-none resize-none">
                    </textarea>
                    {(isError == true && (!updatedData.reason)) ? <span className="text-redPink font-normal text-xs my-1">Reason is required.</span> : ''}
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-5 last:mb-0 flex items-center">
              <button onClick={() => handleConfirm()}
                className={classNames(
                  isApply == true ? 'opacity-50 pointer-events-none' : '',
                  "flex items-center saveButton bg-vorpblue mr-2 px-5 py-[10px] font-poppins font-medium text-center lg:text-sm text-xs text-[#fff] rounded-[6px]"
                )}
              >
                <div className="saveButton hover:bg-vorpblue mr-2">
                  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                    <path d="M1.75 0.875C1.51794 0.875 1.29538 0.967187 1.13128 1.13128C0.967187 1.29538 0.875 1.51794 0.875 1.75V12.25C0.875 12.4821 0.967187 12.7046 1.13128 12.8687C1.29538 13.0328 1.51794 13.125 1.75 13.125H12.25C12.4821 13.125 12.7046 13.0328 12.8687 12.8687C13.0328 12.7046 13.125 12.4821 13.125 12.25V1.75C13.125 1.51794 13.0328 1.29538 12.8687 1.13128C12.7046 0.967187 12.4821 0.875 12.25 0.875H8.3125C8.08044 0.875 7.85788 0.967187 7.69378 1.13128C7.52969 1.29538 7.4375 1.51794 7.4375 1.75V8.13138L9.75275 5.81525C9.8349 5.7331 9.94632 5.68695 10.0625 5.68695C10.1787 5.68695 10.2901 5.7331 10.3722 5.81525C10.4544 5.8974 10.5006 6.00882 10.5006 6.125C10.5006 6.24118 10.4544 6.3526 10.3722 6.43475L7.30975 9.49725C7.26911 9.53799 7.22083 9.57032 7.16768 9.59237C7.11453 9.61443 7.05755 9.62578 7 9.62578C6.94245 9.62578 6.88547 9.61443 6.83232 9.59237C6.77917 9.57032 6.73089 9.53799 6.69025 9.49725L3.62775 6.43475C3.58707 6.39407 3.55481 6.34578 3.53279 6.29264C3.51078 6.23949 3.49945 6.18253 3.49945 6.125C3.49945 6.06747 3.51078 6.01051 3.53279 5.95736C3.55481 5.90422 3.58707 5.85593 3.62775 5.81525C3.66843 5.77457 3.71672 5.74231 3.76986 5.72029C3.82301 5.69828 3.87997 5.68695 3.9375 5.68695C3.99503 5.68695 4.05199 5.69828 4.10514 5.72029C4.15828 5.74231 4.20657 5.77457 4.24725 5.81525L6.5625 8.13138V1.75C6.5625 1.28587 6.74687 0.840752 7.07506 0.512563C7.40325 0.184374 7.84837 0 8.3125 0L12.25 0C12.7141 0 13.1592 0.184374 13.4874 0.512563C13.8156 0.840752 14 1.28587 14 1.75V12.25C14 12.7141 13.8156 13.1592 13.4874 13.4874C13.1592 13.8156 12.7141 14 12.25 14H1.75C1.28587 14 0.840752 13.8156 0.512563 13.4874C0.184374 13.1592 0 12.7141 0 12.25V1.75C0 1.28587 0.184374 0.840752 0.512563 0.512563C0.840752 0.184374 1.28587 0 1.75 0L3.9375 0C4.05353 0 4.16481 0.0460936 4.24686 0.128141C4.32891 0.210188 4.375 0.321468 4.375 0.4375C4.375 0.553532 4.32891 0.664812 4.24686 0.746859C4.16481 0.828906 4.05353 0.875 3.9375 0.875H1.75Z" fill="#FEFCFF" />
                  </svg>
                </div>
                {isApply == true ? <div className="flex items-center justify-center w-full">
                  <CircularProgress sx={{ color: 'white' }} className="w-5 h-5" style={{ width: '20px', height: '20px' }} /><span className="ml-2">Submit</span>
                </div> : "Submit"}
              </button>
              <Button
                type="button"
                label={
                  <span className="flex items-center text-12 leading-[18px] justify-center">
                    <div className="mr-2">
                      <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                        <g clip-path="url(#clip0_17372_20468)">
                          <path d="M4.23821 9.38673C4.08389 9.38702 3.93575 9.32618 3.82618 9.21751L0.173911 5.60579C0.118815 5.5513 0.0750749 5.48643 0.0452229 5.41492C0.0153709 5.34341 0 5.26668 0 5.18919C0 5.1117 0.0153709 5.03498 0.0452229 4.96347C0.0750749 4.89196 0.118815 4.82708 0.173911 4.77259L3.82618 1.16111C3.93667 1.05183 4.08604 0.99092 4.24144 0.991777C4.39684 0.992634 4.54553 1.05519 4.65481 1.16568C4.76409 1.27617 4.825 1.42554 4.82414 1.58094C4.82328 1.73634 4.76073 1.88503 4.65024 1.99431L1.41915 5.18931L4.65024 8.38431C4.73279 8.466 4.7892 8.57038 4.81231 8.6842C4.83542 8.79802 4.82419 8.91613 4.78004 9.02355C4.73588 9.13097 4.6608 9.22285 4.56433 9.28751C4.46786 9.35217 4.35435 9.38671 4.23821 9.38673Z" fill="#464E5F" />
                          <path d="M8.77664 11.0088H7.89844C7.74304 11.0088 7.594 10.9471 7.48412 10.8372C7.37423 10.7273 7.3125 10.5782 7.3125 10.4228C7.3125 10.2674 7.37423 10.1184 7.48412 10.0085C7.594 9.89864 7.74304 9.83691 7.89844 9.83691H8.77664C9.90773 9.83691 10.8281 8.9259 10.8281 7.80605C10.8281 6.68621 9.90773 5.77519 8.77664 5.77519H0.773438C0.618037 5.77519 0.469002 5.71346 0.359117 5.60358C0.249233 5.49369 0.1875 5.34466 0.1875 5.18925C0.1875 5.03385 0.249233 4.88482 0.359117 4.77493C0.469002 4.66505 0.618037 4.60332 0.773438 4.60332H8.77664C10.5539 4.60332 12 6.04004 12 7.80605C12 9.57207 10.5539 11.0088 8.77664 11.0088Z" fill="#464E5F" />
                        </g>
                        <defs>
                          <clipPath id="clip0_17372_20468">
                            <rect width="12" height="12" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                    Cancel
                  </span>
                }
                onClick={() => {
                  setIsOpen(false);
                }}
                variant="no-outline"
                size="lg"
                customClass="min-w-[112px] min-h-[43.6px] bg-offwhite text-graydark border-none backButton"
              />
            </div>
          </div>

        </div>

      </div>
    </Modal>
  );
};
export default EquipmentApplicationPopup;
