import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import Button from "../common/Button";
import {
  CloseIcon,
  DownCloseIcon,
  NextIcon,
  QuestionIcon,
  SearchIcon,
  SelectedIcon,
  SendIcon,
  UpOpenIcon,
} from "../common/Icons";
import { Fade, Modal, Tooltip } from "@mui/material";
import classNames from "classnames";
import "react-phone-input-2/lib/style.css";
import { makeStyles } from "@mui/styles";
import { StringDropdownList } from "../common/DropdownList";
import BaseRadioButton from "../common/BaseRadioButton";
import styles from "./WorkingShiftPopup.module.scss";
// RC Time Picker
// import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";
import moment from "moment";
// import { StylesContext } from "@mui/styles";
import ResuableInput from "../CreateProjectPopup/ResuableInput";
import useInput from "../InputHook/UseInput";
import DebounceSearchInput from "../Tables/DebounceSearchInput";
// import WorkingShiftEmployeeAssignment from "./WorkingShiftEmployeeAssignment";
import { PasswordUserList, assignUsers } from "../../app/services/password";
// import { EmployeeData } from "../EmployeeTable/EmployeeTable";
import UserSelectionTab from "../Passwords/UserSelectionTab";
import { baseObj } from "../../utils/constants";
import WorkingShiftList from "./WorkingShiftList";
import WorkingShiftCustomise from "./WorkingShiftCustomise";
import ResuableToasty from "../Toasty/ResuableToast";
// import WorkingShiftSaturday from "./WorkingShiftSaturday";
import FlexibleWorkingShiftList from "./FlexibleWorkingShiftList";
import TimePicker from "rc-time-picker";
import {
  WorkingShiftCountry,
  WorkingShiftDepartment,
  WorkingShiftEmployee,
} from "../../modals/working-shift/WorkingShift";
import { CommonTagDropdown } from "../common/CommonTagDropdown";
import {
  useSaveWorkingShiftMutation,
  useUpdateWorkingShiftMutation,
} from "../../app/services/working-shift";
// import { parseEventDef } from "@fullcalendar/core/internal";

export const tagsList = [
  { id: 1, name: "Shift Information" },
  { id: 2, name: "Shift Configuration" },
  { id: 3, name: "Assign Employee" },
];

export type TCreateProjectPopup = {
  setIsEdit: Dispatch<SetStateAction<boolean>>;
  isEditing: boolean;
  setIsCreate: Dispatch<SetStateAction<boolean>>;
  isCreate: boolean;
  locationList: any[];
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  setIsDuplicate: Dispatch<SetStateAction<boolean>>;
  isDuplicate: boolean;
  isOpen: boolean;
  editedData?: any;
  setEditData?: any;
  // region: string;
  // setRegion: React.Dispatch<React.SetStateAction<string>>;
  regionList: WorkingShiftCountry[];
  workingDay: any[];
  assignUserListData?: assignUsers[];
  departmentList?: WorkingShiftDepartment[];
  setAssignDepartmentId?: React.Dispatch<React.SetStateAction<string>>;
  assignDepartmentId?: string;
  setAssignKeword?: React.Dispatch<React.SetStateAction<string>>;
  employeeList?: WorkingShiftEmployee[];
};
const format = "h:mm ";
const formatDaily = "HH:mm:ss";
// const formatWithHour = "h:mm ";
const now = moment().hour(0).minute(0);

const workingObject = {
  key: Math.random() * 100,
  location: "",
  firstClock: "00:00:00",
  firstAMPM: "AM",
  secondClock: "00:00:00",
  secondAMPM: "PM",
};

const flexibleDailyObject = {
  key: Math.random() * 100,
  location: "",
  firstClock: "00:00:00",
  firstAMPM: "AM",
  secondClock: "00:00:00",
  secondAMPM: "PM",
};

const workingCustomiseObject = {
  key: Math.random() * 100,
  location: "",
  status: "",
  firstClock: "00:00:00",
  firstAMPM: "AM",
  secondClock: "00:00:00",
  secondAMPM: "PM",
};

const workingObjectMonday = {
  key: Math.random() * 100,
  location: "",
  status: "",
  firstClock: "00:00:00",
  firstAMPM: "AM",
  secondClock: "00:00:00",
  secondAMPM: "PM",
};

const workingObjectTuesday = {
  key: Math.random() * 100,
  location: "",
  status: "",
  firstClock: "00:00:00",
  firstAMPM: "AM",
  secondClock: "00:00:00",
  secondAMPM: "PM",
};

const workingObjectWednesday = {
  key: Math.random() * 100,
  location: "",
  status: "",
  firstClock: "00:00:00",
  firstAMPM: "AM",
  secondClock: "00:00:00",
  secondAMPM: "PM",
};

const workingObjectThursday = {
  key: Math.random() * 100,
  location: "",
  status: "",
  firstClock: "00:00:00",
  firstAMPM: "AM",
  secondClock: "00:00:00",
  secondAMPM: "PM",
};

const workingObjectFriday = {
  key: Math.random() * 100,
  location: "",
  status: "",
  firstClock: "00:00:00",
  firstAMPM: "AM",
  secondClock: "00:00:00",
  secondAMPM: "PM",
};

const workingObjectSaturday = {
  key: Math.random() * 100,
  location: "",
  status: "",
  firstClock: "00:00:00",
  firstAMPM: "AM",
  secondClock: "00:00:00",
  secondAMPM: "PM",
};

const workingObjectSunday = {
  key: Math.random() * 100,
  location: "",
  status: "",
  firstClock: "00:00:00",
  firstAMPM: "AM",
  secondClock: "00:00:00",
  secondAMPM: "PM",
};

//flexible
const mondayFlexibleList = [
  {
    id: 0,
    weekEndName: "Monday",
    isChecked: true,
    text: "Working Hours ",
    clock: "00:00:00",
  },
];

const tuesdayFlexibleList = [
  {
    id: 1,
    weekEndName: "Tuesday",
    isChecked: true,
    text: "Working Hours ",
    clock: "00:00:00",
  },
];

const wednesdayFlexibleList = [
  {
    id: 2,
    weekEndName: "Wednesday",
    isChecked: true,
    text: "Working Hours ",
    clock: "00:00:00",
  },
];

const thursdayFlexibleList = [
  {
    id: 3,
    weekEndName: "Thursday",
    isChecked: true,
    text: "Working Hours ",
    clock: "00:00:00",
  },
];
const fridayFlexibleList = [
  {
    id: 4,
    weekEndName: "Friday",
    isChecked: true,
    text: "Working Hours ",
    clock: "00:00:00",
  },
];
const saturdayFlexibleList = [
  {
    id: 5,
    weekEndName: "Saturday",
    isChecked: true,
    text: "Working Hours ",
    clock: "00:00:00",
  },
];
const sundayFlexibleList = [
  {
    id: 6,
    weekEndName: "Sunday",
    isChecked: true,
    text: "Working Hours ",
    clock: "00:00:00",
  },
];

//weekend
const mondayWeekendList = [
  {
    id: 0,
    weekEndName: "Monday",
    isChecked: true,
    text: "",
  },
];
const tuesdayWeekendList = [
  {
    id: 1,
    weekEndName: "Tuesday",
    isChecked: true,
    text: "Work 9:00AM-5:00PM Break 9:00AM-5:00PM Work 9:00AM-5:00PM",
  },
];
const wednesdayWeekendList = [
  {
    id: 2,
    weekEndName: "Wednesday",
    isChecked: true,
    text: "Work 9:00AM-5:00PM Break 9:00AM-5:00PM Work 9:00AM-5:00PM",
  },
];
const thursdayWeekendList = [
  {
    id: 3,
    weekEndName: "Thursday",
    isChecked: true,
    text: "Work 9:00AM-5:00PM Break 9:00AM-5:00PM Work 9:00AM-5:00PM",
  },
];
const firdayWeekendList = [
  {
    id: 4,
    weekEndName: "Friday",
    isChecked: true,
    text: "Work 9:00AM-5:00PM Break 9:00AM-5:00PM Work 9:00AM-5:00PM",
  },
];
const saturdayWeekendList = [
  {
    id: 5,
    weekEndName: "Saturday",
    isChecked: true,
    text: "---",
  },
];
const sundayWeekendList = [
  {
    id: 6,
    weekEndName: "Sunday",
    isChecked: true,
    text: "---",
  },
];

const dayBeakWork = ["work", "break"];

const useStyles = makeStyles(() => ({
  tooltip: {
    backgroundColor: "transparent",
    margin: 0,
    marginRight: 8,
    padding: 0,
    zIndex: 9999999,
  },
}));

const WorkingShiftPopup: FC<TCreateProjectPopup> = ({
  isOpen,
  setIsOpen,
  editedData,
  setEditData,
  regionList,
  locationList,
  workingDay,
  isDuplicate,
  setIsDuplicate,
  setIsCreate,
  isCreate,
  setIsEdit,
  isEditing,
  // region,
  // setRegion,
  assignUserListData,
  departmentList,
  setAssignDepartmentId,
  assignDepartmentId,
  setAssignKeword,
  employeeList,
}) => {
  const classes = useStyles();
  const [saveWorkingShift] = useSaveWorkingShiftMutation();
  const [updateWorkingShift] = useUpdateWorkingShiftMutation();
  // console.log("edit", editedData);
  const [mondayFlexible, setMondayFlexible] = useState<any[]>([
    ...mondayFlexibleList,
  ]);

  // console.log("mondayFlexible", mondayFlexible);

  const [tuesdayFlexible, setTuesdayFlexible] = useState<any[]>([
    ...tuesdayFlexibleList,
  ]);

  const [wednesdayFlexible, setWednesdayFlexible] = useState<any[]>([
    ...wednesdayFlexibleList,
  ]);

  const [thursdayFlexible, setThursdayFlexible] = useState<any[]>([
    ...thursdayFlexibleList,
  ]);

  const [fridayFlexible, setFridayFlexible] = useState<any[]>([
    ...fridayFlexibleList,
  ]);

  const [saturdayFlexible, setSaturdayFlexible] = useState<any[]>([
    ...saturdayFlexibleList,
  ]);

  const [sundayFlexible, setSundayFlexible] = useState<any[]>([
    ...sundayFlexibleList,
  ]);

  //weenkend
  const [mondayWeekend, setMondayWeekend] = useState<any[]>([
    ...mondayWeekendList,
  ]);
  // console.log("mondayWeekend", mondayWeekend);

  const [tuesdayWeekend, setTuesdayWeekend] = useState<any[]>([
    ...tuesdayWeekendList,
  ]);
  const [wendesdayWeekend, setWednesdayWeekend] = useState<any[]>([
    ...wednesdayWeekendList,
  ]);
  const [thursdayWeekend, setThursdayWeekend] = useState<any[]>([
    ...thursdayWeekendList,
  ]);
  const [firdayWeekend, setFirdayWeekend] = useState<any[]>([
    ...firdayWeekendList,
  ]);
  const [saturdayWeekend, setSaturdayWeekend] = useState<any[]>([
    ...saturdayWeekendList,
  ]);
  const [sundayWeekend, setSundayWeekend] = useState<any[]>([
    ...sundayWeekendList,
  ]);

  const remarkInput = useInput("");
  const [workingList, setWorkingList] = useState<any[]>([workingObject]);
  // console.log("list w", workingList);
  const [standardHour, setStandardHour] = useState<number>(0);
  const [standardMinute, setStandardMinute] = useState<number>(0);
  // console.log("list w", { standardHour, standardMinute });

  useEffect(() => {
    let HourSum: number = 0;
    let HourSum2: number = 0;
    let MinuteSum: number = 0;
    let MinuteSum2: number = 0;

    // calculate sum using forEach() method
    workingList?.forEach((num: any) => {
      const firstHour = Number(String(num.firstClock).split(":")[0]);
      if (firstHour === 0) {
        HourSum += 24;
      } else {
        HourSum += Number(String(num.firstClock).split(":")[0]);
      }
    });
    workingList?.forEach((num: any) => {
      MinuteSum += Number(String(num.firstClock).split(":")[1]);
    });
    const milliH = moment.duration(HourSum, "hours").asMilliseconds();
    const milliM = moment.duration(MinuteSum, "minutes").asMilliseconds();
    const firstTime = Number(milliH) + Number(milliM);

    workingList?.forEach((num: any) => {
      const secondHour = Number(String(num.secondClock).split(":")[0]);
      // console.log("hello", secondHour);
      if (secondHour === 0) {
        HourSum2 += Number(24);
      } else {
        HourSum2 += Number(String(num.secondClock).split(":")[0]);
      }
    });
    workingList?.forEach((num: any) => {
      MinuteSum2 += Number(String(num.secondClock).split(":")[1]);
    });

    const milliH2 = moment.duration(HourSum2, "hours").asMilliseconds();
    const milliM2 = moment.duration(MinuteSum2, "minutes").asMilliseconds();
    const sedondTime = Number(milliH2) + Number(milliM2);

    const lastHourMilli = sedondTime - firstTime;
    const duration = moment.duration(lastHourMilli);
    const hours = Math.floor(duration.asHours());
    const minutes = duration.minutes();
    setStandardHour(hours);
    setStandardMinute(minutes);
  }, [workingList]);

  const [flexibleDaily, setFlexibleDaily] = useState<any[]>([
    flexibleDailyObject,
  ]);
  // console.log("flexibleDaily", flexibleDaily);

  // const [workingListCustomise, setWorkingListCustomise] = useState<any[]>([
  //   workingCustomiseObject,
  // ]);
  const [workingShiftMonday, setWorkingShiftMonday] = useState<any[]>([
    workingObjectMonday,
  ]);
  // console.log("monday", workingShiftMonday);

  const [mondayCustomiseHour, setMondayCustomiseHour] = useState<number>(0);
  const [mondayCustomiseMinute, setMondayCustomiseMinute] = useState<number>(0);

  const [tuesdayCustomiseHour, setTuesdayCustomiseHour] = useState<number>(0);
  const [tuesdayCustomiseMinute, setTuesdayCustomiseMinute] =
    useState<number>(0);

  const [wednesdayCustomiseHour, seWednesdayCustomiseHour] =
    useState<number>(0);
  const [wednesdayCustomiseMinute, setWednesdayCustomiseMinute] =
    useState<number>(0);

  const [thursdayCustomiseHour, setThursdayCustomiseHour] = useState<number>(0);
  const [thursdayCustomiseMinute, setThursdayCustomiseMinute] =
    useState<number>(0);

  const [fridayCustomiseHour, setfridayCustomiseHour] = useState<number>(0);
  const [fridayCustomiseMinute, setfridayCustomiseMinute] = useState<number>(0);

  const [saturdayCustomiseHour, setSaturdayCustomiseHour] = useState<number>(0);
  const [saturdayCustomiseMinute, setSaturdayCustomiseMinute] =
    useState<number>(0);

  const [sundayCustomiseHour, setSundayCustomiseHour] = useState<number>(0);
  const [sundayCustomiseMinute, setSundayCustomiseMinute] = useState<number>(0);

  // console.log("sundayCustomiseHour", sundayCustomiseHour);

  const [totalWorkingHour, setTotalWorkingHour] = useState<number>(0);
  const [totalWorkingMinute, setTotalWorkingMinute] = useState<number>(0);

  useEffect(() => {
    let allHourTime = 0;
    let allMinuteTime = 0;
    allHourTime += moment
      .duration(mondayCustomiseHour, "hours")
      .asMilliseconds();
    allMinuteTime += moment
      .duration(mondayCustomiseMinute, "minutes")
      .asMilliseconds();

    allHourTime += moment
      .duration(tuesdayCustomiseHour, "hours")
      .asMilliseconds();
    allMinuteTime += moment
      .duration(tuesdayCustomiseMinute, "minutes")
      .asMilliseconds();

    allHourTime += moment
      .duration(wednesdayCustomiseHour, "hours")
      .asMilliseconds();
    allMinuteTime += moment
      .duration(wednesdayCustomiseMinute, "minutes")
      .asMilliseconds();

    allHourTime += moment
      .duration(thursdayCustomiseHour, "hours")
      .asMilliseconds();
    allMinuteTime += moment
      .duration(thursdayCustomiseMinute, "minutes")
      .asMilliseconds();

    allHourTime += moment
      .duration(fridayCustomiseHour, "hours")
      .asMilliseconds();
    allMinuteTime += moment
      .duration(fridayCustomiseMinute, "minutes")
      .asMilliseconds();

    allHourTime += moment
      .duration(saturdayCustomiseHour, "hours")
      .asMilliseconds();
    allMinuteTime += moment
      .duration(saturdayCustomiseMinute, "minutes")
      .asMilliseconds();

    allHourTime += moment
      .duration(sundayCustomiseHour, "hours")
      .asMilliseconds();
    allMinuteTime += moment
      .duration(sundayCustomiseMinute, "minutes")
      .asMilliseconds();

    const sedondTime = Number(allHourTime) + Number(allMinuteTime);

    const duration = moment.duration(sedondTime);
    const hours = Math.floor(duration.asHours());
    const minutes = duration.minutes();
    setTotalWorkingHour(hours);
    setTotalWorkingMinute(minutes);

    // console.log("hh & mm", { hours, minutes });
  }, [
    mondayCustomiseHour,
    mondayCustomiseMinute,
    tuesdayCustomiseHour,
    tuesdayCustomiseMinute,
    wednesdayCustomiseHour,
    wednesdayCustomiseMinute,
    thursdayCustomiseHour,
    thursdayCustomiseMinute,
    fridayCustomiseHour,
    fridayCustomiseMinute,
    saturdayCustomiseHour,
    saturdayCustomiseMinute,
    sundayCustomiseHour,
    sundayCustomiseMinute,
  ]);

  useEffect(() => {
    let MondayHourSum: number = 0;
    let MondayMinuteSum: number = 0;
    let MondayHourSum2: number = 0;
    let MondayMinuteSum2: number = 0;

    // calculate sum using forEach() method
    if (workingShiftMonday) {
      workingShiftMonday.forEach((num: any) => {
        const firstHour = Number(String(num.firstClock).split(":")[0]);
        if (firstHour === 0) {
          MondayHourSum += 24;
        } else {
          MondayHourSum += Number(String(num.firstClock).split(":")[0]);
        }
      });
      workingShiftMonday.forEach((num: any) => {
        MondayMinuteSum += Number(String(num.firstClock).split(":")[1]);
      });
      const milliH = moment.duration(MondayHourSum, "hours").asMilliseconds();
      const milliM = moment
        .duration(MondayMinuteSum, "minutes")
        .asMilliseconds();
      const firstTime = Number(milliH) + Number(milliM);

      workingShiftMonday.forEach((num: any) => {
        const firstHour = Number(String(num.secondClock).split(":")[0]);
        if (firstHour === 0) {
          MondayHourSum2 += 24;
        } else {
          MondayHourSum2 += Number(String(num.secondClock).split(":")[0]);
        }
      });
      workingShiftMonday.forEach((num: any) => {
        MondayMinuteSum2 += Number(String(num.secondClock).split(":")[1]);
      });
      const milliH2 = moment.duration(MondayHourSum2, "hours").asMilliseconds();
      const milliM2 = moment
        .duration(MondayMinuteSum2, "minutes")
        .asMilliseconds();
      const sedondTime = Number(milliH2) + Number(milliM2);

      const lastHourMilli = sedondTime - firstTime;

      const duration = moment.duration(lastHourMilli);
      const hours = Math.floor(duration.asHours());
      const minutes = duration.minutes();
      setMondayCustomiseHour(hours);
      setMondayCustomiseMinute(minutes);
    }
  }, [workingShiftMonday]);

  const [workingShiftTuesday, setWorkingShiftTuesday] = useState<any[]>([
    workingObjectTuesday,
  ]);

  useEffect(() => {
    let TuesdayHourSum: number = 0;
    let TuesdayMinuteSum: number = 0;
    let TuesdayHourSum2: number = 0;
    let TuesdayMinuteSum2: number = 0;

    //for tuesday customise
    if (workingShiftTuesday) {
      workingShiftTuesday.forEach((num: any) => {
        const firstHour = Number(String(num.firstClock).split(":")[0]);
        if (firstHour === 0) {
          TuesdayHourSum += 24;
        } else {
          TuesdayHourSum += Number(String(num.firstClock).split(":")[0]);
        }
      });
      workingShiftTuesday.forEach((num: any) => {
        TuesdayMinuteSum += Number(String(num.firstClock).split(":")[1]);
      });
      const milliH = moment.duration(TuesdayHourSum, "hours").asMilliseconds();
      const milliM = moment
        .duration(TuesdayMinuteSum, "minutes")
        .asMilliseconds();
      const firstTime = Number(milliH) + Number(milliM);

      workingShiftTuesday.forEach((num: any) => {
        const secondHour = Number(String(num.secondClock).split(":")[0]);
        if (secondHour === 0) {
          TuesdayHourSum2 += 24;
        } else {
          TuesdayHourSum2 += Number(String(num.secondClock).split(":")[0]);
        }
      });
      workingShiftTuesday.forEach((num: any) => {
        TuesdayMinuteSum2 += Number(String(num.secondClock).split(":")[1]);
      });

      const milliH2 = moment
        .duration(TuesdayHourSum2, "hours")
        .asMilliseconds();
      const milliM2 = moment
        .duration(TuesdayMinuteSum2, "minutes")
        .asMilliseconds();
      const sedondTime = Number(milliH2) + Number(milliM2);

      const lastHourMilli = sedondTime - firstTime;
      const duration = moment.duration(lastHourMilli);
      const hours = Math.floor(duration.asHours());
      const minutes = duration.minutes();

      setTuesdayCustomiseHour(hours);
      setTuesdayCustomiseMinute(minutes);
    }
  }, [workingShiftTuesday]);

  const [workingShiftWednesday, setWorkingShiftWednesday] = useState<any[]>([
    workingObjectWednesday,
  ]);

  useEffect(() => {
    let WednesdayHourSum: number = 0;
    let WednesdayMinuteSum: number = 0;
    let WednesdayHourSum2: number = 0;
    let WednesdayMinuteSum2: number = 0;

    //for wednesday customise
    if (workingShiftWednesday) {
      workingShiftWednesday.forEach((num: any) => {
        const firstHour = Number(String(num.firstClock).split(":")[0]);
        if (firstHour === 0) {
          WednesdayHourSum += 24;
        } else {
          WednesdayHourSum += Number(String(num.firstClock).split(":")[0]);
        }
      });
      workingShiftWednesday.forEach((num: any) => {
        WednesdayMinuteSum += Number(String(num.firstClock).split(":")[1]);
      });
      const milliH = moment
        .duration(WednesdayHourSum, "hours")
        .asMilliseconds();
      const milliM = moment
        .duration(WednesdayMinuteSum, "minutes")
        .asMilliseconds();
      const firstTime = Number(milliH) + Number(milliM);

      workingShiftWednesday.forEach((num: any) => {
        const secondHour = Number(String(num.secondClock).split(":")[0]);
        if (secondHour === 0) {
          WednesdayHourSum2 += 24;
        } else {
          WednesdayHourSum2 += Number(String(num.secondClock).split(":")[0]);
        }
      });

      workingShiftWednesday.forEach((num: any) => {
        WednesdayMinuteSum2 += Number(String(num.secondClock).split(":")[1]);
      });
      const milliH2 = moment
        .duration(WednesdayHourSum2, "hours")
        .asMilliseconds();
      const milliM2 = moment
        .duration(WednesdayMinuteSum2, "minutes")
        .asMilliseconds();
      const sedondTime = Number(milliH2) + Number(milliM2);

      const lastHourMilli = sedondTime - firstTime;
      const duration = moment.duration(lastHourMilli);
      const hours = Math.floor(duration.asHours());
      const minutes = duration.minutes();

      seWednesdayCustomiseHour(hours);
      setWednesdayCustomiseMinute(minutes);
    }
  }, [workingShiftWednesday]);

  const [workingShiftThursday, setWorkingShiftThursday] = useState<any[]>([
    workingObjectThursday,
  ]);

  useEffect(() => {
    let ThursdaydayHourSum: number = 0;
    let ThursdayMinuteSum: number = 0;
    let ThursdaydayHourSum2: number = 0;
    let ThursdayMinuteSum2: number = 0;

    //for thursday customise
    if (workingShiftThursday) {
      workingShiftThursday.forEach((num: any) => {
        const firstHour = Number(String(num.firstClock).split(":")[0]);
        if (firstHour === 0) {
          ThursdaydayHourSum += 24;
        } else {
          ThursdaydayHourSum += Number(String(num.firstClock).split(":")[0]);
        }
      });
      workingShiftThursday.forEach((num: any) => {
        ThursdayMinuteSum += Number(String(num.firstClock).split(":")[1]);
      });
      const milliH = moment
        .duration(ThursdaydayHourSum, "hours")
        .asMilliseconds();
      const milliM = moment
        .duration(ThursdayMinuteSum, "minutes")
        .asMilliseconds();
      const firstTime = Number(milliH) + Number(milliM);

      workingShiftThursday.forEach((num: any) => {
        const secondHour = Number(String(num.secondClock).split(":")[0]);
        if (secondHour === 0) {
          ThursdaydayHourSum2 += 24;
        } else {
          ThursdaydayHourSum2 += Number(String(num.secondClock).split(":")[0]);
        }
      });
      workingShiftThursday.forEach((num: any) => {
        ThursdayMinuteSum2 += Number(String(num.secondClock).split(":")[1]);
      });
      const milliH2 = moment
        .duration(ThursdaydayHourSum2, "hours")
        .asMilliseconds();
      const milliM2 = moment
        .duration(ThursdayMinuteSum2, "minutes")
        .asMilliseconds();
      const sedondTime = Number(milliH2) + Number(milliM2);

      const lastHourMilli = sedondTime - firstTime;
      const duration = moment.duration(lastHourMilli);
      const hours = Math.floor(duration.asHours());
      const minutes = duration.minutes();

      setThursdayCustomiseHour(hours);
      setThursdayCustomiseMinute(minutes);
    }
  }, [workingShiftThursday]);

  const [workingShiftFriday, setWorkingShiftFriday] = useState<any[]>([
    workingObjectFriday,
  ]);

  useEffect(() => {
    let FridaydayHourSum: number = 0;
    let FridayMinuteSum: number = 0;
    let FridaydayHourSum2: number = 0;
    let FridayMinuteSum2: number = 0;

    //for friday customise
    if (workingShiftFriday) {
      workingShiftFriday.forEach((num: any) => {
        const firstHour = Number(String(num.firstClock).split(":")[0]);
        if (firstHour === 0) {
          FridaydayHourSum += 24;
        } else {
          FridaydayHourSum += Number(String(num.firstClock).split(":")[0]);
        }
      });
      workingShiftFriday.forEach((num: any) => {
        FridayMinuteSum += Number(String(num.firstClock).split(":")[1]);
      });
      const milliH = moment
        .duration(FridaydayHourSum, "hours")
        .asMilliseconds();
      const milliM = moment
        .duration(FridayMinuteSum, "minutes")
        .asMilliseconds();
      const firstTime = Number(milliH) + Number(milliM);

      workingShiftFriday.forEach((num: any) => {
        const secondHour = Number(String(num.secondClock).split(":")[0]);
        if (secondHour === 0) {
          FridaydayHourSum2 += 24;
        } else {
          FridaydayHourSum2 += Number(String(num.secondClock).split(":")[0]);
        }
      });
      workingShiftFriday.forEach((num: any) => {
        FridayMinuteSum2 += Number(String(num.secondClock).split(":")[1]);
      });

      const milliH2 = moment
        .duration(FridaydayHourSum2, "hours")
        .asMilliseconds();
      const milliM2 = moment
        .duration(FridayMinuteSum2, "minutes")
        .asMilliseconds();
      const sedondTime = Number(milliH2) + Number(milliM2);

      const lastHourMilli = sedondTime - firstTime;
      const duration = moment.duration(lastHourMilli);
      const hours = Math.floor(duration.asHours());
      const minutes = duration.minutes();

      setfridayCustomiseHour(hours);
      setfridayCustomiseMinute(minutes);
    }
  }, [workingShiftFriday]);

  const [workingShiftSaturday, setWorkingShiftSaturday] = useState<any[]>([
    workingObjectSaturday,
  ]);

  useEffect(() => {
    let SaturdaydayHourSum: number = 0;
    let SaturdayMinuteSum: number = 0;
    let SaturdaydayHourSum2: number = 0;
    let SaturdayMinuteSum2: number = 0;

    //for saturday customise
    if (workingShiftSaturday) {
      workingShiftSaturday.forEach((num: any) => {
        const firstHour = Number(String(num.firstClock).split(":")[0]);
        if (firstHour === 0) {
          SaturdaydayHourSum += 24;
        } else {
          SaturdaydayHourSum += Number(String(num.firstClock).split(":")[0]);
        }
      });
      workingShiftSaturday.forEach((num: any) => {
        SaturdayMinuteSum += Number(String(num.firstClock).split(":")[1]);
      });
      const milliH = moment
        .duration(SaturdaydayHourSum, "hours")
        .asMilliseconds();
      const milliM = moment
        .duration(SaturdayMinuteSum, "minutes")
        .asMilliseconds();
      const firstTime = Number(milliH) + Number(milliM);

      workingShiftSaturday.forEach((num: any) => {
        const secondHour = Number(String(num.secondClock).split(":")[0]);
        if (secondHour === 0) {
          SaturdaydayHourSum2 += 24;
        } else {
          SaturdaydayHourSum2 += Number(String(num.secondClock).split(":")[0]);
        }
      });
      workingShiftSaturday.forEach((num: any) => {
        SaturdayMinuteSum2 += Number(String(num.secondClock).split(":")[1]);
      });
      const milliH2 = moment
        .duration(SaturdaydayHourSum2, "hours")
        .asMilliseconds();
      const milliM2 = moment
        .duration(SaturdayMinuteSum2, "minutes")
        .asMilliseconds();
      const sedondTime = Number(milliH2) + Number(milliM2);

      const lastHourMilli = sedondTime - firstTime;
      const duration = moment.duration(lastHourMilli);
      const hours = Math.floor(duration.asHours());
      const minutes = duration.minutes();

      setSaturdayCustomiseHour(hours);
      setSaturdayCustomiseMinute(minutes);
    }
  }, [workingShiftSaturday]);

  const [workingShiftSunday, setWorkingShiftSunday] = useState<any[]>([
    workingObjectSunday,
  ]);

  useEffect(() => {
    let SundaydayHourSum: number = 0;
    let SundayMinuteSum: number = 0;
    let SundaydayHourSum2: number = 0;
    let SundayMinuteSum2: number = 0;

    //for sunday customise
    if (workingShiftSunday) {
      workingShiftSunday.forEach((num: any) => {
        const firstHour = Number(String(num.firstClock).split(":")[0]);
        if (firstHour === 0) {
          SundaydayHourSum += 24;
        } else {
          SundaydayHourSum += Number(String(num.firstClock).split(":")[0]);
        }
      });
      workingShiftSunday.forEach((num: any) => {
        SundayMinuteSum += Number(String(num.firstClock).split(":")[1]);
      });
      const milliH = moment
        .duration(SundaydayHourSum, "hours")
        .asMilliseconds();
      const milliM = moment
        .duration(SundayMinuteSum, "minutes")
        .asMilliseconds();
      const firstTime = Number(milliH) + Number(milliM);

      workingShiftSunday.forEach((num: any) => {
        const secondHour = Number(String(num.secondClock).split(":")[0]);
        if (secondHour === 0) {
          SundaydayHourSum2 += 24;
        } else {
          SundaydayHourSum2 += Number(String(num.secondClock).split(":")[0]);
        }
      });

      workingShiftSunday.forEach((num: any) => {
        SundayMinuteSum2 += Number(String(num.secondClock).split(":")[1]);
      });
      const milliH2 = moment
        .duration(SundaydayHourSum2, "hours")
        .asMilliseconds();
      const milliM2 = moment
        .duration(SundayMinuteSum2, "minutes")
        .asMilliseconds();
      const sedondTime = Number(milliH2) + Number(milliM2);

      const lastHourMilli = sedondTime - firstTime;
      const duration = moment.duration(lastHourMilli);
      const hours = Math.floor(duration.asHours());
      const minutes = duration.minutes();

      setSundayCustomiseHour(hours);
      setSundayCustomiseMinute(minutes);
    }
  }, [workingShiftSunday]);

  // console.log("mondayWeekend", mondayWeekend);
  const [weeklyHours, setWeeklyHours] = useState<string>("00");

  const [state, setState] = useState<any>({
    current: 0,
    isShowing: true,
  });

  const [flexibleStateData, setFlexibleStateData] = useState<any>({
    current: 0,
    isShowing: true,
  });

  const handleShow = (index: number) => {
    if (index == state.current) {
      setState({
        ...state,
        isShowing: !state.isShowing,
      });
    } else {
      setState({
        current: index,
        isShowing: true,
      });
    }
  };

  const handleShowFlexible = (index: number) => {
    if (index == flexibleStateData.current) {
      setFlexibleStateData({
        ...flexibleStateData,
        isShowing: !flexibleStateData.isShowing,
      });
    } else {
      setFlexibleStateData({
        current: index,
        isShowing: true,
      });
    }
  };

  const [totalEmployeeLength, setTotalEmployeeLength] = useState<number>(0);
  const [tagsData, setTagsData] = useState<number>(1);
  const [nextTagsData, setNextTagsData] = useState<number>(1);
  const [globalSearch, setGlobalSearch] = useState<string>("");
  const [fixWorkHour, setFixWorkHour] = useState<string>("standard");
  const [flexibleState, setFlexibleState] = useState<string>("daily");
  // const [widthBreak, setWidthBreak] = useState<string>("WithoutBreak");
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [isSubmit, setIsSubmit] = useState<boolean>(false);
  const [uploading, setUploading] = useState<boolean>(false);
  const [name, setName] = useState<string>("");
  const [region, setRegion] = useState<string>("");
  const [page1Validation, setPage1Validation] = useState<boolean>(false);
  const [page2Validation, setPage2Validation] = useState<boolean>(false);

  // console.log("11", page1Validation);
  // console.log("22", page2Validation);

  const [departmentID, setDepartmentID] = useState<string>("");
  const [employeeData, setEmployeeData] = useState<string[]>([]);
  const [employeeDataID, setEmployeeDataID] = useState<any[]>([]);
  const [multiLocations, setMultLocations] = useState<string[]>([]);
  const [multiLocationsID, setMultiLocationsID] = useState<any[]>([]);
  // console.log("multiLocations", multiLocationsID);
  // console.log("employeeData", employeeData);
  const [shiftId, setShiftID] = useState<string>("");
  const [empList, setEmpList] = useState<any>(employeeList);
  const [existingMember, setExistingMember] = useState<any[]>([]);
  // const [modalType, setModalType] = useState<"assign" | "remove">("assign");
  // const [isAssignModalOpen, setIsAssignModalOpen] = useState<boolean>(false);
  // const [selectedMember, setSelectedMember] = useState<number[]>();
  const [selectedMember, setSelectedMember] = useState<number[]>(
    // existingMember ? existingMember.map((x: any) => x.id) : []
    []
  );
  // console.log("selectedMember", selectedMember);
  // console.log("existingMember", existingMember);
  // console.log("employeeList", employeeList);

  const [employeeListData, setEmployeeListData] = useState<any[] | undefined>(
    []
  );
  // console.log("first ", employeeListData);
  const [employeeListData2, setEmployeeListData2] = useState<any[] | undefined>(
    []
  );
  // console.log("second ", employeeListData2);

  const [isSelectedAll, setIsSelectedAll] = useState<boolean>(
    existingMember?.length === employeeList?.length
    // false
  );

  const [selectedMemberData, setSelectedMemberData] = useState<number[]>([]);
  // console.log("all emp", selectedMemberData);

  useEffect(() => {
    if (existingMember?.length === employeeList?.length) {
      setIsSelectedAll(true);
    } else {
      setIsSelectedAll(false);
    }
  }, [existingMember, employeeList]);
  const [isClear, setIsClear] = useState<boolean>(false);
  const clearData = () => {
    setName("");
    setShiftID("");
  };

  // const checkValidationAll = (saveData: any) => {
  //   const check1 = checkValidation1(saveData);

  //   if (!check1) {
  //     if (tagsData != 3) {
  //       setTagsData(tagsData + 1);
  //     }
  //   }
  // };

  const checkValidation1 = (saveData: any) => {
    if (saveData.name === "" || saveData.country_id === "") {
      setPage1Validation(true);
      return true;
    } else {
      if (Number(tagsData) === 1) {
        setTagsData(tagsData + 1);
        setIsSubmit(false);
      }
      if (Number(nextTagsData) === 1) {
        setNextTagsData(nextTagsData + 1);
        setIsSubmit(false);
      }
      setPage1Validation(false);
      return false;
    }
  };
  const checkValidation2 = (saveData: any) => {
    const data = saveData?.working_hour_data?.working_hours?.find(
      (work: any) => work.type == ""
    );
    const days = saveData?.working_hour_data?.working_days?.find(
      (work: any) => work.is_working_day == 1
    );
    // console.log(
    //   "location",
    //   saveData?.working_hour_data?.standard_shift_locations?.length
    // );
    // console.log("hour", data);
    // console.log("day", days);
    // console.log(
    //   "final",
    //   saveData?.working_hour_data?.standard_shift_locations?.length == 0 ||
    //     data ||
    //     days == undefined
    // );

    if (
      saveData?.working_hour_data?.standard_shift_locations?.length == 0 ||
      data ||
      days == undefined
    ) {
      setPage2Validation(true);
      return true;
    } else {
      if (tagsData == 2) {
        setTagsData(tagsData + 1);
      }
      if (Number(nextTagsData) === 2) {
        setNextTagsData(nextTagsData + 1);
        setIsSubmit(false);
      }
      setPage2Validation(false);

      return false;
    }

    // if(workingHours)
  };
  const checkValidation3 = (saveData: any) => {
    if (
      saveData.working_hour_data.customise_shift_items[0]?.is_working_day !==
        1 ||
      saveData.working_hour_data.customise_shift_items[0]?.working_hours[0]
        ?.location_id === "" ||
      (saveData.working_hour_data.customise_shift_items[0]?.working_hours[0]
        ?.type === "" &&
        saveData.working_hour_data.customise_shift_items[1]?.is_working_day !==
          1) ||
      saveData.working_hour_data.customise_shift_items[1]?.working_hours[0]
        ?.location_id === "" ||
      (saveData.working_hour_data.customise_shift_items[1]?.working_hours[0]
        ?.type === "" &&
        saveData.working_hour_data.customise_shift_items[2]?.is_working_day !==
          1) ||
      saveData.working_hour_data.customise_shift_items[2]?.working_hours[0]
        ?.location_id === "" ||
      (saveData.working_hour_data.customise_shift_items[2]?.working_hours[0]
        ?.type === "" &&
        saveData.working_hour_data.customise_shift_items[3]?.is_working_day !==
          1) ||
      saveData.working_hour_data.customise_shift_items[3]?.working_hours[0]
        ?.location_id === "" ||
      (saveData.working_hour_data.customise_shift_items[3]?.working_hours[0]
        ?.type === "" &&
        saveData.working_hour_data.customise_shift_items[4]?.is_working_day !==
          1) ||
      saveData.working_hour_data.customise_shift_items[4]?.working_hours[0]
        ?.location_id === "" ||
      (saveData.working_hour_data.customise_shift_items[4]?.working_hours[0]
        ?.type === "" &&
        saveData.working_hour_data.customise_shift_items[5]?.is_working_day !==
          1) ||
      saveData.working_hour_data.customise_shift_items[5]?.working_hours[0]
        ?.location_id === "" ||
      (saveData.working_hour_data.customise_shift_items[5]?.working_hours[0]
        ?.type === "" &&
        saveData.working_hour_data.customise_shift_items[6]?.is_working_day !==
          1) ||
      saveData.working_hour_data.customise_shift_items[6]?.working_hours[0]
        ?.location_id === "" ||
      saveData.working_hour_data.customise_shift_items[6]?.working_hours[0]
        ?.type === ""
    ) {
      // console.log("true");
      return true;
    } else {
      // console.log("next");

      if (tagsData == 2) {
        setTagsData(tagsData + 1);
      }
      if (Number(nextTagsData) === 2) {
        setNextTagsData(nextTagsData + 1);
        setIsSubmit(false);
      }
      return false;
    }
  };

  const checkValidation4Daily = (saveData: any) => {
    if (
      saveData.working_hour_data.flexible_shift_locations?.length === 0 ||
      (saveData.working_hour_data.flexible_shift_items[0]?.is_working_day !==
        1 &&
        saveData.working_hour_data.flexible_shift_items[1]?.is_working_day !==
          1 &&
        saveData.working_hour_data.flexible_shift_items[2]?.is_working_day !==
          1 &&
        saveData.working_hour_data.flexible_shift_items[3]?.is_working_day !==
          1 &&
        saveData.working_hour_data.flexible_shift_items[4]?.is_working_day !==
          1 &&
        saveData.working_hour_data.flexible_shift_items[5]?.is_working_day !==
          1 &&
        saveData.working_hour_data.flexible_shift_items[6]?.is_working_day !==
          1)
    ) {
      // console.log("true");
      return true;
    } else {
      // console.log("next");

      if (tagsData == 2) {
        setTagsData(tagsData + 1);
      }
      if (Number(nextTagsData) === 2) {
        setNextTagsData(nextTagsData + 1);
        setIsSubmit(false);
      }
      return false;
    }
  };

  const [multiFlexibleDaily, setMultiFlexibleDaily] = useState<string[]>([]);
  const [multiFlexibleDailyID, setMultiFlexibleDailyID] = useState<any[]>([]);
  // console.log("daily", multiFlexibleDaily);
  // console.log("idss", multiFlexibleDailyID);

  const handleSubmit = async () => {
    setIsSubmit(true);
    setUploading(true);
    const workingHours = workingList?.map((work: any) => {
      // const firstHour = Number(String(work.firstClock).split(":")[0]);
      // const firstMinute = Number(String(work.firstClock).split(":")[1]);
      // const secondHour = Number(String(work.secondClock).split(":")[0]);
      // const secondMinute = Number(String(work.secondClock).split(":")[1]);
      // console.log("first", { firstHour, firstMinute });
      // console.log("second", { secondHour, secondMinute });

      return {
        type: work.location,
        start: work.firstClock,
        end: work.secondClock,

        // start:
        //   firstHour === 0
        //     ? `24:${firstMinute < 10 ? `0${firstMinute}` : firstMinute}:00`
        //     : work.firstClock,
        // end:
        //   secondHour === 0
        //     ? `24:${secondMinute < 10 ? `0${secondMinute}` : secondMinute}:00`
        //     : work.secondClock,
      };
    });

    // console.log("yyyyyy", workingHours);

    const isSelectDay = workingDay?.map((work: any) => {
      let sameWork = employeeData?.find((employee: any) => employee === work);
      if (sameWork) {
        return {
          day: sameWork,
          is_working_day: 1,
        };
      } else {
        return {
          day: work,
          is_working_day: 0,
        };
      }
    });
    // console.log("isSelectDay", isSelectDay);
    const mondayWorkingHours = workingShiftMonday.map((monday: any) => {
      return {
        location_id: monday.location,
        type: monday.status,
        start: monday.firstClock,
        end: monday.secondClock,
      };
    });
    const tuesdayWorkingHours = workingShiftTuesday.map((monday: any) => {
      return {
        location_id: monday.location,
        type: monday.status,
        start: monday.firstClock,
        end: monday.secondClock,
      };
    });
    const wednesdayWorkingHours = workingShiftWednesday.map((monday: any) => {
      return {
        location_id: monday.location,
        type: monday.status,
        start: monday.firstClock,
        end: monday.secondClock,
      };
    });
    const thursdayWorkingHours = workingShiftThursday.map((monday: any) => {
      return {
        location_id: monday.location,
        type: monday.status,
        start: monday.firstClock,
        end: monday.secondClock,
      };
    });
    const firdayWorkingHours = workingShiftFriday.map((monday: any) => {
      return {
        location_id: monday.location,
        type: monday.status,
        start: monday.firstClock,
        end: monday.secondClock,
      };
    });
    const saturdayWorkingHours = workingShiftSaturday.map((monday: any) => {
      return {
        location_id: monday.location,
        type: monday.status,
        start: monday.firstClock,
        end: monday.secondClock,
      };
    });
    const sundayWorkingHours = workingShiftSunday.map((monday: any) => {
      return {
        location_id: monday.location,
        type: monday.status,
        start: monday.firstClock,
        end: monday.secondClock,
      };
    });

    //flexible

    const mondayDataFlexible = {
      day: String(mondayFlexible[0]["weekEndName"]),
      is_working_day: Boolean(mondayFlexible[0]["isChecked"]) == true ? 1 : 0,
      hours:
        mondayFlexible[0]["isChecked"] == true
          ? String(mondayFlexible[0]["clock"])
          : "",
    };

    const tuesdayDataFlexible = {
      day: String(tuesdayFlexible[0]["weekEndName"]),
      is_working_day: Boolean(tuesdayFlexible[0]["isChecked"]) == true ? 1 : 0,
      hours:
        tuesdayFlexible[0]["isChecked"] == true
          ? String(tuesdayFlexible[0]["clock"])
          : "",
    };

    const wednesdayDataFlexible = {
      day: String(wednesdayFlexible[0]["weekEndName"]),
      is_working_day:
        Boolean(wednesdayFlexible[0]["isChecked"]) == true ? 1 : 0,
      hours:
        wednesdayFlexible[0]["isChecked"] == true
          ? String(wednesdayFlexible[0]["clock"])
          : "",
    };

    const thursdayDataFlexible = {
      day: String(thursdayFlexible[0]["weekEndName"]),
      is_working_day: Boolean(thursdayFlexible[0]["isChecked"]) == true ? 1 : 0,
      hours:
        thursdayFlexible[0]["isChecked"] == true
          ? String(thursdayFlexible[0]["clock"])
          : "",
    };

    const fridayDataFlexible = {
      day: String(fridayFlexible[0]["weekEndName"]),
      is_working_day: Boolean(fridayFlexible[0]["isChecked"]) == true ? 1 : 0,
      hours:
        fridayFlexible[0]["isChecked"] == true
          ? String(fridayFlexible[0]["clock"])
          : "",
    };

    const saturdayDataFlexible = {
      day: String(saturdayFlexible[0]["weekEndName"]),
      is_working_day: Boolean(saturdayFlexible[0]["isChecked"]) == true ? 1 : 0,
      hours:
        saturdayFlexible[0]["isChecked"] == true
          ? String(saturdayFlexible[0]["clock"])
          : "",
    };

    const sundayDataFlexible = {
      day: String(sundayFlexible[0]["weekEndName"]),
      is_working_day: Boolean(sundayFlexible[0]["isChecked"]) == true ? 1 : 0,
      hours:
        sundayFlexible[0]["isChecked"] == true
          ? String(sundayFlexible[0]["clock"])
          : "",
    };

    //end flexible
    const mondayData = {
      day: String(mondayWeekend[0]["weekEndName"]),
      is_working_day: Boolean(mondayWeekend[0]["isChecked"]) == true ? 1 : 0,
      working_hours:
        mondayWeekend[0]["isChecked"] == true ? [...mondayWorkingHours] : [],
    };

    const tuesdayData = {
      day: String(tuesdayWeekend[0]["weekEndName"]),
      is_working_day: Boolean(tuesdayWeekend[0]["isChecked"]) == true ? 1 : 0,
      working_hours:
        tuesdayWeekend[0]["isChecked"] == true ? [...tuesdayWorkingHours] : [],
    };

    const wednesdayData = {
      day: String(wendesdayWeekend[0]["weekEndName"]),
      is_working_day: Boolean(wendesdayWeekend[0]["isChecked"]) == true ? 1 : 0,
      working_hours:
        wendesdayWeekend[0]["isChecked"] == true
          ? [...wednesdayWorkingHours]
          : [],
    };

    const thursdayData = {
      day: String(thursdayWeekend[0]["weekEndName"]),
      is_working_day: Boolean(thursdayWeekend[0]["isChecked"]) == true ? 1 : 0,
      working_hours:
        thursdayWeekend[0]["isChecked"] == true
          ? [...thursdayWorkingHours]
          : [],
    };
    const fridayData = {
      day: String(firdayWeekend[0]["weekEndName"]),
      is_working_day: Boolean(firdayWeekend[0]["isChecked"]) == true ? 1 : 0,
      working_hours:
        firdayWeekend[0]["isChecked"] == true ? [...firdayWorkingHours] : [],
    };
    const saturdayData = {
      day: String(saturdayWeekend[0]["weekEndName"]),
      is_working_day: Boolean(saturdayWeekend[0]["isChecked"]) == true ? 1 : 0,
      working_hours:
        saturdayWeekend[0]["isChecked"] == true
          ? [...saturdayWorkingHours]
          : [],
    };
    const sundayData = {
      day: String(sundayWeekend[0]["weekEndName"]),
      is_working_day: Boolean(sundayWeekend[0]["isChecked"]) == true ? 1 : 0,
      working_hours:
        sundayWeekend[0]["isChecked"] == true ? [...sundayWorkingHours] : [],
    };

    // const flexibleDaily =
    const multSelect = multiLocationsID?.map((mult: any) => mult.id);

    let finalHour: any;

    if (fixWorkHour === "standard") {
      finalHour = `${standardHour}:${standardMinute}:${"00"}`;
    } else if (fixWorkHour === "customise") {
      finalHour = `${totalWorkingHour}:${totalWorkingMinute}:${"00"}`;
    } else {
      if (flexibleState === "daily") {
        finalHour = `${totalFlexibleHour}:${totalFlexibleMinute}:${"00"}`;
      } else {
        finalHour = `${weeklyHours}:${"00"}:${"00"}`;
      }
    }
    // console.log("final", finalHour);

    const saveData = {
      name,
      country_id: region,
      remark: remarkInput.value,

      shift_timing: fixWorkHour,
      total_hours: finalHour,
      working_hour_data: {
        standard_shift_locations: [...multSelect],
        working_hours: workingHours?.length ? [...workingHours] : [],
        working_days: [...isSelectDay],
        //customise
        customise_shift_items: [
          mondayData,
          tuesdayData,
          wednesdayData,
          thursdayData,
          fridayData,
          saturdayData,
          sundayData,
        ],

        //flexible daily
        schedule_type: flexibleState,
        start: flexibleDaily[0]["firstClock"],
        end: flexibleDaily[0]["secondClock"],
        flexible_shift_locations: multiFlexibleDailyID.map(
          (location: any) => location.id
        ),
        //flexible weekly
        total_hours: Number(weeklyHours),
        // flexible daily
        flexible_shift_items: [
          mondayDataFlexible,
          tuesdayDataFlexible,
          wednesdayDataFlexible,
          thursdayDataFlexible,
          fridayDataFlexible,
          saturdayDataFlexible,
          sundayDataFlexible,
        ],
      },
      employee_ids: [...selectedMemberData],
    };

    // console.log("data", saveData);

    // const checkAll = checkValidationAll(saveData);
    const check1 = checkValidation1(saveData);
    const check2 = checkValidation2(saveData);
    const check3 = checkValidation3(saveData);
    const check4 = checkValidation4Daily(saveData);

    // console.log("check1", check1);
    // console.log("check2", check2);

    if (nextTagsData === 3) {
      //nextTagsData === 2 ||
      if (editedData && !isDuplicate) {
        updateWorkingShift({ ...saveData, id: editedData?.id })
          .unwrap()
          .then((payload: any) => {
            setErrorMsg("");
            setUploading(false);
            setEditData("");
            setIsSubmit(false);
            clearStateData();
            // console.log("edit", payload);
            setIsOpen(false);
            clearData();
            setTagsData(1);
            ResuableToasty({
              message: "Successfully Updated",
              position: "top-right",
              type: "success",
            });
          })
          .catch((error: any) => {
            // console.log("error", error);
            setIsSubmit(true);
            setUploading(false);
            setErrorMsg(error?.data?.message);
          });
      } else {
        saveWorkingShift(saveData)
          .unwrap()
          .then((payload: any) => {
            // console.log("p", payload);
            setEditData("");
            setErrorMsg("");
            setUploading(false);
            setIsSubmit(false);
            setIsOpen(false);
            clearData();
            setTagsData(1);
            clearStateData();
            setFixWorkHour("standard");
            ResuableToasty({
              message: "Successfully Saved",
              position: "top-right",
              type: "success",
            });
          })
          .catch((error: any) => {
            setIsSubmit(true);
            setUploading(false);
            setErrorMsg(error?.data?.message);
          });
      }
    } else {
      setUploading(false);
    }
  };

  useEffect(() => {
    if (editedData) {
      setName(editedData?.name);
      setShiftID(String(editedData?.code));
      setRegion(String(editedData?.country?.id));
      remarkInput.setValue(editedData.remark);
      setMultLocations(
        editedData?.workingLocations?.map((work: any) => work.name)
      );
      setMultiLocationsID(
        editedData?.workingLocations?.map((work: any) => work)
      );
      if (editedData?.working_hour_data?.working_hours) {
        setWorkingList(
          editedData?.working_hour_data?.working_hours?.map(
            (work: any, i: any) => {
              return {
                key: i * Math.random() * 100,
                firstClock: work.start,
                secondClock: work.end,
                location: work.type,
              };
            }
          )
        );
      }
      // setExistingMember([...editedData.employees])
      setSelectedMember(editedData?.employees?.map((data: any) => data.id));
      if (editedData?.working_hour_data?.working_days) {
        let employeeList: any = [];
        editedData?.working_hour_data?.working_days?.forEach((data: any) => {
          if (data.is_working_day) {
            employeeList.push(data.day);
          }
        });
        setEmployeeData(employeeList);
      }

      if (editedData?.working_hour_data?.customise_shift_items) {
        setMondayWeekend([
          {
            id: 0,
            isChecked:
              editedData?.working_hour_data?.customise_shift_items[0][
                "is_working_day"
              ] ?? false,
            text: "",
            weekEndName: "Monday",
          },
        ]);

        setTuesdayWeekend([
          {
            id: 1,
            isChecked:
              editedData?.working_hour_data?.customise_shift_items[1][
                "is_working_day"
              ],
            text: "",
            weekEndName: "Tuesday",
          },
        ]);
        setWednesdayWeekend([
          {
            id: 2,
            isChecked:
              editedData?.working_hour_data?.customise_shift_items[2][
                "is_working_day"
              ],
            text: "",
            weekEndName: "Wednesday",
          },
        ]);
        setThursdayWeekend([
          {
            id: 3,
            isChecked:
              editedData?.working_hour_data?.customise_shift_items[3][
                "is_working_day"
              ],
            text: "",
            weekEndName: "Thursday",
          },
        ]);
        setFirdayWeekend([
          {
            id: 4,
            isChecked:
              editedData?.working_hour_data?.customise_shift_items[4][
                "is_working_day"
              ],
            text: "",
            weekEndName: "Friday",
          },
        ]);
        setSaturdayWeekend([
          {
            id: 5,
            isChecked:
              editedData?.working_hour_data?.customise_shift_items[5][
                "is_working_day"
              ],
            text: "",
            weekEndName: "Saturday",
          },
        ]);
        setSundayWeekend([
          {
            id: 6,
            isChecked:
              editedData?.working_hour_data?.customise_shift_items[6][
                "is_working_day"
              ],
            text: "",
            weekEndName: "Sunday",
          },
        ]);
        const mondayCustomise =
          editedData?.working_hour_data?.customise_shift_items[0]?.working_hours.map(
            (data: any) => {
              return {
                firstClock: data.start,
                location: data.location_id,
                secondClock: data.end,
                key: Math.random() * 100,
                status: data.type,
              };
            }
          );
        setWorkingShiftMonday([...mondayCustomise]);

        const tuesdayCustomise =
          editedData?.working_hour_data?.customise_shift_items[1]?.working_hours.map(
            (data: any) => {
              return {
                firstClock: data.start,
                location: data.location_id,
                secondClock: data.end,
                key: Math.random() * 100,
                status: data.type,
              };
            }
          );
        setWorkingShiftTuesday([...tuesdayCustomise]);

        const wednesdayCustomise =
          editedData?.working_hour_data?.customise_shift_items[2]?.working_hours.map(
            (data: any) => {
              return {
                firstClock: data.start,
                location: data.location_id,
                secondClock: data.end,
                key: Math.random() * 100,
                status: data.type,
              };
            }
          );
        setWorkingShiftWednesday([...wednesdayCustomise]);

        const thursdayCustomise =
          editedData?.working_hour_data?.customise_shift_items[3]?.working_hours.map(
            (data: any) => {
              return {
                firstClock: data.start,
                location: data.location_id,
                secondClock: data.end,
                key: Math.random() * 100,
                status: data.type,
              };
            }
          );
        setWorkingShiftThursday([...thursdayCustomise]);

        const fridayCustomise =
          editedData?.working_hour_data?.customise_shift_items[4]?.working_hours.map(
            (data: any) => {
              return {
                firstClock: data.start,
                location: data.location_id,
                secondClock: data.end,
                key: Math.random() * 100,
                status: data.type,
              };
            }
          );
        setWorkingShiftFriday([...fridayCustomise]);

        const saturdayCustomise =
          editedData?.working_hour_data?.customise_shift_items[5]?.working_hours.map(
            (data: any) => {
              return {
                firstClock: data.start,
                location: data.location_id,
                secondClock: data.end,
                key: Math.random() * 100,
                status: data.type,
              };
            }
          );
        setWorkingShiftSaturday([...saturdayCustomise]);

        const sundayCustomise =
          editedData?.working_hour_data?.customise_shift_items[6]?.working_hours.map(
            (data: any) => {
              return {
                firstClock: data.start,
                location: data.location_id,
                secondClock: data.end,
                key: Math.random() * 100,
                status: data.type,
              };
            }
          );
        setWorkingShiftSunday([...sundayCustomise]);
      }

      // if(editedData?.working_hour_data?.)
      // const flexibleData = editedData?.working_hour_data?.
      if (editedData?.working_hour_data?.flexible_shift_locations) {
        let locationArray: any = [];
        let locationArrayName: any = [];
        var uniqueResultOne =
          editedData?.working_hour_data?.flexible_shift_locations.filter(
            function (obj: any) {
              return !locationList.some(function (obj2: any) {
                if (obj == obj2.id) {
                  locationArray.push(obj2);
                  locationArrayName.push(obj2.name);
                  return obj;
                }
                return;
              });
            }
          );

        setMultiFlexibleDailyID(locationArray);
        setMultiFlexibleDaily(locationArrayName);
      }

      setFlexibleDaily([
        {
          key: Math.random() * 100,
          firstClock: editedData?.working_hour_data?.start,
          secondClock: editedData?.working_hour_data?.end,
          location: "",
        },
      ]);

      if (editedData?.working_hour_data?.flexible_shift_items) {
        setMondayFlexible([
          {
            id: 0,
            clock:
              editedData?.working_hour_data?.flexible_shift_items[0]?.hours ??
              "00:00:00",
            isChecked:
              editedData?.working_hour_data?.flexible_shift_items[0]
                ?.is_working_day,
            text: "Working Hours ",
            weekEndName: "Monday",
          },
        ]);
        setTuesdayFlexible([
          {
            id: 1,
            clock:
              editedData?.working_hour_data?.flexible_shift_items[1]?.hours ??
              "00:00:00",
            isChecked:
              editedData?.working_hour_data?.flexible_shift_items[1]
                ?.is_working_day,
            text: "Working Hours ",
            weekEndName: "Tuesday",
          },
        ]);
        setWednesdayFlexible([
          {
            id: 2,
            clock:
              editedData?.working_hour_data?.flexible_shift_items[2]?.hours ??
              "00:00:00",
            isChecked:
              editedData?.working_hour_data?.flexible_shift_items[2]
                ?.is_working_day,
            text: "Working Hours ",
            weekEndName: "Wednesday",
          },
        ]);
        setThursdayFlexible([
          {
            id: 3,
            clock:
              editedData?.working_hour_data?.flexible_shift_items[3]?.hours ??
              "00:00:00",
            isChecked:
              editedData?.working_hour_data?.flexible_shift_items[3]
                ?.is_working_day,
            text: "Working Hours ",
            weekEndName: "Thursday",
          },
        ]);
        setFridayFlexible([
          {
            id: 4,
            clock:
              editedData?.working_hour_data?.flexible_shift_items[4]?.hours ??
              "00:00:00",
            isChecked:
              editedData?.working_hour_data?.flexible_shift_items[4]
                ?.is_working_day,
            text: "Working Hours ",
            weekEndName: "Friday",
          },
        ]);
        setSaturdayFlexible([
          {
            id: 5,
            clock:
              editedData?.working_hour_data?.flexible_shift_items[5]?.hours ??
              "00:00:00",
            isChecked:
              editedData?.working_hour_data?.flexible_shift_items[5]
                ?.is_working_day,
            text: "Working Hours ",
            weekEndName: "Saturday",
          },
        ]);
        setSundayFlexible([
          {
            id: 6,
            clock:
              editedData?.working_hour_data?.flexible_shift_items[6]?.hours ??
              "00:00:00",
            isChecked:
              editedData?.working_hour_data?.flexible_shift_items[6]
                ?.is_working_day,
            text: "Working Hours ",
            weekEndName: "Sunday",
          },
        ]);
      }
    }
  }, [editedData]);

  const handleClickTag = (id: any) => {
    setTagsData(id);
  };

  // const assignedUsers = (selectedMember: number[]) => {};

  useEffect(() => {
    if (globalSearch != "") {
      var data = employeeList?.filter((x) =>
        x.name.toUpperCase().includes(globalSearch.toUpperCase())
      );
      // console.log("serch", data);
      setTotalEmployeeLength(data?.length ?? 0);
      setEmployeeListData(data);
      setEmployeeListData2([]);
    } else {
      // setEmployeeListData(employeeList);
      const matchingObjects = employeeList?.filter((obj1) =>
        existingMember?.some((obj2: any) => obj2.id === obj1.id)
      );
      const nonMatchingObjects = employeeList
        ?.filter(
          (obj1) => !existingMember?.some((obj2: any) => obj2.id === obj1.id)
        )
        .slice(0, Number(employeeList.length) / 2);
      const nonMatchingObjects2 = employeeList
        ?.filter(
          (obj1) => !existingMember?.some((obj2: any) => obj2.id === obj1.id)
        )
        .slice(Number(employeeList.length) / 2);

      if (matchingObjects && nonMatchingObjects) {
        setEmployeeListData([...matchingObjects, ...nonMatchingObjects]);
        setEmployeeListData2([
          ...matchingObjects,
          ...(nonMatchingObjects2 ?? []),
        ]);
      }
    }
  }, [selectedMember, employeeList, globalSearch]);

  useEffect(() => {
    if (employeeList) {
      setTotalEmployeeLength(employeeList.length);

      const matchingObjects = existingMember?.filter((obj1: any) =>
        employeeList?.some((obj2) => obj2.id === obj1.id)
      );
      const nonMatchingObjects = employeeList
        .filter(
          (obj1) => !existingMember?.some((obj2: any) => obj2.id === obj1.id)
        )
        .slice(0, Number(employeeList.length) / 2);
      const nonMatchingObjects2 = employeeList
        .filter(
          (obj1) => !existingMember?.some((obj2: any) => obj2.id === obj1.id)
        )
        .slice(Number(employeeList.length) / 2);
      if (matchingObjects && nonMatchingObjects) {
        setEmployeeListData([...matchingObjects, ...nonMatchingObjects]);
        setEmployeeListData2([...matchingObjects, ...nonMatchingObjects2]);
      }
      setEmpList(employeeList);
    }
  }, [employeeList]);

  useEffect(() => {
    const filter = employeeList?.filter(
      (employee: any) => employee.department_id === departmentID
    );
    setEmployeeListData(filter);
    setEmployeeListData2([]);
  }, [departmentID]);

  const setSelectedCount = (id: number, isChosen: boolean) => {
    var alreadySelected = selectedMember.find((x) => x == id);
    var allData = [];
    if (!alreadySelected) {
      allData = [...selectedMember, id];
      setSelectedMemberData([...selectedMember, id]);
      setSelectedMember([...selectedMember, id]);
    } else {
      allData = selectedMember.filter((x) => x != id);
      setSelectedMember(selectedMember.filter((x) => x != id));
      setSelectedMemberData(selectedMember.filter((x) => x != id));
    }
    if (allData.length == empList.length) {
      setIsSelectedAll(true);
    } else {
      setIsSelectedAll(false);
    }
  };

  const setClearAllSelectedData = () => {
    setSelectedMember([]);
    setIsSelectedAll(false);
    setIsClear(true);
  };

  const setSelectedAll = () => {
    var isAll = !isSelectedAll;
    setIsSelectedAll(isAll);
    if (isAll) {
      setSelectedMember(empList.map((x: any) => x.id));
    } else {
      setSelectedMember([]);
    }
  };

  const handleAddWorkingList = () => {
    setWorkingList([
      ...workingList,
      { ...workingObject, key: Math.random() * 100 },
    ]);
  };

  // const handleAddWorkingCustomise = () => {
  //   setWorkingListCustomise([
  //     ...workingListCustomise,
  //     { ...workingCustomiseObject, key: Math.random() * 100 },
  //   ]);
  // };

  const handleAddWorkingWednesday = () => {
    setWorkingShiftWednesday([
      ...workingShiftWednesday,
      { ...workingObjectWednesday, key: Math.random() * 100 },
    ]);
  };

  const handleAddWorkingThursday = () => {
    setWorkingShiftThursday([
      ...workingShiftThursday,
      { ...workingObjectThursday, key: Math.random() * 100 },
    ]);
  };

  const handleAddWorkingFriday = () => {
    setWorkingShiftFriday([
      ...workingShiftFriday,
      { ...workingObjectFriday, key: Math.random() * 100 },
    ]);
  };
  const handleAddWorkingSaturday = () => {
    setWorkingShiftSaturday([
      ...workingShiftSaturday,
      { ...workingObjectSaturday, key: Math.random() * 100 },
    ]);
  };
  const handleAddWorkingSunday = () => {
    setWorkingShiftSunday([
      ...workingShiftSunday,
      { ...workingObjectSunday, key: Math.random() * 100 },
    ]);
  };

  const handleDelete = (id: any) => {
    // console.log("id", id);
    const filterData = workingList?.filter((work: any) => work.key !== id);
    // console.log("filterData", filterData);
    setWorkingList(filterData);
  };

  const handleAddWorkingMonday = () => {
    setWorkingShiftMonday([
      ...workingShiftMonday,
      { ...workingObjectMonday, key: Math.random() * 100 },
    ]);
  };

  const handleDeleteMonday = (id: any) => {
    // console.log("id", id);
    const filterData = workingShiftMonday.filter(
      (work: any) => work.key !== id
    );
    // console.log("filterData", filterData);
    setWorkingShiftMonday(filterData);
  };

  const handleAddWorkingTuesday = () => {
    setWorkingShiftTuesday([
      ...workingShiftTuesday,
      { ...workingObjectTuesday, key: Math.random() * 100 },
    ]);
  };

  const handleDeleteTuesday = (id: any) => {
    const filterData = workingShiftTuesday.filter(
      (work: any) => work.key !== id
    );
    setWorkingShiftTuesday(filterData);
  };

  const handleDeleteWednesday = (id: any) => {
    const filterData = workingShiftWednesday.filter(
      (work: any) => work.key !== id
    );
    setWorkingShiftWednesday(filterData);
  };

  const handleDeleteThursday = (id: any) => {
    const filterData = workingShiftThursday.filter(
      (work: any) => work.key !== id
    );
    setWorkingShiftThursday(filterData);
  };

  const handleDeleteFriday = (id: any) => {
    const filterData = workingShiftFriday.filter(
      (work: any) => work.key !== id
    );
    setWorkingShiftFriday(filterData);
  };

  const handleDeleteSaturday = (id: any) => {
    const filterData = workingShiftSaturday.filter(
      (work: any) => work.key !== id
    );
    setWorkingShiftSaturday(filterData);
  };

  const handleDeleteSunday = (id: any) => {
    const filterData = workingShiftSunday.filter(
      (work: any) => work.key !== id
    );
    setWorkingShiftSunday(filterData);
  };

  const handleCheckListMonday = () => {
    setMondayWeekend((prev: any) => {
      return prev.map((data: any) => {
        return {
          ...data,
          isChecked: !data.isChecked,
        };
        // return data;
      });
    });
  };

  const handleCheckListTuesday = () => {
    setTuesdayWeekend((prev: any) => {
      return prev.map((data: any) => {
        return {
          ...data,
          isChecked: !data.isChecked,
        };
      });
    });
  };

  const handleCheckListWednesday = () => {
    setWednesdayWeekend((prev: any) => {
      return prev.map((data: any) => {
        return {
          ...data,
          isChecked: !data.isChecked,
        };
      });
    });
  };

  const handleCheckListThursday = () => {
    setThursdayWeekend((prev: any) => {
      return prev.map((data: any) => {
        return {
          ...data,
          isChecked: !data.isChecked,
        };
      });
    });
  };

  const handleCheckListFriday = () => {
    setFirdayWeekend((prev: any) => {
      return prev.map((data: any) => {
        return {
          ...data,
          isChecked: !data.isChecked,
        };
      });
    });
  };

  const handleCheckListSaturday = () => {
    setSaturdayWeekend((prev: any) => {
      return prev.map((data: any) => {
        return {
          ...data,
          isChecked: !data.isChecked,
        };
      });
    });
  };

  const handleCheckListSunday = () => {
    setSundayWeekend((prev: any) => {
      return prev.map((data: any) => {
        return {
          ...data,
          isChecked: !data.isChecked,
        };
      });
    });
  };

  //for flexible

  const handleFlexibleDailyMonday = () => {
    setMondayFlexible((prev: any) => {
      return prev.map((data: any) => {
        return {
          ...data,
          isChecked: !data.isChecked,
        };
      });
    });
  };

  const handleFlexibleDailyTuesday = () => {
    setTuesdayFlexible((prev: any) => {
      return prev.map((data: any) => {
        return {
          ...data,
          isChecked: !data.isChecked,
        };
      });
    });
  };

  const handleFlexibleDailyWednesday = () => {
    setWednesdayFlexible((prev: any) => {
      return prev.map((data: any) => {
        return {
          ...data,
          isChecked: !data.isChecked,
        };
      });
    });
  };
  const handleFlexibleDailyThursday = () => {
    setThursdayFlexible((prev: any) => {
      return prev.map((data: any) => {
        return {
          ...data,
          isChecked: !data.isChecked,
        };
      });
    });
  };

  const handleFlexibleDailyFriday = () => {
    setFridayFlexible((prev: any) => {
      return prev.map((data: any) => {
        return {
          ...data,
          isChecked: !data.isChecked,
        };
      });
    });
  };

  const handleFlexibleDailySaturday = () => {
    setSaturdayFlexible((prev: any) => {
      return prev.map((data: any) => {
        return {
          ...data,
          isChecked: !data.isChecked,
        };
      });
    });
  };

  const handleFlexibleDailySunday = () => {
    setSundayFlexible((prev: any) => {
      return prev.map((data: any) => {
        return {
          ...data,
          isChecked: !data.isChecked,
        };
      });
    });
  };

  const [mondayDailyHours, setMondayDailyHours] = useState<string>("");
  const [mondayDailyMinutes, setMondayDailyMinutes] = useState<string>("");
  const [mondayDailySecond, setMondayDailySecond] = useState<string>("");

  const [tuesdayDailyHours, setTuesdayDailyHours] = useState<string>("");
  const [tuesdayDailyMinutes, setTuesdayDailyMinutes] = useState<string>("");
  const [tuesdayDailySecond, setTuesdayDailySecond] = useState<string>("");

  const [wednesdayDailyHours, setWednesdayDailyHours] = useState<string>("");
  const [wednesdayDailyMinutes, setWednesdayDailyMinutes] =
    useState<string>("");
  const [wednesdayDailySecond, setWednesdayDailySecond] = useState<string>("");

  const [thursdayDailyHours, setThursdayDailyHours] = useState<string>("");
  const [thursdayDailyMinutes, setThursdayDailyMinutes] = useState<string>("");
  const [thursdayDailySecond, setThursdayDailySecond] = useState<string>("");

  const [fridayDailyHours, setFridayDailyHours] = useState<string>("");
  const [fridayDailyMinutes, setFridayDailyMinutes] = useState<string>("");
  const [fridayDailySecond, setFridayDailySecond] = useState<string>("");

  const [saturdayDailyHours, setSaturdayDailyHours] = useState<string>("");
  const [saturdayDailyMinutes, setSaturdayDailyMinutes] = useState<string>("");
  const [saturdayDailySecond, setSaturdayDailySecond] = useState<string>("");

  const [sundayDailyHours, setsundayDailyHours] = useState<string>("");
  const [sundayDailyMinutes, setSundayDailyMinutes] = useState<string>("");
  const [sundayDailySecond, setSundayDailySecond] = useState<string>("");

  useEffect(() => {
    if (mondayFlexible) {
      setMondayDailyHours(String(mondayFlexible[0]["clock"]).split(":")[0]);
      setMondayDailyMinutes(String(mondayFlexible[0]["clock"]).split(":")[1]);
      setMondayDailySecond(String(mondayFlexible[0]["clock"]).split(":")[2]);
    }
    if (tuesdayFlexible) {
      setTuesdayDailyHours(String(tuesdayFlexible[0]["clock"]).split(":")[0]);
      setTuesdayDailyMinutes(String(tuesdayFlexible[0]["clock"]).split(":")[1]);
      setTuesdayDailySecond(String(tuesdayFlexible[0]["clock"]).split(":")[2]);
    }
    if (wednesdayFlexible) {
      setWednesdayDailyHours(
        String(wednesdayFlexible[0]["clock"]).split(":")[0]
      );
      setWednesdayDailyMinutes(
        String(wednesdayFlexible[0]["clock"]).split(":")[1]
      );
      setWednesdayDailySecond(
        String(wednesdayFlexible[0]["clock"]).split(":")[2]
      );
    }
    if (thursdayFlexible) {
      setThursdayDailyHours(String(thursdayFlexible[0]["clock"]).split(":")[0]);
      setThursdayDailyMinutes(
        String(thursdayFlexible[0]["clock"]).split(":")[1]
      );
      setThursdayDailySecond(
        String(thursdayFlexible[0]["clock"]).split(":")[2]
      );
    }
    if (fridayFlexible) {
      setFridayDailyHours(String(fridayFlexible[0]["clock"]).split(":")[0]);
      setFridayDailyMinutes(String(fridayFlexible[0]["clock"]).split(":")[1]);
      setFridayDailySecond(String(fridayFlexible[0]["clock"]).split(":")[2]);
    }

    if (saturdayFlexible) {
      setSaturdayDailyHours(String(saturdayFlexible[0]["clock"]).split(":")[0]);
      setSaturdayDailyMinutes(
        String(saturdayFlexible[0]["clock"]).split(":")[1]
      );
      setSaturdayDailySecond(
        String(saturdayFlexible[0]["clock"]).split(":")[2]
      );
    }
    if (sundayFlexible) {
      setsundayDailyHours(String(sundayFlexible[0]["clock"]).split(":")[0]);
      setSundayDailyMinutes(String(sundayFlexible[0]["clock"]).split(":")[1]);
      setSundayDailySecond(String(sundayFlexible[0]["clock"]).split(":")[2]);
    }
  }, [
    mondayFlexible,
    tuesdayFlexible,
    wednesdayFlexible,
    thursdayFlexible,
    fridayFlexible,
    saturdayFlexible,
    sundayFlexible,
  ]);

  function onChangeDateStartMonday(value: any) {
    setMondayFlexible((prev: baseObj) => {
      return prev.map((data: any) => {
        return { ...data, clock: value && value.format(formatDaily) };
      });
    });
  }

  const handleClearFlexibleMonday = () => {
    setMondayFlexible((prev: baseObj) => {
      return prev.map((data: any) => {
        return { ...data, clock: "00:00:00" };
      });
    });
  };

  const handleClearFlexibleTuesday = () => {
    setTuesdayFlexible((prev: baseObj) => {
      return prev.map((data: any) => {
        return { ...data, clock: "00:00:00" };
      });
    });
  };

  const handleClearFlexibleWed = () => {
    setWednesdayFlexible((prev: baseObj) => {
      return prev.map((data: any) => {
        return { ...data, clock: "00:00:00" };
      });
    });
  };
  const handleClearFlexibleThurs = () => {
    setThursdayFlexible((prev: baseObj) => {
      return prev.map((data: any) => {
        return { ...data, clock: "00:00:00" };
      });
    });
  };
  const handleClearFlexibleFriday = () => {
    setFridayFlexible((prev: baseObj) => {
      return prev.map((data: any) => {
        return { ...data, clock: "00:00:00" };
      });
    });
  };
  const handleClearFlexibleSat = () => {
    setSaturdayFlexible((prev: baseObj) => {
      return prev.map((data: any) => {
        return { ...data, clock: "00:00:00" };
      });
    });
  };
  const handleClearFlexibleSun = () => {
    setSundayFlexible((prev: baseObj) => {
      return prev.map((data: any) => {
        return { ...data, clock: "00:00:00" };
      });
    });
  };

  const [totalFlexibleHour, setTotalFlexibleHour] = useState<number>(0);
  // console.log("aaa", totalFlexibleHour);

  const [totalFlexibleMinute, setTotalFlexibleMinute] = useState<number>(0);
  useEffect(() => {
    let dailyTotalHour: number = 0;
    let dailyTotalMinute: number = 0;

    // const hours_data = String(mondayFlexible[0]["clock"]).split(":")[0];
    // if(Number(hours_data)===0){
    //   dailyTotalHour+= 24;
    // }else{
    //   dailyTotalHour += Number(hours_data);
    // }

    const hoursDataMon = String(mondayFlexible[0]["clock"]).split(":")[0];
    const minuteDataMon = String(mondayFlexible[0]["clock"]).split(":")[1];
    dailyTotalHour += Number(hoursDataMon);
    dailyTotalMinute += Number(minuteDataMon);

    const hoursDataTue = String(tuesdayFlexible[0]["clock"]).split(":")[0];
    const minuteDataTue = String(tuesdayFlexible[0]["clock"]).split(":")[1];
    dailyTotalHour += Number(hoursDataTue);
    dailyTotalMinute += Number(minuteDataTue);

    const hoursDataWed = String(wednesdayFlexible[0]["clock"]).split(":")[0];
    const minuteDataWed = String(wednesdayFlexible[0]["clock"]).split(":")[1];
    dailyTotalHour += Number(hoursDataWed);
    dailyTotalMinute += Number(minuteDataWed);

    const hoursDataThurs = String(thursdayFlexible[0]["clock"]).split(":")[0];
    const minuteDataThurs = String(thursdayFlexible[0]["clock"]).split(":")[1];
    dailyTotalHour += Number(hoursDataThurs);
    dailyTotalMinute += Number(minuteDataThurs);

    const hoursDataFri = String(fridayFlexible[0]["clock"]).split(":")[0];
    const minuteDataFri = String(fridayFlexible[0]["clock"]).split(":")[1];
    dailyTotalHour += Number(hoursDataFri);
    dailyTotalMinute += Number(minuteDataFri);

    const hoursDataSat = String(saturdayFlexible[0]["clock"]).split(":")[0];
    const minuteDataSat = String(saturdayFlexible[0]["clock"]).split(":")[1];
    dailyTotalHour += Number(hoursDataSat);
    dailyTotalMinute += Number(minuteDataSat);

    const hoursDataSun = String(sundayFlexible[0]["clock"]).split(":")[0];
    const minuteDataSun = String(sundayFlexible[0]["clock"]).split(":")[1];
    dailyTotalHour += Number(hoursDataSun);
    dailyTotalMinute += Number(minuteDataSun);

    // console.log("dailyTotalHour", { dailyTotalHour, dailyTotalMinute });

    setTotalFlexibleHour(dailyTotalHour);
    setTotalFlexibleMinute(dailyTotalMinute);
  }, [
    mondayFlexible,
    tuesdayFlexible,
    wednesdayFlexible,
    thursdayFlexible,
    fridayFlexible,
    saturdayFlexible,
    sundayFlexible,
  ]);

  function onChangeDateStartTuesday(value: any) {
    setTuesdayFlexible((prev: baseObj) => {
      return prev.map((data: any) => {
        return { ...data, clock: value && value.format(formatDaily) };
      });
    });
  }

  function onChangeDateStartWednesday(value: any) {
    setWednesdayFlexible((prev: baseObj) => {
      return prev.map((data: any) => {
        return { ...data, clock: value && value.format(formatDaily) };
      });
    });
  }

  function onChangeDateStartThursday(value: any) {
    setThursdayFlexible((prev: baseObj) => {
      return prev.map((data: any) => {
        return { ...data, clock: value && value.format(formatDaily) };
      });
    });
  }

  function onChangeDateStartFriday(value: any) {
    setFridayFlexible((prev: baseObj) => {
      return prev.map((data: any) => {
        return { ...data, clock: value && value.format(formatDaily) };
      });
    });
  }

  function onChangeDateStartSaturday(value: any) {
    setSaturdayFlexible((prev: baseObj) => {
      return prev.map((data: any) => {
        return { ...data, clock: value && value.format(formatDaily) };
      });
    });
  }

  function onChangeDateStartSunday(value: any) {
    setSundayFlexible((prev: baseObj) => {
      return prev.map((data: any) => {
        return { ...data, clock: value && value.format(formatDaily) };
      });
    });
  }

  const clearStateData = () => {
    remarkInput.setValue("");
    setStandardHour(0);
    setStandardMinute(0);
    setTotalWorkingHour(0);
    setTotalWorkingMinute(0);
    setTotalFlexibleHour(0);
    setTotalFlexibleMinute(0);
    setWeeklyHours("00");
    setIsDuplicate(false);
    setIsCreate(false);
    setIsEdit(false);
    setNextTagsData(1);
    setTagsData(1);
    setEmployeeData([]);
    setEmployeeDataID([]);
    setMultiLocationsID([]);
    setMultLocations([]);
    setEmployeeData([]);
    setWorkingList([]);
    setName("");
    setShiftID("");
    setWorkingShiftMonday([]);
    setWorkingShiftTuesday([]);
    setWorkingShiftWednesday([]);
    setWorkingShiftThursday([]);
    setWorkingShiftFriday([]);
    setWorkingShiftSaturday([]);
    setWorkingShiftSunday([]);
    setMultiFlexibleDaily([]);
    setMultiFlexibleDailyID([]);
    setFixWorkHour("standard");
    setFlexibleState("daily");
    setClearAllSelectedData();
    setRegion("");
    setFlexibleDaily([
      {
        key: Math.random() * 100,
        location: "",
        firstClock: "00:00:00",
        firstAMPM: "AM",
        secondClock: "00:00:00",
        secondAMPM: "PM",
      },
    ]);
    setMondayFlexible((prev: baseObj) => {
      return prev.map((data: any) => {
        return { ...data, clock: "00:00:00" };
      });
    });

    setTuesdayFlexible((prev: baseObj) => {
      return prev.map((data: any) => {
        return { ...data, clock: "00:00:00" };
      });
    });

    setWednesdayFlexible((prev: baseObj) => {
      return prev.map((data: any) => {
        return { ...data, clock: "00:00:00" };
      });
    });

    setThursdayFlexible((prev: baseObj) => {
      return prev.map((data: any) => {
        return { ...data, clock: "00:00:00" };
      });
    });
    setFridayFlexible((prev: baseObj) => {
      return prev.map((data: any) => {
        return { ...data, clock: "00:00:00" };
      });
    });
    setSaturdayFlexible((prev: baseObj) => {
      return prev.map((data: any) => {
        return { ...data, clock: "00:00:00" };
      });
    });
    setSundayFlexible((prev: baseObj) => {
      return prev.map((data: any) => {
        return { ...data, clock: "00:00:00" };
      });
    });
  };

  return (
    <Modal
      className="project_popup"
      open={isOpen}
      style={{
        background: "rgb(0 0 0 / 0.5)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      onClose={(event, reason) => {
        if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
          setIsOpen(false);
          setEditData("");
          setIsSubmit(false);
          clearStateData();
        }
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Fade in={isOpen} timeout={500}>
        <div className="max-w-[1000px] w-full m-auto ">
          <div className="modalContent w-full h-full p-6 lg:p-0 flex justify-center items-center relative z-[999]">
            <div className="rounded-xl bg-white font-primary w-full">
              <div className="px-2 sm:pl-4 sm:pr-4 py-3 bg-vorpblue flex justify-between rounded-t-lg items-center relative z-[999]">
                <h3 className="text-18 leading-[27px] font-semibold text-white">
                  {isEditing ? "Update New Working Shift" : null}
                  {isCreate ? "Add New Working Shift" : null}
                  {isDuplicate ? "Duplicate Working Shift" : null}
                </h3>
                <button
                  key={Math.random() * 100}
                  type="button"
                  aria-label="Close Modal"
                  onClick={() => {
                    setIsOpen(false);
                    setIsSubmit(false);
                    setEditData("");
                    setUploading(false);
                    setErrorMsg("");
                    clearData();
                    clearStateData();
                  }}
                >
                  <CloseIcon />
                </button>
              </div>

              <div className="pb-3 flex justify-start items-center">
                <div className="flex flex-wrap">
                  <div className="flex px-3 justify-center items-center min-h-[20px] pt-5">
                    {tagsList.map((tag: any, i: number) => (
                      <div key={i} className="flex">
                        <button
                          className={classNames(
                            tagsData == tag.id
                              ? "border-b-[2px] pb-1 border-b-vorpblue text-vorpblue "
                              : "border-b-[2px] pb-1 border-b-white text-vorpgraylight ",
                            " text-[14px] font-semibold leading-3 not-italic"
                          )}
                          key={i}
                          onClick={() => {
                            handleClickTag(tag?.id);
                          }}
                        >
                          {tag.name}
                        </button>
                        <div
                          className={classNames(
                            tagsList.length - 1 === i ? "hidden" : "",
                            "px-2"
                          )}
                        >
                          <div className="h-5 w-[1px] bg-vorpgraylight mx-1 "></div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              {tagsData === 1 ? (
                <>
                  <div className="flex justify-between w-full ">
                    <div className="mb-0 last:mb-0 w-full px-3">
                      <label className="text-black2 text-15 font-medium font-primary mt-0 pb-2 block">
                        Shift ID<span className=" text-red-500">*</span>
                      </label>
                      <input
                        value={shiftId}
                        onChange={(e) => setShiftID(e.target.value)}
                        type="text"
                        placeholder="Generated by system"
                        className="focus:outline-none focus-visible:outline-none pointer-events-none opacity-30 w-full rounded py-3.5 px-3 border border-goalinputborder text-black2 text-14 font-medium font-primary placeholder:text-graydark h-[48px]"
                      ></input>
                    </div>
                    <div className="mb-0 last:mb-0 w-full px-3">
                      <label className="text-black2 text-15 font-medium font-primary mt-0 pb-2 block">
                        Shift Name<span className=" text-red-500">*</span>
                      </label>
                      <input
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        type="text"
                        placeholder="Name"
                        className="focus:outline-none focus-visible:outline-none w-full rounded py-3.5 px-3 border border-goalinputborder text-black2 text-14 font-medium font-primary placeholder:text-graydark h-[48px]"
                      ></input>
                      <p
                        className={classNames(
                          isSubmit && name == "" && Number(nextTagsData) === 1
                            ? ""
                            : "hidden",
                          "mb-2 font-poppins text-12 text-redPink"
                        )}
                      >
                        Name required
                      </p>
                    </div>
                  </div>

                  <div className="flex justify-between w-full ">
                    <div className="mb-0 last:mb-0 w-full px-3">
                      <label className="text-black2 text-15 font-medium font-primary mt-0 pb-2 block mb-0">
                        Region<span className=" text-red-500">*</span>
                      </label>
                      <StringDropdownList
                        state={region}
                        setState={setRegion}
                        placeholder="Select Region"
                        className="w-full py-3 pl-3 mb-0 text-14"
                        dropdownItems={regionList?.map((status: any) => ({
                          value: status.id,
                          label: status.name,
                        }))}
                        textAlign={"text-left"}
                        fontSize="xl:text-15 text-12"
                      />
                      <p
                        className={classNames(
                          isSubmit &&
                            region === "" &&
                            Number(nextTagsData) === 1
                            ? ""
                            : "hidden",
                          "mb-2 font-poppins text-12 text-redPink"
                        )}
                      >
                        Region is required
                      </p>
                    </div>
                  </div>

                  <div className="w-full mb-2">
                    <ResuableInput
                      label="Remarks"
                      className="w-full px-3"
                      textarea={true}
                      isRequired={false}
                      type="text"
                      placeHolder="Remarks"
                      {...remarkInput}
                    />
                  </div>
                </>
              ) : null}
              {tagsData === 2 ? (
                <>
                  <div className=" px-4 py-1 max-h-[350px] overflow-y-auto">
                    <div className="mb-5">
                      <h2 className="text-left text-15  text-black2 font-medium inline">
                        Shift Timings
                      </h2>
                      <span className=" text-red-500">*</span>
                    </div>
                    <div className="flex mb-3">
                      <div className="flex items-center">
                        <BaseRadioButton
                          state={fixWorkHour}
                          setState={setFixWorkHour}
                          label="Standard"
                          value="standard"
                          id="standard"
                          className="mr-1"
                          fontSize="xl:text-15 text-12"
                          name="claim"
                        />
                        <div className="text-center mr-2">
                          <Tooltip
                            PopperProps={{ disablePortal: true }}
                            style={{ zIndex: 999999999999 }}
                            arrow
                            className="customTooltip"
                            classes={{ tooltip: classes.tooltip }}
                            enterTouchDelay={1000}
                            leaveTouchDelay={5000}
                            placement="left"
                            title={
                              <>
                                <div className="text-white text-[10px] hover:cursor-pointer leading-[15px] z-[2] rounded p-1 w-full bg-tooltipbg">
                                  <span className="flex w-full justify-center h-full items-center">
                                    Standard Working Status
                                  </span>
                                </div>
                              </>
                            }
                          >
                            <div className="flex flex-wrap ">
                              <QuestionIcon width={16} height={17} />
                            </div>
                          </Tooltip>
                        </div>
                      </div>
                      <BaseRadioButton
                        state={fixWorkHour}
                        setState={setFixWorkHour}
                        label="Customise"
                        value="customise"
                        id="customise"
                        className="mr-4 sm:mr-8"
                        fontSize="xl:text-15 text-12"
                        name="claim"
                      />
                      <BaseRadioButton
                        state={fixWorkHour}
                        setState={setFixWorkHour}
                        label="Flexible"
                        value="flexible"
                        id="flexible"
                        className="mr-4 sm:mr-8"
                        fontSize="xl:text-15 text-12"
                        name="claim"
                      />
                    </div>
                    {fixWorkHour === "standard" ? (
                      <>
                        <div className="flex justify-between w-full">
                          <div className="mb-0 w-full">
                            <label className="text-black2 text-15 font-medium font-primary mb-1 block">
                              Work days
                            </label>
                            <div className="flex">
                              <div className="w-full">
                                <div className="mb-5">
                                  <CommonTagDropdown
                                    placeholder="Select"
                                    isCreate={false}
                                    showAll={true}
                                    state={employeeData ? employeeData : []}
                                    setState={setEmployeeData}
                                    setSelected={setEmployeeDataID}
                                    options={workingDay?.map((member) => {
                                      return {
                                        label: member,
                                        value: member,
                                      };
                                    })}
                                    className="border-goalinputborder 6xs:text-goalinputborder text-14"
                                  />
                                  <p
                                    className={classNames(
                                      isSubmit &&
                                        fixWorkHour === "standard" &&
                                        employeeData.length == 0 &&
                                        Number(nextTagsData) === 2
                                        ? ""
                                        : "hidden",
                                      "mb-2 font-poppins text-12 text-redPink"
                                    )}
                                  >
                                    Work day is required
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="mb-2 w-full px-3">
                            <label className="text-black2 text-15 font-medium font-primary block mb-1">
                              Location<span className=" text-red-500">*</span>
                            </label>
                            <div className="mb-5">
                              <CommonTagDropdown
                                placeholder="Select"
                                isCreate={false}
                                showAll={true}
                                state={multiLocations ? multiLocations : []}
                                setState={setMultLocations}
                                setSelected={setMultiLocationsID}
                                options={locationList?.map((member) => {
                                  return {
                                    label: member.name,
                                    value: member.id,
                                  };
                                })}
                                className="border-goalinputborder 6xs:text-goalinputborder text-14"
                              />
                              <p
                                className={classNames(
                                  isSubmit &&
                                    fixWorkHour === "standard" &&
                                    multiLocations.length == 0 &&
                                    Number(nextTagsData) === 2
                                    ? ""
                                    : "hidden",
                                  "mb-2 font-poppins text-12 text-redPink"
                                )}
                              >
                                Location is required
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-col w-full">
                          <label className="text-black2 text-15 font-medium font-primary mt-0 block mb-0 text-start">
                            Working Days
                            <span className=" text-red-500">*</span>
                          </label>
                          <div className="">
                            {workingList?.map((work: any, i: any) => (
                              <WorkingShiftList
                                key={i}
                                dayBeakWork={dayBeakWork}
                                work={work}
                                handleDelete={handleDelete}
                                setData={setWorkingList}
                                isSubmit={isSubmit}
                                nextTagsData={nextTagsData}
                              />
                            ))}
                          </div>
                        </div>
                        <div>
                          <span
                            onClick={handleAddWorkingList}
                            className="text-vorpblue cursor-pointer text-13 font-medium"
                          >
                            + ADD TIME
                          </span>
                        </div>
                        <div className="px-0 mt-4">
                          <span className="text-graydark text-15 font-medium pr-2">
                            Total Working Hours
                          </span>
                          <span className="text-15 txt-black2 font-semibold">
                            {standardHour + " hr " + standardMinute + " mm"}
                          </span>
                        </div>
                      </>
                    ) : null}
                    {fixWorkHour === "customise" ? (
                      <>
                        <div className="max-h-[400px] min-h-[300px] overflow-y-auto">
                          {mondayWeekend.map((monday: any, i: any) => (
                            <div
                              key={i}
                              className="flex w-full mb-2 flex-col border border-gray-300 rounded-md p-0 px-3 "
                            >
                              <div className="flex w-full justify-between">
                                <div className="mt-3 mr-3">
                                  <label
                                    htmlFor={`checklist_${monday.id}`}
                                    className={`flex items-center`}
                                  >
                                    <input
                                      type="checkbox"
                                      id={`checklist_${monday.id}`}
                                      className="hidden"
                                      onChange={handleCheckListMonday}
                                      checked={monday.isChecked}
                                    />
                                    <div
                                      className={`w-6 h-6 cursor-pointer rounded-4 flex justify-center items-center mr-3 ${
                                        monday.isChecked
                                          ? "bg-checkItemColor"
                                          : "border border-[#DCE6F0] bg-disabledColor"
                                      }`}
                                    >
                                      {monday.isChecked ? (
                                        <SelectedIcon width={9} height={7} />
                                      ) : null}
                                    </div>
                                  </label>
                                </div>
                                <div className="px-0 mt-3 text-left min-w-[110px]">
                                  <h3>{monday.weekEndName}</h3>
                                </div>
                                <div
                                  className={classNames(
                                    "mt-3 min-w-[685px] ",
                                    0 !== state.current || !state.isShowing
                                      ? ""
                                      : "hidden"
                                  )}
                                >
                                  {/* <p>{monday.text}</p> */}
                                  {workingShiftMonday.map(
                                    (data: any, i: any) => {
                                      const firstClock =
                                        data.firstClock !== "00:00:00"
                                          ? moment(
                                              data.firstClock,
                                              "HH:mm"
                                            ).format("h:mm A")
                                          : "00:00";
                                      const secondClock =
                                        data.secondClock !== "00:00:00"
                                          ? moment(
                                              data.secondClock,
                                              "HH:mm"
                                            ).format("h:mm A")
                                          : "00:00";

                                      return (
                                        <div key={i} className="inline">
                                          <span className="pr-2">
                                            {data.status ?? ""}
                                          </span>
                                          <span className="pr-2">
                                            {firstClock}
                                          </span>
                                          <span className="pr-2">:</span>
                                          <span className="pr-2">
                                            {secondClock}
                                          </span>
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                                <div
                                  className={classNames(
                                    styles.content,
                                    0 == state.current && state.isShowing
                                      ? styles.active
                                      : ""
                                  )}
                                >
                                  {0 == state.current && state.isShowing && (
                                    <div className="flex flex-col">
                                      <div className="min-w-[700px]">
                                        {workingShiftMonday.map(
                                          (work: any, i: any) => (
                                            <WorkingShiftCustomise
                                              key={i}
                                              dayBeakWork={dayBeakWork}
                                              work={work}
                                              handleDelete={handleDeleteMonday}
                                              setData={setWorkingShiftMonday}
                                              locationList={locationList ?? []}
                                              isSubmit={isSubmit}
                                            />
                                          )
                                        )}
                                      </div>
                                      <div className="pt-4">
                                        <span
                                          onClick={handleAddWorkingMonday}
                                          className="text-vorpblue cursor-pointer text-13 font-medium"
                                        >
                                          + ADD TIME
                                        </span>
                                      </div>
                                      <div className="px-0 my-4 flex justify-end">
                                        <span className="text-graydark text-15 font-medium pr-2">
                                          Total Working Hours
                                        </span>
                                        <span className="text-graydark text-15 font-medium">
                                          {mondayCustomiseHour +
                                            " hr " +
                                            mondayCustomiseMinute +
                                            " mm"}
                                        </span>
                                      </div>
                                    </div>
                                  )}
                                </div>
                                <div
                                  className="mt-3 w-8 h-10 cursor-pointer"
                                  onClick={() => {
                                    handleShow(0);
                                  }}
                                >
                                  {0 == state.current && state.isShowing ? (
                                    <UpOpenIcon />
                                  ) : (
                                    <DownCloseIcon />
                                  )}
                                </div>
                              </div>
                            </div>
                          ))}

                          {tuesdayWeekend.map((monday: any, i: any) => (
                            <div
                              key={i}
                              className="flex w-full mb-2 flex-col border border-gray-300 rounded-md p-0 px-3 "
                            >
                              <div className="flex w-full justify-between">
                                <div className="mt-3">
                                  <label
                                    htmlFor={`checklist_${monday.id}`}
                                    className={`flex items-center`}
                                  >
                                    <input
                                      type="checkbox"
                                      id={`checklist_${monday.id}`}
                                      className="hidden"
                                      onChange={handleCheckListTuesday}
                                      checked={monday.isChecked}
                                    />
                                    <div
                                      className={`w-6 h-6 rounded-4 cursor-pointer flex justify-center items-center mr-3 ${
                                        monday.isChecked
                                          ? "bg-checkItemColor"
                                          : "border border-[#DCE6F0] bg-disabledColor"
                                      }`}
                                    >
                                      {monday.isChecked ? (
                                        <SelectedIcon width={9} height={7} />
                                      ) : null}
                                    </div>
                                  </label>
                                </div>
                                <div className="px-0 mt-3 text-left min-w-[120px]">
                                  <h3>{monday.weekEndName}</h3>
                                </div>
                                <div
                                  className={classNames(
                                    "mt-3 min-w-[685px] ",
                                    1 !== state.current || !state.isShowing
                                      ? ""
                                      : "hidden"
                                  )}
                                >
                                  {/* <p>{monday.text}</p> */}
                                  {workingShiftTuesday.map(
                                    (data: any, i: any) => {
                                      const firstClock =
                                        data.firstClock !== "00:00:00"
                                          ? moment(
                                              data.firstClock,
                                              "HH:mm"
                                            ).format("h:mm A")
                                          : "00:00";
                                      const secondClock =
                                        data.secondClock !== "00:00:00"
                                          ? moment(
                                              data.secondClock,
                                              "HH:mm"
                                            ).format("h:mm A")
                                          : "00:00";

                                      return (
                                        <div key={i} className="inline">
                                          <span className="pr-2">
                                            {data.status ?? ""}
                                          </span>
                                          <span className="pr-2">
                                            {firstClock}
                                          </span>
                                          <span className="pr-2">:</span>
                                          <span className="pr-2">
                                            {secondClock}
                                          </span>
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                                <div
                                  className={classNames(
                                    styles.content,
                                    1 == state.current && state.isShowing
                                      ? styles.active
                                      : ""
                                  )}
                                >
                                  {1 == state.current && state.isShowing && (
                                    <div className="flex flex-col">
                                      <div className="min-w-[700px]">
                                        {/* max-h-[250px] min-h-[150px] overflow-y-auto */}
                                        {workingShiftTuesday.map(
                                          (work: any, i: any) => (
                                            <WorkingShiftCustomise
                                              key={i}
                                              dayBeakWork={dayBeakWork}
                                              work={work}
                                              handleDelete={handleDeleteTuesday}
                                              setData={setWorkingShiftTuesday}
                                              locationList={locationList ?? []}
                                              isSubmit={isSubmit}
                                            />
                                          )
                                        )}
                                      </div>
                                      <div className="pt-4">
                                        <span
                                          onClick={handleAddWorkingTuesday}
                                          className="text-vorpblue cursor-pointer text-13 font-medium"
                                        >
                                          + ADD TIME
                                        </span>
                                      </div>
                                      <div className="px-0 my-4 flex justify-end">
                                        <span className="text-graydark text-15 font-medium pr-2">
                                          Total Working Hours
                                        </span>
                                        <span className="text-graydark text-15 font-medium">
                                          {tuesdayCustomiseHour +
                                            " hr " +
                                            tuesdayCustomiseMinute +
                                            " mm"}
                                        </span>
                                      </div>
                                    </div>
                                  )}
                                </div>
                                <div
                                  className="mt-3 w-8 h-10 cursor-pointer"
                                  onClick={() => {
                                    handleShow(1);
                                  }}
                                >
                                  {1 == state.current && state.isShowing ? (
                                    <UpOpenIcon />
                                  ) : (
                                    <DownCloseIcon />
                                  )}
                                </div>
                              </div>
                            </div>
                          ))}

                          {wendesdayWeekend.map((day: any, i: any) => (
                            <div
                              key={i}
                              className="flex w-full mb-2 flex-col border border-gray-300 rounded-md p-0 px-3 "
                            >
                              <div className="flex w-full justify-between">
                                <div className="mt-3">
                                  <label
                                    htmlFor={`checklist_${day.id}`}
                                    className={`flex items-center`}
                                  >
                                    <input
                                      type="checkbox"
                                      id={`checklist_${day.id}`}
                                      className="hidden"
                                      onChange={handleCheckListWednesday}
                                      checked={day.isChecked}
                                    />
                                    <div
                                      className={`w-6 h-6 rounded-4 flex cursor-pointer justify-center items-center mr-3 ${
                                        day.isChecked
                                          ? "bg-checkItemColor"
                                          : "border border-[#DCE6F0] bg-disabledColor"
                                      }`}
                                    >
                                      {day.isChecked ? (
                                        <SelectedIcon width={9} height={7} />
                                      ) : null}
                                    </div>
                                  </label>
                                </div>
                                <div className="px-0 mt-3 min-w-[120px] text-left">
                                  <h3>{day.weekEndName}</h3>
                                </div>
                                <div
                                  className={classNames(
                                    "mt-3 min-w-[685px] ",
                                    2 !== state.current || !state.isShowing
                                      ? ""
                                      : "hidden"
                                  )}
                                >
                                  {/* <p>{day.text}</p> */}
                                  {workingShiftWednesday.map(
                                    (data: any, i: any) => {
                                      const firstClock =
                                        data.firstClock !== "00:00:00"
                                          ? moment(
                                              data.firstClock,
                                              "HH:mm"
                                            ).format("h:mm A")
                                          : "00:00";
                                      const secondClock =
                                        data.secondClock !== "00:00:00"
                                          ? moment(
                                              data.secondClock,
                                              "HH:mm"
                                            ).format("h:mm A")
                                          : "00:00";

                                      return (
                                        <div key={i} className="inline">
                                          <span className="pr-2">
                                            {data.status ?? ""}
                                          </span>
                                          <span className="pr-2">
                                            {firstClock}
                                          </span>
                                          <span className="pr-2">:</span>
                                          <span className="pr-2">
                                            {secondClock}
                                          </span>
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                                <div
                                  className={classNames(
                                    styles.content,
                                    2 == state.current && state.isShowing
                                      ? styles.active
                                      : ""
                                  )}
                                >
                                  {2 == state.current && state.isShowing && (
                                    <div className="flex flex-col">
                                      <div className="min-w-[700px]">
                                        {/* max-h-[250px] overflow-y-auto */}
                                        {workingShiftWednesday.map(
                                          (work: any, i: any) => (
                                            <WorkingShiftCustomise
                                              key={i}
                                              dayBeakWork={dayBeakWork}
                                              work={work}
                                              handleDelete={
                                                handleDeleteWednesday
                                              }
                                              setData={setWorkingShiftWednesday}
                                              locationList={locationList ?? []}
                                              isSubmit={isSubmit}
                                            />
                                          )
                                        )}
                                      </div>
                                      <div className="pt-4">
                                        <span
                                          onClick={handleAddWorkingWednesday}
                                          className="text-vorpblue cursor-pointer text-13 font-medium"
                                        >
                                          + ADD TIME
                                        </span>
                                      </div>
                                      <div className="px-0 my-4 flex justify-end">
                                        <span className="text-graydark text-15 font-medium pr-2">
                                          Total Working Hours
                                        </span>
                                        <span className="text-graydark text-15 font-medium">
                                          {wednesdayCustomiseHour +
                                            " hr " +
                                            wednesdayCustomiseMinute +
                                            " mm"}
                                        </span>
                                      </div>
                                    </div>
                                  )}
                                </div>
                                <div
                                  className="mt-3 w-8 h-10 cursor-pointer"
                                  onClick={() => {
                                    handleShow(2);
                                  }}
                                >
                                  {2 == state.current && state.isShowing ? (
                                    <UpOpenIcon />
                                  ) : (
                                    <DownCloseIcon />
                                  )}
                                </div>
                              </div>
                            </div>
                          ))}

                          {thursdayWeekend.map((day: any, i: any) => (
                            <div
                              key={i}
                              className="flex w-full mb-2 flex-col border border-gray-300 rounded-md p-0 px-3 "
                            >
                              <div className="flex w-full justify-between">
                                <div className="mt-3">
                                  <label
                                    htmlFor={`checklist_${day.id}`}
                                    className={`flex items-center`}
                                  >
                                    <input
                                      type="checkbox"
                                      id={`checklist_${day.id}`}
                                      className="hidden"
                                      onChange={handleCheckListThursday}
                                      checked={day.isChecked}
                                    />
                                    <div
                                      className={`w-6 h-6 rounded-4 cursor-pointer flex justify-center items-center mr-3 ${
                                        day.isChecked
                                          ? "bg-checkItemColor"
                                          : "border border-[#DCE6F0] bg-disabledColor"
                                      }`}
                                    >
                                      {day.isChecked ? (
                                        <SelectedIcon width={9} height={7} />
                                      ) : null}
                                    </div>
                                  </label>
                                </div>
                                <div className="px-0 mt-3 text-left min-w-[120px]">
                                  <h3>{day.weekEndName}</h3>
                                </div>
                                <div
                                  className={classNames(
                                    "mt-3 min-w-[685px] ",
                                    3 !== state.current || !state.isShowing
                                      ? ""
                                      : "hidden"
                                  )}
                                >
                                  {/* <p>{day.text}</p> */}
                                  {workingShiftThursday.map(
                                    (data: any, i: any) => {
                                      const firstClock =
                                        data.firstClock !== "00:00:00"
                                          ? moment(
                                              data.firstClock,
                                              "HH:mm"
                                            ).format("h:mm A")
                                          : "00:00";
                                      const secondClock =
                                        data.secondClock !== "00:00:00"
                                          ? moment(
                                              data.secondClock,
                                              "HH:mm"
                                            ).format("h:mm A")
                                          : "00:00";

                                      return (
                                        <div key={i} className="inline">
                                          <span className="pr-2">
                                            {data.status ?? ""}
                                          </span>
                                          <span className="pr-2">
                                            {firstClock}
                                          </span>
                                          <span className="pr-2">:</span>
                                          <span className="pr-2">
                                            {secondClock}
                                          </span>
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                                <div
                                  className={classNames(
                                    styles.content,
                                    3 == state.current && state.isShowing
                                      ? styles.active
                                      : ""
                                  )}
                                >
                                  {3 == state.current && state.isShowing && (
                                    <div className="flex flex-col">
                                      <div className="min-w-[700px]">
                                        {/* max-h-[250px] overflow-y-auto */}
                                        {workingShiftThursday.map(
                                          (work: any, i: any) => (
                                            <WorkingShiftCustomise
                                              key={i}
                                              dayBeakWork={dayBeakWork}
                                              work={work}
                                              handleDelete={
                                                handleDeleteThursday
                                              }
                                              setData={setWorkingShiftThursday}
                                              locationList={locationList ?? []}
                                              isSubmit={isSubmit}
                                            />
                                          )
                                        )}
                                      </div>
                                      <div className="pt-4">
                                        <span
                                          onClick={handleAddWorkingThursday}
                                          className="text-vorpblue cursor-pointer text-13 font-medium"
                                        >
                                          + ADD TIME
                                        </span>
                                      </div>
                                      <div className="px-0 my-4 flex justify-end">
                                        <span className="text-graydark text-15 font-medium pr-2">
                                          Total Working Hours
                                        </span>
                                        <span className="text-graydark text-15 font-medium">
                                          {thursdayCustomiseHour +
                                            " hr " +
                                            thursdayCustomiseMinute +
                                            " mm"}
                                        </span>
                                      </div>
                                    </div>
                                  )}
                                </div>
                                <div
                                  className="mt-3 w-8 h-10 cursor-pointer"
                                  onClick={() => {
                                    handleShow(3);
                                  }}
                                >
                                  {3 == state.current && state.isShowing ? (
                                    <UpOpenIcon />
                                  ) : (
                                    <DownCloseIcon />
                                  )}
                                </div>
                              </div>
                            </div>
                          ))}

                          {firdayWeekend.map((day: any, i: any) => (
                            <div
                              key={i}
                              className="flex w-full mb-2 flex-col border border-gray-300 rounded-md p-0 px-3 "
                            >
                              <div className="flex w-full justify-between">
                                <div className="mt-3">
                                  <label
                                    htmlFor={`checklist_${day.id}`}
                                    className={`flex items-center`}
                                  >
                                    <input
                                      type="checkbox"
                                      id={`checklist_${day.id}`}
                                      className="hidden"
                                      onChange={handleCheckListFriday}
                                      checked={day.isChecked}
                                    />
                                    <div
                                      className={`w-6 h-6 rounded-4 cursor-pointer flex justify-center items-center mr-3 ${
                                        day.isChecked
                                          ? "bg-checkItemColor"
                                          : "border border-[#DCE6F0] bg-disabledColor"
                                      }`}
                                    >
                                      {day.isChecked ? (
                                        <SelectedIcon width={9} height={7} />
                                      ) : null}
                                    </div>
                                  </label>
                                </div>
                                <div className="px-0 mt-3 text-left min-w-[120px]">
                                  <h3>{day.weekEndName}</h3>
                                </div>
                                <div
                                  className={classNames(
                                    "mt-3 min-w-[685px] ",
                                    4 !== state.current || !state.isShowing
                                      ? ""
                                      : "hidden"
                                  )}
                                >
                                  {/* <p>{day.text}</p> */}
                                  {workingShiftFriday.map(
                                    (data: any, i: any) => {
                                      const firstClock =
                                        data.firstClock !== "00:00:00"
                                          ? moment(
                                              data.firstClock,
                                              "HH:mm"
                                            ).format("h:mm A")
                                          : "00:00";
                                      const secondClock =
                                        data.secondClock !== "00:00:00"
                                          ? moment(
                                              data.secondClock,
                                              "HH:mm"
                                            ).format("h:mm A")
                                          : "00:00";

                                      return (
                                        <div key={i} className="inline">
                                          <span className="pr-2">
                                            {data.status ?? ""}
                                          </span>
                                          <span className="pr-2">
                                            {firstClock}
                                          </span>
                                          <span className="pr-2">:</span>
                                          <span className="pr-2">
                                            {secondClock}
                                          </span>
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                                <div
                                  className={classNames(
                                    styles.content,
                                    4 == state.current && state.isShowing
                                      ? styles.active
                                      : ""
                                  )}
                                >
                                  {4 == state.current && state.isShowing && (
                                    <div className="flex flex-col">
                                      <div className="min-w-[700px]">
                                        {/* max-h-[250px] overflow-y-auto */}
                                        {workingShiftFriday.map(
                                          (work: any, i: any) => (
                                            <WorkingShiftCustomise
                                              key={i}
                                              dayBeakWork={dayBeakWork}
                                              work={work}
                                              handleDelete={handleDeleteFriday}
                                              setData={setWorkingShiftFriday}
                                              locationList={locationList ?? []}
                                              isSubmit={isSubmit}
                                            />
                                          )
                                        )}
                                      </div>
                                      <div className="pt-4">
                                        <span
                                          onClick={handleAddWorkingFriday}
                                          className="text-vorpblue cursor-pointer text-13 font-medium"
                                        >
                                          + ADD TIME
                                        </span>
                                      </div>
                                      <div className="px-0 my-4 flex justify-end">
                                        <span className="text-graydark text-15 font-medium pr-2">
                                          Total Working Hours
                                        </span>
                                        <span className="text-graydark text-15 font-medium">
                                          {fridayCustomiseHour +
                                            " hr " +
                                            fridayCustomiseMinute +
                                            " mm"}
                                        </span>
                                      </div>
                                    </div>
                                  )}
                                </div>
                                <div
                                  className="mt-3 w-8 h-10 cursor-pointer"
                                  onClick={() => {
                                    handleShow(4);
                                  }}
                                >
                                  {4 == state.current && state.isShowing ? (
                                    <UpOpenIcon />
                                  ) : (
                                    <DownCloseIcon />
                                  )}
                                </div>
                              </div>
                            </div>
                          ))}

                          {saturdayWeekend.map((day: any, i: any) => (
                            <div
                              key={i}
                              className="flex w-full mb-2 flex-col border border-gray-300 rounded-md p-0 px-3 "
                            >
                              <div className="flex w-full justify-between">
                                <div className="mt-3">
                                  <label
                                    htmlFor={`checklist_${day.id}`}
                                    className={`flex items-center`}
                                  >
                                    <input
                                      type="checkbox"
                                      id={`checklist_${day.id}`}
                                      className="hidden"
                                      onChange={handleCheckListSaturday}
                                      checked={day.isChecked}
                                    />
                                    <div
                                      className={`w-6 h-6 rounded-4 flex cursor-pointer justify-center items-center mr-3 ${
                                        day.isChecked
                                          ? "bg-checkItemColor"
                                          : "border border-[#DCE6F0] bg-disabledColor"
                                      }`}
                                    >
                                      {day.isChecked ? (
                                        <SelectedIcon width={9} height={7} />
                                      ) : null}
                                    </div>
                                  </label>
                                </div>
                                <div className="px-0 mt-3 text-left min-w-[120px]">
                                  <h3>{day.weekEndName}</h3>
                                </div>
                                <div
                                  className={classNames(
                                    "mt-3 min-w-[685px] ",
                                    5 !== state.current || !state.isShowing
                                      ? ""
                                      : "hidden"
                                  )}
                                >
                                  {/* <p>{day.text}</p> */}
                                  {workingShiftSaturday.map(
                                    (data: any, i: any) => {
                                      const firstClock =
                                        data.firstClock !== "00:00:00"
                                          ? moment(
                                              data.firstClock,
                                              "HH:mm"
                                            ).format("h:mm A")
                                          : "00:00";
                                      const secondClock =
                                        data.secondClock !== "00:00:00"
                                          ? moment(
                                              data.secondClock,
                                              "HH:mm"
                                            ).format("h:mm A")
                                          : "00:00";

                                      return (
                                        <div key={i} className="inline">
                                          <span className="pr-2">
                                            {data.status ?? ""}
                                          </span>
                                          <span className="pr-2">
                                            {firstClock}
                                          </span>
                                          <span className="pr-2">:</span>
                                          <span className="pr-2">
                                            {secondClock}
                                          </span>
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                                <div
                                  className={classNames(
                                    styles.content,
                                    5 == state.current && state.isShowing
                                      ? styles.active
                                      : ""
                                  )}
                                >
                                  {5 == state.current && state.isShowing && (
                                    <div className="flex flex-col">
                                      <div className="min-w-[700px]">
                                        {/* max-h-[250px] overflow-y-auto */}
                                        {workingShiftSaturday.map(
                                          (work: any, i: any) => (
                                            <WorkingShiftCustomise
                                              key={i}
                                              dayBeakWork={dayBeakWork}
                                              work={work}
                                              handleDelete={
                                                handleDeleteSaturday
                                              }
                                              setData={setWorkingShiftSaturday}
                                              locationList={locationList ?? []}
                                              isSubmit={isSubmit}
                                            />
                                          )
                                        )}
                                      </div>
                                      <div className="pt-4">
                                        <span
                                          onClick={handleAddWorkingSaturday}
                                          className="text-vorpblue cursor-pointer text-13 font-medium"
                                        >
                                          + ADD TIME
                                        </span>
                                      </div>
                                      <div className="px-0 my-4 flex justify-end">
                                        <span className="text-graydark text-15 font-medium">
                                          Total Working Hours:
                                        </span>
                                        <span className="text-graydark text-15 font-medium">
                                          {saturdayCustomiseHour +
                                            " hr " +
                                            saturdayCustomiseMinute +
                                            " mm"}
                                        </span>
                                      </div>
                                    </div>
                                  )}
                                </div>
                                <div
                                  className="mt-3 w-8 h-10 cursor-pointer"
                                  onClick={() => {
                                    handleShow(5);
                                  }}
                                >
                                  {5 == state.current && state.isShowing ? (
                                    <UpOpenIcon />
                                  ) : (
                                    <DownCloseIcon />
                                  )}
                                </div>
                              </div>
                            </div>
                          ))}

                          {sundayWeekend.map((day: any, i: any) => (
                            <div
                              key={i}
                              className="flex w-full mb-2 flex-col border border-gray-300 rounded-md p-0 px-3 "
                            >
                              <div className="flex w-full justify-between">
                                <div className="mt-3">
                                  <label
                                    htmlFor={`checklist_${day.id}`}
                                    className={`flex items-center`}
                                  >
                                    <input
                                      type="checkbox"
                                      id={`checklist_${day.id}`}
                                      className="hidden"
                                      onChange={handleCheckListSunday}
                                      checked={day.isChecked}
                                    />
                                    <div
                                      className={`w-6 h-6 rounded-4 flex cursor-pointer justify-center items-center mr-3 ${
                                        day.isChecked
                                          ? "bg-checkItemColor"
                                          : "border border-[#DCE6F0] bg-disabledColor"
                                      }`}
                                    >
                                      {day.isChecked ? (
                                        <SelectedIcon width={9} height={7} />
                                      ) : null}
                                    </div>
                                  </label>
                                </div>
                                <div className="px-0 mt-3 min-w-[120px] text-left">
                                  <h3>{day.weekEndName}</h3>
                                </div>
                                <div
                                  className={classNames(
                                    "mt-3 min-w-[685px] ",
                                    6 !== state.current || !state.isShowing
                                      ? ""
                                      : "hidden"
                                  )}
                                >
                                  {/* <p>{day.text}</p> */}
                                  {workingShiftSunday.map(
                                    (data: any, i: any) => {
                                      const firstClock =
                                        data.firstClock !== "00:00:00"
                                          ? moment(
                                              data.firstClock,
                                              "HH:mm"
                                            ).format("h:mm A")
                                          : "00:00";
                                      const secondClock =
                                        data.secondClock !== "00:00:00"
                                          ? moment(
                                              data.secondClock,
                                              "HH:mm"
                                            ).format("h:mm A")
                                          : "00:00";

                                      return (
                                        <div key={i} className="inline">
                                          <span className="pr-2">
                                            {data.status ?? ""}
                                          </span>
                                          <span className="pr-2">
                                            {firstClock}
                                          </span>
                                          <span className="pr-2">:</span>
                                          <span className="pr-2">
                                            {secondClock}
                                          </span>
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                                <div
                                  className={classNames(
                                    styles.content,
                                    6 == state.current && state.isShowing
                                      ? styles.active
                                      : ""
                                  )}
                                >
                                  {6 == state.current && state.isShowing && (
                                    <div className="flex flex-col">
                                      <div className="min-w-[700px]">
                                        {/* max-h-[250px] overflow-y-auto */}
                                        {workingShiftSunday.map(
                                          (work: any, i: any) => (
                                            <WorkingShiftCustomise
                                              key={i}
                                              dayBeakWork={dayBeakWork}
                                              work={work}
                                              handleDelete={handleDeleteSunday}
                                              setData={setWorkingShiftSunday}
                                              locationList={locationList ?? []}
                                              isSubmit={isSubmit}
                                            />
                                          )
                                        )}
                                      </div>
                                      <div className="pt-4">
                                        <span
                                          onClick={handleAddWorkingSunday}
                                          className="text-vorpblue cursor-pointer text-13 font-medium"
                                        >
                                          + ADD TIME
                                        </span>
                                      </div>
                                      <div className="px-0 my-4 flex justify-end">
                                        <span className="text-graydark text-15 font-medium pr-2">
                                          Total Working Hours
                                        </span>
                                        <span className="text-graydark text-15 font-medium">
                                          {sundayCustomiseHour +
                                            " hr " +
                                            sundayCustomiseMinute +
                                            " mm"}
                                        </span>
                                      </div>
                                    </div>
                                  )}
                                </div>
                                <div
                                  className="mt-3 w-8 h-10 cursor-pointer"
                                  onClick={() => {
                                    handleShow(6);
                                  }}
                                >
                                  {6 == state.current && state.isShowing ? (
                                    <UpOpenIcon />
                                  ) : (
                                    <DownCloseIcon />
                                  )}
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                        <div className="px-0 mt-4">
                          <span className="text-graydark text-15 font-medium pr-2">
                            Total Working Hours
                          </span>{" "}
                          <span className="text-15 txt-black2 font-semibold">
                            {totalWorkingHour +
                              " hr " +
                              totalWorkingMinute +
                              " mm"}
                          </span>
                        </div>
                      </>
                    ) : null}
                    {fixWorkHour === "flexible" ? (
                      <div className=" px-0 py-1">
                        <div className="mb-5">
                          <h2 className="text-left text-15  text-black2 font-medium inline">
                            Schedule by
                          </h2>
                          <span className=" text-red-500">*</span>
                        </div>
                        <div className="flex mb-3">
                          <BaseRadioButton
                            state={flexibleState}
                            setState={setFlexibleState}
                            label="Daily"
                            value="daily"
                            id="daily"
                            className="mr-4 sm:mr-8"
                            fontSize="xl:text-15 text-12"
                            name="claim"
                          />
                          <BaseRadioButton
                            state={flexibleState}
                            setState={setFlexibleState}
                            label="Weekly"
                            value="weekly"
                            id="weekly"
                            className="mr-4 sm:mr-8"
                            fontSize="xl:text-15 text-12"
                            name="claim"
                          />
                        </div>
                      </div>
                    ) : null}
                    {fixWorkHour === "flexible" && flexibleState === "daily" ? (
                      <>
                        <div className="flex flex-col w-full">
                          <div className="max-h-[200px] overflow-y-auto">
                            {flexibleDaily.map((work: any, i: any) => (
                              <FlexibleWorkingShiftList
                                key={i}
                                locationList={locationList}
                                work={work}
                                handleDelete={handleDelete}
                                setData={setFlexibleDaily}
                                setMultiFlexibleDaily={setMultiFlexibleDaily}
                                setMultiFlexibleDailyID={
                                  setMultiFlexibleDailyID
                                }
                                multiFlexibleDaily={multiFlexibleDaily}
                                isSubmit={isSubmit}
                              />
                            ))}
                          </div>
                          <div className="max-h-[200px] overflow-y-auto">
                            {mondayFlexible.map((monday: any, i: any) => (
                              <div
                                key={i}
                                className="flex w-full mb-2 flex-col border border-gray-300 rounded-md p-0 px-3 "
                              >
                                <div className="flex w-full justify-between items-center">
                                  <div className="mt-0">
                                    <label
                                      htmlFor={`checklist_${monday.id}`}
                                      className={`flex items-center`}
                                    >
                                      <input
                                        type="checkbox"
                                        id={`checklist_${monday.id}`}
                                        className="hidden"
                                        onChange={handleFlexibleDailyMonday}
                                        checked={monday.isChecked}
                                      />
                                      <div
                                        className={`w-6 h-6 rounded-4 flex justify-center cursor-pointer items-center mr-3 ${
                                          monday.isChecked
                                            ? "bg-checkItemColor"
                                            : "border border-[#DCE6F0] bg-disabledColor"
                                        }`}
                                      >
                                        {monday.isChecked ? (
                                          <SelectedIcon width={9} height={7} />
                                        ) : null}
                                      </div>
                                    </label>
                                  </div>
                                  <div className="px-3 min-w-[120px] text-left">
                                    <h3>{monday.weekEndName}</h3>
                                  </div>
                                  <div
                                    className={classNames(
                                      "mt-0  min-w-[550px]",
                                      0 !== flexibleStateData.current ||
                                        !flexibleStateData.isShowing
                                        ? ""
                                        : "hidden"
                                    )}
                                  >
                                    <p>
                                      {monday.text}
                                      {` ${mondayFlexible[0]["clock"]}`}
                                    </p>
                                  </div>
                                  <div
                                    className={classNames(
                                      styles.content,
                                      "",
                                      0 == flexibleStateData.current &&
                                        flexibleStateData.isShowing
                                        ? styles.active
                                        : ""
                                    )}
                                  >
                                    {0 == flexibleStateData.current &&
                                    flexibleStateData.isShowing ? (
                                      <div className="flex items-center">
                                        <label className="text-black2 pr-5 text-15 font-medium font-primary mt-0 flex mb-0">
                                          Working Hours
                                          <span className=" text-red-500">
                                            *
                                          </span>
                                          <div className="text-center ml-2">
                                            <Tooltip
                                              PopperProps={{
                                                disablePortal: true,
                                              }}
                                              style={{ zIndex: 999999999999 }}
                                              arrow
                                              className="customTooltip"
                                              classes={{
                                                tooltip: classes.tooltip,
                                              }}
                                              enterTouchDelay={1000}
                                              leaveTouchDelay={5000}
                                              placement="left"
                                              title={
                                                <>
                                                  <div className="text-white text-[10px] hover:cursor-pointer leading-[15px] z-[2] rounded p-1 w-full bg-tooltipbg">
                                                    <span className="flex w-full justify-center h-full items-center">
                                                      Minimum required hours for
                                                      work
                                                    </span>
                                                  </div>
                                                </>
                                              }
                                            >
                                              <div className="flex flex-wrap ">
                                                <QuestionIcon
                                                  width={16}
                                                  height={17}
                                                />
                                              </div>
                                            </Tooltip>
                                          </div>
                                        </label>
                                        <div className="p-2 working_shift_time_clear">
                                          <TimePicker
                                            showSecond={false}
                                            value={moment()
                                              .hours(Number(mondayDailyHours))
                                              .minute(
                                                Number(mondayDailyMinutes)
                                              )
                                              .second(
                                                Number(mondayDailySecond)
                                              )}
                                            defaultValue={now}
                                            className={
                                              styles.customTimePickerInput
                                            }
                                            onChange={onChangeDateStartMonday}
                                            format={formatDaily}
                                            use12Hours={false}
                                            popupClassName="z-[9999999]"
                                            allowEmpty={false}
                                            addon={() => (
                                              <button
                                                className="p-1 "
                                                onClick={
                                                  handleClearFlexibleMonday
                                                }
                                              >
                                                Clear
                                              </button>
                                            )}
                                          />
                                        </div>
                                      </div>
                                    ) : null}
                                  </div>

                                  <div
                                    className="mt-0 items-center  w-8 h-10 cursor-pointer flex justify-end"
                                    onClick={() => {
                                      handleShowFlexible(0);
                                    }}
                                  >
                                    {0 == flexibleStateData.current &&
                                    flexibleStateData.isShowing ? (
                                      <UpOpenIcon />
                                    ) : (
                                      <DownCloseIcon />
                                    )}
                                  </div>
                                </div>
                              </div>
                            ))}

                            {tuesdayFlexible.map((monday: any, i: any) => (
                              <div
                                key={i}
                                className="flex w-full mb-2 flex-col border border-gray-300 rounded-md p-0 px-3 "
                              >
                                <div className="flex w-full justify-between items-center">
                                  <div className="mt-0">
                                    <label
                                      htmlFor={`checklist_${monday.id}`}
                                      className={`flex items-center`}
                                    >
                                      <input
                                        type="checkbox"
                                        id={`checklist_${monday.id}`}
                                        className="hidden"
                                        onChange={handleFlexibleDailyTuesday}
                                        checked={monday.isChecked}
                                      />
                                      <div
                                        className={`w-6 h-6 rounded-4 flex cursor-pointer justify-center items-center mr-3 ${
                                          monday.isChecked
                                            ? "bg-checkItemColor"
                                            : "border border-[#DCE6F0] bg-disabledColor"
                                        }`}
                                      >
                                        {monday.isChecked ? (
                                          <SelectedIcon width={9} height={7} />
                                        ) : null}
                                      </div>
                                    </label>
                                  </div>
                                  <div className="px-3 min-w-[120px] text-left">
                                    <h3>{monday.weekEndName}</h3>
                                  </div>
                                  <div
                                    className={classNames(
                                      "mt-0  min-w-[550px]",
                                      1 !== flexibleStateData.current ||
                                        !flexibleStateData.isShowing
                                        ? ""
                                        : "hidden"
                                    )}
                                  >
                                    <p>
                                      {monday.text}
                                      {` ${tuesdayFlexible[0]["clock"]}`}
                                    </p>
                                  </div>
                                  <div
                                    className={classNames(
                                      styles.content,
                                      "",
                                      1 == flexibleStateData.current &&
                                        flexibleStateData.isShowing
                                        ? styles.active
                                        : ""
                                    )}
                                  >
                                    {1 == flexibleStateData.current &&
                                    flexibleStateData.isShowing ? (
                                      <div className="flex items-center">
                                        <label className="text-black2 pr-5 text-15 font-medium font-primary mt-0 flex mb-0">
                                          Working Hours
                                          <span className=" text-red-500">
                                            *
                                          </span>
                                          <div className="text-center ml-2">
                                            <Tooltip
                                              PopperProps={{
                                                disablePortal: true,
                                              }}
                                              style={{ zIndex: 999999999999 }}
                                              arrow
                                              className="customTooltip"
                                              classes={{
                                                tooltip: classes.tooltip,
                                              }}
                                              enterTouchDelay={1000}
                                              leaveTouchDelay={5000}
                                              placement="left"
                                              title={
                                                <>
                                                  <div className="text-white text-[10px] hover:cursor-pointer leading-[15px] z-[2] rounded p-1 w-full bg-tooltipbg">
                                                    <span className="flex w-full justify-center h-full items-center">
                                                      Minimum required hours for
                                                      work
                                                    </span>
                                                  </div>
                                                </>
                                              }
                                            >
                                              <div className="flex flex-wrap ">
                                                <QuestionIcon
                                                  width={16}
                                                  height={17}
                                                />
                                              </div>
                                            </Tooltip>
                                          </div>
                                        </label>
                                        <div className="p-2">
                                          <TimePicker
                                            showSecond={false}
                                            value={moment()
                                              .hours(Number(tuesdayDailyHours))
                                              .minute(
                                                Number(tuesdayDailyMinutes)
                                              )
                                              .second(
                                                Number(tuesdayDailySecond)
                                              )}
                                            defaultValue={now}
                                            className={
                                              styles.customTimePickerInput
                                            }
                                            onChange={onChangeDateStartTuesday}
                                            format={formatDaily}
                                            use12Hours={false}
                                            popupClassName="z-[9999999]"
                                            allowEmpty={false}
                                            addon={() => (
                                              <button
                                                className="p-1 "
                                                onClick={
                                                  handleClearFlexibleTuesday
                                                }
                                              >
                                                Clear
                                              </button>
                                            )}
                                          />
                                        </div>
                                      </div>
                                    ) : null}
                                  </div>
                                  <div
                                    className="mt-0 items-center  w-8 h-10 cursor-pointer flex justify-end"
                                    onClick={() => {
                                      handleShowFlexible(1);
                                    }}
                                  >
                                    {1 == flexibleStateData.current &&
                                    flexibleStateData.isShowing ? (
                                      <UpOpenIcon />
                                    ) : (
                                      <DownCloseIcon />
                                    )}
                                  </div>
                                </div>
                              </div>
                            ))}

                            {wednesdayFlexible.map((monday: any, i: any) => (
                              <div
                                key={i}
                                className="flex w-full mb-2 flex-col border border-gray-300 rounded-md p-0 px-3 "
                              >
                                <div className="flex w-full justify-between items-center">
                                  <div className="mt-0">
                                    <label
                                      htmlFor={`checklist_${monday.id}`}
                                      className={`flex items-center`}
                                    >
                                      <input
                                        type="checkbox"
                                        id={`checklist_${monday.id}`}
                                        className="hidden"
                                        onChange={handleFlexibleDailyWednesday}
                                        checked={monday.isChecked}
                                      />
                                      <div
                                        className={`w-6 h-6 rounded-4 flex cursor-pointer justify-center items-center mr-3 ${
                                          monday.isChecked
                                            ? "bg-checkItemColor"
                                            : "border border-[#DCE6F0] bg-disabledColor"
                                        }`}
                                      >
                                        {monday.isChecked ? (
                                          <SelectedIcon width={9} height={7} />
                                        ) : null}
                                      </div>
                                    </label>
                                  </div>
                                  <div className="px-3 min-w-[120px] text-left">
                                    <h3>{monday.weekEndName}</h3>
                                  </div>
                                  <div
                                    className={classNames(
                                      "mt-0  min-w-[550px]",
                                      2 !== flexibleStateData.current ||
                                        !flexibleStateData.isShowing
                                        ? ""
                                        : "hidden"
                                    )}
                                  >
                                    <p>
                                      {monday.text}
                                      {` ${wednesdayFlexible[0]["clock"]}`}
                                    </p>
                                  </div>
                                  <div
                                    className={classNames(
                                      styles.content,
                                      "",
                                      2 == flexibleStateData.current &&
                                        flexibleStateData.isShowing
                                        ? styles.active
                                        : ""
                                    )}
                                  >
                                    {2 == flexibleStateData.current &&
                                    flexibleStateData.isShowing ? (
                                      <div className="flex items-center">
                                        <label className="text-black2 pr-5 text-15 font-medium font-primary mt-0 flex mb-0">
                                          Working Hours
                                          <span className=" text-red-500">
                                            *
                                          </span>
                                          <div className="text-center ml-2">
                                            <Tooltip
                                              PopperProps={{
                                                disablePortal: true,
                                              }}
                                              style={{ zIndex: 999999999999 }}
                                              arrow
                                              className="customTooltip"
                                              classes={{
                                                tooltip: classes.tooltip,
                                              }}
                                              enterTouchDelay={1000}
                                              leaveTouchDelay={5000}
                                              placement="left"
                                              title={
                                                <>
                                                  <div className="text-white text-[10px] hover:cursor-pointer leading-[15px] z-[2] rounded p-1 w-full bg-tooltipbg">
                                                    <span className="flex w-full justify-center h-full items-center">
                                                      Minimum required hours for
                                                      work
                                                    </span>
                                                  </div>
                                                </>
                                              }
                                            >
                                              <div className="flex flex-wrap ">
                                                <QuestionIcon
                                                  width={16}
                                                  height={17}
                                                />
                                              </div>
                                            </Tooltip>
                                          </div>
                                        </label>
                                        <div className="p-2">
                                          <TimePicker
                                            showSecond={false}
                                            value={moment()
                                              .hours(
                                                Number(wednesdayDailyHours)
                                              )
                                              .minute(
                                                Number(wednesdayDailyMinutes)
                                              )
                                              .second(
                                                Number(wednesdayDailySecond)
                                              )}
                                            defaultValue={now}
                                            className={
                                              styles.customTimePickerInput
                                            }
                                            onChange={
                                              onChangeDateStartWednesday
                                            }
                                            format={formatDaily}
                                            use12Hours={false}
                                            popupClassName="z-[9999999]"
                                            allowEmpty={false}
                                            addon={() => (
                                              <button
                                                className="p-1 "
                                                onClick={handleClearFlexibleWed}
                                              >
                                                Clear
                                              </button>
                                            )}
                                          />
                                        </div>
                                      </div>
                                    ) : null}
                                  </div>
                                  <div
                                    className="mt-0 items-center  w-8 h-10 cursor-pointer flex justify-end"
                                    onClick={() => {
                                      handleShowFlexible(2);
                                    }}
                                  >
                                    {2 == flexibleStateData.current &&
                                    flexibleStateData.isShowing ? (
                                      <UpOpenIcon />
                                    ) : (
                                      <DownCloseIcon />
                                    )}
                                  </div>
                                </div>
                              </div>
                            ))}

                            {thursdayFlexible.map((monday: any, i: any) => (
                              <div
                                key={i}
                                className="flex w-full mb-2 flex-col border border-gray-300 rounded-md p-0 px-3 "
                              >
                                <div className="flex w-full justify-between items-center">
                                  <div className="mt-0">
                                    <label
                                      htmlFor={`checklist_${monday.id}`}
                                      className={`flex items-center`}
                                    >
                                      <input
                                        type="checkbox"
                                        id={`checklist_${monday.id}`}
                                        className="hidden"
                                        onChange={handleFlexibleDailyThursday}
                                        checked={monday.isChecked}
                                      />
                                      <div
                                        className={`w-6 h-6 rounded-4 cursor-pointer flex justify-center items-center mr-3 ${
                                          monday.isChecked
                                            ? "bg-checkItemColor"
                                            : "border border-[#DCE6F0] bg-disabledColor"
                                        }`}
                                      >
                                        {monday.isChecked ? (
                                          <SelectedIcon width={9} height={7} />
                                        ) : null}
                                      </div>
                                    </label>
                                  </div>
                                  <div className="px-3 min-w-[120px] text-left">
                                    <h3>{monday.weekEndName}</h3>
                                  </div>
                                  <div
                                    className={classNames(
                                      "mt-0  min-w-[550px]",
                                      3 !== flexibleStateData.current ||
                                        !flexibleStateData.isShowing
                                        ? ""
                                        : "hidden"
                                    )}
                                  >
                                    <p>
                                      {monday.text}
                                      {` ${thursdayFlexible[0]["clock"]}`}
                                    </p>
                                  </div>
                                  <div
                                    className={classNames(
                                      styles.content,
                                      "",
                                      3 == flexibleStateData.current &&
                                        flexibleStateData.isShowing
                                        ? styles.active
                                        : ""
                                    )}
                                  >
                                    {3 == flexibleStateData.current &&
                                    flexibleStateData.isShowing ? (
                                      <div className="flex items-center">
                                        <label className="text-black2 pr-5 text-15 font-medium font-primary mt-0 flex mb-0">
                                          Working Hours
                                          <span className=" text-red-500">
                                            *
                                          </span>
                                          <div className="text-center ml-2">
                                            <Tooltip
                                              PopperProps={{
                                                disablePortal: true,
                                              }}
                                              style={{ zIndex: 999999999999 }}
                                              arrow
                                              className="customTooltip"
                                              classes={{
                                                tooltip: classes.tooltip,
                                              }}
                                              enterTouchDelay={1000}
                                              leaveTouchDelay={5000}
                                              placement="left"
                                              title={
                                                <>
                                                  <div className="text-white text-[10px] hover:cursor-pointer leading-[15px] z-[2] rounded p-1 w-full bg-tooltipbg">
                                                    <span className="flex w-full justify-center h-full items-center">
                                                      Minimum required hours for
                                                      work
                                                    </span>
                                                  </div>
                                                </>
                                              }
                                            >
                                              <div className="flex flex-wrap ">
                                                <QuestionIcon
                                                  width={16}
                                                  height={17}
                                                />
                                              </div>
                                            </Tooltip>
                                          </div>
                                        </label>
                                        <div className="p-2">
                                          <TimePicker
                                            showSecond={false}
                                            value={moment()
                                              .hours(Number(thursdayDailyHours))
                                              .minute(
                                                Number(thursdayDailyMinutes)
                                              )
                                              .second(
                                                Number(thursdayDailySecond)
                                              )}
                                            defaultValue={now}
                                            className={
                                              styles.customTimePickerInput
                                            }
                                            onChange={onChangeDateStartThursday}
                                            format={formatDaily}
                                            use12Hours={false}
                                            popupClassName="z-[9999999]"
                                            allowEmpty={false}
                                            addon={() => (
                                              <button
                                                className="p-1 "
                                                onClick={
                                                  handleClearFlexibleThurs
                                                }
                                              >
                                                Clear
                                              </button>
                                            )}
                                          />
                                        </div>
                                      </div>
                                    ) : null}
                                  </div>
                                  <div
                                    className="mt-0 items-center  w-8 h-10 cursor-pointer flex justify-end"
                                    onClick={() => {
                                      handleShowFlexible(3);
                                    }}
                                  >
                                    {3 == flexibleStateData.current &&
                                    flexibleStateData.isShowing ? (
                                      <UpOpenIcon />
                                    ) : (
                                      <DownCloseIcon />
                                    )}
                                  </div>
                                </div>
                              </div>
                            ))}

                            {fridayFlexible.map((monday: any, i: any) => (
                              <div
                                key={i}
                                className="flex w-full mb-2 flex-col border border-gray-300 rounded-md p-0 px-3 "
                              >
                                <div className="flex w-full justify-between items-center">
                                  <div className="mt-0">
                                    <label
                                      htmlFor={`checklist_${monday.id}`}
                                      className={`flex items-center`}
                                    >
                                      <input
                                        type="checkbox"
                                        id={`checklist_${monday.id}`}
                                        className="hidden"
                                        onChange={handleFlexibleDailyFriday}
                                        checked={monday.isChecked}
                                      />
                                      <div
                                        className={`w-6 h-6 rounded-4 flex cursor-pointer justify-center items-center mr-3 ${
                                          monday.isChecked
                                            ? "bg-checkItemColor"
                                            : "border border-[#DCE6F0] bg-disabledColor"
                                        }`}
                                      >
                                        {monday.isChecked ? (
                                          <SelectedIcon width={9} height={7} />
                                        ) : null}
                                      </div>
                                    </label>
                                  </div>
                                  <div className="px-3 min-w-[120px] text-left">
                                    <h3>{monday.weekEndName}</h3>
                                  </div>
                                  <div
                                    className={classNames(
                                      "mt-0  min-w-[550px]",
                                      4 !== flexibleStateData.current ||
                                        !flexibleStateData.isShowing
                                        ? ""
                                        : "hidden"
                                    )}
                                  >
                                    <p>
                                      {monday.text}
                                      {` ${fridayFlexible[0]["clock"]}`}
                                    </p>
                                  </div>
                                  <div
                                    className={classNames(
                                      styles.content,
                                      "",
                                      4 == flexibleStateData.current &&
                                        flexibleStateData.isShowing
                                        ? styles.active
                                        : ""
                                    )}
                                  >
                                    {4 == flexibleStateData.current &&
                                    flexibleStateData.isShowing ? (
                                      <div className="flex items-center">
                                        <label className="text-black2 pr-5 text-15 font-medium font-primary mt-0 flex mb-0">
                                          Working Hours
                                          <span className=" text-red-500">
                                            *
                                          </span>
                                          <div className="text-center ml-2">
                                            <Tooltip
                                              PopperProps={{
                                                disablePortal: true,
                                              }}
                                              style={{ zIndex: 999999999999 }}
                                              arrow
                                              className="customTooltip"
                                              classes={{
                                                tooltip: classes.tooltip,
                                              }}
                                              enterTouchDelay={1000}
                                              leaveTouchDelay={5000}
                                              placement="left"
                                              title={
                                                <>
                                                  <div className="text-white text-[10px] hover:cursor-pointer leading-[15px] z-[2] rounded p-1 w-full bg-tooltipbg">
                                                    <span className="flex w-full justify-center h-full items-center">
                                                      Minimum required hours for
                                                      work
                                                    </span>
                                                  </div>
                                                </>
                                              }
                                            >
                                              <div className="flex flex-wrap ">
                                                <QuestionIcon
                                                  width={16}
                                                  height={17}
                                                />
                                              </div>
                                            </Tooltip>
                                          </div>
                                        </label>
                                        <div className="p-2">
                                          <TimePicker
                                            showSecond={false}
                                            value={moment()
                                              .hours(Number(fridayDailyHours))
                                              .minute(
                                                Number(fridayDailyMinutes)
                                              )
                                              .second(
                                                Number(fridayDailySecond)
                                              )}
                                            defaultValue={now}
                                            className={
                                              styles.customTimePickerInput
                                            }
                                            onChange={onChangeDateStartFriday}
                                            format={formatDaily}
                                            use12Hours={false}
                                            popupClassName="z-[9999999]"
                                            allowEmpty={false}
                                            addon={() => (
                                              <button
                                                className="p-1 "
                                                onClick={
                                                  handleClearFlexibleFriday
                                                }
                                              >
                                                Clear
                                              </button>
                                            )}
                                          />
                                        </div>
                                      </div>
                                    ) : null}
                                  </div>
                                  <div
                                    className="mt-0 items-center  w-8 h-10 cursor-pointer flex justify-end"
                                    onClick={() => {
                                      handleShowFlexible(4);
                                    }}
                                  >
                                    {4 == flexibleStateData.current &&
                                    flexibleStateData.isShowing ? (
                                      <UpOpenIcon />
                                    ) : (
                                      <DownCloseIcon />
                                    )}
                                  </div>
                                </div>
                              </div>
                            ))}

                            {saturdayFlexible.map((monday: any, i: any) => (
                              <div
                                key={i}
                                className="flex w-full mb-2 flex-col border border-gray-300 rounded-md p-0 px-3 "
                              >
                                <div className="flex w-full justify-between items-center">
                                  <div className="mt-0">
                                    <label
                                      htmlFor={`checklist_${monday.id}`}
                                      className={`flex items-center`}
                                    >
                                      <input
                                        type="checkbox"
                                        id={`checklist_${monday.id}`}
                                        className="hidden"
                                        onChange={handleFlexibleDailySaturday}
                                        checked={monday.isChecked}
                                      />
                                      <div
                                        className={`w-6 h-6 rounded-4 flex cursor-pointer justify-center items-center mr-3 ${
                                          monday.isChecked
                                            ? "bg-checkItemColor"
                                            : "border border-[#DCE6F0] bg-disabledColor"
                                        }`}
                                      >
                                        {monday.isChecked ? (
                                          <SelectedIcon width={9} height={7} />
                                        ) : null}
                                      </div>
                                    </label>
                                  </div>
                                  <div className="px-3 min-w-[120px] text-left">
                                    <h3>{monday.weekEndName}</h3>
                                  </div>
                                  <div
                                    className={classNames(
                                      "mt-0  min-w-[550px]",
                                      5 !== flexibleStateData.current ||
                                        !flexibleStateData.isShowing
                                        ? ""
                                        : "hidden"
                                    )}
                                  >
                                    <p>
                                      {monday.text}
                                      {` ${saturdayFlexible[0]["clock"]}`}
                                    </p>
                                  </div>
                                  <div
                                    className={classNames(
                                      styles.content,
                                      "",
                                      5 == flexibleStateData.current &&
                                        flexibleStateData.isShowing
                                        ? styles.active
                                        : ""
                                    )}
                                  >
                                    {5 == flexibleStateData.current &&
                                    flexibleStateData.isShowing ? (
                                      <div className="flex items-center">
                                        <label className="text-black2 pr-5 text-15 font-medium font-primary mt-0 flex mb-0">
                                          Working Hours
                                          <span className=" text-red-500">
                                            *
                                          </span>
                                          <div className="text-center ml-2">
                                            <Tooltip
                                              PopperProps={{
                                                disablePortal: true,
                                              }}
                                              style={{ zIndex: 999999999999 }}
                                              arrow
                                              className="customTooltip"
                                              classes={{
                                                tooltip: classes.tooltip,
                                              }}
                                              enterTouchDelay={1000}
                                              leaveTouchDelay={5000}
                                              placement="left"
                                              title={
                                                <>
                                                  <div className="text-white text-[10px] hover:cursor-pointer leading-[15px] z-[2] rounded p-1 w-full bg-tooltipbg">
                                                    <span className="flex w-full justify-center h-full items-center">
                                                      Minimum required hours for
                                                      work
                                                    </span>
                                                  </div>
                                                </>
                                              }
                                            >
                                              <div className="flex flex-wrap ">
                                                <QuestionIcon
                                                  width={16}
                                                  height={17}
                                                />
                                              </div>
                                            </Tooltip>
                                          </div>
                                        </label>
                                        <div className="p-2">
                                          <TimePicker
                                            showSecond={false}
                                            value={moment()
                                              .hours(Number(saturdayDailyHours))
                                              .minute(
                                                Number(saturdayDailyMinutes)
                                              )
                                              .second(
                                                Number(saturdayDailySecond)
                                              )}
                                            defaultValue={now}
                                            className={
                                              styles.customTimePickerInput
                                            }
                                            onChange={onChangeDateStartSaturday}
                                            format={formatDaily}
                                            use12Hours={false}
                                            popupClassName="z-[9999999]"
                                            allowEmpty={false}
                                            addon={() => (
                                              <button
                                                className="p-1 "
                                                onClick={handleClearFlexibleSat}
                                              >
                                                Clear
                                              </button>
                                            )}
                                          />
                                        </div>
                                      </div>
                                    ) : null}
                                  </div>
                                  <div
                                    className="mt-0 items-center  w-8 h-10 cursor-pointer flex justify-end"
                                    onClick={() => {
                                      handleShowFlexible(5);
                                    }}
                                  >
                                    {5 == flexibleStateData.current &&
                                    flexibleStateData.isShowing ? (
                                      <UpOpenIcon />
                                    ) : (
                                      <DownCloseIcon />
                                    )}
                                  </div>
                                </div>
                              </div>
                            ))}

                            {sundayFlexible.map((monday: any, i: any) => (
                              <div
                                key={i}
                                className="flex w-full mb-2 flex-col border border-gray-300 rounded-md p-0 px-3 "
                              >
                                <div className="flex w-full justify-between items-center">
                                  <div className="mt-0">
                                    <label
                                      htmlFor={`checklist_${monday.id}`}
                                      className={`flex items-center`}
                                    >
                                      <input
                                        type="checkbox"
                                        id={`checklist_${monday.id}`}
                                        className="hidden"
                                        onChange={handleFlexibleDailySunday}
                                        checked={monday.isChecked}
                                      />
                                      <div
                                        className={`w-6 h-6 rounded-4 flex cursor-pointer justify-center items-center mr-3 ${
                                          monday.isChecked
                                            ? "bg-checkItemColor"
                                            : "border border-[#DCE6F0] bg-disabledColor"
                                        }`}
                                      >
                                        {monday.isChecked ? (
                                          <SelectedIcon width={9} height={7} />
                                        ) : null}
                                      </div>
                                    </label>
                                  </div>
                                  <div className="px-3 min-w-[120px] text-left">
                                    <h3>{monday.weekEndName}</h3>
                                  </div>
                                  <div
                                    className={classNames(
                                      "mt-0  min-w-[550px]",
                                      6 !== flexibleStateData.current ||
                                        !flexibleStateData.isShowing
                                        ? ""
                                        : "hidden"
                                    )}
                                  >
                                    <p>
                                      {monday.text}
                                      {` ${sundayFlexible[0]["clock"]}`}
                                    </p>
                                  </div>
                                  <div
                                    className={classNames(
                                      styles.content,
                                      "",
                                      6 == flexibleStateData.current &&
                                        flexibleStateData.isShowing
                                        ? styles.active
                                        : ""
                                    )}
                                  >
                                    {6 == flexibleStateData.current &&
                                    flexibleStateData.isShowing ? (
                                      <div className="flex items-center">
                                        <label className="text-black2 pr-5 text-15 font-medium font-primary mt-0 flex mb-0">
                                          Working Hours
                                          <span className=" text-red-500">
                                            *
                                          </span>
                                          <div className="text-center ml-2">
                                            <Tooltip
                                              PopperProps={{
                                                disablePortal: true,
                                              }}
                                              style={{ zIndex: 999999999999 }}
                                              arrow
                                              className="customTooltip"
                                              classes={{
                                                tooltip: classes.tooltip,
                                              }}
                                              enterTouchDelay={1000}
                                              leaveTouchDelay={5000}
                                              placement="left"
                                              title={
                                                <>
                                                  <div className="text-white text-[10px] hover:cursor-pointer leading-[15px] z-[2] rounded p-1 w-full bg-tooltipbg">
                                                    <span className="flex w-full justify-center h-full items-center">
                                                      Minimum required hours for
                                                      work
                                                    </span>
                                                  </div>
                                                </>
                                              }
                                            >
                                              <div className="flex flex-wrap ">
                                                <QuestionIcon
                                                  width={16}
                                                  height={17}
                                                />
                                              </div>
                                            </Tooltip>
                                          </div>
                                        </label>
                                        <div className="p-2">
                                          <TimePicker
                                            showSecond={false}
                                            value={moment()
                                              .hours(Number(sundayDailyHours))
                                              .minute(
                                                Number(sundayDailyMinutes)
                                              )
                                              .second(
                                                Number(sundayDailySecond)
                                              )}
                                            defaultValue={now}
                                            className={
                                              styles.customTimePickerInput
                                            }
                                            onChange={onChangeDateStartSunday}
                                            format={formatDaily}
                                            use12Hours={false}
                                            popupClassName="z-[9999999]"
                                            allowEmpty={false}
                                            addon={() => (
                                              <button
                                                className="p-1 "
                                                onClick={handleClearFlexibleSun}
                                              >
                                                Clear
                                              </button>
                                            )}
                                          />
                                        </div>
                                      </div>
                                    ) : null}
                                  </div>
                                  <div
                                    className="mt-0 items-center  w-8 h-10 cursor-pointer flex justify-end"
                                    onClick={() => {
                                      handleShowFlexible(6);
                                    }}
                                  >
                                    {6 == flexibleStateData.current &&
                                    flexibleStateData.isShowing ? (
                                      <UpOpenIcon />
                                    ) : (
                                      <DownCloseIcon />
                                    )}
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                        <div className="px-0 mt-4">
                          <span className="text-graydark text-15 font-medium pr-2">
                            Total Working Hours
                          </span>
                          <span className="text-15 txt-black2 font-semibold">
                            {totalFlexibleHour +
                              " hr " +
                              totalFlexibleMinute +
                              " m"}
                          </span>
                        </div>
                      </>
                    ) : null}
                    {fixWorkHour === "flexible" &&
                    flexibleState === "weekly" ? (
                      <>
                        <div className="flex flex-col w-full">
                          <div className="max-h-[250px] overflow-y-auto">
                            {flexibleDaily?.map((work: any, i: any) => (
                              <FlexibleWorkingShiftList
                                key={i}
                                locationList={locationList}
                                work={work}
                                handleDelete={handleDelete}
                                setData={setFlexibleDaily}
                                setMultiFlexibleDaily={setMultiFlexibleDaily}
                                setMultiFlexibleDailyID={
                                  setMultiFlexibleDailyID
                                }
                                multiFlexibleDaily={multiFlexibleDaily}
                                isSubmit={isSubmit}
                              />
                            ))}
                          </div>
                          <div className="flex items-center">
                            <label className="text-black2 pr-5 text-15 font-medium font-primary mt-0 block mb-0">
                              Working Hours
                              <span className=" text-red-500">*</span>
                            </label>
                            <div className="p-2">
                              <input
                                onKeyDown={(evt) =>
                                  ["e", "E", "+", "-"].includes(evt.key) &&
                                  evt.preventDefault()
                                }
                                onWheel={(e: any) => e.target.blur()}
                                value={weeklyHours}
                                onChange={(e) => setWeeklyHours(e.target.value)}
                                type="number"
                                placeholder="Number"
                                className="focus:outline-none h-[48px] focus-visible:outline-none w-full rounded py-3.5 px-3 border border-goalinputborder text-black2 text-14 font-medium font-primary placeholder:text-graydark"
                              ></input>
                            </div>
                          </div>
                          <div className="px-0 mt-4">
                            <span className="text-graydark text-15 font-medium pr-2">
                              Total Working Hours
                            </span>
                            <span className="text-15 txt-black2 font-semibold">
                              {weeklyHours + " hr "}
                            </span>
                          </div>
                        </div>
                      </>
                    ) : null}
                  </div>
                </>
              ) : null}
              {tagsData === 3 ? (
                <div className="flex flex-col items-center w-full px-3">
                  <div className="flex justify-between items-center w-full">
                    <div className="flex items-center w-full mt-0">
                      <DebounceSearchInput
                        setState={setGlobalSearch}
                        debounce={800}
                      >
                        {(setState) => (
                          <div className="font-normal w-full flex items-center border-1 border-[#C4C4C4] bg-white hover:bg-[#EFEFEF] rounded-[5px]">
                            <input
                              type="text"
                              placeholder="Search"
                              className="focus:outline-none min-h-[43px] py-1 px-4 text-12 xl:text-14 leading-5 bg-transparent rounded-[28px] w-full text-graydark"
                              onChange={(e) => setState(e.target.value)}
                            />
                            <SearchIcon className="mr-2" />
                          </div>
                        )}
                      </DebounceSearchInput>
                    </div>
                    <div className="mb-0 last:mb-0 w-full px-3">
                      <label className="text-black2 text-15 font-medium font-primary mt-[0rem] block mb-0">
                        Department
                        {/* <span className=" text-red-500">*</span> */}
                      </label>
                      <StringDropdownList
                        state={departmentID}
                        setState={setDepartmentID}
                        placeholder="Select Department"
                        className="w-full py-3 pl-3 mb-5 text-14"
                        dropdownItems={departmentList?.map((status: any) => ({
                          value: status.id,
                          label: status.name,
                        }))}
                        textAlign={"text-left"}
                        fontSize="xl:text-15 text-12"
                      />
                      {/* <p
                        className={classNames(
                          isSubmit && departmentID === "" && nextTagsData === 3
                            ? ""
                            : "opacity-0",
                          "mb-0 font-poppins text-12 text-redPink"
                        )}
                      >
                        Department is required
                      </p> */}
                    </div>
                  </div>
                  <div className="flex justify-between items-center w-full">
                    <label
                      htmlFor="check-all"
                      className={classNames(
                        // showInfo == false ? "hidden" : "",
                        "flex mb-5 items-center hover:cursor-pointer"
                      )}
                    >
                      <div className="relative max-h-6">
                        <input
                          type="checkbox"
                          onChange={() => setSelectedAll()}
                          checked={isSelectedAll}
                          id="check-all"
                          className="checked:bg-vorpblue w-3 h-3 sm:w-4 sm:h-4 p-3 border-none outline-none appearance-none bg-offwhite rounded hover:cursor-pointer"
                        />
                        {isSelectedAll && (
                          <SelectedIcon className="absolute top-2 left-0 right-0 m-auto" />
                        )}
                      </div>
                      <p className="text-14 leading-5 text-[#3F4254] ml-3">
                        Select All
                      </p>
                    </label>
                    <div
                      className={classNames(
                        // showInfo == false ? "hidden" : "",
                        "border-b pb-[14px] border-[#F5F5F5] flex flex-col 4xs:flex-row justify-between 4xs:items-center"
                      )}
                    >
                      {/* <h1 className="text-[#5E6278] text-18 md:text-24 leading-9 font-semibold">
                        Members
                      </h1> */}
                      <div className="flex items-start justify-end">
                        <p className="text-12 md:text-15 leading-[22px] text-vorpblue mr-[10px]">
                          Selected members ({selectedMember.length})
                        </p>
                        <button
                          onClick={() => setClearAllSelectedData()}
                          type="button"
                          className="text-14 md:text-15 leading-[22px] text-black2"
                        >
                          Clear
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="max-h-[150px] sm:max-h-[200px] 7xl:max-h-[300px] overflow-y-auto mb-7 pr-3 w-full">
                    <h2 className="text-14 leading-5 text-[#5E6278] font-semibold mb-[23px]">
                      {totalEmployeeLength ? totalEmployeeLength : 0} members
                    </h2>
                    <div className="flex w-full">
                      <div className="w-full">
                        {employeeListData?.map((emp, index: number) => {
                          var className = selectedMember?.find(
                            (x) => x == emp.id
                          )
                            ? styles.selectedMember
                            : "";
                          return (
                            <UserSelectionTab
                              setSelectedMember={setSelectedCount}
                              id={emp.id}
                              index={index}
                              key={index}
                              className={"mb-3 " + className}
                              name={emp.name}
                              email={emp.email}
                              initialState={isSelectedAll}
                              icon={emp.photo}
                              isSelected={isClear}
                              setIsClear={setIsClear}
                              selectedMember={selectedMember}
                            />
                          );
                        })}
                      </div>
                      <div className="w-full">
                        {employeeListData2?.map((emp, index: number) => {
                          var className = selectedMember?.find(
                            (x) => x == emp.id
                          )
                            ? styles.selectedMember
                            : "";
                          return (
                            <UserSelectionTab
                              setSelectedMember={setSelectedCount}
                              id={emp.id}
                              index={index}
                              key={index}
                              className={"mb-3 " + className}
                              name={emp.name}
                              email={emp.email}
                              initialState={isSelectedAll}
                              icon={emp.photo}
                              isSelected={isClear}
                              setIsClear={setIsClear}
                              selectedMember={selectedMember}
                            />
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
              <div className="px-2 pb-5 pt-5 flex justify-between items-center">
                <div className="flex flex-wrap">
                  <div className="flex px-2 justify-center items-center">
                    {nextTagsData === 1 ? (
                      <Button
                        type="button"
                        label={
                          <span className="flex items-center text-12 leading-[18px]">
                            <SendIcon className="mr-[10px] group-hover:opacity-1 transition-all duration-300" />
                            <span>Next</span>
                          </span>
                        }
                        onClick={() => {
                          handleSubmit();
                        }}
                        variant="no-outline"
                        size="lg"
                        customClass={classNames(
                          // uploading ? " pointer-events-none opacity-50" : "",
                          "hover:bg-vorpblue mr-2 py-2 lg:py-2 px-3 custom-button-size"
                        )}
                      />
                    ) : null}

                    {nextTagsData >= 2 &&
                    !isCreate &&
                    !isEditing &&
                    !isDuplicate ? (
                      <Button
                        type="button"
                        label={
                          <span className="flex items-center text-12 leading-[18px]">
                            <NextIcon
                              width={14}
                              height={14}
                              className="mr-[10px] group-hover:opacity-1 transition-all duration-300"
                            />
                            <span>Save</span>
                          </span>
                        }
                        onClick={() => {
                          handleSubmit();
                        }}
                        variant="no-outline"
                        size="lg"
                        customClass={classNames(
                          // uploading ? " pointer-events-none opacity-50" : "",
                          "hover:bg-vorpblue mr-2 py-2 lg:py-2 px-3 custom-button-size"
                        )}
                      />
                    ) : null}

                    {nextTagsData >= 2 && isCreate ? (
                      <Button
                        type="button"
                        label={
                          <span className="flex items-center text-12 leading-[18px]">
                            <NextIcon
                              width={14}
                              height={14}
                              className="mr-[10px] group-hover:opacity-1 transition-all duration-300"
                            />
                            <span>Save</span>
                          </span>
                        }
                        onClick={() => {
                          handleSubmit();
                        }}
                        variant="no-outline"
                        size="lg"
                        customClass={classNames(
                          uploading ? " pointer-events-none opacity-50" : "",
                          "hover:bg-vorpblue mr-2 py-2 lg:py-2 px-3 custom-button-size"
                        )}
                      />
                    ) : null}

                    {nextTagsData >= 2 && isEditing ? (
                      <Button
                        type="button"
                        label={
                          <span className="flex items-center text-12 leading-[18px]">
                            <NextIcon
                              width={14}
                              height={14}
                              className="mr-[10px] group-hover:opacity-1 transition-all duration-300"
                            />
                            <span>Update</span>
                          </span>
                        }
                        onClick={() => {
                          handleSubmit();
                        }}
                        variant="no-outline"
                        size="lg"
                        customClass={classNames(
                          uploading ? " pointer-events-none opacity-50" : "",
                          "hover:bg-vorpblue mr-2 py-2 lg:py-2 px-3 custom-button-size"
                        )}
                      />
                    ) : null}

                    {nextTagsData >= 2 && isDuplicate ? (
                      <Button
                        type="button"
                        label={
                          <span className="flex items-center text-12 leading-[18px]">
                            <NextIcon
                              width={14}
                              height={14}
                              className="mr-[10px] group-hover:opacity-1 transition-all duration-300"
                            />
                            <span>Duplicate</span>
                          </span>
                        }
                        onClick={() => {
                          handleSubmit();
                        }}
                        variant="no-outline"
                        size="lg"
                        customClass={classNames(
                          uploading ? " pointer-events-none opacity-50" : "",
                          "hover:bg-vorpblue mr-2 py-2 lg:py-2 px-3 custom-button-size"
                        )}
                      />
                    ) : null}

                    {/* <Button
                      type="button"
                      label={
                        <span className="flex items-center text-12 leading-[18px]">
                          <NextIcon width={14} height={14} className="mr-[10px] group-hover:opacity-1 transition-all duration-300" />
                          {tagsData === 1 ? (
                            <span onClick={handleSubmit}>Next</span>
                          ) : null}
                          {tagsData === 2 ? (
                            <span onClick={handleSubmit}>Save</span>
                          ) : null}
                          {tagsData === 3 && isCreate ? (
                            <span onClick={handleSubmit}>Save</span>
                          ) : null}
                          {tagsData === 3 && isEditing ? (
                            <span onClick={handleSubmit}>Update</span>
                          ) : null}
                          {tagsData === 3 && isDuplicate ? (
                            <span onClick={handleSubmit}>Duplicate</span>
                          ) : null}
                        </span>
                      }
                      onClick={() => {
                        // handleSubmit();
                      }}
                      variant="no-outline"
                      size="lg"
                      customClass={classNames(
                        uploading ? " pointer-events-none opacity-50" : "",
                        "hover:bg-vorpblue mr-2 py-2 lg:py-2 px-3 custom-button-size"
                      )}
                    /> */}

                    <Button
                      type="button"
                      label={
                        <span className="flex items-center text-12 leading-[18px] ">
                          <CloseIcon
                            width={14}
                            height={14}
                            className="mr-[10px] [&_path]:fill-graydark"
                          />
                          Cancel
                        </span>
                      }
                      onClick={() => {
                        setIsOpen(false);
                        setIsSubmit(false);
                        setEditData("");
                        setErrorMsg("");
                        clearData();
                        setUploading(false);
                        setIsSubmit(false);
                        clearStateData();
                      }}
                      variant="no-outline"
                      size="lg"
                      customClass=" mr-2 py-2 lg:py-2 px-3 bg-offwhite text-black2 border-none custom-button-size"
                    />
                  </div>
                </div>
                <p
                  className={classNames(
                    isSubmit || errorMsg || page1Validation || page2Validation
                      ? ""
                      : "hidden",
                    "mb-2 font-poppins text-12 text-redPink"
                  )}
                >
                  <div>
                    {" "}
                    {page1Validation
                      ? "Please fill required data at shift information"
                      : ""}
                  </div>
                  <div>
                    {" "}
                    {page1Validation
                      ? "Please fill required data at shift configuration"
                      : ""}
                  </div>
                  <div> {errorMsg}</div>
                </p>
              </div>
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default WorkingShiftPopup;
