import { Modal } from "@mui/material";

import { Dispatch, FC, SetStateAction } from "react";
import QuotationNumAddModal from "./QuotationNumModal";
import ProjectScopeModal from "./TextAreaModal";
import { MODALS, TModalState } from "../ProjectsTable";
import { baseObj } from "../../../utils/constants";
import ChecklistModal from "./ChecklistModal";
import KeyDates from "./KeyDates";
import { EmployeeData } from "../../EmployeeTable/EmployeeTable";
// import { KeyDateData } from "../../../app/services/project";
import { IFilterStates } from "../../../projects/views/ProjectList";

interface IModal {
  state: TModalState;
  setState: Dispatch<SetStateAction<TModalState>>;
  rowId: number;
  getPJScope: string;
  data: baseObj[];
  setData: Dispatch<SetStateAction<baseObj[]>>;
  addNewItemData?: any;
  checkListData: EmployeeData[];
  addCheckListData?: any;
  addQuotationData?: any;
  keyDateDataList?: EmployeeData[];
  quotationList?: string;
  // setFilterStates: Dispatch<SetStateAction<IFilterStates>>;
  setFilterStates: any;
  filterStates: IFilterStates;
  updateFilterState: any;
  addKeyDateData: any;
  removeQuotation: any;
  removeKeyDates: any;
  checkManagement: boolean;
}

export const Modals: FC<IModal> = ({
  state,
  setState,
  rowId,
  data,
  setData,
  getPJScope,
  addNewItemData,
  checkListData,
  addCheckListData,
  addQuotationData,
  keyDateDataList,
  filterStates,
  setFilterStates,
  updateFilterState,
  addKeyDateData,
  removeQuotation,
  removeKeyDates,
  checkManagement,
}) => {
  const targetId = Object.entries(state).filter(
    ([key, value]) => value
  )?.[0]?.[0];

  const closeModal = () => {
    setState((prev) => ({ ...prev, [targetId]: false }));
  };

  // const targetRow = data?.filter((row) => row?.id === rowId)[0];
  const targetRow = data?.filter((row) => row?.uniqeID === rowId)[0];
  return (
    <Modal
      open={Boolean(targetId)}
      style={{
        background: "rgb(0 0 0 / 0.5)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      onClose={() => closeModal()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <>
        {(() => {
          switch (targetId) {
            case MODALS.QUOTATION_NUM:
              return (
                <QuotationNumAddModal
                  closeModal={closeModal}
                  addNewItemData={addNewItemData}
                  setData={setData}
                  data={data}
                  rowId={rowId}
                  keyname={targetId}
                  addQuotationData={addQuotationData}
                  removeQuotation={removeQuotation}
                />
              );
            case MODALS.PROJECT_SCOPE:
              return (
                <ProjectScopeModal
                  title="Project Scope"
                  keyname={targetId}
                  initialValue={targetRow?.projectScope}
                  rowId={rowId}
                  getScope={getPJScope}
                  setData={setData}
                  checkManagement={checkManagement}
                />
              );
            case MODALS.REMARKS:
              return (
                <ProjectScopeModal
                  title="Remarks"
                  keyname={targetId}
                  initialValue={targetRow?.remarks}
                  rowId={rowId}
                  setData={setData}
                  getScope={getPJScope}
                  checkManagement={checkManagement}
                />
              );
            case MODALS.CHECKLIST:
              return (
                <ChecklistModal
                  closeModal={closeModal}
                  addCheckListData={addCheckListData}
                  initialValue={targetRow?.checkList}
                  setData={setData}
                  rowId={rowId}
                  checkListData={checkListData}
                  data={data}
                  checkManagement={checkManagement}
                />
              );
            case MODALS.KEYDATES:
              return (
                <KeyDates
                  initialValue={targetRow?.keyDate}
                  data={data}
                  setData={setData}
                  rowId={rowId}
                  keyDateDataList={keyDateDataList}
                  filterStates={filterStates}
                  setFilterStates={setFilterStates}
                  updateFilterState={updateFilterState}
                  addKeyDateData={addKeyDateData}
                  removeKeyDates={removeKeyDates}
                  checkManagement={checkManagement}
                />
              );
            default:
              return <></>;
          }
        })()}
      </>
    </Modal>
  );
};

// TODO deleted after code refractoring
export const QuotationNumModal: FC<{
  state: boolean;
  setState: Dispatch<SetStateAction<boolean>>;
}> = ({ state, setState }) => {
  return <></>;
};
