import classNames from "classnames";
import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import Styles from "./CustomTimePicker.module.scss";
import { baseObj } from "../../utils/constants";
import { Cell } from "@tanstack/react-table";
interface ICustomTimePicker {
  selectedValue: number;
  setData?: Dispatch<SetStateAction<baseObj[]>>;
  cell?: any;
  id?: number;
  maxTimeLimit: number;
  setMaxTimeLimit: any;
  handleTimeBlur: any;
  currentNumber: string;
  setCurrentNumber: any;
  currentValue: number;
  setCurrentValue: any;

  // cell?: Cell<any, any>;
}
let oldSliderValue = '00:00:00';
export const changeStringToHMS = (timeStr: string) => {
  let regExTime = /([0-9]?[0-9]):([0-9][0-9]):([0-9][0-9])/;
  let regExTimeArr: any = regExTime.exec(timeStr);
  if (regExTimeArr) {
    let timeHr = regExTimeArr[1] * 3600 * 1000;
    let timeMin = regExTimeArr[2] * 60 * 1000;
    let timeSec = regExTimeArr[3] * 1000;

    let timeMs = timeHr + timeMin + timeSec;
    var time = timeMs / 1000;
    return time;
  }
};
export const addZeroToSingle = (value: number) => {
  if (value.toString().length < 2) {
    return "0" + value.toString();
  } else {
    return value.toString();
  }
};
export const msToTime = (durationData: any) => {
  const duration=durationData*1000;
  var milliseconds = Math.floor((duration % 1000) / 100),
    seconds = Math.floor((duration / 1000) % 60),
    minutes = Math.floor((duration / (1000 * 60)) % 60),
    hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

  let h = hours < 10 ? "0" + hours : hours;
  let m = minutes < 10 ? "0" + minutes : minutes;
  let s = seconds < 10 ? "0" + seconds : seconds;

  return h + ":" + m + ":" + s;
};
const CustomTimePicker: FC<ICustomTimePicker> = ({
  selectedValue,
  setData,
  cell,
  id,
  maxTimeLimit,
  setMaxTimeLimit,
  handleTimeBlur
}) => {
  const toMinutes = (str: any) =>
    str.split(":").reduce((h: any, m: any) => h * 60 + +m);

  const toString = (min: any) =>
    (
      Math.floor(min / 60) +
      ":" +
      ((min % 60).toString().length > 1 ? min % 60 : "0" + (min % 60))
    ).replace(/\b\d\b/, "0$&");

  const slots = (startStr: string, endStr = "08:00:00") => {
    let start = toMinutes(startStr);
    let end = toMinutes(endStr);
    return Array.from({ length: Math.floor((end - start) / 1) + 1 }, (_, i) =>
      toString(start + i * 1)
    );
  };

  const [currentNumber, setCurrentNumber] = useState<string>(
    selectedValue ? selectedValue.toString() : "08:00:00"
  );
  const [currentValue, setCurrentValue] = useState<number>(selectedValue);
  const [curValue, setCurValue] = useState<string>("00:00:00");

  const updateSlider = (value: number) => {
    var hours = Math.floor(value / 60 / 60);
    var minutes = Math.floor(value / 60) - hours * 60;
    var seconds = value % 60;
    return (
      addZeroToSingle(hours) +
      ":" +
      addZeroToSingle(minutes) +
      ":" +
      addZeroToSingle(Math.ceil(seconds))
    );
  };


  const handleTimeChange = (e: any) => {
    var datavalue = updateSlider(e.target.value);
    if (cell.maxLimit < maxTimeLimit) {
      oldSliderValue = datavalue;
    }
    var time = changeStringToHMS(datavalue) || 0;
    const data = handleTimeBlur(datavalue, time, id || 0);
  };

  const handleTimeBlurData = () => {
    if (setData) {
      setData((prev: baseObj[]) => {
        return prev.map((data: baseObj) => {
          if (data.uniqueId == Number(id)) {
            return {
              ...data,
              hours: `${currentNumber.slice(0, 2)}`,
              minutes: `${currentNumber.slice(3, 5)}`,
              time_spent: `${currentNumber.slice(0, 2)}:${currentNumber.slice(
                3,
                5
              )}:${currentNumber.slice(
                6,
                8
              )}`,
            };
          }
          return data;
        });
      });
    }
  };

  const setBubble = (range: any, bubble: any) => {
    const val = range.value;
    const min = range.min ? range.min : 0;
    const max = range.max ? range.max : 100;
    const newVal = Number(((val - min) * 100) / (max - min));
    bubble.innerHTML = val;
    if (newVal < 80) {
      bubble.style.left = `calc(${newVal}% + (${8 - newVal * 0.15}px))`;
      bubble.style.right = "unset";
    } else {
      bubble.style.right = "0px";
      bubble.style.left = "unset";
    }
    bubble.innerHTML = currentNumber;
  };

  useEffect(() => {
    var time = changeStringToHMS(currentNumber);
    var numberSlot = document.getElementById("numberSlot_" + cell?.uniqueId);
    var numberInput = document.getElementById("myRange_" + cell?.uniqueId);
    if (numberSlot) {
      numberSlot.innerHTML =
        '<div class="number text-[12px]' +
        // classes +
        '">' +
        currentNumber +
        "</div>";
    }

    if (numberInput && time != undefined) {
      var value = ((time - 0) / (maxTimeLimit - 0)) * 100;
      numberInput.style.background =
        "linear-gradient(to right, #3699FF 0%, #3699FF " +
        value +
        "%, #E1F0FF " +
        value +
        "%, #E1F0FF 100%)";
    }
    setCurrentValue(time ? time : 0);
  }, [currentNumber]);

  useEffect(() => {
    var numberSlot = document.getElementById("numberSlot_" + cell?.uniqueId);
    var numberInput = document.getElementById("myRange_" + cell?.uniqueId);
    setBubble(numberInput, numberSlot);
  }, [currentValue]);

  useEffect(() => {
    setCurrentNumber(selectedValue.toString());
  }, [selectedValue]);
  const options: any = {
    timeZone: "Asia/Hong_Kong",
    hour12: false,
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  };

  return (
    <div className="flex items-center m-[5px] w-full relative">
      <input
        onChange={handleTimeChange}
        onMouseUp={handleTimeChange}
        type="range"
        min="0"
        step={0.1}
        max={maxTimeLimit > 0 ? maxTimeLimit : 0}
        // defaultValue={currentValue}
        value={maxTimeLimit > 0 ? currentValue : 0}
        className={classNames(Styles.sliderInput, "slider w-full z-10 min-w-[50px]")}
        id={"myRange_" + cell?.uniqueId}
      />
      <div
        className={classNames(
          Styles.numberSlot,
          "number-slot h-[24px] overflow-hidden absolute -top-1 z-0 text-[12px]"
        )}
        id={"numberSlot_" + cell?.uniqueId}
      >
        <div className="number flex flex-col h-[24px]">
          <div className="number text-[12px] ">{currentNumber}</div>
        </div>
      </div>
    </div>
  );
};
export default CustomTimePicker;
