import authHeader from "./auth-header";
import { api, endpointUrl } from "./api";
import { Employee } from "./employee";
import { EmployeeListData } from "../../components/EmployeeTable/EmployeeTable";
import { baseObj } from "../../utils/constants";
import { redirectToLogin } from "./annoucement-sheet";
import {
  TClaimType,
  TClaimTypeResponse,
  TRequestClaimSave,
} from "../../claim-type/claim.modal";

export type PermissionResponse = {
  data: EmployeeList[];
};

export type EmployeeList = {
  id: number;
  name: string;
  icon: string;
  email: string;
};
export type createdBy = {
  name: string;
  createdAt: Date;
};
export type AssignData = {
  id: number;
  name: string;
  email: string;
  profileImage: string;
};

export type PasswordResponse = {
  module_id: number;
  passwords: Password;
  passwordUserList: PasswordUserList[];
  // show_password: boolean
};

export type PasswordResponseMutation = {
  data: DD;
};

export type DD = {
  data: Datas;
};

export type Datas = {
  module_id: number;
  passwords: Password;
  passwordUserList: PasswordUserList[];
  // show_password: boolean
};

export type Password = {
  data: Pwd[];
  total: number;
  limit?: number | string;
  page?: number | string;
  tag?: number | string;
  share_to?: number | string;
  search?: number | string;
  sort?: string;
  sortBy?: any;
};

export type Pwd = {
  id: number;
  platform: string;
  account: string;
  password: string;
  links: string;
  department: string;
  createdBy: createdBy;
  assignUsers: AssignData[];
};

export type PasswordData = {
  platform: string;
  account: string;
  password: string;
  links: string;
  department: string;
  createdBy: createdBy;
  assignUsers: AssignData[];
  tags?: number[];
  id?: number;
};
export type PasswordDataHR = {
  platform: string;
  company: string;
  account: string;
  password: string;
  links: string;
  tags: number[];
  user_ids?: number[];
};
export type DepartmentResponse = { departmentList: KeyValueLabel[] };

export type KeyValueLabel = {
  id: number;
  name: string;
  label?: string;
};

export type PasswordUserList = {
  id: number;
  name: string;
  email: string;
  profile_image: string;
  photo: string;
};
export type AssignDataList = {
  password_ids: number[];
  user_ids: number[];
};
export type AssignDataSingle = {
  password_ids: number;
  user_ids: number[];
};

export interface IOnePasswordResponse {
  platform: string;
  account: string;
  password: string;
  links: string;
  createdBy: createdBy;
  assignUsers: AssignData[];
  tags?: number[];
  id?: number;
}

export type ResponseOnePassword = {
  password: IOnePasswordResponse;
};
export interface IOnePasswordRequest {
  id: number;
}

export type ResponseKeyPassword = {
  publicKey: string;
};

export type ResponseIsPwdKey = {
  can_see: boolean;
};

export type RequestUserList = {
  search: string;
  department_id: number | string;
};
export interface assignUsers {
  id: number;
  name: string;
  email: string;
  profile_image: string;
  photo: string;
  label?: string | number;
}
export type ResponseUserList = {
  users: assignUsers[];
};

export const claimTypeApi = api.injectEndpoints({
  endpoints: (build) => ({
    getPasswordData: build.mutation<
      PasswordResponseMutation,
      Partial<Password>
    >({
      query: ({ limit }) => ({
        url: `${endpointUrl}passwordsListData?limit=${limit}`,
        headers: authHeader(),
        method: "GET",
      }),
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
      invalidatesTags: [{ type: "PasswordList", id: "LIST" }],
    }),

    getClaimTypeList: build.query<TClaimTypeResponse, Partial<TClaimType>>({
      query: ({ page, limit, status, search,limited,sorting }) => ({
        url: `${endpointUrl}claim-types?page=${page}&limit=${limit}&status=${status}&search=${search}&limited=${limited}&sort=${sorting}`,
        headers: authHeader(),
        method: "GET",
      }),
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
      providesTags: () => [{ type: "ClaimTypeList" as const, id: "LIST" }],
    }),

    createClaimType: build.mutation<PasswordData, Partial<TRequestClaimSave>>({
      query: (data) => ({
        url: `${endpointUrl}claim-types`,
        headers: authHeader(),
        method: "POST",
        body: data,
      }),
      invalidatesTags: [{ type: "ClaimTypeList", id: "LIST" }],
    }),

    updatedClaimType: build.mutation<PasswordData, Partial<TRequestClaimSave>>({
      query: (data) => ({
        url: `${endpointUrl}claim-types/${data.id}`,
        headers: authHeader(),
        method: "PUT",
        body: data,
      }),

      invalidatesTags: [{ type: "ClaimTypeList", id: "LIST" }],
    }),

    deleteClaimType: build.mutation<{ id: number }, number>({
      query: (id) => ({
        url: `${endpointUrl}claim-types/${id}`,
        method: "DELETE",
        headers: authHeader(),
      }),
      invalidatesTags: ["ClaimTypeList"],
    }),

    multiDeleteClaimType: build.mutation< { success: boolean; ids: number[] }, number[]>({
      query: (ids) => ({
        url: `${endpointUrl}delete-claim-types`,
        headers: authHeader(),
        method: "DELETE",
        body: { ids },
      }),
      invalidatesTags: (Password) => [{ type: "ClaimTypeList" }],
    }),







    getSeePassword: build.mutation<PasswordResponse, any>({
      query: (data) => ({
        url: `${endpointUrl}seeAllPasswords?page=${data?.page}&limit=${data?.limit}&tag=${data?.tag}&share_to=${data?.share_to}&search=${data?.search}`,
        headers: authHeader(),
        method: "POST",
        body: data,
      }),
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
      transformResponse: (res: any) => {
        return res;
      },
      invalidatesTags: [{ type: "PasswordList", id: "LIST" }],
    }),

    getSeeMyPassword: build.mutation<PasswordResponse, any>({
      query: (data) => ({
        url: `${endpointUrl}profile/passwords/see-all?page=${data?.page}&limit=${data?.limit}&search=${data?.search}`,
        headers: authHeader(),
        method: "POST",
        body: data,
      }),
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
      transformResponse: (res: any) => {
        // console.log("r", res)
        return res;
      },
      invalidatesTags: [{ type: "MyPasswordList", id: "LIST" }],
    }),

    getOnePassword: build.query<ResponseOnePassword, IOnePasswordRequest>({
      query: ({ id }) => ({
        url: `${endpointUrl}get-password/${id}`,
        headers: authHeader(),
        method: "GET",
      }),
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
      providesTags: () => [{ type: "OnePasswordList" as const, id: "LIST" }],
    }),

    getPasswordKey: build.query<ResponseKeyPassword, void>({
      query: () => ({
        url: `${endpointUrl}system-public-key`,
        headers: authHeader(),
        method: "GET",
      }),
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
      providesTags: () => [{ type: "KeyPasswordList" as const, id: "LIST" }],
    }),

    getIsPwdKey: build.mutation<ResponseIsPwdKey, void>({
      query: () => ({
        url: `${endpointUrl}can-see-password`,
        headers: authHeader(),
        method: "GET",
      }),
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
      invalidatesTags: [{ type: "IsKeyPwd", id: "LIST" }],
    }),

    getSeeOnePassword: build.mutation<PasswordResponse, any>({
      query: (data) => ({
        url: `${endpointUrl}passwords/${data?.id}/see`,
        headers: authHeader(),
        method: "POST",
        body: data,
      }),
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
      transformResponse: (res: any) => {
        return res;
      },
      invalidatesTags: [{ type: "PasswordList", id: "LIST" }],
    }),

    getDepartments: build.query<DepartmentResponse, void>({
      query: () => ({
        url: `${endpointUrl}departments`,
        headers: authHeader(),
      }),
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
      transformResponse: (res: any) => {
        return res;
      },
    }),
    //get assign pwdd

    getUserListAssign: build.query<ResponseUserList, RequestUserList>({
      query: ({ search, department_id }) => ({
        url: `${endpointUrl}user-list?department_id=${department_id}&search=${search}`,
        headers: authHeader(),
      }),
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
      transformResponse: (res: any) => {
        return res;
      },
      providesTags: () => [{ type: "UserAssign" }],
    }),

    createHRPassword: build.mutation<PasswordData, Partial<PasswordDataHR>>({
      query: (data) => ({
        url: `${endpointUrl}passwords`,
        headers: authHeader(),
        method: "POST",
        body: data,
      }),
      invalidatesTags: [{ type: "PasswordList", id: "LIST" }],
    }),

    createAuthPassword: build.mutation<PasswordData, Partial<PasswordDataHR>>({
      query: (data) => ({
        url: `${endpointUrl}savePassword`,
        headers: authHeader(),
        method: "POST",
        body: data,
      }),
      invalidatesTags: [{ type: "MyPasswordList", id: "LIST" }],
    }),

    

    deleteMyPwd: build.mutation<{ id: number }, number>({
      query: (id) => ({
        url: `${endpointUrl}deletePassword/${id}`,
        method: "DELETE",
        headers: authHeader(),
      }),
      // invalidatesTags: ["PasswordList"],
      invalidatesTags: [{ type: "MyPasswordList", id: "LIST" }],
    }),



    assignPasswords: build.mutation<
      { success: boolean; data: AssignDataList },
      number
    >({
      query: (data) => ({
        url: `${endpointUrl}assignPasswords`,
        headers: authHeader(),
        method: "POST",
        body: data,
      }),
      invalidatesTags: (result, error, id) => [
        { type: "PasswordList", id: "LIST" },
      ],
    }),

    assignPassword: build.mutation<Password, Partial<AssignDataSingle>>({
      query: (body) => ({
        url: `${endpointUrl}assignPassword/${body.password_ids}`,
        method: "POST",
        body,
        headers: authHeader(),
      }),
      invalidatesTags: (permissions) => [{ type: "PasswordList", id: "LIST" }],
    }),
  }),
});

export const {
  useGetClaimTypeListQuery,
  useGetPasswordKeyQuery,
  useGetUserListAssignQuery,
  useGetOnePasswordQuery,
  useGetDepartmentsQuery,
  useCreateClaimTypeMutation,
  useGetSeePasswordMutation,
  useGetSeeOnePasswordMutation,
  useGetPasswordDataMutation,
  useDeleteClaimTypeMutation,
  useDeleteMyPwdMutation,
  useMultiDeleteClaimTypeMutation,
  useCreateHRPasswordMutation,
  useCreateAuthPasswordMutation,
  useAssignPasswordsMutation,
  useAssignPasswordMutation,
  useGetSeeMyPasswordMutation,
  useGetIsPwdKeyMutation,
  useUpdatedClaimTypeMutation,
} = claimTypeApi;

export const {
  endpoints: {
    getClaimTypeList,
    getDepartments,
    createClaimType,
    multiDeleteClaimType,
    deleteClaimType,
    deleteMyPwd,
    createHRPassword,
    assignPasswords,
    assignPassword,
    getSeePassword,
    getSeeOnePassword,
    createAuthPassword,
    getSeeMyPassword,
    getOnePassword,
    getPasswordKey,
    getPasswordData,
    getIsPwdKey,
    getUserListAssign,
    updatedClaimType,
  },
} = claimTypeApi;
