import PasswordResetSuccessComponent from '../components/PasswordResetSuccessComponent';

function PasswordResetSuccess() {
  return (
    <>      
      <PasswordResetSuccessComponent />
    </>
  );
}

export default PasswordResetSuccess;
