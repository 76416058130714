import axios from 'axios';
import { endpointUrl } from './app/services/api';
import authHeader from './app/services/auth-header';
axios.defaults.baseURL = endpointUrl;
var userData = localStorage.getItem("userData");
  var userJson = {};
  if (userData) {
    userJson = JSON.parse(userData);
    userJson.token =userJson.accessToken;
  }
axios.defaults.headers.common = {'Authorization': `bearer ${userJson.token}`}
export default axios.create({
  baseURL: endpointUrl,
  headers: authHeader(),
});