import { FC, useEffect, useMemo, useState } from "react";
import {
  // ColumnDef,
  // ColumnFiltersState,
  // createColumnHelper,
  PaginationState,
  // RowSelectionState,
  SortingState,
} from "@tanstack/table-core";
import classNames from "classnames";
// import TimesheetTable from "../../TimesheetTable/TimesheetTable";
import {
  // useGetTimesheetDataQuery,
  useGetMyTimesheetQuery,
} from "../../../app/services/timesheet";
// import ProfileTimesheetTable from "../../TimesheetTable/TimesheetTable";
import { useLocation } from "react-router-dom";
import { useSpring, animated } from "react-spring";
import CommonTableSkeleton from "../../TableSkeleton/CommonTableSkeleton";
import { NumberDropdownList } from "../../common/DropdownList";
import MyTimesheetTable from "../../TimesheetTable/MyTimesheetTable";
import TablePaginator, { hideAllNoneField } from "../../Tables/TablePaginator";

const defaultFields = [
  "date",
  "employee",
  "clockIn1",
  "clockOut1",
  "clockIn2",
  "clockOut2",
  "clockIn3",
  "clockOut3",
  "location1",
  "location2",
  "location3",
  "total_hours",
];
interface IMyTimeSheet {}

const MyTimeSheet: FC<IMyTimeSheet> = () => {
  const location = useLocation();
  //var id = location.state;
  const [state, setState] = useState(localStorage.getItem("state"));
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  //var id = state ? Number(state) : 0;
  var currentUrl = window.location.pathname
    .split("/employeeprofile/")[1]
    ?.split("/")[0];
  var id = currentUrl ? parseInt(currentUrl) : 0;
  useEffect(() => {
    localStorage.removeItem("state");
  }, []);
  useEffect(() => {
    if (location.state) {
      setState(location.state);
    }
  }, [location]);

  // useEffect(() => {}, [state]);
  const [limit, setLimit] = useState<number>(10);
  const [hiddenFields, setHiddenFields] = useState<string[]>(defaultFields);
  const [timeSheetData, setTimeSheetData] = useState<any>([]);
  // const columnHelper = useMemo(() => createColumnHelper<any>(), []);
  const [itemCount, setItemCount] = useState<number>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [sorting, setSorting] = useState<SortingState>([]);
  // const columns = useMemo(
  //   () => [
  //     columnHelper.accessor("date", {
  //       header: "Date",
  //       id: "date",
  //     }),
  //     columnHelper.accessor("employee", {
  //       header: "Employee",
  //       id: "employee",
  //     }),
  //     columnHelper.accessor("clockIn1", {
  //       header: "Clock In 1",
  //       id: "clockIn1",
  //     }),
  //     columnHelper.accessor("clockOut1", {
  //       header: "Clock Out 1",
  //       id: "clockOut1",
  //     }),
  //     columnHelper.accessor("location1", {
  //       header: "Location",
  //       id: "location1",
  //     }),
  //     columnHelper.accessor("clockIn2", {
  //       header: "Clock In 2",
  //       id: "clockIn2",
  //     }),
  //     columnHelper.accessor("clockOut2", {
  //       header: "Clock Out 2",
  //       id: "clockOut2",
  //     }),
  //     columnHelper.accessor("location2", {
  //       header: "Location",
  //       id: "location2",
  //     }),
  //     columnHelper.accessor("clockIn3", {
  //       header: "Clock In 3",
  //       id: "clockIn3",
  //     }),
  //     columnHelper.accessor("clockOut3", {
  //       header: "Clock Out 3",
  //       id: "clockOut3",
  //     }),
  //     columnHelper.accessor("location3", {
  //       header: "Location",
  //       id: "location3",
  //     }),
  //   ],
  //   [columnHelper]
  // );
  
  const { data, isLoading, isFetching } = useGetMyTimesheetQuery({
    id,
    limit: pagination?.pageSize,
    page: currentPage,
    sort: sorting.map((s) => `${s.desc ? "-" : ""}${s.id}`).join(","),
  });

  useEffect(() => {
    if (data) {
      if (data?.timesheets?.data?.length > 0) {
        setTimeSheetData(data?.timesheets?.data);
        setItemCount(data?.timesheets?.total);
      } else {
        setTimeSheetData([]);
      }
    }
    hideAllNoneField();
  }, [data, limit, currentPage]);

  useEffect(() => {
    setCurrentPage(1);
  }, [limit]);

  const props = useSpring({
    to: { opacity: 1 },
    from: { opacity: 0 },
    delay: 500,
  });

  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  // console.log("timeSheetData", timeSheetData);

  return (
    <>
      {isLoading ? (
        <CommonTableSkeleton rows={12} isProfile={true} />
      ) : (
        <animated.div style={props}>
          {windowSize[0] < 640 ? (
            <div className="w-full bg-white rounded-xl text-left p-5">
              <div className="py-3 border-b border-offwhite flex items-center justify-between">
                <h3 className="text-black2 font-semibold text-[14px] leading-[22px] ">
                  My Timesheet
                </h3>
              </div>
              <div className="max-h-[440px] min-h-[430px] overflow-y-auto">
                {timeSheetData?.length
                  ? timeSheetData.map((data: any, i: any) => (
                      <div
                        key={i}
                        className="px-3 py-4 bg-[#F3F6F9] rounded-md gap-10 w-full mb-3"
                      >
                        <div className="w-full">
                          <p className="leading-loose inline-block pr-2  text-12 font-normal text-black2 text-start">
                            {data.date ?? "---"}
                          </p>
                        </div>
                        <div className=" py-2 w-full">
                          <div className="w-full">
                            <p className="leading-loose text-10 inline pr-3 font-normal text-graydark text-start">
                              Employee
                            </p>
                            <p className="leading-loose inline text-12 text-black2 font-normal text-end">
                              {data.employee}
                            </p>
                          </div>
                        </div>
                        <hr color="blue" />
                        <div className="flex pt-3 w-full">
                          <p className="leading-loose text-10 w-1/2 font-normal text-graydark text-start">
                            ClockIn1
                          </p>
                          <p className=" leading-loose text-black2 w-1/2 text-10 font-normal text-start">
                            {data.clockIn1 ?? "---"}
                          </p>
                        </div>
                        <div className="flex">
                          <p className="text-10 w-1/2 font-normal text-graydark text-start leading-loose">
                            ClockOut1
                          </p>
                          <p className=" text-black2 w-1/2 text-10 font-normal text-start leading-loose">
                            {data.clockOut1 ?? "---"}
                          </p>
                        </div>
                        <div className="flex pb-3">
                          <p className="text-10 w-1/2 font-normal text-graydark text-start leading-loose">
                            Location1
                          </p>
                          <p className=" text-vorpblue underline cursor-pointer w-1/2 text-10 font-normal text-start leading-loose">
                            {data.location1 ?? "---"}
                          </p>
                        </div>
                        <hr color="blue" />
                        <div className="flex pt-3 w-full">
                          <p className="leading-loose text-10 w-1/2 font-normal text-graydark text-start">
                            ClockIn2
                          </p>
                          <p className=" leading-loose text-black2 w-1/2 text-10 font-normal text-start">
                            {data.clockIn2 ?? "---"}
                          </p>
                        </div>
                        <div className="flex">
                          <p className="text-10 w-1/2 font-normal text-graydark text-start leading-loose">
                            ClockOut2
                          </p>
                          <p className=" text-black2 w-1/2 text-10 font-normal text-start leading-loose">
                            {data.clockOut2 ?? "---"}
                          </p>
                        </div>
                        <div className="flex pb-3">
                          <p className="text-10 w-1/2 font-normal text-graydark text-start leading-loose">
                            Location2
                          </p>
                          <p className=" text-vorpblue underline cursor-pointer w-1/2 text-10 font-normal text-start leading-loose">
                            {data.location2 ?? "---"}
                          </p>
                        </div>
                        <hr color="blue" />
                        <div className="flex pt-3 w-full">
                          <p className="leading-loose text-10 w-1/2 font-normal text-graydark text-start">
                            ClockIn3
                          </p>
                          <p className=" leading-loose text-black2 w-1/2 text-10 font-normal text-start">
                            {data.clockIn3 ?? "---"}
                          </p>
                        </div>
                        <div className="flex">
                          <p className="text-10 w-1/2 font-normal text-graydark text-start leading-loose">
                            ClockOut3
                          </p>
                          <p className=" text-black2 w-1/2 text-10 font-normal text-start leading-loose">
                            {data.clockOut3 ?? "---"}
                          </p>
                        </div>
                        <div className="flex pb-3">
                          <p className="text-10 w-1/2 font-normal text-graydark text-start leading-loose">
                            Location3
                          </p>
                          <p className=" text-vorpblue underline cursor-pointer w-1/2 text-10 font-normal text-start leading-loose">
                            {data.location3 ?? "---"}
                          </p>
                        </div>
                        <hr color="blue" />
                      </div>
                    ))
                  : null}
              </div>
              <div className="pt-6">
                <TablePaginator
                  total={itemCount ?? 0}
                  pagination={pagination}
                  setPagination={setPagination}
                  itemCount={itemCount ?? 0}
                  setCurrentPage={setCurrentPage}
                  currentPageDefault={currentPage}
                />
              </div>
            </div>
          ) : (
            <div className="w-full bg-white rounded-xl text-left">
              <div className="px-2 sm:px-4 md:px-5 py-1 md:py-2 border-b border-offwhite flex items-center justify-between">
                <h3 className="text-[#3F4254] font-semibold text-[13px] sm:text-[15px] 2xl:text-[18px] leading-[22px] lg:leading-[27px]">
                  My Timesheet
                </h3>
                <div className="flex justify-end mt-1 sm:w-auto w-full sm:flex-nowrap flex-wrap items-center">
                  <div className=" mb-1 flex items-center xl:ml-3 mr-[10px]">
                    <p className="base-label text-12 xl:text-14">DISPLAY</p>
                    <NumberDropdownList
                      state={pagination.pageSize}
                      setState={(value) => {
                        setPagination((prev) => ({
                          ...prev,
                          pageSize: value as number,
                        }));
                        setLimit(value);
                      }}
                      dropdownItems={new Array(5)
                        .fill(1)
                        .map((_, index: number) => {
                          let display = (index + 1) * 10;
                          return {
                            value: display,
                            label: String(display),
                          };
                        })}
                      className="max-w-[55px] 4xs:max-w-[60px] lg:max-w-[72px]"
                      textAlign="text-left pl-3"
                      paddingClass="py-[2px]"
                      placeholder="20"
                    />
                  </div>
                </div>
              </div>
              {isFetching ? null : (
                <div
                  className={classNames(
                    "px-2 sm:px-4 md:px-5 pt-[10px] pb-[35px] border-b border-offwhite"
                  )}
                >
                  <MyTimesheetTable
                    pagination={pagination}
                    setPagination={setPagination}
                    total={itemCount}
                    setCurrentPage={setCurrentPage}
                    currentPage={currentPage}
                    dummyTimesheetData={timeSheetData}
                    data={timeSheetData}
                    id={id}
                    hiddenFields={hiddenFields ? hiddenFields : []}
                    setHiddenFields={setHiddenFields}
                    sorting={sorting}
                    setSorting={setSorting}
                  />
                </div>
              )}
            </div>
          )}
        </animated.div>
      )}
    </>
  );
};

export default MyTimeSheet;
