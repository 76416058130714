import { FC, useEffect, useMemo, useState, useCallback } from "react";
import DebounceSearchInput from "../../components/Tables/DebounceSearchInput";
import { useNavigate } from "react-router-dom";
import { SearchIcon } from "../../components/common/Icons";
import classNames from "classnames";
import Button from "../../components/common/Button";
import { checkedIsNew } from "../../app/services/employee";
import { baseObj } from "../../utils/constants";
import {
    ColumnFiltersState,
    PaginationState,
    SortingState,
} from "@tanstack/react-table";
import { useSelector } from "react-redux";
import { templateList } from "../../active-time-report/views/ActiveTimeReport";
import { useSpring, animated } from "react-spring";
import { useGetByModuleTemplateQuery } from "../../app/services/template";
import { hideAllNoneField } from "../../components/Tables/TablePaginator";
import { getAuthUser } from "../../app/services/dashboard";
import { ToastContainer, toast } from "react-toastify";
import ErrorPopup from "../../components/ErrorPopup/ErrorPopup";
import { RootState } from "../../app/store";
import TaskList2Component from "../../components/TaskList/TaskList2Component";
import TaskListTable from "../../components/TaskList/TaskListTable";
import { CommonListData, TeamLeadData, useGetTaskListQuery } from "../../app/services/tasklist";
import { TaskCategory } from "../../app/services/progress-checker";
import { renderDateFormatYMD } from "../../components/DashboardCalendarView/DashboardAddEventPopup";
import TaskCategoryTable from "../../components/TaskCategoryTable/TaskCategoryTable";
import { TaskDepartmentListData, useCreateTaskCategoryMutation, useDeletTaskCategoryMutation, useGetDepartmentListQuery, useGetTaskCategoriesQuery, useUpdateTaskCategoryMutation } from "../../app/services/task-category";
import TaskCategoryFilter from "../TaskCategoryFilter";
import CommonErrorPopup from "../../components/common/CommonErrorPopup";

export interface IOTData {
    id: number;
    employeeID: string;
    employeeName: string;
    department: string;
    date: string;
    fullHalfDay: string;
    timeIn: string;
    timeOut: string;
    otAmt: string;
}

interface IOT { }

export interface IDepartment {
    id: number;
    name: string;
    label: string;
}

const defaultFields = [
    "name",
    "department_id",
    "actions",
];

export type TStatusListData = {
    id: number | string;
    name: string;
    label?: string;
};


export const addNewChecker = () => {
    return {
        id: 0,
        name: '',
        description: '',
        department_id: 0,
        isNew: true,
    };
};

const TaskCategoryList: FC<IOT> = () => {
    const navigate = useNavigate();
    const { alerts } = useSelector((state: RootState) => state.alertClaim);

    const [globalSearch, setGlobalSearch] = useState<string>("");
    const [itemCount, setItemCount] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [pagination, setPagination] = useState<PaginationState>({
        pageIndex: 0,
        pageSize: 20,
    });
    const [sorting, setSorting] = useState<SortingState>([]);
    const [projectID, setProjectID] = useState<any>("All");
    const [message, setMessage] = useState<any>([]);
    // const [limit, setLimit] = useState<number>(10);
    const [currentView, setCurrentView] = useState<any>(0);
    const [currentViewData, setCurrentViewData] = useState<number>(0);
    const [startDate, setStartDate] = useState<Date>();
    const [endDate, setEndDate] = useState<Date>();
    let [localModuleId, setLocalModuleId] = useState<number>(0);
    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
    const [hiddenFields, setHiddenFields] = useState<string[]>([]);
    const [tempList, setTempList] = useState<any>(templateList);
    const [viewCols, setCols] = useState<any>([]);
    const [visArray, setVisArray] = useState<any>({});
    const [moduleList, setModuleList] = useState<any>([]);
    const [data, setData] = useState<baseObj[]>([]);
    const [isSuccess, setIsSuccess] = useState<boolean>(false);
    const [isTemplate, setIsTemplate] = useState<boolean>(false);
    const [errorPopup, setErrorPopup] = useState<boolean>(false);
    const [isEditing, setIsEdit] = useState<boolean>(false);
    const [successPopup, setSuccessPopup] = useState<boolean>(false);
    const [isLayoutControlOpen, setIsLayoutControlOpen] =
        useState<boolean>(false);
    const [skip, setSkip] = useState<boolean>(false);
    var disabledData = checkedIsNew(data);
    const { data: templateData } = useGetByModuleTemplateQuery(localModuleId);
    const [departmentID, setDepartmentID] = useState<string>("All");
    const [departmentData, setDepartmentData] = useState<TaskDepartmentListData[]>([]);
    const { data: departments } = useGetDepartmentListQuery();
    const { data: taskCategories } = useGetTaskCategoriesQuery({
        page: currentPage,
        limit: pagination.pageSize,
        search: globalSearch,
        sort: sorting.map((s) => `${s.desc ? "-" : ""}${s.id}`).join(","),
        department_id: departmentID
    });
    const [createTaskCategory] = useCreateTaskCategoryMutation();
    const [updateTaskCategory] = useUpdateTaskCategoryMutation();
    const [deleteTaskCategory] = useDeletTaskCategoryMutation();

    const deleteTemplateData = (id: string) => {
        var filterTempList = templateList.filter((x) => x.id !== parseInt(id));
        setTempList(filterTempList);
    };

    var getUser = getAuthUser();
    const getAuthPermis: string[] = useMemo(() => [], []);
    if (getUser?.permissions) {
        getUser?.permissions?.forEach((per: any) => {
            getAuthPermis.push(per?.name);
        });
    }

    const props = useSpring({
        to: { opacity: 1 },
        from: { opacity: 0 },
        delay: 1000,
    });

    const changePage = (pageNo: number) => { };

    useEffect(() => {
        if (taskCategories) {
            setData(taskCategories?.task_categories?.data);
            setItemCount(taskCategories?.task_categories?.total);
            setLocalModuleId(taskCategories?.module_id)
        }
    }, [taskCategories])
    useEffect(() => {
        if (departments) {
            setDepartmentData(departments)
        }
    }, [departments])

    useEffect(() => {
        if (templateData) {
            var updatedTemplate = templateData?.templates.map((x: any, i) => {
                return { ...x, index: i + 1 };
            });
            var defaultTemplate = {
                id: -1,
                index: 0,
                module: 0,
                name: "All",
            };
            setModuleList([defaultTemplate, ...updatedTemplate]);
        }
    }, [templateData]);

    useEffect(() => {
        if (templateList) {
            setTempList(templateList);
        }
        var viewData = templateData?.templates.find(
            (x) => x.id.toString() == currentView.toString()
        );

        setHiddenFields(viewData ? [...viewData.viewColumns] : defaultFields);
        hideAllNoneField();
    }, [templateList, currentView]);

    useEffect(() => {
        if (viewCols?.view_columns?.length > 0) {
            setHiddenFields(viewCols?.view_columns);
        }
    }, [viewCols?.view_columns]);

    useEffect(() => {
        if (moduleList) {
            var lastesTemplate = moduleList[moduleList?.length - 1];
            setCurrentViewData(lastesTemplate ? Number(lastesTemplate?.id) : 0);
        }
    }, [moduleList]);

    useEffect(() => {
        setCurrentPage(1);
    }, [
        departmentID,
        pagination.pageSize,
        globalSearch
    ]);

    useEffect(() => {
        if (alerts[0]?.type) {
            toast(`${alerts[0]?.message}`, {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                type: "success",
            });
        }
    }, [alerts[0]?.type]);
    const createTaskCategoryFunc = (data: any) => {
        createTaskCategory(data).then((res: any) => {            
            if (res?.data?.status == true) {
                setErrorPopup(false);
                setMessage('')
                toast(res?.data?.message, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    type: "success",
                });
            } else{
                setMessage(res?.error?.data?.message)
                setErrorPopup(true)
            }
        }).catch((error)=>{
            setMessage(error?.data?.message)
            setErrorPopup(true)
        })
    }
    const updateTaskCategoryFunc = (data: any) => {
        updateTaskCategory(data).then((res: any) => {
            if (res?.data?.status == true) {
                toast(res?.data?.message, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    type: "success",
                });
                setData((prev: baseObj[]) => {
                    return prev.map((data, index: number) => {
                        return { ...data, isNew: false };
                    });
                });
            } else {
                setMessage(res?.data?.message)
            }
        })
    }
    const deleteTaskCategoryFunc = (id: number) => {
        deleteTaskCategory(id);
    }
    return (
        <div>
            <ToastContainer className={"text-12 font-poppins font-normal"} />
            <CommonErrorPopup
                show={errorPopup}
                setShow={setErrorPopup}
                message={message}
            />
            <div className="px-2 sm:px-4 py-4 bg-white sm:mt-[25px] mt-0 font-poppins text-13 font-semibold text-[#858795] tracking-[-0.03rem] w-full h-full rounded-xl pt-3 pb-8 sm:pt-3 sm:pb-9">
                <div className="flex flex-wrap justify-between items-center">
                    <div className="flex items-center mr-2 xs:mr-4 sm:w-[260px] w-[225px] md:mb-1">
                        <DebounceSearchInput setState={setGlobalSearch} debounce={800}>
                            {(setState) => (
                                <div className="font-normal flex border-1 border-[#C4C4C4] bg-white hover:bg-[#EFEFEF] rounded-[28px]">
                                    <input
                                        id="message"
                                        autoComplete="off"
                                        // name="search"
                                        type="text"
                                        placeholder="Search"
                                        className="focus:outline-none py-1 px-4 text-12 xl:text-14 leading-5 bg-transparent rounded-[28px] w-full text-graydark"
                                        onChange={(e) => setState(e.target.value)}
                                    />
                                    <SearchIcon className={classNames("mr-2")} />
                                </div>
                            )}
                        </DebounceSearchInput>
                    </div>
                    <div className="flex w-full sm:w-auto items-center justify-between md:justify-end lg:justify-end">
                        <div className="">
                            <Button
                                disabled={disabledData?.disabled}
                                label={<span className="flex items-center">+ Add New</span>}
                                onClick={() => {
                                    setIsSuccess(false);
                                    setPagination((prev) => ({ ...prev, pageIndex: 0 }));
                                    // handleAddNew();
                                    setData((prev) => [addNewChecker(), ...prev]);
                                }}
                                type="button"
                                variant="primary"
                                customClass={
                                    disabledData?.disabledClass +
                                    " py-[3px] my-1 px-2 focus:outline-none hover:bg-vorpmyanmar hover:border-vorpmyanmar hover:text-vorpblue 2xl:text-[14px] text-12 leading-5 font-medium text-white"
                                }
                                size="base"
                            />
                        </div>
                    </div>

                </div>

                <TaskCategoryFilter
                    filterOneName="Department"
                    setPagination={setPagination}
                    pagination={pagination}
                    startDate={startDate}
                    setStartDate={setStartDate}
                    endDate={endDate}
                    setEndDate={setEndDate}
                    // setLimit={setLimit}
                    setIsTemplate={setIsTemplate}
                    isTemplate={isTemplate}
                    currentViewData={currentViewData}
                    currentView={currentView}
                    setCurrentView={setCurrentView}
                    moduleList={moduleList}
                    deleteTemplateData={deleteTemplateData}
                    setErrorPopup={setErrorPopup}
                    setSuccessPopup={setSuccessPopup}
                    setMessage={setMessage}
                    setIsLayoutControlOpen={setIsLayoutControlOpen}
                    departmentData={departmentData}
                    departmentID={departmentID}
                    setDepartmentID={setDepartmentID}
                />

                <animated.div style={props}>
                    <TaskCategoryTable
                        data={data}
                        setData={setData}
                        setIsSuccess={setIsSuccess}
                        isSuccess={isSuccess}
                        pagination={pagination}
                        setPagination={setPagination}
                        isLayoutControlOpen={isLayoutControlOpen}
                        setIsLayoutControlOpen={setIsLayoutControlOpen}
                        globalSearch={globalSearch}
                        setGlobalSearch={setGlobalSearch}
                        columnFilters={columnFilters}
                        setColumnFilters={setColumnFilters}
                        setHiddenFields={setHiddenFields}
                        hiddenFields={hiddenFields ? hiddenFields : []}
                        templateList={tempList}
                        setCurrentView={setCurrentView}
                        setIsEdit={setIsEdit}
                        isEditing={isEditing}
                        localModuleId={localModuleId}
                        total={itemCount}
                        changePage={changePage}
                        setCurrentPage={setCurrentPage}
                        currentPage={currentPage}
                        setIsTemplate={setIsTemplate}
                        setCols={setCols}
                        visArray={visArray}
                        setSkip={setSkip}
                        sorting={sorting}
                        setSorting={setSorting}
                        createTaskCategory={createTaskCategoryFunc}
                        updateTaskCategory={updateTaskCategoryFunc}
                        deleteTaskCategory={deleteTaskCategory}
                        departmentData={departmentData}
                    />
                </animated.div>
                <ToastContainer className={"text-12 font-poppins font-normal"} />
            </div>
        </div>
    );
};
export default TaskCategoryList;
