// import { Modal } from "@material-ui/core";
import { Modal } from "@mui/material";
import { Cell } from "@tanstack/react-table";
import classNames from "classnames";
import React, {
  // createRef,
  Dispatch,
  FC,
  SetStateAction,
  useRef,
  useState,
} from "react";
import { baseObj } from "../../utils/constants";
// import { StringDropdownList } from "../common/DropdownList";
import { StringDropdownListWithSearch } from "../common/DropdownListWithSearch";
import { CloseIcon, SearchIcon } from "../common/Icons";
// import { MembereData } from "../EmployeeTable/EmployeeTable";
import styles from "./ReportToPopup.module.scss";

interface ICreateData {
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  selectedMember: string;
  setSelectedMember: Dispatch<SetStateAction<string>>;
  memberList: any[];
  setMemberData?: Dispatch<SetStateAction<string>>;
  data: baseObj[];
  setData: Dispatch<SetStateAction<baseObj[]>>;
  cell: Cell<unknown, unknown>;
  label?: string;
  title?: string;
}
const ReportToPopup: FC<ICreateData> = ({
  show,
  setShow,
  selectedMember,
  setSelectedMember,
  memberList,
  data,
  setData,
  cell,
  label,
  title,
}) => {
  var name = useRef<any>(null);
  const [popupShow, setPopupShow] = useState<boolean>(false);
  const [currentMember, setCurrentMember] = useState<string>(selectedMember);
  const [state, setState] = useState<string>(selectedMember);
  // const [search, setSearch] = useState<string>("");
  var memberName = memberList?.find((x) => x.id.toString() == currentMember);
  const handleConfirm = () => {
    setCurrentMember(state);
    setSelectedMember(state);
    setPopupShow(false);
    setShow(false);
    setData((prev: baseObj[]) => {
      return prev.map((data: baseObj, index: number) => {
        if (index === Number(cell.row.id)) {
          var currentMemderData = memberList.find(
            (x) => x.id.toString() == state
          );
          var selectedState: any = state;
          if (cell.column.id == "reportTo") {
            selectedState = currentMemderData;
          }
          return { ...data, [cell.column.id]: selectedState };
        }
        return data;
      });
    });
  };
  return (
    <div className="h-full w-full">
      <button
        className="text-left w-full h-full min-h-[34px] relative"
        onClick={() => {
          setPopupShow(true);
        }}
      >
        {memberName ? (
          <div className=" w-[30px] h-[30px] bg-[#FFC700] rounded-full relative">
            <p className=" absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex items-center justify-center text-13 font-bold text-white text-center">
              {memberName.name.slice(0, 1)}
            </p>
          </div>
        ) : (
          ""
        )}
      </button>
      <Modal
        open={popupShow}
        onClose={() => setPopupShow(false)}
        disableEnforceFocus={true}
      >
        <div>
          <div className={classNames("z-10 fixed top-0 left-0 w-full h-full")}>
            <div
              className={classNames(
                "relative z-10 top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 md:max-w-[530px] 2xs:max-w-[400px] 5xs:max-w-[340px] max-w-[300px] bg-[#fff] rounded-xl"
              )}
            >
              <div className="flex justify-between items-center bg-darkblue px-10 py-6 rounded-tl-xl rounded-tr-xl">
                <p className="text-left font-poppins font-semibold text-lg text-[#fff]">
                  {title ? title : "Report to"}
                </p>
                <span
                  onClick={() => setPopupShow(false)}
                  className="text-[#B5B5C3] text-20 cursor-pointer"
                >
                  <CloseIcon />
                </span>
              </div>
              <div className="px-10 pt-9 pb-10">
                <p
                  className={classNames(
                    "border-b-2 border-b-[#F5F5F5] pb-[14px] font-poppins font-semibold xl:text-24 text-base text-darkblack"
                  )}
                >
                  {label ? label : "Members"}
                </p>
                {/* <div className="mt-5 mb-5">
                  <div className="rounded-4 flex items-center justify-between px-3 py-3 border-1 border-goalinputborder">
                    <input
                      ref={name}
                      type="text"
                      className="font-poppins font-normal xl:text-sm text-xs rounded w-full bg-[#fff] focus:outline-none"
                      placeholder="Search"
                    />
                    <SearchIcon />
                  </div>
                </div> */}
                <div>
                  <div className={classNames(styles.memberDropdownList)}>
                    <StringDropdownListWithSearch
                      setSearchData={setSelectedMember}
                      state={currentMember}
                      setState={setState}
                      dropdownItems={memberList?.map((member) => {
                        return {
                          label: member.name,
                          value: member.id,
                        };
                      })}
                      className={classNames(
                        styles.memberDropdownList,
                        "w-full min-w-[100px] 2xl:min-w-[128px]  xl:text-sm text-xs"
                      )}
                      placeholder="Select Member"
                      textAlign="text-left py-2 pl-2 pr-3"
                    />
                  </div>
                </div>
                <div className="flex justify-start pb-5 mt-5">
                  <button
                    onClick={() => handleConfirm()}
                    className={classNames(
                      styles.customConfirmBtn,
                      "flex items-center px-3 py-2 rounded-[6px] font-poppins font-semibold lg:text-sm text-xs text-[#fff] bg-[#009EF7] hover:text-vorpblue hover:bg-vorpmyanmar mr-2"
                    )}
                  >
                    <div className="mr-[5px]">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <path
                          d="M2.5 13.3333V11.6667H9.16667V13.3333H2.5ZM2.5 10V8.33333H12.5V10H2.5ZM2.5 6.66667V5H12.5V6.66667H2.5ZM13.625 15.8333L10.6667 12.875L11.8333 11.7083L13.625 13.4583L17.1667 9.91667L18.3333 11.125L13.625 15.8333Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                    Confirm
                  </button>
                  <button
                    onClick={() => setPopupShow(false)}
                    className={classNames(
                      styles.cancelBtn,
                      "flex items-center px-4 py-2 rounded-[6px] font-poppins font-semibold lg:text-sm text-xs text-black2 bg-offwhite hover:bg-anncolor"
                    )}
                  >
                    <CloseIcon />
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};
export default ReportToPopup;
