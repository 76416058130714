import { FC } from "react";
import { baseObj } from "../../../utils/constants";
import { DocsIcon } from "./icons/IconMapper";
import { Row } from "@tanstack/react-table";
import { ImageFormat } from "../../../app/services/documents";

interface IDocumentsGrid {
  className?: string;
  rows: Row<unknown>[];
}

const DocumentsGrid: FC<IDocumentsGrid> = ({ className, rows }) => {
  return (
    <div
      className={`grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-x-3 6xl:gap-x-5 gap-y-2 ${className}`}
    >
      {rows &&
        rows.map((row) => {
          const rowData = row.original as baseObj;
          const url=rowData?.url;
          const [name, extension] = rowData.name.split(".");
          return (
            <div
              key={row.id}
              className="break-all min-h-[45px] 5xs:min-h-[56px] flex items-center px-2 5xs:px-[10px] 5xs:py-4 border border-[#DADADA] text-[11px] 5xs:text-12 5xl:text-14 hover:cursor-pointer leading-5 text-black2 text-left"
            >
              <img
               src={extension ? (DocsIcon[extension] ? DocsIcon[extension] : ImageFormat.includes(extension)?url:DocsIcon["unknow"]) : DocsIcon.folder}
                alt={name}
                className="mr-2 xs:mr-3 w-4 h-4 sm:w-6 sm:h-6 object-cover"
              />
              {rowData.name}
            </div>
          );
        })}
    </div>
  );
};

export default DocumentsGrid;
