import {
  FC,
  SetStateAction,
  Dispatch,
  useState,
  useEffect,
  useRef,
  ReactNode,
} from "react";
import {
  ColumnFiltersState,
  ColumnPinningState,
  PaginationState,
  Row,
  RowSelectionState,
  SortingState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
  ColumnResizeMode,
} from "@tanstack/react-table";
import Table from "./Tables.module.scss";
import classNames from "classnames";
import { DndProvider } from "react-dnd";
import { TouchBackend } from "react-dnd-touch-backend";
import ResuableDraggableHeader from "./ResuableDraggableHeader";

import TableLayoutEditor from "./TableLayoutEditor";
import { SelectedIcon } from "../common/Icons";
import { customGlobalFilterFunction } from "../../utils/helpers";


interface ILayoutAdjustableTable {
  data: any;
  columns: any;
  sorting?: SortingState;
  setSortingState?: Dispatch<SetStateAction<SortingState>>;
  rowSelection: RowSelectionState;
  setRowSelection: Dispatch<SetStateAction<RowSelectionState>>;
  columnOrder: string[];
  setColumnOrder: Dispatch<SetStateAction<string[]>>;
  unDraggableFields: string[];
  theaderColor?: string;
  isLayoutControlOpen?: boolean;
  isWorkingShift?: boolean;
  setIsLayoutControlOpen?: Dispatch<SetStateAction<boolean>>;
  setIsWorkingShift?: Dispatch<SetStateAction<boolean>>;
  label?: string;
  globalFilter?: string;
  setGlobalFilter?: Dispatch<SetStateAction<string>>;
  pagination?: PaginationState;
  setPagination?: Dispatch<SetStateAction<PaginationState>>;
  setItemCount?: Dispatch<SetStateAction<number>>;
  columnFilters?: ColumnFiltersState;
  setColumnFilters?: Dispatch<SetStateAction<ColumnFiltersState>>;
  noPaddingCells?: string[];
  getsharedStateComponent?: (rows: Row<unknown>[]) => ReactNode;
  isTableView?: boolean;
  saveTemplateData?: any;
  hideColumn?: {
    [key: string]: boolean;
  };
  localModuleId?: number;
  visArray?: any;
  columnPinning?: any;
  setColumnPinning?: any;
  setCols?: any;
  setSuccessPopup?: Dispatch<SetStateAction<boolean>>;
  setMessage?: any;
  setErrorPopup?: Dispatch<SetStateAction<boolean>>;
  setGetPlaceHolder?: any;
  setIsTemplate?: Dispatch<SetStateAction<boolean>>;
  setSortDirection?: Dispatch<SetStateAction<string>>;
  sortDirection?: string;
  setCurrentView?: any;
  handleScroll?: any;
  unShowFields?: string[];
  isManualSorting?: boolean;
}

export type IColumnHelpersState = { [key: string]: boolean };
const LayoutAdjustableTable: FC<ILayoutAdjustableTable> = ({
  handleScroll,
  data,
  columns,
  sorting,
  setSortingState,
  rowSelection,
  setRowSelection,
  columnOrder,
  setColumnOrder,
  unDraggableFields,
  theaderColor = "",
  isLayoutControlOpen,
  setIsLayoutControlOpen,
  setIsWorkingShift,
  isWorkingShift,
  label,
  globalFilter,
  setGlobalFilter,
  pagination,
  setPagination,
  setItemCount,
  columnFilters,
  setColumnFilters,
  noPaddingCells,
  getsharedStateComponent,
  isTableView = true,
  hideColumn = {},
  saveTemplateData,
  localModuleId,
  // visArray,
  setCols,
  setSuccessPopup,
  setMessage,
  setErrorPopup,
  setGetPlaceHolder,
  setIsTemplate,
  setCurrentView,
  setSortDirection,
  sortDirection,
  unShowFields,
  isManualSorting
}) => {
  const [columnVisibility, setColumnVisibility] = useState<{
    [key: string]: boolean;
  }>({});

  const [columnPinning, setColumnPinning] = useState<ColumnPinningState>({
    left: [
      "permission",
    ],
    right: ["lasted", "action"],
  });
  const [columnResizeMode, setColumnResizeMode] =
    useState<ColumnResizeMode>("onChange");

  const tbodyRef = useRef<any>(null);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    globalFilterFn: customGlobalFilterFunction,
    state: {
      sorting,
      rowSelection,
      columnOrder,
      columnVisibility,
      globalFilter,
      pagination,
      columnPinning,
      ...(columnFilters ? { columnFilters } : {}),
    },
    manualSorting: isManualSorting==false ? isManualSorting : true,
    columnResizeMode: "onChange",
    onSortingChange: setSortingState,
    onRowSelectionChange: setRowSelection,
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    // onPaginationChange: setPagination, // Prevent rerendering on pagination change
    getPaginationRowModel: getPaginationRowModel(),
    onColumnOrderChange: setColumnOrder,
    onColumnVisibilityChange: setColumnVisibility,
    onGlobalFilterChange: setGlobalFilter,
    onColumnPinningChange: setColumnPinning,
    ...(setColumnFilters ? { onColumnFiltersChange: setColumnFilters } : {}),
  });

  const itemCount = table.getFilteredRowModel().rows.length;
  const currentPageItemCount = table.getRowModel().rows.length;
  const [isColumnHelpersOpen, setIsColumnHelpersOpen] =
    useState<IColumnHelpersState>({});
  const [pinnedArr, setPinnedArr] = useState<string[]>(["permission"]);

  const setPinnedData = (pinnedData: string, isPinned: any) => {
    var data = pinnedArr?.find((x) => x == pinnedData);
    if (!data) {
      if (isPinned == "left") {
        setPinnedArr([...pinnedArr, pinnedData]);
      }
    }
    if (isPinned == false) {
      var filterData = pinnedArr?.filter((x) => x != pinnedData);
      setPinnedArr([...filterData]);
    }
  };

  var updatedData = data;
  const rows = table.getRowModel().rows;


  // const hideAllColumn = () => {
  //   rows.map((row, index) => {
  //     var nones = row
  //       .getVisibleCells()
  //       .filter((x) => x.column.id.includes("none"));
  //     nones.map((none, idx) => {
  //       var id = "body" + index + "_none" + idx;
  //       var box = document.getElementById(id);
  //       box?.classList.add("hidden");
  //     });
  //   });
  //   table.getHeaderGroups().map((headerGroup, index) => {
  //     var nones = headerGroup.headers.filter((x) =>
  //       x.column.id.includes("none")
  //     );
  //     nones.map((none, idx) => {
  //       var id = "headernone" + idx;
  //       var box = document.getElementById(id);
  //       box?.classList.add("hidden");
  //     });
  //   });
  // };
  // const closeNoneID = () => {
  //   // localStorage.setItem('width',"0")
  //   rows.map((row, index) => {
  //     var nones = row
  //       .getVisibleCells()
  //       .filter((x) => x.column.id.includes("none"));
  //     row.getVisibleCells().filter((cell) => {
  //       if (cell.column.id.includes("none")) {
  //         for (var i = 0; i < pinnedArr?.length; i++) {
  //           if (cell.column.id.includes("none")) {
  //             var id = "body" + index + "_none" + i;
  //             var box = document.getElementById(id);
  //             box?.classList.remove("hidden");
  //           }
  //         }
  //         for (var i = pinnedArr?.length; i < nones?.length; i++) {
  //           if (cell.column.id.includes("none")) {
  //             var id = "body" + index + "_none" + i;
  //             var box = document.getElementById(id);
  //             box?.classList.add("hidden");
  //           }
  //         }
  //       }
  //     });
  //   });
  //   table.getHeaderGroups().map((headerGroup, index) => {
  //     var nones = headerGroup.headers.filter((x) =>
  //       x.column.id.includes("none")
  //     );
  //     headerGroup.headers.filter((header) => {
  //       if (header.column.id.includes("none")) {
  //         for (var i = 0; i < pinnedArr?.length; i++) {
  //           var id = "headernone" + i;
  //           var box = document.getElementById(id);
  //           box?.classList.remove("hidden");
  //         }
  //         for (var i = pinnedArr?.length; i < nones?.length; i++) {
  //           var id = "headernone" + i;
  //           var box = document.getElementById(id);
  //           box?.classList.add("hidden");
  //         }
  //       }
  //     });
  //   });
  // };

  // const checkedIsPinned = (
  //   isPinned: any,
  //   headerID: string,
  //   div: string,
  //   headerName: string
  // ) => {
  //   // closeAllNone();
  //   if (pinnedArr.length > 0) {
  //     closeNoneID();
  //   } else {
  //     hideAllColumn();
  //   }
  //   var height = 0;
  //   var index = pinnedArr.indexOf(headerID);
  //   var width = document.getElementById(div)?.getBoundingClientRect().width;
  //   var posWidth = document
  //     .getElementById(div)
  //     ?.previousElementSibling?.getBoundingClientRect().width;
  //   if (posWidth == 0) {
  //     posWidth = document.getElementById(div)?.getBoundingClientRect().width;
  //   }
  //   if (width) {
  //     if (width > 174) {
  //       width = width;
  //     } else {
  //       width = 174;
  //     }
  //   }
  //   if (
  //     (isPinned == "left" &&
  //       headerID !== "selection" &&
  //       headerID !== "permission") ||
  //     headerID.includes("none")
  //   ) {
  //     var test = data.map((x: any) => {
  //       var d = { ...x };
  //       d.test = "";
  //       return d;
  //     });
  //     updatedData = test;
  //     var left = document
  //       .getElementById(div)
  //       ?.previousElementSibling?.getBoundingClientRect().width;
  //     if (pinnedArr.find((x) => x == div)) {
  //       left = document
  //         .getElementById(div)
  //         ?.previousElementSibling?.getBoundingClientRect().width;
  //       if (left == 0) {
  //         left = document.getElementById(div)?.getBoundingClientRect().width;
  //       }
  //     }

  //     var pos = "0";
  //     if (left) {
  //       if (index == 0) {
  //         pos = "0";
  //       } else {
  //         pos = left * index + "px";
  //       }
  //     }
  //     const noneData = document.getElementById("headernone" + index);
  //     if (!div.includes("none")) {
  //       const box = document.getElementById(div);
  //       if (box != null) {
  //         box?.style.setProperty("left", noneData?.offsetLeft + "px");
  //         height = box?.clientHeight;
  //       }
  //     }

  //     if (headerID.includes("none")) {
  //       var bodyName = div.split("_")[0];
  //       if (index != -1) {
  //         return "hidden";
  //       } else if (
  //         headerID != bodyName + "_none" + index ||
  //         headerID != "headernone" + index
  //       ) {
  //         return "hidden";
  //       }
  //     }
  //     if (div.includes("body")) {
  //       var bodyName = div.split("_")[0];
  //       const box1 = document.getElementById(bodyName + "_none" + index);
  //       if (box1 != null) {
  //         box1.classList.remove("hidden");
  //         box1?.style.setProperty("min-width", width + "px");
  //         box1?.style.setProperty("width", width + "px");
  //       }
  //     } else {
  //       const box1 = document.getElementById("headernone" + index);
  //       if (box1 != null) {
  //         box1.classList.remove("hidden");
  //         box1?.style.setProperty("min-width", width + "px");
  //         box1?.style.setProperty("width", width + "px");
  //       }
  //     }

  //     if (div.includes("header") || div.includes("body")) {
  //       const box1 = document.getElementById(div);
  //       if (box1 != null) {
  //         box1?.style.setProperty("width", width + "px");
  //       }
  //     }
  //     return div.includes("header")
  //       ? `absolute left-[${
  //           noneData?.offsetLeft
  //         }] h-[68px] min-w-[174px] bg-white w-[${width}px]${
  //           isColumnHelpersOpen[headerID] ? "" : "z-10"
  //         }`
  //       : `absolute left-[${
  //           noneData?.offsetLeft
  //         }] h-[68px] min-w-[174px] bg-white w-[${width}px] ${
  //           isColumnHelpersOpen[headerID] ? "" : "z-10"
  //         }`;
  //   } else if (headerID.includes("none") && pinnedArr?.length <= 0) {
  //     return "hidden";
  //   } else if (headerID.includes("actions")) {
  //     if (Object.keys(hideColumn)?.length > 0) {
  //       return "relative right-0 h-[68px] min-w-[174px] bg-white left-[unset]";
  //     } else if (Object.keys(columnVisibility)?.length > 5) {
  //       return "relative right-0 h-[68px] min-w-[174px] bg-white left-[unset]";
  //     } else {
  //       return "absolute right-0 h-[68px] min-w-[174px] bg-white left-[unset]";
  //     }
  //   } else {
  //     var bodyName = div.split("_")[0];
  //     const box1 = document.getElementById(bodyName + "_none" + index);
  //     if (box1 != null) {
  //       box1.classList.remove("hidden");
  //     }
  //     const box = document.getElementById(div);
  //     if (box != null) {
  //       box?.style.setProperty("left", "");
  //     }
  //     return "relative";
  //   }
  // };
  // const resetPinned = () => {
  //   rows?.map((row, index) => {
  //     var nones = row
  //       .getVisibleCells()
  //       .filter((x) => x?.column?.id?.includes("none"));
  //     row?.getVisibleCells().map((cell) => {
  //       cell?.column?.pin(false);
  //     });
  //   });
  //   table?.getHeaderGroups().map((headerGroup, index) => {
  //     var nones = headerGroup?.headers?.filter((x) =>
  //       x?.column?.id?.includes("none")
  //     );
  //     headerGroup?.headers?.map((header) => {
  //       header?.column?.pin(false);
  //     });
  //   });
  // };
  // const closeAllNone = () => {
  //   rows.map((row, index) =>
  //     row.getVisibleCells().map((cell) => {
  //       if (cell.column.id.includes("none")) {
  //         var id = "body" + index + "_" + cell.column.id;
  //         var box = document.getElementById(id);
  //         box?.classList.add("hidden");
  //       }
  //     })
  //   );
  //   table.getHeaderGroups().map((headerGroup, index) =>
  //     headerGroup.headers.map((header) => {
  //       if (header.column.id.includes("none")) {
  //         // if(pinnedArr[index]==undefined){
  //         var id = "headernone" + index;
  //         var box = document.getElementById(id);
  //         box?.classList.add("hidden");
  //         // }
  //       }
  //     })
  //   );
  // };
  // const getLeftStickyPos = (index: number) => {
  //   if (!index) return 0;
  //   const prevColumnsTotalWidth = columns
  //     .slice(0, index)
  //     .reduce((curr: any, column: any) => {
  //       return curr ? curr + column.size : column.size;
  //     }, 0);
  //   return prevColumnsTotalWidth;
  // };

  const handleKeyDown = (event: any, row: any) => {
    event.stopPropagation();
    switch (event.key) {
      case "Tab":
        event.target.parentElement?.parentElement?.nextElementSibling?.children[0]?.children[0]?.focus();
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (setItemCount) {
      setItemCount(itemCount);
    }
    if (Object?.keys(hideColumn)?.length > 0) {
      setColumnVisibility({ ...hideColumn, action: false });
    }
  }, [itemCount, setItemCount, hideColumn]);

  useEffect(() => {
    if (currentPageItemCount == 0 && setPagination) {
      setPagination((prev) => ({ ...prev, pageIndex: 0 }));
    }
  }, [pagination?.pageSize, setPagination, currentPageItemCount, data]);

  var noneArr = ["permission"];

  const [changeHeader, setChangeHeader] = useState(false);

  useEffect(() => {
    pinnedCustom();
  }, [pinnedArr, data, table.getLeftTotalSize()]);//changeHeader
  const pinnedCustom = () => {
    if (pinnedArr?.length > 0) {
      if (columnPinning.left) {
        var allPinned = [...noneArr, ...columnPinning.left];
        let uniqueChars = allPinned.filter((element, index) => {
          return allPinned.indexOf(element) == index;
        });
        setColumnPinning({
          left: uniqueChars,
          right: ["action"],
        });
      }
    }

    document.querySelectorAll("th,td").forEach((x) => {
      x.classList.remove("sticky");
      x.classList.add("relative");
      x.classList.add("left-[unset]");
      var div = document.getElementById(x.id);
      if (div) {
        div.style.setProperty("left", "");
      }
      if (x.id.includes("uniqeID")) {
        x.classList.add("hidden");
      }
      if (x.id.includes("actions")) {
        x.classList.add("sticky");
        x.classList.remove("relative");
        x.classList.remove("left-0");
        x.classList.add("right-0");
        x.classList.add("min-w-[120px]");
        x.setAttribute("z-index", '');
      }
      if (x.id.includes("selection")) {
        x.classList.add("max-w-[35px]");
      }
    });
    var left = 0;
    var height = 0;
    pinnedArr.map((name, index) => {
      document.querySelectorAll("th,td").forEach((x, i) => {
        if (x.id.endsWith(name)) {
          var clsID = x.id.replace(name, "");
          height = x.getBoundingClientRect().height;
          x.setAttribute("height", `${height}px`);
          x.setAttribute("min-height", `${height}px`);
          var noneDiv = document.getElementById(clsID + index);
          var noneDiv = document.getElementById(x.id);
          if (window.location.href.includes('profile/leave')) {
            if (index == 1) {
              left = 0;
            } else {
              if (noneDiv) {
                left = noneDiv.offsetLeft - 36;
              }

            }
          }
          else if (index == 1 && !window.location.href.includes('users/permissions') || index == 0) {
            left = 0;
          } else {
            if (noneDiv) {
              if (window.location.href.includes('profile/leave')) {
                left = noneDiv.offsetLeft - 36;
              } else {
                left = noneDiv.offsetLeft == 36 ? 0 : noneDiv.offsetLeft;
              }

            }
          }
          var data = document.getElementById(x.id);
          x.classList.add("sticky"); //absolute
          x.classList.remove("relative"); //relative
          x.classList.add("left-" + left + "px");
          x.setAttribute("left", `${left}px`);
          x.setAttribute("z-index", '99');
          data?.style.setProperty("left", left + "px");
        }
        if (x.id.includes("actions")) {
          x.classList.add("sticky");
          x.classList.add("right-0");
          x.classList.remove("left-0");
        }
      });
    });
  }

  if (data == undefined) {
    return <></>;
  }

  return (
    <>
      {isTableView ? (
        <div id="table-scroll" onScroll={() => handleScroll ? handleScroll() : {}}
          className={classNames(
            "overflow-x-auto overflow-y-hidden w-full relative z-10 bg-white ",
            Table.horizontalScrollBar
          )}
        >
          <DndProvider
            backend={TouchBackend}
            options={{ enableMouseEvents: true }}
          >
            {setIsLayoutControlOpen && isLayoutControlOpen !== undefined && (
              <TableLayoutEditor
                isOpen={isLayoutControlOpen}
                setIsOpen={setIsLayoutControlOpen}
                setIsWorkingShift={setIsWorkingShift}
                isWorkingShift={isWorkingShift}
                table={table}
                label={label}
                unDraggableFields={unDraggableFields}
                setColumnOrder={setColumnOrder}
                setColumnVisibility={setColumnVisibility}
                saveTemplateData={saveTemplateData}
                localModuleId={localModuleId}
                setCols={setCols}
                setIsTemplate={setIsTemplate}
                setSuccessPopup={setSuccessPopup}
                setMessage={setMessage}
                setErrorPopup={setErrorPopup}
                setGetPlaceHolder={setGetPlaceHolder}
                setCurrentView={setCurrentView}
                unShowFields={unShowFields}
              />
            )}

            <table
              className="min-w-full"
              {...{
                style: {
                  width: table.getTotalSize(),
                },
              }}
            >
              <thead>
                {table?.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id}>
                    {headerGroup?.headers?.map((header, key) => {
                      var isPinned = header?.column?.getIsPinned();
                      // {header.getHeaderProps(header.getSortByToggleProps())}
                      return (
                        <ResuableDraggableHeader
                          setChangeHeader={setChangeHeader}
                          setSortDirection={setSortDirection}
                          sortDirection={sortDirection}
                          classes={
                            header?.column?.id?.includes("actions") || header?.column?.id?.includes("action")
                              ? "sticky right-0"
                              : ""
                          }
                          isPinned={isPinned}
                          header={header}
                          table={table}
                          key={header.column.id}
                          unDraggableFields={unDraggableFields}
                          theaderColor={theaderColor}
                          setIsColumnHelpersOpen={setIsColumnHelpersOpen}
                          isColumnHelpersOpen={isColumnHelpersOpen}
                          thColor={"#F5F8FA"}
                          setPinnedArr={setPinnedData}
                          columnResizeMode={columnResizeMode}
                          // getLeftStickyPos={getLeftStickyPos}
                          index={key}
                        // pinnedArr={pinnedArr}
                        />
                      );
                    })}
                  </tr>
                ))}
              </thead>
              <tbody ref={tbodyRef}>
                {rows.map((row: any) => {
                  var isEdit = -1;
                  data?.find((x: any, indx: number) => {
                    if (x?.isNew == true) {
                      isEdit = indx;
                    }
                  });
                  var isNewData = data?.find(
                    (x: any) => x?.uniqeID == row?.original?.uniqeID
                  );
                  var isAdmin = row?.original?.isAdmin;
                  var isAdminAcc = row?.original?.actionDisable;

                  return (
                    <tr
                      key={row.id}
                      className={classNames(isAdmin ? "hidden" : "")}
                    //className={classNames(isNewData?.isNew==true?'bg-offwhite':'')}
                    >
                      {row.getVisibleCells().map((cell: any) => {
                        const isSelection = cell.id.includes("selection");
                        const isPined = cell.column.getIsPinned();
                        return (
                          <td
                            {...{
                              key: cell.id,
                              style: {
                                width: cell.id.includes("selection") ? '35px' : cell.column.getSize() - 2,
                              },
                            }}
                            id={"body" + cell.id}
                            key={cell.id}
                            tabIndex={0}
                            onKeyDown={(e) => handleKeyDown(e, row)}
                            className={classNames(
                              isPined
                                ? "sticky left-0 z-10 bg-white"
                                : "relative",
                              isAdminAcc ? "bg-[#F5F8FA]" : "",
                              "border  border-vorpgraylight text-12 lg:text-14 leading-5 text-black2 whitespace-nowrap",
                              {
                                "py-3 px-[10px]": !noPaddingCells?.includes(
                                  cell.column.id
                                ),
                                "py-2": noPaddingCells?.includes(cell.column.id),
                                "text-center": isSelection,
                              },
                              !isSelection
                                ? isEdit == row?.index
                                  ? "bg-vorpmyanmar text-black2"
                                  : ""
                                : ""
                            )}
                          >
                            {isSelection ? (
                              <label
                                htmlFor={cell.id}
                                className="relative flex justify-center"
                              >
                                <input
                                  disabled={isAdminAcc || isEdit != -1}
                                  checked={row.getIsSelected()}
                                  onChange={row.getToggleSelectedHandler()}
                                  id={cell.id}
                                  type="checkbox"
                                  className={classNames(
                                    isAdminAcc
                                      ? "opacity-50 pointer-events-none"
                                      : "",
                                    "checked:bg-vorpblue w-3 h-3 sm:w-4 sm:h-4 p-3 border-none outline-none appearance-none bg-offwhite rounded hover:cursor-pointer"
                                  )}
                                />
                                {row.getIsSelected() && (
                                  <SelectedIcon className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 m-auto" />
                                )}
                              </label>
                            ) : (
                              <>
                                {flexRender(
                                  cell.column.columnDef.cell,
                                  cell.getContext()
                                )}
                              </>
                            )}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </DndProvider>
        </div>
      ) : (
        <>{getsharedStateComponent && getsharedStateComponent(rows)}</>
      )}
    </>
  );
};

export default LayoutAdjustableTable;
