import { Dispatch, FC, SetStateAction, useCallback, useEffect, useState } from "react";
import { DeleteIcon, EditIcon } from "../../common/Icons";
import { baseObj } from "../../../utils/constants";
import { convertDateToIsoString, formatDate, formatDateData, formatDateDataKey } from "../../../utils/helpers";
import CustomDatePicker from "../../common/BaseDatePicker";
import DatePopup from "../../EmployeeTable/DatePopup";
import CustomDropdownWithAddButton from "../../EmployeeTable/CustomDropdownWithAddButton";
import { KeyDateData } from "../../../app/services/project";
import { EmployeeData } from "../../EmployeeTable/EmployeeTable";
import { StringDropdownList } from "../../common/DropdownList";
import { IFilterStates } from "../../../projects/views/ProjectList";
import classNames from "classnames";
import CustomDropdownListNormal from "../../EmployeeTable/CustomDropdownListNormal";

interface IKeyDates {
  initialValue: IKeyDate[];
  rowId: number;
  setData: Dispatch<SetStateAction<baseObj[]>>;
  data: baseObj[];
  keyDateDataList?: EmployeeData[];
  setFilterStates: Dispatch<SetStateAction<IFilterStates>>;
  filterStates: IFilterStates;
  updateFilterState: any;
  addKeyDateData: any;
  removeKeyDates:any;
  checkManagement: boolean;
}

interface IKeyDate {
  uniqeID: number;
  date: string; // ISO string
  label: string;
  name: string;
  pivot: baseObj;
  id: number;
}

const KeyDates: FC<IKeyDates> = ({ initialValue, rowId, setData,
  data, keyDateDataList, setFilterStates, filterStates, updateFilterState, addKeyDateData,removeKeyDates, checkManagement }) => {

  const [state, setState] = useState<IKeyDate[]>(initialValue);
  const [checkAll, setCheckAll] = useState<string>(filterStates.keyDate ?? "");
  const [isEdit, setIsEdit] = useState<boolean>();
  const [editingFields, setEditingFields] = useState<number[]>([]);
  const fieldSetter = useCallback(
    (index: number) => {
      return (value: string): void => {
        let newValues = [...data];
        newValues[index] = value as any;
        setData(newValues);
      };
    },
    [setData, data]
  );

  useEffect(() => {
    setCheckAll(filterStates.keyDate ?? "")
  }, [filterStates.keyDate])

  const updateFilterStateData = (id: number, value: string | number, key: string) => {
    setState((prev: any) =>
      prev.map((item: any) => {
        if (item.id === id) {
          return {
            ...item, label: value
          };
        } else {
          return { ...item };
        }

      }));
    setData((prev) =>
      prev.map((row) => {
        if (row.uniqeID === rowId) {
          var keyDateData = row.keyDate?.map((x: any) => {
            if (x.id == id) {
              return { ...x, label: value }
            }
            else {
              return { ...x, label: x.label }
            }
          });
          return { ...row, keyDate: keyDateData };
        } else {
          return row;
        }
      })
    );
  }

  const addNewKeydate = () => {
    setState((prev: any) => {
      if (prev) {
        const id = prev?.length === 0 ? 1 : prev[prev?.length - 1].id + 1;
        setEditingFields((prev) => [...prev, id]);
        if (checkAll !== "All") {
          return [...prev, { id, date: "", label: checkAll, flag: "" }];
        } else {
          return [...prev, { id, date: "", label: "", flag: "" }];
        }
      } else {
        const id = 1;
        setEditingFields((prev) => [id]);
        if (checkAll !== "All") {
          return [{ id, date: "", label: checkAll, flag: "" }];
        } else {
          return [{ id, date: "", label: "", flag: "" }];
        }
      }

    });
  };

  const updateKeyDate = (id: number, value?: string, date?: string) => {
    const label = value;
    setState((prev) =>
      prev.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            ...(label !== undefined ? { label: checkAll } : date ? { date } : {}),
          };
        } else {
          return item;
        }
      })
    );
  };

  const saveHandler = () => {
    setData((prev) =>
      prev.map((row) => {
        if (row.uniqeID === rowId) {
          var listData = state.map((s: any) => {
            var keyID=keyDateDataList?.find((x:any)=>x.label==s.label)?.id;
            return { ...s, project_id: row.uniqeID,id:keyID }
          })
          const finalCheck = state[state?.length - 1];
          //addKeyDateData({ id: finalCheck.id, date: finalCheck.date, label: finalCheck.label })
          if (row.uniqeID != 0) {
            addKeyDateData(listData);
          }
          return { ...row, keyDate: state };
        } else {
          return row;
        }
      })
    );
    setIsEdit(false);
  };

  const removeListData = (id: number) => {
    setData((prev) =>
      prev.map((row) => {
        if (row.uniqeID === rowId) {
          var removedData = row.keyDate?.filter((x:any) => x.id != id);
          var listData = removedData.map((s: any) => {
            return { ...s, project_id: row.uniqeID }
          });
          setState(state?.filter((x:any) => x.id != id))
          const finalCheck = state[state?.length - 1];
          //addKeyDateData({ id: finalCheck.id, date: finalCheck.date, label: finalCheck.label })
          
          //removeKeyDates(id);
          return { ...row, keyDate: removedData };
        } else {
          return row;
        }
      })
    );
  }
  return (
    <div className="rounded-xl min-h-[150px sm:min-w-[558px] sm:max-w-[558px] w-[90%] max-w-[90%] bg-white 6xs:max-w-[300px] max-h-[70vh] overflow-y-auto sm:mx-0 mx-4 overflow-hidden border border-offwhite">
      <div className="flex items-center justify-between py-3 px-4 bg-modalHeader border-offwhite border rounded-t-4">
        <h1 className="text-12 md:text-14 leading-[18px] font-semibold text-black2">
          Important Key Dates
        </h1>
        {/* {(!isEdit && checkManagement) && (
          <button
            type="button"
            onClick={() => setIsEdit(!isEdit)}
            className="w-5 h-5 flex justify-center items-center bg-vorpblue rounded-4"
          >
            <EditIcon width={12} height={12} />
          </button>
        )} */}
      </div>
      <div className="p-4 lg:max-h-[500px] overflow-y-auto">
        {state?.map((keyDate: IKeyDate, index: number) => (
          <div
            key={keyDate?.id}
            className={`hover:bg-offwhite flex justify-between items-center text-12 leading-[18px] text-black2 py-2 px-3 mx-[-0.75rem] ${index !== state?.length - 1 ? "" : ""
              }`}
          >
            <div className="w-full mr-5 flex">
              {editingFields.includes(keyDate?.id) && isEdit ? (
                <div className="flex justify-start items-center w-full">
                  <div className="border-1 border-lightGrey  rounded-[4px] px-2 items-center h-full flex justify-start max-w-[100px] min-w-[100px] whitespace-nowrap mr-[23px]">
                    <CustomDatePicker
                      minWClass="min-w-max"
                      placeholder="Select Date"
                      customComponentName="clean"
                      date={keyDate?.date ? new Date(keyDate?.date) : undefined}
                      setDate={(date) => {
                        if (date !== undefined) {
                          updateKeyDate(
                            keyDate?.id,
                            undefined,
                            formatDateDataKey(date as Date)
                          );
                        }
                      }}
                    />
                  </div>
                  <div className="ml-50px w-full max-w-[90%] ">
                    {/* <StringDropdownList
                      state={filterStates.keyDate ?? ""}
                      setState={(value) =>
                        updateFilterStateData(keyDate.id as number, value as string, "keyDate")
                        // updateFilterState(value as string, "keyDate")
                      }
                      dropdownItems={keyDateDataList?.map((region) => ({
                        value: region?.label,
                        label: region?.label,
                      }))}

                      className="w-full max-w-full min-w-full 3xl:min-w-[120px] 7xl:min-w-[225px] 6xs:text-12 7xl:text-14 max-h-[38px] text-dropdownTextColor"
                      placeholder="All"
                      textAlign="text-left py-2 pl-2 pr-3"
                    /> */}
                    <CustomDropdownListNormal
                      id={index.toString()}
                      options={keyDateDataList ? keyDateDataList.map((region) => ({
                        id: region.id,
                        title: region.label,
                        value: region.label
                      })) : []}
                      label=""
                      state={state.find((x => x.id == keyDate.id))?.label ?? ""}
                      setState={(value: any) => {
                        updateFilterStateData(keyDate.id as number, value as string, "keyDate")
                        // updateFilterState(value as string, "keyDate",keyDate.id as number)
                      }}
                      customListStyle="text-12 px-2 py-1 min-h-[unset]"
                    />
                  </div>

                  {/* <input
                  type="text"
                  className="focus:outline-none w-full text-12 leading-[18px] text-black2"
                  placeholder="Type Something"
                  value={keyDate.name}
                  onChange={(e) => updateKeyDate(keyDate?.key_id, e.target.value)}
                /> */}
                </div>
              ) : (
                <>
                  <p
                    aria-label="Edit Date"
                    onClick={() =>
                      isEdit && setEditingFields((prev) => [...prev, keyDate?.id])
                    }
                    className="min-w-[100px] mr-[23px] whitespace-nowrap"
                  >
                    {keyDate?.date && formatDate(new Date(keyDate?.date))}
                  </p>
                  <p
                    aria-label="Edit Label"
                    className="hover:cursor-pointer"
                    onClick={() =>
                      isEdit && setEditingFields((prev) => [...prev, keyDate?.id])
                    }
                  >
                    {keyDate?.label}
                  </p>
                </>
              )}
            </div>
            <div onClick={() => removeListData(keyDate?.id)} className={classNames(isEdit ? 'cursor-pointer' : 'hidden')}>
              <DeleteIcon color="#EE436D" />
            </div>
          </div>
        ))}


        {isEdit && (
          <>
            <button
              type="button"
              onClick={addNewKeydate}
              className="text-vorpblue text-12 leading-[18px] mt-3 mb-4"
            >
              + Add
            </button>
            <div className="flex justify-end items-center text-12 leading-[18px] font-semibold">
              <button
                type="button"
                onClick={() => {
                  setIsEdit(false);
                  setState(initialValue);
                }}
                className="py-2 px-4 bg-[#EFEFEF] rounded-md overflow-hidden flex justify-center items-center text-[#979797] mr-2"
              >
                Discard
              </button>
              <button disabled={state ? false : true}
                type="button"
                onClick={saveHandler}
                className={classNames(state ? '' : 'opacity-50', "py-2 px-4 bg-vorpblue text-white rounded-md")}
              >
                Save
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default KeyDates;
