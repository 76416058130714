import { FC, useEffect, useState } from "react";
import PersonalDetailsInformation from "./PersonalDetailsInformation";
import PersonalDetailsEdit from "./PersonalDetailsEdit";
import { EditIconFlat } from "../../common/Icons";
import { useLocation } from "react-router-dom";
import {
  useGetProfileDataQuery,
  useUpdateMyInfoMutation
} from "../../../app/services/profile";
import classNames from "classnames";
import SuccessPopup from "../../SuccessPopup/SuccessPopup";
import ErrorPopup from "../../ErrorPopup/ErrorPopup";
import { useSpring, animated } from "react-spring";
import BaseInfoSkeleton from "../../TableSkeleton/BaseInfoSkeleton";
import { ToastContainer, toast } from "react-toastify";

const PersonalDetails: FC = () => {
  const [successPopup, setSuccessPopup] = useState<boolean>(false);
  const [errorPopup, setErrorPopup] = useState<boolean>(false);
  const [message, setMessage] = useState<any>([]);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const location = useLocation();
  //var id = location.state;
  const [state, setState] = useState(localStorage.getItem("state"));

  const [userData, setUserData] = useState<any>();
  const [updatedMyInfo] = useUpdateMyInfoMutation();
  // const { data } = useGetProfileInfoQuery(id ? parseInt(id) : 0);

  useEffect(() => {
    localStorage.removeItem("state");
  }, []);
  useEffect(() => {
    if (location.state) {
      setState(location.state);
    }
  }, [location]);

  useEffect(() => {
    // console.log("Passed state", { state });
  }, [state]);

  //var id = state ? Number(state) : 0;
  var currentUrl=window.location.pathname.split('/employeeprofile/')[1]?.split('/')[0];
  var id = currentUrl?parseInt(currentUrl):0;
  const { data, isLoading, isFetching } = useGetProfileDataQuery(id ? id : 0);
  useEffect(() => {
    // if (id != 0) {
    //   setUserData(data?.employee);
    // }
    setUserData(data?.employee);
  }, [data]);

  useEffect(() => {
    setTimeout(() => {
      setSuccessPopup(false)
    }, 1000)
    if (successPopup == false && message != "") {
      toast('Successfully updated.', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        type: "success",
      });
    }

  }, [successPopup])

  const props = useSpring({
    to: { opacity: 1 },
    from: { opacity: 0 },
    delay: 500,
  })


  return (
    <>
      {
        isLoading ? (
          <BaseInfoSkeleton />
        ) : (
          <>
            <ToastContainer className={"text-12 font-poppins font-normal"} />
            <ErrorPopup
              descText="Error Message!"
              confirmBtnText="Yes, Close."
              show={errorPopup}
              setShow={setErrorPopup}
              message={message}
              setMessage={setMessage}
            />
            <animated.div style={props}>
              <div className="w-full mx-auto">
                <div className="bg-white rounded-xl w-full">
                  <div className="px-2 sm:px-4 md:px-5 py-1 md:py-2 border-b border-offwhite flex items-center justify-between">
                    <h3 className="text-[#3F4254] font-semibold text-[13px] sm:text-[15px] 2xl:text-[18px] leading-[22px] lg:leading-[27px]">
                      Personal Details
                    </h3>
                    {!isEdit && (
                      <button
                        type="button"
                        onClick={() => setIsEdit(true)}
                        className={classNames(id ? (id != 0 ? 'hidden' : '') : '', "flex py-[8.5px] pl-[14px] pr-3 text-14 leading-5 text-black2 font-medium items-center bg-offwhite rounded-md")}
                      >
                        <EditIconFlat className="mr-[7px]" />
                        Edit Profile
                      </button>
                    )}
                  </div>
                  {isEdit ? (
                    // Edit Form for Personal Details
                    <PersonalDetailsEdit
                      id={id}
                      setUserData={setUserData}
                      updatedMyInfo={updatedMyInfo}
                      setIsEdit={setIsEdit}
                      userData={userData}
                      setSuccessPopup={setSuccessPopup}
                      setErrorPopup={setErrorPopup}
                      setMessage={setMessage}
                    />
                  ) : (
                    // Personal Details Information Display
                    <PersonalDetailsInformation userData={userData} />
                  )}
                </div>
              </div>
            </animated.div>
          </>
        )
      }
    </>

  );
};

export default PersonalDetails;
