import { FC } from "react";
import BasicLayout from "../layouts/BasicLayout";
import { Outlet } from "react-router-dom";
import MonitoringDetailPage from "./views/MonitoringDetailPage";

interface IMonitoringDetailRoute {
  loginData: any,
  permissions: any;
}

export const monitoringDetailRoute = [{ id: 1, path: "", component: <MonitoringDetailPage /> }];

const MonitoringDetailRoute: FC<IMonitoringDetailRoute> = ({ loginData, permissions }) => {
  const data = loginData;

  return (
    <BasicLayout userData={data} permissions={permissions}>
      <div className="pt-0 pb-[58px]">
        <Outlet />
      </div>
    </BasicLayout>
  );
};

export default MonitoringDetailRoute;
