import classNames from "classnames";
import styles from "./NotificationView.module.scss";
import { useEffect, useState } from "react";
const NotificationView = (props: any) => {
    const { notificationList, viewNoti } = props;
    const [notificationListData, setNotificationListData] = useState<any>([]);
    useEffect(() => {
        setNotificationListData(notificationList)
    }, [notificationList])
    const viewNotification = (noti: any) => {
        viewNoti(noti.id).then((res: any) => {
            if (res.data.status) {
                var url = noti.direction == "profile_document" ? '/profile/documents' :
                    noti.direction == "profile_password" ? '/profile/passwords' :
                        noti.direction == "leave_application" ? '/employeeList/leave' :
                            //noti.direction == "profile_overtime" ? '/employeeList/ot-list' :
                            noti.direction == "overtime_application" ? '/employeeList/ot-list' :
                                noti.direction == "profile_claimform" ? '/profile/myclaim' :
                                    noti.direction == "claimform_application" ? '/claims' :
                                        noti.direction == "profile_project" ? '/profile/projects' :
                                            noti.direction == 'profile_my_daily_report_refill_requests' ? "/profile/my-refill-report" :
                                                noti.direction == 'profile_member_daily_report_refill_requests' ? "/profile/member-daily-report" :
                                                    noti.direction == 'activity_log' ? '/activity_log' :
                                                        noti.direction == 'announcement' ? '/profile/announcement' :
                                                            noti.direction == "profile_leave" ? '/profile/leave' : '#';
                window.location.href = url;
                setNotificationListData((prev: any) => {
                    var data=prev.notifications?.data.map((x: any) => x.id == noti.id ? { ...x, read_at: new Date().toString() } : x)
                    return {...prev,notifications:{...prev.notifications,data:[...data]}};
                })
            }
        });

    }
    return <div className="w-full text-left">
        <ul className="">
            {notificationListData?.notifications?.data?.map((noti: any, key: number) => {
                return <li onClick={() => viewNotification(noti)} className={classNames(noti.read_at == "" ? 'bg-[#F3F6F9] ' + styles.redDot : '', styles.customNotiHover, "text-left pb-2 border-b border-b-[#E4E6EF]")} key={key}>
                    <button className="w-full block hover:text-vorpblue font-poppins font-normal text-sm text-graydark cursor-pointer px-6 py-2"
                    >
                        <p className="text-left font-poppins lg:text-sm text-xs font-bold text-[#464E5F]">{noti.title}</p>
                        <p className="text-left font-poppins text-[10px] text-[#858795] mt-1 font-normal">{noti.updated_at}</p>
                        <div className="text-left font-poppins text-xs font-normal text-[#858795] mt-1" dangerouslySetInnerHTML={{ __html: noti.description }}></div>
                    </button>
                </li>
            })}

        </ul>
    </div>
}
export default NotificationView;