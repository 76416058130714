import { FC, useEffect, useMemo, useState, useCallback } from "react";
import DebounceSearchInput from "../../components/Tables/DebounceSearchInput";
import { SearchIcon } from "../../components/common/Icons";
import classNames from "classnames";
import Button from "../../components/common/Button";
import { checkedIsNew } from "../../app/services/employee";
import {
  ColumnFiltersState,
  PaginationState,
  SortingState,
} from "@tanstack/react-table";
import { useSpring, animated } from "react-spring";
import { useGetByModuleTemplateQuery } from "../../app/services/template";
import { getAuthUser } from "../../app/services/dashboard";
import { ToastContainer, toast } from "react-toastify";
import { useDeletTaskCategoryMutation } from "../../app/services/task-category";
import CommonErrorPopup from "../../components/common/CommonErrorPopup";
import ProjectStatusTable from "../../components/ProjectStatusTable/ProjectStatusTable";
import CreateProjectStatusPopup from "../../components/ProjectStatusPopup/CreateProjectStatusPopup";
import { useDeleteProjectTechMutation, useGetProjectTechListQuery } from "../../app/services/project-tech";
import CreateProjectTechPopup from "../../components/ProjectTechPopup/CreateProjectTechPopup";
import ProjectTechTable from "../../components/ProjectTechTable/ProjectTechTable";

export interface IOTData {
  id: number;
  employeeID: string;
  employeeName: string;
  department: string;
  date: string;
  fullHalfDay: string;
  timeIn: string;
  timeOut: string;
  otAmt: string;
}

interface IOT {}

export interface IDepartment {
  id: number;
  name: string;
  label: string;
}

// const defaultFields = ["name", "order", "actions"];

export type TStatusListData = {
  id: number | string;
  name: string;
  label?: string;
};

const ProjectTech: FC<IOT> = () => {
  const [editedData, setEditData] = useState<any>("");
  const [isOpenPopup, setIsOpenPopup] = useState<boolean>(false);
  const [globalSearch, setGlobalSearch] = useState<string>("");
  const [itemCount, setItemCount] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  });
  const [sorting, setSorting] = useState<SortingState>([]);
  const [message, setMessage] = useState<any>([]);
  const [currentView, setCurrentView] = useState<any>(0);
  let [localModuleId, setLocalModuleId] = useState<number>(0);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [hiddenFields, setHiddenFields] = useState<string[]>([]);
  const [viewCols, setCols] = useState<any>([]);
  const [visArray, setVisArray] = useState<any>({});
  const [data, setData] = useState<any[]>([]);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [errorPopup, setErrorPopup] = useState<boolean>(false);
  const [isEditing, setIsEdit] = useState<boolean>(false);
  const [isLayoutControlOpen, setIsLayoutControlOpen] =
    useState<boolean>(false);
  const [skip, setSkip] = useState<boolean>(false);
  var disabledData = checkedIsNew(data);
  const { data: templateData } = useGetByModuleTemplateQuery(localModuleId);
  const { data: projectTech } = useGetProjectTechListQuery({
    page: currentPage,
    limit: pagination.pageSize,
    search: globalSearch,
    // sort: sorting.map((s) => `${s.desc ? "-" : ""}${s.id}`).join(","),
  });
  const [deleteProjectTech] = useDeleteProjectTechMutation();
  var getUser = getAuthUser();
  const getAuthPermis: string[] = useMemo(() => [], []);
  if (getUser?.permissions) {
    getUser?.permissions?.forEach((per: any) => {
      getAuthPermis.push(per?.name);
    });
  }

  const props = useSpring({
    to: { opacity: 1 },
    from: { opacity: 0 },
    delay: 1000,
  });

  useEffect(() => {
    if (projectTech) {
      setData(projectTech?.data?.data ?? []);
      setItemCount(projectTech?.data?.total);
    }
  }, [projectTech]);

  // console.log("projectTech", projectTech?.data?.data);

  useEffect(() => {
    setCurrentPage(1);
  }, [pagination.pageSize, globalSearch]);

  return (
    <div>
      <ToastContainer className={"text-12 font-poppins font-normal"} />
      <CommonErrorPopup
        show={errorPopup}
        setShow={setErrorPopup}
        message={message}
      />
      <div className="px-2 sm:px-4 py-4 bg-white sm:mt-[25px] mt-0 font-poppins text-13 font-semibold text-[#858795] tracking-[-0.03rem] w-full h-full rounded-xl pt-3 pb-8 sm:pt-3 sm:pb-9">
        <div className="flex flex-wrap justify-between items-center">
          <div className="flex items-center mr-2 xs:mr-4 sm:w-[260px] w-[225px] md:mb-1">
            <DebounceSearchInput setState={setGlobalSearch} debounce={800}>
              {(setState) => (
                <div className="font-normal flex border-1 border-[#C4C4C4] bg-white hover:bg-[#EFEFEF] rounded-[28px]">
                  <input
                    id="message"
                    autoComplete="off"
                    name="search-off"
                    type="text"
                    placeholder="Search"
                    className="focus:outline-none py-1 px-4 text-12 xl:text-14 leading-5 bg-transparent rounded-[28px] w-full text-graydark"
                    onChange={(e) => setState(e.target.value)}
                  />
                  <SearchIcon className={classNames("mr-2")} />
                </div>
              )}
            </DebounceSearchInput>
          </div>
          <div className="flex w-full sm:w-auto items-center justify-between md:justify-end lg:justify-end">
            <div className="">
              <Button
                disabled={disabledData?.disabled}
                label={<span className="flex items-center">+ Add New</span>}
                onClick={() => {
                  setIsSuccess(false);
                  setPagination((prev) => ({ ...prev, pageIndex: 0 }));
                  setIsOpenPopup(true);
                }}
                type="button"
                variant="primary"
                customClass={
                  disabledData?.disabledClass +
                  " py-[3px] my-1 px-2 focus:outline-none hover:bg-vorpmyanmar hover:border-vorpmyanmar hover:text-vorpblue 2xl:text-[14px] text-12 leading-5 font-medium text-white"
                }
                size="base"
              />
            </div>
          </div>
        </div>
        <animated.div style={props}>
          <ProjectTechTable
            data={data}
            setData={setData}
            setIsSuccess={setIsSuccess}
            isSuccess={isSuccess}
            pagination={pagination}
            setPagination={setPagination}
            isLayoutControlOpen={isLayoutControlOpen}
            setIsLayoutControlOpen={setIsLayoutControlOpen}
            globalSearch={globalSearch}
            setGlobalSearch={setGlobalSearch}
            columnFilters={columnFilters}
            setColumnFilters={setColumnFilters}
            setHiddenFields={setHiddenFields}
            hiddenFields={hiddenFields ? hiddenFields : []}
            setCurrentView={setCurrentView}
            setIsEdit={setIsEdit}
            isEditing={isEditing}
            localModuleId={localModuleId}
            total={itemCount}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            setCols={setCols}
            visArray={visArray}
            // setSkip={setSkip}
            sorting={sorting}
            setSorting={setSorting}
            deleteProjectTech={deleteProjectTech}
            setEditData={setEditData}
            setIsOpenPopup={setIsOpenPopup}
          />
        </animated.div>
        <ToastContainer className={"text-12 font-poppins font-normal"} />
      </div>
      <CreateProjectTechPopup
        setData={setData}
        isOpen={isOpenPopup}
        setIsOpen={setIsOpenPopup}
        editedData={editedData}
        setEditData={setEditData}
      />
    </div>
  );
};
export default ProjectTech;
