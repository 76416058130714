import { FC } from "react";
import Styles from "./BasicInfo.module.scss";
import { IUserData } from "./types";
import { renderDateFormatDMY } from "../../DashboardCalendarView/DashboardAddEventPopup";

interface IPersonalDetailsInformation {
  userData?: any;
}


const PersonalDetailsInformation: FC<IPersonalDetailsInformation> = ({
  userData,
}) => {
  return (
    <div className="pt-3 pb-[50px] text-left">
      <div className={Styles.profileDisplay}>
        {layoutStructure.map((row, key: number) => {
          if (row?.fieldName) {
            return (
              <div className={Styles.profileDisplay__row} key={row.id * Math.random() * 100}>
                <h4 className="xl:text-15 text-12">{row.fieldName}</h4>
                <p>{row.getData(userData)}</p>
              </div>
            );
          }
          else {
            return (
              <div className={Styles.profileDisplay__header} key={row.id * Math.random() * 100}>
                <h4 className="xl:text-15 text-12">{row.title}</h4>
              </div>
            );
          }
        })}
      </div>
    </div>
  );
};

const layoutStructure = [
  {
    id: 1,
    fieldName: "Full Name",
    getData: (data: IUserData) => data?.name ?? '----',
    // getData: (data: IUserData) => "Maine Visible Onee",
  },
  {
    id: 2,
    fieldName: "Preferred Name",
    getData: (data: IUserData) => data?.preferredName ?? '----',
  },
  {
    id: 3,
    fieldName: "Employee ID",
    getData: (data: IUserData) => data?.employeeID ?? '----',
  },
  {
    id: 25,
    fieldName: "Work Email",
    getData: (data: IUserData) => data?.workEmail ?? '----',
  },
  {
    id: 4,
    fieldName: "Nationality",
    getData: (data: IUserData) => data?.country ?? '----',
  },
  {
    id: 5,
    fieldName: "DOB",
    getData: (data: IUserData) => data?.dob ? renderDateFormatDMY(new Date(data?.dob), '-') : '----',
  },
  {
    id: 6,
    fieldName: "Identity Card No.",
    getData: (data: IUserData) => data?.nationalityID ?? '----',
  },
  {
    id: 7,
    fieldName: "Passport No.",
    getData: (data: IUserData) => data?.passportNo ?? '----',
  },
  {
    id: 8,
    fieldName: "Mobile No.",
    getData: (data: IUserData) => data?.mobile ?? '----',
  },
  {
    id: 9,
    fieldName: "Gender",
    getData: (data: IUserData) => (
      <span className="capitalize">{data?.gender ?? '----'}</span>
    ),
  },
  {
    id: 10,
    fieldName: "Marital Status",
    getData: (data: IUserData) => (
      <span className="capitalize">{data?.maritalStatus ?? '----'}</span>
    ),
  },
  {
    id: 11,
    title: "Contact Information",
  },
  {
    id: 12,
    fieldName: "Address Street 1",
    // getData: (data: IUserData) => data?.address1,
    getData: (data: IUserData) => data?.primaryAddress ?? '----',
  },
  {
    id: 13,
    fieldName: "Address Street 2",
    // getData: (data: IUserData) => data?.address2,
    getData: (data: IUserData) => data?.secondaryAddress ?? '----',
  },
  {
    id: 14,
    fieldName: "City",
    getData: (data: IUserData) => data?.city ?? '----',
  },
  {
    id: 15,
    fieldName: "State/Province",
    getData: (data: IUserData) => data?.state ?? '----',
  },
  {
    id: 16,
    fieldName: "ZIP/Postal",
    getData: (data: IUserData) => data?.zip ?? '----',
  },
  {
    id: 17,
    fieldName: "Country",
    getData: (data: IUserData) => data?.country ?? '----',
  },
  {
    id: 18,
    fieldName: "Mobile Phone No.",
    getData: (data: IUserData) => data?.mobile ?? '----',
  },
  {
    id: 19,
    fieldName: "Work Telephone",
    getData: (data: IUserData) => data?.workPhone ?? '----',
  },
  {
    id: 20,
    fieldName: "Email",
    getData: (data: IUserData) => data?.email ?? '----',
  },
  {
    id: 21,
    title: "Emergency Contacts",
  },
  {
    id: 22,
    fieldName: "Name",
    getData: (data: IUserData) => data?.emergencyContactName ?? '----',
  },
  {
    id: 23,
    fieldName: "Relationship",
    getData: (data: IUserData) => data?.relationship ?? '----',
  },
  {
    id: 24,
    fieldName: "Contact No.",
    getData: (data: IUserData) => data?.emergencyContactPhone ?? '----',
  },
];

export default PersonalDetailsInformation;
