import {
  ReactElement,
  ReactNode,
  useEffect,
  useState,
  forwardRef,
  useMemo,
  useCallback,
} from "react";
import styles from "./BasicLayout.module.scss";
import logo from "./img/Logo-default.svg";
import arrow from "./img/Arrow-double-left-active.svg";
// import dashboard from "./img/ic_round-space-dashboard.svg";
// import dashboard_active from "./img/ic_round-space-dashboard-active.svg";
// import profile from "./img/profile-icon.svg";
// import profile_active from "./img/profile-icon-active.svg";
// import project from "./img/project-icon.svg";
// import project_active from "./img/project-icon-active.svg";
// import crm from "./img/crm-icon.svg";
// import crm_active from "./img/crm-icon-active.svg";
// import employee from "./img/employee-icon.svg";
// import employee_active from "./img/employee-icon-active.svg";
// import pwd from "./img/pwd-icon.svg";
// import pwd_active from "./img/pwd-icon-active.svg";
// import performance from "./img/performance-icon.svg";
// import performance_active from "./img/performance-icon-active.svg";
// import recruitment from "./img/recruitment-icon.svg";
// import recruitment_active from "./img/recruitment-icon-active.svg";
// import doc from "./img/doc-icon.svg";
// import doc_active from "./img/doc-icon.svg";
// import announcement from "./img/announcement-icon.svg";
// import announcement_active from "./img/announcement-icon-active.svg";
// import bkuser from "./img/bk-user.svg";
// import bkuser_active from "./img/bk-user-active.svg";
// import media from "./img/media.svg";
// import media_active from "./img/media-active.svg";
import menuright from "./img/submenu-righ.svg";
import menurightActive from "./img/submenu-right-active.svg";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import UserInfoPopup from "../../components/UserInfoPopup/UserInfoPopup";
import {
  Box,
  ClickAwayListener,
  Popper,
  PopperPlacementType,
} from "@mui/material";
//import axiosCustom from "axios";
import { endpointUrl } from "../../app/services/api";
import authHeader from "../../app/services/auth-header";
import axios from "axios";
import { logout } from "../../app/services/auth.service";
import SidebarFunc from "./SidebarFunc";
import { useSpring, animated } from "react-spring";
import ErrorPopup from "../../components/ErrorPopup/ErrorPopup";
import { useDispatch, useSelector } from "react-redux";
import { initializeApp } from "@firebase/app";
import { getMessaging, onMessage } from "@firebase/messaging";
import {
  setNotification,
  setProfileNotification,
} from "../../reducers/dashboard";
import {
  useGetLastedNotiQuery,
  useNotificationMaskAllAsReadMutation,
  useViewNotificationMutation,
} from "../../app/services/notification";
import NotiList from "../../components/NotiList/NotiList";
import {
  apiKey,
  appId,
  authDomain,
  firebaseConfig,
  measurementId,
  messagingSenderId,
  projectId,
  storageBucket,
} from "../../app/services/firebase";
import { ToastContainer, toast } from "react-toastify";
// import {
//   // TNotification,
//   // useGetLastedNotiQuery,
// } from "../../app/services/notification";
// import NotificationPopup from "../../components/Notification/NotificationPopup";

var classNames = require("classnames");

interface FadeProps {
  children?: React.ReactElement;
  in?: boolean;
  onEnter?: () => void;
  onExited?: () => void;
}

export type DefaultLayoutProps = {
  children: ReactElement | ReactNode | null;
  userData?: any;
  permissions?: any;
  hideLeftSidebar?: boolean;
};
export type UserDataProps = {
  user: string;
  role: string;
  permissionData: any[];
};

var userDatas = localStorage.getItem("userData");
var userJson: any = {};
if (userDatas) {
  userJson = JSON.parse(userDatas);
  userJson.token = userJson.accessToken;
}

var getUser = userJson;
const getAuthPermissions: string[] = [];

if (getUser?.permissions) {
  getUser?.permissions?.forEach((per: any) => {
    getAuthPermissions.push(per?.name);
  });
}

const Fade = forwardRef<HTMLDivElement, FadeProps>(function Fade(
  props: any,
  ref: any
) {
  const { in: open, children, onEnter, onExited, ...other } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter();
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited();
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {children ?? ""}
    </animated.div>
  );
});

const BasicLayout = (props: DefaultLayoutProps): ReactElement => {
  const { userData, hideLeftSidebar } = props;

  // const checkPermis = getAuthPermissions.includes(permissions[0])
  const [style, setStyle] = useState("");
  const [content, setContent] = useState("");
  const [menuClose, setMenuClose] = useState("hidden");
  const [active, setActiveMenu] = useState("Admin Dashboard");
  const [subActive, setSubActiveMenu] = useState("");
  const [subMenuTitle, setSubMenuTitle] = useState("");
  const [menuTitle, setMenuTitle] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [errorPopup, setErrorPopup] = useState<boolean>(false);
  const [message, setMessage] = useState<any>([]);

  const [showLoginBox, setLoginBox] = useState<boolean>(false);
  const [showNotiBox, setNotiBox] = useState<boolean>(false);
  const [placement, setPlacement] = useState<PopperPlacementType>();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [anchorElNoti, setAnchorElNoti] = useState<HTMLButtonElement | null>(
    null
  );
  const [open, setOpen] = useState(false);
  const [show, setShow] = useState<boolean>(false);
  const [showLogoutPopup, setLogoutPopup] = useState<boolean>(false);
  const [showChangePwPopup, setShowChangePw] = useState<boolean>(false);
  const [unlink, setUnlink] = useState<string>("");
  const [notiClick, setNotiClick] = useState<string>("");
  const { data: notificationList } = useGetLastedNotiQuery(notiClick);
  const [viewNoti] = useViewNotificationMutation();
  const [markAllAsRead] = useNotificationMaskAllAsReadMutation();
  const stateData: any = useSelector((state: any) => state?.dashboard?.notificationDetail);
  const [notifications, setNotifications] = useState<any>([]);
  const [isProjectBoard, setIsProjectBoard] = useState<boolean>(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const messaging = getMessaging(app);
  onMessage(messaging, function (payload) {
    var isDocType = payload?.data?.type == "document";
    var isAnnoucementType = payload?.data?.type == "announcement";
    var isPasswordType = payload?.data?.type == "password";
    var isLeaveApprovedType =
      payload?.data?.type == "leave" && payload?.data?.status == "done";
    var notiList = [...notifications.notifications, payload.notification];
    var data = {
      profile_unread_noti_count: notifications.profile_unread_noti_count,
      unapprove_claim_count: notifications.unapprove_claim_count,
      unapprove_leave_count:
        isPasswordType || isDocType
          ? Number(notifications.unapprove_leave_count) + 1
          : Number(notifications.unapprove_leave_count),
      unapprove_ot_count: Number(notifications?.unapprove_ot_count),
      unread_count: Number(notifications.unread_count) + 1,
      unread_data_count: {
        unread_document_count: isDocType
          ? Number(notifications.unread_data_count.unread_document_count) + 1
          : Number(notifications.unread_data_count.unread_document_count),
        unread_leave_count: Number(
          notifications.unread_data_count.unread_leave_count
        ),
        unread_password_count: isPasswordType
          ? Number(notifications.unread_data_count.unread_password_count) + 1
          : Number(notifications.unread_data_count.unread_password_count),
        unread_claim_count:
          notifications?.unread_data_count?.unread_claim_count,
      },
      notifications: notiList,
    };
    //console.log("payload.notification ", payload);
    toast(payload.notification?.body, {
      position: "bottom-right",
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      type: "success",
    });
    setNotifications(data);
    dispatch(setNotification(payload.notification));
    dispatch(setProfileNotification(data));
    setNotiClick("noti");
  });
  var sidebarsData = SidebarFunc();
  var data = sidebarsData.map((x: any) => {
    var filter = x.subcontents.sort((a: any, b: any) => (a.id > b.id ? 1 : -1));
    x.subcontents = filter;
    return x;
  });
  const [sidebars, setSidebars] = useState(sidebarsData);
  const [showEmp, setShowEmp] = useState<boolean>(true);
  const [showUser, setShowUser] = useState<boolean>(true);
  const confirmEvent = () => {
    localStorage.setItem("token", "");
    deleteDatabase();
    localStorage.clear();
    setLoginBox(false);
  };

  const deleteDatabase = () => {
    const request = indexedDB.deleteDatabase("myDatabase");

    request.onsuccess = () => {
      // console.log("Database deleted successfully");
    };

    request.onerror = (event) => {
      console.error(
        "Error deleting database",
        (event.target as IDBOpenDBRequest).error
      );
    };
  };

  const logoutFun = () => {
    setShow(false);
    setLogoutPopup(true);
  };
  const changePassword = () => {
    setShow(false);
    setShowChangePw(true);
  };
  const saveChangePassword = (
    oldPW: string,
    newPW: string,
    confirmPW: string
  ) => {
    axios
      .post(
        endpointUrl + "password/change",
        {
          current_password: oldPW,
          new_password: newPW,
          password_confirmation: confirmPW,
        },
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        if (response.data.status) {
          setShowChangePw(false);
          setLoginBox(false);
          navigate("/login");
          logout();
        }
      })
      .catch((error) => {
        setShowChangePw(true);
        setLoginBox(true);
        setErrorPopup(true);
        setMessage(
          error?.response
            ? [error?.response?.data?.message]
            : ["Something wrong!. Trying again."]
        );
        setErrorMsg(error.response.data.message);
      });
  };
  const handleMenu = () => {
    setStyle("hidden");
    setContent(styles.leftsideHide);
    setMenuClose(styles.handleMenuCloseImg);
    document.getElementById("");
  };
  const handleMenuClose = () => {
    setContent("");
    setStyle("");
    setMenuClose("hidden");
  };

  const changeSubmenuActive = (name: string) => {
    sidebars?.map((s: any) => {
      s.subcontents.map((m: any) => {
        m.subMenuContents?.map((sub: any) => {
          if (sub.title == name) {
            if (sub.active !== "active") {
              sub.active = "active";
              if (sub.link !== "") {
                setSubMenuTitle(sub.label);
              }
            } else {
              sub.active = "";
            }
          } else {
            sub.active = "";
          }
        });
      });
    });
  };

  const [isNested, setIsNest] = useState<boolean>(false);
  const [isClickDouble, setIsDoouble] = useState<boolean>(true);

  const changeToActiveState = (name: string) => {
    setIsDoouble(!isClickDouble);
    setIsNest(!isNested);
    var activeMenu = "";
    sidebars?.map((s: any) => {
      s.subcontents.map((m: any) => {
        if (m.title == name) {
          if (m.active !== "active") {
            m.active = "active";
            activeMenu = m.title;
            if (m.link == "#") {
              setUnlink(m.title);
            } else {
              //setUnlink('');
            }
          } else {
            m.active = "";
          }
        } else {
          m.active = "";
        }
      });
    });

    setActiveMenu(name);
    setMenuTitle(activeMenu);
    setSubMenuTitle("");
  };

  const location = useLocation();
  // var currentLink = "";

  useEffect(() => {
    var currentPath = location.pathname;
    var data = sidebarsData
      ?.map((x: any) =>
        x?.subcontents.find((sub: any) => sub.link == currentPath)
      )
      .filter((x: any) => x !== undefined);
    var subdata = sidebarsData
      ?.map((x: any) =>
        x.subcontents
          .map((sub: any) =>
            sub.subMenuContents?.find((sm: any) => sm.link == currentPath)
          )
          .filter((x: any) => x !== undefined)
      )
      .filter((x: any) => x.length > 0)[0];
    var activeMenu = "";
    var activeSubMenu = "";
    if (data) {
      if (!window.location.href.includes("#")) {
        setActiveMenu(data[0] ? data[0].title : "");
        // setActiveMenu(data[0] ? data[0].title : "");
        setMenuTitle(data[0] ? data[0].title : "");
        activeMenu = data[0] ? data[0].title : "";
        activeSubMenu = "";
      } else {
        setActiveMenu("");
      }
    }
    if (subdata) {
      setActiveMenu(subdata[0] ? subdata[0].title : "");
      var data = sidebars
        ?.map((x: any) =>
          x.subcontents.find((sub: any) =>
            sub.subMenuContents?.find((x: any) => x.id == subdata[0]?.id)
          )
        )
        .filter((x: any) => x != undefined);
      if (data) {
        setMenuTitle(data[0] ? data[0].title : "");
        activeMenu = data[0] ? data[0].title : "";
      }
      setSubMenuTitle(subdata[0] ? subdata[0].label : "");
      activeSubMenu = subdata[0] ? subdata[0].label : "";
    }
    sidebarsData?.map((x: any) =>
      x.subcontents.map((s: any) => {
        if (s.title == activeMenu) {
          s.active = "active";

          if (window.location.href.includes("#")) {
            setActiveMenu(s.title);
            // setMenuTitle(activeMenu);

            return s.subMenuContents?.map((su: any) => {
              if (su.label == subMenuTitle) {
                su.active = "active";
                setSubActiveMenu(su.label);
              }
            });
          } else {
            setActiveMenu("");
          }
        } else {
          s.active = "";
        }
        if (window.location.href.includes("#")) {
          if (s.title == activeMenu) {
            s.active = "active";
            setActiveMenu(s.title);
            setMenuTitle(s.title);
            return s.subMenuContents?.map((su: any) => {
              if (su.label == subMenuTitle) {
                su.active = "active";
                setSubActiveMenu(su.label);
              }
            });
          } else {
            s.active = "";
          }
        } else {
          if (s.title == activeMenu) {
            s.active = "active";
            if (s.title !== "Employees") {
              setShowEmp(!showEmp);
            }
            if (s.title != "Backend Users") {
              setShowUser(!showUser);
            }
            setActiveMenu(s.title);
            return s.subMenuContents?.map((su: any) => {
              if (su.label == subMenuTitle) {
                su.active = "active";
                setSubActiveMenu(su.label);
                setIsNest(true);
              }
            });
          }
        }
      })
    );
    setSidebars(sidebarsData);
  }, [active, subActive, menuTitle]); //sidebars, sidebarsData

  const handleClickAway = () => {
    setLoginBox(false);
  };
  const handleClickAwayNoti = () => {
    setNotiBox(false);
    // setNotiClick('');
  };
  const handleClick = (
    newPlacement: PopperPlacementType,
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget);
    setPlacement(newPlacement);
  };
  const handleClickNoti = (
    newPlacement: PopperPlacementType,
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorElNoti(event.currentTarget);
    setPlacement(newPlacement);
    setNotiClick("search");
  };

  const canBeOpen = open && Boolean(anchorEl);
  const id = canBeOpen ? "spring-popper" : undefined;

  const propsData = useSpring({
    to: { opacity: 1 },
    from: { opacity: 0 },
    delay: 600,
  });
  useEffect(() => {
    if (notificationList) {
      setNotifications(notificationList);
      dispatch(setProfileNotification(notificationList));
    }
  }, [notificationList]);
  useEffect(() => {
    setNotifications(stateData);
  }, [stateData]);
  useEffect(() => {
    if (window.location.href.includes("projects-board")) {
      setIsProjectBoard(true);
    }
  }, [window.location]);
  return (
    <>
      <ErrorPopup
        descText="Error Message!"
        confirmBtnText="Yes, Close."
        show={errorPopup}
        setShow={setErrorPopup}
        message={message}
        setMessage={setMessage}
      />
      <ToastContainer
        className={"text-12 font-poppins font-normal text-left"}
      />
      <div className="wrapper w-full ">
        <div
          id="sidebar"
          className={classNames(
            style == "hidden" ? styles.hiddenData : styles.openData,
            hideLeftSidebar == true ? "hidden" : "",
            styles.leftSidebar,
            isProjectBoard == true ? "fixed top-0 z-[9999999]" : "",
            "  xl:max-w-[235px] md:max-w-[227px] max-w-[200px] w-full h-full overflow-y-auto bg-white fixed left-0 z-10 border-r-[1px] border-r-[#EEEFF3]"
          )}
        >
          <div className="flex justify-between 2xl:px-6 px-3 py-1 border-b-[1px] border-b-[#EEEFF3] mb-9 h-[64px]">
            <a href="/" className="w-full focus-visible:outline-none h-full">
              <img
                src={logo}
                alt="Logo-default"
                className="xl:max-w-full max-w-[150px] cursor-pointer mx-auto  h-full"
              />
            </a>
            <img
              src={arrow}
              alt="Arrow-double-left-active"
              className={classNames("cursor-pointer max-w-[24px]")}
              onClick={() => handleMenu()}
            />
          </div>

          <div className="px-[15px] transition-all duration-1000">
            {sidebars?.map((sidebar: any, index: any) => (
              <div key={index}>
                <p
                  className={classNames(
                    sidebar?.subcontents.length <= 0 ? "hidden" : "",
                    "mt-[25px] uppercase md:pl-[21px] pl-2 font-poppins md:text-[13px] text-[12px] font-normal text-[#1B3757] text-left mb-[6px]"
                  )}
                >
                  {sidebar.title}
                </p>
                <ul>
                  {sidebar?.subcontents?.map((subcontent: any, key: any) => {
                    // console.log("subcontent", subcontent.link);

                    return (
                      <div
                        key={key}
                        className={classNames(
                          subcontent.link == "/profile"
                            ? userData?.user?.employee_id == 0
                              ? "hidden"
                              : ""
                            : ""
                        )}
                      >
                        <li
                          onClick={() => {
                            changeToActiveState(subcontent.title);
                            if (subcontent.title == "Employees") {
                              setShowEmp(true);
                              setShowUser(false);
                            }
                            if (subcontent.title == "Backend Users") {
                              setShowUser(true);
                              setShowEmp(false);
                            }
                          }}
                          key={subcontent.id}
                          className={classNames(
                            subcontent.link == ""
                              ? "opacity-70 pointer-events-none"
                              : "",
                            subcontent.link == "#"
                              ? unlink == subcontent.title
                                ? styles.menuActive
                                : active == subcontent.title
                                  ? styles.menuActive
                                  : ""
                              : active == subcontent.title
                                ? styles.menuActive
                                : "",
                            "rounded-[8px] xl:pl-[16px] pl-2 py-[11px] flex items-center font-poppins md:text-sm text-[12px] font-normal text-[#858795]"
                          )}
                        >
                          {subcontent ? (
                            subcontent.link.toString() == "#" ? (
                              <a className="flex justify-between w-full pr-4 cursor-pointer">
                                <div className="flex items-center relative">
                                  <img
                                    src={
                                      active == subcontent.title
                                        ? subcontent.activeIcon
                                        : subcontent.icon
                                    }
                                    alt="icon"
                                    className="mr-[10px]"
                                  />
                                  <p className="text-left hover:text-vorpblue">
                                    {subcontent.title}
                                  </p>
                                  <div
                                    className={classNames(
                                      subcontent.title == "Employees" &&
                                        Number(
                                          notifications.unapprove_leave_count
                                            ? notifications.unapprove_leave_count
                                            : 0
                                        ) +
                                        Number(
                                          notifications.unapprove_ot_count
                                            ? notifications.unapprove_ot_count
                                            : 0
                                        ) >
                                        0
                                        ? ""
                                        : "hidden",
                                      " w-[18px] h-[18px] bg-redPink absolute top-1/2 right-[-23px] -translate-y-1/2 rounded-full"
                                    )}
                                  >
                                    <span
                                      className={classNames(
                                        "absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 inline-block text-white text-[10px] font-semibold p-[2px] whitespace-nowrap"
                                      )}
                                    >
                                      {Number(
                                        notifications.unapprove_leave_count
                                          ? notifications.unapprove_leave_count
                                          : 0
                                      ) +
                                        Number(
                                          notifications.unapprove_ot_count
                                            ? notifications.unapprove_ot_count
                                            : 0
                                        )}
                                    </span>
                                  </div>
                                </div>
                                {unlink == subcontent.title ||
                                  active == subcontent.title ? (
                                  <div className="relative">
                                    <img
                                      src={
                                        subcontent.active == "active"
                                          ? menurightActive
                                          : menuright
                                      }
                                      className={classNames(
                                        "rotate-90 active",
                                        subcontent.subMenuContents
                                          ? ""
                                          : "hidden",
                                        "object-contain"
                                      )}
                                    />
                                  </div>
                                ) : (
                                  <img
                                    src={
                                      subcontent.active == "active"
                                        ? menurightActive
                                        : menuright
                                    }
                                    className={classNames(
                                      subcontent.active == "active" ? "" : "",
                                      subcontent.subMenuContents
                                        ? ""
                                        : "hidden",
                                      "object-contain"
                                    )}
                                  />
                                )}
                              </a>
                            ) : (
                              <NavLink
                                to={{
                                  pathname:
                                    subcontent.link == "#"
                                      ? ""
                                      : subcontent.link,
                                }}
                                state={0}
                                className={classNames(
                                  subcontent.link == ""
                                    ? "opacity-70 pointer-events-none"
                                    : "",
                                  "pr-0 w-full flex justify-between items-center relative"
                                )}
                              >
                                <div className="flex items-center relative">
                                  <img
                                    src={
                                      active == subcontent.title
                                        ? subcontent.activeIcon
                                        : subcontent.icon
                                    }
                                    alt="icon"
                                    className="mr-[10px]"
                                  />
                                  <p className="text-left mr-[3px] hover:text-vorpblue">
                                    {subcontent.title}
                                  </p>
                                  <div
                                    className={classNames(
                                      (subcontent.link == "/activity_log" && notifications.unread_activity_log_count > 0) ?
                                        Number(
                                          notifications.unread_activity_log_count ?? 0
                                        ) > 0 :
                                        (subcontent.link == "/profile" && notifications.profile_unread_noti_count > 0) ?
                                          Number(
                                            notifications.profile_unread_noti_count ??
                                            0
                                          ) > 0
                                          : (subcontent.link == "/claims" && notifications.unapprove_claim_count > 0) ?
                                            Number(
                                              notifications.unapprove_claim_count ??
                                              0
                                            ) > 0
                                            : "hidden",
                                      " w-[18px] h-[18px] bg-redPink absolute top-1/2 left-full -translate-y-1/2 rounded-full whitespace-nowrap"
                                    )}
                                  >
                                    <span
                                      className={classNames(
                                        "absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 inline-block text-white text-[10px] font-semibold p-[2px] whitespace-nowrap"
                                      )}
                                    >
                                      {
                                        subcontent.link == "/activity_log"
                                        ? Number(
                                          notifications.unread_activity_log_count ??
                                          0
                                        )
                                        :
                                        subcontent.link == "/profile"
                                          ? Number(
                                            notifications.profile_unread_noti_count ??
                                            0
                                          )
                                          : subcontent.link == "/claims"
                                            ? Number(
                                              notifications.unapprove_claim_count ??
                                              0
                                            )
                                            : ""}
                                    </span>
                                  </div>
                                </div>

                                {unlink == subcontent.title ||
                                  active == subcontent.title ? (
                                  <img
                                    src={
                                      subcontent.active == "active"
                                        ? menurightActive
                                        : menuright
                                    }
                                    className={classNames(
                                      "rotate-90 active",
                                      subcontent.subMenuContents
                                        ? ""
                                        : "hidden",
                                      "object-contain"
                                    )}
                                  />
                                ) : (
                                  <img
                                    src={
                                      subcontent.active == "active"
                                        ? menurightActive
                                        : menuright
                                    }
                                    className={classNames(
                                      subcontent.active == "active" ? "" : "",
                                      subcontent.subMenuContents
                                        ? ""
                                        : "hidden",
                                      "object-contain"
                                    )}
                                  />
                                )}
                              </NavLink>
                            )
                          ) : (
                            ""
                          )}
                        </li>
                        <div
                          className={classNames(
                            subcontent.title == unlink &&
                              (subcontent.title == "Employees"
                                ? showEmp
                                : subcontent.title == "Backend Users"
                                  ? showUser
                                  : false)
                              ? styles.toggleContent
                              : active == subcontent.title &&
                                (subcontent.title == "Employees"
                                  ? showEmp
                                  : subcontent.title == "Backend Users"
                                    ? showUser
                                    : false)
                                ? styles.toggleContent
                                : styles.toggleContentHide,
                            "mt-[10px]"
                          )}
                        >
                          <ul className="pl-[52px] ">
                            {subcontent.subMenuContents?.map(
                              (menucontent: any, uniqKey: any) => {
                                return (
                                  <li
                                    key={menucontent.id}
                                    onClick={() =>
                                      changeSubmenuActive(menucontent.title)
                                    }
                                    className={classNames(
                                      menucontent.active == "active"
                                        ? styles.submenuActive
                                        : "",
                                      "text-left mb-3 list-disc font-poppins text-13 font-normal text-[#858795]",
                                      menucontent.link == ""
                                        ? "opacity-70 pointer-events-none"
                                        : ""
                                    )}
                                  >
                                    {menucontent?.link == "#" ? (
                                      <a className="flex justify-between w-full pr-4">
                                        {menucontent.title}
                                      </a>
                                    ) : (
                                      <div className="relative">
                                        <NavLink
                                          to={{
                                            pathname:
                                              menucontent.link !== "#"
                                                ? menucontent.link
                                                : "",
                                          }}
                                          className={classNames(
                                            menucontent.link == "#"
                                              ? "opacity-70 pointer-events-none"
                                              : ""
                                          )}
                                        >
                                          <div className="relative">
                                            <span className="block mr-[10px]">
                                              {menucontent.title}
                                            </span>
                                            <div
                                              className={classNames(
                                                (menucontent?.link ==
                                                  "/employeeList/leave" &&
                                                  Number(
                                                    notifications.unapprove_leave_count ??
                                                    0
                                                  ) > 0) ||
                                                  (menucontent?.link ==
                                                    "/employeeList/ot-list" &&
                                                    Number(
                                                      notifications.unapprove_ot_count ??
                                                      0
                                                    ) > 0)
                                                  ? ""
                                                  : "hidden",
                                                " w-[18px] h-[18px] bg-redPink absolute top-0 right-0 rounded-full"
                                              )}
                                            >
                                              <span
                                                className={classNames(
                                                  "absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 inline-block text-white text-[10px] font-semibold p-[2px] whitespace-nowrap"
                                                )}
                                              >
                                                {menucontent?.link ==
                                                  "/employeeList/leave" &&
                                                  Number(
                                                    notifications.unapprove_leave_count ??
                                                    0
                                                  ) > 0
                                                  ? Number(
                                                    notifications.unapprove_leave_count ??
                                                    0
                                                  )
                                                  : menucontent?.link ==
                                                    "/employeeList/ot-list" &&
                                                    Number(
                                                      notifications.unapprove_ot_count ??
                                                      0
                                                    ) > 0
                                                    ? Number(
                                                      notifications.unapprove_ot_count ??
                                                      0
                                                    )
                                                    : ""}
                                              </span>
                                            </div>
                                          </div>
                                        </NavLink>
                                      </div>
                                    )}
                                  </li>
                                );
                              }
                            )}
                          </ul>
                        </div>
                      </div>
                    );
                  })}
                </ul>
              </div>
            ))}
          </div>
        </div>

        <div
          id="content-body"
          className={classNames(
            isProjectBoard ? "pt-[30px] bg-[#F3F6F9]" : "",
            "content bg-[#F3F6F9] w-full h-full",
            styles.hidescrollbar,
            styles.defaultWidth,
            isProjectBoard == true ? "relative" : "fixed overflow-auto"
          )}
        >
          <div
            className={classNames(
              content,
              styles.defaultWidth,
              "sm:px-4 px-2 xl:ml-[235px] lg:ml-[7px] ml-0 relative"
            )}
          >
            <div
              className={classNames(
                content,
                styles.defaultWidth,
                "flex items-center top-0 fixed w-full left-0 custom-header h-[65px] bg-white border-b-[1px] border-b-[#EEEFF3] z-[999999]"
              )}
            >
              <img
                src={arrow}
                alt="Arrow-double-left-active"
                className={classNames(
                  menuClose,
                  style == "hidden" ? "rotate-180" : "",
                  "cursor-pointer"
                )}
                onClick={() => handleMenuClose()}
              />

              <div
                className={classNames(
                  content,
                  styles.defaultWidth,
                  "xl:ml-[235px] lg:ml-[227px] ml-0 lg:pl-5 pl-0 xl:pr-9 pr-4 flex justify-between items-center w-full"
                )}
              >
                <p className="font-poppins font-semibold md:text-base text-xs text-[#464E5F] flex xs:flex-row flex-col items-center">
                  {window.location.pathname == "/notifications"
                    ? "Notifications"
                    :window.location.pathname == "/profile/announcement"
                    ? "All Announcements"
                    : window.location.pathname == "/new-claim-type"
                    ? "Claim Type"
                    :window.location.pathname == "/all-task-list"
                    ? "All Task List"
                    : window.location.pathname.includes("progress-checker/")
                    ? "Progress Checker"
                    : window.location.pathname.includes("employee/")
                    ? "Employee Leave"
                    : menuTitle}
                  {subMenuTitle ? (
                    <span
                      className={classNames(
                        styles.subMenuTitle,
                        "xs:ml-4 font-poppins text-xs font-normal text-[#858795]"
                      )}
                    >
                      {subMenuTitle}
                    </span>
                  ) : (
                    ""
                  )}
                </p>
                <div>
                  <div className="flex py-3 relative">
                    <div className="mr-5">
                      <ClickAwayListener onClickAway={handleClickAwayNoti}>
                        <Box sx={{ position: "relative" }}>
                          <button
                            className="relative cursor-pointer"
                            onClick={(event) => {
                              handleClickNoti("bottom-start", event);
                              setNotiBox(!showNotiBox);
                            }}
                          >
                            <div
                              className={classNames(
                                notifications.unread_count ?? 0 > 0
                                  ? ""
                                  : "hidden",
                                "w-[18px] h-[18px] bg-redPink absolute top-0 sm:right-[-5px] right-[-10px] rounded-full"
                              )}
                            >
                              <span className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 inline-block text-white text-[10px] font-semibold p-[2px] whitespace-nowrap">
                                {notifications.unread_count ?? 0}
                              </span>
                            </div>
                            <div
                              className={classNames(
                                styles.notificationIcon,
                                "sm:p-2 p-1 border-1 border-[#E4E6EF] rounded-lg"
                              )}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <path
                                  opacity="0.3"
                                  d="M12 22C13.6569 22 15 20.6569 15 19C15 17.3431 13.6569 16 12 16C10.3431 16 9 17.3431 9 19C9 20.6569 10.3431 22 12 22Z"
                                  fill="#464E5F"
                                />
                                <path
                                  d="M19 15V18C19 18.6 18.6 19 18 19H6C5.4 19 5 18.6 5 18V15C6.1 15 7 14.1 7 13V10C7 7.6 8.7 5.6 11 5.1V3C11 2.4 11.4 2 12 2C12.6 2 13 2.4 13 3V5.1C15.3 5.6 17 7.6 17 10V13C17 14.1 17.9 15 19 15ZM11 10C11 9.4 11.4 9 12 9C12.6 9 13 8.6 13 8C13 7.4 12.6 7 12 7C10.3 7 9 8.3 9 10C9 10.6 9.4 11 10 11C10.6 11 11 10.6 11 10Z"
                                  fill="#464E5F"
                                />
                              </svg>
                            </div>
                          </button>

                          {showNotiBox ? (
                            <Popper
                              id={id}
                              open={showNotiBox}
                              anchorEl={anchorElNoti}
                              transition
                            >
                              {({ TransitionProps }) => (
                                <Fade {...TransitionProps}>
                                  <NotiList
                                    notificationsList={notifications ?? []}
                                    viewNoti={viewNoti}
                                    markAllAsRead={markAllAsRead}
                                  />
                                </Fade>
                              )}
                            </Popper>
                          ) : null}
                        </Box>
                      </ClickAwayListener>
                    </div>
                    <div className="mr-3">
                      <p className="capitalize text-right font-poppins md:text-sm text-xs text-[#464E5F] font-semibold">
                        {userData?.user?.name}
                      </p>
                      <p className=" inline-block text-right font-poppins text-xs text-[#C4C4C4] font-semibold">
                        {userData?.designation ? userData.designation : ""}
                      </p>
                    </div>
                    <ClickAwayListener onClickAway={handleClickAway}>
                      <Box sx={{ position: "relative" }}>
                        {userData?.user?.photo ? (
                          <div className="relative inline-flex w-fit">
                            <button
                              onClick={(event) => {
                                handleClick("bottom-start", event);
                                setLoginBox(!showLoginBox);
                              }}
                            >
                              <img
                                id="profileImage"
                                src={userData?.user?.photo}
                                alt="profile img"
                                className="w-[40px] h-[40px] object-cover rounded-[6px]"
                              />
                            </button>
                          </div>
                        ) : (
                          <div className="bg-[#A5A6F6] rounded-[6px] ">
                            <button
                              onClick={(event) => {
                                handleClick("bottom-start", event);
                                setLoginBox(!showLoginBox);
                              }}
                              className="focus-visible:outline-none uppercase sm:py-[5px] py-[1px] sm:px-[14px] px-2 font-poppins font-medium md:text-xl text-base"
                            >
                              {userData?.user?.name.slice(0, 1)}
                            </button>
                          </div>
                        )}
                        {showLoginBox ? (
                          <Popper
                            id={id}
                            open={showLoginBox}
                            anchorEl={anchorEl}
                            transition
                          >
                            {({ TransitionProps }) => (
                              <Fade {...TransitionProps}>
                                <UserInfoPopup
                                  confirmEvent={confirmEvent}
                                  saveChangePassword={saveChangePassword}
                                  changePassword={changePassword}
                                  logout={logoutFun}
                                  showLogoutPopup={showLogoutPopup}
                                  showChangePwPopup={showChangePwPopup}
                                  setShowChangePw={setShowChangePw}
                                  setLogoutPopup={setLogoutPopup}
                                  setShow={setLoginBox}
                                  errorMsg={errorMsg}
                                />
                              </Fade>
                            )}
                          </Popper>
                        ) : null}
                      </Box>
                    </ClickAwayListener>
                  </div>
                </div>
              </div>
            </div>

            <div
              className={classNames(
                "mt-[76px] ",
                isProjectBoard == true ? "w-[100vw]" : "overflow-x-hidden",
                styles.hidescrollbar
              )}
            >
              {props.children ?? ""}
            </div>
          </div>
        </div>
        <div className="footer"></div>
      </div>
    </>
  );
};

export default BasicLayout;
