import { createAction, createSlice } from '@reduxjs/toolkit';
import { EventList } from '../app/services/dashboard';
export type NotiType={
  unread_document_count:number,
  unread_leave_count:number,
  unread_password_count:number,
}

export type AuthenticationInitialState = { eventsList: null | undefined | EventList[], notification: any, notificationDetail: NotiType,projects:any };

const initialState: AuthenticationInitialState = { eventsList: [], notification: [], notificationDetail: {
  unread_document_count:0,
  unread_leave_count:0,
  unread_password_count:0
},projects:[] };
// type payload={
//     month:number,
//     year:number
// }
export const getEventsListData = createAction('/eventsList', function prepare(month: number, year: number) {
  return {
    payload: {
      month,
      year
    },
  }
})

const slice = createSlice({
  name: 'getEvents',
  initialState,
  reducers: {
    getEvents: (state, action: any) => {
      if (action.type == "getEventsList") {

      }
      return { ...state, eventsList: action.payload };
    },
    setNotification: (state, action) => {
      var notification = [...state.notification, action.payload];
      return { ...state, notification: notification };
    },
    setProfileNotification: (state, action) => {
     // console.log("action.payload ", action.payload,state)
      return { ...state, notificationDetail: action.payload };
    },
    readSingleNoti: (state, action) => {
     // console.log("action.payload ", action.payload)
      return { ...state, notificationDetail: action.payload };
    },
    setProjectAssignRole: (state, action) => {
     // console.log("action.payload ", action.payload)
       return { ...state, projects: action.payload };
     },
  },
});
//const actionCreator = createAction('SOME_ACTION_TYPE')
const { actions } = slice
// Extract and export each action creator by name
export const { getEvents, setNotification, setProfileNotification,readSingleNoti,setProjectAssignRole } = actions
// export const { getEvents } = slice.actions;

export default slice.reducer;
