// import { Modal, Fade, makeStyles } from "@material-ui/core";
import { Fade, Modal } from "@mui/material";
import {
  ChangeEvent,
  Dispatch,
  FC,
  SetStateAction,
  createRef,
  useCallback,
  useEffect,
  useState,
} from "react";
import { CloseIcon, SelectedIcon } from "../common/Icons";
import { NumberDropdownList } from "../common/DropdownList";
import { roles } from "../../utils/constants";
import { truncateFilename } from "../../utils/helpers";
import axios from "../../axios";
import { endpointUrl } from "../../app/services/api";
import authHeader from "../../app/services/auth-header";
import classNames from "classnames";

interface IImportUsersModal {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  sampleCSV?: any;
  importEmployee?: any;
  isImporting?: boolean;
  setIsImporting?: Dispatch<SetStateAction<boolean>>;
  isDownloading?: boolean;
  setIsDownloading?: Dispatch<SetStateAction<boolean>>;
  isChecked?: boolean;
  isProject?: boolean;
}

interface IFormData {
  role: number;
  isUpdateExisting: boolean;
}

// const ImportUsersModal: FC<IImportUsersModal> = ({
const ImportUsersModal: FC<any> = ({
  isOpen,
  setIsOpen,
  sampleCSV,
  importEmployee,
  isImporting,
  setIsImporting,
  isDownloading,
  setIsDownloading,
  isChecked,
  isProject,
}) => {
  const [formData, setFormData] = useState<IFormData>({
    role: 10,
    isUpdateExisting: false,
  });

  const [fileError, setFileError] = useState<boolean>(false);
  const [fileName, setFileName] = useState<string>("");
  const fileRef = createRef<HTMLInputElement>();

  async function importData() {
    const file = fileRef.current?.files?.[0];
    if (file && file.size <= 2097152 && file.type == "text/csv") {
      setFileError(false);
    } else {
      setFileError(true);
    }
    if (file) {
      let formSaveData = new FormData();
      formSaveData.append("file", file);
      formSaveData.append(
        "isReplaced",
        formData.isUpdateExisting == true ? "1" : "0"
      );
      var importedData = {
        file: file,
        isReplaced: formData.isUpdateExisting == true ? 1 : 0,
      };
      importEmployee(formSaveData);
      if (setIsImporting) {
        setIsImporting(true);
      }
    }
    //setIsOpen(false);
  }

  const displayFilename = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const file = e.target?.files?.[0];
      if (file?.name) {
        setFileName(file.name);
      } else {
        setFileName("");
      }
    },
    [setFileName]
  );

  useEffect(() => {
    setFileName("");
    setFormData({ ...formData, isUpdateExisting: false });
  }, [isOpen]);

  return (
    <>
      <Modal
        open={isOpen}
        style={{
          background: "rgb(0 0 0 / 0.3)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        // onClose={() => setIsOpen(false)}
        onClose={(event, reason) => {
          if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
            // Set 'open' to false, however you would do that with your particular code.
            setIsOpen(false);
          }
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableEnforceFocus={true}
        // disableBackdropClick={true}
      >
        <Fade in={isOpen} timeout={500}>
          <div className="modalContent max-w-[530px] w-full m-auto px-4 py-8 xl:px-0 xl:py-0 font-primary">
            <div className="w-full bg-white rounded-xl overflow-hidden">
              <div className="px-6 sm:px-8 md:px-10 py-6 bg-vorpblue flex justify-between items-center rounded-t-xl overflow-hidden">
                <h1 className="text-16 sm:text-18 leading-[27px] font-semibold text-white">
                  Import
                </h1>
                <button type="button" onClick={() => setIsOpen(false)}>
                  <CloseIcon width={18} height={18} />
                </button>
              </div>
              <div className="px-4 sm:px-6 lg:px-10 py-6 pb-8 lg:py-8 lg:pb-10">
                {/* <div className="mb-5">
              <p className="base-label mb-2">Select Roles:</p>
              <NumberDropdownList
                state={formData.role}
                setState={(value) =>
                  setFormData((prev) => ({ ...prev, role: value as number }))
                }
                dropdownItems={roles.map((role) => ({
                  value: role.value,
                  label: role.label,
                }))}
                className="w-full 6xs:text-12 sm:text-14"
                placeholder="All"
                textAlign="text-left py-2 pl-2 pr-3"
              />
            </div> */}
                <div>
                  <button
                    className={classNames(
                      isDownloading ? "opacity-50 pointer-events-none" : "",
                      "py-2 px-4 bg-vorpblue rounded-md text-white text-12 font-semibold font-poppins mb-5"
                    )}
                    onClick={() => {
                      sampleCSV();
                      setIsDownloading(true);
                    }}
                  >
                    {isDownloading
                      ? "downloading..."
                      : "download sample file format"}
                  </button>
                </div>
                <div className="mb-4">
                  <p className="base-label  mb-[10px]">
                    Choose a file from your computer:
                  </p>
                  <div className="flex items-center text-black2 text-12 border-[#C2C2C2]">
                    {/* <form method="post" enctype="multipart/form-data"> */}
                    <input
                      ref={fileRef}
                      type="file"
                      className="rounded hidden"
                      accept=".xlsx, .xls, .csv" //.doc,.docx
                      onChange={displayFilename}
                      // onChange={handleFileUpload}
                    />
                    {/* </form> */}
                    <button
                      type="button"
                      onClick={() => fileRef.current?.click()}
                      className="p-2 border-[#C2C2C2] bg-[#EFEFEF] text-12 leading-[18px] text-graydark rounded"
                    >
                      Choose File
                    </button>
                    <p className="text-12 leading-[18px] text-black2 pl-3">
                      {(fileName && truncateFilename(fileName, 20)) ||
                        "No file Chosen"}
                    </p>
                  </div>
                  {/* <p
                    className={`mt-[10px] text-12 leading-[18px] ${fileError ? "text-redPink" : "text-[#979797]"
                      }`}
                  >
                    Maximum size: 100 MB
                  </p> */}
                </div>
                {
                  // !isChecked ? (
                  !isProject ? (
                    <div className="mb-[30px]">
                      <p className="base-label mb-[10px]">
                        Update existing data
                      </p>
                      <div className="flex items-start">
                        <label
                          htmlFor="update-existing"
                          className="relative max-h-[24px] flex items-start hover:cursor-pointer"
                        >
                          <input
                            type="checkbox"
                            id="update-existing"
                            className=" checked:bg-vorpblue w-3 h-3 sm:w-4 sm:h-4 p-3 border-none outline-none appearance-none bg-offwhite rounded hover:cursor-pointer"
                            checked={formData.isUpdateExisting}
                            onChange={() =>
                              setFormData((prev) => ({
                                ...prev,
                                isUpdateExisting: !prev.isUpdateExisting,
                              }))
                            }
                          />
                          {formData.isUpdateExisting && (
                            <SelectedIcon className="absolute top-2 left-1.5 right-0" />
                          )}
                        </label>
                        <p className="text-12 sm:text-14 leading-5 text-black2 ml-3">
                          Existing data that matched will be updated. Data that
                          do not exist will be skipped.
                        </p>
                      </div>
                    </div>
                  ) : null
                }
                <div className="flex items-center">
                  <button
                    type="button"
                    onClick={importData}
                    // onClick={handleFileSubmit}
                    className={classNames(
                      isImporting && isOpen
                        ? "opacity-50 pointer-events-none"
                        : "",
                      "py-2 px-4 bg-vorpblue rounded-md text-white text-12 leading-[18px] font-semibold"
                    )}
                  >
                    {isImporting && isOpen ? "Importing" : "Import"}
                  </button>
                  <button
                    type="button"
                    onClick={() => setIsOpen(false)}
                    className={classNames(
                      "ml-5 text-12 leading-[18px] font-semibold text-[#B5B5C3]"
                    )} //isImporting && isOpen ? 'opacity-50 pointer-events-none' : '',
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </>
  );
};

export default ImportUsersModal;
