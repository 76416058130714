import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import persistState from './persistState';
import { User } from '../app/services/users';

export type AuthenticationInitialState = { token: string; user: User | null };

const initialState: AuthenticationInitialState = { token: '', user: null };

const slice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    setAuthenticationToken: (state, action: PayloadAction<string>) => ({
      ...state,
      token: action.payload,
    }),
  },
  // extraReducers: (builder) => {
  //   builder
  //     .addMatcher(authApi.endpoints.login.matchPending, (state, action) => {
  //       // console.log('pending', action, state);
  //     })
  //     .addMatcher(authApi.endpoints.login.matchFulfilled, (state, action) => {
  //       // console.log('fulfilled', action);
  //       state.user = action.payload;
  //     })
  //     .addMatcher(authApi.endpoints.login.matchRejected, (state, action) => {
  //       // console.log('rejected', action);
  //     })
  //     .addMatcher(authApi.endpoints.logout.matchPending, (state, action) => {
  //       // console.log('pending', action, state);
  //     })
  //     .addMatcher(authApi.endpoints.logout.matchFulfilled, (state, action) => {
  //       console.log('fulfilled', action);
  //       state.user = null;
  //     })
  //     .addMatcher(authApi.endpoints.logout.matchRejected, (state, action) => {
  //       console.log('rejected', action);
  //     });
  // },
});

export const { setAuthenticationToken } = slice.actions;

export default persistState('authentication', slice.reducer);
