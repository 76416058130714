import { FC } from "react";
import BasicLayout from "../layouts/BasicLayout";
import { Outlet } from "react-router-dom";
import AnnouncementLists from "./views/AnnouncementLists";

interface IAnnouncementsRoute {
  loginData: any,
  permissions: any;
}

export const announcementRoutes = [{ id: 1, path: "", component: <AnnouncementLists /> }];

const AnnouncementsRoute: FC<IAnnouncementsRoute> = ({ loginData, permissions }) => {
  const data = loginData;

  return (
    <BasicLayout userData={data} permissions={permissions}>
      <div className="pt-0 pb-[58px]">
        <Outlet />
      </div>
    </BasicLayout>
  );
};

export default AnnouncementsRoute;
