import { FC, useCallback, useEffect, useState, useRef } from "react";
import Button from "../../components/common/Button";
import {
  AddIcon,
  CustomizeLayoutIcon,
  ExportIcon,
  ImportIcon,
  SearchIcon,
} from "../../components/common/Icons";
import {
  NumberDropdownList,
  StringDropdownList,
} from "../../components/common/DropdownList";
import {
  // paymentStages,
  // paymentStatus,
  paymentTerms,
  projectComplete,

  regions,
  paymentTermsData,
  baseObj,
  // dummyProjectData,
  // addNewProject,
  dummyCrmList
} from "../../utils/constants";

import ImportUsersModal from "../../components/BackendUsers/ImportUsersModal";
import ExportUsersModal from "../../components/BackendUsers/ExportUsersTable";
import { ColumnFiltersState, PaginationState } from "@tanstack/react-table";
import DebounceSearchInput from "../../components/Tables/DebounceSearchInput";
// import Styles from "./ProjectList.module.scss";
import Styles from "../../projects/views/ProjectList.module.scss"
// import ProjectsTable from "../../components/Projects/ProjectsTable";
import { EmployeeData } from "../../components/EmployeeTable/EmployeeTable";
import { templateList } from "../../active-time-report/views/ActiveTimeReport";
import { StringDropdownListWithDeleteBtn } from "../../components/common/StringDropdownListWithDeleteBtn";
import {
  // Project, ProjectType,
  useDeleteProjMutation, useDeleteProjectMutation, useGetProjectDataQuery, useGetProjectTypesQuery,
  useGetProjectStatusQuery, useGetPaymentStatusQuery, useCreateImportProjectMutation,
  useGetRegionListQuery,
  // useGetCountryListQuery
} from "../../app/services/project";
// import { DataPrepareSettings } from "devextreme-react/range-selector";
import { TagsData, useCreateTagMutation, useGetTagsDataQuery } from "../../app/services/annoucement-sheet";
// import { useGetCurrencyListQuery } from "../../app/services/employee";
import { useGetByModuleTemplateQuery } from "../../app/services/template";
import { getAuthUser } from "../../app/services/dashboard";
import axios from "../../axios";
import { endpointUrl } from "../../app/services/api";
// import classNames from "classnames";
import { checkedIsNew } from "../../app/services/employee";
// import SuccessPopup from "../../components/SuccessPopup/SuccessPopup";
import ErrorPopup from "../../components/ErrorPopup/ErrorPopup";
// import { errorMonitor } from "events";
// import CustomCommonError from "../../components/common/CustomCommonError";
import { useNavigate } from 'react-router-dom';
import { FetchBaseError } from "../../components/common/FetchBaseQueryError";
import CustomDropdownListForFilter from "../../components/EmployeeTable/CustomDropdownListForFilter";
import CustomScrollbar from "../../components/CustomScrollbar/CustomScrollbar";
// import { CSSTransition } from 'react-transition-group';
import { useSpring, animated } from 'react-spring'
// import TableLoadingSkeleton from "../../components/TableSkeleton/TableLoadingSkeleton";
// import styles1 from "../../components/Projects/Projects.module.scss";
// import stylesE from "../../components/EmployeeTable/EmployeeTable.module.scss";
// import Table from '../../components/Tables/Tables.module.scss'
import CommonTableSkeleton from "../../components/TableSkeleton/CommonTableSkeleton";
import { ToastContainer, toast } from "react-toastify";
import CrmTable from "../../components/CRM/CrmTable";


interface ICRMList { }

export interface IFilterStates {
  industry?: string;
  primary?: string;
  leadSource?: string;
  leadType?: string;
  leadStatus?: string;
  serviceRequired?: string;
  followUpStatus?: string;
  region?: string;
  projectType?: string;
  projectStatus?: string;
  paymentTerm?: string;
  paymentStage?: number;
  paymentStatus?: string;
  projectCompleted?: boolean | string;
  keyDate?: string;
  department?: string;
  assignTo?: string;
}

export const addNewCrm = () => {
  return {
    uniqeID: 0,
    "clientId": 0,
    "firstName": "",
    "lastName": "",
    "email": "",
    "address": "",
    "contactDID": "",
    "websiteUrl": "",
    "companyName": "",
    "industry": "",
    "primaryOrNot": "",
    "leadSource": "",
    "leadType": "",
    "leadStatus": "",
    "serviceRequired": "",
    "followUpStatus": "",
    key: Math.random() * 100,
    isNew: true,
  }
};

const CRMList: FC<ICRMList> = () => {
  const numberOfRows = 20;

  const navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(20);
  const [payment_status, setPayment_status] = useState<number>(0);
  const [term, setTerm] = useState<number>(0);
  const [region, setRegion] = useState<number>(0);
  const [type, setType] = useState<number>(0);
  const [completed, setCompleted] = useState<any>('all');
  const [status, setStatus] = useState<number>(0);
  const [globalSearch, setGlobalSearch] = useState<string>("");

  const defaultFields = [
    'projectCode',
    'projectName',
    'companyName',
    'email',
    'contactPerson',
    'financialContactPerson',
    'date',
    'region',
    'projectType',
    'duration',
    'projectStatus',
    'projectScope',
    'quotationNumber',
    'confirmOn',
    'currency',
    'projectAmount',
    'paymentTerm',
    'paymentStage',
    'paymentStatus',
    'endDate',
    'projectCompleted',
    'remarks',
    'checklist',
    'keyDate',
    'projectTag',
    // 'lasted',
    // 'actions',
  ]

  const { data: projectInfoData, error, isLoading, isFetching, isSuccess } = useGetProjectDataQuery({
    limit: limit,
    page: currentPage,
    payment_status: payment_status,
    term: term,
    region: region,
    type: type,
    completed: completed,
    status: status,
    search: globalSearch,
    deleted_at:'all'
  });

  const [deleteProj] = useDeleteProjMutation();
  const { data: RegionListData } = useGetRegionListQuery();
  const { data: ProjectTypeData } = useGetProjectTypesQuery();
  const { data: ProjectStatusData } = useGetProjectStatusQuery();
  const { data: PaymentStatusData } = useGetPaymentStatusQuery();
  const { data: ProjectTagsData } = useGetTagsDataQuery();
  let [localModuleId, setLocalModuleId] = useState<number>(0);
  const { data: templateData } = useGetByModuleTemplateQuery(localModuleId);

  const [moduleList, setModuleList] = useState<any>([]);
  const [filterStates, setFilterStates] = useState<IFilterStates>({
    industry: "All",
    primary: "All",
    leadSource: "All",
    leadType: "All",
    leadStatus: "All",
    serviceRequired: "All",
    followUpStatus: "All",
    region: "All",
    projectType: "All",
    projectStatus: "All",
    paymentTerm: "All",
    paymentStage: 0,
    paymentStatus: "All",
    projectCompleted: "All",
    keyDate: "All",
  });

  const [viewCols, setCols] = useState<any>([]);
  const [getPlaceHolder, setGetPlaceHolder] = useState<any>();
  const [isCreated, setIsCreated] = useState<boolean>(false);
  const [isEditing, setIsEdit] = useState<boolean>(false);
  const [isImportModalOpen, setIsImportModalOpen] = useState<boolean>(false);
  const [isExportModalOpen, setIsExportModalOpen] = useState<boolean>(false);
  const [isImporting, setIsImporting] = useState<boolean>(false);
  const [isExporting, setIsExporting] = useState<boolean>(false);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const [isLayoutControlOpen, setIsLayoutControlOpen] =
    useState<boolean>(false);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  });

  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [regionList, setRegionList] = useState<EmployeeData[]>([]);
  const [regionFilterItems, setRegionFilterItems] = useState<string[]>(regions);
  const [projectTypesList, setProjectTypesList] = useState<EmployeeData[]>([]);
  const [paymentStatusList, setPaymentStatusList] = useState<EmployeeData[]>();
  const [projectStatusList, setProjectStatusList] = useState<EmployeeData[]>([]);
  const [projectTagList, setProjectTagList] = useState<TagsData[]>([]);
  const [paymentTermList, setPaymentTermList] = useState<EmployeeData[]>(paymentTermsData);
  const [itemCount, setItemCount] = useState<number>(0);
  const [successPopup, setSuccessPopup] = useState<boolean>(false);
  const [errorPopup, setErrorPopup] = useState<boolean>(false);
  const [message, setMessage] = useState<any>([]);
  const [currentView, setCurrentView] = useState<any>(0);
  const [isTemplate, setIsTemplate] = useState<boolean>(false)
  const [currentViewData, setCurrentViewData] = useState<number>(0);
  // const [data, setData] = useState<baseObj[]>([]);
  const [data, setData] = useState<baseObj[]>(dummyCrmList);
  // console.log("data", data)


  const [dataAll, setAllData] = useState<baseObj[]>([]);
  const [hiddenFields, setHiddenFields] = useState<string[]>([]);
  const [templatePlaceholder, setTemplatePlaceholder] = useState<string>("");
  const [tempList, setTempList] = useState<any>(templateList);
  const [importPj, setImportProject] = useState<boolean>(false);

  const updateFilterState = useCallback(
    (value: string | number, key: string, id?: number) => {
      if (id && key === "keyDate") {
      }
      setFilterStates((prev) => ({ ...prev, [key]: value }));
    },
    [setFilterStates]
  );

  const [checkExport, setCheckExport] = useState<boolean>(false);
  const [checkImport, setCheckImport] = useState<boolean>(false);
  const [checkManagement, setCheckManagement] = useState<boolean>(false);
  const [addNew, setAddNew] = useState<boolean>(false);

  var getUser = getAuthUser();
  const getAuthPermis: string[] = [];

  if (getUser?.permissions) {
    const getPer = getUser?.permissions?.forEach((per: any) => {
      getAuthPermis.push(per?.name)
    })
  }

  useEffect(() => {
    const filterExport = getAuthPermis.filter((f) => f === 'Export_Project')
    const filterImport = getAuthPermis.filter((f) => f === 'Import_Project')
    const filterManagement = getAuthPermis.filter((f) => f === 'Manage_Project')
    const filterCreate = getAuthPermis.filter((f) => f === 'Create_New_Project')
    if (filterManagement.length > 0) {
      setCheckManagement(true)
    }
    if (filterImport.length > 0) {
      setCheckImport(true)
    }
    if (filterExport.length > 0) {
      setCheckExport(true)
    }
    if (filterCreate.length > 0) {
      setAddNew(true)
    }
  }, [getUser])

  useEffect(() => {
    if (projectInfoData) {
      if (isFetching) {
        // setData([]);
      }
      else {
        // setData(projectInfoData?.data);
      }
      // setAllData(projectInfoData?.data)
      setLocalModuleId(projectInfoData?.module_id);
      setItemCount(projectInfoData?.total);
    }
  }, [projectInfoData, hiddenFields, currentView, isFetching])

  useEffect(() => {
    if (RegionListData) {
      setRegionList([{ id: 0, name: "All", label: "All" }, ...RegionListData?.region])
    }

    if (ProjectTypeData) {
      setProjectTypesList([{ id: 0, name: "All", label: "All" }, ...ProjectTypeData])
    }

    if (ProjectStatusData) {
      setProjectStatusList([{ id: 0, name: "All", label: "All" }, ...ProjectStatusData])
    }

    if (PaymentStatusData) {
      setPaymentStatusList([{ id: 0, name: "All", label: "All" }, ...PaymentStatusData])
    }

    if (ProjectTagsData) {
      setProjectTagList(ProjectTagsData?.tagsData?.filter((x: any) => x.flag == "pj" || x.flag == null))
    }

  }, [
    // CountryListData,
    ProjectTypeData, PaymentStatusData, ProjectStatusData,
    ProjectTagsData, templateData, RegionListData
  ])

  useEffect(() => {
    if (templateData) {
      var updatedTemplate = templateData?.templates.map((x: any, i: any) => {
        return { ...x, index: i + 1 };
      });
      var defaultTemplate = {
        id: -1,
        index: 0,
        module: 0,
        name: "All"
      };
      setModuleList([defaultTemplate, ...updatedTemplate]);
    }

  }, [templateData]);

  useEffect(() => {
    if (templateList) {
      setTempList(templateList);
    }

    var viewData = templateData?.templates.find((x: any) => x.id.toString() == currentView);
    setHiddenFields(viewData ? [...viewData?.viewColumns] : []);

    document.querySelectorAll("th,td").forEach((x) => {
      if (x.id.includes("none")) {
        x.classList.add("hidden");
      }
      if (x.id.includes("action")) {
        x.classList.add("absolute");
        x.classList.add("right-0");
      }
      x.classList.remove("absolute");
      x.classList.add("left-0");
      x.classList.add("relative");
      var div = document.getElementById(x.id);
      if (div) {
        div.style.setProperty("left", "");
      }
    });
    setTemplatePlaceholder(currentView);
  }, [templateList, currentView])

  useEffect(() => {
    if (viewCols?.view_columns?.length > 0) {
      // setHiddenFields(viewCols?.view_columns);
    }
  }, [viewCols?.view_columns])

  useEffect(() => {
    if (moduleList) {
      var lastesTemplate = moduleList[moduleList?.length - 1];
      // setCurrentViewData(lastesTemplate ? Number(lastesTemplate?.id) : 0);
    }
  }, [(isTemplate && moduleList)])

  const deleteTemplateData = (id: string) => {
    var filterTempList = templateList.filter((x) => x.id != parseInt(id));
    setTempList(filterTempList);
  };

  const changePage = (pageNo: number) => {
    var pageData = {
      limit: pagination.pageSize,
      currentPage: pagination.pageIndex + 1,
    };
    // getPermissions(pageData)
  };

  const downloadSampleFile = () => {
    axios({
      url: endpointUrl + "downloadProjectSample",
      method: "GET",
      responseType: "blob",
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "sampleProject.xlsx");
      document.body.appendChild(link);
      link.click();
      setIsDownloading(false);
    });
  };

  const sampleImportCSV = () => {
    downloadSampleFile();
  }

  const importProject = async (formSaveData: any) => {
    await axios(endpointUrl + "projecstImport", {
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: formSaveData,
    }).then((res) => {
      axios.get(`${endpointUrl}projects?limit=${limit}&page=${currentPage}`, {}).then((result) => {
        var pjUpdatedData: any = result?.data?.data;
        // console.log("res", pjUpdatedData)
        if (result.data.status) {
          // setData([...pjUpdatedData]);
          setImportProject(true)
          setSuccessPopup(true)
          setMessage(["Successfully import."]);
        }
        setAllData(pjUpdatedData);
        setIsImporting(true);
        setIsImportModalOpen(false);
        toast('Successfully Import.', {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          type: "success",
        });
      });
    }).catch((error) => {
      if (error) {
        setImportProject(false)
        setErrorPopup(true)
        setMessage(["Something went wrong!Trying again."])
      }
    })
  };

  const exportProjectData = (format: string) => {
    axios({
      url: endpointUrl + `projectsExport?limit=${limit}&page=${currentPage}&payment_status=${payment_status}&term=${term}&region=${region}&type=${type}&completed=${completed}&status=${status}&search=${globalSearch}&type=` + format + "",
      method: "GET",
      responseType: "blob",
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "projects." + (format === "excel" ? 'xlsx' : format));
      document.body.appendChild(link);
      link.click();
      setIsExporting(false);
      setIsExportModalOpen(false);
    });
  };
  var disabledData = checkedIsNew(data);

  useEffect(() => {
    setTimeout(() => {
      setSuccessPopup(false)
    }, 1000)
  }, [successPopup])

  useEffect(() => {
    setCurrentPage(1);
  }, [globalSearch, filterStates, limit])

  const [load, setLoad] = useState<boolean>(false)
  const [transmit, setTransmit] = useState<number>(1100)

  useEffect(() => {
    if (isFetching) {
      setLoad(true)
    }
  }, [isFetching])

  useEffect(() => {
    setTransmit(1000)
  }, [load])

  const props = useSpring({
    to: { opacity: 1 },
    from: { opacity: 0 },
    delay: transmit,
  })

  if (error) {
    if (FetchBaseError(error)) {
      if (error?.status === 400) {
        localStorage.removeItem('userData')
        navigate('/login')
      } else {
        setErrorPopup(true)
        setMessage(error?.data?.message ? [error?.data?.message] : ['You are something wrong. Pls login Again!'])
      }
    }
  }


  return (
    <>
      {
        isLoading ? (
          <CommonTableSkeleton rows={12} />
        ) : (
          <animated.div style={props}>
            <ImportUsersModal
              isOpen={isImportModalOpen}
              setIsOpen={setIsImportModalOpen}
              importEmployee={importProject}
              sampleCSV={sampleImportCSV}
              isImporting={isImporting}
              setIsImporting={setIsImporting}
              setIsDownloading={setIsDownloading}
              isDownloading={isDownloading}
            />
            <ExportUsersModal
              isOpen={isExportModalOpen}
              setIsOpen={setIsExportModalOpen}
              exportToCSV={exportProjectData}
              exportToExcel={exportProjectData}
              exportToPDF={exportProjectData}
              isExporting={isExporting}
              setIsExporting={setIsExporting}
            // exportToZip={exportProjectData}
            />

            <ToastContainer className={"text-12 font-poppins font-normal"} />
            <ErrorPopup
              descText="Error Message!"
              confirmBtnText="Yes, Close."
              show={errorPopup}
              setShow={setErrorPopup}
              message={message}
              setMessage={setMessage}
            />

            <div className="p-4 bg-white sm:mt-[25px] mt-0 font-poppins  tracking-[-0.03rem] w-full h-full rounded-xl pt-3 pb-8 sm:pt-3 sm:pb-9 px-4 ">
              <div className="flex justify-between flex-wrap items-center mb-1">
                <div className="flex items-center mr-2 xs:mr-4 sm:w-[260px] w-[225px] md:mb-1">
                  <DebounceSearchInput
                    setState={setGlobalSearch}
                    debounce={800}
                  >
                    {(setState) => (
                      <div className="font-normal flex border-1 border-[#C4C4C4] bg-white hover:bg-[#EFEFEF] rounded-[28px]">
                        <input
                          type="text"
                          placeholder="Search"
                          className="focus:outline-none py-1 px-4 text-12 xl:text-14 leading-5 bg-transparent rounded-[28px] w-full text-graydark"
                          onChange={(e) => setState(e.target.value)}
                        />
                        <SearchIcon className="mr-2" />
                      </div>
                    )}
                  </DebounceSearchInput>
                </div>
                <div className="flex justify-end items-center">
                  {
                    checkImport ? (
                      <Button
                        label={
                          <span className="flex items-center">
                            <ImportIcon className="mr-[5px]" />
                            Import
                          </span>
                        }
                        onClick={() => { setIsImportModalOpen(true); setIsImporting(false); }}
                        type="button"
                        variant="no-outline"
                        customClass="my-1 bg-transparent border-transparent hover:border-vorpblue hover:text-vorpblue transition-all duration-300 text-black2 xs:text-[12px] leading-[18px] font-medium py-1 px-[6px] sm:mr-[10px] mr-[5px]"
                        size="base"
                      />
                    ) : null
                  }
                  {
                    checkExport ? (
                      <Button
                        label={
                          <span className="flex items-center">
                            <ExportIcon className="mr-[5px]" />
                            Export
                          </span>
                        }
                        onClick={() => { setIsExportModalOpen(true); setIsExporting(false); }}
                        type="button"
                        variant="no-outline"
                        customClass="my-1 bg-vorpmyanmar border-vorpmyanmar hover:bg-vorpblue hover:text-white transition-all duration-300 text-vorpblue xs:text-[12px] leading-[18px] font-medium py-1 px-[6px] sm:mr-[10px] mr-[5px]"
                        size="base"
                      />
                    ) : null
                  }
                  {
                    addNew ? (
                      <Button
                        disabled={disabledData?.disabled}
                        label={
                          <span className="flex items-center">
                            + Add New
                          </span>
                        }
                        onClick={() => {
                          setData((prev) => [addNewCrm(), ...prev]);
                          setIsCreated(true)
                        }}
                        type="button"
                        variant="primary"
                        customClass={disabledData?.disabledClass + " py-[3px] my-1 px-2 focus:outline-none hover:bg-vorpmyanmar hover:border-vorpmyanmar hover:text-vorpblue 2xl:text-[14px] text-12 leading-5 font-medium text-white"}
                        size="base"
                      />
                    ) : null
                  }
                </div>
              </div>

              <div className="flex sm:flex-row flex-col justify-between items-center mb-1">
                <div className="flex w-full sm:mr-5 custom-scrollbar sm:mb-0 mb-1">
                  <CustomScrollbar isVertical={false} isHor={true}>
                    <div className="flex w-full items-center">
                      <div
                        className={`flex items-center w-auto mr-3 ${Styles.dropdownFillAvailable}`}
                      >
                        <p className="sm:w-auto w-1/2 text-left base-label xl:text-sm text-12 hidden">INDUSTRY</p>
                        <CustomDropdownListForFilter
                          isFilter={true}
                          state={filterStates.industry ?? ""}
                          setState={(value) => {
                            updateFilterState(value as string, "industry")
                            setRegion(Number(value))
                          }
                          }
                          dropdownItems={regionList.map((region) => ({
                            value: region.id,
                            label: region.name,
                          }))}

                          className="w-full max-w-full min-w-[100px] xl:min-w-[120px] 3xl:min-w-[120px] 7xl:min-w-[120px] 6xs:text-12 7xl:text-14 max-h-[38px] text-dropdownTextColor"
                          placeholder="Industry"
                          textAlign="text-left py-2 pl-2 pr-3"
                        />
                      </div>
                      <div
                        className={`flex items-center w-auto mr-3 ${Styles.dropdownFillAvailable}`}
                      >
                        <p className="sm:w-auto w-1/2 text-left base-label xl:text-sm text-12 hidden">PRIMARY</p>
                        <CustomDropdownListForFilter
                          isFilter={true}
                          state={filterStates.primary ?? ""}
                          setState={(value) => {
                            updateFilterState(value as string, "primary")
                            setType(Number(value))
                          }
                          }
                          dropdownItems={projectTypesList?.map((type: any) => ({
                            value: type.id,
                            label: type.name,
                          }))}
                          className="w-full max-w-full min-w-[100px] xl:min-w-[120px] 3xl:min-w-[120px] 7xl:min-w-[120px] 6xs:text-12 7xl:text-14 max-h-[38px] text-dropdownTextColor"
                          placeholder="Primary"
                          textAlign="text-left py-2 pl-2 pr-3"
                        />
                      </div>
                      <div
                        className={`flex items-center mr-3 w-auto  ${Styles.dropdownFillAvailable}`}
                      >
                        <p className="sm:w-auto w-1/2 text-left base-label xl:text-sm text-12 hidden">LEAD SOURCE</p>
                        <CustomDropdownListForFilter
                          isFilter={true}
                          state={filterStates.leadSource ?? ""}
                          setState={(value) => {
                            updateFilterState(value as string, "leadSource")
                            setStatus(Number(value))
                          }
                          }
                          dropdownItems={projectStatusList?.map((status: any) => ({
                            value: status.id,
                            label: status.name,
                          }))}
                          className="w-full max-w-full min-w-[120px] sm:max-w-[150px] 6xs:text-12 7xl:text-14 max-h-[38px] text-dropdownTextColor"
                          placeholder="Source"
                          textAlign="text-left py-2 pl-2 pr-3"
                        />
                      </div>
                      <div
                        className={`flex items-center mr-3 w-auto ${Styles.dropdownFillAvailable}`}
                      >
                        <p className="sm:w-auto w-1/2 text-left base-label xl:text-sm text-12 whitespace-nowrap hidden">
                          LEAD TYPE
                        </p>
                        <CustomDropdownListForFilter
                          isFilter={true}
                          state={filterStates.leadType ?? ""}
                          setState={(value) => {
                            updateFilterState(value as string, "leadType")
                            setTerm(Number(value))
                          }
                          }
                          dropdownItems={paymentTerms?.map((term: any) => ({
                            value: term.id,
                            label: term.name,
                          }))}
                          className="w-full max-w-full min-w-[120px] sm:max-w-[150px] 6xs:text-12 7xl:text-14 max-h-[38px] text-dropdownTextColor"
                          placeholder="Type"
                          textAlign="text-left py-2 pl-2 pr-3"
                        />
                      </div>
                      <div
                        className={`flex items-center mr-3 w-auto`}
                      >
                        <p className="sm:w-auto w-1/2 text-left base-label xl:text-sm text-12 whitespace-nowrap hidden">
                          LEAD STATUS
                        </p>
                        <CustomDropdownListForFilter
                          isFilter={true}
                          state={filterStates.leadStatus ?? ""}
                          setState={(value) => {
                            updateFilterState(value as string, "leadStatus")
                            setPayment_status(Number(value))
                          }
                          }
                          dropdownItems={paymentStatusList?.map((status: any) => ({
                            value: status.id,
                            label: status.name,
                          }))}
                          className="w-full max-w-full min-w-[120px] sm:max-w-[150px] 6xs:text-12 7xl:text-14 max-h-[38px] text-dropdownTextColor"
                          placeholder="Status"
                          textAlign="text-left py-2 pl-2 pr-3"
                        />
                      </div>
                      <div
                        className={`${Styles.customWFull} flex items-center mr-3 w-auto`}
                      >
                        <p className="sm:w-auto w-1/2 text-left base-label xl:text-sm text-12 whitespace-nowrap hidden">
                          SERVICE REQUIRED
                        </p>
                        <CustomDropdownListForFilter
                          state={filterStates.serviceRequired as string}
                          setState={(value) => {
                            updateFilterState(value as string, "serviceRequired")
                            if (value === 'Yes') {
                              setCompleted(true)
                            } else if (value === 'All') {
                              setCompleted('all')
                            } else {
                              setCompleted(false)
                            }
                          }
                          }
                          dropdownItems={projectComplete.map((bool) => ({
                            value: bool as string,
                            label: bool as string,
                          }))}
                          className="w-full max-w-full min-w-[120px] sm:max-w-[150px] 6xs:text-12 7xl:text-14 max-h-[38px] text-dropdownTextColor"
                          placeholder="Service"
                          textAlign="text-left py-2 pl-2 pr-3"
                        />
                      </div>
                      <div
                        className={`${Styles.customWFull} flex items-center mr-3 w-auto`}
                      >
                        <p className="sm:w-auto w-1/2 text-left base-label xl:text-sm text-12 whitespace-nowrap hidden">
                          FOLLOW UP STATUS
                        </p>
                        <CustomDropdownListForFilter
                          state={filterStates.followUpStatus as string}
                          setState={(value) => {
                            updateFilterState(value as string, "followUpStatus")
                            if (value === 'Yes') {
                              setCompleted(true)
                            } else if (value === 'All') {
                              setCompleted('all')
                            } else {
                              setCompleted(false)
                            }
                          }
                          }
                          dropdownItems={projectComplete.map((bool) => ({
                            value: bool as string,
                            label: bool as string,
                          }))}
                          className="w-full max-w-full min-w-[120px] sm:max-w-[150px] 6xs:text-12 7xl:text-14 max-h-[38px] text-dropdownTextColor"
                          placeholder="Follow Up"
                          textAlign="text-left py-2 pl-2 pr-3"
                        />
                      </div>
                    </div>
                  </CustomScrollbar>
                </div>
                <div className="flex items-center justify-end sm:w-auto w-full sm:mb-0 mb-1">
                  <div className="flex items-center justify-center text-center mr-3">
                    <p className="base-label xl:text-sm text-12">DISPLAY</p>
                    <NumberDropdownList
                      state={pagination.pageSize}
                      setState={(value) => {
                        setPagination((prev) => ({
                          ...prev,
                          pageSize: value as number,
                        }))
                        setLimit(value)
                      }
                      }
                      dropdownItems={new Array(5)
                        .fill(1)
                        .map((_, index: number) => {
                          const count = (index + 1) * 20;
                          return {
                            value: count,
                            label: String(count),
                            // label: (x.id==0?x.name:"View #" + x.index),
                            // value: x.id,
                          };
                        })}
                      className="max-w-[60px] md:max-w-[80px] lg:max-w-[52px] xl:max-w-[60px] 7xl:max-w-[72px]"
                      textAlign="text-left pl-2 pr-3"
                      placeholder="All"
                    />
                  </div>
                  <div className="">
                    <StringDropdownListWithDeleteBtn
                      setIsTemplate={setIsTemplate}
                      state={isTemplate ? currentViewData : currentView}
                      setState={setCurrentView}
                      deleteTemplate={deleteTemplateData}
                      dropdownItems={moduleList?.map((x: any, index: number) => {
                        return {
                          label: (x.id == -1 ? x.name : "View #" + x.index),
                          value: x.id,
                        };
                      })}
                      className="w-full min-w-[100px] lg:min-w-[120px] 4xl:max-w-[137px]  7xl:min-w-[137px] 6xs:text-12 7xl:text-14"
                      placeholder='View#1'
                      textAlign="text-left py-2 pl-2 pr-3"
                      setErrorPopup={setErrorPopup}
                      setSuccessPopup={setSuccessPopup}
                      setMessage={setMessage}
                      isProject={true}
                    />
                  </div>
                  <button
                    type="button"
                    onClick={() => setIsLayoutControlOpen(true)}
                    className="flex justify-center relative items-center rounded-lg bg-vorphongkongborder hover:bg-[#FFF0D2] w-[29px] h-[29px] ml-2 group transition-all duration-300 "
                  >
                    <CustomizeLayoutIcon />
                    <div className="absolute left-7 top-[-5px] border border-white w-4 h-4 rounded-full bg-vorphongkongborder flex justify-center items-center">
                      <AddIcon width={8} height={8} color="white" />
                    </div>
                  </button>
                </div>
              </div>
              <CrmTable
                pagination={pagination}
                data={data}
                setData={setData}
                setPagination={setPagination}
                isLayoutControlOpen={isLayoutControlOpen}
                setIsLayoutControlOpen={setIsLayoutControlOpen}
                globalSearch={globalSearch}
                setGlobalSearch={setGlobalSearch}
                columnFilters={columnFilters}
                setColumnFilters={setColumnFilters}
                regionList={regionList}
                setRegionFilterItems={setRegionFilterItems}
                setRegionList={setRegionList}
                projectTypesList={projectTypesList}
                setProjectTypesList={setProjectTypesList}
                paymentStatusList={paymentStatusList ? paymentStatusList : []}
                setPaymentStatusList={setPaymentStatusList}
                projectStatusList={projectStatusList ? projectStatusList : []}
                setProjectStatusList={setProjectStatusList}

                projectTagList={projectTagList}
                paymentTermList={paymentTermList}
                setPaymentTermList={setPaymentTermList}
                deleteProject={deleteProj}

                filterStates={filterStates}
                setFilterStates={setFilterStates}
                updateFilterState={updateFilterState}
                localModuleId={localModuleId}
                setHiddenFields={setHiddenFields}
                hiddenFields={hiddenFields}

                total={itemCount}
                changePage={changePage}
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}

                checkManagement={checkManagement}
                setCols={setCols}
                setIsTemplate={setIsTemplate}

                setGetPlaceHolder={setGetPlaceHolder}
                setIsCreated={setIsCreated}
                isCreated={isCreated}

                setIsEdit={setIsEdit}
                isEditing={isEditing}
              />
            </div >
          </animated.div>
        )
      }
    </>
  );
};
export default CRMList;
