import {
  ColumnDef,
  ColumnFiltersState,
  PaginationState,
  RowSelectionState,
  SortingState,
  createColumnHelper,
} from "@tanstack/react-table";
import React, {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import EditableCell from "../Tables/EditableCell";
import { baseObj } from "../../utils/constants";
// import {
//   renderDateFormatDMY,
//   renderDateFormatYMD,
// } from "../DashboardCalendarView/DashboardAddEventPopup";
// import DatePopup from "../EmployeeTable/DatePopup";
import classNames from "classnames";
import {
  CancelIcon,
  // CheckIcon,
  // CloseSmallIcon,
  // EditIcon,
  TableSaveIcon,
  TrashIcon,
  ViewIcon,
} from "../common/Icons";
import styles from "./ProjectCheckerTable.module.scss";
import LayoutAdjustableTable from "../Tables/LayoutAdjustableTable";
import { templateData } from "../../app/services/template";
import TablePaginator from "../Tables/TablePaginator";
import DeletePopup from "../DeletePopup/DeletePopup";
// import { toast } from "react-toastify";
// import { tagsColor } from "../Announcements/AnouncementsTable";
// import {
//   useApproveOTMutation,
//   useDeleteOTListMutation,
//   useDeleteOTMutation,
//   useRejectOTMutation,
// } from "../../app/services/otlist";
import { makeStyles } from "@mui/styles";
import ErrorPopup from "../ErrorPopup/ErrorPopup";
// import { getAuthUser } from "../../app/services/dashboard";
import { useDispatch, useSelector } from "react-redux";
// import { setProfileNotification } from "../../reducers/dashboard";
import {
  useDeleteClaimTypeMutation,
  useMultiDeleteClaimTypeMutation,
} from "../../app/services/claim-type";
import { useNavigate } from "react-router-dom";
// import CustomDropdownWithAddButton from "../EmployeeTable/CustomDropdownWithAddButton";
// import { EmployeeData } from "../EmployeeTable/EmployeeTable";
import { Modal, Tooltip } from "@mui/material";
import AssignPopup from "../Passwords/AssignPopup";
import { KeyValueLabel } from "../../app/services/password";
import CommonTableBottom from "../CommonTableBottom/CommonTableBottom";
import { assignedColor } from "../Documents/EmployeeDocumentsTable";
import ResuableToasty from "../Toasty/ResuableToast";


interface IOTTable {
  isLayoutControlOpen: boolean;
  setIsLayoutControlOpen: Dispatch<SetStateAction<boolean>>;
  pagination: PaginationState;
  setPagination: Dispatch<SetStateAction<PaginationState>>;
  globalSearch: string;
  setGlobalSearch: Dispatch<SetStateAction<string>>;
  columnFilters: ColumnFiltersState;
  setColumnFilters: Dispatch<SetStateAction<ColumnFiltersState>>;
  data: baseObj[];
  setData: Dispatch<SetStateAction<baseObj[]>>;
  hiddenFields: string[];
  templateList: templateData[];
  setHiddenFields: any;
  setCurrentView: any;
  total: number;
  changePage: any;
  setCurrentPage: any;
  currentPage: number;
  setIsEdit: Dispatch<SetStateAction<boolean>>;
  isEditing?: boolean;
  setIsTemplate?: Dispatch<SetStateAction<boolean>>;
  localModuleId: number;
  visArray: any;
  setCols?: any;
  setIsSuccess?: React.Dispatch<React.SetStateAction<boolean>>;
  isSuccess?: boolean;
  setSkip: React.Dispatch<React.SetStateAction<boolean>>;
  sorting: SortingState;
  setSorting: React.Dispatch<React.SetStateAction<SortingState>>;
  totalTime?: string;
}

const useStyles = makeStyles(() => ({
  tooltip: {
    backgroundColor: "transparent",
    margin: 0,
    marginRight: 8,
    padding: 0,
  },
}));

const tagsTasks = [
  {
    id: 1,
    name: "UIUX",
  },
  {
    id: 1,
    name: "Frontend",
  },
  {
    id: 1,
    name: "Backend",
  },
];

const ProjectCheckerTable: FC<IOTTable> = ({
  isLayoutControlOpen,
  setIsLayoutControlOpen,
  pagination,
  setPagination,
  globalSearch,
  setGlobalSearch,
  columnFilters,
  setColumnFilters,
  data,
  setData,
  hiddenFields,
  total,
  changePage,
  setCurrentPage,
  currentPage,
  setIsEdit,
  isEditing,
  setIsTemplate,
  localModuleId,
  visArray,
  setCols,
  setSkip,
  setSorting,
  sorting,
  totalTime,
}) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const columnHelper = useMemo(() => createColumnHelper<any>(), []);
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  const [deleteID, setDeleteID] = useState<number | 0>();
  const [deleteRowID, setDeleteRowID] = useState<number | 0>();
  const [openDeletePopup, setOpenDeleteopup] = useState<boolean>(false);
  const [clickLoading, setClickLoading] = useState<boolean>(false);
  const [isShow, setIsShow] = useState<boolean>(false);
  const [cellData, setCellData] = useState<any>();
  const [editedTagData, setEditedTagData] = useState<any>();
  const [showTagPopup, setShowTagPopup] = useState<boolean>(false);
  const [filterTagData, setFilterTagData] = useState<KeyValueLabel[]>(
    tagsTasks ?? []
  );
  const [uploading, setUploading] = useState<boolean>(false);
  const [itemCount, setItemCount] = useState<number>(total);
  const selectRowsCount = Object.keys(rowSelection).length;
  const [errorPopup, setErrorPopup] = useState<boolean>(false);
  const [message, setMessage] = useState<any>([]);
  const [deleteClaimType] = useDeleteClaimTypeMutation();
  const [deleteClaimListByIDs] = useMultiDeleteClaimTypeMutation();
  const [customClass, setCustomClass] = useState<string>("");
  // const dispatch = useDispatch();
  const stateData: any = useSelector(
    (state: any) => state?.dashboard?.notificationDetail
  );
  // const [notifications, setNotifications] = useState<any>(
  //   stateData
  // );
  const [timeSpentPos, setTimeSpentPos] = useState<number>();
  const [totalPos, setTotalPos] = useState<number>();
  const [bottom, setBottom] = useState<number>();
  const createTagData = (label: string) => {
    // var data = {
    //   name: label,
    //   flag: "pwd",
    // };
    // return createTag(data);
  };
  // const deleteOTData = useCallback(
  //   (id?: any, uniqueID?: number) => {
  //     setDeleteID(id);
  //     setDeleteRowID(uniqueID);
  //     setOpenDeleteopup(true);
  //   },
  //   [setData, rowSelection]
  // );
  const deleteClaimItem = useCallback(
    (id?: number, dataId?: number) => {
      setClickLoading(true);
      const selectedRows = Object.keys(rowSelection);
      setData((prev: baseObj[]) => {
        if (dataId != undefined) {
          deleteClaimType(dataId)
            .unwrap()
            .then((payload: any) => {
              setClickLoading(false);
              setOpenDeleteopup(false);
              ResuableToasty({
                message: "Successfully Delete",
                position: "top-right",
                type: "success",
              });
              // toast("Successfully Delete.", {
              //   position: "top-right",
              //   autoClose: 2000,
              //   hideProgressBar: false,
              //   closeOnClick: true,
              //   pauseOnHover: true,
              //   draggable: true,
              //   progress: undefined,
              //   theme: "light",
              //   type: "success",
              // });
            })
            .catch((error: any) => {
              setErrorPopup(true);
              setOpenDeleteopup(false);
              setClickLoading(false);
              setMessage(
                error
                  ? [error?.data.message]
                  : ["Something went wrong!Trying again."]
              );
            });
        }
        return prev.filter((_, index) => {
          return id !== undefined
            ? id !== index
            : !selectedRows.includes(String(index));
        });
      });
      id === undefined && setRowSelection({});
    },
    [setData, rowSelection]
  );

  const deleteClaimMultiple = (ids: number[]) => {
    setClickLoading(true);
    deleteClaimListByIDs(ids)
      .unwrap()
      .then((payload: any) => {
        setClickLoading(false);
        setOpenDeleteopup(false);
        ResuableToasty({
          message: "Successfully Delete",
          position: "top-right",
          type: "success",
        });
        // toast("Successfully Delete.", {
        //   position: "top-right",
        //   autoClose: 2000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "light",
        //   type: "success",
        // });
      })
      .catch((error: any) => {
        setClickLoading(false);
        setErrorPopup(true);
        setOpenDeleteopup(false);
        setMessage(
          error ? [error?.data.message] : ["Something went wrong!Trying again."]
        );
      });
    setRowSelection({});
  };

  const confirmDeleteData = () => {
    var id = deleteID;
    var dataID = deleteRowID;
    const selectedRows = Object.keys(rowSelection);
    var selectionIds = data
      .filter((x, index: number) => {
        return selectedRows.find((s) => s == index.toString()) ? x.id : 0;
      })
      .map((x) => x.id);

    if (selectionIds.length > 0) {
      deleteClaimMultiple(selectionIds);
    } else {
      if (id) {
        deleteClaimItem(id, dataID);
        setDeleteID(-1);
        setDeleteRowID(-1);
      }
    }
  };

  // const addDesignationData = useCallback(() => {}, []);

  const canCeelRow = (id: number) => {
    if (isEditing) {
      setIsEdit(false);
      setData((prev: baseObj[]) => {
        return prev.map((data, index: number) => {
          if (data.id == id) {
            return { ...data, isNew: false };
          }
          return data;
        });
      });
    } else {
      const filterData = data?.filter((d: any) => d?.id !== id);
      setData(filterData);
    }
  };

  const divRef = useRef(null);
  const [width, setWidth] = useState<number>();
  const calculateTotalSpentTime = () => {
    var width = document
      .getElementById("sidebar")
      ?.getBoundingClientRect()?.width;
    var showSidebar = document
      .getElementById("sidebar")
      ?.classList[0].includes("hidden");
    var sidebarWidth = !showSidebar ? (width ? width : 0) : 0;
    const left = document
      .getElementById("headertime_spent")
      ?.getBoundingClientRect()?.left;
    const total = document
      .querySelector("table tr td:first-child")
      ?.getBoundingClientRect().left;
    setTotalPos(total ? total : 0);
    setTimeSpentPos(left ? left - sidebarWidth : 0);
    const bottom = document
      .getElementById("table-scroll")
      ?.getBoundingClientRect()?.bottom;
    setBottom(bottom ? bottom : 0);
    if (left == undefined) {
      setCustomClass("hidden");
    }
  };
  const handleScroll = () => {
    calculateTotalSpentTime();
  };
  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      setWidth(entries[0].contentRect.width);
    });

    if (divRef.current) {
      observer.observe(divRef.current);
    }

    return () => {
      divRef.current && observer.unobserve(divRef.current);
    };
  }, []);
  useEffect(() => {
    calculateTotalSpentTime();
  }, [width]);
  useEffect(() => {
    calculateTotalSpentTime();
  }, [data]);
  useEffect(() => {
    const handleResize = () => {
      calculateTotalSpentTime();
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    const handleScroll = () => {
      calculateTotalSpentTime();
    };
    document
      .getElementById("content-body")
      ?.addEventListener("scroll", handleScroll);
    return () => {
      document
        .getElementById("content-body")
        ?.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const columns = useMemo(
    () => [
      columnHelper.accessor("project_id", {
        header: "Project ID",
        id: "project_id",
        enableSorting: true,
        cell: ({ getValue, cell }) => {
          const id = getValue();
          return (
            <div className=" text-left bg-white text-black2 px-[10px] font-normal">
              <p className="h-full flex items-center">{id}</p>
            </div>
          );
        },
      }),
      columnHelper.accessor("project_name", {
        header: "Project Name",
        id: "project_name",
        enableSorting: true,
        cell: ({ getValue, cell }) => {
          const employeeID = getValue();
          return (
            <div className={`text-left font-normal`}>
              <EditableCell
                setData={setData}
                value={employeeID}
                type="text"
                cell={cell}
              />
            </div>
          );
        },
      }),
      columnHelper.accessor("stage", {
        header: "Stage",
        id: "stage",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const stages = getValue();
          return (
            <div className="text-left font-normal flex flex-wrap max-h-[22px] overflow-y-hidden overflow-x-auto items-center px-2">
              <Tooltip
                arrow
                className="customTooltip"
                classes={{ tooltip: classes.tooltip }}
                enterTouchDelay={1000}
                leaveTouchDelay={5000}
                placement="left"
                title={
                  <>
                    <div className="text-white text-[10px] hover:cursor-pointer leading-[15px] z-[2] rounded p-4 w-full bg-tooltipbg">
                      {stages?.map((x: any, index: number) => {
                        return (
                          <span key={index} className="w-full block">
                            {x.name}
                          </span>
                        );
                      })}
                    </div>
                  </>
                }
              >
                <div className="flex flex-wrap">
                  {stages?.length > 0 ? (
                    stages.map((stage: any, index: number) => {
                      return (
                        <span
                          className="bg-vorpgreen text-white px-[5px] rounded-[5px] mx-2 mb-1"
                          key={index}
                        >
                          {stage?.name}
                        </span>
                      );
                    })
                  ) : (
                    <span className="flex w-full justify-center h-full items-center">
                      ---
                    </span>
                  )}
                </div>
              </Tooltip>
            </div>
          );
        },
      }),
      columnHelper.accessor("team_leader_name", {
        header: "Project Lead",
        id: "team_leader_name",
        enableSorting: true,
        cell: ({ getValue, cell }) => {
          const team_leader_name = getValue();
          const team_leader_code = cell.row.original.team_leader_code;
          return (
            <div className=" min-w-[140px] text-left font-normal flex items-center px-2">
              <span>
                {team_leader_name} - {team_leader_code}
              </span>
            </div>
          );
        },
      }),
      columnHelper.accessor("project_manager_name", {
        header: "Project Manager",
        id: "project_manager_name",
        enableSorting: true,
        cell: ({ getValue, cell }) => {
          const project_manager_name = getValue();
          const project_manager_code = cell.row.original.project_manager_code;
          return (
            <div className=" min-w-[140px] text-left font-normal flex items-center px-2">
              <span>
                {project_manager_name} - {project_manager_code}
              </span>
            </div>
          );
        },
      }),
      columnHelper.accessor("employees", {
        header: "Employees",
        id: "employees",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const employees = getValue();
          return (
            <button
              type="button"
              className="min-w-[150px] flex justify-center items-center h-full ml-[10px]"
            >
              <Tooltip
                arrow
                className="customTooltip"
                classes={{ tooltip: classes.tooltip }}
                enterTouchDelay={1000}
                leaveTouchDelay={5000}
                placement="left"
                title={
                  <>
                    <div className="text-white bg-[#6b6a66] text-[10px] hover:cursor-pointer leading-[15px] z-[2] rounded p-4 w-full ">
                      {/* bg-tooltipbg */}
                      {employees?.map((x: any, index: number) => {
                        return (
                          <div className="flex" key={index}>
                            <span className="block text-12 w-[110px] pr-2">
                              {x.name}
                              {x.project_roles ? ":" : ""}{" "}
                            </span>
                            <span className="text-12 w-full">
                              {x.project_roles
                                ?.map((x: any) => x.name)
                                ?.join(", ")}
                            </span>
                          </div>
                        );
                      })}
                    </div>
                  </>
                }
              >
                <div className="flex ">
                  {employees?.map((x: any, index: number) => {
                    if (index < 3) {
                      var idx = index;
                      if (idx > 4) {
                        idx = 0;
                      }

                      return x.profileImage ? (
                        <img
                          key={index}
                          src={x.profileImage}
                          alt="profileImage"
                          className="w-[35px] h-[35px] rounded-full object-cover"
                        />
                      ) : (
                        <div
                          key={index}
                          className={classNames(
                            "bg-[" + assignedColor[idx] + "]",
                            "ml-[-10px] w-[35px] h-[35px] rounded-full text-14 flex justify-center items-center text-white"
                          )}
                        >
                          {x.name?.substring(0, 1)}
                        </div>
                      );
                    } else {
                      return null;
                    }
                  })}
                  {employees?.length > 3 ? (
                    <div className="ml-[-10px] w-[35px] h-[35px] rounded-full text-10 flex justify-center items-center bg-black2 text-white">
                      {employees?.length - 3}+
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </Tooltip>
            </button>
          );
        },
      }),
      columnHelper.accessor("time_spent", {
        header: "Time Spent",
        id: "time_spent",
        enableSorting: true,
        cell: ({ getValue, cell }) => {
          const time_spent = getValue();
          return (
            <div className={`text-left font-normal px-2`}>
              <span className="h-full flex items-center">{time_spent}</span>
            </div>
          );
        },
      }),
      columnHelper.accessor("start_date", {
        header: "Start Date",
        id: "start_date",
        enableSorting: true,
        cell: ({ getValue, cell }) => {
          const start_date = getValue();
          return (
            <div className={`text-left font-normal px-2`}>
              <span className="h-full flex items-center">
                {start_date ? start_date : "---"}
              </span>
            </div>
          );
        },
      }),
      columnHelper.accessor("launch_date", {
        header: "Launch Date",
        id: "launch_date",
        enableSorting: true,
        cell: ({ getValue, cell }) => {
          const launch_date = getValue();
          return (
            <div className={`text-left font-normal px-2`}>
              <span className="h-full flex items-center">
                {launch_date ? launch_date : "---"}
              </span>
            </div>
          );
        },
      }),
      columnHelper.display({
        header: "Action",
        id: "actions",
        enableSorting: false,
        // `${
        //   manageClaim || data?.find((x) => x.isNew == true)
        //     ? "actions"
        //     : "none-actions"
        // }`,
        cell: ({ cell }) => {
          var isHaveNew = data?.find((x) => x.isNew == true);
          var disabledClass = isHaveNew ? "opacity-50 pointer-events-none" : "";
          const isNew: boolean = cell.row.original?.isNew;
          const id: number = cell.row.original?.id;
          return (
            <div
              className={classNames(
                styles.actionedBtn,
                "px-2 flex justify-center items-center min-w-max"
              )}
            >
              {isNew ? (
                <>
                  <button
                    type="button"
                    onClick={() =>
                      // savePasswordData(Number(cell?.row?.original?.id))
                      {}
                    }
                    className={`w-8 h-8 flex justify-center items-center bg-vorpgreen rounded-md ${
                      uploading ? " pointer-events-none opacity-40" : ""
                    }`}
                  >
                    <TableSaveIcon />
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      canCeelRow(Number(cell?.row?.original?.id));
                    }}
                    className={classNames(
                      styles.customCloseIcon,
                      "w-8 h-8 ml-2 mr-2 flex justify-center items-center group rounded-md  bg-[#71717a] hover:bg-[#71717a] transition-all duration-300"
                    )}
                  >
                    <CancelIcon width={18} height={18} />
                  </button>
                </>
              ) : (
                <>
                  <button
                    onContextMenu={() => {
                      window.open(
                        `/progress-checker/${cell.row.original?.project_id}/task-detail-list`,
                        "_blank"
                      );
                    }}
                    onClick={() => {
                      //navigate(`/progress-checker/${cell.row.original?.project_id}/task-detail-list`)
                      window.open(
                        `/progress-checker/${cell.row.original?.project_id}/task-detail-list`,
                        "_blank"
                      );
                      //   localStorage.setItem(
                      //     "state",
                      //     cell.row.original.uniqeID
                      //   );
                      //   window.open("/employeeprofile/"+cell.row.original.uniqeID, "_blank", "noreferrer");
                    }}
                    className={classNames(
                      styles.customViewdBtn,
                      disabledClass,
                      "w-6 h-6 mr-2 flex justify-center items-center group rounded-md bg-darkpurple hover:bg-[#EAE0FF] transition-all duration-300"
                    )}
                  >
                    <ViewIcon />
                  </button>

                  {/* <button
                    disabled={isHaveNew ? true : false}
                    type="button"
                    onClick={() => {
                      setSkip(true);
                      // navigate(`/new-claim-type`, { state: cell.row.original });
                      if (setIsEdit) {
                        setIsEdit(true);
                      }
                    }}
                    className={classNames(
                      disabledClass,
                      " w-6 h-6 mr-2 flex justify-center items-center group rounded-md bg-vorpblue hover:bg-annhoverbg transition-all duration-300"
                    )}
                  >
                    <EditIcon />
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      deleteOTData(cell.row.id, cell.row.original.id);
                    }}
                    className={classNames(
                      disabledClass,
                      " w-6 h-6 flex justify-center items-center group rounded-md bg-redPink hover:bg-[#FFEAEF] transition-all duration-300"
                    )}
                  >
                    <TrashIcon />
                  </button> */}
                </>
              )}
            </div>
          );
        },
      }),
    ],
    [columnHelper, data, hiddenFields, columnFilters]
  );

  const visibleColumns: { [key: string]: boolean } = {};
  hiddenFields?.map((x) => {
    visibleColumns[x] = true;
  });

  document.querySelectorAll("th,td").forEach((x) => {
    if (x.id.includes("none")) {
      x.classList.add("hidden");
    }

    var div = document.getElementById(x.id);
  });

  if (true) {
    visibleColumns["actions"] = true;
  } else {
    visibleColumns["actions"] = false;
  }

  if (hiddenFields.length > 0) {
    columns.map((x) => {
      if (x.id == "actions" || x.id == "selection" || x.id == "lasted") {
        visibleColumns[x.id ? x.id : ""] = true;
      } else if (x.id == "uniqeID") {
        visibleColumns[x.id ? x.id : ""] = false;
      } else {
        var isHidden = hiddenFields.find((h) => h == x.id);
        if (isHidden) {
          visibleColumns[x.id ? x.id : ""] = true;
        } else {
          visibleColumns[x.id ? x.id : ""] = false;
        }
      }
    });
  }

  const [columnOrder, setColumnOrder] = useState<string[]>(
    columns.map((column: ColumnDef<any, any>) => column.id as string)
  );

  return (
    <div
      ref={divRef}
      id="progress-checker-acc"
      className={classNames(styles.otListTable, "relative overflow-hidden")}
    >
      <ErrorPopup
        descText="Error Message!"
        confirmBtnText="Yes, Close."
        show={errorPopup}
        setShow={setErrorPopup}
        message={message}
        setMessage={setMessage}
      />

      <LayoutAdjustableTable
        hideColumn={visibleColumns}
        columns={columns}
        data={data}
        sorting={sorting}
        setSortingState={setSorting}
        rowSelection={rowSelection}
        setRowSelection={setRowSelection}
        columnOrder={columnOrder}
        setColumnOrder={setColumnOrder}
        unDraggableFields={["actions", "selection", "id"]}
        theaderColor="text-graydark"
        isLayoutControlOpen={isLayoutControlOpen}
        setIsLayoutControlOpen={setIsLayoutControlOpen}
        label="ProgressChecker List"
        setItemCount={setItemCount}
        pagination={pagination}
        setPagination={setPagination}
        globalFilter={globalSearch}
        setGlobalFilter={setGlobalSearch}
        columnFilters={columnFilters}
        setColumnFilters={setColumnFilters}
        noPaddingCells={[]}
        localModuleId={localModuleId}
        visArray={visArray}
        setIsTemplate={setIsTemplate}
        setCols={setCols}
        handleScroll={handleScroll}
      />

      <CommonTableBottom
        data={[
          {
            name: totalTime ? "Total" : "",
            colSpan: 5,
            left: 0,
            bottom: bottom,
            classes: totalTime ? customClass : "hidden",
          },
          {
            name: totalTime ? totalTime : "",
            colSpan: 3,
            left: timeSpentPos,
            bottom: bottom,
            classes: totalTime ? customClass : "hidden",
          },
        ]}
      />

      {selectRowsCount > 0 && (
        <div className="flex items-center text-14 leading-5 font-semibold text-black2 mt-3 mb-3 lg:mt-3 lg:mb-3 ">
          <p>{selectRowsCount} selected.</p>
          <button
            type="button"
            onClick={() => setOpenDeleteopup(true)}
            className=" flex items-center ml-5 text-12 leading-[18px] font-semibold text-redPink"
          >
            <div className="w-6 h-6 bg-redPink flex items-center group justify-center rounded-md mr-[10px] hover:bg-[#FFEAEF]  transition-all duration-300 ">
              <TrashIcon width={10} height={12} />
            </div>
            Delete
          </button>
        </div>
      )}
      <div className="pt-2">
        <TablePaginator
          total={itemCount}
          pagination={pagination}
          setPagination={setPagination}
          itemCount={total}
          setCurrentPage={setCurrentPage}
          currentPageDefault={currentPage}
          changePage={changePage}
        />
      </div>
      <DeletePopup
        descText="Are you sure to delete?"
        confirmBtnText="Yes, delete."
        show={openDeletePopup}
        setShow={setOpenDeleteopup}
        confirmDeleteData={confirmDeleteData}
        loading={clickLoading}
      />

      <Modal
        open={isShow}
        style={{
          background: "rgb(0 0 0 / 0.5)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        onClose={() => setIsShow(false)}
        aria-labelledby="Password Add Modal"
        aria-describedby="Add New Password Form Pop up"
        disableEnforceFocus={true}
      >
        <div className=" max-w-[558px] w-full m-auto">
          <AssignPopup
            createTagData={createTagData}
            editRowData={editedTagData}
            showTagPopup={showTagPopup}
            setIsOpen={setIsShow}
            modalType="assign"
            options={filterTagData}
            cell={cellData}
            setTags={() => 1}
            data={data}
            setData={setData}
          />
        </div>
      </Modal>
    </div>
  );
};
export default ProjectCheckerTable;
