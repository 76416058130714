import { FC, memo, useCallback, useEffect, useState } from "react";
// import { People } from "./img";
import { twMerge } from "tailwind-merge";
import styles from "./PasswordsTable.module.scss";
import classNames from "classnames";
import CustomDropdownEdit from "../Tables/CustomDropdownEdit";
import { Cell } from "@tanstack/react-table";
import { IRole } from "../../backendUsers/views/UserList";
import { TRowData } from "../../app/services/project";
import CustomDropdownEditProject from "../Tables/CustomDropdownEditProject";
interface IUserSelectionTab {
  rowList: TRowData[];
  index: number;
  className?: string;
  initialState?: boolean;
  name: string;
  email?: string;
  icon?: string;
  setSelectedMember: any;
  id: number;
  isSelected: boolean;
  setIsClear: any;
  selectedMember: number[];
  initialValues?: any;
  isShowAddBtn?: boolean;
  isOverflow?: boolean;
  isShow?: boolean;
  isOpen?: boolean;
  setPlacement?: any;
  placement?: any;
  setData: any;
  cell?: Cell<any, any>;
  roles?: IRole[];
  setSelectedRole: any;
  isEditable?: boolean;
  isEditing?: boolean;
  selectedRole?: any;
  setLastData?: any;
  lastData?: any;
  setCheckDataLast?: any;
  setSelectedEmpID?:any;
}

const EmployeeSelectionTab: FC<IUserSelectionTab> = ({
  index,
  className = "",
  initialState,
  name,
  email,
  icon,
  setSelectedMember,
  id,
  isSelected,
  setIsClear,
  selectedMember,
  initialValues,
  isShowAddBtn = false,
  isOverflow = true,
  isShow = false,
  isOpen = false,
  setPlacement,
  placement,
  setData,
  cell,
  roles = [],
  setSelectedRole,
  isEditable = false,
  isEditing,
  rowList,
  selectedRole,
  setLastData,
  lastData,
  setCheckDataLast,
  setSelectedEmpID
}) => {
  const [isChosen, setIsChosen] = useState<boolean>(false);
  const [isClicked, setIsClicked] = useState<boolean>(false);
  const [isClickedExist, setIsClickedExist] = useState<boolean>(false);

  useEffect(() => {
    if (initialState !== undefined) {
      setIsChosen(initialState);
    }
  }, [initialState, isSelected, selectedMember]);

  const changeSelected = (isSelected: boolean) => {
    setIsChosen(isSelected);
    setSelectedMember(id, isSelected);
    setIsClear(false);
    setIsClicked(!isClicked);
    // setIsClickedExist(true);
  };

  //for custom dropdown

  const [value, setValue] = useState<number[]>([]);

  const addNewItem = useCallback((val: string) => {
    const label = val.trim();
    // if (label.length > 0) {
    //   setRoles((roles) => [
    //     ...roles,
    //     { value: roles[roles.length - 1].value + 1, label },
    //   ]);
    // }
  }, []); //setRoles

  useEffect(() => {
    if (initialValues) {
      setValue(initialValues);
    }
    //setSelectedEmpID(id)
  }, [value,initialValues]); //roles
  // useEffect(()=>{
  //   setSelectedEmpID(id)
  // },[value])
  
  return (
    <button
      // onClick={() => {
      //   changeSelected(!isChosen);
      // }}
      type="button"
      className={classNames(
        `flex rounded-[10px] items-center px-[10px] py-[5px] w-full `,
        className
      )}
    >
      {icon ? (
        <img
          src={icon}
          alt={`Person ${index}`}
          width={34}
          height={34}
          className="rounded-full overflow-hidden w-[34px] h-[34px]"
        />
      ) : (
        <div className={classNames(className?.includes('selectedMember')?'hidden':'',"relative bg-yellowBright rounded-full overflow-hidden min-w-[34px] min-h-[34px] w-[34px] h-[34px]")}>
          <p className="text-white font-bold text-13 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
            {name ? name.slice(0, 1) : ""}
          </p>
        </div>
      )}
      <div className="flex items-center justify-between ml-4 text-[#5E6278] w-[370px] ">
        <div
          className="flex justify-start items-start w-[220px] "
          onClick={() => {
            changeSelected(!isChosen);
            setSelectedEmpID(id)
          }}
        >
          <p className="text-14 leading-5 font-semibold">{name}</p>
          <p className="text-15 leading-5">{email}</p>
        </div>
        {isClicked || className?.length > 5 ?  (
          <div className={classNames(className?.includes('selectedMember')?'':'hidden',"flex w-[140px] ")}>
            <CustomDropdownEditProject
              isChosen={isChosen}
              empId={id}
              isProject={true}
              value={value}
              setValue={(val:any)=>{
                setValue(val);
                setSelectedEmpID(id)
              }}
              options={rowList ? rowList : []}
              title="Role"
              addNewItem={addNewItem}
              isOverflow={isOverflow}
              isShowAddBtn={isShowAddBtn}
              isShow={isShow}
              isopen={isOpen}
              placement={placement}
              setPlacement={setPlacement}
              setData={setData}
              cell={cell}
              setSelectedRole={setSelectedRole}
              selectedRole={selectedRole}
              isEditable={isEditable}
              isEditing={isEditing}
              setLastData={setLastData}
              lastData={lastData}
              setCheckDataLast={setCheckDataLast}
              setSelectedEmpID={setSelectedEmpID}
            />
          </div>
        ) : null}
      </div>
    </button>
  );
};

export default memo(EmployeeSelectionTab);
