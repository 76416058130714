import { ReactElement } from "react";
import BasicLayout from "../layouts/BasicLayout";
import { Outlet } from "react-router-dom";
import TaskDetailList from "./view/TaskDetailList";

const TaskDetailListRoute = (props: any): ReactElement => {
  const data = props.loginData;
  const permissions = props.permissions;

  return (
    <BasicLayout userData={data} permissions={permissions}>
      <TaskDetailList />
      <Outlet />
    </BasicLayout>
  );
};

export default TaskDetailListRoute;
