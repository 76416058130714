// import { Modal } from "@material-ui/core";
import { CircularProgress, Modal } from "@mui/material"

import classNames from "classnames";
import React, {
  createRef,
  Dispatch,
  FC,
  memo,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { CloseIcon, SearchIcon } from "../common/Icons";
import styles from "./DeletePopup.module.scss";
interface ICreateData {
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  confirmDeleteData: any;
  descText?: string;
  confirmBtnText?: string;
  setIsProj?: Dispatch<SetStateAction<boolean>>;
  loading?: boolean;
  loadingText?: string;
  confirmBtnColor?: string;
  isPayment?: boolean;
  confirmBtnTextColor?: string;
}

const DeletePopup: FC<ICreateData> =memo( ({ show, setShow, confirmDeleteData, descText, confirmBtnText, setIsProj, loading, loadingText, confirmBtnColor, isPayment }) => {
  const handleConfirm = (event: any) => {
    event.preventDefault();
    // setShow(false);
    confirmDeleteData();
  }

  return (
    <div>
      <Modal open={show} onClose={() => setShow(false)} className="project_popup">
        <div>
          <div className={classNames("z-10 fixed top-0 left-0 w-full h-full")}>
            <div
              className={classNames(
                "relative z-10 top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 md:max-w-[320px] max-w-[300px] bg-[#fff] rounded-xl"
              )}
            >
              <div className="relative text-center px-12 pt-[35px] rounded-tl-xl rounded-tr-xl">
                <p className="text-center font-poppins font-medium text-sm text-[#3F4254]">
                  {descText ? descText : "Are you sure to Approve?"}
                </p>
                <span
                  onClick={() => setShow(false)}
                  className={classNames(styles.popupClose, "text-[#B5B5C3] text-20 cursor-pointer absolute top-[15px] right-[15px]")}
                >
                  <CloseIcon />
                </span>
              </div>
              <div className="pt-[30px]">
                <div className="flex justify-start">
                  <button onClick={(event) => handleConfirm(event)}
                    className={classNames(
                      loading == true ? 'opacity-50 pointer-events-none' : '',
                      styles.customConfirmBtn,
                      confirmBtnColor ? confirmBtnColor : 'bg-redPink hover:text-redPink hover:bg-redPinkLight',
                      "flex justify-center w-1/2 px-5 py-[10px] rounded-bl-lg font-poppins font-medium text-center lg:text-sm text-xs text-[#fff] "
                      //bg-redPink hover:bg-redPinkLight
                    )}
                  >
                    {loading == true ? <div className="flex items-center justify-center w-full">
                      <CircularProgress sx={{ color: 'white' }} className="w-5 h-5" style={{ width: '20px', height: '20px' }} /><span className="ml-2">{loadingText}</span>
                    </div> : confirmBtnText ? confirmBtnText : "Yes, delete."}

                  </button>
                  <button
                    onClick={() => {
                      setShow(false);
                      if (setIsProj) {
                        setIsProj(false);
                      }
                    }}
                    className={classNames(
                      styles.cancelBtn,
                      "w-1/2 px-10 py-[10px] rounded-br-lg font-poppins font-medium text-center lg:text-sm text-xs text-[#979797] bg-grayLight hover:bg-anncolor"
                    )}
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
});
export default DeletePopup;
