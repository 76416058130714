import { Dispatch, FC, SetStateAction, forwardRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CalendarIcon } from "./Icons";
import { weekday } from "../../utils/constants";
import { twMerge } from "tailwind-merge";
import {
  formatDate,
  formatDateData
} from "../../utils/helpers";
import classNames from "classnames";

interface IDatePicker {
  placeholder: string;
  isFilter?: boolean;
  date?: Date;
  setDate: Dispatch<SetStateAction<Date | undefined>> | (() => void);
  className?: string;
  isFilterSection?: boolean;
  customPadding?: string;
  iconColor?: string;
  customComponentName?: string;
  format?: string;
  minWClass?: string;
  minDate?: Date;
  isMonthView?: boolean;
  borderColor?: string;
  fontSize?: string;
  showBorder?: boolean;
  showIcon?: boolean;
}

interface ICustomDateInputUi {
  value?: string;
  onClick?: () => void;
  label: string;
  customPadding?: string;
  iconColor?: string;
  className?: string;
  format?: string;
  isFilter?: boolean;
  borderColor?: string;
  fontSize?: string;
  showBorder?: boolean;
  showIcon?: boolean;
}

const CustomDatePicker: FC<IDatePicker> = ({
  placeholder,
  date,
  setDate,
  className = "",
  isFilterSection = true,
  customPadding = "",
  iconColor,
  customComponentName = "",
  format = "",
  minWClass = "",
  minDate,
  isFilter,
  isMonthView,
  borderColor,
  fontSize,
  showBorder,
  showIcon
}) => {

  return (
    <DatePicker
      className={classNames(minWClass ? minWClass : 'min-w-[100px]', "w-fit ")}
      selected={date}
      onChange={(prev: Date) => setDate(prev)}
      {...(customComponentName
        ? {}
        : { dateFormat: isFilterSection ? "dd-MM-yyyy" : isMonthView == true ? "MMM y" : "d MMM yyyy" })}
      showMonthDropdown
      showYearDropdown
      minDate={minDate}

      customInput={(() => {
        switch (customComponentName) {
          case "clean":
            return (
              <CleanStylesUi
                label={placeholder}
                className={className}
                format={format}
                borderColor={borderColor}
              />
            );
          default:
            return isFilterSection ? (
              <FilterDateInputUi
                label={placeholder}
                customPadding={customPadding}
                isFilter={isFilter}
                borderColor={borderColor}
                fontSize={fontSize}
              />
            ) : (
              <NormalDateInputUi label={placeholder} iconColor={iconColor} borderColor={borderColor} fontSize={fontSize} format={format} showBorder={showBorder} showIcon={showIcon} />
            );
        }
      })()}
    />
  );
};

const FilterDateInputUi = forwardRef(
  (
    { value, onClick, label, isFilter, customPadding = "" }: ICustomDateInputUi,
    ref: any
  ) => (
    <button
      className={classNames(isFilter == true ? 'rounded-[28px] w-auto sm:min-w-[190px]' : 'rounded-[5px] lg:w-[170px] htzxs:w-[150px] min-w-[150px]', "border border-[#CCDAE4] flex justify-between items-center  text-[13px] sm:text-14 leading-5 text-graydark")}
      onClick={onClick}
      ref={ref}
    >
      <span
        className={twMerge(
          "w-full h-full px-[10px] py-2 text-left text-black2 xl:text-sm text-12",
          customPadding
        )}
      >
        {isFilter == true ? (value ? label + ':' + value : label) : value || label}
      </span>
      <div
        className={twMerge(
          "border-l border-[#CCDAE4] px-[10px] py-[6px]",
          customPadding
        )}
      >
        <CalendarIcon width={22} height={22} />
      </div>
    </button>
  )
);

const NormalDateInputUi = forwardRef(
  ({ value, onClick, label, iconColor, borderColor, fontSize, format, showBorder, showIcon }: ICustomDateInputUi, ref: any) => (
    <button
      className={twMerge(showBorder == false ? 'border-0' : 'border', borderColor ? borderColor : 'border-[#CCDAE4]',
        fontSize ? fontSize : 'text-[13px] sm:text-14',
        "rounded-[5px] flex justify-between items-center w-full leading-5 text-graydark")}
      onClick={onClick}
      ref={ref}
    >
      <span className={twMerge("w-full h-full px-[14px] py-3 text-left")}>
        {value ? format ? formatDate(new Date(value), format || "") : `${weekday[new Date(value).getDay()]}, ${value}` : label}
      </span>
      <div className={twMerge(showIcon == false ? 'hidden' : '', "px-[14px] py-3")}>
        <CalendarIcon width={18} height={20} color={iconColor} />
      </div>
    </button>
  )
);

const CleanStylesUi = forwardRef(
  (
    { value, onClick, label, className = "", format = "" }: ICustomDateInputUi,
    ref: any
  ) => (
    <button
      className={twMerge(
        `text-12 leading-[18px] ${value ? "text-black2" : "text-graydark"}`,
        className
      )}
      onClick={onClick}
      ref={ref}
    >
      <span>{value ? formatDate(new Date(value), format || "") : label}</span>
      {/* <span>{value ? formatDateData(new Date(value)) : label}</span> */}
    </button>
  )
);

export default CustomDatePicker;
