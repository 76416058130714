import { Dispatch, FC, Fragment, SetStateAction, useEffect } from "react";
import classNames from "classnames";
import { PaginationState } from "@tanstack/react-table";
import { an } from "@fullcalendar/core/internal-common";
export const hideAllNoneField = () => {
  document.querySelectorAll("th,td").forEach((x) => {

    if (x.id.includes("none")) {
      x.classList.add("hidden");
    }
    else if (x.id.includes("uniqeID")) {
      x.classList.add("hidden");
    }
    else if (x.id.includes("lasted")) {
      //x.classList.add("hidden");
    }
    else if (x.id.includes("action")) {
      x.classList.add("absolute");
      x.classList.add("right-0");
      x.classList.remove("relative");
    } else {
      x.classList.remove("absolute");
      x.classList.add("left-0");
      x.classList.add("relative");
      var div = document.getElementById(x.id);
      if (div) {
        div.style.setProperty("left", "");
      }
    }
  });
};
interface ITablePaginator {
  total: number;
  pagination: PaginationState;
  setPagination: Dispatch<SetStateAction<PaginationState>>;
  itemCount: number;
  changePage?: any;
  setCurrentPage?: any;
  currentPageDefault?: number;
}

const TablePaginator: FC<ITablePaginator> = ({
  pagination,
  setPagination,
  itemCount,
  changePage,
  setCurrentPage,
  currentPageDefault = 0
}) => {
  const currentPage = currentPageDefault != 0 ? currentPageDefault : pagination.pageIndex + 1;
  const limit = pagination.pageSize;
  const totalPages = Math.ceil(itemCount / limit);
  const previousPage = currentPage - 1 || 1;
  const nextPage = currentPage + 1 > totalPages ? currentPage : currentPage + 1;
  const currentPageItemCount = limit * currentPage;

  // console.log("c", currentPage)
  // console.log("lm", limit)


  return (
    <div className="flex flex-col md:flex-row md:justify-between md:items-center">
      <p className="text-12 leading-[18px] text-graydark">
        {/* Displaying items from {(currentPage) * limit + 1} to{" "} */}
        Displaying items from {currentPage === 1 ? 1 : (currentPage * limit) -limit} to{" "}
        {currentPageItemCount > itemCount ? itemCount : currentPageItemCount} of
        total {itemCount} items.
      </p>
      <div className="flex mt-5 md:mt-0 justify-center md:justify-start items-center lg:text-14 text-12 leading-[21px] font-medium">
        <button
          className={classNames("mr-3", {
            "text-black2": currentPage !== previousPage,
            "text-[#97A6B7] pointer-events-none": currentPage === previousPage,
          })}
          onClick={() => {
            setPagination((prev) => ({
              ...prev,
              pageIndex: previousPage - 1,
            }));
            if (setCurrentPage) {
              setCurrentPage(previousPage);
            }
            hideAllNoneField();
          }}
        >
          Previous
        </button>

        {(() => {
          return new Array(totalPages).fill(1).map((_, index: number) => {
            const humanReadablePage = index + 1;
            if (
              humanReadablePage !== 1 &&
              humanReadablePage !== totalPages &&
              ((humanReadablePage > currentPage &&
                humanReadablePage - currentPage > 2) ||
                (humanReadablePage < currentPage &&
                  humanReadablePage + 1 !== currentPage))
            ) {
              return humanReadablePage + 2 === currentPage ||
                humanReadablePage - 3 === currentPage ? (
                <span
                  className="mr-3 w-8 h-8 flex justify-center items-center rounded-md"
                  key={index}
                >
                  ...
                </span>
              ) : (
                <Fragment key={index}></Fragment>
              );
            }

            return (
              <button
                key={index}
                className={classNames(
                  "mr-3 w-8 h-8 flex justify-center items-center rounded-md",
                  {
                    "bg-vorpblue text-white": currentPage === humanReadablePage,
                  }
                )}
                onClick={() => {
                  setPagination((prev) => ({
                    ...prev,
                    pageIndex: (currentPageDefault != 0 ? humanReadablePage-1 : humanReadablePage - 1),
                  }));
                  hideAllNoneField();
                  if (setCurrentPage) {
                    setCurrentPage(humanReadablePage);
                  }
                 // console.log("humanReadablePage ", humanReadablePage)
                  // console.log("humanReadablePage ",humanReadablePage)
                }
                }
              >
                {humanReadablePage}
              </button>
            );
          });
        })()}

        <button
          className={classNames({
            "text-black2": currentPage !== nextPage,
            "text-[#97A6B7] pointer-events-none": currentPage === nextPage,
          })}
          onClick={() => {
            setPagination((prev) => ({
              ...prev,
              pageIndex: nextPage - 1,
            }));
            hideAllNoneField();
            if (setCurrentPage) {
              setCurrentPage(nextPage);
            }

          }}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default TablePaginator;
