import {
    Dispatch,
    FC,
    SetStateAction,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
    forwardRef,
  } from "react";
  import {
    AddIcon,
    ChevronDown,
    // DeleteIcon,
    SearchIcon,
    // TrashIcon,
  } from "../common/Icons";
  // import { Listbox } from "@headlessui/react";
  // import { usePopper } from "../../hooks/usePopper";
  import DebounceSearchInput from "../Tables/DebounceSearchInput";
  // import { EmployeeData } from "../EmployeeTable/EmployeeTable";
  import classNames from "classnames";
  import CreateNewItemPopup from "../CreateNewItemPopup/CreateNewItemPopup";
  // import { baseObj } from "../../utils/constants";
  // import { usePopperCustom } from "../../hooks/usePopperCustom";
  import styles from "../EmployeeTable/EmployeeTable.module.scss";
  // import {
  //   Box,
  //   ClickAwayListener,
  //   makeStyles,
  //   Popper,
  //   PopperPlacementType,
  //   Tooltip,
  // } from "@material-ui/core";
  
  import { makeStyles } from "@mui/styles";
  import { Box, Tooltip, Popper, ClickAwayListener } from "@mui/material";
  // import ClickAwayListener from "@mui/base/ClickAwayListener";
  import { useSpring, animated } from "react-spring";
  
  // import { Cell } from "@tanstack/react-table";
  import { IRole } from "../../backendUsers/views/UserList";
  import SelectBox from "../common/SelectBox";
  import { baseObj } from "../../utils/constants";
  
  interface FadeProps {
    children?: React.ReactElement;
    in?: boolean;
    onEnter?: () => void;
    onExited?: () => void;
  }
  
  const Fade = forwardRef<HTMLDivElement, FadeProps>(function Fade(
    props: any,
    ref: any
  ) {
    const { in: open, children, onEnter, onExited, ...other } = props;
    const style = useSpring({
      from: { opacity: 0 },
      to: { opacity: open ? 1 : 0 },
      onStart: () => {
        if (open && onEnter) {
          onEnter();
        }
      },
      onRest: () => {
        if (!open && onExited) {
          onExited();
        }
      },
    });
  
    return (
      <animated.div ref={ref} style={style} {...other}>
        {children}
      </animated.div>
    );
  });
  
  interface ICustomDropdownList {
    options: any[];
    title: string;
    value: number[];
    setValue: Dispatch<SetStateAction<number[]>>;
    addNewItem?: (val: string) => void;
    isOverflow?: boolean;
    isShowAddBtn?: boolean;
    // show?: boolean;
    // open?: boolean;
    // setOpen?: Dispatch<SetStateAction<boolean>>;
    // setShow?: Dispatch<SetStateAction<boolean>>;
    placement: any | undefined;
    // placement: PopperPlacementType | undefined;
    setPlacement: Dispatch<SetStateAction<any | undefined>>;
    // setPlacement: Dispatch<SetStateAction<PopperPlacementType | undefined>>;
    setData?: any;
    cell?: any;
    isopen?: boolean;
    isShow?: boolean;
    setSelectedRole?: any;
    isEditable?: boolean;
    isEditing?: boolean;
    isProject?: boolean;
    empId?: number;
    selectedRole?: any;
    setLastData?: any;
    lastData?: any;
    setCheckDataLast?: any;
    isChosen?: boolean;
    setSelectedEmpID?: any;
  }
  const useStyles = makeStyles(() => ({
    tooltip: {
      backgroundColor: "transparent",
      margin: 0,
      marginRight: 8,
      padding: 0,
    },
  }));
  const CustomDropdownEditProject: FC<ICustomDropdownList> = ({
    options,
    title,
    value,
    setValue,
    addNewItem,
    isOverflow,
    isShowAddBtn,
    // open,
    // show,
    // setShow,
    // setOpen,
    // placement,
    // setPlacement,
    setData,
    // cell,
    // isopen,
    // isShow,
    setSelectedRole,
    isEditable,
    isEditing,
    isProject,
    empId,
    selectedRole,
    setLastData,
    lastData,
    setCheckDataLast,
    isChosen,
    setSelectedEmpID
  }) => {
    const classes = useStyles();
  
    const [placement, setPlacement] = useState<any>("bottom-start");
    // const [placement, setPlacement] = useState<PopperPlacementType>("bottom-start");
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [open, setOpen] = useState(false);
    // const [selectedName, setSelectedName] = useState<string>(selectedDataItem);
    const [search, SetSearch] = useState<string>("");
    const [showCreatePopup, setShowCreatePopup] = useState<boolean>(false);
    const [show, setShow] = useState<boolean>(false);
    const [isCreated, setIsCreated] = useState<boolean>(false);
    const mainContainer = useRef<HTMLDivElement | null>(null);
    const [width, setWidth] = useState<number>();
    // const [position, setPosition] = useState<string>("");
    // const [getOptions, setOptions] = useState<any>([])
  
    const originalItem = useMemo(
      () => [{ id: 0, value: 0, label: "----" }, ...options],
      []
    );
    const [selectedValues, setSelectedValues] = useState<number[]>(value);
    const [empIdData, setEmpIdData] = useState<number>(0);
    const [filteredList, setFilteredList] = useState(originalItem);
    const chosenRoles = originalItem
      .filter((item) =>
        selectedValues.length > 0
          ? selectedValues.includes(item.value)
          : value?.includes(item.value)
      )
      .map((role) => role.label);
    useEffect(() => {
      const keyword = search?.trim().toLowerCase();
      if (keyword.length > 0) {
        const matchedItems = originalItem.filter((item) =>
          item.label?.toLowerCase().includes(keyword)
        );
        setFilteredList(matchedItems);
      } else {
        setFilteredList(originalItem);
      }
    }, [search, setFilteredList, originalItem]);
  
    // const closeCreatePopup = () => {
    //   if (showCreatePopup == true) {
    //     // setOpen(true);
    //     // setShow(true);
    //     setIsCreated(true);
    //   } else {
    //     // setOpen(false);
    //     // setShow(false);
    //     setIsCreated(false);
    //   }
    //   setShowCreatePopup(false);
    // };
  
    useEffect(() => {
      setWidth(mainContainer.current?.clientWidth as number);
      const resizer: any = window.addEventListener("resize", () =>
        setWidth(mainContainer.current?.clientWidth as number)
      );
      return () => window.removeEventListener("resize", resizer);
    }, [setWidth]);
  
    const handleClick = useCallback(
      (
        newPlacement: any,
        // newPlacement: PopperPlacementType,
        event: React.MouseEvent<HTMLButtonElement>
      ) => {
        // console.log("id is -", empId);
        if (empId) {
          setEmpIdData(empId);
        }
        setAnchorEl(event.currentTarget);
        setPlacement(newPlacement);
        if (setOpen) {
          setOpen(true);
        }
        if (setShow) {
          setShow(true);
        }
      },
      [setAnchorEl, setPlacement, setOpen, setShow]
    );
  
    const handleClickAway = useCallback(
      (e: any) => {
        if (showCreatePopup == true || isCreated == true) {
          if (setOpen) {
            setOpen(true);
          }
          if (setShow) {
            setShow(true);
          }
          setIsCreated(false);
        } else {
          setOpen(false);
          setShow(false);
          setIsCreated(false);
        }
        //setValue(selectedValues)
      },
      [showCreatePopup, isCreated, setOpen, setShow]
    );
  
    const showAll = () => {
      if (setOpen) {
        setOpen(true);
      }
      if (setShow) {
        setShow(true);
      }
    };
  
    const [isAddNewModalOpen, setIsAddNewModalOpen] = useState<boolean>(false);
    var nonSelect = localStorage.getItem("nonSelect");
    var getRole: any = localStorage.getItem("selectedObj");
    var roleData: any = [];
    if (getRole) {
      roleData = JSON.parse(getRole).filter((x: any) => x !== undefined);
    }
  
    // const [lastData, setLastData] = useState<any[]>([]);
    // console.log("lastData", lastData);
    // let empDataChecked: any = [];
    // var selectedObj: any = [];
  
    const changeValue = useCallback(
      (selval: number[]) => {
        var selectedObj: any = [];
        filteredList?.map((f) => {
          if (f.id) {
            if (selval?.includes(f.id)) {
              selectedObj.push(f);
            }
          }
        });
  
        setOpen(true);
        setShow(true);
        setValue(selectedObj);
        localStorage.setItem("selectedObj", JSON.stringify(selectedObj));
        let empDataChecked: any = [];
        if (isProject) {
          const data = {
            employee_id: empId,
            roles: selectedObj?.map((data: any) => data?.id),
          };
          empDataChecked.push(data);
          setCheckDataLast(empDataChecked)
          setLastData([...lastData, data]);
          setSelectedRole(selectedObj);
  
        } else {
          setSelectedRole(selectedObj);
        }
        // console.log("selectedObj 1", selectedObj);
  
        if (Number(nonSelect) === 1 && roleData?.length === 0) {
          // console.log("selectedObj 1", selectedObj);
        } else {
          // console.log("nroleData", roleData);
          // console.log("selectedObj 2", selectedObj);
        }
        if (!isEditing) {
          if (setSelectedEmpID) {
            setSelectedEmpID(empId)
          }
          setData((prev: baseObj[]) => {
            return prev.map((data, index: number) => {
              if (data.id === 0) {
                return { ...data, roles: [...selectedObj] };
              }
              return data;
            });
          });
        }
  
        // console.log("aas", selectedObj);
      },
      [
        // setSelectedRole,
        // setLastData,
        setOpen,
        setShow,
        setValue,
        setSelectedRole,
        filteredList,
        nonSelect,
        getRole,
        isEditing,
        setData,
      ]
    );
  
    const ids = true ? "spring-popper" : undefined;
    useEffect(()=>{
        setSelectedValues(selectedRole)
    },[empId])
    return (
      <div ref={mainContainer} className="h-full w-full">
        {addNewItem && (
          <CreateNewItemPopup
            show={isAddNewModalOpen}
            setShow={setIsAddNewModalOpen}
            addNewItem={addNewItem}
          />
        )}
        <ClickAwayListener onClickAway={handleClickAway}>
          <Box sx={{ position: "relative" }} className="h-full">
            <button
              onClick={(event) => {
                handleClick("bottom-start", event);
                setIsCreated(false);
                setShowCreatePopup(false);
                
                if (selectedValues.length <= 0) {
                  setSelectedValues(value);
                }
                // console.log("last id", empId)
                // setOpen(true);
                // setShow(true);
              }}
              className={classNames(
                isProject ? "  bg-white border-none rounded-[4px] " : isEditable == true ? "bg-vorpmyanmar text-black2" : "bg-offwhite",
                "flex w-full items-center justify-between h-full min-h-[35px] transition-all duration-300  px-[10px] border border-vorpgraylight "
              )}
            >
              <Tooltip
                arrow
                classes={{ tooltip: classes.tooltip }}
                enterTouchDelay={0}
                leaveTouchDelay={5000}
                placement="left"
                title={
                  <>
                    <div className="text-white text-[10px] hover:cursor-pointer leading-[15px] z-[2] rounded p-4 w-full bg-tooltipbg">
                      <span className="block">{chosenRoles.join(", ")}</span>
                    </div>
                  </>
                }
              >
                <div className="flex w-full items-center">
                  <p
                    className={classNames(
                      isOverflow
                        ? "whitespace-nowrap overflow-hidden text-eclipse w-[90%] text-left"
                        : "",
                      "whitespace-nowrap"
                    )}
                  >
                    {chosenRoles.length > 0 ? chosenRoles.join(", ") : "Select"}
                  </p>
                  <ChevronDown />
                </div>
              </Tooltip>
            </button>
            {show ? (
              <Popper id={ids} open={open} anchorEl={anchorEl} transition>
                {({ TransitionProps }) => (
                  <Fade {...TransitionProps}>
                    <div>
                      <div
                        style={{ width }}
                        className={classNames(
                          styles.customList,
                          "min-h-[317px]",
                          "rounded-4 overflow-hidden w-full max-w-[218px] z-10 bg-white"
                        )}
                      >
                        <div className="bg-offwhite  py-[14px] px-5">
                          <div className="flex items-center justify-between mb-2">
                            <h2 className="text-15 text-[#3F4254] font-medium">
                              {title}
                            </h2>
                            {addNewItem && (
                              <button
                                type="button"
                                onClick={() => setIsAddNewModalOpen(true)}
                                className={classNames(
                                  isShowAddBtn == false ? "hidden" : "",
                                  "hover:cursor-pointer"
                                )}
                              >
                                <AddIcon color="#464E5F" />
                              </button>
                            )}
                          </div>
                          <div className="relative">
                            <DebounceSearchInput
                              setState={SetSearch}
                              debounce={400}
                            >
                              {(setState) => (
                                <input
                                  type="text"
                                  placeholder="Search"
                                  className="py-2 px-4 border border-vorpgraylight rounded-4 focus:outline-none w-full text-14 leading-5 text-vorpgraylight"
                                  onChange={(e) => setState(e.target.value)}
                                  onKeyDown={(e) => e.stopPropagation()}
                                  defaultValue=""
                                />
                              )}
                            </DebounceSearchInput>
                            <SearchIcon className="absolute top-0 bottom-0 my-auto right-4" />
                          </div>
                        </div>
                        <div className="flex flex-col overflow-y-auto w-full max-h-[222px] bg-white">
                          {filteredList &&
                            filteredList?.map((item, index) => (
                              <button
                                type="button"
                                onClick={() => {
                                  showAll();
                                }}
                                key={index * Math.random() * 100 || item.value}
                                className="focus:outline-none flex items-center justify-between group px-5 py-2 hover:bg-vorpblue hover:text-white transition-all duration-300 text-left text-14 leading-5 text-graydark"
                              >
                                {item?.value !== 0 && ( //item.label
                                  <SelectBox
                                    // empId={empId}
                                    initialValue={
                                      selectedValues?.length === 0
                                        ? false
                                        : selectedValues?.length > 0
                                          ? selectedValues.includes(item.value)
                                          : value?.includes(item.value)
                                    }
                                    value={item.value || item.id}
                                    setValue={setSelectedValues}
                                    label={item.label || item.name}
                                    filteredList={filteredList}
                                    setOpen={setOpen}
                                    setShow={setShow}
                                    changeValue={changeValue}
                                    handleClick={handleClick}
                                  />
                                )}
                              </button>
                            ))}
                        </div>
                      </div>
                    </div>
                  </Fade>
                )}
              </Popper>
            ) : null}
          </Box>
        </ClickAwayListener>
      </div>
    );
  };
  
  export default CustomDropdownEditProject;
  