import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
  Fragment,
  useMemo,
} from "react";
import { baseObj } from "../../../utils/constants";
import { changeStringToHMS } from "../../CustomTimePicker/CustomTimePicker";
import {
  TProjectListDailyReport,
  TTaskNameList,
  TTaskTagsList,
  useGetClockoutRealTimeMutation,
  useGetProfileDailyReportPopupQuery,
  useSaveProfileDailyReportMutation,
} from "../../../app/services/profile-daily-popup";
import { makeStyles } from "@mui/styles";
import DailyReportPopupForm from "../DailyReport/DailyReportPopupForm";
import {
  ColumnFiltersState,
  PaginationState,
  SortingState,
} from "@tanstack/react-table";
import { getAuthUser } from "../../../app/services/dashboard";
import {
  ProfileDailyReportFilter,
  useGetProfileDailyReportListQuery,
} from "../../../app/services/profile-daily-report";
import { renderDateFormatYMD } from "../../DashboardCalendarView/DashboardAddEventPopup";
import DailyReportRefillForm from "./DailyReportRefillForm";
const useStyles = makeStyles(() => ({
  tooltip: {
    backgroundColor: "transparent",
    margin: 0,
    marginRight: 8,
    padding: 0,
  },
}));
export const sampleData = {
  uniqueId: 0,
  id: 0,
  is_previous: 0,
  project_id: 0,
  type: "project",
  task_category_id: 0,
  task_id: 0,
  time_spent: "00:00:00",
  isDisableRow: true,
  hours: "",
  minutes: "",
};
interface IDailyReport {
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  isOpen: boolean;
  // reportData:any;
  // setReportData: Dispatch<any>;
  setTotalTimeStr: React.Dispatch<React.SetStateAction<string | undefined>>;
  total_time_str: string | undefined;
  setPreviousDataList: Dispatch<SetStateAction<any>>;
  previousDataList: any[];
  setTodayData: React.Dispatch<any>;
  todayData: any;
  setTimeHour: React.Dispatch<React.SetStateAction<string>>;
  timeHour: string;
  setGetHour: React.Dispatch<React.SetStateAction<number>>;
  getHour: number;
  setGetMinute: React.Dispatch<React.SetStateAction<number>>;
  getMinute: number;
  setMaxTimeLimit: React.Dispatch<React.SetStateAction<number>>;
  maxTimeLimit: number;
  refillDate?: Date | undefined;
  setRefillDate?: React.Dispatch<React.SetStateAction<Date | undefined>>;
  filterData: ProfileDailyReportFilter | undefined;
}
const DailyReportPopup: FC<IDailyReport> = ({
  setIsOpen,
  isOpen,
  // setReportData,
  // reportData,
  setTotalTimeStr,
  total_time_str,
  setPreviousDataList,
  previousDataList,
  setTodayData,
  todayData,
  setTimeHour,
  timeHour,
  setGetHour,
  getHour,
  setGetMinute,
  getMinute,
  setMaxTimeLimit,
  maxTimeLimit,
  refillDate,
  setRefillDate,
  filterData,
}) => {
  const [todayArray, setTodayArray] = useState<any[]>([]);
  const [hours, setHours] = useState<number>(0);
  const [minutes, setMinutes] = useState<number>(0);
  const [reportData, setReportData] = useState<any>([]);
  const [totalTimeData, setTotalTimeData] = useState<any>("");
  // const [finalHour, setFinalHour] = useState<number>(0);
  // const [finalMinute, setFinalMinute] = useState<number>(0);

  const [dataClone, setDataClone] = useState<any[]>([]);
  const [updatedData, setUpdatedData] = useState<any[]>([]);
  const [prevId, setPrevId] = useState<any>();
  const [dataHours, setDataHours] = useState<number>(0);
  const [dataMinutes, setDataMinutes] = useState<number>(0);
  const [successPopup, setSuccessPopup] = useState<boolean>(false);
  const [draftData, setDraftData] = useState<any[]>([]);
  const [status, setStatus] = useState<string>("");
  const [state, setState] = useState<number>(0); // 1 for clocked in, 2 for clocked out
  // const [latitude, setLatitude] = useState<number>();
  // const [longitude, setLongitude] = useState<number>();
  const [clockInDate, setClockInDate] = useState<Date>();
  const [clockOutDate, setClockOutDate] = useState<Date>();
  const [clockInTime, setClockInTime] = useState<string>("");
  const [clockOutTime, setClockOutTime] = useState<string>("");
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [isShow, setIsShow] = useState<boolean>(true);
  const [selectedMember, setSelectedMember] = useState<string>("");
  const [uploading, setUploading] = useState<boolean>(false);
  const [isDisabledSubmit, setIsDisabledSubmit] = useState<boolean>(false);
  const [uploadingData, setUploadingData] = useState<boolean>(false);
  const [duplicateData, setDuplicateData] = useState<boolean>(false);
  const [isCheckValue, setIsCheckValue] = useState<boolean>(false);

  const [taskNameId, setTaskNameId] = useState<any>(0);
  const [taskCategoryId, setTaskCategoryId] = useState<any>(0);
  const [projectData, setProjectData] = useState<any>("");
  const [totalTime, setTotalTime] = useState<string>("");
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  const [projectId, setProjectId] = useState<any>({});
  const [taskTagId, setTaskTagId] = useState<number>(0);
  const [taskTagsList, setTaskTagsList] = useState<TTaskTagsList[]>();
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 1,
    pageSize: 10,
  });
  const [sorting, setSorting] = useState<SortingState>([]);
  const [errorPopup, setErrorPopup] = useState<boolean>(false);
  const [message, setMessage] = useState<any>([]);
  // const [isEditing, setIsEdit] = useState<boolean>(false);
  // const [uploading, setUploading] = useState<boolean>(false);
  const [viewLeave, setViewLeave] = useState<boolean>(false);
  const [data, setData] = useState<any>([]);
  const [taskNamesData, setTaskNamesData] = useState<string>("");
  const [messageData, setMessageData] = useState<string>("");
  const [timeError, setTimeError] = useState<string>("");
  // const [userList, setUserList] = useState<baseObj[]>(
  //   paymentStatusListData ?? []
  // );
  const [projectLists, setProjectLists] = useState<TProjectListDailyReport[]>(
    []
  );
  const [projectList, setProjectList] = useState<TProjectListDailyReport[]>();
  const [categoriesList, setCategoriesList] = useState<TTaskTagsList[]>([]);

  const [timeSpentData, setTimeSpentData] = useState(false);

  const [taskNames, setTaskName] = useState<TTaskNameList[]>([]);
  const [saveDaily] = useSaveProfileDailyReportMutation();

  const [totalTimeSpent, setTotalTimeSpent] = useState<number>(0);
  const [currentID, setCurrentID] = useState<number>(0);
  const [getClockoutRealTimeData] = useGetClockoutRealTimeMutation();
  const [realClockoutTime, setRealClockoutTime] = useState<string>("");
  const [memberID, setMemberID] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const { data: dailyPopupData } = useGetProfileDailyReportPopupQuery({
    search: "",
    project_id: 0,
    category_id: 0,
  });
  const { data: dailyReport, isLoading } = useGetProfileDailyReportListQuery({
    page: currentPage,
    limit: pagination.pageSize,
    start: startDate === undefined ? "" : renderDateFormatYMD(startDate, "-"),
    end: endDate === undefined ? "" : renderDateFormatYMD(endDate, "-"),
    type: projectId.type !== undefined ? projectId.type : "",
    project_id: projectId.value !== undefined ? projectId.value : 0,
    task_category_id: taskTagId,
    sorting: sorting
      .map(
        (s) =>
          `${s.desc ? "-" : ""}${
            s.id == "time_spent_text" ? "time_spent" : s.id
          }`
      )
      .join(","),
    employee_id: memberID,
  });
  var getUser = getAuthUser();
  const getAuthPermis: string[] = useMemo(() => [], []);

  if (getUser?.permissions) {
    const getPer = getUser?.permissions?.forEach((per: any) => {
      getAuthPermis.push(per?.name);
    });
  }
  const [currentNumber, setCurrentNumber] = useState<string>("00:00:00");
  const [currentData, setCurrentData] = useState<any>([
    {
      id: 0,
      time_spent: "",
    },
  ]);
  const [currentValue, setCurrentValue] = useState<number>(0);
  const fetchDataMemoized = useCallback(() => {
    if (dailyPopupData) {
      setProjectLists(dailyPopupData.data.project_list);
      setCategoriesList(dailyPopupData.data.task_categories);
      // setPreviousDataList(dailyPopupData.data.previous_data);
      setTaskName(dailyPopupData.data.task_list);
      // setTodayData(dailyPopupData.data.today_data);
      //setTimeHour(dailyPopupData.data.total_time_text);
      // setGetHour(dailyPopupData.data.hours);
      // setGetMinute(dailyPopupData.data.minutes);
      // var time = changeStringToHMS(dailyPopupData.data.total_time);
      // setMaxTimeLimit(time ? time : 0);
    }
  }, [dailyPopupData]);

  const getCurrentDateTime = () => {
    var date = new Date();
    var hour = date.getHours();
    var minute = date.getMinutes();
    var time = changeTimeFormat(hour, minute);
    return {
      date,
      time,
    };
  };
  const changeTimeFormat = (h: number, m: number) => {
    var hour = h.toString();
    var min = m.toString();
    if (hour.length < 2) {
      hour = "0" + hour;
    }
    if (min.length < 2) {
      min = "0" + min;
    }
    return hour + ":" + min;
  };
  const checkLocation = () => {
    const location = window.navigator && window.navigator.geolocation;
    if (location) {
      location.getCurrentPosition(
        (position) => {},
        (error) => {
          setErrorMsg(error?.message);
          // this.setState({ latitude: 'err-latitude', longitude: 'err-longitude' })
        }
      );
    } else {
      setErrorMsg("Please allow to access location");
    }
  };
  const getClockRealTime = () => {
    getClockoutRealTimeData().then((res: any) => {
      if (res.data) {
        setRealClockoutTime(res.data.total_time);
      }
    });
  };

  const deleteFun = (id: number) => {
    const filterData = reportData.filter((d: any) => d.uniqueId !== id);
    setReportData([...filterData]);
  };

  const deleteFunDraft = (id: number) => {
    const filterData = draftData.filter((d: any) => d.id !== id);
    deleteFun(id);
    setDraftData([...filterData]);
  };
  const findDuplicates = (arr: any, propertyName: any) => {
    const seen: any = {};
    const duplicates: any = [];

    arr.forEach((item: any) => {
      const propertyValue = item[propertyName];

      if (seen[propertyValue]) {
        duplicates.push(item);
      } else {
        seen[propertyValue] = true;
      }
    });

    return duplicates;
  };
  const categoryDuplicates = (arr: any, propertyName: any) => {
    const seen: any = {};
    const duplicates: any = [];

    arr.forEach((item: any) => {
      const propertyValue = item[propertyName];

      if (seen[propertyValue]) {
        duplicates.push(item);
      } else {
        seen[propertyValue] = true;
      }
    });

    return duplicates;
  };
  const taskDuplicates = (arr: any, propertyName: any) => {
    const seen: any = {};
    const duplicates: any = [];

    arr.forEach((item: any) => {
      const propertyValue = item[propertyName];

      if (seen[propertyValue]) {
        duplicates.push(item);
      } else {
        seen[propertyValue] = true;
      }
    });

    return duplicates;
  };

  // console.log("cehcing data for drop", isCheckValue);

  useEffect(() => {
    // const final = [...data, ...draftData];
    const final = [...reportData];
    // console.log("dina", final);
    var isZero = final?.find((x: any) => x.time_spent == "00:00:00");
    if (isZero) {
      setTimeSpentData(true);
    } else {
      setTimeSpentData(false);
    }

    const isTaskIDZero = final?.find(
      (x) => x.task_id == 0 && x.task_name == ""
    );
    const duplicates = final.reduce((acc: any, obj: any, index: number) => {
      if (obj.project_id != 0) {
        const duplicates = final.filter((item: any, i: any) => {
          return (
            i !== index &&
            item.task_category_id === obj.task_category_id &&
            item.project_id === obj.project_id &&
            item.task_id == obj.task_id &&
            item.task_name == obj.task_name
          );
        });
        if (
          duplicates.length > 0 &&
          !acc.some(
            (dup: any) =>
              dup.task_category_id === obj.task_category_id &&
              dup.project_id === obj.project_id &&
              dup.task_id == obj.task_id &&
              dup.task_name == obj.task_name
          )
        ) {
          return acc.concat([obj]);
        }
      }
      return acc;
    }, []);
    if (duplicates.length !== 0) {
      setDuplicateData(true);
      setUploading(false);
      return;
    } else {
      setDuplicateData(false);
      setUploading(false);
    }
    if (isTaskIDZero) {
      setUploadingData(true);
      setDuplicateData(false);
      return;
    } else {
      setUploadingData(false);
      setDuplicateData(false);
      return;
    }
  }, [reportData, draftData]);

  useEffect(() => {
    changeEachSliderBackground(reportData);
  }, [reportData]);

  useEffect(() => {
    fetchDataMemoized();
  }, [fetchDataMemoized]);

  // useEffect(() => {
  //     if (previousDataList.length) {
  //         let dataItems: any = [];
  //         const prevDataArr = previousDataList?.find(x => x.id == prevId)?.items
  //         // previousDataList.forEach((dataItem: any) => {
  //         //   const difference = dataItem?.items?.filter((item1: any) => {
  //         //     // Use the find method to check if an item in checkAge exists in allPrevFiles
  //         //     return !data?.find(
  //         //       (item2: any) => item2.is_previous === item1.is_previous
  //         //     );
  //         //   });
  //         //   setData((prev) => {
  //         //     var dif = prev?.filter((x: any) => x.is_previous == 0);
  //         //     console.log("prev ", prev, dif, difference)
  //         //     return [...prev]
  //         //   });
  //         // });
  //         setReportData((prev: any) => {
  //             var dif = prev.filter((x: any) => x.is_previous == 0)
  //             return prevDataArr ? [...prevDataArr, ...dif] : [...dif]
  //         });
  //         // const lastData = data.filter((d: any) => d.is_previous !== 1);
  //         previousDataList.forEach((data: any) => {
  //             if (data.id === prevId) {
  //                 data.items.forEach((item: any) => {
  //                     dataItems.push({
  //                         uniqueId: Number(item.id) * Math.random() * 100,
  //                         id: item.id,
  //                         is_previous: 1,
  //                         type: item.type,
  //                         project_id: item.project_id,
  //                         task_category_id: item.task_category_id,
  //                         task_id: item.task_id,
  //                         time_spent: item.time_spent,
  //                         task_name: item.task_name,
  //                         isDraftData: true,
  //                         is_need_to_create_new:item.is_need_to_create_new
  //                     });
  //                 });
  //             }
  //         });
  //         setDraftData([...dataItems]);
  //     }
  // }, [prevId]);

  // useEffect(() => {
  //     let dataItems: any = [];

  //     if (todayData?.items.length) {
  //         todayData?.items.forEach((item: any) => {
  //             dataItems.push({
  //                 uniqueId: Number(item.id) * Math.random() * 100,
  //                 id: item.id,
  //                 is_previous: item.is_previous,
  //                 type: item.type,
  //                 project_id: item.project_id,
  //                 task_category_id: item.task_category_id,
  //                 task_id: item.task_id,
  //                 time_spent: item.time_spent,
  //                 task_name: item.task_name,
  //                 is_need_to_create_new:item.is_need_to_create_new
  //             });
  //         });
  //         setReportData(removeDuplicateObjects([...draftData, ...dataItems], 'id'));
  //         setTodayArray([...dataItems]);
  //     } else {
  //         setReportData([]);
  //     }
  // }, [todayData]);

  const msToTime = (durationData: any) => {
    const duration = durationData * 1000;
    var milliseconds = Math.floor((duration % 1000) / 100),
      seconds = Math.floor((duration / 1000) % 60),
      minutes = Math.floor((duration / (1000 * 60)) % 60),
      hours = Math.floor(duration / (1000 * 60 * 60)); //%24

    let h = hours < 10 ? "0" + hours : hours;
    let m = minutes < 10 ? "0" + minutes : minutes;
    let s = seconds < 10 ? "0" + seconds : seconds;
    // console.log("msto time", h);

    return h + ":" + m + ":" + s;
  };
  const TimeToMS = (time_spent: string) => {
    let regExTime = /([0-9]?[0-9]):([0-9][0-9]):([0-9][0-9])/;
    let regExTimeArr: any = regExTime.exec(time_spent);
    if (regExTimeArr) {
      let timeHr = regExTimeArr[1] * 3600 * 1000;
      let timeMin = regExTimeArr[2] * 60 * 1000;
      let timeSec = regExTimeArr[3] * 1000;
      let timeMs = timeHr + timeMin + timeSec;
      return timeMs / 1000;
    } else {
      return 0;
    }
  };
  useEffect(() => {
    let regExTime = /([0-9]?[0-9]):([0-9][0-9]):([0-9][0-9])/;
    var total = 0;
    var timespent = reportData?.map((x: any) => {
      let regExTimeArr: any = regExTime.exec(x.time_spent);
      if (regExTimeArr) {
        let timeHr = regExTimeArr[1] * 3600 * 1000;
        let timeMin = regExTimeArr[2] * 60 * 1000;
        let timeSec = regExTimeArr[3] * 1000;

        let timeMs = timeHr + timeMin + timeSec;
        total += timeMs;
      }
    });
    var newData = reportData.map((d: any, index: number) => {
      return {
        ...d,
        limit: maxTimeLimit - TimeToMS(reportData[index - 1]?.time_spent),
      };
    });
    setTotalTime(msToTime(total));
  }, [reportData, getHour, getMinute]);

  useEffect(() => {
    let regExTime = /([0-9]?[0-9]):([0-9][0-9]):([0-9][0-9])/;
    var total = 0;
    var timespent = reportData?.map((x: any) => {
      let regExTimeArr: any = regExTime.exec(x.time_spent);
      if (regExTimeArr) {
        let timeHr = regExTimeArr[1] * 3600 * 1000;
        let timeMin = regExTimeArr[2] * 60 * 1000;
        let timeSec = regExTimeArr[3] * 1000;

        let timeMs = timeHr + timeMin + timeSec;
        total += timeMs;
      }
    });

    var total1 = 0;
    var workingTime = total / 1000;
    setTotalTimeData(msToTime(total / 1000 + total1 / 1000));
    const diff =
      maxTimeLimit - workingTime == 0 ? 0 : maxTimeLimit - workingTime - 300;
    if (diff <= 300 && diff >= -300) {
      setTimeError("");
      setIsDisabledSubmit(false);
    } else if (diff <= 300) {
      setTimeError("The task time spent hours is more than the clocking hours");
      setIsDisabledSubmit(true);
    } else if (diff >= 300) {
      setTimeError(
        "The task time spent hours is lower than the clocking hours"
      );
      setIsDisabledSubmit(true);
    } else {
      setTimeError("");
      setIsDisabledSubmit(false);
    }
  }, [reportData, draftData]);

  useEffect(() => {
    //for draft list hours and minutes
    let draftDataHour: any = draftData?.reduce(function (
      prev: any,
      current: any
    ) {
      return prev + +current?.hours;
    },
    0);

    let draftDataMinute: any = draftData?.reduce(function (
      prev: any,
      current: any
    ) {
      return prev + +current?.minutes;
    },
    0);

    const draftsMinutes = draftDataHour * 60 + draftDataMinute;
    // setDraftDataMinutesFinal(draftsMinutes);
  }, [getHour, getMinute, draftData]);

  useEffect(() => {
    if (timeHour) {
      setHours(Number(timeHour.slice(0, 2)));
      setMinutes(Number(timeHour.slice(4, 6)));
    }
  }, [timeHour]);
  const changeEachSliderBackground = (data: any) => {
    data?.map((x: any) => {
      changeSliderBackground(x, x.time_spent);
    });
  };
  const changeSliderBackground = (cell: any, timestr: string) => {
    var time = changeStringToHMS(timestr);
    var numberInput = document.getElementById("myRange_" + cell?.uniqueId);
    if (numberInput && time) {
      if (maxTimeLimit > 0) {
        var value = ((time - 0) / (maxTimeLimit - 0)) * 100;
        //  console.log("changeSliderBackground ", value)
        numberInput.style.background =
          "linear-gradient(to right, #3699FF 0%, #3699FF " +
          value +
          "%, #E1F0FF " +
          value +
          "%, #E1F0FF 100%)";
      } else {
        var value = 0;
        numberInput.style.background =
          "linear-gradient(to right, #3699FF 0%, #3699FF 0%, #E1F0FF 0%, #E1F0FF 100%)";
      }
    }
  };
  const removeDuplicateObjects = (array: any, property: any) => {
    const uniqueIds: any = [];

    const unique = array.filter((element: any) => {
      const isDuplicate = uniqueIds.includes(element[property]);

      if (!isDuplicate) {
        uniqueIds.push(element[property]);

        return true;
      }

      return false;
    });

    return unique;
  };

  const preventTimeChange = (current: string, id: number) => {
    setReportData((prev: any) => {
      return prev.map((data: baseObj, index: number) => {
        const totalDataTimeSpent =
          prev
            ?.filter((x: any) => x.uniqueId != id)
            ?.reduce(function (acc: any, obj: any) {
              return acc + TimeToMS(obj.time_spent);
            }, 0) + TimeToMS(current);
        const filterCurrentData = removeDuplicateObjects(currentData, "id");
        setCurrentData(filterCurrentData);
        const sameTimeSpent = currentData?.find((x: any) => x.id == id);
        if (sameTimeSpent) {
          sameTimeSpent.time_spent = data.time_spent;
        } else {
          setCurrentData([
            ...currentData,
            { id: id, time_spent: data.time_spent },
          ]);
        }
        if (data.uniqueId == id) {
          var curTimeSpent = TimeToMS(current);

          // data.time_spent = current;
          // var totalTime = prev?.reduce(function (acc: any, obj: any) { return acc + TimeToMS(obj.time_spent); }, 0);
          if (totalDataTimeSpent > maxTimeLimit) {
            const timeData = currentData?.find((x: any) => x.id == id);
            data.maxLimit = totalDataTimeSpent;
            //console.log(maxTimeLimit,totalDataTimeSpent,TimeToMS(current),totalDataTimeSpent-TimeToMS(current))
            data.time_spent = msToTime(
              maxTimeLimit - (totalDataTimeSpent - TimeToMS(current))
            ); //timeData?.time_spent || "00:00:00";
          } else {
            data.maxLimit = totalDataTimeSpent;
            data.time_spent = current;
            if (sameTimeSpent) {
              sameTimeSpent.time_spent = current;
            }
          }
        }
        return data;
      });
    });
  };
  useEffect(() => {
    setReportData(removeDuplicateObjects([...draftData, ...reportData], "id"));
  }, [draftData]);
  return (
    <>
      <DailyReportRefillForm
        dailyPopupData={dailyPopupData}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        setProjectData={setProjectData}
        projectData={projectData}
        categoriesList={categoriesList}
        setCategoriesList={setCategoriesList}
        setTaskNamesData={setTaskNamesData}
        taskNamesData={taskNamesData}
        projectLists={projectLists}
        setProjectLists={setProjectLists}
        setGetHour={setGetHour}
        getHour={getHour}
        setGetMinute={setGetMinute}
        getMinute={getMinute}
        setTotalTime={setTotalTime}
        totalTime={totalTime}
        setPreviousDataList={setPreviousDataList}
        previousDataList={previousDataList}
        draftData={draftData}
        data={reportData}
        setData={setReportData}
        setDraftData={setDraftData}
        setMaxTimeLimit={setMaxTimeLimit}
        maxTimeLimit={maxTimeLimit}
        todayData={todayData}
        setTodayData={setTodayData}
        timeHour={timeHour}
        total_time={total_time_str}
        refillDate={refillDate}
        setRefillDate={setRefillDate}
        currentData={currentData}
        setCurrentData={setCurrentData}
        filterData={filterData}
        setTimeHour={setTimeHour}
        setTotalTimeStr={setTotalTimeStr}
        clockInOutHandler={() => 1}
      />
    </>
  );
};

export default DailyReportPopup;
