import React, { FC, useEffect, useState } from "react";
import classNames from "classnames";
import { SelectedIcon, TrashIcon } from "../common/Icons";
import { baseObj } from "../../utils/constants";
import Styles from "./CreateProjectPopup.module.scss";

export type TCheckListProject = {
  checkData: any;
  setData: any;
  handleCheckDelete: (id: number) => void;
  isSubmit: boolean;
};
const CheckListProject: FC<TCheckListProject> = ({
  checkData,
  setData,
  handleCheckDelete,
  isSubmit,
}) => {
  const [checkValue, setCheckValue] = useState<string>("");

  const handleChange = (value: string) => {
    setCheckValue(value);
    setData((prev: baseObj[]) => {
      return prev.map((data) => {
        if (data.id === checkData.id) {
          return {
            ...data,
            label: value,
          };
        }
        return data;
      });
    });
  };

  const handleCheckList = () => {
    setData((prev: baseObj[]) => {
      return prev.map((data) => {
        if (data.id === checkData.id) {
          return {
            ...data,
            is_checked: !data.is_checked,
          };
        }
        return data;
      });
    });
  };

  useEffect(() => {
    if (checkData.label) {
      setCheckValue(checkData.label);
    }
  }, [checkData]);

  return (
    <div className="flex w-full  flex-col">
      <div className="flex">
        {/* <div className="flex justify-center items-center mb-3 pl-2"> */}
        {/* <input
            checked={checkData.is_checked}
            id="default-checkbox1"
            type="checkbox"
            value=""
            onChange={handleCheckList}
            className={classNames(
              "w-6 h-6 text-blue-600 bg-gray-100 border-gray-300 rounded-md  dark:ring-offset-gray-800 focus:ring-2 "
            )}
          //focus:ring-blue-500 dark:focus:ring-blue-600 dark:bg-gray-700 dark:border-gray-600
          /> */}
        <label
            htmlFor={`checklist_${checkData.id}`}
            className={`flex items-center`}
          >
            <input
              type="checkbox"
              id={`checklist_${checkData.id}`}
              className="hidden"
              onChange={handleCheckList}
              checked={checkData.is_checked}
            />
            <div
              className={`w-6 h-6 rounded-4 flex justify-center items-center mr-3 ${(checkData.is_checked)
                ? "bg-checkItemColor"
                : "border border-[#DCE6F0] bg-disabledColor"
                }`}
            >
              {(checkData.is_checked) && <SelectedIcon width={9} height={7} />}
            </div>
          </label>
        {/* </div> */}

        <input
          value={checkValue}
          onChange={(e) => handleChange(e.target.value)}
          type="text"
          placeholder="Reminder Checklist."
          className="focus:outline-none mb-2 focus-visible:outline-none w-full rounded py-[8px] px-3 border border-goalinputborder text-black2 text-14 font-medium font-primary placeholder:text-graydark h-[44px]"
        ></input>

        <button
          type="button"
          onClick={() => handleCheckDelete(checkData?.id)}
          className={classNames(
            " mx-2 min-w-[3rem] w-12 h-[43px] mr-0 mb-4 flex justify-center items-center group rounded-md bg-redPink hover:bg-redPinkLight transition-all duration-300"
          )}
        >
          <TrashIcon />
        </button>
      </div>
    </div>
  );
};

export default CheckListProject;
