import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "./app/store";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./index.scss";
import { BrowserRouter } from "react-router-dom";
import { UserbackProvider } from "@userback/react";
const container = document.getElementById("root")!;
const root = createRoot(container);
console.clear();
root.render(
  // <React.StrictMode>
  <>
    <UserbackProvider token="35093|80536|RErv6fuATWMmA8Fy8q20ui9Yi">
      <BrowserRouter>
        <Provider store={store}>
          <App />
        </Provider>
      </BrowserRouter>
    </UserbackProvider>
  </>
  // </React.StrictMode >
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
if ('serviceWorker' in navigator) {
  window.addEventListener('load', async () => {
      try {
        const swRegistration = await navigator.serviceWorker.register("service-worker.js");
        swRegistration.addEventListener("updatefound", () => {
          console.log("[Service Worker has been installed.]");
        });
      } catch(e) {
        console.log(e);
      }
  });

}
reportWebVitals();
