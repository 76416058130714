import React from 'react'

const BaseInfoSkeleton = () => {
    return (
        <div>
            <div className="divide-y divide-gray-200 dark:divide-gray-400">
                <div role="status" className="space-y-2.5 animate-pulse max-w-lg mb-3">
                    <div className="flex items-center w-full space-x-2">
                        <div className="h-8 bg-gray-200 rounded-lg dark:bg-gray-400 w-72"></div>
                    </div>
                    <div className="flex items-center w-full space-x-2">
                        <div className="h-5 bg-gray-200 rounded-full dark:bg-gray-400 w-72"></div>
                        <div className="h-5 bg-gray-300 rounded-full dark:bg-gray-400 w-full"></div>
                    </div>
                    <div className="flex items-center w-full space-x-2">
                        <div className="h-5 bg-gray-200 rounded-full dark:bg-gray-400 w-80"></div>
                        <div className="h-5 bg-gray-300 rounded-full dark:bg-gray-400 w-full"></div>
                    </div>
                    <div className="flex items-center w-full space-x-2">
                        <div className="h-5 bg-gray-200 rounded-full dark:bg-gray-400 w-72"></div>
                        <div className="h-5 bg-gray-300 rounded-full dark:bg-gray-400 w-full"></div>
                    </div>
                    <div className="flex items-center w-full space-x-2">
                        <div className="h-5 bg-gray-200 rounded-full dark:bg-gray-400 w-96"></div>
                        <div className="h-5 bg-gray-300 rounded-full dark:bg-gray-400 w-full"></div>
                    </div>
                    <div className="flex items-center w-full space-x-2">
                        <div className="h-5 bg-gray-200 rounded-full dark:bg-gray-400 w-80"></div>
                        <div className="h-5 bg-gray-300 rounded-full dark:bg-gray-400 w-full"></div>
                    </div>
                    <div className="flex items-center w-full space-x-2">
                        <div className="h-5 bg-gray-200 rounded-full dark:bg-gray-400 w-60"></div>
                        <div className="h-5 bg-gray-300 rounded-full dark:bg-gray-400 w-full"></div>
                    </div>
                    <div className="flex items-center w-full space-x-2">
                        <div className="h-5 bg-gray-200 rounded-full dark:bg-gray-400 w-72"></div>
                        <div className="h-5 bg-gray-300 rounded-full dark:bg-gray-400 w-full"></div>
                    </div>
                    <div className="flex items-center w-full space-x-2">
                        <div className="h-5 bg-gray-200 rounded-full dark:bg-gray-400 w-64"></div>
                        <div className="h-5 bg-gray-300 rounded-full dark:bg-gray-400 w-full"></div>
                    </div>
                    <div className="flex items-center w-full space-x-2">
                        <div className="h-5 bg-gray-200 rounded-full dark:bg-gray-400 w-72"></div>
                        <div className="h-5 bg-gray-300 rounded-full dark:bg-gray-400 w-full"></div>
                    </div>
                    <span className="sr-only">Loading...</span>
                </div>
                <div />
            </div>
            <div className="divide-y divide-gray-200 dark:divide-gray-500 mt-2">
                <div role="status" className="space-y-2.5 animate-pulse max-w-lg mb-3">
                    <div className="flex items-center w-full space-x-2">
                        <div className="h-8 bg-gray-200 rounded-lg dark:bg-gray-400 w-72"></div>
                    </div>
                    <div className="flex items-center w-full space-x-2">
                        <div className="h-5 bg-gray-200 rounded-full dark:bg-gray-400 w-72"></div>
                        <div className="h-5 bg-gray-300 rounded-full dark:bg-gray-400 w-full"></div>
                    </div>
                    <div className="flex items-center w-full space-x-2">
                        <div className="h-5 bg-gray-200 rounded-full dark:bg-gray-400 w-80"></div>
                        <div className="h-5 bg-gray-300 rounded-full dark:bg-gray-400 w-full"></div>
                    </div>
                    <div className="flex items-center w-full space-x-2">
                        <div className="h-5 bg-gray-200 rounded-full dark:bg-gray-400 w-72"></div>
                        <div className="h-5 bg-gray-300 rounded-full dark:bg-gray-400 w-full"></div>
                    </div>
                    <div className="flex items-center w-full space-x-2">
                        <div className="h-5 bg-gray-200 rounded-full dark:bg-gray-400 w-96"></div>
                        <div className="h-5 bg-gray-300 rounded-full dark:bg-gray-400 w-full"></div>
                    </div>
                    <div className="flex items-center w-full space-x-2">
                        <div className="h-5 bg-gray-200 rounded-full dark:bg-gray-400 w-80"></div>
                        <div className="h-5 bg-gray-300 rounded-full dark:bg-gray-400 w-full"></div>
                    </div>

                    <div className="flex items-center w-full space-x-2">
                        <div className="h-5 bg-gray-200 rounded-full dark:bg-gray-400 w-72"></div>
                        <div className="h-5 bg-gray-300 rounded-full dark:bg-gray-400 w-full"></div>
                    </div>
                    <div className="flex items-center w-full space-x-2">
                        <div className="h-5 bg-gray-200 rounded-full dark:bg-gray-400 w-64"></div>
                        <div className="h-5 bg-gray-300 rounded-full dark:bg-gray-400 w-full"></div>
                    </div>
                    <div className="flex items-center w-full space-x-2">
                        <div className="h-5 bg-gray-200 rounded-full dark:bg-gray-400 w-72"></div>
                        <div className="h-5 bg-gray-300 rounded-full dark:bg-gray-400 w-full"></div>
                    </div>
                    <span className="sr-only">Loading...</span>
                </div>
                <div />
            </div>
            <div className="divide-y divide-gray-200 dark:divide-gray-500 mt-2">
                <div role="status" className="space-y-2.5 animate-pulse max-w-lg mb-3">
                    <div className="flex items-center w-full space-x-2">
                        <div className="h-8 bg-gray-200 rounded-lg dark:bg-gray-400 w-72"></div>
                    </div>
                    <div className="flex items-center w-full space-x-2">
                        <div className="h-5 bg-gray-200 rounded-full dark:bg-gray-400 w-72"></div>
                        <div className="h-5 bg-gray-300 rounded-full dark:bg-gray-400 w-full"></div>
                    </div>
                    <div className="flex items-center w-full space-x-2">
                        <div className="h-5 bg-gray-200 rounded-full dark:bg-gray-400 w-80"></div>
                        <div className="h-5 bg-gray-300 rounded-full dark:bg-gray-400 w-full"></div>
                    </div>
                    <div className="flex items-center w-full space-x-2">
                        <div className="h-5 bg-gray-200 rounded-full dark:bg-gray-400 w-72"></div>
                        <div className="h-5 bg-gray-300 rounded-full dark:bg-gray-400 w-full"></div>
                    </div>
                    <div className="flex items-center w-full space-x-2">
                        <div className="h-5 bg-gray-200 rounded-full dark:bg-gray-400 w-96"></div>
                        <div className="h-5 bg-gray-300 rounded-full dark:bg-gray-400 w-full"></div>
                    </div>
                    <div className="flex items-center w-full space-x-2">
                        <div className="h-5 bg-gray-200 rounded-full dark:bg-gray-400 w-80"></div>
                        <div className="h-5 bg-gray-300 rounded-full dark:bg-gray-400 w-full"></div>
                    </div>
                    <div className="flex items-center w-full space-x-2">
                        <div className="h-5 bg-gray-200 rounded-full dark:bg-gray-400 w-60"></div>
                        <div className="h-5 bg-gray-300 rounded-full dark:bg-gray-400 w-full"></div>
                    </div>
                    <div className="flex items-center w-full space-x-2">
                        <div className="h-5 bg-gray-200 rounded-full dark:bg-gray-400 w-72"></div>
                        <div className="h-5 bg-gray-300 rounded-full dark:bg-gray-400 w-full"></div>
                    </div>

                    <span className="sr-only">Loading...</span>
                </div>
                <div />
            </div>
        </div>
    )
}

export default BaseInfoSkeleton