import { Dispatch, FC, SetStateAction, useCallback, useEffect } from "react";
import {
  PinIcon,
  SortAscendingIcon,
  SortDescendingIcon,
} from "../common/Icons";
import { IColumnHelpersState } from "./LayoutAdjustableTable";
import { Header } from "@tanstack/react-table";

interface IColumnHelperTooltip {
  header: Header<unknown, unknown>;
  IsColumnHelpersOpen: IColumnHelpersState;
  setIsColumnHelpersOpen: Dispatch<SetStateAction<IColumnHelpersState>>;
  setPinnedArr:any;
}

const ColumnHelperTooltip: FC<IColumnHelperTooltip> = ({
  header,
  IsColumnHelpersOpen,
  setIsColumnHelpersOpen,
  setPinnedArr
}) => {
  var div=document.getElementById("header"+header.id)?.getBoundingClientRect();
  var left=div?.left;
  var top=div?.bottom;
  const composer = useCallback(
    (callback: () => void) => {
      return () => {
        setIsColumnHelpersOpen({});
        callback();
      };
    },
    [setIsColumnHelpersOpen]
  );
  const isColumnPinned = header.column.getIsPinned() == "left";

  useEffect(() => {
    const closeHelperTooltip = (e: any) => {
      if (
        Object.keys(IsColumnHelpersOpen).length > 0 &&
        !e.target?.closest(`.${header.column.id}`)
      ) {
        setIsColumnHelpersOpen({});
      }
    };

    document.addEventListener("mousedown", closeHelperTooltip);
    return () => document.removeEventListener("mousedown", closeHelperTooltip);
  }, []);

  return (
    <div
      onMouseDown={(e) => e.stopPropagation()}      
      className={`tooltip-z top-[${top}px] left-[${left}px] absolute font-normal w-[260px] overflow-hidden rounded-4  z-20 border border-[#DADADA] shadow-[0_2px_16px_rgba(0,0,0,0.25)] ${header.column.id}`}
    >
      <div className="relative">
        <div className="bg-white/60 w-full h-full absolute top-0 left-0 backdrop-blur-[3.6px] z-[-1]"></div>
        <div className="p-3 border-b border-[#E5EAEE]">
          <button
            type="button"
            onClick={
              isColumnPinned
                ? composer(() => {header.column.pin(false);setPinnedArr(header.column.id,false);})
                : composer(() => {header.column.pin("left");setPinnedArr(header.column.id,"left");})
            }
            className="bg-black2 w-full h-8 flex items-center px-2 text-14 leading-[21px] text-white rounded-4"
          >
            <PinIcon className="mr-2" />
            {isColumnPinned
              ? "Unpin this column"
              : "Pin this column to the left"}
          </button>
        </div>
        <div className="py-[18px] px-5 text-14 leading-5 text-black2 w-full">
          <button
            type="button"
            onClick={composer(() => header.column.toggleSorting(false, false))}
            className="flex items-center mb-3 w-full"
          >
            <SortAscendingIcon className="mr-1" />
            Sort by A-Z
          </button>
          <button
            type="button"
            onClick={composer(() => header.column.toggleSorting(true, false))}
            className="flex items-center w-full"
          >
            <SortDescendingIcon className="mr-1" />
            Sort by Z-A
          </button>
        </div>
      </div>
    </div>
  );
};

export default ColumnHelperTooltip;
