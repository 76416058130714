// import { Popper } from "@material-ui/core";
// import { Popper } from "@mui/material"
// hello

import {
    Cell,
    ColumnDef,
    ColumnFiltersState,
    createColumnHelper,
    PaginationState,
    // RowData,
    RowSelectionState,
    SortingState,
} from "@tanstack/react-table";
import classNames from "classnames";
import {
    Dispatch,
    FC,
    SetStateAction,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from "react";
import {
    TemplateData,
} from "../../app/services/employee";
import { baseObj } from "../../utils/constants";
import {
    // AddIcon,
    CancelIcon,
    CheckIcon,
    CloseSmallIcon,
    // ChevronDown,
    EditIcon,
    ReturnIcon,
    ShareIcon,
    TableSaveIcon,
    TrashIcon,
    ViewIcon,
} from "../common/Icons";
// import CustomSingleDropdown from "../CustomSingleDropdown/CustomSingleDropdown";
import {
    // renderDateFormat,
    renderDateFormatDMY,
    // renderDateFormatMDY,
} from "../DashboardCalendarView/DashboardAddEventPopup";
import DeletePopup from "../DeletePopup/DeletePopup";
// import EnableSwitch from "../Projects/ProjectCompleteSwitch";
import ReportToPopup from "../ReportToPopup/ReportToPopup";
import SharePopup from "../SharePopup/SharePopup";
import EditableCell from "../Tables/EditableCell";
import LayoutAdjustableTable from "../Tables/LayoutAdjustableTable";
import TablePaginator from "../Tables/TablePaginator";
// import DepartmentDropdownList from "./DepartmentDropdownList";
import styles from "./EquipmentTable.module.scss";
// import { getAuthUser } from "../../app/services/dashboard";
import { templateData } from "../../app/services/template";
// import SuccessPopup from "../SuccessPopup/SuccessPopup";
import ErrorPopup from "../ErrorPopup/ErrorPopup";
// import { NavLink } from "react-router-dom";
// import { deleteCurrency } from "../../app/services/project";
import CommonErrorPopup from "../common/CommonErrorPopup";
import DOMPurify from "dompurify";
import Table from "../Tables/Tables.module.scss";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { EnableSwitch } from "../BackendUsers/BackendUsersTable";
import ToggleSwitch from "../common/ToggleSwitch";
import { twMerge } from "tailwind-merge";
import { IAppEquipment, IEquipment } from "../../equipments/views/Equipments";

export interface StoreEmployeeType {
    employeeID: string;
    id: number;
    name: string;
    preferredName: string;
    email: string;
    mobile: string;
    joinedDate: string;
    department_id: number;
    designation_id: number;
    currency_id: number;
    position: string;
    // reportTo: number;
    report_to: number;
    currency: string;
    salary: number;
    dob: string;
    gender: string;
    race: string;
    religion: string;
    country_id: number;
    nationalityID: string;
    passportNo: string;
    device: string;
    bank: string;
    bankAccNo: string;
    status: string;
}

export type RegionResponse = {
    region: EmployeeData[];
};

export interface EmployeeData {
    id: number;
    name: string;
    label: string;
    isChecked?: boolean;
    isCheck?: boolean;
    project_id?: number;
    isDone?: boolean;
    amount?: number;
    uniqeID?: number;
    //for keydate
    key_id?: number;
    date?: string | null;
    flag?: string;
    rowId?: number;
    region_id?: number;
    region_name?: string;
    // region?: any,
}

export interface GenderData {
    id: number;
    name: string;
    label: string;
}

export interface MembereData {
    id: number;
    name: string;
    label: string;

    preferred_name?: string;
    year_w_vo?: number;
    age?: number;
}

export type TPayment = {
    id?: number;
    project_status_id?: number | string;
    amount?: number;
    payment_due_date?: string | null;
    payment_received_date?: string | null;
    invoice_no?: string;
    payment_received_no?: string;
    is_completed: number;
};

export type TCheck = {
    label: string;
    is_checked: number;
};

export type TKeyDate = {
    key_id: number;
    key_date: string;
};


export interface EmployeeListData {
    name?: string;
    uniqeID: number;
    id: number;
    employeeID: string;
    realName: string;
    preferredName: string;
    email: string;
    mobile: string;
    joinedDate: string;
    years: string;
    department: string;
    designation: string;
    report_to: string | number;
    // reportTo: string;
    salary: string;
    dob: string;
    age: string;
    gender: string;
    race: string;
    religion: string;
    nationality: string;
    nationalityID: string;
    passportNo: string;
    device: string;
    bank: string;
    bankAccNo: string;
    status: string;
}

export interface EmployeeListDataCap {
    EmployeeID: string;
    RealName: string;
    PreferredName: string;
    Email: string;
    Mobile: string;
    JoinedDate: string;
    Years: string;
    Department: string;
    Designation: string;
    Report_to: string | number;
    // ReportTo: string;
    Salary: string;
    DateOfBirth: string;
    Age: string;
    Gender: string;
    Race: string;
    Religion: string;
    Nationality: string;
    NationalityID: string;
    PassportNo: string;
    Device: string;
    Bank: string;
    BankAccNo: string;
    Status: string;
}

interface IEquipmentTable {
    isLayoutControlOpen: boolean;
    setIsLayoutControlOpen: Dispatch<SetStateAction<boolean>>;
    pagination: PaginationState;
    setPagination: Dispatch<SetStateAction<PaginationState>>;
    globalSearch: string;
    setGlobalSearch: Dispatch<SetStateAction<string>>;
    columnFilters: ColumnFiltersState;
    setColumnFilters: Dispatch<SetStateAction<ColumnFiltersState>>;
    orgDataList: EmployeeListData[];
    data: baseObj[];
    setData: Dispatch<SetStateAction<baseObj[]>>;
    deleteEmployee: any;
    deleteEmployeeByIDs: any;
    hiddenFields: string[];
    setHiddenFields: any;
    setCurrentView: any;
    localModuleId: number;
    templateList: templateData[];
    total: number;
    changePage?: any;
    setCurrentPage: any;
    currentPage: number;
    setIsEdit: Dispatch<SetStateAction<boolean>>;
    isEditing?: boolean;
    setIsTemplate?: Dispatch<SetStateAction<boolean>>;
    setCols?: any;
    addNew: boolean;
    setEmployeeID: Dispatch<SetStateAction<string>>;
    sorting: SortingState;
    setSorting: React.Dispatch<React.SetStateAction<SortingState>>;
    setEditedData: React.Dispatch<React.SetStateAction<IAppEquipment | undefined>>;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    approveEquipmentData: (id: number) => void;
    rejectEquipmentData: (id: number) => void;
    setApplyLoading: React.Dispatch<React.SetStateAction<boolean>>;
    setRejectLoading: React.Dispatch<React.SetStateAction<boolean>>;
    applyLoading: boolean;
    rejectLoading: boolean;
    setOpenConfirm: React.Dispatch<React.SetStateAction<boolean>>;
    setOpenReject: React.Dispatch<React.SetStateAction<boolean>>
    openReject: boolean;
    openConfirm: boolean;
}

interface IEnableSwitch {
    initialState: boolean;
    id: string;
    setData: Dispatch<SetStateAction<baseObj[]>>;
    cell: Cell<any, any>;
    changeUserStatusData: (id: string, value?: any) => void;
    loading: any;
}

const EquipmentApplicationTable: FC<IEquipmentTable> = ({
    isLayoutControlOpen,
    setIsLayoutControlOpen,
    pagination,
    setPagination,
    globalSearch,
    setGlobalSearch,
    columnFilters,
    setColumnFilters,
    data,
    setData,
    // orgDataList,
    deleteEmployee,
    deleteEmployeeByIDs,
    hiddenFields,
    templateList,
    setHiddenFields,
    setCurrentView,
    localModuleId,
    total,
    // changePage,
    setCurrentPage,
    currentPage,
    setIsEdit,
    isEditing,
    setIsTemplate,
    setCols,
    addNew,
    sorting,
    setSorting,
    setEditedData,
    setIsOpen,
    approveEquipmentData,
    rejectEquipmentData,
    setApplyLoading,
    setRejectLoading,
    applyLoading,
    rejectLoading,
    setOpenConfirm,
    setOpenReject,
    openConfirm,
    openReject
}) => {
    const columnHelper = useMemo(() => createColumnHelper<any>(), []);
    const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
    const [itemCount, setItemCount] = useState<number>(total);
    const selectRowsCount = Object.keys(rowSelection).length;
    const [successPopup, setSuccessPopup] = useState<boolean>(false);
    const [errorPopup, setErrorPopup] = useState<boolean>(false);
    const [message, setMessage] = useState<any>([]);
    const [msg, setMsg] = useState<string>("");
    
    const [editedID, setEditedID] = useState<number>();
    const acceptEquipment = useCallback(
        (id?: number) => {
            console.log('id ', id)
            setEditedID(id);
            setOpenConfirm(true);
        },
        [setData, rowSelection]
    );
    const rejectEquipment = useCallback(
        (id?: number) => {
            setEditedID(id);
            setOpenReject(true);
        },
        [setData, rowSelection]
    );
    const columns = useMemo(
        () => [
            columnHelper.accessor("equipment_code", {
                header: "Equipment ID",
                id: "equipmentID",
                cell: ({ getValue, cell }) => {
                    const equipmentID = getValue();
                    return (
                        <div className="  text-left bg-white text-black2 px-[10px]">
                            <p className="h-full flex items-center">{equipmentID}</p>
                        </div>
                    );
                },
            }),
            columnHelper.accessor("serialno", {
                header: "Serial Number",
                id: "serialno",
                cell: ({ getValue, cell }) => {
                    const serialno = getValue();
                    return (
                        <div className="  text-left bg-white text-black2 px-[10px]">
                            <p className="h-full flex items-center">{serialno}</p>
                        </div>
                    );
                },
            }),
            columnHelper.accessor("category", {
                header: "Category",
                id: "category",
                cell: ({ getValue, cell }) => {
                    const category = getValue();
                    return (
                        <div className="  text-left bg-white text-black2 px-[10px]">
                            <p className="h-full flex items-center">{category?.name}</p>
                        </div>
                    );
                },
            }),
            columnHelper.accessor("name", {
                header: "Equipment Name",
                id: "name",
                cell: ({ getValue, cell }) => {
                    const name = getValue();
                    return (
                        <div className="  text-left bg-white text-black2 px-[10px]">
                            <p className="h-full flex items-center">{name}</p>
                        </div>
                    );
                },
            }),
            columnHelper.accessor("employee", {
                header: "Employee",
                id: "employee",
                enableSorting: false,
                cell: ({ getValue, cell }) => {
                    const employee = getValue();
                    return (
                        <div className="  text-left bg-white text-black2 px-[10px]">
                            <p className="h-full flex items-center">{employee?.name}</p>
                        </div>
                    );
                },
            }),
            columnHelper.accessor("application_type", {
                header: "Application Type",
                id: "application",
                enableSorting: false,
                cell: ({ getValue, cell }) => {
                    const application = getValue();
                    return (
                        <div className="  text-left bg-white text-black2 px-[10px]">
                            <p className="h-full flex items-center">{application}</p>
                        </div>
                    );
                },
            }),
            columnHelper.accessor("apply_date", {
                header: "Date Borrowed/Assigned",
                id: "apply_date",
                enableSorting: false,
                cell: ({ getValue, cell }) => {
                    const borrow_date = getValue();
                    return (
                        <div className="  text-left bg-white text-black2 px-[10px]">
                            <p className="h-full flex items-center">{borrow_date}</p>
                        </div>
                    );
                },
            }),
            columnHelper.accessor("expected_return_date", {
                header: "Expected Return Date",
                id: "expected_return_date",
                enableSorting: false,
                cell: ({ getValue, cell }) => {
                    const return_date = getValue();
                    return (
                        <div className="  text-left bg-white text-black2 px-[10px]">
                            <p className={twMerge(return_date?.isOverdue == true ? 'text-redPink' : '', "h-full flex items-center")}>{return_date?.date}{(return_date?.isOverdue == true ? ' (Overdue)' : '')}</p>
                        </div>
                    );
                },
            }),
            columnHelper.accessor("assign_date", {
                header: "Apply Date",
                id: "assign_date",
                enableSorting: false,
                cell: ({ getValue, cell }) => {
                    const borrow_date = getValue();
                    return (
                        <div className="  text-left bg-white text-black2 px-[10px]">
                            <p className="h-full flex items-center">{borrow_date}</p>
                        </div>
                    );
                },
            }),
            columnHelper.accessor("reason", {
                header: "Reason",
                id: "reason",
                enableSorting: false,
                cell: ({ getValue, cell }) => {
                    const reason = getValue();
                    return (
                        <div className="  text-left bg-white text-black2 px-[10px]">
                            <p className="h-full flex items-center">{reason}</p>
                        </div>
                    );
                },
            }),
            columnHelper.display({
                header: "Action",
                id: `actions`,
                cell: ({ cell }) => {
                    const isApprove = cell.row.original.approveOption;
                    const isReject = cell.row.original.rejectOption;
                    const isEdit = cell.row.original.editOption;
                    return (
                        <div
                            className={classNames(
                                styles.actionedBtn,
                                "px-2 flex justify-center items-center "
                                // max-w-[98px] min-w-max
                            )}
                        >
                            {isApprove == true ?
                                <button
                                    type="button"
                                    onClick={() => {
                                        acceptEquipment(cell.row.original.id);
                                    }}
                                    className={classNames(
                                        styles.customSharedBtn,
                                        "w-8 h-8 max-w-[24px] mr-2 flex justify-center items-center group rounded-md bg-vorpphillipines hover:bg-turqouiseLight transition-all duration-300"
                                    )}
                                >
                                    <CheckIcon width={16} height={16} />
                                </button> : null}
                            {isReject == true ?
                                <button
                                    type="button"
                                    onClick={() => {
                                        rejectEquipment(cell.row.original.id);
                                    }
                                    }
                                    className={classNames(
                                        styles.customCloseIcon,
                                        "w-8 h-8 max-w-[24px] mr-2 flex justify-center items-center group rounded-md bg-redPink hover:bg-[#FFEAEF] transition-all duration-300"
                                    )}
                                >
                                    <CloseSmallIcon width={16} height={16} />
                                </button> : null}
                            {isEdit == true ?
                                <button
                                    type="button"
                                    onClick={() => {
                                        editEquipment(Number(cell.row.id), cell);
                                        if (setIsEdit) {
                                            setIsEdit(true);
                                        }
                                    }}
                                    className={classNames(
                                        " w-6 h-6 mr-2 flex justify-center items-center group rounded-md bg-vorpblue hover:bg-annhoverbg transition-all duration-300"
                                    )}
                                >
                                    <EditIcon />
                                </button> : null}
                        </div>
                    );
                },
            }),
        ],
        [
            columnHelper,
            data,
            hiddenFields,
            // allowanceSuccess,
        ]
    );

    const [columnOrder, setColumnOrder] = useState<string[]>(
        columns.map((column: ColumnDef<any, any>) => column.id as string)
    );

    document.querySelectorAll("th,td").forEach((x) => {
        if (x.id.includes("none")) {
            x.classList.add("hidden");
        }

        x.classList.remove("absolute");
        x.classList.add("left-0");
        x.classList.add("relative");
        var div = document.getElementById(x.id);
        if (div) {
            div.style.setProperty("left", "");
        }
        if (x.id.includes("action")) {
            x.classList.add("absolute");
            x.classList.remove("relative");
            x.classList.add("right-0");
            x.classList.remove("left-0");
        }
    });

    const editEquipment = useCallback(
        (id: number, cell: any) => {
            setEditedData(cell.row.original);
            setIsOpen(true);
        },
        [setData]
    );

    const visibleColumns: { [key: string]: boolean } = {};

    hiddenFields?.map((x) => {
        visibleColumns[x] = true;
    });

    if (hiddenFields.length > 0) {
        columns.map((x) => {
            if (x.id == "actions" || x.id == "selection" || x.id == "lasted") {
                visibleColumns[x.id ? x.id : ""] = true;
            } else if (x.id == "uniqeID") {
                visibleColumns[x.id ? x.id : ""] = false;
            } else if (x.id?.includes("none")) {
                visibleColumns[x.id ? x.id : ""] = true;
            } else {
                var isHidden = hiddenFields.find((h) => h == x.id);
                if (isHidden) {
                    visibleColumns[x.id ? x.id : ""] = true;
                } else {
                    visibleColumns[x.id ? x.id : ""] = false;
                }
            }
        });
    }
    const saveTemplateData = (template: any) => {
        var views = columns
            .map((x) => {
                if (x.id) {
                    if (template[x.id] == undefined) {
                        return x.id;
                    }
                }
            })
            .filter((x) => x != undefined);
        var viewsData = views.map((x) => (x != undefined ? x : ""));
        var newTemplate: TemplateData = {
            id: 0,
            name: "View" + templateList.length + 1,
            viewColumns: viewsData,
        };
        setHiddenFields(viewsData);
        setCurrentView("");
    };
    useEffect(() => {
        if (hiddenFields.length > 0) {
            setColumnOrder(["selection", ...hiddenFields]);
        }
    }, [hiddenFields]);

    useEffect(() => {
        setTimeout(() => {
            setSuccessPopup(false);
        }, 1000);
    }, [successPopup]);
    const confirmApply = () => {
        setApplyLoading(true);
        approveEquipmentData(editedID ? Number(editedID) : 0);
    }
    const rejectApply = () => {
        setRejectLoading(true);
        rejectEquipmentData(editedID ? Number(editedID) : 0);
    }
    return (
        <div
            // id="employeeTable"
            className={classNames(
                styles.employeeTable,
                "relative inline-block max-w-full"
            )}
        >
            <ToastContainer className={"text-12 font-poppins font-normal"} />
            <DeletePopup
                loading={applyLoading}
                descText="Are you sure to accept?"
                confirmBtnText="Yes"
                show={openConfirm}
                setShow={setOpenConfirm}
                confirmDeleteData={confirmApply}
                confirmBtnColor="bg-vorpgreen hover:bg-white hover:text-vorpgreen"
            />
            <DeletePopup
                loading={rejectLoading}
                descText="Are you sure to reject?"
                confirmBtnText="Yes"
                show={openReject}
                setShow={setOpenReject}
                confirmDeleteData={rejectApply}
                confirmBtnColor="bg-redPink hover:bg-white hover:text-redPink"
            />
            <ErrorPopup
                descText="Error Message!"
                confirmBtnText="Yes, Close."
                show={errorPopup}
                setShow={setErrorPopup}
                message={message}
                setMessage={setMessage}
            />

            <LayoutAdjustableTable
                saveTemplateData={saveTemplateData}
                hideColumn={visibleColumns}
                columns={columns}
                data={data}
                sorting={sorting}
                setSortingState={setSorting}
                rowSelection={rowSelection}
                setRowSelection={setRowSelection}
                columnOrder={columnOrder}
                setColumnOrder={setColumnOrder}
                unDraggableFields={["actions", "selection", "id"]}
                theaderColor="text-graydark"
                isLayoutControlOpen={isLayoutControlOpen}
                setIsLayoutControlOpen={setIsLayoutControlOpen}
                label="Equipment Application"
                setItemCount={setItemCount}
                pagination={pagination}
                setPagination={setPagination}
                globalFilter={globalSearch}
                setGlobalFilter={setGlobalSearch}
                columnFilters={columnFilters}
                setColumnFilters={setColumnFilters}
                noPaddingCells={[
                    "department",
                    "designation",
                    "gender",
                    "nationality",
                    "status",
                    "currency_id",
                ]}
                localModuleId={localModuleId}
                setIsTemplate={setIsTemplate}
                setCols={setCols}
            />


            <div className="pt-6">
                <TablePaginator
                    total={itemCount}
                    pagination={pagination}
                    setPagination={setPagination}
                    itemCount={total}
                    setCurrentPage={setCurrentPage}
                    currentPageDefault={currentPage}
                // changePage={changePage}
                />
            </div>
            <CommonErrorPopup
                show={msg == "" ? false : true}
                setShow={() => setMsg("")}
                message={msg}
            />
        </div>
    );
};
export default EquipmentApplicationTable;
