import { Dispatch, FC, SetStateAction } from "react";
import { DraggableIcon } from "../common/Icons";
import { Column } from "@tanstack/react-table";
import { useDrag, useDrop } from "react-dnd";

interface IDraggableCard {
  col: Column<unknown, unknown>;
  selectedColumns: Column<unknown, unknown>[];
  setSelectedColumns: Dispatch<SetStateAction<Column<unknown, unknown>[]>>;
}

const reorderColumn = (
  draggedColumnId: string,
  targetColumnId: string,
  columnOrder: Column<unknown, unknown>[]
) => {
  columnOrder.splice(
    columnOrder.indexOf(
      columnOrder.filter((col) => col.id === targetColumnId)[0]
    ),
    0,
    columnOrder.splice(
      columnOrder.indexOf(
        columnOrder.filter((col) => col.id === draggedColumnId)[0]
      ),
      1
    )[0]
  );
  return [...columnOrder];
};

const DraggableCard: FC<IDraggableCard> = ({
  col,
  selectedColumns,
  setSelectedColumns,
}) => {
  const [, dropRef] = useDrop({
    accept: "column",
    drop: (draggedColumn: Column<unknown>) => {
      const newColumnOrder = reorderColumn(
        draggedColumn.id,
        col.id,
        selectedColumns
      );
      setSelectedColumns(newColumnOrder);
    },
  });

  const [, dragRef] = useDrag({
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    item: () => col,
    type: "column",
  });
  const label = col.columnDef.header;
  return (
    <div
      ref={dropRef}
      key={col.id}
      className="w-full mb-2 rounded border border-[#D8D8D8] bg-[#E9EFF4] flex items-center px-3 py-[9px] text-12 sm:text-14 leading-5 text-black2"
    >
      <button type="button" ref={dragRef}>
        <DraggableIcon className="mr-3 hover:cursor-grabbing" />
      </button>
      {typeof label === "function" ? (
        <div className="text-left">
          {
            //@ts-ignore
            label()
          }
        </div>
      ) : (
        label
      )}
    </div>
  );
};

export default DraggableCard;
