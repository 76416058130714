import {
  createColumnHelper,
  SortingState,
  ColumnFiltersState,
  RowSelectionState,
  ColumnDef,
  PaginationState,
} from "@tanstack/table-core";
import {
  FC,
  useState,
  useMemo,
  Dispatch,
  SetStateAction,
  // useEffect,
  memo,
} from "react";
// import { Modal } from "@mui/material";
import { CancelIcon, CloseIcon, EditIcon } from "../../common/Icons";
import { dateRangeFilterFn } from "../../../utils/helpers";
// import {
//   useCreateCancelLeaveMutation,
//   useGetMyAuthLeaveQuery,
// } from "../../../app/services/profile";
import styles from "./MyLeave.module.scss";
import LayoutAdjustableTable from "../../Tables/LayoutAdjustableTable";
import TablePaginator from "../../Tables/TablePaginator";
import { Tdocuments } from "../../../leave-application/views/LeaveApplication";
import { renderDateFormatDMY } from "../../DashboardCalendarView/DashboardAddEventPopup";
import DeletePopup from "../../DeletePopup/DeletePopup";
import { ToastContainer, toast } from "react-toastify";
const classNames = require("classnames");

interface IPersonalLeaveTable {
  data?: any;
  setData?: any;
  columnFilters: ColumnFiltersState;
  setColumnFilters: Dispatch<SetStateAction<ColumnFiltersState>>;
  myLeavesList?: any;
  manageAllLeaveApplication?: boolean;
  id?: number;
  hiddenFields: string[];
  total: number;
  setCurrentPage: any;
  currentPage: number;
  pagination: PaginationState;
  setPagination: Dispatch<SetStateAction<PaginationState>>;
  changePage?: any;
  setShowLeaveFile: Dispatch<SetStateAction<boolean>>;
  setLeaveDocuments: Dispatch<React.SetStateAction<Tdocuments[]>>;
  sorting: SortingState;
  setSorting: React.Dispatch<React.SetStateAction<SortingState>>;
  setIsLeaveApplicationOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  setEditedData?: React.Dispatch<React.SetStateAction<any>>;
  setIsConfirmCancelOpen?:any;
  setIsSuccess?:any;
  isSuccess?:boolean;
  isConfirmCancelOpen?:boolean|false;
  saveCancel?:any;
}

const PersonalLeaveTable: FC<IPersonalLeaveTable> = ({
  // columnFilters,
  // setColumnFilters,
  myLeavesList,
  // manageAllLeaveApplication,
  id,
  hiddenFields,
  total,
  setCurrentPage,
  currentPage,
  pagination,
  setPagination,
  changePage,
  data,
  // setData,
  setShowLeaveFile,
  setLeaveDocuments,
  sorting,
  setSorting,
  setIsLeaveApplicationOpen,
  setEditedData,
  setIsConfirmCancelOpen,
  setIsSuccess,
  isSuccess,
  isConfirmCancelOpen,
  saveCancel
}) => {
  const columnHelper = useMemo(() => createColumnHelper<any>(), []); // TODO add type definition when api binding
  const [rowId, setRowId] = useState<number>();
  const [itemCount, setItemCount] = useState<number>(total);
  // const { data: myLeavesList } = useGetMyAuthLeaveQuery();
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  var columns: any;  
  const columnsWithId = useMemo(
    () => [
      columnHelper.accessor("code", {
        id: "code",
        header: () => (
          <span className="lg:text-[13px] text-12 leading-5 tracking-[0.03em]">
            ID
          </span>
        ),
      }),
      columnHelper.accessor("leaveType", {
        id: "LeaveType",
        enableSorting: false,
        header: () => (
          <span className="lg:text-[13px] text-12 leading-5 tracking-[0.03em]">
            Leave Type
          </span>
        ),
      }),
      // columnHelper.accessor("date", {
      //   id: "date",
      columnHelper.accessor("appliedAt", {
        id: "applied_at",
        header: () => (
          <span className="lg:text-[13px] text-12 leading-5 tracking-[0.03em]">
            Applied Date
          </span>
        ),
        filterFn: dateRangeFilterFn,
      }),
      columnHelper.accessor("from", {
        id: "from",
        header: () => (
          <span className="lg:text-[13px] text-12 leading-5 tracking-[0.03em]">
            From
          </span>
        ),
        cell: ({ getValue, cell }) => {
          const from = getValue();
          return (
            <div className=" text-left bg-white text-black2 px-[10px] font-normal">
              <p className="h-full flex items-center">{renderDateFormatDMY(new Date(from), '-')}</p>
            </div>
          );
        },
      }),
      columnHelper.accessor("to", {
        id: "to",
        header: () => (
          <span className="lg:text-[13px] text-12 leading-5 tracking-[0.03em]">
            To
          </span>
        ),
        cell: ({ getValue, cell }) => {
          const to = getValue();
          return (
            <div className=" text-left bg-white text-black2 px-[10px] font-normal">
              <p className="h-full flex items-center">{renderDateFormatDMY(new Date(to), '-')}</p>
            </div>
          );
        },
      }),
      // columnHelper.accessor("day", {
      //   id: "Full/Half Day",
      columnHelper.accessor("fullHalfDay", {
        id: "Full/Half Day",
        enableSorting: false,
        header: () => (
          <span className="lg:text-[13px] text-12 leading-5 tracking-[0.03em]">
            Full/Half Day
          </span>
        ),
      }),
      columnHelper.accessor("total", {
        id: "Total",
        enableSorting: false,
        header: () => (
          <span className="lg:text-[13px] text-12 leading-5 tracking-[0.03em]">
            Total
          </span>
        ),
      }),
      columnHelper.accessor("status", {
        id: "status",
        enableSorting: false,
        header: () => (
          <span className="lg:text-[13px] text-12 leading-5 tracking-[0.03em] ">
            Status
          </span>
        ),
        cell: (props) => {
          const status = props.getValue();
          return (
            <div
              className={classNames("text-center flex items-center px-3", {
                "text-graydark": status === "Pending",
                "text-[#50CD89]": status === "Approved",
                "text-[#EE436D]": status === "Rejected",
                "text-[#FFA800]": status === "Completed",
              })}
            >
              {status}
            </div>
          );
        },
      }),
      columnHelper.accessor("reason", {
        id: "reason",
        header: () => (
          <span className="lg:text-[13px] text-12 leading-5 tracking-[0.03em]">
            Reason
          </span>
        ),
      }),
      columnHelper.accessor("remark", {
        id: "remark",
        header: () => (
          <span className="lg:text-[13px] text-12 leading-5 tracking-[0.03em]">
            Remark
          </span>
        ),
      }),
      columnHelper.accessor("documents", {
        header: "Supportive Documents",
        id: "documents",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const docs = getValue();
          var txtColor = "text-graydark";
          if (docs.length) {
            txtColor = "text-blue-400";
          }
          return (
            <span
              onClick={() => {
                setLeaveDocuments(docs);
                setShowLeaveFile(true);
              }}
              className={classNames(
                txtColor,
                "text-center justify-center h-full bg-white px-[10px] flex items-center cursor-pointer"
              )}
            >
              {docs.length ? "Attached Files" : "---"}
            </span>
          );
        },
      }),
      columnHelper.display({
        id: "actions",
        header: () => (
          <span className="text-center lg:text-[13px] text-12 leading-5 tracking-[0.03em] block w-full">
            Action
          </span>
        ),
        enableSorting: false,
        cell: (props) => {
          // const status = props.row.original?.status;
          const cancelOption = props.row.original?.cancelOption;
          const editOption = props.row.original?.editOption;
          const ids = props.row.original.id;
          return (
            <>
              {id == 0 ? (
                <div className="text-center flex justify-center items-center">
                  {!cancelOption && !editOption && (
                    <div className="text-center flex justify-center items-center">
                      ---
                    </div>
                  )}
                  {cancelOption == true ? (
                    <button
                      className="w-8 h-8 ml-2 flex justify-center items-center group rounded-md  bg-[#71717a] hover:bg-[#71717a] transition-all duration-300"
                      onClick={() => {
                        setIsConfirmCancelOpen(true);
                        setRowId(ids);
                        setIsSuccess(false);
                      }}
                    >
                      <CancelIcon color="" width={18} height={18} />
                    </button>
                  ) : (
                    ""
                  )}
                  {editOption == true ? (
                    <button
                      type="button"
                      onClick={() => {
                        if (setEditedData) {
                          setEditedData({
                            fromDate: new Date(props.row.original.from),
                            toDate: new Date(props.row.original.to),
                            reason: props.row.original.reason,
                            leaveDuration: props.row.original.fullHalfDay,
                            leaveType: props.row.original.leave_type_id,
                            id: props.row.original.id,
                            documents: props.row.original.documents,
                          });
                        }
                        if (setIsLeaveApplicationOpen) {
                          setIsLeaveApplicationOpen(true);
                        }
                      }}
                      className={classNames(
                        " w-6 h-6 mx-2 flex justify-center items-center group rounded-md bg-vorpblue hover:bg-annhoverbg transition-all duration-300"
                      )}
                    >
                      <EditIcon />
                    </button>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                <div className="text-center flex justify-center items-center">
                  ---
                </div>
              )}
            </>
          );
        },
      }),
    ],
    [columnHelper, isSuccess, myLeavesList?.leaveData,data]
  );

  // const columnsNoId = useMemo(
  //   () => [
  //     columnHelper.accessor("code", {
  //       id: "ID",
  //       header: () => (
  //         <span className="lg:text-[13px] text-12 leading-5 tracking-[0.03em]">ID</span>
  //       ),
  //     }),
  //     columnHelper.accessor("leaveType", {
  //       id: "LeaveType",
  //       enableSorting:false,
  //       header: () => (
  //         <span className="lg:text-[13px] text-12 leading-5 tracking-[0.03em]">
  //           Leave Type
  //         </span>
  //       ),
  //     }),
  //     columnHelper.accessor("appliedAt", {
  //       id: "date",
  //       header: () => (
  //         <span className="lg:text-[13px] text-12 leading-5 tracking-[0.03em]">
  //           Applied Date
  //         </span>
  //       ),
  //       filterFn: dateRangeFilterFn,
  //     }),
  //     columnHelper.accessor("from", {
  //       id: "From",
  //       header: () => (
  //         <span className="lg:text-[13px] text-12 leading-5 tracking-[0.03em]">From</span>
  //       ),
  //     }),
  //     columnHelper.accessor("to", {
  //       id: "To",
  //       header: () => (
  //         <span className="lg:text-[13px] text-12 leading-5 tracking-[0.03em]">To</span>
  //       ),
  //     }),
  //     // columnHelper.accessor("day", {
  //     //   id: "Full/Half Day",
  //     columnHelper.accessor("fullHalfDay", {
  //       id: "Full/Half Day",
  //       header: () => (
  //         <span className="lg:text-[13px] text-12 leading-5 tracking-[0.03em]">
  //           Full/Half Day
  //         </span>
  //       ),
  //     }),
  //     columnHelper.accessor("total", {
  //       id: "Total",
  //       header: () => (
  //         <span className="lg:text-[13px] text-12 leading-5 tracking-[0.03em]">Total</span>
  //       ),
  //     }),
  //     columnHelper.accessor("status", {
  //       id: "status",
  //       header: () => (
  //         <span className="lg:text-[13px] text-12 leading-5 tracking-[0.03em] ">
  //           Status
  //         </span>
  //       ),
  //       cell: (props) => {
  //         const status = props.getValue();
  //         return (
  //           <div
  //             className={classNames("text-center flex items-center", {
  //               "text-graydark": status === "Pending",
  //               "text-[#50CD89]": status === "Approved",
  //               "text-[#EE436D]": status === "Rejected",
  //               "text-[#FFA800]": status === "Completed",
  //             })}
  //           >
  //             <span className="p-5">
  //             {status}
  //               </span>
  //           </div>
  //         );
  //       },
  //     }),
  //     columnHelper.accessor("reason", {
  //       id: "reason",
  //       header: () => (
  //         <span className="lg:text-[13px] text-12 leading-5 tracking-[0.03em]">Reason</span>
  //       ),
  //     }),

  //   ],
  //   [columnHelper, isSuccess, myLeavesList?.leaveData]
  // );

  // if (id) {
  //   columns = columnsNoId;
  // } else {
  //   columns = columnsWithId
  // }
  columns = columnsWithId;
  const [columnOrder, setColumnOrder] = useState<string[]>(
    columns.map((column: ColumnDef<any, any>) => column.id as string)
  );
  
  const visibleColumns: { [key: string]: boolean } = {};

  hiddenFields?.map((x: any) => {
    visibleColumns[x] = true;
  });

  if (hiddenFields.length > 0) {
    columns.map((x: any) => {
      if (x.id == "actions" || x.id == "lasted" || x.id == "selection") {
        visibleColumns[x.id ? x.id : ""] = true;
      } else if (x.id == "uniqeID") {
        visibleColumns[x.id ? x.id : ""] = false;
      } else if (x.id?.includes("none")) {
        visibleColumns[x.id ? x.id : ""] = true;
      } else {
        var isHidden = hiddenFields.find((h) => h == x.id);
        if (isHidden) {
          visibleColumns[x.id ? x.id : ""] = true;
        } else {
          visibleColumns[x.id ? x.id : ""] = false;
        }
      }
    });
  }
  return (
    <div>
      {/* <Modal
        open={isConfirmCancelOpen}
        style={{
          background: "rgb(0 0 0 / 0.5)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        onClose={() => setIsConfirmCancelOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableEnforceFocus={true}
      >
        <div className="modalContent max-w-[90%] 6xs:max-w-[320px] w-full m-auto">
          <div className="w-full max-h-[138px] bg-white rounded-md overflow-hidden">
            <div className="p-[15px] pb-[23px]">
              <button
                className="ml-auto block"
                type="button"
                aria-label="Close Modal"
                onClick={() => setIsConfirmCancelOpen(false)}
              >
                <CloseIcon width={12} height={12} color="#858795" />
              </button>
              <h3 className="text-black2 max-w-[224px] mx-auto text-14 leading-6 text-center font-primary">
                Are you sure to cancel the leave application?
              </h3>
            </div>
            <div className="flex text-12 leading-[18px] font-semibold font-primary">
              <button
                onClick={() => {
                  saveCancel();
                }}
                type="button"
                className="flex-1 py-3 flex justify-center items-center bg-vorpblue text-white"
              >                
                Yes
              </button>
              <button
                onClick={() => setIsConfirmCancelOpen(false)}
                type="button"
                className="flex-1 py-3 flex justify-center items-center bg-offwhite text-vorpgraylight"
              >
                Discard
              </button>
            </div>
          </div>
        </div>
      </Modal> */}
      <div className={styles.personalLeaveTable}>
        <LayoutAdjustableTable
          data={data}
          hideColumn={visibleColumns}
          columns={columns}
          sorting={sorting}
          setSortingState={setSorting}
          rowSelection={rowSelection}
          setRowSelection={setRowSelection}
          columnOrder={columnOrder}
          setColumnOrder={setColumnOrder}
          unDraggableFields={["actions", "selection"]}
          theaderColor="text-graydark"
          isLayoutControlOpen={false}
          setIsLayoutControlOpen={() => 1}
          label="User List"
          setItemCount={setItemCount}
          pagination={pagination}
          setPagination={setPagination}
          noPaddingCells={["leave_type_id", "fullHalfDay", "status"]}
        />
      </div>
      <div className="pt-6">
        <TablePaginator
          total={itemCount}
          pagination={pagination}
          setPagination={setPagination}
          itemCount={total}
          setCurrentPage={setCurrentPage}
          currentPageDefault={currentPage}
          changePage={changePage}
        />
      </div>
      <DeletePopup
        confirmBtnColor="bg-vorpgreen hover:bg-vorpgreen hover:opacity-50 opacity-100"
        descText="Are you sure to Cancel?"
        confirmBtnText="Yes"
        show={isConfirmCancelOpen?isConfirmCancelOpen:false}
        setShow={setIsConfirmCancelOpen}
        confirmDeleteData={()=>saveCancel(rowId)}
      />
      <ToastContainer className={"text-12 font-poppins font-normal"} />
    </div>
  );
};

export default memo(PersonalLeaveTable);
