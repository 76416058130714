import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { CloseIcon, SendIcon, TableSaveIcon } from "../common/Icons";
import { baseObj, departments } from "../../utils/constants";
// import { PasswordUserList } from "../../app/services/password";
// import { Modal, Tooltip, makeStyles } from "@material-ui/core";
import { makeStyles } from "@mui/styles";
import { CommonTagDropdown } from "../common/CommonTagDropdown";
import { Cell } from "@tanstack/react-table";
import Button from "../common/Button";
import { useCreateTagMutation } from "../../app/services/annoucement-sheet";
interface IEmployeeAssignment {
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  modalType: "remove" | "assign";
  options: any;
  tags?: any;
  data: baseObj[];
  setData: Dispatch<SetStateAction<baseObj[]>>;
  setTags: Dispatch<SetStateAction<string[]>>;
  createTagData?: (label: string) => void;
  showTagPopup: boolean;
  cell?: Cell<any, any>;
  editRowData?: any;
}
const useStyles = makeStyles(() => ({
  tooltip: {
    backgroundColor: "transparent",
    margin: 0,
    marginRight: 8,
    padding: 0,
  },
}));
const AssignPopup: FC<IEmployeeAssignment> = ({
  setIsOpen,
  modalType,
  options,
  tags,
  data,
  setData,
  setTags,
  createTagData,
  showTagPopup,
  cell,
  editRowData,
}) => {
  const [createTag] = useCreateTagMutation();
  const classes = useStyles();
  const [isShow, setIsShow] = useState<boolean>(false);
  const [selectedTags, setSelectedTags] = useState<any>(
    tags?.map((x: any) => x.name)
  );
  const [selected, setSelected] = useState<any>(tags);
  useEffect(() => {
    if (editRowData != undefined) {
      const filter = editRowData?.map((t: any) => t.name);
      setSelectedTags(filter);
      setSelected(editRowData);
    }
  }, [editRowData]);

  const confirmSelectedTag = () => {
    setData((prev: baseObj[]) => {
      return prev.map((data: baseObj, index: number) => {
        if (index === Number(cell?.row.id)) {
          if (cell) {
            return { ...data, [cell.column.id]: selected, isNew: true };
          } else {
            return { ...data };
          }
        }
        return data;
      });
    });
    setIsOpen(false);
  };

  return (
    <>
      {" "}
      <div className=" max-w-[530px] max-h-[98vh] overflow-y-auto xl:max-h-none w-full m-auto px-4 py-8 xl:px-0 xl:py-0 font-primary">
        <div className="w-full bg-white rounded-xl overflow-hidden">
          <div className="px-6 sm:px-8 md:px-10 py-4 md:py-6 bg-vorpblue flex justify-between items-center rounded-t-xl overflow-hidden">
            <h1 className="text-16 sm:text-18 leading-[27px] font-medium text-white">
              Assign Tags
            </h1>
            <button type="button" onClick={() => setIsOpen(false)}>
              <CloseIcon width={18} height={18} />
            </button>
          </div>
          <div className="bg-white p-4 xs:p-6 sm:p-8 md:px-10 md:py-9">
            <div className="flex flex-col-reverse xs:flex-row xs:items-center">
              <div className="flex items-center w-full">
                <div className="w-full mb-5">
                  <label htmlFor="" className="base-label mb-2 block">
                    Tag
                  </label>
                  <CommonTagDropdown
                    showAll={true}
                    isObject={true}
                    createData={createTagData}
                    state={selectedTags}
                    setState={setSelectedTags}
                    setSelected={setSelected}
                    options={options?.map((tag: any) => ({
                      label: tag.name,
                      value: tag.id.toString(),
                    }))}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-center my-5 items-center mt-3">
            <Button
              type="button"
              label={
                <span className="flex items-center text-14 leading-[21px] font-medium group-hover:text-vorpblue">
                  <SendIcon className="mr-[10px] group-hover:fill-vorpblue transition-all duration-300" />
                  Submit
                </span>
              }
              onClick={() => confirmSelectedTag()}
              variant="no-outline"
              size="lg"
              customClass="hover:bg-vorpblue mr-2 6xs:py-2 6xs:px-3 hover:bg-vorpmyanmar transition-all duration-300 border-none"
            />
            <Button
              type="button"
              label={
                <span className="flex items-center text-black2 text-14 font-medium leading-[21px]">
                  <CloseIcon
                    width={9}
                    height={9}
                    className="mr-[10px] fill-black2"
                  />
                  Cancel
                </span>
              }
              onClick={() => setIsOpen(false)}
              variant="no-outline"
              size="lg"
              customClass=" 6xs:py-2 6xs:px-3 bg-offwhite text-graydark border-none"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AssignPopup;
