import { ColumnDef, ColumnFiltersState, PaginationState, RowSelectionState, SortingState, createColumnHelper } from '@tanstack/react-table';
import React, { Dispatch, FC, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import EditableCell from '../Tables/EditableCell';
import { baseObj } from '../../utils/constants';
import { renderDateFormatDMY } from '../DashboardCalendarView/DashboardAddEventPopup';
import DatePopup from '../EmployeeTable/DatePopup';
import classNames from 'classnames';
import { CancelIcon, DownloadIcon, EditIcon, TableSaveIcon, TrashIcon, ViewIcon } from '../common/Icons';
import styles from './PerformanceAppraisalTable.module.scss';
import Table from "../Tables/Tables.module.scss";
import { IOTData } from '../../ot-list/views/OTList';
import LayoutAdjustableTable from '../Tables/LayoutAdjustableTable';
import { TemplateData, useCreateDepartmentMutation, useGetDepartmentListQuery } from '../../app/services/employee';
import { templateData } from '../../app/services/template';
import TablePaginator from '../Tables/TablePaginator';
import DeletePopup from '../DeletePopup/DeletePopup';
import { toast } from 'react-toastify';
import CustomDropdownWithAddButton from '../EmployeeTable/CustomDropdownWithAddButton';
import axios from '../../axios';
import { endpointUrl } from '../../app/services/api';
import DOMPurify from 'dompurify';
import { EmployeeData } from '../EmployeeTable/EmployeeTable';
import { NavLink } from 'react-router-dom';
export const statusList = [
    {
        id: 1,
        name: "Pending",
        label: "Pending",
    }
]
interface IOTTable {
    isLayoutControlOpen: boolean;
    setIsLayoutControlOpen: Dispatch<SetStateAction<boolean>>;
    pagination: PaginationState;
    setPagination: Dispatch<SetStateAction<PaginationState>>;
    globalSearch: string;
    setGlobalSearch: Dispatch<SetStateAction<string>>;
    columnFilters: ColumnFiltersState;
    setColumnFilters: Dispatch<SetStateAction<ColumnFiltersState>>;
    orgDataList: IOTData[];
    data: baseObj[];
    setData: Dispatch<SetStateAction<baseObj[]>>;
    deleteEmployee: any;
    deleteEmployeeByIDs: any;
    hiddenFields: string[];
    templateList: templateData[];
    setHiddenFields: any;
    setCurrentView: any;
    total: number;
    changePage: any;
    setCurrentPage: any;
    currentPage: number;
    setIsEdit: Dispatch<SetStateAction<boolean>>;
    isEditing?: boolean;
    setIsTemplate?: Dispatch<SetStateAction<boolean>>;
    localModuleId: number;
    visArray: any;
    setCols?: any;
}
const PerformanceAppraisalTable: FC<IOTTable> = ({
    isLayoutControlOpen,
    setIsLayoutControlOpen,
    pagination,
    setPagination,
    globalSearch,
    setGlobalSearch,
    columnFilters,
    setColumnFilters,
    data,
    setData,
    orgDataList,
    deleteEmployee,
    deleteEmployeeByIDs,
    hiddenFields,
    setHiddenFields,
    templateList,
    setCurrentView,
    total,
    changePage,
    setCurrentPage,
    currentPage,
    setIsEdit,
    isEditing,
    setIsTemplate,
    localModuleId,
    visArray,
    setCols
}) => {
    const columnHelper = useMemo(() => createColumnHelper<any>(), []);
    const [sorting, setSorting] = useState<SortingState>([]);
    const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
    const [deleteID, setDeleteID] = useState<number | 0>();
    const [deleteRowID, setDeleteRowID] = useState<number | 0>();
    const [openDeletePopup, setOpenDeleteopup] = useState<boolean>(false);
    const [department, setDepartment] = useState("All");
    const [itemCount, setItemCount] = useState<number>(total);
    const selectRowsCount = Object.keys(rowSelection).length;
    const saveOTListData = (id: number) => {
        saveOTList(id, false);
    }
    const [successPopup, setSuccessPopup] = useState<boolean>(false);
    const [errorPopup, setErrorPopup] = useState<boolean>(false);
    const [message, setMessage] = useState<any>([]);
    const [msg, setMsg] = useState<string>("");
    const [createDepartment] = useCreateDepartmentMutation();
    const { data: empDepartmentData } = useGetDepartmentListQuery();
    const [departmentData, setDepartmentData] = useState<EmployeeData[]>([]);
    const [deleteDeptID, setDeleteDeptID] = useState<number | 0>();
    useEffect(() => {
        setDepartmentData(empDepartmentData ? empDepartmentData : []);
    }, [empDepartmentData]);
    const saveOTList = useCallback(
        (id: number, isNew: boolean) => {
            setData((prev: baseObj[]) => {
                return prev.map((data, index: number) => {
                    // console.log(data.uniqeID,id)
                    if (data.uniqeID === id) {
                        return {
                            ...data,
                            isNew: isNew,
                        };
                    }
                    return data;
                });
            });
        },
        [setData]
    );
    const cancelRow = (id: number) => {
        if (isEditing) {
            setIsEdit(false)
            setData((prev: baseObj[]) => {
                return prev.map((data, index: number) => {
                    if (data.uniqeID === id) {
                        return { ...data, isNew: false };
                    }
                    return data;
                });
            })
        } else {
            const filterData = data?.filter((d: any) => d?.uniqeID !== id)
            setData(filterData)
        }
    }
    const editOTList = useCallback(
        (id: number) =>
            setData((prev: baseObj[]) => {
                if (document.getElementsByClassName(Table.horizontalScrollBar)[0]) {
                    document.getElementsByClassName(Table.horizontalScrollBar)[0].scrollLeft = 0;
                }
                return prev.map((data, index: number) => {
                    if (index === id) {
                        return { ...data, isNew: true };
                    }
                    return data;
                });
            }),
        [setData]
    );
    const onDownloadClick = (id: number, name: string) => {
        const extension = name.split(".").pop() as string;
        //downloadDoc();
        // using Java Script method to get PDF file
        axios({
            url: endpointUrl + `downloadDocument/${id}`,
            method: "GET",
            responseType: "blob",
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", name + '.' + extension);
            document.body.appendChild(link);
            link.click();
        });
    }
    const saveTemplateData = (template: any) => {
        var views = columns
            .map((x) => {
                if (x.id) {
                    if (template[x.id] == undefined) {
                        return x.id;
                    }
                }
            })
            .filter((x) => x != undefined);
        var viewsData = views.map((x) => (x != undefined ? x : ""));
        var newTemplate: TemplateData = {
            id: 0,
            name: "View" + templateList.length + 1,
            viewColumns: viewsData,
        };
        setHiddenFields(viewsData);
        setCurrentView("");
    };
    const addDepartmentData = (data: string) => {
        const name = DOMPurify.sanitize(data);
        createDepartment({ name })
            .unwrap()
            .then((payload: any) => {
                // console.log("p", payload)
                if (payload.status) {
                    setSuccessPopup(true)
                    setMessage(payload ? [payload?.message] : ['Successfully Delete.']);
                }
            })
            .catch((error: any) => {
                // console.log("e", error)
                setErrorPopup(true)
                setMessage(error ? [error?.data?.message] : ["Something went wrong!Trying again."])
            })
    };
    const columns = useMemo(
        () => [
            columnHelper.display({
                header: "",
                id: "none0",
            }),
            columnHelper.display({
                header: "",
                id: "none1",
            }),
            columnHelper.display({
                header: "",
                id: "none2",
            }),
            columnHelper.display({
                header: "",
                id: "none3",
            }),
            columnHelper.display({
                header: "",
                id: "none4",
            }),
            columnHelper.display({
                header: "",
                id: "none5",
            }),
            columnHelper.accessor("uniqeID", {
                header: "",
                id: "uniqeID",
            }),
            columnHelper.accessor("employeeID", {
                header: "Employee ID",
                id: "employeeID",
                cell: ({ getValue, cell }) => {
                    const name = getValue();
                    return (
                        <div className="max-w-[160px] min-w-[140px] text-left font-normal">
                            <EditableCell
                                setData={setData}
                                value={name}
                                type="text"
                                cell={cell}
                                customDesign="tooltip"
                            />
                        </div>
                    );
                },
            }),
            columnHelper.accessor("employeeName", {
                header: "Employee  Name",
                id: "employeeName",
                cell: ({ getValue, cell }) => {
                    const year = getValue();
                    return (
                        <div className="max-w-[260px] min-w-[240px] text-left font-normal">
                            <EditableCell
                                setData={setData}
                                value={year}
                                IsDisabled={true}
                                type="text"
                                cell={cell}
                                isYear={true}
                            />
                        </div>
                    );
                },
            }),
            columnHelper.accessor("jobTitle", {
                header: "Job Title",
                id: "jobTitle",
                cell: ({ getValue, cell }) => {
                    const timeIn = getValue();
                    return (
                        <div className="max-w-[132px] min-w-[110px] text-left font-normal">
                            <EditableCell
                                setData={setData}
                                value={timeIn}
                                type="text"
                                cell={cell}
                            />
                        </div>
                    );
                },
            }),
            columnHelper.accessor("department", {
                header: "Department",
                id: "department",
                cell: ({ getValue, cell }) => {
                    const dptment = getValue();
                    var departView = departmentD;
                    var departmentD = departmentData?.find((x) => x.id == dptment);
                    if (departmentD == undefined) {
                        departView = departmentData?.find((x) => x.name == dptment);
                    } else {
                        departView = departmentD;
                    }
                    const originalValues = cell.row.original?.role?.map(
                        (role: baseObj) => role.id
                    );
                    const isNew = cell.row.original?.isNew;
                    return (
                        <div
                            className={classNames(
                                "max-w-full min-w-[180px] text-left h-full",
                                styles.customEmployeeDropdown
                            )}
                        >
                            <EditableCell
                                className="py-0"
                                hasPadding={true}
                                setData={setData}
                                value={departView ? departView.name : "Select"}
                                type="custom"
                                cell={cell}
                                customDesign="tooltip"
                                CustomComponent={
                                    <CustomDropdownWithAddButton
                                        addNewItemData={addDepartmentData}
                                        initialValues={originalValues}
                                        dataList={departmentData ? departmentData : []}
                                        setDataList={setDepartmentData}
                                        title="Department"
                                        cell={cell}
                                        data={data}
                                        setData={setData}
                                        setDeleteID={setDeleteDeptID}
                                        setOpenDeleteopup={setOpenDeleteopup}
                                        setSelectedData={setDepartment}
                                        selectedData={departView ? departView.name : "Select"}
                                        addTitle="Create New Department"
                                        isEditable={isNew}
                                    />
                                }
                            />
                        </div>
                    );
                },
            }),
            columnHelper.accessor("batchNo", {
                header: "Batch No.",
                id: "batchNo",
                cell: ({ getValue, cell }) => {
                    const timeOUt = getValue();
                    return (
                        <div className="max-w-[132px] min-w-[110px] text-left font-normal">
                            <EditableCell
                                setData={setData}
                                value={timeOUt}
                                type="text"
                                cell={cell}
                            />
                        </div>
                    );
                },
            }),
            columnHelper.accessor("reviewDate", {
                header: "Review Date",
                id: "reviewDate",
                cell: ({ getValue, cell }) => {
                    const otAmt = getValue();
                    return (
                        <div className=" font-normal max-w-[180px] min-w-[100px] text-left px-[10px] bg-white text-black2">
                            <p className="h-full flex items-center">
                                {otAmt}
                            </p>
                        </div>
                    );
                },
            }),
            columnHelper.accessor("evaluatedBy", {
                header: "Evaluated By",
                id: "evaluatedBy",
                cell: ({ getValue, cell }) => {
                    const otAmt = getValue();
                    return (
                        <div className=" font-normal max-w-[180px] min-w-[100px] text-left px-[10px] bg-white text-black2">
                            <p className="h-full flex items-center">
                                {otAmt}
                            </p>
                        </div>
                    );
                },
            }),
            columnHelper.accessor("relations", {
                header: "Relations",
                id: "relations",
                cell: ({ getValue, cell }) => {
                    const otAmt = getValue();
                    return (
                        <div className=" font-normal max-w-[180px] min-w-[100px] text-left px-[10px] bg-white text-black2">
                            <p className="h-full flex items-center">
                                {otAmt}
                            </p>
                        </div>
                    );
                },
            }),

            columnHelper.display({
                header: "",
                id: 'lasted',
            }),
            columnHelper.display({
                header: "Action",
                id: 'actions',
                cell: ({ cell }) => {
                    var isHaveNew = data?.find(x => x.isNew == true);
                    var disabledClass = isHaveNew ? 'opacity-50 pointer-events-none' : '';
                    const url = '/performance-appraisal-detail';
                    return (
                        <div
                            className={classNames(
                                styles.actionedBtn,
                                "px-2 flex justify-center items-center min-w-max max-w-[98px]"
                            )}
                        >
                            <button className='w-8 h-8 mr-2 flex justify-center items-center group rounded-md bg-[#8950FC] hover:bg-[#EAE0FF] transition-all duration-300'>
                                <NavLink to={{ pathname: url }}>
                                    <ViewIcon width={16} height={16} className={classNames(disabledClass, "group-hover:fill-black2")} />
                                </NavLink></button>
                        </div>
                    );
                },
            }),
        ],
        [
            columnHelper,
            data,
            hiddenFields,
        ]
    );
    const visibleColumns: { [key: string]: boolean } = {};

    hiddenFields?.map((x) => {
        visibleColumns[x] = true;
    });

    // if (checkManage) {
    //     visibleColumns['actions'] = true
    // } else {
    //     visibleColumns['actions'] = false
    // }

    if (hiddenFields.length > 0) {
        columns.map((x) => {
            if (x.id == "actions" || x.id == "selection" || x.id == "lasted") {
                visibleColumns[x.id ? x.id : ""] = true;
            } else if (x.id == "uniqeID") {
                visibleColumns[x.id ? x.id : ""] = false;
            } else {
                var isHidden = hiddenFields.find((h) => h == x.id);
                if (isHidden) {
                    visibleColumns[x.id ? x.id : ""] = true;
                } else {
                    visibleColumns[x.id ? x.id : ""] = false;
                }
            }
        });
    }
    const [columnOrder, setColumnOrder] = useState<string[]>(
        columns.map((column: ColumnDef<any, any>) => column.id as string)
    );
    const deleteOTItem = useCallback(
        (id?: number, dataId?: number) => {
            const selectedRows = Object.keys(rowSelection);
            setData((prev: baseObj[]) => {
                if (dataId != undefined) {
                    // var $dataId = dataId;
                    deleteEmployee(dataId)
                        .unwrap()
                        .then((payload: any) => {
                            // console.log("p", payload)
                            if (payload.status) {
                                setOpenDeleteopup(false)
                                setSuccessPopup(true)
                                setMessage(payload ? [payload?.message] : ['Successfully Delete.']);
                                toast('Successfully Delete.', {
                                    position: "top-right",
                                    autoClose: 2000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                    type: "success",
                                });
                            }
                        })
                        .catch((error: any) => {
                            // console.log("e", error)
                            setErrorPopup(true)
                            setOpenDeleteopup(false)
                            setMessage(error ? [error?.data?.message] : ["Something went wrong!Trying again."])
                        })
                }
                return prev.filter((_, index) => {
                    return id !== undefined
                        ? id !== index
                        : !selectedRows.includes(String(index));
                });
            });
            id === undefined && setRowSelection({});
        },

        [setData, rowSelection]
    );
    const deleteOTDataMultiple = (ids: number[]) => {
        deleteEmployeeByIDs(ids);
        setRowSelection({});
    };
    const confirmDeleteData = () => {
        var id = deleteID;
        var dataID = deleteRowID;
        const selectedRows = Object.keys(rowSelection);
        var selectionIds = data
            .filter((x, index: number) => {
                return selectedRows.find((s) => s == index.toString()) ? x.uniqeID : 0;
            })
            .map((x) => x.uniqeID);
        if (selectionIds.length > 0) {
            deleteOTDataMultiple(selectionIds);
        } else {
            if (id) {
                deleteOTItem(id, dataID);
                setDeleteID(-1);
                setDeleteRowID(-1);
            }
            setOpenDeleteopup(false);
        }
    };
    return <div className={classNames(styles.payRollTable, 'relative')}>
        <LayoutAdjustableTable
            saveTemplateData={saveTemplateData}
            hideColumn={visibleColumns}
            columns={columns}
            data={data}
            sorting={sorting}
            setSortingState={setSorting}
            rowSelection={rowSelection}
            setRowSelection={setRowSelection}
            columnOrder={columnOrder}
            setColumnOrder={setColumnOrder}
            unDraggableFields={["actions", "selection", "id"]}
            theaderColor="text-graydark"
            isLayoutControlOpen={isLayoutControlOpen}
            setIsLayoutControlOpen={setIsLayoutControlOpen}
            label="OT List"
            setItemCount={setItemCount}
            pagination={pagination}
            setPagination={setPagination}
            globalFilter={globalSearch}
            setGlobalFilter={setGlobalSearch}
            columnFilters={columnFilters}
            setColumnFilters={setColumnFilters}
            noPaddingCells={[

            ]}
            localModuleId={localModuleId}
            visArray={visArray}
            setIsTemplate={setIsTemplate}
            setCols={setCols}

        />
        {selectRowsCount > 0 && (
            <div className="flex items-center text-14 leading-5 font-semibold text-black2 mt-8 mb-10 lg:mt-10 lg:mb-12 ">
                <p>{selectRowsCount} selected.</p>
                <button
                    type="button"
                    onClick={() => setOpenDeleteopup(true)}
                    className=" flex items-center ml-5 text-12 leading-[18px] font-semibold text-redPink"
                >
                    <div className="w-6 h-6 bg-redPink flex items-center group justify-center rounded-md mr-[10px] hover:bg-[#FFEAEF]  transition-all duration-300 ">
                        <TrashIcon width={10} height={12} />
                    </div>
                    Delete
                </button>
            </div>
        )}
        <div className="pt-6">
            <TablePaginator
                total={itemCount}
                pagination={pagination}
                setPagination={setPagination}
                itemCount={total}
                setCurrentPage={setCurrentPage}
                currentPageDefault={currentPage}
                changePage={changePage}
            />
        </div>
        <DeletePopup
            descText="Are you sure to delete?"
            confirmBtnText="Yes, delete."
            show={openDeletePopup}
            setShow={setOpenDeleteopup}
            confirmDeleteData={confirmDeleteData}
        />
    </div>
}
export default PerformanceAppraisalTable;