import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { StringDropdownList } from "../common/DropdownList";
import TimePicker from "rc-time-picker";
import { CloseIcon } from "../common/Icons";
import styles from "./WorkingShiftTable.module.scss";
import moment from "moment";
import { baseObj } from "../../utils/constants";
import classNames from "classnames";

const format = "HH:mm:ss";
//a
const workingAMPM = ["AM", "PM"];
const defaultTime = "00:00:00";

const now = moment(defaultTime).hour(0).minute(0).second(0);

type TWorkingShiftListProps = {
  dayBeakWork: string[];
  work: any;
  handleDelete: (id: any) => void;
  setData: Dispatch<SetStateAction<any[]>>;
  isSubmit: boolean;
  nextTagsData: number;
};
const WorkingShiftList = ({
  dayBeakWork,
  work,
  handleDelete,
  setData,
  isSubmit,
  nextTagsData,
}: TWorkingShiftListProps) => {

  // console.log("now", now);


  const [workDayBreak, setWorkDayBreak] = useState<string>("");
  const [firstAMPM, setFirstAMPM] = useState<string>("AM");
  const [secondAMPM, setSecondAMPM] = useState<string>("PM");

  const [firstHours, setFirstHours] = useState<string>("");
  const [firstMinutes, setFirstMinutes] = useState<string>("");
  const [firstseconds, setFirstSeconds] = useState<string>("");
  const [secondHours, setSecondHours] = useState<string>("");
  const [secondMinutes, setSecondMinutes] = useState<string>("");
  const [secondSeconds, setSecondSeconds] = useState<string>("");

  // console.log("f-work", work);
  // console.log("workDayBreak", workDayBreak);
  // console.log("all", secondHours, secondMinutes);

  const haldleLocationChange = (value: any) => {
    setWorkDayBreak(value);
    setData((prev: baseObj) => {
      return prev.map((data: any) => {
        if (data.key === work.key) {
          return { ...data, location: value };
        }
        return data;
      });
    });
  };

  const handleFirstAMPMChange = (value: any) => {
    setFirstAMPM(value);
    setData((prev: baseObj) => {
      return prev.map((data: any) => {
        if (data.key === work.key) {
          return { ...data, firstAMPM: value };
        }
        return data;
      });
    });
  };
  const handleSecondAMPMChange = (value: any) => {
    setSecondAMPM(value);
    setData((prev: baseObj) => {
      return prev.map((data: any) => {
        if (data.key === work.key) {
          return { ...data, secondAMPM: value };
        }
        return data;
      });
    });
  };

  function onChangeDateStart(value: any) {
    // console.log("start", value && value.format(format) );
    //&& value.format(format)
    // setFirstClock(value && value.format(format));
    setData((prev: baseObj) => {
      return prev.map((data: any) => {
        if (data.key === work.key) {
          return { ...data, firstClock: value && value.format(format) };
        }
        return data;
      });
    });
  }
  function onChangeDateEnd(value: any) {
    // setSecondClock(value && value.format(format));
    setData((prev: baseObj) => {
      return prev.map((data: any) => {
        if (data.key === work.key) {
          return { ...data, secondClock: value && value.format(format) };
        }
        return data;
      });
    });
  }


  useEffect(() => {
    setFirstAMPM(work.firstAMPM);
    setSecondAMPM(work.secondAMPM);
    setWorkDayBreak(work.location);
    // setFirstClock(work.firstClock);
    setFirstHours(String(work.firstClock).split(":")[0]);
    setFirstMinutes(String(work.firstClock).split(":")[1]);
    setFirstSeconds(String(work.firstClock).split(":")[2]);
    setSecondHours(String(work.secondClock).split(":")[0]);
    setSecondMinutes(String(work.secondClock).split(":")[1]);
    setSecondSeconds(String(work.secondClock).split(":")[2]);
  }, [work]);

  const handleClearClickFirst = () => {
    setData((prev: baseObj) => {
      return prev.map((data: any) => {
        if (data.key === work.key) {
          return { ...data, firstClock: "00:00:00"};
        }
        return data;
      });
    });

  };

  const handleClearClickSecond = () => {
    setData((prev: baseObj) => {
      return prev.map((data: any) => {
        if (data.key === work.key) {
          return { ...data, secondClock: "00:00:00"};
        }
        return data;
      });
    });

  };

  return (
    <div className="flex items-center ">
      <div className="min-w-[200px] max-w-[250px] mt-1">
        <StringDropdownList
          state={workDayBreak}
          setState={haldleLocationChange}
          placeholder="Select"
          className="w-full py-3 pl-3 mb-0 text-14"
          dropdownItems={dayBeakWork?.map((status: any) => ({
            value: status,
            label: status,
          }))}
          textAlign={"text-left"}
          fontSize="xl:text-15 text-12"
        />
        <p
          className={classNames(
            isSubmit && workDayBreak === "" && nextTagsData === 2 ? "" : "opacity-0",
            "mb-0 font-poppins text-12 text-redPink"
          )}
        >
          Working Days is required
        </p>
      </div>
      <div className="pl-5 mb-3 flex justify-between items-center working_shift_time_clear">
        <TimePicker
          showSecond={false}
          value={moment()
            .hours(Number(firstHours))
            .minute(Number(firstMinutes))
            .second(Number(firstseconds))
          }
          defaultValue={now}
          className={styles.customTimePickerInput}
          onChange={onChangeDateStart}
          format={format}
          use12Hours={false}
          popupClassName="z-[9999999]"
          allowEmpty={false}
          addon={() => <button className="p-1 " onClick={handleClearClickFirst}>Clear</button>}
        />
        <span className="px-5">-</span>
        <TimePicker
          showSecond={false}
          value={moment()
            .hours(Number(secondHours))
            .minute(Number(secondMinutes))
            .second(Number(secondSeconds))
          }
          defaultValue={now}
          className={styles.customTimePickerInput}
          onChange={onChangeDateEnd}
          format={format}
          use12Hours={false}
          popupClassName="z-[9999999]"
          allowEmpty={false}
          addon={() => <button className="p-1 " onClick={handleClearClickSecond}>Clear</button>}
        />
        
      </div>
      <div
        className="ml-3 mt-1 cursor-pointer"
        onClick={() => handleDelete(work.key)}
      >
        <CloseIcon color="#EE436D" width={16} height={16} />
      </div>
    </div>
  );
};

export default WorkingShiftList;

