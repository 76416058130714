import {
  ColumnFiltersState,
  PaginationState,
  SortingState,
} from "@tanstack/react-table";
import classNames from "classnames";
import { useCallback, useEffect, useMemo, useState } from "react";
import ExportUsersModal from "../../components/BackendUsers/ExportUsersTable";
import ImportUsersModal from "../../components/BackendUsers/ImportUsersModal";
import Button from "../../components/common/Button";
import styles from "./Equipments.module.scss";
import { NumberDropdownList } from "../../components/common/DropdownList";
import {
  AddIcon,
  CloseIcon,
  CustomizeLayoutIcon,
  ExportIcon,
  ImportIcon,
  LoadingIcon,
  SearchIcon,
} from "../../components/common/Icons";
import DebounceSearchInput from "../../components/Tables/DebounceSearchInput";
import { baseObj } from "../../utils/constants";
import {
  checkedIsNew,
  useDeleteEmployeeMutation,
  useDeleteEmpMutation,
  // useGetDesignationListQuery,
  useGetEmployeeDataQuery,
  useGetEmployeeFilterDataQuery,
} from "../../app/services/employee";
import { StringDropdownListWithDeleteBtn } from "../../components/common/StringDropdownListWithDeleteBtn";
//import { callback } from "chart.js/dist/helpers/helpers.core";
import { hideAllNoneField } from "../../components/Tables/TablePaginator";
import { getAuthUser } from "../../app/services/dashboard";
import { useGetByModuleTemplateQuery } from "../../app/services/template";
import { templateList } from "../../active-time-report/views/ActiveTimeReport";
import axios from "../../axios";
import { endpointUrl } from "../../app/services/api";
import ErrorPopup from "../../components/ErrorPopup/ErrorPopup";
import { NavLink, useNavigate } from "react-router-dom";
import { FetchBaseError } from "../../components/common/FetchBaseQueryError";
import CustomScrollbar from "../../components/CustomScrollbar/CustomScrollbar";
import CustomDropdownListForFilter from "../../components/EmployeeTable/CustomDropdownListForFilter";
import { useSpring, animated } from "react-spring";
import CommonTableSkeleton from "../../components/TableSkeleton/CommonTableSkeleton";
import { toast } from "react-toastify";
import EquipmentTable from "../../components/EquipmentTable/EquipmentTable";
import DatePopupFoFilter from "../../components/EmployeeTable/DatePopupFoFilter";
import EquipmentPopup from "./EquipmentPopup";
import {
  EquipmentObjectKeys, IEquipmentApp, useApplyEquipmentMutation, useApproveEquipmentMutation, useDeleteEquipmentMutation,
  useEditEquipmentApplicationMutation, useGetEquipmentApplicationsQuery, useGetEquipmentsFilterDataQuery, useGetEquipmentsQuery, useRejectEquipmentMutation
} from "../../app/services/equipments";
import { twMerge } from "tailwind-merge";
import EquipmentApplicationTable from "../../components/EquipmentTable/EquipmentApplicationTable";
import EquipmentApplicationPopup from "./EquipmentApplicationPopup";
import EquipmentViewPopup from "./EquipmentViewPopup";
import EquipmentLogTable from "../../components/EquipmentTable/EquipmentLogTable";
import { useCreateTagMutation } from "../../app/services/annoucement-sheet";
import authHeader from "../../app/services/auth-header";
import CommonErrorPopup from "../../components/common/CommonErrorPopup";

const dummyTags = [
  {
    id: 1,
    name: "Tag1"
  },
  {
    id: 2,
    name: "Tag2"
  }
];
const dummyCategories = [
  {
    id: 1,
    name: "Computer"
  },
  {
    id: 2,
    name: "Category2"
  }
];
const dummyLocations = [
  {
    id: 1,
    name: 'Malaysia VO KL Branch'
  },
  {
    id: 2,
    name: "Location2"
  }
];
const dummyDepartments = [
  {
    id: 1,
    name: "Department1"
  },
  {
    id: 2,
    name: "Department2"
  }
];
const dummyEmployees = [
  {
    id: 1,
    name: "Ivan"
  },
  {
    id: 2,
    name: "Adam"
  },
  {
    id: 3,
    name: "Suzy"
  },
  {
    id: 4,
    name: "Sandy"
  }
];
const dummyStatus = [
  {
    id: 0,
    name: "all"
  },
  {
    id: 1,
    name: "Used"
  },
  {
    id: 2,
    name: "Damaged"
  },
  {
    id: 3,
    name: "New"
  }
]
interface IEquipmentReturnData {
  date: string,
  isOverdue: boolean
}
interface IEquipmentNameDate {
  name: string,
  date: string
}
export interface IEquipmentDateDuration {
  toDate: string,
  fromDate: string
}
export interface IEquipmentLog {
  id: number,
  assign_date: string,
  return_date: string,
  assign_to: string,
  responsible_person: string,
  return_proof_photo: string,
  return_remark: string,

}
export interface IEquipment {
  "id": number,
  "image": string;
  "code": string,
  "serialno": string,
  "category": EquipmentObjectKeys,
  "tags": EquipmentObjectKeys[],
  "name": string,
  "availablity": string,
  "condition": string,
  "location": EquipmentObjectKeys,
  "employeeID": string,
  "employee": EquipmentObjectKeys,
  "department": EquipmentObjectKeys,
  "date": string,
  "return_date": IEquipmentReturnData,
  "version": string,
  "service_provider": string,
  "pricing_detail": string,
  "warranty": IEquipmentDateDuration,
  "license": string,
  "license_date": IEquipmentDateDuration,
  "created_by": IEquipmentNameDate,
  "remark": string,
  "usage_duration": string,
  "application": string,
}
export interface IAppEquipment {
  "id": number,
  "equipment_id": string,
  "assign_date": string,
  "expected_return_date": string,
  "application_type": string,
  "reason": string,
  "employee_id": string,
  "department_id": string,
}
export interface IViewEquipment {
  image: string,
  name: string,
  tags: EquipmentObjectKeys[],
  "equipmentID": string,
  "serialno": string,
  "category": EquipmentObjectKeys,
  "availablity": string,
  "condition": string,
  "location": EquipmentObjectKeys,
  "employee": string,
  "department": string,
  "date": string,
  "return_date": string,
  "version": string,
  "service_provider": string,
  "pricing_detail": string,
  "warranty": IEquipmentDateDuration,
  "license": string,
  "license_date": IEquipmentDateDuration,
  "remark": string,
  "logs": IEquipmentLog[]
}
export interface IEquipmentLog {
  id: number;
  assign_date: string;
  return_date: string;
  assign_to: string;
  responsible_person: string;
  image: string;
  note: string;
}
export const dummyData = [
  {
    "id": 1,
    "image": '../img/equipment.png',
    "code": 'EQ0001',
    "serialno": '12380928412',
    "category": { id: 1, name: 'Computer' },
    "tags": [{ id: 1, name: 'Tag1' }, { id: 2, name: 'Tag2' }],
    "name": 'Designer Laptop 1',
    "availablity": 'Not Available',
    "condition": 'Used',
    "location": { id: 1, name: 'Malaysia VO KL Branch' },
    "employeeID": 'MY0011',
    "employee": { id: 1, name: 'Ivan' },
    "department": { id: 1, name: 'Department1' },
    "date": '19-04-2024',
    "expected_return_date": { date: '19-04-2024', isOverdue: true },
    "version": 'Version1',
    "service_provider": '-',
    "pricing_detail": 'MYR 8230',
    "warranty": { fromDate: '2023-12-31', toDate: '2025-12-31' },
    "license": 'Lifetime',
    "license_date": { fromDate: '2023-12-31', toDate: '2025-12-31' },
    "created_by": { name: 'Tina', date: '01-01-2024, 17:44:38' },
    "remark": 'OS of this laptop occurs bug sometime.',
    "application": 'Assign',
    "usage_duration": '2023-12-31',
    "logs": [
      {
        id: 1,
        assign_date: "31-12-2023",
        return_date: "31-12-2023",
        assign_to: "Ivan",
        responsible_person: "Tina",
        image: "../img/equipment-1.png",
        note: "Everything is fine.",
      },
      {
        id: 2,
        assign_date: "31-12-2023",
        return_date: "31-12-2023",
        assign_to: "Ivan",
        responsible_person: "Tina",
        image: "../img/equipment-1.png",
        note: "Everything is fine.",
      }
    ]
  }
]
const dummyApplicationData = [
  {
    "id": 1,
    "equipmentID": 'EQ0001',
    "serialno": '12380928412',
    "category": { id: 1, name: 'Computer' },
    "name": 'Designer Laptop 1',
    "employee": { id: 1, name: 'Ivan' },
    "application": 'Assign',
    "borrow_date": '19-04-2024',
    "return_date": { date: '19-04-2024', isOverdue: false },
    "assign_date": '19-04-2024',
    "reason": 'OS of this laptop occurs bug sometime.',
    "department": { id: 1, name: 'Department1' },
  }
]
const Equipments = () => {
  const [employeeID, setEmployeeID] = useState<string>("");
  const [status, setStatus] = useState("All");
  const [category, setCategory] = useState("All");
  const [isImportModalOpen, setIsImportModalOpen] = useState<boolean>(false);
  const [isExportModalOpen, setIsExportModalOpen] = useState<boolean>(false);
  const [isImporting, setIsImporting] = useState<boolean>(false);
  const [isExporting, setIsExporting] = useState<boolean>(false);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const [data, setData] = useState<baseObj[]>(dummyData);
  const [applicationData, setApplicationData] = useState<baseObj[]>(dummyApplicationData);
  const [equipmentLogs, setEquipmentLogs] = useState<IEquipmentLog[]>([]);
  // console.log("data", data);
  const [currentView, setCurrentView] = useState<any>(0);
  const [currentViewData, setCurrentViewData] = useState<number>(0);
  const [isTemplate, setIsTemplate] = useState<boolean>(false);
  const [viewCols, setCols] = useState<any>([]);
  const [createTag] = useCreateTagMutation();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const defaultFields = useMemo(
    () => [
      "code",
      "serialno",
      "category",
      "tags",
      "name",
      "availablity",
      "condition",
      "location",
      "employeeID",
      "employee",
      "department",
      "date",
      "expected_return_date",
      "version",
      "service_provider",
      "pricing_detail",
      "warranty",
      "license",
      "license_date",
      "created_by",
      "remark",
    ],
    []
  );
  const defaultApplicationFields = useMemo(
    () => [
      "equipment_code",
      "serialno",
      "category",
      "name",
      "employee",
      "application_type",
      'apply_date',
      "assign_date",
      "expected_return_date",
      "reason",
    ],
    []
  );
  const [globalSearch, setGlobalSearch] = useState<string>("");
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [isLayoutControlOpen, setIsLayoutControlOpen] =
    useState<boolean>(false);
  const [isLayoutControlAppOpen, setIsLayoutControlAppOpen] =
    useState<boolean>(false);
  const [itemCount, setItemCount] = useState<number>(data?.length);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  });
  const [curLocation, setCurLocation] = useState<string>('all');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [isEditing, setIsEdit] = useState<boolean>(false);
  const [successPopup, setSuccessPopup] = useState<boolean>(false);
  const [errorPopup, setErrorPopup] = useState<boolean>(false);
  const [message, setMessage] = useState<any>([]);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [currentID, setCurrentID] = useState<number>(0);
  const {
    data: equipments,
    isLoading,
    isFetching,
    error: employeeError,
  } = useGetEquipmentsQuery({
    category: curLocation == "all" ? category : 'all',
    status: curLocation == "all" ? status : 'all',
    limit: curLocation == "all" ? pagination.pageSize : 1,
    page: curLocation == "all" ? currentPage : 1,
    search: curLocation == "all" ? globalSearch : '',
    tab: curLocation
  });
  const {
    data: equipmentsApp,
  } = useGetEquipmentApplicationsQuery({
    category: curLocation == "application" ? category : 'all',
    status: curLocation == "application" ? status : 'all',
    limit: curLocation == "application" ? pagination.pageSize : 1,
    page: curLocation == "application" ? currentPage : 1,
    search: curLocation == "application" ? globalSearch : '',
    tab: curLocation
  });
  const { data: equipmentFilterData } = useGetEquipmentsFilterDataQuery();
  // const { data: empDesignData } = useGetDesignationListQuery();
  const [isEncrypt, setIsEncrypt] = useState<boolean>(true);
  const [tempList, setTempList] = useState<any>(templateList);
  // const [deleteTemplate] = useDeleteTemplateDataMutation();
  const [deleteEmployees] = useDeleteEmployeeMutation();
  const [deleteEmp] = useDeleteEmpMutation();
  const [hiddenFields, setHiddenFields] = useState<string[]>([]);
  // const { data: templateList } = useGetTemplateListQuery();
  let [localModuleId, setLocalModuleId] = useState<number>(0);
  const { data: templateData } = useGetByModuleTemplateQuery(localModuleId);
  const [moduleList, setModuleList] = useState<any>([]);
  const [addNew, setAddNew] = useState<boolean>(true);
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  const [tagsData, setTagsData] = useState<EquipmentObjectKeys[]>(dummyTags);
  const [categories, setCategories] = useState<EquipmentObjectKeys[]>(dummyCategories);
  const [locations, setLocations] = useState<EquipmentObjectKeys[]>(dummyLocations);
  const [departments, setDepartments] = useState<EquipmentObjectKeys[]>(dummyDepartments);
  const [statusList, setStatusList] = useState<EquipmentObjectKeys[]>(dummyStatus);
  const [employeeList, setEmployeeList] = useState<EquipmentObjectKeys[]>(dummyEmployees);
  const [editedData, setEditedData] = useState<IEquipment | undefined>();
  const [appEditedData, setAppEditedData] = useState<IAppEquipment | undefined>();
  const [applyEditedData, setApplyEditedData] = useState<IAppEquipment | undefined>();
  const [viewData, setViewData] = useState<IViewEquipment | undefined>();

  const [isViewOpen, setIsViewOpen] = useState<boolean>(false);
  const [appIsOpen, setAppIsOpen] = useState<boolean>(false);
  const [applyIsOpen, setApplyIsOpen] = useState<boolean>(false);
  const [appLoading, setAppLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<any>([]);
  const [applyEquipment] = useApplyEquipmentMutation();
  const [deleteEquipment] = useDeleteEquipmentMutation();
  const [approveEquipment] = useApproveEquipmentMutation();
  const [rejectEquipment] = useRejectEquipmentMutation();
  const [openDeletePopup, setOpenDeleteopup] = useState<boolean>(false);
  const [clickLoading, setClickLoading] = useState<boolean>(false);
  const [applyLoading, setApplyLoading] = useState<boolean>(false);
  const [rejectLoading, setRejectLoading] = useState<boolean>(false);
  const [isShowError, setIsShowError] = useState<boolean>(false);
  const [openReject, setOpenReject] = useState<boolean>(false);
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [editEquipmentApplication] = useEditEquipmentApplicationMutation();
  const [isOpenReturnPopup, setIsOpenReturnPopup] = useState<boolean>(false);
  const [isReturn, setIsReturn] = useState<boolean>(false);
  const [isApply, setIsApply] = useState<boolean>(false);
  const [applicationList,setApplicationList]=useState<any>([]);
  var getUser = getAuthUser();
  const getAuthPermis: string[] = useMemo(() => [], []);
  const getEquipmentsApplication = async () => {
    setAppLoading(true);
    await axios(`${endpointUrl}equipment-applications?status=${status}&category=${category}&limit=${pagination.pageSize}&page=${currentPage}&search=${globalSearch}&sort=`,
      {
        method: "GET",
        headers: authHeader(),
        data: {},
      }).then((res) => {
        setApplicationList(res?.data?.equipment_applications)
        setApplicationData(res?.data?.equipment_applications?.data);
        setLocalModuleId(res?.data?.equipment_applications.module_id)
        setItemCount(res?.data?.equipment_applications.total);
        setAppLoading(false);
      })
  }
  const getEquipmentsLogs = async (id: number) => {
    setAppLoading(true);
    await axios(`${endpointUrl}equipments/${id}/logs`,
      {
        method: "GET",
        headers: authHeader(),
        data: {},
      }).then((res) => {
        setEquipmentLogs(res?.data?.equipment_applications);
      })
  }
  if (getUser?.permissions) {
    getUser?.permissions?.forEach((per: any) => {
      getAuthPermis.push(per?.name);
    });
  }

  const importEmployee = async (formSaveData: any) => {
    await axios(endpointUrl + "importEmployees", {
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${getUser.token}`,
      },
      data: formSaveData,
    })
      .then((res) => {
        setSuccessPopup(true);
        setMessage(["Successfully import."]);

        axios
          .get(
            `${endpointUrl}employeeListData?department=All&nationality=All&status=All&limit=20&page=1&search=`,
            {}
          )
          .then((result) => {
            var empUpdatedData = result.data;
            setData(empUpdatedData.employeeList?.data);
            setIsImporting(true);
            setIsImportModalOpen(false);
            toast("Successfully Import.", {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              type: "success",
            });
          });
      })
      .catch((error: any) => {
        setErrorPopup(true);
        setMessage(
          error?.response
            ? [error?.response?.data?.message]
            : ["Something wrong!. Trying again."]
        );
        setIsImportModalOpen(false);
      });
  };
  useEffect(() => {
    if (templateData) {
      var updatedTemplate = templateData?.templates.map((x: any, i) => {
        return { ...x, index: i + 1 };
      });
      var defaultTemplate = {
        id: -1,
        index: 0,
        module: 0,
        name: "All",
      };
      setModuleList([defaultTemplate, ...updatedTemplate]);
    }
  }, [templateData?.templates, templateData]);

  useEffect(() => {
    if (templateList) {
      setTempList(templateList);
    }
    var viewData = templateData?.templates.find(
      (x) => x.id.toString() === currentView.toString()
    );
    setHiddenFields(viewData ? [...viewData.viewColumns] : (curLocation == 'all' ? defaultFields : defaultApplicationFields));

    document.querySelectorAll("th,td").forEach((x) => {
      if (x.id.includes("none")) {
        x.classList.add("hidden");
      }
      if (x.id.includes("action")) {
        x.classList.add("absolute");
        x.classList.add("right-0");
      }
      x.classList.remove("absolute");
      x.classList.add("left-0");
      x.classList.add("relative");
      var div = document.getElementById(x.id);
      if (div) {
        div.style.setProperty("left", "");
      }
    });
  }, [currentView, defaultFields, defaultApplicationFields, curLocation]);

  useEffect(() => {
    if (viewCols?.view_columns?.length > 0) {
      setHiddenFields(viewCols?.view_columns);
    }
  }, [viewCols?.view_columns]);

  useEffect(() => {
    if (moduleList) {
      var lastesTemplate = moduleList[moduleList?.length - 1];
      setCurrentViewData(lastesTemplate ? Number(lastesTemplate?.id) : 0);
    }
  }, [moduleList]);

  const downloadSampleFile = () => {
    axios({
      url: endpointUrl + "downloadEmployeeSample",
      method: "GET",
      responseType: "blob",
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "sampleEmployee.xlsx");
      document.body.appendChild(link);
      link.click();
      setIsDownloading(false);
    });
  };

  const exportEmployeeData = (format: string, isChecked: string) => {
    axios({
      url:
        endpointUrl +
        `equipments?limit=${pagination.pageSize}&category=${category}&page=${currentPage}&status=${status}&startDate=${startDate}&endDate=${endDate}&export=true&only_this_page=${isChecked}&format=` +
        format +
        "",
      method: "GET",
      responseType: "blob",
      headers: {
        Authorization: `Bearer ${getUser.token}`,
      },
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          "employees." + (format === "excel" ? "xlsx" : format)
        );
        document.body.appendChild(link);
        link.click();
        setIsExporting(false);
        setIsExportModalOpen(false);
      })
      .catch((error: any) => {
        setErrorPopup(true);
        setMessage(
          error?.message
            ? [error?.message]
            : ["Something wrong!. Trying again."]
        );
        setIsImportModalOpen(false);
      });
  };

  const sampleImportCSV = () => {
    downloadSampleFile();
  };

  const deleteTemplateData = (id: string) => {
    var filterTempList = templateList?.filter((x) => x.id !== parseInt(id));
    setTempList(filterTempList ? filterTempList : []);
    // deleteTemplate(parseInt(id));
  };
  var disabledData = checkedIsNew(data);

  useEffect(() => {
    setCurrentPage(1);
  }, [globalSearch, status, category, startDate, endDate, pagination.pageSize]);

  useEffect(() => {
    if (successPopup) {
      toast("Done Successfully.", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        type: "success",
      });
    }

    setTimeout(() => {
      setSuccessPopup(false);
    }, 2000);
  }, [successPopup]);

  const props = useSpring({
    to: { opacity: 1 },
    from: { opacity: 0 },
    delay: 1000,
  });

  const changeNavTab = (id: string) => {
    setCurLocation(id);
  }
  // useEffect(() => {
  //   if (curLocation != 'application') {
  //     getEquipmentsApplication();
  //   }
  // }, [curLocation, category, status])
  useEffect(() => {
    if (equipmentFilterData) {
      setLocations(equipmentFilterData.working_locations);
      setEmployeeList(equipmentFilterData.employees);
      setTagsData(equipmentFilterData.tags);
      setCategories(equipmentFilterData.equipment_categories)
      setDepartments(equipmentFilterData.departments)
    }
  }, [equipmentFilterData])
  useEffect(() => {
    if (equipments) {
      setData(equipments.equipments.data);
      setLocalModuleId(equipments.module_id)
      setItemCount(equipments.equipments.total);
    }
  }, [equipments])
  useEffect(() => {
    if (equipmentsApp) {
      setApplicationData(equipmentsApp.equipment_applications.data);
      setLocalModuleId(equipmentsApp.module_id);
      setItemCount(equipmentsApp.equipment_applications.total);
      setApplicationList(equipmentsApp.equipment_applications)
      console.log("applicationList ",equipmentsApp.equipment_applications)
    }
  }, [equipmentsApp])
  useEffect(() => {
    if (applicationList) {
      console.log("applicationList ",applicationList)
      setApplicationData(applicationList.data);
      setLocalModuleId(applicationList.module_id);
      setItemCount(applicationList.total);
    }
  }, [applicationList])
  const createEquipmentTag = (data: any) => {
    var tag = {
      name: data.type_name,
      flag: "equipment",
    };
    return createTag(tag);
  }
  const storeEquipmentData = async (bodyFormData: any, id: number) => {
    const url = (id == 0 ? endpointUrl + "equipments" : endpointUrl + "equipments/" + id);
    const method = (id == 0 ? "POST" : "PUT");
    await axios(url, {
      method: method,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${getUser.token}`,
        Accept: "application/json",
      },
      data: bodyFormData,
    }).then((res) => {
      if (res.data.status == true) {
        setIsOpen(false);
        toast(res.data.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          type: "success",
          theme: "light",
        });
        setData([...data, res.data.equipment])
      }
    }).catch((error) => {
      console.log('error ', error?.response?.data?.errors)
      setErrors(error?.response?.data?.errors)
    })
    setCurLocation('all')
  }
  const returnEquipment = async (bodyFormData: any, id: number) => {
    const url = endpointUrl + "equipments/" + id + '/return'
    const method = "POST";
    await axios(url, {
      method: method,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${getUser.token}`,
        Accept: "application/json",
      },
      data: bodyFormData,
    }).then((res) => {
      if (res.data.status == true) {
        setIsOpen(false);
        toast(res.data.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          type: "success",
          theme: "light",
        });
        setData([...data.map((x) => {
          if (x.id == id) {
            return res.data.equipment;
          }
          return x;
        })]);
        setIsOpenReturnPopup(false);
        setIsReturn(false);
      }
    }).catch((error) => {
      setErrors(error?.response?.data?.errors)
      setIsOpenReturnPopup(false);
      setIsReturn(false);
    })
    setCurLocation('all')
  }
  const deleteEquipmentData = (id: number) => {
    deleteEquipment(id).then((res: any) => {
      console.log('res ', res)
      if (res?.data?.status == true) {
        toast(res.data.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          type: "success",
          theme: "light",
        });
        setData(data.filter(x => x.id != id));
        setClickLoading(false);
        setOpenDeleteopup(false);
      }
    })
  }
  const applyEquipmentData = (data: IEquipmentApp) => {
    if (data.id == 0) {
      applyEquipment(data).then((res: any) => {
        if (res.data?.status == true) {
          toast(res.data.message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            type: "success",
            theme: "light",
          });
          setApplicationData([...applicationData, res.data.equipment_application]);
          setApplyIsOpen(false);
          setAppIsOpen(false);
          setApplyEditedData(undefined);
          setIsApply(false);
        }
      })
    } else {
      editEquipmentApplication(data).then((res: any) => {
        if (res.data?.status == true) {
          toast(res.data.message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            type: "success",
            theme: "light",
          });
          setApplicationData(applicationData?.map((x) => {
            if (x.id == res.data.equipment_application?.id) {
              return res.data.equipment_application;
            }
            return x;
          }));
          setApplyIsOpen(false);
          setAppIsOpen(false);
          setApplyEditedData(undefined);
          setIsApply(false);;
        }
      })
    }

  }
  const approveEquipmentData = (id: number) => {
    approveEquipment(id).then((res: any) => {
      if (res.data?.status == true) {
        toast(res.data.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          type: "success",
          theme: "light",
        });
        setOpenConfirm(false);
        setApplyLoading(false);
        setApplicationData([...applicationData.map((x) => {
          if (x.id == id) {
            return res.data.equipment_application;
          }
          return x;
        })])
      } else {
        console.log('else ', res)
      }
    }).catch((error) => {
      setOpenConfirm(false);
      setIsShowError(true)
      setMessage(error?.data?.mesage)
      setApplyLoading(false);
    })
  }
  const rejectEquipmentData = (id: number) => {
    rejectEquipment(id).then((res: any) => {
      if (res.data?.status == true) {
        toast(res.data.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          type: "success",
          theme: "light",
        });
        setOpenReject(false);
        setRejectLoading(false)
        setApplicationData([...applicationData.map((x) => {
          if (x.id == id) {
            return res.data.equipment_application;
          }
          return x;
        })])
      }
    }).catch((error) => {
      setOpenReject(false);
      setIsShowError(true)
      setMessage(error?.data?.mesage)
      setRejectLoading(false);
    })
  }
  useEffect(() => {
    setCategory('');
    setStatus('');
    setCurrentPage(1);
    setGlobalSearch('');
    setPagination({
      pageIndex: 1,
      pageSize: 20
    })
    if (curLocation == 'all') {
      if (equipments) {
        setLocalModuleId(equipments?.module_id);
        setItemCount(equipments.equipments.total);
      }

    }
    if (curLocation == 'application') {
      if (equipmentsApp) {
        setLocalModuleId(equipmentsApp.module_id);
        setItemCount(equipmentsApp.equipment_applications.total);
      }

    }
  }, [curLocation])
  return (
    <>
      {isLoading ? (
        <CommonTableSkeleton rows={12} />
      ) : (
        <>
          <animated.div style={props}>
            <CommonErrorPopup
              show={isShowError}
              setShow={setIsShowError}
              message={message}
            />
            <EquipmentPopup setIsOpen={setIsOpen} isOpen={isOpen}
              tags={tagsData}
              categories={categories}
              locations={locations}
              departments={departments}
              employees={employeeList}
              editedData={editedData}
              createEquipmentTag={createEquipmentTag}
              storeEquipment={storeEquipmentData}
              errors={errors}
            />
            <EquipmentApplicationPopup setIsOpen={curLocation == 'all' ? setApplyIsOpen : setAppIsOpen}
              isOpen={curLocation == 'all' ? applyIsOpen : appIsOpen}
              departments={departments}
              employees={employeeList}
              editedData={curLocation == 'all' ? applyEditedData : appEditedData}
              applyEquipmentData={applyEquipmentData}
              curLocation={curLocation}
              setIsApply={setIsApply}
              isApply={isApply}
            />
            <EquipmentViewPopup setIsOpen={setIsViewOpen}
              isOpen={isViewOpen}
              editedData={viewData}
              equipmentLogs={equipmentLogs}
            />
            <ImportUsersModal
              isOpen={isImportModalOpen}
              setIsOpen={setIsImportModalOpen}
              sampleCSV={sampleImportCSV}
              importEmployee={importEmployee}
              isImporting={isImporting}
              setIsImporting={setIsImporting}
              setIsDownloading={setIsDownloading}
              isDownloading={isDownloading}
            />

            <ExportUsersModal
              isOpen={isExportModalOpen}
              setIsOpen={setIsExportModalOpen}
              exportToCSV={exportEmployeeData}
              exportToExcel={exportEmployeeData}
              exportToPDF={exportEmployeeData}
              exportToZip={exportEmployeeData}
              isExporting={isExporting}
              setIsExporting={setIsExporting}
              isShowOnlyCheckbox={true}
            />

            <ErrorPopup
              descText="Error Message!"
              confirmBtnText="Yes, Close."
              show={errorPopup}
              setShow={setErrorPopup}
              message={message}
              setMessage={setMessage}
            />
            <div
              className={classNames(
                "px-2 sm:px-4 py-4 bg-white sm:mt-[25px] mt-0 font-poppins text-13 font-semibold text-[#858795] tracking-[-0.03rem] w-full h-full rounded-xl pt-3 pb-8 sm:pt-3 sm:pb-9 "
              )}
            >
              <div className="flex justify-start mb-6">
                <button onClick={() => changeNavTab('all')} className={classNames(curLocation == 'all' ? 'text-vorpblue' : 'text-vorpgraylight ', "text-14 font-semibold  mr-2 relative")}>
                  <span>All Equipment</span>
                </button>
                {equipmentFilterData?.is_admin == true ?
                  <button onClick={() => changeNavTab('application')} className={classNames(curLocation == 'application' ? 'text-vorpblue' : ' text-vorpgraylight', "text-14 font-semibold  mr-2 pl-2 relative border-l-1 border-l-borderGray")}>
                    <span>Equipment Application</span>
                  </button> : null}
              </div>
              <div className="flex flex-wrap justify-between items-center">
                <div className="flex items-center mr-2 xs:mr-4 sm:w-[260px] w-[225px] md:mb-1">
                  <DebounceSearchInput
                    setState={setGlobalSearch}
                    debounce={800}
                  >
                    {(setState) => (
                      <div className="font-normal flex border-1 border-[#C4C4C4] bg-white hover:bg-[#EFEFEF] rounded-[28px]">
                        <input
                          type="text"
                          placeholder="Search"
                          className="focus:outline-none py-1 px-4 text-12 xl:text-14 leading-5 bg-transparent rounded-[28px] w-full text-graydark"
                          onChange={(e) => setState(e.target.value)}
                        />
                        <SearchIcon className="mr-2" />
                      </div>
                    )}
                  </DebounceSearchInput>
                </div>
                <div className="flex flex-wrap justify-end items-center">
                  {curLocation == "all" ? (
                    <Button
                      // disabled={disabledData?.disabled}
                      label="+ Add New"
                      onClick={() => {
                        setIsOpen(true);
                        setEditedData(undefined)
                        setCurLocation('all')
                      }}
                      type="button"
                      variant="primary"
                      customClass={
                        // disabledData?.disabledClass +
                        " py-[3px] my-1 px-2 focus:outline-none hover:bg-vorpmyanmar hover:border-vorpmyanmar hover:text-vorpblue 2xl:text-[14px] text-12 leading-5 font-medium text-white"
                      }
                      size="base"
                    />
                  ) : null}
                </div>
              </div>

              <div className="flex sm:flex-row flex-col sm:justify-between justify-end items-center">
                <div className="flex w-full mt-1 md:mb-1 sm:mr-5 custom-scrollbar">
                  <CustomScrollbar isVertical={false} isHor={true}>
                    <div className="flex w-full items-center">
                      <div
                        className={classNames(
                          "flex items-center mr-[10px]  sm:w-auto w-full"
                        )}
                      >
                        <p className="hidden sm:w-auto w-1/2 text-left font-poppins font-medium xl:text-sm text-12 text-[#464E5F] mr-1">
                          Status
                        </p>
                        <CustomDropdownListForFilter
                          isFilter={true}
                          state={status}
                          placeholder="Status"
                          setState={setStatus}
                          dropdownItems={
                            statusList
                              ? statusList.map((st, id: number) => ({
                                value: st.name,
                                label: st.name,
                              }))
                              : []
                          }
                          textAlign="text-left"
                          className="max-h-[38px] max-w-full sm:max-w-[250px] 2xl:w-32 sm:w-28 w-full"
                        />
                      </div>
                      <div
                        className={classNames(
                          styles.permissionDropdown,
                          "flex items-center mr-[10px] sm:w-auto w-full"
                        )}
                      >
                        <p className="hidden sm:w-auto w-1/2 text-left font-poppins font-medium  xl:text-sm text-12 text-[#464E5F] mr-1">
                          Category
                        </p>
                        <CustomDropdownListForFilter
                          placeholder="Category"
                          state={category}
                          setState={setCategory}
                          dropdownItems={
                            categories
                              ? categories.map((dp) => ({
                                value: dp.id,
                                label: dp.name,
                              }))
                              : []
                          }
                          textAlign="text-left"
                          className="max-h-[38px] max-w-full sm:max-w-[250px] 2xl:w-32 sm:w-28 w-full"
                        />
                      </div>
                      {/* <div className="flex items-center w-auto border border-borderGray rounded-[39px] px-3">
                        <p className=" whitespace-nowrap sm:w-auto 2xs:w-[20%] w-1/2 text-left xl:text-sm text-12 leading-5 font-medium text-filtergray mr-4">
                          Key Date:
                        </p>
                        <div className="flex flex-row w-full">
                          {startDate && endDate && (
                            <button
                              type="button"
                              onClick={() => {
                                setStartDate(undefined);
                                setEndDate(undefined);
                              }}
                              className="text-12 mr-2"
                            >

                              <CloseIcon
                                color="#EE436D"
                                width={10}
                                height={10}
                              />
                            </button>
                          )}
                          <div className={` mr-[10px] htzxs:w-auto w-full`}>
                            <DatePopupFoFilter
                              placeholder="Start Date"
                              date={startDate}
                              setDate={setStartDate}
                              showBorder={false}
                              showIcon={false}
                            />
                          </div>
                          <span className="mx-2 font-normal text-filtergray text-sm">-</span>
                          <div className={`htzxs:w-auto w-full`}>
                            <DatePopupFoFilter
                              placeholder="End Date"
                              date={endDate}
                              setDate={(value) => setEndDate(value)}
                              showBorder={false}
                              showIcon={false}
                            />
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </CustomScrollbar>
                </div>
                <div className="flex justify-end mt-1 sm:w-auto w-full sm:flex-nowrap flex-wrap items-center">
                  <div className=" mb-1 flex items-center xl:ml-3 mr-[10px]">
                    <p className="base-label text-12 xl:text-14">DISPLAY</p>
                    <NumberDropdownList
                      state={pagination.pageSize}
                      setState={(value) =>
                        setPagination((prev) => ({
                          ...prev,
                          pageSize: value as number,
                        }))
                      }
                      dropdownItems={new Array(5)
                        .fill(1)
                        .map((_, index: number) => {
                          let display = (index + 1) * 20;
                          return {
                            value: display,
                            label: String(display),
                          };
                        })}
                      className="max-w-[55px] 4xs:max-w-[60px] lg:max-w-[72px]"
                      textAlign="text-left pl-3"
                      paddingClass="py-[2px]"
                      placeholder="20"
                    />
                  </div>

                  <div className="mb-1 flex justify-end">
                    <StringDropdownListWithDeleteBtn
                      haveDeleteBtn={true}
                      setIsTemplate={setIsTemplate}
                      state={isTemplate ? currentViewData : currentView}
                      setState={setCurrentView}
                      deleteTemplate={deleteTemplateData}
                      dropdownItems={moduleList.map((x: any, index: number) => {
                        return {
                          label: x.id === -1 ? x.name : "View #" + x.index,
                          value: x.id,
                        };
                      })}
                      className="min-w-[80px] 2xl:min-w-[128px] 6xs:text-12 2xl:w-32 w-24"
                      placeholder="View#1"
                      textAlign="text-left py-1 pl-2 pr-2"
                      custPadding="py-[2px]"
                      setSuccessPopup={setSuccessPopup}
                      setErrorPopup={setErrorPopup}
                      setMessage={setMessage}
                    />
                  </div>

                  <button
                    type="button"
                    onClick={() => { curLocation == "all" ? setIsLayoutControlOpen(true) : setIsLayoutControlAppOpen(true) }}
                    className="flex justify-center relative items-center rounded-lg bg-vorphongkongborder hover:bg-[#FFF0D2] w-[29px] h-[29px] ml-2 group transition-all duration-300 "
                  >
                    <CustomizeLayoutIcon />
                    <div className="absolute left-7 top-[-5px] border border-white w-4 h-4 rounded-full bg-vorphongkongborder flex justify-center items-center">
                      <AddIcon width={8} height={8} color="white" />
                    </div>
                  </button>
                </div>
              </div>
              <div className="text-left md:mt-0 mt-2">
                <animated.div style={props}>
                  {curLocation == 'all' ?
                    <EquipmentTable
                      setCurrentView={setCurrentView}
                      data={data}
                      setData={setData}
                      pagination={pagination}
                      setPagination={setPagination}
                      isLayoutControlOpen={isLayoutControlOpen}
                      setIsLayoutControlOpen={setIsLayoutControlOpen}
                      globalSearch={globalSearch}
                      setGlobalSearch={setGlobalSearch}
                      columnFilters={columnFilters}
                      setColumnFilters={setColumnFilters}
                      deleteEmployee={deleteEmp}
                      deleteEmployeeByIDs={deleteEmployees}
                      hiddenFields={hiddenFields ? hiddenFields : []}
                      setHiddenFields={setHiddenFields}
                      templateList={tempList}
                      localModuleId={localModuleId}
                      total={itemCount}
                      // changePage={changePage}
                      setCurrentPage={setCurrentPage}
                      currentPage={currentPage}
                      setIsTemplate={setIsTemplate}
                      setCols={setCols}
                      setIsEdit={setIsEdit}
                      isEditing={isEditing}
                      addNew={addNew}
                      setEmployeeID={setEmployeeID}
                      sorting={sorting}
                      setSorting={setSorting}
                      setEditedData={setEditedData}
                      setViewData={setViewData}
                      setIsOpen={setIsOpen}
                      setIsViewOpen={setIsViewOpen}
                      setApplyEditedData={setApplyEditedData}
                      setIsApplyOpen={setApplyIsOpen}
                      viewApplicationData={getEquipmentsApplication}
                      getEquipmentsLogs={getEquipmentsLogs}
                      applyEquipmentData={applyEquipmentData}
                      equipmentLogs={equipmentLogs}
                      deleteEquipmentData={deleteEquipmentData}
                      setOpenDeleteopup={setOpenDeleteopup}
                      openDeletePopup={openDeletePopup}
                      setClickLoading={setClickLoading}
                      clickLoading={clickLoading}
                      curLocation={curLocation}
                      setCurrentID={setCurrentID}
                      returnEquipment={returnEquipment}
                      isOpenReturnPopup={isOpenReturnPopup}
                      setIsOpenReturnPopup={setIsOpenReturnPopup}
                      setIsReturn={setIsReturn}
                      isReturn={isReturn}
                    /> : <div>
                      {
                        appLoading == true ? <div>
                          <p>Loading ...</p>
                        </div> :
                          <EquipmentApplicationTable
                            setCurrentView={setCurrentView}
                            data={applicationData}
                            setData={setApplicationData}
                            pagination={pagination}
                            setPagination={setPagination}
                            isLayoutControlOpen={isLayoutControlAppOpen}
                            setIsLayoutControlOpen={setIsLayoutControlAppOpen}
                            globalSearch={globalSearch}
                            setGlobalSearch={setGlobalSearch}
                            columnFilters={columnFilters}
                            setColumnFilters={setColumnFilters}
                            // orgDataList={empInfoData ? empInfoData.employeeList : []}
                            orgDataList={[]}
                            deleteEmployee={deleteEmp}
                            deleteEmployeeByIDs={deleteEmployees}
                            hiddenFields={hiddenFields ? hiddenFields : []}
                            setHiddenFields={setHiddenFields}
                            templateList={tempList}
                            localModuleId={localModuleId}
                            total={itemCount}
                            // changePage={changePage}
                            setCurrentPage={setCurrentPage}
                            currentPage={currentPage}
                            setIsTemplate={setIsTemplate}
                            setCols={setCols}
                            setIsEdit={setIsEdit}
                            isEditing={isEditing}
                            addNew={addNew}
                            setEmployeeID={setEmployeeID}
                            sorting={sorting}
                            setSorting={setSorting}
                            setEditedData={setAppEditedData}
                            setIsOpen={setAppIsOpen}
                            approveEquipmentData={approveEquipmentData}
                            rejectEquipmentData={rejectEquipmentData}
                            applyLoading={applyLoading}
                            rejectLoading={rejectLoading}
                            setApplyLoading={setApplyLoading}
                            setRejectLoading={setRejectLoading}
                            setOpenConfirm={setOpenConfirm}
                            openConfirm={openConfirm}
                            openReject={openReject}
                            setOpenReject={setOpenReject}
                          />
                      }
                    </div>
                  }
                </animated.div>
              </div>
            </div>
          </animated.div>
        </>
      )}
    </>
  );
};
export default Equipments;
