import classNames from "classnames";
import React, { Dispatch, FC, SetStateAction, useState } from "react";
import ChangePasswordPopup from "../ChangePasswordPopup/ChangePasswordPopup";
import LogoutPopup from "../LogoutPopup/LogoutPopup";
import styles from "./UserInfoPopup.module.scss";
interface IUserPopup {
  setShowChangePw: Dispatch<SetStateAction<boolean>>;
  setLogoutPopup: Dispatch<SetStateAction<boolean>>;
  setShow: Dispatch<SetStateAction<boolean>>;
  confirmEvent: any;
  saveChangePassword: any;
  changePassword: any;
  logout: any;
  showLogoutPopup: boolean;
  showChangePwPopup: boolean;
  errorMsg?: string;
}
const UserInfoPopup: FC<IUserPopup> = ({
  setShow,
  confirmEvent,
  saveChangePassword,
  changePassword,
  logout,
  showLogoutPopup,
  showChangePwPopup,
  setShowChangePw,
  setLogoutPopup,
  errorMsg,
}) => {
  return (
    <>
      <LogoutPopup
        show={showLogoutPopup}
        setShow={setLogoutPopup}
        confirmEvent={confirmEvent}
      />

      <ChangePasswordPopup
        show={showChangePwPopup}
        setShow={setShowChangePw}
        updateData={saveChangePassword}
        errorMsg={errorMsg}
      />
      
      <div
        className={classNames(
          styles.customInfoBox,
          "max-w-[153px] bg-white rounded-lg xl:right-9 right-4 relative"
        )}
      >
        <ul className="py-5 px-3">
          <li className="text-left mb-2 ">
            <a
              onClick={() => changePassword()}
              className="hover:text-vorpblue font-poppins font-normal text-sm text-graydark cursor-pointer"
            >
              Change password
            </a>
          </li>
          <li className="text-left">
            <a
              onClick={() => logout()}
              className="hover:text-vorpblue font-poppins font-normal text-sm text-graydark cursor-pointer"
            >
              Logout
            </a>
          </li>
        </ul>
      </div>
    </>
  );
};
export default UserInfoPopup;
