import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { StringDropdownList } from "../common/DropdownList";
import TimePicker from "rc-time-picker";
import { CloseIcon } from "../common/Icons";
import styles from "./WorkingShiftCustomise.module.scss";
import moment from "moment";
import { baseObj } from "../../utils/constants";
import classNames from "classnames";

const format = "HH:mm:ss";
const workingAMPM = ["AM", "PM"];
const now = moment().hour(0).minute(0);

type TWorkingShiftListProps = {
  dayBeakWork: any;
  locationList?: any;
  work: any;
  regionList?: any;
  handleDelete: (id: any) => void;
  setData: Dispatch<SetStateAction<any[]>>;
  isSubmit?: boolean;
};
const WorkingShiftCustomise = ({
  dayBeakWork,
  locationList,
  work,
  handleDelete,
  setData,
  isSubmit,
}: TWorkingShiftListProps) => {
  // console.log("now", now);

  const [location, setLocation] = useState<string>("");
  const [breakWork, setBreakWork] = useState<string>("");
  const [firstAMPM, setFirstAMPM] = useState<string>("AM");
  const [secondAMPM, setSecondAMPM] = useState<string>("PM");
  // const [firstClock, setFirstClock] = useState<any>(moment().hour(0).minute(0));
  // const [secondClock, setSecondClock] = useState<any>(moment().hour(0).minute(0));
  const [firstHours, setFirstHours] = useState<string>("");
  const [firstMinutes, setFirstMinutes] = useState<string>("");
  const [firstSecond, setFirstSecond] = useState<string>("");
  const [secondHours, setSecondHours] = useState<string>("");
  const [secondMinutes, setSecondMinutes] = useState<string>("");
  const [towSecond, setTowSecond] = useState<string>("");
  // console.log("f-work", work.firstClock);

  const haldleLocationChange = (value: any) => {
    setLocation(value);
    setData((prev: baseObj) => {
      return prev.map((data: any) => {
        if (data.key === work.key) {
          return { ...data, location: value };
        }
        return data;
      });
    });
  };
  const haldleBreakDayChange = (value: any) => {
    setBreakWork(value);
    setData((prev: baseObj) => {
      return prev.map((data: any) => {
        if (data.key === work.key) {
          return { ...data, status: value };
        }
        return data;
      });
    });
  };
  // const handleFirstAMPMChange = (value: any) => {
  //   setFirstAMPM(value);
  //   setData((prev: baseObj) => {
  //     return prev.map((data: any) => {
  //       if (data.key === work.key) {
  //         return { ...data, firstAMPM: value };
  //       }
  //       return data;
  //     });
  //   });
  // };
  // const handleSecondAMPMChange = (value: any) => {
  //   setSecondAMPM(value);
  //   setData((prev: baseObj) => {
  //     return prev.map((data: any) => {
  //       if (data.key === work.key) {
  //         return { ...data, secondAMPM: value };
  //       }
  //       return data;
  //     });
  //   });
  // };

  function onChangeDateStart(value: any) {
    // console.log("start", value && value.format(format));
    setData((prev: baseObj) => {
      return prev.map((data: any) => {
        if (data.key === work.key) {
          return { ...data, firstClock: value && value.format(format) };
        }
        return data;
      });
    });
  }
  function onChangeDateEnd(value: any) {
    // setSecondClock(value && value.format(format));
    setData((prev: baseObj) => {
      return prev.map((data: any) => {
        if (data.key === work.key) {
          return { ...data, secondClock: value && value.format(format) };
        }
        return data;
      });
    });
  }

  useEffect(() => {
    setLocation(work.location);
    setBreakWork(work.status);
    setFirstAMPM(work.firstAMPM);
    setSecondAMPM(work.secondAMPM);
    setFirstHours(String(work.firstClock).split(":")[0]);
    setFirstMinutes(String(work.firstClock).split(":")[1]);
    setFirstSecond(String(work.firstClock).split(":")[2]);
    setSecondHours(String(work.secondClock).split(":")[0]);
    setSecondMinutes(String(work.secondClock).split(":")[1]);
    setTowSecond(String(work.secondClock).split(":")[2]);
  }, [work]);

  const handleClearClick = () => {
    // Manually set the selected time to 00:00 when clear icon is clicked
    // setSelectedTime(null); // Reset to null or another initial value as needed
    setData((prev: baseObj) => {
      return prev.map((data: any) => {
        if (data.key === work.key) {
          return { ...data, firstClock: ("00:00:00") };
        }
        return data;
      });
    });

  };

  const handleClearClickSecond = () => {
    setData((prev: baseObj) => {
      return prev.map((data: any) => {
        if (data.key === work.key) {
          return { ...data, secondClock: ("00:00:00") };
        }
        return data;
      });
    });

  };


  return (
    <div className="flex items-center">
      <div className="flex flex-col min-w-[200px] max-w-[250px] mt-3 pr-2">
        <StringDropdownList
          state={location}
          setState={haldleLocationChange}
          placeholder="Locations"
          className="w-full py-3 pl-3 mb-0 text-14"
          dropdownItems={locationList?.map((status: any) => ({
            value: status.id,
            label: status.name,
          }))}
          textAlign={"text-left"}
          fontSize="xl:text-15 text-12"
        />
        <p
          className={classNames(
            isSubmit && location === "" ? "" : "opacity-0",
            "mb-0 font-poppins text-12 text-redPink"
          )}
        >
         Location is required
        </p>
      </div>
      <div className=" flex flex-col min-w-[150px] max-w-[200px] mt-3">
        <StringDropdownList
          state={breakWork}
          setState={haldleBreakDayChange}
          placeholder="Work-Break"
          className="w-full py-3 pl-3 mb-0 text-14 z-[99999]"
          dropdownItems={dayBeakWork?.map((status: any) => ({
            value: status,
            label: status,
          }))}
          textAlign={"text-left"}
          fontSize="xl:text-15 text-12"
        />
        <p
          className={classNames(
            isSubmit && breakWork === "" ? "" : "opacity-0",
            "mb-0 font-poppins text-12 text-redPink"
          )}
        >
          Work break is required
        </p>
      </div>
      <div className="pl-5 mb-2 flex justify-between items-center working_shift_time_clear">
        <TimePicker
          showSecond={false}
          value={moment()
            .hours(Number(firstHours))
            .minute(Number(firstMinutes))
            .second(Number(firstSecond))}
          defaultValue={now}
          className={styles.customTimePickerInput}
          onChange={onChangeDateStart}
          format={format}
          use12Hours={false}
          popupClassName="z-[9999999]"
          allowEmpty={false}
          addon={() => <button className="p-1 " onClick={handleClearClick}>Clear</button>} // Custom clear button
        />
        
        <span className="px-3">-</span>
        <TimePicker
          showSecond={false}
          value={moment()
            .hours(Number(secondHours))
            .minute(Number(secondMinutes))
            .second(Number(towSecond))}
          defaultValue={now}
          className={styles.customTimePickerInput}
          onChange={onChangeDateEnd}
          format={format}
          use12Hours={false}
          popupClassName="z-[9999999]"
          allowEmpty={false}
          addon={() => <button className="p-1 " onClick={handleClearClickSecond}>Clear</button>}
        />
       
      </div>
      <div
        className="ml-3 mt-1 cursor-pointer"
        onClick={() => handleDelete(work.key)}
      >
        <CloseIcon color="#EE436D" width={16} height={16} />
      </div>
    </div>
  );
};

export default WorkingShiftCustomise;

