import { FC, useEffect, useMemo, useState, useCallback } from "react";
import DebounceSearchInput from "../../components/Tables/DebounceSearchInput";
import { SearchIcon } from "../../components/common/Icons";
import classNames from "classnames";
import Button from "../../components/common/Button";
import { checkedIsNew } from "../../app/services/employee";
import { baseObj } from "../../utils/constants";
import {
  ColumnFiltersState,
  PaginationState,
  SortingState,
} from "@tanstack/react-table";
import { useSelector } from "react-redux";
import { templateList } from "../../active-time-report/views/ActiveTimeReport";
import { useSpring, animated } from "react-spring";
import { useGetByModuleTemplateQuery } from "../../app/services/template";
import { hideAllNoneField } from "../../components/Tables/TablePaginator";
import { getAuthUser } from "../../app/services/dashboard";
import { ToastContainer, toast } from "react-toastify";
import ErrorPopup from "../../components/ErrorPopup/ErrorPopup";
// import { useGetClaimTypeListQuery } from "../../app/services/claim-type";
import { RootState } from "../../app/store";
import WorkingShiftFilterComponent from "../../components/WorkingShift/WorkingShiftFilterComponent";
import WorkingShiftTable from "../../components/WorkingShift/WorkingShiftTable";
import WorkingShiftPopup from "../../components/WorkingShift/WorkingShiftPopup";
// import {
//   assignUsers,
//   useGetUserListAssignQuery,
// } from "../../app/services/password";
import ResuableToasty from "../../components/Toasty/ResuableToast";
import {
  useGetWorkingShiftFilterListQuery,
  useGetWorkingShiftListQuery,
} from "../../app/services/working-shift";
import {
  WorkingShiftCountry,
  WorkingShiftDepartment,
  WorkingShiftEmployee,
  WorkingShiftLocation,
} from "../../modals/working-shift/WorkingShift";

export interface IOTData {
  id: number;
  employeeID: string;
  employeeName: string;
  department: string;
  date: string;
  fullHalfDay: string;
  timeIn: string;
  timeOut: string;
  otAmt: string;
}

interface IOT {}

export interface IDepartment {
  id: number;
  name: string;
  label: string;
}

const defaultFields = [
  "code",
  "country",
  "name",
  "workingLocations",
  "working_days",
  "shift_timing",
  "working_hours_per_week",
  "employees",
  "remark",
  "actions",
];

export type TStatusListData = {
  id: number | string;
  name: string;
  label?: string;
};

const statusListData: TStatusListData[] = [
  {
    id: "All",
    name: "All",
  },
  {
    id: "standard",
    name: "Standard",
  },
  {
    id: "flexible",
    name: "Flexible",
  },
  {
    id: "customise",
    name: "Customise",
  },
];
const limitedData = [
  {
    id: "All",
    name: "All",
  },
  {
    id: 1,
    name: "Limited",
  },
  {
    id: 0,
    name: "Unlimited",
  },
];
const WorkingShift: FC<IOT> = () => {
  const [isOpenPopup, setIsOpenPopup] = useState<boolean>(false);
  const [isCreate, setIsCreate] = useState<boolean>(false);
  const [isDuplicate, setIsDuplicate] = useState<boolean>(false);
  // console.log("isDuplicate", isDuplicate);
  const [editedData, setEditData] = useState<any>("");
  const [workingShiftFilterCountry, setWorkingShiftFilterCountry] = useState<
    WorkingShiftCountry[]
  >([]);
  const [workingShiftFilterDepartment, setWorkingShiftFilterDepartment] =
    useState<WorkingShiftDepartment[]>([]);
  const [workingShiftFilterEmployee, setWorkingShiftFilterEmployee] = useState<
    WorkingShiftEmployee[]
  >([]);
  const [workingShiftFilterLocation, setWorkingShiftFilterLocation] = useState<
    WorkingShiftLocation[]
  >([]);
  const [workingShiftFilterWorkDay, setWorkingShiftFilterWorkDay] = useState<
    string[]
  >([]);
  // console.log("workingShiftFilterWorkDay", workingShiftFilterWorkDay);
  // const navigate = useNavigate();
  const { alerts } = useSelector((state: RootState) => state.alertClaim);
  const [globalSearch, setGlobalSearch] = useState<string>("");
  const [itemCount, setItemCount] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [department, setDepartment] = useState<string | number>("All");
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  });
  const [sorting, setSorting] = useState<SortingState>([]);
  const [otStatus, setOtStatus] = useState<any>("All");
  const [limitStatus, setLimitStatus] = useState<any>("All");
  const [message, setMessage] = useState<any>([]);
  // const [limit, setLimit] = useState<number>(10);
  const [currentView, setCurrentView] = useState<any>(0);
  const [currentViewData, setCurrentViewData] = useState<number>(0);
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  let [localModuleId, setLocalModuleId] = useState<number>(0);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [hiddenFields, setHiddenFields] = useState<string[]>([]);
  const [tempList, setTempList] = useState<any>(templateList);
  const [viewCols, setCols] = useState<any>([]);
  const [visArray, setVisArray] = useState<any>({});
  const [moduleList, setModuleList] = useState<any>([]);
  // console.log("module", moduleList);
  const [data, setData] = useState<baseObj[]>([]);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [isTemplate, setIsTemplate] = useState<boolean>(false);
  const [errorPopup, setErrorPopup] = useState<boolean>(false);
  const [isEditing, setIsEdit] = useState<boolean>(false);
  const [successPopup, setSuccessPopup] = useState<boolean>(false);
  const [isWorkingShift, setIsWorkingShift] = useState<boolean>(false);
  const [isLayoutControlOpen, setIsLayoutControlOpen] =
    useState<boolean>(false);
  // console.log("first", isWorkingShift);

  const [skip, setSkip] = useState<boolean>(false);
  const [manageClaimType, setManageClaimType] = useState<boolean>(false);
  const [createClaim, setCreateClaim] = useState<boolean>(false);
  var disabledData = checkedIsNew(data);
  const { data: workingShiftFilterData } = useGetWorkingShiftFilterListQuery();
  // console.log("filter ssssssss", workingShiftFilterData);
  const { data: templateData } = useGetByModuleTemplateQuery(localModuleId);
  const { data: WorkingShiftList } = useGetWorkingShiftListQuery({
    page: currentPage,
    limit: pagination.pageSize,
    search: globalSearch,
    country_id: otStatus,
    shift_timing: limitStatus,
    // sorting: sorting.map((s) => `${s.desc ? "-" : ""}${s.id}`).join(","),
  });
  const deleteTemplateData = (id: string) => {
    var filterTempList = templateList.filter((x) => x.id !== parseInt(id));
    setTempList(filterTempList);
  };
  var getUser = getAuthUser();
  const getAuthPermis: string[] = useMemo(() => [], []);
  if (getUser?.permissions) {
    getUser?.permissions?.forEach((per: any) => {
      getAuthPermis.push(per?.name);
    });
  }
  const props = useSpring({
    to: { opacity: 1 },
    from: { opacity: 0 },
    delay: 1000,
  });
  const changePage = (pageNo: number) => {};
  const fetchDataMemoized = useCallback(() => {
    if (WorkingShiftList) {
      setData(WorkingShiftList.working_shift.data);
      setItemCount(WorkingShiftList.working_shift.total);
      setLocalModuleId(WorkingShiftList.module_id);
    }
  }, [WorkingShiftList]);

  useEffect(() => {
    fetchDataMemoized();
  }, [fetchDataMemoized]);

  useEffect(() => {
    const filterManage = getAuthPermis.filter((f) => f === "Manage_ClaimForms");
    // const createClaim = getAuthPermis.filter((f) => f === "Create_Claim_Form");
    if (filterManage.length > 0) {
      setManageClaimType(true);
      setCreateClaim(true);
    }

    // if (createClaim.length > 0) {
    //   setCreateClaim(true);
    // }
  }, [getUser, getAuthPermis]);

  useEffect(() => {
    if (templateData) {
      var updatedTemplate = templateData?.templates.map((x: any, i) => {
        return { ...x, index: i + 1 };
      });
      var defaultTemplate = {
        id: -1,
        index: 0,
        module: 0,
        name: "All",
      };
      setModuleList([defaultTemplate, ...updatedTemplate]);
    }
  }, [templateData]);

  useEffect(() => {
    if (templateList) {
      setTempList(templateList);
    }
    var viewData = templateData?.templates.find(
      (x) => x.id.toString() == currentView.toString()
    );

    setHiddenFields(viewData ? [...viewData.viewColumns] : defaultFields);
    hideAllNoneField();
  }, [templateList, currentView]);

  useEffect(() => {
    if (viewCols?.view_columns?.length > 0) {
      setHiddenFields(viewCols?.view_columns);
    }
  }, [viewCols?.view_columns]);

  useEffect(() => {
    if (moduleList) {
      var lastesTemplate = moduleList[moduleList?.length - 1];
      setCurrentViewData(lastesTemplate ? Number(lastesTemplate?.id) : 0);
    }
  }, [moduleList]);

  useEffect(() => {
    setWorkingShiftFilterCountry(workingShiftFilterData?.countries ?? []);
    setWorkingShiftFilterDepartment(workingShiftFilterData?.departments ?? []);
    setWorkingShiftFilterLocation(
      workingShiftFilterData?.working_locations ?? []
    );
    setWorkingShiftFilterWorkDay(workingShiftFilterData?.days ?? []);
    setWorkingShiftFilterEmployee(workingShiftFilterData?.employees ?? []);
  }, [workingShiftFilterData]);

  useEffect(() => {
    setCurrentPage(1);
  }, [
    department,
    otStatus,
    limitStatus,
    pagination.pageSize,
    currentView,
    globalSearch,
  ]);

  useEffect(() => {
    if (alerts[0]?.type) {
      ResuableToasty({
        message: `${alerts[0]?.message}`,
        position: "top-right",
        type: "success",
      });
    }
  }, [alerts[0]?.type]);

  return (
    <div>
      <ToastContainer className={"text-12 font-poppins font-normal"} />
      <ErrorPopup
        descText="Error Message!"
        confirmBtnText="Yes, Close."
        show={errorPopup}
        setShow={setErrorPopup}
        message={message}
        setMessage={setMessage}
      />
      <div className="px-2 sm:px-4 py-4 bg-white sm:mt-[25px] mt-0 font-poppins text-13 font-semibold text-[#858795] tracking-[-0.03rem] w-full h-full rounded-xl pt-3 pb-8 sm:pt-3 sm:pb-9">
        <div className="flex flex-wrap justify-between items-center">
          <div className="flex items-center mr-2 xs:mr-4 sm:w-[260px] w-[225px] md:mb-1">
            <DebounceSearchInput setState={setGlobalSearch} debounce={800}>
              {(setState) => (
                <div className="font-normal flex border-1 border-[#C4C4C4] bg-white hover:bg-[#EFEFEF] rounded-[28px]">
                  <input
                    id="message"
                    autoComplete="off"
                    name="search-off"
                    type="text"
                    placeholder="Search"
                    className="focus:outline-none py-1 px-4 text-12 xl:text-14 leading-5 bg-transparent rounded-[28px] w-full text-graydark"
                    onChange={(e) => setState(e.target.value)}
                  />
                  <SearchIcon className={classNames("mr-2")} />
                </div>
              )}
            </DebounceSearchInput>
          </div>
          <div className="flex w-full sm:w-auto items-center justify-between md:justify-end lg:justify-end">
            {createClaim ? (
              <Button
                disabled={disabledData?.disabled}
                label="+ Add New"
                onClick={() => {
                  setIsOpenPopup(true);
                  setIsCreate(true);
                  //   setPagination((prev) => ({ ...prev, pageIndex: 0 }));
                  //   setSkip(true);
                  //   navigate("/new-claim-type");
                }}
                type="button"
                variant="primary"
                customClass={
                  disabledData?.disabledClass +
                  "py-[3px] my-1 px-2 focus:outline-none hover:bg-vorpmyanmar hover:border-vorpmyanmar hover:text-vorpblue 2xl:text-[14px] text-12 leading-5 font-medium text-white"
                }
                size="base"
              />
            ) : null}
          </div>
        </div>
        <WorkingShiftFilterComponent
          filterOneName="Region"
          filterTwoName="Shift Timing"
          // filterTwoName="Location"
          department={department}
          setDepartment={setDepartment}
          setPagination={setPagination}
          pagination={pagination}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          setIsTemplate={setIsTemplate}
          isTemplate={isTemplate}
          currentViewData={currentViewData}
          currentView={currentView}
          setCurrentView={setCurrentView}
          moduleList={moduleList}
          deleteTemplateData={deleteTemplateData}
          setErrorPopup={setErrorPopup}
          setSuccessPopup={setSuccessPopup}
          setMessage={setMessage}
          setIsLayoutControlOpen={setIsLayoutControlOpen}
          setIsWorkingShift={setIsWorkingShift}
          otStatus={otStatus}
          setOtStatus={setOtStatus}
          regionList={workingShiftFilterCountry}
          statusListData={statusListData}
          limitStatus={limitStatus}
          setLimitStatus={setLimitStatus}
        />
        <animated.div style={props}>
          <WorkingShiftTable
            data={data}
            setData={setData}
            setIsSuccess={setIsSuccess}
            isSuccess={isSuccess}
            pagination={pagination}
            setPagination={setPagination}
            isLayoutControlOpen={isLayoutControlOpen}
            setIsLayoutControlOpen={setIsLayoutControlOpen}
            setIsWorkingShift={setIsWorkingShift}
            isWorkingShift={isWorkingShift}
            globalSearch={globalSearch}
            setGlobalSearch={setGlobalSearch}
            columnFilters={columnFilters}
            setColumnFilters={setColumnFilters}
            setHiddenFields={setHiddenFields}
            hiddenFields={hiddenFields ? hiddenFields : []}
            templateList={tempList}
            setCurrentView={setCurrentView}
            setIsEdit={setIsEdit}
            isEditing={isEditing}
            localModuleId={localModuleId}
            total={itemCount}
            changePage={changePage}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            setIsTemplate={setIsTemplate}
            setCols={setCols}
            visArray={visArray}
            // manageClaim={manageClaimType}
            setSkip={setSkip}
            sorting={sorting}
            setSorting={setSorting}
            setIsOpenPopup={setIsOpenPopup}
            setIsDuplicate={setIsDuplicate}
            setEditData={setEditData}
          />
        </animated.div>
        <WorkingShiftPopup
          employeeList={workingShiftFilterEmployee}
          isOpen={isOpenPopup}
          isDuplicate={isDuplicate}
          setIsDuplicate={setIsDuplicate}
          setIsOpen={setIsOpenPopup}
          editedData={editedData}
          setEditData={setEditData}
          // region={region}
          // setRegion={setRegion}
          regionList={workingShiftFilterCountry}
          locationList={workingShiftFilterLocation ?? []}
          workingDay={workingShiftFilterWorkDay ?? []}
          departmentList={workingShiftFilterDepartment}
          setIsEdit={setIsEdit}
          isEditing={isEditing}
          setIsCreate={setIsCreate}
          isCreate={isCreate}
        />
      </div>
    </div>
  );
};
export default WorkingShift;
