import React, { useEffect, useRef, useState } from "react";
import styles from "./MultiSelect.module.scss";
import classNames from "classnames";
import { ChevronDown, SearchIcon } from "../common/Icons";

// Icon component
const Icon = ({ isOpen }: any) => {
  return (
    <svg
      viewBox="0 0 24 24"
      width="18"
      height="18"
      stroke="#222"
      strokeWidth="1.5"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={isOpen ? "translate" : ""}
    >
      <polyline points="6 9 12 15 18 9"></polyline>
    </svg>
  );
};

// CloseIcon component
const CloseIcon = () => {
  return (
    <svg
      viewBox="0 0 24 24"
      width="14"
      height="14"
      stroke="#fff"
      strokeWidth="2"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <line x1="18" y1="6" x2="6" y2="18"></line>
      <line x1="6" y1="6" x2="18" y2="18"></line>
    </svg>
  );
};

// CustomSelect component
const CustomSelect = ({
  selectedValueData,
  placeHolder,
  options,
  isMulti,
  isSearchable,
  onChange,
  align,
  hasID,
}: any) => {
  // State variables using React hooks
  const [showMenu, setShowMenu] = useState<boolean>(false); // Controls the visibility of the dropdown menu
  const [selectedValue, setSelectedValue] = useState<any>(
    isMulti ? selectedValueData ?? [] : null
  ); // Stores the selected value(s)

  const [searchValue, setSearchValue] = useState<string>(""); // Stores the value entered in the search input
  const searchRef = useRef<any>(); // Reference to the search input element
  const inputRef = useRef<any>(); // Reference to the custom select input element

  useEffect(() => {
    setSearchValue("");
    if (showMenu && searchRef.current) {
      searchRef.current.focus();
    }
  }, [showMenu]);

  useEffect(() => {
    const handler = (e: any) => {
      if (inputRef.current && !inputRef.current.contains(e.target)) {
        setShowMenu(false);
      }
    };

    window.addEventListener("click", handler);
    return () => {
      window.removeEventListener("click", handler);
    };
  });

  const handleInputClick = (e: any) => {
    setShowMenu(!showMenu);
  };

  const getDisplay = () => {
    if (!selectedValue || selectedValue.length === 0) {
      return placeHolder;
    }
    if (isMulti) {
      return (
        <div className={styles.dropdown_tags}>
          {selectedValue.map((option: any, index: any) => (
            <div
              key={`${option.value}-${index}`}
              className={styles.dropdown_tag_item}
            >
              {option.label}
              <span
                onClick={(e) => onTagRemove(e, option)}
                className={styles.dropdown_tag_close}
              >
                <CloseIcon />
              </span>
            </div>
          ))}
        </div>
      );
    }
    return selectedValue?.label;
  };

  const removeOption = (option: any) => {
    return selectedValue?.filter((o: any) => o.value !== option.value);
  };

  const onTagRemove = (e: any, option: any) => {
    e.stopPropagation();
    const newValue = removeOption(option);
    setSelectedValue(newValue ?? null);
    onChange(newValue);
  };

  const onItemClick = (option: any) => {
    let newValue;
    if (isMulti) {
      if (selectedValue.findIndex((o: any) => o.value === option.value) >= 0) {
        newValue = removeOption(option);
      } else {
        newValue = [...selectedValue, option];
      }
    } else {
      newValue = option;
    }
    setSelectedValue(newValue);
    onChange(newValue, hasID);
  };

  const isSelected = (option: any) => {
    if (isMulti) {
      return (
        selectedValue.filter((o: any) => o.value === option.value).length > 0
      );
    }

    if (!selectedValue) {
      return false;
    }

    return selectedValue.value === option.value;
  };

  const onSearch = (e: any) => {
    setSearchValue(e.target.value);
  };

  const getOptions = () => {
    if (!searchValue) {
      return options;
    }

    return options.filter(
      (option: any) =>
        option.label.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0
    );
  };

  return (
    <div className={styles.custom__dropdown_container}>
      <div
        ref={inputRef}
        onClick={handleInputClick}
        className={styles.dropdown_input_data}
      >
        <div
          className={classNames(
            styles.dropdown_selected_value,
            styles.placeholder,
            `${
              !selectedValue || selectedValue.length === 0 ? "placeholder" : ""
            }`
          )}
        >
          {getDisplay()}
        </div>
        <div className={styles.dropdown_tool}>
          <div className={styles.dropdown_tool}>
            {/* <Icon isOpen={showMenu} /> */}
            <ChevronDown />
          </div>
        </div>
      </div>

      {showMenu && (
        <div
          className={classNames(
            styles.dropdown_menu,
            `alignment__${align || "auto"}`
          )}
        >
          {isSearchable && (
            <div className={styles.search_box}>
              <input
                className={styles.form_control}
                onChange={onSearch}
                value={searchValue}
                ref={searchRef}
              />
            </div>
          )}
          {getOptions().map((option: any) => (
            <div
              onClick={() => onItemClick(option)}
              key={option.value}
              className={classNames(
                styles.dropdown_item,
                `${isSelected(option) && "selected"}`
              )}
            >
              {option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CustomSelect;
