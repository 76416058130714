import { ReactElement } from 'react';
import BasicLayout from '../layouts/BasicLayout';
import { Outlet } from 'react-router-dom';
import Notification from './views/Notification';

const NotificationRoute = (props: any): ReactElement => {
    const data = props.loginData;
    const permissions = props.permissions;
    return (
        <BasicLayout userData={data} permissions={permissions}>
            <Notification />
            <Outlet />
        </BasicLayout>
    )
};

export default NotificationRoute;
