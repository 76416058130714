import {
    ColumnDef,
    ColumnFiltersState,
    PaginationState,
    RowSelectionState,
    SortingState,
    createColumnHelper,
} from "@tanstack/react-table";
import React, {
    Dispatch,
    FC,
    SetStateAction,
    useCallback,
    useMemo,
    // useEffect,
    // useRef,
    useState,
} from "react";
import EditableCell from "../Tables/EditableCell";
import { baseObj } from "../../utils/constants";
// import {
//     renderDateFormatDMY,
//     renderDateFormatYMD,
// } from "../DashboardCalendarView/DashboardAddEventPopup";
// import DatePopup from "../EmployeeTable/DatePopup";
import classNames from "classnames";
import {
    CancelIcon,
    // CheckIcon,
    // CloseSmallIcon,
    EditIcon,
    TableSaveIcon,
    TrashIcon,
    ViewIcon,
} from "../common/Icons";
import styles from "./TaskCategoryTable.module.scss";
import LayoutAdjustableTable from "../Tables/LayoutAdjustableTable";
import { templateData } from "../../app/services/template";
import TablePaginator from "../Tables/TablePaginator";
import DeletePopup from "../DeletePopup/DeletePopup";
import { toast } from "react-toastify";
import { makeStyles } from "@mui/styles";
import ErrorPopup from "../ErrorPopup/ErrorPopup";
import { useDispatch, useSelector } from "react-redux";
import {
    useDeleteClaimTypeMutation,
    useMultiDeleteClaimTypeMutation,
} from "../../app/services/claim-type";
import { useNavigate } from "react-router-dom";
import CustomDropdownWithAddButton from "../EmployeeTable/CustomDropdownWithAddButton";
// import { EmployeeData } from "../EmployeeTable/EmployeeTable";
// import { Modal, Tooltip } from "@mui/material";
// import AssignPopup from "../Passwords/AssignPopup";
// import { KeyValueLabel } from "../../app/services/password";
// import CommonTableBottom from "../CommonTableBottom/CommonTableBottom";
import { TaskDepartmentListData } from "../../app/services/task-category";
interface IOTTable {
    isLayoutControlOpen: boolean;
    setIsLayoutControlOpen: Dispatch<SetStateAction<boolean>>;
    pagination: PaginationState;
    setPagination: Dispatch<SetStateAction<PaginationState>>;
    globalSearch: string;
    setGlobalSearch: Dispatch<SetStateAction<string>>;
    columnFilters: ColumnFiltersState;
    setColumnFilters: Dispatch<SetStateAction<ColumnFiltersState>>;
    data: baseObj[];
    setData: Dispatch<SetStateAction<baseObj[]>>;
    hiddenFields: string[];
    templateList: templateData[];
    setHiddenFields: any;
    setCurrentView: any;
    total: number;
    changePage: any;
    setCurrentPage: any;
    currentPage: number;
    setIsEdit: Dispatch<SetStateAction<boolean>>;
    isEditing?: boolean;
    setIsTemplate?: Dispatch<SetStateAction<boolean>>;
    localModuleId: number;
    visArray: any;
    setCols?: any;
    setIsSuccess?: React.Dispatch<React.SetStateAction<boolean>>;
    isSuccess?: boolean;
    setSkip: React.Dispatch<React.SetStateAction<boolean>>;
    sorting: SortingState;
    setSorting: React.Dispatch<React.SetStateAction<SortingState>>;
    createTaskCategory: any;
    updateTaskCategory: any;
    deleteTaskCategory: any;
    departmentData: TaskDepartmentListData[]
}

const useStyles = makeStyles(() => ({
    tooltip: {
        backgroundColor: "transparent",
        margin: 0,
        marginRight: 8,
        padding: 0,
    },
}));

// const tagsTasks = [
//     {
//         id: 1,
//         name: "UIUX",
//     },
//     {
//         id: 1,
//         name: "Frontend",
//     },
//     {
//         id: 1,
//         name: "Backend",
//     },
// ];

const TaskCategoryTable: FC<IOTTable> = ({
    isLayoutControlOpen,
    setIsLayoutControlOpen,
    pagination,
    setPagination,
    globalSearch,
    setGlobalSearch,
    columnFilters,
    setColumnFilters,
    data,
    setData,
    hiddenFields,
    total,
    changePage,
    setCurrentPage,
    currentPage,
    setIsEdit,
    isEditing,
    setIsTemplate,
    localModuleId,
    visArray,
    setCols,
    setSkip,
    setSorting,
    sorting,
    createTaskCategory,
    updateTaskCategory,
    deleteTaskCategory,
    departmentData
}) => {
    const navigate = useNavigate();
    const classes = useStyles();
    // const [customClass, setCustomClass] = useState<string>('');
    const columnHelper = useMemo(() => createColumnHelper<any>(), []);
    const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
    const [deleteID, setDeleteID] = useState<number | 0>();
    const [deleteRowID, setDeleteRowID] = useState<number | 0>();
    const [openDeletePopup, setOpenDeleteopup] = useState<boolean>(false);
    const [clickLoading, setClickLoading] = useState<boolean>(false);
    // const [isEdited, setIsEdited] = useState<boolean>(false);
    const [department, setDepartment] = useState<string>('All');
    const [uploading, setUploading] = useState<boolean>(false);
    const [itemCount, setItemCount] = useState<number>(total);
    const selectRowsCount = Object.keys(rowSelection).length;
    const [errorPopup, setErrorPopup] = useState<boolean>(false);
    const [message, setMessage] = useState<any>([]);
    // const [deleteClaimType] = useDeleteClaimTypeMutation();
    // const [deleteClaimListByIDs] = useMultiDeleteClaimTypeMutation();
    const dispatch = useDispatch();
    const stateData: any = useSelector((state: any) => state?.dashboard?.notificationDetail);
    // const [notifications, setNotifications] = useState<any>(
    //     stateData
    // );
    // const createTagData = (label: string) => {
    //     var data = {
    //       name: label,
    //       flag: "pwd",
    //     };
    //     return createTag(data);
    // };

    // const deleteOTData = useCallback(
    //     (id?: any, uniqueID?: number) => {
    //         setDeleteID(id);
    //         setDeleteRowID(uniqueID);
    //         setOpenDeleteopup(true);
    //     },
    //     [setData, rowSelection]
    // );

    const editRow = useCallback(
        (id: number) => {
            setData((prev: baseObj[]) => {
                return prev.map((data, index: number) => {
                    if (data.id == id) {
                        return { ...data, isNew: true };
                    } else {
                        return { ...data };
                    }

                    return data;
                });
            });
        },
        [setData]
    );
    const cancelRow = (id: number) => {
        if (isEditing) {
            setIsEdit(false);
            setData((prev: baseObj[]) => {
                return prev.map((data, index: number) => {
                    if (data.id == id) {
                        return { ...data, isNew: false };
                    }
                    return data;
                });
            });
        } else {
            const filterData = data?.filter((d: any) => d?.id !== id);
            setData(filterData);
        }
    };
    const saveTaskCategory = (id: number) => {
        var editedData = data.find((x) => x.id == id);
        if (editedData) {
            if (editedData.id == id) {
                if (id == 0) {
                    createTaskCategory({
                        id: editedData?.id,
                        name: editedData?.name,
                        department_id: editedData.department_id
                    })
                    setIsEdit(false);
                } else {
                    updateTaskCategory({
                        id: editedData?.id,
                        name: editedData?.name,
                        department_id: editedData.department_id
                    })
                    setIsEdit(false);
                }
            }
        }
    };
    const columns = useMemo(
        () => [
            columnHelper.accessor("name", {
                header: "Name",
                id: "name",
                enableSorting: false,
                cell: ({ getValue, cell }) => {
                    const name = getValue();
                    return (
                        <div className=" font-normal text-left overflow-hidden">
                            {/* min-w-[150px] */}
                            <EditableCell
                                setData={setData}
                                value={name}
                                type="text"
                                cell={cell}
                            />
                        </div>
                    );
                },
            }),
            columnHelper.accessor("department_id", {
                header: "Department",
                id: "department_id",
                enableSorting: false,
                cell: ({ getValue, cell }) => {
                    const dptment = getValue();
                    var departView = departmentD;
                    var departmentD = departmentData?.find((x) => x.id == dptment);
                    if (departmentD == undefined) {
                        departView = departmentData?.find((x) => x.name == dptment);
                    } else {
                        departView = departmentD;
                    }
                    const originalValues = cell.row.original?.role?.map(
                        (role: baseObj) => role.id
                    );
                    const isNew = cell.row.original?.isNew;
                    return (
                        <div
                            className={classNames(
                                "  text-left h-full font-normal",
                                // max-w-full min-w-[180px]
                                styles.customEmployeeDropdown
                            )}
                        >
                            <EditableCell
                                className="py-0"
                                hasPadding={true}
                                setData={setData}
                                value={departView ? departView.name : "Select"}
                                type="custom"
                                cell={cell}
                                customDesign="tooltip"
                                CustomComponent={
                                    <CustomDropdownWithAddButton
                                        isShowDelete={false}
                                        isShowAdd={false}
                                        initialValues={originalValues}
                                        dataList={departmentData ? departmentData : []}
                                        setDataList={() => 1}
                                        title="Department"
                                        cell={cell}
                                        data={data}
                                        setData={setData}
                                        setOpenDeleteopup={setOpenDeleteopup}
                                        setSelectedData={setDepartment}
                                        selectedData={departView ? departView.name : "Select"}
                                        addTitle="Create New Department"
                                        isEditable={isNew}
                                    />
                                }
                            />
                        </div>
                    );
                },
            }),
            columnHelper.display({
                header: "Action",
                id: `actions`,
                cell: ({ cell }) => {
                    var isHaveNew = data?.find((x) => x.isNew == true);
                    var disabledClass = isHaveNew ? "opacity-50 pointer-events-none" : "";
                    const isNew: boolean = cell.row.original?.isNew;
                    return (
                        <div
                            className={classNames(
                                "flex justify-center h-full items-center min-w-max " //max-w-[98px]
                            )}
                        >
                            {isNew ? (
                                <>
                                    <button
                                        type="button"
                                        onClick={() => { setIsEdit(true); saveTaskCategory(Number(cell?.row?.original?.id)) }}
                                        className={`w-8 h-8 flex justify-center items-center bg-vorpgreen rounded-md ${isEditing ? " pointer-events-none opacity-40" : ""
                                            }`}
                                    >
                                        <TableSaveIcon />
                                    </button>
                                    <button
                                        type="button"
                                        onClick={() => {
                                            cancelRow(Number(cell?.row?.original?.id));
                                        }}
                                        className={classNames(
                                            styles.customCloseIcon,
                                            "w-8 h-8 ml-2 mr-2 flex justify-center items-center group rounded-md  bg-[#71717a] hover:bg-[#71717a] transition-all duration-300"
                                        )}
                                    >
                                        <CancelIcon width={18} height={18} />
                                    </button>
                                </>
                            ) : (
                                <>
                                    <button
                                        disabled={isHaveNew ? true : false}
                                        type="button"
                                        onClick={() => {
                                            editRow(cell?.row?.original?.id);
                                        }}
                                        className={classNames(
                                            disabledClass,
                                            "w-8 h-[35px] mr-2 flex justify-center items-center group rounded-md bg-vorpblue hover:bg-annhoverbg transition-all duration-300"
                                        )}
                                    >
                                        <EditIcon />
                                    </button>
                                </>
                            )}
                        </div>
                    );
                },
            }),
        ],
        [columnHelper, departmentData, data, hiddenFields,uploading]
    );

    const visibleColumns: { [key: string]: boolean } = {};
    hiddenFields?.map((x) => {
        visibleColumns[x] = true;
    });

    document.querySelectorAll("th,td").forEach((x) => {
        if (x.id.includes("none")) {
            x.classList.add("hidden");
        }

        var div = document.getElementById(x.id);
    });

    if (true) {
        visibleColumns["actions"] = true;
    } else {
        visibleColumns["actions"] = false;
    }

    if (hiddenFields.length > 0) {
        columns.map((x) => {
            if (x.id == "actions" || x.id == "selection" || x.id == "lasted") {
                visibleColumns[x.id ? x.id : ""] = true;
            } else if (x.id == "uniqeID") {
                visibleColumns[x.id ? x.id : ""] = false;
            } else {
                var isHidden = hiddenFields.find((h) => h == x.id);
                if (isHidden) {
                    visibleColumns[x.id ? x.id : ""] = true;
                } else {
                    visibleColumns[x.id ? x.id : ""] = false;
                }
            }
        });
    }

    const [columnOrder, setColumnOrder] = useState<string[]>(
        columns.map((column: ColumnDef<any, any>) => column.id as string)
    );
    const confirmDeleteData = () => {
        setClickLoading(true);
        deleteTaskCategory(deleteID).then((res: any) => {
            
            if (res?.data?.status == true) {
                setOpenDeleteopup(false);
                setDeleteID(0);
                toast(res?.data?.message, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    type: "success",
                });
            } else {
                setMessage(res?.data?.message)
            }
            setClickLoading(false);
        })
    }
    return (
        <div id="progress-checker-acc" className={classNames(styles.otListTable, "relative")}>
            <ErrorPopup
                descText="Error Message!"
                confirmBtnText="Yes, Close."
                show={errorPopup}
                setShow={setErrorPopup}
                message={message}
                setMessage={setMessage}
            />

            <LayoutAdjustableTable
                hideColumn={visibleColumns}
                columns={columns}
                data={data}
                sorting={sorting}
                setSortingState={setSorting}
                rowSelection={rowSelection}
                setRowSelection={setRowSelection}
                columnOrder={columnOrder}
                setColumnOrder={setColumnOrder}
                unDraggableFields={["actions", "selection", "id"]}
                theaderColor="text-graydark"
                isLayoutControlOpen={isLayoutControlOpen}
                setIsLayoutControlOpen={setIsLayoutControlOpen}
                label="Task Cateogry List"
                setItemCount={setItemCount}
                pagination={pagination}
                setPagination={setPagination}
                globalFilter={globalSearch}
                setGlobalFilter={setGlobalSearch}
                columnFilters={columnFilters}
                setColumnFilters={setColumnFilters}
                noPaddingCells={['department_id']}
                localModuleId={localModuleId}
                visArray={visArray}
                setIsTemplate={setIsTemplate}
                setCols={setCols}
            />
            {selectRowsCount > 0 && (
                <div className="flex items-center text-14 leading-5 font-semibold text-black2 mt-3 mb-3 lg:mt-3 lg:mb-3 ">
                    <p>{selectRowsCount} selected.</p>
                    <button
                        type="button"
                        onClick={() => setOpenDeleteopup(true)}
                        className=" flex items-center ml-5 text-12 leading-[18px] font-semibold text-redPink"
                    >
                        <div className="w-6 h-6 bg-redPink flex items-center group justify-center rounded-md mr-[10px] hover:bg-[#FFEAEF]  transition-all duration-300 ">
                            <TrashIcon width={10} height={12} />
                        </div>
                        Delete
                    </button>
                </div>
            )}
            <div className="pt-2">
                <TablePaginator
                    total={itemCount}
                    pagination={pagination}
                    setPagination={setPagination}
                    itemCount={total}
                    setCurrentPage={setCurrentPage}
                    currentPageDefault={currentPage}
                    changePage={changePage}
                />
            </div>
            <ErrorPopup
                descText="Error Message!"
                confirmBtnText="Yes, Close."
                show={errorPopup}
                setShow={setErrorPopup}
                message={message}
                setMessage={setMessage}
            />

            <DeletePopup
                descText="Are you sure to delete?"
                confirmBtnText="Yes, delete."
                show={openDeletePopup}
                setShow={setOpenDeleteopup}
                confirmDeleteData={confirmDeleteData}
            />
        </div>
    );
};
export default TaskCategoryTable;
