import { redirectToLogin } from './annoucement-sheet';
import { api } from './api';
import authHeader from './auth-header';
import { Ids } from './media';

type TaskCategoryListResponse = {
    module_id: number,
    status: boolean,
    total_time: string,
    task_categories: TaskCategoryInfo
}
export type ProjectInfo = {
    project_id: number;
    project_code: string;
    project_name: string;
}
export type TaskCategoryData = {
    id: number;
    name: string;
    description: string;
}
export type TaskCategoryInfo = {
    current_page: number;
    data: TaskCategoryData[],
    total: number;
}
type taskCategoryInput = {
    department_id: string,
    sort: string,
    search: string,
    page: number,
    limit: number,
}
export type TaskDepartmentList = {
    departmentList: TaskDepartmentListData[]
}
export type TaskDepartmentListData = {
    id: number,
    name: string,
}

export const taskCategoryApi = api.injectEndpoints({
    endpoints: (build) => ({
        getTaskCategories: build.query<TaskCategoryListResponse, taskCategoryInput>({
            query: ({ sort, search, department_id, limit, page }) => ({
                url: `task-categories?department_id=${department_id}&sort=${sort}&search=${search}&limit=${limit}&page=${page}
            
            `,
                headers: authHeader()
            }),
            transformErrorResponse: (err) => {
                if (err?.status === 401) {
                    redirectToLogin();
                }
            },
            providesTags: (result: any = []) => {
                if (result.data) {
                    if (result.data.length > 0) {
                        return [
                            ...result.data.map((id: any) => ({ type: "TaskCategories", id } as const)),
                            { type: "TaskCategories" as const, id: "LIST" },
                        ]
                    }
                    else {
                        return [{ type: "TaskCategories" as const, id: "LIST" },]
                    }
                }
                else {
                    return [{ type: "TaskCategories" as const, id: "LIST" },]
                }

            },
        }),
        getDepartmentList: build.query<TaskDepartmentListData[], void>({
            query: () => ({
                url: `departments`,
                headers: authHeader()
            }),
            transformErrorResponse: (err) => {
                if (err?.status === 401) {
                    redirectToLogin();
                }
            },
            providesTags: (result: any = []) => {
                if (result.data) {
                    if (result.data.length > 0) {
                        return [
                            ...result.data.map((id: any) => ({ type: "Departments", id } as const)),
                            { type: "Departments" as const, id: "LIST" },
                        ]
                    }
                    else {
                        return [{ type: "Departments" as const, id: "LIST" },]
                    }
                }
                else {
                    return [{ type: "Departments" as const, id: "LIST" },]
                }

            },
        }),
        createTaskCategory: build.mutation<TaskDepartmentList, TaskCategoryData>({
            query(body) {
                return {
                    headers: authHeader(),
                    url: `/task-categories`,
                    body,
                    method: "POST",
                };
            },
            invalidatesTags: (employees) => {
                return [{ type: "TaskCategories", id: "LIST" }];
            },
        }),
        updateTaskCategory: build.mutation<TaskDepartmentList, TaskCategoryData>({
            query(body) {
                return {
                    headers: authHeader(),
                    url: `/task-categories/${body.id}`,
                    body,
                    method: "PUT",
                };
            },
            invalidatesTags: (employees) => {
                return [{ type: "TaskCategories", id: "LIST" }];
            },
        }),
        deletTaskCategory: build.mutation<TaskDepartmentList, number>({
            query(id) {
                return {
                    headers: authHeader(),
                    url: `/task-categories/${id}`,
                    method: "DELETE",
                };
            },
            invalidatesTags: (employees) => {
                return [{ type: "TaskCategories", id: "LIST" }];
            },
        }),
    }),
});

export const {
    useGetTaskCategoriesQuery,
    useGetDepartmentListQuery,
    useCreateTaskCategoryMutation,
    useUpdateTaskCategoryMutation,
    useDeletTaskCategoryMutation
} = taskCategoryApi;


export const {
    endpoints: {
        getTaskCategories,
        getDepartmentList,
        createTaskCategory,
        updateTaskCategory,
        deletTaskCategory
    },
} = taskCategoryApi;