import {
  Dispatch,
  FC,
  memo,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { CloseIcon, SendIcon } from "../../common/Icons";
import CustomDatePicker from "../../common/BaseDatePicker";
import BaseRadioButton from "../../common/BaseRadioButton";
import { StringDropdownList } from "../../common/DropdownList";
// import { leaveTypes } from "../../../utils/constants";
import Button from "../../common/Button";
import { renderDateFormatYMD } from "../../DashboardCalendarView/DashboardAddEventPopup";
import classNames from "classnames";
// import SuccessPopup from "../../SuccessPopup/SuccessPopup";
import ErrorPopup from "../../ErrorPopup/ErrorPopup";
import DOMPurify from "dompurify";
import { ToastContainer, toast } from "react-toastify";
import styles from "./MyLeave.module.scss";
import { endpointUrl } from "../../../app/services/api";
import { getAuthUser } from "../../../app/services/dashboard";
import axios from "../../../axios";

interface ILeaveApplication {
  setIsLeaveApplicationOpen: Dispatch<SetStateAction<boolean>>;
  leaveTypeList?: any;
  createLeaveData?: any;
  LeaveBalanceList?: any;
  setData: React.Dispatch<any>;
  fromDateOld?: Date;
  toDateOld?: Date;
  leaveDurationOld?: string;
  leaveTypeOld?: string;
  reasonOld?: string;
  id?: number;
  documents?: any[];
  setLeaveBalanceData?: any;
}

type TFile = {
  name: string;
  id: number;
  size: number;
};

const LeaveApplication: FC<ILeaveApplication> = ({
  setIsLeaveApplicationOpen,
  leaveTypeList,
  // createLeaveData,
  LeaveBalanceList,
  setData,
  fromDateOld,
  toDateOld,
  reasonOld,
  leaveDurationOld,
  leaveTypeOld,
  id,
  documents,
  setLeaveBalanceData
}) => {
  const [fromDate, setFromDate] = useState<Date | undefined>(new Date());
  const [toDate, setToDate] = useState<Date>();
  const [leaveDuration, setLeaveDuration] = useState<string>("Full");
  const [leaveType, setLeaveType] = useState<string>("");
  const [reason, setReason] = useState<string>("");
  const [disable, setDisable] = useState<any>(LeaveBalanceList?.full_3_month);
  const [leaveTypeArr, setLeaveTypeArr] = useState<any[]>([]);
  const [totalDay, setTotalDay] = useState<number>(0);
  const [successPopup, setSuccessPopup] = useState<boolean>(false);
  const [errorPopup, setErrorPopup] = useState<boolean>(false);
  const [message, setMessage] = useState<any>([]);
  const [leaveTypeError, setLeaveTypeErrors] = useState<string>("");
  const [reasonErr, setReasonErrors] = useState<string>("");
  const [selectedFileName, setSelectedFileName] = useState("No file chosen");
  const [allFile, setFile] = useState<any[]>([]);
  const [allPrevFiles, setAllPrevFiles] = useState<any[]>([]);
  const [oldDocuments, setOldDocuments] = useState<any[]>([]);
  const [pervEditFileIds, setPervEditFileIds] = useState<number[]>([]);

  const [errorMsg, setErrorMsg] = useState<string>("");
  const [uploading, setUploading] = useState<boolean>(false);
  const uploadRef: any = useRef(null);
  var getUser = getAuthUser();

  const saveLeaveApplication = async () => {
    setUploading(true);
    const fDate = renderDateFormatYMD(
      fromDate ? new Date(fromDate) : new Date(),
      "-"
    );

    const tDate = renderDateFormatYMD(
      toDate ? new Date(toDate) : new Date(),
      "-"
    );
    const reasonData = DOMPurify.sanitize(reason);
    const leaveTypeData = leaveType;
    const durationData = leaveDuration;

    let formSaveData = new FormData();
    if (pervEditFileIds.length > 0) {
      pervEditFileIds?.forEach((image_ids: any) => {
        formSaveData.append("deleted_document_ids[]", image_ids);
      });
    }

    if (allPrevFiles.length > 0) {
      allPrevFiles?.forEach((image_file: any) => {
        formSaveData.append("documents[]", image_file);
      });
      formSaveData.append("from_date", fDate.toString());
      formSaveData.append("to_date", tDate.toString());
      formSaveData.append("full_half_day", durationData.toString());
      formSaveData.append("leave_type_id", leaveTypeData);
      formSaveData.append("reason", reasonData.toString());
    } else {
      formSaveData.append("from_date", fDate.toString());
      formSaveData.append("to_date", tDate.toString());
      formSaveData.append("full_half_day", durationData.toString());
      formSaveData.append("leave_type_id", leaveTypeData);
      formSaveData.append("reason", reasonData.toString());
    }

    if (reason == "" && !leaveType) {
      setReasonErrors("The reason field is required.");
      setLeaveTypeErrors("The leave type field is required.");
      setUploading(false);
      return;
    } else if (reason.length == 0) {
      setReasonErrors("The reason field is required.");
      setLeaveTypeErrors("");
      setUploading(false);
      return;
    } else if (!leaveType) {
      setReasonErrors("");
      setLeaveTypeErrors("The leave type field is required.");
      setUploading(false);
      return;
    } else if (allPrevFiles.length > 5) {
      setErrorPopup(true);
      setUploading(false);
      setMessage(["Files must less than 6 files"]);
    }
    if (id) {
      formSaveData.append("_method", "PUT");
      await axios(endpointUrl + "leaves/" + id, {
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${getUser.token}`,
          Accept: "application/json",
        },
        data: formSaveData,
      })
        .then((res: any) => {
          const msg = res.data.message;
          setUploading(false);
          axios
            .get(`${endpointUrl}profile/leave-information`, {
              method: "GET",
              headers: {
                Authorization: `Bearer ${getUser.token}`,
                Accept: "application/json",
              },
            })
            .then((res: any) => {
              setLeaveBalanceData(res?.data)
            })
            .catch((error: any) => {
              setErrorPopup(true);
              setMessage(
                error.response.data.message
                  ? [error.response.data.message]
                  : ["Something went wrong!Trying again."]
              );
            });
          axios
            .get(`${endpointUrl}profile/leave-taken`, {
              method: "POST",
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${getUser.token}`,
                Accept: "application/json",
              },
            })
            .then((res: any) => {
              setUploading(false);
              setSuccessPopup(true);
              setData(res.data.leaveData.data);
              toast(msg, {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                type: "success",
              });
              setIsLeaveApplicationOpen(false);
            })
            .catch((error: any) => {
              setUploading(false);
              setErrorPopup(true);
              setMessage(
                error.response.data.message
                  ? [error.response.data.message]
                  : ["Something went wrong!Trying again."]
              );
            });

        })
        .catch((error: any) => {
          setErrorPopup(true);
          setUploading(false);
          setMessage(
            error.response.data.message
              ? [error.response.data.message]
              : ["Something went wrong!Trying again."]
          );
        });
    } else {
      await axios(endpointUrl + "leaves", {
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${getUser.token}`,
          Accept: "application/json",
        },
        data: formSaveData,
      })
        .then((res: any) => {
          const msg = res.data.message;
          setUploading(false);
          axios
            .get(`${endpointUrl}profile/leave-information`, {
              method: "GET",
              headers: {
                Authorization: `Bearer ${getUser.token}`,
                Accept: "application/json",
              },
            })
            .then((res: any) => {
              setLeaveBalanceData(res?.data)
            })
            .catch((error: any) => {
              setErrorPopup(true);
              setMessage(
                error.response.data.message
                  ? [error.response.data.message]
                  : ["Something went wrong!Trying again."]
              );
            });
          axios
            .get(`${endpointUrl}profile/leave-taken`, {
              method: "POST",
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${getUser.token}`,
                Accept: "application/json",
              },
            })
            .then((res: any) => {
              setUploading(false);
              setSuccessPopup(true);
              setData(res.data.leaveData.data);
              toast(msg, {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                type: "success",
              });
              setIsLeaveApplicationOpen(false);
            })
            .catch((error: any) => {
              setUploading(false);
              setErrorPopup(true);
              setMessage(
                error.response.data.message
                  ? [error.response.data.message]
                  : ["Something went wrong!Trying again."]
              );
            });
        })
        .catch((error: any) => {
          setErrorPopup(true);
          setUploading(false);
          setMessage(
            error.response.data.message
              ? [error.response.data.message]
              : ["Something went wrong!Trying again."]
          );
        });

    }
  };

  const UploadFile = useCallback(() => {
    const file = uploadRef?.current.files;
    setFile([...file]);

    const MAX_FILE_SIZE = 100 * 1024 * 1024; // 100MB
    if (!file) {
      setErrorMsg("Please choose a file");
      return;
    }
    for (let i = 0; i < file.length; i++) {
      if (file[i].size > MAX_FILE_SIZE) {
        setErrorMsg("File size exceeds the maximum allowed (100MB).");
        return;
      } else {
        setErrorMsg("");
      }
    }
  }, [setSelectedFileName, setFile, setErrorMsg]);

  const CheckedFile = useCallback(
    (fileData: any) => {
      setSelectedFileName(`${fileData.length} files selected`);
      const MAX_FILE_SIZE = 100 * 1024 * 1024; // 100MB
      if (!fileData) {
        setErrorMsg("Please choose a file");
        return;
      }

      for (let i = 0; i < fileData.length; i++) {
        if (fileData[i].size > MAX_FILE_SIZE) {
          setErrorMsg("File size exceeds the maximum allowed (100MB).");
          return;
        } else {
          setErrorMsg("");
        }
      }
    },
    [setSelectedFileName, setErrorMsg]
  );

  useEffect(() => {
    if (allFile.length > 0) {
      if (allPrevFiles.length === 0) {
        setAllPrevFiles([...allPrevFiles, ...allFile]);
      } else {
        const difference = allFile.filter((item1) => {
          // Use the find method to check if an item in checkAge exists in allPrevFiles
          return !allPrevFiles.find((item2) => item2.name === item1.name);
        });

        // const match = allPrevFiles.filter((item1) => {
        //   // Use the find method to check if an item in checkAge exists in allPrevFiles
        //   return allFile.find((item2) => item2.name === item1.name);
        // });
        // console.log("m", match);

        setAllPrevFiles([...allPrevFiles, ...difference]);
      }
    }
  }, [allFile]);

  useEffect(() => {
    if (allPrevFiles.length > 0) {
      CheckedFile(allPrevFiles);
      setSelectedFileName(`${allPrevFiles.length} files selected`);
    } else {
      setSelectedFileName("No file choosen");
    }
  }, [allPrevFiles]);

  const handleSelectedFile = (name: number) => {
    const filtered = allPrevFiles.filter((file) => file.lastModified !== name);
    setAllPrevFiles(filtered);
  };

  const handleSelectedEditFile = (fileData: any) => {
    const filtered = oldDocuments.filter((file) => file.name !== fileData.name);
    setOldDocuments(filtered);
    setPervEditFileIds([...pervEditFileIds, fileData.id]);
  };

  const handleClick = () => {
    uploadRef.current.click();
  };

  useEffect(() => {
    const filterData = leaveTypeList?.filter((f: any) => {
      return f.name == "Unpaid Leave";
    });
    if (!disable) {
      setLeaveTypeArr(filterData);
    } else {
      setLeaveTypeArr(leaveTypeList);
    }
  }, [disable]);

  useEffect(() => {
    setToDate(fromDate);
  }, [fromDate]);

  useEffect(() => {
    setToDate(toDate);
  }, [toDate]);

  useEffect(() => {
    var diff = 0;
    var startDate: any = fromDate?.getTime();
    var endDate: any = toDate?.getTime();

    if (endDate && startDate) {
      diff = endDate - startDate;
    }
    if (toDate == undefined) {
      setToDate(fromDate);
      diff = 1;
    }

    if (startDate != undefined && endDate != undefined) {
      if (leaveDuration) {
        if (leaveDuration.includes("Half")) {
          diff = diff / (1000 * 3600 * 24) / 2;
          if (startDate == endDate) {
            diff = 0.5;
          }
          if (endDate > startDate) {
            diff += 0.5;
          }
        } else {
          diff = diff / (1000 * 3600 * 24);
          if (startDate == endDate) {
            diff = 1;
          }
          if (endDate > startDate) {
            diff += 1;
          }
        }
        setTotalDay(Math.round(diff));
      }
    }
  }, [toDate, fromDate, leaveDuration]);

  useEffect(() => {
    if (fromDateOld) {
      setFromDate(fromDateOld);
    }
    if (toDateOld) {
      setToDate(toDateOld);
    }
    if (reasonOld) {
      setReason(reasonOld);
    }
    if (leaveTypeOld) {
      setLeaveType(leaveTypeOld);
    }
    if (leaveDurationOld) {
      setLeaveDuration(leaveDurationOld ? leaveDurationOld : "Full");
    }
    if (documents) {
      setOldDocuments(documents);
    }
  }, [fromDateOld, toDateOld, reasonOld]);


  useEffect(() => {
    setTimeout(() => {
      setSuccessPopup(false);
    }, 1000);
  }, [successPopup]);

  return (
    <div className="modalContent w-full h-full p-6 lg:p-0 flex justify-center items-center relative z-[999] overflow-hidden">
      <div className="rounded-xl bg-white overflow-hidden font-primary w-full">
        <ToastContainer className={"text-12 font-poppins font-normal"} />
        <ErrorPopup
          descText="Error Message!"
          confirmBtnText="Yes, Close."
          show={errorPopup}
          setShow={setErrorPopup}
          message={message}
          setMessage={setMessage}
        />

        <div className="px-6 sm:pl-10 sm:pr-7 py-6 bg-vorpblue flex justify-between items-center relative z-[999]">
          <h3 className="text-18 leading-[27px] font-semibold text-white">
            Leave Application
          </h3>
          <button
            type="button"
            aria-label="Close Modal"
            onClick={() => setIsLeaveApplicationOpen(false)}
          >
            <CloseIcon />
          </button>
        </div>
        <div className="custom_scrollbar_popup px-4 sm:px-10 my-4 sm:mt-9 sm:mb-10 bg-white flex flex-col-reverse lg:flex-row justify-between max-h-[60vh] xs:max-h-[70vh] lg:max-h-[75vh] overflow-y-auto">
          <div className="flex-1">
            <div className="pb-7 border-b-2 border-offwhite">
              <div className="mb-5">
                <h4 className="xl:text-15 text-12 leading-[22px] font-medium text-black2 mb-2 w-full">
                  Leave from date
                </h4>
                <CustomDatePicker
                  date={fromDate}
                  setDate={setFromDate}
                  minDate={new Date()}
                  isFilterSection={false}
                  placeholder={new Date().toDateString()}
                  iconColor="#858795"
                />
              </div>
              <div className="mb-5">
                <h4 className="xl:text-15 text-12 leading-[22px] font-medium text-black2 mb-2 w-full">
                  Leave to date
                </h4>
                <CustomDatePicker
                  date={toDate}
                  minDate={fromDate != undefined ? fromDate : new Date()}
                  setDate={setToDate}
                  isFilterSection={false}
                  placeholder={
                    toDate != undefined
                      ? new Date(toDate).toDateString()
                      : new Date(fromDate ?? new Date()).toDateString()
                  }
                  iconColor="#858795"
                />
              </div>
              <div className="flex items-center">
                <BaseRadioButton
                  state={leaveDuration}
                  setState={setLeaveDuration}
                  label="Full"
                  value="Full"
                  // value="full"
                  id="full"
                  className="mr-4 sm:mr-8"
                  fontSize="xl:text-15 text-12"
                  name="leave-duration"
                />
                <BaseRadioButton
                  state={leaveDuration}
                  setState={setLeaveDuration}
                  label="Half(AM)"
                  value="Half(AM)"
                  // value="half-am"
                  id="half-am"
                  className="mr-4 sm:mr-8"
                  fontSize="xl:text-15 text-12"
                  name="leave-duration"
                />
                <BaseRadioButton
                  state={leaveDuration}
                  setState={setLeaveDuration}
                  label="Half(PM)"
                  value="Half(PM)"
                  // value="half-pm"
                  id="half-pm"
                  fontSize="xl:text-15 text-12"
                  name="leave-duration"
                />
              </div>
              {leaveDuration && (
                <p className="text-graydark pt-[25px]">
                  <span className="font-medium leading-[22px] xl:text-15 text-12">
                    Leave applied for:
                  </span>{" "}
                  <span className="xl:text-15 text-12 leading-5">
                    {totalDay} day
                  </span>
                </p>
              )}
            </div>

            <div className="pt-[26px]">
              <h4 className="xl:text-15 text-12 leading-[22px] font-medium text-black2 mb-2 w-full">
                Leave Type
              </h4>
              <StringDropdownList
                state={leaveType}
                setState={setLeaveType}
                placeholder="Select Leave Type"
                className="w-full py-3 pl-3 mb-3 text-14"
                // dropdownItems={leaveTypeList?.map((status: any) => ({
                dropdownItems={leaveTypeArr?.map((status: any) => ({
                  value: status.id,
                  label: status.name,
                }))}
                textAlign={"text-left"}
                fontSize="xl:text-15 text-12"
              // isDisabled={disable}
              />
              <p
                className={classNames(
                  leaveTypeError != "" ? "" : "hidden",
                  "my-2 font-poppins text-12 text-redPink"
                )}
              >
                {leaveTypeError ? leaveTypeError : ""}
              </p>
              <textarea
                defaultValue={reason}
                onChange={(ev) => setReason(ev.target.value)}
                onBlur={(ev) => setReason(ev.target.value)}
                rows={5}
                className="placeholder:text-vorpgraylight border border-vorpgraylight w-full rounded-sm focus:outline-none p-3 xl:text-15 text-12 leading-5 text-graydark resize-none mb-5"
                placeholder="Reason of leave"
              ></textarea>
              <p
                className={classNames(
                  reasonErr != "" ? "" : "hidden",
                  "my-2 font-poppins text-12 text-redPink"
                )}
              >
                {reasonErr ? reasonErr : ""}
              </p>

              <div className="mb-5 last:mb-0">
                <p className="text-black2 text-12 sm:text-15 font-medium font-primary mb-[0.688rem] block leading-tight">
                  Supportive Documents:
                </p>
                <div className=" border border-gray-300 text-gray-400">
                  <p className=" text-sm px-4 pt-3">
                    Upload file (.jpg, .png, .pdf,.docx,.jpeg) maximum 5 files
                  </p>
                  <div className="flex items-center p-4">
                    <input
                      multiple
                      type="file"
                      id="upload"
                      name="file"
                      onChange={UploadFile}
                      ref={uploadRef}
                      className="hidden"
                      accept=".pdf,.doc,.docx,.jpg,.png,.jpeg,.svg"
                    />
                    <button
                      onClick={handleClick}
                      className={classNames(
                        allPrevFiles.length > 5 || uploading
                          ? "pointer-events-none"
                          : "",
                        styles.uploadBtn
                      )}
                    >
                      Choose file
                    </button>
                    <p className="font-primary text-10 sm:text-12 text-black2 ml-3">
                      {selectedFileName}
                    </p>
                  </div>
                  {allPrevFiles.length > 0 &&
                    allPrevFiles.map((file: File, i: number) => (
                      <div className="flex" key={i}>
                        <p className=" text-sm py-2 px-4">{file.name}</p>
                        <button
                          type="button"
                          onClick={() => handleSelectedFile(file.lastModified)}
                          className="text-12 mr-2 p-4"
                        >
                          <CloseIcon color="#EE436D" width={10} height={10} />
                        </button>
                      </div>
                    ))}
                  {oldDocuments.length > 0 &&
                    oldDocuments.map((file: File, i: number) => (
                      <div className="flex" key={i}>
                        <p className=" text-sm py-2 px-4">{file.name}</p>
                        <button
                          type="button"
                          onClick={() =>
                            handleSelectedEditFile(file)
                          }
                          className="text-12 mr-2 p-4"
                        >
                          <CloseIcon color="#EE436D" width={10} height={10} />
                        </button>
                      </div>
                    ))}
                </div>
              </div>

              <p className=" text-red-600">{!errorMsg ? "" : errorMsg}</p>
              <hr className="border border-vorpgraylight border-b-0 border-l-0 border-r-0 px-10 my-2" />

              <Button
                type="button"
                label={
                  <span className="flex items-center text-12 leading-[18px]">
                    <SendIcon className="mr-[10px] group-hover:opacity-0 transition-all duration-300" />
                    {uploading ? "Uploading" : "Submit"}
                  </span>
                }
                onClick={() => saveLeaveApplication()}
                variant="no-outline"
                size="lg"
                customClass={classNames(
                  uploading ? " pointer-events-none opacity-50" : "",
                  "hover:bg-vorpblue mr-2"
                )}
              />
              <Button
                type="button"
                label={
                  <span className="flex items-center text-12 leading-[18px]">
                    <CloseIcon
                      width={14}
                      height={14}
                      className="mr-[10px] [&_path]:fill-graydark"
                    />
                    Cancel
                  </span>
                }
                onClick={() => setIsLeaveApplicationOpen(false)}
                variant="no-outline"
                size="lg"
                customClass="min-w-[112px] min-h-[43.6px] bg-offwhite text-graydark border-none"
              />
            </div>
          </div>
          <div className="flex-1 border-offwhite ml-0 pl-0 lg:border-l lg:ml-5 lg:pl-5 xl:ml-7 xl:pl-7">
            <h3 className="text-16 sm:text-18 leading-7 font-semibold text-black2 mb-4">
              Leave Information
            </h3>
            <div className="text-black2 text-14 sm:text-15 leading-[22px] font-medium pb-8 mb-7 border-b-2 border-offwhite">
              {LeaveBalanceList?.leavesInfo?.map((type: any) => (
                <div className="flex mb-1" key={type.leave_type}>
                  <p className="min-w-[182px] mr-4">{type.leave_type}:</p>
                  <p className="text-14 font-normal">{type.leave_balance}</p>
                </div>
              ))}
            </div>
            <div className="pb-8 lg:pb-0">
              {LeaveBalanceList?.leavesInfo?.map((type: any) => (
                <div key={type.leave_type} className="mb-6">
                  <h3 className="xl:text-15 text-12 leading-[22px] font-medium mb-1 text-black2">
                    {type.leave_type}
                  </h3>
                  <p className="text-12 leading-[18px] text-graydark">
                    {type.description}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(LeaveApplication);
