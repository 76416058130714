// import { Modal } from "@material-ui/core";
import { Modal } from "@mui/material"

// import classNames from "classnames";
import React, {
  // createRef,
  Dispatch,
  FC,
  // FocusEvent,
  SetStateAction,
  // useRef,
  // useState,
} from "react";
// import { StringDropdownList } from "../common/DropdownList";
import ProfileCalendarView from "../DashboardCalendarView/ProfileCalendarView";
interface ICreateData {
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  isFullscreen: boolean;
  setIsFullscreen: React.Dispatch<React.SetStateAction<boolean>>
}

const ProfileCalendarFullView: FC<ICreateData> = ({
  show,
  setShow,
  isFullscreen,
  setIsFullscreen
}) => {
  
  return (
    <>
      {
        show && (
          <Modal
            open={show}
            onClose={() => setShow(false)}  disableEnforceFocus={true}
          >
            <ProfileCalendarView isFullscreen={isFullscreen} setIsFullscreen={setIsFullscreen} />
          </Modal>
        )
      }
    </>
  );
};
export default ProfileCalendarFullView;
