import { useEffect, useState } from "react";
import { PlayerIcon } from "../../components/common/Icons";
import styles from './PerformanceAppraisalDetail.module.scss';
import classNames from "classnames";

const AudioPlayerCustom = (props: any) => {
    const uniqueID = props?.id;
    const [audio, setAudio] = useState<any>();
    const [audioPlayBtn, setAudioPlayBtn] = useState<any>();
    const [timeline, setTimeline] = useState<any>();
    const [curTime, setCurTime] = useState<any>();
    const [totalTime, setTotalTime] = useState<any>();
    const playAudio = (id: string) => {
        var audioPlayer: any = document.getElementById("audio" + id);
        var playerButton = document.getElementById("audioPlayer" + id);
        var timelineButton = document.getElementById("timeline" + id);
        setAudioPlayBtn(playerButton)
        setAudio(audioPlayer);
        setTimeline(timelineButton);
        if (audioPlayer?.paused) {
            audioPlayer?.play();
            if (playerButton) {
                playerButton.innerHTML = `
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="#3699FF">
          <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM7 8a1 1 0 012 0v4a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v4a1 1 0 102 0V8a1 1 0 00-1-1z" clip-rule="evenodd" />
        </svg>
              `;
            }

        } else {
            audioPlayer?.pause();
            if (playerButton) {
                playerButton.innerHTML = `
                <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} className={className} viewBox="0 0 46 46" fill="none">
  <circle cx="23" cy="23" r="23" fill="#3699FF"/>
  <path d="M19.7156 29.2778C19.3406 29.5215 18.9608 29.5354 18.576 29.3194C18.192 29.1041 18 28.7715 18 28.3215V16.6778C18 16.2278 18.192 15.8948 18.576 15.6788C18.9608 15.4635 19.3406 15.4778 19.7156 15.7215L28.8844 21.5434C29.2219 21.7684 29.3906 22.0871 29.3906 22.4996C29.3906 22.9121 29.2219 23.2309 28.8844 23.4559L19.7156 29.2778Z" fill="white"/>
  </svg>
              `;
            }
        }
    }
    const formatSecondsAsTime = (secs: any, format: any) => {
        var hr = Math.floor(secs / 3600);
        var min: any = Math.floor((secs - (hr * 3600)) / 60);
        var sec: any = Math.floor(secs - (hr * 3600) - (min * 60));

        if (min < 10) {
            min = "0" + min;
        }
        if (sec < 10) {
            sec = "0" + sec;
        }

        return min + ':' + sec;
    }
    const changeTimelinePosition = () => {
        // setCurTime(formatSecondsAsTime(audio.currentTime, ':'));
        //console.log("change ", audio)
        const percentagePosition = (100 * audio.currentTime) / audio.duration;
        timeline.style.backgroundSize = `${percentagePosition}% 100%`;
        timeline.value = percentagePosition;
        var current = document.getElementById('audioCurrentTime' + uniqueID);
        if (current) {
            current.innerText = formatSecondsAsTime(audio.currentTime, ':');
        }
        //setTotalTime(formatSecondsAsTime(audio.duration, ':'));        
    }
    const audioEnded = () => {
        audioPlayBtn.innerHTML = `
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="#3699FF">
    <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z" clip-rule="evenodd" />
  </svg>
      `;
    }
    if (audio) {
        audio.ontimeupdate = changeTimelinePosition;
        audio.onended = audioEnded;
    }
    const changeSeek = (id: string, event: React.ChangeEvent<HTMLInputElement>) => {
        var timelineNew: any = document.getElementById('timeline' + id);
        var audioNew: any = document.getElementById('audio' + id);
        if (timeline) {
            const time = (timeline.value * audio.duration) / 100;
            audio.currentTime = time;
            timeline.value = event.target.value;
            // setCurTime(formatSecondsAsTime(audio.currentTime, ':'));
        } else {

            const time = (timelineNew.value * audioNew?.duration) / 100;
            audioNew.currentTime = time;
            timelineNew.value = event.target.value;
            setTimeline(timelineNew);
            setAudio(audioNew);
            //setCurTime(formatSecondsAsTime(audioNew.currentTime, ':'));
        }

    }
    return <div className="audio-player w-full">
        <audio id={"audio" + uniqueID} src="https://filesamples.com/samples/audio/mp3/sample1.mp3"></audio>
        <div className="controls">
            <button id={"audioPlayer" + uniqueID} onClick={() => playAudio(uniqueID)} className={classNames(styles.player, "player-button")}>
                <PlayerIcon width={30} height={30} />
            </button>
            <div className={classNames(styles.timeleContainer, "flex items-center w-full")}>
                <span id={"audioCurrentTime" + uniqueID} className="sm:block hidden font-poppins text-12 font-normal text-black2 mr-2">{audio ? formatSecondsAsTime(audio?.currentTime, ':') : '0:00'}</span>
                <input onChange={(event) => changeSeek(uniqueID, event)}
                    id={"timeline" + uniqueID} type="range" className="timeline w-full" max="100" value="0" />
                <span className="sm:block hidden font-poppins text-12 font-normal text-black2">{audio ? formatSecondsAsTime(audio.duration, ':') : '0:0'}</span>
            </div>
        </div>
    </div>
}
export default AudioPlayerCustom;