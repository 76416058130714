import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { Link } from "react-router-dom";
import { Chart, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from 'react-chartjs-2';
import styles from "./DashboardDocument.module.scss";
import annoarrow from "../../components/DashboardAnnoucement/img/anno-arrow.svg";
import { CircularProgressbar } from "react-circular-progressbar";
import design from "./img/design-icon.svg";
import pdf from "./img/pdf-icon.svg";
import hr from "./img/hr-icon.svg";
import Scrollbars from "react-custom-scrollbars";
import { plugins } from "pretty-format";
import { IDocsIcon } from "../DashboardProfile/Documents/icons/IconMapper";
import HR from './img/hr-icon.svg';
import PDF from './img/pdf-icon.svg';
import Design from './img/design-icon.svg';
import CustomScrollbar from "../CustomScrollbar/CustomScrollbar";
import { getAuthUser } from "../../app/services/dashboard";
// import hricon from "./img/Vector-hr-icon.svg";
// import frontendicon from "./img/Vector-frontend-icon.svg";
// import designicon from "./img/Vectorhr-design-icon.svg";
var classNames = require("classnames");
export const renderThumb = (props: any) => {
  const { style, ...rest } = props;
  const thumbStyle = {
    backgroundColor: '#3699FF',
    borderRadius: '3px',
  };
  return <div style={{ ...style, ...thumbStyle }} {...rest} />;
};
export const DocsIcon: IDocsIcon = {
  hr: HR,
  pdf: PDF,
  design: Design
};
export const documentsData = {
  data: [
    {
      name: "HR",
      amount: 12,
    },
    {
      name: "VORP",
      amount: 12,
    },
    {
      name: "DESIGN",
      amount: 12,
    },
    {
      name: "Laravel",
      amount: 12,
    },
    {
      name: "Project",
      amount: 12,
    },
  ],
  totalAmount: "512GB",
  totalFiles: "13, 551"
}
const DashboardDocument = (props: any) => {
  let firstData: any;
  const permission=getAuthUser()?.permissions?.find((x:any)=>x.name=="View_Document");
 // const totalFilesNumber = props.totalDateUse?.toLocaleString("en-US");
  var doc = {
    labels: props.dataLists?.data?.map((x:any) => x.name),
    datasets: [
      {
        //label: 'documents',
        data: props.dataLists?.data?.map((x:any) => x.total),
        backgroundColor: [
          '#E1F0FF',
          '#FDC88E',
          '#7af2ec',
          '#f57e8b',
          '#1BC5BD',
          'rgba(255, 159, 64, 0.2)',
        ],
        borderWidth: 1,
        options: {
          plugins: {
            legend: {
              display: false
            }
          }
        }
      }
    ]
  }
  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
  };
  return (
    <>
      <div className="bg-white pt-[25px] pb-[7px] px-5 h-full">
        <div className={classNames(" pr-4 flex items-center justify-between pr-[10px]")}>
          <p
            className={classNames(
              styles.activeTitle,
              "text-left font-primary text-black2 text-14 xl:text-18 font-semibold"
            )}
          >
            Total Documents
          </p>
          <Link to={permission?"/documents":'#'}>
            <button className={classNames(styles.button, "bg-offwhite flex items-center justify-center rounded-md w-9 h-9")} aria-label="">
              <img src={annoarrow} alt="annoarrow-icon" />
            </button>
          </Link>
        </div>
        <p className="text-left text-12 text-graydark font-primary">{props.dataLists?.totalFiles}</p>
        <div className="my-[30px] max-w-[203px] mx-auto relative">
          <div className="relative mr-4">
            <Doughnut data={doc} options={options} />
            <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
              <p className="font-poppins font-bold text-12 text-[#464E5F] text-center">
              {props.dataLists?.totalFiles}
              </p>
              <p className="font-poppins font-bold text-12 text-[#464E5F] text-center">
                Total Size
              </p>
              <p className="font-poppins font-medium text-12 text-[#858795] mt-1 text-center">
                {props.dataLists?.totalVolume}MB
              </p>
            </div>
          </div>
        </div>
        <div className={classNames("h-[216px] pr-2")}>
          <CustomScrollbar isVertical={true}>
            <div>
              {props.dataLists?.data?.map((data: any, index: number) => {
                var img = data.name.toLowerCase() == "hr" ? HR : (data.name.toLowerCase() == "frontend" ? PDF : (data.name.toLowerCase() == "design") ? Design : HR);
                return (
                  <div key={index} className="flex justify-between lg:mb-8 mb-4 pr-4">
                    <div className="flex items-center">
                      <img
                        src={img}
                        alt="icon"
                        className="xl:mr-4 mr-1 object-contain"
                      />
                      <div>
                        <p className="text-left font-poppins text-sm font-semibold text-[#464E5F]">
                          {data.name}
                        </p>
                        <p className="text-left font-poppins text-sm font-normal text-[#858795]">
                          {data.total}
                        </p>
                      </div>
                    </div>
                    <p className="text-left font-poppins text-sm font-normal text-[#858795]">
                      {data.volume}MB
                    </p>
                  </div>
                );
              })}
            </div>
          </CustomScrollbar>

        </div>
      </div>
    </>
  );
};

export default DashboardDocument;
