export type FetchBaseQueryError =
    | {
        /**
         * * `number`:
         *   HTTP status code
         */
        status: number
        data: { status: number, message: string | number }
    }
    | {
        /**
         * * `"FETCH_ERROR"`:
         *   An error that occurred during execution of `fetch` or the `fetchFn` callback option
         **/
        status: 'FETCH_ERROR'
        data?: { status: boolean, message: string | number }
        error: string
    }
    | {
        /**
         * * `"PARSING_ERROR"`:
         *   An error happened during parsing.
         *   Most likely a non-JSON-response was returned with the default `responseHandler` "JSON",
         *   or an error occurred while executing a custom `responseHandler`.
         **/
        status: 'PARSING_ERROR'
        originalStatus: number
        data: { status: boolean, message: string | number }
        error: string
    }
    | {
        /**
         * * `"CUSTOM_ERROR"`:
         *   A custom error type that you can return from your `queryFn` where another error might not make sense.
         **/
        status: 'CUSTOM_ERROR'
        data?: { status: boolean, message: string | number }
        error: string
    }

export const FetchBaseError = (error: unknown): error is FetchBaseQueryError => {
    return typeof error === 'object' && error != null && 'status' in error
}