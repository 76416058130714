import {
  ColumnDef,
  ColumnFiltersState,
  PaginationState,
  RowSelectionState,
  SortingState,
  createColumnHelper,
} from "@tanstack/react-table";
import React, {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import EditableCell from "../Tables/EditableCell";
import { baseObj } from "../../utils/constants";
import {
  renderDateFormatDMY,
  renderDateFormatYMD,
} from "../DashboardCalendarView/DashboardAddEventPopup";
import DatePopup from "../EmployeeTable/DatePopup";
import classNames from "classnames";
import {
  CancelIcon,
  CheckIcon,
  CloseSmallIcon,
  EditIcon,
  TableSaveIcon,
  TrashIcon,
  ViewIcon,
} from "../common/Icons";
import styles from "./ProjectCheckerAccTable.module.scss";
import LayoutAdjustableTable from "../Tables/LayoutAdjustableTable";
import { templateData } from "../../app/services/template";
import TablePaginator from "../Tables/TablePaginator";
import DeletePopup from "../DeletePopup/DeletePopup";
import { toast } from "react-toastify";
import { tagsColor } from "../Announcements/AnouncementsTable";

import {
  useApproveOTMutation,
  useDeleteOTListMutation,
  useDeleteOTMutation,
  useRejectOTMutation,
} from "../../app/services/otlist";
import { makeStyles } from "@mui/styles";
import ErrorPopup from "../ErrorPopup/ErrorPopup";
import { getAuthUser } from "../../app/services/dashboard";
import { useDispatch, useSelector } from "react-redux";
import { setProfileNotification } from "../../reducers/dashboard";
import {
  useDeleteClaimTypeMutation,
  useMultiDeleteClaimTypeMutation,
} from "../../app/services/claim-type";
import { useNavigate } from "react-router-dom";
import CustomDropdownWithAddButton from "../EmployeeTable/CustomDropdownWithAddButton";
import { EmployeeData } from "../EmployeeTable/EmployeeTable";
import { Modal, Tooltip } from "@mui/material";
import AssignPopup from "../Passwords/AssignPopup";
import { KeyValueLabel } from "../../app/services/password";
import CommonTableBottom from "../CommonTableBottom/CommonTableBottom";
import { assignedColor } from "../Documents/EmployeeDocumentsTable";
interface IOTTable {
  isLayoutControlOpen: boolean;
  setIsLayoutControlOpen: Dispatch<SetStateAction<boolean>>;
  pagination: PaginationState;
  setPagination: Dispatch<SetStateAction<PaginationState>>;
  globalSearch: string;
  setGlobalSearch: Dispatch<SetStateAction<string>>;
  columnFilters: ColumnFiltersState;
  setColumnFilters: Dispatch<SetStateAction<ColumnFiltersState>>;
  data: baseObj[];
  setData: Dispatch<SetStateAction<baseObj[]>>;
  hiddenFields: string[];
  templateList: templateData[];
  setHiddenFields: any;
  setCurrentView: any;
  total: number;
  changePage: any;
  setCurrentPage: any;
  currentPage: number;
  setIsEdit: Dispatch<SetStateAction<boolean>>;
  isEditing?: boolean;
  setIsTemplate?: Dispatch<SetStateAction<boolean>>;
  localModuleId: number;
  visArray: any;
  setCols?: any;
  setIsSuccess?: React.Dispatch<React.SetStateAction<boolean>>;
  isSuccess?: boolean;
  setSkip: React.Dispatch<React.SetStateAction<boolean>>;
  sorting: SortingState;
  setSorting: React.Dispatch<React.SetStateAction<SortingState>>;
  manageClaim?: boolean;
  totalTime?: string;
  cost_spent_usd?: string;
  cost_spent_sgd?: string;
  cost_spent_hkd?: string;
  cost_spent_cny?: string;
  cost_spent_mmk?: string;
  cost_spent_myr?: string;
  cost_spent_php?: string;
  cost_spent_aud?: string;
}

const useStyles = makeStyles(() => ({
  tooltip: {
    backgroundColor: "transparent",
    margin: 0,
    marginRight: 8,
    padding: 0,
  },
}));

const tagsTasks = [
  {
    id: 1,
    name: "UIUX",
  },
  {
    id: 1,
    name: "Frontend",
  },
  {
    id: 1,
    name: "Backend",
  },
];

const ProjectCheckerAccTable: FC<IOTTable> = ({
  isLayoutControlOpen,
  setIsLayoutControlOpen,
  pagination,
  setPagination,
  globalSearch,
  setGlobalSearch,
  columnFilters,
  setColumnFilters,
  data,
  setData,
  hiddenFields,
  total,
  changePage,
  setCurrentPage,
  currentPage,
  setIsEdit,
  isEditing,
  setIsTemplate,
  localModuleId,
  visArray,
  setCols,
  setSkip,
  setSorting,
  sorting,
  totalTime,
  cost_spent_usd,
  cost_spent_sgd,
  cost_spent_hkd,
  cost_spent_cny,
  cost_spent_mmk,
  cost_spent_myr,
  cost_spent_php,
  cost_spent_aud,
}) => {
  const classes = useStyles();
  const divRef = useRef(null);
  const columnHelper = useMemo(() => createColumnHelper<any>(), []);
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  const [timeSpentPos, setTimeSpentPos] = useState<number>();
  const [timeAudPos, setTimeAudPos] = useState<number>();
  const [timeCNYPos, setTimeCNYPos] = useState<number>();
  const [timeHKDPos, setTimeHKDPos] = useState<number>();
  const [timeMMKPos, setTimeMMKPos] = useState<number>();
  const [timeMYRPos, setTimeMYRPos] = useState<number>();
  const [timePHPPos, setTimePHPPos] = useState<number>();
  const [timeSGDPos, setTimeSGDPos] = useState<number>();
  const [timeUSDPos, setTimeUSDPos] = useState<number>();

  const [timeAudPosClass, setTimeAudPosClass] = useState<string>();
  const [timeCNYPosClass, setTimeCNYPosClass] = useState<string>();
  const [timeHKDPosClass, setTimeHKDPosClass] = useState<string>();
  const [timeMMKPosClass, setTimeMMKPosClass] = useState<string>();
  const [timeMYRPosClass, setTimeMYRPosClass] = useState<string>();
  const [timePHPPosClass, setTimePHPPosClass] = useState<string>();
  const [timeSGDPosClass, setTimeSGDPosClass] = useState<string>();
  const [timeUSDPosClass, setTimeUSDPosClass] = useState<string>();

  const [salePos, setSalePos] = useState<number>();
  const [costPos, setCostPos] = useState<number>();
  const [totalPos, setTotalPos] = useState<number>();
  const [customClass, setCustomClass] = useState<string>('');
  const [bottom, setBottom] = useState<number>();

  const [itemCount, setItemCount] = useState<number>(total);
  const [errorPopup, setErrorPopup] = useState<boolean>(false);
  const [message, setMessage] = useState<any>([]);
  const [width, setWidth] = useState<number>();
  const calculateTotalSpentTime = () => {
    var width = document.getElementById('sidebar')?.getBoundingClientRect()?.width;
    var showSidebar = document.getElementById('sidebar')?.classList[0].includes('hidden');
    var sidebarWidth = !showSidebar ? width ? width : 0 : 0;
    const left = document.getElementById('headertime_spent')?.getBoundingClientRect()?.left;
    const total = document.querySelector('table tr td:first-child')?.getBoundingClientRect().left;// document.getElementById('headerproject_id')?.getBoundingClientRect()?.left;    
    setTotalPos(total ? total : 0)
    setTimeSpentPos(left ? left : 0);
    const bottom = document.getElementById('table-scroll')?.getBoundingClientRect()?.bottom;
    const salePos = document.getElementById('headersale')?.getBoundingClientRect()?.left;
    setSalePos(salePos);
    const costPos = document.getElementById('headercost_spent')?.getBoundingClientRect()?.left;
    setCostPos(costPos)
    setBottom(bottom ? bottom : 0)
    const aud = document.getElementById('headercost_spent_aud')?.getBoundingClientRect()?.left;
    setTimeAudPos(aud ? aud - sidebarWidth : 0);
    setTimeAudPosClass(aud ? '' : 'hidden');

    const cny = document.getElementById('headercost_spent_cny')?.getBoundingClientRect()?.left;
    setTimeCNYPos(cny ? cny - sidebarWidth : 0);
    setTimeCNYPosClass(cny ? '' : 'hidden');

    const hkd = document.getElementById('headercost_spent_hkd')?.getBoundingClientRect()?.left;
    setTimeHKDPos(hkd ? hkd - sidebarWidth : 0);
    setTimeHKDPosClass(hkd ? '' : 'hidden');

    const mmk = document.getElementById('headercost_spent_mmk')?.getBoundingClientRect()?.left;
    setTimeMMKPos(mmk ? mmk - sidebarWidth : 0);
    setTimeMMKPosClass(mmk ? '' : 'hidden');

    const myr = document.getElementById('headercost_spent_myr')?.getBoundingClientRect()?.left;
    setTimeMYRPos(myr ? myr - sidebarWidth : 0);
    setTimeMYRPosClass(myr ? '' : 'hidden');

    const php = document.getElementById('headercost_spent_php')?.getBoundingClientRect()?.left;
    setTimePHPPos(php ? php - sidebarWidth : 0);
    setTimePHPPosClass(php ? '' : 'hidden');

    const sgd = document.getElementById('headercost_spent_sgd')?.getBoundingClientRect()?.left;
    setTimeSGDPos(sgd ? sgd - sidebarWidth : 0);
    setTimeSGDPosClass(sgd ? '' : 'hidden');

    const usd = document.getElementById('headercost_spent_usd')?.getBoundingClientRect()?.left;
    setTimeUSDPos(usd ? usd - sidebarWidth : 0);
    setTimeUSDPosClass(usd ? '' : 'hidden');
    // if (left == undefined) {
    //   setCustomClass('hidden')
    // }
  }
  const handleScroll = () => {
    calculateTotalSpentTime();
  };
  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      setWidth(entries[0].contentRect.width);
    });

    if (divRef.current) {
      observer.observe(divRef.current);
    }

    return () => {
      divRef.current && observer.unobserve(divRef.current);
    };
  }, []);
  useEffect(() => {
    calculateTotalSpentTime();
  }, [width])
  useEffect(() => {
    calculateTotalSpentTime()
  }, [data])
  useEffect(() => {
    const handleResize = () => {
      calculateTotalSpentTime();
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  useEffect(() => {
    const handleScroll = () => {
      calculateTotalSpentTime()
    };
    document.getElementById('content-body')?.addEventListener('scroll', handleScroll);
    return () => {
      document.getElementById('content-body')?.removeEventListener('scroll', handleScroll);
    };
  }, []);
  useEffect(() => {
    calculateTotalSpentTime();
  }, [data])
  const columns = useMemo(
    () => [
      columnHelper.accessor("project_id", {
        header: "Project ID",
        id: "project_id",
        enableSorting: true,
        cell: ({ getValue, cell }) => {
          const id = getValue();
          return (
            <div className=" text-left bg-white text-black2 px-[10px] font-normal">
              <p className="h-full flex items-center">{id}</p>
            </div>
          );
        },
      }),
      columnHelper.accessor("project_name", {
        header: "Project Name",
        id: "project_name",
        enableSorting: true,
        cell: ({ getValue, cell }) => {
          const id = getValue();
          return (
            <div className=" text-left bg-white text-black2 px-[10px] font-normal">
              <p className="h-full flex items-center">{id}</p>
            </div>
          );
        },
      }),
      columnHelper.accessor("stage", {
        header: "Stage",
        id: "stage",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const stages = getValue();
          return (
            <div className="text-left font-normal flex flex-wrap max-h-[22px] overflow-y-hidden overflow-x-auto items-center px-2">
              <Tooltip
                arrow
                className="customTooltip"
                classes={{ tooltip: classes.tooltip }}
                enterTouchDelay={1000}
                leaveTouchDelay={5000}
                placement="left"
                title={
                  <>
                    <div className="text-white text-[10px] hover:cursor-pointer leading-[15px] z-[2] rounded p-4 w-full bg-tooltipbg">
                      {stages?.map((x: any, index: number) => {
                        return <span key={index} className="w-full block">{x.name}</span>
                      })}
                    </div>
                  </>
                }
              >
                <div className="flex flex-wrap">
                  {stages?.length > 0 ? stages.map((stage: any, index: number) => {
                    return <span className="bg-vorpgreen text-white px-[5px] rounded-[5px] mx-2 mb-1" key={index}>{stage?.name}</span>
                  }) : <span className="flex w-full justify-center h-full items-center">---</span>}
                </div>
              </Tooltip>

            </div>
          );
        },
      }),
      columnHelper.accessor("team_leader_name", {
        header: "Project Lead",
        id: "team_leader_name",
        enableSorting: true,
        cell: ({ getValue, cell }) => {
          const team_leader_name = getValue();
          const team_leader_code= cell.row.original.team_leader_code;
          return (
            <div className=" min-w-[140px] text-left font-normal flex items-center px-2">
              <span>{team_leader_name} - {team_leader_code}</span>
            </div>
          );
        },
      }),
      columnHelper.accessor("project_manager_name", {
        header: "Project Manager",
        id: "project_manager_name",
        enableSorting: true,
        cell: ({ getValue, cell }) => {
          const project_manager_name = getValue();
          const project_manager_code = cell.row.original.project_manager_code;
          return (
            <div className=" min-w-[140px] text-left font-normal flex items-center px-2">
              <span>{project_manager_name} - {project_manager_code}</span>
            </div>
          );
        },
      }),
      columnHelper.accessor("employees", {
        header: "Employees",
        id: "employees",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const employees = getValue();
          return (
            <button
              type="button"
              className="min-w-[150px] flex justify-center items-center h-full ml-[10px]"
            >
              <Tooltip
                arrow
                className="customTooltip"
                classes={{ tooltip: classes.tooltip }}
                enterTouchDelay={1000}
                leaveTouchDelay={5000}
                placement="left"
                title={
                  <>
                    <div className="text-white text-[10px] hover:cursor-pointer leading-[15px] z-[2] rounded p-4 w-full bg-[#6b6a66]">
                      {employees?.map((x: any, index: number) => {
                        return <div className="flex" key={index}>
                          <span className="block text-12 w-[110px] pr-2">{x.name}{x.project_roles ? ':' : ''} </span>
                          <span className="text-12 w-full">{x.project_roles?.map((x: any) => x.name)?.join(', ')}</span></div>;
                      })}
                    </div>
                  </>
                }
              >
                <div className="flex ">
                  {employees?.map((x: any, index: number) => {
                    if (index < 3) {
                      var idx = index;
                      if (idx > 4) {
                        idx = 0;
                      }

                      return x.profileImage ? (
                        <img
                          key={index}
                          src={x.profileImage}
                          alt="profileImage"
                          className="w-[35px] h-[35px] rounded-full object-cover"
                        />
                      ) : (
                        <div
                          key={index}
                          className={classNames(
                            "bg-[" + assignedColor[idx] + "]",
                            "ml-[-10px] w-[35px] h-[35px] rounded-full text-14 flex justify-center items-center text-white"
                          )}
                        >
                          {x.name?.substring(0, 1)}
                        </div>
                      );
                    } else {
                      return null;
                    }
                  })}
                  {employees?.length > 3 ? (
                    <div className="ml-[-10px] w-[35px] h-[35px] rounded-full text-10 flex justify-center items-center bg-black2 text-white">
                      {employees?.length - 3}+
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </Tooltip>
            </button>
          );
        },
      }),
      columnHelper.accessor("time_spent", {
        header: "Time Spent",
        id: "time_spent",
        enableSorting: true,
        cell: ({ getValue, cell }) => {
          const time_spent = getValue();
          return (
            <div className={`text-left font-normal px-2`}>
              <span className="h-full flex items-center min-w-[100px]">{time_spent}</span>
            </div>
          );
        },
      }),
      columnHelper.accessor("start_date", {
        header: "Start Date",
        id: "start_date",
        enableSorting: true,
        cell: ({ getValue, cell }) => {
          const start_date = getValue();
          return (
            <div className={`text-left font-normal px-2`}>
              <span className="h-full flex items-center">{start_date?start_date:'---'}</span>
            </div>
          );
        },
      }),
      columnHelper.accessor("launch_date", {
        header: "Launch Date",
        id: "launch_date",
        enableSorting: true,
        cell: ({ getValue, cell }) => {
          const launch_date = getValue();
          return (
            <div className={`text-left font-normal px-2`}>
              <span className="h-full flex items-center">{launch_date?launch_date:'---'}</span>
            </div>
          );
        },
      }),
      columnHelper.accessor("sale_aud", {
        header: "Sale AUD",
        id: "sale_aud",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const time_spent = getValue();
          return (
            <div className={`text-left font-normal px-2 min-w-[100px]`}>
              <span className="h-full flex items-center">{time_spent ? time_spent : 0}</span>
            </div>
          );
        },
      }),
      columnHelper.accessor("sale_cny", {
        header: "Sale CNY",
        id: "sale_cny",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const time_spent = getValue();
          return (
            <div className={`text-left font-normal px-2 min-w-[100px]`}>
              <span className="h-full flex items-center">{time_spent ? time_spent : 0}</span>
            </div>
          );
        },
      }),
      columnHelper.accessor("sale_hkd", {
        header: "Sale HKD",
        id: "sale_hkd",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const time_spent = getValue();
          return (
            <div className={`text-left font-normal px-2 min-w-[100px]`}>
              <span className="h-full flex items-center">{time_spent ? time_spent : 0}</span>
            </div>
          );
        },
      }),
      columnHelper.accessor("sale_mmk", {
        header: "Sale MMK",
        id: "sale_mmk",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const time_spent = getValue();
          return (
            <div className={`text-left font-normal px-2 min-w-[100px]`}>
              <span className="h-full flex items-center">{time_spent ? time_spent : 0}</span>
            </div>
          );
        },
      }),
      columnHelper.accessor("sale_myr", {
        header: "Sale MYR",
        id: "sale_myr",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const time_spent = getValue();
          return (
            <div className={`text-left font-normal px-2 min-w-[100px]`}>
              <span className="h-full flex items-center">{time_spent ? time_spent : 0}</span>
            </div>
          );
        },
      }),
      columnHelper.accessor("sale_php", {
        header: "Sale PHP",
        id: "sale_php",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const time_spent = getValue();
          return (
            <div className={`text-left font-normal px-2 min-w-[100px]`}>
              <span className="h-full flex items-center">{time_spent ? time_spent : 0}</span>
            </div>
          );
        },
      }),
      columnHelper.accessor("sale_sgd", {
        header: "Sale SGD",
        id: "sale_sgd",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const time_spent = getValue();
          return (
            <div className={`text-left font-normal px-2 min-w-[100px]`}>
              <span className="h-full flex items-center">{time_spent ? time_spent : 0}</span>
            </div>
          );
        },
      }),
      columnHelper.accessor("sale_usd", {
        header: "Sale USD",
        id: "sale_usd",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const time_spent = getValue();
          return (
            <div className={`text-left font-normal px-2 min-w-[100px]`}>
              <span className="h-full flex items-center">{time_spent ? time_spent : 0}</span>
            </div>
          );
        },
      }),
      columnHelper.accessor("cost_spent_aud", {
        header: "Cost Spent AUD",
        id: "cost_spent_aud",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const cost_spent = getValue();
          return (
            <div className={`text-left font-normal px-2 min-w-[100px]`}>
              <span className="h-full flex items-center">{cost_spent ? cost_spent : 0}</span>
            </div>
          );
        },
      }),
      columnHelper.accessor("cost_spent_cny", {
        header: "Cost Spent CNY",
        id: "cost_spent_cny",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const cost_spent = getValue();
          return (
            <div className={`text-left font-normal px-2 min-w-[100px]`}>
              <span className="h-full flex items-center">{cost_spent ? cost_spent : 0}</span>
            </div>
          );
        },
      }),
      columnHelper.accessor("cost_spent_hkd", {
        header: "Cost Spent HKD",
        id: "cost_spent_hkd",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const cost_spent = getValue();
          return (
            <div className={`text-left font-normal px-2 min-w-[100px]`}>
              <span className="h-full flex items-center">{cost_spent ? cost_spent : 0}</span>
            </div>
          );
        },
      }),
      columnHelper.accessor("cost_spent_mmk", {
        header: "Cost Spent MMK",
        id: "cost_spent_mmk",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const cost_spent = getValue();
          return (
            <div className={`text-left font-normal px-2 min-w-[100px]`}>
              <span className="h-full flex items-center">{cost_spent ? cost_spent : 0}</span>
            </div>
          );
        },
      }),
      columnHelper.accessor("cost_spent_myr", {
        header: "Cost Spent MYR",
        id: "cost_spent_myr",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const cost_spent = getValue();
          return (
            <div className={`text-left font-normal px-2 min-w-[100px]`}>
              <span className="h-full flex items-center">{cost_spent ? cost_spent : 0}</span>
            </div>
          );
        },
      }),
      columnHelper.accessor("cost_spent_php", {
        header: "Cost Spent PHP",
        id: "cost_spent_php",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const cost_spent = getValue();
          return (
            <div className={`text-left font-normal px-2 min-w-[100px]`}>
              <span className="h-full flex items-center">{cost_spent ? cost_spent : 0}</span>
            </div>
          );
        },
      }),
      columnHelper.accessor("cost_spent_sgd", {
        header: "Cost Spent SGD",
        id: "cost_spent_sgd",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const cost_spent = getValue();
          return (
            <div className={`text-left font-normal px-2 min-w-[100px]`}>
              <span className="h-full flex items-center">{cost_spent ? cost_spent : 0}</span>
            </div>
          );
        },
      }),
      columnHelper.accessor("cost_spent_usd", {
        header: "Cost Spent USD",
        id: "cost_spent_usd",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const cost_spent = getValue();
          return (
            <div className={`text-left font-normal px-2 min-w-[100px]`}>
              <span className="h-full flex items-center">{cost_spent ? cost_spent : 0}</span>
            </div>
          );
        },
      }),
      columnHelper.accessor("profit", {
        header: "Profit/Loss",
        id: "profit",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const profit = getValue();
          return (
            <div className={`text-left font-normal px-2`}>
              <span className={classNames(profit?.sign == "+" ? 'text-vorpgreen' : 'text-redPink', "h-full flex items-center")}>{profit ? profit.value : 0}</span>
            </div>
          );
        },
      }),
    ],
    [columnHelper, data, hiddenFields, columnFilters]
  );

  const visibleColumns: { [key: string]: boolean } = {};
  hiddenFields?.map((x) => {
    visibleColumns[x] = true;
  });

  document.querySelectorAll("th,td").forEach((x) => {
    if (x.id.includes("none")) {
      x.classList.add("hidden");
    }

    var div = document.getElementById(x.id);
  });

  if (true) {
    visibleColumns["actions"] = true;
  } else {
    visibleColumns["actions"] = false;
  }

  if (hiddenFields.length > 0) {
    columns.map((x) => {
      if (x.id == "actions" || x.id == "selection" || x.id == "lasted") {
        visibleColumns[x.id ? x.id : ""] = true;
      } else if (x.id == "uniqeID") {
        visibleColumns[x.id ? x.id : ""] = false;
      } else {
        var isHidden = hiddenFields.find((h) => h == x.id);
        if (isHidden) {
          visibleColumns[x.id ? x.id : ""] = true;
        } else {
          visibleColumns[x.id ? x.id : ""] = false;
        }
      }
    });
  }
  const [columnOrder, setColumnOrder] = useState<string[]>(
    columns.map((column: ColumnDef<any, any>) => column.id as string)
  );
  useEffect(() => {
    setColumnOrder(columns.map((column: ColumnDef<any, any>) => column.id as string))
  }, [hiddenFields])
  return (
    <div ref={divRef} id="progress-checker-acc" className={classNames(styles.otListTable, "relative")}>
      <ErrorPopup
        descText="Error Message!"
        confirmBtnText="Yes, Close."
        show={errorPopup}
        setShow={setErrorPopup}
        message={message}
        setMessage={setMessage}
      />

      <LayoutAdjustableTable
        unShowFields={["project_id", "project_name", "sale_hkd", "cost_spent_cny", "cost_spent_hkd"]}
        hideColumn={visibleColumns}
        columns={columns}
        data={data}
        sorting={sorting}
        setSortingState={setSorting}
        rowSelection={rowSelection}
        setRowSelection={setRowSelection}
        columnOrder={columnOrder}
        setColumnOrder={setColumnOrder}
        unDraggableFields={["actions", "selection", "id"]}
        theaderColor="text-graydark"
        isLayoutControlOpen={isLayoutControlOpen}
        setIsLayoutControlOpen={setIsLayoutControlOpen}
        label="Progress Checker Account"
        setItemCount={setItemCount}
        pagination={pagination}
        setPagination={setPagination}
        globalFilter={globalSearch}
        setGlobalFilter={setGlobalSearch}
        columnFilters={columnFilters}
        setColumnFilters={setColumnFilters}
        noPaddingCells={[]}
        localModuleId={localModuleId}
        visArray={visArray}
        setIsTemplate={setIsTemplate}
        setCols={setCols}
        handleScroll={handleScroll}
      />
      <CommonTableBottom data={[
        {
          name: totalTime ? "Total" : '',
          colSpan: 5,
          left: 0,
          bottom: bottom,
          classes: totalTime ? customClass+' min-w-max' : 'hidden'
        },
        {
          name: cost_spent_aud ? cost_spent_aud : '',
          colSpan: 3,
          left: timeAudPos,
          bottom: bottom,
          classes: timeAudPosClass + ' z-[9]'
        },
        {
          name: cost_spent_cny ? cost_spent_cny : '',
          colSpan: 3,
          left: timeCNYPos,
          bottom: bottom,
          classes: timeCNYPosClass + ' z-[8]'
        },
        {
          name: cost_spent_hkd ? cost_spent_hkd : '',
          colSpan: 3,
          left: timeHKDPos,
          bottom: bottom,
          classes: timeHKDPosClass + ' z-[7]'
        },
        {
          name: cost_spent_mmk ? cost_spent_mmk : '',
          colSpan: 3,
          left: timeMMKPos,
          bottom: bottom,
          classes: timeMMKPosClass + ' z-[6]'
        },
        {
          name: cost_spent_myr ? cost_spent_myr : '',
          colSpan: 3,
          left: timeMYRPos,
          bottom: bottom,
          classes: timeMYRPosClass + ' z-[5]'
        },
        {
          name: cost_spent_php ? cost_spent_php : '',
          colSpan: 3,
          left: timePHPPos,
          bottom: bottom,
          classes: timePHPPosClass + ' z-[4]'
        },
        {
          name: cost_spent_sgd ? cost_spent_sgd : '',
          colSpan: 3,
          left: timeSGDPos,
          bottom: bottom,
          classes: timeSGDPosClass + ' z-[3]'
        },
        {
          name: cost_spent_usd ? cost_spent_usd : '',
          colSpan: 3,
          left: timeUSDPos,
          bottom: bottom,
          classes: timeUSDPosClass + ' z-[2]'
        },
      ]}
      />
      <div className="pt-2">
        <TablePaginator
          total={itemCount}
          pagination={pagination}
          setPagination={setPagination}
          itemCount={total}
          setCurrentPage={setCurrentPage}
          currentPageDefault={currentPage}
          changePage={changePage}
        />
      </div>
    </div>
  );
};
export default ProjectCheckerAccTable;
