import classNames from "classnames";
import DebounceSearchInput from "../../components/Tables/DebounceSearchInput";
import { useEffect, useState } from "react";
import { SearchIcon } from "../../components/common/Icons";
// import CustomDropdownListForFilter from "../../components/EmployeeTable/CustomDropdownListForFilter";
import styles from "./AnnoucementList.module.scss";
import { NumberDropdownList } from "../../components/common/DropdownList";
import { PaginationState } from "@tanstack/react-table";
import TablePaginator from "../../components/Tables/TablePaginator";
import { useGetProfileAnnoucementsQuery } from "../../app/services/profile-annoucements";
import AnnoucementModal, { IAnnoucementData } from "./AnnoucementModal";
import { useViewNotificationMutation } from "../../app/services/notification";
// const notiStatusList = ["All", "Read", "Unread"];
// const annoucementExpired = [
//   {
//     id: "All",
//     value: "All",
//   },
//   {
//     id: "0",
//     value: "Non-Expired",
//   },
//   {
//     id: "1",
//     value: "Expired",
//   },
// ];
const AnnoucementList = () => {
  const [globalSearch, setGlobalSearch] = useState<string>("");
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isExpired, setIsExpired] = useState<boolean>(false);
  const [clickData, setClickData] = useState<IAnnoucementData>({
    title: "",
    type: "",
    tags: [],
    content: "",
  });
  const [notiStatus, setNotiStatus] = useState<string>("");
  const [itemCount, setItemCount] = useState<number>(0);
  const [annoucementList, setAnnoucementList] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 1,
    pageSize: 20,
  });
  const { data: annoucements } = useGetProfileAnnoucementsQuery({
    limit: pagination.pageSize,
    page: currentPage,
    search: globalSearch,
    sort: "",
    status: notiStatus,
    isexpired: isExpired,
  });
  const [viewNoti] = useViewNotificationMutation();
  useEffect(() => {
    if (annoucements?.announsheets) {
      var noti = annoucements?.announsheets?.data;
      setAnnoucementList(noti);
      setItemCount(annoucements?.announsheets.total);
    }
  }, [annoucements]);
  useEffect(() => {
    setCurrentPage(1);
  }, [globalSearch, pagination.pageSize]);
  return (
    <div>
      <div
        className={classNames(
          "px-2 sm:px-4 py-4 bg-white sm:mt-[25px] mt-0 font-poppins text-13 font-semibold text-[#858795] tracking-[-0.03rem] w-full h-full rounded-xl pt-3 pb-8 sm:pt-3 sm:pb-9 "
        )}
      >
        {/* <div className="mb-5">
                <h1 className="font-bold text-24 text-[#464E5F] text-left">All Announcements</h1>
            </div> */}
        <div className="flex flex-wrap justify-between items-center mb-5">
          <div className="flex flex-wrap">
            <div className="flex items-center mr-2 xs:mr-4 sm:w-[260px] w-full my-1">
              <DebounceSearchInput setState={setGlobalSearch} debounce={800}>
                {(setState) => (
                  <div className="w-full font-normal flex border-1 border-[#C4C4C4] bg-white hover:bg-[#EFEFEF] rounded-[28px]">
                    <input
                      id="new-data-message"
                      autoComplete="new-data-message"
                      type="text"
                      placeholder="Search"
                      className="focus:outline-none py-1 px-4 text-12 xl:text-14 leading-5 bg-transparent rounded-[28px] w-full text-graydark"
                      onChange={(e) => setState(e.target.value)}
                    />
                    <SearchIcon className="mr-2" />
                  </div>
                )}
              </DebounceSearchInput>
            </div>
            {/* <div
                        className={classNames(
                            styles.permissionDropdown,
                            "flex items-center mr-[10px] sm:w-auto w-full my-1"
                        )}
                    >
                      <CustomDropdownListForFilter
                        isFilter={true}
                        state={isExpired}
                        setState={(value) => {
                            setIsExpired(value);
                        }}
                        dropdownItems={annoucementExpired?.map(
                          (status: any) => ({
                            value: status.id,
                            label: status.value,
                          })
                        )}
                        className="max-h-[38px] max-w-full sm:max-w-[250px] 2xl:w-32 sm:w-28 w-full"
                        placeholder="Expired"
                        textAlign="text-left py-2 pl-2 pr-3"
                      />
                    </div> */}
          </div>
          <div className=" my-1 flex items-center xl:ml-3 mr-[10px]">
            <p className="base-label text-12 xl:text-14">DISPLAY</p>
            <NumberDropdownList
              state={pagination.pageSize}
              setState={(value) =>
                setPagination((prev) => ({
                  ...prev,
                  pageSize: value as number,
                }))
              }
              dropdownItems={new Array(5).fill(1).map((_, index: number) => {
                let display = (index + 1) * 20;
                return {
                  value: display,
                  label: String(display),
                };
              })}
              className="max-w-[55px] 4xs:max-w-[60px] lg:max-w-[72px]"
              textAlign="text-left pl-3"
              paddingClass="py-[2px]"
              placeholder="20"
            />
          </div>
        </div>
        <div className="w-full text-left">
          <ul className="">
            {annoucementList?.map((noti: any, key: number) => {
              return (
                <li
                  onClick={() => {
                    setClickData({
                      content: noti.content,
                      tags: noti.tags,
                      title: noti.title,
                      type: noti.type,
                    });
                    setIsOpen(true);
                    viewNoti(noti.id);
                  }}
                  className={classNames(
                    noti.read_at == "" ? "bg-[#F3F6F9] " + styles.redDot : "",
                    styles.customNotiHover,
                    "text-left pb-2 border-b border-b-[#E4E6EF]"
                  )}
                  key={key}
                >
                  <button className="w-full block hover:text-vorpblue font-poppins font-normal text-sm text-graydark cursor-pointer px-6 py-2">
                    <p className="text-left font-poppins lg:text-sm text-xs font-bold text-[#464E5F]">
                      {noti.title}
                    </p>
                    <p className="text-left font-poppins text-[10px] text-[#858795] mt-1 font-normal">
                      {noti.postedDate}
                    </p>
                    <div
                      className="text-left font-poppins text-xs font-normal text-[#858795] mt-1 line-clamp-2 overflow-ellipsis"
                      dangerouslySetInnerHTML={{ __html: noti.content }}
                    ></div>
                  </button>
                </li>
              );
            })}
          </ul>
        </div>
        <div className="pt-6">
          <TablePaginator
            total={itemCount}
            pagination={pagination}
            setPagination={setPagination}
            itemCount={itemCount}
            setCurrentPage={setCurrentPage}
            currentPageDefault={currentPage}
          />
        </div>
      </div>
      <AnnoucementModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        clickData={clickData}
      />
    </div>
  );
};
export default AnnoucementList;
