export const DBusers = 
    [
      {
        "id": 1,
        "email": "george.bluth@reqres.in",
        "username": "George",
        "avatar": "https://reqres.in/img/faces/1-image.jpg",
        "password": "pass1"
      },
      {
        "username": "updated",
        "id": 2,
        "password": "pass2"
      },
      {
        "id": 3,
        "email": "emma.wong@reqres.in",
        "username": "Emma",
        "avatar": "https://reqres.in/img/faces/3-image.jpg",
        "password": "pass3"
      },
      {
        "id": 4,
        "email": "eve.holt@reqres.in",
        "username": "Eve",
        "avatar": "https://reqres.in/img/faces/4-image.jpg",
        "password": "pass4"
      },
      {
        "id": 5,
        "email": "charles.morris@reqres.in",
        "username": "Charles",
        "avatar": "https://reqres.in/img/faces/5-image.jpg",
        "password": "pass5"
      },
      {
        "id": 6,
        "email": "tracey.ramos@reqres.in",
        "username": "Tracey",
        "avatar": "https://reqres.in/img/faces/6-image.jpg",
        "password": "pass6"
      }
]
