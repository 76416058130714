import classNames from "classnames";
import { useEffect, useState } from "react";
import {
  MonitoringDepartmentType,
  MonitoringDetailModal,
  MonitoringEmployeeType,
  ScreenShotData,
} from "../../modals/moniroting/Monitoring";
// import TablePaginator from "../Tables/TablePaginator";
import { useGetScreenShotByIDQuery } from "../../app/services/monitoring";
import { PaginationState } from "@tanstack/react-table";
import TablePaginator from "../../components/Tables/TablePaginator";
import { useNavigate, useParams } from "react-router-dom";
import CommonTableSkeleton from "../../components/TableSkeleton/CommonTableSkeleton";
import styles from "./MonitoringDetailPage.module.scss";
import {
  AddIcon,
  CustomizeLayoutIcon,
  SearchIcon,
  SettingIcon,
} from "../../components/common/Icons";
import { NumberDropdownList } from "../../components/common/DropdownList";
import MultiDatePopupFilter from "../../components/EmployeeTable/MultiDatePopupFilter";
import CustomDropdownListForFilter from "../../components/EmployeeTable/CustomDropdownListForFilter";
import DebounceSearchInput from "../../components/Tables/DebounceSearchInput";
import CustomScrollbar from "../../components/CustomScrollbar/CustomScrollbar";
import Button from "../../components/common/Button";
import { StringDropdownListWithDeleteBtn } from "../../components/common/StringDropdownListWithDeleteBtn";
import { useSpring, animated } from "react-spring";

const MonitoringDetailPage = ({}: MonitoringDetailModal) => {
  let { id } = useParams();

  const [globalSearch, setGlobalSearch] = useState<string>("");
  const [employeeID, setEmployeeID] = useState<number>(0);
  const [departmentID, setDepartmentID] = useState<number>(0);
  const [department, setDepartment] = useState<MonitoringDepartmentType[]>([]);
  const [employees, setEmployees] = useState<MonitoringEmployeeType[]>([]);
  const [screenShot, setScreenShot] = useState<number>(0);
  const [screenShotList, setScreenShotList] = useState<ScreenShotData[]>([]);
  const [imageCount, setImageCount] = useState<number>(0);
  const [dateRange, setDateRange] = useState<any[]>([null, null]);
  const [startDate, endDate] = dateRange;
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  });
  const [currentPage, setCurrentPage] = useState<number>(1);

  const { data: screenShotByID, isLoading } = useGetScreenShotByIDQuery({
    date: "",
    id: id ?? 0,
  });

  useEffect(() => {
    if (screenShotByID) {
      setScreenShotList(screenShotByID.data.data);
      setImageCount(screenShotByID.data.total);
    }
  }, [screenShotByID]);

  const props = useSpring({
    to: { opacity: 1 },
    from: { opacity: 0 },
    delay: 1200,
  });


  
  console.log("real id", id);
  console.log("id", screenShotList);
  return (
    <>
      {isLoading ? (
        <CommonTableSkeleton rows={12} />
      ) : (
        // <div className="w-full  relative">
        //   <div>
        //     <p className="text-left font-poppins font-semibold md:text-14 text-12 text-black2 px-5 py-3 bg-offwhite">
        //       Images
        //     </p>
        //   </div>
        //   <div className={classNames("mt-3")}>
        //     <div className="grid 2xl:grid-cols-5 lg:grid-cols-4 sm:grid-cols-3 xs:grid-cols-2 grid-cols-1 4xl:gap-6 gap-4">
        //       {screenShotList.length
        //         ? screenShotList.map((screen: ScreenShotData, i: any) => (
        //             <div key={i} className="border-1 border-[#DADADA]">
        //               <div className="w-full 5xl:px-2 6xl:py-8 py-2 px-2 6xl:min-h-[125px]">
        //                 <img
        //                   src={`data:image/jpeg;base64,${screen.image}`}
        //                   alt={`${screen.uploaded_at}`}
        //                   className="w-[272px] h-[147px] object-cover mx-auto"
        //                 />
        //                 <div className="flex w-full justify-between font-normal ">
        //                   <div className="w-full text-10">
        //                     {screen.uploaded_at}
        //                   </div>
        //                   <div className="px-2 text-10 text-center bg-vorpblue text-white rounded-md">
        //                     {screen.employee_name}
        //                   </div>
        //                 </div>
        //               </div>
        //             </div>
        //           ))
        //         : null}
        //     </div>
        //   </div>

        //   <div className="pt-6">
        //     {imageCount ? (
        //       <TablePaginator
        //         total={imageCount}
        //         pagination={pagination}
        //         setPagination={setPagination}
        //         itemCount={imageCount}
        //         setCurrentPage={setCurrentPage}
        //         currentPageDefault={currentPage}
        //       />
        //     ) : (
        //       ""
        //     )}
        //   </div>
        // </div>

        <div
          className={classNames(
            "px-2 sm:px-4 py-4 bg-white sm:mt-[25px] mt-0 font-poppins text-13 font-semibold text-[#858795] tracking-[-0.03rem] w-full h-full rounded-xl pt-3 pb-8 sm:pt-3 sm:pb-9 "
          )}
        >
          <div className="flex flex-wrap justify-between items-center">
            <div className="mb-1 mr-[10px] flex ">
              <div className="flex items-center mr-2 xs:mr-4 mb-1">
                <h3 className="font-poppins font-bold text-16 tracking-normal text-[#464E5F]">
                  Monitoring
                </h3>
              </div>
              <div className="flex p-1">
                <p
                  className={classNames(
                    "p-1 cursor-pointer",
                    !screenShot ? "text-vorpblue" : ""
                  )}
                  onClick={() => setScreenShot(0)}
                >
                  Active Report
                </p>
                <p className="p-1">|</p>
                <p
                  className={classNames(
                    "p-1 cursor-pointer",
                    screenShot ? "text-vorpblue" : ""
                  )}
                  onClick={() => setScreenShot(1)}
                >
                  ScreenShot
                </p>
              </div>
            </div>
            <div className="flex justify-end mb-1 sm:mt-0 items-center">
              {true ? (
                <Button
                  label={
                    <span className="flex items-center">
                      <SettingIcon className="mr-[3px]" />{" "}
                      <span className="pl-1">SetUp</span>
                    </span>
                  }
                  onClick={() => {
                    // setIsExportModalOpen(true);
                  }}
                  type="button"
                  variant="no-outline"
                  customClass="my-1 bg-vorpmyanmar border-vorpmyanmar   transition-all duration-300 text-vorpblue xs:text-[12px] leading-[18px] font-medium py-1 px-[6px] sm:mr-[10px] mr-[3px]"
                  size="base"
                />
              ) : null}
            </div>
          </div>

          <div className="flex sm:flex-row w-full flex-col justify-between items-center mb-1">
            <div className="flex w-full sm:mr-5 custom-scrollbar sm:mt-0 mt-1 sm:mb-0 mb-1">
              <CustomScrollbar isVertical={false} isHor={true}>
                <div className="flex w-full  items-center">
                  <div className="pr-3">
                    <DebounceSearchInput
                      setState={setGlobalSearch}
                      debounce={800}
                    >
                      {(setState) => (
                        <div className="font-normal  flex border-1 border-[#C4C4C4] bg-white hover:bg-[#EFEFEF] rounded-[28px]">
                          <input
                            type="text"
                            placeholder="Search"
                            className="focus:outline-none py-1 px-4 text-12 xl:text-14 h-6 min-w-[200px] leading-5 bg-transparent rounded-[28px] w-full text-graydark"
                            onChange={(e) => setState(e.target.value)}
                          />
                          <SearchIcon className={classNames("mr-2")} />
                        </div>
                      )}
                    </DebounceSearchInput>
                  </div>
                  <div
                    className={classNames(
                      styles.permissionDropdown,
                      "flex items-center mr-[10px] w-auto"
                    )}
                  >
                    <p className="hidden sm:w-auto w-1/2 text-left font-poppins font-medium xl:text-sm text-12 text-[#464E5F] mr-1">
                      Employee
                    </p>
                    <CustomDropdownListForFilter
                      isFilter={true}
                      state={employeeID}
                      setState={setEmployeeID}
                      dropdownItems={
                        employees
                          ? [
                              { value: "All", label: "All" },
                              ...employees.map(
                                (st: Partial<MonitoringEmployeeType>) => ({
                                  value: st.id,
                                  label: st.name ?? "",
                                })
                              ),
                            ]
                          : []
                      }
                      className="w-full max-w-full min-w-[100px] xl:min-w-[120px] 3xl:min-w-[120px] 7xl:min-w-[120px] 6xs:text-12 7xl:text-14 max-h-[38px] text-dropdownTextColor"
                      placeholder={"Employee"}
                      textAlign="text-left py-2 pl-2 pr-3"
                    />
                  </div>

                  <div
                    className={classNames("flex items-center mr-[10px] w-auto")}
                  >
                    <p className="hidden sm:w-auto w-1/2 text-left font-poppins font-medium xl:text-sm text-12 text-[#464E5F] mr-1">
                      Department
                    </p>
                    <CustomDropdownListForFilter
                      isFilter={true}
                      state={employeeID}
                      setState={setDepartmentID}
                      dropdownItems={
                        department
                          ? [
                              { value: "All", label: "All" },
                              ...department.map(
                                (st: Partial<MonitoringDepartmentType>) => ({
                                  value: st.id,
                                  label: st.name ?? "",
                                })
                              ),
                            ]
                          : []
                      }
                      className="w-full max-w-full min-w-[100px] xl:min-w-[120px] 3xl:min-w-[120px] 7xl:min-w-[120px] 6xs:text-12 7xl:text-14 max-h-[38px] text-dropdownTextColor"
                      placeholder={"Department"}
                      textAlign="text-left py-2 pl-2 pr-3"
                    />
                  </div>

                  <div className="flex items-center w-auto">
                    <p className="hidden whitespace-nowrap sm:w-auto 2xs:w-[20%] w-1/2 text-left  uppercase xl:text-sm text-12 leading-5 font-medium text-black2 mr-[10px]">
                      Date
                    </p>
                    <div className="flex flex-row w-full">
                      <div className={` mr-[10px] w-full`}>
                        <MultiDatePopupFilter
                          placeholder="Start- End"
                          startDate={startDate}
                          endDate={endDate}
                          setDateRange={setDateRange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </CustomScrollbar>
            </div>

            <div className="flex sm:flex-nowrap flex-wrap items-center sm:justify-start justify-end">
              <div className=" flex items-center md:ml-3 mr-[10px]">
                <p className="base-label xl:text-sm text-12">DISPLAY</p>
                <NumberDropdownList
                  state={pagination.pageSize}
                  setState={(value) =>
                    setPagination((prev) => ({
                      ...prev,
                      pageSize: value as number,
                    }))
                  }
                  dropdownItems={new Array(5)
                    .fill(1)
                    .map((_, index: number) => {
                      let display = (index + 1) * 20;
                      return {
                        value: display,
                        label: String(display),
                      };
                    })}
                  className="max-w-[55px] 4xs:max-w-[60px] lg:max-w-[72px]"
                  textAlign="text-left pl-3"
                  paddingClass="py-[2px]"
                  placeholder="20"
                />
              </div>

              <div
                className={classNames(
                  screenShot === 1 ? "pointer-events-none opacity-50" : ""
                )}
              >
                {/* <StringDropdownListWithDeleteBtn
                  haveDeleteBtn={true}
                  setIsTemplate={setIsTemplate}
                  state={isTemplate ? currentViewData : currentView}
                  setState={setCurrentView}
                  deleteTemplate={deleteTemplateData}
                  dropdownItems={moduleList.map((x: any, index: number) => {
                    return {
                      value: x.id,
                      label: Number(x.id) === 0 ? x.name : "View #" + x.index,
                    };
                  })}
                  className="w-full min-w-[100px] 2xl:min-w-[128px] 6xs:text-12"
                  placeholder="View#1"
                  textAlign="text-left py-2 pl-2 pr-3"
                  custPadding="py-[2px]"
                  setErrorPopup={setErrorPopup}
                  setSuccessPopup={setSuccessPopup}
                  setMessage={setMessage} 
                />*/}
              </div>
              <button
                type="button"
                onClick={() => {
                  // setIsLayoutControlOpen(true)
                }}
                className={classNames(
                  screenShot === 1
                    ? "hidden"
                    : "flex justify-center relative items-center rounded-lg bg-vorphongkongborder hover:bg-[#FFF0D2] w-[29px] h-[29px] ml-2 group transition-all duration-300 "
                )}
              >
                <CustomizeLayoutIcon />
                <div className="absolute left-7 top-[-5px] border border-white w-4 h-4 rounded-full bg-vorphongkongborder flex justify-center items-center">
                  <AddIcon width={8} height={8} color="white" />
                </div>
              </button>
            </div>
          </div>

          <div className="text-left md:mt-0 mt-2">
            <animated.div style={props}>
              <div className="w-full  relative">
                <div>
                  <p className="text-left font-poppins font-semibold md:text-14 text-12 text-black2 px-5 py-3 bg-offwhite">
                    Images
                  </p>
                </div>
                <div className={classNames("mt-3")}>
                  <div className="grid 2xl:grid-cols-6 lg:grid-cols-4 sm:grid-cols-3 xs:grid-cols-2 grid-cols-1 4xl:gap-6 gap-4">
                    {screenShotList.length
                      ? screenShotList.map((screen: ScreenShotData, i: any) => (
                          <div key={i} className="border-1 border-[#DADADA] cursor-pointer">
                            <div className="w-full 5xl:px-2 6xl:py-8 py-2 px-2">
                              <img
                                src={`data:image/jpeg;base64,${screen.image}`}
                                alt={`${screen.uploaded_at}`}
                                className="object-cover mx-auto"
                              />
                              <div className="flex w-full justify-between font-normal ">
                                <div className="w-full text-10">
                                  {screen.uploaded_at}
                                </div>
                                <div className="px-2 text-10 text-center bg-vorpblue text-white rounded-md">
                                  {screen.employee_name}
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                      : null}
                  </div>
                </div>

                <div className="pt-6">
                  {imageCount ? (
                    <TablePaginator
                      total={imageCount}
                      pagination={pagination}
                      setPagination={setPagination}
                      itemCount={imageCount}
                      setCurrentPage={setCurrentPage}
                      currentPageDefault={currentPage}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </animated.div>
          </div>
        </div>
      )}
    </>
  );
};

export default MonitoringDetailPage;
