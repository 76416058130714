import { Fade, Modal, Tooltip } from "@mui/material";
import {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
  useRef,
  memo,
} from "react";
import { CloseIcon, DownloadIcon, ViewIcon } from "../common/Icons";
import classNames from "classnames";
import styles from "./LeaveApplicationFilesPopup.module.scss";
// import CustomDatePicker from "../common/BaseDatePicker";
// import styles from "./LeaveAllowancePopup.module.scss";
// import { renderDateFormat } from "../DashboardCalendarView/DashboardAddEventPopup";
import { Tdocuments } from "../../leave-application/views/LeaveApplication";
import { makeStyles } from "@mui/styles";
import { DocFiles, ImageFormat } from "../../app/services/documents";
import { DocsIcon } from "../DashboardProfile/Documents/icons/IconMapper";
import axios from "../../axios";
import { endpointUrl } from "../../app/services/api";
import { MediaData } from "../../app/services/media";
import { getAuthUser } from "../../app/services/dashboard";
import CommonErrorPopup from "../common/CommonErrorPopup";
export const onViewDocument = async (name: string, type: string,token:string) => {
  const extension = name.split(".").pop() as string;
  //downloadDoc();
  // using Java Script method to get PDF file
  axios({
    url: endpointUrl + `documents/view/${name}`,
    method: "GET",
    responseType: "blob",
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    var blob = new Blob([response.data], { type: type == "pdf" ? 'application/pdf' : type == "svg" ? 'image/svg+xml' : 'image/' + type });
    var blobURL = URL.createObjectURL(blob);
    window.open(blobURL, '_blank');
    return response;
    //setImgSrc(url);
  }).catch((error)=>{
    return error;
  });
};
const useStyles = makeStyles(() => ({
  tooltip: {
    backgroundColor: "transparent",
    margin: 0,
    marginRight: 8,
    padding: 0,
  },
}));

interface ILeaveAllowance {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  leaveDocuments: Tdocuments[];
}
const LeaveApplicationFilesPopup: FC<ILeaveAllowance> = ({
  isOpen,
  setIsOpen,
  leaveDocuments,
}) => {
  const classes = useStyles();
  const [selectedFiles, setSelectedFiles] = useState<number[]>([]);
  const [files, setFiles] = useState<any[]>(leaveDocuments ?? []);
  var getUser = getAuthUser();
  const [error, setError] = useState<string>("");
  const downloadDocumentByID = (fileId?: number) => {
    var id = fileId ? fileId : selectedFiles[0];
    var filedata = files?.find((x) => Number(x.id) === id);
    var name = filedata?.name;
    var type=filedata?.type;
    axios({
      url: endpointUrl + `downloadDocument/${id}`,
      method: "GET",
      responseType: "blob",
      headers: {
        Authorization: `Bearer ${getUser.token}`,
        Accept: "application/json",
      },
    }).then((response) => {
      var blob = new Blob([response.data], { type: type == "pdf" ? 'application/pdf' : type == "svg" ? 'image/svg+xml' : 'image/' + type });
      var blobURL = URL.createObjectURL(blob);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = blobURL;
      link.setAttribute("download", name ?? "");
      link.setAttribute("href", window.URL.createObjectURL(blob));
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      
    });
  };

  const setSelectedItems = (id: number) => {
    if (selectedFiles.includes(id)) {
      setSelectedFiles(selectedFiles?.filter((x) => x !== id));
    } else {
      setSelectedFiles([...selectedFiles, id]);
    }
  };
  useEffect(()=>{
    
  },[error])
  return (
    <>
      <Modal
        open={isOpen}
        style={{
          background: "rgb(0 0 0 / 0.3)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        onClose={(event, reason) => {
          if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
            setIsOpen(false);
          }
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableEnforceFocus={true}
      >
        <Fade in={isOpen} timeout={500}>
          <div className="modalContent max-w-[700px] w-full m-auto px-4 py-8 xl:px-0 xl:py-0 font-primary">
            <div className="w-full bg-white rounded-xl overflow-hidden">
              <div className="px-6 sm:px-8 md:px-10 py-6 bg-vorpblue flex justify-between items-center rounded-t-xl overflow-hidden">
                <h1 className="text-16 sm:text-18 leading-[27px] font-semibold text-white">
                  Supportive Documents
                </h1>
                <button type="button" onClick={() => setIsOpen(false)}>
                  <CloseIcon width={18} height={18} />
                </button>
              </div>
              <div className="px-4 sm:px-6 lg:px-10 py-6 pb-8 lg:py-8 lg:pb-10">
                <div className="grid 2xl:grid-cols-4 lg:grid-cols-3 sm:grid-cols-2 xs:grid-cols-2 grid-cols-1 4xl:gap-6 gap-4 max-h-[400px] overflow-y-auto">
                  {leaveDocuments?.map((file: Tdocuments, index: number) => {
                    const isSelected = selectedFiles.find((x) => x == file.id);
                    const extension = file.type ?? "";
                    return (
                      <div
                        // onDoubleClick={() => {
                        //   if (!DocFiles.includes(extension ? extension : "")) {
                        //     setSelectedFiles([file.id]);
                        //     downloadDocumentByID(file.id);
                        //   } else {
                        //     window.open(file.url, "_blank");
                        //   }
                        // }}

                        onClick={() => setSelectedFiles([file.id])}
                        key={index}
                        className={classNames(
                          "cursor-pointer",
                          isSelected !== undefined
                            ? styles.selectedDoc
                            : "border-2 border-[#DADADA]"
                        )}
                      >
                        <div className="5xl:px-2 6xl:py-8 py-4 px-2 border-b border-b-[#DADADA] 6xl:min-h-[125px]">
                          <img
                            src={
                              extension
                                ? DocsIcon[extension]
                                  ? DocsIcon[extension]
                                  : ImageFormat.includes(extension)
                                    ? DocsIcon[extension]
                                    : DocsIcon["unknow"]
                                : DocsIcon.folder
                            }
                            className="w-[80px] h-[80px] object-cover mx-auto p-1"
                          />
                        </div>
                        <div className="py-1 4xl:px-5 px-2 ">
                          <Tooltip
                            arrow
                            classes={{ tooltip: classes.tooltip }}
                            enterTouchDelay={0}
                            leaveTouchDelay={5000}
                            placement="left"
                            title={
                              <>
                                <div className="text-white text-[10px] hover:cursor-pointer leading-[15px] z-[2] rounded p-4 w-full bg-tooltipbg">
                                  <p className="text-center break-words">
                                    {file.name}
                                  </p>
                                </div>
                              </>
                            }
                          >
                            <p className="text-center break-words whitespace-nowrap overflow-hidden text-ellipsis text-xs w-[95s%]">
                              {file.name}
                            </p>
                          </Tooltip>
                        </div>
                        <div className="flex 4xl:px-5 px-2 py-2 border-t border-t-[#DADADA]">
                          <button
                            type="button"
                            onClick={() => {
                              setSelectedFiles([file.id]);
                              downloadDocumentByID(file.id);
                            }}
                            className="mr-2 w-6 h-6 flex justify-center items-center group rounded-md bg-black2 hover:bg-[#FFEAEF] transition-all duration-300"
                          >
                            <DownloadIcon className="group-hover:fill-black2" />
                          </button>
                          <button
                            type="button"
                            onClick={() => {
                              onViewDocument(file.url, file.type,getUser.token).then((res)=>{
                                
                              }).catch((error)=>{
                                
                                setError(error?.message)
                              });
                              
                            }}
                            className="mx-2 w-6 h-6 flex justify-center items-center group rounded-md bg-black2  hover:bg-[#FFEAEF] transition-all duration-300 mr-2 last:mr-0"
                          >
                            <ViewIcon className="group-hover:fill-black2" />
                          </button>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
      <CommonErrorPopup
          show={error != "" ? true : false}
          setShow={() => setError("")}
          message={error}
        />
    </>
  );
};
export default memo(LeaveApplicationFilesPopup);
