import { FC, useEffect, useMemo, useState, useCallback } from "react";
import DebounceSearchInput from "../../components/Tables/DebounceSearchInput";
import { useNavigate } from "react-router-dom";
import { SearchIcon } from "../../components/common/Icons";
import classNames from "classnames";
import Button from "../../components/common/Button";
import { checkedIsNew } from "../../app/services/employee";
import { baseObj } from "../../utils/constants";
import {
  ColumnFiltersState,
  PaginationState,
  SortingState,
} from "@tanstack/react-table";
import { useSelector } from "react-redux";
import { templateList } from "../../active-time-report/views/ActiveTimeReport";
import { useSpring, animated } from "react-spring";
import { useGetByModuleTemplateQuery } from "../../app/services/template";
import { hideAllNoneField } from "../../components/Tables/TablePaginator";
import { getAuthUser } from "../../app/services/dashboard";
import { ToastContainer, toast } from "react-toastify";
import ErrorPopup from "../../components/ErrorPopup/ErrorPopup";
import Claim2Component from "../../components/ClaimType/ClaimType2Component";
import ClaimTypeTable from "../../components/ClaimType/ClaimTypeTable";
import { useGetClaimTypeListQuery } from "../../app/services/claim-type";
import { RootState } from "../../app/store";
import ProjectCheckerTable from "../../components/ProjectChecker/ProjectCheckerTable";
import ProjectChecker2Component from "../../components/ProjectChecker/ProjectChecker2Component";
import ProjectCheckerAccTable from "../../components/ProjectCheckerAcc/ProjectChackerAccTable";
import ProjectCheckerAcc2Component from "../../components/ProjectCheckerAcc/ProjectCheckerAcc2component";
import TaskList2Component from "../../components/TaskList/TaskList2Component";
import TaskListTable from "../../components/TaskList/TaskListTable";
import TaskDetailList2Component from "../../components/TaskDetailList/TaskDetailList2Component";
import TaskDetailListTable from "../../components/TaskDetailList/TaskDetailListTable";
import { ProjectInfo, useGetTaskDetailListFilterDataQuery, useGetTaskDetailListQuery } from "../../app/services/task-detail-list";
import { CommonListData, EmployeeListType, TaskCategory, TeamLeadData } from "../../app/services/tasklist";
import { renderDateFormatYMD } from "../../components/DashboardCalendarView/DashboardAddEventPopup";

export interface IOTData {
  id: number;
  employeeID: string;
  employeeName: string;
  department: string;
  date: string;
  fullHalfDay: string;
  timeIn: string;
  timeOut: string;
  otAmt: string;
}

interface IOT { }

export interface IDepartment {
  id: number;
  name: string;
  label: string;
}

const defaultFields = [
  "code",
  // "project_code",
  // "project_name",
  "task_name",
  "task_category_name",
  "employee_name",
  // "project_manager_name",
  // "team_leader_name",
  "date",
  "time_spent_text",
  "actions",
];

const claimTypeDataList: any = [
  {
    id: 0,
    name: "All",
    label: "All",
  },
  {
    id: 1,
    name: "by Year",
    label: "year",
  },
  {
    id: 2,
    name: "by Month",
    label: "month",
  },
  ,
  {
    id: 3,
    name: "by Week",
    label: "week",
  },
  {
    id: 4,
    name: "by Day",
    label: "day",
  },
];

export type TStatusListData = {
  id: number | string;
  name: string;
  label?: string;
};

const statusListData: TStatusListData[] = [
  {
    id: "All",
    name: "All",
  },
  {
    id: 1,
    name: "Active",
  },
  {
    id: 0,
    name: "InActive",
  },
];
const limitedData = [
  {
    id: "All",
    name: "All",
  },
  {
    id: 1,
    name: "Limited",
  },
  {
    id: 0,
    name: "Unlimited",
  },
];

const dymmyData = [
  {
    id: 1,
    "#": "Payroll 10112023",
    project_id: "LGNLAV0280",
    task_name: "v1.0 Homepage Design",
    task_tag: [
      {
        id: 1,
        name: "UIUX",
      },
      {
        id: 1,
        name: "Frontend",
      },
      {
        id: 1,
        name: "Backend",
      },
    ],
    pic: [
      {
        id: 1,
        name: "Ivan-MM094",
      },
      {
        id: 1,
        name: "Nora-MML4354",
      },
      {
        id: 1,
        name: "Kiro-UUI78",
      },
    ],
    project_lead: [
      {
        id: 1,
        name: "Sandy",
      },
      {
        id: 1,
        name: "Liary",
      },
      {
        id: 1,
        name: "Hellery",
      },
    ],
    date: "2023-11-14",
    time_spent: "2023-11-14",
    cost_spent: "HK89887.00",
    remark: "2 design options",
  },
  {
    id: 2,
    "#": "Payroll 10112023",
    project_id: "LGNLAV0280",
    task_name: "v1.0 Homepage Design",
    task_tag: [
      {
        id: 1,
        name: "UIUX",
      },
      {
        id: 1,
        name: "Frontend",
      },
      {
        id: 1,
        name: "Backend",
      },
    ],
    pic: [
      {
        id: 1,
        name: "Ivan-MM094",
      },
      {
        id: 1,
        name: "Nora-MML4354",
      },
      {
        id: 1,
        name: "Kiro-UUI78",
      },
    ],
    project_lead: [
      {
        id: 1,
        name: "Sandy",
      },
      {
        id: 1,
        name: "Liary",
      },
      {
        id: 1,
        name: "Hellery",
      },
    ],
    date: "2023-11-14",
    time_spent: "2023-11-14",
    cost_spent: "HK89887.00",
    remark: "2 design options",
  },
  {
    id: 3,
    "#": "Payroll 10112023",
    project_id: "LGNLAV0280",
    task_name: "v1.0 Homepage Design",
    task_tag: [
      {
        id: 1,
        name: "UIUX",
      },
      {
        id: 1,
        name: "Frontend",
      },
      {
        id: 1,
        name: "Backend",
      },
    ],
    pic: [
      {
        id: 1,
        name: "Ivan-MM094",
      },
      {
        id: 1,
        name: "Nora-MML4354",
      },
      {
        id: 1,
        name: "Kiro-UUI78",
      },
    ],
    project_lead: [
      {
        id: 1,
        name: "Sandy",
      },
      {
        id: 1,
        name: "Liary",
      },
      {
        id: 1,
        name: "Hellery",
      },
    ],
    date: "2023-11-14",
    time_spent: "2023-11-14",
    cost_spent: "HK89887.00",
    remark: "2 design options",
  },
  {
    id: 4,
    "#": "Payroll 10112023",
    project_id: "LGNLAV0280",
    task_name: "v1.0 Homepage Design",
    task_tag: [
      {
        id: 1,
        name: "UIUX",
      },
      {
        id: 1,
        name: "Frontend",
      },
      {
        id: 1,
        name: "Backend",
      },
    ],
    pic: [
      {
        id: 1,
        name: "Ivan-MM094",
      },
      {
        id: 1,
        name: "Nora-MML4354",
      },
      {
        id: 1,
        name: "Kiro-UUI78",
      },
    ],
    project_lead: [
      {
        id: 1,
        name: "Sandy",
      },
      {
        id: 1,
        name: "Liary",
      },
      {
        id: 1,
        name: "Hellery",
      },
    ],
    date: "2023-11-14",
    time_spent: "2023-11-14",
    cost_spent: "HK89887.00",
    remark: "2 design options",
  },
  {
    id: 5,
    "#": "Payroll 10112023",
    project_id: "LGNLAV0280",
    task_name: "v1.0 Homepage Design",
    task_tag: [
      {
        id: 1,
        name: "UIUX",
      },
      {
        id: 1,
        name: "Frontend",
      },
      {
        id: 1,
        name: "Backend",
      },
    ],
    pic: [
      {
        id: 1,
        name: "Ivan-MM094",
      },
      {
        id: 1,
        name: "Nora-MML4354",
      },
      {
        id: 1,
        name: "Kiro-UUI78",
      },
    ],
    project_lead: [
      {
        id: 1,
        name: "Sandy",
      },
      {
        id: 1,
        name: "Liary",
      },
      {
        id: 1,
        name: "Hellery",
      },
    ],
    date: "2023-11-14",
    time_spent: "2023-11-14",
    cost_spent: "HK89887.00",
    remark: "2 design options",
  },
  {
    id: 6,
    "#": "Payroll 10112023",
    project_id: "LGNLAV0280",
    task_name: "v1.0 Homepage Design",
    task_tag: [
      {
        id: 1,
        name: "UIUX",
      },
      {
        id: 1,
        name: "Frontend",
      },
      {
        id: 1,
        name: "Backend",
      },
    ],
    pic: [
      {
        id: 1,
        name: "Ivan-MM094",
      },
      {
        id: 1,
        name: "Nora-MML4354",
      },
      {
        id: 1,
        name: "Kiro-UUI78",
      },
    ],
    project_lead: [
      {
        id: 1,
        name: "Sandy",
      },
      {
        id: 1,
        name: "Liary",
      },
      {
        id: 1,
        name: "Hellery",
      },
    ],
    date: "2023-11-14",
    time_spent: "2023-11-14",
    cost_spent: "HK89887.00",
    remark: "2 design options",
  },
  {
    id: 7,
    "#": "Payroll 10112023",
    project_id: "LGNLAV0280",
    task_name: "v1.0 Homepage Design",
    task_tag: [
      {
        id: 1,
        name: "UIUX",
      },
      {
        id: 1,
        name: "Frontend",
      },
      {
        id: 1,
        name: "Backend",
      },
    ],
    pic: [
      {
        id: 1,
        name: "Ivan-MM094",
      },
      {
        id: 1,
        name: "Nora-MML4354",
      },
      {
        id: 1,
        name: "Kiro-UUI78",
      },
    ],
    project_lead: [
      {
        id: 1,
        name: "Sandy",
      },
      {
        id: 1,
        name: "Liary",
      },
      {
        id: 1,
        name: "Hellery",
      },
    ],
    date: "2023-11-14",
    time_spent: "2023-11-14",
    cost_spent: "HK89887.00",
    remark: "2 design options",
  },
  {
    id: 8,
    "#": "Payroll 10112023",
    project_id: "LGNLAV0280",
    task_name: "v1.0 Homepage Design",
    task_tag: [
      {
        id: 1,
        name: "UIUX",
      },
      {
        id: 1,
        name: "Frontend",
      },
      {
        id: 1,
        name: "Backend",
      },
    ],
    pic: [
      {
        id: 1,
        name: "Ivan-MM094",
      },
      {
        id: 1,
        name: "Nora-MML4354",
      },
      {
        id: 1,
        name: "Kiro-UUI78",
      },
    ],
    project_lead: [
      {
        id: 1,
        name: "Sandy",
      },
      {
        id: 1,
        name: "Liary",
      },
      {
        id: 1,
        name: "Hellery",
      },
    ],
    date: "2023-11-14",
    time_spent: "2023-11-14",
    cost_spent: "HK89887.00",
    remark: "2 design options",
  },
  {
    id: 9,
    "#": "Payroll 10112023",
    project_id: "LGNLAV0280",
    task_name: "v1.0 Homepage Design",
    task_tag: [
      {
        id: 1,
        name: "UIUX",
      },
      {
        id: 1,
        name: "Frontend",
      },
      {
        id: 1,
        name: "Backend",
      },
    ],
    pic: [
      {
        id: 1,
        name: "Ivan-MM094",
      },
      {
        id: 1,
        name: "Nora-MML4354",
      },
      {
        id: 1,
        name: "Kiro-UUI78",
      },
    ],
    project_lead: [
      {
        id: 1,
        name: "Sandy",
      },
      {
        id: 1,
        name: "Liary",
      },
      {
        id: 1,
        name: "Hellery",
      },
    ],
    date: "2023-11-14",
    time_spent: "2023-11-14",
    cost_spent: "HK89887.00",
    remark: "2 design options",
  },
  {
    id: 10,
    "#": "Payroll 10112023",
    project_id: "LGNLAV0280",
    task_name: "v1.0 Homepage Design",
    task_tag: [
      {
        id: 1,
        name: "UIUX",
      },
      {
        id: 1,
        name: "Frontend",
      },
      {
        id: 1,
        name: "Backend",
      },
    ],
    pic: [
      {
        id: 1,
        name: "Ivan-MM094",
      },
      {
        id: 1,
        name: "Nora-MML4354",
      },
      {
        id: 1,
        name: "Kiro-UUI78",
      },
    ],
    project_lead: [
      {
        id: 1,
        name: "Sandy",
      },
      {
        id: 1,
        name: "Liary",
      },
      {
        id: 1,
        name: "Hellery",
      },
    ],
    date: "2023-11-14",
    time_spent: "2023-11-14",
    cost_spent: "HK89887.00",
    remark: "2 design options",
  },
];

export const picData = [
  {
    id: 1,
    name: "Ivan-MM094",
  },
  {
    id: 1,
    name: "Nora-MML4354",
  },
  {
    id: 1,
    name: "Kiro-UUI78",
  },
];

export const projectLeadData = [
  {
    id: 1,
    name: "Sandy",
  },
  {
    id: 1,
    name: "Liary",
  },
  {
    id: 1,
    name: "Hellery",
  },
];
export const addNewChecker = () => {
  return {
    id: 0,
    project_id: "",
    task_name: "",
    task_tag: [
      {
        id: 1,
        name: "UIUX",
      },
      {
        id: 1,
        name: "Frontend",
      },
      {
        id: 1,
        name: "Backend",
      },
    ],
    pic: "",
    project_lead: "",
    date: "",
    time_spent: "",
    cost_spent: "",
    remark: "",
    isNew: true,
  };
};

const TaskDetailList: FC<IOT> = () => {
  const navigate = useNavigate();
  const { alerts } = useSelector((state: RootState) => state.alertClaim);

  const [globalSearch, setGlobalSearch] = useState<string>("");
  const [itemCount, setItemCount] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [department, setDepartment] = useState<string | number>("All");
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  });
  const [sorting, setSorting] = useState<SortingState>([]);
  const [otStatus, setOtStatus] = useState<any>("All");
  const [limitStatus, setLimitStatus] = useState<any>("All");
  const [message, setMessage] = useState<any>([]);
  // const [limit, setLimit] = useState<number>(10);
  const [currentView, setCurrentView] = useState<any>(0);
  const [currentViewData, setCurrentViewData] = useState<number>(0);
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  let [localModuleId, setLocalModuleId] = useState<number>(0);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [departData, setDepartData] =
    useState<IDepartment[]>(claimTypeDataList);
  const [hiddenFields, setHiddenFields] = useState<string[]>([]);
  const [tempList, setTempList] = useState<any>(templateList);
  const [viewCols, setCols] = useState<any>([]);
  const [visArray, setVisArray] = useState<any>({});
  const [moduleList, setModuleList] = useState<any>([]);
  const [data, setData] = useState<baseObj[]>([]);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [isTemplate, setIsTemplate] = useState<boolean>(false);
  const [errorPopup, setErrorPopup] = useState<boolean>(false);
  const [isEditing, setIsEdit] = useState<boolean>(false);
  const [projectInfo, setProjectInfo] = useState<ProjectInfo>();
  const [successPopup, setSuccessPopup] = useState<boolean>(false);
  const [isLayoutControlOpen, setIsLayoutControlOpen] =
    useState<boolean>(false);
  const [skip, setSkip] = useState<boolean>(false);
  // const [listSkip, setListSkip] = useState<boolean>(false);
  const [manageClaimType, setManageClaimType] = useState<boolean>(false);
  const [createClaim, setCreateClaim] = useState<boolean>(false);
  var disabledData = checkedIsNew(data);
  const { data: templateData } = useGetByModuleTemplateQuery(localModuleId);
  const [taskID, setTaskID] = useState<any>([]);
  const [totalTime, setTotalTime] = useState<string>();
  const uniqURL = window.location.href.split('/');
  const [projectID, setProjectID] = useState<any>("");
  const [taskListData, setTaskListData] = useState<TaskCategory[]>();
  const [picList, setPicList] = useState<TeamLeadData[]>();
  const [picID, setPicID] = useState<any>([]);
  const [employeeIDs, setEmployeeIDs] = useState<any>([]);
  const [employeeList, setEmployeeList] = useState<EmployeeListType[]>();
  const { data: taskDetailFilter } = useGetTaskDetailListFilterDataQuery(uniqURL[uniqURL.length - 2]);
  const { data: taskDetailList } = useGetTaskDetailListQuery({
    page: currentPage,
    limit: pagination.pageSize,
    search: globalSearch,
    sort: sorting.map((s) => `${s.desc ? "-" : ""}${s.id == "time_spent_text" ? "time_spent" : s.id}`).join(","),
    task_category_id: taskID,
    project_id: uniqURL[uniqURL.length - 2],
    start: startDate ? renderDateFormatYMD(startDate, "-") : "",
    end: endDate ? renderDateFormatYMD(endDate, "-") : "",
    pic: picID,
    employee_ids:employeeIDs
  });

  const deleteTemplateData = (id: string) => {
    var filterTempList = templateList.filter((x) => x.id !== parseInt(id));
    setTempList(filterTempList);
  };

  var getUser = getAuthUser();
  const getAuthPermis: string[] = useMemo(() => [], []);
  if (getUser?.permissions) {
    getUser?.permissions?.forEach((per: any) => {
      getAuthPermis.push(per?.name);
    });
  }

  const props = useSpring({
    to: { opacity: 1 },
    from: { opacity: 0 },
    delay: 1000,
  });

  const changePage = (pageNo: number) => { };

  useEffect(() => {
    if (taskDetailList) {
      setData(taskDetailList?.progress_checker_details?.data);      
      setItemCount(taskDetailList?.progress_checker_details?.total);
      setTotalTime(taskDetailList?.total_time);
      setProjectInfo(taskDetailList?.project)
    }

  }, [taskDetailList])
  useEffect(()=>{
    if(taskDetailFilter){
      setPicList(taskDetailFilter?.project_manager_lists);
      setTaskListData(taskDetailFilter?.task_categories);
      setEmployeeList(taskDetailFilter?.employee_list)
    }
  },[taskDetailFilter])
  useEffect(() => {
    const filterManage = getAuthPermis.filter((f) => f === "Manage_ClaimForms");
    const createClaim = getAuthPermis.filter((f) => f === "Create_Claim_Form");
    if (filterManage.length > 0) {
      setManageClaimType(true);
      setCreateClaim(true);
    }

    // if (createClaim.length > 0) {
    //   setCreateClaim(true);
    // }
  }, [getUser, getAuthPermis]);

  useEffect(() => {
    if (templateData) {
      var updatedTemplate = templateData?.templates.map((x: any, i) => {
        return { ...x, index: i + 1 };
      });
      var defaultTemplate = {
        id: -1,
        index: 0,
        module: 0,
        name: "All",
      };
      setModuleList([defaultTemplate, ...updatedTemplate]);
    }
  }, [templateData]);

  useEffect(() => {
    if (templateList) {
      setTempList(templateList);
    }
    var viewData = templateData?.templates.find(
      (x) => x.id.toString() == currentView.toString()
    );

    setHiddenFields(viewData ? [...viewData.viewColumns] : defaultFields);
    hideAllNoneField();
  }, [templateList, currentView]);

  useEffect(() => {
    if (viewCols?.view_columns?.length > 0) {
      setHiddenFields(viewCols?.view_columns);
    }
  }, [viewCols?.view_columns]);

  useEffect(() => {
    if (moduleList) {
      var lastesTemplate = moduleList[moduleList?.length - 1];
      setCurrentViewData(lastesTemplate ? Number(lastesTemplate?.id) : 0);
    }
  }, [moduleList]);

  useEffect(() => {
    setCurrentPage(1);
  }, [
    startDate,
    endDate,
    taskID,
    picID,
    pagination.pageSize,
    currentView,
    globalSearch,
  ]);

  useEffect(() => {
    if (alerts[0]?.type) {
      toast(`${alerts[0]?.message}`, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        type: "success",
      });
    }
  }, [alerts[0]?.type]);

  return (
    <div>
      <ToastContainer className={"text-12 font-poppins font-normal"} />
      <ErrorPopup
        descText="Error Message!"
        confirmBtnText="Yes, Close."
        show={errorPopup}
        setShow={setErrorPopup}
        message={message}
        setMessage={setMessage}
      />
      <div className="px-2 sm:px-4 py-4 bg-white sm:mt-[25px] mt-0 font-poppins text-13 font-semibold text-[#858795] tracking-[-0.03rem] w-full h-full rounded-xl pt-3 pb-8 sm:pt-3 sm:pb-9">
        <div className="flex justify-between items-center mb-3">
          <div className="">
            <p className="text-left lg:text-24 text-lg text-[#464E5F]">{projectInfo?.project_name}</p>
            <p className=" text-[15px] text-left text-[#858795]">{projectInfo?.project_code}</p>
            <div className="flex items-center">
              <p className=" text-[14px] text-left text-[#858795] mr-2">Project Manager -</p>
              <p className=" text-[14px] text-left text-[#858795]">( {projectInfo?.project_manager_code} - {projectInfo?.project_manager_name} )</p>
            </div>
            <div className="flex items-center">
              <p className=" text-[14px] text-left text-[#858795] mr-2">Team Lead -</p>
              <p className=" text-[14px] text-left text-[#858795]">( {projectInfo?.team_leader_code} - {projectInfo?.team_leader_name} )</p>
            </div>
          </div>

          {/* <Button
            disabled={disabledData?.disabled}
            label={<span className="flex items-center">Back</span>}
            onClick={() => {
              window.history.back()
            }}
            type="button"
            variant="no-outline"
            customClass={
              disabledData?.disabledClass +
              " py-[3px] my-1 px-2 focus:outline-none bg-[#E4E6EF] border-[#E4E6EF] text-[#888C9F] hover:bg-[#888C9F] hover:border-[#888C9F] hover:text-white 2xl:text-[14px] text-12 leading-5 font-medium"
            }
            size="base"
          /> */}
        </div>

        {/* <div className="flex flex-wrap justify-between items-center">
          <div className="flex items-center mr-2 xs:mr-4 sm:w-[260px] w-[225px] md:mb-1">
            <DebounceSearchInput setState={setGlobalSearch} debounce={800}>
              {(setState) => (
                <div className="font-normal flex border-1 border-[#C4C4C4] bg-white hover:bg-[#EFEFEF] rounded-[28px]">
                  <input
                    id="message"
                    autoComplete="off"
                    name="search"
                    type="text"
                    placeholder="Search"
                    className="focus:outline-none py-1 px-4 text-12 xl:text-14 leading-5 bg-transparent rounded-[28px] w-full text-graydark"
                    onChange={(e) => setState(e.target.value)}
                  />
                  <SearchIcon className={classNames("mr-2")} />
                </div>
              )}
            </DebounceSearchInput>
          </div>
          
        </div> */}

        <TaskDetailList2Component
          setGlobalSearch={setGlobalSearch}
          filterOneName="TaskTag"
          filterTwoName="PIC"
          department={department}
          setDepartment={setDepartment}
          setPagination={setPagination}
          pagination={pagination}
          departData={departData}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          // setLimit={setLimit}
          setIsTemplate={setIsTemplate}
          isTemplate={isTemplate}
          currentViewData={currentViewData}
          currentView={currentView}
          setCurrentView={setCurrentView}
          moduleList={moduleList}
          deleteTemplateData={deleteTemplateData}
          setErrorPopup={setErrorPopup}
          setSuccessPopup={setSuccessPopup}
          setMessage={setMessage}
          setIsLayoutControlOpen={setIsLayoutControlOpen}
          projectID={projectID}
          setProjectID={setProjectID}
          statusListData={taskListData ? taskListData.map((stage: TaskCategory) => {
            return {
              id: stage.id,
              name: stage.name
            }
          }) : []}
          picListData={picList ? picList.map((stage: TeamLeadData) => {
            return {
              id: stage.id,
              name: stage.preferred_name
            }
          }) : []}
          employeeListData={employeeList ? employeeList.map((stage: EmployeeListType) => {
            return {
              id: stage.id,
              name: stage.name
            }
          }) : []}
          setPicID={setPicID}
          picID={picID}
          taskID={taskID}
          setTaskID={setTaskID}
          setEmployeeIDs={setEmployeeIDs}
          employeeIDs={employeeIDs}
        />

        <animated.div style={props}>
          <TaskDetailListTable
            data={data}
            setData={setData}
            setIsSuccess={setIsSuccess}
            isSuccess={isSuccess}
            pagination={pagination}
            setPagination={setPagination}
            isLayoutControlOpen={isLayoutControlOpen}
            setIsLayoutControlOpen={setIsLayoutControlOpen}
            globalSearch={globalSearch}
            setGlobalSearch={setGlobalSearch}
            columnFilters={columnFilters}
            setColumnFilters={setColumnFilters}
            setHiddenFields={setHiddenFields}
            hiddenFields={hiddenFields ? hiddenFields : []}
            templateList={tempList}
            setCurrentView={setCurrentView}
            setIsEdit={setIsEdit}
            isEditing={isEditing}
            localModuleId={localModuleId}
            total={itemCount}
            changePage={changePage}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            setIsTemplate={setIsTemplate}
            setCols={setCols}
            visArray={visArray}
            manageClaim={manageClaimType}
            setSkip={setSkip}
            sorting={sorting}
            setSorting={setSorting}
            totalTime={totalTime}
          />
        </animated.div>
      </div>
    </div>
  );
};
export default TaskDetailList;
