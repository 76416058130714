import { redirectToLogin } from "./annoucement-sheet";
import { api } from "./api";
import authHeader from "./auth-header";
import { Ids } from "./media";

type ProjectStatusResponse = {
  module_id: number;
  status: boolean;
  data: TData;
};

export type TData = {
  data: StatusInfo[];
  total: number;
};
export type StatusInfo = {
  id?: number;
  name: string;
  order: number;
};
type projectStatusInput = {
  sort: string;
  search: string;
  page: number;
  limit: number;
};
export type TaskDepartmentList = {
  departmentList: TaskDepartmentListData[];
};
export type TaskDepartmentListData = {
  id: number;
  name: string;
};

export const projectStatusApi = api.injectEndpoints({
  endpoints: (build) => ({
    getProjectStatusList: build.query<
      ProjectStatusResponse,
      projectStatusInput
    >({
      query: ({ sort, search, limit, page }) => ({
        url: `projectStatuses?sort=${sort}&search=${search}&limit=${limit}&page=${page}
            
            `,
        headers: authHeader(),
      }),
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
      providesTags: (result: any = []) => {
        if (result.data) {
          if (result.data.length > 0) {
            return [
              ...result.data.map(
                (id: any) => ({ type: "TaskCategories", id } as const)
              ),
              { type: "ProjectStatus" as const, id: "LIST" },
            ];
          } else {
            return [{ type: "ProjectStatus" as const, id: "LIST" }];
          }
        } else {
          return [{ type: "ProjectStatus" as const, id: "LIST" }];
        }
      },
    }),
    createProjectStatus: build.mutation<TaskDepartmentList, StatusInfo>({
      query(body) {
        return {
          headers: authHeader(),
          url: `/projectStatuses`,
          body,
          method: "POST",
        };
      },
      invalidatesTags: (employees) => {
        return [{ type: "ProjectStatus", id: "LIST" }];
      },
    }),
    updateProjectStatus: build.mutation<TaskDepartmentList, StatusInfo>({
      query(body) {
        return {
          headers: authHeader(),
          url: `/projectStatuses/${body.id}`,
          body,
          method: "PUT",
        };
      },
      invalidatesTags: (employees) => {
        return [{ type: "ProjectStatus", id: "LIST" }];
      },
    }),
    deleteProjectStatus: build.mutation<TaskDepartmentList, number>({
      query(id) {
        return {
          headers: authHeader(),
          url: `/projectStatuses/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: (employees) => {
        return [{ type: "ProjectStatus", id: "LIST" }];
      },
    }),
  }),
});

export const {
  useGetProjectStatusListQuery,
  useCreateProjectStatusMutation,
  useUpdateProjectStatusMutation,
  useDeleteProjectStatusMutation,
} = projectStatusApi;

export const {
  endpoints: {
    getProjectStatusList,
    createProjectStatus,
    updateProjectStatus,
    deleteProjectStatus,
  },
} = projectStatusApi;
