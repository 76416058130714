import {
  Dispatch,
  FC,
  Fragment,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import style from "./BasicInfo.module.scss";
import { twJoin, twMerge } from "tailwind-merge";
import {
  IFormField,
  dummyCountries,
  profileDataFormStructure,
} from "../../../utils/constants";
import Button from "../../common/Button";
import { IUserData } from "./types";
import { StringDropdownList } from "../../common/DropdownList";
import Styles from "./BasicInfo.module.scss";
import { useUpdateProfileMutation } from "../../../app/services/profile";
import classNames from "classnames";
import DOMPurify from "dompurify";
import { renderDateFormatDMY } from "../../DashboardCalendarView/DashboardAddEventPopup";

interface IPersonalDetailsEdit {
  setIsEdit: Dispatch<SetStateAction<boolean>>;
  userData?: IUserData;
  setUserData?: Dispatch<SetStateAction<IUserData>>;
  updatedMyInfo?: any;
  id?: number;
  setSuccessPopup: Dispatch<SetStateAction<boolean>>;
  setErrorPopup: Dispatch<SetStateAction<boolean>>;
  setMessage: Dispatch<SetStateAction<any>>;
}

const PersonalDetailsEdit: FC<IPersonalDetailsEdit> = ({
  setIsEdit,
  userData,
  setUserData,
  updatedMyInfo,
  setSuccessPopup,
  setErrorPopup,
  setMessage,
}) => {
  const [updateProfile] = useUpdateProfileMutation();
  const [formData, setFormData] = useState<any>(userData);

  const onChangeHandler = useCallback(
    (key: string, value: string) => {
      const name = DOMPurify.sanitize(value);
      setFormData((prev: any) => ({ ...prev, [key]: name }));
    },
    [setFormData]
  );

  const onSubmitHandler = () => {
    if (setUserData) {
      setUserData(formData);
    }

    if (updatedMyInfo) {
      updatedMyInfo(formData)
        .unwrap()
        .then((payload: any) => {
          if (payload.status) {
            setSuccessPopup(true);
            setIsEdit(false);
            setMessage(
              payload ? [payload?.message] : ["Updated Successfully!"]
            );
          }
        })
        .catch((error: any) => {
          setErrorPopup(true);
          setMessage(
            error
              ? [error?.data?.message]
              : ["Something went wrong!Trying again."]
          );
        });
    } else {
      updateProfile(formData).then((res) => {
        setIsEdit(false);
      });
    }
    // setIsEdit(false);
  };

  return (
    <>
      <div className="pt-7 pb-4 text-left grid md:grid-cols-2 border-b border-offwhite">
        {profileDataFormStructure.map((field, index) => (
          <Fragment key={index}>
            {field.header && (
              <div
                key={index}
                className="border border-offwhite pt-5 pb-4 my-7 px-4 sm:px-[30px] md:col-span-2"
              >
                <h4 className="text-12 sm:text-[13px] 2xl:text-18 2xl:leading-[27px] leading-[18px] font-semibold text-black2">
                  {field.header}
                </h4>
              </div>
            )}
            {field.children.map((item) => {
              return (
                <Fragment key={item.label}>
                  <label
                    className={twMerge(
                      style.profileEditLabel,
                      "px-4 sm:pl-7 mb-3 md:mt-3 md:mb-0"
                    )}
                    htmlFor={item.formField[0].id}
                  >
                    {item.label}
                  </label>
                  <div className="flex mb-5 sm:pl-7 md:pl-0 sm:pr-7 px-4">
                    {item.formField.map(
                      (fieldItem: IFormField, index: number) => {
                        const [type, validation, min] =
                          fieldItem.type.split("/"); // TODO RegEx for some fields Validation
                        switch (type) {
                          case "radio":
                            const isChecked =
                              formData[
                              fieldItem.name as keyof typeof formData
                              ] === fieldItem.value;
                            return (
                              <div
                                className="flex items-center mr-[33px] min-w-[74px] mt-5"
                                key={fieldItem.id}
                              >
                                <input
                                  disabled={
                                    fieldItem.readonly == "readOnly"
                                      ? true
                                      : false
                                  }
                                  id={fieldItem.id}
                                  type="radio"
                                  value={fieldItem.value ?? ""}
                                  checked={isChecked}
                                  name={fieldItem.name}
                                  onClick={() => 1}
                                  onChange={(e) =>
                                    onChangeHandler(
                                      fieldItem.name as string,
                                      e.target.value
                                    )
                                  }
                                  className="hidden xl:text-15 text-12"
                                />
                                <label
                                  htmlFor={fieldItem.id}
                                  className="ml-[10px]  xl:text-15 text-12 leading-[22px] flex items-center font-medium text-graydark"
                                >
                                  <div
                                    className={`w-5 h-5 rounded-full flex justify-center items-center mr-[11px] ${isChecked ? "bg-vorpblue" : "bg-offwhite"
                                      }`}
                                  >
                                    {isChecked && (
                                      <div className="w-[10px] h-[10px] rounded-full bg-white"></div>
                                    )}
                                  </div>
                                  {fieldItem.label}
                                </label>
                              </div>
                            );
                          case "select":
                            return (
                              <div
                                key={fieldItem.id}
                                className={Styles.countryDropdown}
                              >
                                <StringDropdownList
                                  state={
                                    formData[
                                    fieldItem.id as keyof typeof formData
                                    ]
                                  }
                                  setState={(value) =>
                                    onChangeHandler(
                                      fieldItem.id,
                                      value as string
                                    )
                                  }
                                  dropdownItems={dummyCountries}
                                  className={classNames(
                                    fieldItem.readonly == "readOnly"
                                      ? "poiner-none"
                                      : "",
                                    "min-h-[42px]"
                                  )}
                                  textAlign="text-left pl-2 py-2 text-black2"
                                />
                              </div>
                            );
                          default:
                            return (
                              <input
                                disabled={
                                  fieldItem.readonly == "readOnly"
                                    ? true
                                    : false
                                }
                                min="0"
                                max="9999999999999"
                                type={type}
                                name={fieldItem.id}
                                id={fieldItem.id}
                                onKeyDown={
                                  fieldItem.zip
                                    ? (evt) =>
                                      ["e", "E", "+", "-"].includes(
                                        evt.key
                                      ) && evt.preventDefault()
                                    : () => null
                                }
                                value={
                                  fieldItem.id == "dob" ? formData[
                                    fieldItem.id as keyof typeof formData
                                  ] ? renderDateFormatDMY(new Date(formData[
                                    fieldItem.id as keyof typeof formData
                                  ]), '-') : '---' :
                                    formData[
                                    fieldItem.id as keyof typeof formData
                                    ] ?? ""
                                }
                                className={twJoin(
                                  " xl:text-15 text-12",
                                  style.profileEditTextInput,
                                  `${index !== item.formField.length - 1
                                    ? "md:mr-5"
                                    : ""
                                  }`
                                )}
                                onChange={(e) =>
                                  onChangeHandler(fieldItem.id, e.target.value)
                                }
                                key={fieldItem.id}
                              />
                            );
                        }
                      }
                    )}
                  </div>
                </Fragment>
              );
            })}
          </Fragment>
        ))}
      </div>
      <div className="flex justify-end items-center py-4 pb-8 px-[30px]">
        <button
          type="button"
          className="mr-6 text-vorpgraylight text-12 leadiing-[18px]"
          onClick={() => setIsEdit(false)}
        >
          Discard
        </button>
        <Button
          type="button"
          onClick={onSubmitHandler}
          label="Save Changes"
          variant="no-outline"
          size="base"
          customClass=""
        />
      </div>
    </>
  );
};

export default PersonalDetailsEdit;
