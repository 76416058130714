import { Slider } from "@mui/material";
import classNames from "classnames";
import AudioPlayerCustom from "./AudioPlayerCustom";
const dummyPerformanceDetail = {
    userInfo: {
        name: "Visibee 1",
        department: "Design",
        jobTitle: "Designer",
        reviewPeriod: "Oct 2022",
        employeeID: "MM001",
        date: "04 Nov 2022",
        reviewedBy: "Sean Dennis",
        reviewerPosition: "UIUX Designer",
        batchID: "202003"
    },
    detailData: [
        {
            id:1,
            title: "Job Knowledge",
            data: [
                {
                    id:1,
                    aspectsTitle: "Skills",
                    aspectsDescription: "Skills and proficiency in carrying out tasks.",
                    marks: 80,
                    comments: 'https://www2.cs.uic.edu/~i101/SoundFiles/BabyElephantWalk60.wav'
                },
                {
                    id:2,
                    aspectsTitle: "Job Efficiency",
                    aspectsDescription: "Perform the job completely",
                    marks: 80,
                    comments: 'https://www2.cs.uic.edu/~i101/SoundFiles/BabyElephantWalk60.wav'
                },
                {
                    id:3,
                    aspectsTitle: "Skills",
                    aspectsDescription: "Skills and proficiency in carrying out tasks.",
                    marks: 80,
                    comments: 'https://www2.cs.uic.edu/~i101/SoundFiles/BabyElephantWalk60.wav'
                }
            ]
        },
        {
            id:2,
            title: "Work Quality",
            data: [
                {
                    id:1,
                    aspectsTitle: "Skills",
                    aspectsDescription: "Skills and proficiency in carrying out tasks.",
                    marks: 80,
                    comments: 'https://www2.cs.uic.edu/~i101/SoundFiles/BabyElephantWalk60.wav'
                },
                {
                    id:2,
                    aspectsTitle: "Job Efficiency",
                    aspectsDescription: "Perform the job completely",
                    marks: 80,
                    comments: 'https://www2.cs.uic.edu/~i101/SoundFiles/BabyElephantWalk60.wav'
                },
                {
                    id:3,
                    aspectsTitle: "Skills",
                    aspectsDescription: "Skills and proficiency in carrying out tasks.",
                    marks: 80,
                    comments: 'https://www2.cs.uic.edu/~i101/SoundFiles/BabyElephantWalk60.wav'
                }
            ]
        }
    ]
}
// const playAudio = (id: string) => {
//     var audio: any = document.getElementById(id);
//     if (audio != null) {
//         if (!audio.paused) {
//             audio.pause();
//         } else {
//             audio.play();
//         }

//     }
// }
const PerformanceAppraisalDetail = () => {
    return <div>
        <div className="flex justify-between items-center">
            <div>
                <h3 className="font-poppins font-semibold xl:text-24 text-18 text-black2 text-left">Visibee 1</h3>
                <p className="font-poppins font-normal xl:text-14 text-12 text-graydark text-left">You’re now reviewing visibee 1.</p>
            </div>
            <button onClick={() => window.history.back()} className={classNames("flex ml-auto justify-end text-12 font-semibold font-poppins text-[#888C9F] px-5 py-1 bg-[#E4E6EF] rounded-md right-0 top-2")}>
                Back
            </button>
        </div>
        <div className="rounded-[12px] p-4 bg-white mt-[25px] font-poppins text-13 font-semibold text-[#858795] tracking-[-0.03rem] w-full h-full rounded-xl">
            <div className="border-1 border-[#DADADA] p-4">
                <div className="grid xl:grid-cols-3 sm:grid-cols-2 grid-cols-1">
                    <div>
                        <div className="flex xs:flex-row flex-col xs:items-center items-start mb-[1px]">
                            <p className="text-15 text-left bg-offwhite 3xl:min-w-[240px] min-w-[150px] px-2 py-1 xs:w-auto w-full">Employee Name</p>
                            <p className="text-14 text-left px-2 py-1">{dummyPerformanceDetail?.userInfo.name}</p>
                        </div>
                        <div className="flex xs:flex-row flex-col xs:items-center items-start mb-[1px]">
                            <p className="text-15 text-left bg-offwhite 3xl:min-w-[240px] min-w-[150px] px-2 py-1 xs:w-auto w-full">Department</p>
                            <p className="text-14 text-left px-2 py-1">{dummyPerformanceDetail?.userInfo.department}</p>
                        </div>
                        <div className="flex xs:flex-row flex-col xs:items-center items-start mb-[1px]">
                            <p className="text-15 text-left bg-offwhite 3xl:min-w-[240px] min-w-[150px] px-2 py-1 xs:w-auto w-full">Job Title</p>
                            <p className="text-14 text-left px-2 py-1">{dummyPerformanceDetail?.userInfo.jobTitle}</p>
                        </div>
                        <div className="flex xs:flex-row flex-col xs:items-center items-start mb-[1px]">
                            <p className="text-15 text-left bg-offwhite 3xl:min-w-[240px] min-w-[150px] px-2 py-1 xs:w-auto w-full">Review Period</p>
                            <p className="text-14 text-left px-2 py-1">{dummyPerformanceDetail?.userInfo.reviewPeriod}</p>
                        </div>
                    </div>
                    <div>
                        <div className="flex xs:flex-row flex-col xs:items-center items-start mb-[1px]">
                            <p className="text-15 text-left bg-offwhite 3xl:min-w-[240px] min-w-[150px] px-2 py-1 xs:w-auto w-full">Employee ID</p>
                            <p className="text-14 text-left px-2 py-1">{dummyPerformanceDetail?.userInfo.employeeID}</p>
                        </div>
                        <div className="flex xs:flex-row flex-col xs:items-center items-start mb-[1px]">
                            <p className="text-15 text-left bg-offwhite 3xl:min-w-[240px] min-w-[150px] px-2 py-1 xs:w-auto w-full">Date</p>
                            <p className="text-14 text-left px-2 py-1">{dummyPerformanceDetail?.userInfo.date}</p>
                        </div>
                        <div className="flex xs:flex-row flex-col xs:items-center items-start mb-[1px]">
                            <p className="text-15 text-left bg-offwhite 3xl:min-w-[240px] min-w-[150px] px-2 py-1 xs:w-auto w-full">Reviewed By</p>
                            <p className="text-14 text-left px-2 py-1">{dummyPerformanceDetail?.userInfo.reviewedBy}</p>
                        </div>
                        <div className="flex xs:flex-row flex-col xs:items-center items-start mb-[1px]">
                            <p className="text-15 text-left bg-offwhite 3xl:min-w-[240px] min-w-[150px] px-2 py-1 xs:w-auto w-full">Reviewer Position</p>
                            <p className="text-14 text-left px-2 py-1">{dummyPerformanceDetail?.userInfo.reviewerPosition}</p>
                        </div>
                    </div>
                    <div>
                        <div className="flex xs:flex-row flex-col xs:items-center items-start mb-[1px]">
                            <p className="text-15 text-left bg-offwhite 3xl:min-w-[240px] min-w-[150px] px-2 py-1 xs:w-auto w-full">Batch ID</p>
                            <p className="text-14 text-left px-2 py-1">{dummyPerformanceDetail?.userInfo.batchID}</p>
                        </div>
                    </div>
                </div>
                <div className="pt-6">
                    {dummyPerformanceDetail?.detailData?.map((detail, key: number) => {
                        return <div key={key} className="pb-6">
                            <h4 className="font-poppins text-18 font-semibold text-vorpblue text-left pb-4">{detail.title}</h4>
                            <div className="grid grid-cols-3 -mx-4">
                                <div className="border-1 border-vorpgraylight bg-offwhite"><p className="text-left text-black2 px-4 py-2 font-poppins text-14 font-semibold">Aspects</p></div>
                                <div className="border-1 border-vorpgraylight bg-offwhite"><p className="text-left text-black2 px-4 py-2 font-poppins text-14 font-semibold">Marks</p></div>
                                <div className="border-1 border-vorpgraylight bg-offwhite"><p className="text-left text-black2 px-4 py-2 font-poppins text-14 font-semibold">Comments</p></div>
                            </div>
                            {detail?.data?.map((content, index: number) => {
                                return <div key={index} className="grid grid-cols-3 -mx-4">
                                    <div className="border-1 border-vorpgraylight md:px-4 px-2 py-2">
                                        <p className="text-left text-black2 font-poppins text-12 font-semibold">{content.aspectsTitle}</p>
                                        <p className="text-left text-black2 font-poppins text-15 font-normal">{content.aspectsDescription}</p></div>
                                    <div className="border-1 border-vorpgraylight md:px-4 px-2 py-2 flex flex-wrap sm:justify-between items-center">
                                        <div className="mr-2 sm:w-[80%] w-full">
                                            <Slider sx={{
                                                '& .MuiSlider-thumb': {
                                                    color: "#3699FF",
                                                    width: "18px",
                                                    height: "18px",
                                                },
                                                '& .MuiSlider-thumb:focusVisible': {
                                                    boxShadow: '0px 0px 0px 8px rgba(25,118,210,0.16) !important'
                                                },
                                                '& .MuiSlider-thumb:focusWithin': {
                                                    boxShadow: '0px 0px 0px 8px rgba(25,118,210,0.16) !important'
                                                },
                                                '& .MuiSlider-thumb:focus': {
                                                    boxShadow: '0px 0px 0px 8px rgba(25,118,210,0.16) !important'
                                                },
                                                '& .MuiSlider-thumb:active': {
                                                    boxShadow: '0px 0px 0px 8px rgba(25,118,210,0.16) !important'
                                                },
                                                '& .MuiSlider-track': {
                                                    color: "#3699FF"
                                                },
                                                '& .MuiSlider-rail': {
                                                    color: "#EFF2F5"
                                                },
                                                '& .MuiSlider-active': {
                                                    color: "green"
                                                },
                                                '& .MuiSlider-valueLabel': {
                                                    backgroundColor: "#fff !important",
                                                    boxShadow: '0px 8px 8px 0px rgba(0, 0, 0, 0.12) !important',
                                                    color: '#858795 !important',
                                                    fontSize: '15px'
                                                }

                                            }} className="" defaultValue={content.marks} aria-label="Default" valueLabelDisplay="auto" />
                                        </div>
                                        <p className="font-poppins font-semibold xl:text-24 text-16 mb-1">{content.marks}</p>
                                    </div>
                                    <div className="border-1 border-vorpgraylight md:px-4 px-2 py-2">
                                        <div className="flex flex-wrap items-center">
                                            <AudioPlayerCustom id={detail.id + "_" + content.id} />
                                        </div>

                                        {/* <p className="text-left text-black2 font-poppins text-14 font-semibold">{content.comments}</p> */}
                                    </div>

                                </div>
                            })}

                        </div>
                    })}
                </div>
            </div>
        </div>
    </div>
}
export default PerformanceAppraisalDetail;