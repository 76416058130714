import classNames from "classnames";
import React, { Dispatch, FC, SetStateAction, useState } from "react";
import styles from "./NotiList.module.scss";
interface INotiList {
    notificationsList: any,
    viewNoti: any,
    markAllAsRead: any
}
const NotiList: FC<INotiList> = ({
    notificationsList,
    viewNoti,
    markAllAsRead
}) => {
    const viewNotification = (noti: any) => {
        viewNoti(noti.id).then((res: any) => {
            if (res.data.status) {
                var url = noti.direction == "profile_document" ? '/profile/documents' :
                    noti.direction == "profile_password" ? '/profile/passwords' :
                        noti.direction == "leave_application" ? '/employeeList/leave' :
                            //noti.direction == "profile_overtime" ? '/employeeList/ot-list' :
                            noti.direction == "overtime_application" ? '/employeeList/ot-list' :
                            noti.direction == "profile_claimform" ? '/profile/myclaim' :   
                            noti.direction == "claimform_application" ? '/claims' :   
                            noti.direction == "profile_project" ? '/profile/projects' :     
                            noti.direction == 'profile_my_daily_report_refill_requests' ? "/profile/my-refill-report" :
                            noti.direction == 'profile_member_daily_report_refill_requests' ? "/profile/member-daily-report" :
                            noti.direction=='activity_log'?'/activity_log':
                            noti.direction=='announcement'?'/profile/announcement':
                                noti.direction == "profile_leave" ? '/profile/leave' : '#';
                window.location.href = url;
            }
        });

    }
    return (
        <>            
            <div
                className={classNames(
                    styles.customInfoBox,
                    "sm:max-w-[340px] max-w-[90%] bg-white rounded-xl xl:right-9 sm:right-4 right-[-1rem] relative mt-3"
                )}
            >
                <div className="flex flex-wrap items-center justify-between bg-[#DBE6ED] py-2 px-3 rounded-tl-xl rounded-tr-xl">
                    <a href="/notifications" className="flex rounded-md items-center font-semibold py-2 text-[#1B3757] text-[13px] mr-3">See All Notification ({notificationsList.unread_count}) <svg className="ml-2 mt-[-1px]" xmlns="http://www.w3.org/2000/svg" width="6" height="10" viewBox="0 0 6 10" fill="none">
                        <path d="M0.21967 1.21936C-0.0732233 0.940416 -0.0732233 0.488155 0.21967 0.209209C0.512563 -0.0697365 0.987437 -0.0697365 1.28033 0.209209L5.78033 4.49492C6.06426 4.76534 6.0742 5.20075 5.80287 5.48266L1.67787 9.76837C1.39797 10.0592 0.923546 10.0788 0.618207 9.81225C0.312867 9.54568 0.29224 9.09385 0.572134 8.80305L4.2119 5.02148L0.21967 1.21936Z" fill="#1B3757" />
                    </svg></a>
                    <button onClick={() => markAllAsRead()} className="rounded-md px-2 py-1 font-medium text-black2 text-[13px] bg-white">Mark All As Read</button>
                </div>
                {notificationsList?.notifications?.length <= 0 ?
                    <ul className="sm:max-h-[514px] max-h-[300px]  overflow-auto">
                        <li className={classNames("px-5 py-2 text-left pb-2 border-b border-b-[#E4E6EF]")}>
                            There is no notification
                        </li>
                    </ul> : <ul className="sm:max-h-[514px] max-h-[300px]  overflow-auto">
                        {notificationsList?.notifications?.map((noti: any, key: number) => {
                            return <li onClick={() => viewNotification(noti)} className={classNames(noti.read_at == "" ? 'bg-[#F3F6F9] ' + styles.redDot : '', "text-left pb-2 border-b border-b-[#E4E6EF]")} key={key}>
                                <button className="w-full block hover:text-vorpblue font-poppins font-normal text-sm text-graydark cursor-pointer px-6 py-4"
                                >
                                    <p className="text-left font-poppins lg:text-sm text-xs font-bold text-[#464E5F]">{noti.title}</p>
                                    <p className="text-left font-poppins text-[10px] text-[#858795] mt-1 font-normal">{noti.updated_at}</p>
                                    <div className="text-left font-poppins text-xs font-normal text-[#858795] mt-1" dangerouslySetInnerHTML={{ __html: noti.description }}></div>
                                </button>
                            </li>
                        })}

                    </ul>}

            </div>
        </>
    );
};
export default NotiList;
