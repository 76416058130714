import {
    createColumnHelper,
    SortingState,
    ColumnFiltersState,
    RowSelectionState,
    ColumnDef,
    PaginationState,
} from "@tanstack/table-core";
import {
    FC,
    useState,
    useMemo,
    Dispatch,
    SetStateAction,
    memo,
    useCallback,
    useRef,
    useEffect,
} from "react";
import styles from "./DailyReport.module.scss";
import LayoutAdjustableTable from "../../Tables/LayoutAdjustableTable";
import TablePaginator from "../../Tables/TablePaginator";
import { renderDateFormatDMY } from "../../DashboardCalendarView/DashboardAddEventPopup";
import CommonTableBottom from "../../CommonTableBottom/CommonTableBottom";
import { CheckIcon, CloseIcon, CloseSmallIcon, EditIcon, ViewIcon } from "../../common/Icons";
import classNames from "classnames";
import DeletePopup from "../../DeletePopup/DeletePopup";
import { Fade, Modal } from "@mui/material";

interface IMemberReportApproval {
    data?: any;
    setData?: any;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    isOpen: boolean;
    date: string;
    reason: string;
    employee_name: string;
    day_format: string;
    showConfirmPopup: (id: number, status: string) => void;
    id: number;
    rejectOption: boolean;
    approveOption: boolean;
    clockIn1: string;
    clockIn2: string;
    clockIn3: string;
    clockOut1: string;
    clockOut2: string;
    clockOut3: string;
    total_clocking_time_text: string;
}
const MemberDailyReportPopup: FC<IMemberReportApproval> = ({
    data,
    setData,
    setIsOpen,
    isOpen,
    date,
    reason,
    employee_name,
    day_format,
    showConfirmPopup,
    id,
    rejectOption,
    approveOption,
    clockIn1,
    clockIn2,
    clockIn3,
    clockOut1,
    clockOut2,
    clockOut3,
    total_clocking_time_text
}) => {
    const columnHelper = useMemo(() => createColumnHelper<any>(), []);
    const [customClass, setCustomClass] = useState<string>("");
    const [totalPos, setTotalPos] = useState<number>();
    const [bottom, setBottom] = useState<number>();
    const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
    const [timeSpentPos, setTimeSpentPos] = useState<number>();
    const [pagination, setPagination] = useState<PaginationState>({
        pageIndex: 1,
        pageSize: 100,
    });
    const [itemCount, setItemCount] = useState<number>(data.length);
    const divRef = useRef(null);
    const columns = useMemo(
        () => [
            columnHelper.accessor("project_name", {
                header: "Project",
                id: "project_name",
                enableSorting: false,
                cell: ({ getValue, cell }) => {
                    const data = getValue();
                    return (
                        <div className="bg-white text-black2 px-2 flex items-center">
                            <p className=" px-2 py-1">{data}</p>
                        </div>
                    );
                },
            }),
            columnHelper.accessor("task_category_name", {
                header: "Task Tag",
                id: "task_category_name",
                enableSorting: false,
                cell: ({ getValue, cell }) => {
                    const data = getValue();
                    return (
                        <div className="bg-white text-black2 px-2 flex items-center">
                            <p className=" px-2 py-1">{data}</p>
                        </div>
                    );
                },
            }),
            columnHelper.accessor("task_name", {
                header: "Task Name",
                id: "task_name",
                enableSorting: false,
                cell: ({ getValue, cell }) => {
                    const name = getValue();
                    return (
                        <div className="bg-white text-black2 px-2 flex items-center">
                            <p className="px-2 py-1">{name}</p>
                        </div>
                    );
                },
            }),

            columnHelper.accessor("time_spent_text", {
                header: "Time Spent",
                id: "time_spent_text",
                enableSorting: false,
                cell: ({ getValue, cell }) => {
                    const data = getValue();
                    return (
                        <div className="bg-white text-black2 px-2 flex items-center">
                            <p className="px-2 py-1">{data}</p>
                        </div>
                    );
                },
            }),
        ],
        [columnHelper, data]
    );
    useEffect(() => {
        if (data) {
            setPagination({
                pageIndex: 1,
                pageSize: data.length
            })
        }
    }, [data])
    const [columnOrder, setColumnOrder] = useState<string[]>(
        columns.map((column: ColumnDef<any, any>) => column.id as string)
    );
    const visibleColumns: { [key: string]: boolean } = {};

    return (
        <Modal className="focus-within:outline-none focus-visible:outline-none focus:outline-none"
            open={isOpen}
            style={{
                background: "rgb(0 0 0 / 0.5)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
            onClose={(event, reason) => {
                // handleClosePopup();
                if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
                    setIsOpen(false);
                }
            }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Fade in={isOpen} timeout={500}>
                <div className="max-w-[1000px] w-full m-auto">
                    {/* overflow-y-auto */}
                    <div className="modalContent w-full h-full p-6 lg:p-0 flex justify-center items-center relative z-[999] focus-within:outline-none focus-visible:outline-none focus:outline-none">
                        {/* //overflow-hidden  */}
                        <div className="rounded-xl bg-white font-primary w-full">

                            <div className="px-3 py-3 bg-vorpblue flex justify-between rounded-t-lg items-center relative z-[999]">
                                <h3 className="text-18 leading-[27px] font-semibold text-white">
                                    {employee_name} - Daily Report
                                </h3>
                                <button
                                    key={Math.random() * 100}
                                    type="button"
                                    aria-label="Close Modal"
                                    onClick={() => {
                                        setIsOpen(false);
                                    }}
                                >
                                    <CloseIcon />
                                </button>
                            </div>
                            <div className="max-h-[80vh] overflow-y-auto">
                                <div className={classNames('mt-9 px-3')}>
                                    <div>
                                        <div className="mb-5">
                                            <span className="lg:text-24 text-18 font-semibold text-black2 mr-[13px]">{date}</span>
                                            <span className="lg:text-14 text-12 font-normal text-black2">{day_format}</span>
                                        </div>
                                        <div className="bg-anncolor py-4 sm:px-4 px-2 mb-5">
                                            <div className="flex items-center">
                                                <table className="w-full custom_table_timesheet pr-2">
                                                    <tr>
                                                        <th className="font-semibold text-13 text-black2">Clock In 1</th>
                                                        <th className="font-semibold text-13 text-black2">Clock Out 1</th>
                                                        <th className="font-semibold text-13 text-black2">Clock In 2</th>
                                                        <th className="font-semibold text-13 text-black2">Clock Out 2</th>
                                                        <th className="font-semibold text-13 text-black2">Clock In 3</th>
                                                        <th className="font-semibold text-13 text-black2">Clock Out 3</th>
                                                    </tr>
                                                    <tr>
                                                        <td headers="Clock In 1" className="font-normal text-13 text-black2 mt-[6px]">{clockIn1 ? clockIn1 : '---'}</td>
                                                        <td headers="Clock Out 1" className="font-normal text-13 text-black2 mt-[6px]">{clockOut1 ? clockOut1 : '---'}</td>
                                                        <td headers="Clock In 2" className="font-normal text-13 text-black2 mt-[6px]">{clockIn2 ? clockIn2 : '---'}</td>
                                                        <td headers="Clock Out 2" className="font-normal text-13 text-black2 mt-[6px]">{clockOut2 ? clockOut2 : '---'}</td>
                                                        <td headers="Clock In 3" className="font-normal text-13 text-black2 mt-[6px]">{clockIn3 ? clockIn3 : '---'}</td>
                                                        <td headers="Clock Out 3" className="font-normal text-13 text-black2 mt-[6px]">{clockOut3 ? clockOut3 : '---'}</td>
                                                    </tr>
                                                </table>
                                                <div className="sm:pl-7 pl-4 border-l border-l-vorpgraylight">
                                                    <p className="font-semibold xl:text-16 text-14 text-black2 whitespace-nowrap">Total Hrs</p>
                                                    <p className="font-medium text-14 text-black2 whitespace-nowrap">{total_clocking_time_text}</p>
                                                </div>
                                            </div>
                                            <div className="pt-3 mt-3 border-t border-t-vorpgraylight">
                                                <h5 className="text-16 font-semibold text-black2">Reason</h5>
                                                <p className="text-14 font-normal text-black2 mt-1">{reason}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={classNames('', styles.tableDaily)}>
                                        <LayoutAdjustableTable
                                            data={data}
                                            hideColumn={visibleColumns}
                                            columns={columns}
                                            rowSelection={rowSelection}
                                            setRowSelection={setRowSelection}
                                            columnOrder={columnOrder}
                                            setColumnOrder={setColumnOrder}
                                            unDraggableFields={["actions", "selection"]}
                                            theaderColor="text-graydark"
                                            isLayoutControlOpen={false}
                                            setIsLayoutControlOpen={() => 1}
                                            label="User List"
                                            setItemCount={setItemCount}
                                            pagination={pagination}
                                            setPagination={setPagination}
                                            noPaddingCells={["leave_type_id", "fullHalfDay", "status"]}
                                        />
                                    </div>
                                    {/* <CommonTableBottom
                            data={[
                                {
                                    name: totalTime ? "Total" : "",
                                    colSpan: 5,
                                    left: 0,
                                    bottom: bottom,
                                    classes: totalTime ? customClass : "hidden",
                                },
                                {
                                    name: totalTime ? totalTime : "",
                                    colSpan: 3,
                                    left: Number(timeSpentPos) - 40,
                                    bottom: bottom,
                                    classes: totalTime ? customClass : "hidden",
                                },
                            ]}
                        /> */}
                                </div>
                                <div className="flex justify-end mb-9 mt-5 px-10">
                                    {approveOption ? <button onClick={() => {
                                        showConfirmPopup(Number(id), 'Approved');
                                    }} className="font-medium text-14 text-white py-2 px-3 rounded-md bg-vorpgreen flex items-center mr-[10px]">
                                        <CheckIcon className="mr-[5px]" />Approved</button> : ''}
                                    {rejectOption ? <button onClick={() =>
                                        showConfirmPopup(Number(id), 'Rejected')
                                    } className="font-medium text-14 text-white py-2 px-3 rounded-md bg-redPink flex items-center">
                                        <CloseSmallIcon className="mr-[5px]" />Rejected</button> : ''}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fade>
        </Modal>
    );
};

export default memo(MemberDailyReportPopup);
