import {
    Cell,
    ColumnDef,
    ColumnFiltersState,
    createColumnHelper,
    PaginationState,
    RowSelectionState,
    SortingState,
} from "@tanstack/react-table";
import {
    Dispatch,
    FC,
    SetStateAction,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from "react";
import LayoutAdjustableTable from "../Tables/LayoutAdjustableTable";
import { baseObj, dummyProjectData } from "../../utils/constants";
import {
    AddIcon,
    CalendarIcon,
    CancelIcon,
    ChevronDown,
    CloseSmallIcon,
    EditIcon,
    ShareIcon,
    TableSaveIcon,
    TrashIcon,
    ViewIcon,
} from "../common/Icons";
import TablePaginator from "../Tables/TablePaginator";
import EnableSwitch from "../Projects/ProjectCompleteSwitch";
import {
    convertDateToIsoString,
    formatDate,
    formatDateData,
    formatMoney,
} from "../../utils/helpers";
import classNames from "classnames";
import styles from "../EmployeeTable/EmployeeTable.module.scss";
import styles1 from "./Crm.module.scss";
import PaymentProgressBar from "../Projects/PaymentProgressBar";

import { Modals } from "../Projects/Modals";
import EditableCell from "../Tables/EditableCell";
import CustomDropdownWithAddButton from "../EmployeeTable/CustomDropdownWithAddButton";
import { EmployeeData } from "../EmployeeTable/EmployeeTable";
//   import CustomDatePicker from "../common/BaseDatePicker";
import {
    useCreateProjectMutation,
    // useGetCountryListQuery,
    useCreateRegionMutation,
    useCreatePJTypeMutation, useCreatePJStatusMutation, useCreatePaymentStatusMutation,
    useCreateCurrencyMutation, useCreateCheckListMutation, useCreateQuotationNoMutation,
    useGetKeyDateQuery, KeyDateData, useCreateKeyDateMutation, useUpdateProjectMutation,
    useDeleteRegionMutation, useDeletePJTypeMutation, useDeletePJStatusMutation,
    useDeleteCurrencyMutation, useDeletePaymentStatusMutation, useMultiDeleteProjectMutation,
    QuotationData, useDeleteQuotationNoMutation, useRemoveKeyDateMutation,
} from "../../app/services/project";
import DeletePopup from "../DeletePopup/DeletePopup";
import { renderDateFormatDMY, renderDateFormatYMD } from "../DashboardCalendarView/DashboardAddEventPopup";
import DatePopup from "../EmployeeTable/DatePopup";
import { StringMultipleSelectBox } from "../common/MultipleSelectBox";
import { TagsData, useCreateTagMutation, useGetTagsDataQuery } from "../../app/services/annoucement-sheet";
import { useGetCurrencyListQuery } from "../../app/services/employee";
import { IFilterStates } from "../../projects/views/ProjectList";
// import { getAuthUser } from "../../app/services/dashboard";
import { forEach } from "lodash";
// import { makeStyles, Modal, Tooltip } from "@material-ui/core";
import { makeStyles } from '@mui/styles';
import {Tooltip, Modal} from '@mui/material';
import SuccessPopup from "../SuccessPopup/SuccessPopup";
import ErrorPopup from "../ErrorPopup/ErrorPopup";
import { useAddTemplateMutation } from "../../app/services/template";
import DOMPurify from 'dompurify';
import AssignPopup from "../Passwords/AssignPopup";
import { tagsColor } from "../Announcements/AnouncementsTable";
import { toast, ToastContainer } from "react-toastify";


interface ICrmTable {
    isLayoutControlOpen: boolean;
    data: baseObj[];
    setData: Dispatch<SetStateAction<baseObj[]>>;
    setIsLayoutControlOpen: Dispatch<SetStateAction<boolean>>;
    pagination: PaginationState;
    setPagination: Dispatch<SetStateAction<PaginationState>>;
    globalSearch: string;
    setGlobalSearch: Dispatch<SetStateAction<string>>;
    columnFilters: ColumnFiltersState;
    setColumnFilters: Dispatch<SetStateAction<ColumnFiltersState>>;
    regionList: EmployeeData[];
    setRegionList: Dispatch<SetStateAction<EmployeeData[]>>;
    setRegionFilterItems: Dispatch<SetStateAction<string[]>>;
    projectTypesList: EmployeeData[];
    setProjectTypesList: Dispatch<SetStateAction<EmployeeData[]>>;
    paymentStatusList: EmployeeData[] | undefined;
    setPaymentStatusList: Dispatch<SetStateAction<EmployeeData[] | undefined>>;
    projectStatusList: EmployeeData[];
    setProjectStatusList: Dispatch<SetStateAction<EmployeeData[]>>;
    paymentTermList: EmployeeData[];
    setPaymentTermList: Dispatch<SetStateAction<EmployeeData[]>>;
    deleteProject: any,
    projectTagList: TagsData[]

    setFilterStates: Dispatch<SetStateAction<IFilterStates>>;
    filterStates: IFilterStates;
    updateFilterState: any;
    localModuleId: number;

    setHiddenFields: any;
    hiddenFields: string[];
    setCurrentPage: any;
    total: number;
    changePage: any;
    currentPage: number;

    checkManagement: boolean;
    setCols?: any;
    setGetPlaceHolder?: any;

    isCreated?: boolean;
    setIsCreated: Dispatch<SetStateAction<boolean>>;
    isEditing?: boolean;
    setIsEdit: Dispatch<SetStateAction<boolean>>;
    setIsTemplate?: Dispatch<SetStateAction<boolean>>;
}

export const MODALS = {
    QUOTATION_NUM: "quotationNumber",
    PROJECT_SCOPE: "projectScope",
    REMARKS: "remarks",
    CHECKLIST: "checklist",
    KEYDATES: "keyDates",
};

const useStyles = makeStyles(() => ({
    tooltip: {
        backgroundColor: "transparent",
        margin: 0,
        marginRight: 8,
        padding: 0,
    },
}));

export type TModalState = {
    [key: typeof MODALS[keyof typeof MODALS]]: boolean;
};

const initialModalState = {
    [MODALS.QUOTATION_NUM]: false,
    [MODALS.PROJECT_SCOPE]: false,
    [MODALS.REMARKS]: false,
    [MODALS.CHECKLIST]: false,
    [MODALS.KEYDATES]: false,
};

const CrmTable: FC<ICrmTable> = ({
    isLayoutControlOpen,
    setIsLayoutControlOpen,
    pagination,
    setPagination,
    globalSearch,
    setGlobalSearch,
    columnFilters,
    setColumnFilters,
    regionList,
    setRegionList,
    projectTypesList,
    setProjectTypesList,
    paymentStatusList,
    setPaymentStatusList,
    projectStatusList,
    setProjectStatusList,
    paymentTermList,
    setPaymentTermList,
    data,
    setData,
    deleteProject,
    projectTagList,

    setFilterStates,
    filterStates,
    updateFilterState,
    localModuleId,

    setHiddenFields,
    hiddenFields,
    setCurrentPage,
    currentPage,
    total,
    changePage,

    checkManagement,
    setCols,
    setIsTemplate,
    setGetPlaceHolder,
    isCreated,
    setIsEdit,
    isEditing,
    setIsCreated,
}) => {
    const columnHelper = useMemo(() => createColumnHelper<any>(), []); // TODO add type definitions when api binding
    const [sorting, setSorting] = useState<SortingState>([]);
    const [createProject] = useCreateProjectMutation();
    const [updateProject] = useUpdateProjectMutation();
    const [isShow, setIsShow] = useState<boolean>(false);
    const [createRegion] = useCreateRegionMutation();
    const [createPjType] = useCreatePJTypeMutation();
    const [createPStatus] = useCreatePJStatusMutation();
    const [createPayStatus] = useCreatePaymentStatusMutation();
    const [createCurrency] = useCreateCurrencyMutation();
    const [createCheckList] = useCreateCheckListMutation();
    const [createQuotation] = useCreateQuotationNoMutation();
    const { data: currencyList } = useGetCurrencyListQuery();
    const [createKeyDate] = useCreateKeyDateMutation();
    const { data: keyDateDataList } = useGetKeyDateQuery();
    const [deleteRegion] = useDeleteRegionMutation();
    const [deletePJType] = useDeletePJTypeMutation();
    const [deletePJStatus] = useDeletePJStatusMutation();
    const [deleteCurrency] = useDeleteCurrencyMutation();
    const [deletePYStatus] = useDeletePaymentStatusMutation();
    const [multDeletePj] = useMultiDeleteProjectMutation();
    const [deleteQuotation] = useDeleteQuotationNoMutation();
    const [removeKeyDates] = useRemoveKeyDateMutation();
    const [createTag] = useCreateTagMutation();
    const [deleteID, setDeleteID] = useState<number>();
    const [openDeletePopup, setOpenDeleteopup] = useState<boolean>(false);
    const [successPopup, setSuccessPopup] = useState<boolean>(false);
    const [errorPopup, setErrorPopup] = useState<boolean>(false);


    const [regionData, setRegionData] = useState("Select");
    const [pjType, setPjType] = useState("Select");
    const [pjStatus, setPjStatus] = useState("Select");
    const [currencyD, setCurrencyD] = useState("All");
    const [paymentStatusD, setPaymentStatusD] = useState("All");
    const [paymentTermD, setPaymentTermD] = useState("All");
    const [itemCount, setItemCount] = useState<number>(total);

    const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
    // const [itemCount, setItemCount] = useState<number>(dummyProjectData.length);
    const selectRowsCount = Object.keys(rowSelection).length;
    const [modalState, setModalState] = useState<TModalState>(initialModalState);
    const [modalRow, setModalRow] = useState<number>(0);
    const [getPJScope, setGetPJScope] = useState<string>("");
    const [message, setMessage] = useState<any>([]);
    const [currencyLists, setCurrencyLists] = useState<EmployeeData[]>([]);
    const [checkListData, setCheckListData] = useState<EmployeeData[]>([]);
    const [clientId, setClientId] = useState<number>();
    const [tagData, setTagDate] = useState<any[]>([]);
    const [deleteRowID, setDeleteRowID] = useState<number | 0>();
    const [editedData, setEditedData] = useState<any>();
    const [editProjectAmount, setEditProjectAmountValue] = useState<number>(0);
    const [isProjectAmount, setIsProjectAmount] = useState<boolean>(false);
    const [editedTagData, setEditedTagData] = useState<any>();
    //for region delete
    const [isRegion, setIsRegion] = useState<boolean>(false)
    const [isPJType, setIsPJType] = useState<boolean>(false)
    const [isPJStatus, setIsPJStatus] = useState<boolean>(false)
    const [isCurr, setIsCurrency] = useState<boolean>(false)
    const [isPYStatus, setIsPYStatus] = useState<boolean>(false)
    const [isProj, setIsProj] = useState<boolean>(false)

    //for multi select
    const [chosenTags, setChosenTags] = useState<string[]>([]);
    // const { data: tagsData } = useGetTagsDataQuery();
    const [showTagPopup, setShowTagPopup] = useState<boolean>(false);
    const [editRowData, setEditRowData] = useState<any>();
    const [cellData, setCellData] = useState<any>();
    const tags = projectTagList?.map((tag, idx) => ({
        label: tag.name,
        value: tag.name,
        name: tag.name,
        id: tag.id
    }))
        .filter((tag) => tag.label.toLowerCase() !== "all");

    useEffect(() => {
        if (currencyList) {
            var transformCurrencyData: EmployeeData[] = currencyList.map((x: any) => {
                return {
                    id: x.id,
                    name: x.currency_name,
                    label: x.currency_code,
                };
            });
            setCurrencyLists(transformCurrencyData ? transformCurrencyData : []);
        }
    }, [currencyList]);

    useEffect(() => {
        if (editedData?.projectAmount > Number(editProjectAmount)) {
            setIsProjectAmount(true)
        } else {
            setIsProjectAmount(false)
        }
    }, [editProjectAmount])

    const modalStateHandler = (target: string) => {
        setModalState((prev) => ({ ...prev, [target]: true }));
    };

    const getPJScopeHandler = (name: string) => {
        setGetPJScope(name)
    };

    const deleteProjects = useCallback(
        (id?: number, uniqueID?: number) => {
            setDeleteID(id);
            setDeleteRowID(uniqueID);
            setOpenDeleteopup(true);
        },
        [setData, rowSelection]
    );

    const createTagData = (label: string) => {
        const name = DOMPurify.sanitize(label);
        var data = {
            // name: label,
            name,
            flag: 'pj'
        }
        createTag(data)
            .unwrap()
            .then((payload: any) => {
                // console.log("re", payload)
                if (payload.status) {
                    setSuccessPopup(true)
                    setMessage(payload ? [payload?.message] : ["Create Successfully!"]);
                    setOpenDeleteopup(false);
                    toast('Successfully Created.', {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        type: "success",
                        theme: "light",
                    });
                }
            })
            .catch((error: any) => {
                // console.log("e", error)
                setErrorPopup(true)
                setMessage(error ? [error?.data?.message] : ["Something went wrong!Trying again."])
            })

        // return createTag(data);
    };

    const confirmDeleteData = () => {
        var id = deleteRowID;
        var dataID = deleteID;
        const selectedRows = Object.keys(rowSelection);
        var selectionIds = data
            .filter((x, index: number) => {
                return selectedRows.find((s) => s == index.toString()) ? x.uniqeID : 0;
            })
            .map((x) => x.uniqeID);
        if (selectionIds.length > 0) {
            deleteProjectMultiple(selectionIds);
        } else {
            deleteItem(id, dataID);
            setDeleteID(-1);
        }
    };

    // const deleteItem = useCallback(
    //   (id?: number) => {
    //     console.log("rree", isRegion);
    //     const selectedRows = Object.keys(rowSelection);
    //     console.log("idssd", id)
    //     setData((prev: baseObj[]) =>
    //       prev.filter((_, index) => {
    //         return id !== undefined
    //           ? id !== index
    //           : !selectedRows.includes(String(index));
    //       })
    //     );
    //     id === undefined && setRowSelection({});
    //     if(isRegion){
    //       console.log("yes")
    //       if(id !== undefined){
    //         console.log("not undefined")
    //         deleteRegion(id).then((res: any) => {
    //           console.log("rettt", res)
    //         })
    //       }
    //     }else{
    //       console.log("pjj")
    //       // deleteProject(id)
    //     }
    //   },
    //   [setData, rowSelection]
    // );

    const deleteItem = (id?: number, dataId?: number) => {
        const selectedRows = Object.keys(rowSelection);
        setData((prev: baseObj[]) =>
            prev.filter((_, index) => {
                return dataId !== undefined
                    ? dataId !== index
                    : !selectedRows.includes(String(index));
            })
        );
        dataId === undefined && setRowSelection({});
        setOpenDeleteopup(false)

        if (isRegion == true) {
            if (dataId !== undefined) {
                deleteRegion(dataId)
                    .unwrap()
                    .then((payload: any) => {
                        // console.log("pa", payload)
                        if (payload.status) {
                            setIsRegion(false)
                            setIsPJType(false)
                            setIsPJStatus(false)
                            setIsCurrency(false)
                            setIsPYStatus(false)
                            setIsProj(false)

                            setSuccessPopup(true)
                            setMessage(payload ? [payload?.message] : ['Delete Successfullly!'])
                            toast('Successfully Deleted.', {
                                position: "top-right",
                                autoClose: 2000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                type: "success",
                                theme: "light",
                            });
                            setOpenDeleteopup(false)
                        } else {
                            setIsRegion(false)
                            setIsPJType(false)
                            setIsPJStatus(false)
                            setIsCurrency(false)
                            setIsPYStatus(false)
                            setIsProj(false)

                            setErrorPopup(true)
                            setOpenDeleteopup(false)
                            setMessage(payload ? [payload?.message] : ['Cannot Delete!'])
                        }
                    })
                    .catch((error: any) => {
                        // console.log("e", error)
                        setIsRegion(false)
                        setIsPJType(false)
                        setIsPJStatus(false)
                        setIsCurrency(false)
                        setIsPYStatus(false)
                        setIsProj(false)

                        setErrorPopup(true)
                        setMessage(error ? [error?.data?.message] : ["Something went wrong!Trying again."])
                        setOpenDeleteopup(false)
                    })
            }
        } else if (isPJType == true) {
            if (dataId !== undefined) {
                deletePJType(dataId)
                    .unwrap()
                    .then((payload: any) => {
                        // console.log("p", payload)
                        if (payload.status) {
                            setIsRegion(false)
                            setIsPJType(false)
                            setIsPJStatus(false)
                            setIsCurrency(false)
                            setIsPYStatus(false)
                            setIsProj(false)

                            setSuccessPopup(true)
                            setMessage(payload ? [payload?.message] : ["Delete Successfully!"]);
                            toast('Successfully Deleted.', {
                                position: "top-right",
                                autoClose: 2000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                type: "success",
                                theme: "light",
                            });
                            setOpenDeleteopup(false)
                        } else {
                            setIsRegion(false)
                            setIsPJType(false)
                            setIsPJStatus(false)
                            setIsCurrency(false)
                            setIsPYStatus(false)
                            setIsProj(false)

                            setErrorPopup(true)
                            setMessage(payload ? [payload?.message] : ['Cannot Delete']);
                            setOpenDeleteopup(false)
                        }

                    })
                    .catch((error: any) => {
                        // console.log("e", error)

                        setIsRegion(false)
                        setIsPJType(false)
                        setIsPJStatus(false)
                        setIsCurrency(false)
                        setIsPYStatus(false)
                        setIsProj(false)

                        setErrorPopup(true)
                        setMessage(error ? [error?.data?.message] : ["Something went wrong!Trying again."])
                        setOpenDeleteopup(false)
                    })
            }
        } else if (isPJStatus === true) {
            if (dataId !== undefined) {
                deletePJStatus(dataId)
                    .unwrap()
                    .then((payload: any) => {
                        // console.log("p", payload)
                        if (payload.status) {
                            setIsRegion(false)
                            setIsPJType(false)
                            setIsPJStatus(false)
                            setIsCurrency(false)
                            setIsPYStatus(false)
                            setIsProj(false)

                            setSuccessPopup(true)
                            setMessage(payload ? [payload?.message] : ['Delete Successfully!']);
                            toast('Successfully Deleted.', {
                                position: "top-right",
                                autoClose: 2000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                type: "success",
                                theme: "light",
                            });
                            setOpenDeleteopup(false)
                        } else {
                            setIsRegion(false)
                            setIsPJType(false)
                            setIsPJStatus(false)
                            setIsCurrency(false)
                            setIsPYStatus(false)
                            setIsProj(false)

                            setErrorPopup(true)
                            setMessage(payload ? [payload?.message] : ['Cannot Delete']);
                            setOpenDeleteopup(false)
                        }
                    })
                    .catch((error: any) => {
                        // console.log("e", error)

                        setIsRegion(false)
                        setIsPJType(false)
                        setIsPJStatus(false)
                        setIsCurrency(false)
                        setIsPYStatus(false)
                        setIsProj(false)

                        setErrorPopup(true)
                        setMessage(error ? [error?.data?.message] : ["Something went wrong!Trying again."])
                        setOpenDeleteopup(false)
                    })
            }
        } else if (isCurr === true) {
            if (dataId !== undefined) {
                deleteCurrency(dataId)
                    .unwrap()
                    .then((payload: any) => {
                        // console.log("c", payload)
                        if (payload.status) {
                            setIsRegion(false)
                            setIsPJType(false)
                            setIsPJStatus(false)
                            setIsCurrency(false)
                            setIsPYStatus(false)
                            setIsProj(false)

                            setSuccessPopup(true)
                            setMessage(payload ? [payload?.message] : ["Successfully Created!."]);
                            toast('Successfully Created.', {
                                position: "top-right",
                                autoClose: 2000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                type: "success",
                                theme: "light",
                            });
                            setOpenDeleteopup(false)
                        } else {
                            setIsRegion(false)
                            setIsPJType(false)
                            setIsPJStatus(false)
                            setIsCurrency(false)
                            setIsPYStatus(false)
                            setIsProj(false)

                            setErrorPopup(true)
                            setOpenDeleteopup(false)
                            setMessage(payload ? [payload?.message] : ['Cannot Delete!'])
                        }
                    })
                    .catch((error: any) => {
                        // console.log("error", error)
                        setIsRegion(false)
                        setIsPJType(false)
                        setIsPJStatus(false)
                        setIsCurrency(false)
                        setIsPYStatus(false)
                        setIsProj(false)

                        setErrorPopup(true)
                        setMessage(error ? [error?.data?.message] : ["Something went wrong!Trying again."])
                        setOpenDeleteopup(false)
                    })
            }
        } else if (isPYStatus === true) {
            if (dataId !== undefined) {
                deletePYStatus(dataId)
                    .unwrap()
                    .then((payload: any) => {
                        // console.log("p", payload)
                        if (payload.status) {
                            setIsRegion(false)
                            setIsPJType(false)
                            setIsPJStatus(false)
                            setIsCurrency(false)
                            setIsPYStatus(false)
                            setIsProj(false)

                            setSuccessPopup(true)
                            setMessage(payload ? [payload?.message] : ['Delete Successfully']);
                            toast('Successfully Deleted.', {
                                position: "top-right",
                                autoClose: 2000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                type: "success",
                                theme: "light",
                            });
                            setOpenDeleteopup(false)
                        } else {
                            setIsRegion(false)
                            setIsPJType(false)
                            setIsPJStatus(false)
                            setIsCurrency(false)
                            setIsPYStatus(false)
                            setIsProj(false)

                            setErrorPopup(true)
                            setMessage(payload ? [payload?.message] : ['Cannot Delete']);
                            setOpenDeleteopup(false)
                        }

                    })
                    .catch((error: any) => {
                        // console.log("e", error)

                        setIsRegion(false)
                        setIsPJType(false)
                        setIsPJStatus(false)
                        setIsCurrency(false)
                        setIsPYStatus(false)
                        setIsProj(false)

                        setErrorPopup(true)
                        setMessage(error ? [error?.data?.message] : ["Something went wrong!Trying again."])
                        setOpenDeleteopup(false)
                    })
            }
        } else if (isProj === true) {
            if (id !== undefined) {
                // console.log("id", id)
                // deleteProject(id)
                //     .unwrap()
                //     .then((payload: any) => {
                //         if (payload) {
                //             setIsRegion(false)
                //             setIsPJType(false)
                //             setIsPJStatus(false)
                //             setIsCurrency(false)
                //             setIsPYStatus(false)
                //             setIsProj(false)

                //             setSuccessPopup(true)
                //             setMessage(payload ? [payload?.message] : ["Successfully Delete!"]);
                //             toast('Successfully Deleted.', {
                //                 position: "top-right",
                //                 autoClose: 2000,
                //                 hideProgressBar: false,
                //                 closeOnClick: true,
                //                 pauseOnHover: true,
                //                 draggable: true,
                //                 progress: undefined,
                //                 type: "success",
                //                 theme: "light",
                //             });
                //             setOpenDeleteopup(false)
                //         }
                //     })
                //     .catch((error: any) => {
                //         setIsRegion(false)
                //         setIsPJType(false)
                //         setIsPJStatus(false)
                //         setIsCurrency(false)
                //         setIsPYStatus(false)
                //         setIsProj(false)

                //         setErrorPopup(true)
                //         setMessage(error ? [error?.data?.message] : ["Something went wrong!Trying again."])
                //         setOpenDeleteopup(false)
                //     })
                setIsProj(false)
            }
        }
    }

    const deleteProjectMultiple = (ids: number[]) => {
        multDeletePj(ids)
            .unwrap()
            .then((payload: any) => {
                // console.log("pp", payload)
                setOpenDeleteopup(false)
                setSuccessPopup(true)
                setMessage(payload ? [payload?.message] : ['Delete Successfully!']);
                toast('Successfully Deleted.', {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    type: "success",
                    theme: "light",
                });
            })
            .catch((error: any) => {
                // console.log("e", error)
                setErrorPopup(true)
                setMessage(error ? [error?.data?.message] : ["Something went wrong!"])
                setOpenDeleteopup(false)
            })
        setRowSelection({});
    };

    const editProject = useCallback(
        (id: number) =>
            setData((prev: baseObj[]) => {
                return prev.map((data, index: number) => {
                    if (index === id) {
                        return { ...data, isEdit: true, isNew: true };
                        // return { ...data, isNew: true };
                    }
                    return data;
                });
            }),
        [setData]
    );

    const addRegionData = (data: string) => {
        const name = DOMPurify.sanitize(data);
        createRegion({ name })
            .unwrap()
            .then((payload: any) => {
                // console.log("re", payload)
                if (payload.status) {
                    setSuccessPopup(true)
                    setMessage([payload?.message]);
                    toast('Successfully Created.', {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        type: "success",
                        theme: "light",
                    });
                    setOpenDeleteopup(false)
                }
            })
            .catch((error: any) => {
                // console.log("e", error)
                setErrorPopup(true)
                setMessage(error ? [error?.data?.message] : ["Something went wrong!Trying again."])
            })
    };

    const addPJTypeData = (data: string) => {
        const name = DOMPurify.sanitize(data);
        createPjType({ name })
            .unwrap()
            .then((payload: any) => {
                // console.log("p", payload)
                if (payload.status) {
                    setSuccessPopup(true)
                    setMessage([payload?.message]);
                    setOpenDeleteopup(false);
                    toast('Successfully Created.', {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        type: "success",
                        theme: "light",
                    });
                }
            })
            .catch((error: any) => {
                // console.log("ee", error)
                setErrorPopup(true)
                setMessage(error ? [error?.data?.message] : ["Something went wrong!Trying again."])
            })
    };

    const addPJStatusData = (data: string) => {
        const name = DOMPurify.sanitize(data);
        createPStatus({ name })
            .unwrap()
            .then((payload: any) => {
                // console.log("p", payload)
                if (payload.status) {
                    setSuccessPopup(true)
                    setMessage(payload ? [payload?.message] : ['Create Successfully']);
                    setOpenDeleteopup(false);
                    toast('Successfully Created.', {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        type: "success",
                        theme: "light",
                    });
                }
            })
            .catch((error: any) => {
                // console.log("e", error)
                setErrorPopup(true)
                setMessage(error ? [error?.data?.message] : ["Something went wrong!Trying again."])
            })
    };

    const addPayStatusData = (data: string) => {
        const name = DOMPurify.sanitize(data);

        createPayStatus({ name })
            .unwrap()
            .then((payload: any) => {
                // console.log("p", payload)
                if (payload.status) {
                    setSuccessPopup(true)
                    setMessage(payload ? [payload?.message] : ['Create Successfully']);
                    setOpenDeleteopup(false);
                    toast('Successfully Created.', {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        type: "success",
                        theme: "light",
                    });
                }
            })
            .catch((error: any) => {
                // console.log("ee", error)
                setErrorPopup(true)
                setMessage(error ? [error?.data?.message] : ["Something went wrong!Trying again."])
            })
    };

    const addQuotationData = (id: number, name: string) => {
        var exQ = data.find(x => x.uniqeID == modalRow)?.quotationNumber;

        var updated = data.map((x) => {
            if (x.uniqeID == modalRow) {
                x.quotationNumber = x.quotationNumber ? x.quotationNumber + "," + name : name;
            }
            return { ...x }
        })
        setData(updated)
        createQuotation({ project_id: id, quotationNo: name }).then((res: any) => {
            var resData = res?.data;

        });
    };

    const removeQuotation = (quoData: QuotationData) => {
        deleteQuotation(quoData);
        var updatedData = data.map((x) => {
            if (x.uniqeID == quoData.project_id) {
                var orgQuo = x.quotationNumber.split(',');
                var filter = orgQuo?.filter((x: any) => x != quoData.quotationNo).join(',');
                x.quotationNumber = filter;
            }
            return { ...x }
        })
        setData(updatedData)
    }

    const addCurreencyData = (data: string, code: string) => {
        const name = DOMPurify.sanitize(data);
        const codes = DOMPurify.sanitize(code);

        createCurrency({ currency_name: name, currency_code: codes })
            .unwrap()
            .then((payload: any) => {
                // console.log("d", payload)
                if (payload.status) {
                    setSuccessPopup(true)
                    setMessage(payload ? [payload?.message] : ['Successfully Created!.']);
                    setIsCurrency(false);
                    toast('Successfully Created.', {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        type: "success",
                        theme: "light",
                    });
                }
            })
            .catch((error: any) => {
                setErrorPopup(true)
                setMessage(error ? [error?.data?.message] : ["Something went wrong!Trying again."])
                setIsCurrency(false)
            })

    };

    const classes = useStyles();
    const addCheckListData = (state: any) => {
        // createCheckList({ id: 0, label: state?.label, isChecked: state?.isCheck, project_id: state?.id })
        //   .unwrap()
        //   .then((payload: any) => {
        //     setSuccessPopup(true)
        //     setMessage(payload?.message);
        //     // isNew = false;
        //   })
        //   .catch((error: any) => {
        //     setErrorPopup(true)
        //     setMessage(error ? error?.data?.message : "Something went wrong!")
        //     // isNew = true;
        //   })
        var checklistData = state?.map((s: any) => {
            return {
                label: s.label,
                isChecked: s.isChecked,
                project_id: s.project_id
            }
        });
        createCheckList(checklistData)
            .unwrap()
            .then((payload: any) => {
                setSuccessPopup(true)
                setMessage([payload?.message]);
                toast('Successfully Created.', {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    type: "success",
                    theme: "light",
                });
            })
            .catch((error: any) => {
                setErrorPopup(true)
                setMessage(error ? [error?.data?.message] : ["Something went wrong!"])
            })
    };

    const addKeyDateData = (state: any) => {
        let keyType: any = [];
        //need to call api
        var keyDateData = state.map((s: any) => {
            return {
                id: s.id,
                date: s.date,
                project_id: s.project_id,
                //name:s.label
            }
        });
        // keyDateDataList?.map(function (item: any) {
        //   if (item.label == state?.label) {
        //     keyType.push(item.id);
        //   }
        // });

        createKeyDate(keyDateData)
            .unwrap()
            .then((payload: any) => {
                var resData = payload?.data;
                setData((prev: any) => {
                    if (prev.uniqeID == modalRow) {
                        prev.checkList.push(resData)
                    }
                    return prev;
                })
                setSuccessPopup(true)
                setMessage([payload?.message]);
                toast('Successfully Created.', {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    type: "success",
                    theme: "light",
                });
            })
            .catch((error: any) => {
                setErrorPopup(true)
                setMessage(error ? [error?.data?.message] : ["Something went wrong!"])
            })

        // .then((res: any) => {
        //   var resData = res?.data;
        //   setData((prev: any) => {
        //     if (prev.uniqeID == modalRow) {
        //       prev.checkList.push(resData)
        //     }
        //     return prev;
        //   })
        //   var updateData = data.find(x => x.uniqeID == modalRow);
        // });
    };
    // TODO add type definitions when api binding
    const columns = useMemo(
        () => [
            columnHelper.display({
                header: "",
                id: "none0",
            }),
            columnHelper.display({
                header: "",
                id: "none1",
            }),
            columnHelper.display({
                header: "",
                id: "none2",
            }),
            columnHelper.display({
                header: "",
                id: "none3",
            }),
            columnHelper.display({
                header: "",
                id: "none4",
            }),
            columnHelper.display({
                header: "",
                id: "none5",
            }),
            columnHelper.display({
                header: "",
                id: "none6",
            }),
            columnHelper.display({
                header: "",
                id: "none7",
            }),
            columnHelper.display({
                header: "",
                id: "none8",
            }),
            columnHelper.display({
                header: "",
                id: "none9",
            }),
            columnHelper.display({
                header: "",
                id: "none10",
            }),
            columnHelper.display({
                header: "",
                id: "none11",
            }),
            columnHelper.display({
                header: "",
                id: "none12",
            }),
            columnHelper.display({
                header: "",
                id: "none13",
            }),
            columnHelper.display({
                header: "",
                id: "none14",
            }),
            columnHelper.display({
                header: "",
                id: "none15",
            }),
            columnHelper.display({
                header: "",
                id: 'selection',
            }),
            columnHelper.accessor("uniqeID", {
                header: "",
                id: "uniqeID",
            }),
            columnHelper.accessor("clientId", {
                header: "Client ID",
                id: "clientId",
                cell: ({ getValue, cell }) => {
                    const projectCode = getValue();
                    const isNew: boolean = cell.row.original?.isNew;

                    return (
                        <div className={isNew ? 'bg-white text-black2 px-2' : '' + 'w-full min-w-[140px] bg-white text-black2 px-2'}>
                            <p >{projectCode}</p>
                        </div>
                    );
                },
            }),
            columnHelper.accessor("firstName", {
                header: "First Name",
                id: "firstName",
                cell: ({ getValue, cell }) => {
                    const firstName = getValue();
                    return (
                        <div className="w-full min-w-[140px]">
                            <EditableCell
                                setData={setData}
                                value={firstName}
                                type="text"
                                cell={cell}
                            />
                        </div>
                    );
                },
            }),
            columnHelper.accessor("lastName", {
                header: "Last Name",
                id: "lastName",
                cell: ({ getValue, cell }) => {
                    const firstName = getValue();
                    return (
                        <div className="w-full min-w-[140px]">
                            <EditableCell
                                setData={setData}
                                value={firstName}
                                type="text"
                                cell={cell}
                            />
                        </div>
                    );
                },
            }),
            columnHelper.accessor("contactDID", {
                header: "Contact DID",
                id: "contactDID",
                cell: ({ getValue, cell }) => {
                    const firstName = getValue();
                    return (
                        <div className="w-full min-w-[140px]">
                            <EditableCell
                                setData={setData}
                                value={firstName}
                                type="text"
                                cell={cell}
                            />
                        </div>
                    );
                },
            }),
            columnHelper.accessor("email", {
                header: "email",
                id: "email",
                cell: ({ getValue, cell }) => {
                    const firstName = getValue();
                    return (
                        <div className="w-full min-w-[140px]">
                            <EditableCell
                                setData={setData}
                                value={firstName}
                                type="text"
                                cell={cell}
                            />
                        </div>
                    );
                },
            }),
            columnHelper.accessor("address", {
                header: "Address",
                id: "address",
                cell: ({ getValue, cell }) => {
                    const firstName = getValue();
                    return (
                        <div className="w-full min-w-[140px]">
                            <EditableCell
                                setData={setData}
                                value={firstName}
                                type="text"
                                cell={cell}
                            />
                        </div>
                    );
                },
            }),
            columnHelper.accessor("companyName", {
                header: "Company Name",
                id: "companyName",
                cell: ({ getValue, cell }) => {
                    const firstName = getValue();
                    return (
                        <div className="w-full min-w-[140px]">
                            <EditableCell
                                setData={setData}
                                value={firstName}
                                type="text"
                                cell={cell}
                            />
                        </div>
                    );
                },
            }),
            columnHelper.accessor("industry", {
                header: "Industry",
                id: "industry",
                cell: ({ getValue, cell }) => {
                    const data = getValue();
                    var getView: any;
                    if (data) {
                        getView = regionList?.find((x) => x.id == data);
                    } else {
                        getView = regionList?.find((x) => x.name == data);
                    }
                    const isEditable = cell?.row?.original?.isNew;
                    const isNew: boolean = cell.row.original?.isNew;
                    return (
                        <>
                            {!isEditable ? (
                                <div className="min-w-[200px] w-full px-3 pr-4 flex justify-between items-center">
                                    <p className={`text-black2`}>{data ? data?.name : 'Select'}</p>
                                </div>
                            ) : (
                                <div className="min-w-[200px] flex justify-between items-center">
                                    <EditableCell
                                        hasPadding={true}
                                        setData={setData}
                                        value={getView ? getView.name : 'Select'}
                                        type="custom"
                                        cell={cell}
                                        CustomComponent={
                                            <CustomDropdownWithAddButton
                                                isShowAdd={true}
                                                isShowDelete={true}
                                                isCode={true}
                                                isRegion={true}
                                                addNewItemData={addRegionData}
                                                setIsRegion={setIsRegion}
                                                setDeleteID={setDeleteID}
                                                setOpenDeleteopup={setOpenDeleteopup}
                                                initialValues={0} // TODO bypassing
                                                dataList={regionList ? regionList : []}
                                                setDataList={setRegionList}
                                                title="Region"
                                                cell={cell}
                                                data={data}
                                                setData={setData}
                                                setSelectedData={setRegionData} // TODO bypassing
                                                // setSelectedData={() => 1} // TODO bypassing
                                                selectedData={getView ? getView.name : data ? data?.name : 'Select'}
                                                isEditable={isNew}
                                            />
                                        }
                                    />
                                </div>
                            )}
                        </>
                    );
                },
            }),
            columnHelper.accessor("primaryOrNot", {
                header: "Primary Or Not",
                id: "primaryOrNot",
                cell: ({ getValue, cell }) => {
                    const data = getValue();
                    var getView: any;
                    if (data) {
                        getView = regionList?.find((x) => x.id == data);
                    } else {
                        getView = regionList?.find((x) => x.name == data);
                    }
                    const isEditable = cell?.row?.original?.isNew;
                    const isNew: boolean = cell.row.original?.isNew;
                    return (
                        <>
                            {!isEditable ? (
                                <div className="min-w-[200px] w-full px-3 pr-4 flex justify-between items-center">
                                    <p className={`text-black2`}>{data ? data?.name : 'Select'}</p>
                                </div>
                            ) : (
                                <div className="min-w-[200px] flex justify-between items-center">
                                    <EditableCell
                                        hasPadding={true}
                                        setData={setData}
                                        value={getView ? getView.name : 'Select'}
                                        type="custom"
                                        cell={cell}
                                        CustomComponent={
                                            <CustomDropdownWithAddButton
                                                isShowAdd={true}
                                                isShowDelete={true}
                                                isCode={true}
                                                isRegion={true}
                                                addNewItemData={addRegionData}
                                                setIsRegion={setIsRegion}
                                                setDeleteID={setDeleteID}
                                                setOpenDeleteopup={setOpenDeleteopup}
                                                initialValues={0} // TODO bypassing
                                                dataList={regionList ? regionList : []}
                                                setDataList={setRegionList}
                                                title="Region"
                                                cell={cell}
                                                data={data}
                                                setData={setData}
                                                setSelectedData={setRegionData} // TODO bypassing
                                                // setSelectedData={() => 1} // TODO bypassing
                                                selectedData={getView ? getView.name : data ? data?.name : 'Select'}
                                                isEditable={isNew}
                                            />
                                        }
                                    />
                                </div>
                            )}
                        </>
                    );
                },
            }),
            columnHelper.accessor("websiteUrl", {
                header: "WebsiteUrl",
                id: "websiteUrl",
                cell: ({ getValue, cell }) => {
                    const firstName = getValue();
                    return (
                        <div className="w-full min-w-[140px]">
                            <EditableCell
                                setData={setData}
                                value={firstName.slice(0, 20)}
                                type="text"
                                cell={cell}
                            />
                        </div>
                    );
                },
            }),
            columnHelper.accessor("leadSource", {
                header: "Lead Source",
                id: "leadSource",
                cell: ({ getValue, cell }) => {
                    const data = getValue();
                    var getView: any;
                    if (data) {
                        getView = regionList?.find((x) => x.id == data);
                    } else {
                        getView = regionList?.find((x) => x.name == data);
                    }
                    const isEditable = cell?.row?.original?.isNew;
                    const isNew: boolean = cell.row.original?.isNew;
                    return (
                        <>
                            {!isEditable ? (
                                <div className="min-w-[200px] w-full px-3 pr-4 flex justify-between items-center">
                                    <p className={`text-black2`}>{data ? data?.name : 'Select'}</p>
                                </div>
                            ) : (
                                <div className="min-w-[200px] flex justify-between items-center">
                                    <EditableCell
                                        hasPadding={true}
                                        setData={setData}
                                        value={getView ? getView.name : 'Select'}
                                        type="custom"
                                        cell={cell}
                                        CustomComponent={
                                            <CustomDropdownWithAddButton
                                                isShowAdd={true}
                                                isShowDelete={true}
                                                isCode={true}
                                                isRegion={true}
                                                addNewItemData={addRegionData}
                                                setIsRegion={setIsRegion}
                                                setDeleteID={setDeleteID}
                                                setOpenDeleteopup={setOpenDeleteopup}
                                                initialValues={0} // TODO bypassing
                                                dataList={regionList ? regionList : []}
                                                setDataList={setRegionList}
                                                title="Region"
                                                cell={cell}
                                                data={data}
                                                setData={setData}
                                                setSelectedData={setRegionData} // TODO bypassing
                                                // setSelectedData={() => 1} // TODO bypassing
                                                selectedData={getView ? getView.name : data ? data?.name : 'Select'}
                                                isEditable={isNew}
                                            />
                                        }
                                    />
                                </div>
                            )}
                        </>
                    );
                },
            }),
            columnHelper.accessor("leadType", {
                header: "Lead Type",
                id: "leadType",
                cell: ({ getValue, cell }) => {
                    const data = getValue();
                    var getView: any;
                    if (data) {
                        getView = regionList?.find((x) => x.id == data);
                    } else {
                        getView = regionList?.find((x) => x.name == data);
                    }
                    const isEditable = cell?.row?.original?.isNew;
                    const isNew: boolean = cell.row.original?.isNew;
                    return (
                        <>
                            {!isEditable ? (
                                <div className="min-w-[200px] w-full px-3 pr-4 flex justify-between items-center">
                                    <p className={`text-black2`}>{data ? data?.name : 'Select'}</p>
                                </div>
                            ) : (
                                <div className="min-w-[200px] flex justify-between items-center">
                                    <EditableCell
                                        hasPadding={true}
                                        setData={setData}
                                        value={getView ? getView.name : 'Select'}
                                        type="custom"
                                        cell={cell}
                                        CustomComponent={
                                            <CustomDropdownWithAddButton
                                                isShowAdd={true}
                                                isShowDelete={true}
                                                isCode={true}
                                                isRegion={true}
                                                addNewItemData={addRegionData}
                                                setIsRegion={setIsRegion}
                                                setDeleteID={setDeleteID}
                                                setOpenDeleteopup={setOpenDeleteopup}
                                                initialValues={0} // TODO bypassing
                                                dataList={regionList ? regionList : []}
                                                setDataList={setRegionList}
                                                title="Region"
                                                cell={cell}
                                                data={data}
                                                setData={setData}
                                                setSelectedData={setRegionData} // TODO bypassing
                                                // setSelectedData={() => 1} // TODO bypassing
                                                selectedData={getView ? getView.name : data ? data?.name : 'Select'}
                                                isEditable={isNew}
                                            />
                                        }
                                    />
                                </div>
                            )}
                        </>
                    );
                },
            }),
            columnHelper.accessor("leadStatus", {
                header: "Lead Status",
                id: "leadStatus",
                cell: ({ getValue, cell }) => {
                    const data = getValue();
                    var getView: any;
                    if (data) {
                        getView = regionList?.find((x) => x.id == data);
                    } else {
                        getView = regionList?.find((x) => x.name == data);
                    }
                    const isEditable = cell?.row?.original?.isNew;
                    const isNew: boolean = cell.row.original?.isNew;
                    return (
                        <>
                            {!isEditable ? (
                                <div className="min-w-[200px] w-full px-3 pr-4 flex justify-between items-center">
                                    <p className={`text-black2`}>{data ? data?.name : 'Select'}</p>
                                </div>
                            ) : (
                                <div className="min-w-[200px] flex justify-between items-center">
                                    <EditableCell
                                        hasPadding={true}
                                        setData={setData}
                                        value={getView ? getView.name : 'Select'}
                                        type="custom"
                                        cell={cell}
                                        CustomComponent={
                                            <CustomDropdownWithAddButton
                                                isShowAdd={true}
                                                isShowDelete={true}
                                                isCode={true}
                                                isRegion={true}
                                                addNewItemData={addRegionData}
                                                setIsRegion={setIsRegion}
                                                setDeleteID={setDeleteID}
                                                setOpenDeleteopup={setOpenDeleteopup}
                                                initialValues={0} // TODO bypassing
                                                dataList={regionList ? regionList : []}
                                                setDataList={setRegionList}
                                                title="Region"
                                                cell={cell}
                                                data={data}
                                                setData={setData}
                                                setSelectedData={setRegionData} // TODO bypassing
                                                // setSelectedData={() => 1} // TODO bypassing
                                                selectedData={getView ? getView.name : data ? data?.name : 'Select'}
                                                isEditable={isNew}
                                            />
                                        }
                                    />
                                </div>
                            )}
                        </>
                    );
                },
            }),
            columnHelper.accessor("serviceRequired", {
                header: "ServiceRequired",
                id: "serviceRequired",
                cell: ({ getValue, cell }) => {
                    const data = getValue();
                    var getView: any;
                    if (data) {
                        getView = regionList?.find((x) => x.id == data);
                    } else {
                        getView = regionList?.find((x) => x.name == data);
                    }
                    const isEditable = cell?.row?.original?.isNew;
                    const isNew: boolean = cell.row.original?.isNew;
                    return (
                        <>
                            {!isEditable ? (
                                <div className="min-w-[200px] w-full px-3 pr-4 flex justify-between items-center">
                                    <p className={`text-black2`}>{data ? data?.name : 'Select'}</p>
                                </div>
                            ) : (
                                <div className="min-w-[200px] flex justify-between items-center">
                                    <EditableCell
                                        hasPadding={true}
                                        setData={setData}
                                        value={getView ? getView.name : 'Select'}
                                        type="custom"
                                        cell={cell}
                                        CustomComponent={
                                            <CustomDropdownWithAddButton
                                                isShowAdd={true}
                                                isShowDelete={true}
                                                isCode={true}
                                                isRegion={true}
                                                addNewItemData={addRegionData}
                                                setIsRegion={setIsRegion}
                                                setDeleteID={setDeleteID}
                                                setOpenDeleteopup={setOpenDeleteopup}
                                                initialValues={0} // TODO bypassing
                                                dataList={regionList ? regionList : []}
                                                setDataList={setRegionList}
                                                title="Region"
                                                cell={cell}
                                                data={data}
                                                setData={setData}
                                                setSelectedData={setRegionData} // TODO bypassing
                                                // setSelectedData={() => 1} // TODO bypassing
                                                selectedData={getView ? getView.name : data ? data?.name : 'Select'}
                                                isEditable={isNew}
                                            />
                                        }
                                    />
                                </div>
                            )}
                        </>
                    );
                },
            }),
            columnHelper.accessor("followUpStatus", {
                header: "follow Up",
                id: "followUpStatus",
                cell: ({ getValue, cell }) => {
                    const data = getValue();
                    var getView: any;
                    if (data) {
                        getView = regionList?.find((x) => x.id == data);
                    } else {
                        getView = regionList?.find((x) => x.name == data);
                    }
                    const isEditable = cell?.row?.original?.isNew;
                    const isNew: boolean = cell.row.original?.isNew;
                    return (
                        <>
                            {!isEditable ? (
                                <div className="min-w-[200px] w-full px-3 pr-4 flex justify-between items-center">
                                    <p className={`text-black2`}>{data ? data?.name : 'Select'}</p>
                                </div>
                            ) : (
                                <div className="min-w-[200px] flex justify-between items-center">
                                    <EditableCell
                                        hasPadding={true}
                                        setData={setData}
                                        value={getView ? getView.name : 'Select'}
                                        type="custom"
                                        cell={cell}
                                        CustomComponent={
                                            <CustomDropdownWithAddButton
                                                isShowAdd={true}
                                                isShowDelete={true}
                                                isCode={true}
                                                isRegion={true}
                                                addNewItemData={addRegionData}
                                                setIsRegion={setIsRegion}
                                                setDeleteID={setDeleteID}
                                                setOpenDeleteopup={setOpenDeleteopup}
                                                initialValues={0} // TODO bypassing
                                                dataList={regionList ? regionList : []}
                                                setDataList={setRegionList}
                                                title="Region"
                                                cell={cell}
                                                data={data}
                                                setData={setData}
                                                setSelectedData={setRegionData} // TODO bypassing
                                                // setSelectedData={() => 1} // TODO bypassing
                                                selectedData={getView ? getView.name : data ? data?.name : 'Select'}
                                                isEditable={isNew}
                                            />
                                        }
                                    />
                                </div>
                            )}
                        </>
                    );
                },
            }),
            columnHelper.display({
                header: '',
                id: 'lasted',
            }),
            columnHelper.display({
                header: "Action",
                id: `${checkManagement ? 'actions' : 'none-actions'}`,
                cell: ({ cell }) => {
                    var isHaveNew = data?.find(x => x.isNew == true);
                    var disabledClass = isHaveNew ? 'opacity-50 pointer-events-none' : '';
                    const isNew: boolean = cell.row.original?.isNew;
                    const getData = cell.row.original;
                    return (
                        <div
                            className={classNames(
                                styles.actionedBtn,
                                "flex justify-center items-center min-w-max max-w-[98px]"
                            )}
                        >

                            {isNew ? (
                                <>
                                    <button
                                        type="button"
                                        onClick={() => saveProject(Number(cell?.row?.original?.uniqeID))}
                                        className={classNames("w-8 h-8 flex justify-center items-center bg-vorpgreen rounded-md")}
                                    >
                                        <TableSaveIcon />
                                    </button>

                                    <button
                                        type="button"
                                        onClick={() => {
                                            canCeelRow(Number(cell?.row?.original?.uniqeID))
                                            setIsCreated(false)
                                        }
                                        }
                                        className={classNames(styles.customCloseIcon, "w-8 h-8 ml-2 mr-2 flex justify-center items-center group rounded-md  bg-[#71717a] hover:bg-[#71717a] transition-all duration-300")}
                                    >
                                        <CancelIcon width={18} height={18} />
                                    </button>
                                </>
                            ) : (
                                <>
                                    {
                                        checkManagement ? (
                                            <>
                                                <button
                                                    disabled={isHaveNew ? true : false}
                                                    type="button"
                                                    onClick={() => {
                                                        editProject(Number(cell.row.id))
                                                        setClientId(cell?.row?.original?.client?.client_id)
                                                        setTagDate(cell?.row?.original?.projectTag)
                                                        setEditedData(getData)
                                                        if (setIsEdit) {
                                                            setIsEdit(true)
                                                        }
                                                        if (setIsCreated) {
                                                            setIsCreated(false)
                                                        }
                                                    }
                                                    }
                                                    className={classNames(disabledClass, 'w-8 h-8 mr-2 flex justify-center items-center group rounded-md bg-vorpblue hover:bg-annhoverbg transition-all duration-300')}
                                                >
                                                    <EditIcon />
                                                </button>
                                                <button
                                                    type="button"
                                                    onClick={() => {
                                                        setIsProj(true)
                                                        deleteProjects(Number(cell.row.id), Number(cell?.row?.original?.clientId))
                                                    }}
                                                    className={classNames(disabledClass, "w-8 h-8 flex justify-center items-center group rounded-md bg-redPink hover:bg-redPinkLight transition-all duration-300")}
                                                >
                                                    <TrashIcon />
                                                </button>
                                            </>
                                        ) : null
                                    }
                                </>
                            )}
                        </div>
                    );
                },
            }),
        ],
        [columnHelper, data]);

    const [columnOrder, setColumnOrder] = useState<string[]>(columns.map((column: ColumnDef<any, any>) => column.id as string));

    const visibleColumns: { [key: string]: boolean } = {}

    hiddenFields?.map((x) => {
        visibleColumns[x] = true;
    });

    if (checkManagement) {
        visibleColumns["actions"] = true;
    } else {
        visibleColumns["actions"] = false;
    }

    if (hiddenFields?.length > 0) {
        columns.map((x: any) => {
            if (x.id == "actions" || x.id == "selection" || x.id == "lasted") {
                visibleColumns[x.id ? x.id : ""] = true;
            } else if (x.id == "uniqeID") {
                visibleColumns[x.id ? x.id : ""] = false;
            } else if (x.id?.includes("none")) {
                visibleColumns[x.id ? x.id : ""] = true;
            } else {
                var isHidden = hiddenFields.find((h) => h == x.id);
                if (isHidden) {
                    visibleColumns[x.id ? x.id : ""] = true;
                } else {
                    visibleColumns[x.id ? x.id : ""] = false;
                }
            }
        });
    }

    const editCell = useCallback(
        (id: number, cell: Cell<unknown, unknown>, value: unknown) => {
            setData((prev: baseObj[]) => {
                return prev.map((data: baseObj, index: number) => {
                    if (index === id) {
                        return { ...data, [cell.column.id]: value };
                    }
                    return data;
                });
            });
        },
        [setData]
    );

    const checkIsValidate = (editedData: any) => {
        var msg: any = [];

        if (editedData.projectName === "") {
            msg.push("The projectName field is required!")
        }
        if (editedData.industry === "") {
            msg.push("The companyName field is required!")
        }
        if (editedData.email === "") {
            msg.push("The email field is required!")
        }
        if (editedData.contactPerson === "") {
            msg.push("The contactPerson field is required!")
        }
        if (editedData.financialContactPerson === "") {
            msg.push("The financialContactPerson field is required!")
        }
        if (editedData.region === "") {
            msg.push("The region field is required!")
        }
        if (editedData.projectType === "") {
            msg.push("The projectType field is required!")
        }
        if (editedData.duration === "") {
            msg.push("The duration field is required!")
        }
        if (editedData.projectStatus === "") {
            msg.push("The projectStatus field is required!")
        }
        if (editedData.quotationNumber === "") {
            msg.push("The quotationNumber field is required!")
        }
        if (editedData.currency === "") {
            msg.push("The currency field is required!")
        }
        if (editedData.paymentTerm === "") {
            msg.push("The paymentTerm field is required!")
        }
        if (editedData.projectAmount === "") {
            msg.push("The projectAmount field is required!")
        }
        if (editedData.paymentStatus === "") {
            msg.push("The paymentStatus field is required!")
        }

        return msg;

    }

    const saveProject = useCallback((id: number) => {

        if (isProjectAmount) {
            setErrorPopup(true)
            setMessage(["Your amount less than Payment Amount."])
        } else {
            var editedData = data.find((x) => x.uniqeID === id);
            if (editedData) {
                if (editedData.uniqeID === id) {
                    var message: any = checkIsValidate(editedData);
                    if (message.length > 0) {
                        setErrorPopup(true)
                        setMessage(message);
                        return;
                    } else {
                        // var isNew = true;
                        let testType: any = [];
                        projectTagList?.map(function (item: any) {
                            chosenTags?.map((vtag: any, id: any) => {
                                if (item.name == vtag) {
                                    testType.push(item.id);
                                }
                            })
                        });

                        //for date
                        var date = renderDateFormatYMD(new Date(), "-");
                        if (editedData.date) {
                            date = renderDateFormatYMD(new Date(editedData.date), "-");
                        }

                        var saveData = {
                            // "projectCode": editedData.projectCode,
                            "projectCode": 0,
                            "projectName": DOMPurify.sanitize(editedData.projectName),
                            "companyName": DOMPurify.sanitize(editedData.companyName),
                            "email": editedData.email,
                            "contactPerson": DOMPurify.sanitize(editedData.contactPerson),
                            "financialContactPerson": DOMPurify.sanitize(editedData.financialContactPerson),
                            "date": date,
                            "region": DOMPurify.sanitize(editedData.region),
                            "projectType": DOMPurify.sanitize(editedData.projectType),
                            "duration": DOMPurify.sanitize(editedData.duration),
                            "projectStatus": DOMPurify.sanitize(editedData.projectStatus),
                            "confirmOn": editedData.confirmOn ? renderDateFormatYMD(new Date(editedData.confirmOn), "-") : renderDateFormatYMD(new Date(), "-"),
                            "endDate": editedData.endDate ? renderDateFormatYMD(new Date(editedData.endDate), "-") : renderDateFormatYMD(new Date(), "-"),
                            "remarks": DOMPurify.sanitize(editedData.remarks),
                            "projectScope": DOMPurify.sanitize(editedData.projectScope),
                            "paymentTerm": DOMPurify.sanitize(editedData.paymentTerm),
                            "paymentStatus": DOMPurify.sanitize(editedData.paymentStatus),
                            "currency": DOMPurify.sanitize(editedData.currency),
                            "projectAmount": DOMPurify.sanitize(editedData.projectAmount),
                            "projectCompleted": editedData.projectCompleted,
                            "projectTags": editedData.projectTag?.map((x: any) => parseInt(x.id)),
                            "projectDepartments": [1, 2, 3],
                            "quotationNumber": DOMPurify.sanitize(editedData.quotationNumber),
                            "keyDate": DOMPurify.sanitize(editedData.keyDate),
                            "checkList": DOMPurify.sanitize(editedData.checkList),
                            id: editedData.uniqeID,
                        }

                        if (editedData.uniqeID === 0) {
                            // const sanitizedInput = DOMPurify.sanitize(saveData);
                            createProject(saveData)
                                .unwrap()
                                .then((payload: any) => {
                                    setIsCreated(false)
                                    setIsEdit(false)
                                    setSuccessPopup(true)
                                    setMessage([payload?.message]);
                                    toast('Successfully Created.', {
                                        position: "top-right",
                                        autoClose: 2000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                        type: "success",
                                        theme: "light",
                                    });
                                })
                                .catch((error: any) => {
                                    setErrorPopup(true)
                                    setMessage(error ? [error?.data?.message] : ["Something went wrong!"])
                                })
                        } else {

                            var updatedData = {
                                "projectCode": DOMPurify.sanitize(editedData.projectCode),
                                "projectName": DOMPurify.sanitize(editedData.projectName),
                                "companyName": DOMPurify.sanitize(editedData.companyName),
                                "email": editedData.email,
                                "contactPerson": DOMPurify.sanitize(editedData.contactPerson),
                                "financialContactPerson": DOMPurify.sanitize(editedData.financialContactPerson),
                                "date": date,
                                "region": DOMPurify.sanitize(editedData.region),
                                "projectType": DOMPurify.sanitize(editedData.projectType),
                                "duration": DOMPurify.sanitize(editedData.duration),
                                "projectStatus": DOMPurify.sanitize(editedData.projectStatus),
                                "confirmOn": renderDateFormatYMD(new Date(editedData.confirmOn), "-"),
                                "endDate": renderDateFormatYMD(new Date(editedData.endDate), "-"),
                                "remarks": DOMPurify.sanitize(editedData.remarks),
                                "projectScope": DOMPurify.sanitize(editedData.projectScope),
                                "paymentTerm": DOMPurify.sanitize(editedData.paymentTerm),
                                "paymentStatus": DOMPurify.sanitize(editedData.paymentStatus),
                                "currency": DOMPurify.sanitize(editedData.currency),
                                "projectAmount": DOMPurify.sanitize(editedData.projectAmount),
                                "projectCompleted": (editedData.projectCompleted),
                                "projectTags": editedData.projectTag?.map((x: any) => parseInt(x.id)),
                                "projectDepartments": [1],
                                "id": editedData.uniqeID,
                                "client_id": clientId,
                                "quotationNumber": DOMPurify.sanitize(editedData.quotationNumber),
                            }

                            //not selected for currency
                            if (typeof editedData.currency !== "number") {
                                let Currency = currencyList?.find((x: any) => x.id == editedData?.currency?.uniqeID);
                                updatedData.currency = DOMPurify.sanitize(Currency?.id?.toString() ?? "");
                            }
                            //not selected for region
                            if (typeof editedData.region !== "number") {
                                let Region = regionList?.find((x: any) => x.id == editedData?.region?.id);
                                updatedData.region = DOMPurify.sanitize(Region?.id?.toString() ?? "");
                            }

                            //not selected for projectType
                            if (typeof editedData.projectType !== "number") {
                                let projectType = projectTypesList?.find((x: any) => x.id == editedData?.projectType?.id);
                                updatedData.projectType = DOMPurify.sanitize(projectType?.id?.toString() ?? "");
                            }

                            //not selected for projectStatus
                            if (typeof editedData.projectStatus !== "number") {
                                let projectStatus = projectStatusList?.find((x: any) => x.id == editedData?.projectStatus?.id);
                                updatedData.projectStatus = DOMPurify.sanitize(projectStatus?.id?.toString() ?? "");
                            }

                            //not selected for paymentStatus
                            if (typeof editedData.paymentStatus !== "number") {
                                let paymentStatus = paymentStatusList?.find((x: any) => x.id == editedData?.paymentStatus?.id);
                                updatedData.paymentStatus = DOMPurify.sanitize(paymentStatus?.id?.toString() ?? "");
                            }

                            var existTags: any = [];
                            const tag = tagData.forEach((t) => {
                                existTags.push(t.id)
                            })
                            //for project tags
                            if (testType.length === 0) {
                                // updatedData.projectTags = existTags
                            } else {
                                // updatedData.projectTags = testType
                            }

                            //console.log("updatedData", updatedData)
                            updateProject(updatedData)
                                .unwrap()
                                .then((payload: any) => {
                                    setIsCreated(false)
                                    setIsEdit(false)
                                    setSuccessPopup(true)
                                    setMessage([payload?.message]);
                                    toast('Successfully Updated.', {
                                        position: "top-right",
                                        autoClose: 2000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                        type: "success",
                                        theme: "light",
                                    });
                                    setData((prev: baseObj[]) => {
                                        return prev.map((data, index: number) => {
                                            if (data.uniqeID === updatedData.id) {
                                                return {
                                                    ...data,
                                                    isNew: false,
                                                    isEdit: false
                                                };
                                            }
                                            return data;
                                        });
                                    });

                                    // isNew = false;
                                })
                                .catch((error: any) => {
                                    setErrorPopup(true)
                                    setMessage(error ? [error?.data?.message] : ["Something went wrong!"])
                                    // isNew = true;
                                })
                        }
                        saveProjectData(id);
                    }
                }
            }
        }


    },
        [setData, data]
    );

    const saveProjectData = useCallback(
        (id: number) => {
            setData((prev: baseObj[]) => {
                return prev.map((data, index: number) => {
                    if (data.uniqeID === id) {
                        return {
                            ...data,
                            isNew: true,
                        };
                    }
                    return data;
                });
            });
        },
        [setData]
    );

    const canCeelRow = (id: number) => {
        if (isEditing) {
            setIsEdit(false)
            if (setIsCreated) {
                setIsCreated(false)
            }
            setData((prev: baseObj[]) => {
                return prev.map((data, index: number) => {
                    if (data.uniqeID === id) {
                        return { ...data, isEdit: false, isNew: false };
                    }
                    return data;
                });
            })
        } else {
            const filterData = data?.filter((d: any) => d?.uniqeID !== id)
            setData(filterData)
        }

    }

    useEffect(() => {
        setTimeout(() => {
            setSuccessPopup(false)
        }, 1000)
    }, [successPopup])

    useEffect(() => {
        // if (hiddenFields.length > 0) {
        //     setColumnOrder(["selection", ...hiddenFields]);
        // }
    }, [hiddenFields])


    // console.log("columns", columns)

    return (
        <div
            className={classNames(
                styles.employeeTable,
                "relative inline-block max-w-full w-full"
            )}
        >
            <ToastContainer className={"text-12 font-poppins font-normal"} />

            <ErrorPopup
                descText="Error Message!"
                confirmBtnText="Yes, Close."
                show={errorPopup}
                setShow={setErrorPopup}
                message={message}
                setMessage={setMessage}
                setIsProjectAmount={setIsProjectAmount}
            />

            <DeletePopup
                descText="Are you sure to delete?"
                confirmBtnText="Yes, delete."
                show={openDeletePopup}
                setShow={setOpenDeleteopup}
                confirmDeleteData={confirmDeleteData}
                setIsProj={setIsProj}
            />

            <Modals
                addKeyDateData={addKeyDateData}
                addCheckListData={addCheckListData}
                addQuotationData={addQuotationData}
                removeQuotation={removeQuotation}
                removeKeyDates={removeKeyDates}
                state={modalState}
                setState={setModalState}
                rowId={modalRow}
                getPJScope={getPJScope}
                checkListData={checkListData}
                data={data}
                setData={setData}
                filterStates={filterStates}
                setFilterStates={setFilterStates}
                updateFilterState={updateFilterState}
                keyDateDataList={keyDateDataList}
                checkManagement={checkManagement}
            />
            <div className={classNames(styles1.projectTable, "relative  max-w-full")}>
                <LayoutAdjustableTable
                    columns={columns}
                    data={data}
                    noPaddingCells={[
                        "region",
                        "projectType",
                        "projectTag",
                        "paymentStatus",
                        "projectStatus",
                        "date",
                        "paymentTerm",
                        "currency",
                    ]}
                    sorting={sorting}
                    setSortingState={setSorting}
                    rowSelection={rowSelection}
                    setRowSelection={setRowSelection}
                    columnOrder={columnOrder}
                    setColumnOrder={setColumnOrder}
                    unDraggableFields={["actions", "selection", "id"]}
                    theaderColor="text-graydark"
                    isLayoutControlOpen={isLayoutControlOpen}
                    setIsLayoutControlOpen={setIsLayoutControlOpen}
                    label="User List"
                    setItemCount={setItemCount}
                    pagination={pagination}
                    setPagination={setPagination}
                    globalFilter={globalSearch}
                    setGlobalFilter={setGlobalSearch}
                    columnFilters={columnFilters}
                    setColumnFilters={setColumnFilters}
                    localModuleId={localModuleId}
                    hideColumn={visibleColumns}
                    setCols={setCols}
                    setIsTemplate={setIsTemplate}

                    setSuccessPopup={setSuccessPopup}
                    setMessage={setMessage}
                    setErrorPopup={setErrorPopup}
                    setGetPlaceHolder={setGetPlaceHolder}
                />
            </div>

            {selectRowsCount > 0 && (
                <div className="flex items-center text-14 leading-5 font-semibold text-black2 mt-3 mb-4 lg:mt-5 lg:mb-5 ">
                    <p>{selectRowsCount} selected.</p>
                    {
                        checkManagement ? (
                            <button
                                type="button"
                                onClick={() => deleteProjects()}
                                className=" flex items-center ml-5 text-12 leading-[18px] font-semibold text-redPink"
                            >
                                <div className="w-6 h-6 bg-redPink flex items-center group justify-center rounded-md mr-[10px] hover:bg-redPinkLight  transition-all duration-300 ">
                                    <TrashIcon width={10} height={12} />
                                </div>
                                Delete
                            </button>
                        ) : null
                    }
                </div>
            )}


            <div className="pt-2">
                <TablePaginator
                    total={itemCount}
                    pagination={pagination}
                    setPagination={setPagination}
                    itemCount={total}
                    changePage={changePage}
                    setCurrentPage={setCurrentPage}
                    currentPageDefault={currentPage}
                />
            </div>

            <Modal
                open={isShow}
                style={{
                    background: "rgb(0 0 0 / 0.5)",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
                onClose={() => setIsShow(false)}
                aria-labelledby="Password Add Modal"
                aria-describedby="Add New Password Form Pop up"
            >
                <div className=" max-w-[558px] w-full m-auto">
                    <AssignPopup
                        createTagData={createTagData}
                        editRowData={editedTagData}
                        showTagPopup={showTagPopup}
                        setIsOpen={setIsShow}
                        modalType="assign"
                        options={tags}
                        cell={cellData}
                        setTags={() => 1}
                        data={data}
                        setData={setData} />
                </div>
            </Modal>
        </div>
    );
};

export default CrmTable;
