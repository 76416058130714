import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Permission } from '../app/services/permissions'
// interface data{
//     id:number,
//     roleID:number,
//     value:boolean
// }
export const permissionSlice = createSlice({
  name: 'permission',
  initialState: [],
  reducers: {
    // changeStatus: (state,action) => {
    //   console.log("state ",state)
    //   //return [...state,action.payload]
    // },
    changeStatus: (state, action: PayloadAction<[]>) => {
      
      return { ...state, permissions: action.payload };
    },
  },
})
export const permissionSliceData = (state:Permission) => state;
export const { changeStatus } = permissionSlice.actions
export default permissionSlice.reducer