import {
    Dispatch,
    FC,
    SetStateAction,
    // useCallback,
    useEffect,
    // useMemo,
    useRef,
    useState,
  } from "react";
  import {
    // AddIcon,
    // ChevronDown,
    // DeleteIcon,
    DropdownICon,
    SearchIcon,
    // TrashIcon,
  } from "../common/Icons";
  // import { Listbox } from "@headlessui/react";
  // import { usePopper } from "../../hooks/usePopper";
  import DebounceSearchInput from "../Tables/DebounceSearchInput";
  // import { EmployeeData } from "../EmployeeTable/EmployeeTable";
  import classNames from "classnames";
  // import CreateNewItemPopup from "../CreateNewItemPopup/CreateNewItemPopup";
  // import { baseObj } from "../../utils/constants";
  // import { usePopperCustom } from "../../hooks/usePopperCustom";
  import styles from "./EmployeeTable.module.scss";
  // import {
  //   Box,
  //   ClickAwayListener,
  //   Fade,
  //   makeStyles,
  //   Popper,
  //   PopperPlacementType,
  //   Tooltip,
  // } from "@material-ui/core";
  
  // import { makeStyles } from "@mui/styles";
  import { Box, Fade, Popper, ClickAwayListener } from "@mui/material";
  // import ClickAwayListener from "@mui/base/ClickAwayListener";
  // import { Cell, RowSelectionState } from "@tanstack/react-table";
  // import DeletePopup from "../DeletePopup/DeletePopup";
  // import { uniqueId } from "lodash";
  // import { P } from "@fullcalendar/core/internal-common";
  import CustomScrollbar from "../CustomScrollbar/CustomScrollbar";
  interface ICustomDropdownList {
    state: any;
    setState: Dispatch<SetStateAction<any>> | (() => void);
    placeholder?: string;
    dropdownItems?: IListItem[];
    // dropdownItems?: EmployeeData[];
    className?: string;
    textAlign?: string;
    dropdownborder?: string;
    isFilter?: boolean;
    classNameCus?: string;
    isAction?: boolean;
    customClassName?: string;
    isObjectGet?: boolean;
  }
  // const useStyles = makeStyles(() => ({
  //   tooltip: {
  //     backgroundColor: "transparent",
  //     margin: 0,
  //     marginRight: 8,
  //     padding: 0,
  //   },
  // }));
  
  interface IListItem {
    label: string;
    value: string | number | undefined;
  }
  const CustomDropdownListWithSearch: FC<ICustomDropdownList> = ({
    state,
    setState,
    dropdownItems,
    placeholder = "All",
    // className = "",
    classNameCus,
    // textAlign = "text-center",
    // dropdownborder = "",
    // isFilter,
    isAction,
    customClassName,
    isObjectGet,
    // isLeave,
  }) => {
    // const [placement, setPlacement] = useState<PopperPlacementType>("bottom-start");
    const [placement, setPlacement] = useState<any>("bottom-start");
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [open, setOpen] = useState(false);
    const [label, setLabel] = useState<string>(
      dropdownItems?.filter((item) => item.value?.toString() == state)[0]
        ?.label || placeholder
    );
  
    // const uuid = uniqueId("custom-dropdown-list");
    const [search, SetSearch] = useState<string>("");
    const [showCreatePopup, setShowCreatePopup] = useState<boolean>(false);
    const [show, setShow] = useState<boolean>(false);
    const [isCreated, setIsCreated] = useState<boolean>(false);
    const mainContainer = useRef<HTMLDivElement | null>(null);
  
    const [width, setWidth] = useState<number>();
  
    const [filteredList, setFilteredList] = useState<any>([]);
  
    useEffect(() => {
      const keyword = search?.trim().toLowerCase();
      if (keyword.length > 0) {
        const matchedItems = dropdownItems?.filter((item) =>
          item.label?.toLowerCase().includes(keyword)
        );
        setFilteredList(matchedItems);
      } else {
        setFilteredList(dropdownItems);
      }
    }, [search, setFilteredList, dropdownItems]);
  
    useEffect(() => {
      setWidth(mainContainer.current?.clientWidth as number);
      const resizer: any = window.addEventListener("resize", () =>
        setWidth(mainContainer.current?.clientWidth as number)
      );
      return () => window.removeEventListener("resize", resizer);
    }, []);
  
    const handleClick = (
      newPlacement: any,
      // newPlacement: PopperPlacementType,
      event: React.MouseEvent<HTMLButtonElement>
    ) => {
      setAnchorEl(event.currentTarget);
      setPlacement(newPlacement);
    };
  
    const handleClickAway = () => {
      if (showCreatePopup == true || isCreated == true) {
        setOpen(true);
        setShow(true);
        setIsCreated(false);
      } else {
        setOpen(false);
        setShow(false);
        setIsCreated(false);
      }
    };
  
    return (
      <div ref={mainContainer} className="h-full w-full">
        <ClickAwayListener onClickAway={handleClickAway}>
          <Box
            sx={{ position: "relative" }}
            className="h-full w-full transition-all duration-500"
          >
            <button
              onClick={(event) => {
                handleClick("bottom-start", event);
                setOpen(!open);
                setShow(!show);
              }}
              className={classNames(
                open ? "bg-[#EFEFEF]" : "bg-white",
                customClassName,
                "rounded-[5px] flex w-full items-center justify-between h-full transition-all duration-300 min-h-[50px] py-[2px] px-[10px] border border-vorpgraylight custom-dropdown-container"
              )}
            >
              <p className="whitespace-nowrap xl:text-sm text-12 text-graydark font-normal mr-1">
                {isAction
                  ? state
                  : label
                  ? placeholder != label && placeholder != "All"
                    ? label
                    : placeholder
                  : "All"}
              </p>
              <DropdownICon />
            </button>
            {show ? (
              <Popper
                open={open}
                anchorEl={anchorEl}
                placement={placement}
                transition
              >
                {({ TransitionProps }) => (
                  <Fade {...TransitionProps} timeout={500}>
                    <div>
                      <div
                       style={{ width }}
                        className={classNames(
                          styles.customList_dropdown,
                          "rounded-4 overflow-hidden w-full max-w-full z-10 bg-white",
                          classNameCus
                        )}
                      >
                        <div className="bg-offwhite py-2 px-3 w-full">
                          <div className="relative">
                            <DebounceSearchInput
                              setState={SetSearch}
                              debounce={400}
                            >
                              {(setState) => (
                                <input
                                  type="text"
                                  placeholder="Search"
                                  className="py-1 pl-3 pr-2 placeholder:text-black2 text-black2 border border-vorpgraylight rounded-4 focus:outline-none w-full xl:text-sm text-12 leading-5"
                                  onChange={(e) => setState(e.target.value)}
                                  onKeyDown={(e) => e.stopPropagation()}
                                />
                              )}
                            </DebounceSearchInput>
                            <SearchIcon className="absolute top-0 bottom-0 my-auto right-2" />
                          </div>
                        </div>
                        <div className="flex flex-col overflow-y-auto w-full h-[218px] max-h-[218px] bg-white">
                          <CustomScrollbar
                            color="#CACACA"
                            isVertical={true}
                            isHor={false}
                          >
                            <div className="">
                              {filteredList?.map((item: any, index: number) => (
                                <div
                                  key={Math.random() * 1000 * index}
                                  className={classNames(
                                    item.id == 0 ? "hidden" : "flex",
                                    " flex-row items-center justify-between hover:bg-[#EFEFEF] text-graydark"
                                  )}
                                >
                                  <button
                                    type="button"
                                    onClick={() => {
                                      if (isObjectGet) {
                                        setState(item);
                                        setLabel(item?.label);
                                        setOpen(false);
                                        setShow(false);
                                      } else {
                                        if (isAction) {
                                          setState(item?.label);
                                          setOpen(false);
                                          setShow(false);
                                        } else {
                                          setState(item?.value as any);
                                          setLabel(item?.label);
                                          setOpen(false);
                                          setShow(false);
                                        }
                                      }
                                    }}
                                    className={classNames(
                                      label == item.label ? "bg-[#EFEFEF]" : "",
                                      "whitespace-normal break-words w-full capitalize focus:outline-none flex items-center justify-between group px-3 py-1  transition-all duration-300 text-left xl:text-sm text-12 leading-5"
                                    )}
                                  >
                                    {item.label}
                                  </button>
                                </div>
                              ))}
                            </div>
                          </CustomScrollbar>
                        </div>
                      </div>
                    </div>
                  </Fade>
                )}
              </Popper>
            ) : null}
          </Box>
        </ClickAwayListener>
      </div>
    );
  };
  
  export default CustomDropdownListWithSearch;
  