// import { Popper } from "@material-ui/core";
// import { Popper } from "@mui/material"
// hello

import {
  Cell,
  ColumnDef,
  ColumnFiltersState,
  createColumnHelper,
  PaginationState,
  // RowData,
  RowSelectionState,
  SortingState,
} from "@tanstack/react-table";
import classNames from "classnames";
import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  TemplateData,
  useCreateCountryMutation,
  useCreateCurrencyMutation,
  useCreateDepartmentMutation,
  useCreateDesignationMutation,
  useCreateEmployeeMutation,
  useEnableEmployeeMutation,
  useGetCountryListQuery,
  useGetCurrencyListQuery,
  useGetDepartmentListQuery,
  useGetDesignationListQuery,
  useRemoveCountryMutation,
  useRemoveCurrencyMutation,
  useRemoveDepartmentMutation,
  useRemoveDesignationMutation,
  useSendEmailMutation,
  useUpdateEmployeeMutation,
} from "../../app/services/employee";
import { baseObj } from "../../utils/constants";
import {
  // AddIcon,
  CancelIcon,
  // ChevronDown,
  EditIcon,
  ShareIcon,
  TableSaveIcon,
  TrashIcon,
  ViewIcon,
} from "../common/Icons";
// import CustomSingleDropdown from "../CustomSingleDropdown/CustomSingleDropdown";
import {
  // renderDateFormat,
  renderDateFormatDMY,
  // renderDateFormatMDY,
} from "../DashboardCalendarView/DashboardAddEventPopup";
import DeletePopup from "../DeletePopup/DeletePopup";
// import EnableSwitch from "../Projects/ProjectCompleteSwitch";
import ReportToPopup from "../ReportToPopup/ReportToPopup";
import SharePopup from "../SharePopup/SharePopup";
import EditableCell from "../Tables/EditableCell";
import LayoutAdjustableTable from "../Tables/LayoutAdjustableTable";
import TablePaginator from "../Tables/TablePaginator";
import CustomDropdownWithAddButton from "./CustomDropdownWithAddButton";
import DatePopup from "./DatePopup";
// import DepartmentDropdownList from "./DepartmentDropdownList";
import styles from "./EmployeeTable.module.scss";
// import { getAuthUser } from "../../app/services/dashboard";
import { templateData } from "../../app/services/template";
// import SuccessPopup from "../SuccessPopup/SuccessPopup";
import ErrorPopup from "../ErrorPopup/ErrorPopup";
// import { NavLink } from "react-router-dom";
// import { deleteCurrency } from "../../app/services/project";
import CommonErrorPopup from "../common/CommonErrorPopup";
import DOMPurify from "dompurify";
import Table from "../Tables/Tables.module.scss";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { EnableSwitch } from "../BackendUsers/BackendUsersTable";
import ToggleSwitch from "../common/ToggleSwitch";

export interface StoreEmployeeType {
  employeeID: string;
  id: number;
  name: string;
  preferredName: string;
  email: string;
  mobile: string;
  joinedDate: string;
  department_id: number;
  designation_id: number;
  currency_id: number;
  position: string;
  // reportTo: number;
  report_to: number;
  currency: string;
  salary: number;
  dob: string;
  gender: string;
  race: string;
  religion: string;
  country_id: number;
  nationalityID: string;
  passportNo: string;
  device: string;
  bank: string;
  bankAccNo: string;
  status: string;
}

export type RegionResponse = {
  region: EmployeeData[];
};

export interface EmployeeData {
  id: number;
  name: string;
  label: string;
  isChecked?: boolean;
  isCheck?: boolean;
  project_id?: number;
  isDone?: boolean;
  amount?: number;
  uniqeID?: number;
  //for keydate
  key_id?: number;
  date?: string | null;
  flag?: string;
  rowId?: number;
  region_id?: number;
  region_name?: string;
  // region?: any,
}

export interface GenderData {
  id: number;
  name: string;
  label: string;
}

export interface MembereData {
  id: number;
  name: string;
  label: string;

  preferred_name?: string;
  year_w_vo?: number;
  age?: number;
}

export type TPayment = {
  id?: number;
  project_status_id?: number | string;
  amount?: number;
  payment_due_date?: string | null;
  payment_received_date?: string | null;
  invoice_no?: string;
  payment_received_no?: string;
  is_completed: number;
};

export type TCheck = {
  label: string;
  is_checked: number;
};

export type TKeyDate = {
  key_id: number;
  key_date: string;
};

export type TSaveProject = {
  id?: number | string;
  project_name: string | number;
  company_name: string | number;
  primary_contact_person_name: string | number;
  secondary_contact_person_email: string | number;
  primary_contact_person_email: string | number;
  secondary_contact_person_name: string | number;

  primary_contact_person_phone: string;
  primary_contact_person_dial_code: string;
  secondary_contact_person_dial_code: string;
  secondary_contact_person_phone: string;
  finance_contact_person_name: string | number;
  finance_contact_person_dial_code: string;
  finance_contact_person_phone: string;
  team_leader_id: number | string;
  project_manager_id: number | string;
  region_id: number | string;
  department_id: number | string;
  duration_type: string;
  duration: number | string;
  scope: string | number;
  quotation_no: string | number;
  brand_level: number | string;
  project_level: number | string;
  project_type_ids: number[];
  status_id: number | string;
  currency_id: number | string;
  total_amount: number | string;
  payment_term: number | string;
  payment_status_id: number | string;
  payments: TPayment[];
  confirm_date: string | null | undefined;
  start_date: string | null | undefined;
  end_date: string | null | undefined;
  is_completed: number | string;
  remark: string | number;
  check_lists: TCheck[];
  key_dates: TKeyDate[];
};

export interface EmployeeListData {
  name?: string;
  uniqeID: number;
  id: number;
  employeeID: string;
  realName: string;
  preferredName: string;
  email: string;
  mobile: string;
  joinedDate: string;
  years: string;
  department: string;
  designation: string;
  report_to: string | number;
  // reportTo: string;
  salary: string;
  dob: string;
  age: string;
  gender: string;
  race: string;
  religion: string;
  nationality: string;
  nationalityID: string;
  passportNo: string;
  device: string;
  bank: string;
  bankAccNo: string;
  status: string;
}

export interface EmployeeListDataCap {
  EmployeeID: string;
  RealName: string;
  PreferredName: string;
  Email: string;
  Mobile: string;
  JoinedDate: string;
  Years: string;
  Department: string;
  Designation: string;
  Report_to: string | number;
  // ReportTo: string;
  Salary: string;
  DateOfBirth: string;
  Age: string;
  Gender: string;
  Race: string;
  Religion: string;
  Nationality: string;
  NationalityID: string;
  PassportNo: string;
  Device: string;
  Bank: string;
  BankAccNo: string;
  Status: string;
}

interface IEmployeeTable {
  isLayoutControlOpen: boolean;
  setIsLayoutControlOpen: Dispatch<SetStateAction<boolean>>;
  pagination: PaginationState;
  setPagination: Dispatch<SetStateAction<PaginationState>>;
  globalSearch: string;
  setGlobalSearch: Dispatch<SetStateAction<string>>;
  columnFilters: ColumnFiltersState;
  setColumnFilters: Dispatch<SetStateAction<ColumnFiltersState>>;
  orgDataList: EmployeeListData[];
  data: baseObj[];
  setData: Dispatch<SetStateAction<baseObj[]>>;
  deleteEmployee: any;
  deleteEmployeeByIDs: any;
  hiddenFields: string[];
  // templateList: TemplateData[];
  setHiddenFields: any;
  setCurrentView: any;
  genderData: EmployeeData[];
  setGenderData: Dispatch<SetStateAction<EmployeeData[]>>;
  statusData: EmployeeData[];
  setStatusData: Dispatch<SetStateAction<EmployeeData[]>>;
  memberData: EmployeeData[];
  setMemberData: Dispatch<SetStateAction<EmployeeData[]>>;
  localModuleId: number;
  templateList: templateData[];
  total: number;
  changePage?: any;
  setCurrentPage: any;
  currentPage: number;
  setIsEdit: Dispatch<SetStateAction<boolean>>;
  isEditing?: boolean;
  setIsTemplate?: Dispatch<SetStateAction<boolean>>;
  setCols?: any;
  checkManage: boolean;
  setLeaveID: React.Dispatch<React.SetStateAction<number>>;
  setShowLeaveAllowance: Dispatch<SetStateAction<boolean>>;
  allowanceSuccess: boolean;
  addNew: boolean;
  setEmployeeID: Dispatch<SetStateAction<string>>;
  getAllowance: (id: number) => void;
  setLeaveAllowanceList: any;
  sorting: SortingState;
  setSorting: React.Dispatch<React.SetStateAction<SortingState>>;
}

interface IEnableSwitch {
  initialState: boolean;
  id: string;
  setData: Dispatch<SetStateAction<baseObj[]>>;
  cell: Cell<any, any>;
  changeUserStatusData: (id: string, value?: any) => void;
  loading: any;
}

export const EmployeeEnableSwitch: FC<IEnableSwitch> = ({
  initialState,
  id,
  setData,
  cell,
  changeUserStatusData,
  loading,
}) => {
  const [isEnable, setIsEnable] = useState<boolean>(initialState);
  const editCell = useCallback(
    (value: boolean) => {
      setData((prev: baseObj[]) => {
        return prev.map((data: baseObj, index: number) => {
          if (index === Number(cell.row.id)) {
            changeUserStatusData(data.uniqeID, value);
            return { ...data, [cell.column.id]: value };
          }
          return data;
        });
      });
    },
    [setData, cell, changeUserStatusData]
  );

  return (
    <div className="max-w-[120px] flex">
      <ToggleSwitch
        id={id}
        state={isEnable}
        setState={setIsEnable}
        updateData={editCell}
      />
      {/* <div
        id={id?.toString() + cell.row.original.uniqeID?.toString()}
        className={classNames(styles.loader, loading ? "" : "hidden")}
      ></div> */}
      {/* {loading ? (
        // <div
        //   id={id?.toString() + (cell.row.original.uniqeID)?.toString()}
        //   className={classNames(styles.loader, "")}
        // ></div>
      ) : (
        // <ToggleSwitch
        //   id={id}
        //   state={isEnable}
        //   setState={setIsEnable}
        //   updateData={editCell}
        // />
        // <div
        //   id={id?.toString() + (cell.row.original.uniqeID)?.toString()}
        //   className={classNames(styles.loader, "")}
        // ></div>
      )} */}
    </div>
  );
};

// interface DropdownFieldData {
//   key: string;
//   value: string;
// }
const EmployeeTable: FC<IEmployeeTable> = ({
  isLayoutControlOpen,
  setIsLayoutControlOpen,
  pagination,
  setPagination,
  globalSearch,
  setGlobalSearch,
  columnFilters,
  setColumnFilters,
  data,
  setData,
  // orgDataList,
  deleteEmployee,
  deleteEmployeeByIDs,
  hiddenFields,
  templateList,
  setHiddenFields,
  setCurrentView,
  genderData,
  setGenderData,
  statusData,
  setStatusData,
  memberData,
  // setMemberData,
  localModuleId,
  total,
  // changePage,
  setCurrentPage,
  currentPage,
  setIsEdit,
  isEditing,
  setIsTemplate,
  setCols,
  checkManage,
  setLeaveID,
  setShowLeaveAllowance,
  allowanceSuccess,
  addNew,
  setEmployeeID,
  getAllowance,
  setLeaveAllowanceList,
  sorting,
  setSorting,
}) => {
  const columnHelper = useMemo(() => createColumnHelper<any>(), []);
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  // const [itemCount, setItemCount] = useState<number>(data.length);
  const [itemCount, setItemCount] = useState<number>(total);
  const selectRowsCount = Object.keys(rowSelection).length;
  const [department, setDepartment] = useState("All");
  const [designate, setDesignation] = useState("All");
  const [currency, setCurrency] = useState("All");
  const [national, setNationality] = useState("All");
  const [status, setStatus] = useState("All");
  const [gender, setGender] = useState("All");
  const [successPopup, setSuccessPopup] = useState<boolean>(false);
  const [errorPopup, setErrorPopup] = useState<boolean>(false);
  const [message, setMessage] = useState<any>([]);
  const [msg, setMsg] = useState<string>("");
  // const [message, setMessage] = useState<string>("");

  const [show, setShow] = useState<boolean>(false);
  const [selectedMember, setSelectedMember] = useState<string>("");
  const [openSharePopup, setOpenSharePopup] = useState<boolean>(false);
  const [isSending, setIsSending] = useState<boolean>(false);
  const [shareID, setShareID] = useState<number>(0);
  const [openDeletePopup, setOpenDeleteopup] = useState<boolean>(false);
  const [deleteID, setDeleteID] = useState<number | 0>();

  const [deleteCurrencyID, setDeleteCurrencyID] = useState<number | 0>();
  const [deleteDeptID, setDeleteDeptID] = useState<number | 0>();
  const [deleteDesiID, setDeleteDesiID] = useState<number | 0>();
  const [deleteNatiID, setDeleteNatiID] = useState<number | 0>();
  const [deleteStatID, setDeleteStatID] = useState<number | 0>();

  const [deleteRowID, setDeleteRowID] = useState<number | 0>();
  // const [dropdownData, setDropDownData] = useState<DropdownFieldData[]>();
  // const [isUpdated, setIsUpdated] = useState<boolean>(false);
  // const [visibleFields, setVisibleField] = useState<any>();
  const [updateEmployee] = useUpdateEmployeeMutation();
  const [createEmployee] = useCreateEmployeeMutation();
  const [createCurrency] = useCreateCurrencyMutation();
  const [createCountry] = useCreateCountryMutation();
  const [sendEmail] = useSendEmailMutation();
  const { data: empDepartmentData } = useGetDepartmentListQuery();
  const { data: empDesignData } = useGetDesignationListQuery();
  const { data: empCurrencyData } = useGetCurrencyListQuery();
  const { data: empCountryData } = useGetCountryListQuery();
  const [createDepartment] = useCreateDepartmentMutation();
  const [createDesignation] = useCreateDesignationMutation();
  const [departmentData, setDepartmentData] = useState<EmployeeData[]>([]);
  const [currencyData, setCurrencytData] = useState<EmployeeData[]>([]);
  const [designationData, setDesignationData] = useState<EmployeeData[]>([]);
  //const [memberData, setMemberData] = useState<EmployeeData[]>([]);
  //const [genderData, setGenderData] = useState<EmployeeData[]>([]);
  const [nationalityData, setNationalityData] = useState<GenderData[]>([]);
  const [deleteCurrency] = useRemoveCurrencyMutation();
  const [deleteDepartment] = useRemoveDepartmentMutation();
  const [deleteCountry] = useRemoveCountryMutation();
  const [deleteDesignation] = useRemoveDesignationMutation();
  const [enableEmployee, { isLoading: enableLoading }] =
    useEnableEmployeeMutation();
  const [lastDateData, setDate] = useState<Date | undefined>();

  const changeUserStatusData = useCallback((id: string) => {
    enableEmployee({ id: parseInt(id) });
  }, []);

  useEffect(() => {
    setDepartmentData(empDepartmentData ? empDepartmentData : []);
    setDesignationData(empDesignData ? empDesignData : []);
    setNationalityData(empCountryData ? empCountryData : []);

    if (empCurrencyData) {
      var transformCurrencyData: EmployeeData[] = empCurrencyData.map((x) => {
        return {
          id: x.id,
          name: x.currency_name,
          label: x.currency_code,
        };
      });
      setCurrencytData(transformCurrencyData ? transformCurrencyData : []);
    }
  }, [empDepartmentData, empDesignData, empCountryData, empCurrencyData]);

  const encodeAlphabet = (alphabet: string) => {
    if (alphabet) {
      return alphabet.toString().replace(/[a-zA-Z0-9]./g, "*");
    } else {
      return "";
    }
  };

  const columns = useMemo(
    () => [
      columnHelper.display({
        header: "",
        id: `${checkManage ? "selection" : "none-selection"}`,
      }),
      columnHelper.accessor("employeeID", {
        header: "Employee ID",
        id: "employeeID",
        cell: ({ getValue, cell }) => {
          const employeeID = getValue();
          return (
            <div className="  text-left bg-white text-black2 px-[10px]">
              {/* max-w-[160px] min-w-[140px]*/}
              <p className="h-full flex items-center">{employeeID}</p>
            </div>
          );
        },
      }),
      columnHelper.accessor("name", {
        header: "Real Name",
        id: "name",
        cell: ({ getValue, cell }) => {
          const name = getValue();
          return (
            <div className=" text-left">
              {/* max-w-[160px]  min-w-[140px]*/}
              <EditableCell
                setData={setData}
                value={name}
                type="text"
                cell={cell}
                customDesign="tooltip"
              />
            </div>
          );
        },
      }),
      columnHelper.accessor("preferredName", {
        header: "Preferred Name",
        id: "preferredName",
        cell: ({ getValue, cell }) => {
          const name = getValue();
          return (
            <div className=" text-left">
              {/* max-w-[160px] min-w-[140px] */}
              <EditableCell
                setData={setData}
                value={name}
                type="text"
                cell={cell}
                customDesign="tooltip"
              />
            </div>
          );
        },
      }),
      columnHelper.accessor("email", {
        header: "Email",
        id: "email",
        cell: ({ getValue, cell }) => {
          const email = getValue();
          return (
            <div className="min-w-[250px]  text-left">
              {/* max-w-[230px] */}
              <EditableCell
                maxLength={30}
                setData={setData}
                value={email}
                type="email"
                cell={cell}
                customDesign="tooltip"
              />
            </div>
          );
        },
      }),
      columnHelper.accessor("workEmail", {
        header: "Work Email",
        id: "workEmail",
        cell: ({ getValue, cell }) => {
          const workEmail = getValue();
          return (
            <div className="min-w-[250px]  text-left">
              {/* max-w-[230px] */}
              <EditableCell
                maxLength={30}
                setData={setData}
                value={workEmail}
                type="email"
                cell={cell}
                customDesign="tooltip"
              />
            </div>
          );
        },
      }),
      columnHelper.accessor("mobile", {
        header: "Mobile",
        id: "mobile",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const mobile = getValue();
          return (
            <div className="  text-left">
              {/* max-w-[260px] min-w-[280px]*/}
              <EditableCell
                setData={setData}
                value={mobile}
                type="text"
                cell={cell}
              />
            </div>
          );
        },
      }),
      columnHelper.accessor("joinedDate", {
        header: "Joined Date",
        id: "joinedDate",
        cell: ({ getValue, cell }) => {
          const joinedDate = getValue();
          var joinDate = new Date();
          if (joinedDate) {
            joinDate = new Date(joinedDate);
          }
          var formatDate = renderDateFormatDMY(joinDate, "/");
          return (
            <div className=" pr-1 flex justify-between items-center text-left">
              {/* max-w-[200px]  min-w-[180px]*/}
              <EditableCell
                setData={setData}
                value={cell.row.original?.isNew ? formatDate : formatDate}
                type="custom"
                cell={cell}
                CustomComponent={
                  <DatePopup
                    dobDate={joinDate}
                    setDate={() => 1}
                    cell={cell}
                    data={data}
                    setData={setData}
                  />
                }
              />
            </div>
          );
        },
      }),
      columnHelper.accessor("lastDate", {
        header: "Last Date",
        id: "lastDate",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const joinedDate = getValue();
          var isHaveNew: any = data?.find((x) => x.uniqeID === 0);

          var joinDate = new Date();
          if (joinedDate) {
            joinDate = new Date(joinedDate);
          }
          if (cell.row.original.uniqeID == 157) {
            // console.log("joinedDate ",joinedDate)
          }
          var formatDate = renderDateFormatDMY(joinDate, "/");
          return (
            <div className="  pr-1 flex justify-between items-center text-left">
              {/* max-w-[200px] min-w-[180px]*/}
              <EditableCell
                setData={setData}
                value={
                  cell.row.original?.isNew
                    ? joinedDate !== null && joinedDate !== "---"
                      ? formatDate
                      : "---"
                    : joinedDate === null
                      ? "---"
                      : formatDate
                }
                type="custom"
                cell={cell}
                CustomComponent={
                  <DatePopup
                    isClearDate={true}
                    dobDate={joinedDate ? joinDate : null}
                    isNew={isHaveNew}
                    setDate={setDate}
                    cell={cell}
                    data={data}
                    setData={setData}
                  />
                }
              />
            </div>
          );
        },
      }),
      columnHelper.accessor("years", {
        header: "Years with VO",
        id: "years",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const year = getValue();

          return (
            <div className="  text-left">
              {/* max-w-[260px] min-w-[240px]*/}
              <EditableCell
                setData={setData}
                value={year}
                IsDisabled={true}
                type="text"
                cell={cell}
                isYear={true}
              />
            </div>
          );
        },
      }),
      columnHelper.accessor("department", {
        header: "Department",
        id: "department",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const dptment = getValue();
          var departView = departmentD;
          var departmentD = departmentData?.find((x) => x.id == dptment);
          if (departmentD == undefined) {
            departView = departmentData?.find((x) => x.name == dptment);
          } else {
            departView = departmentD;
          }
          const originalValues = cell.row.original?.role?.map(
            (role: baseObj) => role.id
          );
          const isNew = cell.row.original?.isNew;
          return (
            <div
              className={classNames(
                "  text-left h-full",
                // max-w-full min-w-[180px]
                styles.customEmployeeDropdown
              )}
            >
              <EditableCell
                className="py-0"
                hasPadding={true}
                setData={setData}
                value={departView ? departView.name : "Select"}
                type="custom"
                cell={cell}
                customDesign="tooltip"
                CustomComponent={
                  <CustomDropdownWithAddButton
                    isShowAdd={false}
                    addNewItemData={addDepartmentData}
                    initialValues={originalValues}
                    dataList={departmentData ? departmentData : []}
                    setDataList={setDepartmentData}
                    title="Department"
                    cell={cell}
                    data={data}
                    setData={setData}
                    setDeleteID={setDeleteDeptID}
                    setOpenDeleteopup={setOpenDeleteopup}
                    setSelectedData={setDepartment}
                    selectedData={departView ? departView.name : "Select"}
                    addTitle="Create New Department"
                    isEditable={isNew}
                  />
                }
              />
            </div>
          );
        },
      }),
      columnHelper.accessor("designation", {
        header: "Designation",
        id: "designation",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const designation = getValue();
          const designateD = designationData.find((x) => x.id == designation);
          var designView = designateD;
          if (designView == undefined) {
            designView = designationData.find((x) => x.name == designation);
          }
          const originalValues = cell.row.original?.role?.map(
            (role: baseObj) => role.id
          );
          const isNew: boolean = cell.row.original?.isNew;
          return (
            <div
              className={classNames(
                " text-left h-full",
                // max-w-full min-w-[180px]
                styles.customEmployeeDropdown
              )}
            >
              <EditableCell
                className="py-0"
                hasPadding={true}
                setData={setData}
                value={designView ? designView.name : "Select"}
                type="custom"
                cell={cell}
                customDesign="tooltip"
                CustomComponent={
                  <CustomDropdownWithAddButton
                  isShowAdd={false}
                    addNewItemData={addDesignationData}
                    customHeight="min-h-[220px]"
                    initialValues={originalValues}
                    dataList={designationData}
                    setDataList={setDesignationData}
                    title="Designation"
                    cell={cell}
                    data={data}
                    setData={setData}
                    setSelectedData={setDesignation}
                    selectedData={designView ? designView.name : "Select"}
                    addTitle="Create New Designation"
                    setDeleteID={setDeleteDesiID}
                    setOpenDeleteopup={setOpenDeleteopup}
                    isEditable={isNew}
                  />
                }
              />
            </div>
          );
        },
      }),
      columnHelper.accessor("reportTo", {
        header: "Report To",
        id: "reportTo",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const reportTo = getValue();
          // var reportToView = reportTo;
          // var reportToID = reportTo;
          // if (typeof reportTo == "object") {
          //   reportToView = reportTo?.name;
          //   reportToID = reportTo?.id;
          // }
          // const originalValues = cell.row.original?.role?.map(
          //   (role: baseObj) => role.id
          // );
          // const isNew: boolean = cell.row.original?.isNew;
          return (
            <div
              className={classNames(
                " min-w-[200px] text-left h-full",
                // max-w-[200px]
                styles.customEmployeeDropdown
              )}
            >
              <EditableCell
                className="py-0"
                customDesign="circle"
                hasPadding={true}
                setData={setData}
                value={reportTo?.name}
                type="custom"
                cell={cell}
                CustomComponent={
                  <ReportToPopup
                    memberList={memberData}
                    setMemberData={setSelectedMember}
                    show={show}
                    setShow={setShow}
                    data={data}
                    cell={cell}
                    setData={setData}
                    selectedMember={reportTo?.id}
                    setSelectedMember={setSelectedMember}
                  //isEditable={isNew}
                  />
                }
              />
            </div>
          );
        },
      }),
      columnHelper.accessor("currency_id", {
        header: "Currency",
        id: "currency_id",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const data = getValue();
          var getView: any;
          if (data) {
            getView = currencyData?.find((x) => x.id == data);
          } else {
            getView = currencyData?.find((x) => x.name == data);
          }
          const originalValues = cell.row.original?.role?.map(
            (role: baseObj) => role.id
          );
          const isNew: boolean = cell.row.original?.isNew;
          return (
            <div
              className={classNames(
                "  text-left h-full",
                // max-w-full min-w-[180px]
                styles.customEmployeeDropdown
              )}
            >
              <EditableCell
                className="py-0"
                hasPadding={true}
                setData={setData}
                value={getView ? getView?.name : "Select"}
                type="custom"
                cell={cell}
                CustomComponent={
                  <CustomDropdownWithAddButton
                  isShowAdd={false}
                    addNewItemData={addCurrencyData}
                    isCurrency={true}
                    customHeight="min-h-[220px]"
                    initialValues={originalValues}
                    dataList={currencyData}
                    setDataList={setCurrencytData}
                    title="Currency"
                    cell={cell}
                    data={data}
                    setData={setData}
                    setSelectedData={setCurrency}
                    selectedData={getView ? getView?.name : "Select"}
                    setDeleteID={setDeleteCurrencyID}
                    setOpenDeleteopup={setOpenDeleteopup}
                    isEditable={isNew}
                  />
                }
              />
            </div>
          );
        },
      }),
      columnHelper.accessor("salary", {
        header: "Salary",
        id: "salary",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const salary = getValue();
          var currency = currencyData.find(
            (x) => x.id == cell.row.original.currency_id
          );
          var salaryView = salary.replace(currency?.label, " ");
          const salaryData =
            cell.row.original?.isEncrypt == false
              ? currency?.label +
              " " +
              new Intl.NumberFormat().format(salaryView)
              : salaryView
                ? currency?.label + " " + encodeAlphabet(salaryView)
                : "";

          return (
            <div className=" text-left">
              {/* max-w-[132px]  min-w-[110px]*/}
              <EditableCell
                setData={setData}
                value={cell.row.original?.isNew ? salaryView : salaryData}
                type="text"
                cell={cell}
              />
            </div>
          );
        },
      }),
      columnHelper.accessor("total_incentive", {
        header: "Total Incentive",
        id: "total_incentive",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const total_incentive = getValue() ? getValue().toString() : "0";
          const total_incentive_encode =
            cell.row.original?.isEncrypt == false
              ? total_incentive
              : total_incentive == "0"
                ? total_incentive?.replace("0", "*")
                : encodeAlphabet(total_incentive);
          const currency_code = cell?.row.original?.currency_code;
          return (
            <div className={`text-left font-normal px-2 min-w-[100px]`}>
              <span className="h-full flex items-center">
                {currency_code} {total_incentive_encode}
              </span>
            </div>
          );
        },
      }),
      columnHelper.accessor("hourly_rate", {
        header: "Hourly Rate",
        id: "hourly_rate",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const hourly_rate = getValue() || "0";
          const hourly_rate_encoded =
            cell.row.original?.isEncrypt == false
              ? hourly_rate
              : encodeAlphabet(hourly_rate);
          const currency_code = cell?.row.original?.currency_code;
          return (
            <div className={`text-left font-normal px-2 min-w-[100px]`}>
              <span className="h-full flex items-center">
                {currency_code} {hourly_rate_encoded}
              </span>
            </div>
          );
        },
      }),
      columnHelper.accessor("dob", {
        header: "Date of Birth",
        id: "dob",
        cell: ({ getValue, cell }) => {
          const dob = getValue();
          var dobDate = new Date();
          if (dob) {
            dobDate = new Date(dob);
          }
          var formatDate = renderDateFormatDMY(dobDate, "/");
          var currentDate = new Date();
          var maxDate = new Date(
            currentDate.getFullYear() - 16,
            currentDate.getMonth(),
            currentDate.getDate()
          );

          return (
            <div className=" pr-1 flex justify-between items-center text-left">
              {/* max-w-[200px] min-w-[180px] */}
              <EditableCell
                setData={setData}
                value={cell.row.original?.isNew ? formatDate : formatDate}
                type="custom"
                cell={cell}
                CustomComponent={
                  <DatePopup
                    dobDate={dobDate}
                    maxDate={maxDate}
                    setDate={() => 1}
                    cell={cell}
                    data={data}
                    setData={setData}
                  />
                }
              />
            </div>
          );
        },
      }),
      columnHelper.accessor("age", {
        header: "Age",
        id: "age",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const age = getValue();
          return (
            <div className=" min-w-[100px] text-left px-[10px] bg-white text-black2 border-r-vorpgraylight border-r-1">
              {/* max-w-[180px] */}
              <p className="h-full flex items-center">{age}</p>
            </div>
          );
        },
      }),
      columnHelper.accessor("gender", {
        header: "Gender",
        id: "gender",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const gender = getValue();
          var genderD = genderData.find((x) => x.id == gender);
          const genderValue = genderD
            ? genderD.name.charAt(0).toUpperCase() + genderD.name.slice(1)
            : "Select";
          const originalValues = cell.row.original?.role?.map(
            (role: baseObj) => role.id
          );
          const isNew: boolean = cell.row.original?.isNew;
          return (
            <div
              className={classNames(
                styles.customEmployeeDropdown,
                "  text-left h-full min-w-[120px]"
                // max-w-[200px]
              )}
            >
              <EditableCell
                className="py-0"
                setData={setData}
                value={gender ? gender : "Select"}
                type="custom"
                cell={cell}
                hasPadding={true}
                CustomComponent={
                  <CustomDropdownWithAddButton
                  isShowAdd={false}
                    isShowDelete={false}
                    isText={true}
                    customHeight="min-h-[220px]"
                    initialValues={originalValues}
                    dataList={genderData}
                    setDataList={setGenderData}
                    title="Gender"
                    cell={cell}
                    data={data}
                    setData={setData}
                    setSelectedData={setGender}
                    selectedData={gender ? gender : "Select"}
                    setDeleteID={setDeleteID}
                    setOpenDeleteopup={setOpenDeleteopup}
                    isEditable={isNew}
                  />
                }
              />
            </div>
          );
        },
      }),
      columnHelper.accessor("race", {
        header: "Race",
        id: "race",
        cell: ({ getValue, cell }) => {
          const race = getValue();
          return (
            <div className="  text-left h-full">
              {/* max-w-[200px] min-w-[180px]*/}
              <EditableCell
                setData={setData}
                value={race}
                type="text"
                cell={cell}
                className="py-0"
              />
            </div>
          );
        },
      }),
      columnHelper.accessor("religion", {
        header: "Religion",
        id: "religion",
        cell: ({ getValue, cell }) => {
          const religion = getValue();
          return (
            <div className="  text-left">
              {/* max-w-[213px] min-w-[193px]*/}
              <EditableCell
                customDesign="tooltip"
                setData={setData}
                value={religion}
                type="text"
                cell={cell}
              />
            </div>
          );
        },
      }),
      columnHelper.accessor("nationality", {
        header: "Nationality",
        id: "nationality",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const nationality = getValue();
          const originalValues = cell.row.original?.role?.map(
            (role: baseObj) => role.id
          );
          var nationalityView = nationalityData.find(
            (x) => x.id == nationality
          );
          var nview = nationalityView;
          if (nview == undefined) {
            nview = nationalityData.find((x) => x.name == nationality);
          }
          const isNew: boolean = cell.row.original?.isNew;
          return (
            <div
              className={classNames(
                "  text-left h-full",
                // max-w-full min-w-[180px]
                styles.customEmployeeDropdown
              )}
            >
              <EditableCell
                className="py-0"
                hasPadding={true}
                setData={setData}
                value={nview ? nview.name : "Select"}
                type="custom"
                cell={cell}
                customDesign="tooltip"
                CustomComponent={
                  <CustomDropdownWithAddButton
                  isShowAdd={false}
                    addNewItemData={addCountryData}
                    initialValues={originalValues}
                    dataList={nationalityData}
                    setDataList={setNationalityData}
                    title="Nationality"
                    cell={cell}
                    data={data}
                    setData={setData}
                    setSelectedData={setNationality}
                    selectedData={nview ? nview.name : "Select"}
                    addTitle="Create New Nationality"
                    setDeleteID={setDeleteNatiID}
                    setOpenDeleteopup={setOpenDeleteopup}
                    isEditable={isNew}
                  />
                }
              />
            </div>
          );
        },
      }),
      columnHelper.accessor("nationalityID", {
        header: "National ID No.",
        id: "nationalityID",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const nationalityID = getValue();
          const nationalData =
            cell.row.original?.isEncrypt == false
              ? nationalityID
              : nationalityID
                ? encodeAlphabet(nationalityID)
                : "";
          return (
            <div className="overflow-hidden  min-w-[140px]  pr-1 flex justify-between items-center text-left">
              {/* max-w-[200px]  */}
              <EditableCell
                maxLength={10}
                setData={setData}
                value={cell.row.original?.isNew ? nationalityID : nationalData}
                type="text"
                cell={cell}
              //customDesign="tooltip"
              />
            </div>
          );
        },
      }),
      columnHelper.accessor("passportNo", {
        header: "Passport No.",
        id: "passportNo",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const passport = getValue();
          const passportData =
            cell.row.original?.isEncrypt == false
              ? passport
              : passport
                ? encodeAlphabet(passport)
                : "";
          return (
            <div className="overflow-hidden   pr-1 flex justify-between items-center text-left">
              {/* max-w-[200px] min-w-[120px] */}
              <EditableCell
                customDesign="tooltip"
                setData={setData}
                value={cell.row.original?.isNew ? passport : passportData}
                type="text"
                cell={cell}
              />
            </div>
          );
        },
      }),
      columnHelper.accessor("device", {
        header: "Device",
        id: "device",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const device = getValue();
          return (
            <div className=" pr-1 flex justify-between items-center text-left">
              {/* max-w-[200px] min-w-[100px] */}
              <EditableCell
                setData={setData}
                value={device}
                type="text"
                cell={cell}
              />
            </div>
          );
        },
      }),

      columnHelper.accessor("device_user_name", {
        header: "Device User Name",
        id: "device_user_name",
        cell: ({ getValue, cell }) => {
          const name = getValue();
          return (
            <div className=" text-left">
              {/* max-w-[160px]  min-w-[140px]*/}
              <EditableCell
                setData={setData}
                value={name}
                type="text"
                cell={cell}
                customDesign="tooltip"
              />
            </div>
          );
        },
      }),

      columnHelper.accessor("is_agent_installed", {
        header: "Enable",
        id: "is_agent_installed",
        enableSorting: false,
        cell: ({ getValue, cell }) => (
          <div
            className={classNames(
              !cell.row.original.isNew ? "" : "pointer-events-none",
              " px-3 min-w-[100px] "
            )}
          >
            <EmployeeEnableSwitch
              loading={enableLoading}
              setData={setData}
              cell={cell}
              initialState={getValue()}
              id={cell.id}
              changeUserStatusData={changeUserStatusData}
            />
          </div>
        ),
      }),

      columnHelper.accessor("bank", {
        header: "Bank",
        id: "bank",
        cell: ({ getValue, cell }) => {
          const bank = getValue();
          return (
            <div className="  text-left">
              {/* max-w-[220px] min-w-[200px]*/}
              <EditableCell
                setData={setData}
                value={bank}
                type="text"
                cell={cell}
                customDesign="tooltip"
              />
            </div>
          );
        },
      }),
      columnHelper.accessor("bankAccountNo", {
        header: "Bank Account No",
        id: "bankAccountNo",
        enableSorting: false,
        cell: ({ getValue, cell }) => {
          const bankNo = getValue();
          return (
            <div className="min-w-[200px] text-left">
              {/* max-w-[220px]  */}
              <EditableCell
                setData={setData}
                value={cell.row.original?.isNew ? bankNo : bankNo}
                type="text"
                cell={cell}
                customDesign="tooltip"
              />
            </div>
          );
        },
      }),
      columnHelper.accessor("leave_allowance", {
        header: "Leave Allowance",
        id: "leave_allowance",
        cell: ({ getValue, cell }) => {
          const bankNo = getValue();
          const rowId = cell?.row?.original?.uniqeID;
          const isNew = cell.row.original?.isNew;
          return (
            <div
              onClick={() => {
                setShowLeaveAllowance(true);
                getAllowance(rowId);
                setLeaveID(cell.row.original.uniqeID);
                setEmployeeID(cell.row.original.employeeID);
              }}
              className={classNames(
                rowId === 0
                  ? "opacity-100 pointer-events-none"
                  : isNew
                    ? "opacity-100 cursor-pointer text-purple-400 underline font-bold decoration-double"
                    : "pointer-events-none opacity-50",
                "  text-left cursor-pointer"
                // max-w-[220px] min-w-[200px]
              )}
            >
              <EditableCell
                setData={setData}
                value={bankNo}
                type="label"
                cell={cell}
                customDesign="tooltip"
              />
            </div>
          );
        },
      }),

      // columnHelper.accessor("status", {
      //   header: "Leave Allowance",
      //   id: "status",
      //   cell: ({ getValue, cell }) => {
      //     var status = getValue();
      //     var statusView = statusData.find((x) => x.id == status);
      //     const originalValues = cell.row.original?.role?.map(
      //       (role: baseObj) => role.id
      //     );
      //     const isNew: boolean = cell.row.original?.isNew;
      //     return (
      //       <div
      //         className={classNames(
      //           "max-w-[200px] min-w-[130px] text-left h-full",
      //           styles.customEmployeeDropdown
      //         )}
      //       >
      //         <EditableCell
      //           className={
      //             status == "Full Time"
      //               ? "text-vorpgreen py-0"
      //               : status == "Part Time"
      //                 ? "text-[#7682DA] py-0"
      //                 : "py-0"
      //           }
      //           customDesign="tooltip"
      //           hasPadding={true}
      //           setData={setData}
      //           value={status}
      //           type="custom"
      //           cell={cell}
      //           CustomComponent={
      //             <CustomDropdownWithAddButton
      //               isText={true}
      //               isShowAdd={false}
      //               isShowDelete={false}
      //               initialValues={originalValues}
      //               dataList={statusData}
      //               setDataList={setStatusData}
      //               title="Status"
      //               cell={cell}
      //               data={data}
      //               setData={setData}
      //               setSelectedData={setStatus}
      //               selectedData={status ? status : "Select"}
      //               addTitle="Create New Status"
      //               setDeleteID={setDeleteStatID}
      //               setOpenDeleteopup={setOpenDeleteopup}
      //               isEditable={isNew}
      //             />
      //           }
      //         />
      //       </div>
      //     );
      //   },
      // }),

      columnHelper.accessor("status", {
        header: "Status",
        id: "status",
        cell: ({ getValue, cell }) => {
          var status = getValue();
          var statusView = statusData.find((x) => x.id == status);
          const originalValues = cell.row.original?.role?.map(
            (role: baseObj) => role.id
          );
          const isNew: boolean = cell.row.original?.isNew;
          return (
            <div
              className={classNames(
                " text-left h-full min-w-[150px] ",
                // max-w-[200px]
                styles.customEmployeeDropdown
              )}
            >
              <EditableCell
                className={
                  status == "Full Time"
                    ? "text-vorpgreen py-0"
                    : status == "Part Time"
                      ? "text-[#7682DA] py-0"
                      : "py-0"
                }
                customDesign="tooltip"
                hasPadding={true}
                setData={setData}
                value={status}
                type="custom"
                cell={cell}
                CustomComponent={
                  <CustomDropdownWithAddButton                  
                    isText={true}
                    isShowAdd={false}
                    isShowDelete={false}
                    initialValues={originalValues}
                    dataList={statusData}
                    setDataList={setStatusData}
                    title="Status"
                    cell={cell}
                    data={data}
                    setData={setData}
                    setSelectedData={setStatus}
                    selectedData={status ? status : "Select"}
                    addTitle="Create New Status"
                    setDeleteID={setDeleteStatID}
                    setOpenDeleteopup={setOpenDeleteopup}
                    isEditable={isNew}
                  />
                }
              />
            </div>
          );
        },
      }),
      // columnHelper.display({
      //   header: "",
      //   id: `${checkManage ? "lasted" : "lasted-no"}`,
      // }),
      columnHelper.display({
        header: "Action",
        id: `${(data?.find((x) => x.isNew == true) && addNew) || checkManage
            ? "actions"
            : "none-actions"
          }`,
        // id: `${checkManage ? 'actions' : 'none-actions'}`,
        cell: ({ cell }) => {
          var isHaveNew = data?.find((x) => x.isNew === true);
          var disabledClass = isHaveNew ? "opacity-50 pointer-events-none" : "";
          const isNew: boolean = cell.row.original?.isNew;
          var link = "/profile?id=" + cell.row.original.uniqeID;
          return (
            <div
              className={classNames(
                styles.actionedBtn,
                "px-2 flex justify-center items-center "
                // max-w-[98px] min-w-max
              )}
            >
              {isNew ? (
                <>
                  <button
                    type="button"
                    onClick={() => {
                      saveEmployeeData(Number(cell.row.original.uniqeID));
                    }}
                    className="w-6 h-6 flex justify-center items-center bg-vorpgreen rounded-md"
                  >
                    <TableSaveIcon />
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      canCeelRow(Number(cell?.row?.original?.uniqeID));
                      // setIsCreated(false)
                    }}
                    className={classNames(
                      styles.customCloseIcon,
                      "w-8 h-8 ml-2 mr-2 flex justify-center items-center group rounded-md  bg-[#71717a] hover:bg-[#71717a] transition-all duration-300"
                    )}
                  >
                    <CancelIcon width={18} height={18} />
                  </button>
                </>
              ) : (
                <>
                  {checkManage ? (
                    <button
                      onClick={() => {
                        localStorage.setItem(
                          "state",
                          cell.row.original.uniqeID
                        );
                        window.open(
                          "/employee/" +
                          cell.row.original.uniqeID +
                          "/leave-information",
                          "_blank",
                          "noreferrer"
                        );
                      }}
                      className={classNames(
                        styles.customViewdBtn,
                        "w-6 h-6 mr-2 flex justify-center items-center group rounded-md bg-darkpurple hover:bg-[#EAE0FF] transition-all duration-300"
                      )}
                    >
                      <ViewIcon />
                    </button>
                  ) : // <NavLink
                    //   to={{
                    //     pathname: "/profile"
                    //   }} target="_blank"
                    //   state={cell.row.original.uniqeID}
                    //   className={classNames(
                    //     styles.customViewdBtn,
                    //     "w-6 h-6 mr-2 flex justify-center items-center group rounded-md bg-darkpurple hover:bg-[#EAE0FF] transition-all duration-300"
                    //   )}
                    // >
                    //   <ViewIcon />
                    // </NavLink>
                    null}
                  {checkManage ? (
                    <button
                      type="button"
                      onClick={() =>
                        shareEmployeeData(Number(cell.row.original.uniqeID))
                      }
                      className={classNames(
                        styles.customSharedBtn,
                        "w-6 h-6 mr-2 flex justify-center items-center group rounded-md bg-vorpphillipines hover:bg-turqouiseLight transition-all duration-300"
                      )}
                    >
                      <ShareIcon />
                    </button>
                  ) : null}
                  {checkManage ? (
                    <button
                      disabled={isHaveNew ? true : false}
                      type="button"
                      onClick={() => {
                        editEmployee(Number(cell.row.id));
                        if (setIsEdit) {
                          setIsEdit(true);
                        }
                      }}
                      className={classNames(
                        disabledClass,
                        " w-6 h-6 mr-2 flex justify-center items-center group rounded-md bg-vorpblue hover:bg-annhoverbg transition-all duration-300"
                      )}
                    >
                      <EditIcon />
                    </button>
                  ) : null}

                  {checkManage ? (
                    <button
                      type="button"
                      onClick={() => {
                        deleteEmployeeData(
                          cell.row.id,
                          cell.row.original.uniqeID
                        );
                      }}
                      className={classNames(
                        disabledClass,
                        " w-6 h-6 flex justify-center items-center group rounded-md bg-redPink hover:bg-[#FFEAEF] transition-all duration-300"
                      )}
                    >
                      <TrashIcon />
                    </button>
                  ) : null}
                </>
              )}
            </div>
          );
        },
      }),
    ],
    [
      columnHelper,
      data,
      departmentData,
      designationData,
      nationalityData,
      currencyData,
      hiddenFields,
      // allowanceSuccess,
    ]
  );

  const [columnOrder, setColumnOrder] = useState<string[]>(
    columns.map((column: ColumnDef<any, any>) => column.id as string)
  );

  document.querySelectorAll("th,td").forEach((x) => {
    if (x.id.includes("none")) {
      x.classList.add("hidden");
    }

    x.classList.remove("absolute");
    x.classList.add("left-0");
    x.classList.add("relative");
    var div = document.getElementById(x.id);
    if (div) {
      div.style.setProperty("left", "");
    }
    if (x.id.includes("action")) {
      x.classList.add("absolute");
      x.classList.remove("relative");
      x.classList.add("right-0");
      x.classList.remove("left-0");
    }
  });

  const deleteEmployeeData = useCallback(
    (id?: any, uniqueID?: number) => {
      setDeleteID(id);
      setDeleteRowID(uniqueID);
      setOpenDeleteopup(true);
    },
    [setData, rowSelection]
  );

  const deleteEmpItem = useCallback(
    (id?: number, dataId?: number) => {
      const selectedRows = Object.keys(rowSelection);
      setData((prev: baseObj[]) => {
        if (dataId != undefined) {
          // var $dataId = dataId;
          deleteEmployee(dataId)
            .unwrap()
            .then((payload: any) => {
              if (payload.status) {
                setOpenDeleteopup(false);
                setSuccessPopup(true);
                setMessage(
                  payload ? [payload?.message] : ["Successfully Delete."]
                );
                toast("Successfully Delete.", {
                  position: "top-right",
                  autoClose: 2000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                  type: "success",
                });
              }
            })
            .catch((error: any) => {
              setErrorPopup(true);
              setOpenDeleteopup(false);
              setMessage(
                error
                  ? [error?.data?.message]
                  : ["Something went wrong!Trying again."]
              );
            });
        }
        return prev.filter((_, index) => {
          return id !== undefined
            ? id !== index
            : !selectedRows.includes(String(index));
        });
      });
      id == undefined && setRowSelection({});
    },
    [setData, rowSelection]
  );

  // const deleteItem = (id?: number) => {
  //   const selectedRows = Object.keys(rowSelection);
  //   setData((prev: baseObj[]) =>
  //     prev.filter((_, index) => {
  //       if (id == index) {
  //         deleteEmployee(prev[id]?.uniqeID);
  //       }
  //       return (id !== undefined && id != -1)
  //         ? id !== index
  //         : !selectedRows.includes(String(index));
  //     })
  //   );
  //   setRowSelection({});
  //   id == undefined && setRowSelection({});
  //   //var uniqueID=
  //   //  deleteEmployee(id);
  // };

  const confirmDeleteData = () => {
    var id = deleteID;
    var dataID = deleteRowID;
    const selectedRows = Object.keys(rowSelection);
    var selectionIds = data
      .filter((x, index: number) => {
        return selectedRows.find((s) => s == index.toString()) ? x.uniqeID : 0;
      })
      .map((x) => x.uniqeID);
    if (selectionIds.length > 0) {
      deleteEmployeeMultiple(selectionIds);
    } else {
      if (deleteCurrencyID) {
        deleteCurrency(deleteCurrencyID)
          .unwrap()
          .then((payload: any) => {
            if (payload.status) {
              setSuccessPopup(true);
              setMessage([payload?.message]);
              setOpenDeleteopup(false);
              toast("Successfully Delete.", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                type: "success",
              });
            }
          })
          .catch((error: any) => {
            setErrorPopup(true);
            setMessage(
              error
                ? [error?.data?.message]
                : ["Something went wrong!Trying again."]
            );
          });
        setDeleteCurrencyID(0);
      }
      if (deleteDeptID) {
        deleteDepartment(deleteDeptID)
          .unwrap()
          .then((payload: any) => {
            if (payload.status) {
              setSuccessPopup(true);
              setMessage([payload?.message]);
              setOpenDeleteopup(false);
              toast("Successfully Delete.", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                type: "success",
              });
            } else {
              setErrorPopup(true);
              setMessage(
                payload
                  ? [payload?.message]
                  : ["Something went wrong!Trying again."]
              );
            }
          })
          .catch((error: any) => {
            setErrorPopup(true);
            setMessage(
              error
                ? [error?.data?.message]
                : ["Something went wrong!Trying again."]
            );
          });
        setDeleteDeptID(0);
      }
      if (deleteNatiID) {
        deleteCountry(deleteNatiID)
          .unwrap()
          .then((payload: any) => {
            if (payload.status) {
              setSuccessPopup(true);
              setMessage([payload?.message]);
              setOpenDeleteopup(false);
              toast("Successfully Delete.", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                type: "success",
              });
            } else {
              setErrorPopup(true);
              setMessage(
                payload
                  ? [payload?.message]
                  : ["Something went wrong!Trying again."]
              );
            }
          })
          .catch((error: any) => {
            setMsg(
              error
                ? error?.data?.message
                : "Something went wrong!Trying again."
            );
          });
        setDeleteNatiID(0);
      }
      if (deleteDesiID) {
        deleteDesignation(deleteDesiID)
          .unwrap()
          .then((payload: any) => {
            if (payload.status) {
              setSuccessPopup(true);
              setMessage([payload?.message]);
              setOpenDeleteopup(false);
              toast("Successfully Delete.", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                type: "success",
              });
            } else {
              setErrorPopup(true);
              setMessage(
                payload
                  ? [payload?.message]
                  : ["Something went wrong!Trying again."]
              );
            }
          })
          .catch((error: any) => {
            setMsg(
              error
                ? error?.data?.message
                : "Something went wrong!Trying again."
            );
          });
        setDeleteDesiID(0);
      }
      if (deleteStatID) {
        deleteDepartment(deleteStatID)
          .unwrap()
          .then((payload: any) => {
            if (payload.status) {
              setSuccessPopup(true);
              setMessage([payload?.message]);
              setOpenDeleteopup(false);
              toast("Successfully Delete.", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                type: "success",
              });
            } else {
              setErrorPopup(true);
              setMessage(
                payload
                  ? [payload?.message]
                  : ["Something went wrong!Trying again."]
              );
            }
          })
          .catch((error: any) => {
            setMsg(
              error
                ? error?.data?.message
                : "Something went wrong!Trying again."
            );
          });
        setDeleteStatID(0);
      }
      if (id) {
        deleteEmpItem(id, dataID);
        setDeleteID(-1);
        setDeleteRowID(-1);
      }
      setOpenDeleteopup(false);
    }
  };

  const deleteEmployeeMultiple = (ids: number[]) => {
    deleteEmployeeByIDs(ids)
      .then((res: any) => {
        if (res.data.status) {
          setOpenDeleteopup(false);
          toast("Successfully Delete.", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            type: "success",
          });
        }
      })
      .catch((err: any) => {
        setOpenDeleteopup(false);
      });
    setRowSelection({});
  };

  // const getDepartmentID = (department: string) => {
  //   var dptData = departmentData.find((x) => x.name == department);
  //   return dptData?.id;
  // };

  const validateEmail = (email: any) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  const validateWorkEmail = (email: any) => {
    const customEmailRegex = /^[a-zA-Z0-9._%+-]+@visibleone\.com$/;
    return customEmailRegex.test(email);
  };

  const validateWorkEmailHK = (email: any) => {
    const customEmailHKRegex = /^[a-zA-Z0-9._%+-]+@visibleone\.com\.hk$/;
    return customEmailHKRegex.test(email);
  };

  const checkIsValidate = (editedData: any) => {
    var message: any = [];
    if (editedData.name == "" || editedData.preferredName == "") {
      message.push("The name field is required");
    }
    if (editedData.email === "" || !validateEmail(editedData.email)) {
      message.push("Email not validate");
    }

    if (
      !validateWorkEmailHK(editedData.workEmail) &&
      !validateWorkEmail(editedData.workEmail)
    ) {
      message.push(
        "Work-Email not validate (xx@visibleone.com.hk) (xx@visibleone.com)"
      );
    }

    if (editedData.mobile == "") {
      message.push("Mobile field is required");
    }
    if (editedData.department == "") {
      message.push("Department field is required");
    }
    if (editedData.designation == "") {
      message.push("Designation field is required");
    }
    if (editedData.reportTo === "") {
      message.push("Report to field is required");
    }
    if (editedData.currency_id == "") {
      message.push("Currency field is required");
    }
    if (editedData.salary == "") {
      message.push("Salary field is required");
    }
    if (editedData.reportTo == "") {
      message.push("Report To field is required");
    }
    if (editedData.gender == "") {
      message.push("Gender field is required");
    }
    if (editedData.nationality == "") {
      message.push("Nationality field is required");
    }
    if (editedData.status == "") {
      message.push("Status field is required");
    }
    var emailAlready = data?.find(
      (x) => x.email == editedData.email && x.uniqeID !== editedData.uniqeID
    );
    var workEmailAlready = data?.find(
      (x) =>
        x.workEmail == editedData.workEmail && x.uniqeID !== editedData.uniqeID
    );
    var emailAlreadyInUser = memberData?.find(
      (x: any) => x.email == editedData.email
    );
    var workEmailAlreadyInUser = memberData?.find(
      (x: any) => x.workEmail == editedData.email
    );
    //console.log("emailAlready ", emailAlready, emailAlreadyInUser, workEmailAlready, data,editedData)
    if (editedData.uniqeID != 0) {
      if (emailAlready) {
        message.push("The email has already been taken.");
      }
      if (workEmailAlready) {
        message.push("The work email has already been taken.");
      }
    } else {
      if (emailAlready || emailAlreadyInUser) {
        message.push("The email has already been taken.");
      }
      if (workEmailAlready || workEmailAlreadyInUser) {
        message.push("The work email has already been taken.");
      }
    }
    var preferredAlready = data?.find(
      (x) =>
        x.preferredName == editedData.preferredName &&
        x.uniqeID !== editedData.uniqeID
    );
    if (preferredAlready) {
      message.push("The preferred name has already been taken.");
    }
    var mobileAlready = data?.find(
      (x) => x.mobile == editedData.mobile && x.uniqeID !== editedData.uniqeID
    );
    if (mobileAlready) {
      message.push("The mobile has already been taken.");
    }
    return message;
  };

  const dptmData = empDepartmentData ? empDepartmentData : [];
  const country = nationalityData;
  const curData = currencyData;

  const saveEmployeeData = (id: number) => {
    var editedData = data.find((x) => x.uniqeID == id);
    console.log("ed", editedData);
    if (editedData) {
      if (Number(editedData.uniqeID) === Number(id)) {
        var message: any = checkIsValidate(editedData);
        if (message.length > 0) {
          setErrorPopup(true);
          setMessage(message);
          return;
        } else {
          var joinedDate = editedData.joinedDate;
          var lastDate = lastDateData;
          // if (editedData.joinedDate) {
          //   joinedDate = renderDateFormat(new Date(editedData.joinedDate), "-");
          // }
          var dob = editedData.dob;

          var currencyCode = currencyData.find(
            (x) => x.id == editedData?.currency_id
          );
          var isNew = true;
          // const name = DOMPurify.sanitize(data);

          var updatedData = {
            employeeID: editedData.employeeID,
            uniqeID: editedData.uniqeID,
            name: DOMPurify.sanitize(editedData.name),
            preferredName: DOMPurify.sanitize(editedData.preferredName),
            email: editedData.email,
            workEmail: editedData.workEmail,
            mobile: DOMPurify.sanitize(editedData.mobile),
            joinedDate: joinedDate,
            lastDate: lastDate,
            department_id: editedData.department,
            designation_id: editedData.designation,
            currency_id: editedData.currency_id,
            position: designate,
            report_to: editedData.reportTo?.id,
            currency: currencyCode ? currencyCode.label : "",
            salary: editedData?.salary,
            dob: dob,
            gender: editedData.gender,
            race: DOMPurify.sanitize(editedData.race),
            religion: DOMPurify.sanitize(editedData.religion),
            country_id: editedData.nationality,
            nationalityID: DOMPurify.sanitize(editedData.nationalityID),
            passportNo: DOMPurify.sanitize(editedData.passportNo),
            device: DOMPurify.sanitize(editedData.device),
            is_agent_installed: editedData.is_agent_installed,
            device_user_name: DOMPurify.sanitize(editedData.device_user_name),
            bank: DOMPurify.sanitize(editedData.bank),
            bankAccountNo: DOMPurify.sanitize(editedData.bankAccountNo),
            status: editedData.status,
            id: editedData.uniqeID,
            // years: editedData.years
          };

          editedData.dob = dob;
          editedData.joinedDate = joinedDate;
          editedData.lastDate = lastDate;

          if (editedData.uniqeID == 0) {
            createEmployee(updatedData)
              .then((payload: any) => {
                if (payload?.data?.status) {
                  toast("Successfully Create.", {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    type: "success",
                  });
                  saveEmployee(id, false);
                  setMessage(
                    payload ? [payload?.message] : ["Successfully Create."]
                  );
                  isNew = false;
                }
              })
              .catch((error: any) => {
                setErrorPopup(true);
                setMessage(
                  error ? [error?.data?.message] : ["Something went wrong!"]
                );
                isNew = true;
              });
          } else {
            if (typeof updatedData.department_id == "string") {
              if (Number.isInteger(updatedData.department_id)) {
                var dptData = dptmData.find(
                  (x) => x.id == updatedData.department_id
                );
                updatedData.department_id = dptData ? dptData.id : 0;
              } else {
                var dptData = dptmData.find(
                  (x) => x.name == updatedData.department_id
                );
                updatedData.department_id = dptData ? dptData.id : 0;
              }
            }

            var designtData = designationData.find(
              (x) => x.name == updatedData.designation_id
            );

            if (typeof updatedData.designation_id == "string") {
              updatedData.designation_id = designtData ? designtData.id : 0;
            }
            var natData = country.find((x) => x.name == updatedData.country_id);
            if (typeof updatedData.country_id == "string") {
              updatedData.country_id = natData ? natData.id : 0;
            }
            var reportTo = updatedData.report_to;
            if (typeof reportTo == "object") {
              updatedData.report_to = reportTo ? reportTo.id : 0;
            }
            var salary = editedData.salary;
            var currency = curData.find((x) => x.id == editedData?.currency_id);
            const currencys = salary.replace(currency?.label, "");
            updatedData.salary = DOMPurify.sanitize(
              currencys?.toString() ?? ""
            );
            updatedData.bankAccountNo = updatedData.bankAccountNo;

            updateEmployee(updatedData)
              .then((payload: any) => {
                // console.log("p", payload);

                if (payload?.data?.status) {
                  saveEmployee(id, false);
                  setSuccessPopup(true);
                  setMessage(
                    payload ? [payload?.message] : ["Successfully Updated."]
                  );
                  isNew = false;
                  toast("Successfully Updated.", {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    type: "success",
                    theme: "light",
                  });
                }
                if (payload?.error?.status) {
                  setErrorPopup(true);
                  setMessage(
                    payload?.error?.data?.message
                      ? [payload?.error?.data?.message]
                      : ["Something went wrong!"]
                  );
                  isNew = true;
                }
              })
              .catch((error: any) => {
                setErrorPopup(true);
                setMessage(
                  error ? [error?.data?.message] : ["Something went wrong!"]
                );
                isNew = true;
              });
          }
        }
      }
    }
  };

  const saveEmployee = useCallback(
    (id: number, isNew: boolean) => {
      setData((prev: baseObj[]) => {
        return prev.map((data, index: number) => {
          if (data.uniqeID == id) {
            return {
              ...data,
              isNew: isNew,
            };
          }
          return data;
        });
      });
    },
    [setData]
  );

  const editEmployee = useCallback(
    (id: number) =>
      setData((prev: baseObj[]) => {
        if (document.getElementsByClassName(Table.horizontalScrollBar)[0]) {
          document.getElementsByClassName(
            Table.horizontalScrollBar
          )[0].scrollLeft = 0;
        }
        return prev.map((data, index: number) => {
          if (index == id) {
            return { ...data, isNew: true };
          }
          return data;
        });
      }),
    [setData]
  );

  const canCeelRow = useCallback(
    (id: number) => {
      if (isEditing) {
        setIsEdit(false);
        setData((prev: baseObj[]) => {
          return prev.map((data, index: number) => {
            if (data.uniqeID == id) {
              return { ...data, isNew: false };
            }
            return data;
          });
        });
      } else {
        const filterData = data?.filter((d: any) => d?.uniqeID !== id);
        setData(filterData);
      }
    },
    [isEditing, setData, data, setIsEdit]
  );

  const addDepartmentData = useCallback(
    (data: string) => {
      const name = DOMPurify.sanitize(data);
      createDepartment({ name })
        .unwrap()
        .then((payload: any) => {
          if (payload.status) {
            setSuccessPopup(true);
            setMessage(payload ? [payload?.message] : ["Successfully Delete."]);
            toast("Successfully Created.", {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              type: "success",
            });
          }
        })
        .catch((error: any) => {
          setErrorPopup(true);
          setMessage(
            error
              ? [error?.data?.message]
              : ["Something went wrong!Trying again."]
          );
        });
    },
    [createDepartment]
  );

  const addDesignationData = useCallback(
    (data: string) => {
      const name = DOMPurify.sanitize(data);
      createDesignation({ name })
        .unwrap()
        .then((payload: any) => {
          if (payload.status) {
            setSuccessPopup(true);
            setMessage([payload?.message]);
            setOpenDeleteopup(false);
            toast("Successfully Created.", {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              type: "success",
            });
          }
        })
        .catch((error: any) => {
          setErrorPopup(true);
          setMessage(
            error
              ? [error?.data?.message]
              : ["Something went wrong!Trying again."]
          );
        });
    },
    [createDesignation]
  );

  const addCurrencyData = useCallback(
    (data: string) => {
      const name = DOMPurify.sanitize(data);
      createCurrency({ currency_name: name, currency_code: name })
        .unwrap()
        .then((payload: any) => {
          if (payload.status) {
            setSuccessPopup(true);
            setMessage([payload?.message]);
            setOpenDeleteopup(false);
            toast("Successfully Created.", {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              type: "success",
            });
          }
        })
        .catch((error: any) => {
          setErrorPopup(true);
          setMessage(
            error
              ? [error?.data?.message]
              : ["Something went wrong!Trying again."]
          );
        });
    },
    [createCurrency]
  );

  const addCountryData = useCallback(
    (data: string) => {
      const name = DOMPurify.sanitize(data);
      createCountry({ name })
        .unwrap()
        .then((payload: any) => {
          if (payload.status) {
            setSuccessPopup(true);
            setMessage([payload?.message]);
            setOpenDeleteopup(false);
            toast("Successfully Created.", {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              type: "success",
            });
          }
        })
        .catch((error: any) => {
          setErrorPopup(true);
          setMessage(
            error
              ? [error?.data?.message]
              : ["Something went wrong!Trying again."]
          );
        });
    },
    [createCountry]
  );

  const shareEmployeeData = useCallback(
    (id: number) => {
      setOpenSharePopup(true);
      setShareID(id);
    },
    [setData]
  );

  const visibleColumns: { [key: string]: boolean } = {};

  hiddenFields?.map((x) => {
    visibleColumns[x] = true;
  });

  if (checkManage) {
    visibleColumns["actions"] = true;
  } else {
    visibleColumns["actions"] = false;
  }

  if (hiddenFields.length > 0) {
    columns.map((x) => {
      if (x.id == "actions" || x.id == "selection" || x.id == "lasted") {
        visibleColumns[x.id ? x.id : ""] = true;
      } else if (x.id == "uniqeID") {
        visibleColumns[x.id ? x.id : ""] = false;
      } else if (x.id?.includes("none")) {
        visibleColumns[x.id ? x.id : ""] = true;
      } else {
        var isHidden = hiddenFields.find((h) => h == x.id);
        if (isHidden) {
          visibleColumns[x.id ? x.id : ""] = true;
        } else {
          visibleColumns[x.id ? x.id : ""] = false;
        }
      }
    });
  }
  const saveTemplateData = (template: any) => {
    var views = columns
      .map((x) => {
        if (x.id) {
          if (template[x.id] == undefined) {
            return x.id;
          }
        }
      })
      .filter((x) => x != undefined);
    var viewsData = views.map((x) => (x != undefined ? x : ""));
    var newTemplate: TemplateData = {
      id: 0,
      name: "View" + templateList.length + 1,
      viewColumns: viewsData,
    };
    setHiddenFields(viewsData);
    setCurrentView("");
  };

  const sendEmailData = (data: any) => {
    sendEmail(data)
      .unwrap()
      .then((payload: any) => {
        if (payload.status) {
          setOpenSharePopup(false);
          setSuccessPopup(true);
          setMessage([payload?.message]);
          setIsSending(false);
          toast("Email Sent Successfully.", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            type: "success",
          });
        }
      })
      .catch((error: any) => {
        setErrorPopup(true);
        setMessage(["Something went wrong!"]);
      });

    // .then((res) => {
    //   setOpenSharePopup(false);
    // });
  };

  useEffect(() => {
    if (hiddenFields.length > 0) {
      setColumnOrder(["selection", ...hiddenFields]);
    }
  }, [hiddenFields]);

  useEffect(() => {
    setTimeout(() => {
      setSuccessPopup(false);
    }, 1000);
  }, [successPopup]);

  return (
    <div
      id="employeeTable"
      className={classNames(
        styles.employeeTable,
        "relative inline-block max-w-full"
      )}
    >
      <ToastContainer className={"text-12 font-poppins font-normal"} />
      {/* <SuccessPopup
        descText="Done Successfully!"
        confirmBtnText="Yes, Close."
        show={successPopup}
        setShow={setSuccessPopup}
        message={message}
        setMessage={setMessage}
      /> */}

      <ErrorPopup
        descText="Error Message!"
        confirmBtnText="Yes, Close."
        show={errorPopup}
        setShow={setErrorPopup}
        message={message}
        setMessage={setMessage}
      />

      <DeletePopup
        descText="Are you sure to delete?"
        confirmBtnText="Yes, delete."
        show={openDeletePopup}
        setShow={setOpenDeleteopup}
        confirmDeleteData={confirmDeleteData}
      />

      <SharePopup
        setIsSending={setIsSending}
        isSending={isSending}
        sendEmailData={sendEmailData}
        shareID={shareID}
        show={openSharePopup}
        setShow={setOpenSharePopup}
      />

      <LayoutAdjustableTable
        saveTemplateData={saveTemplateData}
        hideColumn={visibleColumns}
        columns={columns}
        data={data}
        sorting={sorting}
        setSortingState={setSorting}
        rowSelection={rowSelection}
        setRowSelection={setRowSelection}
        columnOrder={columnOrder}
        setColumnOrder={setColumnOrder}
        unDraggableFields={["actions", "selection", "id"]}
        theaderColor="text-graydark"
        isLayoutControlOpen={isLayoutControlOpen}
        setIsLayoutControlOpen={setIsLayoutControlOpen}
        label="Employee List"
        setItemCount={setItemCount}
        pagination={pagination}
        setPagination={setPagination}
        globalFilter={globalSearch}
        setGlobalFilter={setGlobalSearch}
        columnFilters={columnFilters}
        setColumnFilters={setColumnFilters}
        noPaddingCells={[
          "department",
          "designation",
          "gender",
          "nationality",
          "status",
          "currency_id",
        ]}
        localModuleId={localModuleId}
        setIsTemplate={setIsTemplate}
        setCols={setCols}
      />

      {selectRowsCount > 0 && (
        <div className="flex items-center text-14 leading-5 font-semibold text-black2 mt-8 mb-10 lg:mt-10 lg:mb-12 ">
          <p>{selectRowsCount} selected.</p>
          {checkManage ? (
            <button
              type="button"
              onClick={() => setOpenDeleteopup(true)}
              className=" flex items-center ml-5 text-12 leading-[18px] font-semibold text-redPink"
            >
              <div className="w-6 h-6 bg-redPink flex items-center group justify-center rounded-md mr-[10px] hover:bg-[#FFEAEF]  transition-all duration-300 ">
                <TrashIcon width={10} height={12} />
              </div>
              Delete
            </button>
          ) : null}
        </div>
      )}
      <div className="pt-6">
        <TablePaginator
          total={itemCount}
          pagination={pagination}
          setPagination={setPagination}
          itemCount={total}
          setCurrentPage={setCurrentPage}
          currentPageDefault={currentPage}
        // changePage={changePage}
        />
      </div>
      <CommonErrorPopup
        show={msg == "" ? false : true}
        setShow={() => setMsg("")}
        message={msg}
      />
    </div>
  );
};
export default EmployeeTable;
