import React,{ useEffect, useState } from "react";
import DebounceSearchInput from "../../components/Tables/DebounceSearchInput";
import { AddIcon, CustomizeLayoutIcon, ExportIcon, SearchIcon } from "../../components/common/Icons";
import classNames from "classnames";
import { NumberDropdownList } from "../../components/common/DropdownList";
import { StringDropdownListWithDeleteBtn } from "../../components/common/StringDropdownListWithDeleteBtn";
import { baseObj } from "../../utils/constants";
import { ColumnFiltersState, PaginationState } from "@tanstack/react-table";
import { templateList } from "../../active-time-report/views/ActiveTimeReport";
import { useSpring, animated } from "react-spring";
import PerformanceAppraisalTable from "../../components/PerformanceAppraisalTable/PerformanceAppraisalTable";
import { useGetByModuleTemplateQuery } from "../../app/services/template";
import { hideAllNoneField } from "../../components/Tables/TablePaginator";
import Button from "../../components/common/Button";
import ExportUsersModal from "../../components/BackendUsers/ExportUsersTable";
import axios from "../../axios";
import { endpointUrl } from "../../app/services/api";
const dummyPerformance = [
    {
        "id":1,
        "employeeID": "MM001",
        "employeeName": "Visibee 1",
        "jobTitle": "Designer",
        "department": "Wordpress",
        "batchNo": "202210",
        "reviewDate": "03-11-2022",
        "evaluatedBy": "Visibee2",
        "relations": "Project Colleagues",
    },
    {
        "id":2,
        "employeeID": "MM001",
        "employeeName": "Visibee 1",
        "jobTitle": "Designer",
        "department": "Design",
        "batchNo": "202210",
        "reviewDate": "03-11-2022",
        "evaluatedBy": "Visibee2",
        "relations": "Project Colleagues",
    }
];
const defaultFields = ["employeeID", "employeeName", "jobTitle", "department", "batchNo", "reviewDate", "evaluatedBy", "relations", "actions"];
const PerformanceAppraisal = () => {
    const [globalSearch, setGlobalSearch] = useState<string>("");
    const [isExportModalOpen, setIsExportModalOpen] = useState<boolean>(false);
    const [isExporting, setIsExporting] = useState<boolean>(false);
    const [data, setData] = useState<baseObj[]>(dummyPerformance);
    const [itemCount, setItemCount] = useState<number>(data?.length);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [pagination, setPagination] = useState<PaginationState>({
        pageIndex: 0,
        pageSize: 20,
    });
    const [limit, setLimit] = useState<number>(10);
    const [isTemplate, setIsTemplate] = useState<boolean>(false)
    const [viewCols, setCols] = useState<any>([]);
    const [visArray, setVisArray] = useState<any>({});
    const [currentView, setCurrentView] = useState<any>(0);
    const [currentViewData, setCurrentViewData] = useState<number>(0);
    const [tempList, setTempList] = useState<any>(templateList);
    const [moduleList, setModuleList] = useState<any>([]);
    const [errorPopup, setErrorPopup] = useState<boolean>(false);
    const [message, setMessage] = useState<any>([]);
    const [isEditing, setIsEdit] = useState<boolean>(false);
    const [successPopup, setSuccessPopup] = useState<boolean>(false);
    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
    const [hiddenFields, setHiddenFields] = useState<string[]>([]);
    let [localModuleId, setLocalModuleId] = useState<number>(0);
    const { data: templateData } = useGetByModuleTemplateQuery(localModuleId);
    const [isLayoutControlOpen, setIsLayoutControlOpen] =
        useState<boolean>(false);
    const deleteTemplateData = (id: string) => {
        var filterTempList = templateList?.filter((x) => x.id != parseInt(id));
        setTempList(filterTempList ? filterTempList : []);
        // deleteTemplate(parseInt(id));
    };
    const props = useSpring({
        to: { opacity: 1 },
        from: { opacity: 0 },
        delay: 1000,
    });
    const changePage = (pageNo: number) => {
        var pageData = {
            limit: pagination.pageSize,
            currentPage: pagination.pageIndex + 1,
        };
        // getPermissions(pageData)
    };
    useEffect(() => {
        if (templateList) {
            setTempList(templateList);
        }
        var viewData = templateData?.templates.find((x) => x.id.toString() == currentView);
        setHiddenFields(viewData ? [...viewData.viewColumns] : defaultFields);
        hideAllNoneField();
    }, [templateList, currentView]);
    const exportPerformanceApprisal = (format: string) => {
        axios({
            url: endpointUrl + `exportEmployees?limit=${pagination.pageSize}&page=${currentPage}&format=` + format + "",
            method: "GET",
            responseType: "blob",
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "employees." + (format == "excel" ? 'xlsx' : format));
            document.body.appendChild(link);
            link.click();
            setIsExporting(false);
            setIsExportModalOpen(false);
        });
    };
    return (
        <div>
            <ExportUsersModal
                isOpen={isExportModalOpen}
                setIsOpen={setIsExportModalOpen}
                exportToCSV={exportPerformanceApprisal}
                exportToExcel={exportPerformanceApprisal}
                exportToPDF={exportPerformanceApprisal}
                exportToZip={exportPerformanceApprisal}
                isExporting={isExporting}
                setIsExporting={setIsExporting}
            />
            <div className="px-2 sm:px-4 py-4 bg-white sm:mt-[25px] mt-0 font-poppins text-13 font-semibold text-[#858795] tracking-[-0.03rem] w-full h-full rounded-xl pt-3 pb-8 sm:pt-3 sm:pb-9">
                <div className="flex flex-wrap justify-between items-center">
                    <div className="flex items-center mr-2 xs:mr-4 sm:w-[260px] w-[225px] md:mb-1">
                        <DebounceSearchInput
                            setState={setGlobalSearch}
                            debounce={800}
                        >
                            {(setState) => (
                                <div className="font-normal flex border-1 border-[#C4C4C4] bg-white hover:bg-[#EFEFEF] rounded-[28px]">
                                    <input
                                        id="message"
                                        autoComplete="off"
                                        name="search"
                                        type="text"
                                        placeholder="Search"
                                        className="focus:outline-none py-1 px-4 text-12 xl:text-14 leading-5 bg-transparent rounded-[28px] w-full text-graydark"
                                        onChange={(e) => setState(e.target.value)}
                                    />
                                    <SearchIcon className={classNames("mr-2")} />
                                </div>
                            )}
                        </DebounceSearchInput>
                    </div>
                    <Button
                        label={
                            <span className="flex items-center">
                                <ExportIcon className="mr-[5px]" />
                                Export
                            </span>
                        }
                        onClick={() => { setIsExportModalOpen(true); setIsExporting(false); }}
                        type="button"
                        variant="no-outline"
                        customClass="my-1 bg-vorpmyanmar border-vorpmyanmar hover:bg-vorpblue hover:text-white transition-all duration-300 text-vorpblue xs:text-[12px] leading-[18px] font-medium py-1 px-[6px] sm:mr-[10px] mr-[5px]"
                        size="base"
                    />
                </div>

                <div className=" flex sm:flex-row flex-col justify-end items-center">
                    <div className="flex flex-wrap items-center justify-end sm:w-auto w-full">
                        <div className="flex items-center mr-3 mb-1">
                            <p className="base-label xl:text-sm text-12">DISPLAY</p>
                            <NumberDropdownList
                                state={pagination.pageSize}
                                setState={(value) => {
                                    setPagination((prev) => ({
                                        ...prev,
                                        pageSize: value as number,
                                    }))
                                    setLimit(value)
                                }
                                }
                                dropdownItems={new Array(5)
                                    .fill(1)
                                    .map((_, index: number) => {
                                        let display = (index + 1) * 20;
                                        return {
                                            value: display,
                                            label: String(display),
                                        };
                                    })}
                                className="max-w-[72px]"
                                textAlign="text-left pl-2 pr-3"
                                placeholder="20"
                                paddingClass="py-[2px]"
                            />
                        </div>
                        <div className=" mb-1">
                            <StringDropdownListWithDeleteBtn
                                setIsTemplate={setIsTemplate}
                                state={isTemplate ? currentViewData : currentView}
                                setState={setCurrentView}
                                deleteTemplate={deleteTemplateData}
                                dropdownItems={moduleList.map((x: any, index: number) => {
                                    return {
                                        label: (x.id == -1 ? x.name : "View #" + x.index),
                                        value: x.id,
                                    };
                                })}
                                className="w-full min-w-[100px] 2xl:min-w-[137px] 6xs:text-12"
                                placeholder="View #1"
                                textAlign="text-left py-2 pl-2 pr-3"
                                setErrorPopup={setErrorPopup}
                                setSuccessPopup={setSuccessPopup}
                                setMessage={setMessage}
                                custPadding="py-[1px]"
                            />
                        </div>
                        <button
                            type="button"
                            onClick={() => setIsLayoutControlOpen(true)}
                            className=" mb-1 flex relative justify-center items-center rounded-lg bg-vorphongkongborder w-[29px] h-[29px] ml-2 group transition-all duration-300 "
                        >
                            <CustomizeLayoutIcon pathClassName="group-hover:fill-white" />
                            <div className="absolute left-7 top-[-5px] border border-white w-4 h-4 rounded-full bg-vorphongkongborder hover:bg-vorphongkongborder flex justify-center items-center">
                                <AddIcon width={8} height={8} color="white" />
                            </div>
                        </button>
                    </div>
                </div>
                <animated.div style={props}>
                    <div>
                        <PerformanceAppraisalTable
                            data={data}
                            setData={setData}
                            pagination={pagination}
                            setPagination={setPagination}
                            isLayoutControlOpen={isLayoutControlOpen}
                            setIsLayoutControlOpen={setIsLayoutControlOpen}
                            globalSearch={globalSearch}
                            setGlobalSearch={setGlobalSearch}
                            columnFilters={columnFilters}
                            setColumnFilters={setColumnFilters}
                            setHiddenFields={setHiddenFields}
                            deleteEmployee={() => 1}
                            deleteEmployeeByIDs={() => 1}
                            hiddenFields={hiddenFields ? hiddenFields : []}
                            orgDataList={[]}
                            templateList={[]}
                            setCurrentView={setCurrentView}
                            setIsEdit={setIsEdit}
                            isEditing={isEditing}
                            localModuleId={0}
                            total={itemCount}
                            changePage={changePage}
                            setCurrentPage={setCurrentPage}
                            currentPage={currentPage}
                            setIsTemplate={setIsTemplate}
                            setCols={setCols}
                            visArray={visArray}
                        />
                    </div>
                </animated.div>
            </div>
        </div>
    );
}
export default PerformanceAppraisal;
