import { ColumnDef, ColumnFiltersState, PaginationState, RowSelectionState, SortingState, createColumnHelper } from '@tanstack/react-table';
import React, { Dispatch, FC, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import EditableCell from '../Tables/EditableCell';
import { baseObj } from '../../utils/constants';
import { renderDateFormatDMY, renderDateFormatMY } from '../DashboardCalendarView/DashboardAddEventPopup';
import DatePopup from '../EmployeeTable/DatePopup';
import classNames from 'classnames';
import { CancelIcon, DownloadIcon, EditIcon, TableSaveIcon, TrashIcon, ViewIcon } from '../common/Icons';
import styles from './ExchangeRate.module.scss';
import Table from "../Tables/Tables.module.scss";
import { IOTData } from '../../ot-list/views/OTList';
import LayoutAdjustableTable from '../Tables/LayoutAdjustableTable';
import { TemplateData } from '../../app/services/employee';
import { templateData } from '../../app/services/template';
import TablePaginator from '../Tables/TablePaginator';
import DeletePopup from '../DeletePopup/DeletePopup';
import { toast } from 'react-toastify';
import CustomDropdownWithAddButton from '../EmployeeTable/CustomDropdownWithAddButton';
import axios from '../../axios';
import { endpointUrl } from '../../app/services/api';
import { currencyData, useUpdatedIncentiveMutation } from '../../app/services/employee-incentive';
import EditableCellV2 from '../Tables/EditableCellV2';
import { useCreateExchangeRateMutation, useUpdatedExchangeRateMutation } from '../../app/services/exchange-rate';
import DatePickerMY from '../EmployeeTable/DatePickerMY';
// import moment from 'moment';
export const statusList = [
    {
        id: 1,
        name: "Pending",
        label: "Pending",
    }
]
export const monthList = [
    { id: "January", name: "January", label: "January" },
    { id: "February", name: "February", label: "February" },
    { id: "March", name: "March", label: "March" },
    { id: "April", name: "April", label: "April" },
    { id: "May", name: "May", label: "May" },
    { id: "June", name: "June", label: "June" },
    { id: "July", name: "July", label: "July" },
    { id: "August", name: "August", label: "August" },
    { id: "September", name: "September", label: "September" },
    { id: "October", name: "October", label: "October" },
    { id: "November", name: "November", label: "November" },
    { id: "December", name: "December", label: "December" }
];
interface IChangeRateTable {
    isLayoutControlOpen: boolean;
    setIsLayoutControlOpen: Dispatch<SetStateAction<boolean>>;
    pagination: PaginationState;
    setPagination: Dispatch<SetStateAction<PaginationState>>;
    globalSearch: string;
    setGlobalSearch: Dispatch<SetStateAction<string>>;
    columnFilters: ColumnFiltersState;
    setColumnFilters: Dispatch<SetStateAction<ColumnFiltersState>>;
    orgDataList: IOTData[];
    data: baseObj[];
    setData: Dispatch<SetStateAction<baseObj[]>>;
    hiddenFields: string[];
    templateList: templateData[];
    setHiddenFields: any;
    setCurrentView: any;
    total: number;
    setCurrentPage: any;
    currentPage: number;
    setIsTemplate?: Dispatch<SetStateAction<boolean>>;
    localModuleId: number;
    setCols?: any;
    sorting: SortingState;
    setSorting: React.Dispatch<React.SetStateAction<SortingState>>;
    currencyList: any[];
    setCurrencyList: React.Dispatch<React.SetStateAction<any[]>>;
}
const ExchangeRateTable: FC<IChangeRateTable> = ({
    isLayoutControlOpen,
    setIsLayoutControlOpen,
    pagination,
    setPagination,
    globalSearch,
    setGlobalSearch,
    columnFilters,
    setColumnFilters,
    data,
    setData,
    orgDataList,
    hiddenFields,
    setHiddenFields,
    templateList,
    setCurrentView,
    total,
    setCurrentPage,
    currentPage,
    setIsTemplate,
    localModuleId,
    setCols,
    currencyList,
    setCurrencyList
}) => {
    const columnHelper = useMemo(() => createColumnHelper<any>(), []);
    const [sorting, setSorting] = useState<SortingState>([]);
    const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
    const [currency, setCurrency] = useState<string>("");
    const [activeRow, setActiveRow] = useState<number | 0>();
    const [deleteRowID, setDeleteRowID] = useState<number | 0>();
    const [openDeletePopup, setOpenDeleteopup] = useState<boolean>(false);
    const [itemCount, setItemCount] = useState<number>(total);
    const selectRowsCount = Object.keys(rowSelection).length;
    const [createExchangeRate] = useCreateExchangeRateMutation();
    const [updatedExchangeRate] = useUpdatedExchangeRateMutation();
    const [successPopup, setSuccessPopup] = useState<boolean>(false);
    const [errorPopup, setErrorPopup] = useState<boolean>(false);
    const [message, setMessage] = useState<any>([]);
    const [msg, setMsg] = useState<string>("");
    const [isShow, setIsShow] = useState<any>([{ id: 0, isShow: false }]);
    const saveTemplateData = (template: any) => {
        var views = columns
            .map((x) => {
                if (x.id) {
                    if (template[x.id] == undefined) {
                        return x.id;
                    }
                }
            })
            .filter((x) => x != undefined);
        var viewsData = views.map((x) => (x != undefined ? x : ""));
        var newTemplate: TemplateData = {
            id: 0,
            name: "View" + templateList.length + 1,
            viewColumns: viewsData,
        };
        setHiddenFields(viewsData);
        setCurrentView("");
    };
    const updatedExchangeRateData = useCallback(
        (id: any, updatedData: any, edited?: any) =>
            setData((prev: baseObj[]) => {
                return prev.map((data, index: number) => {
                    if (data.id == id) {
                        if (edited) {
                            return { ...data, [edited.cellId]: edited.value };
                        } else {
                            return { ...data, updatedData };
                        }

                    }
                    return data;
                });
            }),
        [setData]
    );
    const editExchangeRate = (edited: any) => {
        const existingData = data?.find(x => x.id == activeRow);
        const fieldValue = edited.value ? parseFloat(edited.value) : 0;
        var editedData = {
            "id": edited.id ? edited.id : existingData?.id,
            "currency_id": currency,
            "date": existingData?.date ? existingData?.date : new Date(),
            "month": existingData?.month,
            "rate": edited.cellId == "rate" ? fieldValue : parseFloat(existingData?.rate),
            "isNew": edited.id == 0 ? true : false
        }
        
        if (editedData.id != 0 && editedData.id != null) {
            updatedExchangeRateData(edited.id, existingData, edited)
            updatedExchangeRate(editedData).then((res: any) => {
                updatedExchangeRateData(res?.data?.data.id, res?.data?.data)
                setActiveRow(0);
            }).catch((error: any) => {
                
            })
        }
    }
    useEffect(() => {
        if (currency) {
            var editedData = {
                id: activeRow,
                cellId: '',
                value: ''
            }
            editExchangeRate(editedData)
        }
    }, [currency]);
    const columns = useMemo(
        () => [
            // columnHelper.accessor("currency_name", {
            //     header: "Currency",
            //     id: "currency_name",
            // }),
            columnHelper.accessor("currency_name", {
                header: "Currency",
                id: "currency_name",
                cell: ({ getValue, cell }) => {
                    const data = getValue();
                    var getView: any;
                    if (data) {
                        if (typeof (data) == "string") {
                            getView = currencyList?.find((x) => x.name == data);
                        } else {
                            getView = currencyList?.find((x) => x.id == data);
                        }
                    } else {
                        getView = currencyList[0]
                    }
                    const originalValues = cell.row.original?.role?.map(
                        (role: baseObj) => role.id
                    );
                    const isNew: boolean = cell.row.original?.isNew;
                    return (
                        <div
                            className={classNames(
                                "  text-left h-full",
                                styles.customEmployeeDropdown
                            )}
                        >
                            <EditableCellV2
                                setActiveRow={setActiveRow}
                                id={cell.row.original.id}
                                setDataChanged={editExchangeRate}
                                className="py-0 px-0"
                                hasPadding={false}
                                setData={setData}
                                value={getView ? getView?.name : "Select"}
                                type="custom"
                                cell={cell}
                                isShow={isShow}
                                setIsShow={setIsShow}
                                CustomComponent={
                                    <CustomDropdownWithAddButton
                                        isShowAdd={false}
                                        isBorder={false}
                                        isCurrency={true}
                                        isShowDelete={false}
                                        customHeight="min-h-[220px]"
                                        initialValues={originalValues}
                                        dataList={currencyList}
                                        setDataList={setCurrencyList}
                                        title=""
                                        cell={cell}
                                        data={data}
                                        setData={setData}
                                        setSelectedData={(value) => { setCurrency(value); setActiveRow(cell.row.original.id) }}
                                        selectedData={getView ? getView?.name : "Select"}
                                        setOpenDeleteopup={setOpenDeleteopup}
                                        isEditable={isNew}
                                        customClass="showArrow"
                                    />
                                }
                            />
                        </div>
                    );
                },
            }),
            columnHelper.accessor("date", {
                header: "Date",
                id: "date",
                cell: ({ getValue, cell }) => {
                    const joinedDate = getValue();
                    var joinDate = new Date();
                    if (joinedDate) {
                        joinDate = new Date(joinedDate);
                    }
                    var formatDate = renderDateFormatMY(joinDate, " ");
                    return (
                        <div className="max-w-[160px] min-w-[140px] text-left font-normal">
                            <EditableCellV2
                                id={cell.row.original.id}
                                setData={setData}
                                value={formatDate}
                                type="custom"
                                cell={cell}
                                setDataChanged={editExchangeRate}
                                setIsShow={setIsShow}
                                isShow={isShow}
                                setActiveRow={setActiveRow}
                                
                                CustomComponent={
                                    <DatePickerMY
                                        dobDate={joinDate}
                                        setDate={() => 1}
                                        cell={cell}
                                        data={data}
                                        setData={(value) => { setActiveRow(cell.row.original.id); setData(value); }}
                                    />
                                }
                            />
                        </div>
                    );
                },
            }),
            columnHelper.accessor("rate", {
                header: "Rate",
                id: "rate",
                cell: ({ getValue, cell }) => {
                    const noabsent = getValue();
                    return (
                        <div className="max-w-[160px] min-w-[140px] text-left font-normal">
                            <EditableCellV2
                                id={cell.row.original.id}
                                setData={(value) => { setData(value); }}
                                value={noabsent}
                                type="number"
                                cell={cell}
                                setDataChanged={editExchangeRate}
                                setIsShow={setIsShow}
                                isShow={isShow}
                                setActiveRow={setActiveRow}
                            />
                        </div>
                    );
                },
            }),
            // columnHelper.accessor("created_by_name", {
            //     header: "Created By",
            //     id: "created_by_name",
            //     cell: ({ getValue, cell }) => {
            //         const otAmt = getValue();
            //         return (
            //             <div className=" font-normal max-w-[180px] min-w-[100px] text-left px-[10px] bg-white text-black2">
            //                 <p className="h-full flex items-center">
            //                     {otAmt}
            //                 </p>
            //             </div>
            //         );
            //     },
            // }),
            // columnHelper.accessor("updated_at", {
            //     header: "Updated At",
            //     id: "updated_at",
            //     cell: ({ getValue, cell }) => {
            //         const otAmt = getValue();
            //         return (
            //             <div className=" font-normal max-w-[180px] min-w-[100px] text-left px-[10px] bg-white text-black2">
            //                 <p className="h-full flex items-center">
            //                     {otAmt}
            //                 </p>
            //             </div>
            //         );
            //     },
            // }),
        ],
        [
            columnHelper,
            data,
            hiddenFields,
        ]
    );
    const visibleColumns: { [key: string]: boolean } = {};

    hiddenFields?.map((x) => {
        visibleColumns[x] = true;
    });
    const [columnOrder, setColumnOrder] = useState<string[]>(
        columns.map((column: ColumnDef<any, any>) => column.id as string)
    );
    hiddenFields?.map((x) => {
        visibleColumns[x] = true;
    });

    if (hiddenFields.length > 0) {
        columns.map((x) => {
            if (x.id == "actions" || x.id == "selection" || x.id == "lasted") {
                visibleColumns[x.id ? x.id : ""] = true;
            } else if (x.id == "uniqeID") {
                visibleColumns[x.id ? x.id : ""] = false;
            } else if (x.id?.includes("none")) {
                visibleColumns[x.id ? x.id : ""] = true;
            } else {
                var isHidden = hiddenFields.find((h) => h == x.id);
                if (isHidden) {
                    visibleColumns[x.id ? x.id : ""] = true;
                } else {
                    visibleColumns[x.id ? x.id : ""] = false;
                }
            }
        });
    }
    return <div className={classNames(styles.payRollTable, 'relative')}>
        <LayoutAdjustableTable
            saveTemplateData={saveTemplateData}
            hideColumn={visibleColumns}
            columns={columns}
            data={data}
            sorting={sorting}
            setSortingState={setSorting}
            rowSelection={rowSelection}
            setRowSelection={setRowSelection}
            columnOrder={columnOrder}
            setColumnOrder={setColumnOrder}
            unDraggableFields={["actions", "selection", "id"]}
            theaderColor="text-graydark"
            isLayoutControlOpen={isLayoutControlOpen}
            setIsLayoutControlOpen={setIsLayoutControlOpen}
            label="OT List"
            setItemCount={setItemCount}
            pagination={pagination}
            setPagination={setPagination}
            globalFilter={globalSearch}
            setGlobalFilter={setGlobalSearch}
            columnFilters={columnFilters}
            setColumnFilters={setColumnFilters}
            noPaddingCells={[
                "currency_name",
                "Currency"
            ]}
            localModuleId={localModuleId}
            setIsTemplate={setIsTemplate}
            setCols={setCols}

        />
        <div className="pt-6">
            <TablePaginator
                total={itemCount}
                pagination={pagination}
                setPagination={setPagination}
                itemCount={total}
                setCurrentPage={setCurrentPage}
                currentPageDefault={currentPage}
            />
        </div>
    </div>
}
export default ExchangeRateTable;