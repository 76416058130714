import React from "react";
import { Link } from "react-router-dom";
import styles from "./DashboardActivities.module.scss";
import annoarrow from "../../components/DashboardAnnoucement/img/anno-arrow.svg";
import profileImage from "./img/profile-image.png";
import avatar1 from "./img/avatar-1.jpg";
import avatar2 from "./img/avatar-2.jpg";
import avatar3 from "./img/avatar-3.jpg";
import avatar4 from "./img/avatar-4.jpg";
import CustomScrollbar from "../CustomScrollbar/CustomScrollbar";
var classNames = require("classnames");

const activitiestitle = "Recent Activities";
const data = [
  {
    avatar: `${profileImage}`,
    name: "William Cili",
    time: "3m ago",
    message:
      "Outlines keep you honest. Indulging in poorly driving and keep structure Outlines keep you honest. Indulging in poorly driving and keep structure",
    date: "Today 7:51 PM",
  },
  {
    avatar: `${profileImage}`,
    name: "Margret Cate",
    time: "3m ago",
    message:
      "Outlines keep you honest. Indulging in poorly driving and keep structure",
    date: "Today 7:51 PM",
  },
  {
    avatar: `${profileImage}`,
    name: "Margret Cate",
    time: "3m ago",
    message:
      "Outlines keep you honest. Indulging in poorly driving and keep structure",
    date: "Today 7:51 PM",
  },
  {
    avatar: `${profileImage}`,
    name: "Margret Cate",
    time: "3m ago",
    message:
      "Outlines keep you honest. Indulging in poorly driving and keep structure",
    date: "Today 7:51 PM",
  },
  {
    avatar: `${profileImage}`,
    name: "William Cili",
    time: "3m ago",
    message:
      "Outlines keep you honest. Indulging in poorly driving and keep structure",
    date: "Today 7:51 PM",
  },
  {
    avatar: `${profileImage}`,
    name: "Margret Cate",
    time: "3m ago",
    message:
      "Outlines keep you honest. Indulging in poorly driving and keep structure",
    date: "Today 7:51 PM",
  },
  {
    avatar: `${profileImage}`,
    name: "Margret Cate",
    time: "3m ago",
    message:
      "Outlines keep you honest. Indulging in poorly driving and keep structure",
    date: "Today 7:51 PM",
  },
  {
    avatar: `${profileImage}`,
    name: "Margret Cate",
    time: "3m ago",
    message:
      "Outlines keep you honest. Indulging in poorly driving and keep structure",
    date: "Today 7:51 PM",
  },
];

const DashboardActivities = (props: any) => {

  return (
    <>
      <div
        className={classNames(
          styles.activiesContainer,
          "py-[25px] bg-white  overflow-hidden h-full min-h-[410px]"
        )}
      >
        {/* h-[465px] */}
        <div
          className={classNames(
            "flex items-center justify-between mb-[24px] px-[20px] "
          )}
        >
          <p
            className={classNames(
              styles.activeTitle,
              "font-primary text-black2 text-14 xl:text-18 font-semibold"
            )}
          >
            Recent Activities
          </p>
          {props.activityPermis ? (
            <Link to="activity_log">
              <button
                className={classNames(
                  styles.button,
                  "bg-offwhite flex items-center justify-center rounded-md w-9 h-9"
                )}
                aria-label=""
              >
                <img src={annoarrow} alt="annoarrow-icon" />
              </button>
            </Link>
          ) : null}
        </div>
        <div
          className={classNames(
            "h-full pl-5 pr-2 lg:max-h-[450px] max-h-[300px]"
          )}
        >
          <CustomScrollbar isVertical={true}>
            <ul className="pr-5">
              {props.activities &&
                props.activities.map((d: any, index: any) => {
                  return (
                    <li
                      className={classNames(
                        styles.avatarImage,
                        "flex flex-row xlhtz:flex-row mb-[10px] last:mb-0 pr-4"
                      )}
                      key={`activies-${index}`}
                    >
                      {/* <img
                        src={d.causer_image}
                        alt="avatar img"
                        className={classNames(
                          styles.chater,
                          "w-[30px] h-[30px] rounded-full object-cover bg-white p-[4px] xl:mr-[20px] mr-2"
                        )}
                      /> */}
                      {d.causer_image ? (
                        <img
                          src={d.causer_image}
                          alt={`Person ${index}`}
                          width={34}
                          height={34}
                          className="min-w-[34px] rounded-full overflow-hidden w-[34px] h-[34px] mr-3"
                        />
                      ) : (
                        <div className="relative bg-yellowBright rounded-full overflow-hidden min-w-[34px] w-[34px] h-[34px] mr-3">
                          <p className="text-white font-bold text-13 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 min-w-[30px] ">
                            {d.causer ? d.causer.slice(0, 1) : ""}
                          </p>
                        </div>
                      )}

                      <div className="flex flex-col w-full">
                        <div className="xl:flex lg:block flex flex-row flex-wrap xlhtz:flex-row justify-between items-center w-full">
                          <p className="text-left text-black2 text-12 2xl:text-15 font-primary font-medium mr-2">
                            {d.causer}
                          </p>
                          <p className="text-left text-graydark text-10 2xl:text-12 font-primary">
                            {d.event}
                          </p>
                        </div>
                        <div className="text-left mt-[5px]">
                          <p
                            className={classNames(
                              styles.message,
                              "text-black2 text-10 2xl:text-12 font-primary"
                            )}
                          >
                            {d.description}
                          </p>
                        </div>
                        <div className="text-left mt-[5px]">
                          <p className="text-graydark text-10 2xl:text-12 font-primary">
                            {d.created_at}
                          </p>
                        </div>
                      </div>
                    </li>
                  );
                })}
            </ul>
          </CustomScrollbar>
        </div>
      </div>
    </>
  );
};
export default DashboardActivities;
