import authHeader from "./auth-header";
import { api, endpointUrl } from "./api";
import { redirectToLogin } from "./annoucement-sheet";
import { TeamLeadData } from "./progress-checker-acc";

// -----------------------------------
export type TProfileDailyListResponse = {
  status: boolean;
  project_list: TProjectListDailyReport[];
  task_categories: TTaskTagsList[];
  daily_report_list: TObjDailyReportList;
  total_time: string;
};

export type TTaskTagsList = {
  id: number;
  name: string;
};

export type TProjectListDailyReport = {
  id: number;
  name: string;
  type: string;
};

export type TObjDailyReportList = {
  current_page: number;
  data: TObjeDaily[];
  total: number;
};

export type TObjeDaily = {
  id: number;
  project_id: number;
  project_name: string;
  task_name: string;
  task_id: number;
  task_category_name: string;
  task_category_id: number;
  date: string;
  time_spent: string;
  time_spent_text: string;
};

export type TRequestDailyRerpot = {
  id?: number;
  limit?: number | string;
  page?: number | string;
  project_id: number;
  type: string;
  task_category_id: number;
  status?: number | string;
  start?: string;
  end?: string;
  limited?: string;
  sorting?: string;
  month?: string;
  employee_id: number;
};
export type IKeyValueData = {
  "id": number,
  "name": string,
  "type": string,
}
export type IRefailData = {
  "date": string,
  "day_number": string,
  "daily_report": IDailyReportDataItems,
  "can_refill": boolean,
  "total_time": string,
  "total_time_text": string,
  "hours": number,
  "minutes": number
}
export type ProfileDailyReportFilter = {
  project_list: IKeyValueData[];
  task_categories: IKeyValueData[];
  same_department_users: TeamLeadData[];
  is_leader: boolean;
  dates: string[],
  previous_data: IDailyReportData[];
  refill_data: IRefailData[],
  pending_refill_requests_count: number
  refill_dates: IRefillDateObj[]
}
export type IRefillDateObj = {
  date: string,
  can_refill: boolean,
}
export type IDailyReportDataDetail = {
  "id": number,
  "code": string,
  "daily_report_id": number,
  "project_id": number,
  "task_id": number,
  "task_category_id": number,
  "type": string,
  "project_name": string,
  "task_name": string,
  "task_category_name": string,
  "is_previous": number,
  "time_spent": string,
  "time_spent_text": string
}
export type IDailyReportDataItems = {
  "id": number,
  "employee_id": number,
  "date": string,
  "name": string,
  "items": IDailyReportDataDetail[]
}
export type IDailyReportData = {
  "date": string,
  "day_number": string,
  "daily_report": IDailyReportDataItems,
  "can_refill": true,
  "total_time": string,
  "total_time_text": string,
  "hours": number,
  "minutes": number
}
export type IProfileMemberListData = {
  data: IProfileMemberList[]
}
export type IProfileMemberList = {
  "id": number
  "name": string,
  "department_id": number,
  "working_status": string,
  "time": string,
  "photo": string,
}
export type filterInput = {
  search?: string,
  department_id?: number,
  status?: string
}
export type IRefillReport = {
  "status": boolean,
  "pending_refill_requests_count": number,
  "refill_request_list": RefillRequestList
}
export type IMyRefillReport = {
  "status": boolean,
  "my_refill_request_list": RefillRequestList
}
export type RefillRequestListDataItems = {
  "id": number,
  "code": string,
  "daily_report_id": number,
  "project_id": number,
  "task_id": number,
  "task_category_id": number,
  "type": string,
  "project_name": string,
  "task_name": string,
  "task_category_name": string,
  "is_previous": number,
  "is_need_to_create_new": number,
  "time_spent": string,
  "time_spent_text": string
}
export type RefillRequestListData = {
  "id": number,
  "employee_id": number,
  "employee_code": string,
  "refilled_at": string,
  "date": string,
  "day_format": string,
  "reason": string,
  "approve_option": boolean,
  "reject_option": boolean,
  "total_time_spent_text": string,
  "items": RefillRequestListDataItems[]
}
export type RefillRequestList = {
  "current_page": number,
  total: number,
  "data": RefillRequestListData[]
}
export const profileDailyReportApi = api.injectEndpoints({
  endpoints: (build) => ({
    getProfileDailyReportList: build.query<
      TProfileDailyListResponse,
      Partial<TRequestDailyRerpot>
    >({
      query: ({
        sorting,
        page,
        limit,
        start,
        end,
        project_id,
        type,
        task_category_id,
        employee_id
      }) => ({
        url: `${endpointUrl}profile/daily-reports?page=${page}&limit=${limit}&start=${start}&end=${end}&type=${type}&project_id=${project_id}&task_category_id=${task_category_id}&sort=${sorting}&employee_id=${employee_id}`,
        headers: authHeader(),
        method: "GET",
      }),
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
      providesTags: () => [{ type: "ProfileDailyReportPopup", id: "LIST" }],
    }),
    getProfileDailyReportRefillList: build.query<
      IRefillReport,
      Partial<TRequestDailyRerpot>
    >({
      query: ({
        sorting,
        page,
        limit,
        start,
        end,
        project_id,
        type,
        task_category_id,
        employee_id
      }) => ({
        url: `${endpointUrl}profile/daily-reports/member-refill-requests?page=${page}&limit=${limit}&refill_start=${start}&refill_end=${end}&sort=${sorting}`,
        headers: authHeader(),
        method: "GET",
      }),
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
      providesTags: () => [{ type: "ProfileDailyReportRefillPopup", id: "LIST" }],
    }),
    getProfileMyDailyReportRefillList: build.query<
      IMyRefillReport,
      Partial<TRequestDailyRerpot>
    >({
      query: ({
        sorting,
        page,
        limit,
        start,
        end,
        project_id,
        type,
        task_category_id,
        employee_id
      }) => ({
        url: `${endpointUrl}profile/daily-reports/refill-requests?page=${page}&limit=${limit}&refill_start=${start}&refill_end=${end}&sort=${sorting}`,
        headers: authHeader(),
        method: "GET",
      }),
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
      providesTags: () => [{ type: "ProfileDailyReportRefillPopup", id: "LIST" }],
    }),
    getProfileDailyReportFilterData: build.query<ProfileDailyReportFilter, void>({
      query: () => ({ url: `profile/daily-reports/filter-data`, headers: authHeader() }),
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
      providesTags: (result: any = []) => {
        if (result.data) {
          if (result.data.length > 0) {
            return [
              ...result.data.map((id: any) => ({ type: "DailyReportFilterData", id } as const)),
              { type: "DailyReportFilterData" as const, id: "LIST" },
            ]
          }
          else {
            return [{ type: "DailyReportFilterData" as const, id: "LIST" },]
          }
        }
        else {
          return [{ type: "DailyReportFilterData" as const, id: "LIST" },]
        }

      },
    }),
    getMemberList: build.query<IProfileMemberListData, filterInput>({
      query: ({ search, department_id, status }) => ({ url: `profile/member-lists?search=${search}&department_id=${department_id}&working_status=${status}`, headers: authHeader() }),
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
      providesTags: (result: any = []) => {
        if (result.data) {
          if (result.data.length > 0) {
            return [
              ...result.data.map((id: any) => ({ type: "MemberList", id } as const)),
              { type: "MemberList" as const, id: "LIST" },
            ]
          }
          else {
            return [{ type: "MemberList" as const, id: "LIST" },]
          }
        }
        else {
          return [{ type: "MemberList" as const, id: "LIST" },]
        }

      },
    }),
    approveRefillReport: build.mutation<any, number>({
      query: (body) => (
        {
          url: `${endpointUrl}profile/daily-reports/member-refill-requests/${body}/approve`,
          method: 'POST',
          // body,
          headers: authHeader(),
        }),
      // invalidatesTags: (approvee) => [{ type: 'LeaveDataList', id: "LIST" }],
    }),
    rejectRefillReport: build.mutation<any, number>({
      query: (body) => ({
        url: `${endpointUrl}profile/daily-reports/member-refill-requests/${body}/reject`,
        method: 'POST',
        body,
        headers: authHeader()
      }),
      // invalidatesTags: (permissions) => [{ type: 'LeaveDataList', id: "LIST" }],
    }),
  }),
});

export const { useGetProfileDailyReportListQuery, useGetProfileDailyReportFilterDataQuery, useGetMemberListQuery,
  useGetProfileDailyReportRefillListQuery, useApproveRefillReportMutation, useRejectRefillReportMutation, useGetProfileMyDailyReportRefillListQuery } = profileDailyReportApi;

export const {
  endpoints: { getProfileDailyReportList, getProfileDailyReportFilterData, getMemberList, getProfileDailyReportRefillList,
    approveRefillReport, rejectRefillReport, getProfileMyDailyReportRefillList },
} = profileDailyReportApi;
