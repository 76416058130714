// import { Modal } from "@material-ui/core";
import { Modal } from "@mui/material"
import classNames from "classnames";
import React, {
  // createRef,
  Dispatch,
  FC,
  SetStateAction,
  // useEffect,
  // useRef,
  // useState,
} from "react";
import { CloseIcon, SearchIcon } from "../common/Icons";
import styles from "./SuccessPopup.module.scss";

interface ICreateData {
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  descText?: string;
  confirmBtnText?: string;
  message?: any;
  setMessage?: any;
}
const SuccessPopup: FC<ICreateData> = ({ show, setShow, descText, confirmBtnText, message, setMessage }) => {
  // const handleConfirm = () => {
  //   setShow(false);
  //   setMessage("")
  // }
  // console.log("suc", message)

  return (
    <div>
      <Modal open={show} onClose={() => setShow(false)}  disableEnforceFocus={true}>
        <div>
          <div className={classNames("z-10 fixed top-0 left-0 w-full h-full")}>
            <div
              className={classNames(
                "relative z-10 top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 md:max-w-[320px] max-w-[300px] bg-[#fff] rounded-xl"
              )}
            >
              <div className="relative text-center px-12 pt-[10px] rounded-tl-xl rounded-tr-xl">
                <span
                  onClick={() => setShow(false)}
                  className={classNames(styles.popupClose, "text-white text-20 cursor-pointer absolute top-[15px] right-[15px]")}
                >
                  <CloseIcon />
                </span>
              </div>
              <div className="min-h-[60px] flex justify-center items-center">
                {
                  message.length > 0 ? message?.map((m: any) => (
                    <p key={Math.random() * 1000} className="block text-center font-poppins font-medium text-sm text-green-400">
                      {m}
                    </p>
                  ))
                    : ""
                }
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};
export default SuccessPopup;
