import {
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useEffect,
  useState,
} from "react";

interface IDebounceSearchInput {
  children: (prop: Dispatch<SetStateAction<string>>) => ReactNode;
  setState: Dispatch<SetStateAction<string>>;
  debounce?: number;
}

const DebounceSearchInput: FC<IDebounceSearchInput> = ({
  setState,
  debounce = 1000,
  children,
}) => {
  const [value, setValue] = useState<string>("");
  useEffect(() => {
    const timeout = setTimeout(() => setState(value), debounce);
    return () => clearTimeout(timeout);
  }, [value, debounce]);


  return <>{children(setValue)}</>;
};

export default DebounceSearchInput;
