import { ColumnDef, ColumnFiltersState, PaginationState, RowSelectionState, SortingState, createColumnHelper } from '@tanstack/react-table';
import React, { Dispatch, FC, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { baseObj } from '../../utils/constants';
import classNames from 'classnames';
import styles from './EmployeeIncentiveTable.module.scss';
import Table from "../Tables/Tables.module.scss";
import { IOTData } from '../../ot-list/views/OTList';
import LayoutAdjustableTable from '../Tables/LayoutAdjustableTable';
import { TemplateData } from '../../app/services/employee';
import { templateData } from '../../app/services/template';
import TablePaginator from '../Tables/TablePaginator';
import { currencyData, useUpdatedIncentiveMutation } from '../../app/services/employee-incentive';
import EditableCellV2 from '../Tables/EditableCellV2';
import { ToastContainer, toast } from 'react-toastify';
export const statusList = [
    {
        id: 1,
        name: "Pending",
        label: "Pending",
    }
]
interface IIncentiveTable {
    isLayoutControlOpen: boolean;
    setIsLayoutControlOpen: Dispatch<SetStateAction<boolean>>;
    pagination: PaginationState;
    setPagination: Dispatch<SetStateAction<PaginationState>>;
    globalSearch: string;
    setGlobalSearch: Dispatch<SetStateAction<string>>;
    columnFilters: ColumnFiltersState;
    setColumnFilters: Dispatch<SetStateAction<ColumnFiltersState>>;
    orgDataList: IOTData[];
    data: baseObj[];
    setData: Dispatch<SetStateAction<baseObj[]>>;
    hiddenFields: string[];
    templateList: templateData[];
    setHiddenFields: any;
    setCurrentView: any;
    total: number;
    setCurrentPage: any;
    currentPage: number;
    setIsTemplate?: Dispatch<SetStateAction<boolean>>;
    localModuleId: number;
    setCols?: any;
    sorting: SortingState;
    setSorting: React.Dispatch<React.SetStateAction<SortingState>>;
    currencyList: any[];
    setCurrencyList: React.Dispatch<React.SetStateAction<any[]>>;
}
const EmployeeIncentiveTable: FC<IIncentiveTable> = ({
    isLayoutControlOpen,
    setIsLayoutControlOpen,
    pagination,
    setPagination,
    globalSearch,
    setGlobalSearch,
    columnFilters,
    setColumnFilters,
    data,
    setData,
    orgDataList,
    hiddenFields,
    setHiddenFields,
    templateList,
    setCurrentView,
    total,
    setCurrentPage,
    currentPage,
    setIsTemplate,
    localModuleId,
    setCols,
    currencyList,
    setCurrencyList,
    sorting,
    setSorting
}) => {
    const columnHelper = useMemo(() => createColumnHelper<any>(), []);
    const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
    const [currency, setCurrency] = useState<string>("");
    const [activeRow, setActiveRow] = useState<number | 0>();
    const [deleteRowID, setDeleteRowID] = useState<number | 0>();
    const [openDeletePopup, setOpenDeleteopup] = useState<boolean>(false);
    const [itemCount, setItemCount] = useState<number>(total);
    const selectRowsCount = Object.keys(rowSelection).length;
    const [updatedIncentive] = useUpdatedIncentiveMutation();
    const [successPopup, setSuccessPopup] = useState<boolean>(false);
    const [errorPopup, setErrorPopup] = useState<boolean>(false);
    const [message, setMessage] = useState<any>([]);
    const [msg, setMsg] = useState<string>("");
    const [isShow, setIsShow] = useState<any>([{ id: 0, isShow: false }]);
    const saveTemplateData = (template: any) => {
        var views = columns
            .map((x) => {
                if (x.id) {
                    if (template[x.id] == undefined) {
                        return x.id;
                    }
                }
            })
            .filter((x) => x != undefined);
        var viewsData = views.map((x) => (x != undefined ? x : ""));
        var newTemplate: TemplateData = {
            id: 0,
            name: "View" + templateList.length + 1,
            viewColumns: viewsData,
        };
        setHiddenFields(viewsData);
        setCurrentView("");
    };
    const updatedIncentiveData = useCallback(
        (id: any, updatedData: any, edited?: any) =>
            setData((prev: baseObj[]) => {
                return prev.map((data, index: number) => {
                    if (data.id == id) {
                        if (edited) {
                            return { ...data, [edited.cellId]: edited.value };
                        } else {
                            return { ...data, updatedData };
                        }

                    }
                    return data;
                });
            }),
        [setData]
    );
    const editIncentive = (edited: any) => {
        const existingData = data?.find(x => x.id == edited.id);
        const fieldValue = edited.value ? parseFloat(edited.value) : 0;
        var editedData = {
            "id": edited.id,
            "currency_id": existingData?.currency_id,
            "internet_incentive": edited.cellId == "internet_incentive" ? fieldValue : parseFloat(existingData?.internet_incentive),
            "no_absent_incentive": edited.cellId == "no_absent_incentive" ? fieldValue : parseFloat(existingData?.no_absent_incentive),
            "no_lateness_incentive": edited.cellId == "no_lateness_incentive" ? fieldValue : parseFloat(existingData?.no_lateness_incentive),
            "work_quality_rewards_tier_1": edited.cellId == "work_quality_rewards_tier_1" ? fieldValue : parseFloat(existingData?.work_quality_rewards_tier_1),
            "work_quality_rewards_tier_2": edited.cellId == "work_quality_rewards_tier_2" ? fieldValue : parseFloat(existingData?.work_quality_rewards_tier_2),
            "other_rewards": edited.cellId == "other_rewards" ? fieldValue : parseFloat(existingData?.other_rewards),
            "allowance": edited.cellId == "allowance" ? fieldValue : parseFloat(existingData?.allowance),
            "description": edited.cellId == "allowance" ? fieldValue : existingData?.description,
            //"status": 1
        }
        if (editedData.id != 0) {
            updatedIncentiveData(edited.id, existingData, edited)
            updatedIncentive(editedData).then((res: any) => {
                updatedIncentiveData(res?.data?.data.id, res?.data?.data)
                toast(res?.data?.message, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    type: "success",
                });
            }).catch((error: any) => {
                console.log("error ", error)
                toast('Error', {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    type: "success",
                });
            })
        }
    }
    useEffect(() => {
        if (currency) {
            var editedData = {
                id: activeRow,
                cellId: '',
                value: ''
            }
            editIncentive(editedData)
        }
    }, [currency]);
    const encodeAlphabet = (alphabet: string) => {
        if (alphabet) {
            return alphabet.toString().replace(/[a-zA-Z0-9]/g, "*");
        } else {
            return "";
        }
    };
    const columns = useMemo(
        () => [
            columnHelper.accessor("employee_code", {
                header: "Employee Code",
                id: "employee_code",
                cell: ({ getValue, cell }) => {
                    const employeeID = getValue();
                    return (
                        <div className="max-w-[160px] min-w-[140px] text-left bg-white text-black2 px-[10px] font-normal">
                            <p className="h-full flex items-center">{employeeID}</p>
                        </div>
                    );
                },
            }),
            columnHelper.accessor("employee_name", {
                header: "Employee Name",
                id: "employee_name",

                cell: ({ getValue, cell }) => {
                    const employeeID = getValue();
                    return (
                        <div className="max-w-[160px] min-w-[140px] text-left bg-white text-black2 px-[10px] font-normal">
                            <p className="h-full flex items-center">{employeeID}</p>
                        </div>
                    );
                },
            }),
            columnHelper.accessor("currency_name", {
                header: "Currency",
                id: "currency_name",
                cell: ({ getValue, cell }) => {
                    const data = getValue();
                    return (
                        <div className="max-w-[160px] min-w-[140px] text-left bg-white text-black2 px-[10px] font-normal">
                            <p className="h-full flex items-center">{data}</p>
                        </div>
                    );
                },
            }),
            columnHelper.accessor("internet_incentive", {
                header: "Internet Incentive",
                id: "internet_incentive",
                enableSorting:false,
                cell: ({ getValue, cell }) => {
                    const internet = getValue();
                    return (
                        <div className="max-w-[160px] min-w-[140px] text-left font-normal">
                            <EditableCellV2
                                id={cell.row.original.id}
                                setData={setData}
                                value={internet}
                                code={cell.row.original.currency_code}
                                type="number"
                                cell={cell}
                                setDataChanged={editIncentive}
                                setIsShow={setIsShow}
                                isShow={isShow}
                            />
                        </div>
                    );
                },
            }),
            columnHelper.accessor("no_absent_incentive", {
                header: "No Absent Incentive",
                id: "no_absent_incentive",
                enableSorting:false,
                cell: ({ getValue, cell }) => {
                    const noabsent = getValue();
                    return (
                        <div className="max-w-[160px] min-w-[140px] text-left font-normal">
                            <EditableCellV2
                                id={cell.row.original.id}
                                setData={setData}
                                value={noabsent}
                                code={cell.row.original.currency_code}
                                type="number"
                                cell={cell}
                                setDataChanged={editIncentive}
                                setIsShow={setIsShow}
                                isShow={isShow}
                            />
                        </div>
                    );
                },
            }),
            columnHelper.accessor("no_lateness_incentive", {
                header: "No Lateness Incentive",
                id: "no_lateness_incentive",
                enableSorting:false,
                cell: ({ getValue, cell }) => {
                    const lateness = getValue();
                    return (
                        <div className="max-w-[160px] min-w-[140px] text-left font-normal">
                            <EditableCellV2
                                id={cell.row.original.id}
                                setData={setData}
                                value={lateness}
                                code={cell.row.original.currency_code}
                                type="number"
                                cell={cell}
                                setDataChanged={editIncentive}
                                setIsShow={setIsShow}
                                isShow={isShow}
                            />
                        </div>
                    );
                },
            }),
            columnHelper.accessor("work_quality_rewards_tier_1", {
                header: "Work Quality Rewards Tier1",
                id: "work_quality_rewards_tier_1",
                enableSorting:false,
                cell: ({ getValue, cell }) => {
                    const rewards1 = getValue();
                    return (
                        <div className="max-w-[160px] min-w-[140px] text-left font-normal">
                            <EditableCellV2
                                id={cell.row.original.id}
                                setData={setData}
                                value={rewards1}
                                code={cell.row.original.currency_code}
                                type="number"
                                cell={cell}
                                setDataChanged={editIncentive}
                                setIsShow={setIsShow}
                                isShow={isShow}
                            />
                        </div>
                    );
                },
            }),
            columnHelper.accessor("work_quality_rewards_tier_2", {
                header: "Work Quality Rewards Tier2",
                id: "work_quality_rewards_tier_2",
                enableSorting:false,
                cell: ({ getValue, cell }) => {
                    const rewards2 = getValue();
                    return (
                        <div className="max-w-[160px] min-w-[140px] text-left font-normal">
                            <EditableCellV2
                                id={cell.row.original.id}
                                setData={setData}
                                value={rewards2}
                                code={cell.row.original.currency_code}
                                type="number"
                                cell={cell}
                                setDataChanged={editIncentive}
                                setIsShow={setIsShow}
                                isShow={isShow}
                            />
                        </div>
                    );
                },
            }),
            columnHelper.accessor("other_rewards", {
                header: "Other Rewards",
                id: "other_rewards",
                enableSorting:false,
                cell: ({ getValue, cell }) => {
                    const rewards = getValue();
                    return (
                        <div className="max-w-[160px] min-w-[140px] text-left font-normal">
                            <EditableCellV2
                                id={cell.row.original.id}
                                setData={setData}
                                value={rewards}
                                code={cell.row.original.currency_code}
                                type="number"
                                cell={cell}
                                setDataChanged={editIncentive}
                                setIsShow={setIsShow}
                                isShow={isShow}
                            />
                        </div>
                    );
                },
            }),
            columnHelper.accessor("allowance", {
                header: "Allowance",
                id: "allowance",
                enableSorting:false,
                cell: ({ getValue, cell }) => {
                    const allowance = getValue();
                    return (
                        <div className="max-w-[160px] min-w-[140px] text-left font-normal">
                            <EditableCellV2
                                id={cell.row.original.id}
                                setData={setData}
                                value={allowance}
                                code={cell.row.original.currency_code}
                                type="number"
                                cell={cell}
                                setDataChanged={editIncentive}
                                setIsShow={setIsShow}
                                isShow={isShow}
                            />
                        </div>
                    );
                },
            }),
            // columnHelper.accessor("description", {
            //     header: "Description",
            //     id: "description",
            //     cell: ({ getValue, cell }) => {
            //         const description = getValue();
            //         return (
            //             <div className="max-w-[160px] min-w-[140px] text-left font-normal">
            //                 <EditableCellV2
            //                     id={cell.row.original.id}
            //                     setData={setData}
            //                     value={description}
            //                     type="text"
            //                     cell={cell}
            //                     setDataChanged={editIncentive}
            //                     setIsShow={setIsShow}
            //                     isShow={isShow}                                
            //                 />
            //             </div>
            //         );
            //     },
            // }),
            // columnHelper.accessor("status", {
            //     header: "Status",
            //     id: "status",
            //     cell: ({ getValue, cell }) => {
            //         const otAmt = getValue();
            //         return (
            //             <div className=" font-normal max-w-[180px] min-w-[100px] text-left px-[10px] bg-white text-black2">
            //                 <p className="h-full flex items-center">
            //                     {otAmt}
            //                 </p>
            //             </div>
            //         );
            //     },
            // }),
            // columnHelper.accessor("created_by_name", {
            //     header: "Created By",
            //     id: "created_by_name",
            //     cell: ({ getValue, cell }) => {
            //         const otAmt = getValue();
            //         return (
            //             <div className=" font-normal max-w-[180px] min-w-[100px] text-left px-[10px] bg-white text-black2">
            //                 <p className="h-full flex items-center">
            //                     {otAmt}
            //                 </p>
            //             </div>
            //         );
            //     },
            // }),
            // columnHelper.accessor("updated_at", {
            //     header: "Updated At",
            //     id: "updated_at",
            //     cell: ({ getValue, cell }) => {
            //         const otAmt = getValue();
            //         return (
            //             <div className=" font-normal max-w-[180px] min-w-[100px] text-left px-[10px] bg-white text-black2">
            //                 <p className="h-full flex items-center">
            //                     {otAmt}
            //                 </p>
            //             </div>
            //         );
            //     },
            // }),
        ],
        [
            columnHelper,
            data,
            hiddenFields,
        ]
    );
    const visibleColumns: { [key: string]: boolean } = {};

    hiddenFields?.map((x) => {
        visibleColumns[x] = true;
    });
    const [columnOrder, setColumnOrder] = useState<string[]>(
        columns.map((column: ColumnDef<any, any>) => column.id as string)
    );
    hiddenFields?.map((x) => {
        visibleColumns[x] = true;
    });

    if (hiddenFields.length > 0) {
        columns.map((x) => {
            if (x.id == "actions" || x.id == "selection" || x.id == "lasted") {
                visibleColumns[x.id ? x.id : ""] = true;
            } else if (x.id == "uniqeID") {
                visibleColumns[x.id ? x.id : ""] = false;
            } else if (x.id?.includes("none")) {
                visibleColumns[x.id ? x.id : ""] = true;
            } else {
                var isHidden = hiddenFields.find((h) => h == x.id);
                if (isHidden) {
                    visibleColumns[x.id ? x.id : ""] = true;
                } else {
                    visibleColumns[x.id ? x.id : ""] = false;
                }
            }
        });
    }
    return <div className={classNames(styles.payRollTable, 'relative inline-block max-w-full')}>
        <ToastContainer className={"text-12 font-poppins font-normal"} />
        <LayoutAdjustableTable
            saveTemplateData={saveTemplateData}
            hideColumn={visibleColumns}
            columns={columns}
            data={data}
            sorting={sorting}
            setSortingState={setSorting}
            rowSelection={rowSelection}
            setRowSelection={setRowSelection}
            columnOrder={columnOrder}
            setColumnOrder={setColumnOrder}
            unDraggableFields={["actions", "selection", "id"]}
            theaderColor="text-graydark"
            isLayoutControlOpen={isLayoutControlOpen}
            setIsLayoutControlOpen={setIsLayoutControlOpen}
            label="Employee Incentive List"
            setItemCount={setItemCount}
            pagination={pagination}
            setPagination={setPagination}
            globalFilter={globalSearch}
            setGlobalFilter={setGlobalSearch}
            columnFilters={columnFilters}
            setColumnFilters={setColumnFilters}
            noPaddingCells={[
                "currency_name",
                "Currency"
            ]}
            localModuleId={localModuleId}
            setIsTemplate={setIsTemplate}
            setCols={setCols}

        />
        <div className="pt-6">
            <TablePaginator
                total={itemCount}
                pagination={pagination}
                setPagination={setPagination}
                itemCount={total}
                setCurrentPage={setCurrentPage}
                currentPageDefault={currentPage}
            />
        </div>
    </div>
}
export default EmployeeIncentiveTable;