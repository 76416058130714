import { redirectToLogin } from './annoucement-sheet';
import { api } from './api';
import authHeader from './auth-header';
import { Ids } from './media';
export type DocumentResponse = { data: DocumentTableData[] };

type DocumentTableData = {
    docData: docData[]
}
type DocumentTableFilterData = {
    folderList:[];
}
type docData = {
    id: number,
    filepath: string,
    name: string,
    tag: {},
    owner: string,
    path: string,
    lastModified: string,
    postedBy: string,
    isImportant: boolean,
}
type assignUserData = {
    user_ids: number[],
    id: number
}
type assignOwnerData = {
    owner_id: number,
    id: number
}
type assigsTagsData = {
    tag_ids: number[],
    id: number
}
type assignUserMultipleData = {
    user_ids: number[],
    document_ids: number[]
}
type deleteMultipleDoc = {
    ids: number[]
}
type tagDoc = {
    id: number,
    name: string,
    flag: string,
}
type tagDocData = {
    tagsData: tagDoc[]
}
export const ImageFormat = ["png", "jpeg", 'jpg', 'svg', 'jfif', 'pjpeg', 'webp', 'pjp', 'bmp', 'ico', 'tif'];
export const DocFiles = ["png", "jpeg", 'jpg', 'svg', 'jfif', 'pjpeg', 'webp', 'pjp', 'bmp', 'ico', 'tif', "pdf", "xml", 'css', 'txt'];
export const documentApi = api.injectEndpoints({
    endpoints: (build) => ({
        getDocumentTableData: build.query<DocumentTableData, void>({
            query: () => ({ url: `/documentTableData`, headers: authHeader() }),
            transformErrorResponse: (err) => {
                if (err?.status === 401) {
                    redirectToLogin();
                }
            },
            providesTags: (result: any = []) => {
                if (result.data) {
                    if (result.data.length > 0) {
                        return [
                            ...result.data.map((id: any) => ({ type: "Documents", id } as const)),
                            { type: "Documents" as const, id: "LIST" },
                        ]
                    }
                    else {
                        return [{ type: "Documents" as const, id: "LIST" },]
                    }
                }
                else {
                    return [{ type: "Documents" as const, id: "LIST" },]
                }

            },
        }),
        getDocumentTableFilterData: build.query<DocumentTableFilterData, void>({
            query: () => ({
              url: `documents-filter-data`,
              headers: authHeader(),
            }),
          }),
        getTagData: build.query<tagDocData, void>({
            query: () => ({ url: `/tags`, headers: authHeader() }),
            transformErrorResponse: (err) => {
                if (err?.status === 401) {
                    redirectToLogin();
                }
            },
            providesTags: ['Tags'],
        }),
        viewFile: build.query<void, string>({
            query: (url) => ({
                url: "documents/view/" + url, headers: authHeader(),                
            }),
            transformErrorResponse: (err) => {
                if (err?.status === 401) {
                    redirectToLogin();
                }
            },
        }),
        viewFiles: build.mutation<{ success: boolean; id: number }, string>({
            query(url) {
                return {
                    url: `${url}`,
                    headers: {
                        'Authorization': `Bearer 18|4iNXEa2tKXAIlpSYpPyN1WEZGb8JsXphzkUmYTuS`,
                        'Accept': `application/json`,
                        'Content-Type': `application/json`
                    },
                    method: "GET",
                };
            },
            transformErrorResponse: (err) => {
                if (err?.status === 401) {
                    redirectToLogin();
                }
            },
            invalidatesTags: (events) => [{ type: "Documents", id: events?.id }],
        }),
        deleteDocTemplateData: build.mutation<{ success: boolean; id: number }, number>({
            query(id) {
                return {
                    url: `/employee/deleteTemplate/${id}`,
                    headers: authHeader(),
                    method: "DELETE",
                };
            },
            invalidatesTags: (events) => [{ type: "Documents", id: events?.id }],
        }),
        assignUser: build.mutation<DocumentTableData, assignUserData>({
            query: (body) => {
                return {
                    url: `assignDocument/${body.id}`,
                    method: "POST",
                    body,
                    headers: authHeader(),
                };
            },
            invalidatesTags: (events) => [{ type: "Documents", id: "LIST" }],
        }),
        assignOwner: build.mutation<DocumentTableData, assignOwnerData>({
            query: (body) => {
                return {
                    url: `documents/${body.id}/assignOwner`,
                    method: "POST",
                    body,
                    headers: authHeader(),
                };
            },
            invalidatesTags: (events) => [{ type: "Documents", id: "LIST" }],
        }),
        assignTags: build.mutation<DocumentTableData, assigsTagsData>({
            query: (body) => {
                return {
                    url: `documents/${body.id}/assignTags`,
                    method: "POST",
                    body,
                    headers: authHeader(),
                };
            },
            invalidatesTags: (events) => [{ type: "Documents", id: "LIST" }],
        }),
        assignUserMultiple: build.mutation<DocumentTableData, assignUserMultipleData>({
            query: (body) => {
                return {
                    url: `assignDocuments`,
                    method: "POST",
                    body,
                    headers: authHeader(),
                };
            },
            invalidatesTags: (events) => [{ type: "Documents", id: "LIST" }],
        }),
        downloadDoc: build.mutation<DocumentTableData, number>({
            query: (body) => {
                return {
                    url: `downloadDocument/${body}`,
                    method: "POST",
                    body,
                    headers: authHeader(),
                };
            },
            invalidatesTags: (events) => [{ type: "Documents", id: "LIST" }],
        }),
        downloadAllDoc: build.mutation<DocumentTableData, void>({
            query: (body) => {
                return {
                    url: `downloadAllDocuments`,
                    method: "GET",
                    body,
                    headers: authHeader(),
                };
            },
            invalidatesTags: (events) => [{ type: "Documents", id: "LIST" }],
        }),
        deleteDoc: build.mutation<DocumentTableData, number>({
            query: (id) => {
                return {
                    url: `deleteDocument/${id}`,
                    method: "DELETE",
                    headers: authHeader(),
                };
            },
            invalidatesTags: (events) => [{ type: "Documents", id: "LIST" }],
        }),
        // deleteDocMultiple: build.mutation<DocumentTableData, deleteMultipleDoc>({
        //     query: (data) => {
        //         return {
        //             url: `deleteDocuments`,
        //             method: "DELETE",
        //             body: data,
        //             headers: authHeader(),
        //         };
        //     },
        //     invalidatesTags: (events) => [{ type: "Documents", id: "LIST" }],
        // }),
        deleteDocMultiple: build.mutation<DocumentTableData, Ids>({
            query: (data) => ({
                url: `deleteDocuments`,
                headers: authHeader(),
                method: 'DELETE',
                body: data,
            }),
            invalidatesTags: [{ type: 'Documents', id: "LIST" }],
        }),
    }),
});

export const {
    useGetDocumentTableDataQuery,
    useDeleteDocTemplateDataMutation,
    useAssignUserMutation,
    useAssignUserMultipleMutation,
    useDownloadDocMutation,
    useDeleteDocMultipleMutation,
    useDeleteDocMutation,
    useGetTagDataQuery,
    useAssignOwnerMutation,
    useAssignTagsMutation,
    // useViewFilesMutation,
    useViewFileQuery,
    useGetDocumentTableFilterDataQuery
} = documentApi;


export const {
    endpoints: {
        getDocumentTableData,
        deleteDocTemplateData,
        assignUser,
        assignUserMultiple,
        downloadDoc,
        deleteDoc,
        deleteDocMultiple,
        getTagData,
        assignOwner,
        assignTags,
        // viewFiles,
        viewFile,
        getDocumentTableFilterData
    },
} = documentApi;