import { ReactElement } from "react";
import BasicLayout from "../layouts/BasicLayout";
import { Outlet } from "react-router-dom";
import ExchangeRate from "./views/ExchangeRate";

const ExchangeRateRoute = (props:any): ReactElement => {
    const data=props.loginData;
    const permissions = props.permissions;
  
   return (
      <BasicLayout userData={data} permissions={permissions}>
        <ExchangeRate/>
        <Outlet />
      </BasicLayout>
    )
  };
  
  export default ExchangeRateRoute;