import {
  ColumnDef,
  createColumnHelper,
  PaginationState,
  RowSelectionState,
  SortingState,
} from "@tanstack/react-table";
import classNames from "classnames";
import {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";
import { TemplateData } from "../../app/services/employee";

import SharePopup from "../SharePopup/SharePopup";
import LayoutAdjustableTable from "../Tables/LayoutAdjustableTable";
import TablePaginator from "../Tables/TablePaginator";
import styles from "./ActivityLogTable.module.scss";
import ErrorPopup from "../ErrorPopup/ErrorPopup";
import { ToastContainer } from "react-toastify";
import { ILogs } from "../../app/services/password-log-activity";

interface IEmployeeTable {
  isLayoutControlOpen: boolean;
  setIsLayoutControlOpen: Dispatch<SetStateAction<boolean>>;
  pagination: PaginationState;
  setPagination: Dispatch<SetStateAction<PaginationState>>;
  globalSearch: string;
  setGlobalSearch: Dispatch<SetStateAction<string>>;
  data: ILogs[];
  setData: Dispatch<SetStateAction<ILogs[]>>;
  leaveFullHalfData: any;
  setLeaveFullHalfData: Dispatch<SetStateAction<any>>;
  hiddenFields: string[];
  templateList: TemplateData[];
  setHiddenFields: any;
  setCurrentView: any;
  localModuleId: number;
  changePage?: any;
  total: number;
  setCurrentPage: any;
  currentPage: number;
  setCols?: any;
  setIsTemplate?: Dispatch<SetStateAction<boolean>>;
  isEditing?: boolean;
  setIsEdit: Dispatch<SetStateAction<boolean>>;
  sorting: SortingState;
  setSorting: React.Dispatch<React.SetStateAction<SortingState>>;
}


const ActivityLogTable: FC<IEmployeeTable> = ({
  isLayoutControlOpen,
  setIsLayoutControlOpen,
  pagination,
  setPagination,
  globalSearch,
  setGlobalSearch,
  data,
  setData,
  leaveFullHalfData,
  setLeaveFullHalfData,
  // orgDataList,
  hiddenFields,
  templateList,
  setHiddenFields,
  setCurrentView,
  localModuleId,
  changePage,
  total,
  setCurrentPage,
  currentPage,
  setCols,
  setIsTemplate,
  isEditing,
  setIsEdit,
  sorting,
  setSorting
}) => {
  const columnHelper = useMemo(() => createColumnHelper<any>(), []);
  
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  // const [itemCount, setItemCount] = useState<number>(data.length);
  const [itemCount, setItemCount] = useState<number>(total);

  const [successPopup, setSuccessPopup] = useState<boolean>(false);
  const [errorPopup, setErrorPopup] = useState<boolean>(false);
  const [message, setMessage] = useState<any>([]);

  const [openSharePopup, setOpenSharePopup] = useState<boolean>(false);

  // console.log(data);

  const columns = useMemo(
    () => [
      
      columnHelper.accessor("created_at", {
        header: "Date & Time",
        id: "created_at",
        enableSorting:false,
        cell: ({ getValue, cell }) => {
          const applitedAt = getValue();
          return (
            <div className="bg-white text-black2 px-2">
              <p className="h-full flex items-center">{applitedAt}</p>
            </div>
          );
        },
      }),
      columnHelper.accessor("event", {
        header: "Action",
        id: "event",
        enableSorting:false,
        cell: ({ getValue, cell }) => {
          const applitedAt = getValue();
          return (
            <div className="bg-white text-black2 px-2">
              <p className="h-full flex items-center">{applitedAt}</p>
            </div>
          );
        },
      }),
      columnHelper.accessor("description", {
        header: "Activity Log",
        id: "description",
        cell: ({ getValue, cell }) => {
          const description = getValue();
          return (
            <div className="bg-white text-black2 px-2 min-w-[200px]">
              <p className="h-full flex items-center">{description}</p>
            </div>
          );
        },
      }),
      columnHelper.accessor("module", {
        header: "Module",
        id: "module",
        cell: ({ getValue, cell }) => {
          const applitedAt = getValue();
          return (
            <div className="bg-white text-black2 px-2">
              <p className="h-full flex items-center">{applitedAt}</p>
            </div>
          );
        },
      }),
      columnHelper.accessor("causer", {
        header: "User",
        id: "causer",
        cell: ({ getValue, cell }) => {
          const applitedAt = getValue();
          return (
            <div className="bg-white text-black2 px-2">
              <p className="h-full flex items-center">{applitedAt}</p>
            </div>
          );
        },
      }),
    ],
    [columnHelper]
  );

  const [columnOrder, setColumnOrder] = useState<string[]>(
    columns.map((column: ColumnDef<any, any>) => column.id as string)
  );

  const visibleColumns: { [key: string]: boolean } = {};

  hiddenFields?.forEach((x) => {
    visibleColumns[x] = true;
  });

  if (hiddenFields.length > 0) {
    columns.forEach((x: any) => {
      if (x.id == "actions" || x.id == "lasted" || x.id == "selection") {
        visibleColumns[x.id ? x.id : ""] = true;
      } else if (x.id == "uniqeID") {
        visibleColumns[x.id ? x.id : ""] = false;
      } else if (x.id?.includes("none")) {
        visibleColumns[x.id ? x.id : ""] = true;
      } else {
        var isHidden = hiddenFields.find((h) => h == x.id);
        if (isHidden) {
          visibleColumns[x.id ? x.id : ""] = true;
        } else {
          visibleColumns[x.id ? x.id : ""] = false;
        }
      }
    });
  }

  useEffect(() => {
    if (hiddenFields.length > 0) {
      setColumnOrder(["selection", ...hiddenFields]);
    }
  }, [hiddenFields]);

  useEffect(() => {
    setTimeout(() => {
      setSuccessPopup(false);
    }, 1000);
  }, [successPopup]);

  return (
    <div
      className={classNames(
        styles.leaveTable,
        "relative inline-block max-w-full w-full"
      )}
    >
      <ToastContainer className={"text-12 font-poppins font-normal"} />
      <ErrorPopup
        descText="Error Message!"
        confirmBtnText="Yes, Close."
        show={errorPopup}
        setShow={setErrorPopup}
        message={message}
        setMessage={setMessage}
      />

      <SharePopup show={openSharePopup} setShow={setOpenSharePopup} />
      <LayoutAdjustableTable
        hideColumn={visibleColumns}
        columns={columns}
        data={data}
        sorting={sorting}
        setSortingState={setSorting}
        rowSelection={rowSelection}
        setRowSelection={setRowSelection}
        columnOrder={columnOrder}
        setColumnOrder={setColumnOrder}
        unDraggableFields={["actions", "selection"]}
        theaderColor="text-graydark"
        isLayoutControlOpen={isLayoutControlOpen}
        setIsLayoutControlOpen={setIsLayoutControlOpen}
        label="User List"
        setItemCount={setItemCount}
        pagination={pagination}
        setPagination={setPagination}
        globalFilter={globalSearch}
        setGlobalFilter={setGlobalSearch}
        // columnFilters={columnFilters}
        // setColumnFilters={setColumnFilters}
        noPaddingCells={["leave_type_id", "fullHalfDay", "status"]}
        localModuleId={localModuleId}
        setCols={setCols}
        setIsTemplate={setIsTemplate}        
      />

      <div className="pt-6">
        <TablePaginator
          total={itemCount}
          pagination={pagination}
          setPagination={setPagination}
          itemCount={total}
          setCurrentPage={setCurrentPage}
          currentPageDefault={currentPage}
          changePage={changePage}
        />
      </div>
    </div>
  );
};
export default ActivityLogTable;
