// import { Popper } from "@material-ui/core";
// import { Popper } from "@mui/material"
// hello

import {
    Cell,
    ColumnDef,
    ColumnFiltersState,
    createColumnHelper,
    PaginationState,
    // RowData,
    RowSelectionState,
    SortingState,
} from "@tanstack/react-table";
import classNames from "classnames";
import {
    Dispatch,
    FC,
    SetStateAction,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from "react";
import {
    TemplateData,
} from "../../app/services/employee";
import { baseObj } from "../../utils/constants";
import {
    ApplyIcon,
    // AddIcon,
    CancelIcon,
    // ChevronDown,
    EditIcon,
    ReturnIcon,
    ShareIcon,
    TableSaveIcon,
    TrashIcon,
    ViewIcon,
} from "../common/Icons";
import DeletePopup from "../DeletePopup/DeletePopup";
// import EnableSwitch from "../Projects/ProjectCompleteSwitch";
import ReportToPopup from "../ReportToPopup/ReportToPopup";
import SharePopup from "../SharePopup/SharePopup";
import EditableCell from "../Tables/EditableCell";
import LayoutAdjustableTable from "../Tables/LayoutAdjustableTable";
import TablePaginator from "../Tables/TablePaginator";
// import DepartmentDropdownList from "./DepartmentDropdownList";
import styles from "./EquipmentTable.module.scss";
// import { getAuthUser } from "../../app/services/dashboard";
import { templateData } from "../../app/services/template";
// import SuccessPopup from "../SuccessPopup/SuccessPopup";
import ErrorPopup from "../ErrorPopup/ErrorPopup";
// import { NavLink } from "react-router-dom";
// import { deleteCurrency } from "../../app/services/project";
import CommonErrorPopup from "../common/CommonErrorPopup";
import DOMPurify from "dompurify";
import Table from "../Tables/Tables.module.scss";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { EnableSwitch } from "../BackendUsers/BackendUsersTable";
import ToggleSwitch from "../common/ToggleSwitch";
import { twMerge } from "tailwind-merge";
import { IAppEquipment, IEquipment, IEquipmentLog, IViewEquipment } from "../../equipments/views/Equipments";
import EquipmentReturnPopup from "../../equipments/views/EquipmentReturnPopup";
import { IEquipmentApp } from "../../app/services/equipments";

export interface StoreEmployeeType {
    employeeID: string;
    id: number;
    name: string;
    preferredName: string;
    email: string;
    mobile: string;
    joinedDate: string;
    department_id: number;
    designation_id: number;
    currency_id: number;
    position: string;
    // reportTo: number;
    report_to: number;
    currency: string;
    salary: number;
    dob: string;
    gender: string;
    race: string;
    religion: string;
    country_id: number;
    nationalityID: string;
    passportNo: string;
    device: string;
    bank: string;
    bankAccNo: string;
    status: string;

}
export interface GenderData {
    id: number;
    name: string;
    label: string;
}

export interface MembereData {
    id: number;
    name: string;
    label: string;

    preferred_name?: string;
    year_w_vo?: number;
    age?: number;
}

export type TPayment = {
    id?: number;
    project_status_id?: number | string;
    amount?: number;
    payment_due_date?: string | null;
    payment_received_date?: string | null;
    invoice_no?: string;
    payment_received_no?: string;
    is_completed: number;
};

export type TCheck = {
    label: string;
    is_checked: number;
};

export type TKeyDate = {
    key_id: number;
    key_date: string;
};



interface IEquipmentTable {
    isLayoutControlOpen: boolean;
    setIsLayoutControlOpen: Dispatch<SetStateAction<boolean>>;
    pagination: PaginationState;
    setPagination: Dispatch<SetStateAction<PaginationState>>;
    globalSearch: string;
    setGlobalSearch: Dispatch<SetStateAction<string>>;
    columnFilters: ColumnFiltersState;
    setColumnFilters: Dispatch<SetStateAction<ColumnFiltersState>>;
    data: baseObj[];
    setData: Dispatch<SetStateAction<baseObj[]>>;
    deleteEmployee: any;
    deleteEmployeeByIDs: any;
    hiddenFields: string[];
    setHiddenFields: any;
    setCurrentView: any;
    localModuleId: number;
    templateList: templateData[];
    total: number;
    changePage?: any;
    setCurrentPage: any;
    currentPage: number;
    setIsEdit: Dispatch<SetStateAction<boolean>>;
    isEditing?: boolean;
    setIsTemplate?: Dispatch<SetStateAction<boolean>>;
    setCols?: any;
    addNew: boolean;
    setEmployeeID: Dispatch<SetStateAction<string>>;
    sorting: SortingState;
    setSorting: React.Dispatch<React.SetStateAction<SortingState>>;
    setEditedData: React.Dispatch<React.SetStateAction<IEquipment | undefined>>;
    setViewData: React.Dispatch<React.SetStateAction<IViewEquipment | undefined>>;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setIsViewOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setApplyEditedData: React.Dispatch<React.SetStateAction<IAppEquipment | undefined>>;
    setIsApplyOpen: React.Dispatch<React.SetStateAction<boolean>>;
    viewApplicationData: any;
    applyEquipmentData: (data: IEquipmentApp) => void;
    equipmentLogs: IEquipmentLog[];
    getEquipmentsLogs: any;
    deleteEquipmentData: (id: number) => void;
    setOpenDeleteopup: React.Dispatch<React.SetStateAction<boolean>>;
    openDeletePopup: boolean;
    setClickLoading: React.Dispatch<React.SetStateAction<boolean>>;
    clickLoading: boolean;
    curLocation: string;
    setCurrentID: React.Dispatch<React.SetStateAction<number>>;
    returnEquipment: (bodyFormData: any, id: number) => Promise<void>;
    setIsOpenReturnPopup: React.Dispatch<React.SetStateAction<boolean>>;
    isOpenReturnPopup: boolean;
    setIsReturn: React.Dispatch<React.SetStateAction<boolean>>;
    isReturn: boolean;
}

const EquipmentTable: FC<IEquipmentTable> = ({
    isLayoutControlOpen,
    setIsLayoutControlOpen,
    pagination,
    setPagination,
    globalSearch,
    setGlobalSearch,
    columnFilters,
    setColumnFilters,
    data,
    setData,
    // orgDataList,
    deleteEmployee,
    deleteEmployeeByIDs,
    hiddenFields,
    templateList,
    setHiddenFields,
    setCurrentView,
    localModuleId,
    total,
    // changePage,
    setCurrentPage,
    currentPage,
    setIsEdit,
    isEditing,
    setIsTemplate,
    setCols,
    addNew,
    sorting,
    setSorting,
    setEditedData,
    setIsOpen,
    setViewData,
    setIsViewOpen,
    setApplyEditedData,
    setIsApplyOpen,
    viewApplicationData,
    applyEquipmentData,
    equipmentLogs,
    getEquipmentsLogs,
    deleteEquipmentData,
    setOpenDeleteopup,
    openDeletePopup,
    clickLoading,
    setClickLoading,
    curLocation,
    setCurrentID,
    returnEquipment,
    setIsOpenReturnPopup,
    isOpenReturnPopup,
    setIsReturn,
    isReturn
}) => {
    const columnHelper = useMemo(() => createColumnHelper<any>(), []);
    const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
    // const [itemCount, setItemCount] = useState<number>(data.length);
    const [itemCount, setItemCount] = useState<number>(total);
    const selectRowsCount = Object.keys(rowSelection).length;
    const [successPopup, setSuccessPopup] = useState<boolean>(false);
    const [errorPopup, setErrorPopup] = useState<boolean>(false);
    const [message, setMessage] = useState<any>([]);
    const [msg, setMsg] = useState<string>("");
    const [deleteID, setDeleteID] = useState<number | 0>();
    const [isLoadingClick, setIsLoadingClick] = useState<boolean>(false);
    const [openReturnPopup, setOpenReturnPopup] = useState<boolean>(false);
    const [deleteRowID, setDeleteRowID] = useState<number | 0>();
    

    const columns = useMemo(
        () => [
            columnHelper.accessor("code", {
                header: "Equipment ID",
                id: "code",
                cell: ({ getValue, cell }) => {
                    const equipmentID = getValue();
                    return (
                        <div className="  text-left bg-white text-black2 px-[10px]">
                            <p className="h-full flex items-center">{equipmentID ? equipmentID : '---'}</p>
                        </div>
                    );
                },
            }),
            columnHelper.accessor("serialno", {
                header: "Serial Number",
                id: "serialno",
                cell: ({ getValue, cell }) => {
                    const serialno = getValue();
                    return (
                        <div className="  text-left bg-white text-black2 px-[10px]">
                            <p className="h-full flex items-center">{serialno ? serialno : '---'}</p>
                        </div>
                    );
                },
            }),
            columnHelper.accessor("category", {
                header: "Category",
                id: "category",
                cell: ({ getValue, cell }) => {
                    const category = getValue();
                    return (
                        <div className="  text-left bg-white text-black2 px-[10px]">
                            <p className="h-full flex items-center">{category ? category.name : '---'}</p>
                        </div>
                    );
                },
            }),
            columnHelper.accessor("tags", {
                header: "Tags",
                id: "tags",
                cell: ({ getValue, cell }) => {
                    const tags = getValue();
                    return (
                        <div className="  text-left bg-white text-black2 px-[10px]">
                            <div className="h-full flex items-center">{tags?.length > 0 ? tags?.map((x: any) => {
                                return <div className="mr-1 bg-greenLight px-[5px] rounded-[5px] text-greenBright text-sm font-normal text-center">{x.name}</div>
                            }) : '---'}</div>
                        </div>
                    );
                },
            }),
            columnHelper.accessor("name", {
                header: "Name",
                id: "name",
                cell: ({ getValue, cell }) => {
                    const name = getValue();
                    return (
                        <div className="  text-left bg-white text-black2 px-[10px]">
                            <p className="h-full flex items-center">{name ? name : '---'}</p>
                        </div>
                    );
                },
            }),
            columnHelper.accessor("availablity", {
                header: "Availablity",
                id: "availablity",
                enableSorting: false,
                cell: ({ getValue, cell }) => {
                    const availablity = getValue();
                    return (
                        <div className="  text-left bg-white text-black2 px-[10px]">
                            <p className={twMerge(availablity == false ? 'text-redPink' : availablity == true ? 'text-vorpgreen' : 'text-vorpgreen', "h-full flex items-center")}>{availablity == true ? 'Available' : 'Not Available'}</p>
                        </div>
                    );
                },
            }),
            columnHelper.accessor("condition", {
                header: "Condition",
                id: "condition",
                cell: ({ getValue, cell }) => {
                    const condition = getValue();
                    return (
                        <div className="  text-left bg-white text-black2 px-[10px]">
                            <p className="h-full flex items-center">{condition ? condition : '---'}</p>
                        </div>
                    );
                },
            }),
            columnHelper.accessor("location", {
                header: "Location",
                id: "location",
                enableSorting: false,
                cell: ({ getValue, cell }) => {
                    const location = getValue();
                    return (
                        <div className="  text-left bg-white text-black2 px-[10px]">
                            <p className="h-full flex items-center">{location ? location.name : '---'}</p>
                        </div>
                    );
                },
            }),
            columnHelper.accessor("employeeID", {
                header: "Employee ID",
                id: "employeeID",
                enableSorting: false,
                cell: ({ getValue, cell }) => {
                    const employeeID = getValue();

                    return (
                        <div className="  text-left bg-white text-black2 px-[10px]">
                            <p className="h-full flex items-center">{employeeID ? employeeID : '---'}</p>
                        </div>
                    );
                },
            }),
            columnHelper.accessor("employee", {
                header: "Employee",
                id: "employee",
                enableSorting: false,
                cell: ({ getValue, cell }) => {
                    const employee = getValue();
                    return (
                        <div className="  text-left bg-white text-black2 px-[10px]">
                            <p className="h-full flex items-center">{employee?.name ? employee?.name : '---'}</p>
                        </div>
                    );
                },
            }),
            columnHelper.accessor("department", {
                header: "Department",
                id: "department",
                enableSorting: false,
                cell: ({ getValue, cell }) => {
                    const department = getValue();
                    return (
                        <div className="  text-left bg-white text-black2 px-[10px]">
                            <p className="h-full flex items-center">{department?.name ? department?.name : '---'}</p>
                        </div>
                    );
                },
            }),
            columnHelper.accessor("date", {
                header: "Date Borrowed/Assigned",
                id: "date",
                enableSorting: false,
                cell: ({ getValue, cell }) => {
                    const date = getValue();
                    return (
                        <div className="  text-left bg-white text-black2 px-[10px]">
                            <p className="h-full flex items-center">{date ? date : '---'}</p>
                        </div>
                    );
                },
            }),
            columnHelper.accessor("expected_return_date", {
                header: "Expected Return Date",
                id: "expected_return_date",
                enableSorting: false,
                cell: ({ getValue, cell }) => {
                    const return_date = getValue();
                    return (
                        <div className="  text-left bg-white text-black2 px-[10px]">
                            <p className={twMerge(return_date?.isOverdue == true ? 'text-redPink' : '', "h-full flex items-center")}>{return_date ? (return_date.date ? return_date.date : '---') : '---'}{(return_date?.isOverdue == true ? ' (Overdue)' : '')}</p>
                        </div>
                    );
                },
            }),
            columnHelper.accessor("version", {
                header: "Version",
                id: "version",
                enableSorting: false,
                cell: ({ getValue, cell }) => {
                    const version = getValue();
                    return (
                        <div className="  text-left bg-white text-black2 px-[10px]">
                            <p className="h-full flex items-center">{version ? version : '---'}</p>
                        </div>
                    );
                },
            }),
            columnHelper.accessor("service_provider", {
                header: "Service Provider",
                id: "service_provider",
                enableSorting: false,
                cell: ({ getValue, cell }) => {
                    const service_provider = getValue();
                    return (
                        <div className="  text-left bg-white text-black2 px-[10px]">
                            <p className="h-full flex items-center">{service_provider ? service_provider : '---'}</p>
                        </div>
                    );
                },
            }),
            columnHelper.accessor("pricing_detail", {
                header: "Pricing Detail",
                id: "pricing_detail",
                enableSorting: false,
                cell: ({ getValue, cell }) => {
                    const pricing_detail = getValue();
                    return (
                        <div className="  text-left bg-white text-black2 px-[10px]">
                            <p className="h-full flex items-center">{pricing_detail ? pricing_detail : '---'}</p>
                        </div>
                    );
                },
            }),
            columnHelper.accessor("warranty", {
                header: "Warranty",
                id: "warranty",
                cell: ({ getValue, cell }) => {
                    const warranty = getValue();
                    return (
                        <div className="  text-left bg-white text-black2 px-[10px]">
                            <p className="h-full flex items-center">{warranty.fromDate ? ((warranty.fromDate ? warranty.fromDate : '---') + ' to ' + (warranty.toDate ? warranty.toDate : '---')) : '---'}</p>
                        </div>
                    );
                },
            }),
            columnHelper.accessor("license", {
                header: "License Type",
                id: "license",
                enableSorting: false,
                cell: ({ getValue, cell }) => {
                    const license = getValue();
                    return (
                        <div className="  text-left bg-white text-black2 px-[10px]">
                            <p className="h-full flex items-center">{license ? license : '---'}</p>
                        </div>
                    );
                },
            }),
            columnHelper.accessor("license_date", {
                header: "License Validity Date",
                id: "license_date",
                enableSorting: false,
                cell: ({ getValue, cell }) => {
                    const license_date = getValue();
                    return (
                        <div className="  text-left bg-white text-black2 px-[10px]">
                            <p className="h-full flex items-center">{license_date.fromDate ? (license_date?.fromDate + ' to ' + license_date?.toDate) : '---'}</p>
                        </div>
                    );
                },
            }),
            columnHelper.accessor("created_by", {
                header: "Created by",
                id: "created_by",
                cell: ({ getValue, cell }) => {
                    const created_by = getValue();
                    return (
                        <div className="  text-left bg-white px-[10px]">
                            <p className="flex items-center text-black2 text-sm">{created_by?.name}</p>
                            <p className="flex items-center text-graydark text-xs">{created_by?.date}</p>
                        </div>
                    );
                },
            }),
            columnHelper.accessor("remark", {
                header: "Remark",
                id: "remark",
                cell: ({ getValue, cell }) => {
                    const remark = getValue();
                    return (
                        <div className="  text-left bg-white text-black2 px-[10px]">
                            <p className="h-full flex items-center">{remark ? remark : '---'}</p>
                        </div>
                    );
                },
            }),
            columnHelper.display({
                header: "Action",
                id: `actions`,
                cell: ({ cell }) => {
                    const viewOption = cell.row.original.viewOption;
                    const applyOption = cell.row.original.applyOption;
                    const returnOption = cell.row.original.returnOption;
                    const editOption = cell.row.original.editOption;
                    const deleteOption = cell.row.original.deleteOption;
                    return (
                        <div
                            className={classNames(
                                styles.actionedBtn,
                                "px-2 flex justify-center items-center "
                                // max-w-[98px] min-w-max
                            )}
                        >
                            {viewOption == true ?
                                <button
                                    onClick={() => {
                                        viewEquipmentData(Number(cell.row.id), cell)
                                    }}
                                    className={classNames(
                                        styles.customViewdBtn,
                                        "w-6 h-6 mr-2 flex justify-center items-center group rounded-md bg-darkpurple hover:bg-[#EAE0FF] transition-all duration-300"
                                    )}
                                >
                                    <ViewIcon />
                                </button> : ''}
                            {editOption == true ?
                                <button
                                    type="button"
                                    onClick={() => {
                                        editEquipment(Number(cell.row.id), cell);
                                        if (setIsEdit) {
                                            setIsEdit(true);
                                        }
                                    }}
                                    className={classNames(
                                        " w-6 h-6 mr-2 flex justify-center items-center group rounded-md bg-vorpblue hover:bg-annhoverbg transition-all duration-300"
                                    )}
                                >
                                    <EditIcon />
                                </button> : ''}
                            {returnOption==true ?
                                <button
                                    type="button"
                                    onClick={() => {
                                        setOpenReturnPopup(true);
                                        setDeleteID(Number(cell.row.original.id))
                                    }}
                                    className={classNames(
                                        "return-btn w-6 h-6 mr-2 flex justify-center items-center group rounded-md bg-vorphongkongborder hover:bg-annhoverbg transition-all duration-300"
                                    )}
                                >
                                    <ReturnIcon />
                                </button> : ''}
                            {applyOption == true ?
                                <button
                                    type="button"
                                    onClick={() => {
                                        applyEquipment(Number(cell.row.original.id), cell);
                                        if (setIsEdit) {
                                            setIsEdit(true);
                                        }
                                    }}
                                    className={classNames(
                                        "apply-btn w-6 h-6 mr-2 flex justify-center items-center group rounded-md bg-vorpphillipines hover:bg-annhoverbg transition-all duration-300"
                                    )}
                                >
                                    <ApplyIcon />
                                </button> : ''}
                            {deleteOption == true ?
                                <button
                                    type="button"
                                    onClick={() => {
                                        deleteEquipment(Number(cell.row.original.id))
                                    }}
                                    className={classNames(
                                        " w-6 h-6 flex justify-center items-center group rounded-md bg-redPink hover:bg-[#FFEAEF] transition-all duration-300"
                                    )}
                                >
                                    <TrashIcon />
                                </button> : ''}
                        </div>
                    );
                },
            }),
        ],
        [
            columnHelper,
            data,
        ]
    );

    const [columnOrder, setColumnOrder] = useState<string[]>(
        columns.map((column: ColumnDef<any, any>) => column.id as string)
    );

    document.querySelectorAll("th,td").forEach((x) => {
        if (x.id.includes("none")) {
            x.classList.add("hidden");
        }

        x.classList.remove("absolute");
        x.classList.add("left-0");
        x.classList.add("relative");
        var div = document.getElementById(x.id);
        if (div) {
            div.style.setProperty("left", "");
        }
        if (x.id.includes("action")) {
            x.classList.add("absolute");
            x.classList.remove("relative");
            x.classList.add("right-0");
            x.classList.remove("left-0");
        }
    });

    const deleteEmployeeData = useCallback(
        (id?: any, uniqueID?: number) => {
            setDeleteID(id);
            setDeleteRowID(uniqueID);
            setOpenDeleteopup(true);
        },
        [setData, rowSelection]
    );

    const deleteEmpItem = useCallback(
        (id?: number, dataId?: number) => {
            const selectedRows = Object.keys(rowSelection);
            setData((prev: baseObj[]) => {
                if (dataId != undefined) {
                    // var $dataId = dataId;
                    deleteEmployee(dataId)
                        .unwrap()
                        .then((payload: any) => {
                            if (payload.status) {
                                setOpenDeleteopup(false);
                                setSuccessPopup(true);
                                setMessage(
                                    payload ? [payload?.message] : ["Successfully Delete."]
                                );
                                toast("Successfully Delete.", {
                                    position: "top-right",
                                    autoClose: 2000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                    type: "success",
                                });
                            }
                        })
                        .catch((error: any) => {
                            setErrorPopup(true);
                            setOpenDeleteopup(false);
                            setMessage(
                                error
                                    ? [error?.data?.message]
                                    : ["Something went wrong!Trying again."]
                            );
                        });
                }
                return prev.filter((_, index) => {
                    return id !== undefined
                        ? id !== index
                        : !selectedRows.includes(String(index));
                });
            });
            id == undefined && setRowSelection({});
        },
        [setData, rowSelection]
    );
    const editEquipment = useCallback(
        (id: number, cell: any) => {
            setEditedData(cell.row.original);
            setIsOpen(true);            
        },
        [setData]
    );
    const applyEquipment = useCallback(
        (id: number, cell: any) => {
            if (curLocation == 'all') {
                setApplyEditedData({
                    id: 0,
                    equipment_id: id.toString(),
                    department_id: '',
                    employee_id: '',
                    application_type: 'Borrow',
                    assign_date: '',
                    expected_return_date: '',
                    reason: ''
                });
                setCurrentID(id)
            }

            setIsApplyOpen(true);
        },
        [setData]
    );
    const viewEquipmentData = useCallback(
        (id: number, cell: any) => {
            setViewData(cell.row.original);
            setIsViewOpen(true);
            getEquipmentsLogs(cell.row.original.id);
            return setData((prev: baseObj[]) => {
                return prev.map((data, index: number) => {
                    if (index == id) {
                        return { ...data };
                    }
                    return data;
                });
            })
        },
        [setData]
    );

    const visibleColumns: { [key: string]: boolean } = {};

    hiddenFields?.map((x) => {
        visibleColumns[x] = true;
    });

    if (hiddenFields.length > 0) {
        columns.map((x) => {
            if (x.id == "actions" || x.id == "selection" || x.id == "lasted") {
                visibleColumns[x.id ? x.id : ""] = true;
            } else if (x.id == "uniqeID") {
                visibleColumns[x.id ? x.id : ""] = false;
            } else if (x.id?.includes("none")) {
                visibleColumns[x.id ? x.id : ""] = true;
            } else {
                var isHidden = hiddenFields.find((h) => h == x.id);
                if (isHidden) {
                    visibleColumns[x.id ? x.id : ""] = true;
                } else {
                    visibleColumns[x.id ? x.id : ""] = false;
                }
            }
        });
    }
    const saveTemplateData = (template: any) => {
        var views = columns
            .map((x) => {
                if (x.id) {
                    if (template[x.id] == undefined) {
                        return x.id;
                    }
                }
            })
            .filter((x) => x != undefined);
        var viewsData = views.map((x) => (x != undefined ? x : ""));
        var newTemplate: TemplateData = {
            id: 0,
            name: "View" + templateList.length + 1,
            viewColumns: viewsData,
        };
        setHiddenFields(viewsData);
        setCurrentView("");
    };

    useEffect(() => {
        if (hiddenFields.length > 0) {
            setColumnOrder(["selection", ...hiddenFields]);
        }
    }, [hiddenFields]);

    useEffect(() => {
        setTimeout(() => {
            setSuccessPopup(false);
        }, 1000);
    }, [successPopup]);
    const confirmOpenReturnPopup = () => {
        var id = deleteID;
        setIsOpenReturnPopup(true);
        setOpenReturnPopup(false);
    };
    const confirmDeleteData = () => {
        setClickLoading(true);
        var id = deleteID;
        deleteEquipmentData(id ? Number(id) : 0);
    }
    const deleteEquipment = useCallback(
        (id?: number) => {
            setDeleteID(id);
            setOpenDeleteopup(true);
        },
        [setData, rowSelection]
    );
    const returnEquipmentFun = (data: any) => {
        returnEquipment(data, deleteID ? deleteID : 0)
    }
    return (
        <div
            id="employeeTable"
            className={classNames(
                styles.equipmentTable,
                "relative inline-block max-w-full"
            )}
        >
            <ToastContainer className={"text-12 font-poppins font-normal"} />
            <EquipmentReturnPopup setIsOpen={setIsOpenReturnPopup} isOpen={isOpenReturnPopup} returnEquipment={returnEquipmentFun} setIsReturn={setIsReturn} isReturn={isReturn}/>
            <DeletePopup
                loading={isLoadingClick}
                descText="Are you sure to return the equipment?"
                confirmBtnText="Yes, return now"
                show={openReturnPopup}
                setShow={setOpenReturnPopup}
                confirmDeleteData={confirmOpenReturnPopup}
                confirmBtnColor="bg-vorpgreen hover:bg-white hover:text-vorpgreen"
            />
            <DeletePopup
                show={openDeletePopup}
                setShow={setOpenDeleteopup}
                confirmDeleteData={confirmDeleteData}
                descText="Are You Sure To Delete"
                loading={clickLoading}
            />
            <ErrorPopup
                descText="Error Message!"
                confirmBtnText="Yes, Close."
                show={errorPopup}
                setShow={setErrorPopup}
                message={message}
                setMessage={setMessage}
            />

            <LayoutAdjustableTable
                saveTemplateData={saveTemplateData}
                hideColumn={visibleColumns}
                columns={columns}
                data={data}
                sorting={sorting}
                setSortingState={setSorting}
                rowSelection={rowSelection}
                setRowSelection={setRowSelection}
                columnOrder={columnOrder}
                setColumnOrder={setColumnOrder}
                unDraggableFields={["actions", "selection", "id"]}
                theaderColor="text-graydark"
                isLayoutControlOpen={isLayoutControlOpen}
                setIsLayoutControlOpen={setIsLayoutControlOpen}
                label="Equipment List"
                setItemCount={setItemCount}
                pagination={pagination}
                setPagination={setPagination}
                globalFilter={globalSearch}
                setGlobalFilter={setGlobalSearch}
                columnFilters={columnFilters}
                setColumnFilters={setColumnFilters}
                noPaddingCells={[
                    "department",
                    "designation",
                    "gender",
                    "nationality",
                    "status",
                    "currency_id",
                ]}
                localModuleId={localModuleId}
                setIsTemplate={setIsTemplate}
                setCols={setCols}
            />


            <div className="pt-6">
                <TablePaginator
                    total={itemCount}
                    pagination={pagination}
                    setPagination={setPagination}
                    itemCount={total}
                    setCurrentPage={setCurrentPage}
                    currentPageDefault={currentPage}
                // changePage={changePage}
                />
            </div>
            <CommonErrorPopup
                show={msg == "" ? false : true}
                setShow={() => setMsg("")}
                message={msg}
            />
        </div>
    );
};
export default EquipmentTable;
