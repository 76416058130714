import { Fade, Modal } from "@mui/material";
import { FC } from "react";
import whiteCancel from "../../components/common/img/white-cancel.svg";
import classNames from "classnames";
import styles from './AnnoucementList.module.scss'
interface IAnnoucementModal {
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    clickData:IAnnoucementData;    
}
export interface IAnnoucementData{
    title:string;
    content:string;
    tags:IKeyValue[];
    type:string;
}
interface IKeyValue{
    id:number;
    name:string;
}
const colorsList = ["#1BC5BD", "#F64E60", "#FFA800"];
const AnnoucementModal: FC<IAnnoucementModal> = ({
    isOpen,
    setIsOpen,
    clickData
}) => {
    return <Modal
        open={isOpen}
        style={{
            background: "rgb(0 0 0 / 0.3)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        }}
        onClose={(event, reason) => {
            if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
                setIsOpen(false);
            }
        }
        }
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableEnforceFocus={true}
    >
        <Fade in={isOpen} timeout={500}>
            <div className=" modalContent w-[530px] sm:max-w-full max-w-[90%] max-h-[90%] focus-visible:outline-none overflow-auto custom-scrollbar">
                <div id="modal-modal-title" className="bg-vorpblue relative flex justify-between py-6 px-10 rounded-t-xl">
                    <h3 className="text-18 font-primary font-semibold text-white">{clickData?.title}</h3>
                    <button className="flex items-center justify-center" onClick={() => { setIsOpen(false) }}><img src={whiteCancel} alt="close modal icon" /></button>
                </div>
                <div id="modal-modal-description" className="pt-4 max-h-[500px] overflow-y-scroll pb-9 px-10 bg-white rounded-b-xl">
                    <div className="mb-[0.688rem] last:mb-0">
                        <div className="flex justify-between mb-5 items-center">
                            <div className=" flex flex-wrap">
                                {
                                    clickData?.tags?.length > 0 && (
                                        clickData?.tags?.map((d: any) => {
                                            return <p
                                                key={Math.random() * 1000}
                                                className={classNames(`mx-1 my-1 text-12 text-white w-auto inline-block 
                        border rounded-[100px] leading-[18px] font-primary 
                        py-[3px] px-4 justify-center items-center`)}
                                                style={clickData?.type == "Caution" ? { backgroundColor: colorsList[0] } :
                                                    clickData?.type == "Notice" ? { backgroundColor: colorsList[2] } :
                                                        clickData?.type == "Warning" ? { backgroundColor: colorsList[1] } :
                                                            { backgroundColor: colorsList[2] }}
                                            >
                                                {d.name ? d.name : ''}
                                            </p>
                                        })
                                    )
                                }
                            </div>
                            <div>
                                {clickData ? <p
                                    className={classNames(
                                        "my-1 whitespace-nowrap flex text-left mr-3 font-poppins text-xs font-normal text-[#858795] ",
                                        clickData?.type == "Caution" ?
                                            styles.cautionDiv :
                                            clickData?.type == "Notice" ?
                                                styles.noticeDiv :
                                                clickData?.type == "Warning" ? styles.warningDiv : 'hidden'
                                    )}
                                >
                                    {clickData.type ? clickData.type : ''}
                                </p> : ''}

                            </div>
                        </div>
                        <div className="text-black2 text-15 font-medium font-primary mb-[0.688rem] block" dangerouslySetInnerHTML={{ __html: clickData?.content }}></div>
                    </div>
                </div>
            </div>
        </Fade>
    </Modal>
}
export default AnnoucementModal;