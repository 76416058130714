import authHeader from "./auth-header";
import { api, endpointUrl } from "./api";
import { redirectToLogin } from "./annoucement-sheet";

// -----------------------------------

export type TProfileDailyTaskResponse = {
  status: boolean;
  data: TTaskListData;
};
export type TProfileDailyTaskPaginationResponse = {
  status: boolean;
  task_list: TTaskListDataPagination;  
};
export type TTaskListData = {
  task_list: TTaskNameList[];  
};
export type TTaskListDataPagination = {
  data: TTaskNameList[];
  total:number;
};

export type TProfileDailyListResponse = {
  status: boolean;
  data: TDataList;
};

export type TDataList = {
  project_list: TProjectListDailyReport[];
  task_categories: TTaskTagsList[];
  task_list: TTaskNameList[];
  previous_data: TPreviousList[];
  today_data: TPreviousList | null;
  total_time: string;
  total_time_text: string;
  hours: number,
  minutes: number,
  date: string
};

export type TPreviousList = {
  id: number;
  employee_id: number;
  department_id: number;
  date: string;
  name: string;
  is_draft: number;
  created_at: string;
  updated_at: string;
  deleted_at: null;
  items: TItemData[];
};

export type TItemData = {
  id: number;
  daily_report_id: number;
  project_id: number;
  office_project_id: number;
  task_id: number;
  task_category_id: number;
  type: string;
  date: string;
  start_time: null;
  end_time: null;
  time_spent: string;
  is_completed: number;
  created_at: string;
  updated_at: string;
  is_previous: number;
  time_spent_text: string;
};

export type TTaskNameList = {
  id?: number;
  name?: string;
  description?: string;
  project_id?: number;
  office_project_id?: number;
  category_id?: number;
  type?: string;
  is_predefined?: number;
  created_by?: number;
  updated_by?: number;
  created_at?: string;
  updated_at?: string;
  deleted_at?: null;
};

export type TTaskTagsList = {
  id: number;
  name: string;
  is_assign?: number;
};

export type TProjectListDailyReport = {
  id: number;
  name: string;
  type: string;
  is_assign?: number;
};

export type TRequestDailyRerpot = {
  id?: number;
  project_id: number;
  category_id: number;
  search?: string;
};
export type TRequestDailyReportPagination = {
  id?: number;
  project_id: number;
  category_id: number;
  search?: string;
  limit:number;
  page:number;

};
export type TRequestDailyRerpotSubmit = {
  data: TSaveRequestData[];
  is_draft: number;
  is_previous_report: number,
  date: string,
  working_status: string,
  reason: string;
  is_need_to_create_new: number,
  longitude: string;
  latitude: string;
};

export type TResponseDailySubmit = {
  status: boolean;
  message: string;
};
export type TSaveRequestData = {
  id: number;
  is_previous: number;
  project_id: number;
  type: string;
  task_id: number;
  task_category_id: number;
  time_spent: string;
};

export type TRequestTaksNameSubmit = {
  type: string;
  project_id: number;
  name: string;
  category_id: number;
};
export type IClockoutRealTime = {
  hours: number;
  minutes: number;
  total_time: string;
  total_time_text: string;
}
export const profileDailyPopupApi = api.injectEndpoints({
  endpoints: (build) => ({
    getProfileDailyReportPopup: build.query<
      TProfileDailyListResponse,
      Partial<TRequestDailyRerpot>
    >({
      query: ({ project_id, category_id }) => ({
        url: `${endpointUrl}daily-reports-form?project_id=${project_id}&category_id=${category_id}`,
        headers: authHeader(),
        method: "GET",
      }),
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
      providesTags: () => [
        { type: "ProfileDailyReportPopup", id: "LIST" },
      ],
    }),

    getProfileDailyTaskList: build.query<
      TProfileDailyTaskResponse,
      Partial<TRequestDailyRerpot>
    >({
      query: ({ project_id, category_id, search }) => ({
        url: `${endpointUrl}daily-reports-form-task-list?project_id=${project_id}&category_id=${category_id}&search=${search}`,
        headers: authHeader(),
        method: "GET",
      }),
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
      providesTags: () => [
        { type: "ProfileDailyReportPopup", id: "LIST" },
      ],
    }),
    getProfileDailyTaskListWithPagination: build.query<
      TProfileDailyTaskPaginationResponse,
      Partial<TRequestDailyReportPagination>
    >({
      query: ({ project_id, category_id, search,page,limit }) => ({
        url: `${endpointUrl}daily-reports-form-task-list-pagination?project_id=${project_id}&category_id=${category_id}&search=${search}&limit=${limit}&page=${page}`,
        headers: authHeader(),
        method: "GET",
      }),
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
      providesTags: () => [
        { type: "ProfileDailyReportPopup", id: "LIST" },
      ],
    }),
    saveProfileDailyReport: build.mutation<
      TResponseDailySubmit,
      Partial<TRequestDailyRerpotSubmit>
    >({
      query: (data) => ({
        url: `${endpointUrl}profile/daily-reports`,
        headers: authHeader(),
        method: "POST",
        body: data,
      }),
      invalidatesTags: [{ type: "ProfileDailyReportPopup", id: "LIST" }, { type: 'ProfileDailyReportRefillPopup' }, { type: 'DailyReportFilterData' }],
    }),

    createProfileTaskName: build.mutation<
      TResponseDailySubmit,
      Partial<TRequestTaksNameSubmit>
    >({
      query: (data) => ({
        url: `${endpointUrl}tasks`,
        headers: authHeader(),
        method: "POST",
        body: data,
      }),
      invalidatesTags: [{ type: "ProfileTaskName", id: "LIST" }],
    }),



    deleteDailyDraft: build.mutation<{ id: number }, number>({
      query: (id) => ({
        url: `${endpointUrl}profile-daily-report-drafts/${id}`,
        method: "DELETE",
        headers: authHeader(),
      }),
      invalidatesTags: [{ type: "ProfileDailyReportPopup", id: "LIST" }],

    }),
    getClockoutRealTime: build.mutation<IClockoutRealTime, void>({
      query: () => ({
        url: `${endpointUrl}profile/clocking-time`,
        headers: authHeader(),
        method: "GET",
      }),
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
      transformResponse: (res: any) => {
        return res;
      },
      invalidatesTags: [{ type: 'ProfileDailyReportPopup', id: "LIST" }],
    }),

  }),
});

export const {
  useGetProfileDailyReportPopupQuery,
  useGetProfileDailyTaskListQuery,
  useSaveProfileDailyReportMutation,
  useCreateProfileTaskNameMutation,
  useDeleteDailyDraftMutation,
  useGetClockoutRealTimeMutation,
  useGetProfileDailyTaskListWithPaginationQuery
} = profileDailyPopupApi;

export const {
  endpoints: {
    getProfileDailyReportPopup,
    saveProfileDailyReport,
    getProfileDailyTaskList,
    createProfileTaskName,
    deleteDailyDraft,
    getClockoutRealTime,
    getProfileDailyTaskListWithPagination
  },
} = profileDailyPopupApi;
