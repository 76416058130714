import ResetPasswordComponent from '../components/ResetPasswordComponent';

function ResetPasswordPage() {
  return (
    <>      
      <ResetPasswordComponent />
    </>
  );
}

export default ResetPasswordPage;
