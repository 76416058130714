import { useCallback, useEffect, useMemo, useState } from "react";
import { baseObj } from "../../utils/constants";
import { ColumnFiltersState, PaginationState, SortingState } from "@tanstack/react-table";
import { useSpring, animated } from "react-spring";
import CommonTableSkeleton from "../../components/TableSkeleton/CommonTableSkeleton";
import classNames from "classnames";
import DebounceSearchInput from "../../components/Tables/DebounceSearchInput";
import {
    AddIcon,
    CustomizeLayoutIcon,
    ExportIcon,
    ImportIcon,
    SearchIcon,
} from "../../components/common/Icons";
import CustomScrollbar from "../../components/CustomScrollbar/CustomScrollbar";
import CustomDropdownListForFilter from "../../components/EmployeeTable/CustomDropdownListForFilter";
import { NumberDropdownList } from "../../components/common/DropdownList";
import { StringDropdownListWithDeleteBtn } from "../../components/common/StringDropdownListWithDeleteBtn";
import { useGetByModuleTemplateQuery } from "../../app/services/template";
import { templateList } from "../../active-time-report/views/ActiveTimeReport";
import ExchangeRateTable, { monthList } from "../../components/ExchangeRate/ExchangeRateTable";
import { CountryCodeData, useCreateExchangeRateMutation, useGetExchangeRateQuery, useUpdatedExchangeRateMutation } from "../../app/services/exchange-rate";
import Button from "../../components/common/Button";
import { currencyData } from "../../app/services/employee-incentive";
import { renderDateFormat } from "../../components/DashboardCalendarView/DashboardAddEventPopup";
import ExchangeRateForm from "./ExchangeRateForm";
import { ToastContainer, toast } from "react-toastify";

const ExchangeRate = () => {
    const props = useSpring({
        to: { opacity: 1 },
        from: { opacity: 0 },
        delay: 1000,
    });
    const defaultFields = useMemo(
        () => [
            "currency_name",
            "date",
            "month",
            "rate",
            "created_by_name",
            "updated_at"
        ],
        []
    );

    const [currency, setCurrency] = useState<any>("All");
    const [sorting, setSorting] = useState<SortingState>([]);
    const [data, setData] = useState<baseObj[]>([]);
    const [currentView, setCurrentView] = useState<any>(0);
    const [currentViewData, setCurrentViewData] = useState<number>(0);
    const [isTemplate, setIsTemplate] = useState<boolean>(false);
    const [viewCols, setCols] = useState<any>([]);
    const [globalSearch, setGlobalSearch] = useState<string>("");
    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
    const [successPopup, setSuccessPopup] = useState<boolean>(false);
    const [errorPopup, setErrorPopup] = useState<boolean>(false);
    const [message, setMessage] = useState<any>([]);
    const [tempList, setTempList] = useState<any>([]);
    const [moduleList, setModuleList] = useState<any>([]);
    let [localModuleId, setLocalModuleId] = useState<number>(0);
    const [hiddenFields, setHiddenFields] = useState<string[]>([]);
    const { data: templateData } = useGetByModuleTemplateQuery(localModuleId);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [currencyList, setCurrencyList] = useState<currencyData[]>([]);
    const [isLayoutControlOpen, setIsLayoutControlOpen] =
        useState<boolean>(false);
    const [createExchangeRate] = useCreateExchangeRateMutation();
    const [itemCount, setItemCount] = useState<number>(data?.length);
    const [updatedExchangeRate] = useUpdatedExchangeRateMutation();
    const [pagination, setPagination] = useState<PaginationState>({
        pageIndex: 1,
        pageSize: 20,
    });
    const { data: exchangeRateData, isLoading } = useGetExchangeRateQuery({
        limit: pagination.pageSize,
        page: currentPage,
        search: globalSearch,
        sorting: '',
        currency: currency
    });
    const [activeRow, setActiveRow] = useState<number | 0>();
    useEffect(() => {
        if (exchangeRateData) {
            setData(exchangeRateData.exchangeRateList.data);
            setCurrencyList(exchangeRateData.currencyList);
            setLocalModuleId(exchangeRateData.module_id);
            setItemCount(exchangeRateData.exchangeRateList.total)
        }

    }, [exchangeRateData]);
    useEffect(() => {
        if (templateData) {
            var updatedTemplate = templateData?.templates.map((x: any, i) => {
                return { ...x, index: i + 1 };
            });
            var defaultTemplate = {
                id: -1,
                index: 0,
                module: 0,
                name: "All",
            };
            setModuleList([defaultTemplate, ...updatedTemplate]);
        }
    }, [templateData?.templates, templateData]);
    useEffect(() => {
        var viewData = templateData?.templates.find(
            (x) => x.id.toString() === currentView.toString()
        );

        setHiddenFields(viewData ? [...viewData.viewColumns] : defaultFields);
    }, [currentView, defaultFields]);

    useEffect(() => {
        if (viewCols?.view_columns?.length > 0) {
            setHiddenFields(viewCols?.view_columns);
        }
    }, [viewCols?.view_columns]);

    useEffect(() => {
        if (moduleList) {
            var lastesTemplate = moduleList[moduleList?.length - 1];
            setCurrentViewData(lastesTemplate ? Number(lastesTemplate?.id) : 0);
        }
    }, [moduleList]);
    const deleteTemplateData = (id: string) => {
        var filterTempList = templateList?.filter((x) => x.id !== parseInt(id));
        setTempList(filterTempList ? filterTempList : []);
    };
    const addNewExchangeRate = () => {
        return {
            id: 0,
            currency: currencyList ? currencyList[0].id : '',
            date: renderDateFormat(new Date(), '/'),
            month: monthList[0].name,
            rate: 0,
            created_at: '',
            updated_at: '',
            isNew: true,
        };
    };
    const handleAddNew = useCallback(() => {
        const newData = addNewExchangeRate();
        setData([newData, ...data]);
        const createdData = {
            id: 0,
            rate: 0,
            currency_id: newData.currency,
            date: newData.date
        }
        createExchangeRate(createdData).then((res: any) => {
            
        })
    }, [setData, data]);
    const updatedExchangeRateData = useCallback(
        (id: any, updatedData: any, edited?: any) =>
            setData((prev: baseObj[]) => {
                return prev.map((data, index: number) => {
                    if (data.id == id) {
                        if (edited) {
                            return { ...data, [edited.cellId]: edited.value };
                        } else {
                            return { ...data, updatedData };
                        }

                    }
                    return data;
                });
            }),
        [setData]
    );
    const editExchangeRate = (edited: any) => {
        const existingData = data?.find(x => x.id == edited.id);
        const fieldValue = edited.value ? parseFloat(edited.value) : 0;
        var editedData = {
            "id": edited.id ? edited.id : existingData?.id,
            "currency_id": edited.cellId == "currency" ? fieldValue : parseFloat(existingData?.currency_id),
            "date": existingData?.date,
            "month": existingData?.month,
            "rate": edited.cellId == "rate" ? fieldValue : parseFloat(existingData?.rate),
            "isNew": edited.id == 0 ? true : false
        }
        if (editedData.id != 0 && editedData.id != null) {
            updatedExchangeRateData(edited.id, existingData, edited)
            updatedExchangeRate(editedData).then((res: any) => {
                updatedExchangeRateData(res?.data?.data.id, res?.data?.data)
                setActiveRow(0);
                toast(res?.data?.message, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    type: "success",
                });
            }).catch((error: any) => {
                
                toast("Error.", {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    type: "warning",
                });
            })
        }
    }
    return <>
        {isLoading ? (
            <CommonTableSkeleton rows={12} />
        ) : (
            <>
                <ToastContainer className={"text-12 font-poppins font-normal"} />
                <animated.div style={props}>
                    <div
                        className={classNames(
                            "px-2 sm:px-4 py-4 bg-white sm:mt-[25px] mt-0 font-poppins text-13 font-semibold text-[#858795] tracking-[-0.03rem] w-full h-full rounded-xl pt-3 pb-8 sm:pt-3 sm:pb-9 "
                        )}
                    >

                        {data?.map((exchange, key) => {
                            return <div key={key}>
                                <ExchangeRateForm activeRow={activeRow}
                                    setActiveRow={setActiveRow}
                                    currencyList={currencyList}
                                    editExchangeRate={editExchangeRate}
                                    exchange={exchange}
                                    exchangeRateData={exchangeRateData} />
                            </div>
                        })}

                        {/* <ExchangeRateTable
                            setCurrentView={setCurrentView}
                            data={data}
                            setData={setData}
                            pagination={pagination}
                            setPagination={setPagination}
                            isLayoutControlOpen={isLayoutControlOpen}
                            setIsLayoutControlOpen={setIsLayoutControlOpen}
                            globalSearch={globalSearch}
                            setGlobalSearch={setGlobalSearch}
                            columnFilters={columnFilters}
                            setColumnFilters={setColumnFilters}
                            orgDataList={[]}
                            hiddenFields={hiddenFields ? hiddenFields : []}
                            setHiddenFields={setHiddenFields}
                            templateList={tempList}
                            localModuleId={localModuleId}
                            total={itemCount}
                            // changePage={changePage}
                            setCurrentPage={setCurrentPage}
                            currentPage={currentPage}
                            setIsTemplate={setIsTemplate}
                            setCols={setCols}
                            sorting={sorting}
                            setSorting={setSorting}
                            currencyList={currencyList?.map((cur) => {
                                return {
                                    id: cur.id,
                                    name: cur.currency_name,
                                    label: cur.currency_code
                                }
                            })}
                            setCurrencyList={setCurrencyList}

                        /> */}


                    </div>
                </animated.div>
            </>
        )}
    </>
}
export default ExchangeRate;