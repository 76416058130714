import { CircularProgress, Fade, Modal } from "@mui/material";
import {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
  useRef,
} from "react";
import { CloseIcon } from "../common/Icons";
import classNames from "classnames";
import CustomDatePicker from "../common/BaseDatePicker";
import styles from "./LeaveAllowancePopup.module.scss";
import { renderDateFormat } from "../DashboardCalendarView/DashboardAddEventPopup";

interface ILeaveAllowance {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  saveLeaveAllowanceData: any;
  leaveAllowanceList: any;
  leaveAllowances?: any;
  employeeID?: string;
  leaveAllowanceLoading?: boolean;
}

const LeaveAllowancePopup: FC<ILeaveAllowance> = ({
  isOpen,
  setIsOpen,
  saveLeaveAllowanceData,
  leaveAllowanceList,
  employeeID,
  leaveAllowanceLoading,
  // leaveAllowances,
}) => {
  const [leaveLists, setLeaveLists] = useState<any>([]);
  const annualLeaveRef = useRef<any>(null);
  const birthdayRef = useRef<any>(null);
  const sickRef = useRef<any>(null);
  const unpaidRef = useRef<any>(null);
  const maternityRef = useRef<any>(null);
  const paternityRef = useRef<any>(null);
  const substitutionRef = useRef<any>(null);
  const leftOverRef = useRef<any>(null);
  const childCareRef = useRef<any>(null);
  const [leftOverLeaveDate, setLeftOverLeaveDate] = useState<Date | undefined>(
    new Date()
  );

  useEffect(() => {
    // console.log("leaveAllowanceList ",leaveAllowanceList)
    setLeaveLists(leaveAllowanceList);
    setLeftOverLeaveDate(
      leaveAllowanceList[7]?.expired_at
        ? new Date(leaveAllowanceList[7]?.expired_at)
        : new Date()
    );
  }, [leaveAllowanceList]);

  // const [ values, setValue]=useState(0)
  // const handleWheel = (event: React.WheelEvent<HTMLInputElement>) => {
  //     console.log('e', event.target)
  //     if (event.deltaY > 0) {
  //       // Scroll down, decrement value
  //       setValue((prevValue) => prevValue - 1);
  //     } else if (event.deltaY < 0) {
  //       // Scroll up, increment value
  //       setValue((prevValue) => prevValue + 1);
  //     }
  //   }
  // console.log('values', leaveAllowanceList)

  const saveLeaveAllowance = () => {
    let data = [
      {
        leave_allowance_id: leaveAllowanceList[0]?.id,
        leave_type_id: 1,
        allowance: Number(annualLeaveRef.current?.value),
        expired_at: null,
      },
      {
        leave_allowance_id: leaveAllowanceList[1]?.id,
        leave_type_id: 2,
        allowance: Number(birthdayRef.current?.value),
        expired_at: null,
      },
      {
        leave_allowance_id: leaveAllowanceList[2]?.id,
        leave_type_id: 3,
        allowance: Number(sickRef.current?.value),
        expired_at: null,
      },
      {
        leave_allowance_id: leaveAllowanceList[3]?.id,
        leave_type_id: 4,
        allowance: Number(unpaidRef.current?.value),
        expired_at: null,
      },
      {
        leave_allowance_id: leaveAllowanceList[4]?.id,
        leave_type_id: 5,
        allowance: Number(maternityRef.current?.value),
        expired_at: null,
      },
      {
        leave_allowance_id: leaveAllowanceList[5]?.id,
        leave_type_id: 6,
        allowance: Number(paternityRef.current?.value),
        expired_at: null,
      },
      {
        leave_allowance_id: leaveAllowanceList[6]?.id,
        leave_type_id: 7,
        allowance: Number(substitutionRef.current?.value),
        expired_at: null,
      },
      {
        leave_allowance_id: leaveAllowanceList[7]?.id,
        leave_type_id: 8,
        allowance: Number(leftOverRef.current?.value),
        expired_at: leftOverLeaveDate
          ? renderDateFormat(leftOverLeaveDate, "-")
          : null,
      },
      {
        leave_allowance_id: leaveAllowanceList[8]?.id,
        leave_type_id: 9,
        allowance: Number(childCareRef.current?.value),
        expired_at: null,
      },
    ];
    saveLeaveAllowanceData(data);
  };

  return (
    <>
      <Modal
        open={isOpen}
        style={{
          background: "rgb(0 0 0 / 0.3)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        onClose={(event, reason) => {
          if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
            setIsOpen(false);
          }
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableEnforceFocus={true}
      >
        <Fade in={isOpen} timeout={500}>
          <div className="modalContent max-w-[550px] w-full m-auto px-4 py-8 xl:px-0 xl:py-0 font-primary">
            <div className="w-full bg-white rounded-xl overflow-hidden">
              <div className="px-6 sm:px-8 md:px-10 py-6 bg-vorpblue flex justify-between items-center rounded-t-xl overflow-hidden">
                <h1 className="text-16 sm:text-18 leading-[27px] font-semibold text-white">
                  Leave Allowance
                </h1>
                <button type="button" onClick={() => setIsOpen(false)}>
                  <CloseIcon width={18} height={18} />
                </button>
              </div>
              <div className="px-4 sm:px-6 lg:px-10 py-6 pb-8 lg:py-8 lg:pb-10">
                <h1 className="font-poppins lg:text-24 text-20 text-[#5E6278]">
                  {employeeID}
                </h1>
                {leaveAllowanceList && (
                  <div
                    className={classNames(styles.customLeaveAllowanceContent)}
                  >
                    <div className="flex items-center mt-[11px]">
                      <p className="min-w-[139px] mr-5 font-poppins lg:text-[15px] text-12 text-[#5E6278]">
                        {leaveAllowanceList[0]?.leaveType ?? "Annual Leave"}
                      </p>
                      <div className={styles.customNumber}>
                        <input
                          min="0"
                          defaultValue={
                            leaveLists ? leaveLists[0]?.allowance : 0
                          }
                          ref={annualLeaveRef}
                          type="number"
                          className="focus:outline-none focus-visible:outline-none max-w-[100px] border-1 border-[#B5B5C3] rounded-[4px] px-3 py-[9px]"
                        />
                      </div>
                    </div>
                    <div className="flex items-center mt-[11px]">
                      <p className="min-w-[139px] mr-5 font-poppins lg:text-[15px] text-12 text-[#5E6278]">
                        {leaveAllowanceList[1]?.leaveType ?? "Birthday Leave"}
                      </p>
                      <div className={styles.customNumber}>
                        <input
                          min="0"
                          defaultValue={
                            leaveLists ? leaveLists[1]?.allowance : 0
                          }
                          ref={birthdayRef}
                          type="number"
                          className="focus:outline-none focus-visible:outline-none max-w-[100px] border-1 border-[#B5B5C3] rounded-[4px] px-3 py-[9px]"
                        />
                        {/* onWheel={handleWheel} step="1" */}
                      </div>
                    </div>
                    <div className="flex items-center mt-[11px]">
                      <p className="min-w-[139px] mr-5 font-poppins lg:text-[15px] text-12 text-[#5E6278]">
                        {leaveAllowanceList[2]?.leaveType ?? "Sick Leave"}
                      </p>
                      <div className={styles.customNumber}>
                        <input
                          min="0"
                          defaultValue={
                            leaveLists ? leaveLists[2]?.allowance : 0
                          }
                          ref={sickRef}
                          type="number"
                          className="focus:outline-none focus-visible:outline-none max-w-[100px] border-1 border-[#B5B5C3] rounded-[4px] px-3 py-[9px]"
                        />
                      </div>
                    </div>
                    <div className="flex items-center mt-[11px]">
                      <p className="min-w-[139px] mr-5 font-poppins lg:text-[15px] text-12 text-[#5E6278]">
                        {leaveAllowanceList[3]?.leaveType ?? "Unpaid Leave"}
                      </p>
                      <div className={styles.customNumber}>
                        <input
                          min="0"
                          defaultValue={
                            leaveLists ? leaveLists[3]?.allowance : 0
                          }
                          ref={unpaidRef}
                          type="number"
                          className="focus:outline-none focus-visible:outline-none max-w-[100px] border-1 border-[#B5B5C3] rounded-[4px] px-3 py-[9px]"
                        />
                      </div>
                    </div>
                    <div className="flex items-center mt-[11px]">
                      <p className="min-w-[139px] mr-5 font-poppins lg:text-[15px] text-12 text-[#5E6278]">
                        {leaveAllowanceList[4]?.leaveType ?? "Maternity Leave"}
                      </p>
                      <div className={styles.customNumber}>
                        <input
                          min="0"
                          defaultValue={
                            leaveLists ? leaveLists[4]?.allowance : 0
                          }
                          ref={maternityRef}
                          type="number"
                          className="focus:outline-none focus-visible:outline-none max-w-[100px] border-1 border-[#B5B5C3] rounded-[4px] px-3 py-[9px]"
                        />
                      </div>
                    </div>
                    <div className="flex items-center mt-[11px]">
                      <p className="min-w-[139px] mr-5 font-poppins lg:text-[15px] text-12 text-[#5E6278]">
                        {leaveAllowanceList[5]?.leaveType ?? "Paternity Leave"}
                      </p>
                      <div className={styles.customNumber}>
                        <input
                          min="0"
                          defaultValue={
                            leaveLists ? leaveLists[5]?.allowance : 0
                          }
                          ref={paternityRef}
                          type="number"
                          className="focus:outline-none focus-visible:outline-none max-w-[100px] border-1 border-[#B5B5C3] rounded-[4px] px-3 py-[9px]"
                        />
                      </div>
                    </div>
                    <div className="flex items-center mt-[11px]">
                      <p className="min-w-[139px] mr-5 font-poppins lg:text-[15px] text-12 text-[#5E6278]">
                        {leaveAllowanceList[6]?.leaveType ??
                          "Substitution Leave"}
                      </p>
                      <div className={styles.customNumber}>
                        <input
                          min="0"
                          defaultValue={
                            leaveLists ? leaveLists[6]?.allowance : 0
                          }
                          ref={substitutionRef}
                          type="number"
                          className="focus:outline-none focus-visible:outline-none max-w-[100px] border-1 border-[#B5B5C3] rounded-[4px] px-3 py-[9px]"
                        />
                      </div>
                    </div>
                    <div className="flex items-center mt-[11px]">
                      <p className="min-w-[139px] mr-5 font-poppins lg:text-[15px] text-12 text-[#5E6278]">
                        {leaveAllowanceList[7]?.leaveType ?? "Leftover Leave"}
                      </p>
                      <div className={styles.customNumber}>
                        <input
                          min="0"
                          defaultValue={
                            leaveLists ? leaveLists[7]?.allowance : 0
                          }
                          ref={leftOverRef}
                          type="number"
                          className="focus:outline-none focus-visible:outline-none max-w-[100px] border-1 border-[#B5B5C3] rounded-[4px] px-3 py-[9px]"
                        />
                      </div>
                      <div className={styles.customLeaveDatepicker}>
                        <CustomDatePicker
                          date={leftOverLeaveDate}
                          setDate={setLeftOverLeaveDate}
                         // minDate={new Date()}
                          isFilterSection={false}
                          placeholder={new Date().toDateString()}
                          iconColor="#858795"
                          className="px-2"
                        />
                      </div>
                    </div>
                    <div className="flex items-center mt-[11px]">
                      <p className="min-w-[139px] mr-5 font-poppins lg:text-[15px] text-12 text-[#5E6278]">
                        {leaveAllowanceList[8]?.leaveType ??
                          "Child Care Leave"}
                      </p>
                      <div className={styles.customNumber}>
                        <input
                          min="0"
                          defaultValue={
                            leaveLists ? leaveLists[8]?.allowance : 0
                          }
                          ref={childCareRef}
                          type="number"
                          className="focus:outline-none focus-visible:outline-none max-w-[100px] border-1 border-[#B5B5C3] rounded-[4px] px-3 py-[9px]"
                        />
                      </div>
                    </div>
                  </div>
                )}

                <div className="flex items-center mt-9">
                  <button
                    type="button"
                    onClick={saveLeaveAllowance}
                    className={classNames(
                      leaveAllowanceLoading
                        ? " pointer-events-none opacity-50"
                        : "",
                      "flex items-center py-2 px-4 bg-vorpblue rounded-md text-white lg:text-14 text-12 leading-[18px] font-medium"
                    )}
                  >

                    {leaveAllowanceLoading ? (
                      <div className="flex items-center justify-center w-full">
                        <CircularProgress
                          sx={{ color: "white" }}
                          className="w-5 h-5"
                          style={{ width: "20px", height: "20px" }}
                        />
                        <span className="ml-2">Saving</span>
                      </div>
                    ) : (
                      <div className="flex items-center">
                        <div className="mr-3">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            viewBox="0 0 14 14"
                            fill="none"
                          >
                            <path
                              d="M1.75 0.875C1.51794 0.875 1.29538 0.967187 1.13128 1.13128C0.967187 1.29538 0.875 1.51794 0.875 1.75V12.25C0.875 12.4821 0.967187 12.7046 1.13128 12.8687C1.29538 13.0328 1.51794 13.125 1.75 13.125H12.25C12.4821 13.125 12.7046 13.0328 12.8687 12.8687C13.0328 12.7046 13.125 12.4821 13.125 12.25V1.75C13.125 1.51794 13.0328 1.29538 12.8687 1.13128C12.7046 0.967187 12.4821 0.875 12.25 0.875H8.3125C8.08044 0.875 7.85788 0.967187 7.69378 1.13128C7.52969 1.29538 7.4375 1.51794 7.4375 1.75V8.13138L9.75275 5.81525C9.8349 5.7331 9.94632 5.68695 10.0625 5.68695C10.1787 5.68695 10.2901 5.7331 10.3722 5.81525C10.4544 5.8974 10.5006 6.00882 10.5006 6.125C10.5006 6.24118 10.4544 6.3526 10.3722 6.43475L7.30975 9.49725C7.26911 9.53799 7.22083 9.57032 7.16768 9.59237C7.11453 9.61443 7.05755 9.62578 7 9.62578C6.94245 9.62578 6.88547 9.61443 6.83232 9.59237C6.77917 9.57032 6.73089 9.53799 6.69025 9.49725L3.62775 6.43475C3.58707 6.39407 3.55481 6.34578 3.53279 6.29264C3.51078 6.23949 3.49945 6.18253 3.49945 6.125C3.49945 6.06747 3.51078 6.01051 3.53279 5.95736C3.55481 5.90422 3.58707 5.85593 3.62775 5.81525C3.66843 5.77457 3.71672 5.74231 3.76986 5.72029C3.82301 5.69828 3.87997 5.68695 3.9375 5.68695C3.99503 5.68695 4.05199 5.69828 4.10514 5.72029C4.15828 5.74231 4.20657 5.77457 4.24725 5.81525L6.5625 8.13138V1.75C6.5625 1.28587 6.74687 0.840752 7.07506 0.512563C7.40325 0.184374 7.84837 0 8.3125 0L12.25 0C12.7141 0 13.1592 0.184374 13.4874 0.512563C13.8156 0.840752 14 1.28587 14 1.75V12.25C14 12.7141 13.8156 13.1592 13.4874 13.4874C13.1592 13.8156 12.7141 14 12.25 14H1.75C1.28587 14 0.840752 13.8156 0.512563 13.4874C0.184374 13.1592 0 12.7141 0 12.25V1.75C0 1.28587 0.184374 0.840752 0.512563 0.512563C0.840752 0.184374 1.28587 0 1.75 0L3.9375 0C4.05353 0 4.16481 0.0460936 4.24686 0.128141C4.32891 0.210188 4.375 0.321468 4.375 0.4375C4.375 0.553532 4.32891 0.664812 4.24686 0.746859C4.16481 0.828906 4.05353 0.875 3.9375 0.875H1.75Z"
                              fill="white"
                            />
                          </svg>
                        </div>
                        <span>Save</span>
                      </div>
                    )}
                  </button>
                  <button
                    type="button"
                    onClick={() => setIsOpen(false)}
                    className={classNames(
                      "flex items-center bg-offwhite px-3 py-2 rounded-md ml-5 lg:text-14 text-12 leading-[18px] font-medium text-[#464E5F]"
                    )} //isImporting && isOpen ? 'opacity-50 pointer-events-none' : '',
                  >
                    <div className="mr-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                      >
                        <path
                          d="M12.6667 4.27301L11.7267 3.33301L8.00001 7.05967L4.27334 3.33301L3.33334 4.27301L7.06001 7.99967L3.33334 11.7263L4.27334 12.6663L8.00001 8.93967L11.7267 12.6663L12.6667 11.7263L8.94001 7.99967L12.6667 4.27301Z"
                          fill="#464E5F"
                        />
                      </svg>
                    </div>
                    <span>Cancel</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </>
  );
};
export default LeaveAllowancePopup;
