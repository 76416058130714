import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { PaginationState, SortingState } from "@tanstack/react-table";
// import CustomDatePicker from "../../common/BaseDatePicker";
import {
  NumberDropdownList,
  // StringDropdownList,
} from "../../common/DropdownList";
import styles from "./MyLeave.module.scss";

import { ILeaveStatus, baseObj, leaveStatuses } from "../../../utils/constants";
import Button from "../../common/Button";
import PersonalLeaveTable from "./PersonalLeaveTable";
// import { Modal, Fade } from "@material-ui/core";
import { Modal, Fade } from "@mui/material";
import LeaveApplication from "./LeaveApplication";
import PersonalLeaveBalanceTable from "./PersonalLeaveBalanceTable";
import { ColumnFiltersState } from "@tanstack/react-table";
import { IFilterDate } from "../Documents/Documents";
import Styles from "./MyLeave.module.scss";
import { CloseIcon, DocumentIcon, EditIcon } from "../../common/Icons";
import {
  MyAuthLeaveBalanceResponse,
  useCreateCancelLeaveMutation,
  useCreateLeaveDataMutation,
  useGetMyAuthLeaveAllMutation,
  useGetMyAuthLeaveBalanceQuery,
  useGetMyAuthLeaveQuery,
  // useGetMyAuthLeaveTypesQuery,
} from "../../../app/services/profile";
import { renderDateFormatYMD } from "../../DashboardCalendarView/DashboardAddEventPopup";
import { useLocation } from "react-router-dom";
import classNames from "classnames";
import { getAuthUser } from "../../../app/services/dashboard";
import DatePopupFoFilter from "../../EmployeeTable/DatePopupFoFilter";
import CustomDropdownListForFilter from "../../EmployeeTable/CustomDropdownListForFilter";
import CustomScrollbar from "../../CustomScrollbar/CustomScrollbar";
import { useSpring, animated } from "react-spring";
import CommonTableSkeleton from "../../TableSkeleton/CommonTableSkeleton";
import LeaveAllowancePopup from "../../LeaveAllowancePopup/LeaveAllowancePopup";
import {
  ILeaveAllowanceType,
  useGetLeaveAllowanceQuery,
  useSaveLeaveAllowanceMutation,
} from "../../../app/services/leave-application";
import { ToastContainer, toast } from "react-toastify";
import { useViewNotificationByTypeMutation } from "../../../app/services/notification";
import { Tdocuments } from "../../../leave-application/views/LeaveApplication";
import LeaveApplicationFilesPopup from "../../LeaveApplicationFIlesPopup/LeaveApplicationFilesPopup";
import { useSelector } from "react-redux";
import TablePaginator from "../../Tables/TablePaginator";
// import { hideAllNoneField } from "../../Tables/TablePaginator";

const ProfileLeaveSection: FC = () => {
  const [status, setStatus] = useState<ILeaveStatus>("");
  const [dateClean, setDateClean] = useState<boolean>(false);
  const [filterDate, setFilterDate] = useState<IFilterDate>();
  // const { data: leaveTypes } = useGetMyAuthLeaveTypesQuery();
  const [createLeaveData] = useCreateLeaveDataMutation();
  const [start, setStart] = useState<any>("");
  const [end, setEnd] = useState<any>("");
  const location = useLocation();
  //var id = location.state;
  const [state, setState] = useState(localStorage.getItem("state"));
  //var id = state ? Number(state) : 0;
  var currentUrl = window.location.pathname
    .split("/employeeprofile/")[1]
    ?.split("/")[0];
  var id = currentUrl ? parseInt(currentUrl) : 0;
  // const { data: leaveAllowances } = useGetLeaveAllowanceQuery({ id: id });
  const [leaveBalanceData, setLeaveBalanceData] =
    useState<MyAuthLeaveBalanceResponse>();
  const { data: LeaveBalanceList } = useGetMyAuthLeaveBalanceQuery(id ? id : 0);
  const [LeaveBalanceAll] = useGetMyAuthLeaveAllMutation();
  const [isLeaveApplicationOpen, setIsLeaveApplicationOpen] =
    useState<boolean>(false);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [myLeavesListData, setMyLeavesListData] = useState<any>([]);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 1,
    pageSize: 10,
  });
  const [showLeaveFile, setShowLeaveFile] = useState<boolean>(false);
  const [leaveDocuments, setLeaveDocuments] = useState<Tdocuments[]>([]);
  const [leaveAllowanceList, setLeaveAllowanceList] = useState<any>([]);
  const [viewLeave, setViewLeave] = useState<boolean>(false);
  const [createLeave, setCreateLeave] = useState<boolean>(false);
  const [manageAllLeaveApplication, setManageAllLeaveApplication] =
    useState<boolean>(false);
  const [manageLeaveApproval, setManageLeaveApproval] =
    useState<boolean>(false);
  const [cancelMyLeave, setCancelMyLeave] = useState<boolean>(false);
  const [showLeaveAllowance, setShowLeaveAllowance] = useState<boolean>(false);
  // const [hiddenFields, setLeftOverLeaveData] = useState<string[]>([]);
  const [data, setData] = useState<any>([]);
  const [itemCount, setItemCount] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [saveLeaveAllowance, { isLoading: leaveAllowanceLoading }] =
    useSaveLeaveAllowanceMutation();
  const [viewLeaveByType] = useViewNotificationByTypeMutation();
  const [sorting, setSorting] = useState<SortingState>([]);
  const stateData: any = useSelector((state) => state);
  const [createCancelLeave] = useCreateCancelLeaveMutation();
  const [editedData, setEditedData] = useState<any>();
  const [isConfirmCancelOpen, setIsConfirmCancelOpen] =
    useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const {
    data: myLeavesList,
    isFetching,
    isLoading,
  } = useGetMyAuthLeaveQuery({
    status: status,
    start: start,
    end: end,
    id: id ? id : 0,
    page: currentPage,
    limit: pagination.pageSize,
    sort: sorting
      .map(
        (s) =>
          `${s.desc ? "-" : ""}${s.id == "appliedAt"
            ? "applied_at"
            : s.id == "from"
              ? "from_date"
              : s.id == "to"
                ? "to_date"
                : s.id == "ID"
                  ? "code"
                  : s.id
          }`
      )
      .join(","),
  });

  var getUser = getAuthUser();
  const getAuthPermis: string[] = useMemo(() => [], []);
  if (getUser?.permissions) {
    const getPer = getUser?.permissions?.forEach((per: any) => {
      getAuthPermis.push(per?.name);
    });
  }
  useEffect(() => {
    localStorage.removeItem("state");
  }, []);

  useEffect(() => {
    if (location.state) {
      setState(location.state);
    }
  }, [location]);

  useEffect(() => {
    const viewLeave = getAuthPermis.filter(
      (f) => f == "View_Leave_Application"
    );
    const createLeave = getAuthPermis.filter((f) => f == "Create_My_Leave");
    const manageAllLeaveApplication = getAuthPermis.filter(
      (f) => f == "Manage_All_Leave_Application"
    );
    const manageLeaveApproval = getAuthPermis.filter(
      (f) => f == "Manage_Leave_Approval"
    );
    const cancelMyLeave = getAuthPermis.filter((f) => f == "Cancel_My_Leave");

    if (cancelMyLeave.length > 0) {
      setCancelMyLeave(true);
    }

    if (manageLeaveApproval.length > 0) {
      setManageLeaveApproval(true);
    }
    if (manageAllLeaveApplication.length > 0) {
      setManageAllLeaveApplication(true);
    }
    if (createLeave.length > 0) {
      setCreateLeave(true);
    }
    if (viewLeave.length > 0) {
      setViewLeave(true);
    }
  }, [getUser]);

  useEffect(() => {
    if (dateClean) {
      setStart("");
    } else {
      setStart(
        filterDate?.startDate
          ? renderDateFormatYMD(new Date(filterDate?.startDate), "-")
          : ""
      );
    }
  }, [filterDate?.startDate, dateClean]);

  useEffect(() => {
    if (dateClean) {
      setEnd("");
    } else {
      setEnd(
        filterDate?.endDate
          ? renderDateFormatYMD(new Date(filterDate?.endDate), "-")
          : ""
      );
    }
  }, [filterDate?.endDate, dateClean]);
  // console.log("myLeavesList", myLeavesList)
  useEffect(() => {
    // console.log("myLeavesList ",myLeavesList)
    var data = myLeavesList?.statusData?.map((x: any, i: number) => {
      return { id: i + 1, name: x, label: x };
    });
    if (data) {
      //setMyLeavesListData([{ name: "All", id: 0, label: "All" }, ...data]);
    }
    if (myLeavesList) {
      if (myLeavesList?.leaveData?.data) {
        setData(myLeavesList?.leaveData?.data);
        setItemCount(myLeavesList?.leaveData?.total);
      } else {
        setData([]);
      }
    } else {
      setData([]);
    }
  }, [myLeavesList, isFetching]);

  // useEffect(() => {
  //   if (Number(id) !== 0) {
  //     if (leaveAllowances?.data) {
  //       setLeaveAllowanceList(leaveAllowances?.data);
  //     }
  //   }
  // }, [leaveAllowances?.data]);
  useEffect(() => {
    if (LeaveBalanceList) {
      setLeaveBalanceData(LeaveBalanceList);
      setLeaveAllowanceList(LeaveBalanceList.leavesInfo);
      setMyLeavesListData(LeaveBalanceList.statusData)
    }
  }, [LeaveBalanceList]);

  var available = 0;
  if (LeaveBalanceList?.previousBalance?.available) {
    available = LeaveBalanceList?.previousBalance?.available;
  } else {
    available = 0;
  }

  const props = useSpring({
    to: { opacity: 1 },
    from: { opacity: 0 },
    delay: 500,
  });
  const saveCancel = (rowId: number) =>
    createCancelLeave(rowId).then((res: any) => {
      if (res.data.status) {
        setIsConfirmCancelOpen(false);
        setIsSuccess(true);
        toast(res?.data?.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          type: "success",
        });
      } else if (res?.error) {
        setIsConfirmCancelOpen(true);
        setIsSuccess(false);
        toast(res?.data?.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          type: "error",
        });
      }
    });
  const saveLeaveAllowanceData: any = (
    leaveAllowance: ILeaveAllowanceType[]
  ) => {
    const data = {
      id: id,
      data: { data: leaveAllowance },
    };

    saveLeaveAllowance(data)
      .then((res: any) => {
        if (res.data.status) {
          LeaveBalanceAll({ id: id ? id : 0 });
          setShowLeaveAllowance(false);
          toast("Successfully Updated.", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            type: "success",
          });
        } else {
          setShowLeaveAllowance(true);
        }
      })
      .catch((error: any) => { });
  };

  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  useEffect(() => {
    setTimeout(() => {
      var stateDataDetail = stateData?.dashboard?.notificationDetail;
      var updatedData = {
        profile_unread_noti_count: stateDataDetail?.profile_unread_noti_count,
        unapprove_claim_count: stateDataDetail.unapprove_claim_count,
        unapprove_leave_count: stateDataDetail?.unapprove_leave_count,
        unapprove_ot_count: stateDataDetail?.unapprove_ot_count,
        unread_data_count: {
          unread_password_count:
            stateDataDetail?.unread_data_count?.unread_password_count,
          unread_document_count: 0,
          unread_leave_count:
            stateDataDetail?.unread_data_count?.unread_leave_count,
        },
        unread_count: stateDataDetail?.unread_count,
        notifications: stateDataDetail?.notifications,
      };
      viewLeaveByType("leave");
    }, 5000);
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);
  return (
    <>
      {isLoading ? (
        <CommonTableSkeleton rows={12} isProfile={true} />
      ) : (
        <>
          {showLeaveFile ? (
            leaveDocuments.length ? (
              <LeaveApplicationFilesPopup
                isOpen={showLeaveFile}
                setIsOpen={setShowLeaveFile}
                leaveDocuments={leaveDocuments}
              />
            ) : null
          ) : null}

          <ToastContainer className={"text-12 font-poppins font-normal"} />
          <div className="w-full bg-white rounded-xl text-left p-5 relative">
            {windowSize[0] < 640 ? (
              <>
                <div className="py-5 md:py-2 border-b border-offwhite flex items-center justify-between">
                  {/* <div className="flex mt-1 sm:w-auto w-full sm:flex-nowrap flex-wrap items-center">
                  <div className=" mb-1 flex items-center xl:ml-3 mr-[10px]">
                    <p className="base-label text-12 xl:text-14">DISPLAY</p>
                    <NumberDropdownList
                      state={pagination.pageSize}
                      setState={(value) =>
                        setPagination((prev) => ({
                          ...prev,
                          pageSize: value as number,
                        }))
                      }
                      dropdownItems={new Array(5)
                        .fill(1)
                        .map((_, index: number) => {
                          let display = (index + 1) * 10;
                          return {
                            value: display,
                            label: String(display),
                          };
                        })}
                      className="max-w-[55px] 4xs:max-w-[60px] lg:max-w-[72px]"
                      textAlign="text-left pl-3"
                      paddingClass="py-[2px]"
                      placeholder="20"
                    />
                  </div>
                </div> */}
                  <h3 className="text-[#3F4254] font-semibold text-[13px] sm:text-[15px] 2xl:text-[18px] leading-[22px] lg:leading-[27px]">
                    My Leave
                  </h3>
                  <Button
                    label={
                      <div className="flex items-center sm:text-[10px] md:text-[14px]  leading-[21px] font-medium text-white">
                        <DocumentIcon className="mr-2" />
                        Leave Application
                      </div>
                    }
                    onClick={() => setIsLeaveApplicationOpen(true)}
                    type="button"
                    variant="primary"
                    customClass={
                      id
                        ? id != 0
                          ? "hidden"
                          : ""
                        : "" + " hover:bg-vorpblue hover:text-white px-1 py-0"
                    }
                    size="base"
                  />
                </div>

                <div className="flex flex-col w-full gap-y-3 pb-3 justify-between items-center ">
                  <div className="flex w-full">
                    <p className="hidden mb-3 2xs:mb-0 text-13 leading-5 font-medium text-black2 mr-3">
                      DATE
                    </p>
                    {filterDate?.startDate && filterDate?.endDate && (
                      <button
                        type="button"
                        onClick={() => {
                          setDateClean(true);
                          setFilterDate({
                            startDate: "",
                            endDate: "",
                          });
                        }}
                        className="text-12 mr-2"
                      >
                        <CloseIcon color="#EE436D" width={10} height={10} />
                      </button>
                    )}

                    <div className="flex justify-between w-full">
                      <div
                        className={`mr-3 ${windowSize[0] < 640 ? "w-full" : ""
                          }`}
                      >
                        <DatePopupFoFilter
                          classesProps="min-w-full"
                          placeholder="Start"
                          date={filterDate?.startDate}
                          setDate={(value) => {
                            setFilterDate((prev) => ({
                              ...prev,
                              startDate: value as Date,
                            }));
                            setDateClean(false);
                          }}
                        />
                      </div>
                      <div className={`mr-0 w-full`}>
                        {/* ${Styles.leaveDatePicker} */}
                        <DatePopupFoFilter
                          classesProps="min-w-full"
                          placeholder="End"
                          date={filterDate?.endDate}
                          setDate={(value) => {
                            setFilterDate((prev) => ({
                              ...prev,
                              endDate: value as Date,
                            }));
                            setDateClean(false);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex w-full">
                    <p className="hidden mb-3 2xs:mb-0 text-13 leading-5 font-medium text-black2 mr-3">
                      STATUS
                    </p>
                    <CustomDropdownListForFilter
                      isFilter={true}
                      placeholder="Status"
                      state={status}
                      setState={setStatus}
                      dropdownItems={myLeavesListData?.map((status: any) => ({
                        value: status.name,
                        label: status.label,
                      }))}
                      className="max-h-[38px] max-w-[135px]"
                    />
                  </div>
                </div>
              </>
            ) : (
              <div className="px-2 sm:px-4 md:px-5 py-1 border-b border-offwhite flex items-center justify-between">
                <h3 className="text-[#3F4254] font-semibold text-[13px] sm:text-[15px] 2xl:text-[18px] leading-[22px] lg:leading-[27px]">
                  My Leave
                </h3>
                {Number(id) == 0 ? (
                  <Button
                    label={
                      <div className="flex items-center sm:text-[10px] md:text-[14px] leading-[21px] font-medium text-white">
                        <DocumentIcon className="mr-2" />
                        Leave Application
                      </div>
                    }
                    onClick={() => {
                      setIsLeaveApplicationOpen(true);
                      setEditedData({});
                    }}
                    type="button"
                    variant="primary"
                    customClass={
                      id
                        ? id != 0
                          ? "hidden"
                          : ""
                        : "" + " hover:bg-vorpblue hover:text-white px-2 py-1"
                    }
                    size="base"
                  />
                ) : null}
              </div>
            )}

            <LeaveAllowancePopup
              saveLeaveAllowanceData={saveLeaveAllowanceData}
              isOpen={showLeaveAllowance}
              setIsOpen={setShowLeaveAllowance}
              leaveAllowanceList={leaveAllowanceList}
              leaveAllowanceLoading={leaveAllowanceLoading}
            />

            <Modal
              open={isLeaveApplicationOpen}
              style={{
                background: "rgb(0 0 0 / 0.5)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              onClose={(event, reason) => {
                if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
                  setIsLeaveApplicationOpen(false);
                }
              }}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              disableEnforceFocus={true}
            >
              <Fade in={isLeaveApplicationOpen} timeout={500}>
                <div className="max-w-[1000px] w-full m-auto">
                  <LeaveApplication
                    setIsLeaveApplicationOpen={setIsLeaveApplicationOpen}
                    leaveTypeList={LeaveBalanceList?.leaveTypes}
                    createLeaveData={createLeaveData}
                    LeaveBalanceList={LeaveBalanceList}
                    setData={setData}
                    fromDateOld={editedData?.fromDate}
                    toDateOld={editedData?.toDate}
                    reasonOld={editedData?.reason}
                    leaveDurationOld={editedData?.leaveDuration}
                    leaveTypeOld={editedData?.leaveType}
                    id={editedData?.id}
                    documents={editedData?.documents}
                    setLeaveBalanceData={setLeaveBalanceData}
                  />
                </div>
              </Fade>
            </Modal>

            <div
              className={classNames(
                windowSize[0] < 640
                  ? "max-h-[440px] min-h-[430px] overflow-y-auto gap-3"
                  : "pt-1 px-2 sm:px-4 md:px-5",
                "   pb-[10px] border-b border-offwhite "
              )}
            >
              {windowSize[0] > 640 ? (
                <>
                  <div className="mb-0">
                    <div className="flex justify-between lg:flex-col-reverse xl:flex-row xl:items-center">
                      <div className="flex w-full mt-1 md:mb-1 custom-scrollbar ">
                        <CustomScrollbar isVertical={false} isHor={true}>
                          <div className="flex w-full items-center ">
                            <div className="flex">
                              <p className="hidden mb-3 2xs:mb-0 text-13 leading-5 font-medium text-black2 mr-3">
                                DATE
                              </p>
                              {filterDate?.startDate && filterDate?.endDate && (
                                <button
                                  type="button"
                                  onClick={() => {
                                    setDateClean(true);
                                    setFilterDate({
                                      startDate: "",
                                      endDate: "",
                                    });
                                  }}
                                  className="text-12 mr-2"
                                >
                                  <CloseIcon
                                    color="#EE436D"
                                    width={10}
                                    height={10}
                                  />
                                </button>
                              )}

                              <div className="flex">
                                <div
                                  className={`mr-3 ${Styles.leaveDatePicker}`}
                                >
                                  <DatePopupFoFilter
                                    placeholder="Start"
                                    date={filterDate?.startDate}
                                    setDate={(value) => {
                                      setFilterDate((prev) => ({
                                        ...prev,
                                        startDate: value as Date,
                                      }));
                                      setDateClean(false);
                                    }}
                                  />
                                </div>
                                <div
                                  className={`mr-3 ${Styles.leaveDatePicker}`}
                                >
                                  <DatePopupFoFilter
                                    placeholder="End"
                                    date={filterDate?.endDate}
                                    setDate={(value) => {
                                      setFilterDate((prev) => ({
                                        ...prev,
                                        endDate: value as Date,
                                      }));
                                      setDateClean(false);
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="flex mr-3">
                              <p className="hidden mb-3 2xs:mb-0 text-13 leading-5 font-medium text-black2 mr-3">
                                STATUS
                              </p>
                              <CustomDropdownListForFilter
                                isFilter={true}
                                placeholder="Status"
                                state={status}
                                setState={setStatus}
                                dropdownItems={myLeavesListData?.map(
                                  (status: any) => ({
                                    value: status,
                                    label: status,
                                  })
                                )}
                                className="max-h-[38px] max-w-[135px]"
                              />
                            </div>
                          </div>
                        </CustomScrollbar>
                        <div className="flex justify-end mt-1 sm:w-auto w-full sm:flex-nowrap flex-wrap items-center">
                          <div className=" mb-1 flex items-center xl:ml-3 mr-[10px]">
                            <p className="base-label text-12 xl:text-14">
                              DISPLAY
                            </p>
                            <NumberDropdownList
                              state={pagination.pageSize}
                              setState={(value) =>
                                setPagination((prev) => ({
                                  ...prev,
                                  pageSize: value as number,
                                }))
                              }
                              dropdownItems={new Array(5)
                                .fill(1)
                                .map((_, index: number) => {
                                  let display = (index + 1) * 10;
                                  return {
                                    value: display,
                                    label: String(display),
                                  };
                                })}
                              className="max-w-[55px] 4xs:max-w-[60px] lg:max-w-[72px]"
                              textAlign="text-left pl-3"
                              paddingClass="py-[2px]"
                              placeholder="20"
                            />
                          </div>
                        </div>
                        <div className="flex mr-3">
                          <p className="hidden mb-3 2xs:mb-0 text-13 leading-5 font-medium text-black2 mr-3">
                            STATUS
                          </p>
                          <CustomDropdownListForFilter
                            isFilter={true}
                            placeholder="Status"
                            state={status}
                            setState={setStatus}
                            dropdownItems={myLeavesListData?.map(
                              (status: any) => ({
                                value: status,
                                label: status,
                              })
                            )}
                            className="max-h-[38px] max-w-[135px] min-w-[150px]"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <PersonalLeaveTable
                    columnFilters={columnFilters}
                    setColumnFilters={setColumnFilters}
                    data={data ? data : []}
                    manageAllLeaveApplication={manageAllLeaveApplication}
                    hiddenFields={[]}
                    id={Number(id)}
                    pagination={pagination}
                    setPagination={setPagination}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    total={itemCount}
                    setShowLeaveFile={setShowLeaveFile}
                    setLeaveDocuments={setLeaveDocuments}
                    sorting={sorting}
                    setSorting={setSorting}
                    setIsLeaveApplicationOpen={setIsLeaveApplicationOpen}
                    setEditedData={setEditedData}
                    saveCancel={saveCancel}
                    setIsSuccess={setIsSuccess}
                    setIsConfirmCancelOpen={setIsConfirmCancelOpen}
                    isConfirmCancelOpen={isConfirmCancelOpen}
                  />
                </>
              ) : (
                <>
                  {data?.length
                    ? data.map((data: any, i: any) => (
                      <div key={i} className="">
                        <div
                          key={i}
                          className="px-3 py-4 bg-[#F3F6F9] rounded-md gap-10 w-full mb-3"
                        >
                          <span className=" rounded-[9px] max-w-min px-[8px] py-[3px] text-center text-[9px] font-medium leading-normal bg-turquiseIcon text-white">
                            {data.leaveType ?? "---"}
                          </span>
                          <div className="flex py-2 w-full">
                            <div className="flex w-1/2">
                              <p className="leading-loose text-10 pr-5 font-normal text-graydark text-start">
                                From
                              </p>
                              <p className="leading-loose text-black2 w-1/2 text-10 font-normal text-start">
                                {data.from ?? "---"}
                              </p>
                            </div>
                            <div className="flex w-1/2">
                              <p className="leading-loose text-10 pr-5 font-normal text-graydark text-start">
                                To
                              </p>
                              <p className="leading-loose text-black2 w-1/2 text-10 font-normal text-start">
                                {data.to ?? "---"}
                              </p>
                            </div>
                          </div>
                          <hr color="blue" />
                          <div className="flex pt-3 w-full">
                            <p className="leading-loose text-10 w-1/2 font-normal text-graydark text-start">
                              Full/HalfDay
                            </p>
                            <p className=" leading-loose text-black2 w-1/2 text-10 font-normal text-start">
                              {data.fullHalfDay ?? "---"}
                            </p>
                          </div>
                          <div className="flex">
                            <p className="text-10 w-1/2 font-normal text-graydark text-start leading-loose">
                              Total
                            </p>
                            <p className=" text-black2 w-1/2 text-10 font-normal text-start leading-loose">
                              {data.total ?? "---"}
                            </p>
                          </div>
                          <div className="flex">
                            <p className="text-10 w-1/2 font-normal text-graydark text-start leading-loose">
                              Reason
                            </p>
                            <p className=" text-black2 w-1/2 text-10 font-normal text-start leading-loose">
                              {data.reason ?? "---"}
                            </p>
                          </div>
                          <div className="flex">
                            <p className="text-10 w-1/2 font-normal text-graydark text-start leading-loose">
                              ID
                            </p>
                            <p className=" text-black2 w-1/2 text-10 font-normal text-start leading-loose">
                              {data.code ?? "---"}
                            </p>
                          </div>
                          <div className="flex">
                            <p className="text-10 w-1/2 font-normal text-graydark text-start leading-loose">
                              Leave Type
                            </p>
                            <p className=" text-black2 w-1/2 text-10 font-normal text-start leading-loose">
                              {data.leaveType ?? "---"}
                            </p>
                          </div>
                          <div className="flex">
                            <p className="text-10 w-1/2 font-normal text-graydark text-start leading-loose">
                              Apply Date
                            </p>
                            <p className=" text-black2 w-1/2 text-10 font-normal text-start leading-loose">
                              {data.appliedAt ?? "---"}
                            </p>
                          </div>
                          <div className="flex">
                            <p className="text-10 w-1/2 font-normal text-graydark text-start leading-loose">
                              Remark
                            </p>
                            <p className=" text-black2 w-1/2 text-10 font-normal text-start leading-loose">
                              {data.remark.length ? data.remark : "---"}
                            </p>
                          </div>
                          <div className="flex">
                            <p className="text-10 w-1/2 font-normal text-graydark text-start leading-loose">
                              Supportive Document
                            </p>
                            <p
                              onClick={() => {
                                setLeaveDocuments(data.documents);
                                setShowLeaveFile(true);
                              }}
                              className={classNames(
                                data.documents.length
                                  ? "text-vorpblue cursor-pointer"
                                  : "text-black2",
                                "  w-1/2 text-10 font-normal text-start leading-loose"
                              )}
                            >
                              {data.documents.length
                                ? "Attached Files"
                                : "---"}
                            </p>
                          </div>
                          <div className="flex">
                            <p className="text-10 w-1/2 font-normal text-graydark text-start leading-loose">
                              Status
                            </p>
                            <div className="flex items-center">
                              <span
                                className={classNames(
                                  data.status.includes("Approve")
                                    ? "bg-vorpgreen"
                                    : "bg-graydark",
                                  "w-2 h-2 rounded-full mx-2"
                                )}
                              ></span>
                              <p
                                className={classNames(
                                  data.status.includes("Approve")
                                    ? "text-vorpgreen"
                                    : "text-black2",
                                  "w-1/2 text-10 font-normal text-start leading-loose"
                                )}
                              >
                                {data.status ?? "---"}
                              </p>
                            </div>
                          </div>
                          <div className="flex pt-3">
                            {data.cancelOption ? (
                              <button
                                onClick={() => saveCancel(data.id)}
                                className="leading-loose underline text-10 font-normal text-black2"
                              >
                                Cancel
                              </button>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    ))
                    : null}
                </>
              )}
            </div>
            {windowSize[0] < 640 ? (
              <div className="pt-0">
                <TablePaginator
                  total={itemCount}
                  pagination={pagination}
                  setPagination={setPagination}
                  itemCount={itemCount}
                  setCurrentPage={setCurrentPage}
                  currentPageDefault={currentPage}
                />
              </div>
            ) : null}
            <div
              className={classNames(
                styles.attendance1Table,
                "pt-5 pb-9 px-2 5xs:px-4 sm:px-5 mb-7 border-b border-offwhite"
              )}
            >
              <animated.div style={props}>
                <div className="flex justify-between items-center">
                  <h3 className="text-14 xl:text-18 leading-[22px] pb-1 border-b border-b-offwhite font-semibold text-black2 mb-1">
                    Leave Balance
                  </h3>
                  {Number(id) !== 0 ? (
                    <div>
                      <Button
                        label={
                          <span
                            className={classNames(
                              Styles.customEdit,
                              "flex items-center"
                            )}
                          >
                            <EditIcon className="mr-[5px]" color="#000000" />{" "}
                            Edit
                          </span>
                        }
                        onClick={() => {
                          setShowLeaveAllowance(true);
                        }}
                        type="button"
                        variant="no-outline"
                        customClass="my-1 bg-offwhite border-transparent hover:border-vorpblue hover:text-vorpblue transition-all duration-300 text-black2 xs:text-[12px] leading-[18px] font-medium py-2 px-[8px] sm:mr-[10px] mr-[5px]"
                        size="base"
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <PersonalLeaveBalanceTable
                  leaveBalance={leaveBalanceData?.leavesInfo}
                />
              </animated.div>

              <div className="mt-4">
                <div className="flex items-end font-poppins md:text-14 text-12 text-black2 font-normal mt-2">
                  <p className="mr-1">Leftover Expiry Date:</p>
                  <p>
                    {leaveBalanceData?.previousBalance?.expiredDate
                      ? leaveBalanceData?.previousBalance?.expiredDate
                      : "---"}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ProfileLeaveSection;
