import { ReactElement } from 'react';
import BasicLayout from '../layouts/BasicLayout';
import { Outlet } from 'react-router-dom';
import ProjectChecker from './view/ProjectChecker';

const ProjectCheckerRoute = (props:any): ReactElement => {
  const data=props.loginData;

  const permissions = props.permissions;

 return (
    <BasicLayout userData={data} permissions={permissions}>
      <ProjectChecker/>
      <Outlet />
    </BasicLayout>
  )
};

export default ProjectCheckerRoute;
