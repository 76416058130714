import { Component, ReactNode, ComponentType } from 'react';
import { useLocation } from 'react-router-dom';
import ErrorPage from '../components/ErrorPage';

export type Props = {
  pathname: string | null;
  children: JSX.Element | ReactNode;
};

export type State = {
  hasError: boolean;
  pathname: string | null;
};

class RoutedErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false, pathname: null };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  static getDerivedStateFromProps(props: Props, state: State) {
    if (props.pathname !== state.pathname) {
      return { hasError: false, pathname: props.pathname };
    }

    return state;
  }

  componentDidCatch(error: unknown) {
    if (!(process.env.NODE_ENV === 'development')) {
      // print the error
      // do not print on development to avoid printing twice in console
      console.error(error);
    }

    // and capture it
    //* store it in the state or log it to third party services
  }

  render() {
    const { hasError } = this.state;

    if (hasError) {
      return <ErrorPage />;
    }

    const { children } = this.props;

    return children;
  }
}

export default RoutedErrorBoundary;

export const withErrorBoundary = <Props extends {} = {}>(
  WrappedComponent: ComponentType<Props>
): ComponentType<Props> => {
  const EnhancedComponent = (props: any) => {
    const location = useLocation();

    return (
      <RoutedErrorBoundary pathname={location.pathname}>
        <WrappedComponent {...props} />
      </RoutedErrorBoundary>
    );
  };

  EnhancedComponent.displayName = `withErrorBoundary(${
    WrappedComponent.displayName || WrappedComponent.name || 'Component'
  })`;

  return EnhancedComponent;
};
