import {
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useEffect,
  useState,
} from "react";

interface IDebounceInput {
  children: (prop: Dispatch<SetStateAction<IValue>>) => ReactNode;
  setState: Dispatch<SetStateAction<IValue>>;
  debounce?: number;
}
interface IValue{
  id:number,
  cellId:string,
  value:any
}

const DebounceInput: FC<IDebounceInput> = ({
  setState,
  debounce = 1000,
  children,
}) => {
  const [value, setValue] = useState<IValue>({
    id:0,
    cellId:'',
    value:''
  });
  useEffect(() => {
    const timeout = setTimeout(() => setState(value), debounce);
    return () => clearTimeout(timeout);
  }, [value, debounce]);


  return <>{children(setValue)}</>;
};

export default DebounceInput;
