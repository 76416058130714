import {
  createColumnHelper,
  SortingState,
  ColumnFiltersState,
  RowSelectionState,
  ColumnDef,
  PaginationState,
} from "@tanstack/table-core";
import {
  FC,
  useState,
  useMemo,
  Dispatch,
  SetStateAction,
  memo,
  useCallback,
  useRef,
  useEffect,
} from "react";
import styles from "./DailyReport.module.scss";
import LayoutAdjustableTable from "../../Tables/LayoutAdjustableTable";
import TablePaginator from "../../Tables/TablePaginator";
import { baseObj } from "../../../utils/constants";
import { toast } from "react-toastify";

import {
  useCancelMyClaimMutation,
  useDeleteMyClaimMutation,
} from "../../../app/services/claims";
import DeletePopup from "../../DeletePopup/DeletePopup";
import { renderDateFormatDMY } from "../../DashboardCalendarView/DashboardAddEventPopup";
import CommonTableBottom from "../../CommonTableBottom/CommonTableBottom";
const classNames = require("classnames");

interface IPersonalLeaveTable {
  data?: any;
  setData?: any;
  columnFilters: ColumnFiltersState;
  setColumnFilters: Dispatch<SetStateAction<ColumnFiltersState>>;
  myLeavesList?: any;
  total: number;
  setCurrentPage: any;
  currentPage: number;
  pagination: PaginationState;
  setPagination: Dispatch<SetStateAction<PaginationState>>;
  changePage?: any;
  sorting: SortingState;
  setSorting: React.Dispatch<React.SetStateAction<SortingState>>;
  totalTime?: string;
}

export const regionLists = [
  {
    id: "All",
    name: "All",
  },
  {
    id: 1,
    name: "LGN01LAV0280",
  },
  {
    id: 2,
    name: "LGN01LAV0287",
  },
];

export const taskLists = [
  {
    id: "All",
    name: "All",
  },
  {
    id: 1,
    name: "UIUX",
  },
  {
    id: 2,
    name: "Frontend",
  },
];

const DailyReportTable: FC<IPersonalLeaveTable> = ({
  total,
  setCurrentPage,
  currentPage,
  pagination,
  setPagination,
  changePage,
  data,
  setData,
  sorting,
  setSorting,
  totalTime,
}) => {
  const columnHelper = useMemo(() => createColumnHelper<any>(), []); // TODO add type definition when api binding
  const [deleteID, setDeleteID] = useState<number | 0>();
  const [customClass, setCustomClass] = useState<string>("");
  const [totalPos, setTotalPos] = useState<number>();
  const [bottom, setBottom] = useState<number>();

  const [errorPopup, setErrorPopup] = useState<boolean>(false);
  const [message, setMessage] = useState<any>([]);
  const [deleteRowID, setDeleteRowID] = useState<number | 0>();
  const [openDeletePopup, setOpenDeleteopup] = useState<boolean>(false);
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  const [timeSpentPos, setTimeSpentPos] = useState<number>();

  const [itemCount, setItemCount] = useState<number>(total);
  const [deleteClaim, { isLoading: deleteLoading }] =
    useDeleteMyClaimMutation();

  const deleteOTItem = useCallback(
    (id?: number, dataId?: number) => {
      const selectedRows = Object.keys(rowSelection);
      setData((prev: baseObj[]) => {
        if (dataId != undefined) {
          deleteClaim(dataId)
            .unwrap()
            .then((payload: any) => {
              setOpenDeleteopup(false);
              toast("Successfully Delete.", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                type: "success",
              });
            })
            .catch((error: any) => {
              setErrorPopup(true);
              setOpenDeleteopup(false);
              setMessage(
                error
                  ? [error?.data.message]
                  : ["Something went wrong!Trying again."]
              );
            });
        }
        return prev.filter((_, index) => {
          return id !== undefined
            ? id !== index
            : !selectedRows.includes(String(index));
        });
      });
      id === undefined && setRowSelection({});
    },
    [setData, rowSelection]
  );

  const divRef = useRef(null);
  const [width, setWidth] = useState<number>();

  const calculateTotalSpentTime = () => {
    const left = document
      .getElementById("headertime_spent_text")
      ?.getBoundingClientRect()?.left;
      const total =document.querySelector('table tr td:first-child')?.getBoundingClientRect().left;
    setTotalPos(total ? total : 0);
    setTimeSpentPos(left ? Number(left) : 0);
    const bottom = document
      .getElementById("table-scroll")
      ?.getBoundingClientRect()?.bottom;
    setBottom(bottom ? bottom : 0);
    if (left == undefined) {
      setCustomClass("hidden");
    }
  };
  const handleScroll = () => {
    calculateTotalSpentTime();
  };

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      setWidth(entries[0].contentRect.width);
    });

    if (divRef.current) {
      observer.observe(divRef.current);
    }

    return () => {
      divRef.current && observer.unobserve(divRef.current);
    };
  }, []);

  useEffect(() => {
    calculateTotalSpentTime();
  }, [width]);
  useEffect(() => {
    calculateTotalSpentTime();
  }, [data]);
  useEffect(() => {
    const handleResize = () => {
      calculateTotalSpentTime();
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    const handleScroll = () => {
      calculateTotalSpentTime();
    };
    document
      .getElementById("content-body")
      ?.addEventListener("scroll", handleScroll);
    return () => {
      document
        .getElementById("content-body")
        ?.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const columns = useMemo(
    () => [
      columnHelper.accessor("date", {
        header: "Date",
        id: "date",
        cell: ({ getValue, cell }) => {
          const date = getValue();

          var joinDate = new Date();
          if (date) {
            joinDate = new Date(date);
          }
          var formatDate = renderDateFormatDMY(joinDate, "-");

          return (
            <div className="bg-white text-black2 px-2 flex items-center">
              <p className=" px-2 py-1">{formatDate}</p>
            </div>
          );
        },
      }),
      columnHelper.accessor("project_code", {
        header: "Project Code",
        id: "project_code",
        cell: ({ getValue, cell }) => {
          const data = getValue();
          return (
            <div className="bg-white text-black2 px-2 flex items-center">
              <p className=" px-2 py-1">{data}</p>
            </div>
          );
        },
      }),
      columnHelper.accessor("project_name", {
        header: "Project Name",
        id: "project_name",
        cell: ({ getValue, cell }) => {
          const data = getValue();
          return (
            <div className="bg-white text-black2 px-2 flex items-center">
              <p className=" px-2 py-1">{data}</p>
            </div>
          );
        },
      }),

      columnHelper.accessor("task_name", {
        header: "Task Name",
        id: "task_name",
        cell: ({ getValue, cell }) => {
          const name = getValue();
          return (
            <div className="bg-white text-black2 px-2 flex items-center relative overflow-hidden mx-2">
              <p className="px-2 py-1 absolute">{name}</p>
            </div>
          );
        },
      }),

      columnHelper.accessor("task_category_name", {
        header: "Task Tag",
        id: "task_category_name",
        enableSorting: true,
        cell: ({ getValue, cell }) => {
          const data = getValue();
          return (
            <div className="bg-white text-black2 px-2 flex items-center">
              <p className="bg-[#C9F7F5] px-2 py-0 text-[#1BC5BD] rounded-sm">
                {data}
              </p>
            </div>
          );
        },
      }),
      columnHelper.accessor("time_spent_text", {
        header: "Time Spent",
        id: "time_spent_text",
        enableSorting: true,
        cell: ({ getValue, cell }) => {
          const name = getValue();
          return (
            <div className="bg-white text-black2 px-2 flex items-center">
              <p className="px-2 py-1">{name}</p>
            </div>
          );
        },
      }),
    ],
    [columnHelper, data]
  );

  const [columnOrder, setColumnOrder] = useState<string[]>(
    columns.map((column: ColumnDef<any, any>) => column.id as string)
  );

  const visibleColumns: { [key: string]: boolean } = {};

  return (
    <div>
      <div className={styles.tableDaily}>
        <LayoutAdjustableTable
          data={data}
          hideColumn={visibleColumns}
          columns={columns}
          sorting={sorting}
          setSortingState={setSorting}
          rowSelection={rowSelection}
          setRowSelection={setRowSelection}
          columnOrder={columnOrder}
          setColumnOrder={setColumnOrder}
          unDraggableFields={["actions", "selection"]}
          theaderColor="text-graydark"
          isLayoutControlOpen={false}
          setIsLayoutControlOpen={() => 1}
          label="User List"
          setItemCount={setItemCount}
          pagination={pagination}
          setPagination={setPagination}
          noPaddingCells={["leave_type_id", "fullHalfDay", "status"]}
          handleScroll={handleScroll}
        />
        <CommonTableBottom
          data={[
            {
              name: totalTime ? "Total" : "",
              colSpan: 5,
              left: 0,
              bottom: bottom,
              classes: totalTime ? customClass : "hidden",
            },
            {
              name: totalTime ? totalTime : "",
              colSpan: 3,
              left: Number(timeSpentPos) - 40,
              bottom: bottom,
              classes: totalTime ? customClass : "hidden",
            },
          ]}
        />
      </div>
      <div className="pt-6">
        <TablePaginator
          total={itemCount}
          pagination={pagination}
          setPagination={setPagination}
          itemCount={total}
          setCurrentPage={setCurrentPage}
          currentPageDefault={currentPage}
          changePage={changePage}
        />
      </div>
    </div>
  );
};

export default memo(DailyReportTable);
