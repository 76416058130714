// for announcement 
  export const addNewAnnounce = () => {
    return {          
        title: "",
        tag:  [],
        postedDate: Date.now(),
        expiryDate: "",
        postedBy: "",
        updatedBy: "",
        content: ""
    };
  };
 
  export const tagTypes = ["All", "Fontend", "HR", "Backend", "Design"];

  export const tagTypeArr = [
    {"id":0,"name":"All",badgeColor: ""},
    {"id":1,"name":"Fontend",badgeColor: "#FFF0CD"},
    {"id":2,"name":"HR",badgeColor: "#E1E9FF"},
    {"id":3,"name":"Backend",badgeColor: "#E5F6FF"},
    {"id":4,"name":"Design",badgeColor: "#EEE5FF"}
  ];

  
  const dummyBaseAnnounceData = {
    id: 1,
    noticeCode: "000001",
    noticeName: "System Upgrade for Rocket.Chat",
    postDate: "2022-11-01 02:19:21",
    expiryDate: "2022-15-01 04:19:21",
    tag:[ { name: "Fontend", badgeColor: "#FFF0CD" },
        { name: "HR", badgeColor: "#E1E9FF" },
        { name: "Backend", badgeColor: "#E5F6FF" },
        { name: "Design", badgeColor: "#EEE5FF" }
      ],
    postedBy : "Super Admin",
    updatedBy: "HR Manager"
  };
  
  export const dummyAnnounceData = [
    {
      ...dummyBaseAnnounceData,
    },
    {
      ...dummyBaseAnnounceData,
      id: 2,
      noticeCode: "00002",
      tag:[ { name: "Fontend", badgeColor: "#FFF0CD" },
        { name: "HR", badgeColor: "#E1E9FF" },
        { name: "Backend", badgeColor: "#E5F6FF" },
        { name: "Design", badgeColor: "#EEE5FF" }
      ],
    },
    {
      ...dummyBaseAnnounceData,
      id: 3,
      noticeCode: "00003",
      tag:[ { name: "Fontend", badgeColor: "#FFF0CD" },
        { name: "HR", badgeColor: "#E1E9FF" },
        { name: "Backend", badgeColor: "#E5F6FF" },
      ],
    },
    {
      ...dummyBaseAnnounceData,
      id: 4,
      noticeCode: "000004",
      tag:[ { name: "Backend", badgeColor: "#E5F6FF" }, ],
    },
    {
      ...dummyBaseAnnounceData,
      id: 5,
      noticeCode: "000005",
      tag:[
        { name: "HR", badgeColor: "#E1E9FF" },
        { name: "Design", badgeColor: "#EEE5FF" }
      ],
    },
    {
      ...dummyBaseAnnounceData,
      id: 6,
      noticeCode: "000006",
    },
    {
      ...dummyBaseAnnounceData,
      noticeCode: "000007",
      id: 7,
     
    },
    {
      ...dummyBaseAnnounceData,
      id: 8,
      projectType: "React.js",
      noticeCode: "000008",
    },
    {
      ...dummyBaseAnnounceData,
      id: 9,
      noticeCode: "000009",
    },
    {
      ...dummyBaseAnnounceData,
      id: 10,
      noticeCode: "000001",
    },
    {
      ...dummyBaseAnnounceData,
      id: 11,
      noticeCode: "000011",
    },
    {
      ...dummyBaseAnnounceData,
      id: 12,
      noticeCode: "000012",
     
    },
    {
      ...dummyBaseAnnounceData,
      id: 13,
      noticeCode: "000013",
     
    },
    {
      ...dummyBaseAnnounceData,
      id: 14,
      noticeCode: "000014",
    },
  ];
  

 
  

  
  
  
  