import { redirectToLogin } from './annoucement-sheet';
import { api } from './api';
import authHeader from './auth-header';
import { Ids } from './media';
import { EmployeePC } from './progress-checker-acc';

type ProgressCheckerResponse = {
    progress_checker_list: ProgressCheckerData,
    module_id: number,
    status: boolean,
    total_time: string,
    
}
type ProgressCheckerFilterResponse={
    task_categories: TaskCategory[]
}
export type TaskCategory = {
    id: number;
    name: string;
}
export type TeamLeadData = {
    id: number;
    code: string,
    preferred_name: string;
    year_w_vo: number;
    age: number;
}
export type CountryListData = {
    id: number;
    name: string;
    code: string;
    time_zone: string;
}
export type ProgressChecker = {
    "id": number,
    "project_id": string,
    "project_name": string,
    "stage": TaskCategory[],
    "team_lead": TeamLeadData,
    "project_manager": TeamLeadData,
    "time_spent": number,
    "employees":EmployeePC[]
}
export type ProgressCheckerData={
    current_page:number;
    data:ProgressChecker[],
    total:number;
}
type progressCheckerInput = {
    task_category_id: number,
    sort: string,
    search: string,
    page:number,
    limit:number
}
export const progressCheckerApi = api.injectEndpoints({
    endpoints: (build) => ({
        getProgressChecker: build.query<ProgressCheckerResponse, progressCheckerInput>({
            query: ({ sort, search, task_category_id,limit,page }) => ({ url: `progress-checker-lists?task_category_ids=${task_category_id}&sort=${sort}&search=${search}&limit=${limit}&page=${page}`, headers: authHeader() }),
            transformErrorResponse: (err) => {
                if (err?.status === 401) {
                    redirectToLogin();
                }
            },
            providesTags: (result: any = []) => {
                if (result.data) {
                    if (result.data.length > 0) {
                        return [
                            ...result.data.map((id: any) => ({ type: "ProgressChecker", id } as const)),
                            { type: "ProgressChecker" as const, id: "LIST" },
                        ]
                    }
                    else {
                        return [{ type: "ProgressChecker" as const, id: "LIST" },]
                    }
                }
                else {
                    return [{ type: "ProgressChecker" as const, id: "LIST" },]
                }

            },
        }),
        getProgressCheckerFilterData: build.query<ProgressCheckerFilterResponse, void>({
            query: () => ({ url: `progress-checker-lists-filter-data`, headers: authHeader() }),
            transformErrorResponse: (err) => {
                if (err?.status === 401) {
                    redirectToLogin();
                }
            },
            providesTags: (result: any = []) => {
                if (result.data) {
                    if (result.data.length > 0) {
                        return [
                            ...result.data.map((id: any) => ({ type: "ProgressCheckerFilter", id } as const)),
                            { type: "ProgressCheckerFilter" as const, id: "LIST" },
                        ]
                    }
                    else {
                        return [{ type: "ProgressCheckerFilter" as const, id: "LIST" },]
                    }
                }
                else {
                    return [{ type: "ProgressCheckerFilter" as const, id: "LIST" },]
                }

            },
        }),
    }),
});

export const {
    useGetProgressCheckerQuery,
    useGetProgressCheckerFilterDataQuery
} = progressCheckerApi;


export const {
    endpoints: {
        getProgressChecker,
        getProgressCheckerFilterData
    },
} = progressCheckerApi;