import { ReactElement } from "react";
import BasicLayout from "../layouts/BasicLayout";
import { Outlet } from "react-router-dom";
import UserList from "./views/UserList";
import RolesList from "./views/RolesList";
import Permissions from "../permissions/views/Permissions";

export const backendUserRoutes = [
  { id: 1, path: "", component: <UserList /> },
  { id: 2, path: "roles", component: <RolesList /> },
  { id: 3, path: "permissions", component: <Permissions /> },
];

const BackendUserRoute = (props: any): ReactElement => {
  const data = props.loginData;
  const permissions = props.permissions;

  return (
    <BasicLayout userData={data} permissions={permissions}>
      <div className="pt-0 pb-[58px]">
        <Outlet />
      </div>
    </BasicLayout>
  );
};
export default BackendUserRoute;
