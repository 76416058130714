import {
    createColumnHelper,
    SortingState,
    ColumnFiltersState,
    RowSelectionState,
    ColumnDef,
    PaginationState,
} from "@tanstack/table-core";
import {
    FC,
    useState,
    useMemo,
    Dispatch,
    SetStateAction,
    memo,
    // useCallback,
    // useRef,
    // useEffect,
} from "react";
import styles from "./MyProjects.module.scss";
// import { toast } from "react-toastify";
import TablePaginator from "../Tables/TablePaginator";
import LayoutAdjustableTable from "../Tables/LayoutAdjustableTable";
import { makeStyles } from "@mui/styles";
import { assignedColor } from "../Documents/EmployeeDocumentsTable";
import { Tooltip } from "@mui/material";
const classNames = require("classnames");

interface IPersonalLeaveTable {
    data?: any;
    // setData?: any;
    columnFilters: ColumnFiltersState;
    setColumnFilters: Dispatch<SetStateAction<ColumnFiltersState>>;
    myLeavesList?: any;
    total: number;
    setCurrentPage: any;
    currentPage: number;
    pagination: PaginationState;
    setPagination: Dispatch<SetStateAction<PaginationState>>;
    changePage?: any;
    sorting: SortingState;
    setSorting: React.Dispatch<React.SetStateAction<SortingState>>;
    // totalTime?: string;
}
const useStyles = makeStyles(() => ({
    tooltip: {
        backgroundColor: "transparent",
        margin: 0,
        marginRight: 8,
        padding: 0,
    },
}));
const MyProjectTable: FC<IPersonalLeaveTable> = ({
    total,
    setCurrentPage,
    currentPage,
    pagination,
    setPagination,
    changePage,
    data,
    // setData,
    sorting,
    setSorting,
    // totalTime,
}) => {
    const classes = useStyles();
    const columnHelper = useMemo(() => createColumnHelper<any>(), []);
    const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
    const [itemCount, setItemCount] = useState<number>(total);
    const columns = useMemo(
        () => [
            columnHelper.accessor("project_id", {
                header: "Project ID",
                id: "project_id",
                enableSorting: true,
                cell: ({ getValue, cell }) => {
                    const id = getValue();
                    return (
                        <div className=" text-left bg-white text-black2 px-[10px] font-normal">
                            <p className="h-full flex items-center">{id}</p>
                        </div>
                    );
                },
            }),
            columnHelper.accessor("project_name", {
                header: "Project Name",
                id: "project_name",
                enableSorting: true,
                cell: ({ getValue, cell }) => {
                    const project_name = getValue();
                    return (
                        <div className=" text-left bg-white text-black2 px-[10px] font-normal">
                            <p className="h-full flex items-center">{project_name}</p>
                        </div>
                    );
                },
            }),
            columnHelper.accessor("stage", {
                header: "Stage",
                id: "stage",
                enableSorting: false,
                cell: ({ getValue, cell }) => {
                    const stages = getValue();
                    return (
                        <div className="text-left font-normal flex flex-wrap max-h-[22px] overflow-y-hidden overflow-x-auto items-center px-2">
                            <Tooltip
                                arrow
                                className="customTooltip"
                                classes={{ tooltip: classes.tooltip }}
                                enterTouchDelay={1000}
                                leaveTouchDelay={5000}
                                placement="left"
                                title={
                                    <>
                                        <div className="text-white text-[10px] hover:cursor-pointer leading-[15px] z-[2] rounded p-4 w-full bg-tooltipbg">
                                            {stages?.map((x: any, index: number) => {
                                                return <span className="w-full block">{x.name}</span>
                                            })}
                                        </div>
                                    </>
                                }
                            >
                                <div className="flex flex-wrap">
                                    {stages?.length > 0 ? stages.map((stage: any, index: number) => {
                                        return <span className="bg-vorpgreen text-white px-[5px] rounded-[5px] mr-3 mb-1" key={index}>{stage?.name}</span>
                                    }) : <span className="flex w-full justify-center h-full items-center">---</span>}
                                </div>
                            </Tooltip>

                        </div>
                    );
                },
            }),
            columnHelper.accessor("team_leader_name", {
                header: "Project Lead",
                id: "team_leader_name",
                enableSorting: true,
                cell: ({ getValue, cell }) => {
                  const team_leader_name = getValue();
                  const team_leader_code= cell.row.original.team_leader_code;
                  return (
                    <div className=" min-w-[140px] text-left font-normal flex items-center px-2">
                      <span>{team_leader_name} - {team_leader_code}</span>
                    </div>
                  );
                },
              }),
              columnHelper.accessor("project_manager_name", {
                header: "Project Manager",
                id: "project_manager_name",
                enableSorting: true,
                cell: ({ getValue, cell }) => {
                  const project_manager_name = getValue();
                  const project_manager_code = cell.row.original.project_manager_code;
                  return (
                    <div className=" min-w-[140px] text-left font-normal flex items-center px-2">
                      <span>{project_manager_name} - {project_manager_code}</span>
                    </div>
                  );
                },
              }),
            columnHelper.accessor("employees", {
                header: "Members",
                id: "employees",
                enableSorting: false,
                cell: ({ getValue, cell }) => {
                    const employees = getValue();
                    return (
                        <button
                            type="button"
                            className="min-w-[150px] flex justify-center items-center h-full ml-[10px]"
                        >
                            <Tooltip
                                arrow
                                className="customTooltip"
                                classes={{ tooltip: classes.tooltip }}
                                enterTouchDelay={1000}
                                leaveTouchDelay={5000}
                                placement="left"
                                title={
                                    <>
                                        <div className="text-white bg-[#6b6a66] text-[10px] hover:cursor-pointer leading-[15px] z-[2] rounded p-4 w-full ">
                                            {employees?.map((x: any, index: number) => {
                                                return <div className="flex" key={index}>
                                                    <span className="block text-12 w-[110px] pr-2">{x.name}{x.project_roles.length > 0 ? ':' : ''} </span>
                                                    <span className="text-12 w-full">{x.project_roles?.map((x: any) => x.name)?.join(', ')}</span></div>;
                                            })}
                                        </div>
                                    </>
                                }
                            >
                                <div className="flex ">
                                    {employees?.map((x: any, index: number) => {
                                        if (index < 3) {
                                            var idx = index;
                                            if (idx > 4) {
                                                idx = 0;
                                            }

                                            return x.profileImage ? (
                                                <img
                                                    key={index}
                                                    src={x.profileImage}
                                                    alt="profileImage"
                                                    className="w-[35px] h-[35px] rounded-full object-cover"
                                                />
                                            ) : (
                                                <div
                                                    key={index}
                                                    className={classNames(
                                                        "bg-[" + assignedColor[idx] + "]",
                                                        "ml-[-10px] w-[35px] h-[35px] rounded-full text-14 flex justify-center items-center text-white"
                                                    )}
                                                >
                                                    {x.name?.substring(0, 1)}
                                                </div>
                                            );
                                        } else {
                                            return null;
                                        }
                                    })}
                                    {employees?.length > 3 ? (
                                        <div className="ml-[-10px] w-[35px] h-[35px] rounded-full text-10 flex justify-center items-center bg-black2 text-white">
                                            {employees?.length - 3}+
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </Tooltip>
                        </button>
                    );
                },
            }),
        ],
        [columnHelper, data]
    );

    const [columnOrder, setColumnOrder] = useState<string[]>(
        columns.map((column: ColumnDef<any, any>) => column.id as string)
    );

    const visibleColumns: { [key: string]: boolean } = {};

    return (
        <div>
            <div className={styles.tableDaily}>
                <LayoutAdjustableTable
                    data={data}
                    hideColumn={visibleColumns}
                    columns={columns}
                    sorting={sorting}
                    setSortingState={setSorting}
                    rowSelection={rowSelection}
                    setRowSelection={setRowSelection}
                    columnOrder={columnOrder}
                    setColumnOrder={setColumnOrder}
                    unDraggableFields={["actions", "selection"]}
                    theaderColor="text-graydark"
                    isLayoutControlOpen={false}
                    setIsLayoutControlOpen={() => 1}
                    label="User List"
                    setItemCount={setItemCount}
                    pagination={pagination}
                    setPagination={setPagination}
                    noPaddingCells={["leave_type_id", "fullHalfDay", "status"]}
                />
            </div>
            <div className="pt-6">
                <TablePaginator
                    total={itemCount}
                    pagination={pagination}
                    setPagination={setPagination}
                    itemCount={total}
                    setCurrentPage={setCurrentPage}
                    currentPageDefault={currentPage}
                    changePage={changePage}
                />
            </div>
        </div>
    );
};

export default memo(MyProjectTable);
