import { Outlet, Route, Routes, useNavigate } from "react-router-dom";
import { withErrorBoundary } from "./layouts/RoutedErrorBoundary";
import LoginRoute from "./login";
import ForgetPasswordRoute from "./forgetpassword";
import EmailSentRoute from "./emailsent";
import SectionRoute from "./auth/components/SectionRoute";
import UsersRoute, { empRoutes, userRoutes } from "./userProfile";
import DashboardRoute from "./dashboard";
import { Dashboard } from "./dashboard/views/Dashboard";
import MedaiRoute from "./media";
import PermissionRoute from "./permissions";
import BackendUserRoute, { backendUserRoutes } from "./backendUsers";
import NotFoundPage from "./components/NotFoundPage";
import ProjectsRoute, { projectRoutes } from "./projects";
import jwt_decode from "jwt-decode";
import { passwordRoutes } from "./passwords";
import ActiveTimeReportRoute from "./active-time-report";
import { employeeRoutes } from "./employee-list";
import AnnouncementRoute, { announcementRoutes } from "./announcements";
import Documents, { documentRoutes } from "./documents";
// import LeaveApplication from "./components/DashboardProfile/MyLeave/LeaveApplication";
// import LeaveApplicationRoute from "./leave-application";
import { logout } from "./app/services/auth.service";
import ResetPasswordRoute from "./reset-password";
import PasswordResetSuccessRoute from "./password-reset-success";
import Passwords from "./passwords";
import CrmRoute, { crmRoutes } from "./crm";
import PerformanceAppraisalRoute, {
  performanceRoutes,
} from "./performance-appraisal";
import PerformanceAppraisalDetailRoute from "./performance-appraisal-detail";
import PageNotFound from "./components/PageNotFound/PageNotFound";
import ActivityLogRoute from "./activity-log";
// import ProjectBoardRoute from "./pro";
import NotificationView from "./components/NotificationView/NotificationView";
import NotificationRoute from "./notificationlist";
import { lazy, Suspense } from "react";
import { useGetLoginDataMutation } from "./app/services/dashboard";
import { useEffect } from "react";
// import CommonTableSkeleton from "./components/TableSkeleton/CommonTableSkeleton";
import EmployeeIncentiveRoute from "./employee-incentive";
import ExchangeRateRoute from "./exchange-rate";
import DocumentViews from "./components/DocumentView/DocumentView";
import ProjectCheckerRoute from "./project-checker";
import ProjectCheckerAccRoute from "./projectCheckerAcc";
import TaskListRoute from "./tasks-list";
import TaskDetailListRoute from "./task-detail-list";
import TaskCategoryList from "./taskCategory/view/TaskCategory";
import BasicLayout from "./layouts/BasicLayout";
import ProjectStatus from "./projectStatus/view/ProjectStatus";
import ProjectTech from "./project-tech/view/ProjectTech";
import AnnoucementListRoute from "./annoucementlist";
import EmployeeLeaveRoute from "./employeeLeave";
import MonitoringDetailRoute, {
  monitoringDetailRoute,
} from "./monitoring-detail";
import Department from "./department/view/Department";
import Designation from "./designation/view/Designation";
import WorkingShiftRoute from "./working-shift";
// import LeaveTypeRoute from "./leave-type";
// import ProjectKeyDateRoute from "./project-keydate";
// import ProjectKeyDate from "./project-keydate/view/ProjectKeyDate";
// import ProjectBoardRoute from "./project-board";
// import TaskDetailList from "./task-detail-list/view/TaskDetailList";
// import PayRollRoute from "./payroll";
// import { useJwt } from "react-jwt";

const LeaveTypeRoute = lazy(() => import("./leave-type"));
const ProjectBoardRoute = lazy(() => import("./project-board"));
const EmployeeListRoute = lazy(() => import("./employee-list"));
const ProjectKeyDateRoute = lazy(() => import("./project-keydate"));
// const PayRollRoute = lazy(() => import("./payroll"));
const ClaimFormRoute = lazy(() => import("./claim-form"));
// const PayrollRateRoute = lazy(() => import("./payroll-rate"));
const ClaimType = lazy(() => import("./claim-type"));
const OffDayRoute = lazy(() => import("./off-day-office-working"));
const AddClaimTypeRoute = lazy(() => import("./claim-new"));

export interface userData {
  email: string;
  token: string;
  role: string;
}

export const checkToken = (route: any) => {
  var userData = localStorage.getItem("userData");
  var userJson: any = {};
  if (userData) {
    userJson = JSON.parse(userData);
    userJson.token = userJson.accessToken;
  }
  var isValid = false;
  if (userJson.token != undefined && userJson.token != null) {
    try {
      const decodedToken: { sub: string; role: string; exp: number } =
        jwt_decode(userJson.token);
      const userId = decodedToken.sub;
      const userRole = decodedToken.role;
      if (decodedToken.exp * 1000 < Date.now()) {
        logout();
        route("/login");
      }
      isValid = true;
    } catch (e) {
      isValid = false;
    }
  }
  return isValid;
};

const AnnouncPermissions: string[] = [
  "View_Announcement",
  "Import_Announcement",
  "Export_Announcement",
  "Create_New_Announcement",
  "Management_Announcement",
  "Delete_Announcement",
];

const claimFormPermissions: string[] = [
  "View_Own_ClaimForm_List",
  "View_All_ClaimForm_List",
  "Manage_All_ClaimForm_List",
  "Manage_Own_ClaimForm_List",
  "Manage_ClaimForms",
  "Approve_ClaimForms",
  // "Create_Claim_Form",
];
const exchangeRatePermissions: string[] = ["Manage_Exchange_Rates"];
const incentivePermissions: string[] = ["Manage_Employee_Incentives"];
const claimTypePermissions: string[] = [
  "Manage_ClaimForms",
  // "Create_Claim_Form",
];

const DocumentPemis: string[] = [
  "View_Document",
  "View_Own_Document",
  "Download_My_Document",
  "Upload_Document",
  "Download_Document",
  "Assign_Document",
  "Delete_Document",
];

const UserPemis: string[] = [
  "View_My_Profile",
  "Export_User",
  "Import_User",
  "Manage_User",
  "View_User",
  "Activate_User",
  "Create_New_User",
  "Delete_User",
];

const DashBoardPermis: string[] = ["View_Admin_Dashboard"];
const offDayPermissions: string[] = ["Manage_Office_Working_Days"];

const MediaPermis: string[] = ["View_Media_Library", "Manage_Media_Library"];

const ActivityPermis: string[] = ["View_Activity_Log", "Manage_Activity_Log"];

const ProjectCheckerPermis: string[] = ["View_Progress_Checker"];
const ProjectCheckerAccPermis: string[] = ["View_Progress_Checker_Account"];

const TaskListPermis: string[] = ["View_Progress_Checker"];

const BKUserPermis: string[] = ["View_Role", "View_User"];

const ProjectPermis: string[] = ["View_Project"];

const CrmPermis: string[] = ["View_Crm"];

const PasswordPermis: string[] = ["View_Password", "View_Own_Password"];

const EmployeePermis: string[] = [
  "View_Employee",
  "View_Leave_Application",
  "View_OTList",
  "View_All_OT_List",
  "View_Own_OT_List",
  "View_Active_Time_Report",
  "Import_Employee",
  "Create_New_Employee",
  "Manage_Employee",
  "View_Employee_Profile",
  "View_Employee_Coded_Value",
  "Send_Employee_Message",
  "Delete_Employee",
  "Manage_Employee_Basic_Info",
  "Manage_Payroll",
];
const TaskCategoryPermis: string[] = ["Manage_Task_Categories"];
// const PayrollPermis: string[] = ["Manage_Payroll"];

const MainRouter = () => {
  const [getLoginData] = useGetLoginDataMutation();
  var pathData = new URLSearchParams(window.location.search);
  var code = pathData.get("code");
  var state = pathData.get("state");
  var userData = localStorage.getItem("userData");
  useEffect(() => {
    if (code && state) {
      var data = {
        code: code,
        state: state,
      };
      getLoginData(data).then((res: any) => {
        if (res.data) {
          userData = localStorage.getItem("userData");
        }
      });
    }
  }, []);
  const navigate = useNavigate();

  var userJson: any = {};
  if (userData) {
    userJson = JSON.parse(userData);
    userJson.token = userJson.accessToken;
  }
  var data = userJson;

  var isValid = checkToken(navigate);

  return (
    <>
      <Routes>
        <Route
          path="/login"
          element={
            <SectionRoute loginData={data} isValid={isValid}>
              <LoginRoute />
            </SectionRoute>
          }
        />
        <Route
          path="/forgetpassword"
          element={
            <SectionRoute loginData={data} isValid={isValid}>
              <ForgetPasswordRoute />
            </SectionRoute>
          }
        />
        <Route
          path="/resetpassword"
          element={
            <SectionRoute loginData={data} isValid={isValid}>
              <ResetPasswordRoute />
            </SectionRoute>
          }
        />
        <Route
          path="/resetpassword-success"
          element={
            <SectionRoute loginData={data} isValid={isValid}>
              <PasswordResetSuccessRoute />
            </SectionRoute>
          }
        />
        <Route
          path="/emailsent"
          element={
            <SectionRoute loginData={data} isValid={isValid}>
              <EmailSentRoute />
            </SectionRoute>
          }
        />
        <Route
          path="/"
          element={
            <SectionRoute
              loginData={data}
              permissions={DashBoardPermis}
              isValid={isValid}
            >
              <DashboardRoute loginData={data} permissions={DashBoardPermis} />
            </SectionRoute>
          }
        />

        <Route
          path="/media"
          element={
            <SectionRoute
              loginData={data}
              isValid={isValid}
              permissions={MediaPermis}
            >
              <MedaiRoute loginData={data} permissions={MediaPermis} />
            </SectionRoute>
          }
        />

        <Route
          path="/activity_log"
          element={
            <SectionRoute
              loginData={data}
              isValid={isValid}
              permissions={ActivityPermis}
            >
              <ActivityLogRoute loginData={data} permissions={ActivityPermis} />
            </SectionRoute>
          }
        />

        {/* Activity log */}

        <Route
          path="/working-shift"
          element={
            <SectionRoute
              loginData={data}
              isValid={isValid}
              permissions={ActivityPermis}
            >
              <WorkingShiftRoute loginData={data} permissions={ActivityPermis} />
            </SectionRoute>
          }
        />

        {/* project board route */}

        <Route
          path="/projects-board"
          element={
            <SectionRoute
              loginData={data}
              isValid={isValid}
              permissions={ActivityPermis}
            >
              <Suspense fallback={<h1>Loading.....</h1>}>
                <ProjectBoardRoute
                  loginData={data}
                  permissions={ActivityPermis}
                />
              </Suspense>
            </SectionRoute>
          }
        ></Route>

        <Route
          path="/projects-board"
          element={
            <SectionRoute
              loginData={data}
              isValid={isValid}
              permissions={ActivityPermis}
            >
              <ProjectBoardRoute
                loginData={data}
                permissions={ActivityPermis}
              />
            </SectionRoute>
          }
        />

        {/* <Route
        path="/dashboard"
        element={
          <SectionRoute
            loginData={data}
            permissions={["admin", "manager"]}
            isValid={isValid}
          >
            <DashboardRoute loginData={data} />
          </SectionRoute>
        }
      ></Route> */}
        <Route
          path="/profile"
          element={
            <SectionRoute
              loginData={data}
              isValid={isValid}
              permissions={UserPemis}
            >
              <UsersRoute loginData={data} permissions={UserPemis} />
            </SectionRoute>
          }
        >
          {userRoutes.map(({ path, component: Element }) => (
            <Route path={path} element={Element} key={path} />
          ))}
        </Route>
        <Route
          path="/employeeprofile/:id"
          element={
            <SectionRoute
              loginData={data}
              isValid={isValid}
              permissions={UserPemis}
            >
              <UsersRoute loginData={data} permissions={UserPemis} />
            </SectionRoute>
          }
        >
          {userRoutes.map(({ path, component: Element }) => (
            <Route path={path} element={Element} key={path} />
          ))}
        </Route>
        <Route
          path="/users"
          element={
            <SectionRoute
              loginData={data}
              isValid={isValid}
              permissions={BKUserPermis}
            >
              <BackendUserRoute loginData={data} permissions={BKUserPermis} />
            </SectionRoute>
          }
        >
          {backendUserRoutes.map(({ path, component: Element }) => (
            <Route path={path} element={Element} key={path} />
          ))}
        </Route>
        <Route
          path="/projects"
          element={
            <SectionRoute
              loginData={data}
              isValid={isValid}
              permissions={ProjectPermis}
            >
              <ProjectsRoute loginData={data} permissions={ProjectPermis} />
            </SectionRoute>
          }
        >
          {projectRoutes.map(({ path, component: Element }) => (
            <Route path={path} element={Element} key={path} />
          ))}
        </Route>

        <Route
          path="/progress-checker"
          element={
            <SectionRoute
              loginData={data}
              isValid={isValid}
              permissions={ProjectCheckerPermis}
            >
              <ProjectCheckerRoute
                loginData={data}
                permissions={ProjectCheckerPermis}
              />
            </SectionRoute>
          }
        />

        <Route
          path="/project-checker-acc"
          element={
            <SectionRoute
              loginData={data}
              isValid={isValid}
              permissions={ProjectCheckerAccPermis}
            >
              <ProjectCheckerAccRoute
                loginData={data}
                permissions={ProjectCheckerAccPermis}
              />
            </SectionRoute>
          }
        />

        <Route
          path="/all-task-list"
          element={
            <SectionRoute
              loginData={data}
              isValid={isValid}
              permissions={TaskListPermis}
            >
              <TaskListRoute loginData={data} permissions={TaskListPermis} />
            </SectionRoute>
          }
        />

        <Route
          path="/progress-checker/:id/task-detail-list"
          element={
            <SectionRoute
              loginData={data}
              isValid={isValid}
              permissions={TaskListPermis}
            >
              <TaskDetailListRoute
                loginData={data}
                permissions={TaskListPermis}
              />
            </SectionRoute>
          }
        />

        <Route
          path="/password"
          element={
            <SectionRoute
              loginData={data}
              isValid={isValid}
              permissions={PasswordPermis}
            >
              <Passwords loginData={data} permissions={PasswordPermis} />
            </SectionRoute>
          }
        >
          {passwordRoutes.map(({ path, component: Element }) => (
            <Route path={path} element={Element} key={path} />
          ))}
        </Route>
        {/* employee  list */}
        <Route
          path="/employeeList"
          element={
            <SectionRoute
              loginData={data}
              isValid={isValid}
              permissions={EmployeePermis}
            >
              <Suspense fallback={<h1>Loading.....</h1>}>
                <EmployeeListRoute
                  loginData={data}
                  permissions={EmployeePermis}
                />
              </Suspense>
            </SectionRoute>
          }
        >
          {employeeRoutes.map(({ path, component: Element }) => (
            <Route path={path} element={Element} key={path} />
          ))}
        </Route>
        <Route
          path="/announcement"
          element={
            <SectionRoute
              loginData={data}
              isValid={isValid}
              permissions={AnnouncPermissions}
            >
              <AnnouncementRoute
                loginData={data}
                permissions={AnnouncPermissions}
              />
            </SectionRoute>
          }
        >
          {announcementRoutes.map(({ path, component: Element }) => (
            <Route path={path} element={Element} key={path} />
          ))}
        </Route>
        {/* for screeen shot deail page */}
        <Route
          path="/detail-screenshot/:id"
          element={
            <SectionRoute
              loginData={data}
              isValid={isValid}
              permissions={AnnouncPermissions}
            >
              <MonitoringDetailRoute
                loginData={data}
                permissions={AnnouncPermissions}
              />
            </SectionRoute>
          }
        >
          {monitoringDetailRoute.map(({ path, component: Element }) => (
            <Route path={path} element={Element} key={path} />
          ))}
        </Route>

        <Route
          path="/documents"
          element={
            <SectionRoute
              loginData={data}
              isValid={isValid}
              permissions={DocumentPemis}
            >
              <Documents loginData={data} permissions={DocumentPemis} />
            </SectionRoute>
          }
        >
          {documentRoutes.map(({ path, component: Element }) => (
            <Route path={path} element={Element} key={path} />
          ))}
        </Route>
        <Route
          path="/employee/:id/leave-information"
          element={
            <SectionRoute
              loginData={data}
              isValid={isValid}
              permissions={EmployeePermis}
            >
              <EmployeeLeaveRoute
                loginData={data}
                permissions={EmployeePermis}
              />
            </SectionRoute>
          }
        >
          {documentRoutes.map(({ path, component: Element }) => (
            <Route path={path} element={Element} key={path} />
          ))}
        </Route>
        {/* for crm */}
        <Route
          path="/crm"
          element={
            <SectionRoute
              loginData={data}
              isValid={isValid}
              permissions={CrmPermis}
            >
              <CrmRoute loginData={data} permissions={CrmPermis} />
            </SectionRoute>
          }
        >
          {crmRoutes.map(({ path, component: Element }) => (
            <Route path={path} element={Element} key={path} />
          ))}
        </Route>
        <Route
          path="/performance-appraisal"
          element={
            <SectionRoute loginData={data} isValid={isValid} permissions={[]}>
              <PerformanceAppraisalRoute loginData={data} permissions={[]} />
            </SectionRoute>
          }
        ></Route>
        <Route
          path="/performance-appraisal-detail"
          element={
            <SectionRoute loginData={data} isValid={isValid} permissions={[]}>
              <PerformanceAppraisalDetailRoute
                loginData={data}
                permissions={[]}
              />
            </SectionRoute>
          }
        ></Route>
        <Route
          path="/notifications"
          element={
            <NotificationRoute loginData={data} permissions={EmployeePermis} />
          }
        ></Route>
        <Route
          path="/profile/announcement"
          element={
            <AnnoucementListRoute
              loginData={data}
              permissions={EmployeePermis}
            />
          }
        ></Route>
        {/* ----------------for claim form type-------------------- */}
        <Route
          path="/claim-type"
          element={
            <SectionRoute
              loginData={data}
              isValid={isValid}
              permissions={claimTypePermissions}
            >
              <Suspense fallback={<h1>Loading.....</h1>}>
                <ClaimType
                  loginData={data}
                  permissions={claimTypePermissions}
                />
              </Suspense>
            </SectionRoute>
          }
        ></Route>
        {/* ----------------end claim form type-------------------- */}

        {/* ----------------for new claim form type-------------------- */}
        <Route
          path="/new-claim-type"
          element={
            <SectionRoute
              loginData={data}
              isValid={isValid}
              permissions={claimFormPermissions}
            >
              <Suspense fallback={<h1>Loading.....</h1>}>
                <AddClaimTypeRoute
                  loginData={data}
                  permissions={claimFormPermissions}
                />
              </Suspense>
            </SectionRoute>
          }
        ></Route>
        {/* ----------------end new claim form type-------------------- */}

        {/* ----------------for claims form list-------------------- */}
        <Route
          path="/claims"
          element={
            <SectionRoute
              loginData={data}
              isValid={isValid}
              permissions={claimFormPermissions}
            >
              <Suspense fallback={<h1>Loading.....</h1>}>
                <ClaimFormRoute
                  loginData={data}
                  permissions={claimFormPermissions}
                />
              </Suspense>
            </SectionRoute>
          }
        ></Route>
        {/* ----------------end claims form list-------------------- */}
        <Route
          path="/incentive"
          element={
            <SectionRoute
              loginData={data}
              isValid={isValid}
              permissions={incentivePermissions}
            >
              <Suspense fallback={<h1>Loading.....</h1>}>
                <EmployeeIncentiveRoute
                  loginData={data}
                  permissions={incentivePermissions}
                />
              </Suspense>
            </SectionRoute>
          }
        ></Route>
        <Route
          path="/exchange-rate"
          element={
            <SectionRoute
              loginData={data}
              isValid={isValid}
              permissions={exchangeRatePermissions}
            >
              <Suspense fallback={<h1>Loading.....</h1>}>
                <ExchangeRateRoute
                  loginData={data}
                  permissions={exchangeRatePermissions}
                />
              </Suspense>
            </SectionRoute>
          }
        ></Route>

        {/* <Route
          path="/payroll-rate"
          element={
            <SectionRoute
              loginData={data}
              isValid={isValid}
              permissions={claimFormPermissions}
            >
              <Suspense fallback={<h1>Loading.....</h1>}>
                <PayrollRateRoute
                  loginData={data}
                  permissions={claimFormPermissions}
                />
              </Suspense>
            </SectionRoute>
          }
        ></Route> */}

        {/* ----------------for off day list-------------------- */}
        <Route
          path="/off-day"
          element={
            <SectionRoute
              loginData={data}
              isValid={isValid}
              permissions={offDayPermissions}
            >
              <Suspense fallback={<h1>Loading.....</h1>}>
                <OffDayRoute loginData={data} permissions={offDayPermissions} />
              </Suspense>
            </SectionRoute>
          }
        ></Route>
        {/* ----------------end off day list-------------------- */}

        <Route
          path="/document-view"
          element={
            <SectionRoute
              loginData={data}
              isValid={isValid}
              permissions={EmployeePermis}
            >
              <DocumentViews />
            </SectionRoute>
          }
        ></Route>
        <Route
          path="*"
          element={
            <SectionRoute
              loginData={data}
              isValid={isValid}
              permissions={EmployeePermis}
            >
              <PageNotFound />
            </SectionRoute>
          }
        ></Route>
        <Route
          path="/time"
          element={
            <SectionRoute
              loginData={data}
              isValid={isValid}
              permissions={UserPemis}
            >
              <PageNotFound />
            </SectionRoute>
          }
        ></Route>
        <Route
          path="/task-category"
          element={
            <SectionRoute
              loginData={data}
              isValid={isValid}
              permissions={TaskCategoryPermis}
            >
              <BasicLayout userData={data} permissions={TaskCategoryPermis}>
                <TaskCategoryList />
                <Outlet />
              </BasicLayout>
            </SectionRoute>
          }
        ></Route>
        <Route
          path="/project-status"
          element={
            <SectionRoute
              loginData={data}
              isValid={isValid}
              permissions={TaskCategoryPermis}
            >
              <BasicLayout userData={data} permissions={TaskCategoryPermis}>
                <ProjectStatus />
                <Outlet />
              </BasicLayout>
            </SectionRoute>
          }
        ></Route>
        <Route
          path="/project-tech"
          element={
            <SectionRoute
              loginData={data}
              isValid={isValid}
              permissions={TaskCategoryPermis}
            >
              <BasicLayout userData={data} permissions={TaskCategoryPermis}>
                <ProjectTech />
                <Outlet />
              </BasicLayout>
            </SectionRoute>
          }
        ></Route>

        {/* ----------------for project key date list-------------------- */}
        <Route
          path="/project-keydate"
          element={
            <SectionRoute
              loginData={data}
              isValid={isValid}
              permissions={TaskCategoryPermis}
            >
              <Suspense fallback={<h1>Loading.....</h1>}>
                <ProjectKeyDateRoute
                  loginData={data}
                  permissions={TaskCategoryPermis}
                />
              </Suspense>
            </SectionRoute>
          }
        ></Route>
        {/* ----------------end project key date list-------------------- */}

        {/* ----------------for department list-------------------- */}
        <Route
          path="/departments"
          element={
            <SectionRoute
              loginData={data}
              isValid={isValid}
              permissions={EmployeePermis}
            >
              <BasicLayout userData={data} permissions={EmployeePermis}>
                <Department />
                <Outlet />
              </BasicLayout>
            </SectionRoute>
          }
        ></Route>
        {/* ----------------end department list-------------------- */}

        {/* ----------------for leave type list-------------------- */}
        <Route
          path="/leave-type"
          element={
            <SectionRoute
              loginData={data}
              isValid={isValid}
              permissions={EmployeePermis}
            >
              <Suspense fallback={<h1>Loading.....</h1>}>
                {/* <BasicLayout userData={data} permissions={EmployeePermis}>
                  <LeaveTypeRoute />
                  <Outlet />
                </BasicLayout> */}
                <LeaveTypeRoute loginData={data} permissions={EmployeePermis} />
              </Suspense>
            </SectionRoute>
          }
        ></Route>
        {/* ----------------end leave type list-------------------- */}

        {/* ----------------for designations list-------------------- */}
        <Route
          path="/designations"
          element={
            <SectionRoute
              loginData={data}
              isValid={isValid}
              permissions={EmployeePermis}
            >
              <BasicLayout userData={data} permissions={EmployeePermis}>
                <Designation />
                <Outlet />
              </BasicLayout>
            </SectionRoute>
          }
        ></Route>
        {/* ----------------end designations list-------------------- */}
      </Routes>
    </>
  );
};

export default withErrorBoundary(MainRouter);
