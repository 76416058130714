import NotFound from './img/404.png';
const PageNotFound = () => {
    return <div className="relative bg-[#F3F6F9] h-[100vh] overflow-hidden">
        <div className='h-full flex flex-col justify-center'>
            <div className='relative'>
                <p className='absolute w-full text-center 2xl:top-[80px] md:top-[40px] 5xs:top-5 top-0 font-poppins xl:text-[56px] sm:text-[36px] text-[30px] text-black2 font-semibold'>Page Not Found</p>
                <img src={NotFound} alt='404' className='max-w-[85%] mx-auto object-cover sm:h-auto 5xs:h-[400px] h-[300px]'/>
                <div className='absolute w-full text-center md:bottom-[80px] 5xs:bottom-5 bottom-0 5xs:px-12 px-8'>
                    <p className='font-poppins xl:text-20 text-sm text-black2 font-semibold'>The page you are looking for might have been removed had its name changed or is temporarily unavailable.</p>
                </div>
            </div>
        </div>
    </div>
}
export default PageNotFound;