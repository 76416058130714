import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { PaginationState, SortingState } from "@tanstack/react-table";
import { ColumnFiltersState } from "@tanstack/react-table";
import Styles from "../MyLeave/MyLeave.module.scss";
import { CloseIcon, EditIcon, MemberIcon } from "../../common/Icons";
import { renderDateFormatYMD } from "../../DashboardCalendarView/DashboardAddEventPopup";
import { getAuthUser } from "../../../app/services/dashboard";
import DatePopupFoFilter from "../../EmployeeTable/DatePopupFoFilter";
import CustomDropdownListForFilter from "../../EmployeeTable/CustomDropdownListForFilter";
import CustomScrollbar from "../../CustomScrollbar/CustomScrollbar";
import { useSpring } from "react-spring";
import CommonTableSkeleton from "../../TableSkeleton/CommonTableSkeleton";
import { ToastContainer, toast } from "react-toastify";
import { Tdocuments } from "../../../leave-application/views/LeaveApplication";
import DailyReportTable from "./DailyReportTable";
import ErrorPopup from "../../ErrorPopup/ErrorPopup";
import { checkedIsNew } from "../../../app/services/employee";
import {
  IDailyReportData,
  IRefillDateObj,
  TProjectListDailyReport,
  TTaskTagsList,
  useGetProfileDailyReportFilterDataQuery,
  useGetProfileDailyReportListQuery
} from "../../../app/services/profile-daily-report";
import { NumberDropdownList } from "../../common/DropdownList";
import CustomDropdownListForFilterWithImage from "../../EmployeeTable/CustomDropdownListForFilterWithImage";
import DailyReportPopup from "./DailyReportPopup";
import { changeStringToHMS } from "../../CustomTimePicker/CustomTimePicker";
import { TPreviousList } from "../../../app/services/profile-daily-popup";
import Button from "../../common/Button";
import classNames from "classnames";
import MemberReportApproal from "./MemberReportApproal";
import { useSelector } from "react-redux";
import { useViewNotificationByTypeMutation } from "../../../app/services/notification";
import { useDispatch } from "react-redux";
import { setProfileNotification } from "../../../reducers/dashboard";
import { NavLink } from "react-router-dom";

export const addNewRow = () => {
  return {
    id: 0,
    date: "",
    project_id: "",
    task_name: "",
    task_tag: "",
    time_spent: "",
    isNew: true,
  };
};

const DailyReportMemberApplication: FC = () => {
  const [totalTime, setTotalTime] = useState<string>("");
  const [startDate, setStartDate] = useState<Date>();
  const [refillDate, setRefillDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  const [projectId, setProjectId] = useState<any>({});
  const [taskTagId, setTaskTagId] = useState<number>(0);
  const [projectLists, setProjectList] = useState<TProjectListDailyReport[]>();
  const [taskTagsList, setTaskTagsList] = useState<TTaskTagsList[]>();
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 1,
    pageSize: 10,
  });
  const [sorting, setSorting] = useState<SortingState>([]);
  const [errorPopup, setErrorPopup] = useState<boolean>(false);
  const [message, setMessage] = useState<any>([]);
  // const [isEditing, setIsEdit] = useState<boolean>(false);
  // const [uploading, setUploading] = useState<boolean>(false);
  const [viewLeave, setViewLeave] = useState<boolean>(false);
  const [data, setData] = useState<any>([]);
  const [itemCount, setItemCount] = useState<number>(0);
  const [memberID, setMemberID] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [memberData, setMemberData] = useState<any>([]);
  const [isLeader, setIsLeader] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [maxTimeLimit, setMaxTimeLimit] = useState<number>(0);
  const [total_time_str, setTotalTimeStr] = useState<string | undefined>('');
  const [todayData, setTodayData] = useState<any>();
  const [timeHour, setTimeHour] = useState<string>("");
  const [getHour, setGetHour] = useState<number>(0);
  const [getMinute, setGetMinute] = useState<number>(0);  
  const [refillDateList, setRefillDateList] = useState<IRefillDateObj[]>([]);
  const [previousDataList, setPreviousDataList] = useState<IDailyReportData[]>([]);
  const [pendingCount,setPendingCount]=useState<number>(0);
  const [currentTab, setCurrentTab] = useState<string>('memberReport');
  const { data: filterData } = useGetProfileDailyReportFilterDataQuery();
  const { data: dailyReport } = useGetProfileDailyReportListQuery({
    page: currentPage,
    limit: pagination.pageSize,
    start: startDate === undefined ? "" : renderDateFormatYMD(startDate, "-"),
    end: endDate === undefined ? "" : renderDateFormatYMD(endDate, "-"),
    type: projectId.type !== undefined ? projectId.type : "",
    project_id: projectId.value !== undefined ? projectId.value : 0,
    task_category_id: taskTagId,
    sorting: sorting.map((s) => `${s.desc ? "-" : ""}${s.id == "time_spent_text" ? "time_spent" : s.id}`).join(","),
    employee_id: memberID
  });
  const stateData: any = useSelector((state) => state);
  const [viewLeaveByType] = useViewNotificationByTypeMutation();
  const dispatch = useDispatch();
  var getUser = getAuthUser();
  const getAuthPermis: string[] = useMemo(() => [], []);

  if (getUser?.permissions) {
    const getPer = getUser?.permissions?.forEach((per: any) => {
      getAuthPermis.push(per?.name);
    });
  }

  const fetchDataMemoized = useCallback(() => {
    if (dailyReport) {
      setData(dailyReport.daily_report_list.data);
      setItemCount(dailyReport.daily_report_list.total);      
      setTotalTime(dailyReport.total_time);
    }
  }, [dailyReport]);
  useEffect(() => {
    if (filterData) {
      setProjectList([
        { id: 0, name: "All", type: "All" },
        ...filterData.project_list,
      ]);
      setTaskTagsList([{ id: 0, name: "All" }, ...filterData.task_categories]);
      setIsLeader(filterData.is_leader)
      setMemberData(filterData.same_department_users)
      setRefillDateList(filterData?.refill_dates)
      setPendingCount(filterData.pending_refill_requests_count)
      // if(filterData.refill_data){
      //   const formatRefillDate = renderDateFormatYMD(new Date(filterData.refill_data[0]?.date), '-');
      //   const todayDataArr: any = filterData.refill_data.find(x => x.date == formatRefillDate);
      //   setRefillDate(new Date(filterData.refill_data[0]?.date));
      //   setTodayData(todayDataArr?.daily_report);

      // }
    }
  }, [filterData])
  useEffect(() => {
    fetchDataMemoized();
  }, [fetchDataMemoized]);
  useEffect(() => {
    if (refillDate) {
      if (filterData) {
        const formatRefillDate = renderDateFormatYMD(refillDate, '-');
        var refillDataPrev: any = filterData.previous_data.filter(x => x.date != formatRefillDate)
        const todayData = filterData.refill_data.find(x => x.date == formatRefillDate);
        var time = changeStringToHMS(todayData ? todayData.total_time : '');
        setMaxTimeLimit(time ? time : 0);
        //setTodayData(todayData?.daily_report);        
        //setReportData(todayData?.daily_report ? todayData?.daily_report?.items : []);
        setTimeHour(todayData?.total_time_text ? todayData?.total_time_text : '');
        setGetHour(todayData?.hours ? todayData?.hours : 0);
        setGetMinute(todayData?.minutes ? todayData?.minutes : 0);
        setTotalTimeStr(todayData?.total_time_text)
        setPreviousDataList(refillDataPrev)
      }
    }
  }, [refillDate])
  useEffect(() => {
    const viewLeave = getAuthPermis.filter(
      (f) => f == "View_Leave_Application"
    );
    if (viewLeave.length > 0) {
      setViewLeave(true);
    }
  }, [getUser]);

  const props = useSpring({
    to: { opacity: 1 },
    from: { opacity: 0 },
    delay: 500,
  });

  useEffect(() => {
    setCurrentPage(1);
  }, [
    startDate,
    endDate,
    pagination.pageSize,
    projectId?.type,
    projectId?.value,
    taskTagId,
  ]);

  var disabledData = checkedIsNew(data);

  const changeDailyReportTab = (tab: string) => {
    setCurrentTab(tab);
  }


  return (
    <>
     <>
          <ErrorPopup
            descText="Error Message!"
            confirmBtnText="Yes, Close."
            show={errorPopup}
            setShow={setErrorPopup}
            message={message}
            setMessage={setMessage}
          />

          <ToastContainer className={"text-12 font-poppins font-normal"} />
          <div className="w-full bg-white rounded-xl text-left">
            <div className="px-2 sm:px-4 md:px-5 py-1 md:py-2 border-b border-offwhite flex items-center justify-between">
              <div className="flex flex-wrap items-center text-vorpgraylight">
                <h3 className="mr-7 text-[#3F4254] font-semibold text-[13px] sm:text-[15px] 2xl:text-[18px] leading-[22px] lg:leading-[27px]">
                  Daily Report
                </h3>
                <NavLink
                  to={{
                    pathname: "/profile/daily-report",
                  }} data-id="myReport" className={classNames(currentTab == "myReport" ? 'text-vorpblue' : 'text-vorpgraylight', "text-14 font-semibold  mr-2 relative")}>
                  <span>My Report</span>                 
                </NavLink>
                <span className={classNames("mx-2")}> | </span>
                <NavLink
                  to={{
                    pathname: "/profile/my-refill-report",
                  }} data-id="myRefillReport" className={classNames(currentTab == "myRefillReport" ? 'text-vorpblue' : 'text-vorpgraylight', "text-14 font-semibold  mr-2 relative")}>
                  <span>My Application</span>                  
                </NavLink>
                <span className={classNames(isLeader == true ? '' : 'hidden', "mx-2")}> | </span>
                <NavLink
                  to={{
                    pathname: "/profile/member-daily-report",
                  }} data-id="memberReport" className={classNames(isLeader == true ? '' : 'hidden', currentTab == "memberReport" ? 'text-vorpblue' : 'text-vorpgraylight', "text-14 font-semibold relative")}>
                  <span>Member’s Application</span>
                  {pendingCount > 0 ?
                    <div className="w-[18px] h-[18px] bg-redPink absolute top-0 left-full rounded-full"><span className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 inline-block text-white text-[10px] font-semibold p-[2px] whitespace-nowrap">{pendingCount}</span></div> : ''}
                </NavLink>
              </div>
              <div className="flex justify-end items-center">
                
                <button onClick={() => {
                  setIsOpen(true)
                }} className="whitespace-nowrap mb-1 lg:mb-1 xl:mb-1 py-2 my-1 px-3 rounded-md flex items-center focus:outline-none bg-vorpblue hover:border-vorpmyanmar 2xl:text-[14px] text-12 leading-5 font-medium text-white">
                  <EditIcon className="mr-2" />
                  Modify Report
                </button>
              </div>
            </div>            
            <div className="px-2 sm:px-4 md:px-5 pt-3 pb-[10px] border-b border-offwhite">
              {currentTab == "memberReport" ?               
                <div>
                  <MemberReportApproal
                    projectLists={projectLists}
                    setProjectList={setProjectList}
                    setTaskTagsList={setTaskTagsList}
                    setPendingCount={setPendingCount}
                    taskTagsList={taskTagsList} />
                </div>:''}
              <DailyReportPopup
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                // reportData={reportData ? reportData : []}
                // setReportData={setReportData}
                setTotalTimeStr={setTotalTimeStr}
                total_time_str={total_time_str}
                previousDataList={previousDataList}
                setPreviousDataList={setPreviousDataList}
                todayData={todayData}
                setTodayData={setTodayData}
                setTimeHour={setTimeHour}
                timeHour={timeHour}
                setGetHour={setGetHour}
                setGetMinute={setGetMinute}
                getHour={getHour}
                getMinute={getMinute}
                setMaxTimeLimit={setMaxTimeLimit}
                maxTimeLimit={maxTimeLimit}
                refillDate={refillDate}
                setRefillDate={setRefillDate}
                filterData={filterData}
              />
            </div>
          </div>
        </>
    </>
  );
};

export default DailyReportMemberApplication;
