import { FC, SetStateAction, Dispatch } from "react";
import {
  ColumnFiltersState,
  SortingState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { SortIcon } from "../common/Icons";
import Table from "./Tables.module.scss";
import classNames from "classnames";
import { customGlobalFilterFunction } from "../../utils/helpers";

interface IMinimalTable {
  data: any;
  columns: any;
  sorting?: SortingState;
  setSortingState?: Dispatch<SetStateAction<SortingState>>;
  theaderColor?: string;
  globalFilter?: string;
  setGlobalFilter?: Dispatch<SetStateAction<string>>;
  columnFilters?: ColumnFiltersState;
  setColumnFilters?: Dispatch<SetStateAction<ColumnFiltersState>>;
}

const MinimalTable: FC<IMinimalTable> = ({
  data,
  columns,
  sorting,
  setSortingState,
  theaderColor = "",
  globalFilter,
  setGlobalFilter,
  columnFilters,
  setColumnFilters,
}) => {
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      sorting,

      ...(globalFilter !== undefined ? { globalFilter } : {}),
      ...(columnFilters ? { columnFilters } : {}),
    },
    globalFilterFn: customGlobalFilterFunction,
    onSortingChange: setSortingState,
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    ...(setGlobalFilter ? { onGlobalFilterChange: setGlobalFilter } : {}),
    ...(setColumnFilters ? { onColumnFiltersChange: setColumnFilters } : {}),
  });

  // console.log("d", data)

  if (data === undefined) {
    return <></>;
  }

  return (
    <div className={`overflow-x-auto w-full ${Table.horizontalScrollBar}`}>
      <table className="overflow-x-auto min-w-full">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  id={header.id}
                  key={header.id}
                  className={classNames(
                    "text-12 leading-[18px] whitespace-nowrap font-semibold  bg-offWhite border border-vorpgraylight p-3 bg-offwhite",
                    {
                      "text-vorpgraylight": theaderColor === "",
                      [theaderColor]: theaderColor !== "",
                    }
                  )}
                >
                  <div className="flex justify-between items-center">
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                    {header.column.getCanSort() && (
                      <button
                        onClick={() => {
                          const currentState =
                            header.column.getIsSorted() === "desc";
                          header.column.toggleSorting(!currentState, false);
                        }}
                        className="ml-2"
                      >
                        <SortIcon />
                      </button>
                    )}
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map(
                (cell) => (
                  (
                    <td
                      id={row.id}
                      key={cell.id}
                      className={classNames(
                        "border border-vorpgraylight text-12 lg:text-14 leading-5 text-black2 py-3 px-[10px] whitespace-nowrap"
                      )}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  )
                )
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default MinimalTable;
