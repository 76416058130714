// import { Modal } from "@material-ui/core";
import { Modal } from "@mui/material"
import classNames from "classnames";
import React, { createRef, Dispatch, FC, SetStateAction, useRef } from "react";
interface ICreateData {
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  addNewItem?: any;
  closePopup?: any;
  isCurrency?: boolean;
  isRegion?: boolean;
  isProjectType?: boolean;
  isProjectStatus?: boolean;
  isPaymentStatus?: boolean;
  title?: string;
}
const CreateNewItemPopup: FC<ICreateData> = ({ show, setShow, addNewItem,
  closePopup, isCurrency, isRegion, isProjectType, isProjectStatus,
  isPaymentStatus, title }) => {
  var name = useRef<any>(null);
  var code = useRef<any>(null);

  return (
    <Modal open={show} onClose={() => {
      setShow(false);

    }}  disableEnforceFocus={true}>
      <div>
        <div
          className={classNames(
            "z-10 fixed top-0 left-0 w-full h-full"
          )}
        >
          <div
            className={classNames(
              "relative z-10 top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 max-w-[320px] bg-[#fff] px-5 rounded-lg"
            )}
          >
            <div className="flex justify-between items-center pb-[18px]">
              {
                isRegion ? (
                  <p className="pt-5 text-left font-poppins font-medium text-sm text-[#3F4254]">
                    Create new region
                  </p>
                ) : isProjectType ? (
                  <p className="pt-5 text-left font-poppins font-medium text-sm text-[#3F4254]">
                    Create new project type
                  </p>
                ) : isProjectStatus ? (
                  <p className="pt-5 text-left font-poppins font-medium text-sm text-[#3F4254]">
                    Create new project status
                  </p>
                ) : isPaymentStatus ? (
                  <p className="pt-5 text-left font-poppins font-medium text-sm text-[#3F4254]">
                    Create new payment status
                  </p>
                ) : isCurrency ? (
                  <p className="pt-5 text-left font-poppins font-medium text-sm text-[#3F4254]">
                    Create new currency
                  </p>
                ) : <p className="pt-5 text-left font-poppins font-medium text-sm text-[#3F4254]">
                  {title}
                </p>
              }
              {/* <p className="pt-5 text-left font-poppins font-medium text-sm text-[#3F4254]">
                Create new terms
              </p> */}
              <span
                onClick={() => closePopup ? closePopup() : setShow(false)}
                className="text-[#B5B5C3] text-20 pt-4 cursor-pointer"
              >
                &times;
              </span>
            </div>
            <input ref={name}
              type="text"
              className="text-14 rounded w-full border-[2px] border-[#3699FF] px-[11px] py-2 bg-[#fff] focus:outline-none mb-3"
              placeholder={`Enter ${isRegion ? 'region' : ''}${isProjectType ? 'project type' : ''}${isProjectStatus ? 'project status' : ''}${isCurrency ? 'Currency Name' : ''}${isPaymentStatus ? 'payment status' : ''} `}
            />
            {
              isCurrency ? (
                <input ref={code}
                  type="text"
                  className="text-14 rounded w-full border-[2px] border-[#3699FF] px-[11px] py-2 bg-[#fff] focus:outline-none mb-3"
                  placeholder="Enter Currency Code"
                />
              ) : null
            }
            <div className="flex justify-end pb-5">
              <button
                onClick={() => closePopup ? closePopup() : setShow(false)}
                className="px-4 py-2 rounded-[6px] font-poppins font-semibold text-xs text-[#979797] bg-[#EFEFEF] mr-2"
              >
                Cancel
              </button>
              {
                isCurrency ? (<button
                  onClick={() => addNewItem(name.current.value, code.current.value)}
                  className="px-4 py-2 rounded-[6px] font-poppins font-semibold text-xs text-[#fff] bg-[#009EF7]"
                >
                  Create
                </button>) : (<button
                  onClick={() => addNewItem(name.current.value)}
                  className="px-4 py-2 rounded-[6px] font-poppins font-semibold text-xs text-[#fff] bg-[#009EF7]"
                >
                  Create
                </button>)}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default CreateNewItemPopup;
