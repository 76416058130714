import {
  Dispatch,
  FC,
  FocusEvent,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { ChevronDown, SearchIcon } from "./Icons";
import { uniqueId } from "lodash";
import styles from "./Custom.module.scss";
const classNames = require("classnames");

interface ICustomDropdownList<P = unknown> {
  state: P;
  setState: any;
  placeholder?: string;
  dropdownItems: IListItem[];
  className?: string;
  textAlign?: string;
  dropdownborder?: string;
  setSearchData: Dispatch<SetStateAction<string>>;
  isShow?: boolean;
  setIsShow?: Dispatch<SetStateAction<boolean>>;
  isProject?: boolean;
  handleChange?: (id: number) => void;
  hasID?: number;
  isMultiData?: boolean;
}

interface IListItem {
  label?: string;
  value: string | number;
  photo?: string;
  email?: string;
}

// Type Factory for Generic Types

const CustomDropdownListFactory = <P extends unknown>() => {
  const ActualInstance: FC<ICustomDropdownList<P>> = ({
    state,
    setState,
    setSearchData,
    dropdownItems,
    placeholder = "All",
    className = "",
    textAlign = "text-center",
    dropdownborder = "",
    isShow,
    setIsShow,
    isProject,
    handleChange,
    hasID,
    isMultiData,
  }) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [filterDropdownItem, setFilterDropdownItem] =
      useState<IListItem[]>(dropdownItems);

    const [label, setLabel] = useState<string>(
      dropdownItems?.filter((item) => item.value === Number(state))[0]?.label ||
        placeholder
    );
    const uuid = uniqueId("custom-dropdown-list");
    var name = useRef<any>(null);
    const handleCloseDropdown = (event: FocusEvent<HTMLButtonElement>) => {
      const relatedTarget = event.relatedTarget;
      // If the click target is not the dropdown or dropdown item, close the list.
      if (
        !relatedTarget ||
        !(
          relatedTarget.id === uuid ||
          relatedTarget.className.includes(`${uuid}item`)
        )
      ) {
        setIsOpen(false);
      }
    };

    const [search, setSearch] = useState<any>();
    const handleInputChange = (search: string) => {
      const timeout = setTimeout(() => {
        setSearch(search);
      }, 300);
      return () => clearTimeout(timeout);
    };
    useEffect(() => {
      if (search) {
        var filterData = dropdownItems?.filter((x) =>
          x?.label?.toLocaleLowerCase().startsWith(search.toLocaleLowerCase())
        );
        setFilterDropdownItem(filterData);
      } else {
        setFilterDropdownItem(dropdownItems);
      }
    }, [search]);

    useEffect(() => {
      setFilterDropdownItem(dropdownItems);
    }, [dropdownItems]);

    useEffect(() => {
      const labels =
        dropdownItems?.filter((item) => item.value === Number(state))[0]
          ?.label || placeholder;
      setLabel(labels);
    }, [state, placeholder]);

    // function getRandomColor() {
    //   var letters = "0123456789ABCDEF";
    //   var color = "#";
    //   for (var i = 0; i < 6; i++) {
    //     color += letters[Math.floor(Math.random() * 16)];
    //   }
    //   return color;
    // }
    // const randomColor = getRandomColor();

    // console.log("state", state);
    // console.log("randomColor", randomColor);
    // console.log("label", label);

    return (
      <div className="relative customDropdownContainer">
        <button
          id={uuid}
          className={classNames(
            dropdownborder,
            isProject ? "h-12" : "h-10",
            `flex hover:cursor-pointer text-14 leading-5 text-graydark relative justify-between w-32 h-10 items-center pl-[11px] pr-[17px] border border-[#CCDAE4] rounded-[5px] custom-dropdown-list ${className}`
          )}
          onClick={() => {
            setIsOpen(!isOpen);
            if (setIsShow) {
              setIsShow(!isShow);
            }
          }}
          onBlur={handleCloseDropdown}
        >
          {/* {isProject && state ? (
            <p className="flex items-center">
              <p
                style={{
                  backgroundColor: `${randomColor}`,
                }}
                className={classNames(
                  // `bg-[${randomColor}]`,
                  " rounded-xl px-2 py-1 text-white font-bold text-13 mr-3"
                )}
              >
                {`${String(label).charAt(0)} `}
              </p>
              <span className="text-darkblack">
                {String(label) || placeholder}
              </span>
            </p>
          ) : (
            String(label) || String(state) || placeholder
          )} */}
          {String(label) || String(state) || placeholder}
          <ChevronDown />
        </button>

        {isShow ? (
          <div
            className={classNames(
              styles.customDropdownScroll,
              " absolute left-0 text-12 max-h-[300px] overflow-y-auto z-10 overflow-hidden top-[104%] w-full border-1 border-goalinputborder bg-white rounded-md"
              // { hidden: true }
            )}
          >
            <div className="rounded-4 flex items-center justify-between px-3 py-3 border-b border--b-goalinputborder">
              <input
                onChange={(e) => handleInputChange(e.target.value)}
                ref={name}
                type="text"
                className={classNames(
                  uuid + "item",
                  "text-graydark font-poppins font-normal xl:text-sm text-xs rounded w-full bg-[#fff] focus:outline-none"
                )}
                placeholder="Search"
              />
              <SearchIcon />
            </div>

            {filterDropdownItem?.map((item: IListItem) => (
              <button
                key={item.value}
                className={`px-5 custom-dropdown-item bg-white hover:bg-vorpblue py-1 w-full hover:text-white transition-all duration-300 text-graydark ${textAlign} ${
                  uuid + "item"
                }`}
                onClick={() => {
                  setState(item.value as any);
                  setLabel(item?.label ?? "");
                  setIsOpen(false);
                  if (setIsShow) {
                    setIsShow(!isShow);
                  }
                }}
              >
                <div className="flex flex-row p-1 items-center">
                  <div className="p-1">
                    {item.photo ? (
                      <img
                        src={item.photo}
                        alt={`Person ${item.photo}`}
                        width={34}
                        height={34}
                        className="rounded-full overflow-hidden w-[34px] h-[34px]"
                      />
                    ) : (
                      <div className="relative bg-yellowBright rounded-full overflow-hidden w-[34px] h-[34px]">
                        <p className="text-white font-bold text-13 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
                          {item.label ? item.label.charAt(0) : ""}
                        </p>
                      </div>
                    )}
                  </div>
                  <div className="flex flex-col text-sm p-1">
                    <span>{item.label ?? ""}</span>
                    <span>{item.email ?? ""}</span>
                  </div>
                </div>
              </button>
            ))}
          </div>
        ) : (
          <div
            className={classNames(
              styles.customDropdownScroll,
              " absolute left-0 text-12 max-h-[300px] overflow-y-auto z-10 overflow-hidden top-[104%] w-full border-1 border-goalinputborder bg-white rounded-md",
              { hidden: !isOpen }
            )}
          >
            <div className="rounded-4 flex items-center justify-between px-3 py-3 border-b border--b-goalinputborder">
              <input
                onChange={(e) => handleInputChange(e.target.value)}
                ref={name}
                type="text"
                className={classNames(
                  uuid + "item",
                  "text-graydark font-poppins font-normal xl:text-sm text-xs rounded w-full bg-[#fff] focus:outline-none"
                )}
                placeholder="Search"
              />
              <SearchIcon />
            </div>

            {filterDropdownItem?.map((item: IListItem) => (
              <button
                key={item.value}
                className={`px-5 custom-dropdown-item bg-white hover:bg-vorpblue py-1 w-full hover:text-white transition-all duration-300 text-graydark ${textAlign} ${
                  uuid + "item"
                }`}
                onClick={() => {
                  if (isMultiData) {
                    setState(Number(hasID), item.value as any);
                  } else {
                    setState(item.value as any);
                  }
                  setLabel(item?.label ?? "");
                  setIsOpen(false);
                }}
              >
                {/* {item.label} */}
                <div className="flex flex-row p-1 items-center">
                  <div className="p-1">
                    {item.photo ? (
                      <img
                        src={item.photo}
                        alt={`Person ${item.photo}`}
                        width={34}
                        height={34}
                        className="rounded-full overflow-hidden w-[34px] h-[34px]"
                      />
                    ) : (
                      <div className="relative bg-yellowBright rounded-full overflow-hidden w-[34px] h-[34px]">
                        <p className="text-white font-bold text-13 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
                          {item.label ? item.label.charAt(0) : ""}
                        </p>
                      </div>
                    )}
                  </div>
                  <div className="flex flex-col text-sm p-1">
                    <span>{item.label ?? ""}</span>
                    <span>{item.email ?? ""}</span>
                  </div>
                </div>
              </button>
            ))}
          </div>
        )}
      </div>
    );
  };
  return ActualInstance;
};

// Extend More for Required Types

export const StringDropdownListWithSearch = CustomDropdownListFactory<string>();
