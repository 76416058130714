import logo from '../../logo.svg';
import LogInComponent from '../components/LogInComponent';

function App() {
  return (
    <>      
      <LogInComponent />
    </>
  );
}

export default App;
