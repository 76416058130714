import React from "react";
import { WeatherIcon } from "../../common/Icons";
import useDate from "../../../hooks/useDate";

const ClockLiveComponent = () => {
  const {formattedDate, formattedTime} = useDate();

  return (
    <>
      <p className="flex items-start mr-[14px] text-12 leading-[18px] text-black2 mt-6 xsm:mt-0">
        <WeatherIcon className="mr-2 mt-[-4px]" />
        {formattedDate} . HKT -{" "}
        <span className="w-[80px]">{formattedTime}</span>
      </p>
    </>
  );
};

export default ClockLiveComponent;
