import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { PaginationState, SortingState } from "@tanstack/react-table";
import { ColumnFiltersState } from "@tanstack/react-table";
import { useGetProfileProjectsQuery } from "../../app/services/my-project";
// import { useSpring } from "react-spring";
import CommonTableSkeleton from "../TableSkeleton/CommonTableSkeleton";
import ErrorPopup from "../ErrorPopup/ErrorPopup";
import { ToastContainer } from "react-toastify";
// import CustomScrollbar from "../CustomScrollbar/CustomScrollbar";
import { NumberDropdownList } from "../common/DropdownList";
import DebounceSearchInput from "../Tables/DebounceSearchInput";
import { SearchIcon } from "../common/Icons";
import classNames from "classnames";
import MyProjectTable from "./MyProjectTable";
const MyProjects: FC = () => {
    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
    const [pagination, setPagination] = useState<PaginationState>({
        pageIndex: 1,
        pageSize: 10,
    });
    const [sorting, setSorting] = useState<SortingState>([]);
    const [errorPopup, setErrorPopup] = useState<boolean>(false);
    const [message, setMessage] = useState<any>([]);
    const [globalSearch, setGlobalSearch] = useState<string>("");
    // const [viewLeave, setViewLeave] = useState<boolean>(false);
    const [data, setData] = useState<any>([]);
    const [itemCount, setItemCount] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const { data: profileProjects, isLoading } = useGetProfileProjectsQuery({
        page: currentPage,
        limit: pagination.pageSize,
        search: globalSearch,
        sort: sorting.map((s) => `${s.desc ? "-" : ""}${s.id == "project_id" ? "project_code" : s.id}`).join(","),
    });
    useEffect(() => {
        if (profileProjects) {
            setData(profileProjects?.project_list?.data);
            setItemCount(profileProjects?.project_list?.total);
        }
    }, [profileProjects])

    useEffect(() => {
        setCurrentPage(1);
    }, [
        pagination.pageSize,
        globalSearch
    ]);
    return (
        <>
            {isLoading ? (
                <CommonTableSkeleton rows={12} isProfile={true} />
            ) : (
                <>
                    <ErrorPopup
                        descText="Error Message!"
                        confirmBtnText="Yes, Close."
                        show={errorPopup}
                        setShow={setErrorPopup}
                        message={message}
                        setMessage={setMessage}
                    />

                    <ToastContainer className={"text-12 font-poppins font-normal"} />
                    <div className="w-full bg-white rounded-xl text-left">
                        <div className="px-2 sm:px-4 md:px-5 py-1 md:py-2 border-b border-offwhite flex items-center justify-between">
                            <h3 className="text-[#3F4254] font-semibold text-[13px] sm:text-[15px] 2xl:text-[18px] leading-[22px] lg:leading-[27px]">
                                Projects
                            </h3>
                        </div>

                        <div className="px-2 sm:px-4 md:px-5 pt-3 pb-[10px] border-b border-offwhite">
                            <div className="mb-1 lg:mb-1 xl:mb-1">
                                <div className="flex justify-between flex-row items-center">
                                    <div className="items-center mr-2 xs:mr-4 sm:w-[260px] w-[225px] md:mb-1 hidden">
                                        <DebounceSearchInput setState={setGlobalSearch} debounce={800}>
                                            {(setState) => (
                                                <div className="font-normal flex border-1 border-[#C4C4C4] bg-white hover:bg-[#EFEFEF] rounded-[28px]">
                                                    <input
                                                        id="message"
                                                        autoComplete="off"
                                                        name="search"
                                                        type="text"
                                                        placeholder="Search"
                                                        className="focus:outline-none py-1 px-4 text-12 xl:text-14 leading-5 bg-transparent rounded-[28px] w-full text-graydark"
                                                        onChange={(e) => setState(e.target.value)}
                                                    />
                                                    <SearchIcon className={classNames("mr-2")} />
                                                </div>
                                            )}
                                        </DebounceSearchInput>
                                    </div>
                                    <div className="flex items-center mr-2 xs:mr-4 sm:w-[260px] w-[225px] md:mb-1">
                                        <DebounceSearchInput setState={setGlobalSearch} debounce={800}>
                                            {(setState) => (
                                                <div className="font-normal flex border-1 border-[#C4C4C4] bg-white hover:bg-[#EFEFEF] rounded-[28px]">
                                                    <input
                                                        id="message"
                                                        autoComplete="off"
                                                        name="search"
                                                        type="text"
                                                        placeholder="Search"
                                                        className="focus:outline-none py-1 px-4 text-12 xl:text-14 leading-5 bg-transparent rounded-[28px] w-full text-graydark"
                                                        onChange={(e) => setState(e.target.value)}
                                                    />
                                                    <SearchIcon className={classNames("mr-2")} />
                                                </div>
                                            )}
                                        </DebounceSearchInput>
                                    </div>
                                    <div className="flex items-center justify-end md:justify-start sm:w-auto w-full">
                                        <div className="flex items-center mb-auto mr-3">
                                            <p className="base-label xl:text-sm text-12 hidden 3xs:block">
                                                DISPLAY
                                            </p>
                                            <NumberDropdownList
                                                state={pagination.pageSize}
                                                setState={(value) => {
                                                    setPagination((prev) => ({
                                                        ...prev,
                                                        pageSize: value as number,
                                                    }));
                                                }}
                                                dropdownItems={new Array(5)
                                                    .fill(1)
                                                    .map((_, index: number) => {
                                                        let display = (index + 1) * 10;
                                                        return {
                                                            value: display,
                                                            label: String(display),
                                                        };
                                                    })}
                                                className="max-w-[72px]"
                                                textAlign="text-left pl-2 pr-3"
                                                paddingClass="py-[2px]"
                                                placeholder="20"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <MyProjectTable
                                columnFilters={columnFilters}
                                setColumnFilters={setColumnFilters}
                                data={data ? data : []}
                                // setData={setData}
                                // hiddenFields={[]}
                                pagination={pagination}
                                setPagination={setPagination}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                total={itemCount}
                                sorting={sorting}
                                setSorting={setSorting}
                            />
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default MyProjects;
