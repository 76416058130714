import { FC, useState } from "react";
import { currencyData } from "../../app/services/employee-incentive";
import { ExchangeRageListData, ExchangeRageResponse } from "../../app/services/exchange-rate";
import CustomDropdownListForFilter from "../../components/EmployeeTable/CustomDropdownListForFilter";
import DebounceInput from "../../components/Tables/DebounceInput";
import DatePopup from "../../components/EmployeeTable/DatePopup";
import CustomDatePicker from "../../components/common/BaseDatePicker";
import classNames from "classnames";
interface ExchangeRateData {
    exchangeRateData: ExchangeRageResponse | undefined;
    currencyList: currencyData[];
    exchange: any;
    setActiveRow: React.Dispatch<React.SetStateAction<number | undefined>>;
    activeRow: number | undefined;
    editExchangeRate: any;

}
const ExchangeRateForm: FC<ExchangeRateData> = ({ currencyList, exchangeRateData, exchange, activeRow, setActiveRow, editExchangeRate }) => {
    const [currency, setCurrency] = useState<string>(exchange?exchange.currency_name:currencyList[0]?.currency_name);
    const [fromDate, setFromDate] = useState<Date | undefined>(new Date());
    return <div>
        {/* <div className="w-1/2 mt-5">
            <CustomDatePicker
                date={fromDate}
                setDate={setFromDate}
                minDate={new Date()}
                isFilterSection={false}
                placeholder={new Date().toDateString()}
                iconColor="#858795"
                isMonthView={true}
            />
        </div> */}
        <div className="flex justify-between items-center border-1 border-goalinputborder px-5 w-1/2 rounded-[4px] mt-5">
            <div className="max-w-[100px] mr-5 custom-exchange-input">
                <DebounceInput setState={editExchangeRate} debounce={800}>
                    {(setState) => (
                        <input
                            id={exchange?.id}
                            type="number"
                            defaultValue={exchange?.rate}
                            className={classNames(exchange?.sorting==1?'opacity-70 pointer-events-none':'',`py-2 min-w-full min-h-full w-[80%] h-full focus:outline-none focus-within:outline-none focus-visible:outline-none text-ellipsis overflow-hidden disabled:bg-transparent px-2 font-normal font-poppins`)}
                            onChange={(e) => {
                                setActiveRow(exchange?.id)
                                setState({
                                    id: exchange?.id,
                                    cellId: 'rate',
                                    value: e.target.value
                                })
                            }}
                        />
                    )}
                </DebounceInput>
            </div>
            <div className="w-auto text-right">
                <div>
                    <p className="text-black2 text-sm font-normal">{exchange?exchange.currency_name:currencyList[0]?.currency_name} ({exchange?exchange.currency_code:currencyList[0]?.currency_code})</p>
                </div>
                {/* <CustomDropdownListForFilter
                    isFilter={true}
                    state={currency}
                    placeholder=" "
                    isAction={true}
                    setState={(value) => {
                        setActiveRow(exchange?.id);
                        editExchangeRate({
                            id: exchange?.id,
                            cellId: 'currency',
                            value: currencyList?.find(x => x.currency_name == value)?.id
                        })
                        setCurrency(value);
                    }}
                    dropdownItems={
                        currencyList
                            ? currencyList.map((st, id: number) => ({
                                value: st.id,
                                label: st.currency_name,
                            }))
                            : []
                    }
                    textAlign="text-left"
                    className="max-h-[38px] max-w-full sm:max-w-[250px] 2xl:w-32 sm:w-28 w-full border-0"
                    customClassName="showArrow"
                /> */}
            </div>
        </div>
    </div>
}
export default ExchangeRateForm;