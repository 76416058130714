import { redirectToLogin } from './annoucement-sheet';
import { api } from './api';
import authHeader from './auth-header';
import { Ids } from './media';
import { TaskListFilterResponse } from './tasklist';

type TaskListResponse = {
    project:ProjectInfo,
    progress_checker_details: TaskListData,
    module_id: number,
    status: boolean,
    total_time: string,
    // task_categories:TaskCategory[]
    // project_manager_lists:TeamLeadData[],
}
export type ProjectInfo={
    project_id:number;
    project_code:string;
    project_name:string;
    project_manager_code:string;
    project_manager_name:string;
    team_leader_code:string;
    team_leader_name:string;
}
export type TaskCategory = {
    id: number;
    name: string;
}
export type TeamLeadData = {
    id: number;
    code: string,
    name: string;
    year_w_vo: number;
    preferred_name:string;
    age: number;
}
export type CommonListData = {
    "project_id": number,
    "project_code": string,
    "project_name": string,
}
export type TaskListDataFormat = {
    "id": number,
    "code": string,
    "project_id": number,
    "project_code": string,
    "task_name": string,
    "task_category_name": string,
    "project_manager_name": string,
    "project_manager_code": string,
    "team_leader_name": string,
    "team_leader_code": string,
    "date": string,
    "time_spent": string,
    "time_spent_text": string,
}
export type TaskListData = {
    current_page: number;
    data: TaskListDataFormat[],
    total: number;
}
type taskListInput = {
    task_category_id: string,
    employee_ids:string,
    sort: string,
    search: string,
    page: number,
    limit: number,
    project_id?:string,
    pic?:string,
    start?:string,
    end?:string
}
export const taskDetailListApi = api.injectEndpoints({
    endpoints: (build) => ({
        getTaskDetailList: build.query<TaskListResponse, taskListInput>({
            query: ({ sort, search, task_category_id, limit, page,pic,project_id,start,end,employee_ids }) => ({ url: `progress-checker-lists/${project_id}/tasks?task_category_ids=${task_category_id}&sort=${sort}&search=${search}&limit=${limit}&page=${page}&start=${start}&end=${end}&employee_ids=${employee_ids}
            
            `,
             headers: authHeader() }),
             keepUnusedDataFor:0,
            transformErrorResponse: (err) => {
                if (err?.status === 401) {
                    redirectToLogin();
                }
            },
            providesTags: (result: any = []) => {
                if (result.data) {
                    if (result.data.length > 0) {
                        return [
                            ...result.data.map((id: any) => ({ type: "TaskDetailList", id } as const)),
                            { type: "TaskDetailList" as const, id: "LIST" },
                        ]
                    }
                    else {
                        return [{ type: "TaskDetailList" as const, id: "LIST" },]
                    }
                }
                else {
                    return [{ type: "TaskDetailList" as const, id: "LIST" },]
                }

            },
        }),
        getTaskDetailListFilterData: build.query<TaskListFilterResponse, string>({
            query: (id) => ({ url: `progress-checker-lists/${id}/tasks-filter-data`, headers: authHeader() }),
            transformErrorResponse: (err) => {
                if (err?.status === 401) {
                    redirectToLogin();
                }
            },
        }),
    }),
});

export const {
    useGetTaskDetailListQuery,
    useGetTaskDetailListFilterDataQuery
} = taskDetailListApi;


export const {
    endpoints: {
        getTaskDetailList,
        getTaskDetailListFilterData
    },
} = taskDetailListApi;