import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { PaginationState, SortingState } from "@tanstack/react-table";
// import CustomDatePicker from "../../common/BaseDatePicker";
import {
  NumberDropdownList,
  // StringDropdownList,
} from "../../common/DropdownList";
import { ILeaveStatus, baseObj, leaveStatuses } from "../../../utils/constants";
import Button from "../../common/Button";
import PersonalLeaveTable from "../MyLeave/PersonalLeaveTable";
// import { Modal, Fade } from "@material-ui/core";
import { Modal, Fade } from "@mui/material";

import LeaveApplication from "../MyLeave/LeaveApplication";
import PersonalLeaveBalanceTable from "../MyLeave/PersonalLeaveBalanceTable";
import { ColumnFiltersState } from "@tanstack/react-table";
import { IFilterDate } from "../Documents/Documents";
import Styles from "../MyLeave/MyLeave.module.scss";
import { CloseIcon, DocumentIcon, EditIcon } from "../../common/Icons";
import {
  useCreateLeaveDataMutation,
  useGetMyAuthLeaveAllMutation,
  useGetMyAuthLeaveBalanceQuery,
  useGetMyAuthLeaveQuery,
  useGetMyAuthLeaveTypesQuery,
} from "../../../app/services/profile";
import { renderDateFormatYMD } from "../../DashboardCalendarView/DashboardAddEventPopup";
import { useLocation } from "react-router-dom";
import classNames from "classnames";
import { getAuthUser } from "../../../app/services/dashboard";
import DatePopupFoFilter from "../../EmployeeTable/DatePopupFoFilter";
import CustomDropdownListForFilter from "../../EmployeeTable/CustomDropdownListForFilter";
import CustomScrollbar from "../../CustomScrollbar/CustomScrollbar";
import { useSpring, animated } from "react-spring";
import CommonTableSkeleton from "../../TableSkeleton/CommonTableSkeleton";
import LeaveAllowancePopup from "../../LeaveAllowancePopup/LeaveAllowancePopup";
import {
  ILeaveAllowanceType,
  useGetLeaveAllowanceQuery,
  useSaveLeaveAllowanceMutation,
} from "../../../app/services/leave-application";
import { ToastContainer, toast } from "react-toastify";
import { useViewNotificationByTypeMutation } from "../../../app/services/notification";
import { Tdocuments } from "../../../leave-application/views/LeaveApplication";
import LeaveApplicationFilesPopup from "../../LeaveApplicationFIlesPopup/LeaveApplicationFilesPopup";
import { useSelector } from "react-redux";
import MyClaimTable from "./MyClaimTable";
import CreateClaimPopup from "../../ClaimForm/CreateClaimPopup";
import { useGetEmployeeOTListQuery } from "../../../app/services/otlist";
import { useGetMyClaimsListQuery } from "../../../app/services/claims";
import { TStatusListData } from "../../../ot-list/views/OTList";
import UpdatedClaimPopup from "../../ClaimForm/UpdatedClaimPopup";
import ErrorPopup from "../../ErrorPopup/ErrorPopup";
import MyClaimUpdatedPopup from "../../ClaimForm/MyClaimUpdatedPopup";
import MyClaimCreatePopup from "../../ClaimForm/MyClaimCreatePopup";
import TablePaginator from "../../Tables/TablePaginator";

const statusListData: TStatusListData[] = [
  {
    id: "All",
    name: "All",
  },
  {
    id: 0,
    name: "Pending",
  },
  {
    id: 1,
    name: "Approved(Leader)",
  },

  {
    id: 2,
    name: "Approved",
  },
  {
    id: 3,
    name: "Completed",
  },
  {
    id: 4,
    name: "Cancelled",
  },
  {
    id: -1,
    name: "Rejected",
  },
];

const MyClaim: FC = () => {
  const [status, setStatus] = useState<ILeaveStatus>("");
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  const location = useLocation();
  //var id = location.state;
  const [state, setState] = useState(localStorage.getItem("state"));
  //var id = state ? Number(state) : 0;
  var currentUrl = window.location.pathname
    .split("/employeeprofile/")[1]
    ?.split("/")[0];
  var id = currentUrl ? parseInt(currentUrl) : 0;

  const [typeArr, setTypeArr] = useState<baseObj[]>([]);
  const { data: LeaveBalanceList } = useGetMyAuthLeaveBalanceQuery(id ? id : 0);
  const [LeaveBalanceAll] = useGetMyAuthLeaveAllMutation();
  const [skip, setSkip] = useState<boolean>(false);
  const [isLeaveApplicationOpen, setIsLeaveApplicationOpen] =
    useState<boolean>(false);
  const [isCreateOpen, setIsCreateOpen] = useState<boolean>(false);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 1,
    pageSize: 10,
  });
  const [errorPopup, setErrorPopup] = useState<boolean>(false);
  const [message, setMessage] = useState<any>([]);
  const [editOTData, setEditOTData] = useState<any>();

  const [currencyList, setCurrencyList] = useState<baseObj[]>([]);
  const [showLeaveFile, setShowLeaveFile] = useState<boolean>(false);
  const [leaveDocuments, setLeaveDocuments] = useState<Tdocuments[]>([]);
  const [dept, setDept] = useState<string>("");
  const [employeeData, setEmployeeData] = useState<any>("");
  const [uploading, setUploading] = useState<boolean>(false);
  const [empCode, setEmpCode] = useState<string>("");
  const [leaveAllowanceList, setLeaveAllowanceList] = useState<any>([]);
  const [viewLeave, setViewLeave] = useState<boolean>(false);
  const [createLeave, setCreateLeave] = useState<boolean>(false);
  const [manageAllLeaveApplication, setManageAllLeaveApplication] =
    useState<boolean>(false);
  const [manageLeaveApproval, setManageLeaveApproval] =
    useState<boolean>(false);
  const [cancelMyLeave, setCancelMyLeave] = useState<boolean>(false);
  const [showLeaveAllowance, setShowLeaveAllowance] = useState<boolean>(false);
  // const [hiddenFields, setLeftOverLeaveData] = useState<string[]>([]);
  const [data, setData] = useState<any>([]);
  const [itemCount, setItemCount] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [saveLeaveAllowance] = useSaveLeaveAllowanceMutation();
  const [viewLeaveByType] = useViewNotificationByTypeMutation();

  const [sorting, setSorting] = useState<SortingState>([]);
  const stateData: any = useSelector((state) => state);

  // const { data: employeeList } = useGetEmployeeOTListQuery(
  //   {
  //     search: "",
  //     code: empCode,
  //     department: dept,
  //   },
  //   {
  //     skip,
  //   }
  // );
  const { data: claimList, isLoading } = useGetMyClaimsListQuery(
    {
      page: currentPage,
      limit: pagination.pageSize,
      // search: globalSearch,
      status: status,
      start: startDate === undefined ? "" : renderDateFormatYMD(startDate, "-"),
      end: endDate === undefined ? "" : renderDateFormatYMD(endDate, "-"),
      sorting: sorting
        .map(
          (s) =>
            `${s.desc ? "-" : ""}${s.id == "applied_at" ? "claim_date " : s.id}`
        )
        .join(","),
    },
    {
      // skip: listSkip,
    }
  );

  var getUser = getAuthUser();
  const getAuthPermis: string[] = useMemo(() => [], []);

  if (getUser?.permissions) {
    const getPer = getUser?.permissions?.forEach((per: any) => {
      getAuthPermis.push(per?.name);
    });
  }

  useEffect(() => {
    localStorage.removeItem("state");
  }, []);

  useEffect(() => {
    if (location.state) {
      setState(location.state);
    }
  }, [location]);

  useEffect(() => {
    const viewLeave = getAuthPermis.filter(
      (f) => f == "View_Leave_Application"
    );
    const createLeave = getAuthPermis.filter((f) => f == "Create_My_Leave");
    const manageAllLeaveApplication = getAuthPermis.filter(
      (f) => f == "Manage_All_Leave_Application"
    );
    const manageLeaveApproval = getAuthPermis.filter(
      (f) => f == "Manage_Leave_Approval"
    );
    const cancelMyLeave = getAuthPermis.filter((f) => f == "Cancel_My_Leave");

    if (cancelMyLeave.length > 0) {
      setCancelMyLeave(true);
    }

    if (manageLeaveApproval.length > 0) {
      setManageLeaveApproval(true);
    }
    if (manageAllLeaveApplication.length > 0) {
      setManageAllLeaveApplication(true);
    }
    if (createLeave.length > 0) {
      setCreateLeave(true);
    }
    if (viewLeave.length > 0) {
      setViewLeave(true);
    }
  }, [getUser]);

  const fetchDataMemoized = useCallback(() => {
    if (claimList) {
      setCurrencyList(claimList?.currencyList);
      setTypeArr(claimList?.activeClaimTypeList);
      setData(claimList.claimFormList.data);
      setItemCount(claimList.claimFormList.total);
      //   setLocalModuleId(claimList.module_id);
      //   setClaimTypeData([
      //     { id: 0, name: "All", label: "All", status: 0, limit: "" },
      //     ...claimList.allClaimTypeList,
      //   ]);
    }
  }, [claimList]);

  useEffect(() => {
    fetchDataMemoized();
  }, [fetchDataMemoized]);

  // useEffect(() => {
  //   if (employeeList) {
  //     setAssignUserListData(employeeList?.data ?? []);
  //   }
  // }, [employeeList]);

  // useEffect(() => {
  //   if (Number(id) !== 0) {
  //     if (leaveAllowances?.data) {
  //       setLeaveAllowanceList(leaveAllowances?.data);
  //     }
  //   }
  // }, [leaveAllowances?.data]);

  var available = 0;
  if (LeaveBalanceList?.previousBalance?.available) {
    available = LeaveBalanceList?.previousBalance?.available;
  } else {
    available = 0;
  }

  const props = useSpring({
    to: { opacity: 1 },
    from: { opacity: 0 },
    delay: 500,
  });

  const saveLeaveAllowanceData: any = (
    leaveAllowance: ILeaveAllowanceType[]
  ) => {
    const data = {
      id: id,
      data: { data: leaveAllowance },
    };

    saveLeaveAllowance(data)
      .then((res: any) => {
        if (res.data.status) {
          LeaveBalanceAll({ id: id ? id : 0 });
          setShowLeaveAllowance(false);
          toast("Successfully Updated.", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            type: "success",
          });
        } else {
          setShowLeaveAllowance(true);
        }
      })
      .catch((error: any) => {});
  };

  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  const SaveOTListData = (data: any) => {};

  useEffect(() => {
    setTimeout(() => {
      var stateDataDetail = stateData?.dashboard?.notificationDetail;
      var updatedData = {
        profile_unread_noti_count: stateDataDetail?.profile_unread_noti_count,
        unapprove_claim_count: stateDataDetail?.unapprove_claim_count,
        unapprove_leave_count: stateDataDetail?.unapprove_leave_count,
        unapprove_ot_count: stateDataDetail?.unapprove_ot_count,
        unread_data_count: {
          unread_password_count:
            stateDataDetail?.unread_data_count?.unread_password_count,
          unread_document_count: 0,
          unread_leave_count:
            stateDataDetail?.unread_data_count?.unread_leave_count,
          unread_claim_count:
            stateDataDetail?.unread_data_count?.unread_claim_count,
        },
        unread_count: stateDataDetail?.unread_count,
        notifications: stateDataDetail?.notifications,
      };
      viewLeaveByType("claim_form");
    }, 5000);
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  useEffect(() => {
    setCurrentPage(1);
  }, [startDate, endDate, pagination.pageSize, status]);
  let bgTypeColors = ["#4FC9DA", "#FFA800", "#50CD89"];

  // console.log("d", data);
  
  return (
    <>
      {isLoading ? (
        <CommonTableSkeleton rows={12} isProfile={true} />
      ) : (
        <>
          {showLeaveFile && leaveDocuments.length && (
            <LeaveApplicationFilesPopup
              isOpen={showLeaveFile}
              setIsOpen={setShowLeaveFile}
              leaveDocuments={leaveDocuments}
            />
          )}

          <ErrorPopup
            descText="Error Message!"
            confirmBtnText="Yes, Close."
            show={errorPopup}
            setShow={setErrorPopup}
            message={message}
            setMessage={setMessage}
          />

          <LeaveAllowancePopup
            saveLeaveAllowanceData={saveLeaveAllowanceData}
            isOpen={showLeaveAllowance}
            setIsOpen={setShowLeaveAllowance}
            leaveAllowanceList={leaveAllowanceList}
          />
          <MyClaimCreatePopup
            saveData={SaveOTListData}
            setIsOpen={setIsCreateOpen}
            isOpen={isCreateOpen}
            //assignUserListData={assignUserListData}
            setEmployeeData={setEmployeeData}
            employeeData={employeeData}
            uploading={uploading}
            setUploading={setUploading}
            setSkip={setSkip}
            data={data}
            typeArr={typeArr}
            currencyList={currencyList}
            setData={setData}
            setItemCount={setItemCount}
            setErrorPopup={setErrorPopup}
            setMessage={setMessage}
          />

          <ToastContainer className={"text-12 font-poppins font-normal"} />
          {windowSize[0] < 640 ? (
            <div className="w-full bg-white rounded-xl flex flex-col p-5 gap-y-3">
              <div className="py-1 border-b border-offwhite flex items-center justify-between">
                <h3 className="text-[#3F4254] font-semibold text-[13px] sm:text-[15px] 2xl:text-[18px] leading-[22px] lg:leading-[27px]">
                  My Claim
                </h3>
                <Button
                  label={
                    <div className="flex items-center text-13 sm:text-10 leading-[21px] font-medium text-white whitespace-nowrap">
                      <DocumentIcon className="mr-2" />
                      Apply Claim
                    </div>
                  }
                  onClick={() => setIsLeaveApplicationOpen(true)}
                  type="button"
                  variant="primary"
                  customClass={
                    id
                      ? id != 0
                        ? "hidden"
                        : ""
                      : "" + " hover:bg-vorpblue hover:text-white px-2 py-1"
                  }
                  size="base"
                />
              </div>

              <div className="w-full">
                <p className="hidden mb-3 2xs:mb-0 text-13 leading-5 font-medium text-black2 mr-3">
                  DATE
                </p>
                {startDate && endDate && (
                  <button
                    type="button"
                    onClick={() => {
                      setStartDate(undefined);
                      setEndDate(undefined);
                    }}
                    className="text-12 mr-2"
                  >
                    <CloseIcon color="#EE436D" width={10} height={10} />
                  </button>
                )}

                <div className="flex">
                  <div className={`mr-3 w-full ${Styles.leaveDatePicker}`}>
                    <DatePopupFoFilter
                      classesProps="min-w-full"
                      placeholder="Start"
                      date={startDate}
                      setDate={setStartDate}
                    />
                  </div>
                  <div className={`mr-0 w-full ${Styles.leaveDatePicker}`}>
                    <DatePopupFoFilter
                      classesProps="min-w-full"
                      placeholder="end"
                      date={endDate}
                      setDate={setEndDate}
                    />
                  </div>
                </div>
              </div>

              <div className="flex">
                <p className="hidden mb-3 2xs:mb-0 text-13 leading-5 font-medium text-black2 mr-3">
                  STATUS
                </p>
                <CustomDropdownListForFilter
                  isFilter={true}
                  placeholder="Status"
                  state={status}
                  setState={setStatus}
                  dropdownItems={statusListData?.map((status: any) => ({
                    value: status.id,
                    label: status.name,
                  }))}
                  className="max-h-[38px] max-w-[135px]"
                />
              </div>

              <div className="max-h-[440px] min-h-[430px] overflow-y-auto">
                {data?.length
                  ? data.map((data: any, i: any) => {
                      let bgColors = "bg-graydark";
                      if (data.current_status == "Rejected") {
                        bgColors = "bg-redPink";
                      } else if (data.current_status == "Approved") {
                        bgColors = "bg-vorpgreen";
                      } else if (data.current_status === "Completed") {
                        bgColors = "bg-sky-500";
                      }
                      let colorsData = "text-graydark";
                      if (data.current_status == "Rejected") {
                        colorsData = "text-redPink";
                      } else if (data.current_status == "Approved") {
                        colorsData = "text-vorpgreen";
                      } else if (data.current_status === "Completed") {
                        colorsData = "text-sky-500";
                      }

                      return (
                        <div
                          key={i}
                          className="px-3 py-4 bg-[#F3F6F9] rounded-md gap-10 w-full mb-3"
                        >
                          <div className="w-full pb-3">
                            <p
                              style={{
                                backgroundColor: `${
                                  i > bgTypeColors.length
                                    ? bgTypeColors[0]
                                    : bgTypeColors[i]
                                }`,
                              }}
                              className={classNames(
                                "leading-loose inline py-1 px-2 rounded-2xl text-[9px] font-medium text-white text-start"
                              )}
                            >
                              {data.claim_type_name ?? "---"}
                            </p>
                          </div>

                          <hr color="blue" />
                          <div className="flex pt-3 w-full">
                            <p className="leading-loose text-10 w-1/2 font-normal text-graydark text-start">
                              Claim Date
                            </p>
                            <p className=" leading-loose text-black2 w-1/2 text-10 font-normal text-start">
                              {data.claim_date ?? "---"}
                            </p>
                          </div>
                          <div className="flex">
                            <p className="text-10 w-1/2 font-normal text-graydark text-start leading-loose">
                              Reason
                            </p>
                            <p className=" text-black2 w-1/2 text-10 font-normal text-start leading-loose">
                              {data.description ?? "---"}
                            </p>
                          </div>
                          <div className="flex">
                            <p className="text-10 w-1/2 font-normal text-graydark text-start leading-loose">
                              Amount
                            </p>
                            <p className=" text-black2 w-1/2 text-10 font-normal text-start leading-loose">
                              {data.amount_text ?? "---"}
                            </p>
                          </div>
                          <div className="flex w-full">
                            <p className="leading-loose text-10 w-1/2 font-normal text-graydark text-start">
                              Supportive Documents
                            </p>
                            <p
                              onClick={() => {
                                setLeaveDocuments(data.documents);
                                setShowLeaveFile(true);
                              }}
                              className={classNames(
                                !data.documents.length
                                  ? " pointer-events-none text-black2"
                                  : " cursor-pointer text-vorpblue",
                                " leading-loos w-1/2 text-10 font-normal text-start"
                              )}
                            >
                              Attached Files
                            </p>
                          </div>
                          <div className="flex">
                            <p className="text-10 w-1/2 font-normal text-graydark text-start leading-loose">
                              Status
                            </p>

                            <div className="flex w-1/2 items-center pb-5">
                              <p
                                className={classNames(
                                  `${bgColors}`,
                                  "p-[6px] h-2 rounded-full inline mr-2"
                                )}
                              ></p>
                              <p className={classNames(colorsData, "inline  text-10 font-normal text-start leading-loose")}>
                                {data.current_status ?? "---"}
                              </p>
                            </div>
                          </div>
                          <div className="flex pb-3">
                            <p className="text-10 w-1/2 font-normal text-black2 text-start leading-loose underline">
                              Cancel
                            </p>
                          </div>
                        </div>
                      );
                    })
                  : null}
              </div>
              <div className="pt-3">
                <TablePaginator
                  total={itemCount ?? 0}
                  pagination={pagination}
                  setPagination={setPagination}
                  itemCount={itemCount ?? 0}
                  setCurrentPage={setCurrentPage}
                  currentPageDefault={currentPage}
                />
              </div>
            </div>
          ) : (
            <div className="w-full bg-white rounded-xl text-left">
              <div className="px-2 sm:px-4 md:px-5 py-1 md:py-2 border-b border-offwhite flex items-center justify-between">
                <h3 className="text-[#3F4254] font-semibold text-[13px] sm:text-[15px] 2xl:text-[18px] leading-[22px] lg:leading-[27px]">
                  My Claim
                </h3>
                {Number(id) == 0 ? (
                  <Button
                    label={
                      <div className="flex items-center text-13 sm:text-10 leading-[21px] font-medium text-white whitespace-nowrap">
                        <DocumentIcon className="mr-2" />
                        Apply Claim
                      </div>
                    }
                    onClick={() => setIsCreateOpen(true)}
                    type="button"
                    variant="primary"
                    customClass={
                      id
                        ? id != 0
                          ? "hidden"
                          : ""
                        : "" + " hover:bg-vorpblue hover:text-white px-2 py-1"
                    }
                    size="base"
                  />
                ) : null}
              </div>
              <div className="px-2 sm:px-4 md:px-5 pt-3 pb-[10px] border-b border-offwhite">
                <div className="mb-1 lg:mb-1 xl:mb-1">
                  <div className="flex justify-between lg:flex-col-reverse xl:flex-row xl:items-center">
                    <div className="flex w-full custom-scrollbar ">
                      <CustomScrollbar isVertical={false} isHor={true}>
                        <div className="flex w-full items-center ">
                          <div className="flex">
                            <p className="hidden mb-3 2xs:mb-0 text-13 leading-5 font-medium text-black2 mr-3">
                              DATE
                            </p>
                            {startDate && endDate && (
                              <button
                                type="button"
                                onClick={() => {
                                  setStartDate(undefined);
                                  setEndDate(undefined);
                                }}
                                className="text-12 mr-2"
                              >
                                <CloseIcon
                                  color="#EE436D"
                                  width={10}
                                  height={10}
                                />
                              </button>
                            )}

                            <div className="flex">
                              <div className={`mr-3 ${Styles.leaveDatePicker}`}>
                                <DatePopupFoFilter
                                  placeholder="Start"
                                  date={startDate}
                                  setDate={setStartDate}
                                />
                              </div>
                              <div className={`mr-3 ${Styles.leaveDatePicker}`}>
                                <DatePopupFoFilter
                                  placeholder="end"
                                  date={endDate}
                                  setDate={setEndDate}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="flex mr-3">
                            <p className="hidden mb-3 2xs:mb-0 text-13 leading-5 font-medium text-black2 mr-3">
                              STATUS
                            </p>
                            <CustomDropdownListForFilter
                              isFilter={true}
                              placeholder="Status"
                              state={status}
                              setState={setStatus}
                              dropdownItems={statusListData?.map(
                                (status: any) => ({
                                  value: status.id,
                                  label: status.name,
                                })
                              )}
                              className="max-h-[38px] max-w-[135px]"
                            />
                          </div>
                        </div>
                      </CustomScrollbar>
                      {windowSize[0] > 450 ? (
                        <div className="flex justify-end mt-1 sm:w-auto w-full sm:flex-nowrap flex-wrap items-center">
                          <div className=" mb-1 flex items-center xl:ml-3 mr-[10px]">
                            <p className="base-label text-12 xl:text-14">
                              DISPLAY
                            </p>
                            <NumberDropdownList
                              state={pagination.pageSize}
                              setState={(value) =>
                                setPagination((prev) => ({
                                  ...prev,
                                  pageSize: value as number,
                                }))
                              }
                              dropdownItems={new Array(5)
                                .fill(1)
                                .map((_, index: number) => {
                                  let display = (index + 1) * 10;
                                  return {
                                    value: display,
                                    label: String(display),
                                  };
                                })}
                              className="max-w-[55px] 4xs:max-w-[60px] lg:max-w-[72px]"
                              textAlign="text-left pl-3"
                              paddingClass="py-[2px]"
                              placeholder="20"
                            />
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <MyClaimTable
                  columnFilters={columnFilters}
                  setColumnFilters={setColumnFilters}
                  data={data ? data : []}
                  setData={setData}
                  manageAllLeaveApplication={manageAllLeaveApplication}
                  hiddenFields={[]}
                  id={Number(id)}
                  pagination={pagination}
                  setPagination={setPagination}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  total={itemCount}
                  setShowLeaveFile={setShowLeaveFile}
                  setLeaveDocuments={setLeaveDocuments}
                  sorting={sorting}
                  setSorting={setSorting}
                  setEditOTData={setEditOTData}
                  setIsOpen={setIsLeaveApplicationOpen}
                />

                {editOTData && (
                  <MyClaimUpdatedPopup
                    setEditOTData={setEditOTData}
                    isMyprofile={true}
                    saveData={SaveOTListData}
                    setIsOpen={setIsLeaveApplicationOpen}
                    isOpen={isLeaveApplicationOpen}
                    setEmployeeData={setEmployeeData}
                    employeeData={employeeData}
                    uploading={uploading}
                    setUploading={setUploading}
                    setSkip={setSkip}
                    data={data}
                    typeArr={typeArr}
                    currencyList={currencyList}
                    setData={setData}
                    setItemCount={setItemCount}
                    setErrorPopup={setErrorPopup}
                    setMessage={setMessage}
                    editOTData={editOTData}
                  />
                )}
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default MyClaim;
