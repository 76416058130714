import EmailSentComponent from '../components/EmailSentComponent';

function App() {
  return (
    <>      
      <EmailSentComponent />
    </>
  );
}

export default App;
