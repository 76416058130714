import classNames from "classnames";
import DebounceSearchInput from "../../components/Tables/DebounceSearchInput";
import { useEffect, useState } from "react";
import { AddIcon, SearchIcon } from "../../components/common/Icons";
import CustomDropdownListForFilter from "../../components/EmployeeTable/CustomDropdownListForFilter";
import styles from './Notification.module.scss'
import { NumberDropdownList } from "../../components/common/DropdownList";
import { PaginationState } from "@tanstack/react-table";
import { useSpring, animated } from "react-spring";
import NotificationView from "../../components/NotificationView/NotificationView";
import { useGetAllNotificationsQuery, useViewNotificationMutation } from "../../app/services/notification";
import TablePaginator from "../../components/Tables/TablePaginator";
const notiTypeList =
    [{ label: "All", value: "all" },
    { label: "Leave Applications", value: 'leave' },
    { label: "Assigned Passwords", value: 'password' },
    { label: "Assigned Documents", value: 'document' },
    { label: "Announcements", value: 'announcement' }];
const notiStatusList = ["All", "Read", "Unread"];

const Notification = (props: any) => {
    const [globalSearch, setGlobalSearch] = useState<string>("");
    const [notiType, setNotiType] = useState<string>("");
    const [notiStatus, setNotiStatus] = useState<string>("");
    const [itemCount, setItemCount] = useState<number>(0);
    const [notificationList, setNotificationList] = useState<any>([]);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [pagination, setPagination] = useState<PaginationState>({
        pageIndex: 1,
        pageSize: 20,
    });
    const { data: notifications } = useGetAllNotificationsQuery({
        limit: pagination.pageSize,
        page: currentPage,
        search: globalSearch,
        type: notiType,
        status: notiStatus
    });
    const [viewNoti] = useViewNotificationMutation();
    useEffect(() => {
        var noti = notifications?.data;
        setNotificationList(noti);
        if (noti?.notifications?.data?.length) {
            setItemCount(noti?.notifications?.total)
        }

    }, [notifications])
    useEffect(()=>{
        setCurrentPage(1);
    },[globalSearch,notiType,notiStatus])
    return <div>
        <div
            className={classNames(
                "px-2 sm:px-4 py-4 bg-white sm:mt-[25px] mt-0 font-poppins text-13 font-semibold text-[#858795] tracking-[-0.03rem] w-full h-full rounded-xl pt-3 pb-8 sm:pt-3 sm:pb-9 "
            )}
        >
            {/* <div className="mb-5">
                <h1 className="font-bold text-24 text-[#464E5F] text-left">All Notifications ({notificationList?.unread_count})</h1>
            </div> */}
            <div className="flex flex-wrap justify-between items-center mb-5">
                <div className="flex flex-wrap">
                    <div className="flex items-center mr-2 xs:mr-4 sm:w-[260px] w-full my-1">
                        <DebounceSearchInput setState={setGlobalSearch} debounce={800}>
                            {(setState) => (
                                <div className="w-full font-normal flex border-1 border-[#C4C4C4] bg-white hover:bg-[#EFEFEF] rounded-[28px]">
                                    <input
                                        type="text"
                                        placeholder="Search"
                                        className="focus:outline-none py-1 px-4 text-12 xl:text-14 leading-5 bg-transparent rounded-[28px] w-full text-graydark"
                                        onChange={(e) => setState(e.target.value)}
                                    />
                                    <SearchIcon className="mr-2" />
                                </div>
                            )}
                        </DebounceSearchInput>
                    </div>
                    <div
                        className={classNames(
                            "flex items-center mr-[10px]  sm:w-auto w-full my-1"
                        )}
                    >
                        <p className="sm:w-auto w-1/2 text-left font-poppins font-medium xl:text-sm text-12 text-[#464E5F] mr-1">
                            Type
                        </p>
                        <CustomDropdownListForFilter
                            isFilter={true}
                            state={notiType}
                            placeholder="Type"
                            setState={setNotiType}
                            dropdownItems={
                                notiTypeList
                                    ? notiTypeList.map((st, id: number) => ({
                                        value: st.value,
                                        label: st.label,
                                    }))
                                    : []
                            }
                            textAlign="text-left"
                            className="max-h-[38px] max-w-full sm:max-w-[250px] 2xl:w-32 sm:w-28 w-full"
                        />
                    </div>
                    <div
                        className={classNames(
                            styles.permissionDropdown,
                            "flex items-center mr-[10px] sm:w-auto w-full my-1"
                        )}
                    >
                        <p className="sm:w-auto w-1/2 text-left font-poppins font-medium  xl:text-sm text-12 text-[#464E5F] mr-1">
                            Status
                        </p>
                        <CustomDropdownListForFilter
                            placeholder="Status"
                            state={notiStatus}
                            setState={setNotiStatus}
                            dropdownItems={
                                notiStatusList
                                    ? notiStatusList.map((dp) => ({
                                        value: dp,
                                        label: dp,
                                    }))
                                    : []
                            }
                            textAlign="text-left"
                            className="max-h-[38px] max-w-full sm:max-w-[250px] 2xl:w-32 sm:w-28 w-full"
                        />
                    </div>
                </div>
                <div className=" my-1 flex items-center xl:ml-3 mr-[10px]">
                    <p className="base-label text-12 xl:text-14">DISPLAY</p>
                    <NumberDropdownList
                        state={pagination.pageSize}
                        setState={(value) =>
                            setPagination((prev) => ({
                                ...prev,
                                pageSize: value as number,
                            }))
                        }
                        dropdownItems={new Array(5)
                            .fill(1)
                            .map((_, index: number) => {
                                let display = (index + 1) * 20;
                                return {
                                    value: display,
                                    label: String(display),
                                };
                            })}
                        className="max-w-[55px] 4xs:max-w-[60px] lg:max-w-[72px]"
                        textAlign="text-left pl-3"
                        paddingClass="py-[2px]"
                        placeholder="20"
                    />
                </div>
            </div>
            <animated.div style={props}>
                <div className="text-left">
                    <NotificationView notificationList={notificationList} viewNoti={viewNoti} />
                </div>
            </animated.div>
            <div className="pt-6">
                <TablePaginator
                    total={itemCount}
                    pagination={pagination}
                    setPagination={setPagination}
                    itemCount={itemCount}
                    setCurrentPage={setCurrentPage}
                    currentPageDefault={currentPage}
                />
            </div>
        </div>
    </div>
}
export default Notification;