import { FC } from "react";
import BasicLayout from "../layouts/BasicLayout";
import { Outlet } from "react-router-dom";
import CRMList from "./views/CRMList";

interface ICrmRoute {
  loginData: any;
  permissions?: any
}

export const crmRoutes = [{
  id: 1,
  path: "",
  component: <CRMList />
}
];

const CrmRoute: FC<ICrmRoute> = ({ loginData, permissions }) => {
  const data = loginData;

  return (
    <BasicLayout userData={data} permissions={permissions}>
      <div className="pt-0 pb-[58px]">
        <Outlet />
      </div>
    </BasicLayout>
  );
};

export default CrmRoute;
