import authHeader from "./auth-header";
import { api, endpointUrl } from "./api";
import { redirectToLogin } from "./annoucement-sheet";
import {
  TClaimType,
  TRequestClaimSave,
} from "../../claim-type/claim.modal";

export type PermissionResponse = {
  data: EmployeeList[];
};

export type EmployeeList = {
  id: number;
  name: string;
  icon: string;
  email: string;
};
export type createdBy = {
  name: string;
  createdAt: Date;
};
export type AssignData = {
  id: number;
  name: string;
  email: string;
  profileImage: string;
};

export type PasswordResponse = {
  module_id: number;
  passwords: Password;
  passwordUserList: PasswordUserList[];
  // show_password: boolean
};

export type PasswordResponseMutation = {
  data: DD;
};

export type DD = {
  data: Datas;
};

export type Datas = {
  module_id: number;
  passwords: Password;
  passwordUserList: PasswordUserList[];
  // show_password: boolean
};

export type Password = {
  data: Pwd[];
  total: number;
  limit?: number | string;
  page?: number | string;
  tag?: number | string;
  share_to?: number | string;
  search?: number | string;
  sort?: string;
  sortBy?: any;
};

export type Pwd = {
  id: number;
  platform: string;
  account: string;
  password: string;
  links: string;
  department: string;
  createdBy: createdBy;
  assignUsers: AssignData[];
};

export type PasswordData = {
  platform: string;
  account: string;
  password: string;
  links: string;
  department: string;
  createdBy: createdBy;
  assignUsers: AssignData[];
  tags?: number[];
  id?: number;
};
export type PasswordDataHR = {
  platform: string;
  company: string;
  account: string;
  password: string;
  links: string;
  tags: number[];
  user_ids?: number[];
};
export type DepartmentResponse = { departmentList: KeyValueLabel[] };

export type KeyValueLabel = {
  id: number;
  name: string;
  label?: string;
};

export type PasswordUserList = {
  id: number;
  name: string;
  email: string;
  profile_image: string;
  photo: string;
};
export type AssignDataList = {
  password_ids: number[];
  user_ids: number[];
};
export type AssignDataSingle = {
  password_ids: number;
  user_ids: number[];
};

export interface IOnePasswordResponse {
  platform: string;
  account: string;
  password: string;
  links: string;
  createdBy: createdBy;
  assignUsers: AssignData[];
  tags?: number[];
  id?: number;
}

export type ResponseOnePassword = {
  password: IOnePasswordResponse;
};
export interface IOnePasswordRequest {
  id: number;
}

export type ResponseKeyPassword = {
  publicKey: string;
};

export type ResponseIsPwdKey = {
  can_see: boolean;
};

export type RequestUserList = {
  search: string;
  department_id: number | string;
};
export interface assignUsers {
  id: number;
  name: string;
  email: string;
  profile_image: string;
  photo: string;
  label?: string | number;
}
export type ResponseUserList = {
  users: assignUsers[];
};

// -----------------------------------
export type claimObj = {
  claimFormList: ClaimType;
  module_id: number;
  allClaimTypeList: TClaimTypeData[];
  activeClaimTypeList: TactiveClaimTypeList[];
  currencyList: TCurrencyList[];
};
export type claimFilterObj = {
  allClaimTypeList: TClaimTypeData[];
  activeClaimTypeList: TactiveClaimTypeList[];
  currencyList: TCurrencyList[];
};

export type TCurrencyList = {
  id: number;
  currency_name: string;
  currency_code: string;
};

export type TactiveClaimTypeList = {
  id: number;
  name: string;
  status: number;
  limit: string;
};

export type TClaimTypeData = {
  id: number;
  name: string;
  status: number;
  limit: string;
  label?: string;
};

export type ClaimType = {
  data: TClaimResponse[];
  total: number;
};

export type TClaimResponse = {
  id: number;
  code: string;
  description: string;
  applied_at: string;
  employee_name: string;
  claim_type_name: string;
  currency_id: number;
  currency_name: string;
  currency_code: string;
  amount: number;
  amount_text: string;
  documents: TDocuments[];
  remark: null;
  current_status: string;
  approve_option: boolean;
  reject_option: boolean;
  complete_option: boolean;
  created_at: string;
  updated_at: string;
};

export type TDocuments = {
  id: number;
  name: string;
  hash: string;
  path: string;
  type: string;
  url: string;
};

export const claimsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getClaimsList: build.query<claimObj, Partial<TClaimType>>({
      query: ({ page, limit, status, search, type, start, end,sorting }) => ({
        url: `${endpointUrl}claim-forms?page=${page}&limit=${limit}&status=${status}&search=${search}&type=${type}&start=${start}&end=${end}&sort=${sorting}`,
        headers: authHeader(),
        method: "GET",
      }),
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
      providesTags: () => [{ type: "Claims" as const, id: "LIST" }],
    }),
    getClaimsFilterData: build.query<claimFilterObj, void>({
      query: () => ({
        url: `${endpointUrl}claim-forms-filter-data`,
        headers: authHeader(),
        method: "GET",
      }),
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
      providesTags: () => [{ type: "ClaimsFilter" as const, id: "LIST" }],
    }),
    getMyClaimsList: build.query<claimObj, Partial<TClaimType>>({
      keepUnusedDataFor:0,
      query: ({ page, limit, status, start, end,sorting }) => ({
        url: `${endpointUrl}profile/claim-forms?page=${page}&limit=${limit}&status=${status}&start=${start}&end=${end}&sort=${sorting}`,

        headers: authHeader(),
        method: "GET",
      }),
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
      providesTags: () => [{ type: "MyClaims" as const, id: "LIST" }],
    }),

    approveClaims: build.mutation<PasswordData, Partial<TRequestClaimSave>>({
      query: ({ id }) => ({
        url: `${endpointUrl}claim-forms/${id}/approve`,
        headers: authHeader(),
        method: "POST",
      }),
      invalidatesTags: [{ type: "Claims", id: "LIST" }],
    }),

    completeClaims: build.mutation<PasswordData, Partial<TRequestClaimSave>>({
      query: ({ id }) => ({
        url: `${endpointUrl}claim-forms/${id}/complete`,
        headers: authHeader(),
        method: "POST",
      }),
      invalidatesTags: [{ type: "Claims", id: "LIST" }],
    }),

    rejectClaims: build.mutation<PasswordData, Partial<TRequestClaimSave>>({
      query: ({ id }) => ({
        url: `${endpointUrl}claim-forms/${id}/reject`,
        headers: authHeader(),
        method: "POST",
      }),
      invalidatesTags: [{ type: "Claims", id: "LIST" }],
    }),

    updatedClaimType: build.mutation<PasswordData, Partial<TRequestClaimSave>>({
      query: (data) => ({
        url: `${endpointUrl}claim-types/${data.id}`,
        headers: authHeader(),
        method: "PUT",
        body: data,
      }),

      invalidatesTags: [{ type: "ClaimTypeList", id: "LIST" }],
    }),

    deleteClaimType: build.mutation<{ id: number }, number>({
      query: (id) => ({
        url: `${endpointUrl}claim-types/${id}`,
        method: "DELETE",
        headers: authHeader(),
      }),
      invalidatesTags: ["ClaimTypeList"],
    }),

    cancelMyClaim: build.mutation<{ id: number }, number>({
      query: (id) => ({
        url: `${endpointUrl}claim-forms/${id}/cancel`,
        method: "POST",
        headers: authHeader(),
      }),
      invalidatesTags: ["MyClaims"],
    }),

    deleteMyClaim: build.mutation<{ id: number }, number>({
      query: (id) => ({
        url: `${endpointUrl}claim-forms/${id}`,
        method: "DELETE",
        headers: authHeader(),
      }),
      invalidatesTags: ["MyClaims"],
    }),

    deleteAdminClaim: build.mutation<{ id: number }, number>({
      query: (id) => ({
        url: `${endpointUrl}claim-forms/${id}`,
        method: "DELETE",
        headers: authHeader(),
      }),
      invalidatesTags: ["Claims"],
    }),

    multiDeleteClaimType: build.mutation<
      { success: boolean; ids: number[] },
      number[]
    >({
      query: (ids) => ({
        url: `${endpointUrl}delete-claim-types`,
        headers: authHeader(),
        method: "DELETE",
        body: { ids },
      }),
      invalidatesTags: (Password) => [{ type: "ClaimTypeList" }],
    }),
  }),
});

export const {
  useGetClaimsListQuery,
  useGetMyClaimsListQuery,
  useApproveClaimsMutation,
  useCompleteClaimsMutation,
  useRejectClaimsMutation,
  useDeleteClaimTypeMutation,
  useDeleteMyClaimMutation,
  useDeleteAdminClaimMutation,
  useCancelMyClaimMutation,

  useMultiDeleteClaimTypeMutation,
  useUpdatedClaimTypeMutation,
  useGetClaimsFilterDataQuery
} = claimsApi;

export const {
  endpoints: {
    getClaimsList,
    getMyClaimsList,
    approveClaims,
    completeClaims,
    rejectClaims,
    multiDeleteClaimType,
    deleteClaimType,
    deleteMyClaim,
    deleteAdminClaim,
    cancelMyClaim,
    updatedClaimType,
    getClaimsFilterData
  },
} = claimsApi;
