import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import persistState from './persistState';
import { User } from '../app/services/users';

export type ContextState = { user: User | null };

const initialState: ContextState = { user: null };

const slice = createSlice({
  name: 'context',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<any>) => ({
      ...state,
      user: action.payload,
    }),
  },
});

export const { setUser } = slice.actions;

export default persistState('context', slice.reducer);
