import { useEffect, useMemo, useState } from "react";
import { CountryListData, currencyData, useGetEmployeeIncentiveFilterDataQuery, useGetEmployeeIncentiveQuery } from "../../app/services/employee-incentive";
import { baseObj } from "../../utils/constants";
import { ColumnFiltersState, PaginationState, SortingState } from "@tanstack/react-table";
import { useSpring, animated } from "react-spring";
import CommonTableSkeleton from "../../components/TableSkeleton/CommonTableSkeleton";
import classNames from "classnames";
import DebounceSearchInput from "../../components/Tables/DebounceSearchInput";
import {
    AddIcon,
    CustomizeLayoutIcon,
    ExportIcon,
    ImportIcon,
    SearchIcon,
} from "../../components/common/Icons";
import CustomScrollbar from "../../components/CustomScrollbar/CustomScrollbar";
import CustomDropdownListForFilter from "../../components/EmployeeTable/CustomDropdownListForFilter";
import { NumberDropdownList } from "../../components/common/DropdownList";
import { StringDropdownListWithDeleteBtn } from "../../components/common/StringDropdownListWithDeleteBtn";
import { useGetByModuleTemplateQuery } from "../../app/services/template";
import { templateList } from "../../active-time-report/views/ActiveTimeReport";
import EmployeeIncentiveTable from "../../components/EmployeeIncentive/EmployeeIncentiveTable";
import Button from "../../components/common/Button";
import ExportUsersModal from "../../components/BackendUsers/ExportUsersTable";
import { endpointUrl } from "../../app/services/api";
import axios from "../../axios";
import { getAuthUser } from "../../app/services/dashboard";

const EmployeeIncentive = () => {
    const props = useSpring({
        to: { opacity: 1 },
        from: { opacity: 0 },
        delay: 1000,
    });
    const defaultFields = useMemo(
        () => [
            "employee_code",
            "employee_name",
            "currency_id",
            "currency_name",
            "currency_code",
            "internet_incentive",
            "no_absent_incentive",
            "no_lateness_incentive",
            "work_quality_rewards_tier_1",
            "work_quality_rewards_tier_2",
            "other_rewards",
            "allowance",
            // "description",
            // "status",
            // "created_by_name",
            // "updated_at"
        ],
        []
    );

    const [currency, setCurrency] = useState<number>();
    const [country, setCountry] = useState<number>();
    const [sorting, setSorting] = useState<SortingState>([]);
    const [data, setData] = useState<baseObj[]>([]);
    const [currentView, setCurrentView] = useState<any>(0);
    const [currentViewData, setCurrentViewData] = useState<number>(0);
    const [isTemplate, setIsTemplate] = useState<boolean>(false);
    const [viewCols, setCols] = useState<any>([]);
    const [globalSearch, setGlobalSearch] = useState<string>("");
    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
    const [successPopup, setSuccessPopup] = useState<boolean>(false);
    const [errorPopup, setErrorPopup] = useState<boolean>(false);
    const [message, setMessage] = useState<any>([]);
    const [tempList, setTempList] = useState<any>([]);
    const [moduleList, setModuleList] = useState<any>([]);
    let [localModuleId, setLocalModuleId] = useState<number>(0);
    const [hiddenFields, setHiddenFields] = useState<string[]>([]);
    const { data: templateData } = useGetByModuleTemplateQuery(localModuleId);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [currencyList, setCurrencyList] = useState<currencyData[]>([]);
    const [countryList, setCountryList] = useState<CountryListData[]>([]);
    const [isLayoutControlOpen, setIsLayoutControlOpen] =
        useState<boolean>(false);
    const { data: employeeIncentive, isLoading } = useGetEmployeeIncentiveQuery({
        search: globalSearch,
        currencyId: currency ? currency.toString() : 'all',
        countryId: country ? country.toString() : 'all',
        sorting: sorting.map((s) => `${s.desc ? '-' : ''}${s.id}`).join(',')
    });
    const {data:employeeIncentiveFilterData}=useGetEmployeeIncentiveFilterDataQuery();
    const [itemCount, setItemCount] = useState<number>(data?.length);
    const [pagination, setPagination] = useState<PaginationState>({
        pageIndex: 1,
        pageSize: 20,
    });
    const [isExportModalOpen, setIsExportModalOpen] = useState<boolean>(false);
    const [isExporting, setIsExporting] = useState<boolean>(false);
    var getUser = getAuthUser();
    useEffect(() => {
        if (employeeIncentive) {
            setData(employeeIncentive.employee_incentives);           
            setLocalModuleId(employeeIncentive.module_id);           
            setItemCount(employeeIncentive.employee_incentives.length)
        }

    }, [employeeIncentive]);
    useEffect(()=>{
        if(employeeIncentiveFilterData){
            setCurrencyList(employeeIncentiveFilterData.currencyList);
            setCountryList(employeeIncentiveFilterData.countryList);
        }
    },[employeeIncentiveFilterData])
    useEffect(() => {
        if (templateData) {
            var updatedTemplate = templateData?.templates.map((x: any, i: number) => {
                return { ...x, index: i + 1 };
            });
            var defaultTemplate = {
                id: -1,
                index: 0,
                module: 0,
                name: "All",
            };
            setModuleList([defaultTemplate, ...updatedTemplate]);
        }
    }, [templateData?.templates, templateData]);
    useEffect(() => {
        var viewData = templateData?.templates.find(
            (x) => x.id.toString() === currentView.toString()
        );

        setHiddenFields(viewData ? [...viewData.viewColumns] : defaultFields);
    }, [currentView, defaultFields]);

    useEffect(() => {
        if (viewCols?.view_columns?.length > 0) {
            setHiddenFields(viewCols?.view_columns);
        }
    }, [viewCols?.view_columns]);

    useEffect(() => {
        if (moduleList) {
            var lastesTemplate = moduleList[moduleList?.length - 1];
            setCurrentViewData(lastesTemplate ? Number(lastesTemplate?.id) : 0);
        }
    }, [moduleList]);
    useEffect(() => {
        setCurrentPage(1);
    }, [globalSearch, country, currency])
    const deleteTemplateData = (id: string) => {
        var filterTempList = templateList?.filter((x) => x.id !== parseInt(id));
        setTempList(filterTempList ? filterTempList : []);
    };
    const exportEmployeeIncentive = (format: string) => {
        axios({
            url:
                endpointUrl +
                `employee-incentives?curreny_id=${currency ? currency : 'all'}&country_id=${country ? country : 'all'}&export=true&format=` +
                format +
                "",
            method: "GET",
            responseType: "blob",
            headers: {
                Authorization: `Bearer ${getUser.token}`,
            },
        })
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute(
                    "download",
                    "incentive." + (format === "excel" ? "xlsx" : format)
                );
                document.body.appendChild(link);
                link.click();
                setIsExporting(false);
                setIsExportModalOpen(false);
            })
            .catch((error: any) => {
                setErrorPopup(true);
                setMessage(
                    error?.message
                        ? [error?.message]
                        : ["Something wrong!. Trying again."]
                );
            });
    };
    return <>
        {isLoading ? (
            <CommonTableSkeleton rows={12} />
        ) : (
            <>
                <animated.div style={props}>
                    <ExportUsersModal
                        isOpen={isExportModalOpen}
                        setIsOpen={setIsExportModalOpen}
                        exportToCSV={exportEmployeeIncentive}
                        exportToExcel={exportEmployeeIncentive}
                        exportToPDF={exportEmployeeIncentive}
                        exportToZip={exportEmployeeIncentive}
                        isExporting={isExporting}
                        setIsExporting={setIsExporting}
                    />
                    <div
                        className={classNames(
                            "px-2 sm:px-4 py-4 bg-white sm:mt-[25px] mt-0 font-poppins text-13 font-semibold text-[#858795] tracking-[-0.03rem] w-full h-full rounded-xl pt-3 pb-8 sm:pt-3 sm:pb-9 "
                        )}
                    >

                        <div className="flex flex-wrap justify-between items-center">
                            <div className="flex items-center mr-2 xs:mr-4 sm:w-[260px] w-[225px] md:mb-1">
                                <DebounceSearchInput setState={setGlobalSearch} debounce={800}>
                                    {(setState) => (
                                        <div className="font-normal flex border-1 border-[#C4C4C4] bg-white hover:bg-[#EFEFEF] rounded-[28px]">
                                            <input
                                                type="text"
                                                placeholder="Search"
                                                className="focus:outline-none py-1 px-4 text-12 xl:text-14 leading-5 bg-transparent rounded-[28px] w-full text-graydark"
                                                onChange={(e) => setState(e.target.value)}
                                            />
                                            <SearchIcon className="mr-2" />
                                        </div>
                                    )}
                                </DebounceSearchInput>
                            </div>
                            <Button
                                label={
                                    <span className="flex items-center">
                                        <ExportIcon className="mr-[5px]" /> Export
                                    </span>
                                }
                                onClick={() => {
                                    setIsExportModalOpen(true);
                                    setIsExporting(false);
                                }}
                                type="button"
                                variant="no-outline"
                                customClass="my-1 bg-vorpmyanmar border-vorpmyanmar hover:bg-vorpblue hover:text-white transition-all duration-300 text-vorpblue xs:text-[12px] leading-[18px] font-medium py-1 px-[6px] sm:mr-[10px] mr-[5px]"
                                size="base"
                            />
                        </div>

                        <div className="flex sm:flex-row flex-col sm:justify-between justify-end items-center">
                            <div className="flex w-full mt-1 md:mb-1 sm:mr-5 custom-scrollbar">
                                <CustomScrollbar isVertical={false} isHor={true}>
                                    <div className="flex w-full items-center">
                                        <div
                                            className={classNames(
                                                "flex items-center mr-[10px]  sm:w-auto w-full"
                                            )}
                                        >
                                            <p className="hidden sm:w-auto w-1/2 text-left font-poppins font-medium xl:text-sm text-12 text-[#464E5F] mr-1">
                                                Currency
                                            </p>
                                            <CustomDropdownListForFilter
                                                isFilter={true}
                                                state={currency}
                                                placeholder="Currency"
                                                setState={setCurrency}
                                                dropdownItems={
                                                    currencyList
                                                        ? [{ name: "All", id: 0, currency_name: "All" }, ...currencyList].map((st, id: number) => ({
                                                            value: st.id,
                                                            label: st.currency_name,
                                                        }))
                                                        : []
                                                }
                                                textAlign="text-left"
                                                className="max-h-[38px] max-w-full sm:max-w-[250px] 2xl:w-32 sm:w-28 w-full"
                                            />
                                        </div>
                                        <div
                                            className={classNames(
                                                "flex items-center mr-[10px]  sm:w-auto w-full"
                                            )}
                                        >
                                            <p className="hidden sm:w-auto w-1/2 text-left font-poppins font-medium xl:text-sm text-12 text-[#464E5F] mr-1">
                                                Country
                                            </p>
                                            <CustomDropdownListForFilter
                                                isFilter={true}
                                                state={country}
                                                placeholder="Country"
                                                setState={setCountry}
                                                dropdownItems={
                                                    countryList
                                                        ? [{ name: "All", id: 0, currency_name: "All" }, ...countryList].map((st, id: number) => ({
                                                            value: st.id,
                                                            label: st.name,
                                                        }))
                                                        : []
                                                }
                                                textAlign="text-left"
                                                className="max-h-[38px] max-w-full sm:max-w-[250px] 2xl:w-32 sm:w-28 w-full"
                                            />
                                        </div>
                                    </div>
                                </CustomScrollbar>
                            </div>
                            <div className="flex justify-end mt-1 sm:w-auto w-full sm:flex-nowrap flex-wrap items-center">
                                <div className=" mb-1 flex items-center xl:ml-3 mr-[10px]">
                                    <p className="base-label text-12 xl:text-14">DISPLAY</p>
                                    <NumberDropdownList
                                        state={pagination.pageSize}
                                        setState={(value) =>
                                            setPagination((prev) => ({
                                                ...prev,
                                                pageSize: value as number,
                                            }))
                                        }
                                        dropdownItems={new Array(5)
                                            .fill(1)
                                            .map((_, index: number) => {
                                                let display = (index + 1) * 20;
                                                return {
                                                    value: display,
                                                    label: String(display),
                                                };
                                            })}
                                        className="max-w-[55px] 4xs:max-w-[60px] lg:max-w-[72px]"
                                        textAlign="text-left pl-3"
                                        paddingClass="py-[2px]"
                                        placeholder="20"
                                    />
                                </div>
                                <div className="mb-1 flex justify-end">
                                    <StringDropdownListWithDeleteBtn
                                        haveDeleteBtn={true}
                                        setIsTemplate={setIsTemplate}
                                        state={isTemplate ? currentViewData : currentView}
                                        setState={setCurrentView}
                                        deleteTemplate={deleteTemplateData}
                                        dropdownItems={moduleList.map((x: any, index: number) => {
                                            return {
                                                label: x.id === -1 ? x.name : "View #" + x.index,
                                                value: x.id,
                                            };
                                        })}
                                        className="min-w-[80px] 2xl:min-w-[128px] 6xs:text-12 2xl:w-32 w-24"
                                        placeholder="View#1"
                                        textAlign="text-left py-1 pl-2 pr-2"
                                        custPadding="py-[2px]"
                                        setSuccessPopup={setSuccessPopup}
                                        setErrorPopup={setErrorPopup}
                                        setMessage={setMessage}
                                    />
                                </div>

                                <button
                                    type="button"
                                    onClick={() => setIsLayoutControlOpen(true)}
                                    className="flex justify-center relative items-center rounded-lg bg-vorphongkongborder hover:bg-[#FFF0D2] w-[29px] h-[29px] ml-2 group transition-all duration-300 "
                                >
                                    <CustomizeLayoutIcon />
                                    <div className="absolute left-7 top-[-5px] border border-white w-4 h-4 rounded-full bg-vorphongkongborder flex justify-center items-center">
                                        <AddIcon width={8} height={8} color="white" />
                                    </div>
                                </button>
                            </div>

                        </div>
                        <div className="text-left md:mt-0 mt-2">
                            <animated.div style={props}>
                                <EmployeeIncentiveTable
                                    setCurrentView={setCurrentView}
                                    data={data}
                                    setData={setData}
                                    pagination={pagination}
                                    setPagination={setPagination}
                                    isLayoutControlOpen={isLayoutControlOpen}
                                    setIsLayoutControlOpen={setIsLayoutControlOpen}
                                    globalSearch={globalSearch}
                                    setGlobalSearch={setGlobalSearch}
                                    columnFilters={columnFilters}
                                    setColumnFilters={setColumnFilters}
                                    orgDataList={[]}
                                    hiddenFields={hiddenFields ? hiddenFields : []}
                                    setHiddenFields={setHiddenFields}
                                    templateList={tempList}
                                    localModuleId={localModuleId}
                                    total={itemCount}
                                    // changePage={changePage}
                                    setCurrentPage={setCurrentPage}
                                    currentPage={currentPage}
                                    setIsTemplate={setIsTemplate}
                                    setCols={setCols}
                                    sorting={sorting}
                                    setSorting={setSorting}
                                    currencyList={currencyList?.map((cur) => {
                                        return {
                                            id: cur.id,
                                            name: cur.currency_name,
                                            label: cur.currency_code
                                        }
                                    })}
                                    setCurrencyList={setCurrencyList}

                                />
                            </animated.div></div>

                    </div>
                </animated.div>
            </>
        )}
    </>
}
export default EmployeeIncentive;