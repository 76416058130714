import {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useRef,
  useState,
  forwardRef,
} from "react";
import { Popper } from "@mui/material";
import { useSpring, animated } from "react-spring";
import classNames from "classnames";
import styles from "./MultiDatePopupFilter.module.scss";
import ReactDatePicker from "react-datepicker";
import {
  renderDateFormatDMY,
  renderDateFormatYMD,
} from "../DashboardCalendarView/DashboardAddEventPopup";
import { CalendarIcon, CloseIcon, DropdownICon } from "../common/Icons";
import { twMerge } from "tailwind-merge";
import { start } from "repl";
import { addDays } from "@fullcalendar/core/internal";

interface IDate {
  clearDate?: () => void;
  placeholder: string;
  includeDates?: Date[];
  startDate: any;
  endDate: any;
  setDateRange: React.Dispatch<React.SetStateAction<any[]>>;
  isAttendance?: boolean;
  fontSize?: string;
  color?: string;
  padding?: string;
  borderRadius?: string;
}

interface FadeProps {
  children?: React.ReactElement;
  in?: boolean;
  onEnter?: () => void;
  onExited?: () => void;
}

const Fade = forwardRef<HTMLDivElement, FadeProps>(function Fade(
  props: any,
  ref: any
) {
  const { in: open, children, onEnter, onExited, ...other } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter();
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited();
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {children}
    </animated.div>
  );
});

const MultiDatePopupFilter: FC<IDate> = ({
  startDate,
  endDate,
  setDateRange,
  placeholder,
  isAttendance,
  fontSize,
  color,
  padding,
  borderRadius,
}) => {
  var buttonRef = useRef<HTMLButtonElement>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(
    buttonRef?.current
  );
  const [open, setOpen] = useState(true);
  const [placement, setPlacement] = useState<any>();
  // const [selectedDate, setSelectedDate] = useState(date);

  var dateRef = useRef<ReactDatePicker>(null);
  const handleClick = (newPlacement: any, event: any) => {
    setOpen(true);
    setAnchorEl(event.currentTarget);
    setPlacement(newPlacement);
    dateRef.current?.setOpen(true);
  };

  const canBeOpen = open && Boolean(anchorEl);
  const id = canBeOpen ? "spring-popper" : undefined;

  useEffect(() => {
    setOpen(true);
  }, [open]);
  useEffect(() => {
    setAnchorEl(buttonRef.current);
  }, [buttonRef]);

  return (
    <>
      <div
        className={classNames(
          fontSize ? fontSize : "text-[13px] sm:text-14 ",
          padding ? padding : "py-3 ",
          borderRadius ? borderRadius : " rounded-4",
          isAttendance ? "min-w-[132px]" : "min-w-[180px]",
          "rounded-[28px] flex justify-normal border border-[#CCDAE4] w-fit  max-w-full text-[13px] sm:text-14 leading-5 text-graydark"
        )}
      >
        <div
          className={classNames(
            isAttendance
              ? "min-w-[40px] md:min-w-[60px]"
              : "min-w-[100px] md:min-w-[110px]",
            "flex items-center "
          )}
        >
          <span
            onClick={() => {
              setDateRange([null, null]);
            }}
            className={classNames(
              isAttendance ? "" : "cursor-pointer",
              "pl-[8px] pr-1 font-normal text-graydark xl:text-sm text-12 "
            )}
          >
            {isAttendance ? "Date" : "Date: Today"}
          </span>
          <DropdownICon />
        </div>
        <button
          ref={buttonRef}
          onClick={(event) => {
            handleClick("left-start", event);
          }}
        >
          <span
            className={twMerge(
              startDate ? "pr-[5px]" : "pr-[8px]",
              fontSize ? fontSize : "xl:text-sm text-12 ",
              color ? color : "text-graydark ",
              "font-normal  w-auto h-full  py-[2px] text-center text-graydark xl:text-sm text-12 whitespace-nowrap"
            )}
          >
            {startDate || endDate
              ? `${renderDateFormatDMY(new Date(startDate), "/")}  -  ${
                  endDate ? renderDateFormatDMY(new Date(endDate), "/") : ""
                }`
              : placeholder}
          </span>
          {startDate || endDate ? (
            <button
              type="button"
              onClick={() => {
                setDateRange([null, null]);
                setOpen(false);
              }}
              className="text-12 pr-3"
            >
              <CloseIcon color="#EE436D" width={10} height={10} />
            </button>
          ) : null}
          {/* <CalendarIcon /> */}
        </button>
      </div>
      {anchorEl && (
        <Popper
          id={id}
          open={open}
          anchorEl={anchorEl}
          transition
          placement={placement}
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps}>
              <ReactDatePicker
                excludeDates={[addDays(new Date(), 0), addDays(new Date(), 0)]}
                // calendarClassName="relative"
                // popperClassName="relative"
                ref={dateRef}
                className="w-fit"
                dateFormat="dd/MM/yyyy"
                placeholderText="Start - End"
                selectsRange={true}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                onChange={(update: any) => {
                  setDateRange(update);
                }}
                isClearable={true}
                dayClassName={(date) =>
                  1 < Math.random() * 31 ? "random" : ""
                }
                clearButtonClassName="text-red-500 bg-[#fff]"
              />
            </Fade>
          )}
        </Popper>
      )}
    </>
  );
};

export default MultiDatePopupFilter;
