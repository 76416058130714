// import { Modal } from "@material-ui/core";
import { Modal } from "@mui/material"
import classNames from "classnames";
import React, {
  // createRef,
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { CloseIcon, SearchIcon } from "../common/Icons";
import styles from "./SharePopup.module.scss";
interface ICreateData {
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  sendEmailData?: any;
  shareID?: number;
  setIsSending?:Dispatch<SetStateAction<boolean>>;
  isSending?:boolean;
}
const SharePopup: FC<ICreateData> = ({ show, setShow, sendEmailData, shareID,setIsSending,isSending }) => {
  var subject = useRef<any>(null);
  var description = useRef<any>(null);
  const [error, setError] = useState<string>("");

  const checkValidate = (data: any) => {
    var messag = "";
    if (data.subject == "" || data.subject == undefined) {
      messag = "The subject field is required";
      setError("The subject field is required");
    } else if (data.body == "" || data.body == undefined) {
      messag = "The body field is required";
      setError("The body field is required");
    } else {
      messag = "";
      setError("");
    }
    return messag;
  }
  useEffect(()=>{
    if(setIsSending){
      setIsSending(false);
    }
  },[show])
  const handleConfirm = () => {
    var data = {
      id: shareID,
      subject: subject.current.value,
      body: description.current.value
    };
    var msg = checkValidate(data);
    if (msg !== "") {
      return;
    } else {
      if(setIsSending){
        setIsSending(true);
      }
      sendEmailData(data);
    }

    //setShow(false);
    //clearData();
  }
  const clearData = () => {
    subject.current = "";
    description.current = "";
  }
  return (
    <div>
      <Modal open={show} onClose={() => setShow(false)}  disableEnforceFocus={true}>
        <div>
          <div className={classNames("z-10 fixed top-0 left-0 w-full h-full")}>
            <div
              className={classNames(
                "relative z-10 top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 md:max-w-[530px] 2xs:max-w-[400px] 5xs:max-w-[340px] max-w-[300px] bg-[#fff] rounded-xl"
              )}
            >
              <div className="flex justify-between items-center bg-darkblue px-10 py-6 rounded-tl-xl rounded-tr-xl">
                <p className="text-left font-poppins font-semibold text-lg text-[#fff]">
                  Send
                </p>
                <span
                  onClick={() => setShow(false)}
                  className="text-[#B5B5C3] text-20 cursor-pointer"
                >
                  <CloseIcon />
                </span>
              </div>
              <div className="px-10 pt-9 pb-10">
                <div>
                  <p
                    className={classNames(
                      "border-b-2 border-b-[#F5F5F5] pb-2 font-poppins font-medium md:text-15 text-xs text-darkblack"
                    )}
                  >
                    Subject
                  </p>
                  <div>
                    <input ref={subject}
                      placeholder="Subject"
                      className="focus:outline-none rounded-4 w-full font-poppins font-normal md:text-sm text-xs py-3 px-3 text-darkblack placeholder-vorpgraylight border border-vorpgraylight"
                    />
                  </div>
                </div>
                <div className="mt-5">
                  <p
                    className={classNames(
                      "border-b-2 border-b-[#F5F5F5] pb-2 font-poppins font-medium md:text-15 text-xs text-darkblack"
                    )}
                  >
                    Description
                  </p>
                  <div>
                    <textarea ref={description} rows={5} placeholder="Description"
                      className="resize-none focus:outline-none rounded-4 w-full font-poppins font-normal md:text-sm text-xs py-3 px-3  text-darkblack placeholder-vorpgraylight border border-vorpgraylight"
                    ></textarea>
                  </div>
                </div>
                <div className="flex justify-start pb-5 mt-5">
                  <button onClick={() => handleConfirm()}
                    className={classNames(
                      styles.customConfirmBtn,
                      "flex items-center px-3 py-2 rounded-[6px] font-poppins font-semibold lg:text-sm text-xs text-[#fff] bg-[#009EF7] hover:text-vorpblue hover:bg-vorpmyanmar mr-2",
                      isSending==true?"opacit-50 pointer-events-none":""
                    )}
                  >
                    <div className="mr-[5px]">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <path
                          d="M2.5 13.3333V11.6667H9.16667V13.3333H2.5ZM2.5 10V8.33333H12.5V10H2.5ZM2.5 6.66667V5H12.5V6.66667H2.5ZM13.625 15.8333L10.6667 12.875L11.8333 11.7083L13.625 13.4583L17.1667 9.91667L18.3333 11.125L13.625 15.8333Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                    {isSending?"Sending":"Confirm"}
                  </button>
                  <button
                    onClick={() => { setShow(false); clearData(); }}
                    className={classNames(
                      styles.cancelBtn,
                      "flex items-center px-4 py-2 rounded-[6px] font-poppins font-semibold lg:text-sm text-xs text-black2 bg-offwhite hover:bg-anncolor",
                      isSending==true?"opacit-50 pointer-events-none":""
                    )}
                  >
                    <CloseIcon />
                    Cancel
                  </button>
                </div>
                <div className={classNames("mt-4", error == "" ? "hidden" : '')}>
                  <span className="text-base text-red-500 font-semibold">{error}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};
export default SharePopup;
