import { Modal } from "@mui/material";

import classNames from "classnames";
import {
  ChangeEvent,
  createRef,
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useRef,
  useState,
} from "react";
interface ICreateData {
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  addNewItem?: any;
  closePopup?: any;
  title?: string;
  buttonConfirm?: string;
  isFile?: boolean;
  isUploading?: boolean;
}

const CreateNewMediaPopup: FC<ICreateData> = ({
  show,
  setShow,
  addNewItem,
  closePopup,
  title,
  buttonConfirm,
  isFile,
  isUploading,
}) => {
  var name = useRef<any>(null);
  var code = useRef<any>(null);
  const fileRef = createRef<HTMLInputElement>();
  const [fileName, setFileName] = useState<string>("");
  const [errorMsg, setErrorMsg] = useState<string>("");

  const displayFilename = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const file = e.target?.files?.[0];
      if (file?.name) {
        setFileName(file.name);
      } else {
        setFileName("");
      }
      validateSelectedFile(file);
    },
    [setFileName]
  );

  const validateSelectedFile = (files: File | undefined) => {
    const MAX_FILE_SIZE = 100 * 1024 * 1024; // 100MB
    if (!files) {
      setErrorMsg("Please choose a file");
      return;
    }
    const fileSizeKiloBytes = files.size;

    if (fileSizeKiloBytes > MAX_FILE_SIZE) {
      setErrorMsg("File size is greater than maximum limit (100MB)");
      return;
    }

    setErrorMsg("");
  };

  async function importData() {
    const file = fileRef.current?.files?.[0];
    addNewItem(file);
  }

  return (
    <Modal
      open={show}
      onClose={(event, reason) => {
        if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
          setShow(false);
        }
      }}  disableEnforceFocus={true}
    >
      <div>
        <div
          className={classNames(
            isUploading ? "pointer-events-none" : null,
            "z-10 fixed top-0 left-0 w-full h-full"
          )}
        >
          <div
            className={classNames( isUploading? ' max-w-[400px]' : '',
              "relative z-10 top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 max-w-[350px] bg-[#fff] px-5 rounded-lg"
            )}
          >
            <div className="flex justify-between items-center pb-[18px]">
              <p className={classNames( isUploading ? ' text-orange-500' : '', "pt-5 text-left font-poppins font-medium text-sm text-[#3F4254]")}>
                {isUploading ? "Wait a moment. Don't reload or close the page" : title}
              </p>
              <span
                onClick={() => (closePopup ? closePopup() : setShow(false))}
                className="text-[#B5B5C3] text-20 pt-4 cursor-pointer"
              >
                &times;
              </span>
            </div>
            {isFile ? (
              <>
                <div className="flex items-center text-black2 text-12 border-[#C2C2C2]">
                  {/* <form method="post" enctype="multipart/form-data"> */}
                  <input
                    ref={fileRef}
                    type="file"
                    className="rounded hidden"
                    onChange={displayFilename}
                    accept=".pdf,.doc,.docx,.ppt,.pptx,.txt,.csv,.xls,.xlsx,.xml,.json,.md,.html,.csr,.cer,.exe,.apk,.app,.ini,.ppl,.zip,.rar,.tar,.tar,.gz,.gzip,.jpg,.png,.jpeg,.svg,.webp,.gif,.tif,.tiff,.psd,.fig,.ai,.xd,.ttf,.otf,.fnt,.woff,.woff2,.Iso,.dmg"
                  />
                  {/* </form> */}
                  <button
                    type="button"
                    onClick={() => fileRef.current?.click()}
                    className="p-2 border-[#C2C2C2] bg-[#EFEFEF] text-12 leading-[18px] text-graydark rounded"
                  >
                    Choose File
                  </button>
                  <p className="text-12 leading-[18px] text-black2 pl-3 break-all">
                    {fileName ? fileName : "No file Chosen"}
                  </p>
                </div>
                <p className={classNames("text-red-500 text-sm")}>
                  {!errorMsg ? "" : errorMsg}
                </p>
              </>
            ) : (
              <input
                ref={name}
                type="text"
                className="rounded w-full border-[2px]  text-14 border-[#3699FF] px-[11px] py-2 bg-[#fff] focus:outline-none mb-3"
                placeholder="Enter Name"
              />
            )}

            <div className="flex justify-end pb-5">
              <button
                onClick={() => {
                  closePopup ? closePopup() : setShow(false);
                  setFileName("");
                }}
                className="px-4 py-2 rounded-[6px] font-poppins font-semibold text-xs text-[#979797] bg-[#EFEFEF] mr-2"
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  isFile ? importData() : addNewItem(name.current.value);
                  setFileName("");
                }}
                className={classNames(
                  errorMsg ? " pointer-events-none opacity-50" : "",
                  isUploading ? "pointer-events-none opacity-50" : "",
                  "px-4 py-2 rounded-[6px] font-poppins font-semibold text-xs text-[#fff] bg-[#009EF7]"
                )}
              >
                {isUploading ? "Uploading..." : buttonConfirm}
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default CreateNewMediaPopup;
