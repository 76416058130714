import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const initialState: any = {
  alerts: [],
};

const alertSlice = createSlice({
  name: "alertClaim",
  initialState,
  reducers: {
    createAlert: (state, action: PayloadAction<any>) => {
      state.alerts.push({
        message: action.payload.message,
        type: action.payload.type,
      });
    },
    clearAlert: (state, action: PayloadAction<any>) => {
      state.alerts=[];
    },
  },
});

export const alertAction = alertSlice.actions;
export const alertReducer = alertSlice.reducer;
