import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
  Fragment,
} from "react";
import {
  ClockInIcon,
  ClockOutIcon,
  CloseIcon,
  DocumentIcon,
  DoneIcon,
  DraftIcon,
  LoadingIcon,
  ReloadIcon,
  SendIcon,
  TrashIcon,
} from "../../common/Icons";
import ButtonStyles from "./TimeButton.module.scss";
import classNames from "classnames";
import { ITimeInData } from "./UserDailyInfo";
import { Navigate, redirect, useNavigate } from "react-router-dom";
import CommonErrorPopup from "../../common/CommonErrorPopup";
import { Fade, Modal, Tooltip } from "@mui/material";
import { StringDropdownList } from "../../common/DropdownList";
import Button from "../../common/Button";
import TimeRangeSlider from "../../TimeRangeComponent/TimeRangeSlider";
import ResizableSlider from "../../TimeRangeComponent/TimeSlider";
import { StringDropdownListWithSearch } from "../../common/DropdownListWithSearch";
import {
  baseObj,
  paymentStatusListData,
  paymentTermsData,
} from "../../../utils/constants";
import { DropdownListSearchInput } from "../../common/SearchInputDropdown";
import CustomTimePicker, {
  changeStringToHMS,
} from "../../CustomTimePicker/CustomTimePicker";
import { TaskTagDropdownList } from "../../common/TaskTagDropDownList";
import { TaskDropdownSearchInput } from "../../common/TaskSearchInputDropdown";
import { ProjectDropdownSearchInput } from "../../common/ProjectDropdownSearchInput";
import TimePickerViewRenderers from "./TimeWithClock";
import {
  TDataList,
  TItemData,
  TPreviousList,
  TProfileDailyListResponse,
  TProjectListDailyReport,
  TTaskNameList,
  TTaskTagsList,
  useCreateProfileTaskNameMutation,
  useGetClockoutRealTimeMutation,
  useGetProfileDailyReportPopupQuery,
  useGetProfileDailyTaskListQuery,
  useSaveProfileDailyReportMutation,
} from "../../../app/services/profile-daily-popup";
import DailyRows from "./DailyRows";
import { toast } from "react-toastify";
import dayjs, { Dayjs } from "dayjs";
import { AnyIfEmpty } from "react-redux";
import { StringDropdownListWithDeleteBtn } from "../../common/StringDropdownListWithDeleteBtn";
import { callback } from "chart.js/dist/helpers/helpers.core";
import useDate from "../../../hooks/useDate";
import useSecond from "../../../hooks/useSecond";
import TimeHoursComponent from "./TimeHoursComponent";
import { makeStyles } from "@mui/styles";
import DailyReportPopupForm, { workingStatusList } from "../DailyReport/DailyReportPopupForm";
import { renderDateFormatYMD } from "../../DashboardCalendarView/DashboardAddEventPopup";
const useStyles = makeStyles(() => ({
  tooltip: {
    backgroundColor: "transparent",
    margin: 0,
    marginRight: 8,
    padding: 0,
  },
}));
interface ITimeButton {
  setTimeInData: Dispatch<SetStateAction<ITimeInData[]>>;
  count: number;
  createCheckInOut?: any;
  latitude?: any;
  longitude?: any;
  clockStatus?: any;
  timeInData?: any;
  isDisabled?: boolean;
  location?: any;
  setClockStatus?: any;
  setActiveHour: any;
  setActiveStatus: any;
  setProjectLists: React.Dispatch<React.SetStateAction<TProjectListDailyReport[]>>;
  projectLists: TProjectListDailyReport[];
  setCategoriesList: (value: SetStateAction<TTaskTagsList[]>) => void;
  categoriesList: TTaskTagsList[];
  setReportDate: (value: SetStateAction<string>) => void;
  reportDate: string;
  dailyPopupData: TDataList | undefined;
  setIsDisabled: React.Dispatch<SetStateAction<boolean>>;
}


export const sampleData = {
  uniqueId: 0,
  id: 0,
  is_previous: 0,
  project_id: 0,
  type: "project",
  task_category_id: 0,
  task_id: 0,
  time_spent: "00:00:00",
  isDisableRow: true,
  hours: "",
  minutes: "",
  is_need_to_create_new: 1
};

const TimeButton: FC<ITimeButton> = ({
  setTimeInData,
  count,
  createCheckInOut,
  clockStatus,
  latitude,
  longitude,
  timeInData,
  isDisabled,
  setClockStatus,
  setActiveHour,
  setActiveStatus,
  setIsDisabled
  //setProjectLists,
  // projectLists,
  // setCategoriesList,
  // categoriesList,
  // setReportDate,
  // reportDate,
  //dailyPopupData
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [todayArray, setTodayArray] = useState<any[]>([]);
  const [timeHour, setTimeHour] = useState<string>("");

  const [hours, setHours] = useState<number>(0);
  const [minutes, setMinutes] = useState<number>(0);

  const [totalTimeData, setTotalTimeData] = useState<any>("");
  // const [finalHour, setFinalHour] = useState<number>(0);
  // const [finalMinute, setFinalMinute] = useState<number>(0);
  const [getHour, setGetHour] = useState<number>(0);
  const [getMinute, setGetMinute] = useState<number>(0);
  const [data, setData] = useState<any[]>([]);
  const [prevId, setPrevId] = useState<any>();
  const [totalTime, setTotalTime] = useState<string>("");
  const [successPopup, setSuccessPopup] = useState<boolean>(false);
  const [errorPopup, setErrorPopup] = useState<boolean>(false);
  const [message, setMessage] = useState<any>([]);

  const [draftData, setDraftData] = useState<any[]>([]);
  const [status, setStatus] = useState<string>("");
  const [state, setState] = useState<number>(0); // 1 for clocked in, 2 for clocked out
  // const [latitude, setLatitude] = useState<number>();
  // const [longitude, setLongitude] = useState<number>();
  const [clockInDate, setClockInDate] = useState<Date>();
  const [clockOutDate, setClockOutDate] = useState<Date>();
  const [clockInTime, setClockInTime] = useState<string>("");
  const [clockOutTime, setClockOutTime] = useState<string>("");
  const [cuslatitude, setCusLatitude] = useState<any>(latitude);
  const [cuslongitude, setCusLongitude] = useState<any>(longitude);
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [isShow, setIsShow] = useState<boolean>(true);
  const [selectedMember, setSelectedMember] = useState<string>("");
  const [uploading, setUploading] = useState<boolean>(false);
  const [isDisabledSubmit, setIsDisabledSubmit] = useState<boolean>(false);
  const [uploadingData, setUploadingData] = useState<boolean>(false);
  const [duplicateData, setDuplicateData] = useState<boolean>(false);
  const [isCheckValue, setIsCheckValue] = useState<boolean>(false);
  const [workingStatus, setWorkingStatus] = useState<string>('');
  const [clickSubmit, setClickSubmit] = useState<boolean>(false);
  const [taskNameId, setTaskNameId] = useState<any>(0);
  const [taskCategoryId, setTaskCategoryId] = useState<any>(0);
  const [projectData, setProjectData] = useState<any>("");
  const [taskNamesData, setTaskNamesData] = useState<string>("");
  const [messageData, setMessageData] = useState<string>("");
  const [timeError, setTimeError] = useState<string>("");
  // const [userList, setUserList] = useState<baseObj[]>(
  //   paymentStatusListData ?? []
  // );
  const [projectLists, setProjectLists] = useState<TProjectListDailyReport[]>(
    []
  );
  const [categoriesList, setCategoriesList] = useState<TTaskTagsList[]>([]);
  const [previousDataList, setPreviousDataList] = useState<TPreviousList[]>([]);
  const [todayData, setTodayData] = useState<TPreviousList | null>();
  const [timeSpentData, setTimeSpentData] = useState(false);

  const [taskNames, setTaskName] = useState<TTaskNameList[]>([]);
  const [saveDaily] = useSaveProfileDailyReportMutation();
  // const [createTasks] = useCreateProfileTaskNameMutation();
  const [maxTimeLimit, setMaxTimeLimit] = useState<number>(0);
  const [totalTimeSpent, setTotalTimeSpent] = useState<number>(0);
  const [currentID, setCurrentID] = useState<number>(0);
  const [getClockoutRealTimeData] = useGetClockoutRealTimeMutation();
  const [realClockoutTime, setRealClockoutTime] = useState<string>("");
  const [reportDate, setReportDate] = useState<string>('');
  const { data: dailyPopupData } = useGetProfileDailyReportPopupQuery({
    search: "",
    project_id: 0,
    category_id: 0,
  });
  const [currentNumber, setCurrentNumber] = useState<string>(
    "00:00:00"
  );
  const [currentData, setCurrentData] = useState<any>(
    [
      {
        id: 0,
        time_spent: ''
      }
    ]
  );
  const [currentValue, setCurrentValue] = useState<number>(0);
  const fetchDataMemoized = useCallback(() => {
    if (dailyPopupData) {
      // setProjectLists(dailyPopupData.project_list);
      // setCategoriesList(dailyPopupData.task_categories);
      // setPreviousDataList(dailyPopupData.previous_data);
      // setTaskName(dailyPopupData.task_list);
      // setTodayData(dailyPopupData.today_data);
      // setTimeHour(dailyPopupData.total_time_text);
      // setGetHour(dailyPopupData.hours);
      // setGetMinute(dailyPopupData.minutes);
      // setReportDate(dailyPopupData.date)
      // var time = changeStringToHMS(dailyPopupData.total_time);
      // setMaxTimeLimit(time ? time : 0);
      // setReportDate(dailyPopupData.date)
      setProjectLists(dailyPopupData?.data?.project_list);
      setCategoriesList(dailyPopupData?.data?.task_categories);
      setPreviousDataList(dailyPopupData?.data?.previous_data);
      setTaskName(dailyPopupData?.data?.task_list);
      setTodayData(dailyPopupData?.data?.today_data);
      setTimeHour(dailyPopupData?.data?.total_time_text);
      setGetHour(dailyPopupData?.data?.hours);
      setGetMinute(dailyPopupData?.data?.minutes);
      setReportDate(dailyPopupData?.data?.date)
      var time = changeStringToHMS(dailyPopupData?.data?.total_time);
      setMaxTimeLimit(time ? time : 0);
      setReportDate(dailyPopupData?.data?.date)
    }
  }, [dailyPopupData]);

  const getCurrentDateTime = () => {
    var date = new Date();
    var hour = date.getHours();
    var minute = date.getMinutes();
    var time = changeTimeFormat(hour, minute);
    return {
      date,
      time,
    };
  };
  const changeTimeFormat = (h: number, m: number) => {
    var hour = h.toString();
    var min = m.toString();
    if (hour.length < 2) {
      hour = "0" + hour;
    }
    if (min.length < 2) {
      min = "0" + min;
    }
    return hour + ":" + min;
  };
  const checkLocation = () => {
    const location = window.navigator && window.navigator.geolocation;
    if (location) {
      location.getCurrentPosition(
        (position) => { },
        (error) => {
          setErrorMsg(error?.message);
          // this.setState({ latitude: 'err-latitude', longitude: 'err-longitude' })
        }
      );
    } else {
      setErrorMsg("Please allow to access location");
    }
  };
  const getClockRealTime = () => {
    getClockoutRealTimeData().then((res: any) => {
      if (res.data) {
        setRealClockoutTime(res.data.total_time)
      }
    })
  }
  const checkIsClockOut = () => {
    getClockRealTime();
    if (clockStatus == "checkout") {
      if (todayArray) {
        setData(todayArray)
      }
      setPrevId(0)
      setIsOpen(true);
    } else {
      clockInOutHandler();
    }
  };

  const clockInOutHandler = useCallback(() => {
    setStatus("loading");
    createCheckInOut({ latitude: latitude, longitude: longitude }).then(
      (res: any) => {
        changeClockStatus(res)
      }
    );
    
  }, [latitude, longitude]);
  const changeClockStatus = (res:any) => {
    setStatus("loaded");

    if (res?.data?.status == true) {
      setClockStatus(res?.data?.clockStatus)
      setTimeInData(res?.data?.timeSheets)
      setActiveStatus(res?.data.activeStatus)
      setActiveHour(res?.data.totalHours)
      toast(res?.data?.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        type: "success",
      });
    }
    if (clockStatus === "checkout") {
      setState(0);
    }

    var dateData = getCurrentDateTime();
    setClockInDate(dateData.date);
    setClockInTime(dateData.time);
  }
  const onKeyDown = (event: any) => {
    if (event.code === "Space") event.preventDefault();
    if (event.which === 13) event.preventDefault();
  };
  const handleResetTime = () => {
    setData([...data]?.map((x: any) => {
      x.time_spent = '00:00:00'
      return x;
    }))
  }
  const handleAddNew = () => {
    setData([
      ...data,
      {
        ...sampleData,
        uniqueId: Math.random() * 100,
        task_category_id: categoriesList[0]?.id ?? 0,
        hours: "08",
        minutes: "00",
      },
    ]);
    // setData([...data, { ...sampleData, uniqueId: Math.random() * 100 }]);
  };

  const deleteFun = (id: number) => {
    const filterData = data.filter((d: any) => d.uniqueId !== id);
    setData([...filterData]);
  };

  const deleteFunDraft = (id: number) => {
    const filterData = draftData.filter((d: any) => d.id !== id);
    deleteFun(id)
    setDraftData([...filterData]);
  };

  // const checkDuplicateData = (final: any) => {
  //   // let projectValueArr = final.map(function (item: any) {
  //   //   return item.project_id;
  //   // });
  //   // let isDuplicateProject = projectValueArr.some(function (
  //   //   item: any,
  //   //   idx: any
  //   // ) {
  //   //   return projectValueArr.indexOf(item) != idx;
  //   // });
  //   // // console.log("isDuplicateProject", isDuplicateProject);
  //   // let categoryValueArr = final.map(function (item: any) {
  //   //   return item.task_category_id;
  //   // });
  //   // let isDuplicateCategory = categoryValueArr.some(function (
  //   //   item: any,
  //   //   idx: any
  //   // ) {
  //   //   return categoryValueArr.indexOf(item) != idx;
  //   // });
  //   // // console.log("isDuplicateCategory", isDuplicateCategory);
  //   // let taskValueArr = final.map(function (item: any) {
  //   //   return item.task_id;
  //   // });
  //   // let isDuplicateTasks = taskValueArr.some(function (item: any, idx: any) {
  //   //   return taskValueArr.indexOf(item) != idx;
  //   // });
  //   // // console.log("isDuplicateTasks", isDuplicateTasks);
  //   // if (isDuplicateProject && isDuplicateCategory && isDuplicateTasks) {
  //   //   return true;
  //   // } else {
  //   //   return false;
  //   // }
  // };

  const findDuplicates = (arr: any, propertyName: any) => {
    const seen: any = {};
    const duplicates: any = [];

    arr.forEach((item: any) => {
      const propertyValue = item[propertyName];

      if (seen[propertyValue]) {
        duplicates.push(item);
      } else {
        seen[propertyValue] = true;
      }
    });

    return duplicates;
  };
  const categoryDuplicates = (arr: any, propertyName: any) => {
    const seen: any = {};
    const duplicates: any = [];

    arr.forEach((item: any) => {
      const propertyValue = item[propertyName];

      if (seen[propertyValue]) {
        duplicates.push(item);
      } else {
        seen[propertyValue] = true;
      }
    });

    return duplicates;
  };
  const taskDuplicates = (arr: any, propertyName: any) => {
    const seen: any = {};
    const duplicates: any = [];

    arr.forEach((item: any) => {
      const propertyValue = item[propertyName];

      if (seen[propertyValue]) {
        duplicates.push(item);
      } else {
        seen[propertyValue] = true;
      }
    });

    return duplicates;
  };

  // console.log("cehcing data for drop", isCheckValue);

  useEffect(() => {
    // const final = [...data, ...draftData];
    const final = [...data];
    // console.log("dina", final);
    var isZero = final?.find((x: any) => x.time_spent == "00:00:00");
    if (isZero) {
      setTimeSpentData(true);
    } else {
      setTimeSpentData(false);
    }

    const isTaskIDZero = final?.find(x => x.task_id == 0 && x.task_name == "");
    const duplicates = final.reduce((acc: any, obj: any, index: number) => {
      if (obj.project_id != 0) {
        const duplicates = final.filter(
          (item: any, i: any) => {
            return i !== index &&
              item.task_category_id === obj.task_category_id &&
              item.project_id === obj.project_id &&
              (item.task_id == obj.task_id &&
                item.task_name == obj.task_name)
          }
        );
        if (
          duplicates.length > 0 &&
          !acc.some(
            (dup: any) =>
              dup.task_category_id === obj.task_category_id &&
              dup.project_id === obj.project_id &&
              (dup.task_id == obj.task_id &&
                dup.task_name == obj.task_name)
          )
        ) {
          return acc.concat([obj]);
        }
      }
      return acc;
    }, []);
    if (duplicates.length !== 0) {
      setDuplicateData(true);
      setUploading(false);
      return;
    } else {
      setDuplicateData(false);
      setUploading(false);
    }
    if (isTaskIDZero) {
      setUploadingData(true);
      setDuplicateData(false);
      return;
    } else {
      setUploadingData(false);
      setDuplicateData(false);
      return
    }
  }, [data, draftData]);

  useEffect(() => {
    changeEachSliderBackground(data);
  }, [data])

  const handleSubmit = () => {
    setUploading(true);
    const final = [...data]?.filter((d: any) => d.task_name != '' || d.task_name != undefined)?.map((d: any) => {
      return d;
    });

    // const isValidate = checkDuplicateData(final);
    // console.log("isValidate", isValidate);
    //console.log("data submit ", final, data)   
    saveDaily({
      data: final,
      is_draft: 0,
      is_previous_report: 0,
      date: reportDate,
      working_status: workingStatus,
      reason: '',
      is_need_to_create_new: 0
    })
      .then((res: any) => {
        if (res?.data?.status) {
          setTimeSpentData(false);
          setDuplicateData(false);
          changeClockStatus(res)
          setMessageData("");
          setUploading(false);
          // toast("Successfully Created.", {
          //   position: "top-right",
          //   autoClose: 2000,
          //   hideProgressBar: false,
          //   closeOnClick: true,
          //   pauseOnHover: true,
          //   draggable: true,
          //   progress: undefined,
          //   theme: "light",
          //   type: "success",
          // });
          setIsOpen(false);
          setData([]);
          setProjectData("");
          setTaskCategoryId(0);
        }
        if (res?.error) {
          toast(res?.error?.message, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            type: "success",
          });
          setDuplicateData(false);
          setMessageData(res.error.data.message);
          setUploading(false);
        }
      })
      .catch((error: any) => {
        toast(error?.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          type: "success",
        });
        setUploading(false);
        setDuplicateData(false);
      });
    setPrevId(0)
    // }

  };

  const handleSubmitDraft = () => {
    setUploading(true);
    const final = [...data];

    saveDaily({
      data: final,
      is_draft: 0,
      is_previous_report: 0,
      date: reportDate,
      working_status: workingStatus,
      reason: '',
    })
      .then((res: any) => {

        if (res?.data?.status) {
          setTimeSpentData(false);
          //clockInOutHandler();
          setUploading(false);
          toast("Successfully Draft.", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            type: "success",
          });
          setIsOpen(false);
          setData([]);
          setProjectData("");
          setTaskCategoryId(0);
          setMessageData("");
        }
        if (res?.error) {
          setUploading(false);
          setMessageData(res.error.data.message);
        }
      })
      .catch((error: any) => {

        setUploading(false);
      });
  };

  const handleClosePopup = () => {
    if (!todayArray) {
      setData([]);
    }
  };

  // const handleCreateTasks = () => {
  //   const data = {
  //     type: projectData.type ?? "project",
  //     project_id: projectData.value ?? 0,
  //     name: taskNamesData,
  //     category_id: taskCategoryId,
  //   };

  //   createTasks(data)
  //     .then((res: any) => {
  //       if (res.data.status) {
  //         setTaskName([
  //           ...taskNames,
  //           { id: res.data.task.id, name: res.data.task.name },
  //         ]);
  //       }
  //     })
  //     .catch((error: any) => {
  //       setUploading(false);
  //       console.log("e", error);
  //     });
  // };

  useEffect(() => {
    fetchDataMemoized();
  }, [fetchDataMemoized]);

  // useEffect(() => {
  //   if (previousDataList.length) {
  //     let dataItems: any = [];
  //     const prevDataArr = previousDataList?.find(x => x.id == prevId)?.items
  //     setData((prev) => {
  //       var dif = prev.filter(x => x.is_previous == 0)
  //       return prevDataArr ? [...prevDataArr, ...dif] : [...dif]
  //     });

  //     // const lastData = data.filter((d: any) => d.is_previous !== 1);
  //     previousDataList.forEach((data: any) => {
  //       if (data.id === prevId) {
  //         data.items.forEach((item: any) => {
  //           dataItems.push({
  //             uniqueId: Number(item.id) * Math.random() * 100,
  //             id: item.id,
  //             is_previous: 1,
  //             type: item.type,
  //             project_id: item.project_id,
  //             task_category_id: item.task_category_id,
  //             task_id: item.task_id,
  //             time_spent: item.time_spent,
  //             task_name: item.task_name,
  //             isDraftData: true,
  //             is_need_to_create_new: item.is_need_to_create_new
  //           });
  //         });
  //       }
  //     });
  //     setDraftData([...dataItems]);
  //   }
  // }, [prevId]);


  useEffect(() => {
    if (state === 1) {
      var data = {
        checkIn: clockInTime,
        checkOut: clockOutTime,
        hour: "4h 0m",
        gps: "" + latitude + ", " + longitude + "",
        url:
          "https://maps.google.com/maps?q=" + latitude + "," + longitude + "",
      };
      setTimeInData((prevRecord) => prevRecord ? [...prevRecord, data] : [data]);
    } else if (state == 0) {
      var clockOutData;
      var dateData = getCurrentDateTime();
      setClockOutDate(dateData.date);
      setClockInTime(dateData.time);
      setTimeInData((prevRecord) => {
        return prevRecord?.map((x) => {
          if (x.out == "") {
            x.out = dateData.time;
            clockOutData = x;
          }
          return x;
        });
      });
    }
  }, [state, setTimeInData]);

  useEffect(() => {
    if (clockStatus === "checkin") {
      setState(0);
    } else {
      setState(1);
    }
    setState((prev) => (prev === 0 ? 1 : 0));
    setStatus("");
  }, [clockStatus]);

  useEffect(() => {
    let dataItems: any = [];

    if (todayData?.items.length) {
      todayData?.items.forEach((item: any) => {
        dataItems.push({
          uniqueId: Number(item.id) * Math.random() * 100,
          id: item.id,
          is_previous: item.is_previous,
          type: item.type,
          project_id: item.project_id,
          task_category_id: item.task_category_id,
          task_id: item.task_id,
          time_spent: item.time_spent,
          task_name: item.task_name,
          is_need_to_create_new: item.is_need_to_create_new
        });
      });
      setData(removeDuplicateObjects([...draftData, ...dataItems], 'id'));
      setTodayArray([...dataItems]);
    } else {
      console.log("here")
      setData([]);
    }
  }, [todayData]);

  const msToTime = (durationData: any) => {
    const duration = durationData * 1000;
    var milliseconds = Math.floor((duration % 1000) / 100),
      seconds = Math.floor((duration / 1000) % 60),
      minutes = Math.floor((duration / (1000 * 60)) % 60),
      hours = Math.floor(duration / (1000 * 60 * 60)); //%24

    let h = hours < 10 ? "0" + hours : hours;
    let m = minutes < 10 ? "0" + minutes : minutes;
    let s = seconds < 10 ? "0" + seconds : seconds;
    // console.log("msto time", h);

    return h + ":" + m + ":" + s;
  };
  const TimeToMS = (time_spent: string) => {
    let regExTime = /([0-9]?[0-9]):([0-9][0-9]):([0-9][0-9])/;
    let regExTimeArr: any = regExTime.exec(time_spent);
    if (regExTimeArr) {
      let timeHr = regExTimeArr[1] * 3600 * 1000;
      let timeMin = regExTimeArr[2] * 60 * 1000;
      let timeSec = regExTimeArr[3] * 1000;
      let timeMs = timeHr + timeMin + timeSec;
      return timeMs / 1000;
    } else {
      return 0;
    }
  }
  useEffect(() => {
    let regExTime = /([0-9]?[0-9]):([0-9][0-9]):([0-9][0-9])/;
    var total = 0;
    var timespent = data?.map((x: any) => {
      let regExTimeArr: any = regExTime.exec(x.time_spent);
      if (regExTimeArr) {
        let timeHr = regExTimeArr[1] * 3600 * 1000;
        let timeMin = regExTimeArr[2] * 60 * 1000;
        let timeSec = regExTimeArr[3] * 1000;

        let timeMs = timeHr + timeMin + timeSec;
        total += timeMs;
      }
    });
    var newData = data.map((d, index: number) => {
      return { ...d, limit: maxTimeLimit - TimeToMS(data[index - 1]?.time_spent) }
    });
    setTotalTime(msToTime(total));
  }, [data, getHour, getMinute]);

  useEffect(() => {
    let regExTime = /([0-9]?[0-9]):([0-9][0-9]):([0-9][0-9])/;
    var total = 0;
    var timespent = data?.map((x: any) => {
      let regExTimeArr: any = regExTime.exec(x.time_spent);
      if (regExTimeArr) {
        let timeHr = regExTimeArr[1] * 3600 * 1000;
        let timeMin = regExTimeArr[2] * 60 * 1000;
        let timeSec = regExTimeArr[3] * 1000;

        let timeMs = timeHr + timeMin + timeSec;
        total += timeMs;
      }
    });

    var total1 = 0;
    var workingTime = (total) / 1000;
    setTotalTimeData(msToTime(total / 1000 + total1 / 1000));
    const diff = (maxTimeLimit - workingTime) == 0 ? 0 : (maxTimeLimit - workingTime) - 300;
    if (diff <= 300 && diff >= -300) {
      setTimeError('')
      setIsDisabledSubmit(false)
    }
    else if (diff <= 300) {
      setTimeError('The task time spent hours is more than the clocking hours')
      setIsDisabledSubmit(true)
    } else if (diff >= 300) {
      setTimeError('The task time spent hours is lower than the clocking hours')
      setIsDisabledSubmit(true)
    } else {
      setTimeError('')
      setIsDisabledSubmit(false)
    }
  }, [data, draftData]);

  useEffect(() => {
    //for draft list hours and minutes
    let draftDataHour: any = draftData?.reduce(function (
      prev: any,
      current: any
    ) {
      return prev + +current?.hours;
    },
      0);

    let draftDataMinute: any = draftData?.reduce(function (
      prev: any,
      current: any
    ) {
      return prev + +current?.minutes;
    },
      0);

    const draftsMinutes = draftDataHour * 60 + draftDataMinute;
    // setDraftDataMinutesFinal(draftsMinutes);
  }, [getHour, getMinute, draftData]);

  useEffect(() => {
    if (timeHour) {
      setHours(Number(timeHour.slice(0, 2)));
      setMinutes(Number(timeHour.slice(4, 6)));
    }
  }, [timeHour]);
  useEffect(() => {
    if (successPopup) {
      toast("Done Successfully.", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        type: "success",
      });
    }

    setTimeout(() => {
      setSuccessPopup(false);
    }, 2000);
  }, [successPopup]);
  const handleTimeBlur = (value: string, time: number, id: number) => {
    const totalData = preventTimeChange(value, id);
    return totalData;
  };
  const changeEachSliderBackground = (data: any) => {
    data?.map((x: any) => {
      changeSliderBackground(x, x.time_spent)
    })
  }
  const changeSliderBackground = (cell: any, timestr: string) => {
    var time = changeStringToHMS(timestr);
    var numberInput = document.getElementById("myRange_" + cell?.uniqueId);
    if (numberInput && time) {
      if (maxTimeLimit > 0) {
        var value = ((time - 0) / (maxTimeLimit - 0)) * 100;
        //  console.log("changeSliderBackground ", value)
        numberInput.style.background =
          "linear-gradient(to right, #3699FF 0%, #3699FF " +
          value +
          "%, #E1F0FF " +
          value +
          "%, #E1F0FF 100%)";
      } else {
        var value = 0;
        numberInput.style.background =
          "linear-gradient(to right, #3699FF 0%, #3699FF 0%, #E1F0FF 0%, #E1F0FF 100%)";
      }

    }
  }
  const removeDuplicateObjects = (array: any, property: any) => {
    const uniqueIds: any = [];

    const unique = array.filter((element: any) => {
      const isDuplicate = uniqueIds.includes(element[property]);

      if (!isDuplicate) {
        uniqueIds.push(element[property]);

        return true;
      }

      return false;
    });

    return unique;
  }

  const preventTimeChange = (current: string, id: number) => {
    setData((prev: any) => {
      return prev.map((data: baseObj, index: number) => {
        const totalDataTimeSpent = prev?.filter((x: any) => x.uniqueId != id)?.reduce(function (acc: any, obj: any) { return acc + TimeToMS(obj.time_spent); }, 0) + TimeToMS(current);
        const filterCurrentData = removeDuplicateObjects(currentData, 'id')
        setCurrentData(filterCurrentData)
        const sameTimeSpent = currentData?.find((x: any) => x.id == id);
        if (sameTimeSpent) {
          sameTimeSpent.time_spent = data.time_spent;
        } else {
          setCurrentData([...currentData, { id: id, time_spent: data.time_spent }])
        }
        if (data.uniqueId == id) {
          var curTimeSpent = TimeToMS(current);

          // data.time_spent = current;
          // var totalTime = prev?.reduce(function (acc: any, obj: any) { return acc + TimeToMS(obj.time_spent); }, 0);
          if (totalDataTimeSpent > maxTimeLimit) {
            const timeData = currentData?.find((x: any) => x.id == id);
            data.maxLimit = totalDataTimeSpent;
            //console.log(maxTimeLimit,totalDataTimeSpent,TimeToMS(current),totalDataTimeSpent-TimeToMS(current))
            data.time_spent = msToTime(maxTimeLimit - (totalDataTimeSpent - TimeToMS(current)))//timeData?.time_spent || "00:00:00";
          } else {
            data.maxLimit = totalDataTimeSpent;
            data.time_spent = current;
            if (sameTimeSpent) {
              sameTimeSpent.time_spent = current;
            }
          }
        }
        return data
      })
    });

  }
  useEffect(() => {
    setData(removeDuplicateObjects([...draftData, ...data], 'id'))
  }, [draftData])

  return (
    <>
      <CommonErrorPopup
        show={errorMsg != "" ? true : false}
        setShow={() => setErrorMsg("")}
        message={errorMsg}
      />
      <button
        onKeyDown={onKeyDown}
        onClick={(event) => {
          // checkLocation();
          // clockInOutHandler();
          checkIsClockOut();
          // setIsOpen(true);
        }}
        className={classNames(
          "timebutton",
          isDisabled === true ? "pointer-events-none opacity-50" : "",
          "whitespace-nowrap w-fit md:ml-auto focus:outline-none text-white flex items-center py-1 md:py-1 px-2 md:px-3 text-10 5xs:text-12 leading-[18px] font-semibold rounded-md",
          {
            "bg-vorpblue hover:bg-white border border-vorpblue hover:text-vorpblue": clockStatus === "checkin" && status === "",
            "bg-redPink hover:bg-white border border-redPink hover:text-redPink": clockStatus === "checkout" && status === "",
            "bg-vorpblue/50 hover:bg-white border border-vorpblue/50 hover:text-vorpblue/50": status === "loading" && state === 0,
            "bg-redPink/50 hover:bg-white border border-redPink/50 hover:text-redPink/50": status === "loading" && state === 1,
            "bg-[#1BC5BD] hover:bg-white border border-[#1BC5BD] hover:text-[#1BC5BD]": status === "loaded",
            "pointer-events-none": status !== "",
          }
        )}
      >
        {status === "" ? (
          // state === 1 ? (
          clockStatus === "checkout" ? (
            <ClockOutIcon className="mr-[14px]" />
          ) : (
            <ClockInIcon className="mr-[14px]" />
          )
        ) : status === "loading" ? (
          <LoadingIcon className={`${ButtonStyles.clockingIn} mr-[14px]`} />
        ) : (
          <DoneIcon className="mr-[14px]" />
        )}
        {clockStatus === "checkout" ? "Clock out" : "Clock in"}
        {/* {state === 1
        // ? status === "loaded"
        ? clockStatus === "checkout"
          ? "Clocked out"
          : "Clock out"
        // : status === "loaded"
        : clockStatus === "checkin"
          ? "Clocked in"
          : "Clock in"
        } */}
      </button>

      <DailyReportPopupForm
        dailyPopupData={dailyPopupData?.data}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        setProjectData={setProjectData}
        projectData={projectData}
        categoriesList={categoriesList}
        setCategoriesList={setCategoriesList}
        setTaskNamesData={setTaskNamesData}
        taskNamesData={taskNamesData}
        projectLists={projectLists}
        setProjectLists={setProjectLists}
        setGetHour={setGetHour}
        getHour={getHour}
        setGetMinute={setGetMinute}
        getMinute={getMinute}
        setTotalTime={setTotalTime}
        totalTime={totalTime}
        setPreviousDataList={setPreviousDataList}
        previousDataList={previousDataList}
        draftData={draftData}
        data={data}
        setData={setData}
        setDraftData={setDraftData}
        setMaxTimeLimit={setMaxTimeLimit}
        maxTimeLimit={maxTimeLimit}
        todayData={todayData}
        setTodayData={setTodayData}
        timeHour={timeHour}
        currentData={currentData}
        setCurrentData={setCurrentData}
        reportDate={reportDate}
        setReportDate={setReportDate}
        clockInOutHandler={clockInOutHandler}
        changeClockStatus={changeClockStatus}
        latitude={latitude}
        longitude={longitude}
         />

    </>
  );
};

export default TimeButton;
