import { redirectToLogin } from "./annoucement-sheet";
import { api, endpointUrl } from "./api";
import authHeader from "./auth-header";

export interface ILogs {
  id: number;
  module: string;
  description: string;
  event: string;
  causer_id: number;
  causer: string;
  created_at: string;
}

interface IActivity {
  data: ILogs[];
  total: number;
}

export interface IPasswordLogResponse {
  activity_logs: IActivity;
  length: number ;
  module_id: number;
}

export interface IUserData {
  id: number;
  name: string;
  email: string;
  profile_image: string;
}

type ListUserData = {
  users: IUserData[];
};

type PasswordLogRequest = {
  search: string;
  limit: number;
  action: string;
  start: string;
  end: string;
  causer_id: number;
  page: number;
  sort?:string;
};

export const passwordLogActivity = api.injectEndpoints({
  endpoints: (build) => ({
    getPasswordLogActivity: build.query<
      IPasswordLogResponse,
      Partial<PasswordLogRequest>
    >({
      query: ({ search, limit, action, start, end, causer_id, page,sort }) => ({
        url: `${endpointUrl}activity-logs?search=${search}&limit=${limit}&action=${action}&start=${start}&end=${end}&causer_id=${causer_id}&page=${page}&sort=${sort}`,
        headers: authHeader(),
      }),
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
      providesTags: (result: any = []) => {
        if (result?.activity_logs?.data?.length > 0) {
          return [
            ...result?.activity_logs?.data?.map(
              (id: any) => ({ type: "PasswordLog", id } as const)
            ),
            { type: "PasswordLog" as const, id: "LIST" },
          ];
        } else {
          return [{ type: "PasswordLog" as const, id: "LIST" }];
        }
      },
    }),
    getListUsersData: build.query<ListUserData, void>({
      query: () => ({ url: `${endpointUrl}user-list`, headers: authHeader() }),
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
      providesTags: ["ListUsers"],
    }),
  }),
});

export const { useGetPasswordLogActivityQuery, useGetListUsersDataQuery } =
  passwordLogActivity;

export const {
  endpoints: { getPasswordLogActivity, getListUsersData },
} = passwordLogActivity;
