import { ReactElement, useState } from "react";
import BasicLayout from "../layouts/BasicLayout";
import { Dashboard } from "../dashboard/views/Dashboard";

const DashboardRoute = (props: any): ReactElement => {
  const data = props.loginData;
  const permissions = props.permissions;
  // console.log("da", props)

  const [fullscreen, setFullscreen] = useState(false);
  const checkFullScreen = () => {
    setFullscreen(!fullscreen);
  };
  if (fullscreen == true) {
    return (
      <Dashboard
        checkFullScreen={checkFullScreen}
        fullscreen={fullscreen}
        permissions={permissions}
      />
    );
  } else {
    return (
      <div>
        <BasicLayout userData={data} permissions={permissions}>
          <Dashboard userData={data}
            checkFullScreen={checkFullScreen}
            fullscreen={fullscreen}
            permissions={permissions}
          />
        </BasicLayout>
      </div>
    );
  }
};

export default DashboardRoute;
