// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import axios from 'axios';
import { endpointUrl } from "./api";
import { getAuthUser } from "./dashboard";
export const apiKey = process.env.REACT_APP_FIREBASE_KEY;
export const authDomain = process.env.REACT_APP_FIREBASE_DOMAIN;
export const projectId = process.env.REACT_APP_FIREBASE_PROJECT_ID;
export const storageBucket = process.env.REACT_APP_FIREBASE_STORAGE;
export const messagingSenderId = process.env.REACT_APP_FIREBASE_SENDER_ID;
export const appId = process.env.REACT_APP_FIREBASE_APP_ID;
export const measurementId = process.env.REACT_APP_FIREBASE_MEASURE_ID;
export const serverkey = process.env.REACT_APP_SERVER_KEY;
export const firebaseConfig = {
    apiKey: apiKey,
    authDomain: authDomain,
    projectId: projectId,
    storageBucket: storageBucket,
    messagingSenderId: messagingSenderId,
    appId: appId,
    measurementId: measurementId
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);
if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
        // navigator.serviceWorker.register(window.location.origin + '/firebase-messaging-sw.js');
        if (process.env.NODE_ENV == "development") {
            navigator.serviceWorker.register(window.location.origin + '/firebase-messaging-sw.js');
        } else {
            navigator.serviceWorker.register(window.location.origin + '/firebase-messaging-sw-prod.js');
        }

    });
}
const isSupported = () =>
    'Notification' in window &&
    'serviceWorker' in navigator &&
    'PushManager' in window;
export const requestPermission = () => {
    if (isSupported()) {
        console.log("Requesting User Permission......");
        Notification.requestPermission().then((permission) => {

            if (permission === "granted") {

                console.log("Notification User Permission Granted.");
                return getToken(messaging, { vapidKey: serverkey })
                    .then((currentToken) => {
                       // console.log("currentToken ", currentToken)
                        if (currentToken) {
                            //    messaging.getInstance().subscribeToTopic("news");
                            callChannel(currentToken);
                        } else {

                            console.log('Failed to generate the app registration token.');
                        }
                    })
                    .catch((err) => {
                        console.log('An error occurred when requesting to receive the token.', err);
                    });
            } else {

                console.log("User Permission Denied.");
            }
        });
    }


}
if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
        navigator.serviceWorker.register('/firebase-messaging-sw.js')
            .then(function (registration) {
                // Registration was successful
                console.log('firebase-message-sw :ServiceWorker registration successful with scope: ', registration.scope);
            }, function (err) {
                // registration failed :(
                console.log('firebase-message-sw: ServiceWorker registration failed: ', err);
            });
    });

}
export const onMessageListener = () =>
    new Promise((resolve) => {
        onMessage(messaging, (payload) => {
            resolve(payload);
        });
    });

function subscribeToTopic() {
    // test()
    //     .subscribeToTopic('vo-channel')
    //     .then(() => console.log('Subscribed to topic!'));
}



// function callChannel(token) {
//     let authToken = "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiYjVlMjlmYWRhN2Q1OThmYzc4ZDQ3ZDBjYWM0ZGQ0ZTQ0YzM4ZDM3MWQyZmYxNmEyNWUzNThlMGE0NWM5ZWM5ZmRiZjhkMTIwOThlYjk1YzUiLCJpYXQiOjE2OTQ3NjMwOTkuOTUxMzI5OTQ2NTE3OTQ0MzM1OTM3NSwibmJmIjoxNjk0NzYzMDk5Ljk1MTMzNTkwNjk4MjQyMTg3NSwiZXhwIjoxNjk1MTk1MDk5Ljk0MjYxMjg4NjQyODgzMzAwNzgxMjUsInN1YiI6IjQiLCJzY29wZXMiOltdfQ.FEnntKmZSnIPqjM4X0nbsSb7sz3oHkdmIO7zUqBeKFDhNPnI--HR7yY1UpMJSXzMUQlYvilWK03CgnV0j9Zv2z4UPf-42YfsL5RUsthLgXhqZ0feOjmqJ8vgbCy8I6EC9PJh5X2kM8aruq52hV9GiJ_xEe9ibwO42rGqYZuNqV-q4kkt-aMoDjDd_HBF2yuQZyoJnt5qFQbahOGzvXnhOJwdZ-liZ_pvrPePrxza3WBSU8-ch979dNIIXfwDODfNdwrxTMMbd52jkxJxqwbCXRQ1Rl2EcoYeJGwMp3gmeqoH84qky4kW93VPunT5BeLle4B37mD2m1uKEiemUWBv0DDEjrZgLQMHw-6UOiRwzjOiS4Li9s-Iz9UPjXjuPX-z5uKZrJ9YG69EXSSu5YELsNRSBA7Ut5AlZZf9sWIsC30ZucbQ5m0MgL0O62uYWV9dF6OfgbhsQxLhz3Fu_O4YToPzzGClt-K73Ejhx8fdNy8v7wdsoKngBFqlki1ZbPSmxwftp2pPIWJYbU_yxQYlcLJqqlVE0DRi166yIQHbzd7jYAwuC1xqMzq0ebTkZb_fzdBFzV6ugzOh9GRFLgsvPSfcpYaW40vl5p9o-aWqaKrVQ9-TTgknyFyXFRxuId_MYx2zV6iagD1KulrJbfr5nbxcqVMZR0NWxuzHjRu6ojU";
//     axios.post(`https://vorpapidev.visibleone.io/api/v1/fcm-token`, {
//         token: token
//     }, {
//         headers: {
//             'Authorization': `Bearer ${authToken}`
//         }
//     }
//     ).then((result) => {
//         console.log("result ", result)
//     });
// }
function callChannel(token) {
    var getUser = getAuthUser();
    if (getUser?.token) {
        axios(endpointUrl + "fcm-token", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${getUser.token}`,
            },
            data: {
                token: token
            },
        }).then((res) => {
           // console.log("res ", res)
        })
    }
}
