import { Dispatch, FC, memo, SetStateAction, useEffect, useState } from "react";
import Button from "../common/Button";
import { AddIcon, CloseIcon, SelectedIcon, SendIcon } from "../common/Icons";
import { Fade, Modal } from "@mui/material";
import classNames from "classnames";
import { NumberDropdownList, StringDropdownList } from "../common/DropdownList";
import CustomDatePicker from "../common/FullWidthDatePicker";
import BaseRadioButton from "../common/BaseRadioButton";
import { CommonTagDropdown } from "../common/CommonTagDropdown";
import styles from "./CreateProjectPopup.module.scss";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { EmployeeData } from "../EmployeeTable/EmployeeTable";

import {
  TEmployee,
  TEmployeeListForProject,
  TRowData,
  useNewUpdatedProjectMutation,
  useSaveNewUpdatedProjectMutation,
} from "../../app/services/project";
import { renderDateFormatYMD } from "../DashboardCalendarView/DashboardAddEventPopup";
// import PaymentCreate from "./PaymentCreate";
import CheckListProject from "./CheckListProject";
import KeyDateProject from "./KeyDateProject";
import ResuableInput from "./ResuableInput";
import useInput from "../InputHook/UseInput";
import ResuableToasty from "../Toasty/ResuableToast";
import { StringDropdownListWithSearch } from "../common/DropdownListWithSearch";
import CreateProjectAssignTo from "./CreateProjectAssignTo";
import PaymentBar from "./PaymentBar";
import { isNotEmpty, isNotZero } from "../Validation/Validation";

export type TCreateProjectPopup = {
  rowList?: TRowData[];
  assignUserListData: TEmployee[];
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  isOpen: boolean;
  createProjectTech: any;
  regionList: EmployeeData[];
  countryList: EmployeeData[];
  projectTypesList: any[];
  projectStatusList: EmployeeData[];
  currencyLists: EmployeeData[];
  memberDataList: TEmployeeListForProject[];
  keyListData: any[];
  paymentStatusList: EmployeeData[];
  departmentList: EmployeeData[];
  editedData?: any;
  setEditData: any;
};

export const tagsList = [
  { id: 1, name: "Project Information" },
  { id: 2, name: "Project Details" },
  { id: 3, name: "Project Team" },
  { id: 4, name: "Financial Details" },
  { id: 5, name: "Addition Information" },
];
export const typeList = ["Caution", "Notice", "Warning"];
export const durationList = ["weeks", "months", "years"];
export const paymentTermList = [
  {
    id: 1,
    value: 1,
    label: "1 Times",
  },
  {
    id: 2,
    value: 2,
    label: "2 Times",
  },
  {
    id: 3,
    value: 3,
    label: "3 Times",
  },
  {
    id: 4,
    value: 4,
    label: "4 Times",
  },
  {
    id: 5,
    value: 5,
    label: "5 Times",
  },
  {
    id: 6,
    value: 6,
    label: "6 Times",
  },
];
export const paymentSaveList = [
  {
    id: 0,
    unique: 0,
    project_status_id: 1,
    amount: 0,
    payment_due_date: "2023-12-31",
    payment_received_date: "2023-12-31",
    invoice_no: "",
    payment_received_no: "",
  },
];

const CreateProjectPopup: FC<TCreateProjectPopup> = memo(
  ({
    rowList,
    isOpen,
    createProjectTech,
    setIsOpen,
    countryList,
    projectTypesList,
    projectStatusList,
    currencyLists,
    memberDataList,
    keyListData,
    paymentStatusList,
    departmentList,
    editedData,
    setEditData,
    assignUserListData,
  }) => {
    // console.log("edit", editedData);
    const projectNameInput = useInput("");
    const brandValueInput = useInput("");
    const projectComplexityInput = useInput("");
    const projectCodeInput = useInput("");
    const companyNameInput = useInput("");
    const primaryEmailInput = useInput("");
    const primaryContactInput = useInput("");
    const secondaryContactInput = useInput("");
    const secondaryEmailInput = useInput("");
    const financialInput = useInput("");
    const textAreaInput = useInput("");
    const projectScopeInput = useInput("");
    const projectStatusAreaInput = useInput("");
    const quotationInput = useInput("");
    const developmentUrlInput = useInput("");
    const uatUrlInput = useInput("");
    const liveUrlInput = useInput("");
    const remarkInput = useInput("");
    const [projectStatus, setProjectStatus] = useState<string>("NoStart");
    const [dateDiff, setDateDiff] = useState<number>(0);
    const [pojectStageList, setProjectStageList] = useState<any[]>([]);
    const [paymentStage, setPaymentStage] = useState<any[]>([]);
    const [paymentList, setPaymentList] = useState<any[]>([]);
    const [errorMsg, setErrorMsg] = useState<string>("");
    const [brnadLevel, setBrandLevel] = useState<string>("");
    const [pComplexity, setPComplexity] = useState<string>("");
    const [isSubmit, setIsSubmit] = useState<boolean>(false);
    const [uploading, setUploading] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);
    const [errorPrimary, setErrorPrimary] = useState<boolean>(false);
    const [techList, setTechList] = useState<any[]>([]);
    const [currencyLabel, setCurrencyLabel] = useState<string>("HK");
    const [primaryCode, setPrimaryCode] = useState<string>("+852");
    const [primaryContactPhone, setPrimaryContactPhone] = useState<string>("");
    const [secondaryPersonCode, setSecondaryPersonCode] =
      useState<string>("+852");
    const [secondaryPersonPhone, setSecondaryPersonPhone] =
      useState<string>("");
    // const [secondaryPersonEmail, setSecondaryPersonEmail] =
    //   useState<string>("");
    const [checkList, setCheckList] = useState<any>([]); //reminderCheck
    const [keyDateList, setKeyDateList] = useState<any>([]); //keyDateObj
    const [chosenTags, setChosenTags] = useState<string[]>([]);
    const [assignToList, setAssignToList] = useState<any[]>([]);
    const [tagsData, setTagsData] = useState<number>(1);
    const [regionData, setRegionData] = useState<string>("");
    const [currentStatus, setCurrentStatus] = useState<string>("");
    const [currencyData, setCurrencyData] = useState<number>(0);
    // console.log("first", currencyLabel);
    const [paymentTermData, setPaymentTermData] = useState<string>("");
    // const [paymentStatusData, setPaymentStatusData] = useState<string>("");
    const [endDate, setEndDate] = useState<Date | undefined>();
    const [launchDate, setLaunchDate] = useState<Date | undefined>();
    const [kickOffDate, setKickOffDate] = useState<Date | undefined>();
    const [confirmDate, setConfirmDate] = useState<Date | undefined>();
    const [actualDate, setActualDate] = useState<Date | undefined>();
    const [maintenanceDate, setMaintenanceDate] = useState<Date | undefined>();
    const [totalAmount, setTotalAmount] = useState<string>("");
    const [paidAmountData, setpaidAmountData] = useState<number>(0);
    const [paymentTimes, setPaymentTimes] = useState<number>(0);
    const [selectedMember, setSelectedMember] = useState<string>("");
    const [teamLead, setTeamLead] = useState<any>("");
    const [projectManager, setProjectManager] = useState<any>("");
    const [saveProject] = useSaveNewUpdatedProjectMutation();
    const [updatedProject] = useNewUpdatedProjectMutation();

    const handlePaymentValueChange = (id: number, value: number) => {
      setPaymentList((prev: any[]) => {
        return prev.map((data: any) => {
          if (data.project_status_id === id) {
            return { ...data, amount: value };
          }
          return data;
        });
      });
    };
    const handleCheckPaymentDone = (id: number) => {
      const paymentListClone = [...paymentList];
      const filterPayment = paymentListClone.map((data: any) => {
        if (data.project_status_id === id) {
          return { ...data, isCompleted: !data.isCompleted };
        }
        return data;
      });
      const isDoneFilter = filterPayment?.filter((p: any) => p.isCompleted);

      setPaymentTimes(isDoneFilter?.length);
      setPaymentList([...filterPayment]);
    };
    const handlePaymentChange = (id: number) => {
      setPaymentStage((prev: any[]) => {
        return prev.map((data: any) => {
          if (data.id === id) {
            const hasData = paymentList.find(
              (d: any) => d.project_status_id === id
            );
            if (!hasData) {
              setPaymentList([
                ...paymentList,
                {
                  project_status_id: data.id,
                  name: data.name,
                  amount: "",
                  isCompleted: false,
                },
              ]);
            } else {
              setPaymentList((prev: any) => {
                return prev.filter(
                  (d: any) => d.project_status_id !== hasData.project_status_id
                );
              });
            }
            return { ...data, isPaymentCheck: !data.isPaymentCheck };
          }
          return data;
        });
      });
    };

    const handleProjectStatusChanage = (id: number) => {
      setProjectStageList((prev: any[]) => {
        return prev.map((data: any) => {
          if (data.id === id) {
            const hasData = paymentStage.find((d: any) => d.id === id);
            if (!hasData) {
              setPaymentStage([
                ...paymentStage,
                { id: data.id, name: data.name, isPaymentCheck: false },
              ]);
            } else {
              setPaymentStage((prev: any) => {
                return prev.filter((d: any) => d.id !== hasData.id);
              });
            }
            return { ...data, check: !data.check };
          }
          return data;
        });
      });
    };

    const clearData = () => {
      // setProjectStageList([]);
      setPaymentStage([]);
      setPaymentList([]);
      setAssignToList([]);
      setErrorPrimary(false);
      setCurrencyLabel("HK");
      setActualDate(undefined);
      brandValueInput.setValue("");
      projectComplexityInput.setValue("");
      projectNameInput.setValue("");
      projectCodeInput.setValue("");
      companyNameInput.setValue("");
      primaryEmailInput.setValue("");
      primaryContactInput.setValue("");
      secondaryContactInput.setValue("");
      secondaryEmailInput.setValue("");
      financialInput.setValue("");
      textAreaInput.setValue("");
      projectStatusAreaInput.setValue("");
      projectScopeInput.setValue("");
      quotationInput.setValue("");
      remarkInput.setValue("");
      developmentUrlInput.setValue("");
      uatUrlInput.setValue("");
      liveUrlInput.setValue("");
      setPrimaryCode("+852");
      setPrimaryContactPhone("");
      setSecondaryPersonCode("+852");
      setSecondaryPersonPhone("");
      // setSecondaryPersonEmail("");
      setTeamLead("");
      setProjectManager("");
      setRegionData("");
      setChosenTags([]);
      setCurrencyData(0);
      setTotalAmount("");
      setPaymentTermData("");
      setConfirmDate(undefined);
      setMaintenanceDate(undefined);
      setKickOffDate(undefined);
      // setEndDate(undefined);
      setKeyDateList([]); //keyDateObj
      setCheckList([]); //reminderCheck
      setLaunchDate(undefined);
      setBrandLevel("");
      setPComplexity("");
      setCurrentStatus("");
    };

    const handleAddAssignTo = () => {
      setAssignToList((prev) => [
        ...prev,
        {
          id: Math.random() * 100,
          employee_id: 0,
          roles: [],
        },
      ]);
    };

    const handleAssignDelete = (id: number) => {
      setAssignToList((prev) => {
        return prev.filter((assign) => {
          if (assign.id !== id) {
            return assign;
          }
        });
      });
    };
    const handleAddNewCheckList = () => {
      setCheckList([
        ...checkList,
        { id: Math.random() * 100, is_checked: true, label: "" },
      ]);
    };
    const handleAddNewKeyList = () => {
      setKeyDateList([
        ...keyDateList,
        {
          id: Math.random() * 100,
          key_id: 0,
          key_date: undefined,
          // key_date: renderDateFormatYMD(new Date(), "-"),
        },
      ]);
    };

    const handleKeyDelete = (id: number) => {
      const filter = keyDateList.filter((key: any) => key.id !== id);
      setKeyDateList(filter);
    };

    const handleCheckDelete = (id: number) => {
      const filter = checkList.filter((key: any) => key.id !== id);
      setCheckList(filter);
    };

    const handleClickTag = (id: any) => {
      setTagsData(id);
    };

    const validateEmail = (email: any) => {
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      return emailRegex.test(email);
    };

    const checkValidation = (saveData: any) => {
      const primaryEmail = !validateEmail(
        saveData.primary_contact_person_email
      );
      if (primaryEmail) {
        setErrorPrimary(true);
      } else {
        setErrorPrimary(false);
      }

      if (
        isNotEmpty(saveData.project_name) ||
        isNotEmpty(saveData.company_name) ||
        isNotEmpty(saveData.primary_contact_person_name) ||
        isNotEmpty(saveData.primaryCode) ||
        isNotEmpty(saveData.primary_contact_person_phone) ||
        !validateEmail(saveData.primary_contact_person_email) ||
        isNotZero(saveData.brand_level) ||
        isNotZero(saveData.project_level) ||
        isNotZero(saveData.region_id) ||
        isNaN(Number(saveData.region_id)) ||
        saveData.project_type_ids.length === 0 ||
        saveData.kickoff_date === null ||
        saveData.target_launch_date === null ||
        isNotEmpty(saveData.team_leader_id) ||
        isNotZero(saveData.team_leader_id) ||
        isNotEmpty(saveData.project_manager_id) ||
        isNotZero(saveData.project_manager_id) ||
        isNotZero(saveData.total_amount) ||
        isNotZero(saveData.payment_term) ||
        isNotZero(saveData.project_stages.length)
        // isNotZero(saveData.payment_stages.length)
      ) {
        if (
          isNotEmpty(saveData.project_name) ||
          isNotEmpty(saveData.company_name) ||
          isNotEmpty(saveData.primary_contact_person_name) ||
          isNotEmpty(saveData.primary_contact_person_phone) ||
          isNotEmpty(saveData.primaryCode) ||
          isNotEmpty(saveData.primaryContactPhone) ||
          !validateEmail(saveData.primary_contact_person_email) ||
          isNotZero(saveData.brand_level) ||
          isNotZero(saveData.project_level)
        ) {
          setTagsData(1);
        } else if (
          isNotZero(saveData.region_id) ||
          isNaN(Number(saveData.region_id)) ||
          saveData.project_type_ids.length === 0 ||
          saveData.kickoff_date === null ||
          saveData.target_launch_date === null
        ) {
          setTagsData(2);
        } else if (
          isNotEmpty(saveData.team_leader_id) ||
          isNotZero(saveData.team_leader_id) ||
          isNotEmpty(saveData.project_manager_id) ||
          isNotZero(saveData.project_manager_id)
        ) {
          setTagsData(3);
        } else if (
          isNotZero(saveData.total_amount) ||
          isNotZero(saveData.payment_term) ||
          saveData.project_stages.length === 0
          // saveData.payment_stages.length === 0
        ) {
          setTagsData(4);
        }
        return true;
      } else {
        return false;
      }
    };

    const [widthBrand, setWidthBrand] = useState<number>(0);
    const [widthComplexity, setWidthComplexity] = useState<number>(0);
    const [colorBrandNo, setColorBrandNo] = useState<number>(0);
    const [colorProjectNo, setColorProjectNo] = useState<number>(0);
    const [brandText, setBrandText] = useState<string>("");
    const [complexityText, setComplexityText] = useState<string>("");

    useEffect(() => {
      setColorBrandNo(Number(brnadLevel));
      setWidthBrand(Number(brnadLevel) / 2);
      if (Number(brnadLevel) <= 10) {
        setBrandText("Low: Small Brand");
      } else if (Number(brnadLevel) >= 11 && Number(brnadLevel) <= 20) {
        setBrandText("Standard: Emerging Brand");
      } else if (Number(brnadLevel) >= 21 && Number(brnadLevel) <= 50) {
        setBrandText("Medium: Locally Popular");
      } else if (Number(brnadLevel) >= 51 && Number(brnadLevel) <= 80) {
        setBrandText("High: Nationally Popular");
      } else if (Number(brnadLevel) >= 81 && Number(brnadLevel) <= 99) {
        setBrandText("Highest: Globally Popular");
      }
    }, [brnadLevel]);

    useEffect(() => {
      setWidthComplexity(Number(pComplexity) / 20);
      setColorProjectNo(Number(pComplexity));
      if (Number(pComplexity) > 0 && Number(pComplexity) <= 199) {
        setComplexityText("Level 1: Basic Complexity");
      } else if (Number(pComplexity) >= 200 && Number(pComplexity) <= 399) {
        setComplexityText("Level 2: Standard Complexity");
      } else if (Number(pComplexity) >= 400 && Number(pComplexity) <= 599) {
        setComplexityText("Level 3: Moderate Complexity");
      } else if (Number(pComplexity) >= 600 && Number(pComplexity) <= 799) {
        setComplexityText("Level 4: Advanced Complexity");
      } else if (Number(pComplexity) >= 800 && Number(pComplexity) <= 999) {
        setComplexityText("Level 5: High Complexity");
      }
    }, [pComplexity]);

    useEffect(() => {
      const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
      const firstDate: any = kickOffDate ? new Date(kickOffDate) : new Date();
      const secondDate: any = launchDate ? new Date(launchDate) : new Date();
      const diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay));
      setDateDiff(diffDays);
    }, [launchDate, kickOffDate]);

    const handleSubmit = async () => {
      setIsSubmit(true);
      setUploading(true);

      let dateConfirm =
        confirmDate !== undefined
          ? renderDateFormatYMD(new Date(confirmDate), "-")
          : null;

      let actualDateData =
        actualDate !== undefined
          ? renderDateFormatYMD(new Date(actualDate), "-")
          : null;
      let maintenanceDateData =
        maintenanceDate !== undefined
          ? renderDateFormatYMD(new Date(maintenanceDate), "-")
          : null;

      let dateStart =
        kickOffDate !== undefined
          ? renderDateFormatYMD(new Date(kickOffDate), "-")
          : null;

      let launchDateData =
        launchDate !== undefined
          ? renderDateFormatYMD(new Date(launchDate), "-")
          : null;

      const keyDateData = keyDateList?.map((key: any) => {
        return {
          key_id: key.key_id,
          key_date:
            key.key_date !== undefined
              ? renderDateFormatYMD(new Date(key.key_date), "-")
              : null,
        };
      });

      const checkListData = checkList.map((check: any) => ({
        id: check.id,
        is_checked: check.is_checked ? 1 : 0,
        label: check.label,
      }));
      let testType: any = [];
      techList?.map(function (item: any) {
        chosenTags?.map((vtag: any, id: any) => {
          if (item.label == vtag) {
            testType.push(item.id);
          }
        });
      });

      let dataProject: any = [];
      pojectStageList?.forEach((data) => {
        if (data.check === true) {
          dataProject.push(data.id);
        }
      });
      let dataPayment: any = [];
      paymentStage?.forEach((data) => {
        if (data.isPaymentCheck === true) {
          dataPayment.push(data.id);
        }
      });
      const dataPaymentDone = paymentList?.map((d: any) => ({
        project_status_id: d.project_status_id,
        name: d.name,
        amount: d.amount,
        is_completed: d.isCompleted ? 1 : 0,
      }));

      const saveData = {
        project_name: projectNameInput.value,
        company_name: companyNameInput.value,
        primary_contact_person_name: primaryContactInput.value,
        primary_contact_person_email: primaryEmailInput.value,
        primary_contact_person_dial_code: primaryCode,
        primary_contact_person_phone: primaryContactPhone,
        secondary_contact_person_name: secondaryContactInput.value ?? "",
        secondary_contact_person_email: secondaryEmailInput.value ?? "",
        secondary_contact_person_dial_code: secondaryPersonCode ?? "",
        secondary_contact_person_phone: secondaryPersonPhone ?? "",
        brand_level: Number(brnadLevel),
        project_level: Number(pComplexity),
        region_id: Number(regionData),
        project_type_ids: testType,
        confirm_date: dateConfirm,
        kickoff_date: dateStart,
        target_launch_date: launchDateData,
        current_working_status: projectStatus,
        current_working_status_remark: projectStatusAreaInput.value,
        scope: projectScopeInput.value,
        remark: remarkInput.value,
        team_leader_id: teamLead ? Number(teamLead) : 0,
        project_manager_id: projectManager ? Number(projectManager) : 0,
        assign_to: assignToList?.map((assign) => ({
          employee_id: assign.employee_id,
          roles: assign?.roles.map((d: any) => d.value),
        })),
        current_stage: Number(currentStatus),
        quotation_no: quotationInput.value,
        currency_id: currencyData,
        total_amount: Number(totalAmount ?? 0),
        payment_term: Number(paymentTermData ?? 0),
        project_stages: dataProject,
        payment_stages: dataPayment,
        payments: dataPaymentDone,
        actual_launch_date: actualDateData,
        maintenance_enddate: maintenanceDateData,
        check_lists: checkListData,
        development_url: developmentUrlInput.value,
        live_url: liveUrlInput.value,
        key_dates: keyDateData,
        duration: Number(dateDiff),
      };

      const check = checkValidation(saveData);
      // console.log("che", check);
      // setIsSubmit(false);
      // setUploading(false);

      // console.log("saveData", saveData);

      if (!check) {
        if (editedData) {
          // console.log("updated is data");
          updatedProject({ ...saveData, id: editedData?.uniqeID })
            .unwrap()
            .then((payload: any) => {
              setErrorMsg("");
              setUploading(false);
              setEditData("");
              setIsSubmit(false);
              // console.log("updated", payload);
              setIsSubmit(false);
              setIsOpen(false);
              clearData();
              ResuableToasty({
                message: "Successfully Updated",
                position: "top-right",
                type: "success",
              });
            })
            .catch((error: any) => {
              // console.log("error", error);
              setIsSubmit(false);
              setUploading(false);
              setErrorMsg(error?.data?.message);
            });
        } else {
          saveProject(saveData)
            .unwrap()
            .then((payload: any) => {
              // console.log("p", payload);
              setEditData("");
              setErrorMsg("");
              setUploading(false);
              setIsSubmit(false);
              setIsOpen(false);
              clearData();
              ResuableToasty({
                message: "Successfully Updated",
                position: "top-right",
                type: "success",
              });
            })
            .catch((error: any) => {
              setIsSubmit(false);
              setUploading(false);
              setErrorMsg(error?.data?.message);
            });
        }
        setTagsData(1);
      } else {
        setUploading(false);
      }
    };

    useEffect(() => {
      if (paymentList.length) {
        const totalPay = paymentList.filter((d: any) => {
          if (d.isCompleted) {
            return d.amount;
          }
        });

        const lastPayData = totalPay.reduce((accumulator, object) => {
          return accumulator + Number(object.amount);
        }, 0);

        setPaymentTimes(totalPay.length);
        setpaidAmountData(lastPayData);
      }
    }, [paymentList]);

    useEffect(() => {
      if (editedData) {
        setCurrentStatus(String(editedData?.currentStage));
        projectStatusAreaInput.setValue(editedData?.currentWorkingStatusRemark);
        projectNameInput.setValue(editedData?.projectName);
        projectCodeInput.setValue(editedData?.projectCode);
        companyNameInput.setValue(editedData?.companyName);
        primaryEmailInput.setValue(editedData?.email);
        primaryContactInput.setValue(editedData?.contactPerson);
        secondaryContactInput.setValue(editedData?.secondaryContactPersonName);
        secondaryEmailInput.setValue(editedData?.secondaryContactPersonEmail);
        if (editedData?.primaryContactPersonDialCode) {
          setPrimaryCode(editedData?.primaryContactPersonDialCode);
        } else {
          setPrimaryCode("852");
        }

        setPrimaryContactPhone(editedData?.primaryContactPersonPhone);
        setActualDate(
          editedData?.launchDate ? new Date(editedData?.launchDate) : undefined
        );
        setMaintenanceDate(
          editedData?.maintenanceEndDate
            ? new Date(editedData?.maintenanceEndDate)
            : undefined
        );

        if (editedData?.secondaryContactPersonDialCode) {
          setSecondaryPersonCode(editedData?.secondaryContactPersonDialCode);
        } else {
          setSecondaryPersonCode("852");
        }
        setSecondaryPersonPhone(editedData?.secondaryContactPersonPhone);
        financialInput.setValue(editedData?.financialContactPerson);

        if (editedData?.payments) {
          const data = editedData?.payments?.map((d: any) => ({
            ...d,
            isCompleted: d.is_completed ? true : false,
          }));
          setPaymentList([...data]);

          const editPayment = editedData?.payments.filter((d: any) => {
            if (d.is_completed) {
              return d.amount;
            }
          });

          const lastPayData = editPayment?.reduce(
            (accumulator: any, object: any) => {
              return accumulator + Number(object.amount);
            },
            0
          );

          setPaymentTimes(editPayment.length);
          setpaidAmountData(lastPayData);
        }

        const projectStagesUpdated = projectStatusList?.map((item1) => {
          const found = editedData?.projectStages?.find(
            (item2: any) => item2 == item1.id
          );
          if (found) {
            return { ...item1, check: true };
          }
          return item1;
        });
        setProjectStageList([...projectStagesUpdated]);
        if (editedData.paymentStages) {
          const paymentt = editedData.paymentStages?.map((d: any) => ({
            ...d,
            isPaymentCheck: d.is_payment_checked ? true : false,
          }));
          setPaymentStage([...paymentt]);
        }

        setTeamLead(editedData?.teamLeader?.id);
        setProjectManager(editedData?.projectManager?.id);
        setRegionData(String(editedData?.region?.id));
        projectScopeInput.setValue(editedData?.projectScope);
        setProjectStatus(editedData?.currentWorkingStatus);
        quotationInput.setValue(editedData?.quotationNumber);

        setPaymentTermData(String(editedData?.paymentTerm));
        setConfirmDate(
          editedData?.confirmOn ? new Date(editedData?.confirmOn) : undefined
        );
        setKickOffDate(
          editedData?.kickOffDate
            ? new Date(editedData?.kickOffDate)
            : undefined
        );
        // setEndDate(
        //   editedData?.endDate ? new Date(editedData?.endDate) : undefined
        // );
        remarkInput.setValue(editedData?.remarks);
        if (editedData?.keyDate?.length) {
          const keys = editedData?.keyDate?.map((k: any) => {
            return {
              id: k.id,
              key_id: k.id,
              label: k.label,
              key_date: k.date ? new Date(k.date) : new Date(),
            };
          });
          setKeyDateList(keys);
        }

        if (editedData?.checkList?.length) {
          const check = editedData?.checkList?.map((k: any) => {
            return {
              id: k.id,
              label: k.label,
              is_checked: k.isChecked,
            };
          });
          setCheckList(check);
        }

        if (editedData?.assignTo) {
          const data = editedData?.assignTo?.map((d: any) => ({
            id: Math.random() * 100,
            employee_id: d.employee_id,
            roles: d.roles.map((d: any) => ({ value: d.id, label: d.name })),
          }));
          setAssignToList([...data]);
        }

        if (editedData?.projectTypes?.length) {
          const tech = editedData?.projectTypes?.map((t: any) => t.name);
          setChosenTags(tech);
        }
        setBrandLevel(String(editedData?.brandLevel));
        setPComplexity(String(editedData?.projectLevel));

        if (editedData?.paidAmount) {
          const data = editedData?.paidAmount?.paymentValue?.map((d: any) => {
            return {
              id: d.id,
              uniqueId: Math.random() * 100,
              project_status_id: d.project_status_id,
              amount: d.amount,
              payment_due_date:
                d?.payment_due_date === null
                  ? undefined
                  : new Date(d?.payment_due_date),
              payment_received_date:
                d?.payment_received_date === null
                  ? undefined
                  : new Date(d?.payment_received_date),
              invoice_no: d.invoice_no,
              payment_received_no: d.payment_received_no,
            };
          });
        }

        setTotalAmount(String(editedData?.projectAmount));

        setCurrencyData(Number(editedData?.currency?.uniqeID));
        setCurrencyLabel(String(editedData?.currencyCode));
        setLaunchDate(
          editedData?.targetLaunchDate
            ? new Date(editedData?.targetLaunchDate)
            : undefined
        );
        developmentUrlInput.setValue(editedData?.developmentUrl);
        uatUrlInput.setValue(editedData?.uat_url);
        liveUrlInput.setValue(editedData?.liveUrl);
      }
    }, [editedData]);

    useEffect(() => {
      if (!editedData) {
        setProjectStageList(
          projectStatusList?.map((status: any) => ({
            check: false,
            name: status.name,
            id: status.id,
          }))
        );
      }
    }, [projectStatusList, editedData]);

    useEffect(() => {
      setTechList(
        projectTypesList.map((p: any) => ({ id: p.id, label: p.name }))
      );
    }, [projectTypesList]);


    return (
      <Modal
        className="project_popup"
        open={isOpen}
        style={{
          background: "rgb(0 0 0 / 0.5)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        onClose={(event, reason) => {
          if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
            setIsOpen(false);
            setEditData("");
            setIsSubmit(false);
          }
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Fade in={isOpen} timeout={500}>
          <div className="max-w-[1100px]  w-full m-auto">
            <div className="modalContent  w-full h-full p-6 lg:p-0 flex justify-center items-center relative z-[999]">
              <div className="rounded-xl bg-white font-primary w-full">
                <div className="px-2 sm:pl-4 sm:pr-4 py-3 bg-vorpblue flex justify-between rounded-t-lg items-center relative z-[999]">
                  <h3 className="text-18 leading-[27px] font-semibold text-white">
                    {editedData ? "Update Project" : "Add New Project"}
                  </h3>
                  <button
                    key={Math.random() * 100}
                    type="button"
                    aria-label="Close Modal"
                    onClick={() => {
                      setIsOpen(false);
                      setIsSubmit(false);
                      setEditData("");
                      setUploading(false);
                      setErrorMsg("");
                      clearData();
                      setTagsData(1);
                    }}
                  >
                    
                    <CloseIcon />
                  </button>
                </div>
                <div className="pb-3 flex justify-start items-center">
                  <div className="flex flex-wrap">
                    <div className="flex px-5 justify-center items-center min-h-[20px] pt-5">
                      {tagsList.map((tag: any, i: number) => (
                        <div key={i} className="flex">
                          <button
                            className={classNames(
                              tagsData == tag.id
                                ? "border-b-[2px] pb-1 border-b-vorpblue text-vorpblue "
                                : "border-b-[2px] pb-1 border-b-white text-vorpgraylight ",
                              " text-[14px] font-semibold leading-3 not-italic"
                            )}
                            key={i}
                            onClick={() => {
                              handleClickTag(tag?.id);
                            }}
                          >
                            {tag.name}
                          </button>
                          <div
                            className={classNames(
                              tagsList.length - 1 === i ? "hidden" : "",
                              "px-2"
                            )}
                          >
                            <div className="h-5 w-[1px] bg-vorpgraylight mx-1 "></div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                {tagsData === 1 ? (
                  <div className="px-2 py-3 min-h-[400px]  max-h-[410px] overflow-y-auto custom_scrollbar_popup mr-0">
                    <div className="flex flex-col items-center justify-between">
                      <div className="flex mb-4 justify-between w-full ">
                        <ResuableInput
                          errorMessage="Project is required"
                          label="Project Name"
                          className="w-full px-3"
                          isRequired={true}
                          isSubmit={isSubmit}
                          type="text"
                          placeHolder="Enter Project Name (eg: Visible One)"
                          {...projectNameInput}
                        />
                        <ResuableInput
                          errorMessage="Company Name is required"
                          label="Company Name"
                          className="w-full px-3"
                          isRequired={true}
                          isSubmit={isSubmit}
                          type="text"
                          placeHolder="Enter Company Name (eg: Visible One Limited)"
                          {...companyNameInput}
                        />
                      </div>
                      <div className="flex mb-0 justify-between w-full ">
                        <ResuableInput
                          errorMessage="Contact person is required"
                          label="Contact Person"
                          className="w-full px-3"
                          isSubmit={isSubmit}
                          isRequired={true}
                          type="text"
                          placeHolder="Contact Person1 *"
                          {...primaryContactInput}
                        />
                        <ResuableInput
                          isLabel={true}
                          errorMessage="Email is required"
                          isRequired={true}
                          className="w-full px-3"
                          mailError={errorPrimary}
                          isSubmit={isSubmit}
                          type="email"
                          placeHolder="Email *"
                          {...primaryEmailInput}
                        />
                        <div className="mb-0 w-full px-3">
                          <label className="text-black2 text-15 font-medium font-primary mb-[0rem] block"></label>
                          <div className="flex flex-col w-full">
                            <label htmlFor="" className="min-h-[30px]"></label>
                            <div className="flex flex-row w-full items-center">
                              <div className="flex items-center justify-center text-center mr-3">
                                <PhoneInput
                                  country={"hk"}
                                  value={primaryCode}
                                  onChange={setPrimaryCode}
                                  //  onChange={(e) => {
                                  //   setPrimaryCode(e);
                                  //   setIp(e.target.value);
                                  // }}
                                  containerClass={styles.container_class}
                                  inputClass={styles.input_class}
                                  dropdownClass={styles.dropdown_class}
                                  countryCodeEditable={false}
                                  // disableCountryCode={true}
                                />
                              </div>
                              <div className="flex flex-col w-full">
                                <input
                                  onKeyDown={(evt) =>
                                    ["e", "E", "+", "-"].includes(evt.key) &&
                                    evt.preventDefault()
                                  }
                                  onWheel={(e: any) => e.target.blur()}
                                  value={primaryContactPhone}
                                  onChange={(e) =>
                                    setPrimaryContactPhone(e.target.value)
                                  }
                                  type="number"
                                  placeholder="Number"
                                  className="focus:outline-none h-[48px] focus-visible:outline-none w-full rounded py-3.5 px-3 border border-goalinputborder text-black2 text-14 font-medium font-primary placeholder:text-graydark"
                                ></input>
                                <></>
                              </div>
                            </div>
                            <p
                              className={classNames(
                                isSubmit && primaryContactPhone === ""
                                  ? ""
                                  : "hidden",
                                " font-poppins text-[12px] text-redPink"
                              )}
                            >
                              Primary Number required
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="flex mb-4 justify-between items-end w-full ">
                        <ResuableInput
                          className="w-full px-3 mb-0"
                          isRequired={false}
                          isSubmit={isSubmit}
                          type="text"
                          placeHolder="Contact Person2"
                          {...secondaryContactInput}
                        />
                        <ResuableInput
                          className="w-full px-3"
                          mailError={errorPrimary}
                          isSubmit={isSubmit}
                          type="text"
                          placeHolder="Email"
                          {...secondaryEmailInput}
                        />
                        <div className="mb-0 last:mb-0 w-full px-3">
                          <div className="flex flex-col w-full">
                            <div className="flex flex-row w-full">
                              <div className="flex items-center justify-center text-center mr-3">
                                <PhoneInput
                                  country={"hk"}
                                  value={secondaryPersonCode}
                                  onChange={setSecondaryPersonCode}
                                  containerClass={styles.container_class}
                                  inputClass={styles.input_class}
                                  dropdownClass={styles.dropdown_class}
                                  // disableCountryCode={true}
                                  countryCodeEditable={false}
                                />
                              </div>
                              <input
                                onKeyDown={(evt) =>
                                  ["e", "E", "+", "-"].includes(evt.key) &&
                                  evt.preventDefault()
                                }
                                onWheel={(e: any) => e.target.blur()}
                                value={secondaryPersonPhone}
                                onChange={(e) =>
                                  setSecondaryPersonPhone(e.target.value)
                                }
                                type="number"
                                placeholder="Number"
                                className="focus:outline-none focus-visible:outline-none w-full rounded py-3 px-3 border border-goalinputborder text-black2 text-14 font-medium font-primary placeholder:text-graydark h-[45px]"
                              ></input>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="flex mb-4 justify-between w-full ">
                        <ResuableInput
                          label="Project Scope"
                          className="w-full px-3 placeholder:text-graydark"
                          textarea={true}
                          type="text"
                          placeHolder="Write your message here..."
                          {...projectScopeInput}
                        />
                      </div>

                      <div className="flex mb-1 justify-between w-full ">
                        <div className="w-full px-3">
                          <label className="text-black2 pb-2 text-14 font-medium font-primary mb-[0rem] block">
                            Brand Value <span className=" text-redPink">*</span>
                          </label>
                          <div>
                            <input
                              max={100}
                              value={brnadLevel ?? ""}
                              maxLength={3}
                              onKeyDown={(evt) =>
                                ["e", "E", "+", "-"].includes(evt.key) &&
                                evt.preventDefault()
                              }
                              onChange={(event: any) => {
                                if (event.target.value > 99) {
                                  event.preventDefault();
                                } else {
                                  setBrandLevel(event.target.value);
                                }
                              }}
                              type="number"
                              placeholder="2 digit numbers from 01 to 99 to indicate brand value"
                              className="focus:outline-none mb-0 focus-visible:outline-none w-full rounded py-3.5 px-3 border border-goalinputborder text-black2 text-14 font-medium font-primary placeholder:text-[#858795] h-[48px]"
                            />
                            <p
                              className={classNames(
                                isSubmit && brnadLevel === ""
                                  ? ""
                                  : " opacity-0",
                                " font-poppins text-[12px] text-redPink"
                              )}
                            >
                              Brand Value is required
                            </p>
                          </div>
                        </div>
                        <div className="w-full px-3">
                          <label className="text-black2 pb-2 text-14 font-medium font-primary mb-[0rem] block">
                            Project Complexity{" "}
                            <span className=" text-redPink">*</span>
                          </label>
                          <div>
                            <input
                              max={1000}
                              value={pComplexity ?? ""}
                              maxLength={3}
                              onKeyDown={(evt) =>
                                ["e", "E", "+", "-"].includes(evt.key) &&
                                evt.preventDefault()
                              }
                              onChange={(event: any) => {
                                if (event.target.value > 999) {
                                  event.preventDefault();
                                } else {
                                  setPComplexity(event.target.value);
                                }
                              }}
                              type="number"
                              placeholder="3 digit numbers from 001 to 999 to indicate complexity"
                              className="focus:outline-none mb-0 focus-visible:outline-none w-full rounded py-3.5 px-3 border border-goalinputborder text-black2 text-14 font-medium font-primary placeholder:text-[#858795] h-[48px]"
                            />
                            <p
                              className={classNames(
                                isSubmit && pComplexity === ""
                                  ? ""
                                  : " opacity-0",
                                " font-poppins text-[12px] text-redPink"
                              )}
                            >
                              Project Complexity is required
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="flex mb-1 justify-between w-full ">
                        <div className="w-full flex justify-between items-center px-3">
                          <div
                            style={{
                              width: `${widthBrand}%`,
                            }}
                          >
                            <div
                              className={classNames(
                                Number(colorBrandNo) > 0 &&
                                  Number(colorBrandNo) <= 10 &&
                                  "bg-[#D62121]",
                                Number(colorBrandNo) >= 11 &&
                                  Number(colorBrandNo) < 20 &&
                                  "bg-[#FB8A38]",
                                Number(colorBrandNo) >= 21 &&
                                  Number(colorBrandNo) <= 50 &&
                                  "bg-[#FFBB20]",
                                Number(colorBrandNo) >= 51 &&
                                  Number(colorBrandNo) < 80 &&
                                  "bg-[#F5DF11]",
                                Number(colorBrandNo) >= 81 &&
                                  Number(colorBrandNo) <= 99 &&
                                  " bg-[#19CDA2]",
                                "w-full h-1 max-h-1"
                              )}
                            ></div>
                          </div>
                          {brnadLevel.length ? (
                            <div>
                              <span className="text-graydark text-sm font-medium font-primary mb-[0rem]">
                                {brandText}
                              </span>
                            </div>
                          ) : null}
                        </div>
                        <div className="w-full flex justify-between items-center px-3">
                          <div
                            style={{
                              width: `${widthComplexity}%`,
                            }}
                          >
                            <div
                              className={classNames(
                                Number(colorProjectNo) >= 800 &&
                                  Number(colorProjectNo) <= 999 &&
                                  "bg-[#D62121]",
                                Number(colorProjectNo) >= 600 &&
                                  Number(colorProjectNo) <= 799 &&
                                  "bg-[#FB8A38]",
                                Number(colorProjectNo) >= 400 &&
                                  Number(colorProjectNo) <= 599 &&
                                  "bg-[#FFBB20]",
                                Number(colorProjectNo) >= 200 &&
                                  Number(colorProjectNo) <= 399 &&
                                  "bg-[#F5DF11]",
                                Number(colorProjectNo) > 0 &&
                                  Number(colorProjectNo) <= 199 &&
                                  " bg-[#19CDA2]",
                                "w-full h-1 max-h-1"
                              )}
                            ></div>
                          </div>
                          {pComplexity.length ? (
                            <div>
                              <span className="text-graydark text-sm font-medium font-primary mb-[0rem]">
                                {complexityText}
                              </span>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}

                {tagsData === 2 ? (
                  <div className="px-2 py-3 min-h-[400px] max-h-[410px] overflow-y-auto custom_scrollbar_popup mr-0">
                    <div className="flex mb-0 items-center justify-between w-full ">
                      <div className=" px-3 w-full">
                        <label className="text-black2 pb-2 text-14 font-medium font-primary mb-[0rem] block">
                          Tech Type <span className=" text-red-500">*</span>
                        </label>

                        <CommonTagDropdown
                          setTechList={setTechList}
                          techList={techList}
                          createData={createProjectTech}
                          isProject={true}
                          showAll={true}
                          state={chosenTags}
                          setState={setChosenTags}
                          options={techList ? techList : []}
                          className="py-3 border-goalinputborder 6xs:text-goalinputborder text-14 h-[48px]"
                        />
                        <p
                          className={classNames(
                            isSubmit && chosenTags.length === 0
                              ? ""
                              : "opacity-0",
                            " font-poppins text-[12px] text-redPink"
                          )}
                        >
                          Tech Type is required
                        </p>
                      </div>
                      <div className="w-full px-3 ">
                        <label className="text-black2 pb-2 text-15 font-medium font-primary mb-[0rem] block">
                          Region <span className=" text-red-500">*</span>
                        </label>
                        <div className="mb-0 ">
                          <StringDropdownList
                            state={regionData}
                            setState={setRegionData}
                            dropdownItems={countryList?.map(
                              (x: any, i: number) => {
                                return {
                                  label: x.name,
                                  value: x.id,
                                };
                              }
                            )}
                            className="w-full xl:text-14 text-12 py-[13px] border-goalinputborder"
                            placeholder="Select"
                            textAlign="text-left py-2 pl-2 pr-3"
                          />
                        </div>
                        <p
                          className={classNames(
                            isSubmit &&
                              (regionData === "" || isNaN(Number(regionData)))
                              ? ""
                              : "opacity-0",
                            " font-poppins text-[12px] text-redPink"
                          )}
                        >
                          Region required
                        </p>
                      </div>
                    </div>

                    <div className="flex mb-3 items-center w-full">
                      <div className=" px-3 w-full">
                        <label className="text-black2 pb-2 text-14 font-medium font-primary mb-[0rem] block">
                          Project Confirmation On
                        </label>
                        <div className="flex items-center">
                          {confirmDate && (
                            <button
                              type="button"
                              onClick={() => {
                                setConfirmDate(undefined);
                              }}
                              className="text-12 mr-2"
                            >
                              <CloseIcon
                                color="#EE436D"
                                width={10}
                                height={10}
                              />
                            </button>
                          )}

                          <div className="w-full">
                            <CustomDatePicker
                              isProjectCalendarIcon={true}
                              isPlaceHolder="Select Confirmation Date"
                              isProject={true}
                              placeholder={new Date().toDateString()}
                              date={confirmDate}
                              setDate={(value) => setConfirmDate(value)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="flex mb-0 items-end w-full">
                      <div className=" px-3 w-full">
                        <label className="text-black2 pb-2 text-14 font-medium font-primary mb-[0rem] block">
                          Project Duration{" "}
                          <span className=" text-red-500">*</span>{" "}
                          {dateDiff > 1
                            ? `${dateDiff} Days`
                            : `${dateDiff} Day`}
                        </label>
                        <div className="flex items-center">
                          {kickOffDate && (
                            <button
                              type="button"
                              onClick={() => {
                                setKickOffDate(undefined);
                              }}
                              className="text-12 mr-2 mb-3"
                            >
                              <CloseIcon
                                color="#EE436D"
                                width={10}
                                height={10}
                              />
                            </button>
                          )}

                          <div className="w-full flex flex-col">
                            <CustomDatePicker
                              isProjectCalendarIcon={true}
                              isPlaceHolder="Select Kickoff Date"
                              isProject={true}
                              placeholder={new Date().toDateString()}
                              date={kickOffDate}
                              // minData={new Date()}
                              setDate={(value) => setKickOffDate(value)}
                            />{" "}
                            <p
                              className={classNames(
                                isSubmit && kickOffDate === undefined
                                  ? ""
                                  : "opacity-0",
                                " font-poppins text-[12px] text-redPink"
                              )}
                            >
                              Kiffoff is required
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className=" px-3 w-full">
                        <div className="flex items-center">
                          {launchDate && (
                            <button
                              type="button"
                              onClick={() => {
                                setLaunchDate(undefined);
                              }}
                              className="text-12 mr-2 mb-3"
                            >
                              <CloseIcon
                                color="#EE436D"
                                width={10}
                                height={10}
                              />
                            </button>
                          )}

                          <div className="w-full flex flex-col">
                            <CustomDatePicker
                              isProjectCalendarIcon={true}
                              isPlaceHolder="Select Target Launch Date"
                              isProject={true}
                              placeholder={new Date().toDateString()}
                              date={launchDate}
                              // minData={new Date()}
                              setDate={(value) => setLaunchDate(value)}
                            />
                            <p
                              className={classNames(
                                isSubmit && launchDate === undefined
                                  ? ""
                                  : "opacity-0",
                                " font-poppins text-[12px] text-redPink"
                              )}
                            >
                              Kiffoff is required
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="flex mb-3 w-full flex-col">
                      <div className=" px-3 w-full">
                        <label className="text-black2 pb-2 text-14 font-medium font-primary mb-[0rem] block">
                          Project Status
                        </label>
                        <div className="flex items-center">
                          <BaseRadioButton
                            state={projectStatus}
                            setState={setProjectStatus}
                            label="NoStart"
                            value="NoStart"
                            id="NoStart"
                            className="mr-4 sm:mr-8 text-darkblack"
                            fontSize="text-14"
                            name="leave-duration"
                          />
                          <BaseRadioButton
                            state={projectStatus}
                            setState={setProjectStatus}
                            label="InProgress"
                            value="InProgress"
                            id="InProgress"
                            className="mr-4 sm:mr-8 text-darkblack"
                            fontSize="text-14"
                            name="leave-duration"
                          />
                          <BaseRadioButton
                            state={projectStatus}
                            setState={setProjectStatus}
                            label="OnHold"
                            value="OnHold"
                            id="OnHold"
                            className="mr-4 sm:mr-8 text-darkblack"
                            fontSize="text-14"
                            name="leave-duration"
                          />
                          <BaseRadioButton
                            state={projectStatus}
                            setState={setProjectStatus}
                            label="Completed"
                            value="Completed"
                            id="Completed"
                            className="mr-4 sm:mr-8 text-darkblack"
                            fontSize="text-14"
                            name="leave-duration"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="flex mb-3 justify-between w-full ">
                      <ResuableInput
                        className="w-full px-3"
                        textarea={true}
                        type="text"
                        placeHolder="Remark"
                        {...projectStatusAreaInput}
                      />
                    </div>
                  </div>
                ) : null}

                {tagsData === 3 ? (
                  <div className="px-2 py-3 min-h-[410px] max-h-[420px] overflow-y-auto custom_scrollbar_popup mr-0">
                    <div className="flex flex-col items-center justify-between">
                      <div className="flex mb-3 items-center justify-between w-full ">
                        <div className=" w-1/2 px-3 mb-0">
                          <label className="text-black2 pb-2 text-15 font-medium font-primary mb-[0rem] block">
                            Project Manager{" "}
                            <span className=" text-red-500">*</span>
                          </label>
                          <div className={classNames("mb-0")}>
                            <StringDropdownListWithSearch
                              isProject={true}
                              setSearchData={setSelectedMember}
                              state={projectManager}
                              setState={setProjectManager}
                              dropdownItems={memberDataList?.map(
                                (member: any) => {
                                  return {
                                    label: member.preferred_name,
                                    value: member.id,
                                  };
                                }
                              )}
                              className={classNames(
                                styles.memberDropdownList,
                                "w-full min-w-[100px] 2xl:min-w-[128px]  xl:text-sm text-xs"
                              )}
                              placeholder="Select Member"
                              textAlign="text-left py-2 pl-2 pr-3"
                            />
                          </div>
                          <p
                            className={classNames(
                              isSubmit &&
                                (projectManager === "" || isNaN(projectManager))
                                ? ""
                                : "hidden",
                              " font-poppins text-[12px] text-redPink"
                            )}
                          >
                            Project Manager is required
                          </p>
                        </div>

                        <div className="w-1/2 px-3 mb-0">
                          <label className="text-black2 pb-2 text-15 font-medium font-primary mb-[0rem] block">
                            Team Lead <span className=" text-red-500">*</span>
                          </label>
                          <div
                            className={classNames(styles.memberDropdownList)}
                          >
                            <StringDropdownListWithSearch
                              isProject={true}
                              setSearchData={setSelectedMember}
                              state={teamLead}
                              setState={setTeamLead}
                              dropdownItems={memberDataList?.map(
                                (member: any) => {
                                  return {
                                    label: member.preferred_name,
                                    value: member.id,
                                  };
                                }
                              )}
                              className={classNames(
                                styles.memberDropdownList,
                                "w-full min-w-[100px] 2xl:min-w-[128px]  xl:text-sm text-xs"
                              )}
                              placeholder="Select Member"
                              textAlign="text-left py-2 pl-2 pr-3"
                            />
                          </div>
                          <p
                            className={classNames(
                              isSubmit &&
                                (teamLead === "" ||
                                  teamLead === 0 ||
                                  isNaN(teamLead))
                                ? ""
                                : "hidden",
                              " font-poppins text-[12px] text-redPink"
                            )}
                          >
                            Team Lead is required
                          </p>
                        </div>
                      </div>
                      <div className="flex items-center pb-2 justify-between w-full px-3">
                        <label className="text-black2 pb-2 text-15 font-medium font-primary mb-[0rem] block">
                          Assign To:
                        </label>
                      </div>
                      <div className="flex px-3 flex-col w-full">
                        {assignToList?.map((assign: any, i: number) => (
                          <CreateProjectAssignTo
                            rowList={rowList}
                            key={i}
                            assign={assign}
                            deleteAssign={handleAssignDelete}
                            assignUserListData={assignUserListData}
                            setData={setAssignToList}
                          />
                        ))}
                        <div className="flex text-start px-3">
                          <Button
                            type="button"
                            label={
                              <span className="flex items-center text-12 leading-[18px]">
                                <AddIcon
                                  width={18}
                                  height={16}
                                  className="mr-[0px] transition-all duration-300"
                                />
                              </span>
                            }
                            onClick={handleAddAssignTo}
                            variant="no-outline"
                            size="lg"
                            customClass={classNames(
                              uploading || isError
                                ? " pointer-events-none opacity-50"
                                : "",
                              " py-1 lg:py-2 px-2 custom-button-size"
                            )}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}

                {tagsData === 4 ? (
                  <div className="px-2 py-3 min-h-[400px] max-h-[410px] overflow-y-auto custom_scrollbar_popup mr-0">
                    <div className="flex flex-col items-center justify-between">
                      <div className="flex items-center mb-4 w-full ">
                        <ResuableInput
                          label="Quotation No"
                          className="w-full px-3"
                          isRequired={false}
                          type="text"
                          placeHolder="Enter quotation No"
                          {...quotationInput}
                        />
                      </div>

                      <div className="flex items-center w-full mb-3">
                        <div className="w-full px-3 mb-0">
                          <label className="text-black2 text-14 pb-2 font-medium font-primary mb-[0rem] block">
                            Total Amount{" "}
                            <span className=" text-red-500">*</span>
                          </label>
                          <div className="flex justify-between items-baseline w-full">
                            <div className="flex w-1/2 items-center justify-center text-center mr-3">
                              <NumberDropdownList
                                isProject={true}
                                setCurrencyLabel={setCurrencyLabel}
                                state={currencyData}
                                setState={setCurrencyData}
                                dropdownItems={currencyLists?.map(
                                  (x: any, i: number) => {
                                    return {
                                      label: x.name,
                                      value: x.id,
                                      name: x.label,
                                    };
                                  }
                                )}
                                className="w-full"
                                textAlign="text-left pl-2 pr-3"
                                placeholder="Select Currency"
                                paddingClass="py-[12px]"
                              />
                            </div>
                            <div className="w-1/2">
                              <input
                                value={totalAmount}
                                onChange={(e) => setTotalAmount(e.target.value)}
                                type="number"
                                placeholder="Total  Amount"
                                className="focus:outline-none focus-visible:outline-none w-full rounded py-3.5 px-3 border border-vorpgraylight text-black2 text-14 font-medium font-primary placeholder:text-graydark h-[48px]"
                              ></input>
                              <p
                                className={classNames(
                                  isSubmit && totalAmount === ""
                                    ? ""
                                    : "opacity-0",
                                  " font-poppins text-[12px] text-redPink"
                                )}
                              >
                                Amount is required
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="w-full px-2 mb-0">
                          <label className="text-black2 text-14 pb-2 font-medium font-primary mb-[0rem] block">
                            Payment Terms{" "}
                            <span className=" text-red-500">*</span>
                          </label>
                          <div className="mb-0">
                            <StringDropdownList
                              state={paymentTermData}
                              setState={setPaymentTermData}
                              dropdownItems={paymentTermList?.map((x: any) => {
                                return {
                                  label: x.label,
                                  value: x.value,
                                };
                              })}
                              className={classNames(
                                "w-full xl:text-14 text-12 py-[13px] border-goalinputborder"
                              )}
                              placeholder="Select"
                              textAlign="text-left py-2 pl-2 pr-3"
                            />
                            <p
                              className={classNames(
                                isSubmit && paymentTermData === ""
                                  ? ""
                                  : "opacity-0",
                                " font-poppins text-[12px] text-redPink"
                              )}
                            >
                              Amount is required
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="flex w-full px-3">
                        <div className="flex flex-col   items-center w-full mb-2 ">
                          <div className="flex items-center w-full px-3 pb-2">
                            <label className="text-black2 pb-2 text-14 font-medium font-primary mb-[0rem] block">
                              Project Stages{" "}
                              <span className=" text-red-500">*</span>
                            </label>
                          </div>
                          {pojectStageList?.map((status: any, i: number) => (
                            <div
                              key={i}
                              className="flex items-center w-full mb-2 "
                            >
                              <div className="flex items-center w-full mb-2">
                                <label
                                  htmlFor={`project_status_${i}`}
                                  className={`flex items-center`}
                                >
                                  <input
                                    type="checkbox"
                                    id={`project_status_${i}`}
                                    className="hidden"
                                    onChange={() => {
                                      handleProjectStatusChanage(status?.id);
                                    }}
                                    checked={status?.check}
                                  />
                                  <div
                                    className={`w-6 h-6 rounded-4 flex justify-center items-center mr-3 ${
                                      status?.check
                                        ? "bg-checkItemColor"
                                        : "border border-[#DCE6F0] bg-disabledColor"
                                    }`}
                                  >
                                    {status?.check && (
                                      <SelectedIcon width={9} height={7} />
                                    )}
                                  </div>
                                </label>
                                <span className=" sm:text-12 md:text-14 text-black2">
                                  {status?.name}
                                </span>
                              </div>
                            </div>
                          ))}
                        </div>
                        <div className="flex flex-col items-center w-full mb-2 ">
                          <div className="flex items-center w-full px-3 pb-2">
                            <label className="text-black2 pb-2 text-14 font-medium font-primary mb-[0rem] block">
                              Payment Stages{" "}
                              <span className=" text-red-500">*</span>
                            </label>
                          </div>
                          {paymentStage.map((payment: any, i: number) => {
                            return (
                              <div
                                key={i}
                                className="flex items-center w-full mb-2 "
                              >
                                <div className="flex flex-col justify-between px-3">
                                  <div className="flex items-center w-full mb-2">
                                    <label
                                      htmlFor={`payment_${i}`}
                                      className={`flex items-center`}
                                    >
                                      <input
                                        type="checkbox"
                                        id={`payment_${i}`}
                                        className="hidden"
                                        onChange={() => {
                                          handlePaymentChange(payment.id);
                                        }}
                                        checked={payment?.isPaymentCheck}
                                      />
                                      <div
                                        className={`w-6 h-6 rounded-4 flex justify-center items-center mr-3 ${
                                          payment?.isPaymentCheck
                                            ? "bg-checkItemColor"
                                            : "border border-[#DCE6F0] bg-disabledColor"
                                        }`}
                                      >
                                        {payment?.isPaymentCheck && (
                                          <SelectedIcon width={9} height={7} />
                                        )}
                                      </div>
                                    </label>
                                    <span>{payment?.name}</span>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>

                      <div className="w-full px-3 ">
                        <label className="text-black2 pb-2 text-15 font-medium font-primary mb-[0rem] block">
                          Current Stage{" "}
                          {/* <span className=" text-red-500">*</span> */}
                        </label>
                        <div className="mb-0 ">
                          <StringDropdownList
                            state={currentStatus}
                            setState={setCurrentStatus}
                            dropdownItems={projectStatusList?.map(
                              (x: any, i: number) => {
                                return {
                                  label: x.name,
                                  value: x.id,
                                };
                              }
                            )}
                            className="w-full xl:text-14 text-12 py-[13px] border-goalinputborder"
                            placeholder="Select"
                            textAlign="text-left py-2 pl-2 pr-3"
                          />
                        </div>
                        <p
                          className={classNames(
                            isSubmit &&
                              (regionData === "" || isNaN(Number(regionData)))
                              ? ""
                              : "opacity-0",
                            " font-poppins text-[12px] text-redPink"
                          )}
                        >
                          Region required
                        </p>
                      </div>

                      <div className="flex justify-between w-full mb-4 ">
                        <div className="w-full px-3">
                          <label className="text-black2 pb-2 text-14 font-medium font-primary mb-[0rem] block">
                            Payment Progress
                          </label>
                          <div className="w-1/2">
                            <PaymentBar
                              {...{
                                currency: currencyLabel,
                                paymentTimes: paymentTimes,
                                paidAmountData: paidAmountData,
                                projectAmount: Number(totalAmount),
                              }}
                            />

                            {/* <p className="text-xs mt-1 text-gray-600">
                              {`${currencyLabel}$ ${paidAmountData} of ${currencyLabel}$ ${Number(
                                totalAmount
                              )} paid`}
                            </p> */}
                          </div>
                        </div>
                      </div>

                      <div className="flex flex-col justify-between w-full ">
                        {paymentList?.map((project: any, i: number) => {
                          return (
                            <div
                              key={i}
                              className=" flex items-center justify-between w-full px-3 pb-3"
                            >
                              <label htmlFor="" className="text-start w-[43%]">
                                {project.name}
                              </label>
                              <input
                                value={project.amount ?? ""}
                                onKeyDown={(evt: any) =>
                                  ["e", "E", "+", "-"].includes(evt.key)
                                }
                                onChange={(e: any) =>
                                  handlePaymentValueChange(
                                    project.project_status_id,
                                    e.target.value
                                  )
                                }
                                type="number"
                                placeholder="Amount"
                                className="focus:outline-none mb-0 focus-visible:outline-none w-[40%] rounded py-3.5 px-3 border border-goalinputborder text-black2 text-14 font-medium font-primary placeholder:text-graydark h-[48px]"
                              />

                              <div className="flex pl-5 items-center w-full mb-0">
                                <label
                                  htmlFor={`hasPayment_${i}`}
                                  className={`flex items-center`}
                                >
                                  <input
                                    type="checkbox"
                                    id={`hasPayment_${i}`}
                                    className="hidden"
                                    onChange={() => {
                                      handleCheckPaymentDone(
                                        project.project_status_id
                                      );
                                    }}
                                    checked={project.isCompleted}
                                  />
                                  <div
                                    className={`w-6 h-6 rounded-xl flex justify-center items-center mr-3 ${
                                      project.isCompleted
                                        ? "bg-green-400"
                                        : "border border-[#DCE6F0] bg-disabledColor"
                                    }`}
                                  >
                                    {project.isCompleted && (
                                      <SelectedIcon width={9} height={7} />
                                    )}
                                  </div>
                                </label>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                ) : null}
                {tagsData === 5 ? (
                  <div className="px-2 py-3 min-h-[400px] max-h-[410px] overflow-y-auto custom_scrollbar_popup mr-0">
                    <div className="flex flex-col items-center justify-between">
                      <div className="flex mb-2 items-end w-full">
                        <div className=" px-3 w-full">
                          <label className="text-black2 text-14 pb-2 font-medium font-primary mb-[0rem] block">
                            Actual Launch Date
                          </label>
                          <div className="flex items-center">
                            {actualDate && (
                              <button
                                type="button"
                                onClick={() => {
                                  setActualDate(undefined);
                                }}
                                className="text-12 mr-2"
                              >
                                <CloseIcon
                                  color="#EE436D"
                                  width={10}
                                  height={10}
                                />
                              </button>
                            )}
                            <div className="w-full flex flex-col">
                              <CustomDatePicker
                                isProjectCalendarIcon={true}
                                isProject={true}
                                placeholder={new Date().toDateString()}
                                date={actualDate}
                                setDate={(value) => setActualDate(value)}
                              />
                            </div>
                          </div>
                        </div>

                        <div className=" px-3 w-full">
                          <label className="text-black2 text-14 pb-2 font-medium font-primary mb-[0rem] block">
                            Maintenance End Date
                          </label>
                          <div className="flex items-center">
                            {maintenanceDate && (
                              <button
                                type="button"
                                onClick={() => {
                                  setMaintenanceDate(undefined);
                                }}
                                className="text-12 mr-2"
                              >
                                <CloseIcon
                                  color="#EE436D"
                                  width={10}
                                  height={10}
                                />
                              </button>
                            )}

                            <div className="w-full flex flex-col">
                              <CustomDatePicker
                                isProjectCalendarIcon={true}
                                isProject={true}
                                placeholder={new Date().toDateString()}
                                date={maintenanceDate}
                                setDate={(value) => setMaintenanceDate(value)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="w-full mb-2">
                        <ResuableInput
                          label="Remarks"
                          className="w-full px-3"
                          textarea={true}
                          isRequired={false}
                          type="text"
                          placeHolder="Remarks"
                          {...remarkInput}
                        />
                      </div>

                      <div className="flex flex-col mb-2 justify-between w-full ">
                        <div className="mb-3 w-full px-3">
                          <label className="text-black2 text-14 font-medium font-primary mb-[0rem] block">
                            Reminder Checklist
                          </label>

                          {checkList?.map((checkData: any, i: number) => (
                            <CheckListProject
                              checkData={checkData}
                              setData={setCheckList}
                              key={i}
                              handleCheckDelete={handleCheckDelete}
                              isSubmit={isSubmit}
                            />
                          ))}
                          {isSubmit &&
                          checkList?.find(
                            (x: any) => x.label == "" || x.label == null
                          ) ? (
                            <p
                              className={classNames(
                                " font-poppins text-12 text-redPink"
                              )}
                            >
                              CheckList data is required.
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="mb-3 flex justify-start items-center px-2">
                          <Button
                            // disabled={uploading}
                            label={
                              <span className="flex items-center">
                                + Add New
                              </span>
                            }
                            onClick={() => {
                              handleAddNewCheckList();
                            }}
                            type="button"
                            variant="primary"
                            customClass={
                              // disabledData?.disabledClass +
                              classNames(
                                uploading
                                  ? " pointer-events-none opacity-50"
                                  : "",
                                " py-[8px] my-1 px-3 focus:outline-none hover:bg-vorpmyanmar hover:border-vorpmyanmar hover:text-vorpblue 2xl:text-[14px] text-12 leading-5 font-medium text-white"
                              )
                            }
                            size="base"
                          />
                        </div>
                      </div>

                      <div className="flex flex-col mb-2 justify-between w-full ">
                        <div className="mb-3 last:mb-0 w-full px-3">
                          <label className="text-black2 text-14 font-medium font-primary mb-2 block">
                            Important Key Date
                          </label>

                          {keyDateList?.map((q: any, i: number) => (
                            <KeyDateProject
                              key={i}
                              keyDate={q}
                              keyListData={keyListData}
                              setData={setKeyDateList}
                              handleKeyDelete={handleKeyDelete}
                              isSubmit={isSubmit}
                            />
                          ))}
                          {isSubmit &&
                          (keyDateList?.find(
                            (x: any) => x.key_date == "" || x.key_date == null
                          ) ||
                            keyDateList?.find((x: any) => x.key_id == 0)) ? (
                            <p
                              className={classNames(
                                " font-poppins text-12 text-redPink"
                              )}
                            >
                              Keydates data is required.
                            </p>
                          ) : (
                            ""
                          )}
                        </div>

                        <div className="mb-0 flex justify-start items-center px-2">
                          <Button
                            // disabled={uploading}
                            label={
                              <span className="flex items-center">
                                + Add New
                              </span>
                            }
                            onClick={() => {
                              handleAddNewKeyList();
                            }}
                            type="button"
                            variant="primary"
                            customClass={
                              // disabledData?.disabledClass +
                              classNames(
                                uploading
                                  ? " pointer-events-none opacity-50"
                                  : "",
                                " py-[8px] my-1 px-3 focus:outline-none hover:bg-vorpmyanmar hover:border-vorpmyanmar hover:text-vorpblue 2xl:text-[14px] text-12 leading-5 font-medium text-white"
                              )
                            }
                            size="base"
                          />
                        </div>
                      </div>
                      <div className="flex justify-between w-full ">
                        <ResuableInput
                          label="Development Url"
                          className="w-full px-3"
                          isRequired={false}
                          type="text"
                          placeHolder="Development Url"
                          {...developmentUrlInput}
                        />
                        <ResuableInput
                          label="Live Url"
                          className="w-full px-3"
                          isRequired={false}
                          type="text"
                          placeHolder="Live Url"
                          {...liveUrlInput}
                        />
                      </div>
                    </div>
                  </div>
                ) : null}

                <div className="px-2 pb-5 pt-5 flex justify-between items-center">
                  <div className="flex flex-wrap">
                    <div className="flex px-2 justify-center items-center">
                      <Button
                        type="button"
                        label={
                          <span className="flex items-center text-12 leading-[18px]">
                            <SendIcon className="mr-[10px] group-hover:opacity-0 transition-all duration-300" />
                            {editedData ? "Update" : "Submit"}
                          </span>
                        }
                        onClick={() => {
                          handleSubmit();
                          //setTagsData(1);
                        }}
                        variant="no-outline"
                        size="lg"
                        customClass={classNames(
                          uploading || isError
                            ? " pointer-events-none opacity-50"
                            : "",
                          "hover:bg-vorpblue mr-2 py-2 lg:py-2 px-3 custom-button-size"
                        )}
                      />
                      <Button
                        type="button"
                        label={
                          <span className="flex items-center text-12 leading-[18px] ">
                            <CloseIcon
                              width={14}
                              height={14}
                              className="mr-[10px] [&_path]:fill-graydark"
                            />
                            Cancel
                          </span>
                        }
                        onClick={() => {
                          setIsOpen(false);
                          setIsSubmit(false);
                          setEditData("");
                          setErrorMsg("");
                          clearData();
                          setUploading(false);
                          setTagsData(1);
                          setIsSubmit(false);
                        }}
                        variant="no-outline"
                        size="lg"
                        customClass=" mr-2 py-2 lg:py-2 px-3 bg-offwhite text-black2 border-none custom-button-size"
                      />
                    </div>
                  </div>
                  <p
                    className={classNames(
                      isSubmit || errorMsg ? "" : "hidden",
                      "mb-2 font-poppins text-12 text-redPink"
                    )}
                  >
                    {errorMsg}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    );
  }
);

export default CreateProjectPopup;
