import React, {
  // Component,
  Dispatch,
  FC,
  SetStateAction,
  useState,
  useRef,
  useEffect,
  // useCallback,
} from "react";
// import { Modal } from "@material-ui/core";
import { Modal, Fade, CircularProgress } from "@mui/material";

import { Editor } from "@tinymce/tinymce-react";
import CustomDatePicker from "../../components/common/FullWidthDatePicker";
// import { tagTypeArr } from "../../utils/announcementDatas";
import { DBusers } from "../../utils/DBusers";
// import { StringMultipleSelectBox } from "../common/MultipleSelectBox";
import whiteCancel from "../common/img/white-cancel.svg";
import blackCancel from "../common/img/black-cancel.svg";
import saveIcon from "../ModalAnnounEditor/img/submit-icon.svg";
import {
  // AnnounSheet,
  // TagsData,
  useGetAnnounsDataQuery,
  useGetTagsDataQuery,
  useUpdateAnnouncMutation,
} from "../../app/services/annoucement-sheet";
import { CommonTagDropdown } from "../common/CommonTagDropdown";
import DOMPurify from "dompurify";
import { toast } from "react-toastify";
import { StringDropdownList } from "../common/DropdownList";
import { typeList } from "../ModalAnnounEditor/ModalAnnounEditor";
import { renderDateFormatYMD } from "../DashboardCalendarView/DashboardAddEventPopup";
import classNames from "classnames";

interface IModalEditAnnounce {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  // valueChangeCallback: (value:any) => void,
  // totalLength:number,
  // handleUpdateAnnounce: (value: any) => void;
  // loggedUser: string;
  // editRowData?: number;
  setSuccessPopup?: any;
  setErrorPopup?: any;
  setMessage?: any;
  createTagData?: (label: string) => void;
  editRowDataCus?: any;
  saving?: boolean;
  setSaving?: Dispatch<SetStateAction<boolean>>
}

const ModalEditAnnounce: FC<IModalEditAnnounce> = ({
  isOpen,
  setIsOpen,
  // loggedUser,
  // editRowData,
  // handleUpdateAnnounce,
  setSuccessPopup,
  setErrorPopup,
  setMessage,
  createTagData,
  editRowDataCus,
  saving,
  setSaving
}) => {
  const editorRef: any = useRef(null);
  const [goalTitle, setGoalTitle] = useState("");
  const { data } = useGetAnnounsDataQuery();
  const { data: tagsData } = useGetTagsDataQuery();
  const [updatePost] = useUpdateAnnouncMutation();

  const tags = tagsData?.tagsData
    ?.filter((x: any) => x.flag == "anno" || x.flag == null)
    .map((tag: any) => ({
      label: tag.name,
      value: tag.id,
    }))
    .filter((tag: any) => tag.label.toLowerCase() !== "all");

  const editValue = () => {
    if (editRowDataCus) {
      // let editValues: any = data?.data.find(
      //   (announce) => announce.id === editRowData
      // );
      return editRowDataCus;
    }
  };

  const [typeData, setTypeData] = useState(
    editRowDataCus.type ? editRowDataCus.type : typeList[0]
  );
  const [chosenTags, setChosenTags] = useState<string[]>([]);
  const [updatedTags, setUpdatedTags] = useState<number[]>([]);
  const [chosenTagsObj, setChosenTagsObj] = useState<number[]>([]);

  const [expiryDate, setExpiryDate] = useState<Date | undefined>();

  useEffect(() => {
    setExpiryDate(new Date(editValue().expiryDate));
  }, [editValue()]);

  const [myGoal, setMyGoal] = useState("");
  const goalTitleHandler = (event: any) => {
    setGoalTitle(event.target.value);
  };

  // const [announData, setAnnounData] = useState<AnnounSheet[]>([]);
  // const [filterStates, setFilterStates] = useState<IFilterStates>({
  //   tag: "All",
  // });

  // const updateFilterState = useCallback(
  //   (value: string, key: string) => {
  //     setFilterStates((prev) => ({ ...prev, [key]: value }));
  //   },
  //   [setFilterStates]
  // );

  // console.log("updateFilterState => " + filterStates);
  const handleEditorChange = (event: any) => {
    var content = event.target.getContent();
    setMyGoal(content);
  };

  useEffect(() => {
    if (editorRef.current) {
      setMyGoal(editorRef.current.getContent());
    }
    const dataStorage: any = localStorage.getItem("userData");
    var parsedObject = JSON.parse(dataStorage);
    let testType: any = [];

    tagsData?.tagsData?.map(function (item: any) {
      editValue().tags.map((vtag: any, id: any) => {
        if (item.id == vtag.id) {
          testType.push({
            id: item.id,
            name: item.name,
          });
        }
      });
    });
    setChosenTags(
      testType
        .map((x: any) => x.name)
        .filter((item: any, pos: any, self: any) => self.indexOf(item) === pos)
    );
    setChosenTagsObj(testType);
  }, [editorRef, data, editValue()]);

  useEffect(() => {
    let numberTagType: any = [];
    chosenTagsObj?.map((vtag: any) => {
      tagsData?.tagsData?.map(function (item: any) {
        if (item.id == vtag.id) {
          numberTagType.push(item.id);
        }
      });
    });
    setUpdatedTags(chosenTagsObj.map((x: any) => x.id));
  }, [chosenTagsObj]);

  const testingHandler = async () => {
    if(setSaving){
      setSaving(true);
    }    
    // for change date format
    function addZero(i: any) {
      if (i < 10) {
        i = "0" + i;
      }
      return i;
    }

    function formatDate(date: any) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear(),
        h = addZero(d.getHours()),
        m = addZero(d.getMinutes()),
        s = addZero(d.getSeconds());
      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;
      var customeDate =
        [year, month, day].join("-") + " " + h + ":" + m + ":" + s;
      return customeDate;
    }
    let submitData = {
      id: editValue().id,
      title: goalTitle
        ? DOMPurify.sanitize(goalTitle)
        : DOMPurify.sanitize(editValue().title),
      content: myGoal ? myGoal : editValue().content,
      //"tags": updatedTags.filter((item, pos, self) => self.indexOf(item) === pos),
      tags: updatedTags.filter((item, pos, self) => self.indexOf(item) === pos),
      expired_at: expiryDate
        ? renderDateFormatYMD(expiryDate, "-").toString()
        : editValue().expiryDate,
      type: typeData,
    };

    await updatePost(submitData)
      .unwrap()
      .then((payload: any) => {
        if (payload.status) {
          if (setSaving) {
            setSaving(false);
          }
          setIsOpen(false);
          setGoalTitle("");
          setChosenTags([]);
          setExpiryDate(new Date());
          setMyGoal("");
          setSuccessPopup(true);
          setMessage([payload?.message]);
          toast("Successfully Updated.", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            type: "success",
            theme: "light",
          });
        }
      })
      .catch((error: any) => {
        setErrorPopup(true);
        setMessage(error ? [error?.data?.message] : ["Something went wrong!"]);
      });
  };

  return (
    <Modal
      open={isOpen}
      style={{
        background: "rgb(0 0 0 / 0.3)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      onClose={(event, reason) => {
        if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
          // Set 'open' to false, however you would do that with your particular code.
          setIsOpen(false);
        }
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableEnforceFocus={true}
    >
      <Fade in={isOpen} timeout={500}>
        <div className="modalContent lg:w-[1000px] max-w-[90%] annoucement-modal overflow-y-auto max-h-[100%] w-full">
          <div
            id="modal-modal-title"
            className="bg-vorpblue relative flex justify-between py-6 px-10 rounded-t-xl"
          >
            <h3 className="text-18 font-primary font-semibold text-white">
              Edit Announcement
            </h3>
            <button
              className="flex items-center justify-center"
              onClick={() => {
                setIsOpen(false);
              }}
            >
              <img src={whiteCancel} alt="close modal icon" />
            </button>
          </div>
          <div
            id="modal-modal-description"
            className="py-9 px-5 bg-white rounded-b-xl"
          >
            <div className="md:flex">
              <div className="md:w-1/2 w-full sm:pl-5">
                <div className="mb-5">
                  <label className="text-black2 text-15 font-medium font-primary mb-[0.688rem] block">
                    ID
                  </label>
                  {editValue() && (
                    <p className="w-full rounded py-3.5 px-3 border border-goalinputborder text-black2 text-14 font-medium font-primary placeholder:text-graydark h-[48px]">
                      #00000{editValue().id}
                    </p>
                  )}
                </div>
                <div className="mb-5 last:mb-0">
                  <label className="text-black2 text-15 font-medium font-primary mb-[0.688rem] block">
                    Title
                  </label>
                  {editValue().title && (
                    <input
                      type="text"
                      onChange={goalTitleHandler}
                      defaultValue={
                        editValue().title ? editValue().title : goalTitle
                      }
                      placeholder="My Goal in 2023"
                      className="focus:outline-none focus-visible:outline-none w-full rounded py-3.5 px-3 border border-goalinputborder text-black2 text-14 font-medium font-primary placeholder:text-graydark h-[48px]"
                    ></input>
                  )}
                </div>
                <div className="mb-5 last:mb-0">
                  <label className="text-black2 text-15 font-medium font-primary mb-[0.688rem] block">
                    Type
                  </label>
                  <StringDropdownList
                    state={typeData}
                    setState={setTypeData}
                    dropdownItems={typeList?.map((x: string) => {
                      return {
                        label: x,
                        value: x,
                      };
                    })}
                    className="w-full xl:text-14 text-12 py-[13px] border-goalinputborder"
                    placeholder="All"
                    textAlign="text-left py-2 pl-2 pr-3"
                  />
                </div>
                <div className="mb-5 last:mb-0">
                  <label className="text-black2 text-15 font-medium font-primary mb-[0.688rem] block">
                    Expiry Date
                  </label>
                  <div className="w-full">
                    {editValue().expiryDate && (
                      <CustomDatePicker
                        placeholder="Monday, 7 Nov 2022"
                        date={expiryDate}
                        setDate={(value) => setExpiryDate(value)}
                      />
                    )}
                  </div>
                </div>
                <div className="mb-5 last:mb-0">
                  <label className="text-black2 text-15 font-medium font-primary mb-[0.688rem] block">
                    Tag
                  </label>
                  <CommonTagDropdown
                    showAll={true}
                    createData={createTagData}
                    state={chosenTags}
                    setState={setChosenTags}
                    setSelected={setChosenTagsObj}
                    options={tags ? tags : []}
                    className="border-goalinputborder 6xs:text-goalinputborder text-14"
                  />
                </div>
              </div>
              <div className="md:w-1/2 w-full sm:px-5">
                <div className="mb-5 last:mb-0 h-full">
                  <label className="text-black2 text-15 font-medium font-primary mb-[0.688rem] block">
                    Content
                  </label>
                  {editValue() && (
                    <Editor
                     apiKey="7lo2e7xqoln1oo18qkxrhvk9wohy5picx4824cjgas8odbg3"
                      onInit={(evt, editor) => (editorRef.current = editor)}
                      initialValue={
                        editValue().content ? editValue().content : ""
                      }
                      init={{
                        height: 247,
                        menubar: false,
                        plugins: ["code", "link"],
                        // plugins: [
                        //   "advlist autolink lists link image charmap print preview anchor",
                        //   "searchreplace visualblocks code fullscreen",
                        //   "insertdatetime media table paste code help wordcount",
                        //   "textcolor",
                        // ],
                        // toolbar: 'undo redo | formatselect | styleselect | fontsizeselect | ' +
                        // 'bold italic backcolor | alignleft aligncenter ' +
                        // 'alignright alignjustify | bullist numlist outdent indent | ' +
                        // 'removeformat | help | styleselect fontselect fontsizeselect | forecolor backcolor',
                        toolbar:
                          "undo redo | fontsize | forecolor backcolor | styleselect fontsizeselect | bold italic | alignleft aligncenter alignright alignjustify | outdent indent | code | link",
                        block_formats:
                          "Paragraph=p; Header 1=h1; Header 2=h2; Header 3=h3",
                        font_size_formats:
                          "8px 9px 10px 11px 12px 13px 14px 15px 16px 17px 18px 24px 36px",
                        content_style:
                          "@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap'); body { font-family: 'Poppins', sans-serif; font-size: 14px; } h1,h2,h3,h4,h5,h6 { font-family: 'Poppins', sans-serif; }",
                        font_formats: "Poppins=poppins;",
                      }}
                      onChange={handleEditorChange}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="mt-5 last:mb-0 flex items-center px-5">
              <button
                className={classNames(saving?'opacity-50 pointer-events-none':'',"flex items-center justify-center bg-vorpblue mr-2.5 text-white font-primary text-14 font-medium rounded-md py-[8.5px] px-3")}
                onClick={testingHandler}
              >
                {saving == true ? <div className="flex items-center justify-center w-full">
                  <CircularProgress sx={{ color: 'white' }} className="w-5 h-5" style={{ width: '20px', height: '20px' }} /><span className="ml-2">Saving</span>
                </div> : <div className="flex items-center">
                  <img src={saveIcon} alt="save icon" className="mr-3"></img>
                  Updated</div>}
              </button>
              <button
                className="flex items-center justify-center bg-offwhite font-primary text-14 font-medium text-black2 rounded-md py-[8.5px] px-3"
                onClick={() => {
                  setIsOpen(false);
                }}
              >
                <img src={blackCancel} alt="black icon" className="mr-3"></img>
                Cancel
              </button>
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default ModalEditAnnounce;
