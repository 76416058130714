import {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useRef,
  useState,
  forwardRef,
} from "react";
import { Popper } from "@mui/material";

import ReactDatePicker from "react-datepicker";
import { renderDateFormatMY } from "../DashboardCalendarView/DashboardAddEventPopup";
import { Cell } from "@tanstack/react-table";
import { baseObj } from "../../utils/constants";
import { useSpring, animated } from "@react-spring/web";

interface IDate {
  dobDate?: Date | null;
  setDate: Dispatch<SetStateAction<Date | undefined>>;
  cell: Cell<unknown, unknown>;
  data: baseObj[];
  setData: Dispatch<SetStateAction<baseObj[]>>;
  maxDate?: Date;
  minDate?: Date;
  hasMaxDate?: boolean;
  hasMinDate?: boolean;
  isNew?: any;
  isClearDate?: boolean;
}

interface FadeProps {
  children?: React.ReactElement;
  in?: boolean;
  onEnter?: () => void;
  onExited?: () => void;
}

const Fade = forwardRef<HTMLDivElement, FadeProps>(function Fade(
  props: any,
  ref: any
) {
  const { in: open, children, onEnter, onExited, ...other } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter();
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited();
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {children}
    </animated.div>
  );
});

const DatePickerMY: FC<IDate> = ({
  dobDate,
  setDate,
  data,
  setData,
  cell,
  maxDate,
  minDate,
  hasMaxDate,
  hasMinDate,
  isClearDate,
  isNew,
}) => {
  var buttonRef = useRef<HTMLButtonElement>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(
    buttonRef.current
  );
  const [open, setOpen] = useState(true);
  const [placement, setPlacement] = useState<any>();
  const [selectedDate, setSelectedDate] = useState(dobDate);
  var dateRef = useRef<ReactDatePicker>(null);
  var resetDate = useRef<ReactDatePicker>(null);
  const handleClick = (newPlacement: any, event: any) => {
    setOpen(true);
    setAnchorEl(event.currentTarget);
    setPlacement(newPlacement);
    setTimeout(() => {
      dateRef.current?.setOpen(true);
    }, 100);
  };

  const handleChangeDate = (date: Date | undefined) => {
    setSelectedDate(date ? date : undefined);
    setDate(date);
    setData((prev: baseObj[]) => {
      return prev.map((data: baseObj, index: number) => {
        if (index === Number(cell.row.id)) {
          return {
            ...data,
            [cell.column.id]: date
              ? renderDateFormatMY(new Date(date), " ")
              : renderDateFormatMY(new Date(), " "),
          };
        }
        return data;
      });
    });
  };

  const clearDate = () => {
    setSelectedDate(undefined);
    setDate(undefined);
  };
  const canBeOpen = open && Boolean(anchorEl);
  const id = canBeOpen ? "spring-popper" : undefined;

  useEffect(() => {
    setOpen(true);
  }, [open]);

  return (
    <div>
    <ReactDatePicker
      calendarClassName="relative"
      popperClassName="relative"
      ref={dateRef}
      className="w-fit"
      selected={dobDate}
      onChange={(prev: any) => {
        handleChangeDate(prev);
        setOpen(false);
      }}
      showMonthYearPicker
      showYearDropdown
      showMonthDropdown
      dateFormat="MMM yyyy"
      onFocus={(e) => {
        setOpen(false);
        dateRef.current?.setOpen(false);
      }}
    />
  </div>
  );
};

export default DatePickerMY;
