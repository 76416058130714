import { useCallback, useEffect, useState } from "react";
import {
  MediaData,
  // useGetFileManagerQuery,
  useCreateFolderMutation,
  // useUploadFileMutation,
  // useDeleteFileMutation,
  useDeleteFilesMultiMutation,
  // useDownloadFileDocumentMultipleQuery,
  useDeleteFolderMutation,
  MediaItems,
  useGetFileManagerWithSearchQuery,
} from "../../app/services/media";
import {
  GridLayoutIcon,
  HorizontalGridIcon,
  MediaFolder,
  MediaUpload,
} from "../../components/common/Icons";
import axios from "../../axios";
import { AddIcon, SearchIcon } from "../../components/common/Icons";
import DebounceSearchInput from "../../components/Tables/DebounceSearchInput";
import classNames from "classnames";
import { StringDropdownList } from "../../components/common/DropdownList";
import { DocsIcon } from "../../../src/components/DashboardProfile/Documents/icons/IconMapper";
import styles from "./Media.module.scss";
import CreateNewMediaPopup from "../../components/CreateNewItemPopup/CreateNewMediaPopup";
import { endpointUrl } from "../../app/services/api";
import { getAuthUser, httpErrorHandler } from "../../app/services/dashboard";
// import { useNavigate } from "react-router-dom";
import DeletePopup from "../../components/DeletePopup/DeletePopup";
import { DocFiles, ImageFormat } from "../../app/services/documents";
import CommonErrorPopup from "../../components/common/CommonErrorPopup";
// import { Tooltip, makeStyles } from "@material-ui/core";
import { makeStyles } from "@mui/styles";
import { Tooltip } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";

import MediaSkeleton from "../../components/MediaFileManager/MediaSkeleton";
import ErrorPopup from "../../components/ErrorPopup/ErrorPopup";
import { onViewDocument } from "../../components/LeaveApplicationFIlesPopup/LeaveApplicationFilesPopup";
const sortByKey = ["name", "data"];
const useStyles = makeStyles(() => ({
  tooltip: {
    backgroundColor: "transparent",
    margin: 0,
    marginRight: 8,
    padding: 0,
  },
}));
const Media = () => {
  const classes = useStyles();
  const [successData, setSuccessData] = useState<boolean>(false);
  // const [fileList,setFileList]=useState<any>([]);
  // const { data, isLoading } = useGetMediasQuery();
  // const { data: iframe } = useGetIframeQuery();
  // const [iframe, setIframe] = useState<string>("");
  const [files, setFiles] = useState<MediaData[]>([]);
  const [filterFiles, setFilterFiles] = useState<MediaData[]>([]);
  const [filterFilesDoc, setFilterFilesDoc] = useState<MediaItems[]>([]);
  const [globalSearch, setGlobalSearch] = useState<string>("");
  const [isTableView, setIsTableView] = useState<boolean>(false);
  const [sortBy, setSortBy] = useState<string>("");
  const [selectedFolder, setSelectedFolder] = useState<number>(0);
  const [selectedSubFolder, setSelectedSubFolder] = useState<string>("");
  const [selectedFolderData, setSelectedFolderData] = useState<MediaData[]>([]);
  const [isShowActions, setIsShowActions] = useState<boolean>(false);
  const [keywords, setKeywords] = useState<string>("");
  //const { data: filesData } = useGetFileManagerQuery();

  const {
    data: filterFilesData,
    isLoading,
    isFetching,
  } = useGetFileManagerWithSearchQuery({
    keywords: keywords,
  });
  const [isFolderShow, setIsFolderShow] = useState<boolean>(false);
  const [isFileUpload, setIsFileUpload] = useState<boolean>(false);
  const [view, setView] = useState<string>("grid");
  const [createFolder] = useCreateFolderMutation();
  // const [uploadFile] = useUploadFileMutation();
  const [selectedFiles, setSelectedFiles] = useState<number[]>([]);
  const [docs, setDocs] = useState<number[]>([]);
  const [showFileActions, setShowFileAction] = useState<boolean>(false);
  const [xPos, setXPos] = useState<string>("");
  const [yPos, setYPos] = useState<string>("");
  // const [deleteDocument] = useDeleteFileMutation();
  const [deleteDocumentMulti] = useDeleteFilesMultiMutation();
  const [deleteFolder] = useDeleteFolderMutation();
  const [isDeleteFolder, setIsDeleteFolder] = useState<boolean>(false);
  const [isDeleteShow, setIsDeleteShow] = useState<boolean>(false);
  // const [selectedDocs, setSelectedDocs] = useState<MediaItems[]>([]);
  const [error, setError] = useState<string>("");
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [errorPopup, setErrorPopup] = useState<boolean>(false);
  const [message, setMessage] = useState<any>([]);
  // const { data: Documents } = useDownloadFileDocumentMultipleQuery({
  //   ids: docs
  // });
  // const navigate = useNavigate();

  const [manageMediaLibrary, setManageMediaLibrary] = useState<boolean>(false);

  var getUser = getAuthUser();
  const getAuthPermis: string[] = [];

  if (getUser?.permissions) {
    getUser?.permissions?.forEach((per: any) => {
      getAuthPermis.push(per?.name);
    });
  }

  useEffect(() => {
    const manageMediaLibrary = getAuthPermis.filter(
      (f) => f == "Manage_Media_Library"
    );
    if (manageMediaLibrary.length > 0) {
      setManageMediaLibrary(true);
    }
  }, [getUser]);

  useEffect(() => {
    // console.log(" useEffect ",filterFilesData,filesData)

    setKeywords(globalSearch);
    //if (globalSearch) {
    if (filterFilesData) {
      setFiles(filterFilesData?.folders);
      if (filterFilesData?.folders.length > 0) {
        var docs = filterFilesData?.folders[0]?.documents;
        if (!selectedFolder) {
          setSelectedFolder(filterFilesData?.folders[0]?.id);
        } else {
          if (globalSearch) {
            setSelectedFolder(filterFilesData?.folders[0]?.id);
          } else {
            setSelectedFolder(selectedFolder);
          }
        }

        setFilterFilesDoc(docs ? docs : []);
        setFilterFiles(filterFilesData?.folders);
      }
      // if(filterFilesData?.documents.length>0){
      //   setFilterFilesDoc(filterFilesData?.documents);
      //   setSelectedFolder(filterFilesData?.documents[0]?.folder_id);
      // }
    }
    // } else {
    //   if (filesData) {
    //     setFiles(filesData?.folders);
    //     if (filesData?.folders.length > 0) {
    //       var docs = filesData?.folders.find((x: any) => x.id == filesData?.folders[0]?.id)?.documents;
    //       setSelectedFolder(filesData?.folders[0]?.id);
    //       setFilterFilesDoc(docs ? docs : [])
    //       setFilterFiles(filesData?.folders);
    //     }
    //     // if(filterFilesData?.documents.length>0){
    //     //   setFilterFilesDoc(filterFilesData?.documents);
    //     //   setSelectedFolder(filterFilesData?.documents[0]?.folder_id);
    //     // }

    //   }
    // }

    // else {
    //   if (filesData) {
    //     setFiles(filesData?.folders);
    //     var initialData = filesData?.folders[0];
    //     if (initialData) {
    //       if (selectedFolder == 0) {
    //         setSelectedFolder(initialData?.id);
    //         var docs = filesData?.folders.find(x => x.id == initialData?.id)?.documents;
    //         setSelectedDocs(docs ? docs : []);
    //         setFilterFilesDoc(docs ? docs : [])
    //       }
    //     }
    //   }
    // }
  }, [globalSearch, filterFilesData]);
  useEffect(() => {
    // console.log("filesData ",filterFilesData)
  }, [filterFilesData]);
  const changeFolder = (id: number) => {
    setSelectedFolder(id);
    setSelectedSubFolder("");
    var folder = files?.filter((x) => x.id == id);
    setSelectedFolderData(folder ? folder : []);
    setSelectedFiles([]);
    setShowFileAction(false);
  };
  const changeSubFolder = (id: string, parentId: number) => {
    setSelectedSubFolder(id);
    setSelectedFolder(parentId);
    var folder = files?.filter((x) => x.id == parentId);
    setSelectedFolderData(folder ? folder : []);
  };
  const showMediaActions = () => {
    setIsShowActions(!isShowActions);
  };

  const createFolderFunc = (name: string) => {
    createFolder({
      name: name,
    })
      .then((folder: any) => {
        toast("Successfully Create.", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          type: "success",
        });
      })
      .catch((error: any) => { });
    setIsFolderShow(false);
  };

  const uploadFileFunc = async (file: any) => {
    if (file) {
      setIsUploading(true);
      let formSaveData = new FormData();
      formSaveData.append("file", file);
      formSaveData.append("folder_id", selectedFolder.toString());
      var importedData = {
        file: file,
        folder_id: selectedFolder,
      };
      await axios(endpointUrl + "file-manager/upload", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${getUser.token}`,
          "Content-Type": "multipart/form-data",
          // Cookie: document.cookie,
        },
        data: formSaveData,
      })
        .then((res: any) => {
          if (res.status) {
            setIsUploading(false);
            setSuccessData(true);
            setIsFileUpload(false);
            toast("Successfully Create.", {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              type: "success",
            });
          }
          axios.get(`${endpointUrl}file-manager`, {}).then((result) => {
            setFiles(result?.data?.folders);
          });
        })
        .catch((error: any) => {
          setIsUploading(false);
          // console.log("error", error);
          setError(error?.response?.data?.message);
          // console.log("error ", error?.response?.data?.message);
        });
    } else {
      setErrorPopup(true);
      setMessage(["Please choose file!."]);
    }
  };

  const setSelectedItems = (id: number) => {
    if (selectedFiles.includes(id)) {
      setSelectedFiles(selectedFiles?.filter((x) => x !== id));
    } else {
      setSelectedFiles([...selectedFiles, id]);
    }
    setShowFileAction(false);
  };

  const handleContextMenu = useCallback(
    (e: any, id: number, selected: number[]) => {
      setIsDeleteFolder(false);
      if (selected.length <= 0) {
        setSelectedFiles([id]);
      } else {
        setSelectedFiles(selected);
      }
      e.preventDefault();
      setXPos(`${e.pageX}px`);
      setYPos(`${e.pageY}px`);
      setShowFileAction(true);
    },
    [setXPos, setYPos]
  );
  const deleteDocumentByID = () => {
    setIsDeleteShow(true);
  };
  const confirmDeleteData = () => {
    if (isDeleteFolder) {
      deleteFolder(selectedFolder).then((res: any) => {
        toast("Successfully Delete Folder.", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          type: "success",
        });
      });
    } else {
      deleteDocumentMulti({
        ids: selectedFiles,
      })
        .then((res: any) => {
          axios.get(`${endpointUrl}file-manager`, {}).then((result) => {
            setFiles(result?.data?.folders);
          });
          toast("Successfully Delete.", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            type: "success",
          });
        })
        .catch((error: any) => {
          // console.log("ee", error);
        });
    }
    setIsDeleteShow(false);
  };
  const downloadDocumentByID = (fileId?: number) => {
    var id = fileId ? fileId : selectedFiles[0];
    var filedata = files
      ?.map((f) => {
        var docs = f.documents?.find((x) => x.id == id);
        return docs;
      })
      .find((x) => x != undefined);
    const extension = filedata?.name.split(".").pop() as string;
    var name = filedata?.name;

    axios({
      url: endpointUrl + `downloadDocument/${id}`,
      method: "GET",
      responseType: "blob",
      headers: {
        Authorization: `Bearer ${getUser.token}`,
        Accept: "application/json",
      },
    }).then((response) => {
      // console.log("response ", response);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", name ? name : "");
      document.body.appendChild(link);
      link.click();
    });
  };
  const downloadDocumentByIDs = () => {
    var id = selectedFiles[0];
    var filedata = files
      ?.map((f) => {
        var docs = f.documents?.find((x) => x.id == id);
        return docs;
      })
      .find((x) => x != undefined);
    const extension = filedata?.name.split(".").pop() as string;
    var name = filedata?.name;
    if (selectedFiles.length == 1) {
      downloadDocumentByID();
    } else {
      axios({
        url: endpointUrl + `downloadDocuments?ids=${selectedFiles.join(",")}`,
        method: "GET",
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${getUser.token}`,
          Accept: "application/json",
        },
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          // console.log("url ", url);
          link.setAttribute("download", "documents.zip");
          document.body.appendChild(link);
          link.click();
        })
        .catch(httpErrorHandler);
    }
  };
  const handleDeleteFolder = (e: any, id: number) => {
    e.preventDefault();
    setXPos(`${e.pageX}px`);
    setYPos(`${e.pageY}px`);
    setShowFileAction(true);
    setIsDeleteFolder(true);
    setSelectedFolder(id);
  };
  return (
    <>
      <DeletePopup
        show={isDeleteShow}
        setShow={setIsDeleteShow}
        confirmBtnText="Yes"
        descText="Are you sure to delete?"
        confirmDeleteData={() => confirmDeleteData()}
      />
      <CreateNewMediaPopup
        addNewItem={createFolderFunc}
        title="Create Folder"
        buttonConfirm="Create"
        show={isFolderShow}
        setShow={setIsFolderShow}
      />
      <CreateNewMediaPopup
        addNewItem={uploadFileFunc}
        title="Upload File"
        buttonConfirm="Upload"
        isFile={true}
        show={isFileUpload}
        setShow={setIsFileUpload}
        isUploading={isUploading}
      />
      <ToastContainer className={"text-12 font-poppins font-normal"} />
      <ErrorPopup
        descText="Error Message!"
        confirmBtnText="Yes, Close."
        show={errorPopup}
        setShow={setErrorPopup}
        message={message}
        setMessage={setMessage}
      />
      <div
        onClick={() => setShowFileAction(false)}
        className={classNames(
          "px-2 sm:px-4 py-4 bg-white sm:mt-[25px] mt-0 font-poppins text-13 font-semibold text-[#858795] tracking-[-0.03rem] w-full h-full rounded-xl pt-3 pb-8 sm:pt-3 sm:pb-9"
        )}
      >
        <div className="md:flex block justify-between mb-1">
          <div className="xl:w-[30%] lg:w-[40%] md:w-[20%] flex items-center mr-2 xs:mr-4 w-full 3xl:w-fit mb-1 xs:mt-4 md:mt-1">
            <DebounceSearchInput setState={setGlobalSearch} debounce={800}>
              {(setState) => (
                <div className="font-normal flex border-1 border-[#C4C4C4] bg-white hover:bg-[#EFEFEF] rounded-[28px] sm:w-[260px] w-[225px]">
                  <input
                    type="text"
                    placeholder="Search"
                    className="focus:outline-none py-1 px-4 text-12 xl:text-14 leading-5 bg-transparent rounded-[28px] w-full text-graydark"
                    onChange={(e) => setState(e.target.value)}
                  />
                  <SearchIcon className="mr-2" />
                </div>
              )}
            </DebounceSearchInput>
          </div>

          <div className="flex items-center">
            <button
              type="button"
              onClick={() => {
                setIsTableView(false);
                setView("grid");
                setSelectedFiles([]);
                setDocs([]);
              }}
              className="mr-[10px] bg-offwhite rounded-lg flex justify-center items-center min-w-[34px] h-[34px]"
            >
              <GridLayoutIcon isActive={!isTableView} />
            </button>
            <button
              type="button"
              onClick={() => {
                setIsTableView(true);
                setView("list");
                setSelectedFiles([]);
                setDocs([]);
              }}
              className="bg-offwhite rounded-lg  flex justify-center items-center min-w-[34px] h-[34px]"
            >
              <HorizontalGridIcon isActive={isTableView} />
            </button>
            <div className="hidden items-center md:mr-[14px] ml-3">
              <p className="base-label-sm whitespace-nowrap block 5xs:hidden 2xs:block">
                Sort
              </p>
              <div className={`w-full`}>
                <StringDropdownList
                  state={sortBy}
                  setState={setSortBy}
                  dropdownItems={sortByKey.map((type) => ({
                    value: type,
                    label: type,
                  }))}
                  textAlign="text-left"
                  className={`max-h-[34px] w-full 5xs:min-w-full 5xs:w-[78px] 5xs:max-w-[78px] xs:max-w-full xs:w-[100px] lg:w-[110px] text-[#B6B6B6]`}
                />
              </div>
            </div>
          </div>
        </div>

        {isLoading || isFetching ? (
          <>
            <MediaSkeleton isMedia={true} />
          </>
        ) : (
          <div className="mt-6 flex min-h-[540px]">
            <div className="sm:w-[25%] xs:w-[30%] w-[45%] bg-[#F3F6F9] sm:mr-5 mr-2 relative">
              <div>
                <ul>
                  {files?.map((file, index: number) => {
                    return (
                      <li
                        onContextMenu={(e) => handleDeleteFolder(e, file.id)}
                        key={index}
                        className={classNames(
                          selectedFolder == file.id ? "bg-[#E5EAEE]" : "",
                          "cursor-pointer p-[10px] list-none font-poppins font-normal md:text-14 text-12 text-black2"
                        )}
                      >
                        <div
                          onClick={() => changeFolder(file.id)}
                          className={classNames("flex item-center w-full")}
                        >
                          <img
                            src={
                              selectedFolder == file.id
                                ? DocsIcon.folderOpen
                                : DocsIcon.folder
                            }
                            className="mr-3"
                          />
                          <span>{file.name}</span>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div className="absolute right-5 bottom-5 max-w-[50px]">
                <div
                  className={classNames(
                    isShowActions
                      ? "top-[-120px]"
                      : "opacity-0 top-[-100%]  z-[-1]",
                    styles.actionsButton,
                    " mb-[14px] absolute"
                  )}
                >
                  <div
                    onClick={() => {
                      setIsFileUpload(true);
                      setIsFolderShow(false);
                      setSelectedFiles([]);
                    }}
                    className={classNames(
                      styles.customButton,
                      "inline-block w-[50px] h-[50px] rounded-[50%] bg-white cursor-pointer"
                    )}
                  >
                    <MediaUpload className="h-full m-auto" />
                  </div>
                  <div
                    onClick={() => {
                      setIsFileUpload(false);
                      setIsFolderShow(true);
                      setSelectedFiles([]);
                    }}
                    className={classNames(
                      styles.customButton,
                      "inline-block w-[50px] h-[50px] rounded-[50%] bg-white cursor-pointer"
                    )}
                  >
                    <MediaFolder className="h-full m-auto" />
                  </div>
                </div>
                {manageMediaLibrary ? (
                  <div
                    onClick={() => {
                      showMediaActions();
                      setSelectedFiles([]);
                    }}
                    className="inline-block w-[50px] h-[50px] rounded-[50%] bg-vorpblue cursor-pointer"
                  >
                    <AddIcon
                      color="#fff"
                      className={classNames(
                        isShowActions ? styles.svgRotate : "",
                        styles.svgIcon,
                        "h-full m-auto"
                      )}
                    />
                  </div>
                ) : null}
              </div>
            </div>
            <div className="sm:w-[75%] xs:w-[70%] w-[55%] relative">
              <div>
                <div>
                  <p className="text-left font-poppins font-semibold md:text-14 text-12 text-black2 px-5 py-3 bg-offwhite">
                    File
                  </p>
                </div>
                <div
                  className={classNames(view == "grid" ? "" : "hidden", "mt-3")}
                >
                  <div className="grid 2xl:grid-cols-6 lg:grid-cols-4 sm:grid-cols-3 xs:grid-cols-2 grid-cols-1 4xl:gap-6 gap-4">
                    {files?.map((file, index: number) => {
                      return file?.documents?.map((doc, ind: number) => {
                        const isSelected = selectedFiles.find(
                          (x) => x == doc.id
                        );
                        // const extension = doc.url.split('.').pop();
                        const extension = doc.type;
                        return (
                          <div
                            onDoubleClick={() => {
                              if (
                                !DocFiles.includes(extension ? extension : "")
                              ) {
                                setSelectedFiles([doc.id]);
                                downloadDocumentByID(doc.id);
                              } else {
                                // window.open(doc.url, "_blank");
                                // window.open(window.location.origin+'/document-view?id='+doc.hash, "_blank");
                                onViewDocument(doc.hash, doc.type, getUser.token)
                              }
                            }}


                            onContextMenu={(e) => {
                              handleContextMenu(e, doc.id, selectedFiles);
                              e.preventDefault();
                            }}
                            onClick={() => setSelectedItems(doc.id)}
                            key={ind}
                            className={classNames(
                              selectedFolder == file.id ? "" : "hidden",
                              "cursor-pointer",
                              isSelected !== undefined
                                ? styles.selectedDoc
                                : "border-2 border-[#DADADA]"
                            )}
                          >
                            <div className="5xl:px-2 6xl:py-8 py-4 px-2 border-b border-b-[#DADADA] 6xl:min-h-[125px]">
                              <img
                                src={
                                  extension
                                    ? DocsIcon[extension]
                                      ? DocsIcon[extension]
                                      : ImageFormat.includes(extension)
                                        ? DocsIcon[extension]
                                        : DocsIcon["unknow"]
                                    : DocsIcon["folder"]
                                }
                                className="w-[80px] h-[80px] object-cover mx-auto p-1"
                              />
                            </div>
                            <div className="py-1 4xl:px-5 px-2 ">
                              <Tooltip
                                arrow
                                classes={{ tooltip: classes.tooltip }}
                                enterTouchDelay={0}
                                leaveTouchDelay={5000}
                                placement="left"
                                title={
                                  <>
                                    <div className="text-white text-[10px] hover:cursor-pointer leading-[15px] z-[2] rounded p-4 w-full bg-tooltipbg">
                                      <p className="text-center break-words">
                                        {doc.name}
                                      </p>
                                    </div>
                                  </>
                                }
                              >
                                <p className="text-center break-words whitespace-nowrap overflow-hidden text-ellipsis w-[95s%]">
                                  {doc.name}
                                </p>
                              </Tooltip>
                            </div>
                          </div>
                        );
                      });
                    })}
                  </div>
                </div>
                <div
                  className={classNames(view == "list" ? "" : "hidden", "mt-3")}
                >
                  <div className="">
                    <ul>
                      {files?.map((file, index: number) =>
                        file?.documents?.map((doc, ind: number) => {
                          const isSelected = selectedFiles.find(
                            (x) => x == doc.id
                          );
                          const extension = doc.url.split(".").pop();
                          return (
                            <li
                              onDoubleClick={() => {
                                if (
                                  !DocFiles.includes(extension ? extension : "")
                                ) {
                                  setSelectedFiles([doc.id]);
                                  downloadDocumentByID(doc.id);
                                } else {
                                  //window.open(window.location.origin+'/document-view?id='+doc.hash, "_blank");
                                  onViewDocument(doc.hash, doc.type, getUser.token)
                                }
                              }}
                              onContextMenu={(e) => {
                                handleContextMenu(e, doc.id, selectedFiles);
                                e.preventDefault();
                              }}
                              onClick={() => setSelectedItems(doc.id)}
                              key={ind}
                              className={classNames(
                                selectedFolder == file.id ? "" : "hidden",
                                "flex items-center py-[10px] border-b border-b-[#E5EAEE] cursor-pointer",
                                isSelected !== undefined ? "bg-[#E5EAEE]" : ""
                              )}
                            >
                              <div className="mr-2 w-[50px]">
                                <img
                                  src={
                                    extension
                                      ? DocsIcon[extension]
                                        ? DocsIcon[extension]
                                        : ImageFormat.includes(extension)
                                          ? DocsIcon[extension]
                                          : DocsIcon["unknow"]
                                      : DocsIcon["folder"]
                                  }
                                  className="max-w-[40px] max-h-[40px] mx-auto p-1"
                                />
                              </div>
                              <div className="py-1 4xl:px-5 px-2">
                                <p className="text-center break-words">
                                  {doc.name}
                                </p>
                              </div>
                            </li>
                          );
                        })
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                top: yPos,
                left: xPos,
              }}
              className={classNames(
                showFileActions ? "" : "hidden",
                "fixed  max-w-[50px] bg-offwhite min-w-[150px] z-[9]"
              )}
            >
              {manageMediaLibrary ? (
                <ul>
                  <li
                    onClick={() => {
                      downloadDocumentByIDs();
                      //selectedFiles.length==1?downloadDocumentByID():
                      setDocs(selectedFiles);
                    }}
                    className={classNames(
                      isDeleteFolder ? "hidden" : "",
                      "py-2 cursor-pointer 4xl:px-5 px-4 border border-[#DADADA] font-poppins font-normal md:text-14 text-12 text-black2 text-left"
                    )}
                  >
                    Download
                  </li>
                  <li
                    onClick={() => deleteDocumentByID()}
                    className="py-2 cursor-pointer 4xl:px-5 px-4 border border-[#DADADA] font-poppins font-normal md:text-14 text-12 text-black2 text-left"
                  >
                    Delete
                  </li>
                </ul>
              ) : null}
            </div>
          </div>
        )}
        <CommonErrorPopup
          show={error != "" ? true : false}
          setShow={() => setError("")}
          message={error}
        />
      </div>
    </>
  );
};
export default Media;
