import { FC } from "react";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
// import { Employee, EmployeeData } from "../../active-time-report/views/ActiveTimeReport";
// import { useState } from "react";
import { TimeSheetDetail } from "../../app/services/timesheet";
ChartJS.register(ArcElement);
interface CurrentEmployee {
  // currentEmployeeData?: EmployeeData;
  currentEmployeeData?: TimeSheetDetail;
}

const TimeTrackingPieChart: FC<CurrentEmployee> = ({ currentEmployeeData }) => {

  const data = {
    labels: ["Productive", "Unproductive", "Neutral"],
    datasets: [
      {
        label: "# of Votes",
        data: [
          Number(currentEmployeeData?.employee_id) != 0 ?
            (Number(currentEmployeeData?.productive_percentage) == 0 && Number(currentEmployeeData?.unproductive_percentage) == 0 && Number(currentEmployeeData?.neutral_percentage) == 0) ?
              100 : currentEmployeeData?.productive_percentage : 100,
          Number(currentEmployeeData?.employee_id) != 0 ?
            (Number(currentEmployeeData?.productive_percentage) == 0 && Number(currentEmployeeData?.unproductive_percentage) == 0 && Number(currentEmployeeData?.neutral_percentage) == 0) ?
              100 : currentEmployeeData?.unproductive_percentage : 100,
          Number(currentEmployeeData?.employee_id) != 0 ?
            (Number(currentEmployeeData?.productive_percentage) == 0 && Number(currentEmployeeData?.unproductive_percentage) == 0 && Number(currentEmployeeData?.neutral_percentage) == 0) ?
              100 : currentEmployeeData?.neutral_percentage : 100,
        ],
        backgroundColor: (Number(currentEmployeeData?.employee_id) == 0 || (currentEmployeeData?.productive_percentage == 0 && currentEmployeeData?.unproductive_percentage == 0 && currentEmployeeData?.neutral_percentage == 0)) ?
          ["#f2eded", "#f2eded", "#f2eded"] : ["#50CD89", "#EE436D", "#FFA800"],
        borderColor: (Number(currentEmployeeData?.employee_id) == 0 || (currentEmployeeData?.unproductive_percentage == 0 && currentEmployeeData?.unproductive_percentage == 0 && currentEmployeeData?.neutral_percentage == 0)) ?
          ["#f2eded", "#f2eded", "#f2eded"] : ["#50CD89", "#EE436D", "#FFA800"],
        borderWidth: 1,
        options: {
          legend: {
            display: false
          },
          tooltips: {
            enabled: false
          }
        }
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: false,
        labels: {
          color: '#fff'
          // color: '#f2eded'
        },
      },

    },
    tooltips: {
      enabled: false
    },
    events: []
  }
  return (
    <div>
      <Pie data={data} options={options} />
    </div>
  );
};
export default TimeTrackingPieChart;
