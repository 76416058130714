import { FC, useEffect, useMemo, useState } from "react";
import { baseObj, dummyFileTypes } from "../../../utils/constants";
import { NumberDropdownList } from "../../common/DropdownList";
import {
  CloseIcon,
  GridLayoutIcon,
  HorizontalGridIcon,
  SearchIcon,
} from "../../common/Icons";
import DocumentsTable from "./DocumentsTable";
import DocumentStyles from "./Documents.module.scss";
import {
  ColumnFiltersState,
  PaginationState,
  SortingState,
} from "@tanstack/react-table";
import DebounceSearchInput from "../../Tables/DebounceSearchInput";
import { useLocation } from "react-router-dom";
import { useGetProfileDocumentsQuery } from "../../../app/services/profile";
import CustomDropdownListForFilter from "../../EmployeeTable/CustomDropdownListForFilter";
import DatePopupFoFilter from "../../EmployeeTable/DatePopupFoFilter";
import CustomScrollbar from "../../CustomScrollbar/CustomScrollbar";
import { getAuthUser } from "../../../app/services/dashboard";
import { useSpring, animated } from "react-spring";
import CommonTableSkeleton from "../../TableSkeleton/CommonTableSkeleton";
import { useViewNotificationByTypeMutation } from "../../../app/services/notification";
import { useSelector } from "react-redux";
import classNames from "classnames";
import axios from "../../../axios";
import { endpointUrl } from "../../../app/services/api";
import TablePaginator from "../../Tables/TablePaginator";

interface IDocuments {}

export interface IFilterDate {
  startDate?: any;
  endDate?: any;
}

const Documents: FC<IDocuments> = () => {
  const location = useLocation();
  const [itemCount, setItemCount] = useState<number>(0);
  const [state, setState] = useState(localStorage.getItem("state"));
  const [sorting, setSorting] = useState<SortingState>([]);
  var currentUrl = window.location.pathname
    .split("/employeeprofile/")[1]
    ?.split("/")[0];
  var id = currentUrl ? parseInt(currentUrl) : 0;
  const [filterStates, setFilterStates] = useState<baseObj>({
    name: "All",
    tags: "All",
  });
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [filterDate, setFilterDate] = useState<IFilterDate>();
  const [fileType, setFileType] = useState<string>("");
  const [isTableView, setIsTableView] = useState<boolean>(true);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 1,
    pageSize: 10,
  });
  const [globalSearch, setGlobalSearch] = useState<string>("");
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([
    { id: "filepath", value: "" },
  ]);
  const [data, setData] = useState<baseObj[]>([]);
  const [manageDocument, setManageDocument] = useState<boolean>(false);

  const [viewLeaveByType] = useViewNotificationByTypeMutation();
  const stateData: any = useSelector((state) => state);
  const { data: docData, isLoading } = useGetProfileDocumentsQuery({
    start: filterDate?.startDate?.toDateString(),
    end: filterDate?.endDate?.toDateString(),
    limit: pagination.pageSize,
    search: globalSearch,
    type: fileType,
    tag: filterStates?.tags,
    id: id,
    page: currentPage,
    sort: sorting
      .map(
        (s) =>
          `${s.desc ? "-" : ""}${
            s.id == "lastModifiedAt"
              ? "last_modified_at"
              : s.id == "owner"
              ? "owner_name"
              : s.id == "postedBy"
              ? "posted_user"
              : s.id
          }`
      )
      .join(","),
  });
  var getUser = getAuthUser();
  const getAuthPermis: string[] = useMemo(() => [], []);

  if (getUser?.permissions) {
    const getPer = getUser?.permissions?.forEach((per: any) => {
      getAuthPermis.push(per?.name);
    });
  }
  const changePage = (pageNo: number) => {};

  const props = useSpring({
    to: { opacity: 1 },
    from: { opacity: 0 },
    delay: 500,
  });

  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  const onDownloadClick = (id: number, name: string) => {
    const extension = name.split(".").pop() as string;
    // using Java Script method to get PDF file
    axios({
      url: endpointUrl + `downloadDocument/${id}`,
      method: "GET",
      responseType: "blob",
      headers: {
        Authorization: `Bearer ${getUser.token}`,
        Accept: "application/json",
      },
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", name + "." + extension);
      document.body.appendChild(link);
      link.click();
    });
  };

  useEffect(() => {
    localStorage.removeItem("state");
  }, []);

  useEffect(() => {
    if (location.state) {
      setState(location.state);
    }
  }, [location]);

  useEffect(() => {
    const Manage_Document = getAuthPermis.filter(
      (f) => f === "Manage_Document"
    );

    if (Manage_Document.length > 0) {
      setManageDocument(true);
    }
  }, [getUser, getAuthPermis]);

  useEffect(() => {
    if (fileType === "All") {
      setColumnFilters([
        {
          id: "name",
          value: "",
        },
      ]);
    } else {
      setColumnFilters([
        {
          id: "name",
          value: fileType,
        },
      ]);
    }
  }, [fileType]);

  useEffect(() => {
    if (filterDate?.startDate && filterDate?.endDate) {
      setColumnFilters((prev) => [
        ...prev,
        {
          id: "lastModifiedAt",
          value: filterDate,
        },
      ]);
    } else {
      setColumnFilters((prev) => [
        ...prev,
        {
          id: "lastModifiedAt",
          value: {},
        },
      ]);
    }
  }, [filterDate]);

  // var count = 0;
  // const createZip = () => {
  //   const zip = new JSZip();
  //   var files = dummyDocumentTableData
  //     .map((x) => {
  //       if (x.filepath != "") {
  //         return x;
  //       }
  //     })
  //     .filter((x) => x != undefined);
  //   files.forEach(async function (file, i) {
  //     var filename = file ? file.name : "";
  //     if (file?.filepath) {
  //       var image = await fetch(file.filepath);
  //       var imageBlog = await image.blob();
  //       var img = zip.folder("documents");
  //       img?.file(filename, imageBlog, { binary: true });
  //       count++;
  //       if (count == files.length) {
  //         zip.generateAsync({ type: "blob" }).then(function (content) {
  //           FileSaver.saveAs(content, "test.zip");
  //         });
  //       }
  //     }
  //   });
  // };
  // const onDownloadAllClick = () => {
  //   axios({
  //     url: endpointUrl + `downloadAllMyDocuments`,
  //     method: "GET",
  //     responseType: "blob",
  //   }).then((response) => {
  //     const url = window.URL.createObjectURL(new Blob([response.data]));
  //     const link = document.createElement("a");
  //     link.href = url;
  //     // console.log("url ", url)
  //     link.setAttribute("download", "documents.zip");
  //     document.body.appendChild(link);
  //     link.click();
  //   });
  // }

  useEffect(() => {
    if (docData) {
      setData(docData?.documents?.data);
      setItemCount(docData?.documents?.total);
    }
  }, [docData]);

  useEffect(() => {
    setCurrentPage(1);
  }, [globalSearch, fileType, pagination.pageSize, filterDate]);

  useEffect(() => {
    setTimeout(() => {
      var stateDataDetail = stateData?.dashboard?.notificationDetail;
      var updatedData = {
        profile_unread_noti_count: stateDataDetail?.profile_unread_noti_count,
        unapprove_claim_count: stateDataDetail?.unapprove_claim_count,
        unapprove_leave_count: stateDataDetail?.unapprove_leave_count,
        unapprove_ot_count: stateDataDetail?.unapprove_ot_count,
        unread_data_count: {
          unread_password_count:
            stateDataDetail?.unread_data_count?.unread_password_count,
          unread_document_count:
            stateDataDetail?.unread_data_count?.unread_document_count,
          unread_leave_count: 0,
        },
        unread_count: stateDataDetail?.unread_count,
        notifications: stateDataDetail?.notifications,
      };
      viewLeaveByType("document");
    }, 5000);
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const bgColors = ["#9870D8", "#EDA96B", "#3699FF"];
  // console.log("data", data);

  return (
    <>
      {isLoading ? (
        <CommonTableSkeleton rows={12} isProfile={true} />
      ) : (
        <animated.div style={props}>
          <div className=" bg-white rounded-xl pb-1 w-full">
            {windowSize[0] < 640 ? (
              <div
                className={`flex  flex-col p-5 ${DocumentStyles.downloadButtonContainer}`}
              >
                <h3 className="text-[#3F4254] font-semibold text-[14px] leading-[22px] py-3">
                  Documents
                </h3>
                <div className="flex flex-col gap-3 mb-4">
                  <div className="w-full ">
                    <DebounceSearchInput
                      setState={setGlobalSearch}
                      debounce={800}
                    >
                      {(setState) => (
                        <div className="font-normal flex border-1 border-[#C4C4C4] bg-white hover:bg-[#EFEFEF] rounded-[28px] w-full">
                          <input
                            type="text"
                            placeholder="Search"
                            className="focus:outline-none py-[2px] px-4 text-12 xl:text-14 leading-5 bg-transparent rounded-[28px] w-full text-graydark"
                            onChange={(e) => setState(e.target.value)}
                          />
                          <SearchIcon className="mr-2" />
                        </div>
                      )}
                    </DebounceSearchInput>
                  </div>
                  <div className="flex justify-between w-full">
                    <div
                      className={`mr-3 ${windowSize[0] < 640 ? "w-full" : ""}`}
                    >
                      <DatePopupFoFilter
                        classesProps="min-w-full"
                        placeholder="Start"
                        date={filterDate?.startDate}
                        setDate={(value) => {
                          setFilterDate((prev) => ({
                            ...prev,
                            startDate: value as Date,
                          }));
                          // setDateClean(false);
                        }}
                      />
                    </div>
                    <div className={`mr-0 w-full`}>
                      <DatePopupFoFilter
                        classesProps="min-w-full"
                        placeholder="End"
                        date={filterDate?.endDate}
                        setDate={(value) => {
                          setFilterDate((prev) => ({
                            ...prev,
                            endDate: value as Date,
                          }));
                          // setDateClean(false);
                        }}
                      />
                    </div>
                  </div>
                  <div className="flex items-center">
                    <p className="hidden base-label-sm whitespace-nowrap xl:text-14 text-12">
                      FILE TYPE
                    </p>
                    <div className={`${DocumentStyles.filterFileType} w-full`}>
                      <CustomDropdownListForFilter
                        placeholder="File Type"
                        isFilter={true}
                        state={fileType}
                        setState={setFileType}
                        dropdownItems={dummyFileTypes.map((type) => ({
                          value: type,
                          label: type,
                        }))}
                        textAlign="text-left"
                        classNameCus="py-[0px]"
                        className={`py-0 max-w-full sm:max-w-[250px] 2xl:w-32 sm:w-28 w-full text-[#B6B6B6] ${DocumentStyles.customChevronColor}`}
                      />
                    </div>
                  </div>
                </div>
                <div className="max-h-[440px] min-h-[430px] overflow-y-auto">
                  {data?.length
                    ? data.map((data: any, i: any) => (
                        <div
                          key={i}
                          className="px-3 py-4 bg-[#F3F6F9] rounded-md gap-10 w-full mb-3"
                        >
                          <div className="flex justify-stretch py-2 w-full">
                            <div className="w-1/2">
                              <p className="leading-loose text-10  font-normal text-graydark text-start">
                                {data.name.length
                                  ? data.name.length > 25
                                    ? data.name.slice(0, 25)
                                    : data.name
                                  : "---"}
                              </p>
                            </div>
                            <div className=" w-1/2">
                              <p
                                onClick={() =>
                                  onDownloadClick(data.id, data.name)
                                }
                                className="leading-loose cursor-pointer text-10 text-vorpblue font-normal underline text-end"
                              >
                                Download
                              </p>
                            </div>
                          </div>
                          <hr color="blue" />
                          <div className="flex pt-3 w-full">
                            <p className="leading-loose text-10 w-1/2 font-normal text-graydark text-start">
                              ID
                            </p>
                            <p className=" leading-loose text-black2 w-1/2 text-10 font-normal text-start">
                              {data.code ?? "---"}
                            </p>
                          </div>
                          <div className="flex">
                            <p className="text-10 w-1/2 font-normal text-graydark text-start leading-loose">
                              Owner
                            </p>
                            <p className=" text-black2 w-1/2 text-10 font-normal text-start leading-loose">
                              {data.owner.name.length ? data.owner.name : "---"}
                            </p>
                          </div>
                          <div className="flex">
                            <p className="text-10 w-1/2 font-normal text-graydark text-start leading-loose">
                              Path
                            </p>
                            <p className=" text-black2 w-1/2 text-10 font-normal text-start leading-loose">
                              {data.path.length
                                ? data.path.length > 25
                                  ? data.path.slice(0, 25)
                                  : data.path
                                : "---"}
                            </p>
                          </div>
                          <div className="flex">
                            <p className="text-10 w-1/2 font-normal text-graydark text-start leading-loose">
                              Last Modified
                            </p>
                            <p className=" text-black2 w-1/2 text-10 font-normal text-start leading-loose">
                              {data.lastModifiedAt ?? "---"}
                            </p>
                          </div>
                          <div className="flex">
                            <p className="text-10 w-1/2 font-normal text-graydark text-start leading-loose">
                              Posted By
                            </p>
                            <p className=" text-black2 w-1/2 text-10 font-normal text-start leading-loose">
                              {data.postedBy.name ?? "---"}
                            </p>
                          </div>
                          <div className="flex pt-2 pb-1">
                            <p
                              className={classNames(
                                data.assignUsers.length ? "cursor-pointer" : "",
                                "text-10 w-1/2 font-normal text-vorpblue underline text-start leading-loose"
                              )}
                            >
                              View Members
                            </p>
                          </div>
                          <hr color="blue" />

                          <div className="flex flex-col w-full">
                            <p className="text-10 my-2 w-full block font-normal text-graydark text-start leading-loose">
                              Tags
                            </p>
                            <div className=" w-full text-10 font-normal">
                              {data.tags.map((d: any, i: number) => (
                                <button
                                  style={{
                                    backgroundColor: `${
                                      i > bgColors.length
                                        ? bgColors[1]
                                        : bgColors[i]
                                    }`,
                                  }}
                                  className={classNames(
                                    "px-3 py-1 mr-1 mb-1 rounded-md text-white "
                                  )}
                                >
                                  {d.name}
                                </button>
                              ))}
                            </div>
                          </div>
                          {/* <div className="flex">
                          <p className="text-10 w-1/2 font-normal text-graydark text-start leading-loose">
                            Supportive Document
                          </p>
                          <p
                            onClick={() => {
                              // setLeaveDocuments(data.documents);
                              // setShowLeaveFile(true);
                            }}
                            className={classNames(
                              // data.documents.length
                              true
                                ? "text-vorpblue cursor-pointer"
                                : "text-black2",
                              "  w-1/2 text-10 font-normal text-start leading-loose"
                            )}
                          >
                            {data.documents.length ? "Attached Files" : "---"}
                          </p>
                        </div>
                        <div className="flex">
                          <p className="text-10 w-1/2 font-normal text-graydark text-start leading-loose">
                            Status
                          </p>
                          <div className="flex items-center">
                            <span
                              className={classNames(
                                data.status.includes("Approve")
                                  ? "bg-vorpgreen"
                                  : "bg-graydark",
                                "w-2 h-2 rounded-full mx-2"
                              )}
                            ></span>
                            <p
                              className={classNames(
                                data.status.includes("Approve")
                                  ? "text-vorpgreen"
                                  : "text-black2",
                                "w-1/2 text-10 font-normal text-start leading-loose"
                              )}
                            >
                              {data.status ?? "---"}
                            </p>
                          </div>
                        </div> */}
                          {/* <div className="flex pt-3">
                          {data.cancelOption ? (
                            <button
                              // onClick={() => saveCancel(data.id)}
                              className="leading-loose underline text-10 font-normal text-black2"
                            >
                              Cancel
                            </button>
                          ) : null}
                        </div> */}
                        </div>
                      ))
                    : null}
                </div>

                <div className={"pt-2"}>
                  <TablePaginator
                    total={itemCount}
                    pagination={pagination}
                    setPagination={setPagination}
                    itemCount={itemCount}
                    changePage={changePage}
                    setCurrentPage={setCurrentPage}
                    currentPageDefault={currentPage}
                  />
                </div>
              </div>
            ) : (
              <>
                <div
                  className={`px-2 sm:px-4 md:px-5 py-1 md:py-2 border-b border-offwhite flex items-center justify-between  ${DocumentStyles.downloadButtonContainer}`}
                >
                  <h3 className="text-[#3F4254] font-semibold text-[13px] sm:text-[15px] 2xl:text-[18px] leading-[22px] lg:leading-[27px]">
                    Documents
                  </h3>
                </div>
                <div className="px-2 sm:px-4 pl-2 sm:pl-5 sm:pr-4 md:px-6 7xl:pl-[30px] 7xl:pr-[31px] pt-2">
                  <div className="flex justify-between sm:flex-row flex-col mb-2 md:mb-3">
                    <div className="flex w-full mt-1 mb-1 sm:mr-5 custom-scrollbar doc-scrollbar my-1">
                      <CustomScrollbar isVertical={false} isHor={true}>
                        <div className="flex w-full items-center">
                          <div className="flex items-center mr-3 md:w-[260px] w-[120px] ">
                            <DebounceSearchInput
                              setState={setGlobalSearch}
                              debounce={800}
                            >
                              {(setState) => (
                                <div className="font-normal flex border-1 border-[#C4C4C4] bg-white hover:bg-[#EFEFEF] rounded-[28px] sm:w-[260px] w-[200px]">
                                  <input
                                    type="text"
                                    placeholder="Search"
                                    className="focus:outline-none py-[2px] px-4 text-12 xl:text-14 leading-5 bg-transparent rounded-[28px] w-full text-graydark"
                                    onChange={(e) => setState(e.target.value)}
                                  />
                                  <SearchIcon className="mr-2" />
                                </div>
                              )}
                            </DebounceSearchInput>
                          </div>
                          <div className="flex items-center mr-3">
                            <p className="hidden base-label-sm whitespace-nowrap xl:text-14 text-12">
                              FILE TYPE
                            </p>
                            <div
                              className={`${DocumentStyles.filterFileType} w-full`}
                            >
                              <CustomDropdownListForFilter
                                placeholder="File Type"
                                isFilter={true}
                                state={fileType}
                                setState={setFileType}
                                dropdownItems={dummyFileTypes.map((type) => ({
                                  value: type,
                                  label: type,
                                }))}
                                textAlign="text-left"
                                classNameCus="py-[0px]"
                                className={`py-0 max-w-full sm:max-w-[250px] 2xl:w-32 sm:w-28 w-full text-[#B6B6B6] ${DocumentStyles.customChevronColor}`}
                              />
                            </div>
                          </div>
                          <div className="flex xs:items-center mr-3">
                            <div className="flex items-center mr-3">
                              <p className="base-label-sm xl:text-14 text-12 hidden">
                                DATE
                              </p>
                              {filterDate?.startDate && filterDate?.endDate && (
                                <button
                                  type="button"
                                  onClick={() => setFilterDate({})}
                                  className="text-12"
                                >
                                  <CloseIcon
                                    color="#EE436D"
                                    width={10}
                                    height={10}
                                  />
                                </button>
                              )}
                            </div>
                            <div className="flex items-center">
                              <div
                                className={`mr-3 ${DocumentStyles.leaveDatePicker}`}
                              >
                                <DatePopupFoFilter
                                  placeholder="Start"
                                  date={filterDate?.startDate}
                                  setDate={(value) =>
                                    setFilterDate((prev) => ({
                                      ...prev,
                                      startDate: value as Date,
                                    }))
                                  }
                                />
                              </div>
                              <div
                                className={`mr-3 ${DocumentStyles.leaveDatePicker}`}
                              >
                                <DatePopupFoFilter
                                  placeholder="End"
                                  date={filterDate?.endDate}
                                  setDate={(value) =>
                                    setFilterDate((prev) => ({
                                      ...prev,
                                      endDate: value as Date,
                                    }))
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </CustomScrollbar>
                    </div>
                    <div className="flex items-center justify-end md:w-fit w-full ">
                      <p className="base-label-sm  xl:text-14 text-12">
                        DISPLAY
                      </p>
                      <NumberDropdownList
                        state={pagination.pageSize}
                        setState={(value) =>
                          setPagination((prev) => ({
                            ...prev,
                            pageSize: value as number,
                          }))
                        }
                        dropdownItems={new Array(5)
                          .fill(1)
                          .map((_, index: number) => {
                            let display = (index + 1) * 10;
                            return {
                              value: display,
                              label: String(display),
                            };
                          })}
                        textAlign="text-left pl-4"
                        className="max-h-[34px] max-w-[65px] mr-[10px]"
                        paddingClass="py-[2px]"
                      />
                      <button
                        type="button"
                        onClick={() => setIsTableView(true)}
                        className="mr-[10px] bg-offwhite rounded-lg  flex justify-center items-center min-w-[34px] h-[34px]"
                      >
                        <HorizontalGridIcon isActive={isTableView} />
                      </button>
                      <button
                        type="button"
                        onClick={() => setIsTableView(false)}
                        className="bg-offwhite rounded-lg flex justify-center items-center min-w-[34px] h-[34px]"
                      >
                        <GridLayoutIcon isActive={!isTableView} />
                      </button>
                    </div>
                  </div>

                  {/* Document Table Section */}
                  <DocumentsTable
                    isTableView={isTableView}
                    pagination={pagination}
                    setPagination={setPagination}
                    globalSearch={globalSearch}
                    setGlobalSearch={setGlobalSearch}
                    columnFilters={columnFilters}
                    setColumnFilters={setColumnFilters}
                    filterDate={filterDate}
                    id={Number(id)}
                    data={data}
                    setData={setData}
                    manageDocument={manageDocument}
                    sorting={sorting}
                    setSorting={setSorting}
                    total={itemCount}
                    changePage={changePage}
                    setCurrentPage={setCurrentPage}
                    currentPage={currentPage}
                    onDownloadClick={onDownloadClick}
                  />
                </div>
              </>
            )}
          </div>
        </animated.div>
      )}
    </>
  );
};

export default Documents;
