// import React from "react";
import NotFound from '../../components/PageNotFound/img/401.png'
const AccessDenied = () => {
    return (
        <>
            <div className="relative bg-[#F3F6F9] h-[100vh] overflow-hidden">
                <div className='h-full flex flex-col justify-center'>
                    <div className='relative'>
                        <p className='absolute w-full text-center xl:top-[40px] 5xs:top-5 top-0 font-poppins xl:text-[56px] sm:text-[36px] text-[30px] text-black2 font-semibold'>Server Error</p>
                        <img src={NotFound} alt='401' className='max-w-[85%] mx-auto object-cover sm:h-auto 5xs:h-[400px] h-[300px]' />
                        <div className='absolute w-full text-center 2xl:bottom-[80px] lg:bottom-5 bottom-[-30px] 5xs:px-12 px-8'>
                            <p className='font-poppins 4xl:text-[36px] xl:text-[30px] text-sm text-redPink font-semibold'>Unauthorized: Access is denied due to invalid credentials.</p>
                            <p className='font-poppins xl:text-20 text-14 xl:mt-4 mt-2 text-black2 font-semibold'>You do not have permission to view this directory or page using the credentials that you supplied.</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
// 

export default AccessDenied;
