import React from "react";
import { Scrollbars } from "react-custom-scrollbars";
import classNames from "classnames";

type CustomScrollbarData = {
  children: any;
  isVertical?: boolean;
  isHor?: boolean;
  color?: string;
  width?: any;
};
const CustomScrollbar: React.FC<CustomScrollbarData> = ({
  children,
  isVertical,
  isHor,
  color,
  width,
}) => {
  const renderThumb = (props: any) => {
    const { style, ...rest } = props;
    const thumbStyle = {
      backgroundColor: color ? color : "#3699FF",
      borderRadius: "3px",
      height: "3px",
    };
    return <div style={{ ...style, ...thumbStyle }} {...rest} />;
  };

  const renderThumbHor = (props: any) => {
    const { style, ...rest } = props;
    const thumbStyle = {
      backgroundColor: color ? color : "#3699FF",
      borderRadius: "3px",
      height: "3px",
      bottom: "-6px",
    };
    return (
      <div
        style={{
          ...style,
          ...thumbStyle,
          height: "2px",
          left: "0px",
          bottom: "-6px",
        }}
        {...rest}
      />
    );
  };

  return isVertical != false ? (
    <Scrollbars style={{ width: width }} renderThumbVertical={renderThumb}>
      {children}
    </Scrollbars>
  ) : (
    <Scrollbars renderThumbHorizontal={renderThumbHor}>{children}</Scrollbars>
  );
};

export default CustomScrollbar;
