import {
    ColumnDef,
    ColumnFiltersState,
    createColumnHelper,
    // PaginationState,
    RowSelectionState,
    SortingState,
  } from "@tanstack/react-table";
  import classNames from "classnames";
  import { useMemo, useState, useEffect } from "react";
  import { TemplateData } from "../../app/services/employee";
  import LayoutAdjustableTable from "../Tables/LayoutAdjustableTable";
  import styles from "./TimesheetTable.module.scss";
  // import { baseObj } from "../../utils/constants";
  import TablePaginator from "../Tables/TablePaginator";
  
  const MyTimesheetTable = (props: any) => {
    const columnHelper = useMemo(() => createColumnHelper<any>(), []);
    const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
    const [itemCount, setItemCount] = useState<number>(
      props?.data?.length
    );  
  
    const columns = useMemo(
      () => [
        // columnHelper.display({
        //   header: "",
        //   id: "none0",
        // }),
        // columnHelper.display({
        //   header: "",
        //   id: "none1",
        // }),
        // columnHelper.display({
        //   header: "",
        //   id: "none2",
        // }),
        // columnHelper.display({
        //   header: "",
        //   id: "none3",
        // }),
        // columnHelper.display({
        //   header: "",
        //   id: "none4",
        // }),
        // columnHelper.display({
        //   header: "",
        //   id: "none5",
        // }),
        // columnHelper.display({
        //   header: "",
        //   id: "none6",
        // }),
        // columnHelper.display({
        //   header: "",
        //   id: "none7",
        // }),
        // columnHelper.display({
        //   header: "",
        //   id: "none8",
        // }),
        // columnHelper.display({
        //   header: "",
        //   id: "none9",
        // }),
        // columnHelper.display({
        //   header: "",
        //   id: "none10",
        // }),
        columnHelper.accessor("date", {
          header: "Date",
          id: "date",
        }),
        columnHelper.accessor("employee", {
          header: "Employee",
          id: "employee",
          enableSorting:false
        }),
        columnHelper.accessor("clockIn1", {
          header: "Clock In 1",
          id: "clockIn1",
          enableSorting:false,
          cell: ({ getValue, cell }) => {
            const clockIn = getValue();
            return <div className="flex items-center h-full px-[10px] bg-[#F6FFFA]">
              {clockIn ? clockIn : '---'}
            </div>
          }
        }),
        columnHelper.accessor("clockOut1", {
          header: "Clock Out 1",
          id: "clockOut1",
          enableSorting:false,
          cell: ({ getValue, cell }) => {
            const clockOut = getValue();
            return <div className="flex items-center h-full px-[10px] bg-[#FEF4F4]">
              {clockOut ? clockOut : '---'}
            </div>
          }
        }),
        columnHelper.accessor("location1", {
          header: "Location1",
          id: "location1",
          enableSorting:false,
          cell: ({ getValue, cell }) => {
            const location = getValue();
            var url = "https://maps.google.com/maps?q=" + location + "";
            return <div className="flex items-center h-full ">
              {
                location?.includes('-') ? (
                  '---'
                ) : (
                  <a href={url} target="_blank" className="text-vorpblue">{location?.includes('-') ? '' : location}</a>
                )
              }
            </div>
          }
        }),
        columnHelper.accessor("clockIn2", {
          header: "Clock In 2",
          id: "clockIn2",
          enableSorting:false,
          cell: ({ getValue, cell }) => {
            const clockIn = getValue();
            return <div className="flex items-center h-full px-[10px] bg-[#F6FFFA]">
              {clockIn ? clockIn : '---'}
            </div>
          }
        }),
        columnHelper.accessor("clockOut2", {
          header: "Clock Out 2",
          id: "clockOut2",
          enableSorting:false,
          cell: ({ getValue, cell }) => {
            const clockOut = getValue();
            return <div className="flex items-center h-full px-[10px] bg-[#FEF4F4]">
              {clockOut ? clockOut : '---'}
            </div>
          }
        }),
        columnHelper.accessor("location2", {
          header: "Location2",
          id: "location2",
          enableSorting:false,
          cell: ({ getValue, cell }) => {
            const location = getValue();
            var url = "https://maps.google.com/maps?q=" + location + "";
            return <div className="flex items-center h-full">
              {
                location?.includes('-') ? (
                  '---'
                ) : (
                  <a href={url} target="_blank" className="text-vorpblue">{location?.includes('-') ? '' : location}</a>
                )
              }
            </div>
          }
        }),
        columnHelper.accessor("clockIn3", {
          header: "Clock In 3",
          id: "clockIn3",
          enableSorting:false,
          cell: ({ getValue, cell }) => {
            const clockIn = getValue();
            return <div className="flex items-center h-full px-[10px] bg-[#F6FFFA]">
              {clockIn ? clockIn : '---'}
            </div>
          }
        }),
        columnHelper.accessor("clockOut3", {
          header: "Clock Out 3",
          id: "clockOut3",
          enableSorting:false,
          cell: ({ getValue, cell }) => {
            const clockOut = getValue();
            return <div className="flex items-center h-full px-[10px] bg-[#FEF4F4]">
              {clockOut ? clockOut : '---'}
            </div>
          }
        }),
        columnHelper.accessor("location3", {
          header: "Location3",
          id: "location3",
          enableSorting:false,
          cell: ({ getValue, cell }) => {
            const location = getValue();
            var url = "https://maps.google.com/maps?q=" + location + "";
            return <div className="flex items-center h-full">
              {
                location?.includes('-') ? (
                  '---'
                ) : (
                  <a href={url} target="_blank" className="text-vorpblue">{location?.includes('-') ? '' : location}</a>
                )
              }
            </div>
          }
        }),
        columnHelper.accessor("total_hours", {
          header: "Total Hours",
          id: "total_hours",
          enableSorting:false,
          cell: ({ getValue, cell }) => {
            const clockIn = getValue();
            return <div className="flex items-center h-full px-[10px] bg-[#F6FFFA]">
              {clockIn ? clockIn : '---'}
            </div>
          }
        }),
      ],
      [columnHelper, props.data]
    );
    
    const [columnOrder, setColumnOrder] = useState<string[]>(
      columns?.map((column: ColumnDef<any, unknown>) => column?.id as string)
    );
  
    document.querySelectorAll("th,td").forEach((x) => {
      if (x.id.includes("none")) {
        x.classList.add("hidden");
      }else{
        x.classList.remove("absolute");
        x.classList.add("left-0");
        x.classList.add("relative");
        var div = document.getElementById(x.id);
        if (div) {
          div.style.setProperty("left", "");
        }
        if (x.id.includes("action")) {
          x.classList.add("absolute");
          x.classList.remove("relative");
          x.classList.add("right-0");
          x.classList.remove("left-0");
        }
      }
      
    });
  
    const visibleColumns: { [key: string]: boolean } = {};
    props.hiddenFields?.map((x: string) => {
      visibleColumns[x] = true;
    });
    if (props?.hiddenFields?.length > 0) {
      columns.map((x: any) => {
        if (x.id == "actions" || x.id == "selection") {
          visibleColumns[x.id ? x.id : ""] = true;
        } else if (x.id?.includes("none")) {
          visibleColumns[x.id ? x.id : ""] = true;
        } else {
          var isHidden = props.hiddenFields.find((h: any) => h == x.id);
          if (isHidden) {
            visibleColumns[x.id ? x.id : ""] = true;
          } else {
            visibleColumns[x.id ? x.id : ""] = false;
          }
        }
      });
    }
    // console.log("visibleColumns ",visibleColumns)
    const saveTemplateData = (template: any) => {
      var views = columns?.map((x: any) => {
        if (x.id) {
          if (template[x.id] == undefined) {
            return x.id;
          }
        }
      })
        .filter((x: any) => x != undefined);
      var viewsData = views.map((x: any) => (x != undefined ? x : ""));
      var newTemplate: TemplateData = {
        id: 0,
        name: "View" + props.templateList.length + 1,
        viewColumns: viewsData,
      };
      props.setHiddenFields(viewsData);
      props.setCurrentView("");
    };
  
    useEffect(() => {
      if (props?.hiddenFields?.length > 0) {
        setColumnOrder(["selection", ...props?.hiddenFields]);
      }
    }, [props?.hiddenFields])
  
    return (
      <div className={classNames(styles.myTimeSheet,styles.myTimeSheetCustom, "relative inline-block max-w-full")}>
        <LayoutAdjustableTable
          saveTemplateData={saveTemplateData}
          hideColumn={visibleColumns}
          columns={columns}
          data={props.data}
          sorting={props.sorting}
          setSortingState={props.setSorting}
          rowSelection={rowSelection}
          setRowSelection={setRowSelection}
          columnOrder={columnOrder}
          setColumnOrder={setColumnOrder}
          unDraggableFields={[]}
          //unDraggableFields={['date','employee','clockIn1', 'clockOut1', 'clockIn2', 'clockOut2', 'clockIn3', 'clockOut3','location','location2','location3','total_hours']}
          columnFilters={columnFilters}
          setColumnFilters={setColumnFilters}
          setItemCount={setItemCount}
          pagination={props.pagination}
          setPagination={props.setPagination}
          globalFilter={props.globalSearch}
          setGlobalFilter={props.setGlobalSearch}
          isLayoutControlOpen={props.isLayoutControlOpen}
          setIsLayoutControlOpen={props.setIsLayoutControlOpen}
          localModuleId={props.localModuleId}
          setCols={props?.setCols}
          setIsTemplate={props?.setIsTemplate}
          noPaddingCells={['clockIn1', 'clockOut1', 'clockIn2', 'clockOut2', 'clockIn3', 'clockOut3']}
          
        />
        <div className="pt-6">
          {props.total ? <TablePaginator
            total={props.total}
            pagination={props.pagination}
            setPagination={props.setPagination}
            itemCount={props.total}
            setCurrentPage={props.setCurrentPage}
            currentPageDefault={props.currentPage}
            changePage={props.changePage}
          /> : ''}
  
        </div>
      </div>
    );
  };
  export default MyTimesheetTable;
  