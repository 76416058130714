import { ReactElement } from 'react';
import BasicLayout from '../layouts/BasicLayout';
import { Outlet } from 'react-router-dom';
import EmployeeLeave from './views/EmployeeLeave';

const EmployeeLeaveRoute = (props:any): ReactElement => {
  const data=props.loginData;
  const permissions = props.permissions;

 return (
    <BasicLayout userData={data} permissions={permissions}>
      <EmployeeLeave />
    </BasicLayout>
  )
};

export default EmployeeLeaveRoute;
