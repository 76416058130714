import { ReactElement } from 'react';
import BasicLayout from '../layouts/BasicLayout';
import { Outlet } from 'react-router-dom';
import AnnoucementList from './views/AnnoucementList';

const AnnoucementListRoute = (props: any): ReactElement => {
    const data = props.loginData;
    const permissions = props.permissions;
    return (
        <BasicLayout userData={data} permissions={permissions}>
            <AnnoucementList />
            <Outlet />
        </BasicLayout>
    )
};

export default AnnoucementListRoute;
