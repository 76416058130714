import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styles from "./DashboardAnnoucement.module.scss";
import annoarrow from "./img/anno-arrow.svg";
import { renderDateFormatYMD, renderDateFormatYMDTFormat } from "../DashboardCalendarView/DashboardAddEventPopup";
// import { Modal, Tooltip, makeStyles, Fade } from "@material-ui/core";//Modal
import { Modal, Tooltip, Fade } from "@mui/material"
import { makeStyles } from '@mui/styles';

import whiteCancel from "../common/img/white-cancel.svg";
// import Scrollbars from "react-custom-scrollbars";
// import { renderThumb } from "../DashboardDocument/DashboardDocument";
import CustomScrollbar from "../CustomScrollbar/CustomScrollbar";
// import { Modal } from 'react-responsive-modal';
import "react-responsive-modal/styles.css";
import { getAuthUser, useViewAnnoucementMutation } from "../../app/services/dashboard";

var classNames = require("classnames");
const useStyles = makeStyles(() => ({
  tooltip: {
    backgroundColor: "transparent",
    margin: 0,
    marginRight: 8,
    padding: 0,
  },
}));

const colors = ["#FF4B55", "#1BC5BD", "#FFCE96 ", "#EEA0FF"];
const colorsList = ["#1BC5BD", "#F64E60", "#FFA800"];
const cautionColor = "#1BC5BD";
const warningColor = "#F64E60";
const noticeColor = "#FFA800";
var index = Math.round(Math.random() * (colors.length - 0) + 0);


const DashboardAnnoucement = (props: any) => {
  const permission = getAuthUser()?.permissions?.find((x: any) => x.name == "View_Announcement");
  const twoCol = props.twoCol ? props.twoCol : false;
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [clickData, setClickData] = useState<any>();
  const [view] = useViewAnnoucementMutation();
  const [annoucementData,setAnnoucementData]=useState<any>(props.annoucements);
  useEffect(()=>{
    setAnnoucementData(props.annoucements);
  },[props.annoucements])
  const classes = useStyles();
  const handleOpen = (d: any) => {
    setIsOpen(true)
    setClickData(d)
  }
  const viewAnnoucement = (id: number) => {
    setAnnoucementData((current:any) =>
      current.map((obj:any) => {
        if (obj.id === id) {
          return {...obj, is_read: true};
        }

        return obj;
      }),
    );
    view(id);
  }

  return (
    <>
      <Modal
        open={isOpen}
        style={{
          background: "rgb(0 0 0 / 0.3)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
            setIsOpen(false);
          }
        }
        }
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableEnforceFocus={true}
      >
        <Fade in={isOpen} timeout={500}>
          <div className=" modalContent w-[530px] sm:max-w-full max-w-[90%] max-h-[90%] focus-visible:outline-none overflow-auto custom-scrollbar">
            <div id="modal-modal-title" className="bg-vorpblue relative flex justify-between py-6 px-10 rounded-t-xl">
              <h3 className="text-18 font-primary font-semibold text-white">{clickData?.title}</h3>
              <button className="flex items-center justify-center" onClick={() => { setIsOpen(false) }}><img src={whiteCancel} alt="close modal icon" /></button>
            </div>
            <div id="modal-modal-description" className="pt-4 max-h-[500px] overflow-y-scroll pb-9 px-10 bg-white rounded-b-xl">
              <div className="mb-[0.688rem] last:mb-0">
                <div className="flex justify-between mb-5 items-center">
                  <div className=" flex flex-wrap">
                    {
                      clickData?.tags?.length > 0 && (
                        clickData?.tags?.map((d: any) => {
                          return <p
                            key={Math.random() * 1000}
                            className={classNames(`mx-1 my-1 text-12 text-white w-auto inline-block 
                            border rounded-[100px] leading-[18px] font-primary 
                            py-[3px] px-4 justify-center items-center`)}
                            style={clickData.type == "Caution" ? { backgroundColor: colorsList[0] } :
                              clickData.type == "Notice" ? { backgroundColor: colorsList[2] } :
                                clickData.type == "Warning" ? { backgroundColor: colorsList[1] } :
                                  { backgroundColor: colorsList[2] }}
                          >
                            {d ? d : ''}
                          </p>
                        })
                      )
                    }
                  </div>
                  <div>
                    {clickData?<p
                      className={classNames(
                        "my-1 whitespace-nowrap flex text-left mr-3 font-poppins text-xs font-normal text-[#858795] ", 
                        clickData?.type=="Caution"?
                        styles.cautionDiv:
                        clickData?.type=="Notice"?
                        styles.noticeDiv:
                        clickData?.type=="Warning"?styles.warningDiv:'hidden'
                      )}
                    >
                      {clickData.type?clickData.type:''}
                    </p>:''}
                    
                  </div>
                </div>
                <div className="text-black2 text-15 font-medium font-primary mb-[0.688rem] block" dangerouslySetInnerHTML={{ __html: clickData?.content }}></div>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>

      <div className={classNames(
        styles.annoucementContainer,
        " pt-[18px] bg-white overflow-hidden",
        twoCol ? "rounded-xl h-[400px]" : "h-full min-h-[410px]"
      )}
      >
        <div
          className={classNames("flex items-center justify-between lg:mb-[12px] mb-1 px-2 5xs:px-4 sm:px-5")}
        >
          <p
            className={classNames(
              styles.annoTitle,
              "font-primary text-black2 text-14 xl:text-18 font-semibold"
            )}
          >
            Announcement
          </p>
          {twoCol ? (
           <Link to={"/profile/announcement" }>
           <button
             className={classNames(
               styles.button,
               "bg-offwhite flex items-center justify-center rounded-md w-9 h-9"
             )}
             aria-label=""
           >
             <img src={annoarrow} alt="annoarrow-icon" />
           </button>
         </Link>
          ) : (
            <Link to={permission ? "/announcement" : '#'}>
              <button
                className={classNames(
                  styles.button,
                  "bg-offwhite flex items-center justify-center rounded-md w-9 h-9"
                )}
                aria-label=""
              >
                <img src={annoarrow} alt="annoarrow-icon" />
              </button>
            </Link>
          )}
        </div>
        <div
          className={classNames(
            // styles.activiesBox,
            "overflow-hidden pl-2 5xs:pl-4 sm:pl-5 lg:pr-2 sm:pr-5 5xs:pr-4 pr-2",
            twoCol ? " h-full  max-h-[340px]" : " h-full  lg:max-h-[470px] max-h-[300px]"
          )}
        >
          <CustomScrollbar isVertical={true}>
            <ul className={classNames(styles.annoucementContent, "pr-3")}>
              {annoucementData &&
                annoucementData?.map((d: any, index: any) => {
                  return (
                    <li
                      className={classNames((d.is_read==false) ?styles.annoucementRead+' bg-annhoverbg':'bg-anncolor',"last:pb-4  px-[10px] 2xl:px-[8px] py-[5px] 2xl:py-[8px] mb-2 last:mb-0 group hover:bg-annhoverbg rounded-xl cursor-pointer")}
                      key={`annoucement-${index}`}
                      onClick={() => { handleOpen(d); viewAnnoucement(d.id) }}
                    >
                      {twoCol ? (
                        <div className="flex items-center flex-col w-full">
                          <div className="w-full text-left flex flex-wrap flex-col 4xs:flex-row items-center justify-between pr-5">
                            <p className="w-auto text-12 text-graydark font-primary">
                              {
                                renderDateFormatYMDTFormat(new Date(d?.postedAt), "-")
                              }
                            </p>
                            <div className=" flex flex-wrap justify-end">
                              {d.tags?.length > 0 && (
                                <p
                                  key={Math.random() * 1000}
                                  className={classNames(`mx-1 my-1 text-12 text-white w-auto inline-block 
                                          border rounded-[100px] leading-[18px] font-primary 
                                          py-[3px] px-4 justify-center items-center`)}
                                  style={d.type == "Caution" ? { backgroundColor: colorsList[0] } :
                                    d.type == "Notice" ? { backgroundColor: colorsList[2] } :
                                      d.type == "Warning" ? { backgroundColor: colorsList[1] } :
                                        { backgroundColor: colorsList[1] }}
                                >
                                  {d.tags ? d.tags[0] : ''}
                                </p>
                                // <>
                                //   <Tooltip
                                //     arrow
                                //     classes={{ tooltip: classes.tooltip }}
                                //     enterTouchDelay={0}
                                //     leaveTouchDelay={5000}
                                //     placement="left"
                                //     title={
                                //       <div className="text-white text-[10px] hover:cursor-pointer leading-[15px] z-[2] rounded p-4 w-full bg-tooltipbg">
                                //         <p
                                //           className="mx-1 my-1 text-12 text-white w-auto inline-block 
                                //                 font-primary 
                                //                 py-[3px] justify-center items-center"
                                //         >
                                //           {d.tags?.join(', ')}
                                //         </p>
                                //       </div>
                                //     }
                                //   >
                                //     <p
                                //       key={Math.random() * 1000}
                                //       className={classNames(`mx-1 my-1 text-12 text-white w-auto inline-block 
                                //                 border rounded-[100px] leading-[18px] font-primary 
                                //                 py-[3px] px-4 justify-center items-center`)}
                                //       style={d.type == "Caution" ? { backgroundColor: colorsList[0] } :
                                //         d.type == "Notice" ? { backgroundColor: colorsList[1] } : { backgroundColor: colorsList[2] }}
                                //     >
                                //       {d.tags ? d.tags[0] : ''}
                                //     </p>
                                //   </Tooltip>
                                //   <div className="relative hover:cursor-pointer pl-1"></div>
                                // </>
                              )}
                            </div>
                          </div>
                          <div className="w-full mt-1">
                            <div dangerouslySetInnerHTML={{ __html: d.title.replaceAll('&nbsp;', '') }}
                              className={classNames(
                                styles.andesp,
                                styles.twoColandes,
                                "line-clamp-1 text-14 text-left text-black2 font-primary font-semibold group-hover:text-vorpblue"
                              )}
                            >
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="flex items-center flex-col 2xl:flex-row w-full pr-5">
                          <div className="w-full 2xl:w-[70%] text-left">
                            <p className="text-12 text-graydark font-primary">
                              {d.postedAt}
                            </p>
                            <div dangerouslySetInnerHTML={{
                              __html: d?.title
                              //d.content.replaceAll('&nbsp;','') 
                            }}
                              className={classNames(
                                styles.andesp,
                                "line-clamp-1 text-14 text-black2 font-primary font-semibold group-hover:text-vorpblue"
                              )}
                            >
                              {/* {d.title} */}

                            </div>
                          </div>
                          <div className="w-full 2xl:w-[30%] mt-2 sm:mt-0 flex flex-wrap justify-end">
                            {d.tags?.length > 0 && (
                              <p
                                key={Math.random() * 1000}
                                className="mx-1 my-1 text-12 text-white w-auto inline-block 
                                           border rounded-[100px] leading-[18px] font-primary 
                                           py-[3px] px-4 justify-center items-center"
                                style={d.type == "Caution" ? { backgroundColor: colorsList[0] } :
                                  d.type == "Notice" ? { backgroundColor: colorsList[2] } :
                                    d.type == "Warning" ? { backgroundColor: colorsList[1] } :
                                      { backgroundColor: colorsList[2] }}
                              >
                                {d.tags ? d.tags[0] : ''}
                              </p>
                              // <>
                              //   <Tooltip
                              //     arrow
                              //     classes={{ tooltip: classes.tooltip }}
                              //     enterTouchDelay={0}
                              //     leaveTouchDelay={5000}
                              //     placement="left"
                              //     title={
                              //       <div className="text-white text-[10px] hover:cursor-pointer leading-[15px] z-[2] rounded p-4 w-full bg-tooltipbg">
                              //         <p
                              //           className="mx-1 my-1 text-12 text-white w-auto inline-block 
                              //                   font-primary 
                              //                   py-[3px] justify-center items-center"
                              //         >
                              //           {d.tags?.join(', ')}
                              //         </p>
                              //       </div>
                              //     }
                              //   >
                              //     <p
                              //       key={Math.random() * 1000}
                              //       className="mx-1 my-1 text-12 text-white w-auto inline-block 
                              //                   border rounded-[100px] leading-[18px] font-primary 
                              //                   py-[3px] px-4 justify-center items-center"
                              //       style={d.type == "Caution" ? { backgroundColor: colorsList[0] } :
                              //         d.type == "Notice" ? { backgroundColor: colorsList[1] } : { backgroundColor: colorsList[2] }}
                              //     >
                              //       {d.tags ? d.tags[0] : ''}
                              //     </p>
                              //   </Tooltip>
                              //   <div className="relative hover:cursor-pointer pl-1"></div>
                              // </>
                            )}
                          </div>
                        </div>
                      )}
                    </li>
                  );
                })}
            </ul>
          </CustomScrollbar>

        </div>


      </div>
    </>
  );
};
export default DashboardAnnoucement;
