import authHeader from "./auth-header";
import { IleaveType } from "../../leave-application/views/LeaveApplication";
import { api, endpointUrl } from "./api";
import { IOTData } from "../../ot-list/views/OTList";
import { redirectToLogin } from "./annoucement-sheet";
import { baseObj } from "../../utils/constants";

export type LeaveResponse = { data: OTData[]; module_id: number };

export type OTListData = {
  id: number;
  code: string;
  employee_name: string;
  employee_code: string;
  department_name: string;
  department_id: 2;
  date: string;
  period: string;
  type: string;
  reason: string;
  time_in: null | string;
  time_out: null | string;
  total_time: null | string;
  created_at: string;
  updated_at: string;
};

type OTDataList = {
  current_page: number;
  data: OTListData[];
  total: number;
};

type OTData = {
  overtimeList: OTDataList;
  module_id: number;
  data: baseObj[];
  limit?: number;
  page?: number;
  department?: number | string;
  search?: string;
  start?: string;
  end?: string;
  period?: string;
  code?: number | string;
  deaprtment?: number | string;
  id?: number;
  sort?: string;
  status?: string;
};
export type LeaveDataWithPagination = {
  current_page: number;
  from: number;
  to: number;
  total: number;
  per_page: number;
  data: IOTData[];
  first_page_url: string;
  last_page_url: string;
  next_page_url: string;
};
type IOTInfo = {
  id: number;
  fromData: string;
  toData: string;
  fullHalfDay: string;
  otType: string;
  reason: string;
  empCode: string;
  empName: string;
  department: string;
  timeIn: string;
  timeOut: string;
  otAmount: string;
};
export type EditLeave = {
  from_date: string;
  to_date: string;
  full_half_day: string;
  total_days: number;
  leave_type_id: number;
  reason: string;
};

export type TDeleteResponse = {
  status: boolean;
  message: string;
};


export const otApi = api.injectEndpoints({
  endpoints: (build) => ({
    getOTList: build.query<OTData, Partial<OTData>>({
      query: ({ page, limit, department, search, start, end, period, sort, status }) => ({
        url: `${endpointUrl}over-time?page=${page}&limit=${limit}&department_id=${department}&search=${search}&start=${start}&end=${end}&period=${period}&sort=${sort}&status=${status}`,
        headers: authHeader(),
        method: "GET",
      }),
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
      providesTags: (result: any = []) => {
        if (result?.overtimeList?.data?.length > 0) {
          return [
            ...result?.overtimeList?.data?.map(
              (id: any) => ({ type: "OTList", id } as const)
            ),
            { type: "OTList" as const, id: "LIST" },
          ];
        } else {
          return [{ type: "OTList" as const, id: "LIST" }];
        }
      },
    }),

    getEmployeeOTList: build.query<OTData, Partial<OTData>>({
      query: ({ search, code, department }) => ({
        url: `${endpointUrl}over-time-employees?department_id=${department}&search=${search}&employee_code=${code}`,
        headers: authHeader(),
        method: "GET",
      }),
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
      providesTags: (result: any = []) => {
        if (result.length > 0) {
          return [
            ...result?.leaveData?.data?.map(
              (id: any) => ({ type: "OTList", id } as const)
            ),
            { type: "OTList" as const, id: "LIST" },
          ];
        } else {
          return [{ type: "OTList" as const, id: "LIST" }];
        }
      },
    }),

    createOTList: build.mutation<OTData, Partial<IOTInfo>>({
      query: (body) => ({
        url: `${endpointUrl}over-time`,
        method: "POST",
        body,
        headers: authHeader(),
      }),
      invalidatesTags: (permissions) => [{ type: "OTList", id: "LIST" }],
    }),

    updatedOTList: build.mutation<OTData, Partial<OTData>>({
      query: (body) => ({
        url: `${endpointUrl}over-time/${body.id}`,
        headers: authHeader(),
        method: "PUT",
        body,
      }),
      invalidatesTags: () => [{ type: "OTList", id: "LIST" }],
    }),

    deleteOTList: build.mutation<TDeleteResponse, number[]>({
      query(ids) {
        return {
          headers: authHeader(),
          url: `${endpointUrl}delete-over-times`,
          body: { ids },
          method: "DELETE",
        };
      },
      invalidatesTags: () => {
        return [{ type: "OTList", id: "LIST" }];
      },
    }),

    deleteOT: build.mutation<TDeleteResponse, number>({
      query(id) {
        return {
          url: `${endpointUrl}over-time/${id}`,
          method: "DELETE",
          headers: authHeader(),
        };
      },
      invalidatesTags: (data) => [{ type: "OTList", id: "LIST" }],
    }),

    rejectOT: build.mutation<TDeleteResponse, number>({
      query(id) {
        return {
          url: `${endpointUrl}over-time/${id}/reject`,
          method: "POST",
          headers: authHeader(),
        };
      },
      invalidatesTags: (data) => [{ type: "OTList", id: "LIST" }],
    }),


    approveOT: build.mutation<TDeleteResponse, number>({
      query(id) {
        return {
          url: `${endpointUrl}over-time/${id}/approve`,
          method: "POST",
          headers: authHeader(),
        };
      },
      invalidatesTags: (data) => [{ type: "OTList", id: "LIST" }],
    }),



  }),
});

export const {
  useGetOTListQuery,
  useGetEmployeeOTListQuery,
  useCreateOTListMutation,
  useDeleteOTMutation,
  useDeleteOTListMutation,
  useUpdatedOTListMutation,
  useRejectOTMutation,
  useApproveOTMutation
} = otApi;

export const {
  endpoints: {
    getOTList,
    createOTList,
    deleteOT,
    deleteOTList,
    getEmployeeOTList,
    updatedOTList,
    rejectOT,
    approveOT,
  },
} = otApi;
