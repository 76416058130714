// import { Modal, Fade } from "@material-ui/core";
import { Fade, Modal } from '@mui/material';
// import Modal from '@mui/material/Modal';
import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { CloseIcon, SelectedIcon } from "../common/Icons";
import { NumberDropdownList, StringDropdownList } from "../common/DropdownList";
import { roles } from "../../utils/constants";
import { CSVLink } from "react-csv";
import classNames from "classnames";

interface IExportUsersModal {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  exportToCSV?: any;
  exportToExcel?: any;
  exportToPDF?: any;
  exportToZip?: any;
  isExporting?: boolean;
  setIsExporting?: Dispatch<SetStateAction<boolean>>;
  isShowOnlyCheckbox?: boolean;
}

interface IFormData {
  role: number;
  format: string;
}

// const useStyles = makeStyles({
//   customTransition: {
//     transitionDuration: '3s',
//     transitionTimingFunction: 'ease-out',
//     // Add any other custom transition properties
//   },
// });

const exportFileFormats = ["Excel", "CSV"];//ZIPPDF

const ExportUsersModal: FC<IExportUsersModal> = ({
  isOpen,
  setIsOpen,
  exportToCSV,
  exportToExcel,
  exportToPDF,
  isExporting,
  setIsExporting,
  isShowOnlyCheckbox
  // exportToZip
}) => {
  const [formData, setFormData] = useState<IFormData>({
    role: 10,
    format: "PDF",
  });
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const exportDataCustom = () => {
    var checkData = isChecked == true ? '1' : '0';
    if (formData.format == "CSV") {
      exportToCSV('csv', checkData);
    } else if (formData.format == "Excel") {
      exportToExcel('excel', checkData);
    } else if (formData.format == "PDF") {
      exportToPDF('pdf', checkData);
    }
    if (setIsExporting) {
      setIsExporting(true);
    }
  }
  // const classes = useStyles();
  useEffect(() => {
    setIsChecked(false);
  }, [isOpen])

  
  return (
    <Modal
      open={isOpen}
      style={{
        background: "rgb(0 0 0 / 0.3)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      // onClose={() => setIsOpen(false)}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
          // Set 'open' to false, however you would do that with your particular code.
          setIsOpen(false);
        }
      }
      }
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableEnforceFocus={true}
    >
      <Fade in={isOpen} timeout={500}>
        <div className="modalContent max-w-[530px] w-full m-auto px-4 py-8 xl:px-0 xl:py-0 font-primary">
          <div className="w-full bg-white rounded-xl">
            <div className="px-6 sm:px-8 md:px-10 py-6 bg-vorpblue flex justify-between items-center rounded-t-xl overflow-hidden">
              <h1 className="text-16 sm:text-18 leading-[27px] font-semibold text-white">
                Export
              </h1>
              <button type="button" onClick={() => setIsOpen(false)}>
                <CloseIcon width={18} height={18} />
              </button>
            </div>
            <div className="px-4 sm:px-6 lg:px-10 py-6 pb-8 lg:py-8 lg:pb-10">
              <div className="mb-5 hidden">
                <p className="base-label mb-2">Select Roles:</p>
                <NumberDropdownList
                  state={formData.role}
                  setState={(value) =>
                    setFormData((prev) => ({ ...prev, role: value as number }))
                  }
                  dropdownItems={roles.map((role) => ({
                    value: role.value,
                    label: role.label,
                  }))}
                  className="w-full 6xs:text-12 sm:text-14"
                  placeholder="All"
                  textAlign="text-left py-2 pl-2 pr-3"
                />
              </div>

              <div className="mb-[30px]">
                <p className="base-label mb-2">
                  Select Export Format:
                  <span className="text-[#F64E60]">*</span>
                </p>
                <StringDropdownList
                  state={formData.format}
                  setState={(value) =>
                    setFormData((prev) => ({ ...prev, format: value as string }))
                  }
                  dropdownItems={exportFileFormats.map((format) => ({
                    value: format,
                    label: format,
                  }))}
                  className="w-full 6xs:text-12 sm:text-14 py-[5px]"
                  placeholder="All"
                  textAlign="text-left py-2 pl-2 pr-3"
                />
              </div>
              {isShowOnlyCheckbox == true ?
                <div className='mb-5'>
                  <label
                    htmlFor="update-existing"
                    className="relative max-h-[24px] flex items-center hover:cursor-pointer"
                  >
                    <input
                      type="checkbox"
                      id="update-existing"
                      className=" checked:bg-vorpblue w-3 h-3 sm:w-4 sm:h-4 p-3 border-none outline-none appearance-none bg-gray-200 rounded hover:cursor-pointer"
                      checked={isChecked}
                      onChange={(e) =>
                        setIsChecked(e.target.checked)
                      }
                    />
                    {isChecked && (
                      <SelectedIcon className="absolute top-2 left-1.5 right-0" />
                    )}
                    <p className="text-12 sm:text-14 leading-5 text-black2 ml-3">
                      Export Only This Page
                    </p>
                  </label>
                </div> : ''}
              <div className="flex items-center">
                <button
                  type="button"
                  onClick={() => exportDataCustom()}
                  className={classNames(isOpen && isExporting ? 'opacity-50 pointer-events-none' : '', "py-2 px-4 bg-vorpblue rounded-md text-white text-12 leading-[18px] font-semibold")}
                >
                  {isOpen && isExporting ? 'Exporting' : 'Export'}
                </button>
                <button
                  type="button"
                  onClick={() => setIsOpen(false)}
                  className={classNames(isOpen && isExporting ? 'opacity-50 pointer-events-none' : '', "ml-5 text-12 leading-[18px] font-semibold text-[#B5B5C3]")}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default ExportUsersModal;
