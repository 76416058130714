import { Employee } from '../../active-time-report/views/ActiveTimeReport';
import { redirectToLogin } from './annoucement-sheet';
import { api, endpointUrl } from './api';
import authHeader from './auth-header';
import { TemplateData } from './employee';
export type DashboardResponse = { data: TimeSheetData[] };
export type TimeSheet = {
  id: number;
  date: string,
  employee: string;
  clockIn1: string;
  clockOut1: string;
  location1: string;
  clockIn2: string;
  clockOut2: string;
  location2: string;
  clockIn3: string;
  clockOut3: string;
  location3: string;
}

export type TimeSheetDataResponse = {
  //data: TimeSheetData;
  module_id: number,
  data: TimeSheet[],
  current_page: number;
  from: number;
  to: number;
  total: number;
  per_page: number;
  first_page_url: string;
  last_page_url: string;
  next_page_url: string;
}
export type TimeSheetDataResponseCus = {
  //data: TimeSheetData;
 
  data: TimeSheet[],
  current_page: number;
  from: number;
  to: number;
  total: number;
  per_page: number;
  first_page_url: string;
  last_page_url: string;
  next_page_url: string;
}
export type TimeSheetDataResponseCusList={
  data:TimeSheetDataResponseCus,
  module_id: number,
}
export type EmpyTimeSheetDataResponse = {
  employeeList: EmpyTimeSheet[];
}

export type TimeSheetDetailResponse = {
  data: TimeSheetDetail
}

export type TimeSheetDetail = {
  employee_id: string | number,
  date: string,
  startOfWork: string,
  endOfWork: string,
  totalTime: string,
  active: string,
  verifiedDeducted: string,
  verifiedActive: string,
  downTime: string,
  lateness: string,
  overTime: string,
  productive: string,
  unproductive: string,
  neutral: string,
  remar: string,
  productive_percentage: number | any,
  unproductive_percentage: number | any,
  neutral_percentage: number | any,
  graphlabels: string[],
  graphData: number[],
  name: string,
  last_updated_at?: string | number;
}

export type EmpyTimeSheet = {
  id: number,
  name: string,
  year_w_vo: number,
  age: number,
  department: string,
  designation: string,
  country: string
}
export type TimeSheetResponse = {   
data: MyTimeSheet[];
current_page: number;
from: number;
to: number;
total: number;
per_page: number;
first_page_url: string;
last_page_url: string;
next_page_url: string;
};

export type MyTimeSheetResponse = {
  // timesheets: MyTimeSheet[],
  timesheets: TimeSheetResponse,
}

// export type TimeSheetData ={

// }

export type MyTimeSheet = {
  date: string,
  employee_id: 4,
  employee: string,
  clockIn1: string,
  clockOut1: string,
  location1: string,
  clockIn2: string,
  clockOut2: string,
  location2: string,
  clockIn3: string,
  clockOut3: string,
  location3: string,

}

type TimeSheetData = {
  timesheets: TimeSheet[];
  data: TimeSheet[];
  employeeList: Employee[];
  total: number;
  start?: string,
  end?: string,
  limit?: number,
  page: number,
  search: string,
  sort?:string;
}

export type TimeSheetRequest = {
  id?: number | 0;
  limit: number;
  page:number;
  sort?:string;
}

type PTimeSheetData = {
  timesheets: TimeSheet[];
  data: TimeSheet[];
  employeeList: Employee[];
  total: number;
  start?: string,
  end?: string,
  limit?: number,
  id: number,
  page?: number,
  sort?:string

}
export const timesheetdApi = api.injectEndpoints({
  endpoints: (build) => ({
    getTimesheetData: build.query<TimeSheetDataResponseCusList, Partial<TimeSheetData>>({
      query: ({ start, end, limit, page, search,sort }) => ({
        url: `${endpointUrl}timesheetsData?start=${start}&end=${end}&limit=${limit}&page=${page}&search=${search}&sort=${sort}`,
        headers: authHeader()
      }),
      transformErrorResponse:(err)=>{
        if(err?.status===401){
        redirectToLogin();
        }
      },
      // providesTags: (result: any = {}) => [
      //   ...result?.data?.map(
      //     (id: number) => ({ type: "MyTimeSheet", id } as const)
      //   ),
      //   { type: "MyTimeSheet" as const, id: "LIST" },
      // ],
    }),
    getProfileTimesheetData: build.query<TimeSheetDataResponse, Partial<PTimeSheetData>>({
      query: ({ start, end, limit, id, page }) => (
        {
          url: (id != 0 ? `${endpointUrl}profile/${id}/timesheetsData?start=${start}&end=${end}&limit=${limit}` :
            `${endpointUrl}timesheetsData?start=${start}&end=${end}&limit=${limit}&page=${page}`),
          headers: authHeader()
        }),
        transformErrorResponse:(err)=>{
          if(err?.status===401){
           redirectToLogin();
          }
        },
      // providesTags: (result: any = {}) => [
      //   ...result?.data?.data?.map(
      //     (id: number) => ({ type: "MyTimeSheet", id } as const)
      //   ),
      //   { type: "MyTimeSheet" as const, id: "LIST" },
      // ],
    }),

    getEmpyTimesheetData: build.query<EmpyTimeSheetDataResponse, void>({
      query: () => ({
        // url: `${endpointUrl}timesheetEmployeeList`,
        url: `${endpointUrl}employeeList`,
        headers: authHeader()
      }),
      transformErrorResponse:(err)=>{
        if(err?.status===401){
        redirectToLogin();
        }
      },
      providesTags: (result: any = {}) => [
        ...result?.employeeList?.map(
          (id: number) => ({ type: "MyEmpyTimeSheet", id } as const)
        ),
        { type: "MyEmpyTimeSheet" as const, id: "LIST" },
      ],
    }),


    getTimesheetDetail: build.query<TimeSheetDetailResponse, Partial<TimeSheetDetail>>({
      query: ({ employee_id, date }) => ({
        url: `${endpointUrl}timesheetDetail?employee_id=${employee_id}&date=${date}`,
        headers: authHeader()
      }),
      transformErrorResponse:(err)=>{
        if(err?.status===401){
        redirectToLogin();
        }
      },
      providesTags: (time) => [{ type: "TimeSheetDetail" as const, id: "LIST" }],

    }),


    getMyTimesheet: build.query<MyTimeSheetResponse, Partial<TimeSheetRequest>>({
      query: ({id, limit,page,sort}) => ({
        url: (id && id !== 0) ? `${endpointUrl}profile/${id}/timesheetsData?limit=${limit}&page=${page}` : `${endpointUrl}profile/time-sheets?limit=${limit}&page=${page}&sort=${sort}`,
        headers: authHeader(),
      }),
      keepUnusedDataFor:0,
      transformErrorResponse:(err)=>{
        if(err?.status===401){
        redirectToLogin();
        }
      },
      providesTags: (time) => [{ type: "GetTimeSheet" as const, id: "LIST" }],
    }),


    getActiveTimeTemplateList: build.query<TemplateData[], void>({
      query: () => ({
        url: `${endpointUrl}activeTimeTemplates`,
        headers: authHeader()
      }),
      transformErrorResponse:(err)=>{
        if(err?.status===401){
        redirectToLogin();
        }
      },
    }),
  }),
});

export const {
  useGetTimesheetDataQuery,
  useGetMyTimesheetQuery,
  useGetActiveTimeTemplateListQuery,
  useGetEmpyTimesheetDataQuery,
  useGetTimesheetDetailQuery,
  useGetProfileTimesheetDataQuery
} = timesheetdApi;

export const {
  endpoints: { getTimesheetData, getActiveTimeTemplateList, getEmpyTimesheetData,
    getMyTimesheet,
    getTimesheetDetail, getProfileTimesheetData
  },
} = timesheetdApi;
