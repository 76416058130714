// import { Modal } from "@material-ui/core";
import { Modal } from "@mui/material"

import classNames from "classnames";
import React, {
  createRef,
  Dispatch,
  FC,
  FocusEvent,
  SetStateAction,
  useRef,
  useState,
} from "react";
import { StringDropdownList } from "../common/DropdownList";
interface ICreateData {
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  addNewItem: any;
  closePopup?: any;
  dropdownList?: any;
  createEventTypeData?: any;
}
const EventCreateNewItemPopup: FC<ICreateData> = ({
  show,
  setShow,
  addNewItem,
  closePopup,
  dropdownList,
}) => {
  const [selectedEventType, setSelectedEventType] = useState<string>("");
  var name = useRef<any>(null);
  const createNewItem = () => {
    var data = dropdownList.find((x: any) => x.uniqeID == selectedEventType);
    var eventData = {
      name: name.current.value,
      description: "",
      flag: data?.flag,
      indicator: data?.indicator,
    };
    addNewItem(eventData);
  };
  
  return (
    <Modal
      open={show}
      onClose={() => setShow(false)}  disableEnforceFocus={true}
    >
      <div>
        <div className={classNames("z-10 fixed top-0 left-0 w-full h-full")}>
          <div
            className={classNames(
              "relative z-10 top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 max-w-[320px] bg-[#fff] px-5 rounded-lg"
            )}
          >
            <div className="flex justify-between items-center pb-[18px]">
              <p className="pt-5 text-left font-poppins font-medium text-sm text-[#3F4254]">
                Create new terms
              </p>
              <span
                onClick={() => (closePopup ? closePopup() : setShow(false))}
                className="text-[#B5B5C3] text-20 pt-4 cursor-pointer"
              >
                &times;
              </span>
            </div>
            <div className="mb-4">
              <StringDropdownList
                state={selectedEventType}
                setState={setSelectedEventType}
                dropdownItems={dropdownList
                  ?.filter((x: any) => x.indicator == "O" || x.indicator == "H")
                  .map((emp: any) => ({
                    value: emp.uniqeID,
                    label: emp.typeName,
                  }))}
                className="w-full min-w-full 5xs:min-w-[240px] max-w-full xs:min-w-[180px] sm:min-w-[160px] 2xl:min-w-[180px] 6xs:text-12"
                placeholder="---"
                textAlign="text-left py-2 pl-2 pr-3"
              />
            </div>
            <input
              ref={name}
              type="text"
              className="text-12 rounded w-full border-[2px] border-[#3699FF] px-[11px] py-2 bg-[#fff] focus:outline-none mb-3"
              placeholder="Untitled"
            />
            <div className="flex justify-end pb-5">
              <button
                onClick={() => (closePopup ? closePopup() : setShow(false))}
                className="px-4 py-2 rounded-[6px] font-poppins font-semibold text-xs text-[#979797] bg-[#EFEFEF] mr-2"
              >
                Cancel
              </button>
              <button
                onClick={() => createNewItem()}
                className="px-4 py-2 rounded-[6px] font-poppins font-semibold text-xs text-[#fff] bg-[#009EF7]"
              >
                Create
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default EventCreateNewItemPopup;
