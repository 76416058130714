import { FC } from "react";
import BasicLayout from "../layouts/BasicLayout";
import { Outlet } from "react-router-dom";
import PasswordList from "./views/PasswordList";

interface IPasswords {
  loginData: any;
  permissions: any;
}

export const passwordRoutes = [
  { id: 1, path: "", component: <PasswordList /> },
];

const Passwords: FC<IPasswords> = ({ loginData, permissions }) => {
  return (
    <BasicLayout userData={loginData} permissions={permissions}>
      <div className="pt-0 pb-[58px]">
        <Outlet />
      </div>
    </BasicLayout>
  );
};

export default Passwords;
