import classNames from "classnames";
import {
  Dispatch,
  FC,
  FocusEvent,
  Fragment,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { EditIcon, TickIcon } from "../common/Icons";
import { formatMoney } from "../../utils/helpers";
import { usePopper } from "../../hooks/usePopper";
import { uniqueId } from "lodash";
// import PaymentTermsEdit from "./PaymentTermsEdit";
import { baseObj } from "../../utils/constants";
import {
  useCreatePaymentMutation,
  useGetPaymentDataQuery,
} from "../../app/services/project";
import { Cell } from "@tanstack/react-table";

interface IPaymentProgressBar {
  paymentTimes: number;
  paidAmountData: number;
  projectAmount: number;
  currency?: string;
  // paidAmount?: IPaidAmount;
  // setData?: Dispatch<SetStateAction<baseObj[]>>;
  // id?: number;
  // paymentTerm?: number;
  // rowId?: number;
  // isNew?: boolean;
  // setErrorPopup?: Dispatch<SetStateAction<boolean>>;
  // setSuccessPopup?: Dispatch<SetStateAction<boolean>>;
  // setMessage?: any;
  // setIsPaymentChange?: Dispatch<SetStateAction<boolean>>;
  // setEditedData?: any;
  // editedData?: any;
  // cell?: Cell<any, any>;
}

export interface IPaidAmount {
  value: number;
  paymentValue: { amount: number; is_completed: boolean; id: number }[];
}

const PaymentBar: FC<IPaymentProgressBar> = ({
  // paidAmount,
  projectAmount,
  currency,
  paidAmountData,
  paymentTimes,
  // setData,
  // paymentTerm,
  // id,
  // rowId,
  // isNew,
  // setErrorPopup,
  // setSuccessPopup,
  // setMessage,
  // setIsPaymentChange,
  // setEditedData,
  // editedData,
  // cell,
}) => {
  // const [createPayment] = useCreatePaymentMutation();
  //   console.log("leg", paidAmount);

  let paymentStage = paymentTimes;
  // const paymentStage =
  //   paidAmount?.paymentValue?.length !== 0
  //     ? paidAmount?.paymentValue?.filter((term) => term?.is_completed)?.length
  //     : [];

  // const [amountOver, setAmountOver] = useState<boolean>(false);
  // const realCurrentProjectAmount: number = projectAmount;
  const [currentProjectAmount, setCurrentProjectAmount] =
    useState<number>(projectAmount);
  const [currentPaidAmount, setCurrentPaidAmount] = useState<any>({
    value: paidAmountData,
  });

  useEffect(() => {
    setCurrentPaidAmount({ value: paidAmountData });
  }, [paidAmountData]);

  useEffect(() => {
    setCurrentProjectAmount(projectAmount);
  }, [projectAmount]);

  // console.log("length", paymentStage);
  // console.log("origin amount", projectAmount);
  // console.log("need pay amount", paidAmountData);
  // paymentValue: paidAmount?.paymentValue?.slice(0, paymentTerm),

  // useEffect(() => {
  //   // setCurrentPaidAmount({
  //   //   value: paidAmount?.value,
  //   //   paymentValue: paidAmount?.paymentValue?.filter(
  //   //     (d: any) => d.is_completed
  //   //   ),
  //   // });
  // }, [paidAmount]);

  const actualPaidPercent = Math.ceil(
    (currentPaidAmount?.value / currentProjectAmount) * 100
  );
  const paidPercent = actualPaidPercent - (actualPaidPercent % 10) || 0;

  // const paymentPercent = currentPaidAmount?.paymentValue
  //   ?.map((value) => {
  //     const actualPercent = Math.ceil(
  //       (value?.amount / currentProjectAmount) * 100
  //     );
  //     const roundedPercent = actualPercent - (actualPercent % 10);
  //     return roundedPercent || 0;
  //   })
  //   .filter((value) => value !== 0);

  // let [trigger, container] = usePopper({
  //   placement: "bottom-end",
  //   strategy: "fixed",
  //   modifiers: [{ name: "offset", options: { offset: [80, 7] } }],
  // });

  // const uuid = uniqueId("payment-amounts");
  // const [isOpen, setIsOpen] = useState<boolean>(false);
  // const [isEditing, setIsEditing] = useState<boolean>(false);

  // const handleCloseDropdown = (event: FocusEvent<HTMLButtonElement>) => {
  //   const relatedTarget = event.relatedTarget;
  //   // If the click target is not the view button, close the tooltip.
  //   if (
  //     !relatedTarget ||
  //     !(
  //       relatedTarget.id === uuid ||
  //       relatedTarget.className.includes(`${uuid}child`)
  //     )
  //   ) {
  //     discardChanges();
  //     setIsOpen(false);
  //   }
  // };

  // const discardChanges = () => {
  //   setIsEditing(false);
  //   setCurrentPaidAmount({
  //     value: paidAmount?.value,
  //     paymentValue: paidAmount?.paymentValue?.slice(0, paymentTerm),
  //   });
  //   setCurrentProjectAmount(projectAmount);
  // };

  // const saveChanges = () => {
  //   // setIsPaymentChange(true);
  //   // console.log("res setIsPaymentChange");

  //   var savePaymentData: any = [];
  //   let paidAmount: any = [];
  //   let lastAmount = 0;
  //   // currentPaidAmount?.paymentValue?.forEach((d: any) => {
  //   //   lastAmount += Number(d?.amount);
  //   //   savePaymentData.push({
  //   //     project_id: rowId,
  //   //     id: d.id,
  //   //     amount: d.amount,
  //   //     is_completed: d.is_completed,
  //   //   });
  //   //   paidAmount.push({
  //   //     id: d.id,
  //   //     amount: d.amount,
  //   //     is_completed: d.is_completed,
  //   //   });
  //   // });

  //   setEditedData({
  //     ...editedData,
  //     paidAmount: { paymentValue: savePaymentData, value: lastAmount },
  //   });

  //   setIsOpen(false);
  //   setIsEditing(false);
  //   createPayment(savePaymentData)
  //     .unwrap()
  //     .then((payload: any) => {
  //       // if (payload?.status) {
  //       //   setSuccessPopup(true);
  //       //   setMessage(payload ? [payload?.message] : ["Create Successfully"]);
  //       //   setData((prev) =>
  //       //     prev.map((prev) => {
  //       //       if (prev.uniqeID === id) {
  //       //         return {
  //       //           ...prev,
  //       //           paidAmount: currentPaidAmount,
  //       //         };
  //       //       } else {
  //       //         return prev;
  //       //       }
  //       //     })
  //       //   );
  //       // }
  //     })
  //     .catch((error: any) => {
  //       // console.log("er", error.data.message)
  //       if (error) {
  //         //   setErrorPopup(true);
  //         setMessage(
  //           error?.data?.message
  //             ? [error?.data?.message]
  //             : ["Something wrong! Try again."]
  //         );
  //       }
  //     });
  // };

  // useEffect(() => {
  //   if (realCurrentProjectAmount < currentProjectAmount) {
  //     setAmountOver(true);
  //   } else {
  //     setAmountOver(false);
  //   }
  // }, [currentProjectAmount]);

  // console.log("percent", projectAmount)

  return (
    <div className="flex items-center w-full h-full relative">
      <div
        className={classNames(
          " rounded-2xl px-2 py-[5px] relative w-full min-h-[32px] overflow-hidden",
          {
            "bg-vorpmyanmar": paymentStage !== 0,
            "bg-offwhite": paymentStage === 0,
          }
        )}
      >
        <div
          className={classNames(
            "absolute h-full top-0 left-0 rounded-2xl  z-[1] flex items-center",
            {
              "bg-vorpblue": paidPercent !== 100,
              "bg-vorpgreen": paidPercent === 100 || paidPercent >= 100,
            }
          )}
          style={{ width: `${paidPercent}%` }}
        >
          <div
            className={classNames(
              "rounded-full min-w-[22px] min-h-[22px] mr-3  my-auto ml-2 flex justify-center items-center text-12 leading-[18px] font-medium text-vorpblue",
              {
                "bg-vorpmyanmar": paymentStage !== 0,
                "bg-white": paymentStage === 0,
              }
            )}
          >
            {paidPercent === 100 ? <TickIcon /> : paymentStage}
          </div>
        </div>
        <p
          className={classNames(
            "text-[10px] leading-4 font-semibold z-[4] absolute left-10 top-2 bottom-0 my-auto",
            {
              "text-black2": paidPercent === 0,
              " text-white": paidPercent ? paidPercent : 0 > 0,
            }
          )}
        >
          {formatMoney(
            currentPaidAmount?.value ? currentPaidAmount?.value : 0,
            currency ? currency : "$"
          )}
          <span className="pl-1">paid</span>
        </p>

        <div className="bg-white px-2 w-10 h-[22px] rounded-xl flex justify-center items-center text-12 leading-[18px] font-medium text-black2 absolute right-[5px] top-0 bottom-0 my-auto z-[3]">
          {paidPercent > 100 ? "100" : paidPercent}%
        </div>
      </div>
    </div>
  );
};

export default PaymentBar;
