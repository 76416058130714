import { FC, useCallback, useEffect, useMemo, useState } from "react";
import PasswordsTable from "./PasswordsTable";
import { EditIcon, SearchIcon, TrashIcon } from "../../common/Icons";
import Button from "../../common/Button";
// import PasswordForm from "./PasswordForm";
// import { Modal } from "@material-ui/core";
// import { Modal } from "@mui/material"
import { JSEncrypt } from "jsencrypt";

import DebounceSearchInput from "../../Tables/DebounceSearchInput";
import { baseObj } from "../../../utils/constants";
// import AddPasswordModal from "../../Passwords/AddPasswordModal";
import styles from "./MyPasswords.module.scss";
import classNames from "classnames";
import {
  KeyValueLabel,
  useGetIsPwdKeyMutation,
  useGetPasswordInformationQuery,
  // useCreateAuthPasswordMutation,
  useGetPasswordKeyQuery,
  useGetSeeMyPasswordMutation,
  useGetSeeOnePasswordMutation,
  // useGetSeePasswordMutation,
} from "../../../app/services/password";
import {
  useCreateTagMutation,
  useGetTagsDataQuery,
} from "../../../app/services/annoucement-sheet";
import { ModalPwd } from "../../Passwords/ModalPwd";
import { TModalState } from "../../Projects/ProjectsTable";
import {
  MODALS,
  initialModalState,
} from "../../../passwords/views/PasswordList";
import {
  useGetEmployeeProfilePasswordsQuery,
  useGetMyPasswordProfileMutation,
} from "../../../app/services/profile";
import { useLocation } from "react-router-dom";
import { getAuthUser } from "../../../app/services/dashboard";
// import DOMPurify from "dompurify";
import SuccessPopup from "../../SuccessPopup/SuccessPopup";
import ErrorPopup from "../../ErrorPopup/ErrorPopup";
import { useSpring, animated } from "react-spring";
import { PaginationState, SortingState } from "@tanstack/react-table";
import CommonTableSkeleton from "../../TableSkeleton/CommonTableSkeleton";
import { NumberDropdownList } from "../../common/DropdownList";
import { checkedIsNew } from "../../../app/services/employee";
import { useViewNotificationByTypeMutation } from "../../../app/services/notification";
import { useDispatch, useSelector } from "react-redux";
import { setProfileNotification } from "../../../reducers/dashboard";
import TablePaginator from "../../Tables/TablePaginator";

interface IMyPasswords {}
var decrypt = new JSEncrypt();
const MyPasswords: FC<IMyPasswords> = () => {
  const defaultFields = useMemo(
    () => ["company", "platform", "account", "password", "links"],
    []
  );
  const [isKey] = useGetIsPwdKeyMutation();
  const [getKey, setGetKey] = useState<boolean>(false);
  const [showAllPassword, setShowAllPassword] = useState<boolean>(false);
  const [onePwd, setOnePwd] = useState<boolean>(false);
  const [modalState, setModalState] = useState<TModalState>(initialModalState);
  const [modalRow, setModalRow] = useState<number>(0);
  // console.log("a", modalRow);
  const [eyeClick, setEyeClick] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [hiddenFields, setHiddenFields] = useState<string[]>([]);

  const [privateKey, setPrivateKey] = useState<string | null>(null);
  const [keyData, setKeyData] = useState<string>();
  const { data: keyDatas } = useGetPasswordInformationQuery();
  const [globalFilter, setGlobalFilter] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [itemCount, setItemCount] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);
  const [pageOne, SetPageOne] = useState<boolean>(false);

  //const { data: tagsLists } = useGetTagsDataQuery();
  const [seeMyPasswordAllData, { isLoading: allLoading }] =
    useGetSeeMyPasswordMutation();
  const [MyPasswordAllData] = useGetMyPasswordProfileMutation();
  const [seeOnePasswordData] = useGetSeeOnePasswordMutation();
  const [createMyPassword, setCreateMyPassword] = useState<boolean>(false);
  const [showMyPassword, setShowMyPassword] = useState<boolean>(false);
  const [viewPassword, setViewPassword] = useState<boolean>(false);
  const [managePassword, setMnagePassword] = useState<boolean>(false);
  const [successPopup, setSuccessPopup] = useState<boolean>(false);
  const [errorPopup, setErrorPopup] = useState<boolean>(false);
  const [message, setMessage] = useState<any>([]);
  const [data, setData] = useState<baseObj[]>([]);
  const [createTag] = useCreateTagMutation();
  const [isEditing, setIsEdit] = useState<boolean>(false);
  const location = useLocation();
  const stateData: any = useSelector((state) => state);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [viewLeaveByType] = useViewNotificationByTypeMutation();
  const dispatch = useDispatch();
  //var id = location.state;
  const [state, setState] = useState(localStorage.getItem("state"));
  //var id = state ? Number(state) : 0;
  var currentUrl = window.location.pathname
    .split("/employeeprofile/")[1]
    ?.split("/")[0];
  var id = currentUrl ? parseInt(currentUrl) : 0;
  const { data: passwordEmpData } = useGetEmployeeProfilePasswordsQuery({
    id: id,
    globalFilter: globalFilter,
    limit: limit,
    page: currentPage,
    sort: sorting
      .map(
        (s) =>
          `${s.desc ? "-" : ""}${s.id == "createdBy" ? "created_by" : s.id}`
      )
      .join(","),
  });

  const [filterTagData, setFilterTagData] = useState<KeyValueLabel[]>([]);
  var getUser = getAuthUser();
  const getAuthPermis: string[] = useMemo(() => [], []);
  var encrypt: any = new JSEncrypt();

  if (getUser?.permissions) {
    const getPer = getUser?.permissions?.forEach((per: any) => {
      getAuthPermis.push(per?.name);
    });
  }

  useEffect(() => {
    localStorage.removeItem("state");
  }, []);

  useEffect(() => {
    if (location.state) {
      setState(location.state);
    }
  }, [location]);

  useEffect(() => {
    const Create_My_Password = getAuthPermis.filter(
      (f) => f === "Create_New_Password"
    );
    const Show_My_Password = getAuthPermis.filter(
      (f) => f === "Show_My_Password"
    );
    const view_Password = getAuthPermis.filter((f) => f === "View_Password");
    const manage_Password = getAuthPermis.filter(
      (f) => f === "Manage_Password"
    );

    if (manage_Password.length > 0) {
      setMnagePassword(true);
    }
    if (Create_My_Password.length > 0) {
      setCreateMyPassword(true);
    }

    if (Show_My_Password.length > 0) {
      setShowMyPassword(true);
    }
    if (view_Password.length > 0) {
      setViewPassword(true);
    }
  }, [getUser, getAuthPermis]);

  useEffect(() => {
    const request = indexedDB.open("myDatabase", 1);
    request.onsuccess = (event) => {
      const db = (event.target as IDBOpenDBRequest).result;
      const transaction = db.transaction(["myStore"], "readwrite");
      const store = transaction.objectStore("myStore");
      const getRequest = store.getAll(); // Assuming the ID of the data you want to retrieve is 1
      getRequest.onsuccess = (event) => {
        const data = (event.target as IDBRequest).result;
        if (data !== undefined) {
          setPrivateKey(data[0].privateKey);
        }
      };

      getRequest.onerror = (event) => {
        console.error(
          "Error retrieving data",
          (event.target as IDBOpenDBRequest).error
        );
        setErrorPopup(true);
        setMessage(["Your Key are missing!. Trying again."]);
      };
    };
  }, [getUser, getAuthPermis, modalRow]);
  useEffect(() => {
    if (keyDatas) {
      setKeyData(keyDatas?.public_key);
      setFilterTagData([
        { name: "All", id: 0, label: "All" },
        ...keyDatas?.tagsData?.filter(
          (x: any) => x.flag == "pwd" || x.flag == null
        ),
      ]);
    }
  }, [keyDatas]);

  if (privateKey !== null) {
    decrypt.setPrivateKey(privateKey);
  }
  useEffect(() => {
    if (privateKey !== null) {
      decrypt.setPrivateKey(privateKey);
    }
  }, [privateKey]);
  var publicKey: string | undefined = keyData;

  useEffect(() => {
    if (passwordEmpData) {
      setData(passwordEmpData?.passwords?.data);
      setItemCount(passwordEmpData?.passwords?.total);
    }
  }, [passwordEmpData]); //

  const addNewPasswordData = () => {
    return {
      id: 0,
      account: "",
      links: "",
      is_show: 0,
      password: "",
      platform: "",
      company: "",
      tags: [],
      user_ids: [],
      createdBy: {
        createdBy: null,
        name: "",
      },
      isNew: true,
    };
  };

  // useEffect(() => {
  //   if (tagsLists) {
  //     setFilterTagData([
  //       { name: "All", id: 0, label: "All" },
  //       ...tagsLists?.tagsData?.filter(
  //         (x: any) => x.flag == "pwd" || x.flag == null
  //       ),
  //     ]);
  //   }
  // }, [tagsLists]);

  const modalStateHandler = (target: string) => {
    setModalState((prev) => ({ ...prev, [target]: true }));
  };

  const handleGetAllKey = () => {
    isKey()
      .then((res: any) => {
        if (res.data.can_see) {
          setGetKey(false);
          seeMyPasswordAllData({
            page: currentPage,
            limit: limit,
            search: globalFilter,
          }).then((res: any) => {
            setGetKey(false);
            if (res?.data?.status) {
              let rest = res?.data?.passwords?.data;
              if (res) {
                const decodeData = rest.map((data: any, index: number) => ({
                  ...data,
                  password: decrypt.decrypt(data.password),
                }));
                setData(decodeData);
              }
              setEyeClick(true);
              setIsSuccess(true);
            } else if (Number(res?.error.status) === 403) {
              setIsError(true);
            }
          });
        } else {
          setGetKey(true);
        }
      })
      .catch((err: any) => {});
  };

  const handleOneGetKey = useCallback(
    (id: number) => {
      isKey()
        .then((res: any) => {
          if (res.data.can_see) {
            setGetKey(false);
            seeOnePasswordData({ id: id }).then((res: any) => {
              //passwords/13/see
              if (res?.data?.status) {
                var rest = res?.data?.password;
                setData((prev: any) => {
                  return prev.map((data: any, index: number) => {
                    if (rest?.id === data?.id) {
                      return {
                        ...data,
                        password: decrypt.decrypt(rest.password),
                        is_show: 1,
                      };
                    }
                    return data;
                  });
                });
                setOnePwd(false);
                setEyeClick(true);
                setShowAllPassword(false);
                setIsSuccess(true);
              } else if (res?.error.status === 403) {
                setIsError(true);
              }
            });
          } else {
            setGetKey(true);
          }
        })
        .catch((err: any) => {});
    },
    [
      setData,
      privateKey,
      seeOnePasswordData,
      setGetKey,
      setOnePwd,
      decrypt,
      isKey,
    ]
  );

  const getAllSeePwd = async (values?: any) => {
    if (publicKey !== undefined) {
      encrypt.setPublicKey(publicKey);
    }
    var encrypted = encrypt.encrypt(values);

    if (onePwd) {
      await seeOnePasswordData({
        password: encrypted,
        id: modalRow,
        page: currentPage,
        limit: limit,
        search: globalFilter,
      })
        .unwrap()
        .then((res: any) => {
          setShowAllPassword(false);
          var rest = res.password;
          setGetKey(false);
          setData((prev: any) => {
            return prev.map((data: any, index: number) => {
              if (Number(rest.id) === Number(data.id)) {
                return {
                  ...data,
                  password: decrypt.decrypt(rest.password),
                  is_show: 1,
                };
              }
              return data;
            });
          });
          setOnePwd(false);
          setEyeClick(true);
          setIsSuccess(true);
        })
        .catch((error: any) => {
          setIsSuccess(false);
          setErrorPopup(true);
          setIsError(true);
          setMessage(["Authentication Fail. Try Again!!"]);
        });
    } else if (showAllPassword) {
      await seeMyPasswordAllData({
        password: encrypted,
        page: currentPage,
        limit: limit,
        search: globalFilter,
      })
        .unwrap()
        .then((res: any) => {
          setShowAllPassword(false);
          let rest = res.passwords.data;
          setGetKey(false);
          if (res) {
            const decodeData = rest.map((data: any, index: number) => ({
              ...data,
              password: decrypt.decrypt(data.password),
            }));
            setData(decodeData);
          }
          setEyeClick(true);
          setIsSuccess(true);
        })
        .catch((error: any) => {
          setIsSuccess(false);
          setErrorPopup(true);
          setIsError(true);
          setMessage(["Authentication Fail. Try Again!!"]);
        });
    }
  };

  const handleClickBtn = () => {
    setGetKey(false);
    if (showAllPassword === true || eyeClick === true) {
      MyPasswordAllData({ id: id ? id : 0, limit: limit }) //myPasswords/users/${id}/passwords
        .then((data: any) => {
          setData(data.data.passwords.data);
          SetPageOne(true);
        })
        .catch((error: any) => {});
    }
    setOnePwd(false);
    setIsSuccess(false);
  };

  useEffect(() => {
    setTimeout(() => {
      setSuccessPopup(false);
    }, 1000);
  }, [successPopup]);

  useEffect(() => {
    setCurrentPage(1);
    if (!globalFilter.length) {
      setIsSuccess(false);
    }
  }, [globalFilter, limit, pageOne]);

  useEffect(() => {
    setIsSuccess(false);
  }, [currentPage, limit]);

  const createTagData = (label: string) => {
    var data = {
      name: label,
      flag: "pwd",
    };
    return createTag(data);
  };

  const props = useSpring({
    to: { opacity: 1 },
    from: { opacity: 0 },
    delay: 500,
  });

  var disabledData = checkedIsNew(data);

  const handleAddNew = useCallback(() => {
    const datas = data?.map((d: any) => {
      return {
        ...d,
        password: "********",
        is_show: 0,
      };
    });
    setData([addNewPasswordData(), ...datas]);
  }, [setData, data]);

  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);
  useEffect(() => {
    setTimeout(() => {
      var stateDataDetail = stateData?.dashboard?.notificationDetail;
      var updatedData = {
        profile_unread_noti_count: stateDataDetail?.profile_unread_noti_count,
        unapprove_claim_count: stateDataDetail.unapprove_claim_count,
        unapprove_leave_count: stateDataDetail?.unapprove_leave_count,
        unapprove_ot_count: stateDataDetail?.unapprove_ot_count,
        unread_data_count: {
          unread_password_count: 0,
          unread_document_count:
            stateDataDetail?.unread_data_count?.unread_document_count,
          unread_leave_count:
            stateDataDetail?.unread_data_count?.unread_leave_count,
          unread_claim_count:
            stateDataDetail?.unread_data_count?.unread_claim_count,
        },
        unread_count: stateDataDetail?.unread_count,
        notifications: stateDataDetail?.notifications,
      };

      viewLeaveByType("password").then((res) => {
        dispatch(setProfileNotification(updatedData));
      });
    }, 5000);
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const bgColors = ["#9870D8", "#EDA96B", "#3699FF"];

  // console.log("d", data);

  return (
    <>
      {/* { globalFilter.length ? ( //isLoading, allLoading &&
        <CommonTableSkeleton rows={12} isProfile={true} />
      ) : ( */}
      <>
        <SuccessPopup
          descText="Done Successfully!"
          confirmBtnText="Yes, Close."
          show={successPopup}
          setShow={setSuccessPopup}
          message={message}
          setMessage={setMessage}
        />

        <ErrorPopup
          descText="Error Message!"
          confirmBtnText="Yes, Close."
          show={errorPopup}
          setShow={setErrorPopup}
          message={message}
          setMessage={setMessage}
        />
        <animated.div style={props}>
          {windowSize[0] < 640 ? (
            <div className="w-full p-5 bg-white rounded-xl text-left">
              <div className="py-3 border-b border-offwhite flex items-center justify-between">
                <h3 className="text-black2 font-semibold text-[14px] leading-normal">
                  My Password
                </h3>

                {Number(id) === 0 ? (
                  <div className="whitespace-nowrap flex justify-center items-center">
                    <Button
                      // disabled={disabledData?.disabled}
                      label="+ Add New"
                      onClick={() => {
                        // setIsSuccess(false);
                        // setPagination((prev) => ({ ...prev, pageIndex: 0 }));
                        // handleAddNew();
                      }}
                      type="button"
                      variant="primary"
                      customClass={
                        id
                          ? id != 0
                            ? "hidden"
                            : ""
                          : "" +
                            // disabledData?.disabledClass +
                            " hover:bg-vorpmyanmar hover:border-vorpmyanmar hover:text-vorpblue text-[10px] leading-3 font-small text-white"
                      }
                      size="base"
                    />
                  </div>
                ) : null}
              </div>
              {getKey && (
                <ModalPwd
                  getAllSeePwd={getAllSeePwd}
                  state={modalState}
                  setState={setModalState}
                  rowId={modalRow}
                  data={data}
                  setData={setData}
                  setShowPasswords={setShowAllPassword}
                  isSuccess={isSuccess}
                  setIsSuccess={setIsSuccess}
                  isError={isError}
                  setIsError={setIsError}
                />
              )}

              <div
                className={classNames(
                  Number(id) === 0 ? "" : "hidden",
                  "flex justify-between items-center py-3"
                )}
              >
                <div className=" w-full">
                  <DebounceSearchInput
                    setState={setGlobalFilter}
                    debounce={800}
                  >
                    {(setState) => (
                      <div className="font-normal flex border-1 border-[#C4C4C4] bg-white hover:bg-[#EFEFEF] rounded-[28px]">
                        <input
                          id="message"
                          autoComplete="new-password"
                          type="text"
                          name="search"
                          placeholder="Search"
                          className="focus:outline-none py-1 px-4 text-12 xl:text-14 leading-5 bg-transparent rounded-[28px] w-full text-graydark"
                          onChange={(e) => setState(e.target.value)}
                        />
                        <SearchIcon className="mr-2" />
                      </div>
                    )}
                  </DebounceSearchInput>
                </div>
              </div>

              <div className="max-h-[440px] min-h-[430px] overflow-y-auto">
                {data?.length
                  ? data.map((data: any, i: any) => (
                      <div
                        key={i}
                        className="px-3 py-4 bg-[#F3F6F9] rounded-md gap-10 w-full mb-3"
                      >
                        <div className="flex justify-stretch py-2 w-full">
                          <div className="w-full">
                            <p className="leading-loose inline-block pr-2  text-12 font-normal text-black2 text-start">
                              {data.platform ?? "---"}
                            </p>
                            <p className="leading-loose text-10 inline font-normal text-graydark text-start">
                              {data.company ?? "---"}
                            </p>
                          </div>
                        </div>
                        <hr color="blue" />
                        <div className="flex pt-3 w-full">
                          <p className="leading-loose text-10 w-1/2 font-normal text-graydark text-start">
                            Account
                          </p>
                          <p className=" leading-loose text-black2 w-1/2 text-10 font-normal text-start">
                            {data.account ?? "---"}
                          </p>
                        </div>
                        <div className="flex">
                          <p className="text-10 w-1/2 font-normal text-graydark text-start leading-loose">
                            Link
                          </p>
                          <p className=" text-black2 w-1/2 text-10 font-normal text-start leading-loose">
                            {data.links ?? "---"}
                          </p>
                        </div>

                        <div className="flex">
                          <p className="text-10 w-1/2 font-normal text-graydark text-start leading-loose">
                            Password
                          </p>
                          <p className=" text-black2 w-1/2 text-10 font-normal text-start leading-loose">
                            {data.password ?? "---"}
                          </p>
                        </div>

                        <div className="flex py-2">
                          <p
                            onClick={() => {
                              if (setModalRow) {
                                setModalRow(data.id);
                              }
                              handleOneGetKey(data.id);
                              setOnePwd(true);
                              modalStateHandler(MODALS.SHOW_PWD);
                              setIsSuccess(false);
                            }}
                            className={classNames(
                              // data.assignUsers.length ? "cursor-pointer" : "",
                              "text-10 w-1/2 font-normal text-vorpblue cursor-pointer underline text-start leading-loose"
                            )}
                          >
                            Show Password
                          </p>
                        </div>
                        <hr color="blue" />

                        <div className="flex flex-col w-full">
                          <p className="text-10 my-2 w-full block font-normal text-graydark text-start leading-loose">
                            Tags
                          </p>
                          <div className=" w-full text-10 font-normal">
                            {data.tags.map((d: any, i: number) => (
                              <button
                                style={{
                                  backgroundColor: `${
                                    i > bgColors.length
                                      ? bgColors[1]
                                      : bgColors[i]
                                  }`,
                                }}
                                className={classNames(
                                  "px-3 py-1 mr-1 mb-1 rounded-md text-white "
                                )}
                              >
                                {d.name}
                              </button>
                            ))}
                          </div>
                        </div>

                        <hr color="blue" />
                        {data.is_own ? (
                          <div className="pt-2 flex justify-end">
                            <button
                              // disabled={isHaveNew ? true : false}
                              type="button"
                              onClick={() => {
                                // editRow(cell?.row?.original?.id);
                                // if (setIsEdit) {
                                //   setIsEdit(true);
                                //   setIsSuccess(false);
                                // }
                              }}
                              className={classNames(
                                // disabledClass,
                                "w-6 h-6 mr-2 flex justify-center items-center group rounded-md bg-vorpblue hover:bg-annhoverbg transition-all duration-300"
                              )}
                            >
                              <EditIcon width={18} height={18} />
                            </button>
                            <button
                              type="button"
                              onClick={() => {
                                // deletePwdFun(Number(cell?.row?.original?.id));
                              }}
                              className={classNames(
                                // disabledClass,
                                "w-6 h-6 flex justify-center items-center group rounded-md bg-redPink hover:bg-redPinkLight transition-all duration-300"
                              )}
                            >
                              <TrashIcon width={18} height={18} />
                            </button>
                          </div>
                        ) : null}
                      </div>
                    ))
                  : null}
              </div>

              <div className={"pt-2"}>
                <TablePaginator
                  total={itemCount}
                  pagination={pagination}
                  setPagination={setPagination}
                  itemCount={itemCount}
                  setCurrentPage={setCurrentPage}
                  currentPageDefault={currentPage}
                />
              </div>
            </div>
          ) : (
            <div className="w-full bg-white rounded-xl text-left">
              <div className="px-2 sm:px-4 md:px-5 py-1 md:py-2 border-b border-offwhite flex items-center justify-between">
                <h3 className="text-[#3F4254] font-semibold text-[13px] sm:text-[15px] 2xl:text-[18px] leading-[22px] lg:leading-[27px]">
                  Password Management
                </h3>
              </div>
              {getKey && (
                <ModalPwd
                  getAllSeePwd={getAllSeePwd}
                  state={modalState}
                  setState={setModalState}
                  rowId={modalRow}
                  data={data}
                  setData={setData}
                  setShowPasswords={setShowAllPassword}
                  isSuccess={isSuccess}
                  setIsSuccess={setIsSuccess}
                  isError={isError}
                  setIsError={setIsError}
                />
              )}

              <div
                className={classNames(
                  Number(id) === 0 ? "" : "hidden",
                  "flex flex-wrap justify-between items-center mb-0 px-2 sm:px-4 pt-2"
                )}
              >
                <div className="flex items-center mr-2 xs:mr-4 sm:w-[260px] w-[225px] my-1">
                  <DebounceSearchInput
                    setState={setGlobalFilter}
                    debounce={800}
                  >
                    {(setState) => (
                      <div className="font-normal flex border-1 border-[#C4C4C4] bg-white hover:bg-[#EFEFEF] rounded-[28px]">
                        <input
                          id="message"
                          autoComplete="new-password"
                          type="text"
                          name="search"
                          placeholder="Search"
                          className="focus:outline-none py-1 px-4 text-12 xl:text-14 leading-5 bg-transparent rounded-[28px] w-full text-graydark"
                          onChange={(e) => setState(e.target.value)}
                        />
                        <SearchIcon className="mr-2" />
                      </div>
                    )}
                  </DebounceSearchInput>
                </div>

                {/* {
                // Number(id) === 0 && createMyPassword ? (
                (windowSize[0] < 450 && createMyPassword) ? (
                  <div className="whitespace-nowrap flex justify-center items-center">
                    <Button
                      disabled={disabledData?.disabled}
                      label="+ Add"
                      onClick={() => {
                        setIsSuccess(false);
                        setPagination((prev) => ({ ...prev, pageIndex: 0 }));
                        handleAddNew();
                      }}
                      type="button"
                      variant="primary"
                      customClass={
                        id
                          ? id != 0
                            ? "hidden"
                            : ""
                          : "" +
                          disabledData?.disabledClass +
                          " hover:bg-vorpmyanmar hover:border-vorpmyanmar hover:text-vorpblue text-[10px] leading-3 font-small text-white"
                      }
                      size="base"
                    />
                  </div>
                ) : (
                  <div className="whitespace-nowrap flex justify-center items-center">
                    <Button
                      disabled={disabledData?.disabled}
                      label="+ Add New Password"
                      onClick={() => {
                        setIsSuccess(false);
                        setPagination((prev) => ({ ...prev, pageIndex: 0 }));
                        handleAddNew();
                      }}
                      type="button"
                      variant="primary"
                      customClass={
                        id
                          ? id != 0
                            ? "hidden"
                            : ""
                          : "" +
                          disabledData?.disabledClass +
                          " hover:bg-vorpmyanmar hover:border-vorpmyanmar hover:text-vorpblue text-[10px] leading-3 font-small text-white"
                      }
                      size="base"
                    />
                  </div>
                )
                // ) : null
              } */}

                {Number(id) === 0 ? (
                  <div className="whitespace-nowrap flex justify-center items-center">
                    <Button
                      disabled={disabledData?.disabled}
                      label="+ Add New"
                      onClick={() => {
                        setIsSuccess(false);
                        setPagination((prev) => ({ ...prev, pageIndex: 0 }));
                        handleAddNew();
                      }}
                      type="button"
                      variant="primary"
                      customClass={
                        id
                          ? id != 0
                            ? "hidden"
                            : ""
                          : "" +
                            disabledData?.disabledClass +
                            " hover:bg-vorpmyanmar hover:border-vorpmyanmar hover:text-vorpblue text-[10px] leading-3 font-small text-white"
                      }
                      size="base"
                    />
                  </div>
                ) : null}

                {Number(id) === 0 ? (
                  isSuccess ? (
                    <button
                      type="button"
                      onClick={() => {
                        handleClickBtn();
                        setShowAllPassword(false);
                        setOnePwd(false);
                        setIsSuccess(false);
                      }}
                      className="mr-3 xl:text-sm text-12 leading-5 font-medium text-vorpblue whitespace-nowrap sm:block"
                    >
                      {"Hide Passwords"}
                    </button>
                  ) : (
                    <button
                      type="button"
                      onClick={() => {
                        modalStateHandler(MODALS.SHOW_PWD);
                        setShowAllPassword(true);
                        setOnePwd(false);
                        setIsSuccess(false);
                        handleGetAllKey();
                      }}
                      className="mr-3 xl:text-sm text-12 leading-5 font-medium text-vorpblue whitespace-nowrap sm:block"
                    >
                      {"Show Passwords"}
                    </button>
                  )
                ) : null}

                <div className=" mb-1 flex items-center">
                  <p className="base-label text-12 xl:text-14">DISPLAY</p>
                  <NumberDropdownList
                    state={pagination.pageSize}
                    setState={(value) => {
                      setPagination((prev) => ({
                        ...prev,
                        pageSize: value as number,
                      }));
                      setLimit(value);
                    }}
                    dropdownItems={new Array(5)
                      .fill(1)
                      .map((_, index: number) => {
                        let display = (index + 1) * 10;
                        return {
                          value: display,
                          label: String(display),
                        };
                      })}
                    className="max-w-[55px] 4xs:max-w-[60px] lg:max-w-[72px]"
                    textAlign="text-left pl-3"
                    paddingClass="py-[2px]"
                    placeholder="20"
                  />
                </div>
              </div>

              <div
                className={classNames(
                  styles.myPasswordTable,
                  "px-2 sm:px-4 md:px-5 py-3  md:pb-[48px] border-b border-offwhite relative"
                )}
              >
                <PasswordsTable
                  publicKey={keyData}
                  pagination={pagination}
                  setPagination={setPagination}
                  showAllPassword={showAllPassword}
                  setEyeClick={setEyeClick}
                  eyeClick={eyeClick}
                  setOnePwd={setOnePwd}
                  setShowAllPassword={setShowAllPassword}
                  modalStateHandler={modalStateHandler}
                  isSuccess={isSuccess}
                  setModalRow={setModalRow}
                  setIsSuccess={setIsSuccess}
                  id={Number(id)}
                  createTagData={createTagData}
                  data={data}
                  setData={setData}
                  setIsEdit={setIsEdit}
                  isEditing={isEditing}
                  filterTagData={filterTagData}
                  setCurrentPage={setCurrentPage}
                  currentPage={currentPage}
                  total={itemCount}
                  handleClickBtn={handleClickBtn}
                  handleOneGetKey={handleOneGetKey}
                  hiddenFields={hiddenFields}
                  managePassword={managePassword}
                  // setItemCount={setItemCount}
                  sorting={sorting}
                  setSorting={setSorting}
                  // setItemCount={setItemCount}
                  createMyPassword={createMyPassword}
                />
              </div>
            </div>
          )}
        </animated.div>
      </>
    </>
  );
};

export default MyPasswords;
