import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { PaginationState, SortingState } from "@tanstack/react-table";
import { ColumnFiltersState } from "@tanstack/react-table";
import Styles from "../MyLeave/MyLeave.module.scss";
import { CloseIcon, EditIcon, MemberIcon } from "../../common/Icons";
import { renderDateFormatYMD } from "../../DashboardCalendarView/DashboardAddEventPopup";
import { getAuthUser } from "../../../app/services/dashboard";
import DatePopupFoFilter from "../../EmployeeTable/DatePopupFoFilter";
import CustomDropdownListForFilter from "../../EmployeeTable/CustomDropdownListForFilter";
import CustomScrollbar from "../../CustomScrollbar/CustomScrollbar";
import { useSpring } from "react-spring";
import CommonTableSkeleton from "../../TableSkeleton/CommonTableSkeleton";
import { ToastContainer, toast } from "react-toastify";
import { Tdocuments } from "../../../leave-application/views/LeaveApplication";
import DailyReportTable from "./DailyReportTable";
import ErrorPopup from "../../ErrorPopup/ErrorPopup";
import { checkedIsNew } from "../../../app/services/employee";
import {
  IDailyReportData,
  IRefillDateObj,
  TProjectListDailyReport,
  TTaskTagsList,
  useGetProfileDailyReportFilterDataQuery,
  useGetProfileDailyReportListQuery,
  useGetProfileMyDailyReportRefillListQuery,
} from "../../../app/services/profile-daily-report";
import { NumberDropdownList } from "../../common/DropdownList";
import CustomDropdownListForFilterWithImage from "../../EmployeeTable/CustomDropdownListForFilterWithImage";
import DailyReportPopup from "./DailyReportPopup";
import { changeStringToHMS } from "../../CustomTimePicker/CustomTimePicker";
import { TPreviousList } from "../../../app/services/profile-daily-popup";
import Button from "../../common/Button";
import classNames from "classnames";
import MemberReportApproal from "./MemberReportApproal";
import { useSelector } from "react-redux";
import { useViewNotificationByTypeMutation } from "../../../app/services/notification";
import { useDispatch } from "react-redux";
import { setProfileNotification } from "../../../reducers/dashboard";
import { NavLink } from "react-router-dom";
import MyRefillTable from "./MyRefillTable";
import TablePaginator from "../../Tables/TablePaginator";

export const addNewRow = () => {
  return {
    id: 0,
    date: "",
    project_id: "",
    task_name: "",
    task_tag: "",
    time_spent: "",
    isNew: true,
  };
};

const MyRefillReport: FC = () => {
  const [totalTime, setTotalTime] = useState<string>("");
  const [startDate, setStartDate] = useState<Date>();
  const [refillDate, setRefillDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  const [projectId, setProjectId] = useState<any>({});
  const [taskTagId, setTaskTagId] = useState<number>(0);
  const [projectLists, setProjectList] = useState<TProjectListDailyReport[]>();
  const [taskTagsList, setTaskTagsList] = useState<TTaskTagsList[]>();
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 1,
    pageSize: 10,
  });
  const [sorting, setSorting] = useState<SortingState>([]);
  const [errorPopup, setErrorPopup] = useState<boolean>(false);
  const [message, setMessage] = useState<any>([]);
  // const [isEditing, setIsEdit] = useState<boolean>(false);
  // const [uploading, setUploading] = useState<boolean>(false);
  const [viewLeave, setViewLeave] = useState<boolean>(false);
  const [data, setData] = useState<any>([]);
  const [itemCount, setItemCount] = useState<number>(0);
  const [memberID, setMemberID] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [memberData, setMemberData] = useState<any>([]);
  const [isLeader, setIsLeader] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [maxTimeLimit, setMaxTimeLimit] = useState<number>(0);
  const [total_time_str, setTotalTimeStr] = useState<string | undefined>("");
  const [todayData, setTodayData] = useState<any>();
  const [timeHour, setTimeHour] = useState<string>("");
  const [getHour, setGetHour] = useState<number>(0);
  const [getMinute, setGetMinute] = useState<number>(0);
  const [refillDateList, setRefillDateList] = useState<IRefillDateObj[]>([]);
  const [previousDataList, setPreviousDataList] = useState<IDailyReportData[]>(
    []
  );
  const [currentTab, setCurrentTab] = useState<string>("myRefillReport");
  const [pendingCount, setPendingCount] = useState<number>(0);
  const { data: filterData } = useGetProfileDailyReportFilterDataQuery();
  const { data: refillReport } = useGetProfileMyDailyReportRefillListQuery({
    page: currentPage,
    limit: pagination.pageSize,
    start: startDate === undefined ? "" : renderDateFormatYMD(startDate, "-"),
    end: endDate === undefined ? "" : renderDateFormatYMD(endDate, "-"),
    type: projectId.type !== undefined ? projectId.type : "",
    sorting: sorting
      .map(
        (s) =>
          `${s.desc ? "-" : ""}${
            s.id == "time_spent_text" ? "time_spent" : s.id
          }`
      )
      .join(","),
  });
  const stateData: any = useSelector((state) => state);
  const [viewLeaveByType] = useViewNotificationByTypeMutation();
  const dispatch = useDispatch();
  var getUser = getAuthUser();
  const getAuthPermis: string[] = useMemo(() => [], []);

  if (getUser?.permissions) {
    const getPer = getUser?.permissions?.forEach((per: any) => {
      getAuthPermis.push(per?.name);
    });
  }

  useEffect(() => {
    if (refillReport) {
      setData(refillReport.my_refill_request_list.data);
      setItemCount(refillReport.my_refill_request_list.total);
    }
  }, [refillReport]);
  useEffect(() => {
    setCurrentPage(1);
  }, [startDate, endDate]);
  useEffect(() => {
    setTimeout(() => {
      var stateDataDetail = stateData?.dashboard?.notificationDetail;
      var updatedData = {
        profile_unread_noti_count: stateDataDetail?.profile_unread_noti_count,
        unapprove_claim_count: stateDataDetail.unapprove_claim_count,
        unapprove_leave_count: stateDataDetail?.unapprove_leave_count,
        unapprove_ot_count: stateDataDetail?.unapprove_ot_count,
        unread_data_count: {
          unread_password_count:
            stateDataDetail?.unread_data_count?.unread_password_count,
          unread_document_count:
            stateDataDetail?.unread_data_count?.unread_document_count,
          unread_leave_count:
            stateDataDetail?.unread_data_count?.unread_leave_count,
          unread_claim_count:
            stateDataDetail?.unread_data_count?.unread_claim_count,
          unread_my_refill_request_count:
            stateDataDetail?.unread_data_count?.unread_my_refill_request_count,
          unread_member_refill_request_count: 0,
        },
        unread_count: stateDataDetail?.unread_count,
        notifications: stateDataDetail?.notifications,
      };
      viewLeaveByType("daily_report").then((res) => {
        dispatch(setProfileNotification(updatedData));
      });
    }, 5000);
  }, []);
  useEffect(() => {
    if (filterData) {
      setProjectList([
        { id: 0, name: "All", type: "All" },
        ...filterData.project_list,
      ]);
      setTaskTagsList([{ id: 0, name: "All" }, ...filterData.task_categories]);
      setIsLeader(filterData.is_leader);
      setMemberData(filterData.same_department_users);
      setRefillDateList(filterData?.refill_dates);
      setPendingCount(filterData.pending_refill_requests_count);
      // if(filterData.refill_data){
      //   const formatRefillDate = renderDateFormatYMD(new Date(filterData.refill_data[0]?.date), '-');
      //   const todayDataArr: any = filterData.refill_data.find(x => x.date == formatRefillDate);
      //   setRefillDate(new Date(filterData.refill_data[0]?.date));
      //   setTodayData(todayDataArr?.daily_report);

      // }
    }
  }, [filterData]);
  useEffect(() => {
    if (refillDate) {
      if (filterData) {
        const formatRefillDate = renderDateFormatYMD(refillDate, "-");
        var refillDataPrev: any = filterData.previous_data.filter(
          (x) => x.date != formatRefillDate
        );
        const todayData = filterData.refill_data.find(
          (x) => x.date == formatRefillDate
        );
        var time = changeStringToHMS(todayData ? todayData.total_time : "");
        setMaxTimeLimit(time ? time : 0);
        //setTodayData(todayData?.daily_report);
        //setReportData(todayData?.daily_report ? todayData?.daily_report?.items : []);
        setTimeHour(
          todayData?.total_time_text ? todayData?.total_time_text : ""
        );
        setGetHour(todayData?.hours ? todayData?.hours : 0);
        setGetMinute(todayData?.minutes ? todayData?.minutes : 0);
        setTotalTimeStr(todayData?.total_time_text);
        setPreviousDataList(refillDataPrev);
      }
    }
  }, [refillDate]);
  useEffect(() => {
    const viewLeave = getAuthPermis.filter(
      (f) => f == "View_Leave_Application"
    );
    if (viewLeave.length > 0) {
      setViewLeave(true);
    }
  }, [getUser]);

  const props = useSpring({
    to: { opacity: 1 },
    from: { opacity: 0 },
    delay: 500,
  });

  useEffect(() => {
    setCurrentPage(1);
  }, [
    startDate,
    endDate,
    pagination.pageSize,
    projectId?.type,
    projectId?.value,
    taskTagId,
  ]);

  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  // console.log("first my", data);

  return (
    <>
      <>
        <ErrorPopup
          descText="Error Message!"
          confirmBtnText="Yes, Close."
          show={errorPopup}
          setShow={setErrorPopup}
          message={message}
          setMessage={setMessage}
        />

        <ToastContainer className={"text-12 font-poppins font-normal"} />
        {windowSize[0] < 640 ? (
          <div className="w-full bg-white rounded-xl text-left p-5">
            <div className="px-2 sm:px-4 md:px-5 py-1 md:py-2 border-b border-offwhite flex items-center justify-between">
              <div className="flex flex-wrap items-center text-vorpgraylight">
                {/* <h3 className="mr-7 text-[#3F4254] font-semibold text-[13px] sm:text-[15px] 2xl:text-[18px] leading-[22px] lg:leading-[27px]">
                  Daily Report My Refill
                </h3> */}
                <NavLink
                  to={{
                    pathname: "/profile/daily-report",
                  }}
                  data-id="myReport"
                  className={classNames(
                    currentTab == "myReport"
                      ? "text-vorpblue"
                      : "text-vorpgraylight",
                    "text-14 font-semibold  mr-2 relative"
                  )}
                >
                  <span>My Report</span>
                </NavLink>
                <span className={classNames("mx-2")}> | </span>
                <NavLink
                  to={{
                    pathname: "/profile/my-refill-report",
                  }}
                  data-id="myRefillReport"
                  className={classNames(
                    currentTab == "myRefillReport"
                      ? "text-vorpblue"
                      : "text-vorpgraylight",
                    "text-14 font-semibold  mr-2 relative"
                  )}
                >
                  <span>My Application</span>
                </NavLink>
                <span
                  className={classNames(
                    isLeader == true ? "" : "hidden",
                    "mx-2"
                  )}
                >
                  {" "}
                  |{" "}
                </span>
                <NavLink
                  to={{
                    pathname: "/profile/member-daily-report",
                  }}
                  data-id="memberReport"
                  className={classNames(
                    isLeader == true ? "" : "hidden",
                    currentTab == "memberReport"
                      ? "text-vorpblue"
                      : "text-vorpgraylight",
                    "text-14 font-semibold relative"
                  )}
                >
                  <span>Member’s Application</span>
                  {pendingCount > 0 ? (
                    <div className="w-[18px] h-[18px] bg-redPink absolute top-0 left-full rounded-full">
                      <span className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 inline-block text-white text-[10px] font-semibold p-[2px] whitespace-nowrap">
                        {pendingCount}
                      </span>
                    </div>
                  ) : (
                    ""
                  )}
                </NavLink>
              </div>
              {/* <div className="flex justify-end items-center">
                <button
                  onClick={() => {
                    setIsOpen(true);
                  }}
                  className="whitespace-nowrap mb-1 lg:mb-1 xl:mb-1 py-2 my-1 px-3 rounded-md flex items-center focus:outline-none bg-vorpblue hover:border-vorpmyanmar 2xl:text-[14px] text-12 leading-5 font-medium text-white"
                >
                  <EditIcon className="mr-2" />
                  Modify Report
                </button>
              </div> */}
            </div>
            <div className="px-2 sm:px-4 md:px-5 pt-3 pb-[10px] border-b border-offwhite">
              {currentTab == "myRefillReport" ? (
                <div>
                  <div className="mb-1 lg:mb-1 xl:mb-1">
                    <div className="flex justify-between flex-col sm:flex-row xl:items-center">
                      <div className="flex w-full">
                        <div className="flex w-full items-center ">
                          <div className="flex w-full">
                            <p className="hidden mb-3 2xs:mb-0 text-13 leading-5 font-medium text-black2 mr-3">
                              DATE
                            </p>
                            {startDate && endDate && (
                              <button
                                type="button"
                                onClick={() => {
                                  setStartDate(undefined);
                                  setEndDate(undefined);
                                }}
                                className="text-12 mr-2"
                              >
                                <CloseIcon
                                  color="#EE436D"
                                  width={10}
                                  height={10}
                                />
                              </button>
                            )}

                            <div className="flex w-full gap-x-2">
                              <div
                                className={`mr-0 w-full ${Styles.leaveDatePicker}`}
                              >
                                <DatePopupFoFilter
                                  classesProps="min-w-full"
                                  placeholder="Start"
                                  date={startDate}
                                  setDate={setStartDate}
                                />
                              </div>
                              <div
                                className={`mr-0 w-full ${Styles.leaveDatePicker}`}
                              >
                                <DatePopupFoFilter
                                  classesProps="min-w-full"
                                  placeholder="end"
                                  date={endDate}
                                  setDate={setEndDate}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="max-h-[440px] min-h-[430px] overflow-y-auto pt-3">
                    {data?.length
                      ? data.map((data: any, i: any) => {
                          const color =
                            data.current_status == "Approved"
                              ? "text-vorpgreen"
                              : data.current_status == "Rejected"
                              ? "text-redPink"
                              : "text-black2";
                          const bgColor =
                            data.current_status == "Approved"
                              ? "bg-vorpgreen"
                              : data.current_status == "Rejected"
                              ? "bg-redPink"
                              : "bg-black2";

                          return (
                            <div
                              key={i}
                              className="px-3 py-4 bg-[#F3F6F9] rounded-md gap-10 w-full mb-3"
                            >
                              <div className="flex pt-3 w-full">
                                <p className="leading-loose text-10 w-1/2 font-normal text-graydark text-start">
                                  Applied Date
                                </p>
                                <p className=" leading-loose text-black2 w-1/2 text-10 font-normal text-start">
                                  {data.refilled_at ?? "---"}
                                </p>
                              </div>
                              <div className="flex">
                                <p className="text-10 w-1/2 font-normal text-graydark text-start leading-loose">
                                  Report Date
                                </p>
                                <p className=" text-black2 w-1/2 text-10 font-normal text-start leading-loose">
                                  {data.date ? data.date : "---"}
                                </p>
                              </div>
                              <div className="flex">
                                <p className="text-10 w-1/2 font-normal text-graydark text-start leading-loose">
                                  Reason
                                </p>
                                <p className=" text-black2 w-1/2 text-10 font-normal text-start leading-loose">
                                  {data.reason ?? "---"}
                                </p>
                              </div>
                              <div className="flex">
                                <p className="text-10 w-1/2 font-normal text-graydark text-start leading-loose">
                                  Status
                                </p>

                                <div className="flex w-1/2 items-center pb-5">
                                  <p
                                    className={classNames(
                                      `${bgColor}`,
                                      "p-[6px] h-2 rounded-full inline mr-2"
                                    )}
                                  ></p>
                                  <p
                                    className={classNames(
                                      `${color}`,
                                      "inline  text-10 font-normal text-start leading-loose"
                                    )}
                                  >
                                    {data.current_status ?? "---"}
                                  </p>
                                </div>
                              </div>
                            </div>
                          );
                        })
                      : null}
                  </div>
                  <div className="pt-3">
                    <TablePaginator
                      total={itemCount}
                      pagination={pagination}
                      setPagination={setPagination}
                      itemCount={itemCount}
                      setCurrentPage={setCurrentPage}
                      currentPageDefault={currentPage}
                    />
                  </div>

                  <MyRefillTable
                    columnFilters={columnFilters}
                    setColumnFilters={setColumnFilters}
                    data={data ? data : []}
                    setData={setData}
                    // hiddenFields={[]}
                    pagination={pagination}
                    setPagination={setPagination}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    total={itemCount}
                    sorting={sorting}
                    setSorting={setSorting}
                  />
                </div>
              ) : (
                ""
              )}
              <DailyReportPopup
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                // reportData={reportData ? reportData : []}
                // setReportData={setReportData}
                setTotalTimeStr={setTotalTimeStr}
                total_time_str={total_time_str}
                previousDataList={previousDataList}
                setPreviousDataList={setPreviousDataList}
                todayData={todayData}
                setTodayData={setTodayData}
                setTimeHour={setTimeHour}
                timeHour={timeHour}
                setGetHour={setGetHour}
                setGetMinute={setGetMinute}
                getHour={getHour}
                getMinute={getMinute}
                setMaxTimeLimit={setMaxTimeLimit}
                maxTimeLimit={maxTimeLimit}
                refillDate={refillDate}
                setRefillDate={setRefillDate}
                filterData={filterData}
              />
            </div>
          </div>
        ) : (
          <div className="w-full bg-white rounded-xl text-left">
            <div className="px-2 sm:px-4 md:px-5 py-1 md:py-2 border-b border-offwhite flex items-center justify-between">
              <div className="flex flex-wrap items-center text-vorpgraylight">
                <h3 className="mr-7 text-[#3F4254] font-semibold text-[13px] sm:text-[15px] 2xl:text-[18px] leading-[22px] lg:leading-[27px]">
                  Daily Report My Refill
                </h3>
                <NavLink
                  to={{
                    pathname: "/profile/daily-report",
                  }}
                  data-id="myReport"
                  className={classNames(
                    currentTab == "myReport"
                      ? "text-vorpblue"
                      : "text-vorpgraylight",
                    "text-14 font-semibold  mr-2 relative"
                  )}
                >
                  <span>My Report</span>
                </NavLink>
                <span className={classNames("mx-2")}> | </span>
                <NavLink
                  to={{
                    pathname: "/profile/my-refill-report",
                  }}
                  data-id="myRefillReport"
                  className={classNames(
                    currentTab == "myRefillReport"
                      ? "text-vorpblue"
                      : "text-vorpgraylight",
                    "text-14 font-semibold  mr-2 relative"
                  )}
                >
                  <span>My Application</span>
                </NavLink>
                <span
                  className={classNames(
                    isLeader == true ? "" : "hidden",
                    "mx-2"
                  )}
                >
                  {" "}
                  |{" "}
                </span>
                <NavLink
                  to={{
                    pathname: "/profile/member-daily-report",
                  }}
                  data-id="memberReport"
                  className={classNames(
                    isLeader == true ? "" : "hidden",
                    currentTab == "memberReport"
                      ? "text-vorpblue"
                      : "text-vorpgraylight",
                    "text-14 font-semibold relative"
                  )}
                >
                  <span>Member’s Application</span>
                  {pendingCount > 0 ? (
                    <div className="w-[18px] h-[18px] bg-redPink absolute top-0 left-full rounded-full">
                      <span className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 inline-block text-white text-[10px] font-semibold p-[2px] whitespace-nowrap">
                        {pendingCount}
                      </span>
                    </div>
                  ) : (
                    ""
                  )}
                </NavLink>
              </div>
              <div className="flex justify-end items-center">
                <button
                  onClick={() => {
                    setIsOpen(true);
                  }}
                  className="whitespace-nowrap mb-1 lg:mb-1 xl:mb-1 py-2 my-1 px-3 rounded-md flex items-center focus:outline-none bg-vorpblue hover:border-vorpmyanmar 2xl:text-[14px] text-12 leading-5 font-medium text-white"
                >
                  <EditIcon className="mr-2" />
                  Modify Report
                </button>
              </div>
            </div>
            <div className="px-2 sm:px-4 md:px-5 pt-3 pb-[10px] border-b border-offwhite">
              {currentTab == "myRefillReport" ? (
                <div>
                  <div className="mb-1 lg:mb-1 xl:mb-1">
                    <div className="flex justify-between flex-col sm:flex-row xl:items-center">
                      <div className="flex w-full">
                        <div className="flex w-full items-center ">
                          <div className="flex">
                            <p className="hidden mb-3 2xs:mb-0 text-13 leading-5 font-medium text-black2 mr-3">
                              DATE
                            </p>
                            {startDate && endDate && (
                              <button
                                type="button"
                                onClick={() => {
                                  setStartDate(undefined);
                                  setEndDate(undefined);
                                }}
                                className="text-12 mr-2"
                              >
                                <CloseIcon
                                  color="#EE436D"
                                  width={10}
                                  height={10}
                                />
                              </button>
                            )}

                            <div className="flex">
                              <div className={`mr-3 ${Styles.leaveDatePicker}`}>
                                <DatePopupFoFilter
                                  placeholder="Start"
                                  date={startDate}
                                  setDate={setStartDate}
                                />
                              </div>
                              <div className={`mr-3 ${Styles.leaveDatePicker}`}>
                                <DatePopupFoFilter
                                  placeholder="end"
                                  date={endDate}
                                  setDate={setEndDate}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex items-center justify-end md:justify-start sm:w-auto w-full">
                        <div className="flex items-center mb-auto mr-3">
                          <p className="base-label xl:text-sm text-12 hidden 3xs:block">
                            DISPLAY
                          </p>
                          <NumberDropdownList
                            state={pagination.pageSize}
                            setState={(value) => {
                              setPagination((prev) => ({
                                ...prev,
                                pageSize: value as number,
                              }));
                            }}
                            dropdownItems={new Array(5)
                              .fill(1)
                              .map((_, index: number) => {
                                let display = (index + 1) * 10;
                                return {
                                  value: display,
                                  label: String(display),
                                };
                              })}
                            className="max-w-[72px]"
                            textAlign="text-left pl-2 pr-3"
                            paddingClass="py-[2px]"
                            placeholder="20"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <MyRefillTable
                    columnFilters={columnFilters}
                    setColumnFilters={setColumnFilters}
                    data={data ? data : []}
                    setData={setData}
                    // hiddenFields={[]}
                    pagination={pagination}
                    setPagination={setPagination}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    total={itemCount}
                    sorting={sorting}
                    setSorting={setSorting}
                  />
                </div>
              ) : (
                ""
              )}
              <DailyReportPopup
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                // reportData={reportData ? reportData : []}
                // setReportData={setReportData}
                setTotalTimeStr={setTotalTimeStr}
                total_time_str={total_time_str}
                previousDataList={previousDataList}
                setPreviousDataList={setPreviousDataList}
                todayData={todayData}
                setTodayData={setTodayData}
                setTimeHour={setTimeHour}
                timeHour={timeHour}
                setGetHour={setGetHour}
                setGetMinute={setGetMinute}
                getHour={getHour}
                getMinute={getMinute}
                setMaxTimeLimit={setMaxTimeLimit}
                maxTimeLimit={maxTimeLimit}
                refillDate={refillDate}
                setRefillDate={setRefillDate}
                filterData={filterData}
              />
            </div>
          </div>
        )}
      </>
    </>
  );
};

export default MyRefillReport;
