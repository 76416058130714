import { redirectToLogin } from './annoucement-sheet';
import { api } from './api';
import authHeader from './auth-header';
interface IFolder {
  name: string;
}
export interface FileManager {
  folders: MediaData[],
  documents: MediaItems[]
}
export interface MediaData {
  id: number;
  name: string;
  "parent_folder_id": number;
  "created_at": string;
  "updated_at": string;
  documents: MediaItems[]
}
interface MediaFolder {
  documents: MediaItems[]
}
export interface MediaItems {
  id: number;
  name: string;
  type: string;
  path: string;
  url: string;
  hash:string;
  "size": number;
  "folder_id": number;
  "posted_by": number;
  "updated_by": number;
  "last_modified_at": string;
  "created_at": string;
  "updated_at": string;
}
interface MediaFile {
  folder_id: number;
  file: any;
}
export interface Ids {
  ids: number[]
}
export interface ISearch {
  keywords: string
}
export const mediaApi = api.injectEndpoints({
  endpoints: (build) => ({
    getMedias: build.query<any[], void>({
      query: () => ({ url: `https://localhost:3004/fileitems`, headers: authHeader() }),
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
      transformResponse: (res: any) => {
        return res;
      },
    }),
    getFileManager: build.query<FileManager, void>({
      query: () => ({ url: `file-manager`, headers: authHeader() }),
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
      transformResponse: (res: any) => {
        return res;
      },
      providesTags: (result: any = []) => {
        if (result.length > 0) {
          if (result.folders) {
            if (result.folders.length > 0) {
              return [
                ...result.folders?.map((id: any) => ({ type: 'Medias', id } as const)),
                { type: 'Medias' as const, id: 'LIST' },
              ]
            }
            else {
              return [{ type: 'Medias' as const, id: 'LIST' }];
            }
          }
          else {
            return [{ type: 'Medias' as const, id: 'LIST' }];
          }
        } else {
          return [{ type: 'Medias' as const, id: 'LIST' }];
        }
      },
    }),
    getFileManagerWithSearch: build.query<FileManager, ISearch>({
      query: (search) => {
        return ({ url: `file-manager/search?keywords=` + search.keywords, headers: authHeader() })
      },
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
      transformResponse: (res: any) => {
        return res;
      },
      providesTags: (result: any = []) => {
        if (result.length > 0) {
          return [
            ...result?.folders?.map((id: any) => ({ type: 'Medias', id } as const)),
            { type: 'Medias' as const, id: 'LIST' },
          ]
        } else {
          return [[], { type: 'Medias' as const, id: 'LIST' },];
        }
      },
    }),
    addFolder: build.mutation<IFolder, Partial<IFolder>>({
      query: () => ({
        url: `fileitems/items`,
        headers: authHeader(),
        method: 'POST',
        body: { isDirectory: true, name: "test" },
      }),
    }),
    createFolder: build.mutation<FileManager, Partial<IFolder>>({
      query: (data) => ({
        url: `folders`,
        headers: authHeader(),
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [{ type: 'Medias', id: "LIST" }],
    }),
    uploadFile: build.mutation<FileManager, Partial<MediaFile>>({
      query: (data) => ({
        url: `file-manager/upload`,
        headers: authHeader(),
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [{ type: 'Medias', id: "LIST" }],
    }),
    deleteFolder: build.mutation<FileManager, number>({
      query: (data) => ({
        url: `folders/${data}`,
        headers: authHeader(),
        method: 'DELETE',
        body: data,
      }),
      invalidatesTags: [{ type: 'Medias', id: "LIST" }],
    }),
    deleteFile: build.mutation<FileManager, number>({
      query: (data) => ({
        url: `deleteDocument/${data}`,
        headers: authHeader(),
        method: 'DELETE',
        body: data,
      }),
      invalidatesTags: [{ type: 'Medias', id: "LIST" }],
    }),
    deleteFilesMulti: build.mutation<FileManager, Ids>({
      query: (data) => ({
        url: `deleteDocuments`,
        headers: authHeader(),
        method: 'DELETE',
        body: data,
      }),
      invalidatesTags: [{ type: 'Medias', id: "LIST" }],
    }),
    downloadFileDocument: build.mutation<FileManager, number>({
      query: (data) => ({
        url: `downloadDocument/${data}`,
        headers: authHeader(),
        method: 'GET',
        body: data,
      }),
      invalidatesTags: [{ type: 'Medias', id: "LIST" }],
    }),
    downloadFileDocumentMultiple: build.query<FileManager, Ids>({
      query: (data) => ({
        url: `downloadDocument/${data}`,
        headers: authHeader(),
        method: 'GET',
        body: data,
      }),
      transformResponse: (res: any) => {
        return res;
      },
    }),
  }),
});

export const {
  useGetMediasQuery,
  useAddFolderMutation,
  useGetFileManagerQuery,
  useCreateFolderMutation,
  useUploadFileMutation,
  useDeleteFileMutation,
  useDownloadFileDocumentMutation,
  useDeleteFilesMultiMutation,
  useDownloadFileDocumentMultipleQuery,
  useDeleteFolderMutation,
  useGetFileManagerWithSearchQuery

} = mediaApi;

export const {
  endpoints: { getMedias, addFolder, getFileManager, createFolder, uploadFile, deleteFile, downloadFileDocument, deleteFilesMulti, downloadFileDocumentMultiple, deleteFolder, getFileManagerWithSearch },
} = mediaApi;
