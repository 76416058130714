import { useCallback, useEffect, useState } from "react";
import { StringDropdownListWithSearch } from "../common/DropdownListWithSearch";
import classNames from "classnames";
import styles from "./CreateProjectPopup.module.scss";
import { TrashIcon } from "../common/Icons";
import CustomSelect from "./MultiSelectComponentProject";

type CreateAsignto = {
  deleteAssign: (id: number) => void;
  assign: any;
  assignUserListData: any[];
  rowList?: any[];
  setData: any;
};
const CreateProjectAssignTo = ({
  assign,
  rowList,
  deleteAssign,
  assignUserListData,
  setData,
}: CreateAsignto) => {
  const [selectedMember, setSelectedMember] = useState<string>("");
  const [employee, setEmployee] = useState<string>(
    String(assign?.employee_id) ?? ""
  );

  const handleChange = (id: number, value: any) => {
    setEmployee(value);
    setData((prev: any[]) => {
      return prev.map((data) => {
        if (data.id == id) {
          return { ...data, employee_id: value };
        }
        return data;
      });
    });
  };
  const handleChangeSelect = (e: any, id?: any) => {
    setData((prev: any[]) => {
      return prev.map((data) => {
        if (data.id === Number(id)) {
          return { ...data, roles: e.map((e: any) => e) };
        }
        return data;
      });
    });
  };

  return (
    <div className="flex items-center justify-between mb-2 w-full">
      <div className={classNames(styles.memberDropdownList, "w-full px-0")}>
        <StringDropdownListWithSearch
          hasID={assign.id}
          isMultiData={true}
          isProject={true}
          setSearchData={setSelectedMember}
          state={employee}
          setState={handleChange}
          dropdownItems={assignUserListData?.map((member: any) => {
            return {
              label: member?.name,
              value: member?.id,
            };
          })}
          className={classNames(
            styles.memberDropdownList,
            "w-full min-w-[100px] 2xl:min-w-[128px]  xl:text-sm text-xs"
          )}
          placeholder="Select Member"
          textAlign="text-left py-2 pl-2 pr-3"
        />
      </div>
      <div className="px-3 w-full">
        <CustomSelect
          selectedValueData={assign?.roles}
          hasID={assign.id}
          options={rowList?.map((row) => ({
            label: row.label,
            value: row.value,
          }))}
          placeHolder="Select Role"
          onChange={(e: any, id?: any) => handleChangeSelect(e, id)}
          isSearchable
          isMulti
        />
      </div>
      <button
        type="button"
        onClick={() => deleteAssign(assign.id)}
        className={classNames(
          " ml-2 min-w-[3rem] w-12 h-[48px] flex justify-center items-center group rounded-md bg-redPink hover:bg-redPinkLight transition-all duration-300"
        )}
      >
        <TrashIcon />
      </button>
    </div>
  );
};

export default CreateProjectAssignTo;
