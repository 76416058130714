import { FC, useEffect, useState } from "react";
import { People } from "./img";
import { twMerge } from "tailwind-merge";
import styles from "./PasswordsTable.module.scss";
import classNames from "classnames";
interface IUserSelectionTab {
  index: number;
  className?: string;
  initialState?: boolean;
  name: string;
  email: string;
  icon: string;
  setSelectedMember: any;
  id:number;
  isSelected:boolean;
  setIsClear:any;
  selectedMember:number[];
}

const UserSelectionTab: FC<IUserSelectionTab> = ({
  index,
  className = "",
  initialState,
  name,
  email,
  icon,
  setSelectedMember,
  id,
  isSelected,
  setIsClear,
  selectedMember
}) => {
  
  const [isChosen, setIsChosen] = useState<boolean>(false);  
  useEffect(() => {
    if (initialState !== undefined) {
      setIsChosen(initialState);
    }
  }, [initialState,isSelected,selectedMember]);
  const changeSelected=(isSelected:boolean)=>{
    setIsChosen(isSelected)
    setSelectedMember(id,isSelected);
    setIsClear(false);
  }
  return (
    <button
      onClick={() => {        
        changeSelected(!isChosen);        
      }}
      type="button"
      className={classNames(
        `flex rounded-[10px] items-center px-[10px] py-[5px] w-full `,
        className
      )}
    >
      {icon ? (
        <img
          src={icon}
          alt={`Person ${index}`}
          width={34}
          height={34}
          className="rounded-full overflow-hidden w-[34px] h-[34px]"
        />
      ) : (
        <div className="relative bg-yellowBright rounded-full overflow-hidden w-[34px] h-[34px]">
          <p className="text-white font-bold text-13 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
            {name ? name.slice(0, 1) : ""}
          </p>
        </div>
      )}
      <div className="flex flex-col items-start ml-4 text-[#5E6278]">
        <p className="text-14 leading-5 font-semibold">{name}</p>
        <p className="text-15 leading-5">{email}</p>
      </div>
    </button>
  );
};

export default UserSelectionTab;
