import React, {
  Dispatch,
  FC,
  SetStateAction,
  memo,
  useCallback,
  useEffect,
  useState,
} from "react";
import { ProjectDropdownSearchInput } from "../../common/ProjectDropdownSearchInput";
import { TaskDropdownSearchInput } from "../../common/TaskSearchInputDropdown";
import { DropdownListSearchInput } from "../../common/SearchInputDropdown";
import classNames from "classnames";
import ButtonStyles from "./TimeButton.module.scss";
import {
  TProjectListDailyReport,
  TTaskNameList,
  TTaskTagsList,
  useCreateProfileTaskNameMutation,
  useGetProfileDailyTaskListQuery,
  useGetProfileDailyTaskListWithPaginationQuery,
} from "../../../app/services/profile-daily-popup";
import TimePickerViewRenderers from "./TimeWithClock";
import { TrashIcon } from "../../common/Icons";
import dayjs, { Dayjs } from "dayjs";
import styles from "./TimeWithClock.module.scss";
import TimeRangeSlider from "../../TimeRangeComponent/TimeRangeSlider";
import ResizableSlider from "../../TimeRangeComponent/TimeSlider";
import ToggleSwitch from "../../common/ToggleSwitch";
import CustomTimePicker from "../../CustomTimePicker/CustomTimePicker";
import { endpointUrl } from "../../../app/services/api";
import axios from "../../../axios";

type TDailyRow = {
  data: any;
  setData: Dispatch<SetStateAction<any[]>>;
  setSelectedMember: Dispatch<SetStateAction<string>>;
  setIsShow: Dispatch<SetStateAction<boolean>>;
  setProjectData?: Dispatch<any>;
  projectLists: TProjectListDailyReport[];
  setTaskCategoryId?: Dispatch<any>;
  categoriesList: TTaskTagsList[];
  setTaskNamesData: Dispatch<SetStateAction<string>>;
  setTaskNameId: Dispatch<any>;
  // handleCreateTasks: () => void;
  deleteFun?: (id: number) => void;
  deleteFunDraft?: (id: number) => void;
  isDraft: boolean;
  uploading?: boolean;
  projectData?: any;
  taskNamesData?: string;
  taskCategoryId?: any;
  clockInOutHandler: () => void;
  checkValidateData?: boolean;
  setUploadingData: Dispatch<SetStateAction<boolean>>;
  setIsCheckValue?: Dispatch<SetStateAction<boolean>>;
  maxTimeLimit: number;
  setMaxTimeLimit: any;
  handleTimeBlur: any;
  currentNumber: string;
  setCurrentNumber: any;
  currentValue: number;
  setCurrentValue: any
};
const DailyRows: FC<TDailyRow> = ({
  data,
  setData,
  setIsShow,
  setSelectedMember,
  setProjectData,
  projectLists,
  setTaskCategoryId,
  categoriesList,
  setTaskNamesData,
  setTaskNameId,
  // handleCreateTasks,
  deleteFun,
  isDraft,
  uploading,
  projectData,
  taskNamesData,
  taskCategoryId,
  clockInOutHandler,
  deleteFunDraft,
  checkValidateData,
  setUploadingData,
  maxTimeLimit,
  setIsCheckValue,
  setMaxTimeLimit,
  handleTimeBlur,
  currentNumber,
  setCurrentNumber,
  currentValue,
  setCurrentValue
}) => {
  const [taskNames, setTaskName] = useState<TTaskNameList[]>([]);
  const [validSearch, setValidSearch] = useState<boolean>(false);
  const [validProject, setValidProject] = useState<boolean>(false);
  const [validCategory, setValidCategory] = useState<boolean>(false);
  const [projectDataObj, setProjectDataObj] = useState<any>("");
  const [searchDataValue, setSearchDataValue] = useState<string>("");
  const [categoryId, setCategoryId] = useState<any>(0);
  const [taskDataId, setTaskDataId] = useState<any>(0);
  const [taskCreateError, setTaskCreateError] = useState<string>('');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [createTasks] = useCreateProfileTaskNameMutation();
  // const { data: dailyTaksList } = useGetProfileDailyTaskListWithPaginationQuery({
  //   project_id: projectDataObj?.value ?? 0,
  //   category_id: categoryId,// !== 0 ? categoryId : data?.task_category_id, //data?.task_category_id
  //   search: searchDataValue,
  //   limit: 20,
  //   page: currentPage
  // })
  // const { data: dailyTaksList } = useGetProfileDailyTaskListQuery({
  //   project_id: projectDataObj?.value ?? 0,
  //   category_id: categoryId,// !== 0 ? categoryId : data?.task_category_id, //data?.task_category_id
  //   search: searchDataValue,
  // });
  const handleCreateTasks = () => {
    const datas = {
      type: projectDataObj.type ?? data.type,
      project_id: projectDataObj.value ?? data.project_id,
      name: taskNamesData,
      category_id: categoryId !== 0 ? categoryId : data.task_category_id,
    };

    createTasks(datas)
      .then((res: any) => {
        if (res.data.status) {
          setTaskDataId(res.data.task.id ?? 0);
          setTaskName([
            ...taskNames,
            { id: res.data.task.id, name: res.data.task.name },
          ]);
        } else {
          setTaskCreateError(res.data?.message)
        }
      })
      .catch((error: any) => {
        console.log("e", error);
        setTaskCreateError(error?.data?.message)
      });
  };


  // const fetchDataMemoizedTasks = useCallback(() => {
  //   if (dailyTaksList) {
  //     // const itemsPerPage = dailyTaksList?.task_list.total;
  //     // if (taskNames) {
  //     //   const startIndex = (currentPage - 1) * itemsPerPage;
  //     //   const endIndex = startIndex + itemsPerPage;
  //     //   const alltasks = [...taskNames, ...dailyTaksList.task_list?.data];
  //     //   const visibleTodos = alltasks.slice(startIndex, endIndex);



  //     //   //const filterData = alltasks.filter((ele: any, ind: number) => ind === alltasks.findIndex((elem: any) => elem.value === ele.value && elem.label === ele.label))
  //     //   console.log("filterData ", visibleTodos,startIndex,endIndex)
  //     //   setTaskName(alltasks)
  //     // }
  //     const alltasks = [...taskNames, ...dailyTaksList.task_list?.data];
  //     const unique = alltasks.filter((obj: any, index: number) => {
  //       return index === alltasks.findIndex((o: any) => obj.value === o.value);
  //     });
  //     console.log("unique ", unique)
  //     setTaskName(dailyTaksList.task_list?.data)
  //   }
  // }, [dailyTaksList, taskCategoryId]);


  // useEffect(() => {
  //   fetchDataMemoizedTasks();
  // }, [fetchDataMemoizedTasks]);

  useEffect(() => {
    if (
      !data.project_id ||
      !data.task_category_id ||
      data.task_id == 0 ||
      validSearch ||
      validCategory ||
      validProject
    ) {
      setUploadingData(true);
    } else {
      setUploadingData(false);
    }
  }, [
    data?.project_id,
    data?.task_category_id,
    data?.task_id,
    validSearch,
    validCategory,
    validProject,
  ]);
  useEffect(() => {
    setCurrentPage(1)
  }, [searchDataValue])
  useEffect(() => {
    fetchData()
  }, [currentPage,projectDataObj,categoryId,searchDataValue])
  const fetchData = async () => {
    try {
      axios.get(`${endpointUrl}daily-reports-form-task-list-pagination?project_id=${projectDataObj?.value ?? 0}&category_id=${categoryId}&search=${searchDataValue}&page=${currentPage}`, {}).then((result) => {
        const newData = result.data.task_list?.data;
        if(searchDataValue && currentPage==1){
          setTaskName(prevData => [...newData]);
        }
        else{
          setTaskName(prevData => [...prevData, ...newData]);
        }
        setTotalItems(result.data.task_list?.total)
      });

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  return (
    <div
      className={classNames(
        isDraft == true ? "bg-[#F3F6F9]" : "",
        "grid gap-2 sm:grid-cols-2 grid-cols-1 lg:flex pb-3 flex-col lg:flex-row lg:pb-3 justify-between items-start lg:items-center px-3 pt-3 lg:mb-0 mb-3 rounded-4 lg:border-0 border-1 border-vorpgraylight"
      )}
    >
      <div className="w-full lg:w-[150px] mb-6 lg:mb-2 h-[48px]">
        <ProjectDropdownSearchInput
          setIsCheckValue={setIsCheckValue}
          setValidProject={setValidProject}
          setProjectData={setProjectData}
          isObjectGet={true}
          id={data?.uniqueId}
          setData={setData}
          isCreate={false}
          isPlaceHolder="Select Project"
          isNew={true}
          isShow={true}
          setIsShow={setIsShow}
          setSearchData={setSelectedMember}
          // state={projectData?.value}
          state={data.project_id || 0}
          setState={setProjectDataObj}
          dropdownItems={projectLists?.map((member: any) => {
            return {
              label: member.name,
              value: member.id,
              type: member.type,
              is_assign: member.is_assign,
            };
          })}
          className={classNames(
            ButtonStyles.memberDropdownList,
            "w-full min-w-[150px] max-w-[250px] xl:text-sm text-xs h-[46px] mb-0"
          )}
          placeholder="Select Task Tag"
          textAlign="text-left py-1 px-1 rounded-sm"
        />
        {data.project_id === 0 || validProject ? (
          <p className="text-[#EE436D] text-[12px] lg:text-[12px]">
            * Project required
          </p>
        ) : null}
      </div>
      <div className="w-full  lg:w-[200px] mb-6 lg:mb-2 h-[48px] lg:mx-2">
        <TaskDropdownSearchInput
          setValidCategory={setValidCategory}
          setTaskCategoryId={setTaskCategoryId}
          id={data.uniqueId}
          setData={setData}
          isCreate={false}
          isPlaceHolder="Select Task Tag"
          isNew={true}
          isShow={true}
          setIsShow={setIsShow}
          setSearchData={setSelectedMember}
          state={data.task_category_id}
          setState={setCategoryId}
          dropdownItems={categoriesList?.map((member: any) => {
            return {
              label: member.name,
              value: member.id,
              is_assign: member.is_assign,
            };
          })}
          className={classNames(
            ButtonStyles.memberDropdownList,
            "w-full min-w-[200px] max-w-[250px] xl:text-sm text-xs h-[46px] mb-0"
          )}
          placeholder="Select Task Tag"
          textAlign="text-left py-1 px-1 rounded-sm"
        />
        {data.task_category_id === 0 && !taskCategoryId && validCategory ? (
          <p className="text-[#EE436D] text-[12px] lg:text-[12px]">
            *TaskTag required
          </p>
        ) : null}
      </div>
      <div className=" w-full mb-6 lg:w-[300px] lg:mb-2 h-[48px] lg:mx-2">
        <DropdownListSearchInput
          setValidSearch={setValidSearch}
          setTaskNamesData={setTaskNamesData}
          // isDisable={projectData?.value ? false : true}
          isDisable={data.isDisableRow}
          isLabel={data.task_name}
          createTasks={handleCreateTasks}
          taskDataId={taskDataId}
          id={data.uniqueId}
          setData={setData}
          isCreate={true}
          isPlaceHolder="Enter Task Name"
          isNew={true}
          isShow={true}
          setIsShow={setIsShow}
          setSearchData={setSelectedMember}
          state={data.task_id}
          setSearchDataValue={setSearchDataValue}
          // state={taskNameId}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          setState={setTaskNameId}
          setTaskName={setTaskName}
          taskNames={taskNames}
          data={data}
          taskCreateError={taskCreateError}
          fetchMoreData={fetchData}
          total={totalItems}
          dropdownItems={taskNames?.map((member: any) => {
            return {
              label: member.name,
              value: member.id,
            };
          })}
          className={classNames(
            ButtonStyles.memberDropdownList,
            " w-full min-w-[320px] max-w-[380px] xl:text-sm text-xs h-[46px] mb-0 "
          )}
          placeholder="Select Member"
          textAlign="text-left py-1 px-1 rounded-sm"
        />
        {data.task_id === 0 && (data.task_name == '' || data.task_name == undefined) ? (
          <p className="text-[#EE436D] text-[12px] lg:text-[12px] ">
            *Tasks required
          </p>
        ) : null}
      </div>
      <div className="flex mb-2 lg:mb-2  lg:flex-row justify-start items-center w-full lg:w-[350px] ">
        <div
          className={classNames(
            styles.timepickerContainer,
            " w-full mr-2 lg:mr-2 h-[48px] flex border border-slate-300 rounded-md bg-white"
          )}
        >
          <CustomTimePicker
            selectedValue={data.time_spent}
            cell={data}
            id={data.uniqueId}
            setData={setData}
            maxTimeLimit={maxTimeLimit}
            setMaxTimeLimit={setMaxTimeLimit}
            handleTimeBlur={handleTimeBlur}
            currentNumber={currentNumber}
            setCurrentNumber={setCurrentNumber}
            currentValue={currentValue}
            setCurrentValue={setCurrentValue}
          />
        </div>
        <div className="flex justify-center">
          <button
            type="button"
            onClick={() => {
              // if (isDraft) {
              //   if (deleteFunDraft) {
              //     deleteFunDraft(data.id);
              //   }
              // } else {
              //   if (deleteFun) {
              //     deleteFun(data.uniqueId);
              //   }
              // }
              if (deleteFun) {
                deleteFun(data.uniqueId);
              }
            }}
            className={classNames(
              uploading ? " pointer-events-none opacity-30" : "",
              "w-12 h-12 flex justify-center items-center group rounded-md bg-redPink hover:bg-redPinkLight transition-all duration-300"
            )}
          >
            <TrashIcon />
          </button>
        </div>
      </div>
    </div>
  );
};

export default DailyRows;
