import { FC, SetStateAction, memo, useEffect, useRef, useState } from "react";
import { ProfileUploadIcon, WeatherIcon } from "../../common/Icons";
import TimeButton from "./TimeButton";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import classNames from "classnames";
import { userRoutes } from "../../../userProfile";
import User from "./img/user.png";
import {
  IUser,
  ProfileInfoData,
  useCreateCheckInOutMutation,
} from "../../../app/services/profile";
// import { baseObj } from "../../../utils/constants";
import { getAuthUser } from "../../../app/services/dashboard";
import styles from "./TimeButton.module.scss";
import { endpointUrl } from "../../../app/services/api";
import axios from "../../../axios";
import CommonErrorPopup from "../../common/CommonErrorPopup";
import { useSelector } from "react-redux";
import ClockLiveComponent from "./ClockLiveComponent";
import { TDataList, TProfileDailyListResponse, TProjectListDailyReport, TTaskTagsList } from "../../../app/services/profile-daily-popup";

export interface ITimeInData {
  in?: string;
  out?: string;
  hours?: string;
  gps?: string;
  url?: string;
}

interface IUserData {
  user?: IUser;
  userTimeSheet?: any;
  clockStatus?: string | undefined;
  data: ProfileInfoData | undefined;
  id: number;
  // formattedDate: string;
  // formattedTime: string;
  notificationsList: any;
  latitude: string;
  longitude: string;
  setProjectLists: React.Dispatch<React.SetStateAction<TProjectListDailyReport[]>>;
  projectLists: TProjectListDailyReport[];
  setCategoriesList: (value: SetStateAction<TTaskTagsList[]>) => void;
  categoriesList: TTaskTagsList[];
  setReportDate: (value: SetStateAction<string>) => void;
  reportDate: string;
  dailyPopupData: TDataList | undefined;
}
export interface NavData {
  id: number;
  label: string;
}

const UserDailyInfo: FC<IUserData> = ({
  data,
  user,
  id,
  // formattedTime,
  // formattedDate,
  notificationsList,
  latitude,
  longitude,
  setProjectLists,
  projectLists,
  setCategoriesList,
  categoriesList,
  setReportDate,
  reportDate,
  dailyPopupData
}) => {
  var userData = getAuthUser()?.user?.name;
  var userDataInfo = getAuthUser();

  const [createCheckInOut] = useCreateCheckInOutMutation();

  const [timeInData, setTimeInData] = useState<any>([]);
  const [activeStatus, setActiveStatus] = useState<boolean>(false);
  const [activeHour, setActiveHour] = useState<string>('');

  const [userName, setUserName] = useState<string>("");
  const [position, setPosition] = useState<string>("");
  // const [currentTime, setCurrentTime] = useState<string>('');
  // const [currentDate, setCurrentDate] = useState<string>("");
  const [clockStatus, setClockStatus] = useState<string>("");
  const [profileImage, setProfileImage] = useState<string>("");
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  const location = useLocation();
  const [skip, setSkip] = useState<boolean>(false);

  const [state, setState] = useState(localStorage.getItem("state"));
  const profileImageRef = useRef<HTMLInputElement>(null);
  const [notifications, setNotifications] = useState<any>([]);

  var getUser = getAuthUser();
  const stateData: any = useSelector((state) => state);
  const navigate = useNavigate();
  useEffect(() => {
    localStorage.removeItem("state");
    changeActiveUrl()
  }, []);

  useEffect(() => {
    if (location.state) {
      setState(location.state);
    }
  }, [location]);
  useEffect(() => {
    setNotifications(notificationsList);
  }, [notificationsList]);
  useEffect(() => {
    setNotifications(stateData?.dashboard?.notificationDetail);
  }, [stateData]);

  var userRoutesFilter = userRoutes;
  if (id) {
    userRoutesFilter = userRoutesFilter.map((f) => {
      f.profileID = id;
      return f;
    });
  }

  // useEffect(() => {
  //   var currentDate = new Date().toLocaleDateString("en-US", {
  //     year: "numeric",
  //     month: "long",
  //     day: "numeric",
  //   });
  //   setCurrentDate(currentDate);
  //   displayTime();
  // }, []); //old is empty dependency

  useEffect(() => {
    var useDD = data;
    if (Number(id) !== 0) {
      useDD = userDataInfo;
    }
    if (data) {
      setUserName(data?.user?.name);
      if (data?.user?.photo) {
        var imgPath = data?.user?.photo;
        var hostname = window.location.host;
        if (hostname.includes("localhost")) {
          hostname = "http://vorpapidev.visibleone.io/";
        }
        if (imgPath.replaceAll(hostname) !== "undefined") {
          var loginData = getAuthUser();
          if (Number(loginData?.user?.id) === Number(data?.user?.id)) {
            document
              .getElementById("profileImage")
              ?.setAttribute("src", data?.user?.photo);
          }
          setProfileImage(data?.user?.photo);
        } else {
          setProfileImage("");
        }
      }
      setPosition(data?.designationData?.designation);
    }

    if (data) {
      setActiveStatus(data?.activeStatus)
      setActiveHour(data?.totalHours)
      setClockStatus(data.clockStatus);
      var timeSheetData: any = data?.timeSheets
        .map((timesheet: any, index: number) => {
          return {
            ...timesheet,
            url:
              "https://maps.google.com/maps?q=" +
              timesheet.latitude +
              "," +
              timesheet.longitude +
              "",
          };
        })
        .slice(0, 3);
      if (
        Number(timeSheetData?.length) !== 0 &&
        Number(timeSheetData?.length) === 3 &&
        Number(timeSheetData?.length) >= 3 &&
        timeSheetData[2]?.checkOut !== "N/A"
      ) {
        setIsDisabled(true);
      } else {
        setIsDisabled(false);
      }
      setTimeInData(timeSheetData);
    }
  }, [data]); //old is data

  const displayTime = () => {
    // let Accepted_time = new Date();
    // const option = { timeZone: 'Asia/Hong_Kong', hour12: false }
    // var currentDate = Accepted_time.toLocaleString('zh-HK', option);
    // var dd = currentDate.split(' ');
    // var dateDate = dd[0];
    // var timeDate = dd[1];
    // var dateList = dateDate.split('/');
    // var day = dateList[0].length <= 1 ? '0' + dateList[0] : dateList[0];
    // var month = dateList[1].length <= 1 ? '0' + dateList[1] : dateList[1];
    // var year = dateList[2];
    // var hkDate = new Date(year + '-' + month + '-' + day + 'T' + timeDate + '');
    // var currentHour = hkDate.getHours().toString();
    // var currentMinute = hkDate.getMinutes().toString();
    // var currentSecond = hkDate.getSeconds().toString();
    // if (currentHour.length < 2) {
    //   currentHour = '0' + currentHour;
    // }
    // if (currentMinute.length <= 1) {
    //   currentMinute = '0' + currentMinute;
    // }
    // if (currentSecond.length <= 1) {
    //   currentSecond = '0' + currentSecond;
    // }
    // var curTime = currentHour + ':' + currentMinute + ':' + currentSecond;
    // setCurrentTime(curTime);
    // setTimeout(() => {
    //   displayTime();
    // }, 1000)
  };

  const changeProfileImage = () => {
    profileImageRef.current?.click();
  };
  const changeProfile = (e: any) => {
    var filesize = Math.round(e.target.files[0]?.size / 1000) / 1024;
    if (filesize > 2) {
      setErrorMsg("The uploaded image size is greater than 2mb. ");
      return;
    }
    var url = URL.createObjectURL(e.target.files[0]);
    setProfileImage(url);
    document.getElementById("profileImage")?.setAttribute("src", url);
    uploadFileFunc(e.target.files[0]);
  };
  const uploadFileFunc = async (file: any) => {
    if (file) {
      let formSaveData = new FormData();
      formSaveData.append("photo", file);
      // var importedData = {
      //   photo: file,
      // };
      await axios(endpointUrl + "profile/upload-profile", {
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: formSaveData,
      })
        .then((res: any) => {
          // axios.get(`${endpointUrl}file-manager`, {}).then((result) => {
          // });
        })
        .catch((err) => {
          setErrorMsg(err?.response?.data.message);
        });
    }
  };
  const changeNextUrl = () => {
    let location = window.location.pathname.replaceAll('/profile/', '');
    location = location.replaceAll('/profile', '');
    const activeUrl = location;//window.location.pathname.replaceAll(location, '');
    const url = userRoutesFilter.find(x => x.path == activeUrl);
    const index = url ? userRoutesFilter.indexOf(url) : -1;
    let nextIndex = Number(index) + 1;
    const container = document.getElementById('profile-navlink');
    const activeLink = document.querySelector('#profile-navlink a.active-link');    
    let left = 0;
    if (nextIndex >= userRoutesFilter.length) {
      nextIndex = 0;
      left = 0;
    }
    const nextUrl = userRoutesFilter[nextIndex];
    document.querySelector('#profile-navlink a[href="'+nextUrl.path+'"]')?.classList.add('active-link');
    navigate('/profile/' + nextUrl?.path);
    if (container && activeLink) {
      const position = activeLink.getClientRects()[0];
      left = nextIndex == 0 ? position?.x : (nextIndex * position?.width);
      if (nextIndex == 0) {
        left = 0;
      }
      if (nextUrl.label.includes("Daily Report")) {
        nextIndex = 6;        
        left = nextIndex == 0 ? position?.x : (nextIndex * position?.width);
      }

      // console.log("left ", left, nextIndex, nextUrl.label)
      container.scrollLeft = left;
    }
  }
  const changePrevUrl = () => {
    let location = window.location.pathname.replaceAll('/profile/', '');
    location = location.replaceAll('/profile', '');
    const activeUrl = location;//window.location.pathname.replaceAll(location, '');
    const url = userRoutesFilter.find(x => x.path == activeUrl);
    const index = url ? userRoutesFilter.indexOf(url) : -1;    
    let nextIndex = Number(index) - 1;
    const container = document.getElementById('profile-navlink');
    const activeLink = document.querySelector('#profile-navlink a.active-link');    
    let left = 0;
    if (nextIndex <= 0) {
      nextIndex = 0;
      left = 0;
    }
    const nextUrl = userRoutesFilter[nextIndex];
    document.querySelector('#profile-navlink a[href="'+nextUrl.path+'"]')?.classList.add('active-link');
    navigate('/profile/' + nextUrl?.path);
    if (container && activeLink) {
      const position = activeLink.getClientRects()[0];
      left = nextIndex == 0 ? position?.x : (nextIndex * position?.width);
      if (nextIndex == 0) {
        left = 0;
      }
      if (nextUrl.label.includes("Daily Report")) {
        nextIndex = 6;        
        left = nextIndex == 0 ? position?.x : (nextIndex * position?.width);
      }
      container.scrollLeft = left;
    }
  }
  const changeActiveUrl = () => {
    let location = window.location.pathname.replaceAll('/profile/', '');
    location = location.replaceAll('/profile', '');
    const activeUrl = location;
    const url = userRoutesFilter.find(x => x.path == activeUrl);
    const index = url ? userRoutesFilter.indexOf(url) : -1;
    const container = document.getElementById('profile-navlink');
    const activeLink = document.querySelector('#profile-navlink a.active-link');
    let left = 0;
    if (container && activeLink) {
      const position = activeLink.getClientRects()[0];
      left = index == 0 ? position?.x : (index * position?.width);
      if (index == 0) {
        left = 0;
      }
      container.scrollLeft = left;
    }
  }


  return (
    <div className="rounded-lg bg-white px-2 sm:px-4 md:px-5 6xl:px-7">
      <div className={classNames("pt-4 sm:pt-[30px] pb-2 ")}>
        {/* <button onClick={() => window.history.back()} className={classNames((id == 0 || id==null) ? 'hidden' : '', "flex ml-auto justify-end  font-12 font-semibold font-poppins text-[#888C9F] px-5 py-1 bg-[#E4E6EF] rounded-md right-0 top-2")}>
          Back
        </button> */}
        <div className="flex flex-col md:flex-row justify-between items-start">
          <div className="md:mr-8 xs:mb-4 md:mb-0 flex items-center md:block">
            <div className="relative max-w-[130px] 2xl:max-w-[160px] mb-5">
              <img
                src={User}
                alt="Visibee"
                width={160}
                height={160}
                className="rounded-lg mb-5 hidden"
              />
              <div className="inline-block relative">
                <div
                  className={classNames(
                    styles.profileImageDiv,
                    "bg-[#A5A6F6] rounded-[6px] 2xl:w-[160px] 2xl:h-[160px] sm:w-[130px] sm:h-[130px] w-[100px] h-[100px]"
                  )}
                >
                  {profileImage ? (
                    <img
                      src={profileImage}
                      alt="profile"
                      className="w-full h-full object-cover rounded-lg"
                    />
                  ) : (
                    <button className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 uppercase py-[5px] px-[14px] font-poppins font-medium md:text-[32px] text-[28px]">
                      {userName ? userName.slice(0, 1) : userData?.slice(0, 1)}
                    </button>
                  )}
                  <div
                    onClick={() => changeProfileImage()}
                    className={classNames(
                      Number(id) !== 0 || userName === ""
                        ? `hidden`
                        : ` ${styles.customUploadBtn}`,
                      "hidden rounded-lg w-full h-full absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 cursor-pointer"
                    )}
                  >
                    <ProfileUploadIcon color="#fff" width={126} height={126} />
                    <input
                      onChange={(e) => changeProfile(e)}
                      className="hidden"
                      type="file"
                      ref={profileImageRef}
                      accept="image/*"
                    />
                  </div>
                </div>
              </div>
              <div
                className={classNames(
                  activeStatus ? "bg-[#50CD89]" : "bg-[#464E5F]",
                  "w-4 h-4 rounded-full absolute -right-4 bottom-6 -translate-x-1/2"
                )}
              ></div>
            </div>
            <div className="pl-3 xs:pl-6 md:pl-0 mt-[-10px] md:mt-0">
              <h2 className="block md:hidden text-14 5xs:text-18 xs:text-24 leading-5 font-semibold mb-1 text-black2">
                {userName}
              </h2>
              <span className="block md:hidden text-12 lg:text-14 leading-[18px] font-semibold text-vorpgraylight mb-3">
                {position}
              </span>
              <p className="text-12 leading-[18px] font-semibold text-black2 pl-0 md:pl-1  whitespace-nowrap">
                Total Hours:{" "}
                <span className="text-12 lg:text-14 leading-5 font-normal">
                  {activeHour ? activeHour : 0}
                </span>
              </p>
            </div>
          </div>
          <div className=" w-full">
            <div className="flex flex-col-reverse xsm:flex-row xsm:items-center w-full">
              <div className="flex justify-between items-center w-full">
                <div className="hidden md:block">
                  <h2 className="text-18 leading-4 font-semibold text-black2">
                    {userName}
                  </h2>
                  <span className="text-12 leading-[18px] font-semibold text-vorpgraylight break-words whitespace-normal">
                    {position}
                  </span>
                </div>
                <ClockLiveComponent />
              </div>
              <div
                className={classNames(
                  id ? (Number(id) !== 0 ? "hidden" : "") : ""
                )}
              >
                <TimeButton
                  location={location}
                  isDisabled={isDisabled}
                  setIsDisabled={setIsDisabled}
                  count={timeInData?.length}
                  setTimeInData={setTimeInData}
                  timeInData={timeInData}
                  createCheckInOut={createCheckInOut}
                  latitude={latitude}
                  longitude={longitude}
                  clockStatus={clockStatus}
                  setClockStatus={setClockStatus}
                  setActiveHour={setActiveHour}
                  setActiveStatus={setActiveStatus}
                  setProjectLists={setProjectLists}
                  projectLists={projectLists}
                  setCategoriesList={setCategoriesList}
                  categoriesList={categoriesList}
                  setReportDate={setReportDate}
                  reportDate={reportDate}
                  dailyPopupData={dailyPopupData}
                />
              </div>
            </div>
            <div className="bg-[#DBE6ED] rounded-[8px] text-black2 px-2 md:px-4 py-[6px] mt-2 grid grid-cols-5 text-12 lg:text-[13px] sm:text-[15px] leading-[22px] font-medium">
              <h3 className="lg:text-[15px] text-12">In</h3>
              <h3 className="lg:text-[15px] text-12">Out</h3>
              <h3 className="lg:text-[15px] text-12">Hours</h3>
              <h3 className="col-span-2 lg:text-[15px] text-12">GPS</h3>
            </div>
            {timeInData?.filter((x: any) => x?.id != null)?.slice(0, 3)?.map((data: any, index: number) => {
              var url =
                "https://maps.google.com/maps?q=" +
                data.latitude +
                "," +
                data.longitude +
                "";
              return (
                <div
                  key={index}
                  className="bg-offwhite rounded-[8px] text-black2 px-2 xs:px-3 md:px-4 py-[6px] mt-2 grid grid-cols-5 text-10 5xs:text-12 sm:text-[14px] leading-[21px] font-medium"
                >
                  <h3 className="lg:text-[15px] text-12">
                    {data?.checkIn ? data?.checkIn : "N/A"}
                  </h3>
                  <h3 className="lg:text-[15px] text-12">
                    {data?.checkOut ? data?.checkOut : "N/A"}
                  </h3>
                  <h3 className="lg:text-[15px] text-12">{data?.hour}</h3>
                  <h3 className="col-span-2 lg:text-[15px] text-12">
                    {/* {data?.gps ? (
                    <a target="_blank" className="text-vorpblue" href={data.url}>
                      {data.gps}
                    </a>
                  ) : (
                    // <span className="text-vorpblue">{data.gps}</span>
                    "-"
                  )} */}

                    <a
                      target="_blank"
                      className="text-vorpblue underline underline-offset-[3px]"
                      href={url}
                    >
                      {data?.latitude
                        ? data?.latitude + ", " + data?.longitude
                        : ""}
                    </a>
                  </h3>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="w-full px-[2px] max-w-[100%] overscroll-auto">
        <div className="rounded-[3px] w-full h-[1px] bg-offwhite "></div>
        <div className="flex items-center w-full justify-between">
          <div onClick={()=>changePrevUrl()} className="md:hidden block cursor-pointer mr-5 w-[28px]">
            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
              <rect width="28" height="28" rx="6" fill="#F3F6F9" />
              <path d="M16.2597 17.7383C16.5411 18.014 16.5411 18.4609 16.2597 18.7366C15.9783 19.0123 15.522 19.0123 15.2406 18.7366L10.9171 14.5013C10.6443 14.2341 10.6348 13.8038 10.8955 13.5252L14.8587 9.28992C15.1276 9.00254 15.5834 8.98312 15.8768 9.24655C16.1702 9.50998 16.19 9.9565 15.9211 10.2439L12.424 13.981L16.2597 17.7383Z" fill="#858795" />
            </svg>
          </div>
          {/* <div className="flex pt-4 justify-start items-center  flex-wrap 2xl:flex-nowrap max-w-full overflow-x-auto custom_scrollbar_popup_x"> */}
          <div id="profile-navlink" className="scroll-smooth flex pt-4 justify-start items-center flex-nowrap md:max-w-full max-w-[60%] overflow-x-auto custom_scrollbar_popup_x">
            {userRoutesFilter.map(
              ({ label, path, component, profileID }, index: number) => {
                // var pathData = ((path ? (profileID ? path + "?id=99" : path) : path))
                return (
                  <NavLink
                    to={{
                      pathname: path,
                    }}
                    className={
                      (({ isActive }) =>
                        // classNames(label == "Employee Evaluations" ? "opacity-50 pointer-events-none" : '',
                        classNames(
                          path == "member-daily-report" ? 'md:hidden' : '',
                          path == "my-refill-report" ? 'md:hidden' : '',
                          isActive ? 'active-link' : '',
                          isActive
                            ? "md:border-b-[3px] md:border-b-vorpblue text-vorpblue"
                            : "md:border-b-[3px] md:border-b-white",
                          "md:min-w-max min-w-full text-12 lg:text-14 leading-5 font-semibold pb-2 mb-2 text-center 3xl:text-left relative hover:text-vorpblue transition-all",
                          {
                            "text-graydark": !isActive,
                            "mr-0 mb-2 md:mr-6 5xl:mr-9":
                              index !== userRoutes.length - 1,
                            // "mb-auto": index === userRoutes.length - 1,
                          }
                        ))
                    }
                    state={profileID}
                    key={path}
                    about={profileID.toString()}
                    end
                  >
                    <span className="block md:mr-[5px] mr-[3px] whitespace-nowrap">
                      {label}
                    </span>
                    <div
                      className={classNames(
                        (path == "daily-report" &&
                          // notifications?.unread_data_count?.unread_member_refill_request_count >
                          // 0) ||
                          // (path == "daily-report" &&
                          notifications?.unread_data_count?.unread_my_refill_request_count >
                          0) ||
                          (path == "leave" &&
                            notifications?.unread_data_count?.unread_leave_count >
                            0) ||
                          (path == "documents" &&
                            notifications?.unread_data_count
                              ?.unread_document_count > 0) ||
                          (path == "myclaim" &&
                            notifications?.unread_data_count?.unread_claim_count >
                            0) ||
                          (path == "passwords" &&
                            notifications?.unread_data_count
                              ?.unread_password_count > 0)
                          ? ""
                          : "hidden",
                        "w-[18px] h-[18px] bg-redPink absolute top-0 left-full rounded-full"
                      )}
                    >
                      <span className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 inline-block text-white text-[10px] font-semibold p-[2px] whitespace-nowrap">
                        {path == "leave"
                          ? notifications?.unread_data_count?.unread_leave_count
                          : path == "documents"
                            ? notifications?.unread_data_count
                              ?.unread_document_count
                            : path == "myclaim"
                              ? notifications?.unread_data_count?.unread_claim_count
                              : path == "passwords"
                                ? notifications?.unread_data_count
                                  ?.unread_password_count :
                                path == "daily-report" ?
                                  (notifications?.unread_data_count?.unread_member_refill_request_count + notifications?.unread_data_count?.unread_my_refill_request_count)
                                  : ""}
                      </span>
                    </div>
                  </NavLink>
                );
              }
            )}
          </div>
          <div onClick={() => {
            changeNextUrl()
          }} className="md:hidden block cursor-pointer ml-5 w-[28px]">
            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
              <rect width="28" height="28" rx="6" transform="matrix(-1 0 0 1 28 0)" fill="#F3F6F9" />
              <path d="M11.7403 17.7383C11.4589 18.014 11.4589 18.4609 11.7403 18.7366C12.0217 19.0123 12.478 19.0123 12.7594 18.7366L17.0829 14.5013C17.3557 14.2341 17.3652 13.8038 17.1045 13.5252L13.1413 9.28992C12.8724 9.00254 12.4166 8.98312 12.1232 9.24655C11.8298 9.50998 11.81 9.9565 12.0789 10.2439L15.576 13.981L11.7403 17.7383Z" fill="#858795" />
            </svg>
          </div>
        </div>
      </div>
      <CommonErrorPopup
        show={errorMsg != "" ? true : false}
        setShow={() => setErrorMsg("")}
        message={errorMsg}
      />
    </div>
  );
};

export default memo(UserDailyInfo);
