import { Dispatch, FC, FocusEvent, SetStateAction, useEffect, useRef, useState } from "react";
import { uniqueId } from "lodash";
import { ChevronDown } from "../common/Icons";
import styles1 from "../common/Custom.module.scss";
const classNames = require("classnames");

interface ICustomDropdownList<P = unknown> {
  state: P;
  setState: any;
  placeholder?: string;
  dropdownItems: IListItem[];
  className?: string;
  textAlign?: string;
  dropdownborder?: string;
  parendID: number;
  leaveDataType?: string;
  isSearch?: boolean;
}

interface IListItem {
  label: string;
  value: string | number;
  indicator?: string | number;
}

// Type Factory for Generic Types

const CustomDropdownListFactory = <P extends unknown>() => {
  const ActualInstance: FC<ICustomDropdownList<P>> = ({
    state,
    setState,
    dropdownItems,
    placeholder = "All",
    className = "",
    textAlign = "text-center",
    dropdownborder = "",
    parendID = 0,
    leaveDataType = "",
    isSearch = false
  }) => {
    var name = useRef<any>(null);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [filterSearch, setFilterSearch] = useState<string>('');
    const [label, setLabel] = useState<string>(
      dropdownItems?.filter((item) => item.value == state)[0]?.label ||
      placeholder
    );
    const [dropdownList, setDropdownList] = useState<IListItem[]>(dropdownItems);
    const uuid = uniqueId("custom-dropdown-list");

    const handleCloseDropdown = (event: FocusEvent<HTMLButtonElement>) => {
      const relatedTarget = event.relatedTarget;
      // If the click target is not the dropdown or dropdown item, close the list.
      if (
        !relatedTarget ||
        !(
          relatedTarget.id == uuid ||
          relatedTarget.className.includes(`${uuid}item`)
        )
      ) {
        setIsOpen(false);
      }
    };
    useEffect(() => {
      setDropdownList(dropdownItems)
    }, [dropdownItems])
    const handleInputChange = (search: string) => {
      setFilterSearch(search);
      setDropdownList(dropdownItems?.filter((x: any) => (
        x.label.toLocaleLowerCase().startsWith(search.toLocaleLowerCase())
        || x.label.toLocaleLowerCase().includes(search.toLocaleLowerCase()))))
    }

    return (
      <div className="relative customDropdownContainer  border border-[#F3F6F9] w-full">
        <button
          id={uuid}
          className={classNames(
            dropdownborder,
            `flex hover:cursor-pointer text-14 leading-5 text-graydark relative justify-between w-32 h-10 items-center pl-[11px] pr-[17px] border border-[#CCDAE4] rounded-[5px] custom-dropdown-list ${className}`
          )}
          onClick={() => {
            setIsOpen(!isOpen); setFilterSearch('');
            if(name){
              name.current.value="";
            }
          }}
          onBlur={handleCloseDropdown}
        >
          {String(label) || String(state) || placeholder}
          <ChevronDown />
        </button>
        <div className={classNames("relative", { hidden: !isOpen })}>
          <div
            className={classNames(
              isSearch == true ? '' : 'hidden',
              "absolute z-[9999] top-0 bg-white rounded-4 h-[40px] border-zinc-200 border-solid border-2 w-full mb-0 flex items-center justify-between px-2 py-2 border--b-goalinputborder"
            )}
          >
            <input
              onChange={(e) => {
                handleInputChange(e.target.value);
                setIsOpen(true);
              }}
              autoFocus={true}
              ref={name}
              type="text"
              className={classNames(
                uuid + "item",
                "text-graydark bg-transparent font-poppins h-full font-normal xl:text-sm text-xs rounded w-full bg-[#fff] focus:outline-none py-[10px]"
              )}
              placeholder={"Enter Name"}
            />
          </div>
          <div
            className={classNames(
              styles1.customDropdownScroll,
              isSearch == true ? 'top-[40px]' : 'top-0',
              " absolute left-0 text-12 max-h-[150px] overflow-y-auto z-10 overflow-hidden w-full border border-[#EFF2F5] bg-white rounded-md",
              { hidden: !isOpen }
            )}
          >

            {filterSearch ?
              dropdownList?.map((item: IListItem) => (
                <button
                  key={item.value}
                  className={`px-5 custom-dropdown-item bg-white hover:bg-vorpblue py-1 w-full hover:text-white transition-all duration-300 text-graydark ${textAlign} ${uuid + "item"
                    }`}
                  onClick={() => {
                    setState({ eventID: parendID, leaveID: item.value as any, indicator: item.indicator, leaveDataType: leaveDataType, label: item.label });
                    setLabel(item.label);
                    setIsOpen(false);
                    if (name) {
                      name.current.value = ""
                    }
                  }}
                >
                  {item.label}
                </button>
              )) : dropdownItems?.map((item: IListItem) => (
                <button
                  key={item.value}
                  className={`px-5 custom-dropdown-item bg-white hover:bg-vorpblue py-1 w-full hover:text-white transition-all duration-300 text-graydark ${textAlign} ${uuid + "item"
                    }`}
                  onClick={() => {
                    setState({ eventID: parendID, leaveID: item.value as any, indicator: item.indicator, leaveDataType: leaveDataType, label: item.label });
                    setLabel(item.label);
                    setIsOpen(false);
                    setFilterSearch('');
                    if (name) {
                      name.current.value = ""
                    }
                  }}
                >
                  {item.label}
                </button>
              ))}
          </div>
        </div>
      </div>
    );
  };
  return ActualInstance;
};
// Extend More for Required Types

export const CustomLeaveTypeDropdown = CustomDropdownListFactory<string>();
