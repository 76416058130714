import React, {
  // Component,
  Dispatch,
  FC,
  SetStateAction,
  useState,
  useRef,
  useEffect,
  // useCallback,
} from "react";
import { Modal, Fade, CircularProgress } from "@mui/material";

import "tinymce/skins/ui/oxide/skin.min.css";
import { Editor } from "@tinymce/tinymce-react";
import CustomDatePicker from "../../components/common/FullWidthDatePicker";

import { StringDropdownList } from "../../components/common/DropdownList";
// import { tagTypeArr, tagTypes } from "../../utils/announcementDatas";
// import { StringMultipleSelectBox } from "../common/MultipleSelectBox";
import whiteCancel from "../common/img/white-cancel.svg";
import blackCancel from "../common/img/black-cancel.svg";
import saveIcon from "./img/submit-icon.svg";
import {
  // ErrorState,
  useGetTagsDataQuery,
} from "../../app/services/annoucement-sheet";
import { CommonTagDropdown } from "../common/CommonTagDropdown";
import DOMPurify from "dompurify";
import classNames from "classnames";
import { renderDateFormatYMD } from "../DashboardCalendarView/DashboardAddEventPopup";

export const typeList = ["Caution", "Notice", "Warning"];

interface IModalAnnounEditor {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  valueChangeCallback: (value: any) => void;
  totalLength: number;
  loggedUser: string;
  createTagData?: (label: string) => void;
  saving?: boolean;
  setSaving?: Dispatch<SetStateAction<boolean>>;
}

// interface IFilterStates {
//   tag: string;
// }
const ModalAnnounEditor: FC<IModalAnnounEditor> = ({
  isOpen,
  setIsOpen,
  valueChangeCallback,
  totalLength,
  loggedUser,
  createTagData,
  saving,
  setSaving,
}) => {
  const { data: tagsData } = useGetTagsDataQuery();

  const tags = tagsData?.tagsData
    .filter((x: any) => x.flag === "anno" || x.flag === null)
    ?.map((tag: any, idx: any) => ({
      label: tag.name,
      value: tag.name,
    }))
    .filter((tag: any) => tag.label.toLowerCase() !== "all");

  const editorRef: any = useRef(null);
  const [goalTitle, setGoalTitle] = useState("");

  const [chosenTags, setChosenTags] = useState<string[]>([]);
  // onSave: (value:any) => void,
  // tasks: any;
  // mainTitle: string,
  // expiryDate: string,
  // tag:[tagStyle],
  // postedDate: string,
  const [postedDate, setPostedDate] = useState(new Date());
  const [expiryDate, setExpiryDate] = useState<Date>();
  const [myGoal, setMyGoal] = useState("");
  // const [loading, setLoading] = useState(true);
  const [titleError, setTitleError] = useState("");
  const [contentError, setContentError] = useState("");
  const [expireError, setExpireError] = useState("");
  const [tagError, setTagError] = useState("");
  const [typeData, setTypeData] = useState(typeList[0]);
  // const [typeError, setTypeError] = useState("");
  const goalTitleHandler = (event: any) => {
    setGoalTitle(event.target.value);
  };
  // const [filterStates, setFilterStates] = useState<IFilterStates>({
  //   tag: "All",
  // });

  // const updateFilterState = useCallback(
  //   (value: string, key: string) => {
  //     setFilterStates((prev) => ({ ...prev, [key]: value }));
  //   },
  //   [setFilterStates]
  // );

  const handleEditorChange = (event: any) => {
    // var content = event.target.getContent();
    // const content = DOMPurify.sanitize(event.target.getContent());
    // console.log("content ", event.target)
    setMyGoal(event.target.getContent());
  };

  const testingHandler = (event: any) => {
    if (setSaving) {
      setSaving(true);
    }
    //    console.log("chosenTags = > " + chosenTags,"expiryDate => ",expiryDate, "postedDate => ",postedDate);
    //    const tags = tagTypeArr
    //     .map((tag,idx) =>({
    //         label: tag.name,
    //         value: tag.name,
    //     })).filter((tag) => tag.label.toLowerCase() !== "all");
    //    var chosenArr = tagTypeArr.map((arr:any,idx:any) => {

    //    })
    // const filterArr = (chosenTags:any) => {
    //     console.log("chosenTags => " , chosenTags)
    //     const filterType = tagTypeArr.filter((tag,id) => {
    //         if(tag.name == "FrontEnd"){
    //             return tag;
    //     }});
    //     console.log("filterType => " , filterType);
    //     return filterType;
    // }
    // console.log("expiryDate => " ,expiryDate?.getMonth());
    if (goalTitle === "") {
      setTitleError("Title field is required");
      if (setSaving) {
        setSaving(false);
      }
      return;
    } else {
      setTitleError("");
    }
    if (expiryDate === null) {
      setExpiryDate(new Date());
      //setExpireError("ExpireData field is required");
      return;
    }
    if (myGoal === "" || myGoal === null) {
      if (setSaving) {
        setSaving(false);
      }
      setContentError("Content field is required");
      return;
    } else {
      setContentError("");
    }
    if (chosenTags.length <= 0) {
      setTagError("Tag field is required");
      if (setSaving) {
        setSaving(false);
      }
      return;
    } else {
      setTagError("");
    }
    setTitleError("");
    setExpireError("");
    setContentError("");
    setTagError("");
    const title = DOMPurify.sanitize(goalTitle);
    const expiry = DOMPurify.sanitize(expiryDate?.toString() ?? "");
    const posted = postedDate ? renderDateFormatYMD(postedDate, "-") : "";
    const content = myGoal; //DOMPurify.sanitize(myGoal);
    const log = DOMPurify.sanitize(loggedUser);

    var submitData = {
      title,
      tag: chosenTags,
      expiryDate: expiry,
      postedDate: posted,
      content: myGoal,
      postedBy: log,
      updatedBy: log,
      typeData: typeData,
    };

    valueChangeCallback(submitData);
  };

  useEffect(() => {
    if (editorRef.current) {
      setMyGoal(editorRef.current.getContent());
      // setMyGoal(DOMPurify.sanitize(editorRef.current.innerHTML));
    }
  }, [editorRef]);

  useEffect(() => {
    if (isOpen === false) {
      setGoalTitle("");
      setChosenTags([]);
      setPostedDate(new Date());
      setExpiryDate(new Date());
      setMyGoal("");
    }
  }, [isOpen, saving]);

  

  return (
    <Modal
      open={isOpen}
      style={{
        background: "rgb(0 0 0 / 0.3)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      onClose={(event, reason) => {
        if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
          // Set 'open' to false, however you would do that with your particular code.
          setIsOpen(false);
        }
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableEnforceFocus={true}
    >
      <Fade in={isOpen} timeout={500}>
        <div className="modalContent lg:w-[1000px] max-w-[90%] annoucement-modal overflow-y-auto max-h-[100%] w-full">
          <div
            id="modal-modal-title"
            className="bg-vorpblue relative flex justify-between py-6 sm:px-10 px-5 rounded-t-xl"
          >
            <h3 className="text-18 font-primary font-semibold text-white">
              New Announcement
            </h3>
            <button
              className="flex items-center justify-center"
              onClick={() => {
                setIsOpen(false);
              }}
            >
              <img src={whiteCancel} alt="close modal icon" />
            </button>
          </div>

          <div
            id="modal-modal-description"
            className="py-9 px-5 bg-white rounded-b-xl"
          >
            <div className="md:flex">
              <div className="md:w-1/2 w-full sm:pl-5">
                <div className="mb-5">
                  <label className="text-black2 text-15 font-medium font-primary mb-[0.688rem] block">
                    ID
                  </label>
                  <p className="w-full rounded py-3.5 px-3 border border-goalinputborder text-black2 text-14 font-medium font-primary placeholder:text-graydark h-[48px]">
                    #00000{totalLength}
                  </p>
                </div>
                <div className="mb-5 last:mb-0">
                  <label className="text-black2 text-15 font-medium font-primary mb-[0.688rem] block">
                    Title
                  </label>
                  <input
                    type="text"
                    value={goalTitle}
                    onChange={goalTitleHandler}
                    placeholder="Title"
                    className="focus:outline-none focus-visible:outline-none w-full rounded py-3.5 px-3 border border-goalinputborder text-black2 text-14 font-medium font-primary placeholder:text-graydark h-[48px]"
                  ></input>
                  <p
                    className={classNames(
                      titleError !== "" ? "" : "hidden",
                      "mt-2 font-poppins text-12 text-redPink"
                    )}
                  >
                    {titleError ? titleError : ""}
                  </p>
                </div>
                <div className="mb-5 last:mb-0">
                  <label className="text-black2 text-15 font-medium font-primary mb-[0.688rem] block">
                    Type
                  </label>
                  <StringDropdownList
                    state={typeData}
                    setState={setTypeData}
                    dropdownItems={typeList?.map((x: string) => {
                      return {
                        label: x,
                        value: x,
                      };
                    })}
                    className="w-full xl:text-14 text-12 py-[13px] border-goalinputborder"
                    placeholder="All"
                    textAlign="text-left py-2 pl-2 pr-3"
                  />
                  {/* <p className={classNames(typeError != "" ? '' : 'hidden', "mt-2 font-poppins text-12 text-redPink")}>
                    {typeError ? typeError : ''}
                  </p> */}
                </div>
                <div className="mb-5 last:mb-0">
                  <label className="text-black2 text-15 font-medium font-primary mb-[0.688rem] block">
                    Expiry Date
                  </label>
                  <div className="w-full">
                    <CustomDatePicker
                      placeholder={new Date().toDateString()}
                      date={expiryDate}
                      minData={new Date()}
                      setDate={(value) => setExpiryDate(value)}
                    />
                  </div>
                  <p
                    className={classNames(
                      expireError !== "" ? "" : "hidden",
                      "mt-2 font-poppins text-12 text-redPink"
                    )}
                  >
                    {expireError ? expireError : ""}
                  </p>
                </div>
                <div className="mb-5 last:mb-0">
                  <label className="text-black2 text-15 font-medium font-primary mb-[0.688rem] block">
                    Tag
                  </label>

                  <CommonTagDropdown
                    createData={createTagData}
                    showAll={true}
                    state={chosenTags}
                    setState={setChosenTags}
                    options={tags ? tags : []}
                    className="py-3 border-goalinputborder 6xs:text-goalinputborder text-14 h-[48px]"
                  />
                  <p
                    className={classNames(
                      tagError !== "" ? "" : "hidden",
                      "mt-2 font-poppins text-12 text-redPink"
                    )}
                  >
                    {tagError ? tagError : ""}
                  </p>
                </div>
              </div>
              <div className="md:w-1/2 w-full sm:px-5">
                <div className="mb-5 last:mb-0 h-full">
                  <label className="text-black2 text-15 font-medium font-primary mb-[0.688rem] block">
                    Content
                  </label>
                  <Editor
                    apiKey="7lo2e7xqoln1oo18qkxrhvk9wohy5picx4824cjgas8odbg3"
                    onInit={(evt, editor) => {
                      // console.log("loading", editor);
                      // setLoading(false);
                      editorRef.current = editor;
                    }}
                    initialValue="<p></p>"
                    init={{
                      skin: false,
                      content_css: false,
                      height: 247,
                      menubar: false,
                      plugins: ["code", "link"],
                      // plugins: [
                      //   "lists link image print",
                      //   "media table paste code help",
                      //   "textcolor",
                      // ],
                      // toolbar: 'undo redo | formatselect | styleselect | fontsizeselect | ' +
                      // 'bold italic backcolor | alignleft aligncenter ' +
                      // 'alignright alignjustify | bullist numlist outdent indent | ' +
                      // 'removeformat | help | styleselect fontselect fontsizeselect | forecolor backcolor',
                      toolbar:
                        "undo redo | fontsize | forecolor backcolor | styleselect fontsizeselect | bold italic | alignleft aligncenter alignright alignjustify | outdent indent | code | link",
                      block_formats:
                        "Paragraph=p; Header 1=h1; Header 2=h2; Header 3=h3",
                      font_size_formats:
                        "8px 9px 10px 11px 12px 13px 14px 15px 16px 17px 18px 24px 36px",
                      content_style:
                        "@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap'); body { font-family: 'Poppins', sans-serif; font-size: 14px; } h1,h2,h3,h4,h5,h6 { font-family: 'Poppins', sans-serif; }",
                      font_formats: "Poppins=poppins;",
                    }}
                    onChange={handleEditorChange}
                  />
                  <p
                    className={classNames(
                      contentError !== "" ? "" : "hidden",
                      "mt-2 font-poppins text-12 text-redPink"
                    )}
                  >
                    {contentError ? contentError : ""}
                  </p>
                </div>
              </div>
            </div>
            <div className="mt-5 last:mb-0 flex items-center sm:px-5">
              <button
                className={classNames(
                  saving ? "opacity-50 pointer-events-none" : "",
                  "flex items-center justify-center bg-vorpblue mr-2.5 text-white font-primary text-14 font-medium rounded-md py-[8.5px] px-3"
                )}
                onClick={testingHandler}
              >
                {saving == true ? (
                  <div className="flex items-center justify-center w-full">
                    <CircularProgress
                      sx={{ color: "white" }}
                      className="w-5 h-5"
                      style={{ width: "20px", height: "20px" }}
                    />
                    <span className="ml-2">Saving</span>
                  </div>
                ) : (
                  <div className="flex items-center">
                    <img src={saveIcon} alt="save icon" className="mr-3"></img>
                    Updated
                  </div>
                )}
              </button>
              <button
                className="flex items-center justify-center bg-offwhite font-primary text-14 font-medium text-black2 rounded-md py-[8.5px] px-3"
                onClick={() => {
                  setIsOpen(false);
                }}
              >
                <img src={blackCancel} alt="black icon" className="mr-3"></img>
                Cancel
              </button>
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default ModalAnnounEditor;
