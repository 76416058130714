import { FC, useCallback, useEffect, useState } from "react";
import { SelectedIcon } from "./Icons";
import { IRole } from "../../backendUsers/views/UserList";

interface ISelectBox {
  value: any;
  initialValue?: boolean;
  setValue: any;
  label?: string;
  setOpen?: any;
  setShow?: any;
  filteredList?: IRole[];
  changeValue?: any;
  handleClick?: any;
  empId?: number;
}

const SelectBox: FC<ISelectBox> = ({
  value,
  initialValue,
  setValue,
  label,
  empId,
  // setOpen,
  // filteredList,
  // setShow,
  changeValue,
  // handleClick
}) => {
  const [isChecked, setIsChecked] = useState<boolean>(false);

  const selectBoxOnChange = useCallback(
    (isChk: boolean) => {
      if (isChk === true) {
        setIsChecked((prev) => {
          if (prev) {
            setValue((prev1: any) => {
              if (changeValue) {
                changeValue(prev1.filter((id: any) => id !== value))
              }
              return prev1.filter((id: any) => id !== value)
            });
          }
          else {
            setValue((prev1: any) => {
              if (changeValue) {
                changeValue([...prev1, value])
              }
              return [...prev1, value]
            });
          }
          return !prev;
        });
      }
      else {
        setValue((prev1: any) => {
          if (changeValue) {
            changeValue(prev1.filter((id: any) => id !== value))
          }
          return prev1.filter((id: any) => id !== value)
        });
      }
    }, [setIsChecked, setValue, changeValue]);

  useEffect(() => {
    if (initialValue !== undefined) {
      setIsChecked(initialValue);
    }
  }, [initialValue]);

  return (
    <label onChange={(event) => { selectBoxOnChange(!isChecked); }}
      htmlFor={String(value)}
      className="relative flex items-center hover:cursor-pointer w-full"
    >
      <div className="relative max-h-6">
        <input
          readOnly
          type="checkbox"
          checked={isChecked}
          id={String(value)}
          className="checked:bg-vorpblue w-3 h-3 sm:w-4 sm:h-4 p-3 border-none outline-none appearance-none bg-offwhite rounded hover:cursor-pointer"
        />
        {isChecked && (
          <SelectedIcon className="absolute top-2 left-0 right-0 m-auto" />
        )}
      </div>
      {label && (
        <p className="ml-3 text-12 md:text-14 leading-[21px] text-[#3F4254]">
          {label}
        </p>
      )}
    </label>
  );
};

export default SelectBox;
