import {
  Dispatch,
  FC,
  FocusEvent,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { ChevronDown, SearchIcon } from "./Icons";
import { debounce, uniqueId } from "lodash";
import styles from "./Custom.module.scss";
import { baseObj } from "../../utils/constants";
import debouce from "lodash.debounce";
import InfiniteScroll from "react-infinite-scroll-component";
const classNames = require("classnames");

interface ICustomDropdownList<P = unknown> {
  state: P;
  setState: Dispatch<SetStateAction<P>> | (() => void);
  placeholder?: string;
  dropdownItems: IListItem[];
  className?: string;
  textAlign?: string;
  dropdownborder?: string;
  setSearchData: Dispatch<SetStateAction<string>>;
  isShow?: boolean;
  setIsShow?: Dispatch<SetStateAction<boolean>>;
  isNew?: boolean;
  isPlaceHolder?: string;
  isCreate?: boolean;
  setData?: Dispatch<SetStateAction<any>>;
  id?: number;
  taskDataId?: number;
  isLabel?: any;
  setTaskNamesData?: Dispatch<SetStateAction<string>>;
  createTasks?: () => void;
  isDisable: boolean;
  setSearchDataValue?: Dispatch<SetStateAction<string>>;
  setValidSearch?: Dispatch<SetStateAction<boolean>>;
  data?: any;
  setTaskName: any;
  taskNames: any;
  taskCreateError?: string;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  currentPage: number;
  total: number;
  fetchMoreData: any;
}

interface IListItem {
  label?: string;
  value?: string | number;
  photo?: string;
  email?: string;
}

// Type Factory for Generic Types

const SearchInputDropdown = <P extends unknown>() => {
  const ActualInstance: FC<ICustomDropdownList<P>> = ({
    state,
    setState,
    setSearchData,
    dropdownItems,
    placeholder = "All",
    className,
    textAlign = "text-center",
    dropdownborder = "",
    isShow,
    setIsShow,
    isNew,
    isPlaceHolder,
    isCreate,
    setData,
    id,
    isLabel,
    setTaskNamesData,
    createTasks,
    isDisable,
    taskDataId,
    setSearchDataValue,
    setValidSearch,
    data,
    setTaskName,
    taskNames,
    taskCreateError,
    setCurrentPage,
    currentPage,
    total,
    fetchMoreData
  }) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    // const [lastId, setLastId] = useState<any>();
    const [focusedItem, setFocusedItem] = useState<number>(-1);
    // const [isSelectLabel, setIsSelectLabel] = useState<boolean>(true);
    // const [dropDownOpen, setDropDownOpen] = useState<boolean>(false);
    const [filterDropdownItem, setFilterDropdownItem] =
      useState<IListItem[]>(dropdownItems);
    const [label, setLabel] = useState<string>(
      dropdownItems?.filter((item) => item.value == state)[0]?.label || isLabel
    );
    const listInnerRef = useRef<any>(null);
    const uuid = uniqueId("custom-dropdown-list");
    var name = useRef<any>(null);
    const [searchInput, setSearchInput] = useState<string>('');
    const handleCloseDropdown = (event: FocusEvent<HTMLButtonElement>) => {
      const relatedTarget = event.relatedTarget;
      // If the click target is not the dropdown or dropdown item, close the list.
      if (
        !relatedTarget ||
        !(
          relatedTarget.id === uuid ||
          relatedTarget.className.includes(`${uuid}item`)
        )
      ) {
        setIsOpen(false);
      }
    };

    const handleFocus = () => {
      // setDropDownOpen(true);
      setIsOpen(true);
    };

    const handleBlur = () => {
      setTimeout(() => {
        // setDropDownOpen(false);
        setIsOpen(false);
      }, 800);
    };

    const [search, setSearch] = useState<any>();

    const handleInputChange = (search: string, id?: number) => {
      setSearchInput(search)
      if (name.current.value.length === 0) {
        if (setValidSearch) {
          setValidSearch(true);
        }

        if (setData) {
          setData((prev: baseObj[]) => {
            return prev.map((data: baseObj) => {
              if (data.uniqueId == Number(id)) {
                return { ...data, task_id: 0, task_name: name.current.value };
              }
              return data;
            });
          });
        }
      } else {
        if (setValidSearch) {
          setValidSearch(false);
        }
      }

      if (setTaskNamesData) {
        setTaskNamesData(search);
      }
      const timeout = setTimeout(() => {
        setSearch(search);
        setCurrentPage(1)
      }, 1000);
      var filter = taskNames?.find((x: any) => x.name?.toLocaleLowerCase() == search?.toLocaleUpperCase())
      // setTaskName([
      //   ...taskNames,
      //   { id: 0, name: search },
      // ]);
      debouncedSearch(search)
      setCurrentPage(1)
      if (setData) {
        setData((prev: baseObj[]) => {
          return prev.map((data: baseObj) => {
            if (data.uniqueId == Number(id)) {
              const taskData = filterDropdownItem?.find(x => x.label == name?.current?.value)
              return { ...data, task_id: taskData?.value || 0, task_name: taskData?.label || name?.current?.value };
            }
            return data;
          });
        });
      }

      return () => clearTimeout(timeout);
    };
    const debouncedSearch = useRef(
      debounce(async (criteria) => {
        if (setSearchDataValue) {
          setSearchDataValue(name?.current?.value);
        }
      }, 300)
    ).current;

    useEffect(() => {
      return () => {
        debouncedSearch.cancel();
      };
    }, [debouncedSearch]);

    const changeDropdownField = (ids: any, isTaks?: boolean, label?: string) => {
      if (setValidSearch) {
        setValidSearch(false);
      }

      if (setData) {
        setData((prev: baseObj[]) => {
          return prev.map((data: baseObj) => {
            if (data.uniqueId == Number(id)) {
              if (isTaks) {
                // if (setTaskNamesData) {
                //   if (createTasks) {
                //     setTimeout(() => {
                //       createTasks();
                //     }, 0);
                //   }
                // }
              }
              return { ...data, task_id: ids, task_name: label };
            }
            return data;
          });
        });
      }
    };


    const handleKeyDown = (e: any) => {
      if (focusedItem < filterDropdownItem.length) {
        if (e.key === "ArrowUp" && focusedItem > 0) {
          setFocusedItem((prev) => prev - 1);
        } else if (e.key === "ArrowDown" && filterDropdownItem.length - 1) {
          setFocusedItem((prev) => prev + 1);
        } else if (e.key === "Enter" && focusedItem >= 0) {
          setLabel(filterDropdownItem[focusedItem].label ?? "");
          setState(filterDropdownItem[focusedItem].value as unknown as any);
          name.current.value = filterDropdownItem[focusedItem].label ?? "";
          setSearchInput(filterDropdownItem[focusedItem].label ?? "")
          setIsOpen(false);
          changeDropdownField(filterDropdownItem[focusedItem].value);
        }
      } else {
        setFocusedItem(-1);
      }
    };

    useEffect(() => {
      if (taskDataId) {
        if (setData) {
          setData((prev: baseObj[]) => {
            return prev.map((data: baseObj) => {
              if (data.uniqueId == Number(id)) {
                const taskData = taskNames?.find((x: any) => x.id == taskDataId)
                return { ...data, task_id: taskDataId, task_name: taskData?.name };
              }
              return data;
            });
          });
        }
      }
    }, [taskDataId]);

    useEffect(() => {
      const labels =
        dropdownItems?.filter((item) => item.value == state)[0]?.label ||
        isLabel ||
        search;
      setLabel(labels);
      name.current.value = labels ?? "";
      setSearchInput(labels ?? "")
    }, [state]);

    const debouncedDropdown = useRef(
      debounce(async (criteria, data) => {
        if (name.current.value) {
          setFilterDropdownItem(criteria)
        } else {
          const alltasks = [...data, ...criteria];
          const unique = alltasks.filter((obj: any, index: number) => {
            return index === alltasks.findIndex((o: any) => obj.value === o.value);
          });
          setFilterDropdownItem(unique)
        }

      }, 300)
    ).current;

    useEffect(() => {
      return () => {
        debouncedDropdown.cancel();
      };
    }, [debouncedDropdown]);
    useEffect(() => {
      debouncedDropdown(dropdownItems, filterDropdownItem)
    }, [dropdownItems]);
    // useEffect(() => {
    //   //setCurrentPage(1);
    //   setTaskName([])
    // }, [isOpen])
    const fetchMore = () => {
      let page = currentPage;
      if ((10 * page) >= total) {
      } else {
        page = currentPage + 1;
        setCurrentPage(page);
      }
    }

    return (
      <div className="relative h-full customDropdownContainer daily_report_dropdown">
        {/* {label && isSelectLabel ? ( */}
        {false ? (
          <button
            id={uuid}
            className={classNames(
              dropdownborder,
              `flex hover:cursor-pointer text-14 leading-5 text-graydark relative
               justify-between w-32 h-12 items-center pl-[11px] pr-[17px] border
                border-[#CCDAE4] rounded-[5px] custom-dropdown-list mb-0 overflow-x-auto ${className}`
            )}
            onClick={() => {
              // setIsSelectLabel(false);
              setIsOpen(true);
              if (setIsShow) {
                setIsShow(!isShow);
              }
            }}
            onBlur={handleCloseDropdown}
          >
            <span className="px-2">{String(label)}</span>
            <ChevronDown className="px-0" />
          </button>
        ) : (
          <div
            className={classNames(
              isNew ? "" : " pointer-events-none ",
              "rounded-4 h-full border-zinc-200 border-solid border-2 w-full mb-0 flex items-center justify-between px-2 py-1 border-b border--b-goalinputborder"
            )}
          >
            <input
              onChange={(e) => {
                handleInputChange(e.target.value, id);
                setIsOpen(true);
              }}
              onKeyDown={handleKeyDown}
              onFocus={handleFocus}
              autoFocus={isOpen ? true : false}
              onBlur={() => {
                setTimeout(() => {
                  handleBlur();
                }, 500);
              }}
              ref={name}
              value={searchInput}
              type="text"
              className={classNames(
                uuid + "item",
                isDisable ? "pointer-events-none opacity-30" : "",
                "text-graydark bg-transparent px-1 font-poppins h-full font-normal xl:text-sm text-xs rounded w-full bg-[#fff] focus:outline-none"
              )}
              placeholder={isPlaceHolder ?? "Enter Name"}
            />
            {!isCreate ? <ChevronDown className="" /> : null}
          </div>
        )}
        <span className="text-12 text-redPink">{taskCreateError}</span>
        {/* <div id="scrollableDiv" style={{ height: 200, overflow: "auto" }}>
          <InfiniteScroll
            dataLength={taskNames.length}
            next={fetchMore}
            hasMore={true}
            loader={<h4>Loading...</h4>}
            scrollableTarget="scrollableDiv"
          >
            {filterDropdownItem.map((i: any, index) => (
              <div key={index}>
                {i.label}
              </div>
            ))}
          </InfiniteScroll>
        </div> */}
        {isOpen && (
          <div id="search_dropdown" ref={listInnerRef} //onScroll={handleScroll}
            className={classNames(
              // styles.customDropdownScroll,
              " absolute left-0 text-12 max-h-[140px] z-10 overflow-hidden top-[104%] w-full border-1 border-goalinputborder bg-white rounded-md"
              // { hidden: false }
              // { hidden: !isOpen }
            )}
          >
            {filterDropdownItem.length ? (
              // filterDropdownItem?.map((item: IListItem, index: number) => (

              <div className={styles.customDropdownScroll} id="scrollableDiv" style={{ height: 140, overflow: "auto" }}>
                <InfiniteScroll
                  dataLength={taskNames.length}
                  next={fetchMore}
                  hasMore={(total > (currentPage * 10) ? true : false)}
                  loader={<h4 className="px-1 py-1 bg-offwhite">Loading...</h4>}
                  scrollableTarget="scrollableDiv"
                >
                  {filterDropdownItem.map((item: any, index) => (
                    <div className="flex flex-col" key={index}>
                      <button
                        className={classNames(
                          focusedItem === index
                            ? "bg-vorpblue text-white"
                            : "bg-white",
                          `custom-dropdown-item hover:bg-vorpblue py-1 w-full hover:text-white transition-all duration-300 text-graydark ${textAlign} ${uuid + "item"
                          }`
                        )}
                        onClick={() => {
                          changeDropdownField(item?.value, false, item?.label);
                          setState(item.value as any);
                          setLabel(item?.label ?? "");
                          name.current.value = item?.label ?? "";
                          setSearchInput(item?.label ?? "")
                          setIsOpen(false);
                          if (setIsShow) {
                            setIsShow(!isShow);
                          }
                        }}
                      >
                        <div className="flex flex-row items-center">
                          <div className="flex flex-col text-sm">
                            <span>{item.label ?? ""}</span>
                          </div>
                        </div>
                      </button>
                    </div>
                  ))}
                </InfiniteScroll>
              </div>
              // ))
            )
              // : isCreate ? (
              //   <div
              //     key={uuid}
              //     className={` custom-dropdown-item bg-white w-full flex flex-row justify-center items-center transition-all duration-300 text-graydark ${textAlign} ${uuid + "item"
              //       }`}
              //   >
              //     <button
              //       onClick={() => {
              //         changeDropdownField(search, true);
              //         setLabel(search);
              //         if (setTaskNamesData) {
              //           if (createTasks) {
              //             setTimeout(() => {
              //               createTasks();
              //             }, 0);
              //           }
              //         }
              //       }}
              //       className="bg-vorpblue text-white rounded-xl p-1 mr-2"
              //     >
              //       Create +
              //     </button>
              //     <p className=" max-w-[150px] overflow-auto">{search}</p>
              //   </div>
              // ) 
              : null}
          </div>
        )}
      </div>
    );
  };
  return ActualInstance;
};
// Extend More for Required Types
export const DropdownListSearchInput = SearchInputDropdown<string>();
