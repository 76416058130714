import { FC, useCallback, useEffect, useState, useMemo } from "react";
import Button from "../../components/common/Button";
import {
  AddIcon,
  CustomizeLayoutIcon,
  ExportIcon,
  ImportIcon,
  SearchIcon,
} from "../../components/common/Icons";
import { NumberDropdownList } from "../../components/common/DropdownList";
import {
  paymentTerms,
  projectComplete,
  paymentTermsData,
  baseObj,
} from "../../utils/constants";

import ImportUsersModal from "../../components/BackendUsers/ImportUsersModal";
import ExportUsersModal from "../../components/BackendUsers/ExportUsersTable";
import {
  ColumnFiltersState,
  PaginationState,
  SortingState,
} from "@tanstack/react-table";
import DebounceSearchInput from "../../components/Tables/DebounceSearchInput";
import Styles from "./ProjectList.module.scss";
import ProjectsTable from "../../components/Projects/ProjectsTable";
import { EmployeeData } from "../../components/EmployeeTable/EmployeeTable";
import { templateList } from "../../active-time-report/views/ActiveTimeReport";
import { StringDropdownListWithDeleteBtn } from "../../components/common/StringDropdownListWithDeleteBtn";
import {
  // Project, ProjectType,
  useDeleteProjMutation,
  useGetProjectDataQuery,
  TEmployeeListForProject,
  useGetEmployeeListAssignQuery,
  TEmployee,
  useAssignEmployeeMutation,
  TRowData,
  useRestoreProjectsMutation,
  useGetProjectFilterDataQuery,
  useCreateProjectTechMutation,
} from "../../app/services/project";
// import { DataPrepareSettings } from "devextreme-react/range-selector";
// import {
//   TagsData,
//   useGetTagsDataQuery,
// } from "../../app/services/annoucement-sheet";
import { useGetByModuleTemplateQuery } from "../../app/services/template";
import { getAuthUser } from "../../app/services/dashboard";
import axios from "../../axios";
import { endpointUrl } from "../../app/services/api";
import { checkedIsNew } from "../../app/services/employee";
import ErrorPopup from "../../components/ErrorPopup/ErrorPopup";
import { useNavigate } from "react-router-dom";
import { FetchBaseError } from "../../components/common/FetchBaseQueryError";
import CustomDropdownListForFilter from "../../components/EmployeeTable/CustomDropdownListForFilter";
import CustomScrollbar from "../../components/CustomScrollbar/CustomScrollbar";
//import { CSSTransition } from 'react-transition-group';
import { useSpring, animated } from "react-spring";
//import TableLoadingSkeleton from "../../components/TableSkeleton/TableLoadingSkeleton";
import CommonTableSkeleton from "../../components/TableSkeleton/CommonTableSkeleton";
import { ToastContainer, toast } from "react-toastify";
// import { assignUsers } from "../../app/services/password";
import CreateProjectPopup from "../../components/CreateProjectPopup/CreateProjectPopup";
import classNames from "classnames";
import CustomDropdownListMultipleForFilter from "../../components/EmployeeTable/CustomDropdownListMultipleForFilter";
import ResuableToasty from "../../components/Toasty/ResuableToast";

interface IProjectsList {}

export interface IFilterStates {
  region?: string;
  projectType?: string;
  projectStatus?: string;
  paymentTerm?: string;
  paymentStage?: number;
  paymentStatus?: string;
  projectCompleted?: boolean | string;
  keyDate?: string;
  department?: string;
  assignTo?: string;
  status?: string;
}

export const addNewProjects = () => {
  return {
    uniqeID: 0,
    projectCode: "",
    projectName: "",
    companyName: "",
    email: "",
    contactPerson: "",
    financialContactPerson: "",
    teamLeader: "",
    projectManager: "",
    date: "",
    region: "",
    department: "",
    // projectType: "",
    duration: "",
    projectStatus: "",
    quotationNumber: "",
    confirmOn: "",
    remarks: "",
    projectScope: "",
    paymentTerm: "",
    currency: "",
    projectAmount: "",
    paidAmount: "",
    paymentStatus: "",
    endDate: "",
    projectCompleted: false,
    checklist: [],
    keyDates: [],
    projectTags: [],
    key: Math.random() * 100,
    isNew: true,
    isEdit: false,
    isCancel: false,
  };
};
const projectDeletedList = [
  {
    id: "All",
    value: "All",
  },
  {
    id: "0",
    value: "Non-Deleted",
  },
  {
    id: "1",
    value: "Deleted",
  },
];
const ProjectsList: FC<IProjectsList> = () => {
  //const numberOfRows = 20;

  // const renderedRows = [...Array(numberOfRows)].map((e, i) => (
  //   <div key={i}>
  //     <Row />
  //   </div>
  // ));

  const navigate = useNavigate();
  const [editedData, setEditData] = useState<any>("");
  const [isOpenPopup, setIsOpenPopup] = useState<boolean>(false);
  // const [uploading, setUploading] = useState<boolean>(false);
  const [stage, setStage] = useState<number[]>([]);
  const [deleted_at, setDeletedAt] = useState<string>("0");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(20);
  const [payment_status, setPayment_status] = useState<number>(0);
  const [term, setTerm] = useState<number>(0);
  const [region, setRegion] = useState<any>("all");
  const [type, setType] = useState<number>(0);
  const [completed, setCompleted] = useState<any>("all");
  const [status, setStatus] = useState<number>(0);
  const [globalSearch, setGlobalSearch] = useState<string>("");
  const [sorting, setSorting] = useState<SortingState>([]);
  const defaultFields = useMemo(
    () => [
      "logo",
      "projectCode",
      "projectName",
      "companyName",
      "email",
      "contactPerson",
      "financialContactPerson",
      "teamLeader",
      "projectManager",
      "employees",
      "kickOffDate",
      "targetLaunchDate",
      "region",
      "department",
      "duration",
      // "current_working_status",
      "projectScope",
      "quotationNumber",
      "confirmOn",
      "currency",
      "projectAmount",
      "paymentTerm",
      "paymentStage",
      // "paymentStatus",
      "maintenanceEndDate",
      // "endDate",
      "launchDate",
      // "launchDate",
      // "projectCompleted",
      "remarks",
      "checklist",
      "keyDate",
      "projectTypes",
      "developmentUrl",
      // "uat_url",
      "liveUrl",
      "currentWorkingStatus",
      "currentStage",
      "projectStageStatuses",
      "brandLevel",
      "projectLevel",
    ],
    []
  );

  const {
    data: projectInfoData,
    error,
    isLoading,
    isFetching,
  } = useGetProjectDataQuery({
    limit: limit,
    page: currentPage,
    payment_status: payment_status,
    term: term,
    region: region,
    type: type,
    completed: completed == true ? 1 : completed == false ? 0 : "All",
    status: status,
    search: globalSearch,
    deleted_at: deleted_at,
    status_ids: stage.join(","),
    sort: sorting
      .map(
        (s) =>
          `${s.desc ? "-" : ""}${
            s.id == "projectCode"
              ? "project_code"
              : s.id == "projectName"
              ? "project_name"
              : s.id == "date"
              ? "start_date"
              : s.id == "confirmOn"
              ? "confirm_date"
              : s.id == "paymentTerm"
              ? "payment_term"
              : s.id == "endDate"
              ? "end_date"
              : s.id
          }`
      )
      .join(","),
  });
  const { data: projectFilterData } = useGetProjectFilterDataQuery();
  // console.log("projectFilterData", projectFilterData);
  const [keyListData, setKeyListData] = useState<any>([]);
  const [deleteProj] = useDeleteProjMutation();
  const [createProjectTech] = useCreateProjectTechMutation();
  let [localModuleId, setLocalModuleId] = useState<number>(0);
  const { data: templateData } = useGetByModuleTemplateQuery(localModuleId);
  const [moduleList, setModuleList] = useState<any>([]);
  const [filterStates, setFilterStates] = useState<IFilterStates>({
    region: "All",
    projectType: "All",
    projectStatus: "All",
    paymentTerm: "All",
    paymentStage: 0,
    paymentStatus: "All",
    projectCompleted: "All",
    keyDate: "All",
  });

  const [viewCols, setCols] = useState<any>([]);
  // const [getPlaceHolder, setGetPlaceHolder] = useState<any>();
  const [isEditing, setIsEdit] = useState<boolean>(false);
  const [memberData, setMemberData] = useState<TEmployeeListForProject[]>([]);
  const [rowList, setRowList] = useState<TRowData[]>([]);
  const [currencyLists, setCurrencyLists] = useState<EmployeeData[]>([]);
  const [isImportModalOpen, setIsImportModalOpen] = useState<boolean>(false);
  const [isExportModalOpen, setIsExportModalOpen] = useState<boolean>(false);
  const [isImporting, setIsImporting] = useState<boolean>(false);
  const [isExporting, setIsExporting] = useState<boolean>(false);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const [isLayoutControlOpen, setIsLayoutControlOpen] =
    useState<boolean>(false);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  });
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [regionList, setRegionList] = useState<EmployeeData[]>([]);
  const [countryList, setCountryList] = useState<EmployeeData[]>([]);
  const [projectTypesList, setProjectTypesList] = useState<EmployeeData[]>([]);
  const [paymentStatusList, setPaymentStatusList] = useState<EmployeeData[]>(
    []
  );
  const [projectStatusList, setProjectStatusList] = useState<EmployeeData[]>(
    []
  );
  const [projectTagList, setProjectTagList] = useState<any[]>([]);
  const [paymentTermList, setPaymentTermList] =
    useState<EmployeeData[]>(paymentTermsData);
  const [itemCount, setItemCount] = useState<number>(0);
  const [successPopup, setSuccessPopup] = useState<boolean>(false);
  const [errorPopup, setErrorPopup] = useState<boolean>(false);
  const [message, setMessage] = useState<any>([]);
  const [currentView, setCurrentView] = useState<any>(0);
  const [isTemplate, setIsTemplate] = useState<boolean>(false);
  const [currentViewData, setCurrentViewData] = useState<number>(0);
  const [data, setData] = useState<any[]>([]);
  // console.log("da", data);
  const [pjDataClone, setPjDataClone] = useState<any[]>([]);
  const [hiddenFields, setHiddenFields] = useState<string[]>([]);
  const updateFilterState = useCallback(
    (value: string | number, key: string, id?: number) => {
      if (id && key == "keyDate") {
      }
      setFilterStates((prev) => ({ ...prev, [key]: value }));
    },
    [setFilterStates]
  );
  const [viewPermis, setViewPermis] = useState<boolean>(false);
  // const [checkExport, setCheckExport] = useState<boolean>(false);
  // const [checkImport, setCheckImport] = useState<boolean>(false);
  const [checkManagement, setCheckManagement] = useState<boolean>(false);
  // const [addNew, setAddNew] = useState<boolean>(false);
  const [assignUserListData, setAssignUserListData] = useState<TEmployee[]>([]);
  const [departmentList, setDepartmentList] = useState<EmployeeData[]>([]);
  const [assignDepartmentId, setAssignDepartmentId] = useState<string>("all");
  const [assignKeword, setAssignKeword] = useState<string>("");
  //const { data: currencyList } = useGetCurrencyListQuery();
  const [employeeListRoles, setEmployeeListRoles] = useState<any[] | undefined>(
    []
  );
  //const { data: assignRowProjectList } = useGetAssignRowsProjectQuery();
  const { data: assignEmployeeList } = useGetEmployeeListAssignQuery({
    department_id: assignDepartmentId,
    search: assignKeword,
  });
  //const { data: departments } = useGetDepartmentListQuery();
  const [assignEmployee] = useAssignEmployeeMutation();
  const [restoreProject] = useRestoreProjectsMutation();
  var getUser = getAuthUser();
  const getAuthPermis: string[] = useMemo(() => [], []);
  if (getUser?.permissions) {
    getUser?.permissions?.forEach((per: any) => {
      getAuthPermis.push(per?.name);
    });
  }
  useEffect(() => {
    getAuthUser();
    const filterManagement = getAuthPermis.filter(
      (f) => f === "Manage_Project"
    );
   
    if (filterManagement.length > 0) {
      setCheckManagement(true);
    }
   
  }, [getUser, getAuthPermis]);

  useEffect(() => {
    if (projectInfoData) {
      if (isFetching) {
        // setData([]);
      } else {
        setPjDataClone(projectInfoData?.data?.data);
        setData(projectInfoData?.data?.data);
        setMemberData(projectInfoData.employee_list);
      }
      setLocalModuleId(projectInfoData?.module_id);
      setItemCount(projectInfoData?.data?.total);
    }
  }, [projectInfoData, hiddenFields, currentView, isFetching]);
  useEffect(() => {
    if (assignKeword == "" && assignDepartmentId == "all") {
      if (assignEmployeeList) {
        setEmployeeListRoles(assignEmployeeList?.employeeList);
      }
    }
  }, [assignEmployeeList]);

  useEffect(() => {
    if (projectFilterData) {
      var transformCurrencyData: EmployeeData[] =
        projectFilterData.currencies.map((x) => {
          return {
            id: x.id,
            name: x.currency_name,
            label: x.currency_code,
          };
        });
      setCurrencyLists(transformCurrencyData ? transformCurrencyData : []);
      setKeyListData(
        projectFilterData.key_dates?.map((keydate: any) => {
          return {
            id: keydate.id,
            label: keydate.name,
          };
        })
      );
      let rolesArry: any = [];
      projectFilterData.assign_project_role_lists?.forEach((target) => {
        // const [key, value] = Object.entries( target )[0];
        // if(target.label != null && target.label !== undefined && target.label !== '') {
        rolesArry.push({
          id: target.id,
          value: target.id,
          label: target.name,
        });
        // }
      });

      setRowList([...rolesArry]);
      setDepartmentList([
        //{ name: "All Departments", id: 0, label: "All Departments" },
        ...projectFilterData?.departments,
      ]);
      setCountryList([
        // { id: 0, name: "All", label: "All" },
        ...projectFilterData?.countries,
      ]);
      setRegionList([
        // { id: 0, name: "All", label: "All" },
        ...projectFilterData.countries,
      ]);
      setProjectTypesList([
        // { id: 0, name: "All", label: "All" },
        ...projectFilterData?.project_types,
      ]);

      setProjectStatusList([
        //{ id: 0, name: "All", label: "All" },
        ...projectFilterData?.project_statuses,
      ]);
      setPaymentStatusList([
        // { id: 0, name: "All", label: "All" },
        ...projectFilterData?.payment_statuses,
      ]);
      const filterData = projectFilterData?.tags?.filter(
        (x: any) => x.flag === "pj" || x.flag === null
      );

      const lastData = filterData?.map((data: any) => {
        return {
          id: data.id,
          label: data.name,
          value: data.id,
        };
      });

      setProjectTagList(lastData);
    }
  }, [projectFilterData]);
  // console.log("projectFilterData",projectFilterData )

  useEffect(() => {
    if (assignEmployeeList) {
      setAssignUserListData(assignEmployeeList?.employeeList);
    }
    
    if (assignKeword == "" && assignDepartmentId == "all") {
      if (assignEmployeeList) {
        setEmployeeListRoles(assignEmployeeList?.employeeList);
      }
    }
  }, [assignEmployeeList]);
  useEffect(() => {
    if (templateData) {
      var updatedTemplate = templateData?.templates.map((x: any, i: any) => {
        return { ...x, index: i + 1 };
      });
      var defaultTemplate = {
        id: -1,
        index: 0,
        module: 0,
        name: "All",
      };
      setModuleList([defaultTemplate, ...updatedTemplate]);
    }
  }, [templateData]);

  useEffect(() => {
    if (templateList) {
      // setTempList(templateList);
    }
    
    var viewData = templateData?.templates.find(
      (x: any) => x.id.toString() === currentView.toString()
    );
    setHiddenFields(viewData ? [...viewData?.viewColumns] : defaultFields);
    document.querySelectorAll("th,td").forEach((x) => {
      if (x.id.includes("none")) {
        x.classList.add("hidden");
      }
      if (x.id.includes("action")) {
        x.classList.add("absolute");
        x.classList.add("right-0");
      }
      x.classList.remove("absolute");
      x.classList.add("left-0");
      x.classList.add("relative");
      var div = document.getElementById(x.id);
      if (div) {
        div.style.setProperty("left", "");
      }
    });
    // setTemplatePlaceholder(currentView);
  }, [currentView, defaultFields]); // old are templateList

  useEffect(() => {
    if (viewCols?.view_columns?.length > 0) {
      setHiddenFields(viewCols?.view_columns);
    }
  }, [viewCols?.view_columns]);

  useEffect(() => {
    if (moduleList) {
      var lastesTemplate = moduleList[moduleList?.length - 1];
      setCurrentViewData(lastesTemplate ? Number(lastesTemplate?.id) : 0);
    }
  }, [moduleList]); //old is isTemplate && moduleList

  const deleteTemplateData = (id: string) => {
    // var filterTempList = templateList.filter((x) => x.id !== parseInt(id));
    // setTempList(filterTempList);
  };

  const changePage = (pageNo: number) => {
    // var pageData = {
    //   limit: pagination.pageSize,
    //   currentPage: pagination.pageIndex + 1,
    // };
    // getPermissions(pageData)
  };

  const downloadSampleFile = () => {
    axios({
      url: endpointUrl + "downloadProjectSample",
      method: "GET",
      responseType: "blob",
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "sampleProject.xlsx");
      document.body.appendChild(link);
      link.click();
      setIsDownloading(false);
    });
  };

  const sampleImportCSV = () => {
    downloadSampleFile();
  };

  const importProject = async (formSaveData: any) => {
    await axios(endpointUrl + "projecstImport", {
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${getUser.token}`,
      },
      data: formSaveData,
    })
      .then((res) => {
        axios
          .get(`${endpointUrl}projects?limit=${limit}&page=${currentPage}`, {})
          .then((result) => {
            var pjUpdatedData: any = result?.data?.data;
            if (result.data.status) {
              setData([...pjUpdatedData]);
              setItemCount(pjUpdatedData?.length);
              setSuccessPopup(true);
              setMessage(["Successfully import."]);
            }
            setIsImporting(true);
            setIsImportModalOpen(false);
            ResuableToasty({
              message: "Import Successfully",
              position: "top-right",
              type: "success",
            });
          });
      })
      .catch((error: any) => {
        // console.log("e", error)
        if (error) {
          setErrorPopup(true);
          setMessage(
            error?.response
              ? [error?.response?.data?.message]
              : ["Something wrong!. Trying again."]
          );
          setIsImportModalOpen(false);
        }
      });
  };

  const exportProjectData = (format: string, isChecked: string) => {
    axios({
      url:
        endpointUrl +
        `projects?limit=${limit}&page=${currentPage}&payment_status=${payment_status}&
        term=${term}&region=${region}&type=${type}&completed=${completed}&status=${status}&
        search=${globalSearch}&deleted_at=${deleted_at}&format=${format}&export=true&only_this_page=${isChecked}`,
      method: "GET",
      responseType: "blob",
      headers: {
        Authorization: `Bearer ${getUser.token}`,
        Accept: "application/json",
      },
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          "projects." + (format === "excel" ? "xlsx" : format)
        );
        document.body.appendChild(link);
        link.click();
        setIsExporting(false);
        setIsExportModalOpen(false);
      })
      .catch((error: any) => {
        setErrorPopup(true);
        setMessage(
          error?.message
            ? [error?.message]
            : ["Something wrong!. Trying again."]
        );
        setIsExporting(false);
        setIsImportModalOpen(false);
      });
  };

  var disabledData = checkedIsNew(data);

  useEffect(() => {
    if (successPopup) {
      ResuableToasty({
        message: "Done Successfully",
        position: "top-right",
        type: "success",
      });
    }

    setTimeout(() => {
      setSuccessPopup(false);
    }, 2000);
  }, [successPopup]);

  useEffect(() => {
    setCurrentPage(1);
  }, [globalSearch, filterStates, limit, deleted_at]);

  const props = useSpring({
    to: { opacity: 1 },
    from: { opacity: 0 },
    delay: 1100,
  });

  if (error) {
    if (FetchBaseError(error)) {
      if (error?.status === 400) {
        localStorage.removeItem("userData");
        navigate("/login");
      } else {
        setErrorPopup(true);
        setMessage(
          error?.data?.message
            ? [error?.data?.message]
            : ["You are something wrong. Pls login Again!"]
        );
      }
    }
  }
  const restoreProjectData = (id: number) => {
    restoreProject(id)
      .then((res: any) => {
        if (res.data) {
          ResuableToasty({
            message: `${res?.data?.message ?? "Successfully Updated"}`,
            position: "top-right",
            type: "success",
          });
        }
      })
      .catch((error: any) => {
        ResuableToasty({
          message: `${error?.data?.message ?? "Something wrong!"}`,
          position: "top-right",
          type: "error",
        });
      });
  };
  // useEffect(()=>{
  //   console.log("useEffect pjDataClone ", pjDataClone,data)
  //   if(assignKeword=="" && assignDepartmentId=="all"){
  //     setData(pjDataClone)
  //   }
  // },[pjDataClone])
  // console.log("first")

  return (
    <>
      {isLoading ? (
        <CommonTableSkeleton rows={12} />
      ) : (
        <animated.div style={props}>
          <ImportUsersModal
            isOpen={isImportModalOpen}
            setIsOpen={setIsImportModalOpen}
            importEmployee={importProject}
            sampleCSV={sampleImportCSV}
            isImporting={isImporting}
            setIsImporting={setIsImporting}
            setIsDownloading={setIsDownloading}
            isDownloading={isDownloading}
            isProject={true}
          />

          <ExportUsersModal
            isOpen={isExportModalOpen}
            setIsOpen={setIsExportModalOpen}
            exportToCSV={exportProjectData}
            exportToExcel={exportProjectData}
            exportToPDF={exportProjectData}
            isExporting={isExporting}
            setIsExporting={setIsExporting}
            isShowOnlyCheckbox={true}
            // exportToZip={exportProjectData}
          />

          <ToastContainer className={"text-12 font-poppins font-normal"} />
          <ErrorPopup
            descText="Error Message!"
            confirmBtnText="Yes, Close."
            show={errorPopup}
            setShow={setErrorPopup}
            message={message}
            setMessage={setMessage}
          />
          <div className="px-2 sm:px-4 py-4 bg-white sm:mt-[25px] mt-0 font-poppins  tracking-[-0.03rem] w-full h-full rounded-xl pt-3 pb-8 sm:pt-3 sm:pb-9">
            <div className="flex justify-between flex-wrap items-center mb-1">
              <div className="flex items-center mr-2 xs:mr-4 sm:w-[260px] w-[225px] md:mb-1">
                <DebounceSearchInput setState={setGlobalSearch} debounce={800}>
                  {(setState) => (
                    <div className="font-normal flex border-1 border-[#C4C4C4] bg-white hover:bg-[#EFEFEF] rounded-[28px]">
                      <input
                        type="text"
                        placeholder="Search"
                        className="focus:outline-none py-1 px-4 text-12 xl:text-14 leading-5 bg-transparent rounded-[28px] w-full text-graydark"
                        onChange={(e) => setState(e.target.value)}
                      />
                      <SearchIcon className="mr-2" />
                    </div>
                  )}
                </DebounceSearchInput>
              </div>
              <div className="flex justify-end items-center">
                {checkManagement ? (
                  <Button
                    label={
                      <span className="flex items-center">
                        <ImportIcon className="mr-[5px]" />
                        Import
                      </span>
                    }
                    onClick={() => {
                      setIsImportModalOpen(true);
                      setIsImporting(false);
                    }}
                    type="button"
                    variant="no-outline"
                    customClass="my-1 bg-transparent border-transparent hover:border-vorpblue hover:text-vorpblue transition-all duration-300 text-black2 xs:text-[12px] leading-[18px] font-medium py-1 px-[6px] sm:mr-[10px] mr-[5px]"
                    size="base"
                  />
                ) : null}
                {checkManagement ? (
                  <Button
                    label={
                      <span className="flex items-center">
                        <ExportIcon className="mr-[5px]" />
                        Export
                      </span>
                    }
                    onClick={() => {
                      setIsExportModalOpen(true);
                      setIsExporting(false);
                    }}
                    type="button"
                    variant="no-outline"
                    customClass="my-1 bg-vorpmyanmar border-vorpmyanmar hover:bg-vorpblue hover:text-white transition-all duration-300 text-vorpblue xs:text-[12px] leading-[18px] font-medium py-1 px-[6px] sm:mr-[10px] mr-[5px]"
                    size="base"
                  />
                ) : null}
                {checkManagement ? (
                  <Button
                    disabled={disabledData?.disabled}
                    label={<span className="flex items-center">+ Add New</span>}
                    onClick={() => {
                      // setData((prev) => [addNewProjects(), ...prev]);
                      setIsOpenPopup(true);
                    }}
                    type="button"
                    variant="primary"
                    customClass={
                      disabledData?.disabledClass +
                      " py-[3px] my-1 px-2 focus:outline-none hover:bg-vorpmyanmar hover:border-vorpmyanmar hover:text-vorpblue 2xl:text-[14px] text-12 leading-5 font-medium text-white"
                    }
                    size="base"
                  />
                ) : null}
              </div>
            </div>
            <div className="flex sm:flex-row flex-col justify-between items-center mb-1">
              <div className="flex w-full sm:mr-5 custom-scrollbar sm:mb-0 mb-1">
                <CustomScrollbar isVertical={false} isHor={true}>
                  <div className="flex w-full items-center">
                    <div
                      className={`flex items-center w-auto mr-3 ${Styles.dropdownFillAvailable}`}
                    >
                      <p className="sm:w-auto w-1/2 text-left base-label xl:text-sm text-12 hidden">
                        REGION
                      </p>
                      <CustomDropdownListForFilter
                        isFilter={true}
                        state={filterStates.region ?? ""}
                        setState={(value) => {
                          updateFilterState(value as string, "region");
                          setRegion(value);
                        }}
                        dropdownItems={[
                          { value: "All", label: "All" },
                          ...regionList.map((region) => ({
                            value: region.id,
                            label: region.name,
                          })),
                        ]}
                        className="w-full max-w-full min-w-[100px] xl:min-w-[120px] 3xl:min-w-[120px] 7xl:min-w-[120px] 6xs:text-12 7xl:text-14 max-h-[38px] text-dropdownTextColor"
                        placeholder="Region"
                        textAlign="text-left py-2 pl-2 pr-3"
                      />
                    </div>
                    <div
                      className={`flex items-center w-auto mr-3 ${Styles.dropdownFillAvailable}`}
                    >
                      <p className="sm:w-auto w-1/2 text-left base-label xl:text-sm text-12 hidden">
                        TYPE
                      </p>
                      <CustomDropdownListForFilter
                        isFilter={true}
                        state={filterStates.projectType ?? ""}
                        setState={(value) => {
                          updateFilterState(value as string, "projectType");
                          setType(value);
                        }}
                        dropdownItems={[
                          { value: "All", label: "All" },
                          ...projectTypesList?.map((type: any) => ({
                            value: type.id,
                            label: type.name,
                          })),
                        ]}
                        className="w-full max-w-full min-w-[100px] xl:min-w-[120px] 3xl:min-w-[120px] 7xl:min-w-[120px] 6xs:text-12 7xl:text-14 max-h-[38px] text-dropdownTextColor"
                        placeholder="Type"
                        textAlign="text-left py-2 pl-2 pr-3"
                      />
                    </div>
                    <div
                      className={`flex items-center mr-3 w-auto  ${Styles.dropdownFillAvailable}`}
                    >
                      <p className="sm:w-auto w-1/2 text-left base-label xl:text-sm text-12 hidden">
                        STATUS
                      </p>
                      <CustomDropdownListForFilter
                        isFilter={true}
                        state={filterStates.projectStatus ?? ""}
                        setState={(value) => {
                          updateFilterState(value as string, "projectStatus");
                          setStatus(value);
                        }}
                        // dropdownItems={ProjectStatusData?.map((status: any) => ({
                        dropdownItems={[
                          { value: "All", label: "All" },
                          ...projectStatusList?.map((status: any) => ({
                            value: status.id,
                            label: status.name,
                          })),
                        ]}
                        className="w-full max-w-full min-w-[120px] sm:max-w-[150px] 6xs:text-12 7xl:text-14 max-h-[38px] text-dropdownTextColor"
                        placeholder="CurrentStatus"
                        textAlign="text-left py-2 pl-2 pr-3"
                      />
                    </div>
                    <div
                      className={`flex items-center mr-3 w-auto ${Styles.dropdownFillAvailable}`}
                    >
                      <p className="sm:w-auto w-1/2 text-left base-label xl:text-sm text-12 whitespace-nowrap hidden">
                        TERMS
                      </p>
                      <CustomDropdownListForFilter
                        isFilter={true}
                        state={filterStates.paymentTerm ?? ""}
                        setState={(value) => {
                          updateFilterState(value as string, "paymentTerm");
                          setTerm(value);
                        }}
                        dropdownItems={paymentTerms?.map((term: any) => ({
                          value: term.id,
                          label: term.name,
                        }))}
                        className="w-full max-w-full min-w-[120px] sm:max-w-[150px] 6xs:text-12 7xl:text-14 max-h-[38px] text-dropdownTextColor"
                        placeholder="Terms"
                        textAlign="text-left py-2 pl-2 pr-3"
                      />
                    </div>
                    <div className={`flex items-center mr-3 w-auto`}>
                      <p className="sm:w-auto w-1/2 text-left base-label xl:text-sm text-12 whitespace-nowrap hidden">
                        PAYMENT STATUS
                      </p>
                      <CustomDropdownListForFilter
                        isFilter={true}
                        state={filterStates.paymentStatus ?? ""}
                        setState={(value) => {
                          updateFilterState(value as string, "paymentStatus");
                          setPayment_status(value);
                        }}
                        dropdownItems={[
                          { value: "All", label: "All" },
                          ...paymentStatusList?.map((status: any) => ({
                            value: status.id,
                            label: status.name,
                          })),
                        ]}
                        className="w-full max-w-full min-w-[120px] sm:max-w-[150px] 6xs:text-12 7xl:text-14 max-h-[38px] text-dropdownTextColor"
                        placeholder="Payment Status"
                        textAlign="text-left py-2 pl-2 pr-3"
                      />
                    </div>
                    <div
                      className={`${Styles.customWFull} flex items-center mr-3 w-auto`}
                    >
                      <p className="sm:w-auto w-1/2 text-left base-label xl:text-sm text-12 whitespace-nowrap hidden">
                        COMPLETED
                      </p>
                      <CustomDropdownListForFilter
                        state={filterStates.projectCompleted as string}
                        setState={(value) => {
                          updateFilterState(
                            value as string,
                            "projectCompleted"
                          );
                          if (value === "Yes") {
                            setCompleted(true);
                          } else if (value === "All") {
                            setCompleted("all");
                          } else {
                            setCompleted(false);
                          }
                        }}
                        dropdownItems={projectComplete.map((bool) => ({
                          value: bool as string,
                          label: bool as string,
                        }))}
                        className="w-full max-w-full min-w-[120px] sm:max-w-[150px] 6xs:text-12 7xl:text-14 max-h-[38px] text-dropdownTextColor"
                        placeholder="Completed"
                        textAlign="text-left py-2 pl-2 pr-3"
                      />
                    </div>
                    <div
                      className={`flex items-center mr-3 w-auto  ${Styles.dropdownFillAvailable}`}
                    >
                      <p className="sm:w-auto w-1/2 text-left base-label xl:text-sm text-12 hidden">
                        STATUS
                      </p>
                      <CustomDropdownListForFilter
                        isFilter={true}
                        state={deleted_at}
                        setState={(value) => {
                          setDeletedAt(value);
                        }}
                        // dropdownItems={ProjectStatusData?.map((status: any) => ({
                        dropdownItems={projectDeletedList?.map(
                          (status: any) => ({
                            value: status.id,
                            label: status.value,
                          })
                        )}
                        className="w-full max-w-full min-w-[120px] sm:max-w-[150px] 6xs:text-12 7xl:text-14 max-h-[38px] text-dropdownTextColor"
                        placeholder="Deleted Status"
                        textAlign="text-left py-2 pl-2 pr-3"
                      />
                    </div>
                    <div
                      className={classNames(
                        "flex items-center mr-[10px] w-auto ml-2"
                      )}
                    >
                      <CustomDropdownListMultipleForFilter
                        placeholder="Stage"
                        state={stage}
                        setState={setStage}
                        dropdownItems={
                          projectStatusList
                            ? [
                                ...projectStatusList.map((st) => ({
                                  value: st.id,
                                  label: st.name,
                                })),
                              ]
                            : []
                        }
                        textAlign="text-left"
                        className="max-h-[38px] max-w-full sm:max-w-[250px] 2xl:w-32 sm:w-28 w-full"
                      />
                    </div>
                    
                  </div>
                </CustomScrollbar>
              </div>
              <div className="flex items-center justify-end sm:w-auto w-full sm:mb-0 mb-1">
                <div className="flex items-center justify-center text-center mr-3">
                  <p className="base-label xl:text-sm text-12">DISPLAY</p>
                  <NumberDropdownList
                    state={pagination.pageSize}
                    setState={(value) => {
                      setPagination((prev) => ({
                        ...prev,
                        pageSize: value as number,
                      }));
                      setLimit(value);
                    }}
                    dropdownItems={new Array(5)
                      .fill(1)
                      .map((_, index: number) => {
                        const count = (index + 1) * 20;
                        return {
                          value: count,
                          label: String(count),
                          // label: (x.id==0?x.name:"View #" + x.index),
                          // value: x.id,
                        };
                      })}
                    className="max-w-[60px] md:max-w-[80px] lg:max-w-[52px] xl:max-w-[60px] 7xl:max-w-[72px]"
                    textAlign="text-left pl-2 pr-3"
                    placeholder="All"
                    paddingClass="py-[2px]"
                  />
                </div>
                <div className="">
                  <StringDropdownListWithDeleteBtn
                    haveDeleteBtn={true}
                    setIsTemplate={setIsTemplate}
                    state={isTemplate ? currentViewData : currentView}
                    setState={setCurrentView}
                    deleteTemplate={deleteTemplateData}
                    dropdownItems={moduleList?.map((x: any, index: number) => {
                      return {
                        label:
                          Number(x.id) === -1 ? x.name : "View #" + x.index,
                        value: x.id,
                      };
                    })}
                    className="w-full min-w-[100px] lg:min-w-[120px] 4xl:max-w-[137px]  7xl:min-w-[137px] 6xs:text-12 7xl:text-14"
                    placeholder="View#1"
                    textAlign="text-left py-[2px] pl-2 pr-3"
                    custPadding="py-[2px]"
                    setErrorPopup={setErrorPopup}
                    setSuccessPopup={setSuccessPopup}
                    setMessage={setMessage}
                    isProject={true}
                  />
                </div>
                <button
                  type="button"
                  onClick={() => setIsLayoutControlOpen(true)}
                  className="flex justify-center relative items-center rounded-lg bg-vorphongkongborder hover:bg-[#FFF0D2] w-[29px] h-[29px] ml-2 group transition-all duration-300 "
                >
                  <CustomizeLayoutIcon />
                  <div className="absolute left-7 top-[-5px] border border-white w-4 h-4 rounded-full bg-vorphongkongborder flex justify-center items-center">
                    <AddIcon width={8} height={8} color="white" />
                  </div>
                </button>
              </div>
            </div> 
            <ProjectsTable
              setMemberData={setMemberData}
              memberData={memberData}
              pagination={pagination}
              data={data}
              setData={setData}
              setPagination={setPagination}
              isLayoutControlOpen={isLayoutControlOpen}
              setIsLayoutControlOpen={setIsLayoutControlOpen}
              globalSearch={globalSearch}
              setGlobalSearch={setGlobalSearch}
              columnFilters={columnFilters}
              setColumnFilters={setColumnFilters}
              regionList={regionList}
              setRegionList={setRegionList}
              projectTypesList={projectTypesList}
              setProjectTypesList={setProjectTypesList}
              paymentStatusList={paymentStatusList ? paymentStatusList : []}
              setPaymentStatusList={setPaymentStatusList}
              projectStatusList={projectStatusList ? projectStatusList : []}
              setProjectStatusList={setProjectStatusList}
              projectTagList={projectTagList}
              paymentTermList={paymentTermList}
              setPaymentTermList={setPaymentTermList}
              deleteProject={deleteProj}
              filterStates={filterStates}
              setFilterStates={setFilterStates}
              updateFilterState={updateFilterState}
              localModuleId={localModuleId}
              setHiddenFields={setHiddenFields}
              hiddenFields={hiddenFields}
              total={itemCount}
              changePage={changePage}
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
              checkManagement={checkManagement}
              setCols={setCols}
              setIsTemplate={setIsTemplate}
              setIsEdit={setIsEdit}
              isEditing={isEditing}
              // addNew={addNew}
              viewPermis={viewPermis} 
              sorting={sorting}
              setSorting={setSorting}
              // setGetPlaceHolder={setGetPlaceHolder}
              assignUserListData={assignUserListData}
              departmentList={departmentList}
              setAssignDepartmentId={setAssignDepartmentId}
              assignDepartmentId={assignDepartmentId}
              setAssignKeword={setAssignKeword}
              assignEmployee={assignEmployee}
              rowList={rowList}
              // setIsUploading={setIsUploading}
              // isUploading={isUploading}
              restoreProjectData={restoreProjectData}
              // setIsUploading={setIsUploading}
              // isUploading={isUploading}
              currencyLists={currencyLists}
              setCurrencyLists={setCurrencyLists}
              keyDateDataList={keyListData}
              setEditData={setEditData}
              setIsOpenPopup={setIsOpenPopup}
              // setIsUploading={setIsUploading}
              // isUploading={isUploading}
              pjDataClone={pjDataClone}
              setPjDataClone={setPjDataClone}
            />
          </div>

          <CreateProjectPopup
            rowList={rowList}
            assignUserListData={assignUserListData}
            createProjectTech={createProjectTech}
            isOpen={isOpenPopup}
            setIsOpen={setIsOpenPopup}
            // uploading={uploading}
            regionList={regionList}
            countryList={countryList}
            projectTypesList={projectTypesList}
            // projectTagList={projectTagList}
            projectStatusList={projectStatusList}
            currencyLists={currencyLists}
            memberDataList={memberData}
            keyListData={keyListData}
            paymentStatusList={paymentStatusList ?? []}
            departmentList={departmentList}
            editedData={editedData}
            setEditData={setEditData}
          />
        </animated.div>
      )}
    </>
  );
};
export default ProjectsList;






