import { Dispatch, FC, SetStateAction, useState } from "react";
import { EditIcon } from "../../common/Icons";
import { baseObj } from "../../../utils/constants";

interface ITextAreaModal {
  rowId: number;
  initialValue: string;
  setData: Dispatch<SetStateAction<baseObj[]>>;
  keyname: string;
  title: string;
  getScope?: string;
  checkManagement: boolean;
}

const TextAreaModal: FC<ITextAreaModal> = ({
  rowId,
  initialValue,
  setData,
  keyname,
  title,
  getScope,
  checkManagement,
}) => {
  const [isEdit, setIsEdit] = useState<boolean>(false);
  // const [value, setValue] = useState<string>(initialValue);
  const [values, setValues] = useState<any | undefined>(getScope);

  
  const saveHandler = () => {
    setData((prev) =>
      prev.map((row) => {
        if (row.uniqeID === rowId) {
          return { ...row, [keyname]: values };
        } else {
          return row;
        }
      })
    );
    setIsEdit(false);
  };

  return (
    <div className="rounded-xl min-h-[150px] w-full sm:min-w-[558px] sm:max-w-[558px] max-w-[90%] bg-white mx-4 before:overflow-hidden">
      <div className="bg-modalHeader flex justify-between items-center py-3 px-4 w-full border border-offwhite rounded-t-4">
        <h1 className="text-12 md:text-14 leading-[18px] font-semibold text-black2">
          {title}
        </h1>
        {/* {(!isEdit && checkManagement) &&(
          <button
            type="button"
            onClick={() => setIsEdit(true)}
            className="w-5 h-5 flex justify-center items-center bg-vorpblue rounded-4"
          >
            <EditIcon width={12} height={12} />
          </button>
        )} */}
      </div>
      <div className="p-4 bg-white">
        {isEdit ? (
          <textarea
            rows={4}
            value={values}
            onChange={(e) => setValues(e.target.value)}
            className="w-full focus:outline-none text-12 mb-0 leading-[18px] text-black2 resize-none"
          ></textarea>
        ) : (
          <p className="whitespace-pre-wrap text-12 leading-[18px] text-black2">
            {values}
          </p>
        )}
        {isEdit && (
          <div className="flex justify-end items-center text-12 leading-[18px] font-semibold">
            <button
              type="button"
              onClick={() => setIsEdit(false)}
              className="py-2 px-4 bg-[#EFEFEF] rounded-md overflow-hidden flex justify-center items-center text-[#979797] mr-2"
            >
              Discard

            </button>
            <button
              type="button"
              onClick={saveHandler}
              className="py-2 px-4 bg-vorpblue text-white rounded-md"
            >
              Save
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default TextAreaModal;
