// import { Modal, Fade, makeStyles } from "@material-ui/core";
import { Fade, Modal } from "@mui/material";
import {
  ChangeEvent,
  Dispatch,
  FC,
  SetStateAction,
  createRef,
  useCallback,
  useEffect,
  useState,
} from "react";
import { CloseIcon, SelectedIcon } from "../common/Icons";
import { NumberDropdownList } from "../common/DropdownList";
import { roles } from "../../utils/constants";
import { truncateFilename } from "../../utils/helpers";
import axios from "../../axios";
import { endpointUrl } from "../../app/services/api";
import authHeader from "../../app/services/auth-header";
import classNames from "classnames";
import DatePickerMY from "../EmployeeTable/DatePickerMY";
import MonthYearPicker from "../EmployeeTable/MonthYearPicker";
import DatePicker from "react-datepicker";
import { renderDateFormatMLongY, renderDateFormatMY } from "../DashboardCalendarView/DashboardAddEventPopup";
import { useGeneratePayRollMutation } from "../../app/services/payroll";
import { toast } from "react-toastify";
import ErrorPopup from "../ErrorPopup/ErrorPopup";
import MonthPicker from "../EmployeeTable/MonthPicker";
import CommonErrorPopup from "../common/CommonErrorPopup";

interface IImportUsersModal {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  sampleCSV?: any;
  importEmployee?: any;
  isImporting?: boolean;
  setIsImporting?: Dispatch<SetStateAction<boolean>>;
  isDownloading?: boolean;
  setIsDownloading?: Dispatch<SetStateAction<boolean>>;
  isChecked?: boolean;
}

interface IFormData {
  role: number;
  isUpdateExisting: boolean;
}

// const ImportUsersModal: FC<IImportUsersModal> = ({
const GeneratePopup: FC<any> = ({
  isOpen,
  setIsOpen,
  sampleCSV,
  importEmployee,
  isImporting,
  setIsImporting,
  isDownloading,
  setIsDownloading,
  isChecked,
}) => {
  const [formData, setFormData] = useState<IFormData>({
    role: 10,
    isUpdateExisting: false,
  });

  const [generate] = useGeneratePayRollMutation();
  const [dateData, setDateData] = useState<any>(new Date());
  const [errorPopup, setErrorPopup] = useState<boolean>(false);
  const [error,setError]=useState<string>('');
  const [message, setMessage] = useState<any>([]);

  const handleGenerate = () => {
    setIsImporting(true);

    let getMonth: any = dateData
      ? renderDateFormatMY(new Date(dateData), " ")
      : renderDateFormatMY(new Date(), " ");

    let data: any = {
      month: getMonth,
      //is_replaced: formData.isUpdateExisting ? 1 : 0,
    };
    generate(data)
      .then((response: any) => {
        setIsOpen(false);
        setIsImporting(false);
        if (response?.error) {
          setError(response?.error?.data?.message);
        } else {
          toast("Successfully Generate.", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            type: "success",
          });
        }

      })
      .catch((error: any) => {
        setIsOpen(false);

        setErrorPopup(true);
        setMessage(
          error
            ? ["Something went wrong!Trying again."]
            : ["Something went wrong!Trying again."]
        );
      });
  };

  useEffect(() => {
    setFormData({ ...formData, isUpdateExisting: false });
  }, [isOpen]);

  return (
    <>
      <CommonErrorPopup
        show={error != "" ? true : false}
        setShow={() => setError("")}
        message={error}
      />
      <ErrorPopup
        descText="Error Message!"
        confirmBtnText="Yes, Close."
        show={errorPopup}
        setShow={setErrorPopup}
        message={message}
        setMessage={setMessage}
      />

      <Modal
        open={isOpen}
        style={{
          background: "rgb(0 0 0 / 0.3)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        // onClose={() => setIsOpen(false)}
        onClose={(event, reason) => {
          if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
            // Set 'open' to false, however you would do that with your particular code.
            setIsOpen(false);
          }
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableEnforceFocus={true}
      // disableBackdropClick={true}
      >
        <Fade in={isOpen} timeout={500}>
          <div className="modalContent max-w-[530px] w-full m-auto px-4 py-8 xl:px-0 xl:py-0 font-primary generate_popup">
            <div className="w-full bg-white rounded-xl overflow-hidden">
              <div className="px-6 sm:px-8 md:px-10 py-6 bg-vorpblue flex justify-between items-center rounded-t-xl overflow-hidden">
                <h1 className="text-16 sm:text-18 leading-[27px] font-semibold text-white">
                  Generate
                </h1>
                <button type="button" onClick={() => setIsOpen(false)}>
                  <CloseIcon width={18} height={18} />
                </button>
              </div>
              <div className="px-4 sm:px-6 lg:px-10 py-6 pb-8 lg:py-8 lg:pb-10">
                <div></div>
                <div className="mb-4">
                  <p className="base-label  mb-[10px]">Month to generate:</p>
                  <div className="flex items-center text-black2 text-12 border-[#C2C2C2] mb-10 z-[9999999]">
                    <MonthPicker
                      dobDate={dateData}
                      setDate={setDateData}
                      isShow={true}
                    />
                  </div>
                </div>
                {/* {!isChecked ? (
                  <div className="mb-[30px]">
                    <p className="base-label mb-[10px]">Update existing data</p>
                    <div className="flex items-start">
                      <label
                        htmlFor="update-existing"
                        className="relative max-h-[24px] flex items-start hover:cursor-pointer"
                      >
                        <input
                          type="checkbox"
                          id="update-existing"
                          className=" checked:bg-vorpblue w-3 h-3 sm:w-4 sm:h-4 p-3 border-none outline-none appearance-none bg-offwhite rounded hover:cursor-pointer"
                          checked={formData.isUpdateExisting}
                          onChange={() =>
                            setFormData((prev) => ({
                              ...prev,
                              isUpdateExisting: !prev.isUpdateExisting,
                            }))
                          }
                        />
                        {formData.isUpdateExisting && (
                          <SelectedIcon className="absolute top-2 left-1.5 right-0" />
                        )}
                         <p className="text-12 sm:text-14 leading-5 text-black2 ml-3">
                        Existing data that matched will be updated. Data that do
                        not exist will be skipped.
                      </p>
                      </label>
                     
                    </div>
                  </div>
                ) : null} */}
                <div className="flex items-center">
                  <button
                    type="button"
                    onClick={handleGenerate}
                    className={classNames(
                      isImporting && isOpen
                        ? "opacity-50 pointer-events-none"
                        : "",
                      "py-2 px-4 bg-vorpblue rounded-md text-white text-12 leading-[18px] font-semibold"
                    )}
                  >
                    {isImporting && isOpen ? "Generating" : "Generate"}
                  </button>
                  <button
                    type="button"
                    onClick={() => setIsOpen(false)}
                    className={classNames(
                      "ml-5 text-12 leading-[18px] font-semibold text-[#B5B5C3]"
                    )} //isImporting && isOpen ? 'opacity-50 pointer-events-none' : '',
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </>
  );
};

export default GeneratePopup;
