import { createRef, Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { CloseIcon, HiddenIcon, SendIcon, ShowIcon } from "../../common/Icons";
import Button from "../../common/Button";
import { StringMultipleSelectBox } from "../../common/MultipleSelectBox";
import { passwordTags } from "../../../utils/constants";
import classNames from "classnames";
import { CommonTagDropdown } from "../../common/CommonTagDropdown";
import DOMPurify from 'dompurify';

import { Modal } from 'react-responsive-modal';
import "react-responsive-modal/styles.css";
import Astyles from '../../../components/DashboardAnnoucement/Custom-animation.module.scss';


interface IPasswordForm {
  addNewPassword: any;
  setShowPasswordForm: Dispatch<SetStateAction<boolean>>;
  tagsLists?: any;
  createdError?: string;
  createTagData?: (label: string) => void;
  open?: boolean;
}

const PasswordForm: FC<IPasswordForm> = ({ setShowPasswordForm, addNewPassword, tagsLists, createdError, createTagData, open }) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [error, setError] = useState<string>(createdError ? createdError : '');
  const [tags, setTags] = useState<string[]>([]);
  const [updatedTags, setUpdatedTags] = useState<any[]>([]);
  var platform = createRef<HTMLInputElement>();
  const account = createRef<HTMLInputElement>();
  const password = createRef<HTMLInputElement>();
  const url = createRef<HTMLInputElement>();

  const saveNewPassword = () => {
    const platforms = DOMPurify.sanitize(platform?.current?.value ?? "");
    const passwords = DOMPurify.sanitize(password?.current?.value ?? "");
    const accounts = DOMPurify.sanitize(account.current?.value ?? "");
    const urls = DOMPurify.sanitize(url.current?.value ?? "");

    var passwordData = {
      platform: platforms,
      password: passwords,
      tags: changeTagToTabObj(),
      account: accounts,
      links: urls,
      user_ids: [],
    };
    var isValid = checkValidate(passwordData);
    if (!isValid) {
      return;
    }
    addNewPassword(passwordData);
  }
  const checkValidate = (data: any) => {
    var isValidate = true;
    if (data.platform == "") {
      isValidate = false;
      setError("The platform field is required.");
    } else if (data.account == "") {
      isValidate = false;
      setError("The account field is required.");
    } else if (data.password == "") {
      isValidate = false;
      setError("The password field is required.");
    }
    else if (data.links == "") {
      isValidate = false;
      setError("The link field is required.");
    }
    else {
      setError("");
    }
    // else if(data.tags.length<=0){
    //   isValidate=false;
    //   setError("The tags field is required.");
    // }
    return isValidate;
  }
  // const changeTagToTabObj=()=>{
  //   var data=passwordTags.filter(x=>{
  //     return tags.find(t=>t==x.label.toString())
  //   });
  //   return data;
  // }

  const changeTagToTabObj = () => {
    // var data = tagsLists?.tagsData?.filter((x: any) => {
    var data = tagsLists?.filter((x: any) => {
      return tags.find(t => t == x.name.toString())
    });
    return data;
  }

  useEffect(() => {
    setError(createdError ? createdError : '')
  }, [createdError])

  const modalStyles = {
    modal: {
      // maxWidth: '600px', // Set your desired width here
      background: 'none',
      // transition: 'all 1.3s',
    },
  };

  return (
    // <Modal
    //   open={open?? false}
    //   onClose={() => setShowPasswordForm(false)}
    //   center
    //   // classNames={{
    //   //   // overlayAnimationIn: `${Astyles.customEnterOverlayAnimation}`,
    //   //   // overlayAnimationOut: `${Astyles.customLeaveOverlayAnimation}`,
    //   //   // modalAnimationIn: `${Astyles.customEnterModalAnimation}`,
    //   //   modalAnimationOut: `${Astyles.customLeaveModalAnimation}`,
    //   // }}
    //   styles={modalStyles}
    //   animationDuration={400}
    //   showCloseIcon={false}
    // >

      <div className="w-[500px] h-full p-6 lg:p-0 flex justify-center items-center">
        <div className="rounded-xl bg-white overflow-hidden font-primary w-full">
          <div className="px-6 sm:px-10 py-3 sm:py-6 bg-vorpblue flex justify-between items-center">
            <h3 className="text-16 sm:text-18 leading-[27px] font-semibold text-white">
              New Password
            </h3>
            <button
              type="button"
              aria-label="Close Modal"
              onClick={() => setShowPasswordForm(false)}
            >
              <CloseIcon />
            </button>
          </div>
          <div className="px-4 sm:px-10 my-6 sm:mt-9 sm:mb-10 bg-white max-h-[75vh] overflow-y-auto">
            <div className="w-full">
              <label htmlFor="" className="base-label mb-2 block">
                Platform Name
              </label>
              <input ref={platform}
                type="text"
                placeholder="Platform Name"
                className="px-3 py-2 sm:py-[14px] text-14 leading-5 text-graydark border border-graydark focus:outline-none rounded-4 w-full mb-5"
              />
            </div>
            <div className="w-full mb-5">
              <label htmlFor="" className="base-label mb-2 block">
                Tag
              </label>
              <CommonTagDropdown showAll={true}
                createData={createTagData}
                state={tags}
                setState={setTags}
                // options={passwordTags.map((tag) => ({ label: tag.label, value: tag.id.toString() }))}
                options={tagsLists?.map((tag: any) => ({ label: tag.name, value: tag.id.toString() }))}
              />
            </div>
            <div className="w-full">
              <label htmlFor="" className="base-label mb-2 block">
                Account
              </label>
              <input ref={account}
                type="text"
                placeholder="Account Name"
                className="px-3 py-2 sm:py-[14px] text-14 leading-5 text-graydark border border-graydark focus:outline-none rounded-4 w-full mb-5"
              />
            </div>
            <div className="w-full">
              <label htmlFor="" className="base-label mb-2 block">
                Password
              </label>
              <div className="relative w-full mb-2">
                <input ref={password}
                  type={showPassword ? "text" : "password"}
                  placeholder="********"
                  className="px-3 py-2 sm:py-[14px] text-14 leading-5 text-graydark border border-graydark focus:outline-none rounded-4 w-full"
                />
                <button
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                  className="absolute top-0 bottom-0 my-auto right-3 focus:outline-none"
                >
                  {!showPassword ? <HiddenIcon /> : <ShowIcon />}
                </button>
              </div>
            </div>
            <div className="w-full">
              <label htmlFor="" className="base-label mb-2 block">
                URL Link
              </label>
              <input ref={url}
                type="text"
                placeholder="#"
                className="px-3 py-2 sm:py-[14px] text-14 leading-5 text-graydark border border-graydark focus:outline-none rounded-4 w-full mb-2"
              />
            </div>
            <div className={classNames("my-4", error == "" ? "hidden" : "")}>
              <span className="text-16 text-red-500 font-semibold">{error}</span>
            </div>
            <div className="flex items-center mt-3">
              <Button
                type="button"
                label={
                  <span className="flex items-center text-14 leading-[21px] font-medium group-hover:text-vorpblue">
                    <SendIcon className="mr-[10px] group-hover:fill-vorpblue transition-all duration-300" />
                    Submit
                  </span>
                }
                onClick={() => saveNewPassword()}
                variant="no-outline"
                size="lg"
                customClass="hover:bg-vorpblue mr-2 6xs:py-2 6xs:px-3 hover:bg-vorpmyanmar transition-all duration-300 border-none"
              />
              <Button
                type="button"
                label={
                  <span className="flex items-center text-black2 text-14 font-medium leading-[21px]">
                    <CloseIcon
                      width={9}
                      height={9}
                      className="mr-[10px] fill-black2"
                    />
                    Cancel
                  </span>
                }
                onClick={() => setShowPasswordForm(false)}
                variant="no-outline"
                size="lg"
                customClass=" 6xs:py-2 6xs:px-3 bg-offwhite text-graydark border-none"
              />
            </div>

          </div>
        </div>
      </div>
    // {/* </Modal> */}
  );
};

export default PasswordForm;
