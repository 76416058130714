import { redirectToLogin } from './annoucement-sheet';
import { api } from './api';
import authHeader from './auth-header';
export type IMyProjectstResponse = {
    module_id: number,
    status: boolean,
    project_list: IMyProjects
}
export type IMyProjectsData = {
    id: number;
    name: string;
    description: string;
}
export type IMyProjects = {
    current_page: number;
    data: IMyProjectsData[],
    total: number;
}
type IMyProjectsInput = {
    sort: string,
    search: string,
    page: number,
    limit: number,
}
export const profileProjectApi = api.injectEndpoints({
    endpoints: (build) => ({
        getProfileProjects: build.query<IMyProjectstResponse, IMyProjectsInput>({
            query: ({ sort, search, limit, page }) => ({
                url: `/profile/projects?sort=${sort}&search=${search}&limit=${limit}&page=${page}
            
            `,
                headers: authHeader()
            }),
            transformErrorResponse: (err) => {
                if (err?.status === 401) {
                    redirectToLogin();
                }
            },
            providesTags: (result: any = []) => {
                if (result.data) {
                    if (result.data.length > 0) {
                        return [
                            ...result.data.map((id: any) => ({ type: "ProfileProjects", id } as const)),
                            { type: "ProfileProjects" as const, id: "LIST" },
                        ]
                    }
                    else {
                        return [{ type: "ProfileProjects" as const, id: "LIST" },]
                    }
                }
                else {
                    return [{ type: "ProfileProjects" as const, id: "LIST" },]
                }

            },
        }),
    }),
});

export const {
    useGetProfileProjectsQuery,
} = profileProjectApi;


export const {
    endpoints: {
        getProfileProjects,
    },
} = profileProjectApi;