import React, { FC } from "react";
import classNames from "classnames";
import styles from './ProfileTodayEvent.module.scss'
import sgHoliday from "../DashboardCalendar/img/sgHoliday.svg";
import hkHoliday from "../DashboardCalendar/img/hkHoliday.svg";
import { IEventList } from "../../app/services/profile";
import noteIcon from '../DashboardCalendar/img/note-icon.svg';
const defaultEvent = [
    {
        "uniqueID": 53,
        "title": "SH",
        "date": "2023-06-20",
        "start": "2023-06-20",
        "end": "2023-06-20",
        "note": "RemFly",
        "eventType": "SGH",
        "eventTypeDescription": "Singpore Holiday",
        "eventTarget": "H",
        "targetId": 0,
        "eventNote": false,
        "fullHalfDay": null,
        "description": "RemFly",
        "show_note": false
    },
    {
        "uniqueID": 647,
        "title": "Arlo-UL",
        "date": "2023-10-02",
        "start": "2023-10-02",
        "end": "2023-10-02",
        "note": "",
        "eventType": "UL",
        "eventTypeDescription": "Unpaid Leave",
        "eventTarget": "L",
        "targetId": 34,
        "eventNote": false,
        "fullHalfDay": "Full",
        "description": "",
        "show_note": false
    }
]
interface IProfileEvents {
    eventList: IEventList[]
}
const ProfileTodayEvent: FC<IProfileEvents> = ({ eventList }) => {
    var eventDataList = eventList ? eventList : defaultEvent;
    var today = new Date();    
    return (
        <div className="pl-2 5xs:pl-4 sm:pl-5 pr-2 py-5 bg-white rounded-xl">
            <div className="pt-[20px] pr-5 flex max-h-[400px] custom-scrollbar overflow-auto">
                <div className="min-w-[40px] mr-5">
                    <h1 className="text-center font-bold text-[24px] text-black2">{today.toLocaleDateString('en', { day: "2-digit" })}</h1>
                    <p className="text-center font-normal text-xs text-black2 -mt-[8px]">{today.toLocaleDateString('en', { weekday: 'short' })}</p>
                </div>
                <div className="w-full">
                    <ul>
                        {eventDataList?.map((todayevt, key) => {
                            var eventType = todayevt.eventType;
                            var eventNote = todayevt.eventNote;
                            var leaveFullHalf = todayevt.fullHalfDay;
                            return <li key={key}>
                                <div className={
                                    classNames(
                                        eventType == "AL" && !leaveFullHalf?.includes("Half")
                                            ? styles.eventtitle
                                            : eventType == "UAT"
                                                ? styles.eventProjectUAT
                                                : eventType == "Launch"
                                                    ? styles.eventProjectLaunch
                                                    : eventType == "DC"
                                                        ? styles.eventProjectDesign
                                                        : eventType == "SL" && !leaveFullHalf?.includes("Half")
                                                            ? styles.eventSickLeave
                                                            : eventType == "SL" && leaveFullHalf?.includes("Half")
                                                                ? styles.eventHalfSickLeave
                                                                : eventType == "UL" && !leaveFullHalf?.includes("Half")
                                                                    ? styles.eventUnpaidLeave
                                                                    : eventType == "UL" && leaveFullHalf?.includes("Half")
                                                                        ? styles.eventHalfUnpaidLeave
                                                                        : eventType == "BL" && !leaveFullHalf?.includes("Half")
                                                                            ? styles.eventBd
                                                                            : eventType == "BL" && leaveFullHalf?.includes("Half")
                                                                                ? styles.eventHalfBd
                                                                                : eventType == "AL" && leaveFullHalf?.includes("Half")
                                                                                    ? styles.eventHalfDay
                                                                                    : eventType == "KOM"
                                                                                        ? styles.eventKOM
                                                                                        : eventType == "D"
                                                                                            ? styles.eventDevelopment
                                                                                            : eventType == "SEOS"
                                                                                                ? styles.eventSEO
                                                                                                : eventType == "CUAT"
                                                                                                    ? styles.eventClientUAT
                                                                                                    : eventType == "ML" && !leaveFullHalf?.includes("Half")
                                                                                                        ? styles.eventMaternity
                                                                                                        : eventType == "ML" && leaveFullHalf?.includes("Half")
                                                                                                            ? styles.eventMaternityHalf
                                                                                                            : eventType == "PL" && !leaveFullHalf?.includes("Half")
                                                                                                                ? styles.eventPaternity
                                                                                                                : eventType == "PL" && leaveFullHalf?.includes("Half")
                                                                                                                    ? styles.eventPaternityHalf
                                                                                                                    : eventType == "TL" && !leaveFullHalf?.includes("Half")
                                                                                                                        ? styles.eventSubstitute
                                                                                                                        : eventType == "TL" && leaveFullHalf?.includes("Half")
                                                                                                                            ? styles.eventSubstituteHalf
                                                                                                                            : eventType == "OL" && !leaveFullHalf?.includes("Half")
                                                                                                                                ? styles.eventLeftOver
                                                                                                                                : eventType == "OL" && leaveFullHalf?.includes("Half")
                                                                                                                                    ? styles.eventLeftOverHalf
                                                                                                                                    : eventType == "CL"
                                                                                                                                    ? styles.eventChildLeave        
                                                                                                                                    : eventType == "LD"
                                                                                                                                        ? styles.eventLaunchDay
                                                                                                                                        : "items-center",
                                        "flex items-start font-poppins text-sm font-medium text-[#464E5F]"
                                    )}
                                >
                                    {eventType == "SGH" ? (
                                        <img src={sgHoliday} alt="sgHoliday" className="mr-2 sm:w-[10px] w-[7px]" />
                                    ) : eventType == "HKH" ? (
                                        <img src={hkHoliday} alt="hkHoliday" className="mr-2 sm:w-[10px] w-[7px]" />
                                    ) : (
                                        ""
                                    )}
                                    {eventType=='N'?
                                        <img src={noteIcon} alt="noteIcon" className="mr-2 sm:w-auto w-[7px]" />:''
                                    }
                                    <div className="w-full">
                                        <p className="whitespace-normal overflow-hidden text-ellipsis  3xl:w-[100%] sm:w-[80%] w-1/2">
                                            {todayevt.title}
                                            {/* - {todayevt.eventTypeDescription} */}
                                        </p>
                                        <p className="text-[11px] text-graydark font-normal">{todayevt.fullHalfDay == "Full" ? "All day" : todayevt.fullHalfDay}</p>
                                    </div>
                                </div>
                                <hr className={classNames("my-1 bg-[#F3F6F9]", (eventDataList.length - 1) == key ? 'hidden' : '')} />
                            </li>
                        })}
                    </ul>
                </div>
            </div>
        </div>
    )
}
export default ProfileTodayEvent;