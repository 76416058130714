import { FC, useEffect, useMemo, useState, useCallback } from "react";
import DebounceSearchInput from "../../components/Tables/DebounceSearchInput";
import { SearchIcon } from "../../components/common/Icons";
import classNames from "classnames";
import Button from "../../components/common/Button";
import { checkedIsNew, useGetDepartmentListQuery } from "../../app/services/employee";
import {
  ColumnFiltersState,
  PaginationState,
  SortingState,
} from "@tanstack/react-table";
import { useSpring, animated } from "react-spring";
import { getAuthUser } from "../../app/services/dashboard";
import { ToastContainer, toast } from "react-toastify";
import CommonErrorPopup from "../../components/common/CommonErrorPopup";
// import CreatePopupOnlyName from "../../components/ProjectStatusPopup/CreatePopupOnlyName";
// import DepartmentTable from "../../components/DepartmentTable/DepartmentTable";
import { useCreateDesignationMutation, useDeleteDesignationMutation, useGetDesignationsQuery, useUpdateDesignationMutation } from "../../app/services/designation";
import DesignationTable from "../../components/DesignationTable/DesignationTable";
import { DepartmentInfo } from "../../app/services/departments";
import CreatePopupWithDropdown from "../../components/ProjectStatusPopup/CreatePopupWithDropdown";
import styles from './Designation.module.scss'
import CustomDropdownListForFilter from "../../components/EmployeeTable/CustomDropdownListForFilter";

interface IOT { }

export interface IDepartment {
  id: number;
  name: string;
  label: string;
}

// const defaultFields = ["name", "order", "actions"];

export type TStatusListData = {
  id: number | string;
  name: string;
  label?: string;
};

const Designation: FC<IOT> = () => {
  const [editedData, setEditData] = useState<any>("");

  const [isOpenPopup, setIsOpenPopup] = useState<boolean>(false);
  const [globalSearch, setGlobalSearch] = useState<string>("");
  const [itemCount, setItemCount] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  });
  const [sorting, setSorting] = useState<SortingState>([]);
  const [message, setMessage] = useState<any>([]);
  const [currentView, setCurrentView] = useState<any>(0);
  // const [currentViewData, setCurrentViewData] = useState<number>(0);
  let [localModuleId, setLocalModuleId] = useState<number>(0);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [hiddenFields, setHiddenFields] = useState<string[]>([]);
  const [viewCols, setCols] = useState<any>([]);
  const [visArray, setVisArray] = useState<any>({});
  const [data, setData] = useState<any[]>([]);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [isTemplate, setIsTemplate] = useState<boolean>(false);
  const [errorPopup, setErrorPopup] = useState<boolean>(false);
  const [isEditing, setIsEdit] = useState<boolean>(false);
  const [isSubmit, setIsSubmit] = useState<boolean>(false);
  const [uploading, setUploading] = useState<boolean>(false);
  const [department, setDepartment] = useState<string>('');
  const [deptFilter, setDeptFilter] = useState<string>('');
  const [departmentList, setDepartmentList] = useState<DepartmentInfo[]>([]);
  const [error, setError] = useState<string>('');
  const [isLayoutControlOpen, setIsLayoutControlOpen] =
    useState<boolean>(false);
  const [skip, setSkip] = useState<boolean>(false);
  var disabledData = checkedIsNew(data ? data : []);
  const { data: designations } = useGetDesignationsQuery({
    // page: currentPage,
    // limit: pagination.pageSize,
    // search: globalSearch,
    // sort: sorting.map((s) => `${s.desc ? "-" : ""}${s.id}`).join(","),
  });
  const { data: departments } = useGetDepartmentListQuery();
  const [deleteDesignation] = useDeleteDesignationMutation();
  const [saveDesignationData] = useCreateDesignationMutation();
  const [updateDesignationData] = useUpdateDesignationMutation();

  var getUser = getAuthUser();
  const getAuthPermis: string[] = useMemo(() => [], []);
  if (getUser?.permissions) {
    getUser?.permissions?.forEach((per: any) => {
      getAuthPermis.push(per?.name);
    });
  }

  const props = useSpring({
    to: { opacity: 1 },
    from: { opacity: 0 },
    delay: 1000,
  });

  const changePage = (pageNo: number) => { };

  useEffect(() => {
    if (designations) {
      setData(designations ? designations?.designationList : []);
      setItemCount(designations?.designationList?.length)
    }
  }, [designations]);

  useEffect(() => {
    if (departments) {
      setDepartmentList(departments)
    }
  }, [departments])

  useEffect(() => {
    setCurrentPage(1);
    setItemCount(data.length)
  }, [pagination.pageSize, globalSearch, deptFilter]);

  const createDesignation = (name: string) => {
    const saveData = {
      name: name,
      department_id: department
    };
    saveDesignationData(saveData)
      .unwrap()
      .then((payload: any) => {
        setEditData("");
        toast("Successfully Saved.", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          type: "success",
          theme: "light",
        });
        setIsSubmit(false);
        setUploading(false);
        var dept = departmentList.find(x => x.id == payload.data?.department_id)
        var responseData = {
          ...payload.data, department: {
            id: dept?.id,
            name: dept?.name
          }
        }
        setData((prev: any) => [...prev, responseData])
        setIsOpenPopup(false);
        setDepartment('')
      })
      .catch((error: any) => {
        setError(error?.data?.message);
        setIsSubmit(false);
        setUploading(false);
        setIsOpenPopup(false);
      });
  }
  const updateDesignation = (name: string) => {
    const saveData = {
      name: name,
      id: editedData?.id,
      department_id: department
    };
    updateDesignationData(saveData)
      .unwrap()
      .then((payload: any) => {
        setEditData("");
        toast("Successfully Saved.", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          type: "success",
          theme: "light",
        });
        setIsSubmit(false);
        setUploading(false);
        setData(prev => prev.map(item => {
          if (item.id === editedData.id) {
            var dept = departmentList.find(x => x.id == payload.data?.department_id)
            var responseData = {
              ...payload.data, department: {
                id: dept?.id,
                name: dept?.name
              }
            }
            console.log("responseData ", responseData)
            return { ...item, name: payload?.data?.name, department: responseData?.department };
          } else {
            return item;
          }
        }));
        setIsOpenPopup(false);
        setDepartment('');
        setItemCount(data.length);
      })
      .catch((error: any) => {
        setError(error?.data?.message);
        setIsSubmit(false);
        setUploading(false);
        setIsOpenPopup(false);
      });
  }
  useEffect(() => {
    setItemCount(data.length);
  }, [data])
  useEffect(() => {
    setData(designations ? designations?.designationList : []);
    if (deptFilter != "0") {
      setData((prev) => prev.filter(x => x.department_id == deptFilter))
    } else {
      setData(designations ? designations?.designationList : []);
    }
  }, [deptFilter])
  return (
    <div>
      <ToastContainer className={"text-12 font-poppins font-normal"} />
      <CommonErrorPopup
        show={errorPopup}
        setShow={setErrorPopup}
        message={message}
      />
      <div className="px-2 sm:px-4 py-4 bg-white sm:mt-[25px] mt-0 font-poppins text-13 font-semibold text-[#858795] tracking-[-0.03rem] w-full h-full rounded-xl pt-3 pb-8 sm:pt-3 sm:pb-9">
        <div className="flex flex-wrap justify-between items-center">
          <div className="flex items-center">
            <div className="flex items-center mr-2 xs:mr-4 sm:w-[260px] w-[225px] md:mb-1">
              <DebounceSearchInput setState={setGlobalSearch} debounce={800}>
                {(setState) => (
                  <div className="font-normal flex border-1 border-[#C4C4C4] bg-white hover:bg-[#EFEFEF] rounded-[28px]">
                    <input
                      id="message"
                      autoComplete="off"
                      name="search-off"
                      type="text"
                      placeholder="Search"
                      className="focus:outline-none py-1 px-4 text-12 xl:text-14 leading-5 bg-transparent rounded-[28px] w-full text-graydark"
                      onChange={(e) => setState(e.target.value)}
                    />
                    <SearchIcon className={classNames("mr-2")} />
                  </div>
                )}
              </DebounceSearchInput>
            </div>
            <div
              className={classNames(
                styles.permissionDropdown,
                "flex items-center mr-[10px] sm:w-auto w-full"
              )}
            >
              <p className="hidden sm:w-auto w-1/2 text-left font-poppins font-medium  xl:text-sm text-12 text-[#464E5F] mr-1">
                Department
              </p>
              <CustomDropdownListForFilter
                placeholder="Department"
                state={deptFilter}
                setState={setDeptFilter}
                dropdownItems={
                  departmentList
                    ? [{ id: 0, name: 'All' }, ...departmentList].map((dp) => ({
                      value: dp.id,
                      label: dp.name,
                    }))
                    : []
                }
                textAlign="text-left"
                className="max-h-[38px] max-w-full sm:max-w-[250px] 2xl:w-32 sm:w-28 w-full"
              />
            </div>
          </div>
          <div className="flex w-full sm:w-auto items-center justify-between md:justify-end lg:justify-end">
            <div className="">
              <Button
                disabled={disabledData?.disabled}
                label={<span className="flex items-center">+ Add New</span>}
                onClick={() => {
                  setIsSuccess(false);
                  setPagination((prev) => ({ ...prev, pageIndex: 0 }));
                  setIsOpenPopup(true);
                }}
                type="button"
                variant="primary"
                customClass={
                  disabledData?.disabledClass +
                  " py-[3px] my-1 px-2 focus:outline-none hover:bg-vorpmyanmar hover:border-vorpmyanmar hover:text-vorpblue 2xl:text-[14px] text-12 leading-5 font-medium text-white"
                }
                size="base"
              />
            </div>
          </div>
        </div>

        <animated.div style={props}>
          <DesignationTable
            data={data}
            setData={setData}
            setIsSuccess={setIsSuccess}
            isSuccess={isSuccess}
            pagination={pagination}
            setPagination={setPagination}
            isLayoutControlOpen={isLayoutControlOpen}
            setIsLayoutControlOpen={setIsLayoutControlOpen}
            globalSearch={globalSearch}
            setGlobalSearch={setGlobalSearch}
            columnFilters={columnFilters}
            setColumnFilters={setColumnFilters}
            setHiddenFields={setHiddenFields}
            hiddenFields={hiddenFields ? hiddenFields : []}
            setCurrentView={setCurrentView}
            setIsEdit={setIsEdit}
            isEditing={isEditing}
            localModuleId={localModuleId}
            total={itemCount}
            changePage={changePage}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            setIsTemplate={setIsTemplate}
            setCols={setCols}
            visArray={visArray}
            setSkip={setSkip}
            sorting={sorting}
            setSorting={setSorting}
            deleteDepartment={deleteDesignation}
            setEditData={setEditData}
            setIsOpenPopup={setIsOpenPopup}
          />
        </animated.div>
        <ToastContainer className={"text-12 font-poppins font-normal"} />
      </div>
      <CreatePopupWithDropdown
        department={department}
        setDepartment={setDepartment}
        departmentList={departmentList}
        isOpen={isOpenPopup}
        setIsOpen={setIsOpenPopup}
        editedData={editedData}
        setEditData={setEditData}
        savePopupData={createDesignation}
        updatePopupData={updateDesignation}
        title="Designation"
        isSubmit={isSubmit}
        setIsSubmit={setIsSubmit}
        uploading={uploading}
        setUploading={setUploading}
      />
      <CommonErrorPopup
        show={error != "" ? true : false}
        setShow={() => setError("")}
        message={error}
      />
    </div>
  );
};
export default Designation;
