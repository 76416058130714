import React, { Component, Dispatch, FC, SetStateAction, useState, useRef, useEffect } from 'react';
// import { Modal, Fade } from "@material-ui/core";
import { Modal, Fade} from "@mui/material"
import { Editor } from '@tinymce/tinymce-react';

import whiteCancel from "../common/img/white-cancel.svg";
import blackCancel from "../common/img/black-cancel.svg";
import saveIcon from "../common/img/save-icon.svg";

interface IGoalModalTiny {
    isOpen: boolean;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
    onSave: (value: any) => void,
    tasks: any;
    mainTitle: string;
    id: number;
}

const ModalGoalTiny: FC<IGoalModalTiny> = ({ isOpen, setIsOpen, onSave, tasks, mainTitle, id }) => {
    const editorRef: any = useRef(null);

    const [goalTitle, setGoalTitle] = useState<any>();
    const [myGoal, setMyGoal] = useState(tasks);
    const goalTitleHandler = (event: any) => {
        setGoalTitle(event.target.value);
    }
    const handleEditorChange = (event: any) => {
        var content = event.target.getContent();
        setMyGoal(content);
    }
    const [defaultTask, setDefaultTask] = useState(tasks);
    useEffect(() => {
        if (editorRef.current) {
            setMyGoal(editorRef.current.getContent());
        }
    }, [editorRef])

    useEffect(() => {
        setGoalTitle(mainTitle)
        setMyGoal(tasks)
    }, [mainTitle,tasks])


    return (
        <Modal
            open={isOpen}
            style={{
                background: "rgb(0 0 0 / 0.3)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
            // aa
            onClose={() => { setIsOpen(false) }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            disableEnforceFocus={true}
        >
            <Fade in={isOpen} timeout={500}>
                <div className="modalContent w-[530px] focus-visible:outline-none">
                    <div id="modal-modal-title" className="bg-vorpblue relative flex justify-between py-6 px-10 rounded-t-xl">
                        <h3 className="text-18 font-primary font-semibold text-white">{goalTitle}</h3>
                        <button className="flex items-center justify-center" onClick={() => { setIsOpen(false) }}><img src={whiteCancel} alt="close modal icon" /></button>
                    </div>
                    <div id="modal-modal-description" className="py-9 px-10 bg-white rounded-b-xl">
                        <div className="mb-[0.688rem] last:mb-0">
                            <label className="text-black2 text-15 font-medium font-primary mb-[0.688rem] block">Title</label>
                            <input readOnly type="text" value={goalTitle} onChange={goalTitleHandler} placeholder="My Goal in 20299" className="w-full rounded py-3.5 px-3 border border-goalinputborder bg-offwhite text-black2 text-14 font-medium font-primary placeholder:text-graydark focus:outline-none focus-within:outline-none focus-visible:outline-none"></input>
                        </div>
                        <div className="mb-[0.688rem] last:mb-0">
                            <label className="text-black2 text-15 font-medium font-primary mb-[0.688rem] block">Content</label>
                            <Editor
                                apiKey="7lo2e7xqoln1oo18qkxrhvk9wohy5picx4824cjgas8odbg3"
                                onInit={(evt, editor) => editorRef.current = editor}
                                initialValue={tasks}
                                init={{
                                    height: 300,
                                    menubar: false,
                                    plugins:["code","link","lists"],
                                    // plugins: [
                                    //   "lists link image print",
                                    //   "media table paste code help",
                                    //   "textcolor",
                                    // ],
                                    // toolbar: 'undo redo | formatselect | styleselect | fontsizeselect | ' +
                                    // 'bold italic backcolor | alignleft aligncenter ' +
                                    // 'alignright alignjustify | bullist numlist outdent indent | ' +
                                    // 'removeformat | help | styleselect fontselect fontsizeselect | forecolor backcolor',
                                    toolbar:
                                      "undo redo | fontsize | forecolor backcolor | styleselect fontsizeselect | bold italic | alignleft aligncenter alignright alignjustify | outdent indent | code | link | numlist bullist",
                                    block_formats:
                                      "Paragraph=p; Header 1=h1; Header 2=h2; Header 3=h3",
                                    font_size_formats:
                                      "8px 9px 10px 11px 12px 13px 14px 15px 16px 17px 18px 24px 36px",
                                    content_style:
                                      "@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap'); body { font-family: 'Poppins', sans-serif; font-size: 14px; } h1,h2,h3,h4,h5,h6 { font-family: 'Poppins', sans-serif; }",
                                    font_formats: "Poppins=poppins;",
                                }}
                                onChange={handleEditorChange}
                            />
                        </div>
                        <div className="mb-[0.688rem] last:mb-0 flex items-center">
                            <button className="flex items-center justify-center bg-vorpblue mr-2.5 text-white font-primary text-14 font-medium rounded-md py-[8.5px] px-3" onClick={() => onSave({ myGoal, goalTitle })}><img src={saveIcon} alt="save icon" className="mr-3"></img>Save</button>
                            <button className="flex items-center justify-center bg-white font-primary text-14 font-medium text-black2" onClick={() => { setIsOpen(false) }}><img src={blackCancel} alt="black icon" className="mr-3"></img>Cancel</button>
                        </div>
                    </div>
                </div>
            </Fade>
        </Modal>
    )
}

export default ModalGoalTiny