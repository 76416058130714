import authHeader from './auth-header';
// import { EmployeeData, EmployeeListData } from '../../components/EmployeeTable/EmployeeTable';
import { IKeyValue, ILeave, IleaveType } from '../../leave-application/views/LeaveApplication';
import { api, endpointUrl } from './api';
import { TemplateData } from './employee';
import { redirectToLogin } from './annoucement-sheet';

export type LeaveResponse = { data: LeaveData[], module_id: number };

type LeaveData = {
  leaveData: LeaveDataWithPagination,
  statusData: string[],
  leaveType: IleaveType[],
  leaveFullHalf: string[],
  module_id: number,
  type?: number | string,
  status?: string,
  start?: string,
  end?: string,
  limit?: number,
  page?: number,
  search?: string,
  sort?: string,
  id?: number
}
type LeaveFilterData = {
  statusData: string[],
  leaveType: IleaveType[],
  leaveFullHalf: string[],
  type?: number | string,
  status?: string,
}
export type LeaveDataWithPagination = {
  current_page: number;
  from: number;
  to: number;
  total: number;
  per_page: number;
  data: ILeave[];
  first_page_url: string;
  last_page_url: string;
  next_page_url: string;
}
type IStatusData = {
  id: number,
  status: boolean
}
export type ILeaveAllowanceType = {
  id: number;
  data: {
    "data": any[]
  }
  // annualLeave: number;
  // bdLeave: number;
  // sickLeave: number;
  // unpaidLeave: number;
  // paternityLeave: number;
  // maternityLeave: number;
  // subsittutionLeave: number;
  // leftOverLeave: number;
}

export type EditLeave = {
  from_date: string,
  to_date: string,
  full_half_day: string,
  total_days: number,
  leave_type_id: number,
  reason: string,
}
export type ILeaveInfo = {
  "id": number,
  "leave_type": string,
  "description": string,
  "total_allowance": number,
  "actual_allowance": number,
  "leave_taken": number,
  "leave_balance": number
}
export interface ILeaveAllowance {
  "id": number,
  "employee_id": number,
  "leave_type_id": number,
  "employeeName": string,
  "leaveType": string,
  "description": string,
  "allowance": number,
  "expired_at": null | undefined,
}

export interface IleaveAllowanceResponse {
  data: ILeaveAllowance[]
}
export type ILeaveTaken={
  status: boolean,
  leaveData: LeaveDataWithPagination,
}
export interface IleaveAllowanceRequest extends ILeaveAllowance {

}
export type ILeaveType = {
  id: number,
  name: string,
  description: string,
  available: number
}
export type ILeaveBalanceData = {
  leavesInfo: ILeaveInfo[],
  previousBalance: {
    "available": number,
    "expiredDate": string
  },
}
export type ILeaveInformation = {
  status: boolean,
  employee: any,
  leaveData: LeaveDataWithPagination,
  leavesInfo: ILeaveInfo[],
  previousBalance: {
    "available": number,
    "expiredDate": string
  },
  leaveAllowances: ILeaveAllowance[],
  leaveTypes: ILeaveType[],
  statusData: string[],
  leaveFullHalf: string[]
}
export const leaveApi = api.injectEndpoints({
  endpoints: (build) => ({
    getLeaveData: build.query<LeaveData, Partial<LeaveData>>({
      query: ({ type, status, start, end, limit, page, search, sort }) => ({
        url: `${endpointUrl}leaveApplicationData?page=${page}&limit=${limit}&type=${type}&status=${status}&start=${start}&end=${end}&search=${search}&sort=${sort}`,
        headers: authHeader(),
      }),
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
      providesTags: (result: any = []) => {
        if (result.length > 0) {
          return [
            ...result?.leaveData?.data?.map((id: any) => ({ type: 'LeaveDataList', id } as const)),
            { type: 'LeaveDataList' as const, id: 'LIST' },
          ]
        } else {
          return [{ type: 'LeaveDataList' as const, id: 'LIST' },]
        }
      },
    }),
    getLeaveDataByID: build.query<LeaveData, any>({
      query: (id) => ({
        url: `${endpointUrl}leaveApplicationData/${id}`,
        headers: authHeader(),
      }),
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
      providesTags: (result: any = []) => {
        if (result.length > 0) {
          return [
            ...result?.leaveData?.data?.map((id: any) => ({ type: 'LeaveDataList', id } as const)),
            { type: 'LeaveDataList' as const, id: 'LIST' },
          ]
        } else {
          return [{ type: 'LeaveDataList' as const, id: 'LIST' },]
        }
      },
    }),
    getLeaveFilterData: build.query<LeaveFilterData, void>({
      query: () => ({
        url: `${endpointUrl}leaveApplicationData-filter-data`,
        headers: authHeader(),
      }),
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
      providesTags: (result: any = []) => {
        if (result.length > 0) {
          return [
            ...result?.leaveData?.data?.map((id: any) => ({ type: 'LeaveFilterDataList', id } as const)),
            { type: 'LeaveFilterDataList' as const, id: 'LIST' },
          ]
        } else {
          return [{ type: 'LeaveFilterDataList' as const, id: 'LIST' },]
        }
      },
    }),
    getLeaveAllowance: build.query<IleaveAllowanceResponse, Partial<IleaveAllowanceRequest>>({
      query: ({ id }) => (
        {
          url: id ? `${endpointUrl}employees/${id}/leave-allowances` : '',
          headers: authHeader(),
        }),
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
      providesTags: (result: any = []) => {
        if (result.length > 0) {
          return [
            ...result?.data?.data?.map((id: any) => ({ type: 'LeaveAllowanceList', id } as const)),
          ]
        } else {
          return [{ type: 'LeaveAllowanceList' as const, id: 'LIST' }]
        }
      }
    }),
    getLeaveAllowances: build.mutation<IleaveAllowanceResponse, number>({
      query: (id) => (
        {
          url: `${endpointUrl}employees/${id}/leave-allowances`,
          headers: authHeader(),
          method: 'GET',
        }),
      transformErrorResponse: (err: any) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
      invalidatesTags: (approvee) => [{ type: 'LeaveAllowanceList', id: "LIST" }],
    }),
    getLeaveTemplateList: build.query<TemplateData[], void>({
      query: () => ({ url: `http://localhost:3004/leaveTemplates`, headers: authHeader() }),
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
    }),
    editData: build.mutation<EditLeave, Partial<IStatusData>>({
      query: (body) => (
        {
          url: `${endpointUrl}leaves/${body.id}`,
          method: 'PUT',
          body,
          headers: authHeader()
        }),
      invalidatesTags: (approvee) => [{ type: 'LeaveDataList', id: "LIST" }],
    }),
    changeApproveLeaveStatus: build.mutation<LeaveData, Partial<IStatusData>>({
      query: (body) => (
        {
          url: `${endpointUrl}leaves/${body.id}/approve`,
          method: 'POST',
          // body,
          headers: authHeader(),
        }),
      // invalidatesTags: (approvee) => [{ type: 'LeaveDataList', id: "LIST" }],
    }),
    changeRejectedLeaveStatus: build.mutation<LeaveData, Partial<IStatusData>>({
      query: (body) => ({
        url: `${endpointUrl}leaves/${body.id}/reject`,
        method: 'POST',
        body,
        headers: authHeader()
      }),
      // invalidatesTags: (permissions) => [{ type: 'LeaveDataList', id: "LIST" }],
    }),
    saveLeaveAllowance: build.mutation<LeaveData, Partial<ILeaveAllowanceType>>({
      query: ({ id, data }) => (
        {
          url: `${endpointUrl}employees/${id}/leave-allowances`,
          method: 'PUT',
          body: data,
          headers: authHeader()
        }),
      invalidatesTags: (permissions) => [{ type: 'LeaveAllowanceList', id: "LIST" },{ type: 'LeaveDataList' }],
    }),
    getLeaveInformation: build.query<ILeaveInformation, number>({
      query: (id) => ({
        url: `${endpointUrl}employees/${id}/leave-information`,
        headers: authHeader(),
      }),
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
      providesTags: (result: any = []) => {
        if (result.length > 0) {
          return [
            ...result?.leaveData?.data?.map((id: any) => ({ type: 'LeaveDataList', id } as const)),
            { type: 'LeaveDataList' as const, id: 'LIST' },
          ]
        } else {
          return [{ type: 'LeaveDataList' as const, id: 'LIST' },]
        }
      },
    }),
    getLeaveTaken: build.query<ILeaveTaken, Partial<LeaveData>>({
      query: ({ id, type, status, start, end, limit, page, search, sort }) => ({
        url: `${endpointUrl}employees/${id}/leave-taken?page=${page}&limit=${limit}&type=${type}&status=${status}&start=${start}&end=${end}&sort=${sort}`,
        headers: authHeader(),
      }),
      transformErrorResponse: (err) => {
        if (err?.status === 401) {
          redirectToLogin();
        }
      },
      providesTags: (result: any = []) => {
        if (result.length > 0) {
          return [
            ...result?.leaveData?.data?.map((id: any) => ({ type: 'LeaveTaken', id } as const)),
            { type: 'LeaveTaken' as const, id: 'LIST' },
          ]
        } else {
          return [{ type: 'LeaveTaken' as const, id: 'LIST' },]
        }
      },
    }),
  }),
});

export const {
  useGetLeaveDataQuery,
  useGetLeaveTemplateListQuery,
  useChangeApproveLeaveStatusMutation,
  useChangeRejectedLeaveStatusMutation,
  useEditDataMutation,
  useSaveLeaveAllowanceMutation,
  useGetLeaveAllowancesMutation,
  useGetLeaveAllowanceQuery,
  useGetLeaveFilterDataQuery,
  useGetLeaveTakenQuery,
  useGetLeaveInformationQuery

} = leaveApi;

export const {
  endpoints: {
    getLeaveData,
    getLeaveTemplateList,
    changeApproveLeaveStatus,
    changeRejectedLeaveStatus,
    editData,
    saveLeaveAllowance,
    getLeaveAllowance,
    getLeaveAllowances,
    getLeaveFilterData,
    getLeaveInformation,
    getLeaveTaken
  }
} = leaveApi;


