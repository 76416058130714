import React from 'react'
import TableLoadingSkeleton from './TableLoadingSkeleton'
import SkeletonTable from './SkeletonTable';
var classNames = require("classnames");

const CommonTableSkeleton = (props: any) => {
  return (
    <div className="animate-pulse">
      <div className={classNames(props?.isProfile ? 'mt-[5px] p-1' : 'mt-[25px] p-4 pt-3', " font-poppins  tracking-[-0.03rem] w-full h-full rounded-xl  pb-8 sm:pt-3 sm:pb-9 px-4 ")}>
        <div className="flex justify-between flex-wrap items-center mb-1">
          <div className="flex items-center mr-2 xs:mr-4 sm:w-[260px] w-[225px] md:mb-1">
            <div className="bg-gray-300 w-[250px] h-8 rounded-full"></div>
          </div>
          <div className=" flex justify-end items-center">
            <div className={"bg-gray-300 h-8 w-32 py-[3px] my-1 px-2 ml-1 leading-5 rounded-full"} />
            <div className={"bg-gray-300 h-8 w-32 py-[3px] my-1 px-2 ml-1 leading-5 rounded-full"} />
            <div className={"bg-gray-300 h-8 w-32 py-[3px] my-1 px-2 ml-1 leading-5 rounded-full"} />
          </div>
        </div>
        <div className="flex sm:flex-row flex-col justify-between items-center mb-1">
          <div className="flex w-full sm:mr-5 custom-scrollbar sm:mb-0 mb-1">
            <div className={"bg-gray-300 h-8 w-32 py-[3px] my-1 px-2 ml-1 leading-5 rounded-full"} />
          </div>
          <div className="flex items-center justify-end sm:w-auto w-full sm:mb-0 mb-1">
            <div className="flex items-center justify-center text-center mr-3">
              <div className={"bg-gray-300 h-8 w-20 py-[3px] my-1 px-2 ml-1 leading-5 rounded-full"} />
            </div>
            <div className="">
              <div className={"bg-gray-300 h-8 w-20 py-[3px] my-1 px-2 ml-1 leading-5 rounded-full"} />
            </div>
            <div className={"bg-gray-300 h-8 w-20 py-[3px] my-1 px-2 ml-1 leading-5 rounded-full"} />
          </div>
        </div>
        <div className="flex flex-col w-full mt-3 overflow-x-scroll">
          <div className="min-w-[1000px] flex flex-row justify-between items-center mt-2">
            <div className="h-8 w-5 rounded-md bg-gray-300 m-1"></div>
            <div className="h-8 w-24 rounded-lg bg-gray-300 m-1"></div>
            <div className="h-8 w-44 rounded-lg bg-gray-300 m-1"></div>
            <div className="h-8 w-32 rounded-lg bg-gray-300 m-1"></div>
            <div className="h-8 w-40 rounded-lg bg-gray-300 m-1"></div>
            <div className="h-8 w-52 rounded-lg bg-gray-300 m-1"></div>
            <div className="h-8 w-20 rounded-lg bg-gray-300 m-1"></div>
            <div className="h-8 w-32 rounded-lg bg-gray-300 m-1"></div>
          </div>
          <div className="min-w-[1000px] flex flex-row justify-between items-center mt-2">
            <div className="h-8 w-5 rounded-md bg-gray-300 m-1"></div>
            <div className="h-8 w-24 rounded-lg bg-gray-300 m-1"></div>
            <div className="h-8 w-44 rounded-lg bg-gray-300 m-1"></div>
            <div className="h-8 w-32 rounded-lg bg-gray-300 m-1"></div>
            <div className="h-8 w-40 rounded-lg bg-gray-300 m-1"></div>
            <div className="h-8 w-52 rounded-lg bg-gray-300 m-1"></div>
            <div className="h-8 w-20 rounded-lg bg-gray-300 m-1"></div>
            <div className="h-8 w-32 rounded-lg bg-gray-300 m-1"></div>
          </div>
          <div className="min-w-[1000px] flex flex-row justify-between items-center mt-2">
            <div className="h-8 w-5 rounded-md bg-gray-300 m-1"></div>
            <div className="h-8 w-24 rounded-lg bg-gray-300 m-1"></div>
            <div className="h-8 w-44 rounded-lg bg-gray-300 m-1"></div>
            <div className="h-8 w-32 rounded-lg bg-gray-300 m-1"></div>
            <div className="h-8 w-40 rounded-lg bg-gray-300 m-1"></div>
            <div className="h-8 w-52 rounded-lg bg-gray-300 m-1"></div>
            <div className="h-8 w-20 rounded-lg bg-gray-300 m-1"></div>
            <div className="h-8 w-32 rounded-lg bg-gray-300 m-1"></div>
          </div>
          <div className="min-w-[1000px] flex flex-row justify-between items-center mt-2">
            <div className="h-8 w-5 rounded-md bg-gray-300 m-1"></div>
            <div className="h-8 w-24 rounded-lg bg-gray-300 m-1"></div>
            <div className="h-8 w-44 rounded-lg bg-gray-300 m-1"></div>
            <div className="h-8 w-32 rounded-lg bg-gray-300 m-1"></div>
            <div className="h-8 w-40 rounded-lg bg-gray-300 m-1"></div>
            <div className="h-8 w-52 rounded-lg bg-gray-300 m-1"></div>
            <div className="h-8 w-20 rounded-lg bg-gray-300 m-1"></div>
            <div className="h-8 w-32 rounded-lg bg-gray-300 m-1"></div>
          </div>
          <div className="min-w-[1000px] flex flex-row justify-between items-center mt-2">
            <div className="h-8 w-5 rounded-md bg-gray-300 m-1"></div>
            <div className="h-8 w-24 rounded-lg bg-gray-300 m-1"></div>
            <div className="h-8 w-44 rounded-lg bg-gray-300 m-1"></div>
            <div className="h-8 w-32 rounded-lg bg-gray-300 m-1"></div>
            <div className="h-8 w-40 rounded-lg bg-gray-300 m-1"></div>
            <div className="h-8 w-52 rounded-lg bg-gray-300 m-1"></div>
            <div className="h-8 w-20 rounded-lg bg-gray-300 m-1"></div>
            <div className="h-8 w-32 rounded-lg bg-gray-300 m-1"></div>
          </div>
          <div className="min-w-[1000px] flex flex-row justify-between items-center mt-2">
            <div className="h-8 w-5 rounded-md bg-gray-300 m-1"></div>
            <div className="h-8 w-24 rounded-lg bg-gray-300 m-1"></div>
            <div className="h-8 w-44 rounded-lg bg-gray-300 m-1"></div>
            <div className="h-8 w-32 rounded-lg bg-gray-300 m-1"></div>
            <div className="h-8 w-40 rounded-lg bg-gray-300 m-1"></div>
            <div className="h-8 w-52 rounded-lg bg-gray-300 m-1"></div>
            <div className="h-8 w-20 rounded-lg bg-gray-300 m-1"></div>
            <div className="h-8 w-32 rounded-lg bg-gray-300 m-1"></div>
          </div>
          <div className="min-w-[1000px] flex flex-row justify-between items-center mt-2">
            <div className="h-8 w-5 rounded-md bg-gray-300 m-1"></div>
            <div className="h-8 w-24 rounded-lg bg-gray-300 m-1"></div>
            <div className="h-8 w-44 rounded-lg bg-gray-300 m-1"></div>
            <div className="h-8 w-32 rounded-lg bg-gray-300 m-1"></div>
            <div className="h-8 w-40 rounded-lg bg-gray-300 m-1"></div>
            <div className="h-8 w-52 rounded-lg bg-gray-300 m-1"></div>
            <div className="h-8 w-20 rounded-lg bg-gray-300 m-1"></div>
            <div className="h-8 w-32 rounded-lg bg-gray-300 m-1"></div>
          </div>
          <div className="min-w-[1000px] flex flex-row justify-between items-center mt-2">
            <div className="h-8 w-5 rounded-md bg-gray-300 m-1"></div>
            <div className="h-8 w-24 rounded-lg bg-gray-300 m-1"></div>
            <div className="h-8 w-44 rounded-lg bg-gray-300 m-1"></div>
            <div className="h-8 w-32 rounded-lg bg-gray-300 m-1"></div>
            <div className="h-8 w-40 rounded-lg bg-gray-300 m-1"></div>
            <div className="h-8 w-52 rounded-lg bg-gray-300 m-1"></div>
            <div className="h-8 w-20 rounded-lg bg-gray-300 m-1"></div>
            <div className="h-8 w-32 rounded-lg bg-gray-300 m-1"></div>
          </div>
          <div className="min-w-[1000px] flex flex-row justify-between items-center mt-2">
            <div className="h-8 w-5 rounded-md bg-gray-300 m-1"></div>
            <div className="h-8 w-24 rounded-lg bg-gray-300 m-1"></div>
            <div className="h-8 w-44 rounded-lg bg-gray-300 m-1"></div>
            <div className="h-8 w-32 rounded-lg bg-gray-300 m-1"></div>
            <div className="h-8 w-40 rounded-lg bg-gray-300 m-1"></div>
            <div className="h-8 w-52 rounded-lg bg-gray-300 m-1"></div>
            <div className="h-8 w-20 rounded-lg bg-gray-300 m-1"></div>
            <div className="h-8 w-32 rounded-lg bg-gray-300 m-1"></div>
          </div>
          <div className="min-w-[1000px] flex flex-row justify-between items-center mt-2">
            <div className="h-8 w-5 rounded-md bg-gray-300 m-1"></div>
            <div className="h-8 w-24 rounded-lg bg-gray-300 m-1"></div>
            <div className="h-8 w-44 rounded-lg bg-gray-300 m-1"></div>
            <div className="h-8 w-32 rounded-lg bg-gray-300 m-1"></div>
            <div className="h-8 w-40 rounded-lg bg-gray-300 m-1"></div>
            <div className="h-8 w-52 rounded-lg bg-gray-300 m-1"></div>
            <div className="h-8 w-20 rounded-lg bg-gray-300 m-1"></div>
            <div className="h-8 w-32 rounded-lg bg-gray-300 m-1"></div>
          </div>
          <div className="min-w-[1000px] flex flex-row justify-between items-center mt-2">
            <div className="h-8 w-5 rounded-md bg-gray-300 m-1"></div>
            <div className="h-8 w-24 rounded-lg bg-gray-300 m-1"></div>
            <div className="h-8 w-44 rounded-lg bg-gray-300 m-1"></div>
            <div className="h-8 w-32 rounded-lg bg-gray-300 m-1"></div>
            <div className="h-8 w-40 rounded-lg bg-gray-300 m-1"></div>
            <div className="h-8 w-52 rounded-lg bg-gray-300 m-1"></div>
            <div className="h-8 w-20 rounded-lg bg-gray-300 m-1"></div>
            <div className="h-8 w-32 rounded-lg bg-gray-300 m-1"></div>
          </div>
          <div className="min-w-[1000px] flex flex-row justify-between items-center mt-2">
            <div className="h-8 w-5 rounded-md bg-gray-300 m-1"></div>
            <div className="h-8 w-24 rounded-lg bg-gray-300 m-1"></div>
            <div className="h-8 w-44 rounded-lg bg-gray-300 m-1"></div>
            <div className="h-8 w-32 rounded-lg bg-gray-300 m-1"></div>
            <div className="h-8 w-40 rounded-lg bg-gray-300 m-1"></div>
            <div className="h-8 w-52 rounded-lg bg-gray-300 m-1"></div>
            <div className="h-8 w-20 rounded-lg bg-gray-300 m-1"></div>
            <div className="h-8 w-32 rounded-lg bg-gray-300 m-1"></div>
          </div>
          <div className="min-w-[1000px] flex flex-row justify-between items-center mt-2">
            <div className="h-8 w-5 rounded-md bg-gray-300 m-1"></div>
            <div className="h-8 w-24 rounded-lg bg-gray-300 m-1"></div>
            <div className="h-8 w-44 rounded-lg bg-gray-300 m-1"></div>
            <div className="h-8 w-32 rounded-lg bg-gray-300 m-1"></div>
            <div className="h-8 w-40 rounded-lg bg-gray-300 m-1"></div>
            <div className="h-8 w-52 rounded-lg bg-gray-300 m-1"></div>
            <div className="h-8 w-20 rounded-lg bg-gray-300 m-1"></div>
            <div className="h-8 w-32 rounded-lg bg-gray-300 m-1"></div>
          </div>
        </div>
      </div >
    </div >
  )
}

export default CommonTableSkeleton