import {
    CloseIcon,
    DraftIcon,
    ReloadIcon,
    SendIcon
} from "../../common/Icons";
import classNames from "classnames";
import { Fade, Modal, Tooltip } from "@mui/material";
import { StringDropdownList } from "../../common/DropdownList";
import Button from "../../common/Button";
import { toast } from "react-toastify";
import { Dispatch, FC, SetStateAction, useCallback, useEffect, useState } from "react";
import { TDataList, TPreviousList, TProfileDailyListResponse, TProjectListDailyReport, TTaskNameList, TTaskTagsList, useGetClockoutRealTimeMutation, useSaveProfileDailyReportMutation } from "../../../app/services/profile-daily-popup";
import { changeStringToHMS } from "../../CustomTimePicker/CustomTimePicker";
import { baseObj } from "../../../utils/constants";
import DailyRows from "../Wrapper/DailyRows";
import { renderDateFormatDMY, renderDateFormatYMD } from "../../DashboardCalendarView/DashboardAddEventPopup";
export const sampleData = {
    uniqueId: 0,
    id: 0,
    is_previous: 0,
    project_id: 0,
    type: "project",
    task_category_id: 0,
    task_id: 0,
    time_spent: "00:00:00",
    isDisableRow: true,
    hours: "",
    minutes: "",
    is_need_to_create_new: 1
};
export const workingStatusList = [
    {
        id: 1,
        name: 'Break',
        value: "out"
    },
    {
        id: 2,
        name: 'Off work',
        value: "out"
    },
]
interface DailyReportPopup {
    clockInOutHandler: any;
    dailyPopupData: TDataList | undefined;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    isOpen: boolean;
    setProjectData: React.Dispatch<any>;
    projectData: any;
    setCategoriesList: React.Dispatch<React.SetStateAction<TTaskTagsList[]>>;
    categoriesList: TTaskTagsList[];
    setTaskNamesData: React.Dispatch<React.SetStateAction<string>>;
    taskNamesData: string;
    setProjectLists: React.Dispatch<React.SetStateAction<TProjectListDailyReport[]>>;
    projectLists: TProjectListDailyReport[];
    setGetHour: React.Dispatch<React.SetStateAction<number>>;
    getHour: number;
    setGetMinute: React.Dispatch<React.SetStateAction<number>>;
    getMinute: number;
    setTotalTime: Dispatch<SetStateAction<string>>;
    totalTime: string;
    setPreviousDataList: Dispatch<SetStateAction<TPreviousList[]>>;
    previousDataList: TPreviousList[];
    draftData: any[];
    setData: Dispatch<SetStateAction<any[]>>;
    data: any[];
    setDraftData: Dispatch<SetStateAction<any[]>>;
    setMaxTimeLimit: Dispatch<SetStateAction<number>>;
    maxTimeLimit: number;
    setTodayData: Dispatch<SetStateAction<TPreviousList | null | undefined>>;
    todayData: TPreviousList | null | undefined;
    timeHour: string;
    total_time?: string;
    refillDate?: Date | undefined;
    setCurrentData: Dispatch<any>;
    currentData: any;
    reportDate?: string;
    setReportDate?: Dispatch<SetStateAction<string>>;
    setRefillDate?: React.Dispatch<React.SetStateAction<Date | undefined>>;
    changeClockStatus: (res: any) => void;
    longitude:string;
    latitude:string;
}
const DailyReportPopupForm: FC<DailyReportPopup> = ({
    clockInOutHandler,
    dailyPopupData,
    isOpen,
    setIsOpen,
    setProjectData,
    projectData,
    setCategoriesList,
    categoriesList,
    setTaskNamesData,
    taskNamesData,
    setProjectLists,
    projectLists,
    setGetHour,
    getHour,
    setGetMinute,
    getMinute,
    setTotalTime,
    totalTime,
    setPreviousDataList,
    previousDataList,
    draftData,
    setData,
    data,
    setDraftData,
    setMaxTimeLimit,
    maxTimeLimit,
    setTodayData,
    todayData,
    timeHour,
    total_time,
    refillDate,
    setCurrentData,
    currentData,
    reportDate,
    setReportDate,
    setRefillDate,
    changeClockStatus,
    longitude,
    latitude
}) => {
    const [todayArray, setTodayArray] = useState<any[]>([]);
    const [hours, setHours] = useState<number>(0);
    const [minutes, setMinutes] = useState<number>(0);
    const [totalTimeData, setTotalTimeData] = useState<any>("");
    const [prevId, setPrevId] = useState<any>();
    const [workingStatus, setWorkingStatus] = useState<string>('');
    const [successPopup, setSuccessPopup] = useState<boolean>(false);
    const [clickSubmit, setClickSubmit] = useState<boolean>(false);
    const [errorPopup, setErrorPopup] = useState<boolean>(false);
    const [message, setMessage] = useState<any>([]);
    const [errorMsg, setErrorMsg] = useState<string>("");
    const [isShow, setIsShow] = useState<boolean>(true);
    const [selectedMember, setSelectedMember] = useState<string>("");
    const [uploading, setUploading] = useState<boolean>(false);
    const [isDisabledSubmit, setIsDisabledSubmit] = useState<boolean>(false);
    const [uploadingData, setUploadingData] = useState<boolean>(false);
    const [duplicateData, setDuplicateData] = useState<boolean>(false);

    const [taskNameId, setTaskNameId] = useState<any>(0);
    const [taskCategoryId, setTaskCategoryId] = useState<any>(0);
    const [messageData, setMessageData] = useState<string>("");
    const [timeError, setTimeError] = useState<string>("");
    const [timeSpentData, setTimeSpentData] = useState(false);
    const [saveDaily] = useSaveProfileDailyReportMutation();
    const [getClockoutRealTimeData] = useGetClockoutRealTimeMutation();
    const [realClockoutTime, setRealClockoutTime] = useState<string>("");
    const [currentNumber, setCurrentNumber] = useState<string>(
        "00:00:00"
    );

    const [currentValue, setCurrentValue] = useState<number>(0);
    const handleResetTime = () => {
        setData([...data]?.map((x: any) => {
            x.time_spent = '00:00:00'
            return x;
        }))
    }
    const handleAddNew = () => {
        setData([
            ...data,
            {
                ...sampleData,
                uniqueId: Math.random() * 100,
                task_category_id: categoriesList[0]?.id ?? 0,
                hours: "08",
                minutes: "00",
            },
        ]);
        // setData([...data, { ...sampleData, uniqueId: Math.random() * 100 }]);
    };

    const deleteFun = (id: number) => {
        const filterData = data.filter((d: any) => d.uniqueId !== id);
        setData([...filterData]);
    };

    const deleteFunDraft = (id: number) => {
        const filterData = draftData.filter((d: any) => d.id !== id);
        deleteFun(id)
        setDraftData([...filterData]);
    };

    useEffect(() => {
        // const final = [...data, ...draftData];
        const final = [...data];
        // console.log("dina", final);
        var isZero = final?.find((x: any) => x.time_spent == "00:00:00");
        if (isZero) {
            setTimeSpentData(true);
        } else {
            setTimeSpentData(false);
        }

        const isTaskIDZero = final?.find(x => x.task_id == 0 && x.task_name == "");
        const duplicates = final.reduce((acc: any, obj: any, index: number) => {
            if (obj.project_id != 0) {
                const duplicates = final.filter(
                    (item: any, i: any) => {
                        return i !== index &&
                            item.task_category_id === obj.task_category_id &&
                            item.project_id === obj.project_id &&
                            (item.task_id == obj.task_id &&
                                item.task_name == obj.task_name)
                    }
                );
                if (
                    duplicates.length > 0 &&
                    !acc.some(
                        (dup: any) =>
                            dup.task_category_id === obj.task_category_id &&
                            dup.project_id === obj.project_id &&
                            (dup.task_id == obj.task_id &&
                                dup.task_name == obj.task_name)
                    )
                ) {
                    return acc.concat([obj]);
                }
            }
            return acc;
        }, []);
        if (duplicates.length !== 0) {
            setDuplicateData(true);
            setUploading(false);
            return;
        } else {
            setDuplicateData(false);
            setUploading(false);
        }
        if (isTaskIDZero) {
            setUploadingData(true);
            setDuplicateData(false);
            return;
        } else {
            setUploadingData(false);
            setDuplicateData(false);
            return
        }
    }, [data, draftData]);

    useEffect(() => {
        changeEachSliderBackground(data);
    }, [data])

    const handleSubmit = () => {
        if(!refillDate){
            setClickSubmit(true);
            if (workingStatus == "") {
                return;
            }
        }
       
        setUploading(true);
        const final = [...data]?.filter((d: any) => d.task_name != '' || d.task_name != undefined)?.map((d: any) => {
            return d;
        });
        saveDaily(
            {
                data: final,
                is_draft: 0,
                is_previous_report: refillDate ? 1 : 0,
                date: refillDate ? renderDateFormatYMD(refillDate, '-') : reportDate,
                working_status: workingStatus,
                reason:'',
                latitude: latitude,
                longitude: longitude
            })
            .then((res: any) => {
                if (res?.data?.status) {
                    setTimeSpentData(false);
                    setDuplicateData(false);
                    //clockInOutHandler();
                    changeClockStatus(res)
                    setMessageData("");
                    setUploading(false);
                    setIsOpen(false);
                    if(setRefillDate){
                        setRefillDate(undefined)
                    }                    
                    setData([]);
                    setProjectData("");
                    setTaskCategoryId(0);
                }
                if (res?.error) {
                    toast(res?.error?.message, {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        type: "success",
                    });
                    setDuplicateData(false);
                    setMessageData(res.error.data.message);
                    setUploading(false);
                }
                setClickSubmit(false);
            })
            .catch((error: any) => {
                toast(error?.message, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    type: "success",
                });
                setUploading(false);
                setDuplicateData(false);
                setClickSubmit(false);
            });
        // setPrevId(0)
        // }

    };

    const handleSubmitDraft = () => {
        setUploading(true);
        const final = [...data];

        saveDaily({
            data: final,
            is_draft: 1,
            is_previous_report: 0,
            date: refillDate ? renderDateFormatYMD(refillDate, '-') : reportDate,
            working_status: '',
            reason:''
        })
            .then((res: any) => {

                if (res?.data?.status) {
                    setTimeSpentData(false);
                    //clockInOutHandler();
                    setUploading(false);
                    toast("Successfully Draft.", {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        type: "success",
                    });
                    setIsOpen(false);
                    setData([]);
                    setProjectData("");
                    setTaskCategoryId(0);
                    setMessageData("");
                }
                if (res?.error) {
                    setUploading(false);
                    setMessageData(res.error.data.message);
                }
            })
            .catch((error: any) => {

                setUploading(false);
            });
    };

    const handleClosePopup = () => {
        if (!todayArray) {
            setData([]);
        }
    };
    useEffect(() => {
        let dataItems: any = [];
        if (todayData?.items.length) {
            todayData?.items.forEach((item: any) => {
                dataItems.push({
                    uniqueId: Number(item.id) * Math.random() * 100,
                    id: item.id,
                    is_previous: item.is_previous,
                    type: item.type,
                    project_id: item.project_id,
                    task_category_id: item.task_category_id,
                    task_id: item.task_id,
                    time_spent: item.time_spent,
                    task_name: item.task_name,
                    is_need_to_create_new: item.is_need_to_create_new
                });
            });
            setData(removeDuplicateObjects([...draftData, ...dataItems], 'id'));
            setTodayArray([...dataItems]);
        } else {
            setData([]);
        }
    }, [todayData]);
    useEffect(() => {
        if (previousDataList.length) {
            let dataItems: any = [];
            const prevDataArr = previousDataList?.find(x => x.id == prevId)?.items
            setData((prev) => {                
                var dif = prev.filter(x => x.is_previous == 0);
                return prevDataArr ? [...prevDataArr, ...dif] : [...dif]
            });
            // const lastData = data.filter((d: any) => d.is_previous !== 1);
            previousDataList.forEach((data: any) => {
                if (data.id === prevId) {
                    data.items.forEach((item: any) => {
                        dataItems.push({
                            uniqueId: Number(item.id) * Math.random() * 100,
                            id: item.id,
                            is_previous: 1,
                            type: item.type,
                            project_id: item.project_id,
                            task_category_id: item.task_category_id,
                            task_id: item.task_id,
                            time_spent: item.time_spent,
                            task_name: item.task_name,
                            isDraftData: true,
                            is_need_to_create_new: item.is_need_to_create_new
                        });
                    });
                }
            });
            setDraftData([...dataItems]);
        }
    }, [prevId]);

    const msToTime = (durationData: any) => {
        const duration = durationData * 1000;
        var milliseconds = Math.floor((duration % 1000) / 100),
            seconds = Math.floor((duration / 1000) % 60),
            minutes = Math.floor((duration / (1000 * 60)) % 60),
            hours = Math.floor(duration / (1000 * 60 * 60)); //%24

        let h = hours < 10 ? "0" + hours : hours;
        let m = minutes < 10 ? "0" + minutes : minutes;
        let s = seconds < 10 ? "0" + seconds : seconds;
        // console.log("msto time", h);

        return h + ":" + m + ":" + s;
    };
    const TimeToMS = (time_spent: string) => {
        let regExTime = /([0-9]?[0-9]):([0-9][0-9]):([0-9][0-9])/;
        let regExTimeArr: any = regExTime.exec(time_spent);
        if (regExTimeArr) {
            let timeHr = regExTimeArr[1] * 3600 * 1000;
            let timeMin = regExTimeArr[2] * 60 * 1000;
            let timeSec = regExTimeArr[3] * 1000;
            let timeMs = timeHr + timeMin + timeSec;
            return timeMs / 1000;
        } else {
            return 0;
        }
    }
    useEffect(() => {
        let regExTime = /([0-9]?[0-9]):([0-9][0-9]):([0-9][0-9])/;
        var total = 0;
        var timespent = data?.map((x: any) => {
            let regExTimeArr: any = regExTime.exec(x.time_spent);
            if (regExTimeArr) {
                let timeHr = regExTimeArr[1] * 3600 * 1000;
                let timeMin = regExTimeArr[2] * 60 * 1000;
                let timeSec = regExTimeArr[3] * 1000;

                let timeMs = timeHr + timeMin + timeSec;
                total += timeMs;
            }
        });
        var newData = data.map((d, index: number) => {
            return { ...d, limit: maxTimeLimit - TimeToMS(data[index - 1]?.time_spent) }
        });
        setTotalTime(msToTime(total));
    }, [data, getHour, getMinute]);

    useEffect(() => {
        let regExTime = /([0-9]?[0-9]):([0-9][0-9]):([0-9][0-9])/;
        var total = 0;
        var timespent = data?.map((x: any) => {
            let regExTimeArr: any = regExTime.exec(x.time_spent);
            if (regExTimeArr) {
                let timeHr = regExTimeArr[1] * 3600 * 1000;
                let timeMin = regExTimeArr[2] * 60 * 1000;
                let timeSec = regExTimeArr[3] * 1000;

                let timeMs = timeHr + timeMin + timeSec;
                total += timeMs;
            }
        });

        var total1 = 0;
        var workingTime = (total) / 1000;
        setTotalTimeData(msToTime(total / 1000 + total1 / 1000));
        const diff = (maxTimeLimit - workingTime) == 0 ? 0 : (maxTimeLimit - workingTime) - 300;
        if (diff <= 300 && diff >= -300) {
            setTimeError('')
            setIsDisabledSubmit(false)
        }
        else if (diff <= 300) {
            setTimeError('The task time spent hours is more than the clocking hours')
            setIsDisabledSubmit(true)
        } else if (diff >= 300) {
            setTimeError('The task time spent hours is lower than the clocking hours')
            setIsDisabledSubmit(true)
        } else {
            setTimeError('')
            setIsDisabledSubmit(false)
        }
        // const final = [...data, ...draftData];
        // const isValidate = checkDuplicateData(final);   


    }, [data, draftData]);

    useEffect(() => {
        //for draft list hours and minutes
        let draftDataHour: any = draftData?.reduce(function (
            prev: any,
            current: any
        ) {
            return prev + +current?.hours;
        },
            0);

        let draftDataMinute: any = draftData?.reduce(function (
            prev: any,
            current: any
        ) {
            return prev + +current?.minutes;
        },
            0);

        const draftsMinutes = draftDataHour * 60 + draftDataMinute;
        // setDraftDataMinutesFinal(draftsMinutes);
    }, [getHour, getMinute, draftData]);

    useEffect(() => {
        if (timeHour) {
            setHours(Number(timeHour.slice(0, 2)));
            setMinutes(Number(timeHour.slice(4, 6)));
        }
    }, [timeHour]);
    useEffect(() => {
        if (successPopup) {
            toast("Done Successfully.", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                type: "success",
            });
        }

        setTimeout(() => {
            setSuccessPopup(false);
        }, 2000);
    }, [successPopup]);
    const handleTimeBlur = (value: string, time: number, id: number) => {
        const totalData = preventTimeChange(value, id);
        return totalData;
    };
    const changeEachSliderBackground = (data: any) => {
        data?.map((x: any) => {
            changeSliderBackground(x, x.time_spent)
        })
    }
    const changeSliderBackground = (cell: any, timestr: string) => {
        var time = changeStringToHMS(timestr);
        var numberInput = document.getElementById("myRange_" + cell?.uniqueId);
        if (numberInput && time) {
            if (maxTimeLimit > 0) {
                var value = ((time - 0) / (maxTimeLimit - 0)) * 100;
                //  console.log("changeSliderBackground ", value)
                numberInput.style.background =
                    "linear-gradient(to right, #3699FF 0%, #3699FF " +
                    value +
                    "%, #E1F0FF " +
                    value +
                    "%, #E1F0FF 100%)";
            } else {
                var value = 0;
                numberInput.style.background =
                    "linear-gradient(to right, #3699FF 0%, #3699FF 0%, #E1F0FF 0%, #E1F0FF 100%)";
            }

        }
    }
    const removeDuplicateObjects = (array: any, property: any) => {
        const uniqueIds: any = [];

        const unique = array.filter((element: any) => {
            const isDuplicate = uniqueIds.includes(element[property]);

            if (!isDuplicate) {
                uniqueIds.push(element[property]);

                return true;
            }

            return false;
        });

        return unique;
    }

    const preventTimeChange = (current: string, id: number) => {
        setData((prev: any) => {
            return prev.map((data: baseObj, index: number) => {
                const totalDataTimeSpent = prev?.filter((x: any) => x.uniqueId != id)?.reduce(function (acc: any, obj: any) { return acc + TimeToMS(obj.time_spent); }, 0) + TimeToMS(current);
                const filterCurrentData = removeDuplicateObjects(currentData, 'id')
                setCurrentData(filterCurrentData)
                const sameTimeSpent = currentData?.find((x: any) => x.id == id);
                if (sameTimeSpent) {
                    sameTimeSpent.time_spent = data.time_spent;
                } else {
                    setCurrentData([...currentData, { id: id, time_spent: data.time_spent }])
                }
                if (data.uniqueId == id) {
                    var curTimeSpent = TimeToMS(current);

                    // data.time_spent = current;
                    // var totalTime = prev?.reduce(function (acc: any, obj: any) { return acc + TimeToMS(obj.time_spent); }, 0);
                    if (totalDataTimeSpent > maxTimeLimit) {
                        const timeData = currentData?.find((x: any) => x.id == id);
                        data.maxLimit = totalDataTimeSpent;
                        //console.log(maxTimeLimit,totalDataTimeSpent,TimeToMS(current),totalDataTimeSpent-TimeToMS(current))
                        data.time_spent = msToTime(maxTimeLimit - (totalDataTimeSpent - TimeToMS(current)))//timeData?.time_spent || "00:00:00";
                    } else {
                        data.maxLimit = totalDataTimeSpent;
                        data.time_spent = current;
                        if (sameTimeSpent) {
                            sameTimeSpent.time_spent = current;
                        }
                    }
                }
                return data
            })
        });

    }
    const clearRefillDate=()=>{
        if(setRefillDate){
            setRefillDate(undefined)
        }
        
    }
    useEffect(() => {
        setData(removeDuplicateObjects([...draftData, ...data], 'id'))
    }, [draftData])    
    return <Modal
        open={isOpen}
        style={{
            background: "rgb(0 0 0 / 0.5)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        }}
        onClose={(event, reason) => {
            // handleClosePopup();
            if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
                setIsOpen(false);
                clearRefillDate();
            }
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
    >
        <Fade in={isOpen} timeout={500}>
            <div className="max-w-[1000px] w-full m-auto">
                {/* overflow-y-auto */}
                <div className="modalContent w-full h-full p-6 lg:p-0 flex justify-center items-center relative z-[999]">
                    {/* //overflow-hidden  */}
                    <div className="rounded-xl bg-white font-primary w-full">
                        <div className="px-3 py-3 bg-vorpblue flex justify-between rounded-t-lg items-center relative z-[999]">
                            <h3 className="text-18 leading-[27px] font-semibold text-white">
                                Daily Report ({refillDate ? renderDateFormatDMY(refillDate,'-') : reportDate?renderDateFormatDMY(new Date(reportDate),'-'):''})
                            </h3>
                            <button
                                key={Math.random() * 100}
                                type="button"
                                aria-label="Close Modal"
                                onClick={() => {
                                    setMessageData("");
                                    handleClosePopup();
                                    setIsOpen(false);
                                    setPrevId(0)
                                    clearRefillDate();
                                }}
                            >
                                <CloseIcon />
                            </button>
                        </div>
                        <div className="m-[5px] p-3 max-h-[500px] overflow-y-auto custom_scrollbar_popup">
                            {messageData.length ? (
                                <p className="text-[#EE436D] text-sm">{messageData}</p>
                            ) : null}

                            <div className="flex flex-col md:flex-row items-center justify-between">
                                <div className="">
                                    <p className=" text-zinc-400 text-sm lg:text-base">
                                        Please allocate your working time for each task.
                                    </p>
                                </div>
                                <div className="flex flex-wrap justify-end w-full lg:w-[293px]">
                                    <div className="">
                                        <StringDropdownList
                                            isDelete={true}
                                            state={prevId}
                                            setState={setPrevId}
                                            placeholder="Copy From Previous"
                                            className="w-full py-3 pl-3 mb-3 text-14 min-w-[200px] max-w-[200px]"
                                            dropdownItems={previousDataList?.map(
                                                (status: any) => ({
                                                    value: status.id,
                                                    label: status.name,
                                                    is_draft: status.is_draft,
                                                })
                                            )}
                                            textAlign={"text-left"}
                                            fontSize="xl:text-15 text-12"
                                            setSuccessPopup={setSuccessPopup}
                                            setErrorPopup={setErrorPopup}
                                            setMessage={setMessage}
                                        />
                                    </div>
                                    <div className={refillDate ? 'hidden' : 'opacity-1'}>
                                        <Button
                                            label={
                                                <div className="flex items-center text-14 sm:text-10 leading-[21px] font-medium text-sky-500">
                                                    <DraftIcon className="mr-2 text-sky-500" />
                                                    {uploading ? "Draft" : "Draft"}
                                                </div>
                                            }
                                            onClick={() => {
                                                handleSubmitDraft();
                                            }}
                                            type="button"
                                            variant="#E1F0FF"
                                            customClass={classNames(
                                                // uploading
                                                data?.find(x => x.task_id == 0 && (x.task_name == "" || x.task_name == undefined)) ||
                                                    data.length <= 0 ||
                                                    // duplicateData ||
                                                    uploading
                                                    || uploadingData// || timeError != ""
                                                    ? " pointer-events-none opacity-50"
                                                    : "",
                                                "bg-sky-200 hover:text-sky-500 px-[10px] py-3 mb-[13px] ml-[13px]"
                                            )}
                                            size="base"
                                        />
                                    </div>
                                </div>
                            </div>
                            {data?.map((d: any, index: number) => {
                                return (
                                    <DailyRows key={index}
                                        clockInOutHandler={clockInOutHandler}
                                        uploading={uploading}
                                        isDraft={d.isDraftData}
                                        data={d}
                                        setData={setData}
                                        setSelectedMember={setSelectedMember}
                                        setIsShow={setIsShow}
                                        setProjectData={setProjectData}
                                        projectLists={projectLists}
                                        setTaskCategoryId={setTaskCategoryId}
                                        categoriesList={categoriesList}
                                        setTaskNamesData={setTaskNamesData}
                                        setTaskNameId={setTaskNameId}
                                        // handleCreateTasks={handleCreateTasks}
                                        deleteFun={deleteFun}
                                        projectData={projectData}
                                        taskNamesData={taskNamesData}
                                        taskCategoryId={taskCategoryId}
                                        setUploadingData={setUploadingData}
                                        maxTimeLimit={maxTimeLimit}
                                        setMaxTimeLimit={setMaxTimeLimit}
                                        handleTimeBlur={handleTimeBlur}
                                        currentNumber={currentNumber}
                                        setCurrentNumber={setCurrentNumber}
                                        currentValue={currentValue}
                                        setCurrentValue={setCurrentValue}
                                    />
                                )
                            })}

                            <div className="flex justify-between items-center">
                                <div className="mb-2 mt-4">
                                    <Button
                                        disabled={uploading}
                                        label={
                                            <span className="flex items-center">+ Add New</span>
                                        }
                                        onClick={() => {
                                            handleAddNew();
                                        }}
                                        type="button"
                                        variant="primary"
                                        customClass={
                                            // disabledData?.disabledClass +
                                            classNames(
                                                uploading ? " pointer-events-none opacity-50" : "",
                                                " py-[10px] my-1 px-4 focus:outline-none hover:bg-vorpmyanmar hover:border-vorpmyanmar hover:text-vorpblue 2xl:text-[14px] text-12 leading-5 font-medium text-white"
                                            )
                                        }
                                        size="base"
                                    />
                                </div>
                                <div className="mb-2 mt-4 flex items-center">
                                    <span className="w-full block mr-2 text-12">Reset Time</span>
                                    <button
                                        type="button"
                                        onClick={() => {
                                            handleResetTime()
                                        }}
                                        className={classNames(
                                            "group rounded-md bg-zinc-400 hover:bg-annhoverbg transition-all duration-300"
                                        )}
                                    >
                                        <ReloadIcon />
                                    </button>
                                </div>
                            </div>
                            <div className="flex justify-end items-end flex-col">
                                <div>
                                    {/* {duplicateData ? (
                                        <p className="text-[#EE436D] text-12 mb-2">
                                            Duplicate tasks are exists!.
                                        </p>
                                    ) : null} */}
                                    {timeSpentData ? (
                                        <p className="text-[#EE436D] text-12 mb-2">
                                            Time spent hours must not 0hr 0m 0s.
                                        </p>
                                    ) : null}

                                    <p className="text-12 text-redPink mb-4">{timeError}</p>
                                </div>
                            </div>
                            <div className="flex justify-between mb-12 flex-wrap">
                                <div className={refillDate ? 'opacity-0' : ''}>
                                    <div className="relative max-w-[200px]">
                                        <StringDropdownList
                                            isDelete={true}
                                            state={workingStatus}
                                            setState={setWorkingStatus}
                                            placeholder="Working Status"
                                            className="w-full py-3 pl-3 mb-3 text-14 min-w-[200px] max-w-[200px]"
                                            dropdownItems={workingStatusList?.map(
                                                (status: any) => ({
                                                    value: status.name,
                                                    label: status.name,
                                                })
                                            )}
                                            textAlign={"text-left"}
                                            fontSize="xl:text-15 text-12"
                                            setSuccessPopup={setSuccessPopup}
                                            setErrorPopup={setErrorPopup}
                                            setMessage={setMessage}
                                        />
                                    </div>
                                    {(workingStatus == "" && clickSubmit == true) ? <p className="text-12 text-redPink mb-4">The working status field is required.</p> : ''}
                                </div>
                                <div className="flex justify-end items-center">
                                    <div className="flex flex-wrap">
                                        <div className="mr-2 max-h-[30px] mt-3">
                                            <div className=" font-semibold ">
                                                <span className=" text-red-500 text-xs max-h-[20px]">
                                                    {`${totalTimeData.split(":")[0]}h `}
                                                    {`${totalTimeData.split(":")[1]}m/`}
                                                </span>
                                                <span className="text-xs">
                                                    {realClockoutTime ? realClockoutTime.slice(0, 2) + 'h ' + realClockoutTime.slice(3, 5) + 'm '
                                                        : (total_time ? total_time : dailyPopupData?.total_time)
                                                    }
                                                </span>
                                            </div>
                                        </div>
                                        <div className="flex justify-center items-center">
                                            <Button
                                                type="button"
                                                label={
                                                    <span className="flex items-center text-12 leading-[18px] ">
                                                        <CloseIcon
                                                            width={14}
                                                            height={14}
                                                            className="mr-[10px] [&_path]:fill-graydark"
                                                        />
                                                        Cancel
                                                    </span>
                                                }
                                                onClick={() => {
                                                    setMessageData("");
                                                    setIsOpen(false);
                                                    if (!todayArray) {
                                                        setData([]);
                                                    }
                                                    setPrevId(0)
                                                    setUploading(false);
                                                    clearRefillDate();
                                                }}
                                                variant="no-outline"
                                                size="lg"
                                                customClass="min-w-[112px] mr-2 bg-offwhite text-graydark border-none"
                                            />
                                            <Button
                                                type="button"
                                                label={
                                                    <span className="flex items-center text-12 leading-[18px]">
                                                        <SendIcon className="mr-[10px] group-hover:opacity-0 transition-all duration-300" />
                                                        {uploading || uploadingData ? "Submit" : "Submit"}
                                                    </span>
                                                }
                                                onClick={() => {
                                                    handleSubmit();
                                                }}
                                                variant="no-outline"
                                                size="lg"
                                                customClass={classNames(
                                                    data?.find(x => x.task_id == 0 && (x.task_name == "" || x.task_name == undefined)) ||
                                                        timeSpentData ||
                                                        //duplicateData ||
                                                        uploading ||
                                                        uploadingData ||
                                                        timeError != ""
                                                        ? " pointer-events-none opacity-50"
                                                        : "",
                                                    "hover:bg-vorpblue mr-0 py-3 lg:py-3 px-5"
                                                )}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fade>
    </Modal>
}
export default DailyReportPopupForm;