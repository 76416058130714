import classNames from "classnames";
import { Dispatch, FC, SetStateAction, memo, useEffect } from "react";

interface IBaseRadioButton {
  id: string;
  setState: Dispatch<SetStateAction<string>>;
  value: string;
  label: string;
  name: string;
  className?: string;
  state: string;
  fontSize?: string;
}

const BaseRadioButton: FC<IBaseRadioButton> = memo(({ id, setState, value, label, name, state, className = "", fontSize }) => {
    const isSelected = value === state;

    //   let isSelected: boolean = false;
    //   useEffect(() => {
    // if(value == state){
    //   isSelected=true
    // }else{
    //   isSelected=false
    // }
    //   },[value, state]);

    return (
      <button
        onClick={() => setState(value)}
        type="button"
        className={`flex items-center ${className}`}
      >
        <input
          id={id}
          type="radio"
          value={value}
          name={name}
          className=" hidden"
        />
        <div
          className={classNames(
            "w-3 h-3 sm:w-5 sm:h-5 bg-offwhite rounded-full flex justify-center items-center hover:cursor-pointer",
            { " bg-vorpblue": isSelected }
          )}
        >
          {isSelected && (
            <div className="w-[6px] h-[6px] sm:w-[10px] sm:h-[10px] bg-white rounded-full"></div>
          )}
        </div>
        <label
          htmlFor={id}
          className={classNames(
            fontSize && fontSize != "" ? fontSize : "text-[13px] sm:text-15",
            "ml-[10px]  leading-[22px] font-medium  text-darkblack"
          )}
        >
          {label}
        </label>
      </button>
    );
  }
);

export default BaseRadioButton;
