import { redirectToLogin } from './annoucement-sheet';
import { api } from './api';
import authHeader from './auth-header';
import { Ids } from './media';

type ProgressCheckerResponse = {
    progress_checker_account_list: ProgressCheckerData,
    module_id: number,
    status: boolean,
    total_time: string,
    task_categories: TaskCategory[],
    time_spent:string,
    sale:string,
    total_cost_spent_usd:string,
    total_cost_spent_sgd:string,
    total_cost_spent_hkd:string,
    total_cost_spent_cny:string,
    total_cost_spent_mmk:string,
    total_cost_spent_myr:string,
    total_cost_spent_php:string,
    total_cost_spent_aud:string,
    profit:string,
}
type ProgressCheckerFilterResponse = {
    task_categories: TaskCategory[]
}
export type TaskCategory = {
    id: number;
    name: string;
}
export type TeamLeadData = {
    id: number;
    code: string,
    preferred_name: string;
    year_w_vo: number;
    age: number;
}
export type CountryListData = {
    id: number;
    name: string;
    code: string;
    time_zone: string;
}
export type ProgressChecker = {
    "id": number,
    "project_id": string,
    "project_name": string,
    "stage": TaskCategory[],
    "team_lead": TeamLeadData,
    "project_manager": TeamLeadData,
    "time_spent": number,
    "employees":EmployeePC[]
}
export type EmployeePC={
    "id": number,
    "code": string,
    "name": string,
    "year_w_vo":number,
    "age": number,
}
export type ProgressCheckerData = {
    current_page: number;
    data: ProgressChecker[],
    total: number;
}
type progressCheckerAccInput = {
    sort: string,
    search: string,
    page: number,
    limit: number,
    task_category_id:string
}
export const progressCheckerAccApi = api.injectEndpoints({
    endpoints: (build) => ({
        getProgressCheckerAcc: build.query<ProgressCheckerResponse, progressCheckerAccInput>({
            query: ({ sort, search, limit, page,task_category_id }) => ({ url: `progress-checker-account?task_category_ids=${task_category_id}&sort=${sort}&search=${search}&limit=${limit}&page=${page}`, headers: authHeader() }),
            transformErrorResponse: (err) => {
                if (err?.status === 401) {
                    redirectToLogin();
                }
            },
            providesTags: (result: any = []) => {
                if (result.data) {
                    if (result.data.length > 0) {
                        return [
                            ...result.data.map((id: any) => ({ type: "ProgressCheckerAcc", id } as const)),
                            { type: "ProgressCheckerAcc" as const, id: "LIST" },
                        ]
                    }
                    else {
                        return [{ type: "ProgressCheckerAcc" as const, id: "LIST" },]
                    }
                }
                else {
                    return [{ type: "ProgressCheckerAcc" as const, id: "LIST" },]
                }

            },
        }),
        getProgressCheckerAccFilterData: build.query<ProgressCheckerFilterResponse, void>({
            query: () => ({ url: `progress-checker-account-filter-data`, headers: authHeader() }),
            transformErrorResponse: (err) => {
                if (err?.status === 401) {
                    redirectToLogin();
                }
            },
            providesTags: (result: any = []) => {
                if (result.data) {
                    if (result.data.length > 0) {
                        return [
                            ...result.data.map((id: any) => ({ type: "ProgressCheckerAccFilter", id } as const)),
                            { type: "ProgressCheckerAccFilter" as const, id: "LIST" },
                        ]
                    }
                    else {
                        return [{ type: "ProgressCheckerAccFilter" as const, id: "LIST" },]
                    }
                }
                else {
                    return [{ type: "ProgressCheckerAccFilter" as const, id: "LIST" },]
                }

            },
        }),
    }),
});

export const {
    useGetProgressCheckerAccQuery,
    useGetProgressCheckerAccFilterDataQuery
} = progressCheckerAccApi;


export const {
    endpoints: {
        getProgressCheckerAcc,
        getProgressCheckerAccFilterData
    },
} = progressCheckerAccApi;