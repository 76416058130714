import React from "react";
import styles from "./DashboardOverview.module.scss";
import DashboardAnnoucement from "../../components/DashboardAnnoucement/DashboardAnnoucement";
import DashboardActivities from "../../components/DashboardActivities/DashboardActivities";
import DashboardDocument from "../../components/DashboardDocument/DashboardDocument";

const DashboardOverview = (props: any) => {
  return (
    <>
      <div className="flex flex-wrap lg:flex-nowrap">
        <div className="w-full 3xl:w-1/4 lg:w-[30%] h-full">
          <DashboardDocument dataLists={props.docs} />
        </div>
        <div className="w-full mt-5 lg:mt-0 3xl:w-[49%] lg:w-[40%] lg:ml-[24px]">
          <DashboardAnnoucement
            annoucements={props.annoucementData}
            twoCol={props.twoCol}
          />
        </div>
        <div className="w-full mt-5 lg:mt-0 3xl:w-1/4 lg:w-[30%] lg:ml-[24px]">
          <DashboardActivities
            activityPermis={props.activityPermis}
            title={props.activitiestitle}
            activities={props.activitiesData}
          ></DashboardActivities>
        </div>
      </div>
    </>
  );
};
export default DashboardOverview;
